var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { RequestResponsePreview } from "../RequestResponsePreview";
import { AssertionContainer } from "../../../../containers/account/TestProject/Assertion";
export const Assertions = (_a) => {
    var { assertions, assertionTypeItems, conditionSelectPlaceholder, isShowResponse, onChangeResponsePreviewSource, onToggleResponseShowing, responseBodyPreviewModeLabel, responsePreviewData, responsePreviewSource, responsePreviewSourceItems, responsePreviewText, responsePreviewTitle, typeSelectPlaceholder, valuePlaceholder } = _a, restProps = __rest(_a, ["assertions", "assertionTypeItems", "conditionSelectPlaceholder", "isShowResponse", "onChangeResponsePreviewSource", "onToggleResponseShowing", "responseBodyPreviewModeLabel", "responsePreviewData", "responsePreviewSource", "responsePreviewSourceItems", "responsePreviewText", "responsePreviewTitle", "typeSelectPlaceholder", "valuePlaceholder"]);
    return (React.createElement(React.Fragment, null,
        (assertions || []).map((assertion) => assertion ? (React.createElement(AssertionContainer, Object.assign({}, restProps, { assertion: assertion, assertionTypeItems: assertionTypeItems, conditionSelectPlaceholder: conditionSelectPlaceholder, typeSelectPlaceholder: typeSelectPlaceholder, key: assertion.id, valuePlaceholder: valuePlaceholder }))) : (React.createElement(React.Fragment, null))),
        React.createElement(RequestResponsePreview, { isShowResponse: isShowResponse, mode: restProps.mode, onChangeResponsePreviewSource: onChangeResponsePreviewSource, onToggleResponseShowing: onToggleResponseShowing, responseBodyPreviewModeLabel: responseBodyPreviewModeLabel, responsePreviewData: responsePreviewData, responsePreviewSource: responsePreviewSource, responsePreviewSourceItems: responsePreviewSourceItems, responsePreviewText: responsePreviewText, responsePreviewTitle: responsePreviewTitle })));
};
