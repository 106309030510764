import React from "react";
import { connect } from "react-redux";
import { useIntl } from "@boomq/utils";
import { hintTheme, hintTooltipTheme, TimersCount } from "./styled";
import { Hint } from "../../../common/Hint";
import { RequestTimerParams, RequestTimerType } from "../../../../helpers/types";
import timer from "../../../../assets/images/icons/timer.svg";
import { getTestProjectSettingsField } from "../../../../redux/reducers/newTest";
import { getEnabledTimerList } from "../../../../helpers/requestTimers";
import { getTimerEnabled, getTimerType } from "../../../../models/requestTimer";
const connector = connect((state) => ({
    JMXTimersSettings: getTestProjectSettingsField(state, "JMXTimersSettings")
}));
const getTimerText = (constantTimer, randomTimer, multiplicationFactor) => constantTimer
    ? `${multiplicationFactor * constantTimer.duration} sec`
    : `${multiplicationFactor * randomTimer.lowerLimit}...${multiplicationFactor * randomTimer.upperLimit} sec`;
const getTimerTextByType = (timerList, multiplicationFactor) => {
    const enabledTimerList = getEnabledTimerList(timerList);
    const constantTimer = enabledTimerList.find((timer) => getTimerType(timer) === RequestTimerType.Constant);
    const randomTimer = enabledTimerList.find((timer) => getTimerType(timer) === RequestTimerType.Random);
    return (constantTimer || randomTimer) && multiplicationFactor > 0
        ? getTimerText(constantTimer, randomTimer, multiplicationFactor)
        : "";
};
const getTimerDurationWithMultiplicationFactor = (value, multiplicationFactor) => multiplicationFactor === 1 ? String(value) : `${multiplicationFactor} ${String.fromCharCode(215)} ${value}`;
const getTimerRandomValueWithMultiplicationFactor = (lowerLimit, upperLimit, multiplicationFactor) => multiplicationFactor === 1
    ? `${lowerLimit}...${upperLimit}`
    : `${multiplicationFactor} ${String.fromCharCode(215)} (${lowerLimit}...${upperLimit})`;
const getTimerTooltipText = (timer, multiplicationFactor, isTimerDisableText, timerLabel) => {
    const multiplicationCoeff = getTimerEnabled(timer) ? multiplicationFactor : 0;
    switch (getTimerType(timer)) {
        case RequestTimerType.Constant: {
            return {
                defaultMessage: "{label}: Constant timer, {duration} sec {isTimerOn}",
                id: `account.new.test.project.group.requests.timer.${RequestTimerType.Constant}`,
                values: {
                    duration: getTimerDurationWithMultiplicationFactor(timer.duration, multiplicationCoeff),
                    label: timerLabel,
                    isTimerOn: timer.enabled ? "" : isTimerDisableText
                }
            };
        }
        case RequestTimerType.Random: {
            return {
                defaultMessage: "{label}: Random timer, {value} sec {isTimerOn}",
                id: `account.new.test.project.group.requests.timer.${RequestTimerType.Random}`,
                values: {
                    [RequestTimerParams.Type]: timer.randomTimerType,
                    isTimerOn: timer.enabled ? "" : isTimerDisableText,
                    label: timerLabel,
                    value: getTimerRandomValueWithMultiplicationFactor(timer.lowerLimit, timer.upperLimit, multiplicationCoeff)
                }
            };
        }
        case RequestTimerType.Other: {
            return {
                defaultMessage: "{label}: {type} {isTimerOn}",
                id: `account.new.test.project.group.requests.timer.${timer.otherTimerType}`,
                values: {
                    type: timer.otherTimerType,
                    isTimerOn: timer.enabled ? "" : isTimerDisableText,
                    label: timerLabel
                }
            };
        }
        default: {
            return { defaultMessage: "Type: none", id: "account.new.test.project.group.requests.timer.none" };
        }
    }
};
const RequestJMXTimerIcon = ({ timerList, JMXTimersSettings }) => {
    const { formatMessage } = useIntl();
    const { multiplicationFactor } = JMXTimersSettings || {};
    const isTimerDisableText = formatMessage({
        defaultMessage: " - off",
        id: "account.new.test.project.group.requests.timer.off"
    });
    const timersCount = (timerList || []).length;
    const getTimerLabel = (index) => `${formatMessage({
        defaultMessage: "Timer",
        id: "account.new.test.project.group.requests.timer.label"
    })} ${index + 1}`;
    const getTimersTooltipText = (timerList, multiplicationFactor, isTimerDisableText) => timerList.map((timer, index) => (Object.assign({ key: index }, getTimerTooltipText(timer, multiplicationFactor, isTimerDisableText, getTimerLabel(index)))));
    return timersCount === 0 ? (React.createElement(React.Fragment, null)) : (React.createElement(React.Fragment, null,
        React.createElement(Hint, { icon: timer, theme: hintTheme, themeTooltip: hintTooltipTheme, tooltipText: getTimersTooltipText(timerList, multiplicationFactor, isTimerDisableText) }),
        timersCount > 1 && React.createElement(TimersCount, null, timerList.length),
        getTimerTextByType(timerList || [], multiplicationFactor)));
};
export default connector(RequestJMXTimerIcon);
