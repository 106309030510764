import { handleActions } from "redux-actions";
import { usersFailure, usersRequest, usersSuccess, userDelete, deleteUserRequest, deleteUserSuccess, deleteUserFailure, resetPasswordSuccess } from "../../actions/admin/users";
const initialState = {
    currentPage: 0,
    error: null,
    isDeleting: [],
    isFetching: false,
    totalPages: 0,
    userSites: [],
    users: []
};
export default handleActions({
    [usersRequest]: (state, action) => (Object.assign(Object.assign({}, state), { currentPage: action.payload.page, isFetching: true, users: [] })),
    [usersSuccess]: (state, action) => (Object.assign(Object.assign(Object.assign({}, state), { isFetching: false }), action.payload)),
    [usersFailure]: (state, action) => (Object.assign(Object.assign({}, state), { isFetching: false, error: action.payload })),
    [userDelete]: (state, action) => (Object.assign(Object.assign({}, state), { users: state.users.filter((user) => user.id !== action.payload) })),
    [deleteUserRequest]: (state, action) => (Object.assign(Object.assign({}, state), { isDeleting: [...state.isDeleting, action.payload.id] })),
    [deleteUserSuccess]: (state, action) => (Object.assign(Object.assign({}, state), { isDeleting: state.isDeleting.filter((item) => item !== action.payload) })),
    [deleteUserFailure]: (state, action) => (Object.assign(Object.assign({}, state), { isDeleting: state.isDeleting.filter((item) => item !== action.payload) })),
    [resetPasswordSuccess]: (state, action) => (Object.assign(Object.assign({}, state), { users: state.users.map((userData) => userData.id === action.payload.userId ? Object.assign(Object.assign({}, userData), { resetUrl: action.payload.resetUrl }) : userData) }))
}, initialState);
export const getListOfUsers = (state) => state.users.users;
export const getUsersCurrentPage = (state) => state.users.currentPage;
export const getUsersTotalPages = (state) => state.users.totalPages;
export const getUsersLoading = (state) => state.users.isFetching;
export const getDeletingUsers = (state) => state.users.isDeleting;
