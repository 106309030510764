import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getValueByPath } from "@boomq/utils";
import { AllTestProjectSettingsSetsItem } from "../../../components/account/AllTestProjectSettingsSetsItem";
import { prepareLabels } from "../../../helpers/labels";
import { getSettingsSetActionItemsByTeamMemberPermissions } from "../../../helpers/testProjectSettingsSets";
import { SettingsSetsItemAction } from "../../../helpers/types";
import { addLabelRequest, addLabelWithCreatingRequest, copyTestProjectSettingsSetRequest, deleteLabelRequest } from "../../../redux/actions/testProjectSettingsSet";
import { searchLabelsByName } from "../../../redux/actions/testProjectSettingsSets";
import { getClientPermissionList } from "../../../redux/reducers/authorizePlate";
const connector = connect((state) => ({
    permissionList: getClientPermissionList(state)
}), {
    addLabelRequest,
    addLabelWithCreatingRequest,
    [SettingsSetsItemAction.Copy]: copyTestProjectSettingsSetRequest,
    deleteLabelRequest,
    searchLabelsByName
});
const WrappedAllTestProjectSettingsSetsItem = (props) => {
    const actionItems = getSettingsSetActionItemsByTeamMemberPermissions(props.permissionList);
    const labels = prepareLabels(getValueByPath(props, "setting.labelSet", []));
    return React.createElement(AllTestProjectSettingsSetsItem, Object.assign({ actionItems: actionItems }, props, { labels: labels }));
};
export default withRouter(connector(WrappedAllTestProjectSettingsSetsItem));
