import styled from "styled-components";
import { TableCell } from "../SlaReportTable/styled";
import { IconArrow } from "../../../styled/Icons/Arrow";
import { IconCheck } from "../../../styled/Icons/Check";
import { IconCloseSVG } from "../../../styled/Icons/CloseSVG";
import { getColorByName } from "../../../../helpers/color/colorByName";
export const HintIcon = styled.span `
    display: flex;
`;
export const IconArrowUp = styled(IconArrow) `
    transform: rotate(180deg);
`;
export const StyledIconCheck = styled(IconCheck) `
    cursor: help;
    line-height: 0px;
`;
export const StyledIconClose = styled(IconCloseSVG) `
    cursor: help;
    line-height: 0px;
`;
export const TableCellSlaResultContent = styled.div `
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
    svg {
        margin-left: 4px;
    }
`;
export const TableCellSlaResultWrapper = styled(TableCell) `
    background: ${({ isBaselineTest }) => isBaselineTest ? getColorByName("lightBlue") : getColorByName("white")};
    color: ${({ isBaselineTest }) => (isBaselineTest ? getColorByName("white") : getColorByName("black"))};
    text-align: center;
`;
