import React from "react";
import { connect } from "react-redux";
import { ConfirmForm } from "../../../common/ConfirmForm";
import { changeTestProjectVersion } from "../../../../redux/actions/testProjects";
const connector = connect(null, { changeTestProjectVersion });
const customButtonTheme = {
    minWidth: "300px",
    paddingLeft: "10px!important",
    paddingRight: "10px!important",
    width: "40%!important"
};
const ChangeTestProjectVersionConfirmForm = ({ changeTestProjectVersion, value }) => (React.createElement(ConfirmForm, { confirmButton: {
        action: () => changeTestProjectVersion({ isAccepted: true, value }),
        theme: customButtonTheme,
        title: {
            defaultMessage: "Yes, switch version",
            id: "account.modal.new.test.project.version.change.confirm.button.yes"
        }
    }, cancelButton: {
        theme: customButtonTheme,
        title: {
            defaultMessage: "No, edit the current version",
            id: "account.modal.new.test.project.version.change.confirm.button.no"
        }
    }, text: {
        description: [
            {
                defaultMessage: "You have unsaved changes in the current vesrion. If you change the project version now, all the unsaved changes will be discarded. Do you want to continue?",
                id: "account.modal.new.test.project.version.change.confirm.text"
            }
        ]
    } }));
export default connector(ChangeTestProjectVersionConfirmForm);
