import styled from "styled-components";
import { Color, IconDelete } from "@boomq/uikit";
export const Action = styled.div `
    cursor: pointer;
    svg {
        fill: ${Color.blue};
    }
`;
export const StyledIconDelete = styled(IconDelete) `
    margin-left: 12px;
`;
