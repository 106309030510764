import { handleActions } from "redux-actions";
import { isObject } from "@boomq/utils";
import authorizeActions from "../../actions/authorize";
import { saveTestProjectSettingsSetSuccess, testProjectSettingsSetChangeParams, testProjectSettingsSetChangeParamsField, testProjectSettingsSetSuccess } from "../../actions/testProjectSettingsSet";
import { RequestTimerParams, RequestTimerType } from "../../../helpers/types";
const { logout } = authorizeActions;
export const initialState = {
    defaultTimer: {
        enabled: true,
        [RequestTimerParams.Type]: RequestTimerType.Constant,
        [RequestTimerParams.ConstantValueParam]: 0
    },
    httpRequestDefaults: {
        connectTimeout: 60,
        responseTimeout: 120
    },
    id: undefined,
    labelSet: [],
    name: ""
};
export default handleActions({
    [logout]: () => (Object.assign({}, initialState)),
    [saveTestProjectSettingsSetSuccess]: (state, action) => (Object.assign(Object.assign({}, state), { id: action && action.payload > 0 ? action.payload : initialState.id })),
    [testProjectSettingsSetChangeParams]: (state, action) => isObject(action.payload)
        ? Object.assign(Object.assign({}, state), action.payload) : state,
    [testProjectSettingsSetChangeParamsField]: (state, action) => {
        const { field, subfield, value } = action && action.payload ? action.payload : {};
        return field && subfield
            ? Object.assign(Object.assign({}, state), { [field]: Object.assign(Object.assign({}, state[field]), { [subfield]: value }) }) : Object.assign(Object.assign({}, state), { [field]: value });
    },
    [testProjectSettingsSetSuccess]: (state, action) => (Object.assign(Object.assign({}, state), action.payload))
}, initialState);
