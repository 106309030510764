var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { objectToParams } from "@boomq/utils";
import { instance } from "./authSrv";
import { generateApiFilterByLabels } from "../helpers/labels";
const location = window.location;
const servicePort = location.port ? `:${location.port}` : "";
const serviceName = "test-srv";
export const getTestSrvUrl = () => `${location.protocol}//${location.hostname}${servicePort}/${serviceName}`;
export const createAndStartTest = (params) => instance.post(`${getTestSrvUrl()}/test`, params);
export const getClientTestsUrl = (_a) => {
    var { labelNames } = _a, restProps = __rest(_a, ["labelNames"]);
    return `${getTestSrvUrl()}/test?sort=createDate,desc${objectToParams(restProps, "&")}${generateApiFilterByLabels(labelNames)}`;
};
export const getClientTestsByAdmin = (props) => instance.get(`${getTestSrvUrl()}/admin/test?sort=id,desc${objectToParams(props, "&")}`);
export const getClientTests = (props = {}) => instance.get(getClientTestsUrl(props));
export const getClientTest = (id) => instance.get(`${getTestSrvUrl()}/test/${id}`);
export const stopClientTest = (id) => instance.post(`${getTestSrvUrl()}/test/${id}/stop`);
export const getTestsLabelsByQuery = (query) => instance.get(`${getTestSrvUrl()}/test/label?query=${query}`);
export const getTestResultFiles = (testId) => instance.get(`${getTestSrvUrl()}/testResultFile?testId=${testId}`);
export const getTestResultFileDownloadLink = ({ containerId, fileName, testId }) => instance.get(`${getTestSrvUrl()}/testResultFile/downloadLink?containerId=${containerId}&testId=${testId}&fileName=${fileName}`);
export const getTestUserList = () => instance.get(`${getTestSrvUrl()}/user`);
export const createSlaReportProfile = (params) => instance.post(`${getTestSrvUrl()}/slaReportProfile`, params);
export const deleteSlaReportProfile = (slaReportId) => instance.delete(`${getTestSrvUrl()}/slaReportProfile/${slaReportId}`);
export const previewSlaReport = (params) => instance.post(`${getTestSrvUrl()}/slaReportProfile/previewReport`, params);
export const generateSlaReport = (slaReportProfileId) => instance.post(`${getTestSrvUrl()}/slaReport?slaReportProfileId=${slaReportProfileId}`);
export const getAvailableSlaTypeList = (params) => instance.post(`${getTestSrvUrl()}/slaReportProfile/slaTypeList`, params);
export const getSlaReportProfiles = () => instance.get(`${getTestSrvUrl()}/slaReportProfile`);
export const getSlaReportProfile = (slaReportId) => instance.get(`${getTestSrvUrl()}/slaReportProfile/${slaReportId}`);
export const updateSlaReportProfile = ({ slaReportId, params }) => instance.put(`${getTestSrvUrl()}/slaReportProfile/${slaReportId}`, params);
export const updateTestResultSla = ({ testId, params }) => instance.post(`${getTestSrvUrl()}/testResult/sla?testId=${testId}`, params);
