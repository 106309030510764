import React, { FC } from "react";
import { SelectBodyTemplateProps, DefaultComboBoxBody } from "@boomq/uikit";

export const ResourceBodyTemplate: FC<SelectBodyTemplateProps> = ({
    items,
    selectedItems
}: SelectBodyTemplateProps): JSX.Element => {
    const selectedItemIds = (selectedItems || []).map((selectedItem) => selectedItem.id, []);
    const getBodyText = (items || [])
        .filter((item) => selectedItemIds.includes(item.id))
        .map((resource) => resource.bodyText)
        .join(", ");

    return <DefaultComboBoxBody>{getBodyText}</DefaultComboBoxBody>;
};
