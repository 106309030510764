import React, { FC } from "react";
import { round } from "@boomq/utils";
import { Spoiler, getUltimateThreadGroupStepCaptionTemplateByMode } from "@boomq/common";
import { customRailStyleTheme, Content, LoadProfileStep, TypeSliders } from "./styled";
import { TypeSlider } from "../../TypeSlider";
import { HashMap, TestSetting } from "../../../../helpers/types";

interface Props {
    children?: JSX.Element | JSX.Element[];
    handleStyle?: HashMap<any>;
    isDisabledSettings?: boolean;
    loadProfile: HashMap<any>;
    step: HashMap<any>;
    testSettings: TestSetting[];
    trackStyle?: HashMap<any>;
}

interface Handlers {
    onChange?: (data) => void;
    onCopy?: (id: string) => void;
    onDelete?: (id: string) => void;
}

const getStepCaption = (params): JSX.Element => {
    const CaptionComponent = getUltimateThreadGroupStepCaptionTemplateByMode(params.mode);

    return <CaptionComponent {...params} />;
};

const DEFAULT_PROPS = {
    isDisabledSettings: false,
    loadProfile: {},
    step: {},
    testSettings: []
};

export const UltimateThreadGroupLoadProfileStep: FC<Props & Handlers> = (
    props: Props & Handlers = DEFAULT_PROPS
): JSX.Element => {
    const changeHandler = ({ field, value }): void =>
        props && typeof props.onChange === "function"
            ? props.onChange({ stepId: props.step.id, field, value })
            : undefined;

    const getStylePropBySetting = (propName: string, settingName: string): HashMap<any> =>
        props[propName] ? props[propName][settingName] : undefined;

    return (
        <LoadProfileStep key={props.step.id}>
            <Spoiler caption={getStepCaption(props)} onlyArrowClick={true}>
                <Content>
                    <TypeSliders disabled={props.isDisabledSettings}>
                        {props.testSettings.map((item) => (
                            <TypeSlider
                                key={item.name}
                                changeHandler={changeHandler}
                                disabled={props.isDisabledSettings}
                                label={item.label}
                                railStyle={customRailStyleTheme}
                                slider={{
                                    value: round(props.step[item.name] || 0, 100),
                                    min: item.min,
                                    max: item.max,
                                    step: item.step
                                }}
                                units={item.units}
                                name={item.name}
                                handleStyle={getStylePropBySetting("handleStyle", item.name)}
                                trackStyle={getStylePropBySetting("trackStyle", item.name)}
                                tooltipText={item.tooltipText}
                            />
                        ))}
                    </TypeSliders>

                    {props.children}
                </Content>
            </Spoiler>
        </LoadProfileStep>
    );
};
