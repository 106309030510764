import { Select } from "@boomq/uikit";
import styled from "styled-components";
import { ExtractorMatchType } from "../../../../helpers/types";
const getMatchTypeWidthByValue = ({ defaultValue }) => defaultValue === ExtractorMatchType.Specific ? "180px" : "250px";
export const MatchValueSection = styled.div `
    display: flex;
    flex: 1;
    min-width: 250px;
    max-width: 250px;
    width: 250px;
`;
export const MatchTypeSelect = styled(Select) `
    margin-right: 10px;
    min-width: ${getMatchTypeWidthByValue};
    width: ${getMatchTypeWidthByValue};
`;
