import { call, put, takeEvery } from "redux-saga/effects";
import { push } from "connected-react-router";
import { getResponseErrorData } from "../../helpers/errors";
import { loginFailed } from "../actions/login";
import { resetRequest, resetSuccess, resetFailed } from "../actions/reset";
import { getAuthorizeRoute, getChangePasswordRoute } from "../../helpers/routes";
import { resetClientPasswordByEmail, resetClientPasswordByToken } from "../../services/authSrv";
export function* authorizationForResetPasswordFlow(hash) {
    try {
        const token = (hash || "").replace("#", "");
        yield call(resetClientPasswordByToken, token);
        yield put(push(getChangePasswordRoute()));
    }
    catch (e) {
        const { exceptionMessage } = getResponseErrorData(e);
        yield put(push(getAuthorizeRoute()));
        yield put(loginFailed({ code: exceptionMessage }));
    }
}
function* resetPasswordFlow(action) {
    var _a;
    try {
        const login = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.login;
        yield call(resetClientPasswordByEmail, login);
        yield put(resetSuccess());
    }
    catch (e) {
        yield put(resetFailed(getResponseErrorData(e)));
    }
}
export function* resetFlow() {
    yield takeEvery(resetRequest, resetPasswordFlow);
}
