import styled from "styled-components";
import { Color, IconHelp, withShadow } from "@boomq/uikit";
import { ToolbarLink } from "../../website/ToolbarLink";
import { NavBar } from "../NavBar";
export const LogoImage = styled.img `
    height: 100%;
    transition: all 0.1s ease-in-out;
    width: auto;
`;
export const LogoLink = styled(ToolbarLink) `
    height: 40px;
    margin-right: 14px;
    text-align: center;
    &:active ${LogoImage} {
        transform: scale(0.9);
    }
`;
export const StyledHeader = styled.header `
    ${withShadow}
    align-items: center;
    background: ${Color.white};
    display: flex;
    height: 64px;
    justify-content: space-between;
    padding: 0 28px;
`;
export const StyledIconHelp = styled(IconHelp) `
    fill: ${Color.grey65};
    height: 24px;
    width: 24px;
`;
export const HelpLink = styled.a `
    align-items: center;
    cursor: pointer;
    display: flex;
    margin-left: 12px;
    padding: 4px;
    &:hover {
        ${StyledIconHelp} {
            fill: ${Color.blue};
        }
    }
`;
export const StyledNavBar = styled(NavBar) `
    flex: auto;
`;
export const UserButtonsBlock = styled.div `
    align-items: center;
    display: flex;
`;
