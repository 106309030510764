import styled from "styled-components";
import { Color } from "@boomq/uikit";
export const BlockContent = styled.div `
    margin: 24px;
`;
export const BlockTitle = styled.div `
    align-items: center;
    border-bottom: 1px solid ${Color.lightGrey95};
    color: ${Color.darkGrey5};
    display: flex;
    padding: 15px 24px;
`;
