import { instance } from "./authSrv";
const location = window.location;
const servicePort = location.port ? `:${location.port}` : "";
const serviceName = "report-srv";
export const getReportSrvUrl = () => `${location.protocol}//${location.hostname}${servicePort}/${serviceName}`;
export const apiCreateReport = (data) => instance.post(`${getReportSrvUrl()}/report`, data);
export const apiGenerateReportChartPreview = (params) => instance.post(`${getReportSrvUrl()}/report/previewChart`, params);
export const apiGenerateReportTablePreview = (params) => instance.post(`${getReportSrvUrl()}/report/previewTable`, params);
export const apiGetReport = (id) => instance.get(`${getReportSrvUrl()}/report/${id}`);
export const apiGetReportContent = (id) => instance.get(`${getReportSrvUrl()}/report/${id}/content`);
export const apiGetReports = (reportParams) => instance.post(`${getReportSrvUrl()}/report/search`, reportParams);
export const apiUpdateReport = (id, data) => instance.put(`${getReportSrvUrl()}/report/${id}`, data);
export const apiGetReportsLabelsByQuery = (query) => instance.get(`${getReportSrvUrl()}/report/label?query=${query}`);
