var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { connect } from "react-redux";
import { isEmpty, useIntl } from "@boomq/utils";
import { UPDATED_URL_LABEL, URL_TO_REWRITE_LABEL } from "./intl";
import { UrlOverwrite } from "../../../../components/account/TestProjectSettingsSet/UrlOverwrite";
import { addUrlOverwriteItem, changeUrlOverwriteItemField, deleteUrlOverwriteItem } from "../../../../redux/actions/urlOverwrite";
import { getUrlOverwriteItems } from "../../../../redux/reducers/urlOverwrite";
const connector = connect((state) => ({
    items: getUrlOverwriteItems(state)
}), { onAdd: addUrlOverwriteItem, onChange: changeUrlOverwriteItemField, onDelete: deleteUrlOverwriteItem });
const formatErrorMessage = (errorObj = {}, formatMessage) => isEmpty(errorObj)
    ? {}
    : {
        error: Object.keys(errorObj).reduce((res, fieldError) => errorObj[fieldError] ? Object.assign(Object.assign({}, res), { [fieldError]: formatMessage(errorObj[fieldError]) }) : res, {})
    };
const WrappedUrlOverwrite = (_a) => {
    var { items } = _a, rest = __rest(_a, ["items"]);
    const { formatMessage } = useIntl();
    const updatedUrlLabel = formatMessage(UPDATED_URL_LABEL);
    const urlToRewriteLabel = formatMessage(URL_TO_REWRITE_LABEL);
    const validatedUrlList = items.map((urlItem) => (Object.assign(Object.assign({}, urlItem), formatErrorMessage(urlItem.error, formatMessage))));
    return (React.createElement(UrlOverwrite, Object.assign({}, rest, { items: validatedUrlList, urlToRewriteLabel: urlToRewriteLabel, updatedUrlLabel: updatedUrlLabel })));
};
export default connector(WrappedUrlOverwrite);
