import { TIMER_CONSTANT_DURATION_PLACEHOLDER_MESSAGE, TIMER_CONSTANT_TEXT_MESSAGE, TIMER_RANDOM_GAUSSIAN_PLACEHOLDER_MESSAGE, TIMER_RANDOM_GAUSSIAN_TEXT_MESSAGE, TIMER_RANDOM_LOWER_LIMIT_PLACEHOLDER_MESSAGE, TIMER_RANDOM_POISSON_PLACEHOLDER_MESSAGE, TIMER_RANDOM_POISSON_TEXT_MESSAGE, TIMER_RANDOM_TEXT_MESSAGE, TIMER_RANDOM_UNIFORM_PLACEHOLDER_MESSAGE, TIMER_RANDOM_UNIFORM_TEXT_MESSAGE, TIMER_RANDOM_UPPER_LIMIT_PLACEHOLDER_MESSAGE } from "./intl";
import { RequestRandomTimerType, RequestTimerParams, RequestTimerType } from "../../helpers/types";
export var TimerDtoParams;
(function (TimerDtoParams) {
    TimerDtoParams["ConstantValueParam"] = "durationMs";
    TimerDtoParams["RandomValueParamMin"] = "lowerLimitMs";
    TimerDtoParams["RandomValueParamMax"] = "upperLimitMs";
})(TimerDtoParams || (TimerDtoParams = {}));
export const timerTypeItems = [
    {
        id: 0,
        placeholder: {
            duration: TIMER_CONSTANT_DURATION_PLACEHOLDER_MESSAGE
        },
        text: TIMER_CONSTANT_TEXT_MESSAGE,
        value: RequestTimerType.Constant
    },
    {
        id: 1,
        placeholder: {
            lowerLimit: TIMER_RANDOM_LOWER_LIMIT_PLACEHOLDER_MESSAGE,
            upperLimit: TIMER_RANDOM_UPPER_LIMIT_PLACEHOLDER_MESSAGE
        },
        text: TIMER_RANDOM_TEXT_MESSAGE,
        value: RequestTimerType.Random
    }
];
export const timerRandomTypeItems = [
    {
        id: 0,
        placeholder: TIMER_RANDOM_GAUSSIAN_PLACEHOLDER_MESSAGE,
        text: TIMER_RANDOM_GAUSSIAN_TEXT_MESSAGE,
        value: RequestRandomTimerType.Gaussian
    },
    {
        id: 1,
        placeholder: TIMER_RANDOM_POISSON_PLACEHOLDER_MESSAGE,
        text: TIMER_RANDOM_POISSON_TEXT_MESSAGE,
        value: RequestRandomTimerType.Poisson
    },
    {
        id: 2,
        placeholder: TIMER_RANDOM_UNIFORM_PLACEHOLDER_MESSAGE,
        text: TIMER_RANDOM_UNIFORM_TEXT_MESSAGE,
        value: RequestRandomTimerType.Uniform
    }
];
export const timerValueParamsByType = {
    [RequestTimerType.Constant]: [RequestTimerParams.ConstantValueParam],
    [RequestTimerType.Random]: [RequestTimerParams.RandomValueParamMin, RequestTimerParams.RandomValueParamMax]
};
export const timerTypeParamsByType = {
    [RequestTimerType.Constant]: [RequestTimerParams.Type],
    [RequestTimerType.Random]: [RequestTimerParams.Type, RequestTimerParams.RandomTimerType]
};
export const timerTypeItemsByTypeParam = {
    [RequestTimerParams.Type]: timerTypeItems,
    [RequestTimerParams.RandomTimerType]: timerRandomTypeItems
};
export const getTimerEnabled = (timer) => (timer ? timer[RequestTimerParams.Enabled] : false);
export const getTimerId = (timer) => (timer ? timer[RequestTimerParams.Id] : undefined);
export const getIsBoomqTimer = (timer) => (timer ? timer[RequestTimerParams.isBoomqTimer] : false);
export const getTimerType = (timer) => (timer ? timer[RequestTimerParams.Type] : "");
