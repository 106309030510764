import { put, select, takeLatest } from "redux-saga/effects";
import { push } from "connected-react-router";
import { getFromLocalStorage, removeFromLocalStorage, stringToObject } from "@boomq/utils";
import { AUTH_ERROR_CODES } from "./error";
import { initTestProjectParamsFlow } from "./newTest";
import { authorizationForResetPasswordFlow } from "./reset";
import { languages } from "../../helpers/applicationParams";
import { getAuthCookie } from "../../helpers/auth";
import { getNewTestRoute } from "../../helpers/routes";
import authorizeActions from "../actions/authorize";
import { setLanguage } from "../actions/language";
import { loginFailed } from "../actions/login";
import { closeSiteModal, getSiteModal } from "../actions/siteModal";
import { getPreviousRouterLocation } from "../reducers/location";
import { authSrvName } from "../../services/authSrv";
const { authRequest, authFailure } = authorizeActions;
function* checkUserAuthorization(cause) {
    yield put(authRequest());
    yield put(authFailure({ code: cause }));
}
function* failedAuthorization(cause, activeUrl) {
    activeUrl === "/account" && (yield put(authFailure({ code: cause })));
    yield put(loginFailed({ code: cause }));
}
function* checkUser(cause, activeUrl) {
    const tokenFromCookie = getAuthCookie();
    return tokenFromCookie ? yield* checkUserAuthorization(cause) : yield* failedAuthorization(cause, activeUrl);
}
function* redirectToActiveUrl(activeUrl) {
    removeFromLocalStorage("activeUrl");
    yield put(push(activeUrl));
}
function* checkActiveUrl(activeUrl) {
    return activeUrl ? yield* redirectToActiveUrl(activeUrl) : yield put(push("/authorize"));
}
function* authError(search) {
    const { cause } = stringToObject(search.replace("?", ""));
    const activeUrl = getFromLocalStorage("activeUrl");
    yield* checkActiveUrl(activeUrl);
    return AUTH_ERROR_CODES.includes(cause) ? yield* checkUser(cause, activeUrl) : undefined;
}
function* checkLanguage(search) {
    const searchLangRes = search && RegExp(`[?, &]lang=(${languages.join("|")})(&.+){0,}$`, "i").exec(search);
    const lang = searchLangRes && searchLangRes.length && searchLangRes[1];
    return lang ? yield put(setLanguage(lang.toLowerCase())) : undefined;
}
function* checkErrorAuthorizationByPathName(pathname, search) {
    return pathname === "/error" && search.includes("cause=") ? yield* authError(search) : undefined;
}
function redirectToAuthInviteLink(pathname) {
    window.location.href = `/${authSrvName}${pathname}`;
}
function checkInviteLink(pathname) {
    return pathname.includes("/invite/") && !pathname.includes(`/${authSrvName}/invite/`)
        ? redirectToAuthInviteLink(pathname)
        : undefined;
}
function* checkNewTestPageByPathName(pathname) {
    const prevLocation = yield select(getPreviousRouterLocation);
    return pathname === getNewTestRoute() && prevLocation !== getNewTestRoute()
        ? yield* initTestProjectParamsFlow()
        : undefined;
}
function* checkResetPassword(pathname, hash) {
    return pathname === "/reset-password" && hash && hash.length > 0
        ? yield* authorizationForResetPasswordFlow(hash)
        : undefined;
}
function* checkSuccessLoginByPathName(pathname) {
    return pathname === "/successLogin" ? yield put(authRequest()) : undefined;
}
export function* locationFlow({ payload }) {
    const { hash, pathname, search } = (payload === null || payload === void 0 ? void 0 : payload.location) || {};
    window && typeof window.scrollTo === "function" && window.scrollTo(0, 0);
    checkInviteLink(pathname);
    yield* checkLanguage(search);
    yield* checkNewTestPageByPathName(pathname);
    yield* checkSuccessLoginByPathName(pathname);
    yield* checkErrorAuthorizationByPathName(pathname, search);
    yield* checkResetPassword(pathname, hash);
}
function modalFlow(action) {
    const childNode = document.getElementById("root").childNodes[0];
    let overflow, position, height;
    switch (action.type) {
        case getSiteModal.toString():
            overflow = "hidden";
            position = "fixed";
            height = "100%";
            break;
        case closeSiteModal.toString():
            overflow = "auto";
            position = "relative";
            height = "100%";
            break;
        default:
            overflow = "auto";
            position = "relative";
            height = "100%";
            break;
    }
    document.body.style.overflow = overflow;
    childNode && (childNode.style.overflow = overflow);
    childNode && (childNode.style.position = position);
    childNode && (childNode.style.height = height);
    childNode && (childNode.style.width = "100%");
}
export function* listener() {
    yield takeLatest("@@router/LOCATION_CHANGE", locationFlow);
}
