import { select, put, takeEvery, call } from "redux-saga/effects";
import { getValueByPath, getUniqueId } from "@boomq/utils";
import { prepareTestProjectParams } from "./newTest";
import { generateChartDataWithTimeLine, getLoadProfileTimeLine } from "./testProjectJmxChart";
import { calculateJMXTestLoadProfileParams } from "../../helpers/loadProfile";
import { RequestTimerType, TestMode, TestType } from "../../helpers/types";
import { getTimerType } from "../../models/requestTimer";
import { initSettings } from "../../models/testProject";
import newTestActions from "../actions/newTest";
import { getRequestTimers, getLoadProfileByGroupId, getLoadProfiles, getTestProjectSettingsField, getTestType } from "../reducers/newTest";
import { getTestDuration } from "../../services/testPlanSrv";
const { addStepInGroup, changeJmxLoadProfile, copyStepInGroup, deleteStepInGroup, testProjectChangeParamsData, testProjectChangeParamsField, toggleActiveGroups, toggleEnabledGroup } = newTestActions;
function* addStepInGroupFlow(action) {
    const { groupId } = action && action.payload ? action.payload : {};
    const loadProfile = yield select(getLoadProfileByGroupId, groupId);
    const stepsMaxIndex = loadProfile.steps.length > 0 ? loadProfile.steps[loadProfile.steps.length - 1].index : 0;
    const initialDelay = loadProfile.steps && loadProfile.steps.length > 0
        ? loadProfile.steps.reduce((sum, step) => sum + (step.rampUp || 0) + (step.rampDown || 0) + (step.stepLength || 0), 0)
        : 0;
    const newLoadProfile = groupId
        ? Object.assign(Object.assign({}, loadProfile), { steps: [
                ...loadProfile.steps,
                Object.assign(Object.assign({ id: getUniqueId() }, initSettings), { index: stepsMaxIndex + 1, initialDelay, name: `step ${stepsMaxIndex + 1}` })
            ] }) : loadProfile;
    yield* changeLoadProfileData({ groupId, loadProfile: newLoadProfile });
}
function* changeJMXTimersSettings(data) {
    const { isTimersDisabled, prevIsTimersDisabled, multiplicationFactor } = data || {};
    const timers = yield select(getTestProjectSettingsField, "timers");
    const timersWithDuration = Object.keys(timers || {}).filter((timerId) => [RequestTimerType.Constant, RequestTimerType.Random].includes(getTimerType(timers[timerId])) > -1);
    yield put(testProjectChangeParamsField({
        field: "JMXTimersSettings",
        value: { isTimersDisabled, multiplicationFactor }
    }));
    return prevIsTimersDisabled !== isTimersDisabled ? timers.length : timersWithDuration.length;
}
export function* JMXTimersSettingsFlow(action) {
    const { isTimersDisabled, prevIsTimersDisabled, multiplicationFactor, prevMultiplicationFactor } = action && action.payload ? action.payload : {};
    const timers = yield select(getRequestTimers);
    isTimersDisabled !== prevIsTimersDisabled &&
        (yield put(testProjectChangeParamsField({
            field: "timers",
            value: Object.keys(timers || {}).reduce((res, timerId) => (Object.assign(Object.assign({}, res), { [timerId]: Object.assign(Object.assign({}, timers[timerId]), { enabled: !isTimersDisabled }) })), {})
        })));
    return multiplicationFactor !== prevMultiplicationFactor || isTimersDisabled !== prevIsTimersDisabled
        ? yield* changeJMXTimersSettings(action.payload)
        : 0;
}
function* changeLoadProfileWithTimeLineChanging(params) {
    const { groupId, loadProfile } = params || {};
    const timeLine = getLoadProfileTimeLine(loadProfile);
    return yield* changeLoadProfileData({
        groupId: groupId,
        loadProfile: Object.assign(Object.assign({}, params.loadProfile), { timeLine: Object.assign(Object.assign({}, timeLine), { value: [getValueByPath(timeLine, "value.0", 0), timeLine.max] }) })
    });
}
function* getCalculatedJMXTestLoadProfileParams() {
    const loadProfileData = yield select(getLoadProfiles);
    const { JMXTestDurationSec, JMXTestVUserMins, JMXTestVUsers } = calculateJMXTestLoadProfileParams(loadProfileData);
    try {
        const testProjectParams = yield* prepareTestProjectParams();
        const isDebugMode = yield select(getTestProjectSettingsField, "isDebugMode");
        const testMode = isDebugMode ? TestMode.Debug : TestMode.Test;
        const { data } = yield call(getTestDuration, testMode, testProjectParams);
        return {
            JMXTestVUsers,
            JMXTestVUserMins: data ? data.vuserMinutes : JMXTestVUserMins,
            JMXTestDurationSec: data ? data.totalDuration : JMXTestDurationSec
        };
    }
    catch (e) {
        return { JMXTestDurationSec, JMXTestVUsers };
    }
}
export function* reCalculateLoadProfileParams() {
    try {
        const jmxTestCalculatedParams = yield* getCalculatedJMXTestLoadProfileParams();
        yield put(testProjectChangeParamsData(jmxTestCalculatedParams));
        return jmxTestCalculatedParams;
    }
    catch (e) {
        console.error(e);
    }
}
function* reCalculateLoadProfileParamsByTestType() {
    try {
        const testType = yield select(getTestType);
        return testType === TestType.JMX ? yield* reCalculateLoadProfileParams() : undefined;
    }
    catch (e) {
        console.error(e);
    }
}
const modifyExistedChartData = (loadProfile) => loadProfile.chartData ? Object.assign(Object.assign({}, loadProfile), { chartData: generateChartDataWithTimeLine(loadProfile) }) : loadProfile;
function* changeLoadProfileData(params) {
    const { groupId, loadProfile } = params || {};
    const modifiedLoadProfile = modifyExistedChartData(loadProfile);
    yield put(changeJmxLoadProfile({ groupId, loadProfile: modifiedLoadProfile }));
    return yield* reCalculateLoadProfileParams();
}
export function* changeJmxLoadProfileSettingFlow(action) {
    const { groupId, stepId, settingName, value } = action && action.payload ? action.payload : {};
    const loadProfile = yield select(getLoadProfileByGroupId, groupId);
    const newLoadProfile = groupId && stepId && settingName
        ? Object.assign(Object.assign({}, loadProfile), { steps: loadProfile.steps.map((step) => step.id === stepId ? Object.assign(Object.assign({}, step), { [settingName]: value }) : step) }) : loadProfile;
    return yield* changeLoadProfileData({
        groupId,
        loadProfile: Object.assign(Object.assign({}, newLoadProfile), { timeLine: getLoadProfileTimeLine(newLoadProfile) })
    });
}
function* copyStepInGroupFlow(action) {
    const { groupId, stepId } = action && action.payload ? action.payload : {};
    const loadProfile = yield select(getLoadProfileByGroupId, groupId);
    const copiedStep = loadProfile && Array.isArray(loadProfile.steps) ? loadProfile.steps.find((step) => step.id === stepId) : null;
    const stepsMaxIndex = loadProfile && Array.isArray(loadProfile.steps) && loadProfile.steps.length > 0
        ? loadProfile.steps[loadProfile.steps.length - 1].index
        : 0;
    const addStep = copiedStep ? Object.assign({}, copiedStep) : Object.assign({}, initSettings);
    const newLoadProfile = groupId
        ? Object.assign(Object.assign({}, loadProfile), { steps: [
                ...loadProfile.steps,
                Object.assign(Object.assign({}, addStep), { changed: false, id: getUniqueId(), index: stepsMaxIndex + 1, name: `step ${stepsMaxIndex + 1}` })
            ] }) : loadProfile;
    yield* changeLoadProfileWithTimeLineChanging({ groupId, loadProfile: newLoadProfile });
}
function* deleteStepInGroupFlow(action) {
    const { groupId, stepId } = action && action.payload ? action.payload : {};
    const loadProfile = yield select(getLoadProfileByGroupId, groupId);
    const newLoadProfile = groupId && stepId
        ? Object.assign(Object.assign({}, loadProfile), { steps: loadProfile.steps.filter((step) => step.id !== stepId) }) : loadProfile;
    yield* changeLoadProfileWithTimeLineChanging({ groupId, loadProfile: newLoadProfile });
}
export function* testProjectJmxFlow() {
    yield takeEvery(addStepInGroup, addStepInGroupFlow);
    yield takeEvery(copyStepInGroup, copyStepInGroupFlow);
    yield takeEvery(deleteStepInGroup, deleteStepInGroupFlow);
    yield takeEvery(toggleActiveGroups, reCalculateLoadProfileParamsByTestType);
    yield takeEvery(toggleEnabledGroup, reCalculateLoadProfileParamsByTestType);
}
