export var FormType;
(function (FormType) {
    FormType["Add"] = "ADD";
    FormType["Edit"] = "EDIT";
    FormType["View"] = "VIEW";
    FormType["EditWithoutAdding"] = "EDIT_WITHOUT_ADDING";
})(FormType || (FormType = {}));
export var ImportFileType;
(function (ImportFileType) {
    ImportFileType["har"] = "HAR";
    ImportFileType["insomnia"] = "INSOMNIA_JSON";
    ImportFileType["postman"] = "POSTMAN_JSON";
})(ImportFileType || (ImportFileType = {}));
export var SelectionType;
(function (SelectionType) {
    SelectionType["Combine"] = "COMBINE";
    SelectionType["Multiple"] = "MULTIPLE";
    SelectionType["Single"] = "SINGLE";
})(SelectionType || (SelectionType = {}));
export var TypeSliderButtonType;
(function (TypeSliderButtonType) {
    TypeSliderButtonType["Decrement"] = "DECREMENT";
    TypeSliderButtonType["Increment"] = "INCREMENT";
})(TypeSliderButtonType || (TypeSliderButtonType = {}));
export var TypeSliderInputType;
(function (TypeSliderInputType) {
    TypeSliderInputType["Input"] = "INPUT";
    TypeSliderInputType["Slider"] = "SLIDER";
})(TypeSliderInputType || (TypeSliderInputType = {}));
