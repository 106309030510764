export const dataLossConfirmForm = ({ confirmAction, element }) => ({
    component: {
        element,
        props: {
            confirmButton: { action: confirmAction, theme: { width: "40%!important" } },
            cancelButton: { theme: { width: "40%!important" } },
            text: {
                description: [
                    {
                        defaultMessage: "You have unsaved changes in the thread group. If you go to test now, all the unsaved changes will be discarded. Do you want to continue?",
                        id: "account.request.group.editor.modal.unsaved.thread.group.attention.text"
                    }
                ]
            }
        },
        width: "800px"
    },
    title: {
        id: "account.request.group.editor.modal.unsaved.thread.group.attention.title",
        defaultMessage: "Attention"
    }
});
