import { combineActions, handleActions } from "redux-actions";
import { isObject } from "@boomq/utils";
import authorizeActions from "../actions/authorize";
import { setEstimatedStartTime, setTestDetailsData, testDetailsRequestStop, testDetailsSuccess, updateTestResultSlaSuccess } from "../actions/testDetails";
import { TestResultSlaState } from "../../models/testRun";
const { logout } = authorizeActions;
export const initialState = {};
export default handleActions({
    [combineActions(logout, testDetailsRequestStop)]: () => (Object.assign({}, initialState)),
    [setEstimatedStartTime]: (state, action) => (Object.assign(Object.assign({}, state), { estimatedStartTime: action.payload })),
    [combineActions(setTestDetailsData, testDetailsSuccess)]: (state, action) => (Object.assign(Object.assign({}, state), (isObject(action.payload) ? action.payload : {}))),
    [updateTestResultSlaSuccess]: (state, { payload }) => {
        const { sla, testResultSlaState } = payload || {
            sla: {},
            testResultSlaState: TestResultSlaState.Undefined
        };
        return Object.assign(Object.assign({}, state), { sla: Object.assign({}, sla), testResult: Object.assign(Object.assign({}, state.testResult), { testResultSlaState }) });
    }
}, initialState);
const getStateParam = (state, paramName) => state && state.testDetails ? state.testDetails[paramName] : initialState[paramName];
export const getTestDetailsData = (state) => state.testDetails;
export const getTestDetailsSlaData = (state) => getStateParam(state, "sla");
export const getTestDetailsSlaState = (state) => {
    const testResult = getStateParam(state, "testResult");
    return testResult ? testResult.testResultSlaState : undefined;
};
export const getTestEstimatedStartTime = (state) => getStateParam(state, "estimatedStartTime");
