import React, { useEffect } from "react";
import { isEmpty } from "@boomq/utils";
import { DEFAULT_ACTIVE_INDEXES } from "./constants";
const getDefaultActiveIndexes = (defaultActiveIndex) => isEmpty(defaultActiveIndex) ? DEFAULT_ACTIVE_INDEXES : [defaultActiveIndex];
const getActiveIndexesFromProps = (activeIndexes, defaultActiveIndex) => !Array.isArray(activeIndexes) || activeIndexes.length === 0
    ? getDefaultActiveIndexes(defaultActiveIndex)
    : activeIndexes;
export const Accordion = ({ activeIndexes, children, defaultActiveIndex, onToggleTab }) => {
    const [activeTabIndexes, setActiveTabIndexes] = React.useState(getActiveIndexesFromProps(activeIndexes, defaultActiveIndex));
    useEffect(() => {
        setActiveTabIndexes(getActiveIndexesFromProps(activeIndexes, defaultActiveIndex));
    }, [activeIndexes, defaultActiveIndex]);
    const isSelected = ({ id }) => activeTabIndexes.includes(id);
    const clickTabHandler = (tabIndex) => setActiveTabIndexes([tabIndex]);
    return React.Children.map(children, (child, index) => React.cloneElement(child, Object.assign(Object.assign({}, child.props), { key: index, indexParamName: "index", isOpened: isSelected(child.props), onClick: clickTabHandler })));
};
