import { copyTextToClipboard } from "@boomq/utils";
import { IconCopy } from "./IconCopy";
import { IconHash } from "./IconHash";
import { IconHelp } from "./IconHelp";
import { IconStrikeThroughHash } from "./IconStrikeThroughHash";
import { ACTIONS_BUTTON_TITLE, ACTIONS_INPUT_PLACEHOLDER, ACTIONS_LINK_TOOLTIP_TEXT } from "./intl";
import "./pflbAnchor.css";
export class PFLBAnchor {
    constructor({ api }) {
        this.ENTER_KEY = 13;
        this.CSS = {
            anchor: "pflb-anchor",
            anchorButton: "pflb-anchor-button",
            anchorSelected: "pflb-anchor--selected",
            button: "ce-inline-tool",
            buttonActive: "ce-inline-tool--active",
            buttonCopy: "button-copy",
            buttonCopyShowed: "button-copy--showed",
            buttonHash: "ce-inline-tool--anchor",
            buttonStrikeThroughHash: "ce-inline-tool--strike-through-anchor",
            buttonTooltip: "button-tooltip",
            div: "pflb-anchor-inline-tool",
            inlineToolbarActions: "ce-inline-toolbar__actions",
            input: "ce-inline-tool-input",
            inputShowed: "ce-inline-tool-input--showed",
            linkTooltip: "pflb-anchor-link-tooltip"
        };
        this.tag = "SPAN";
        this.nodes = {
            button: null,
            buttonCopy: null,
            buttonTooltip: null,
            div: null,
            input: null,
            tooltip: null
        };
        this.inputOpened = false;
        this.api = api;
        this.i18n = api.i18n;
        this.inlineToolbar = api.inlineToolbar;
        this.inputOpened = false;
        this.notifier = api.notifier;
        this.range = null;
        this.wrapper = null;
    }
    render() {
        this.nodes.button = document.createElement("button");
        this.nodes.button.type = "button";
        this.nodes.button.classList.add(this.CSS.button, this.CSS.buttonStrikeThroughHash);
        this.nodes.button.innerHTML = IconHash;
        return this.nodes.button;
    }
    renderActions() {
        this.nodes.input = document.createElement("input");
        this.nodes.input.placeholder = this.i18n.t(ACTIONS_INPUT_PLACEHOLDER);
        this.nodes.input.value = "#";
        this.nodes.input.classList.add(this.CSS.input);
        this.nodes.input.addEventListener("input", (event) => this.changeInputValue(event));
        this.nodes.input.addEventListener("keydown", (event) => this.keyDownListener(event));
        this.nodes.input.addEventListener("blur", (event) => this.blur(event));
        this.nodes.buttonCopy = document.createElement("button");
        this.nodes.buttonCopy.innerHTML = IconCopy;
        this.nodes.buttonCopy.title = this.i18n.t(ACTIONS_BUTTON_TITLE);
        this.nodes.buttonCopy.classList.add(this.CSS.anchorButton, this.CSS.buttonCopy);
        this.nodes.buttonCopy.addEventListener("mousedown", (event) => this.handleClickButtonCopy(event), true);
        this.nodes.tooltip = document.createElement("div");
        this.nodes.tooltip.innerText = this.i18n.t(ACTIONS_LINK_TOOLTIP_TEXT);
        this.nodes.tooltip.classList.add(this.CSS.linkTooltip);
        this.nodes.buttonTooltip = document.createElement("button");
        this.nodes.buttonTooltip.innerHTML = IconHelp;
        this.nodes.buttonTooltip.classList.add(this.CSS.anchorButton, this.CSS.buttonTooltip);
        this.nodes.buttonTooltip.appendChild(this.nodes.tooltip);
        this.nodes.div = document.createElement("div");
        this.nodes.div.classList.add(this.CSS.div);
        this.nodes.div.appendChild(this.nodes.buttonTooltip);
        this.nodes.div.appendChild(this.nodes.input);
        this.nodes.div.appendChild(this.nodes.buttonCopy);
        return this.nodes.div;
    }
    changeInputValue(event) {
        const input = event === null || event === void 0 ? void 0 : event.target;
        this.stopEvent(event);
        return this.nodes.input ? this.setInputValue(this.nodes.input, input === null || input === void 0 ? void 0 : input.value) : undefined;
    }
    stopEvent(event) {
        event === null || event === void 0 ? void 0 : event.preventDefault();
        event === null || event === void 0 ? void 0 : event.stopPropagation();
        event === null || event === void 0 ? void 0 : event.stopImmediatePropagation();
    }
    setInputValue(inputNode, value) {
        const checkedValue = value || "";
        inputNode.value = checkedValue[0] === "#" ? checkedValue : `#${checkedValue}`;
    }
    keyDownListener(event) {
        return event.keyCode === this.ENTER_KEY ? this.pressedEnter(event) : undefined;
    }
    pressedEnter(event) {
        this.stopEvent(event);
        return this.blur(event);
    }
    handleClickButtonCopy(event) {
        var _a;
        const value = ((_a = this.nodes.input) === null || _a === void 0 ? void 0 : _a.value) || "";
        this.stopEvent(event);
        copyTextToClipboard(value);
        return this.blur(event);
    }
    surround(range) {
        return range ? this.handleClickButton(range) : undefined;
    }
    handleClickButton(range) {
        this.toggleActions();
        return this.wrapper ? this.unwrap(this.wrapper) : this.setPseudoSelectionBackground(range);
    }
    checkState() {
        const anchorTag = this.api.selection.findParentTag(this.tag, this.CSS.anchor);
        const selection = window.getSelection();
        this.checkSelection(selection);
        this.setWrapper(anchorTag);
        this.setNodesDataByTagExisting(anchorTag);
        return !!anchorTag;
    }
    checkSelection(selection) {
        var _a, _b;
        try {
            const range = (_a = selection === null || selection === void 0 ? void 0 : selection.getRangeAt) === null || _a === void 0 ? void 0 : _a.call(selection, 0);
            const classList = this.getParentElementClassListFromSelection(selection);
            return ((_b = classList.includes) === null || _b === void 0 ? void 0 : _b.call(classList, this.CSS.inlineToolbarActions)) ? undefined : this.setRange(range);
        }
        catch (error) {
            return;
        }
    }
    getParentElementClassListFromSelection(selection) {
        var _a, _b;
        try {
            return Array.from(((_b = (_a = selection === null || selection === void 0 ? void 0 : selection.anchorNode) === null || _a === void 0 ? void 0 : _a.parentElement) === null || _b === void 0 ? void 0 : _b.classList) || []);
        }
        catch (error) {
            return [];
        }
    }
    setRange(range) {
        this.range = range;
    }
    setWrapper(wrapper) {
        this.wrapper = wrapper;
    }
    setNodesDataByTagExisting(anchorTag) {
        return anchorTag ? this.setStrikeThroughHashNodeData(anchorTag) : this.setHashNodeData();
    }
    setStrikeThroughHashNodeData(anchorTag) {
        var _a, _b;
        this.nodes.button && (this.nodes.button.innerHTML = IconStrikeThroughHash);
        (_a = this.nodes.button) === null || _a === void 0 ? void 0 : _a.classList.add(this.CSS.buttonStrikeThroughHash);
        (_b = this.nodes.button) === null || _b === void 0 ? void 0 : _b.classList.add(this.CSS.buttonActive);
        this.openActions();
        const dataIdAttr = anchorTag.getAttribute("data-id");
        return this.nodes.input ? this.setInputValue(this.nodes.input, dataIdAttr) : undefined;
    }
    setHashNodeData() {
        var _a, _b;
        this.nodes.button && (this.nodes.button.innerHTML = IconHash);
        (_a = this.nodes.button) === null || _a === void 0 ? void 0 : _a.classList.remove(this.CSS.buttonStrikeThroughHash);
        (_b = this.nodes.button) === null || _b === void 0 ? void 0 : _b.classList.remove(this.CSS.buttonActive);
    }
    updateWrapper(wrapper, value) {
        wrapper.setAttribute("data-id", this.clearValue(value));
        wrapper.classList.remove(this.CSS.anchorSelected);
        this.setRange(null);
        this.setWrapper(null);
    }
    clearValue(value) {
        return (value || "").replace("#", "");
    }
    wrap(value) {
        var _a;
        const span = document.createElement(this.tag);
        const rangeContent = (_a = this.range) === null || _a === void 0 ? void 0 : _a.extractContents();
        span.classList.add(this.CSS.anchor);
        span.setAttribute("data-id", this.clearValue(value));
        this.appendChild(span, rangeContent);
        this.insertNodeToRange(this.range, span);
        this.setRange(null);
        this.setWrapper(null);
    }
    appendChild(element, child) {
        return element && child ? element.appendChild(child) : undefined;
    }
    unwrap(wrapper) {
        var _a, _b, _c, _d, _e;
        const selection = window.getSelection();
        (_b = (_a = this.range) === null || _a === void 0 ? void 0 : _a.selectNodeContents) === null || _b === void 0 ? void 0 : _b.call(_a, wrapper);
        const unwrappedContent = (_c = this.range) === null || _c === void 0 ? void 0 : _c.extractContents();
        (_e = (_d = wrapper === null || wrapper === void 0 ? void 0 : wrapper.parentNode) === null || _d === void 0 ? void 0 : _d.removeChild) === null || _e === void 0 ? void 0 : _e.call(_d, wrapper);
        this.insertNodeToRange(this.range, unwrappedContent);
        selection === null || selection === void 0 ? void 0 : selection.removeAllRanges();
        this.addRangeToSelection(selection, this.range);
        this.setWrapper(null);
    }
    insertNodeToRange(range, content) {
        return range && content ? range === null || range === void 0 ? void 0 : range.insertNode(content) : undefined;
    }
    addRangeToSelection(selection, range) {
        return range ? selection === null || selection === void 0 ? void 0 : selection.addRange(range) : undefined;
    }
    clear() {
        setTimeout(() => {
            var _a, _b, _c, _d;
            (_a = this.nodes.input) === null || _a === void 0 ? void 0 : _a.removeEventListener("blur", (event) => this.blur(event));
            (_b = this.nodes.input) === null || _b === void 0 ? void 0 : _b.addEventListener("input", (event) => this.changeInputValue(event));
            (_c = this.nodes.input) === null || _c === void 0 ? void 0 : _c.removeEventListener("keydown", (event) => this.keyDownListener(event));
            (_d = this.nodes.buttonCopy) === null || _d === void 0 ? void 0 : _d.removeEventListener("mousedown", (event) => this.handleClickButtonCopy(event), true);
            this.setRange(null);
            this.setWrapper(null);
            this.closeActions();
        });
    }
    static get shortcut() {
        return "CMD+SHIFT+K";
    }
    toggleActions() {
        return !this.inputOpened ? this.openActions(true) : this.closeActions();
    }
    openActions(needFocus = false) {
        var _a, _b, _c;
        (_a = this.nodes.input) === null || _a === void 0 ? void 0 : _a.classList.add(this.CSS.inputShowed);
        (_b = this.nodes.buttonCopy) === null || _b === void 0 ? void 0 : _b.classList.add(this.CSS.buttonCopyShowed);
        this.inputOpened = true;
        return needFocus ? (_c = this.nodes.input) === null || _c === void 0 ? void 0 : _c.focus() : undefined;
    }
    setPseudoSelectionBackground(range) {
        const span = document.createElement(this.tag);
        const rangeContent = range === null || range === void 0 ? void 0 : range.extractContents();
        span.classList.add(this.CSS.anchor, this.CSS.anchorSelected);
        this.appendChild(span, rangeContent);
        this.insertNodeToRange(range, span);
        setTimeout(() => this.setWrapper(span));
    }
    closeActions() {
        var _a, _b;
        (_a = this.nodes.input) === null || _a === void 0 ? void 0 : _a.classList.remove(this.CSS.inputShowed);
        (_b = this.nodes.buttonCopy) === null || _b === void 0 ? void 0 : _b.classList.remove(this.CSS.buttonCopyShowed);
        this.inputOpened = false;
        return this.nodes.input ? this.setInputValue(this.nodes.input, "") : undefined;
    }
    blur(event) {
        var _a;
        let value = ((_a = this.nodes.input) === null || _a === void 0 ? void 0 : _a.value) || "";
        if (!value.trim() || value.trim() === "#") {
            this.unwrap(this.wrapper);
            event === null || event === void 0 ? void 0 : event.preventDefault();
            this.closeActions();
            return;
        }
        if (!this.validateAnchor(value)) {
            this.notifier.show({
                message: "Pasted anchor is not valid.",
                style: "error"
            });
            console.warn("Incorrect anchor pasted", value);
            return;
        }
        value = this.prepareAnchor(value);
        this.stopEvent(event);
        this.inlineToolbar.close();
        return this.wrapper ? this.updateWrapper(this.wrapper, value) : this.wrap(value);
    }
    validateAnchor(str) {
        var _a;
        try {
            (_a = document === null || document === void 0 ? void 0 : document.querySelector) === null || _a === void 0 ? void 0 : _a.call(document, `[data-id="${this.clearValue(str)}"]`);
            return true;
        }
        catch (error) {
            return false;
        }
    }
    prepareAnchor(anchor) {
        anchor = anchor.trim();
        return anchor;
    }
    static get sanitize() {
        return {
            span: {
                "data-id": true,
                class: "pflb-anchor"
            }
        };
    }
}
PFLBAnchor.isInline = true;
PFLBAnchor.title = "Anchor";
