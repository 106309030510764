import styled from "styled-components";
import { Color, IconCheckHalfCircle, IconFolder, IconList, IconPlus, IconTrending } from "@boomq/uikit";
export const StyledIconButton = styled(IconPlus) `
    fill: ${Color.white};
    width: 16px;
    height: 16px;
`;
export const StyledIconList = styled(IconList) `
    fill: ${Color.grey65};
`;
export const StyledIconCheckCircle = StyledIconList.withComponent(IconCheckHalfCircle);
export const StyledIconFolder = StyledIconList.withComponent(IconFolder);
export const StyledIconTrending = StyledIconList.withComponent(IconTrending);
