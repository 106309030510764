import styled from "styled-components";
import { Button, Color, FontBodyBigBold, FontBodyMediumMedium, FontBodyMediumRegular, FontBodySmall, H2Styles } from "@boomq/uikit";
import { media } from "../../styled/response";
export const StyledContainer = styled.div `
    margin: 0 auto;
    max-width: 1206px;
    padding: 120px 28px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
`;
export const StyledTitle = styled.h2 `
    ${H2Styles}
    font-size: 32px;
`;
export const StyledSubtitle = styled.p `
    ${FontBodyMediumMedium}
    max-width: 683px;
    padding: 28px 0 60px;
    line-height: 1.5;
`;
export const StyledCardsWrapper = styled.div `
    display: flex;
    gap: 40px;
    justify-content: space-between;
    ${media.desktop} {
        gap: 20px;
    }
    ${media.tablet} {
        flex-direction: column;
    }
`;
export const StyleCard = styled.div `
    width: 100%;
    min-height: 270px;
    border: 1px dashed ${Color.grey65};
    border-radius: 16px;
    padding: 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`;
export const CardTitle = styled.h3 `
    ${FontBodyBigBold}
`;
export const CardText = styled.p `
    ${FontBodyMediumRegular}
    line-height: 1.5;
`;
export const CardButton = styled(Button) `
    ${FontBodySmall}
    padding: 10px 20px;
    ${media.desktop} {
        ${FontBodySmall}
    }
    width: 100%;
    background-color: #5f8ffe;
    border-radius: 8px;
    :hover {
        background-color: #5f8ffe;
    }
`;
