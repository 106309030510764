import styled from "styled-components";
import { Color, FontBodyMediumMedium, Tab } from "@boomq/uikit";
import { withDisable } from "../../styled/global";
import { media } from "../../styled/response";
export const Description = styled.p `
    ${FontBodyMediumMedium}
    color: ${Color.darkGrey5};
    margin-left: 24px;
    ${media.tablet} {
        margin-left: 0;
        margin-top: 16px;
    }
`;
export const LoadProfileParams = styled.div `
    ${withDisable};
    display: flex;
    flex-direction: column;
`;
export const StyledBlockContent = styled.div `
    align-items: center;
    display: flex;
    margin-left: 24px;
    margin-right: 24px;
    margin-top: 24px;
`;
export const StyledTab = styled(Tab) `
    min-width: 208px;
`;
