import { getRandomColor, getUniqueId, getValueByPath, isEmpty, isEmptyArray } from "@boomq/utils";
import { parseLoadProfileDurationParam } from "./loadProfile";
import { getThreadGroupsCommonData, prepareThreadGroupData } from "./threadGroup";
import { generatePreviewChartDataForSteps } from "../generators";
export const parseStepLoadProfileParams = (params) => ({
    initialDelay: parseLoadProfileDurationParam(params, "initDelayMs"),
    rampUp: parseLoadProfileDurationParam(params, "startupTimeMs"),
    stepLength: parseLoadProfileDurationParam(params, "holdLoadTimeMs"),
    rampDown: parseLoadProfileDurationParam(params, "shutdownTimeMs"),
    usersPerStep: getValueByPath(params, "startThreadsCount", 0)
});
export const parseSteps = (steps) => steps && !isEmptyArray(steps)
    ? steps.map((step, index) => (Object.assign(Object.assign({}, parseStepLoadProfileParams(step)), { changed: step.changed ? true : false, id: getUniqueId(), index: index + 1, name: `step ${index + 1}`, stepName: step.name })))
    : [];
export const getStartTimeLine = (steps) => Math.min(...(steps || []).reduce((res, step) => [...res, step.initialDelay || 0], []));
const getTimeLinePoint = (step) => ["initialDelay", "rampUp", "rampDown", "stepLength"].reduce((res, paramName) => res + (step[paramName] || 0), 0);
export const getEndTimeLine = (steps) => Math.max(...(steps || []).reduce((res, step) => [...res, getTimeLinePoint(step)], []));
export const parseTestProjectLoadProfile = (steps, groupId) => {
    const color = getRandomColor({ E: "C", F: "C" });
    const startTimeLine = getStartTimeLine(steps);
    const endTimeLine = getEndTimeLine(steps);
    return steps
        ? {
            [groupId]: {
                chartData: [
                    {
                        coords: generatePreviewChartDataForSteps(steps, [startTimeLine, endTimeLine], true),
                        params: { color }
                    }
                ],
                isMergedChart: true,
                steps,
                timeLine: {
                    min: startTimeLine,
                    value: [startTimeLine, endTimeLine],
                    max: endTimeLine
                }
            }
        }
        : {};
};
export const getUltimateThreadGroupDataVer2 = ({ res, index, group }) => {
    var _a;
    const { enabled = true, id } = group || {};
    const groupId = isEmpty(id) ? getUniqueId() : String(id);
    const commonParams = getThreadGroupsCommonData({ res, index, group, groupId });
    const steps = parseSteps(group.steps);
    return Object.assign(Object.assign(Object.assign({}, res), commonParams), { groups: [
            ...res.groups,
            Object.assign(Object.assign({}, prepareThreadGroupData(groupId, index, group)), { displayTypeName: (_a = group.typeDisplayName) !== null && _a !== void 0 ? _a : "", enabled, type: group.type })
        ], JMXProfiles: Object.assign(Object.assign({}, res.JMXProfiles), parseTestProjectLoadProfile(steps, groupId)) });
};
