import { call, put, select, takeEvery } from "redux-saga/effects";
import { getValueByPath, isEmptyArray } from "@boomq/utils";
import { testProjectSettingsSetSavingFlow } from "./testProjectSettingsSet";
import { hideLoader, showLoader } from "../actions/loader";
import { monitoringSystemMetricSavingRequest, monitoringSystemMetricChangingFailure, monitoringSystemMetricChangingSuccess, monitoringSystemMetricFileDownloadingFailure, monitoringSystemMetricFileDownloadingRequest, monitoringSystemMetricFileDownloadingSuccess, monitoringSystemMetricPartsRefreshing, setMonitoringSystemMetricsData } from "../actions/monitoring";
import { getSystemMetricPartItems } from "../reducers/monitoring";
import { getSettingsSetId } from "../reducers/testProjectSettingsSet";
import { getResponseErrorData } from "../../helpers/errors";
import { getMonitoringSystemMetricParts, getMonitoringSystemMetricsConfDownloadLinks } from "../../services/projectSrv";
function* monitoringSystemMetricChangingFlow(action) {
    try {
        const { settingsSetId } = action && action.payload ? action.payload : {};
        const { data } = yield* testProjectSettingsSetSavingFlow({ payload: settingsSetId });
        const { parts = [], systemMonitoringTelegrafLocation = "" } = getValueByPath(data, "content.systemMetricsMonitoring", {});
        yield put(monitoringSystemMetricChangingSuccess({
            systemMetrics: parts,
            systemMonitoringTelegrafLocation
        }));
    }
    catch (e) {
        yield put(monitoringSystemMetricChangingFailure(getResponseErrorData(e)));
    }
}
function* downloadConfFile(fileLinkTypeName) {
    const settingsSetId = yield select(getSettingsSetId);
    const { data } = yield call(getMonitoringSystemMetricsConfDownloadLinks, settingsSetId);
    yield put(setMonitoringSystemMetricsData(data));
    return data && data[fileLinkTypeName] ? window.open(data[fileLinkTypeName]) : undefined;
}
function* monitoringSystemMetricFileDownloadingFlow(action) {
    try {
        const fileLinkTypeName = action ? action.payload : "";
        yield* downloadConfFile(fileLinkTypeName);
        yield put(monitoringSystemMetricFileDownloadingSuccess());
    }
    catch (e) {
        yield put(monitoringSystemMetricFileDownloadingFailure(getResponseErrorData(e)));
    }
}
function* refreshSystemMetricParts() {
    yield put(showLoader());
    const { data } = yield call(getMonitoringSystemMetricParts);
    yield put(setMonitoringSystemMetricsData({
        systemMetricPartItems: data
    }));
    yield put(hideLoader());
}
function* monitoringSystemMetricPartsFlow() {
    try {
        const systemMetricPartItems = yield select(getSystemMetricPartItems);
        return isEmptyArray(systemMetricPartItems) ? yield* refreshSystemMetricParts() : undefined;
    }
    catch (e) {
        console.error(e);
    }
}
export function* monitoringFlow() {
    yield takeEvery(monitoringSystemMetricSavingRequest, monitoringSystemMetricChangingFlow);
    yield takeEvery(monitoringSystemMetricFileDownloadingRequest, monitoringSystemMetricFileDownloadingFlow);
    yield takeEvery(monitoringSystemMetricPartsRefreshing, monitoringSystemMetricPartsFlow);
}
