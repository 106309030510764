var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { LIST_ITEM_DEFAULT_LINK_TYPE } from "./constants";
import { MenuListItemExternal } from "./ExternalLink";
import { MenuListItemNav } from "./NavLink";
import { MenuListItemNoLink } from "./NoLink";
import { MenuLinkType } from "../../../helpers/types";
const listItemTemplateByLinkType = {
    [MenuLinkType.External]: MenuListItemExternal,
    [MenuLinkType.Nav]: MenuListItemNav,
    [MenuLinkType.None]: MenuListItemNoLink
};
const getListItemTemplateKeyByLinkType = (linkType) => linkType && Object.keys(listItemTemplateByLinkType).includes(linkType) ? linkType : LIST_ITEM_DEFAULT_LINK_TYPE;
const getListItemTemplateByLinkType = (linkType) => listItemTemplateByLinkType[getListItemTemplateKeyByLinkType(linkType)];
export const MenuListItemSingle = (_a) => {
    var { linkType } = _a, restProps = __rest(_a, ["linkType"]);
    const ListItemTemplate = getListItemTemplateByLinkType(linkType);
    return ListItemTemplate ? React.createElement(ListItemTemplate, Object.assign({}, restProps)) : React.createElement(React.Fragment, null);
};
