import { combineActions, handleActions } from "redux-actions";
import newTestActions from "../actions/newTest";
import { showTestProjectNotification, hideTestProjectNotification } from "../actions/testProjectNotification";
const { selectProfile, setStep } = newTestActions;
export const initialState = {
    params: { afterHidingFunc: null, text: {} },
    isVisible: false
};
export default handleActions({
    [showTestProjectNotification]: (state, action) => (Object.assign(Object.assign({}, state), { isVisible: true, params: action.payload })),
    [combineActions(hideTestProjectNotification, selectProfile, setStep)]: () => (Object.assign({}, initialState))
}, initialState);
export const getTestProjectNotificationParams = (state) => state.testProjectNotification.params;
export const getTestProjectNotificationVisibility = (state) => state.testProjectNotification.isVisible;
