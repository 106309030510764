export var TestResultFilesState;
(function (TestResultFilesState) {
    TestResultFilesState["Processing"] = "PROCESSING";
    TestResultFilesState["Finished"] = "FINISHED";
    TestResultFilesState["Failed"] = "FAILED";
})(TestResultFilesState || (TestResultFilesState = {}));
export var TestMode;
(function (TestMode) {
    TestMode["Test"] = "TEST";
    TestMode["Debug"] = "DEBUG_TEST";
})(TestMode || (TestMode = {}));
export var TestType;
(function (TestType) {
    TestType["Scalability"] = "SCALABILITY";
    TestType["Stable"] = "STABLE";
    TestType["JMX"] = "OWN_JMX";
})(TestType || (TestType = {}));
