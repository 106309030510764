import React from "react";
import { ButtonSize, ButtonType } from "@boomq/uikit";
import { TestResultSlaState } from "@boomq/common";
import { StyledButton } from "./styled";

export const SlaButton = (props) => {
    const clickSlaHandler = () => (typeof props.onShowSlaForm === "function" ? props.onShowSlaForm() : undefined);

    return (
        <StyledButton
            disabled={props.testResultSlaState === TestResultSlaState.Processing}
            onClick={clickSlaHandler}
            size={ButtonSize.medium}
            styletype={ButtonType.outlinedHighlight}
            theme={props.theme}
        >
            SLA
        </StyledButton>
    );
};
