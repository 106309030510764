import { takeEvery } from "redux-saga/effects";
import {
    cancelEditTreeData,
    cancelEditThreadGroupTreeDataFlow,
    leaveEditor,
    leaveThreadGroupTreeEditorFlow
} from "@boomq/common";
import store from "../index";

function* cancelEditTreeDataFlow() {
    try {
        yield* cancelEditThreadGroupTreeDataFlow(store.dispatch);
    } catch (e) {
        console.error(e);
    }
}

function* leaveEditorFlow({ payload }) {
    try {
        yield* leaveThreadGroupTreeEditorFlow({ ...payload, dispatch: store.dispatch });
    } catch (e) {
        console.error(e);
    }
}

export function* threadGroupEditorFlow() {
    yield takeEvery(cancelEditTreeData, cancelEditTreeDataFlow);
    yield takeEvery(leaveEditor, leaveEditorFlow);
}
