var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { getDateOnly, getISODayRange, useIntl } from "@boomq/utils";
import { Button, DateRangePicker, Select, SelectItemType } from "@boomq/uikit";
import { DEFAULT_TOP_URL_COUNT } from "./constants";
import { ACCOUNT_SELECTION_SELECT_PLACEHOLDER, ADD_ACCOUNT_ACTION_LABEL, IMPORT_BUTTON_LABEL, REFRESH_SITE_LIST_ACTION_LABEL, SITE_SELECTION_SELECT_PLACEHOLDER } from "./intl";
import { AccountSelect, ButtonSection, ComboBoxSection, DateRangeStatistics, ImportAnalyticsParams, ImportAnalyticsSection, SiteSelection } from "./styled";
import { formatIntlMessage } from "../../../../helpers/intl";
import { importStatsValidator } from "../../../../helpers/validators/importStats";
import { importStatsTopUrlCountItems } from "../../../../models/importData";
import authorizeActions from "../../../../redux/actions/authorize";
import clientActions from "../../../../redux/actions/client";
import { closeSiteModal } from "../../../../redux/actions/siteModal";
import { getClientAuthProviders, getCurrentDateFormatLocale, getIsAuthorize } from "../../../../redux/reducers/authorizePlate";
import { getWebsitesByType, getWebsitesClientsByType } from "../../../../redux/reducers/clientWebsites";
import { getCurrentLanguage } from "../../../../redux/reducers/languagePlate";
const { linkAccountRequest } = authorizeActions;
const { analyticsRequest, clientWebsitesRequest } = clientActions;
const connector = connect((state, ownProps) => ({
    clientAuthProviders: getClientAuthProviders(state),
    clients: getWebsitesClientsByType(state, ownProps.sourceType),
    clientWebsites: getWebsitesByType(state, ownProps.sourceType),
    currentLanguage: getCurrentLanguage(state),
    isAuthorized: getIsAuthorize(state),
    dateFormatLocale: getCurrentDateFormatLocale(state)
}), {
    analyticsRequest,
    clientWebsitesRequest,
    closeSiteModal,
    linkAccountRequest
});
const initState = {
    endDate: getISODayRange(-30).endDate,
    errors: {
        dates: null,
        site: null
    },
    siteId: null,
    startDate: getISODayRange(-30).startDate,
    topUrl: 5,
    userId: null
};
const ImportAnalytics = (props) => {
    const { formatMessage } = useIntl();
    const getClientAuthProvidersByType = () => props.isAuthorized
        ? props.clientAuthProviders.filter((provider) => provider.identityProviderType === props.importType)
        : [];
    const [state, setState] = useState(initState);
    const changeDateRangeHandler = (dates) => setState(Object.assign(Object.assign(Object.assign({}, state), dates), { errors: Object.assign(Object.assign({}, state.errors), { dates: null }) }));
    const getClientWebSites = (userId) => {
        const { clientWebsitesRequest, importType } = props;
        clientWebsitesRequest({ type: importType, identityUserId: userId });
    };
    const isExistClientByUserId = (clients = [], userId) => clients.indexOf(userId) > -1;
    const changeAccountProvider = (value) => {
        setState(Object.assign(Object.assign({}, state), { userId: value }));
        !isExistClientByUserId(props.clients, value) && getClientWebSites(value);
    };
    const changeProviderAccountHandler = ({ value }) => {
        const { formType, importType, linkAccountRequest } = props;
        return value === "linkAccount"
            ? linkAccountRequest({ formType, serviceType: String(importType).toLocaleLowerCase() })
            : changeAccountProvider(value);
    };
    const changeSiteHandler = ({ value }) => value === "refreshClientWebsites"
        ? getClientWebSites(state.userId)
        : setState(Object.assign(Object.assign({}, state), { siteId: value, errors: Object.assign(Object.assign({}, state.errors), { site: null }) }));
    const changeTopUrlHandler = ({ value }) => setState(Object.assign(Object.assign({}, state), { topUrl: value }));
    const importStatsHandler = () => {
        const { analyticsRequest, closeSiteModal, importType } = props;
        const { endDate, siteId, startDate, topUrl, userId } = state;
        const { errors, isValid } = importStatsValidator({ startDate, endDate, siteId });
        isValid &&
            analyticsRequest({
                siteId,
                top: topUrl,
                type: importType,
                userId,
                startDay: getDateOnly(startDate),
                endDay: getDateOnly(endDate)
            });
        isValid && closeSiteModal();
        !isValid && setState(Object.assign(Object.assign({}, state), { errors }));
    };
    const prepareSiteItems = () => [
        {
            id: -1,
            text: formatIntlMessage(REFRESH_SITE_LIST_ACTION_LABEL, formatMessage),
            type: SelectItemType.Action,
            value: "refreshClientWebsites"
        },
        ...(props.clientWebsites || [])
            .filter((site) => site.identityUserId === state.userId)
            .map((site) => (Object.assign(Object.assign({}, site), { text: site.url, value: site.id })))
    ];
    const prepareProviderAccounts = () => [
        ...getClientAuthProvidersByType().map((account) => ({
            id: account.identityUserId,
            text: account.email,
            value: account.identityUserId
        })),
        {
            id: -1,
            text: formatIntlMessage(ADD_ACCOUNT_ACTION_LABEL, formatMessage),
            type: SelectItemType.Action,
            value: "linkAccount"
        }
    ];
    const formattedImportStatsTopUrlCountItems = importStatsTopUrlCountItems.map((_a) => {
        var { text } = _a, restParams = __rest(_a, ["text"]);
        return (Object.assign(Object.assign({}, restParams), { text: formatIntlMessage(text, formatMessage) }));
    });
    const providerAccountSelectPlaceholder = formatIntlMessage(ACCOUNT_SELECTION_SELECT_PLACEHOLDER, formatMessage);
    const siteSelectionSelectPlaceholder = formatIntlMessage(SITE_SELECTION_SELECT_PLACEHOLDER, formatMessage);
    return (React.createElement(ImportAnalyticsSection, null,
        React.createElement(AccountSelect, { items: prepareProviderAccounts(), onChange: changeProviderAccountHandler, placeholder: providerAccountSelectPlaceholder }),
        React.createElement(ImportAnalyticsParams, null,
            React.createElement(SiteSelection, null,
                React.createElement(Select, { error: state.errors.site, items: prepareSiteItems(), onChange: changeSiteHandler, placeholder: siteSelectionSelectPlaceholder })),
            React.createElement(ComboBoxSection, null,
                React.createElement(Select, { defaultValue: DEFAULT_TOP_URL_COUNT, items: formattedImportStatsTopUrlCountItems, onChange: changeTopUrlHandler }),
                React.createElement(DateRangeStatistics, null,
                    React.createElement(DateRangePicker, { error: state.errors.dates, startDate: state.startDate, endDate: state.endDate, locale: props.dateFormatLocale, onChange: changeDateRangeHandler })))),
        React.createElement(ButtonSection, null,
            React.createElement(Button, { name: "import", onClick: importStatsHandler },
                React.createElement(FormattedMessage, Object.assign({}, IMPORT_BUTTON_LABEL))))));
};
export default connector(ImportAnalytics);
