import styled from "styled-components";
import { Color, FontBodyMediumBold, IconPlus } from "@boomq/uikit";
export const NewItem = styled.div `
    align-items: center;
    background: ${Color.white};
    display: flex;
    padding: 0 24px;
    z-index: 1;
`;
export const NewItemSection = styled.div `
    ${FontBodyMediumBold}
    align-items: center;
    color: ${Color.blue};
    cursor: pointer;
    display: flex;
    justify-content: center;
    position: relative;
    &:before {
        border-bottom: 1px dashed ${Color.blue};
        content: '';
        height: 1px;
        position: absolute;
        top: calc(50% - 1px);
        width: 100%;
    }
`;
export const StyledIconPlus = styled(IconPlus) `
    fill: ${Color.blue};
    height: 20px;
    margin-right: 6px;
    width: 20px;
`;
