import React from "react";
import { connect } from "react-redux";
import { ConfirmForm } from "../../../common/ConfirmForm";
import newTestActions from "../../../../redux/actions/newTest";
const { changeAutoPercentages } = newTestActions;
const connector = connect(null, { changeAutoPercentages });
const AutoPercentagesConfirmForm = ({ changeAutoPercentages }) => (React.createElement(ConfirmForm, { confirmButton: {
        action: () => changeAutoPercentages({ isAccepted: true, value: true }),
        theme: { width: "40%!important" },
        title: {
            defaultMessage: "Yes, re-calculate values",
            id: "account.modal.new.test.project.auto.percentages.button.yes"
        }
    }, cancelButton: {
        theme: { width: "40%!important" },
        title: {
            defaultMessage: "No, keep existing value",
            id: "account.modal.new.test.project.auto.percentages.button.no"
        }
    }, text: {
        description: [
            {
                defaultMessage: "All transaction's share of users will be automatically re-calculated.",
                id: "account.modal.new.test.project.auto.percentages.text1"
            },
            {
                defaultMessage: "All manually set values will be re-calculated and lost. Do you want to continue?",
                id: "account.modal.new.test.project.auto.percentages.text2"
            }
        ]
    } }));
export default connector(AutoPercentagesConfirmForm);
