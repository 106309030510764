import { call } from "redux-saga/effects";
import { reportTableDataGenerationFailed } from "./reportTableDataGenerationFailed";
import { generateTableDataForSLA, generateTableParamsForSLAByTransaction } from "../../../helpers/generators/report";
import { apiGenerateReportTablePreview } from "../../../services/reportSrv";
export function* generateTableDataForSLAByTransactionFlow(params) {
    try {
        const tableParams = generateTableParamsForSLAByTransaction(params);
        const { data } = yield call(apiGenerateReportTablePreview, tableParams);
        return generateTableDataForSLA(data);
    }
    catch (e) {
        return yield* reportTableDataGenerationFailed();
    }
}
