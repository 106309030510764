import { IconCopy, IconEdit, IconPlay } from "../../components/styled/Icons";
import { StyledIconSettings } from "../../components/account/AllTestProjectsItem/styled";
import { TeamMemberPermission, TestProjectsItemAction } from "../types";
const actionsByTeamMemberPermissionEdit = [
    TestProjectsItemAction.ShowSettingsSetSidebar,
    TestProjectsItemAction.Copy,
    TestProjectsItemAction.Edit
];
const actionsByTeamMemberPermissionRun = [
    ...actionsByTeamMemberPermissionEdit,
    TestProjectsItemAction.RedirectToLoadProfile
];
const actionsByTeamMemberPermissions = {
    [TeamMemberPermission.Admin]: actionsByTeamMemberPermissionRun,
    [TeamMemberPermission.Edit]: actionsByTeamMemberPermissionEdit,
    [TeamMemberPermission.Run]: actionsByTeamMemberPermissionRun,
    [TeamMemberPermission.View]: [TestProjectsItemAction.ShowSettingsSetSidebar]
};
const actionIconsByName = {
    [TestProjectsItemAction.Copy]: IconCopy,
    [TestProjectsItemAction.Edit]: IconEdit,
    [TestProjectsItemAction.RedirectToLoadProfile]: IconPlay,
    [TestProjectsItemAction.ShowSettingsSetSidebar]: StyledIconSettings
};
const getTestProjectsActionsByTeamMemberPermission = (permission) => {
    const actionsByPermission = actionsByTeamMemberPermissions[permission];
    return actionsByPermission ? actionsByPermission : [];
};
const getTestProjectsActionsByTeamMemberPermissions = (permissions) => [
    ...new Set(permissions.reduce((res, permission) => [
        ...res,
        ...getTestProjectsActionsByTeamMemberPermission(permission)
    ], []))
];
export const getActionIconByName = (actionName) => actionIconsByName[actionName];
export const getActionItemsByTeamMemberPermissions = (permissions) => getTestProjectsActionsByTeamMemberPermissions(permissions).map((actionName) => ({
    id: actionName,
    actionName
}));
const actionHandler = (props, actionName, params) => typeof props[actionName] === "function" ? props[actionName](params) : undefined;
export const testProjectsItemActions = {
    [TestProjectsItemAction.Copy]: (props, id) => actionHandler(props, TestProjectsItemAction.Copy, { id }),
    [TestProjectsItemAction.Edit]: (props, id) => actionHandler(props, TestProjectsItemAction.Edit, { id, step: 1 }),
    [TestProjectsItemAction.RedirectToLoadProfile]: (props, id) => actionHandler(props, TestProjectsItemAction.RedirectToLoadProfile, { id, step: 2 }),
    [TestProjectsItemAction.ShowSettingsSetSidebar]: (props, id) => actionHandler(props, TestProjectsItemAction.ShowSettingsSetSidebar, { id })
};
