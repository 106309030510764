import styled from "styled-components";
import { Color, IconArrowRight } from "@boomq/uikit";
import { SidebarButton } from "../../styled/TestProject";
export const Name = styled.p `
    flex: auto;
`;
export const StyledSidebarButton = styled(SidebarButton) `
    justify-content: space-between;
    text-align: left;
    width: 100%;
    .icon {
        margin-right: 4px;
    }
`;
export const StyledIconArrowRight = styled(IconArrowRight) `
    fill: ${Color.grey75};
`;
