import styled from "styled-components";
export const customNewRequestFormButtonTheme = {
    flex: "none",
    lineHeight: "17px",
    width: "auto!important"
};
export const Content = styled.div `
    padding: 20px;
`;
export const Group = styled.li `
    background: rgba(73, 137, 214, 0.1);
    border: 1px solid #4989d6;
    border-radius: 10px;
    margin-bottom: 30px;
`;
export const NewRequestSection = styled.div `
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
`;
