import React from "react";
import { FormattedMessage } from "react-intl";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import arrow from "../../assets/images/icons/test_details/arrow.svg";
const renderText = (text) => (text && text.id ? React.createElement(FormattedMessage, Object.assign({}, text)) : text);
const Title = ({ history, onClick, text, url }) => {
    const changeHistory = () => (typeof url === "string" ? history.push(url) : history.goBack());
    const clickHandler = () => (typeof onClick === "function" ? onClick() : changeHistory());
    return (React.createElement(LinkWithIcon, { onClick: clickHandler },
        React.createElement(Arrow, { src: arrow, alt: "arrow" }),
        React.createElement(Text, null, renderText(text))));
};
const Arrow = styled.img `
    height: auto;
    transition: all 0.3s ease-in-out;
    width: 25px;
`;
const Text = styled.span `
    color: #ffffff;
    overflow: hidden;
    padding-left: 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
`;
const LinkWithIcon = styled.div `
    cursor: pointer;
    display: flex;
    padding-left: 5px;
    &:hover {
        ${Arrow} {
            transform: translateX(-5px);
        }
    }
`;
export default withRouter(Title);
