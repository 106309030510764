var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useState } from "react";
import { DefaultHeaderTemplate } from "./DefaultHeaderTemplate";
import { checkClicking, getHeaderTemplateProps, getStateValue } from "./helpers";
import { SpoilerBody } from "./styled";
export const Spoiler = (_a) => {
    var { children, headerTemplate, isOpened, onlyArrowClick, onToggle } = _a, restProps = __rest(_a, ["children", "headerTemplate", "isOpened", "onlyArrowClick", "onToggle"]);
    const [isOpenedValue, setIsOpenedValue] = useState(getStateValue(isOpened));
    useEffect(() => {
        setIsOpenedValue(getStateValue(isOpened));
    }, [isOpened]);
    const toggle = (value) => {
        setIsOpenedValue(value);
        return typeof onToggle === "function" ? onToggle(value) : undefined;
    };
    const toggleHandler = (event) => checkClicking(event, onlyArrowClick) ? toggle(!isOpenedValue) : undefined;
    return (React.createElement("div", Object.assign({}, restProps),
        React.isValidElement(headerTemplate) ? (React.cloneElement(headerTemplate, Object.assign(Object.assign({}, getHeaderTemplateProps(headerTemplate.props)), { isOpened: isOpenedValue, onClick: toggleHandler }))) : (React.createElement(DefaultHeaderTemplate, Object.assign({ isOpened: isOpenedValue, onClick: toggleHandler }, restProps))),
        React.createElement(SpoilerBody, { isOpened: isOpenedValue }, children)));
};
