import styled from "styled-components";
import { IconUser } from "@boomq/uikit2";
export const StyledIconUserPhoto = styled(IconUser) `
    fill: #c8cad9;
    height: 36px;
    margin-right: 14px;
    width: 36px;
`;
export const UserData = styled.p `
    display: flex;
    flex-direction: column;
    justify-content: center;
`;
export const UserEmail = styled.span `
    color: #7f8198;
`;
export const UserInfoBlock = styled.div `
    background: #f6f8f9;
    border: 2px solid #e3e9f3;
    border-radius: 8px;
    box-shadow: inset 0 2px 4px rgba(196, 213, 228, 0.2);
    display: flex;
    min-width: 266px;
    padding: 14px;
`;
export const UserName = styled.span `
    color: #000;
`;
