import { arrayToObject, getUniqueId, isEmpty, objectToArray } from "@boomq/utils";
import { completedStatuses, getFormattedTestStatusText } from "../testStatus";
import { TestDisplayStatus } from "../../models/testRun";
export const getWebhookTestDisplayStatusList = (formatMessageFunc) => [...completedStatuses, TestDisplayStatus.Waiting, TestDisplayStatus.Running].map((testDisplayStatus) => ({
    id: testDisplayStatus,
    text: getFormattedTestStatusText(testDisplayStatus, formatMessageFunc),
    value: testDisplayStatus
}));
const parseTestDisplayStateSet = (testDisplayStateSet) => (testDisplayStateSet || []).map((testDisplayState) => ({
    id: testDisplayState,
    text: testDisplayState,
    value: testDisplayState
}));
const parseWebhook = ({ headers, testDisplayStateSet, url }) => ({
    headers: objectToArray(headers, "key", "value"),
    id: getUniqueId(),
    testDisplayStateSet: parseTestDisplayStateSet(testDisplayStateSet),
    url
});
export const parseWebhooksDataFromDto = (webhooks) => (webhooks || []).map(parseWebhook);
const prepareTestDisplayStateSet = (testDisplayStateSet) => (testDisplayStateSet || []).map(({ value }) => value);
const prepareWebhook = ({ headers, testDisplayStateSet, url }) => ({
    url,
    headers: arrayToObject(headers, "key", "value"),
    testDisplayStateSet: prepareTestDisplayStateSet(testDisplayStateSet)
});
export const prepareWebhooksData = (webhooks) => (webhooks || []).filter(({ testDisplayStateSet }) => !isEmpty(testDisplayStateSet)).map(prepareWebhook);
