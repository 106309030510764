var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { PseudoInput, StyledCheckbox } from "./styled";
export var SwitcherDirection;
(function (SwitcherDirection) {
    SwitcherDirection["Horizontal"] = "HORIZONTAL";
    SwitcherDirection["Vertical"] = "VERTICAL";
})(SwitcherDirection || (SwitcherDirection = {}));
export const Switcher = (_a) => {
    var { direction = SwitcherDirection.Horizontal, disabled, name, onChange, onClick, defaultChecked, theme } = _a, restProps = __rest(_a, ["direction", "disabled", "name", "onChange", "onClick", "defaultChecked", "theme"]);
    return (React.createElement("label", Object.assign({}, restProps, { onClick: onClick }),
        React.createElement(PseudoInput, { direction: direction, type: "checkbox", checked: defaultChecked, disabled: disabled, name: name || "checkbox", onChange: onChange }),
        React.createElement(StyledCheckbox, { direction: direction, theme: theme })));
};
