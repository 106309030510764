import React from "react";
import { Link } from "react-router-dom";
import { useIntl } from "@boomq/utils";
import { ButtonSize, Tooltip } from "@boomq/uikit";
import {
    TestModeSwitcher,
    TestProjectNotification,
    getTestProjectSaveNotification,
    isAllowedTestProjectActionByPermissions,
    startTestNotification
} from "@boomq/common";
import { NOTIFICATION_LINKPROJECT_HERE_TEXT, RUN_TEST_BUTTON_LABEL, SAVE_TEST_BUTTON_LABEL } from "./intl";
import { ButtonSection, StyledButton, StyledButtonWithMargin, TooltipEl } from "./styled";
import { TestProjectAction } from "../../../../helpers/types";

const isNewTestPage = (location) => location && location.pathname === "/account/new-test";

const isError = (error) => error && !error.isValid;

const renderRunTestButton = ({ error, formatMessage, onRunTest }) => {
    const { exceptionText, isValid } = error || {};
    const { defaultMessage, id, values } = exceptionText ? exceptionText : {};

    return isValid ? (
        <StyledButtonWithMargin onClick={onRunTest} size={ButtonSize.medium}>
            {formatMessage(RUN_TEST_BUTTON_LABEL)}
        </StyledButtonWithMargin>
    ) : (
        <StyledButton disabled={isError(error)} size={ButtonSize.medium}>
            {formatMessage(RUN_TEST_BUTTON_LABEL)}
            <Tooltip title={formatMessage({ defaultMessage, id }, values)}>
                <TooltipEl />
            </Tooltip>
        </StyledButton>
    );
};

export const StartSelection = ({
    error,
    hideTestProjectNotification,
    isAuthorized,
    isDebugMode,
    location,
    onRunTest,
    onSave,
    permissionList,
    testProjectChangeParamsField
}) => {
    const { formatMessage } = useIntl();

    const clickSaveHandler = () =>
        onSave({
            postAction: {
                success: {
                    actionType: getTestProjectSaveNotification.toString(),
                    payload: {
                        isShowProjectLink: isNewTestPage(location),
                        getNotificationPayload: (projectId, isShowProjectLink) =>
                            startTestNotification({
                                action: hideTestProjectNotification,
                                linkProject: isShowProjectLink ? (
                                    <Link to={`/account/tests/${projectId}`}>
                                        {formatMessage(NOTIFICATION_LINKPROJECT_HERE_TEXT)}
                                    </Link>
                                ) : undefined
                            })
                    }
                }
            }
        });

    const toggleTestModeHandler = (value) => testProjectChangeParamsField({ field: "isDebugMode", value });

    return (
        <ButtonSection>
            <TestProjectNotification />

            {isAuthorized && isAllowedTestProjectActionByPermissions(permissionList, TestProjectAction.Save) && (
                <StyledButtonWithMargin
                    // disabled={ !isModified }
                    onClick={clickSaveHandler}
                    size={ButtonSize.medium}
                >
                    {formatMessage(SAVE_TEST_BUTTON_LABEL)}
                </StyledButtonWithMargin>
            )}

            {isAllowedTestProjectActionByPermissions(permissionList, TestProjectAction.RunTest) && (
                <>
                    {renderRunTestButton({ error, formatMessage, onRunTest })}

                    <TestModeSwitcher onToggle={toggleTestModeHandler} value={isDebugMode} />
                </>
            )}
        </ButtonSection>
    );
};
