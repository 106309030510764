import styled from "styled-components";
import { Color, IconPeople } from "@boomq/uikit";
import { TeamInfo } from "../TeamInfo";
import { HeaderText } from "../../styled/Header";
import { ToolbarLink } from "../../website/ToolbarLink";
import { StyledIconPeople, TeamName } from "../TeamInfo/styled";
export const MobileHeaderText = styled(HeaderText) `
    color: #6b8cae;
    font-size: 18px;
    font-weight: 500;
    margin-left: 8px;
    text-align: center;
`;
const MobileIconPeopleStyles = `
    fill: ${Color.blue};
    height: 40px;
    width: 40px;
`;
const TeamItemStyles = `
    color: #6b8cae;
    padding: 8px 0px;
`;
export const StyledMobileIconPeople = styled(IconPeople) `
    ${MobileIconPeopleStyles}
`;
export const StyledTeamInfo = styled(TeamInfo) `
    ${TeamItemStyles}
    color: #6b8cae;
    font-size: 18px;
    margin-left: 0;
    padding: 8px 0;
    text-align: center;
    ${StyledIconPeople} {
        ${MobileIconPeopleStyles}
    }
    ${TeamName} {
        color: #6b8cae;
        font-size: 18px;
        font-weight: 500;
        margin-left: 8px;
        max-width: calc(100% - 80px);
    }
`;
export const TeamLink = styled(ToolbarLink) `
    ${TeamItemStyles}
    align-items: center;
    background: transparent;
    cursor: pointer;
    display: flex;
    outline: none;
    transition: all 0.3s ease-in-out;
`;
