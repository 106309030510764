export const ALL_TESTS_MENU_ITEM_LABEL = {
    defaultMessage: "Test runs",
    id: "account.navbar.all"
};
export const NEW_TEST_MENU_ITEM_LABEL = {
    defaultMessage: "New test",
    id: "account.navbar.new"
};
export const PROJECTS_MENU_ITEM_LABEL = {
    defaultMessage: "Tests",
    id: "account.navbar.projects"
};
export const REPORTS_MENU_ITEM_LABEL = {
    defaultMessage: "Reports",
    id: "account.navbar.reports"
};
export const TRENDING_MENU_ITEM_LABEL = {
    defaultMessage: "Trending",
    id: "account.navbar.trending"
};
