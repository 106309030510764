import { isEmpty } from "@boomq/utils";
import { SLA_RESULTS_BY_TEST_DURATION_WARN } from "./intl";
import { getTestTotalDuration } from "../generators/generators2";
import { getSlaFromDate } from "../sla/slaParams";
import { SlaTargetType, SlaType } from "../../models/sla/enums";
export const slaByTestDurationValidator = (testProjectData, slaData) => {
    const slaFromDateList = Object.keys(slaData)
        .reduce((res, slaType) => [...res, ...slaData[slaType]], [])
        .map((slaItem) => getSlaFromDate(slaItem));
    const slaMaxFromDate = Math.max(...slaFromDateList);
    const totalDuration = getTestTotalDuration(testProjectData);
    const isValid = totalDuration < slaMaxFromDate ? false : true;
    return {
        isValid,
        exceptionText: isValid ? undefined : SLA_RESULTS_BY_TEST_DURATION_WARN
    };
};
const slaRequestValidator = ({ requestName, threadGroupId, transactionName }) => !isEmpty(requestName) && !isEmpty(transactionName) && !isEmpty(threadGroupId);
const slaSystemMetricsHostValidator = ({ hostname }) => !isEmpty(hostname);
const slaThreadGroupValidator = ({ threadGroupId }) => !isEmpty(threadGroupId);
const slaTransactionValidator = ({ threadGroupId, transactionName }) => !isEmpty(transactionName) && !isEmpty(threadGroupId);
const slaItemValidatorByType = {
    [SlaType.Request]: slaRequestValidator,
    [SlaType.SystemMetricsHost]: slaSystemMetricsHostValidator,
    [SlaType.ThreadGroup]: slaThreadGroupValidator,
    [SlaType.Transaction]: slaTransactionValidator
};
export const slaItemValidator = (slaItem) => Object.keys(slaItemValidatorByType).includes(slaItem.type) ? slaItemValidatorByType[slaItem.type](slaItem) : true;
export const slaItemsValidator = (slaData) => [
    SlaTargetType.SystemMetricsHost,
    SlaTargetType.ThreadGroup,
    SlaTargetType.Transaction,
    SlaTargetType.Request
].reduce((res, slaTargetType) => {
    let invalidSlaTargetType = res.invalidSlaTargetType;
    let isResultValid = res.isValid;
    const validatedSlaItems = slaData[slaTargetType].map((slaItem) => {
        const isValid = slaItemValidator(slaItem);
        invalidSlaTargetType = isValid ? invalidSlaTargetType : slaTargetType;
        isResultValid = isValid ? isResultValid : false;
        return Object.assign(Object.assign({}, slaItem), { isValid });
    });
    return {
        invalidSlaTargetType,
        isValid: isResultValid,
        validatedSlaData: Object.assign(Object.assign({}, res.validatedSlaData), { [slaTargetType]: validatedSlaItems })
    };
}, { invalidSlaTargetType: null, isValid: true, validatedSlaData: slaData });
