export const FIELD_DELIMITER_LABEL_TEXT = {
    defaultMessage: "Field delimiter",
    id: "account.new.test.project.parameters.import.csv.delimiter.title"
};
export const HAS_HEADERS_LABEL_TEXT = {
    defaultMessage: "The first line contains parameter's names",
    id: "account.new.test.project.parameters.import.csv.headers.title"
};
export const ORDER_RANDOM_LABEL_TEXT = {
    defaultMessage: "Random",
    id: "account.new.test.project.parameters.import.csv.order.random.label"
};
export const ORDER_SEQUENTIAL_LABEL_TEXT = {
    defaultMessage: "Sequential",
    id: "account.new.test.project.parameters.import.csv.order.sequential.label"
};
export const ORDER_TITLE_TEXT = {
    defaultMessage: "Order:",
    id: "account.new.test.project.parameters.import.csv.order.title"
};
export const PREVIEW_TITLE_TEXT = {
    defaultMessage: "Preview",
    id: "account.new.test.project.parameters.import.csv.file.preview.title"
};
export const RECYCLE_LABEL_TEXT = {
    defaultMessage: "Re-use values at the end of file",
    id: "account.new.test.project.parameters.import.csv.recycle.title"
};
export const INDEPENDENT_LIST_PER_THREAD_LABEL_TEXT = {
    defaultMessage: "Each thread uses each row or values are separated by threads",
    id: "account.new.test.project.parameters.import.csv.independentListPerThread.title"
};
