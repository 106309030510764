var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState } from "react";
import { isEmpty } from "@boomq/utils";
import { InputText, Radio, Tooltip } from "@boomq/uikit";
import { SETTINGS_SET_NAME_INITIAL_STATE } from "./constants";
import { INCORRECT_SETTINGS_SET_NAME_EMPTY, NEW_SETTINGS_SET_ACTION_CANCEL_TOOLTIP, NEW_SETTINGS_SET_ACTION_CONFIRM_TOOLTIP } from "./intl";
import { Action, NewSettingsSet, StyledIconCheck, StyledIconClose } from "./styled";
export const NewSettingsSetInSidebar = (_a) => {
    var { onAdd, onCancel, settingsSetNamePlaceholder } = _a, restProps = __rest(_a, ["onAdd", "onCancel", "settingsSetNamePlaceholder"]);
    const [error, setError] = useState(null);
    const [settingsSetName, setSettingsSetName] = useState(SETTINGS_SET_NAME_INITIAL_STATE);
    const isValidSettingsSetName = () => {
        const { error, isValid } = isEmpty(settingsSetName)
            ? {
                error: INCORRECT_SETTINGS_SET_NAME_EMPTY,
                isValid: false
            }
            : {
                error: null,
                isValid: true
            };
        setError(error);
        return isValid;
    };
    const addNewSettingsSet = () => (typeof onAdd === "function" ? onAdd(settingsSetName) : undefined);
    const addNewSettingsSetHandler = () => (isValidSettingsSetName() ? addNewSettingsSet() : undefined);
    const cancelHandler = () => {
        setError(null);
        return typeof onCancel === "function" ? onCancel() : undefined;
    };
    const changeSettingsNameHandler = (value) => setSettingsSetName(value);
    const keyDownHandler = (e) => e.keyCode === 13 ? addNewSettingsSetHandler() : undefined;
    return (React.createElement(NewSettingsSet, Object.assign({}, restProps),
        React.createElement(Radio, { disabled: true, name: "newSettingsSet" }),
        React.createElement(InputText, { error: error, name: "Settings set name", onChange: changeSettingsNameHandler, onKeyDown: keyDownHandler, placeholder: settingsSetNamePlaceholder, value: settingsSetName }),
        React.createElement(Tooltip, { title: NEW_SETTINGS_SET_ACTION_CONFIRM_TOOLTIP },
            React.createElement(Action, { onClick: addNewSettingsSetHandler },
                React.createElement(StyledIconCheck, null))),
        React.createElement(Tooltip, { title: NEW_SETTINGS_SET_ACTION_CANCEL_TOOLTIP },
            React.createElement(Action, { onClick: cancelHandler },
                React.createElement(StyledIconClose, null)))));
};
