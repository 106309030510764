export const SLA_RESULTS_BY_TEST_DURATION_WARN = {
    defaultMessage: "SLA timeline is beyond test duration. SLA results of test may be incorrect",
    id: "account.test.profile.warn.sla.results"
};
export const EMPTY_LITERAL_PARAMETER_NAME = {
    defaultMessage: "Parameter name is required",
    id: "account.test.project.settings.set.literal.parameter.empty.name"
};
export const EMPTY_NAME = {
    defaultMessage: "Name can't be empty",
    id: "validation.name.can.not.be.empty"
};
export const EMPTY_TEXT = {
    defaultMessage: " ",
    id: "title.empty"
};
export const INCORRECT_ELEMENT_PARAM = {
    defaultMessage: "Required field",
    id: "account.request.group.editor.element.error.required.field"
};
export const INCORRECT_ASSERTION_PARAM = {
    defaultMessage: "Required field",
    id: "account.request.form.assertion.error.required.field"
};
export const INCORRECT_EXTRACTOR_PARAM = {
    defaultMessage: "Required field",
    id: "account.request.form.extract.response.error.required.field"
};
export const INCORRECT_REQUIRED_FIELD = {
    defaultMessage: "Required field",
    id: "account.error.required.field"
};
export const INCORRECT_SETTINGS_SET_NAME_EMPTY = {
    defaultMessage: "Settings set name can't be empty",
    id: "account.test.project.settings.set.incorrect.name"
};
export const INCORRECT_TEAM_NAME_EMPTY = {
    defaultMessage: "Team name can't be empty",
    id: "account.team.name.error.empty"
};
export const INCORRECT_TEST_PROJECT_SETTINGS_SET = {
    defaultMessage: "Incorrect settings in {incorrectSettingTabs}: {incorrectSettingTabNames}",
    id: "account.test.project.settings.set.incorrect.settings"
};
export const INCORRECT_URL_OVERWRITE_URL = {
    defaultMessage: "Invalid URL is exist in URL overwrite",
    id: "account.test.project.settings.set.url.overwrite.error.invalid.url"
};
export const INCORRECT_VALUE_BY_ISO_8859_1 = {
    defaultMessage: "Value contains non-ISO-8859-1 characters",
    id: "validation.value.iso.8859.1.invalid"
};
export const INCORRECT_WEBHOOK = {
    defaultMessage: "Invalid webhook is exist in other settings",
    id: "account.test.project.settings.set.webhook.error"
};
export const INCORRECT_WEBHOOK_ITEM = {
    defaultMessage: "Webhook has incorrect params",
    id: "account.test.project.settings.set.webhook.error.item"
};
export const INCORRECT_WEBHOOK_TEST_STATUS = {
    defaultMessage: "Test run status can not be empty",
    id: "account.test.project.settings.set.webhook.error.test.status"
};
