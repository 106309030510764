import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { useIntl } from "@boomq/utils";
import { CommonProfileSettings } from "../../../../components/account/TestProject/CommonProfileSettings";
import { withResize } from "../../../../components/styled/response";
import { TestProjectAction, TestType } from "../../../../helpers/types";
import authorizeActions from "../../../../redux/actions/authorize";
import { newRequestChangeParams, newRequestChangeParamsField } from "../../../../redux/actions/newRequest";
import newTestActions from "../../../../redux/actions/newTest";
import { getSiteModal } from "../../../../redux/actions/siteModal";
import { hideTestProjectNotification } from "../../../../redux/actions/testProjectNotification";
import { getActiveAction, getClientPermissionList, getIsAuthorize } from "../../../../redux/reducers/authorizePlate";
import { getAutoPercentages, getProfileDistribution, getProjectId, getProjectName, getRequestGroups, getRequests, getTestType, getThreadGroupsElements, getThreadGroupsRequests } from "../../../../redux/reducers/newTest";
const { setActiveAction } = authorizeActions;
const { addThreadGroup, changeAutoPercentages, changeDistribution, saveTestProjectRequest, selectProfile } = newTestActions;
const getProps = (state) => {
    const testType = getTestType(state);
    return {
        requests: testType === TestType.JMX ? getRequests(state) : getThreadGroupsRequests(state),
        testType
    };
};
const connector = connect((state) => (Object.assign({ activeAction: getActiveAction(state), autoPercentages: getAutoPercentages(state), elementsData: getThreadGroupsElements(state), isAuthorized: getIsAuthorize(state), permissionList: getClientPermissionList(state), profileDistribution: getProfileDistribution(state), projectId: getProjectId(state), projectName: getProjectName(state), threadGroups: getRequestGroups(state) }, getProps(state))), {
    getSiteModal,
    hideTestProjectNotification,
    newRequestChangeParams,
    newRequestChangeParamsField,
    onAddThreadGroup: addThreadGroup,
    [TestProjectAction.ChangeAutoPercentages]: changeAutoPercentages,
    onChangeDistribution: changeDistribution,
    onClickNext: selectProfile,
    onSave: saveTestProjectRequest,
    setActiveAction
});
const ProfileSettingsContainer = (props) => {
    const { formatMessage } = useIntl();
    return React.createElement(CommonProfileSettings, Object.assign({}, props, { intl: { formatMessage } }));
};
export default withResize(withRouter(connector(ProfileSettingsContainer)));
