import styled, { css } from "styled-components";
import { media } from "@boomq/common";

export const LoadingStation = styled.div`
    align-items: center;
    display: flex;
    justify-content: flex-start;
    position: relative;
    ${media.tablet} {
        flex-direction: column;
    }
    ${({ theme }): any => (theme ? css(theme) : css``)}
`;
