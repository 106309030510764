import styled, { css } from "styled-components";
export const Item = styled.div `
    padding-left: 32px;
    margin-top: 20px;
    &:first-child {
        margin-top: 0;
    }
`;
export const ContentWrapper = styled.div `
    display: flex;
    align-items: center;
`;
export const Text = styled.p `
    font-style: normal;
    line-height: normal;
    color: ${({ color }) => (color ? color : "#396496")};
    font-weight: ${({ desc }) => (!desc ? 600 : 500)};
    font-size: ${({ desc }) => (!desc ? "16px" : "14px")};
    margin-left: ${({ desc }) => (!desc ? "18px" : "0")};
    margin-right: ${({ desc }) => (!desc ? "5px" : "0")};
    ${({ comment }) => comment
    ? css `
                  flex: 1;
                  height: 57px;
                  margin: 10px 25px 0 38px;
                  word-break: break-all;
              `
    : css ``}
    ${({ status }) => status
    ? css `
                  margin-left: 15px;
              `
    : css ``}
`;
export const ImgWrapper = styled.div `
    position: relative;
    width: 20px;
    height: 20px;
`;
export const Img = styled.img `
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;
