import styled from "styled-components";
import { Color, IconCheck, IconClose, StyledInputWrapper } from "@boomq/uikit";
import { ActionStyles } from "../../styled/Table";
export const Action = styled.div `
    ${ActionStyles}
    margin-left: 6px;
`;
export const NewSettingsSet = styled.div `
    align-items: center;
    display: flex;
    margin-top: 16px;
    ${StyledInputWrapper} {
        margin-left: 12px;
    }
`;
export const StyledIconCheck = styled(IconCheck) `
    fill: ${Color.blue};
`;
export const StyledIconClose = styled(IconClose) `
    fill: ${Color.blue};
`;
