import { handleActions } from "redux-actions";
import { getValueByPath } from "@boomq/utils";
import { getMinutes, getTimeZoneHours } from "../../helpers/date";
import { SELECTION_TYPE, SPOILER_NAME, TIME_UNIT } from "../../helpers/slaReport";
import { SlaReportTableDiffType, SlaReportTableValueType, SlaReportType } from "../../helpers/types";
import authorizeActions from "../actions/authorize";
import { addSlaReportChart, addSlaReportTable, applySlaReportSettingsRequest, applySlaReportSettingsSuccess, clearSlaReportProfileData, deleteSlaReportProfileChart, deleteSlaReportProfileTable, getTestListSuccess, saveSlaReportProfileSuccess, slaReportProfileByTestSuccess, slaReportProfileChangeBaselineTestRequest, slaReportProfileChangeFieldByViewType, slaReportProfileChangeParamsField, slaReportProfileSuccess, slaReportProfileTableChangeFieldRequest } from "../actions/slaReportProfile";
const { logout } = authorizeActions;
export const initialState = {
    charts: {},
    clientTestList: [],
    currentSettingsFilters: {
        selectionTypeFilter: SELECTION_TYPE[0],
        timeUnitFilter: TIME_UNIT[0]
    },
    excludedTestIdsFilter: [],
    name: `untitled_profile_${getTimeZoneHours()}:${getMinutes()}`,
    openedSpoilerName: SPOILER_NAME[0],
    searchClientTestList: [],
    settingsFilters: {},
    slaTypeList: [],
    tables: {},
    testsLabelList: []
};
export default handleActions({
    [logout]: () => (Object.assign({}, initialState)),
    [addSlaReportChart]: (state, action) => (Object.assign(Object.assign({}, state), { charts: Object.assign({ [action.payload]: { slaTypes: [], type: SlaReportType.Chart } }, state.charts) })),
    [addSlaReportTable]: (state, action) => (Object.assign(Object.assign({}, state), { tables: Object.assign({ [action.payload]: {
                diffType: SlaReportTableDiffType.Absolute,
                slaTypes: [],
                type: SlaReportType.Table,
                valueType: { id: SlaReportTableValueType.Value, value: SlaReportTableValueType.Value }
            } }, state.tables) })),
    [applySlaReportSettingsRequest]: (state, action) => (Object.assign(Object.assign({}, state), { settingsFilters: action.payload })),
    [applySlaReportSettingsSuccess]: (state, action) => (Object.assign(Object.assign({}, state), action.payload)),
    [clearSlaReportProfileData]: () => (Object.assign({}, initialState)),
    [deleteSlaReportProfileChart]: (state, action) => {
        const newCharts = state.charts;
        delete newCharts[action.payload];
        return Object.assign(Object.assign({}, state), { charts: Object.assign({}, newCharts) });
    },
    [deleteSlaReportProfileTable]: (state, action) => {
        const newTables = state.tables;
        delete newTables[action.payload];
        return Object.assign(Object.assign({}, state), { tables: Object.assign({}, newTables) });
    },
    [getTestListSuccess]: (state, { payload = {} }) => (Object.assign(Object.assign({}, state), payload)),
    [saveSlaReportProfileSuccess]: (state, { payload }) => (Object.assign(Object.assign({}, state), { charts: Object.keys(state.charts).reduce((res, chartIndex) => (Object.assign(Object.assign({}, res), { [chartIndex]: Object.assign(Object.assign({}, state.charts[chartIndex]), { chartId: getValueByPath(payload, `chartIds.${chartIndex}`) }) })), {}), tables: Object.keys(state.tables).reduce((res, tableIndex) => (Object.assign(Object.assign({}, res), { [tableIndex]: Object.assign(Object.assign({}, state.tables[tableIndex]), { tableId: getValueByPath(payload, `tableIds.${tableIndex}`) }) })), {}) })),
    [slaReportProfileByTestSuccess]: (state, action) => (Object.assign(Object.assign({}, state), action.payload)),
    [slaReportProfileChangeBaselineTestRequest]: (state, action) => {
        const { tableIndex, value } = action && action.payload ? action.payload : {};
        return tableIndex && value
            ? Object.assign(Object.assign({}, state), { tables: Object.assign(Object.assign({}, state.tables), { [tableIndex]: Object.assign(Object.assign({}, state.tables[tableIndex]), { baselineTestId: value }) }) }) : state;
    },
    [slaReportProfileChangeParamsField]: (state, action) => {
        const { field, subfield, value } = action && action.payload ? action.payload : {};
        return field && subfield
            ? Object.assign(Object.assign({}, state), { [field]: Object.assign(Object.assign({}, state[field]), { [subfield]: value }) }) : Object.assign(Object.assign({}, state), { [field]: value });
    },
    [slaReportProfileChangeFieldByViewType]: (state, action) => {
        const { field, subfield, value, type } = action && action.payload ? action.payload : {};
        return field && subfield
            ? Object.assign(Object.assign({}, state), { [type]: Object.assign(Object.assign({}, state[type]), { [field]: Object.assign(Object.assign({}, state[type][field]), { [subfield]: value }) }) }) : Object.assign(Object.assign({}, state), { [type]: Object.assign(Object.assign({}, state[type]), { [field]: value }) });
    },
    [slaReportProfileTableChangeFieldRequest]: (state, action) => {
        const { field, subfield, value } = action && action.payload ? action.payload : {};
        return field && subfield
            ? Object.assign(Object.assign({}, state), { tables: Object.assign(Object.assign({}, state.tables), { [field]: Object.assign(Object.assign({}, state.tables[field]), { [subfield]: value }) }) }) : Object.assign(Object.assign({}, state), { tables: Object.assign(Object.assign({}, state.tables), { [field]: value }) });
    },
    [slaReportProfileSuccess]: (state, action) => (Object.assign(Object.assign({}, state), action.payload))
}, initialState);
const getStateParam = (state, paramName) => state && state.slaReportProfile ? state.slaReportProfile[paramName] : initialState[paramName];
export const getClientTestDuration = (state) => getValueByPath(getClientTestList(state), "0.totalDuration", 0);
export const getClientTestList = (state) => getStateParam(state, "clientTestList");
export const getSearchClientTestList = (state) => getStateParam(state, "searchClientTestList");
export const getSlaReportCharts = (state) => getStateParam(state, "charts");
export const getSlaReportExcludedTestIdsFilter = (state) => getStateParam(state, "excludedTestIdsFilter");
export const getSlaReportItemIndexList = (state) => {
    const charts = getSlaReportCharts(state);
    const tables = getSlaReportTables(state);
    return [...Object.keys(charts), ...Object.keys(tables)].sort((a, b) => b - a);
};
export const getSlaReportTables = (state) => getStateParam(state, "tables");
export const getSlaReportChartById = (state, chartIndex) => chartIndex ? getStateParam(state, "charts")[chartIndex] : {};
export const getSlaReportTableById = (state, tableIndex) => tableIndex ? getStateParam(state, "tables")[tableIndex] : {};
export const getSlaReportProfileSettingsFilters = (state) => getStateParam(state, "settingsFilters");
export const getSlaReportProfileCurrentSettingsFilters = (state) => getStateParam(state, "currentSettingsFilters");
export const getSlaReportFilters = (state) => (Object.assign({ excludedTestIdsFilter: getSlaReportExcludedTestIdsFilter(state) }, getSlaReportProfileSettingsFilters(state)));
export const getSlaReportTestsLabelList = (state) => getStateParam(state, "testsLabelList");
export const getSlaReportOpenedSpoilerName = (state) => getStateParam(state, "openedSpoilerName");
export const getSlaReportProfileName = (state) => getStateParam(state, "name");
export const getSlaReportSlaTypeList = (state) => getStateParam(state, "slaTypeList");
