import { DEFAULT_HTTP1_UPGRADE_VALUE } from "./constants";
import { prepareRequestData } from "./threadGroupSampler";
import { formatHttpRequestParams, getHttpRequestIndividualProps } from "./threadGroupSamplerHttp";
import { getTimersFromRequest } from "./timers";
import { getThreadGroupElementName } from "../threadGroup";
import { SamplerType } from "../../models/threadGroupEditor";
const getHttp2RequestIndividualProps = ({ individualProperties }) => (Object.assign(Object.assign({}, getHttpRequestIndividualProps({ individualProperties })), { http1Upgrade: (individualProperties === null || individualProperties === void 0 ? void 0 : individualProperties.hhtp1Upgrade) || DEFAULT_HTTP1_UPGRADE_VALUE }));
export const formatHttp2RequestParams = (params) => (Object.assign(Object.assign({}, formatHttpRequestParams(params)), { controllerType: SamplerType.Http2, http1Upgrade: params.hhtp1Upgrade }));
export const parseHttp2RequestParams = (request, groupId) => {
    const { body, headers, http1Upgrade, method, params, requestUrl } = getHttp2RequestIndividualProps(request);
    const requestName = getThreadGroupElementName(request);
    return formatHttp2RequestParams(Object.assign(Object.assign({}, request), { body,
        groupId,
        headers,
        http1Upgrade,
        method,
        params,
        requestName,
        requestUrl }));
};
export const parseHttp2Request = ({ res, groupElement, groupId, parentElementId, path }) => {
    const requestTimers = getTimersFromRequest(groupElement, groupId);
    const parsedElementData = parseHttp2RequestParams(Object.assign(Object.assign({}, groupElement), { timerList: Object.keys(requestTimers) }), groupId);
    return prepareRequestData({
        res,
        groupElement,
        parentElementId,
        parsedElementData,
        path,
        requestTimers
    });
};
