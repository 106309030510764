var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { PassInput } from "../AuthorizeFormInputs/styled";
import { AuthErrorMessage, AuthForm, AuthInput, AuthLabel } from "../../styled/Auth";
export const ChangePasswordForm = (_a) => {
    var { children, confirmPassword, confirmPassInputPlaceholder, errorConfirmPass, errorPass, onChangeConfirmPass, onChangePassword, onSubmit, password, passwordInputPlaceholder, passwordTooltip, type, className } = _a, restProps = __rest(_a, ["children", "confirmPassword", "confirmPassInputPlaceholder", "errorConfirmPass", "errorPass", "onChangeConfirmPass", "onChangePassword", "onSubmit", "password", "passwordInputPlaceholder", "passwordTooltip", "type", "className"]);
    return (React.createElement(AuthForm, Object.assign({}, restProps, { onSubmit: onSubmit, className: className }),
        React.createElement(AuthLabel, null,
            React.createElement(PassInput, { isError: errorPass, name: "password", value: password, onChange: onChangePassword, type: "password", passwordTooltip: passwordTooltip, placeholder: passwordInputPlaceholder }),
            errorPass && React.createElement(AuthErrorMessage, null, errorPass)),
        React.createElement(AuthLabel, null,
            React.createElement(AuthInput, { isError: errorConfirmPass, name: "confirmPassword", value: confirmPassword, onChange: onChangeConfirmPass, type: "password", placeholder: confirmPassInputPlaceholder }),
            errorConfirmPass && React.createElement(AuthErrorMessage, null, errorConfirmPass)),
        children));
};
