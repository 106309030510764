import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getTestProjectSettingsField, newTestActions } from "@boomq/common";
import { LoadTypeSettings } from "../../../components/account/LoadTypeSettings";

const { initLoadTypeSettings, testProjectChangeLoadProfileParams } = newTestActions;

const connector = connect(
    (state, ownProps) => ({
        chartData: ownProps.chartData || getTestProjectSettingsField(state, "chartData")
    }),
    { onChange: testProjectChangeLoadProfileParams, onInit: initLoadTypeSettings }
);

const LoadTypeSettingsContainer = ({ onInit, ...restProps }) => {
    useEffect(() => {
        const init = () => onInit();

        init();
    }, []);

    return <LoadTypeSettings {...restProps} />;
};

export default withRouter(connector(LoadTypeSettingsContainer));
