import styled from "styled-components";
import { Help } from "@boomq/uikit";
import { media } from "../../styled/response";
export const Button = styled.button `
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    outline: none;
    border: 2px solid #e0e0e0;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    color: #4989d6;
    background: #ffffff;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    transition: transform 0.1s ease-in-out;
    &:active {
        transform: translateY(-50%) scale(0.9);
    }
    &:first-child {
        left: 0;
    }
    &:last-child {
        right: 0;
    }
`;
export const HandleValue = styled.div `
    color: #4989d6;
    font-size: 12px;
    font-weight: 600;
    line-height: normal;
    position: absolute;
    top: -20px;
`;
export const ItemWrapper = styled.div `
    display: flex;
    align-items: center;
    margin-top: 20px;
    user-select: none;
    &:first-child {
        margin-top: 0;
    }
    .rc-slider-rail {
        height: 2px;
        top: 50%;
        transform: translateY(-50%);
    }
    .rc-slider-track {
        background: #4989d6;
    }
    .rc-slider-handle {
        width: 16px;
        height: 16px;
        margin-left: -8px;
        margin-top: -6px;
        border: 3px solid #4989d6;
    }
    .rc-slider-dot {
        display: none;
    }
    .rc-slider-mark-text {
        font-weight: 600;
        line-height: normal;
        font-size: 12px;
        color: #bdbdbd;
    }
    .rc-slider-mark-text {
        margin: 0 !important;
        width: min-content !important;
        transform: translateX(-50%);
    }
    ${media.tablet} {
        flex-direction: column;
        align-items: flex-start;
    }
`;
export const SliderWrapper = styled.div `
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 25px;
`;
export const StyledHelp = styled(Help) `
    margin-left: 8px;
`;
export const TextBlock = styled.div `
    align-items: center;
    color: #396496;
    display: flex;
    flex: auto;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    > span {
        margin-right: 3px;
    }
    ${media.tablet} {
        padding-left: 20px;
    }
`;
export const Wrapper = styled.div `
    flex: auto;
    display: flex;
    align-items: center;
    width: 100%;
    ${media.tablet} {
        margin-top: 5px;
    }
`;
export const Units = styled.span `
    font-size: 12px;
`;
