import React, { useState } from "react";
import { connect } from "react-redux";
import { deepCompare, useIntl } from "@boomq/utils";
import { InputNumber } from "@boomq/uikit";
import { ButtonSection, customHintTheme, customSelectTheme, ModalFormBodySettings, SaveButtonSection, Setting, Settings, Text } from "./styled";
import { StyledButton } from "../../../styled/Buttons";
import { CustomSelect } from "../../../common/CustomSelect";
import { Hint } from "../../../common/Hint";
import { Switcher } from "../../../common/Switcher";
import newTestActions from "../../../../redux/actions/newTest";
import { showTestProjectNotification, hideTestProjectNotification } from "../../../../redux/actions/testProjectNotification";
import { getDefaultTimerSettings, getRequestsCount } from "../../../../redux/reducers/newTest";
import { getTimerInputUnit, getTimerTypeItemsByParamName, getTimerTypeValuesByType, getTimerValueByType, getTimerValueParams } from "../../../../helpers/requestTimers";
import { RequestTimerParams } from "../../../../helpers/types";
import { requestTimerValidator } from "../../../../helpers/validators";
import { requestTimersSettingsNotification } from "../../../../data/content/notifications";
import warning from "../../../../assets/images/icons/warn.svg";
import { getTimerType } from "../../../../models/requestTimer";
const { testProjectChangeParamsField } = newTestActions;
const connector = connect((state) => ({
    defaultTimerSettings: getDefaultTimerSettings(state),
    requestsCount: getRequestsCount(state)
}), {
    showTestProjectNotification,
    hideTestProjectNotification,
    testProjectChangeParamsField
});
const RequestDefaultTimerSettingsForm = (props) => {
    const { defaultTimerSettings, requestsCount } = props || {};
    const { enabled } = defaultTimerSettings;
    const { formatMessage } = useIntl();
    const [errorData, setErrorData] = useState(requestTimerValidator(getTimerValueByType(defaultTimerSettings, 0)));
    const [isEnabledDefaultTimer, setIsEnabledDefaultTimer] = useState(enabled);
    const [timerType, setTimerType] = useState(getTimerType(defaultTimerSettings));
    const [timerValues, setTimerValues] = useState(getTimerValueByType(defaultTimerSettings, 0));
    const applyTimersSettings = () => {
        props.testProjectChangeParamsField({
            field: "defaultTimer",
            value: Object.assign(Object.assign({ enabled: isEnabledDefaultTimer }, getTimerTypeValuesByType(timerType)), getTimerValueByType(Object.assign(Object.assign({}, timerValues), { [RequestTimerParams.Type]: timerType }), 0))
        });
        isExistModification() &&
            props.showTestProjectNotification(requestTimersSettingsNotification({
                action: props.hideTestProjectNotification,
                timersCount: requestsCount
            }));
    };
    const changeTimerType = (type) => {
        setTimerType(type);
        setTimerValues(getTimerValueByType(Object.assign(Object.assign({}, defaultTimerSettings), { [RequestTimerParams.Type]: type }), 0));
    };
    const changeTimerValue = (paramName, paramValue) => {
        const newValues = Object.assign(Object.assign({}, timerValues), { [paramName]: paramValue });
        setErrorData(requestTimerValidator(Object.assign(Object.assign({}, newValues), { enabled: isEnabledDefaultTimer, [RequestTimerParams.Type]: timerType })));
        setTimerValues(Object.assign(Object.assign({}, timerValues), { [paramName]: paramValue }));
    };
    const isExistModification = () => enabled !== isEnabledDefaultTimer ||
        getTimerType(defaultTimerSettings) !== timerType ||
        !deepCompare(timerValues, getTimerValueByType(defaultTimerSettings, 0));
    const clickApplyHandler = () => (isExistModification() ? applyTimersSettings() : undefined);
    const getError = (errorData) => (errorData ? errorData.error : undefined);
    const getIsValid = (errorData) => (errorData ? errorData.isValid : true);
    const getPlaceholder = (timerType, valueInputName) => {
        const timerTypeMap = getTimerTypeItemsByParamName(RequestTimerParams.Type).find((type) => type.value === timerType);
        const placeholder = timerTypeMap && timerTypeMap.placeholder;
        return placeholder && placeholder[valueInputName] && placeholder[valueInputName].id
            ? formatMessage(placeholder[valueInputName])
            : "";
    };
    const getTimerValueWithTextBlockByType = (timer) => getTimerValueParams(timer).map((timerValueParamName) => (React.createElement(Setting, { key: `${timer.id}_${timerValueParamName}` },
        React.createElement(Text, null, formatMessage({
            defaultMessage: timerValueParamName,
            id: `account.modal.default.timer.settings.${timerValueParamName}.text`
        })),
        React.createElement(InputNumber, { decimalCount: 2, enabled: isEnabledDefaultTimer, error: getError(errorData), name: `Timer_${timerValueParamName}`, onChange: (value) => changeTimerValue(timerValueParamName, value), placeholder: getPlaceholder(getTimerType(timer), timerValueParamName), unit: getTimerInputUnit(), value: timer[timerValueParamName] }))));
    return (React.createElement(ModalFormBodySettings, null,
        React.createElement(Settings, null,
            React.createElement(Setting, null,
                React.createElement(Text, null, formatMessage({
                    defaultMessage: "On/off default timer",
                    id: "account.modal.default.timer.settings.enabled.text"
                })),
                React.createElement(Switcher, { defaultChecked: isEnabledDefaultTimer, name: "enabled", onClick: () => setIsEnabledDefaultTimer(!isEnabledDefaultTimer) })),
            React.createElement(Setting, null,
                React.createElement(Text, null, formatMessage({
                    defaultMessage: "Timer type",
                    id: "account.modal.default.timer.settings.type.text"
                })),
                React.createElement(CustomSelect, { defaultValue: timerType, disabled: !isEnabledDefaultTimer, items: getTimerTypeItemsByParamName(RequestTimerParams.Type), onChange: (item) => changeTimerType(item.value), theme: customSelectTheme })),
            getTimerValueWithTextBlockByType(Object.assign({ [RequestTimerParams.Type]: timerType }, timerValues))),
        React.createElement(SaveButtonSection, null,
            React.createElement(ButtonSection, null,
                React.createElement(StyledButton, { className: "close_modal_window", disabled: !getIsValid(errorData), onClick: clickApplyHandler }, formatMessage({
                    defaultMessage: "Apply",
                    id: "account.modal.timers.settings.button"
                })),
                !getIsValid(errorData) && (React.createElement(Hint, { icon: warning, theme: customHintTheme, tooltipText: getError(errorData) }))))));
};
export default connector(RequestDefaultTimerSettingsForm);
