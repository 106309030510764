var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { Select } from "@boomq/uikit";
import { CompareButton } from "./CompareButton";
import { Filters, PanelRow, PanelWrapperWithMarginTop } from "./styled";
import { StatusLegend } from "../StatusLegend";
import { SelectionType } from "../../../helpers/types";
export const AllTestsPanel = (_a) => {
    var { filtersData = {}, onChangeTestProjectFilter, onChangeTestLabelFilter, onChangeTestStatusFilter, onChangeTestUserFilter, onClearTestLabelFilter, onClearTestProjectFilter, onClearTestStatusFilter, onClearTestUserFilter, statuses, testLabels, testLabelSelectPlaceholder, testProjects, testProjectSelectPlaceholder, testStatusItems, testStatusSelectPlaceholder, testUserSelectPlaceholder, testUsers } = _a, restProps = __rest(_a, ["filtersData", "onChangeTestProjectFilter", "onChangeTestLabelFilter", "onChangeTestStatusFilter", "onChangeTestUserFilter", "onClearTestLabelFilter", "onClearTestProjectFilter", "onClearTestStatusFilter", "onClearTestUserFilter", "statuses", "testLabels", "testLabelSelectPlaceholder", "testProjects", "testProjectSelectPlaceholder", "testStatusItems", "testStatusSelectPlaceholder", "testUserSelectPlaceholder", "testUsers"]);
    return (React.createElement(PanelWrapperWithMarginTop, null,
        React.createElement(PanelRow, null,
            React.createElement(Filters, null,
                React.createElement(Select, { isShowClear: true, items: testProjects, label: testProjectSelectPlaceholder, name: "testProjectFilter", onClear: onClearTestProjectFilter, onChange: onChangeTestProjectFilter, placeholder: testProjectSelectPlaceholder, value: filtersData.testProjectFilter }),
                React.createElement(Select, { isShowClear: true, items: testStatusItems, label: testStatusSelectPlaceholder, name: "testStatusFilter", onClear: onClearTestStatusFilter, onChange: onChangeTestStatusFilter, placeholder: testStatusSelectPlaceholder, value: filtersData.testStatusFilter }),
                React.createElement(Select, { isShowClear: true, items: testLabels, label: testLabelSelectPlaceholder, name: "testLabelFilter", onClear: onClearTestLabelFilter, onChange: onChangeTestLabelFilter, placeholder: testLabelSelectPlaceholder, selectionType: SelectionType.Multiple, textAttrName: "name", titleAttrName: "name", valueAttrName: "name", value: filtersData.testLabelFilter }),
                React.createElement(Select, { isShowClear: true, items: testUsers, label: testUserSelectPlaceholder, name: "testUserFilter", onClear: onClearTestUserFilter, onChange: onChangeTestUserFilter, placeholder: testUserSelectPlaceholder, selectionType: SelectionType.Multiple, value: filtersData.testUserFilter })),
            React.createElement(CompareButton, Object.assign({}, restProps))),
        React.createElement(StatusLegend, { statuses: statuses })));
};
