var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getUniqueId, useIntl } from "@boomq/utils";
import { DEFAULT_COOKIES_TITLE } from "./intl";
import { CLEAR_EACH_ITERATION_SWITCHER_LABEL, DEFAULT_COOKIE_DOMAIN_PLACEHOLDER, DEFAULT_COOKIE_NAME_PLACEHOLDER, DEFAULT_COOKIE_PATH_PLACEHOLDER, DEFAULT_COOKIE_VALUE_PLACEHOLDER, HTTPS_ONLY_CHECKBOX_LABEL } from "../../../../components/account/DefaultCookie";
import { withResize } from "../../../../components/styled/response";
import { DefaultCookies } from "../../../../components/account/TestProjectSettingsSet/DefaultCookies";
import { NEW_ITEM_ID } from "../../../../helpers/routes";
import { cookieValidatorByRequiredFields } from "../../../../helpers/validators";
import { addCookie, changeCookie, changeCookieParamsField, deleteCookie } from "../../../../redux/actions/testProjectSettingsSetCookies";
import { getRequestGlobalParametersList } from "../../../../redux/reducers/requestParameters";
import { getSettingsSetDefaultCookieItems, getSettingsSetDefaultCookiesClearEachIteration } from "../../../../redux/reducers/testProjectSettingsSetCookies";
const generateDefaultCookieNewItem = () => ({
    id: `${NEW_ITEM_ID}_${getUniqueId()}`,
    domain: "",
    name: "",
    path: "",
    secure: false,
    value: ""
});
const getDefaultCookies = (state) => [
    ...getSettingsSetDefaultCookieItems(state),
    generateDefaultCookieNewItem()
];
const connector = connect((state) => ({
    clearEachIteration: getSettingsSetDefaultCookiesClearEachIteration(state),
    defaultCookies: getDefaultCookies(state),
    parameterList: getRequestGlobalParametersList(state)
}), { onAdd: addCookie, onChangeItem: changeCookie, onChangeParam: changeCookieParamsField, onDelete: deleteCookie });
const DefaultCookiesContainer = (_a) => {
    var { onAdd, onChangeItem, onChangeParam, onDelete } = _a, restProps = __rest(_a, ["onAdd", "onChangeItem", "onChangeParam", "onDelete"]);
    const { formatMessage } = useIntl();
    const changeHandler = (data, field, value) => {
        const { validatedData } = cookieValidatorByRequiredFields(Object.assign(Object.assign({}, data), { [field]: value }));
        return validatedData.id.includes(NEW_ITEM_ID)
            ? onAdd(Object.assign(Object.assign({}, validatedData), { id: getUniqueId() }))
            : onChangeItem(validatedData);
    };
    const deleteHandler = (id) => onDelete(id);
    const toggleClearEachIterationHandler = () => onChangeParam({ field: "clearEachIteration", value: !restProps.clearEachIteration });
    const clearEachIterationSwitcherLabel = formatMessage(CLEAR_EACH_ITERATION_SWITCHER_LABEL);
    const httpsOnlyCheckboxLabel = formatMessage(HTTPS_ONLY_CHECKBOX_LABEL);
    const placeholderDomain = formatMessage(DEFAULT_COOKIE_DOMAIN_PLACEHOLDER);
    const placeholderName = formatMessage(DEFAULT_COOKIE_NAME_PLACEHOLDER);
    const placeholderPath = formatMessage(DEFAULT_COOKIE_PATH_PLACEHOLDER);
    const placeholderValue = formatMessage(DEFAULT_COOKIE_VALUE_PLACEHOLDER);
    const title = formatMessage(DEFAULT_COOKIES_TITLE);
    return (React.createElement(DefaultCookies, Object.assign({}, restProps, { clearEachIterationSwitcherLabel: clearEachIterationSwitcherLabel, httpsOnlyCheckboxLabel: httpsOnlyCheckboxLabel, onChangeItem: changeHandler, onDelete: deleteHandler, onToggleClearEachIteration: toggleClearEachIterationHandler, placeholderDomain: placeholderDomain, placeholderName: placeholderName, placeholderPath: placeholderPath, placeholderValue: placeholderValue, title: title })));
};
export default withResize(withRouter(connector(DefaultCookiesContainer)));
