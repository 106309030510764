import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { preventZoom } from "@boomq/utils";
import { history } from "@boomq/common";
// ToDo: replace to common
import './assets/index.css';
import App from "./components/App";
import store from "./redux/index";

preventZoom();

const domElement = document.getElementById("root");

const ReactElement = () => (
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <App />
        </ConnectedRouter>
    </Provider>
);

if (domElement?.hasChildNodes()) {
    ReactDOM.hydrate(<ReactElement />, domElement);
} else {
    ReactDOM.render(<ReactElement />, domElement);
}
