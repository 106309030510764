import * as React from "react";
import { connect } from "react-redux";
import RcPagination from "rc-pagination";
import en from "rc-pagination/es/locale/en_GB";
import ru from "rc-pagination/es/locale/ru_RU";
import "./styles.css";
import { ArrowImage, Button, StyledPagination } from "./styled";
import arrow from "../../../assets/images/icons/arrow.svg";
import { getCurrentLanguage } from "../../../redux/reducers/languagePlate";
const connector = connect((state) => ({
    lang: getCurrentLanguage(state)
}));
const locale = { ru, en };
const Pagination = ({ totalPages, clickHandler, pageSize = 10, currentPage, lang }) => {
    const itemRender = (current, type) => {
        switch (type) {
            case "prev":
                return (React.createElement(Button, { isDisabled: currentPage === 0 },
                    React.createElement(ArrowImage, { src: arrow, left: "true" })));
            case "next":
                return (React.createElement(Button, { isDisabled: currentPage + 1 === totalPages },
                    React.createElement(ArrowImage, { src: arrow, right: "true" })));
            case "jump-prev":
            case "jump-next":
                return React.createElement(Button, null, "...");
            default:
                return React.createElement(Button, { isDisabled: currentPage + 1 === current }, current);
        }
    };
    return (React.createElement(StyledPagination, null,
        React.createElement(RcPagination, { onChange: (page) => clickHandler(page - 1), current: currentPage + 1, defaultPageSize: 5, pageSize: pageSize, total: totalPages * pageSize, itemRender: itemRender, locale: locale[lang] })));
};
export default connector(Pagination);
