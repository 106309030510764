import { getUniqueId, getValueByPath, isEmpty, objectToArray } from "@boomq/utils";
import { parseAssertions } from "./assertions";
import { parseExtractors } from "./extractors";
import { generateRequestUrlFromParams, prepareRequestData } from "./threadGroupSampler";
import { getTimersFromRequest, prepareTimerList } from "./timers";
import { convertMsToSec } from "../date";
import { generateBodyPreview, getThreadGroupElementName } from "../threadGroup";
import { GrpcMethod } from "../../models/request";
import { SamplerType, ThreadGroupController } from "../../models/threadGroupEditor";
const getParamsFromRequestUrl = (individualProperties) => {
    const protocol = getValueByPath(individualProperties, "protocol", "");
    const requestUrl = generateRequestUrlFromParams(Object.assign(Object.assign({}, individualProperties), { protocol: "" }));
    return protocol.toLowerCase() === "https" ? { isTls: true, requestUrl } : { isTls: false, requestUrl };
};
const getGrpcRequestIndividualProps = ({ individualProperties }) => (Object.assign({ body: { TEXT: getValueByPath(individualProperties, "body", "") }, deadline: getValueByPath(individualProperties, "deadline", ""), fullMethod: getValueByPath(individualProperties, "fullMethod", {}), metadata: getValueByPath(individualProperties, "metadata", {}), method: getValueByPath(individualProperties, "method", GrpcMethod.Unary), protoFiles: getValueByPath(individualProperties, "protoFiles", "") }, getParamsFromRequestUrl(individualProperties)));
export const formatGrpcRequestParams = ({ assertions, body, deadline, extractors, fullMethod, groupId, id, isTls, metadata, method, requestName, requestUrl, responsePreview, timerList }) => ({
    id: id || getUniqueId(),
    assertions: parseAssertions(assertions),
    body,
    bodyPreview: generateBodyPreview(body),
    controllerType: SamplerType.Grpc,
    deadline: convertMsToSec(deadline),
    extractResponse: parseExtractors(extractors),
    fullMethod,
    groupId,
    isCopyRequestNameFromUrl: requestName === requestUrl || isEmpty(requestName),
    isTls,
    metadata: metadata ? objectToArray(metadata, "key", "value") : [],
    method,
    requestUrl,
    responsePreview,
    timerList: prepareTimerList(timerList),
    type: ThreadGroupController.Sampler
});
export const parseGrpcRequestParams = (request, groupId) => {
    const individualPropertiesData = getGrpcRequestIndividualProps(request);
    const requestName = getThreadGroupElementName(request);
    return formatGrpcRequestParams(Object.assign(Object.assign(Object.assign({}, request), individualPropertiesData), { groupId,
        requestName }));
};
export const parseGrpcRequest = ({ res, groupElement, groupId, parentElementId, path }) => {
    const requestTimers = getTimersFromRequest(groupElement, groupId);
    const parsedElementData = parseGrpcRequestParams(Object.assign(Object.assign({}, groupElement), { timerList: Object.keys(requestTimers) }), groupId);
    return prepareRequestData({
        res,
        groupElement,
        parentElementId,
        parsedElementData,
        path,
        requestTimers
    });
};
