import React from "react";
import { TypeSelection as CommonTypeSelection } from "@boomq/common";
import { LoadTypeSettingsContainer } from "../../../containers/account/LoadTypeSettings";

const TypeSelection = (props) => (
    <CommonTypeSelection {...props}>
        <LoadTypeSettingsContainer disabled={props.disabledSettings} {...props}/>
    </CommonTypeSelection>
);

export default TypeSelection;
