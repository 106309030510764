var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useReducer, useState } from "react";
import { useIntl } from "@boomq/utils";
import { DEFAULT_ACTIVE_TAB_NAME } from "./constants";
import { ADD_SLA_BUTTON_LABEL, SAVE_BUTTON_LABEL } from "./intl";
import { reducer } from "./reducer";
import { SlaForm } from "../../../components/account/SlaForm";
import { formatIntlMessage } from "../../../helpers/intl";
import { slaItemsValidator } from "../../../helpers/validators";
import { Mode, slaTabList } from "../../../models/sla";
const isDisabledByMode = (mode) => mode === Mode.View;
const isShowSlaResultByMode = (mode) => [Mode.EditResult, Mode.View].includes(mode);
const getActiveTabName = (defaultActiveTabName) => defaultActiveTabName ? defaultActiveTabName : DEFAULT_ACTIVE_TAB_NAME;
const SlaFormContainer = (_a) => {
    var { defaultActiveTabName, mode, onSave, testDuration, testResultSlaState, testType } = _a, restProps = __rest(_a, ["defaultActiveTabName", "mode", "onSave", "testDuration", "testResultSlaState", "testType"]);
    const { formatMessage } = useIntl();
    const [slaData, dispatch] = useReducer(reducer, restProps.slaData);
    const [targetType, setTargetType] = useState(getActiveTabName(defaultActiveTabName));
    useEffect(() => {
        dispatch({ type: "set", payload: restProps.slaData });
    }, [restProps.slaData]);
    const formattedSlaTabItems = slaTabList.map((slaTabItem) => (Object.assign(Object.assign({}, slaTabItem), { component: Object.assign(Object.assign({}, slaTabItem.component), { props: {
                isDisabled: isDisabledByMode(mode),
                isShowSlaResult: isShowSlaResultByMode(mode),
                onDispatchAction: dispatch,
                slaData,
                testDuration,
                testResultSlaState,
                testType
            } }), title: formatIntlMessage(slaTabItem.title, formatMessage) })));
    const addSlaItemHandler = () => dispatch({ type: "add", payload: { type: targetType, testDuration } });
    const changeTabHandler = (tabName) => setTargetType(tabName);
    const setInvalidSlaData = ({ invalidSlaTargetType, validatedSlaData }) => {
        dispatch({ type: "set", payload: Object.assign({}, validatedSlaData) });
        return invalidSlaTargetType ? setTargetType(invalidSlaTargetType) : undefined;
    };
    const setTestProjectSla = () => (typeof onSave === "function" ? onSave(slaData) : undefined);
    const clickSaveHandler = () => {
        const _a = slaItemsValidator(slaData), { isValid } = _a, restData = __rest(_a, ["isValid"]);
        return isValid ? setTestProjectSla() : setInvalidSlaData(restData);
    };
    const addSlaButtonLabel = formatIntlMessage(ADD_SLA_BUTTON_LABEL, formatMessage);
    const saveButtonLabel = formatIntlMessage(SAVE_BUTTON_LABEL, formatMessage);
    return (React.createElement(SlaForm, { addSlaButtonLabel: addSlaButtonLabel, isShowAddSlaButton: mode !== Mode.View, isShowSaveButton: mode !== Mode.View, onChangeTab: changeTabHandler, onClickAddSla: addSlaItemHandler, onClickSave: clickSaveHandler, saveButtonLabel: saveButtonLabel, slaTabItems: formattedSlaTabItems, targetType: targetType }));
};
export default SlaFormContainer;
