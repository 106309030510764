import React from "react";
import { SpoilerDefaultArrow } from "@boomq/uikit";
import { SpoilerHeader, StyledIconDelete } from "./styled";
export const ChartSerieParamsHeader = ({ displayProps, id, isOpened = false, name, onClickDeleteIcon, onClickHeader }) => {
    const clickDeleteIconHandler = () => onClickDeleteIcon(id);
    const clickHeaderHandler = () => onClickHeader(id);
    return (React.createElement(SpoilerHeader, { onClick: clickHeaderHandler, titleColor: displayProps === null || displayProps === void 0 ? void 0 : displayProps.color },
        name,
        " ",
        React.createElement(StyledIconDelete, { onClick: clickDeleteIconHandler }),
        React.createElement(SpoilerDefaultArrow, { isopened: isOpened.toString(), onClick: clickHeaderHandler })));
};
