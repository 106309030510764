export var Color;
(function (Color) {
    Color["black"] = "#000";
    Color["blue"] = "#176ED6";
    Color["darkBlue60"] = "#0e4280";
    Color["darkBlue80"] = "#1258ab";
    Color["darkGrey5"] = "#142c4a";
    Color["darkGrey35"] = "#5e6f83";
    Color["darkGrey95"] = "#1e1e1e";
    Color["gold"] = "#F8AB00";
    Color["green"] = "#17D663";
    Color["grey50"] = "#83909f";
    Color["grey65"] = "#a8b1bc";
    Color["grey75"] = "#c1c7cf";
    Color["lightBlue10"] = "#e8f0fb";
    Color["lightBlue30"] = "#b9d3f3";
    Color["lightBlue60"] = "#74a8e6";
    Color["lightGrey85"] = "#dadee2";
    Color["lightGrey95"] = "#ebedf0";
    Color["lightGrey98"] = "#fafbfb";
    Color["orange"] = "#f0ad00";
    Color["red"] = "#ff5959";
    Color["white"] = "#fff";
})(Color || (Color = {}));
