import { Color, FontBodyMediumMedium } from "@boomq/uikit";
import styled from "styled-components";
export const StyledLegend = styled.div `
    display: flex;
    flex-wrap: wrap;
    margin-top: 12px;
`;
export const StyledStatus = styled.div `
    align-items: center;
    display: flex;
    margin-right: 32px;
`;
export const Text = styled.p `
    ${FontBodyMediumMedium}
    color: ${Color.grey50};
    line-height: normal;
    margin-left: 8px;
`;
