import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { Color } from "@boomq/uikit";
import { media } from "../response";
import { StyledButton } from "../Buttons";
import { StyledInput } from "../Input";
import { SectionTitle } from "../Titles";
export const AuthBlock = styled.div `
    background: #fff;
    border-radius: 15px;
    margin-top: 36px;
    max-width: 450px;
    padding: ${({ type }) => (type === "modal" ? "0" : "56px")};
`;
export const AuthSectionTitle = styled(SectionTitle) `
    color: #022349;
    font-size: 40px;
    font-weight: 700;
`;
export const AuthPlateWrapper = styled.div `
    align-items: center;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
`;
export const AuthWrapper = styled.div `
    background: #f4f8ff;
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 600px;
    overflow: auto;
    position: relative;
`;
export const AuthButtonBlock = styled.div `
    margin-top: 20px;
    margin-bottom: 15px;
    ${StyledButton} {
        background: #055ffc;
        border-radius: 7px;
        line-height: 24px;
        padding: 12px 20px;
        width: 100%;
        &:hover {
            background: #022349;
            color: ${Color.white};
        }
    }
`;
const AuthError = styled.p `
    color: #f6331b;
    font-size: 12px;
`;
export const PassRulesErrorMessage = styled(AuthError) `
    margin-top: 20px;
`;
export const AuthErrorMessage = styled(AuthError) `
    position: absolute;
`;
export const AuthInput = styled(StyledInput) `
    border: 1px solid ${(props) => (props && props.isError ? "#ff3000" : "#a2a5ad")};
    border-radius: 7px;
    color: #a2a5ad;
    line-height: 24px;
`;
export const AuthLabel = styled.label `
    position: relative;
    margin-top: 20px;
`;
export const AuthForm = styled.form `
    display: flex;
    flex-direction: column;
    width: 100%;
    ${AuthLabel} {
        &:first-child {
            margin-top: 0;
        }
    }
`;
export const AuthFormLink = styled(Link) `
    color: ${({ color }) => (color ? color : "#677897")};
    cursor: pointer;
    flex: auto;
    font-size: 12px;
    font-style: normal;
    font-weight: ${({ weight }) => (!weight ? "normal" : weight)};
    line-height: 130%;
    text-align: ${({ align }) => (!align ? "center" : align)};
    text-decoration: ${({ decoration }) => (!decoration ? "none" : decoration)};
    &:hover {
        text-decoration: underline;
    }
`;
export const AuthBlockTitle = styled.div `
    align-items: center;
    display: flex;
    flex-direction: column;
    ${({ type }) => type === "modal"
    ? css ``
    : css `
                  padding-top: 70px;
              `}
    position: relative;
    width: 540px;
    ${media.tablet} {
        width: 470px;
    }
    ${media.phone} {
        width: 92%;
    }
`;
export const AuthRootPlateWrapper = styled.div `
    flex: 1 0 auto;
    padding-bottom: 30px;
`;
export const AuthorizeSection = styled.div `
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 56px 0;
    text-align: center;
`;
export const AuthModalFormLink = AuthFormLink.withComponent("span");
export const AuthModalFormSignUpLink = styled(AuthModalFormLink) `
    color: #055ffc;
    font-weight: 600;
`;
export const AuthModalFormRememberPassLink = styled(AuthModalFormSignUpLink) `
    text-align: center;
`;
