var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { GridItem, GridRowCol, GridRowColText, GridRowWithLink } from "@boomq/uikit";
import { gridRowColTheme } from "./styled";
import { Actions } from "./Actions";
import { StyledLabels } from "../AllTestProjectSettingsSetsItem/styled";
import { sizes } from "../../styled/response";
import { ReportsItemAction } from "../../../models/reports";
export const AllReportsItem = (_a) => {
    var { actionItems, emptyLabelsText, gridHeaders, labels, data: { id, createdFrom, name, searchedLabels, updatedByUserEmailIn, updatedFrom }, onClickAction, testIds, windowWidth } = _a, restProps = __rest(_a, ["actionItems", "emptyLabelsText", "gridHeaders", "labels", "data", "onClickAction", "testIds", "windowWidth"]);
    return (React.createElement(GridItem, null,
        React.createElement(GridRowWithLink, { to: `/account/reports/${id}`, windowWidth: windowWidth },
            React.createElement(GridRowCol, { flex: 1.5, theme: gridRowColTheme },
                windowWidth <= sizes.tablet && React.createElement(GridRowColText, null, gridHeaders[0]),
                React.createElement(GridRowColText, null, name)),
            React.createElement(GridRowCol, { flex: 1 },
                windowWidth <= sizes.tablet && React.createElement(GridRowColText, null, gridHeaders[1]),
                React.createElement(StyledLabels, { labels: labels, addLabelMethod: restProps[ReportsItemAction.AddLabel], addLabelWithCreatingMethod: restProps[ReportsItemAction.AddLabelWithCreating], emptyLabelsText: emptyLabelsText, minLength: 3, onDelete: restProps[ReportsItemAction.DeleteLabel], searchLabelsMethod: restProps[ReportsItemAction.SearchLabels], searchedItems: searchedLabels })),
            React.createElement(GridRowCol, { flex: 1 },
                windowWidth <= sizes.tablet && React.createElement(GridRowColText, null, gridHeaders[2]),
                React.createElement(GridRowColText, null, testIds)),
            React.createElement(GridRowCol, { flex: 1 },
                windowWidth <= sizes.tablet && React.createElement(GridRowColText, null, gridHeaders[3]),
                React.createElement(GridRowColText, null, updatedByUserEmailIn)),
            React.createElement(GridRowCol, { flex: 1 },
                windowWidth <= sizes.tablet && React.createElement(GridRowColText, null, gridHeaders[4]),
                React.createElement(GridRowColText, null, updatedFrom)),
            React.createElement(GridRowCol, { flex: 1 },
                windowWidth <= sizes.tablet && React.createElement(GridRowColText, null, gridHeaders[5]),
                React.createElement(GridRowColText, null, createdFrom)),
            React.createElement(Actions, { actionItems: actionItems, onClick: onClickAction }))));
};
