import { DEFAULT_DATE_FORMAT_LOCALE_OPTIONS, formatDateByLocale, getFromLocalStorage, setToLocalStorage } from "@boomq/utils";
import { DATE_FORMAT_LOCALE_LIST, DATE_FORMAT_LOCALE_PARAM_NAME } from "./constants";
export const generateDateFormatSelectItems = () => DATE_FORMAT_LOCALE_LIST.map((localeName) => ({
    id: localeName,
    text: formatDateByLocale(new Date(), localeName, DEFAULT_DATE_FORMAT_LOCALE_OPTIONS),
    value: localeName
}));
export const generateDateFormatLocaleSelectPlaceholderByLocale = (locale) => formatDateByLocale(new Date(), locale, DEFAULT_DATE_FORMAT_LOCALE_OPTIONS);
export const getDateFormatLocaleFromLocalStorage = () => getFromLocalStorage(DATE_FORMAT_LOCALE_PARAM_NAME);
export const setDateFormatLocaleToLocalStorage = (value) => setToLocalStorage(DATE_FORMAT_LOCALE_PARAM_NAME, value);
