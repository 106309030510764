import styled, { css } from "styled-components";
import { Color, FontBodyMediumRegular, InputText } from "@boomq/uikit";
import { InvalidParameterIndicator } from "../InvalidParameterIndicator";
export const usedParameterIndicatorTheme = { marginRight: "5px" };
export const HeaderColumn = styled.td `
    border: 1px solid ${Color.lightGrey85};
    padding: 14px 6px 8px 8px;
    ${({ hasHeaders }) => hasHeaders
    ? css `
                  padding: 18px;
              `
    : css ``}
`;
export const HeaderColumnContent = styled.div `
    align-items: center;
    display: flex;
    position: relative;
`;
export const ParameterName = styled(InputText) `
    background: #fafbfb;
    box-shadow: inset 0 0 0 20px #fafbfb !important;
`;
export const StyledInvalidParameterIndicator = styled(InvalidParameterIndicator) `
    cursor: pointer;
    fill: ${Color.blue};
    margin-left: 4px;
`;
export const Table = styled.table `
    border-collapse: collapse;
    margin-top: 16px;
    width: 100%;
`;
export const TableColumn = styled.td `
    ${FontBodyMediumRegular}
    border: 1px solid ${Color.lightGrey85};
    color: ${Color.darkGrey5};
    padding: 8px 12px;
`;
export const TableHead = styled.tr `
    background: ${Color.lightGrey98};
`;
