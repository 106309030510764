import styled from "styled-components";
import { Color, FontBodyMediumMedium } from "@boomq/uikit";
import { media } from "../../styled/response";
export const FileContentSection = styled.div `
    max-width: 99%;
    overflow: auto;
`;
export const InputSection = styled.div `
    flex: auto;
    margin-left: 12px;
`;
export const Setting = styled.div `
    align-items: center;
    display: flex;
    margin-top: 16px;
    position: relative;
    &:first-child {
        margin-top: 0;
    }
    ${media.tablet} {
        flex-direction: column;
        align-items: flex-start;
    }
`;
export const SettingsLeftSection = styled.div `
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    width: 40%;
`;
export const SettingsRightSection = styled.div `
    display: flex;
    flex-direction: column;
    width: 59%;
`;
export const SettingsSection = styled.div `
    display: flex;
    justify-content: space-between;
`;
export const Text = styled.div `
    ${FontBodyMediumMedium}
    color: ${Color.darkGrey5};
`;
export const RadioButton = styled(Text) `
    margin-left: 12px;
    span {
        margin-left: 4px;
    }
`;
export const SwitcherText = styled(Text) `
    margin-left: 4px;
`;
