import React, { forwardRef } from "react";
import { ButtonType } from "@boomq/uikit";
import { dateFormatSelectListTheme, dateFormatSelectListItemTheme, DateFormatSelect, DateFormatText, IconApiToken, IconDateFormat, IconNotification, InputWithCopy, NotificationSwitcher, NotificationText, ProfileBlockTitle, ProfileButtonWrapper, ProfileDateFormatRow, ProfileLabel, ProfileRow, ProfileUserAccountInfoRow, ProfileUserPrimaryButton, SecretTokenButton, SecretTokenIconCopy, SecretTokenSection, SecretTokenText, SecretTokenValue, StyledCopyButton, UserAccountInfoBlock } from "./styled";
export const ProfileUserAccountInfo = ({ children, dateFormatLocaleLabel, dateFormatLocaleSelectItems, dateFormatLocaleSelectPlaceholder, emailNotification, emailNotificationLabel, isEnabledSecretTokenCopyButton, onChangeDateFormatLocale, onChangeEmailNotification, onCopySecretToken, onReplaceSecretToken, onSave, saveButtonLabel, secretToken, secretTokenLabel, secretTokenReplaceButtonLabel, title }, _ref) => (React.createElement(UserAccountInfoBlock, null,
    React.createElement(ProfileBlockTitle, { as: "h3" }, title),
    children,
    React.createElement(ProfileUserAccountInfoRow, null,
        React.createElement(IconNotification, null),
        React.createElement(NotificationText, null, emailNotificationLabel),
        React.createElement(NotificationSwitcher, { defaultChecked: emailNotification, name: "emailNotification", onChange: onChangeEmailNotification })),
    React.createElement(ProfileRow, null,
        React.createElement(ProfileLabel, null,
            React.createElement(IconApiToken, null),
            React.createElement(SecretTokenText, null, secretTokenLabel)),
        React.createElement(SecretTokenSection, null,
            React.createElement(InputWithCopy, null,
                React.createElement(SecretTokenValue, { ref: _ref, title: secretToken }, secretToken),
                React.createElement(StyledCopyButton, { disabled: !isEnabledSecretTokenCopyButton, onClick: onCopySecretToken },
                    React.createElement(SecretTokenIconCopy, { disabled: !isEnabledSecretTokenCopyButton }))),
            React.createElement(SecretTokenButton, { onClick: onReplaceSecretToken, styletype: ButtonType.outlined }, secretTokenReplaceButtonLabel))),
    React.createElement(ProfileDateFormatRow, null,
        React.createElement(ProfileLabel, null,
            React.createElement(IconDateFormat, null),
            React.createElement(DateFormatText, null, dateFormatLocaleLabel)),
        React.createElement(DateFormatSelect, { themeList: dateFormatSelectListTheme, themeListItem: dateFormatSelectListItemTheme, items: dateFormatLocaleSelectItems || [], placeholder: dateFormatLocaleSelectPlaceholder, onChange: onChangeDateFormatLocale })),
    React.createElement(ProfileButtonWrapper, null,
        React.createElement(ProfileUserPrimaryButton, { onClick: onSave }, saveButtonLabel))));
export default forwardRef(ProfileUserAccountInfo);
