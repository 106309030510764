import { IconGoogleAnalytics, IconGrpc2, IconHttp, IconInsomnia, IconJMeter, IconKafka, IconPostman } from "@boomq/uikit";
export const CARDS_CONTENT = [
    {
        title: {
            defaultMessage: "Create test from scratch",
            id: "account.user.welcome.card.title.create.test"
        },
        text: {
            defaultMessage: "Create scenario-based load test without a single line of code. Use Protobuf or Kafka for a quick start.",
            id: "account.user.welcome.card.text.create.test"
        },
        buttonLabel: {
            defaultMessage: "Create test",
            id: "account.user.welcome.card.button.label.create.test"
        },
        icons: [IconHttp, IconKafka, IconGrpc2]
    },
    {
        title: {
            defaultMessage: "Import requests",
            id: "account.user.welcome.card.title.import.data"
        },
        text: {
            defaultMessage: "Use real user data from Google Analytics, Postman, Insomnia and HAR-files to create a precise load profile for your test.",
            id: "account.user.welcome.card.text.import.data"
        },
        buttonLabel: {
            defaultMessage: "Import data",
            id: "account.user.welcome.card.button.label.import.data"
        },
        icons: [IconGoogleAnalytics, IconPostman, IconInsomnia]
    },
    {
        title: {
            defaultMessage: "Run your own JMeter",
            id: "account.user.welcome.card.title.run.jmeter"
        },
        text: {
            defaultMessage: "Run a test based on the existing test scenarios and workflows from Jmeter.",
            id: "account.user.welcome.card.text.run.jmeter"
        },
        buttonLabel: {
            defaultMessage: "Run JMeter",
            id: "account.user.welcome.card.button.label.run.jmeter"
        },
        icons: [IconJMeter]
    }
];
