import { IconEdit, IconRemove } from "../../components/styled/Icons";
import { TeamMemberPermission, SlaReportProfilesItemAction } from "../types";
const actionsByTeamMemberPermissionEdit = [SlaReportProfilesItemAction.Edit, SlaReportProfilesItemAction.Delete];
const actionsByTeamMemberPermissions = {
    [TeamMemberPermission.Admin]: actionsByTeamMemberPermissionEdit,
    [TeamMemberPermission.Edit]: actionsByTeamMemberPermissionEdit,
    [TeamMemberPermission.Run]: actionsByTeamMemberPermissionEdit
};
const actionIconsByName = {
    [SlaReportProfilesItemAction.Delete]: IconRemove,
    [SlaReportProfilesItemAction.Edit]: IconEdit
};
const getSlaProfilesActionsByTeamMemberPermission = (permission) => {
    const actionsByPermission = actionsByTeamMemberPermissions[permission];
    return actionsByPermission ? actionsByPermission : [];
};
const getSlaProfilesItemActionsByTeamMemberPermissions = (permissions) => [
    ...new Set(permissions.reduce((res, permission) => [
        ...res,
        ...getSlaProfilesActionsByTeamMemberPermission(permission)
    ], []))
];
export const getActionIconByName = (actionName) => actionIconsByName[actionName];
export const getActionItemsByTeamMemberPermissions = (permissions) => getSlaProfilesItemActionsByTeamMemberPermissions(permissions).map((actionName) => ({
    id: actionName,
    actionName
}));
