import React, { useEffect, useState } from "react";
import { Button, ButtonSize, Tooltip } from "@boomq/uikit";
import { IconErrorBlock, StyledIconAlertCircle, StyledPage } from "./styled";
import { Wizard } from "../../../account/TestProjectSettingsSet/Wizard";
import { BackLinkButton } from "../../../styled/BackLinkButton";
import { BlockHeaderWithMarginTop, PageFixedActionsPanel } from "../../../styled/Common";
import { TestProjectSettingsSetNameContainer } from "../../../../containers/account/TestProjectSettingsSet/TestProjectSettingsSetName";
import { getTestProjectSettingsSetsRoute } from "../../../../helpers/routes";
import { DEFAULT_BLOCK, getTestProjectSettingsSetBlockByName, isAllowedSettingsSetActionByPermissions } from "../../../../helpers/testProjectSettingsSet";
import { getSiteTitle } from "../../../../helpers/title";
import { SettingsSetAction } from "../../../../helpers/types";
const renderTestProjectSettingsSetBlock = (selectedTestProjectSettingsSetName, props) => {
    const TestProjectSettingsSetBlock = getTestProjectSettingsSetBlockByName(selectedTestProjectSettingsSetName);
    return React.createElement(TestProjectSettingsSetBlock, Object.assign({}, props));
};
const renderErrorHint = ({ isValid, errorText }) => isValid ? (React.createElement(React.Fragment, null)) : (React.createElement(Tooltip, { title: errorText },
    React.createElement(IconErrorBlock, null,
        React.createElement(StyledIconAlertCircle, null))));
const renderSaveButton = ({ errorText, isValid, onSave, permissionList, saveButtonLabel }) => {
    return isAllowedSettingsSetActionByPermissions(permissionList, SettingsSetAction.Save) ? (React.createElement(React.Fragment, null,
        React.createElement(Button, { disabled: !isValid, size: ButtonSize.medium, onClick: onSave }, saveButtonLabel),
        renderErrorHint({ isValid, errorText }))) : (React.createElement(React.Fragment, null));
};
export const TestProjectSettingsSet = (props) => {
    const [selectedTestProjectSettingsSetName, setTestProjectSettingsSetName] = useState(DEFAULT_BLOCK);
    useEffect(() => {
        document.title = getSiteTitle(props.pageTitle);
    }, []);
    const changeWizardItemHandler = (wizardItemName) => setTestProjectSettingsSetName(wizardItemName);
    return (React.createElement(StyledPage, null,
        React.createElement(BlockHeaderWithMarginTop, null,
            React.createElement(BackLinkButton, { to: getTestProjectSettingsSetsRoute() }),
            React.createElement(TestProjectSettingsSetNameContainer, null)),
        React.createElement(Wizard, { onChange: changeWizardItemHandler, selectedWizardItem: selectedTestProjectSettingsSetName }),
        renderTestProjectSettingsSetBlock(selectedTestProjectSettingsSetName, props),
        React.createElement(PageFixedActionsPanel, null, renderSaveButton(props))));
};
