export var AnomalyClassification;
(function (AnomalyClassification) {
    AnomalyClassification["VUResponseTimeWarning"] = "VU_RESPONSE_TIME_WARNING";
    AnomalyClassification["ResponseTimeAlarm"] = "RESPONSE_TIME_ALARM";
    AnomalyClassification["ResponseTimeWarning"] = "RESPONSE_TIME_WARNING";
})(AnomalyClassification || (AnomalyClassification = {}));
export var SensitivityLevel;
(function (SensitivityLevel) {
    SensitivityLevel["Low"] = "LOW";
    SensitivityLevel["Moderate"] = "MODERATE";
    SensitivityLevel["Default"] = "DEFAULT";
    SensitivityLevel["HighPrecision"] = "HIGH_PRECISION";
})(SensitivityLevel || (SensitivityLevel = {}));
