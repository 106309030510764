import React from "react";
import styled from "styled-components";
import { useIntl } from "@boomq/utils";
import { CustomSelect } from "../../../common/CustomSelect";
import { withDisable } from "../../../styled/global";
import { IconDownload } from "../../../styled/Icons";
const getEmptyMessage = (items, formatMessage) => {
    const messageParams = (items || []).length > 0
        ? {
            defaultMessage: "select log",
            id: "account.ended.test.button.logs.select.placeholder"
        }
        : {
            defaultMessage: "no logs or processing...",
            id: "account.ended.test.button.logs.select.empty.placeholder"
        };
    return React.createElement(Placeholder, { title: formatMessage(messageParams) }, formatMessage(messageParams));
};
const CustomSelectBody = (items, selectedItems, formatMessage) => (React.createElement(Body, null, selectedItems.length === 0 ? (getEmptyMessage(items, formatMessage)) : (React.createElement("span", { title: selectedItems[0].fileName }, selectedItems[0].fileName))));
const getSelectedLogFile = (items) => (items || []).find(({ selected }) => selected);
const isDownloadLinkEnabled = (selectedLogFile) => selectedLogFile && selectedLogFile.id && selectedLogFile.id.length > 0;
const getDownloadLinkTitle = (selectedLogFile, formatMessage) => selectedLogFile && selectedLogFile.fileName
    ? formatMessage({
        id: "account.ended.test.button.logs.download.title",
        defaultMessage: "Download {fileName}"
    }, { fileName: selectedLogFile.fileName })
    : "";
export const TestLogsDownloader = ({ items, onChange }) => {
    const { formatMessage } = useIntl();
    const selectedLogFile = getSelectedLogFile(items) || {};
    const changeHandler = (value) => typeof onChange === "function" && onChange(value);
    return (React.createElement(DownloaderSection, null,
        React.createElement(CustomSelect, { defaultValue: selectedLogFile.id, items: items || [], onChange: changeHandler, bodyTemplate: (selectedItems) => CustomSelectBody(items, selectedItems, formatMessage), textAttrName: "fileName", titleAttrName: "fileName", valueAttrName: "id", theme: customSelectTheme, themeList: customSelectThemeList, themeListItem: customSelectThemeListItem }),
        React.createElement(DownloadLink, { disabled: !isDownloadLinkEnabled(selectedLogFile), title: getDownloadLinkTitle(selectedLogFile, formatMessage), href: selectedLogFile.downloadLink },
            React.createElement(IconDownload, null))));
};
const customSelectTheme = { minWidth: "190px", maxWidth: "190px" };
const customSelectThemeList = { border: "1px solid #4394e8" };
const customSelectThemeListItem = { borderBottom: "1px solid #4394e8" };
const Body = styled.div `
    align-items: center;
    background: #fff;
    border: 2px solid #4394e8;
    border-radius: 100px;
    display: flex;
    max-height: 46px;
    min-height: 46px;
    overflow: hidden;
    padding: 5px 60px 5px 30px;
    text-overflow: ellipsis;
    white-space: nowrap;
`;
const DownloadLink = styled.a `
    ${withDisable}
    cursor: pointer;
    margin-left: 4px;
`;
export const DownloaderSection = styled.div `
    align-items: center;
    display: flex;
`;
const Placeholder = styled.span `
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;
