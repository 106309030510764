import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, stopEvent } from "@boomq/utils";
import { classNames } from "./ClassNames";
import ObjectUtils from "./ObjectUtils";
import { UITreeNode } from "./UITreeNode";
import "./Tree.css";
import "./theme.css";
import { getAdditionalClassName, getUITreeNodeKey } from "./utils";
export class Tree extends Component {
    constructor(props) {
        super(props);
        this.getFilterValue = () => (this.props.onFilterValueChange ? this.props.filterValue : this.state.filterValue);
        this.getExpandedKeys = () => (this.props.onToggle ? this.props.expandedKeys : this.state.expandedKeys);
        this.getRootNode = () => (this.props.filter && this.filteredNodes ? this.filteredNodes : this.props.value);
        this.getDragStatePath = (dragState) => (dragState ? dragState.path : null);
        this.onToggle = (event) => typeof this.props.onToggle === "function"
            ? this.props.onToggle(event)
            : this.setState({
                expandedKeys: event.value
            });
        this.getPathLength = (path) => path.substring(0, path.lastIndexOf("-"));
        this.areSiblings = (path1, path2) => path1.length === 1 && path2.length === 1 ? true : this.getPathLength(path1) === this.getPathLength(path2);
        this.isNodeLeaf = (node) => (node.leaf === false ? false : !(node.children && node.children.length));
        this.onFilterInputKeyDown = (event) => (event.which === 13 ? event.preventDefault() : undefined);
        this.filter = (value) => this.setState({ filterValue: ObjectUtils.isNotEmpty(value) ? value : "" }, this._filter);
        this._isActiveDnD = true;
        this.state = {};
        if (!this.props.onFilterValueChange) {
            this.state["filterValue"] = "";
        }
        if (!this.props.onToggle) {
            this.state["expandedKeys"] = this.props.expandedKeys;
        }
        this.changeActiveDnD = this.changeActiveDnD.bind(this);
        this.isNodeLeaf = this.isNodeLeaf.bind(this);
        this.onToggle = this.onToggle.bind(this);
        this.onDragStart = this.onDragStart.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.onDropPoint = this.onDropPoint.bind(this);
        this.onDropPointDragEnterValidation = this.onDropPointDragEnterValidation.bind(this);
        this.onFilterInputChange = this.onFilterInputChange.bind(this);
        this.onFilterInputKeyDown = this.onFilterInputKeyDown.bind(this);
    }
    changeActiveDnD(value) {
        this._isActiveDnD = value;
    }
    dragStart(event) {
        this.dragState = {
            path: event.path,
            index: event.index
        };
        return typeof this.props.onDragStart === "function" ? this.props.onDragStart(event) : undefined;
    }
    preventDnD(event) {
        stopEvent(event.originalEvent);
        this.onDragEnd();
    }
    onDragStart(event) {
        return this._isActiveDnD ? this.dragStart(event) : this.preventDnD(event);
    }
    onDragEnd() {
        this.dragState = null;
    }
    reorderNodeInTreeByDrop(event) {
        if (this.validateDropNode(this.getDragStatePath(this.dragState), event.path)) {
            let value = JSON.parse(JSON.stringify(this.props.value));
            const dragNodeParent = this.findDragDropParentNode(value, this.dragState.path);
            let dragNode = dragNodeParent ? dragNodeParent.children[this.dragState.index] : value[this.dragState.index];
            let dropNode = this.findNode(value, event.path.split("-"));
            if (typeof this.props.onDropValidation === "function" &&
                !this.props.onDropValidation({ dragNode, dropNode })) {
                this.dragState = null;
                return undefined;
            }
            if (dropNode.children) {
                dropNode.children.push(dragNode);
            }
            else {
                dropNode.children = [dragNode];
            }
            if (dragNodeParent) {
                dragNodeParent.children.splice(this.dragState.index, 1);
            }
            else {
                value.splice(this.dragState.index, 1);
            }
            this.dragState = null;
            return this.onReorderNodeInTree({
                originalEvent: event.originalEvent,
                value,
                dragNode,
                dropNode,
                dropIndex: event.index
            });
        }
    }
    getNodeDataFromDataTransfer(event) {
        try {
            return JSON.parse(event.dataTransfer.getData("node"));
        }
        catch (e) {
            return;
        }
    }
    addNodeToTreeByDrop(event) {
        const value = JSON.parse(JSON.stringify(this.props.value));
        const dragNode = this.getNodeDataFromDataTransfer(event.originalEvent);
        const dropNode = this.findNode(value, event.path.split("-"));
        if (typeof this.props.onDropValidation === "function" && !this.props.onDropValidation({ dragNode, dropNode })) {
            this.dragState = null;
            return undefined;
        }
        if (dropNode.children) {
            dropNode.children.push(dragNode);
        }
        else {
            dropNode.children = [dragNode];
        }
        this.dragState = null;
        return this.onAdd({
            originalEvent: event.originalEvent,
            value,
            dragNode,
            dropNode,
            dropIndex: event.index
        });
    }
    onDrop(event) {
        this.checkOnBeforeDragDropProp();
        return this.dragState ? this.reorderNodeInTreeByDrop(event) : this.addNodeToTreeByDrop(event);
    }
    findDragDropParentNode(value, path) {
        let paths = path.split("-");
        paths.pop();
        return this.findNode(value, paths);
    }
    checkOnBeforeDragDropProp() {
        this.dragState =
            typeof this.props.onBeforeDragDrop === "function"
                ? this.props.onBeforeDragDrop(this.dragState)
                : this.dragState;
    }
    addNodeToTreeByDropPoint(event) {
        const dragNode = this.getNodeDataFromDataTransfer(event.originalEvent);
        const value = JSON.parse(JSON.stringify(this.props.value));
        const dropNodeParent = this.findDragDropParentNode(value, event.path);
        if (typeof this.props.onDropValidation === "function" &&
            !this.props.onDropValidation({ dragNode, dropNodeParent })) {
            this.dragState = null;
            return undefined;
        }
        if (event.position < 0) {
            let dropIndex = event.index;
            if (dropNodeParent) {
                dropNodeParent.children.splice(dropIndex, 0, dragNode);
            }
            else {
                value.splice(dropIndex, 0, dragNode);
            }
        }
        else {
            if (dropNodeParent) {
                dropNodeParent.children.push(dragNode);
            }
            else {
                value.push(dragNode);
            }
        }
        this.dragState = null;
        return this.onAdd({
            originalEvent: event.originalEvent,
            value,
            dragNode,
            dropNode: dropNodeParent,
            dropIndex: event.index
        });
    }
    reorderNodeInTreeByDropPoint(event) {
        if (this.validateDropPoint(event)) {
            let value = JSON.parse(JSON.stringify(this.props.value));
            const dragNodeParent = this.findDragDropParentNode(value, this.dragState.path);
            const dropNodeParent = this.findDragDropParentNode(value, event.path);
            const dragNode = dragNodeParent
                ? dragNodeParent.children[this.dragState.index]
                : value[this.dragState.index];
            const siblings = this.areSiblings(this.dragState.path, event.path);
            if (typeof this.props.onDropValidation === "function" &&
                !this.props.onDropValidation({ dragNode, dropNodeParent })) {
                this.dragState = null;
                return undefined;
            }
            if (dragNodeParent) {
                dragNodeParent.children.splice(this.dragState.index, 1);
            }
            else {
                value.splice(this.dragState.index, 1);
            }
            if (event.position < 0) {
                let dropIndex = siblings
                    ? this.dragState.index > event.index
                        ? event.index
                        : event.index - 1
                    : event.index;
                if (dropNodeParent) {
                    dropNodeParent.children.splice(dropIndex, 0, dragNode);
                }
                else {
                    value.splice(dropIndex, 0, dragNode);
                }
            }
            else {
                if (dropNodeParent) {
                    dropNodeParent.children.push(dragNode);
                }
                else {
                    value.push(dragNode);
                }
            }
            this.dragState = null;
            return this.onReorderNodeInTree({
                originalEvent: event.originalEvent,
                value,
                dragNode,
                dropNode: dropNodeParent,
                dropIndex: event.index
            });
        }
    }
    onAdd(data) {
        this.onDragDrop(data);
        return typeof this.props.onAdd === "function" ? this.props.onAdd(data) : undefined;
    }
    onReorderNodeInTree(data) {
        this.onDragDrop(data);
        return typeof this.props.onReorder === "function" ? this.props.onReorder(data) : undefined;
    }
    onDragDrop(data) {
        return typeof this.props.onDragDrop === "function" ? this.props.onDragDrop(data) : undefined;
    }
    onDropPoint(event) {
        this.checkOnBeforeDragDropProp();
        return this.dragState ? this.reorderNodeInTreeByDropPoint(event) : this.addNodeToTreeByDropPoint(event);
    }
    validateDrop(dragPath, dropPath) {
        if (!dragPath) {
            return false;
        }
        if (dragPath === dropPath) {
            return false;
        }
        if (dropPath.indexOf(dragPath) === 0) {
            return false;
        }
        return true;
    }
    validateDropNode(dragPath, dropPath) {
        let validateDrop = this.validateDrop(dragPath, dropPath);
        if (validateDrop) {
            if (dragPath.indexOf("-") > 0 && dragPath.substring(0, dragPath.lastIndexOf("-")) === dropPath) {
                return false;
            }
            return true;
        }
        else {
            return false;
        }
    }
    validateDropPoint(event) {
        let validateDrop = this.validateDrop(this.getDragStatePath(this.dragState), event.path);
        if (validateDrop) {
            if (event.position === -1 &&
                this.areSiblings(this.dragState.path, event.path) &&
                this.dragState.index + 1 === event.index) {
                return false;
            }
            return true;
        }
        return false;
    }
    findNode(value, path) {
        if (path.length === 0) {
            return null;
        }
        else {
            const index = parseInt(path[0], 10);
            const nextSearchRoot = value.children ? value.children[index] : value[index];
            if (path.length === 1) {
                return nextSearchRoot;
            }
            else {
                path.shift();
                return this.findNode(nextSearchRoot, path);
            }
        }
    }
    onFilterInputChange(event) {
        this.filterChanged = true;
        let filterValue = event.target.value;
        if (this.props.onFilterValueChange) {
            this.props.onFilterValueChange({
                originalEvent: event,
                value: filterValue
            });
        }
        else {
            this.setState({ filterValue });
        }
    }
    _filter() {
        if (!this.filterChanged) {
            return;
        }
        const filterValue = this.getFilterValue();
        if (ObjectUtils.isEmpty(filterValue)) {
            this.filteredNodes = this.props.value;
        }
        else {
            this.filteredNodes = [];
            const searchFields = this.props.filterBy.split(",");
            const filterText = filterValue.toLocaleLowerCase(this.props.filterLocale);
            const isStrictMode = this.props.filterMode === "strict";
            for (let node of this.props.value) {
                let copyNode = Object.assign({}, node);
                let paramsWithoutNode = { searchFields, filterText, isStrictMode };
                if ((isStrictMode &&
                    (this.findFilteredNodes(copyNode, paramsWithoutNode) ||
                        this.isFilterMatched(copyNode, paramsWithoutNode))) ||
                    (!isStrictMode &&
                        (this.isFilterMatched(copyNode, paramsWithoutNode) ||
                            this.findFilteredNodes(copyNode, paramsWithoutNode)))) {
                    this.filteredNodes.push(copyNode);
                }
            }
        }
        this.filterChanged = false;
    }
    findFilteredNodes(node, paramsWithoutNode) {
        if (node) {
            let matched = false;
            if (node.children) {
                let childNodes = [...node.children];
                node.children = [];
                for (let childNode of childNodes) {
                    let copyChildNode = Object.assign({}, childNode);
                    if (this.isFilterMatched(copyChildNode, paramsWithoutNode)) {
                        matched = true;
                        node.children.push(copyChildNode);
                    }
                }
            }
            if (matched) {
                node.expanded = true;
                return true;
            }
        }
    }
    isFilterMatched(node, { searchFields, filterText, isStrictMode }) {
        let matched = false;
        for (let field of searchFields) {
            let fieldValue = String(ObjectUtils.resolveFieldData(node, field)).toLocaleLowerCase(this.props.filterLocale);
            if (fieldValue.indexOf(filterText) > -1) {
                matched = true;
            }
        }
        if (!matched || (isStrictMode && !this.isNodeLeaf(node))) {
            matched = this.findFilteredNodes(node, { searchFields, filterText, isStrictMode }) || matched;
        }
        return matched;
    }
    onDropPointDragEnterValidation({ dropNodeParent, event }) {
        let dragNode;
        if (this.dragState) {
            const value = JSON.parse(JSON.stringify(this.props.value));
            const dragNodeParent = this.findDragDropParentNode(value, this.dragState.path);
            dragNode = dragNodeParent ? dragNodeParent.children[this.dragState.index] : value[this.dragState.index];
        }
        return typeof this.props.onDropPointDragEnterValidation == "function"
            ? this.props.onDropPointDragEnterValidation({ dragNode, dropNodeParent, event })
            : true;
    }
    renderRootChild(node, index, last) {
        return (React.createElement(UITreeNode, { key: getUITreeNodeKey(node, this.props.nodeKeyName), changeActiveDnD: this.changeActiveDnD, className: getAdditionalClassName(node, this.props.nodeClassNameFunc), contextMenuSelectionKey: this.props.contextMenuSelectionKey, disabled: this.props.disabled, dragdropScope: this.props.dragdropScope, dropPointContentTemplate: this.props.dropPointContentTemplate, expandedKeys: this.getExpandedKeys(), index: index, isNodeLeaf: this.isNodeLeaf, last: last, metaKeySelection: this.props.metaKeySelection, node: node, nodeClassNameFunc: this.props.nodeClassNameFunc, nodeKeyName: this.props.nodeKeyName, nodeLabelTemplate: this.props.nodeLabelTemplate, nodeTemplate: this.props.nodeTemplate, onCollapse: this.props.onCollapse, onContextMenu: this.props.onContextMenu, onContextMenuSelectionChange: this.props.onContextMenuSelectionChange, onDragStart: this.onDragStart, onDragEnd: this.onDragEnd, onDrop: this.onDrop, onDropPoint: this.onDropPoint, onDropPointDragEnterValidation: this.onDropPointDragEnterValidation, onExpand: this.props.onExpand, onNodeClick: this.props.onNodeClick, onNodeDoubleClick: this.props.onNodeDoubleClick, onSelect: this.props.onSelect, onSelectionChange: this.props.onSelectionChange, onToggle: this.onToggle, onUnselect: this.props.onUnselect, path: String(index), propagateSelectionDown: this.props.propagateSelectionDown, propagateSelectionUp: this.props.propagateSelectionUp, selectionKeys: this.props.selectionKeys, selectionMode: this.props.selectionMode, togglerTemplate: this.props.togglerTemplate }));
    }
    renderRootChildren() {
        if (this.props.filter) {
            this.filterChanged = true;
            this._filter();
        }
        const value = this.getRootNode();
        return isEmpty(value)
            ? this.renderRootChild({}, 0, true)
            : value.map((node, index) => this.renderRootChild(node, index, index === value.length - 1));
    }
    renderModel() {
        if (this.props.value) {
            const rootNodes = this.renderRootChildren();
            let contentClass = classNames("p-tree-container", this.props.contentClassName);
            return (React.createElement("ul", { className: contentClass, role: "tree", "aria-label": this.props.ariaLabel, "aria-labelledby": this.props.ariaLabelledBy, style: this.props.contentStyle }, rootNodes));
        }
        return null;
    }
    renderLoader() {
        if (this.props.loading) {
            let icon = classNames("p-tree-loading-icon pi-spin", this.props.loadingIcon);
            return (React.createElement("div", { className: "p-tree-loading-overlay p-component-overlay" },
                React.createElement("i", { className: icon })));
        }
        return null;
    }
    renderFilter() {
        if (this.props.filter) {
            let filterValue = this.getFilterValue();
            filterValue = ObjectUtils.isNotEmpty(filterValue) ? filterValue : "";
            return (React.createElement("div", { className: "p-tree-filter-container" },
                React.createElement("input", { type: "text", value: filterValue, autoComplete: "off", className: "p-tree-filter p-inputtext p-component", placeholder: this.props.filterPlaceholder, onKeyDown: this.onFilterInputKeyDown, onChange: this.onFilterInputChange, disabled: this.props.disabled }),
                React.createElement("span", { className: "p-tree-filter-icon pi pi-search" })));
        }
        return null;
    }
    renderHeader() {
        if (this.props.showHeader) {
            const filterElement = this.renderFilter();
            let content = filterElement;
            if (this.props.header) {
                const defaultContentOptions = {
                    filterContainerClassName: "p-tree-filter-container",
                    filterIconClasssName: "p-tree-filter-icon pi pi-search",
                    filterInput: {
                        className: "p-tree-filter p-inputtext p-component",
                        onKeyDown: this.onFilterInputKeyDown,
                        onChange: this.onFilterInputChange
                    },
                    filterElement,
                    element: content,
                    props: this.props
                };
                content = ObjectUtils.getJSXElement(this.props.header, defaultContentOptions);
            }
            return React.createElement("div", { className: "p-tree-header" }, content);
        }
        return null;
    }
    renderFooter() {
        const content = ObjectUtils.getJSXElement(this.props.footer, this.props);
        return React.createElement("div", { className: "p-tree-footer" }, content);
    }
    render() {
        const className = classNames("p-tree p-component", this.props.className, {
            "p-tree-selectable": this.props.selectionMode,
            "p-tree-loading": this.props.loading,
            "p-disabled": this.props.disabled
        });
        const loader = this.renderLoader();
        const content = this.renderModel();
        const header = this.renderHeader();
        const footer = this.renderFooter();
        return (React.createElement("div", { id: this.props.id, className: className, style: this.props.style },
            loader,
            header,
            content,
            footer));
    }
}
Tree.defaultProps = {
    id: null,
    value: null,
    disabled: false,
    dropPointContentTemplate: null,
    selectionMode: null,
    selectionKeys: null,
    onSelectionChange: null,
    contextMenuSelectionKey: null,
    onContextMenuSelectionChange: null,
    expandedKeys: null,
    style: null,
    className: null,
    contentStyle: null,
    contentClassName: null,
    metaKeySelection: true,
    propagateSelectionUp: true,
    propagateSelectionDown: true,
    loading: false,
    loadingIcon: "pi pi-spinner",
    dragdropScope: null,
    header: null,
    footer: null,
    showHeader: true,
    filter: false,
    filterValue: null,
    filterBy: "label",
    filterMode: "lenient",
    filterPlaceholder: null,
    filterLocale: undefined,
    nodeKeyName: "key",
    nodeTemplate: null,
    nodeLabelTemplate: null,
    togglerTemplate: null,
    onSelect: null,
    onUnselect: null,
    onExpand: null,
    onCollapse: null,
    onToggle: null,
    onBeforeDragDrop: null,
    onDragDrop: null,
    onDropPointDragEnterValidation: null,
    onDropValidation: null,
    onContextMenu: null,
    onFilterValueChange: null,
    onNodeClick: null,
    onNodeDoubleClick: null
};
Tree.propTypes = {
    id: PropTypes.string,
    value: PropTypes.any,
    disabled: PropTypes.bool,
    dropPointContentTemplate: PropTypes.any,
    selectionMode: PropTypes.string,
    selectionKeys: PropTypes.any,
    onSelectionChange: PropTypes.func,
    contextMenuSelectionKey: PropTypes.any,
    onContextMenuSelectionChange: PropTypes.func,
    expandedKeys: PropTypes.object,
    style: PropTypes.object,
    className: PropTypes.string,
    contentStyle: PropTypes.object,
    contentClassName: PropTypes.string,
    metaKeySelection: PropTypes.bool,
    propagateSelectionUp: PropTypes.bool,
    propagateSelectionDown: PropTypes.bool,
    loading: PropTypes.bool,
    loadingIcon: PropTypes.string,
    dragdropScope: PropTypes.string,
    header: PropTypes.any,
    footer: PropTypes.any,
    showHeader: PropTypes.bool,
    filter: PropTypes.bool,
    filterValue: PropTypes.string,
    filterBy: PropTypes.any,
    filterMode: PropTypes.string,
    filterPlaceholder: PropTypes.string,
    filterLocale: PropTypes.string,
    nodeKeyName: PropTypes.string,
    nodeTemplate: PropTypes.any,
    nodeLabelTemplate: PropTypes.any,
    togglerTemplate: PropTypes.func,
    onSelect: PropTypes.func,
    onUnselect: PropTypes.func,
    onExpand: PropTypes.func,
    onCollapse: PropTypes.func,
    onToggle: PropTypes.func,
    onBeforeDragDrop: PropTypes.func,
    onDragDrop: PropTypes.func,
    onDropPointDragEnterValidation: PropTypes.func,
    onDropValidation: PropTypes.func,
    onContextMenu: PropTypes.func,
    onFilterValueChange: PropTypes.func,
    onNodeClick: PropTypes.func,
    onNodeDoubleClick: PropTypes.func
};
