import { call, put, takeEvery } from "redux-saga/effects";
import { getTestProjects } from "../../services/projectSrv";
import { prepareClientTestProjectList } from "../../helpers/clientTestProjectList";
import { getResponseErrorData } from "../../helpers/errors";
import { clientTestProjectListFailure, clientTestProjectListRequest, clientTestProjectListSuccess } from "../actions/clientTestProjectList";
export function* clientTestProjectsFlow() {
    try {
        const { data } = yield call(getTestProjects, { size: 999 });
        yield put(clientTestProjectListSuccess(prepareClientTestProjectList(data.content)));
    }
    catch (e) {
        yield put(clientTestProjectListFailure(getResponseErrorData(e)));
    }
}
export function* clientTestProjectListFlow() {
    yield takeEvery(clientTestProjectListRequest, clientTestProjectsFlow);
}
