import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getIsAuthorize, getSelectedTeamId, newTestActions, TestProjectCommonContainer } from "@boomq/common";
import { TestProjectSettingsWizardContainer } from "../../../account/TestProject/TestProjectSettingsWizard";

const connector = connect(
    (state) => ({
        isAuthorized: getIsAuthorize(state),
        selectedTeamId: getSelectedTeamId(state)
    }),
    { onAuthorized: newTestActions.onAuthorizedTestProject }
);

const TestProjectContainer = (props) => {
    useEffect(() => {
        const checkAuthorization = () => (props.isAuthorized ? props.onAuthorized?.() : undefined);

        checkAuthorization();
    }, [props.isAuthorized]);

    return (
        <TestProjectCommonContainer {...props}>
            <TestProjectSettingsWizardContainer />
        </TestProjectCommonContainer>
    );
};

export default connector(TestProjectContainer);
