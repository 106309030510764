import { Block, Color, FontBodyMediumBold, FontBodyMediumMedium, FontBodySmallMedium } from "@boomq/uikit";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { getZIndex, hoverWithShadow } from "../../styled/global";
import { media } from "../../styled/response";
export const LabelSet = styled.ul `
    display: flex;
    flex-wrap: wrap;
`;
export const PreviewImg = styled.div `
    max-height: 75px;
    max-width: 150px;
    min-width: 150px;
    rect {
        fill-opacity: 0!important;
    }
`;
export const ProjectName = styled.div `
    ${FontBodyMediumBold};
    color: ${Color.blue};
    word-break: break-all;
`;
export const StatusIndicate = styled.i `
    background: ${({ bg }) => (bg ? bg : Color.white)};
    border-bottom-left-radius: 12px;
    border-top-left-radius: 12px;
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 6px;
    z-index: ${getZIndex("TestRunStatus")};
    ${media.tablet} {
        width: 100%;
        height: 6px;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
    }
`;
export const StyledLink = styled(Link) `
    display: flex;
    width: 100%;
`;
export const StyledText = styled.p `
    ${FontBodyMediumMedium}
    color: ${Color.darkGrey5};
    word-break: break-word;
`;
export const TestContent = styled.div `
    flex: ${(props) => (props.long ? 1.5 : 1)};
    padding-right: 8px;
    &:last-child {
        ${media.tablet} {
            padding-bottom: 0;
        }
    }
    ${media.tablet} {
        padding-bottom: 20px;
    }
`;
export const TestDate = styled.div `
    ${FontBodySmallMedium}
    color: ${Color.grey50};
    margin-top: 12px;
`;
export const TestListItem = styled.li `
    ${Block}
    display: flex;
    margin-top: 8px;
    position: relative;
    transition: all 0.3s ease;
    transition-delay: ${({ delay }) => (delay ? delay : 0)}s;
    &:first-child {
        margin-top: 0;
    }
    ${hoverWithShadow};
`;
export const TestListItemData = styled.div `
    border-bottom-left-radius: 12px;
    border-top-left-radius: 12px;
    cursor: pointer;
    display: flex;
    flex: 1;
    padding: 14px 24px 14px 16px;
    ${media.tablet} {
        align-items: flex-start;
        flex-direction: column;
        padding: 24px 25px 16px 25px;
    }
`;
