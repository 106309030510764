import React from "react";
import { getValueByPath } from "@boomq/utils";
import { TypeSelect } from "./styled";
import { ButtonRemove, RequestParam, RequestSection } from "../ExtractResponseParameter/styled";
import { IconRemoveSection } from "../../KeyValue";
import { IconDeleteGrey } from "../../../styled/StyledIcons";
import { ASSERTION_NEW_ITEM_ID_PREFIX, getAssertionId } from "../../../../models/assertion";
import { FormType } from "../../../../helpers/types";
const renderDeleteButton = ({ assertion, mode, onDelete }) => mode !== FormType.View ? (React.createElement(IconRemoveSection, null, !getAssertionId(assertion).includes(ASSERTION_NEW_ITEM_ID_PREFIX) && (React.createElement(ButtonRemove, { onClick: onDelete },
    React.createElement(IconDeleteGrey, null))))) : (React.createElement(React.Fragment, null));
export const AssertionCommonTemplate = ({ assertion, assertionTypeItems, children, mode, onChangeType, onDelete, typeSelectPlaceholder }) => (React.createElement(RequestSection, null,
    React.createElement(RequestParam, null,
        React.createElement(TypeSelect, { defaultValue: assertion.type, disabled: mode === FormType.View, dropdownAutoPositionMinItemCount: 6, error: getValueByPath(assertion, "error.type"), isAutoPosition: true, items: assertionTypeItems, name: "Assertion_type", onChange: onChangeType, placeholder: typeSelectPlaceholder }),
        children,
        renderDeleteButton({ assertion, mode, onDelete }))));
