import { isEmpty } from "@boomq/utils";
import { convertMsToSec, convertSecToMs } from "../date";
import { TIME_COEFFICIENT, generatePreviewData } from "../generators";
import { getLoadProfileParams } from "../loadProfile";
import { testSettings } from "../../data/content/testSettings";
import { LoadProfileType, ProfileDistribution } from "../../models/testProject";
const getLoadProfileParamsData = (data) => getLoadProfileParams().reduce((res, setting) => (Object.assign(Object.assign({}, res), (data && !isEmpty(data[setting.name])
    ? {
        [setting.name]: Number(setting.type === "time" ? data[setting.name] * TIME_COEFFICIENT : data[setting.name])
    }
    : {}))), {});
const generateLoadProfile = (testProjectData) => {
    const { stepCount = 1, usersPerStep = 1, stepLength = 1, rampUp = 0, rampDown = 0, durationAddedOnLastStep = 0 } = getLoadProfileParamsData(testProjectData);
    return {
        loadProfile: {
            loadProfileType: "BOOMQ",
            boomqProfileType: testProjectData.testType,
            stepCount,
            usersPerStep,
            stepLengthMs: convertSecToMs(stepLength),
            rampUpMs: convertSecToMs(rampUp),
            rampDownMs: convertSecToMs(rampDown),
            durationAddedOnLastStepMs: convertSecToMs(durationAddedOnLastStep)
        }
    };
};
const getTestSettings = () => (testSettings ? testSettings.SCALABILITY : []);
export const getTestProjectLoadProfile = (data) => getTestSettings().reduce((res, setting) => {
    const settingName = setting.type === "time" ? `${setting.name}Ms` : setting.name;
    const multiplicationFactor = setting.type === "time" ? 1 / 60000 : 1;
    return data && !isEmpty(data[settingName])
        ? Object.assign(Object.assign({}, res), { [setting.name]: Number(data[settingName] * multiplicationFactor) }) : res;
}, {});
const getTestProjectDataByLoadProfilePerTest = (loadProfileData) => {
    const { boomqProfileType } = loadProfileData || {};
    const loadProfile = getTestProjectLoadProfile(loadProfileData);
    return {
        chartData: [{ coords: generatePreviewData(Object.assign(Object.assign({}, loadProfile), { loadType: boomqProfileType })) }],
        loadProfileType: LoadProfileType.PerTest,
        profileDistribution: ProfileDistribution.Percent,
        [boomqProfileType]: loadProfile
    };
};
export const getTestProjectDataByLoadProfileType = (testPlanDataFromYaml) => {
    const { loadProfile, loadProfileType } = testPlanDataFromYaml || {};
    return loadProfileType === LoadProfileType.PerTest
        ? getTestProjectDataByLoadProfilePerTest(loadProfile)
        : { loadProfileType, profileDistribution: ProfileDistribution.Users };
};
export const parseLoadProfileDurationParam = (params, paramName) => convertMsToSec(params[paramName] || 0) / TIME_COEFFICIENT;
