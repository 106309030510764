import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { getUniqueId } from "@boomq/utils";
import { PFLBTable, PFLBTableWithIntl } from "@boomq/common";
import store from "../../../../redux";

export class PFLBTableConnectedToStore extends PFLBTable {
    constructor({ data, ...restParams }) {
        const pflbTableId = data?.id || getUniqueId();

        super({ data: data?.tableData ?? {}, pflbTableId, ...restParams });

        this._pflbTableId = pflbTableId;
    }

    renderPFLBTableInNode(node, table) {
        render(
            <Provider store={store}>
                <PFLBTableWithIntl
                    id={this._pflbTableId}
                    onInitData={(reportTable) => super.initTableData(reportTable)}
                    onRefresh={(reportTable) => super.refreshTableData(reportTable)}
                    onToggleTableParamsSidebar={() => super.toggleTableParamsSidebar()}
                />
            </Provider>,
            node
        );

        node.appendChild(table);

        return node;
    }
}
