import { JMX_INVALID_FILE_EXCEPTION_MESSAGE, JMX_MISMATCH_NEEDED_FILES_EXCEPTION_MESSAGE, JMX_UNSUPPORTED_MODULES_EXCEPTION_MESSAGE } from "./constants";
import { JMX_FILE_NEEDED_FILES_TEXT, JMX_FILE_UNSUPPORTED_MODULES_TITLE } from "./intl";
import { showError } from "./showError";
import { ExceptionForm } from "../../../components/common/ExceptionForm";
import UnsupportedJMeterModulesException from "../../../components/account/newTest/UnsupportedJMeterModulesException";
import { InvalidJMXFileException } from "../../../components/account/newTest/InvalidJMXFileException";
export function* uploadJmxFileFailureFlow(action) {
    const params = getJmxFileUploadingFailureParams(action);
    return yield* showError(params);
}
const getJmxFileUploadingFailureParams = (action) => {
    const { exceptionMessage, invalidModulesInfo, missingNeededFiles } = action && action.payload ? action.payload : {};
    switch (exceptionMessage) {
        case JMX_UNSUPPORTED_MODULES_EXCEPTION_MESSAGE: {
            return {
                element: UnsupportedJMeterModulesException,
                props: { invalidModulesInfo },
                title: JMX_FILE_UNSUPPORTED_MODULES_TITLE,
                height: "90%",
                width: "700px"
            };
        }
        case JMX_INVALID_FILE_EXCEPTION_MESSAGE: {
            return {
                element: InvalidJMXFileException,
                props: {},
                title: JMX_FILE_UNSUPPORTED_MODULES_TITLE,
                width: "600px"
            };
        }
        case JMX_MISMATCH_NEEDED_FILES_EXCEPTION_MESSAGE: {
            return {
                element: ExceptionForm,
                props: {
                    texts: [
                        JMX_FILE_NEEDED_FILES_TEXT,
                        Array.isArray(missingNeededFiles) ? missingNeededFiles.join(", ") : ""
                    ]
                },
                theme: { alignItems: "center" },
                title: JMX_FILE_UNSUPPORTED_MODULES_TITLE,
                width: "630px"
            };
        }
        default: {
            return undefined;
        }
    }
};
