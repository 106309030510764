import { TeamMemberPermission, SlaReportProfileAction } from "../types";
const actionsByTeamMemberPermissionEdit = [SlaReportProfileAction.Add, SlaReportProfileAction.Save];
const actionsByTeamMemberPermissions = {
    [TeamMemberPermission.Admin]: actionsByTeamMemberPermissionEdit,
    [TeamMemberPermission.Edit]: actionsByTeamMemberPermissionEdit,
    [TeamMemberPermission.Run]: actionsByTeamMemberPermissionEdit
};
const getSlaProfileActionsByTeamMemberPermission = (permission) => {
    const actionsByPermission = actionsByTeamMemberPermissions[permission];
    return actionsByPermission ? actionsByPermission : [];
};
const getSlaProfileActionsByTeamMemberPermissions = (permissions) => [
    ...new Set(permissions.reduce((res, permission) => [
        ...res,
        ...getSlaProfileActionsByTeamMemberPermission(permission)
    ], []))
];
export const isAllowedSlaReportProfileActionByPermissions = (permissionList = [], actionName) => {
    const actionsByPermissions = getSlaProfileActionsByTeamMemberPermissions(permissionList);
    return actionsByPermissions.includes(actionName);
};
