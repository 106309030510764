import styled from "styled-components";
import { Color, FontBodySmallMedium, IconClose, IconDelete, IconEdit, IconImage } from "@boomq/uikit";
import { SubtitleStyles } from "../TeamInfoBlock";
import { IconTeam } from "../../../styled/Icons";
import { ActionWithTextStyles } from "../../../styled/Table";
const iconStyle = `
    cursor: pointer;
    fill: ${Color.blue};
    height: 20px;
    width: 20px;
`;
export const imageUploaderTheme = {
    "> label": {
        padding: "12px 24px",
        "&.error": {
            div: {
                color: Color.red
            },
            svg: {
                fill: Color.red
            }
        },
        "&:hover": {
            div: {
                color: Color.blue
            },
            svg: {
                fill: Color.blue
            }
        }
    }
};
export const Action = styled.div `
    ${ActionWithTextStyles}
`;
export const BlockTitle = styled.div `
    align-items: center;
    display: flex;
    margin-bottom: 8px;
`;
export const EmptyImageText = styled.div `
    ${FontBodySmallMedium}
    color: ${Color.lightGrey85};
`;
export const ImagePreview = styled.img `
    height: 90px;
    max-height: 90px;
    max-width: 90px;
    width: 90px;
`;
export const PreviewBlock = styled.div `
    display: flex;
`;
export const StyledIconImage = styled(IconImage) `
    fill: ${Color.lightGrey85};
    height: 32px;
    margin-bottom: 8px;
    width: 32px;
`;
export const StyledIconCancel = styled(IconClose) `
    fill: ${Color.blue};
    height: 20px;
    margin-right: 6px;
    width: 20px;
`;
export const StyledIconDelete = styled(IconDelete) `
    ${iconStyle}
    margin-left: 8px;
`;
export const StyledIconEdit = styled(IconEdit) `
    ${iconStyle}
    margin-left: 16px;
`;
export const TeamDefaultImage = styled(IconTeam) `
    fill: ${Color.darkGrey35};
    height: auto;
    width: 90px;
`;
export const TeamImageTitle = styled.div `
    ${SubtitleStyles}
    flex: auto;
`;
