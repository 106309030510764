import React from "react";
import styled from "styled-components";
import { useIntl } from "@boomq/utils";
import { IconDownload } from "../../../styled/Icons";
import { Link } from "../../../styled/global";
const getDownloadLinkTitle = (selectedLogFile, formatMessage) => selectedLogFile && selectedLogFile.fileName
    ? formatMessage({
        id: "account.ended.test.button.logs.download.title",
        defaultMessage: "Download {fileName}"
    }, { fileName: selectedLogFile.fileName })
    : "";
export const TestLogs = ({ items, onClick }) => {
    const { formatMessage } = useIntl();
    return (React.createElement(LogFiles, null, Object.keys(items || {}).map((containerId) => (React.createElement(ContainerLogFiles, { key: containerId }, (items[containerId] || []).map((item) => (React.createElement(LogFile, { key: item.id },
        React.createElement(LogFileName, { title: getDownloadLinkTitle(item, formatMessage), onClick: () => onClick(item) }, item.fileName),
        React.createElement(IconDownload, { height: "20", width: "32.5", onClick: () => onClick(item), title: getDownloadLinkTitle(item, formatMessage) })))))))));
};
const ContainerLogFiles = styled.div `
    display: flex;
    margin-top: 10px;
    &:first-child {
        margin-top: 0;
    }
`;
const LogFile = styled.div `
    > svg {
        cursor: pointer;
    }
`;
const LogFileName = styled(Link) `
    font-size: 16px;
    font-weight: 600;
    margin-right: 4px;
`;
const LogFiles = styled.div `
    display: flex;
    flex-direction: column;
    margin: 20px 30px 0 30px;
`;
