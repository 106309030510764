import React from "react";
import { connect } from "react-redux";
import { LoaderImg, StyledLoader } from "./styled";
import { getIsShowLoader } from "../../../redux/reducers/loader";
import loader from "../../../assets/images/icons/loading.svg";
const connector = connect((state) => ({
    isShow: getIsShowLoader(state)
}));
const Loader = ({ children, isShow, size = "md" }) => (React.createElement(StyledLoader, { isShow: isShow },
    React.createElement(LoaderImg, { size: size, src: loader, alt: "loader" }),
    children));
export default connector(Loader);
