import * as React from 'react';
import { FormattedMessage } from "react-intl";
import ReactSVG from "react-svg";
import styled, { css, keyframes } from 'styled-components';
import loader from '../../assets/images/icons/loading.svg';
const CheckStatusItem = ({ img, text, isFetching, isVerified, isError }) => (React.createElement(Item, { verified: isVerified, error: isError },
    React.createElement(IconWrapper, null,
        React.createElement(Img, { src: img })),
    React.createElement(Text, null,
        React.createElement(FormattedMessage, Object.assign({}, text))),
    isFetching &&
        React.createElement(LoaderWrapper, null,
            React.createElement(LoaderIcon, { src: loader }))));
const rollUp = keyframes `
    from {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    to {
        transform: translate(-50%, -50%) rotate(360deg);
    }
`;
const Img = styled(ReactSVG) `
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    fill: #CACACA;
`;
const IconWrapper = styled.div `
    position: relative;
    width: 100px;
    height: 100px;
    background: #fff;
    border: 3px solid #E0E0E0;
    border-radius: 50%;
`;
const LoaderIcon = styled.img `
    width: 40px;
    height: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    animation: ${rollUp} 1s linear infinite;
`;
const LoaderWrapper = styled.div `
    width: 100px;
    height: 100px;
    border: 3px solid #E0E0E0;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(255, 255, 255, 0.6);
`;
const Text = styled.p `
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    font-size: 16px;
    text-align: center;
    color: #BDBDBD;
    margin-top: 20px;
`;
const Item = styled.li `
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    &:before, &:after {
        content: '';
        position: absolute;
        top: 36%;
        width: 45px;
        height: 4px;
        background: #E0E0E0;
    }
    &:after {
        right: 0;
        border-radius: 5px 0 0 5px;
    }
    &:before {
        left: 0;
        border-radius: 0 5px 5px 0;
    }
    &:first-child:before, &:last-child:after {
        display: none;
    }
    ${(props) => props.verified && css `
      &:before, &:after {
        background: #4989D6;
      }
      svg {
        fill: #ffffff;
      }
      ${IconWrapper} {
        background: #4989D6;
        border-color: #4989D6;
      }
      ${Text} {
        color: #4F4F4F;
      }
    `}
    ${(props) => props.error && css `
      &:before, &:after {
        background: #eb5757;
      }
      svg {
        fill: #ffffff;
      }
      ${IconWrapper} {
        background: #eb5757;
        border-color: #eb5757;
      }
      ${Text} {
        color: #4F4F4F;
      }
    `}
`;
export default CheckStatusItem;
