export const COMBO_BOX_HEIGHT = 36;
export const OPTION_LI_HEIGHT = 40;
export const DEFAULT_OPTION_LI_MAX_COUNT = 11;
export const DEFAULT_OPTION_UL_MAX_HEIGHT = `${OPTION_LI_HEIGHT * DEFAULT_OPTION_LI_MAX_COUNT}px`;
export const DEFAULT_TEXT_ATTR_NAME = "text";
export const DEFAULT_TITLE_ATTR_NAME = "title";
export const DEFAULT_VALUE_ATTR_NAME = "value";
export const DEFAULT_TEXT = {
    defaultMessage: " ",
    id: "title.empty"
};
