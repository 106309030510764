export const ADD_LABEL_MESSAGE = {
    defaultMessage: "Add label",
    id: "account.new.settings.labels.button.label"
};
export const LABELS_CHECKBOX_LABEL_MESSAGE = {
    defaultMessage: "Copy labels from test",
    id: "account.new.settings.labels.checkbox.copy.from.project.label"
};
export const LABELS_TITLE_MESSAGE = {
    defaultMessage: "Labels",
    id: "account.new.settings.labels.title"
};
