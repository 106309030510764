import styled from "styled-components";
import { Block, Color, FontBodyBigBold, FontBodyMediumMedium, getZIndex, IconAlertCircle, withShadow } from "@boomq/uikit";
export const SubtitleStyles = `
    ${FontBodyMediumMedium}
    color: ${Color.darkGrey35};
`;
export const TeamsStyledIconAlertCircle = styled(IconAlertCircle) `
    fill: ${Color.blue};
`;
export const TeamsSubtitle = styled.div `
    ${FontBodyBigBold}
    color: ${Color.darkGrey5};
    margin-left: 12px;
`;
export const TeamActionsPanel = styled.div `
    ${withShadow}
    align-items: center;
    background: ${Color.white};
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    bottom: 0;
    display: flex;
    justify-content: center;
    margin: 0 32px;
    padding: 13px 0;
    position: absolute;
    z-index: ${getZIndex("TestProjectSettingsSetActionActionsPanel")};
    width: calc(100% - 64px);
`;
export const TeamSectionBlock = styled.div `
    ${Block}
    display: flex;
    flex-direction: column;
    margin-bottom: 0px;
    margin-left: 32px;
    margin-right: 32px;
    margin-top: 24px;
    padding: 12px;
`;
const TeamSectionTitle = styled.div `
    ${FontBodyBigBold}
    color: ${Color.darkGrey5};
`;
export const TeamNameSection = styled.div `
    display: flex;
    flex-direction: column;
`;
export const TeamImageSection = styled(TeamNameSection) `
    margin-left: 110px;
`;
export const TeamInfo = styled.div `
    display: flex;
    margin: 16px 12px;
`;
export const TeamNameSubtitle = styled.div `
    ${SubtitleStyles}
    margin-bottom: 32px;
`;
export const TeamNameTitle = styled(TeamSectionTitle) `
    margin-bottom: 8px;
`;
