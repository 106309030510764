import { Color, FontBodyMediumBold } from "@boomq/uikit";
import styled from "styled-components";
export const ContentBody = styled.div `
    ${FontBodyMediumBold}
    color: ${Color.grey50};
    display: flex;
    flex-direction: column;
    height: 200px;
    margin-top: 24px;
    min-height: 200px
    max-height: 200px;
    overflow-y: auto;
    width: 100%;
`;
