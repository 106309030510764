import React from "react";
import { Legend, Tooltip } from "recharts";
import { Color } from "@boomq/uikit";
import { chartLeftAxisLabel, chartLeftAxisLabelOrientation, chartRightAxisLabel, chartRightAxisLabelOrientation, ResponseTimeChartWrapper, VUsersLabel } from "./styled";
import AreaChart from "../../common/AreaChart";
import { CustomDot } from "../../common/AreaChartCustomComponents/CustomDot";
import { CustomTooltipLink } from "../../common/AreaChartCustomComponents/CustomTooltipLink";
import { CustomLegendContainer } from "../../../containers/account/AreaChartCustomComponents/CustomLegend";
import { CustomTooltipContainer } from "../../../containers/account/AreaChartCustomComponents/CustomTooltip";
import { TIMELINE_PCT_95_RESP_TIME, TIMELINE_THREADS } from "../../../helpers/testDetailsStats";
export const ResponseTimeChart = ({ chartData, chartResponseLabel, chartVUsersLabel, isAnomalyEnabled, isVisibleTooltipLink, isShowAnomalyLegend, leftYAxisResponseTimeMaxLength, onClickDot, onClickGraph, rightYAxisValMaxLength, testDetails, tooltipLinkPosition }) => (React.createElement(ResponseTimeChartWrapper, { onClick: onClickGraph },
    React.createElement(AreaChart, { data: chartData, leftYAxisValMaxLength: leftYAxisResponseTimeMaxLength, rightYAxisValMaxLength: rightYAxisValMaxLength, axis: {
            x: "time",
            y: [
                Object.assign({ dataKey: TIMELINE_PCT_95_RESP_TIME, label: React.createElement("text", Object.assign({}, chartLeftAxisLabel), chartResponseLabel), unit: "s" }, chartLeftAxisLabelOrientation),
                Object.assign({ dataKey: TIMELINE_THREADS, label: React.createElement(VUsersLabel, Object.assign({}, chartRightAxisLabel), chartVUsersLabel) }, chartRightAxisLabelOrientation)
            ]
        }, areas: [
            {
                dataKey: TIMELINE_THREADS,
                fillOpacity: 0,
                name: chartVUsersLabel,
                stroke: Color.blue,
                strokeWidth: 3,
                type: "linear",
                yAxisId: "right"
            },
            {
                activeDot: !isAnomalyEnabled,
                dataKey: TIMELINE_PCT_95_RESP_TIME,
                dot: isAnomalyEnabled && React.createElement(CustomDot, { onClick: onClickDot }),
                fill: "#B6D3FF",
                fillOpacity: 0.6,
                name: chartResponseLabel,
                stroke: "#B6D3FF",
                type: "monotone",
                yAxisId: "left"
            }
        ] }, isShowAnomalyLegend && (React.createElement(React.Fragment, null,
        React.createElement(Tooltip, { content: React.createElement(CustomTooltipContainer, null) }),
        React.createElement(Legend, { content: React.createElement(CustomLegendContainer, null) })))),
    isVisibleTooltipLink && (React.createElement(CustomTooltipLink, { tooltipPosition: tooltipLinkPosition, url: testDetails.grafanaTestUrl }))));
