import styled from "styled-components";
export const ScrollableTable = styled.div `
    display: flex;
    overflow-x: auto;
    width: 100%;
`;
export const Table = styled.table `
    border-collapse: collapse;
    width: 100%;
`;
