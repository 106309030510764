import styled, { css } from "styled-components";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { Color, FontBodyBigBold, IconFolder, IconSettings } from "@boomq/uikit";
export const Button = styled(Link) `
    ${FontBodyBigBold}
    align-items: center;
    background: transparent;
    border: none;
    color: ${({ selected }) => (selected ? Color.blue : Color.grey50)};
    cursor: pointer;
    display: flex;
    flex: 1;
    justify-content: center;
    line-height: normal;
    outline: none;
    padding-bottom: 11px;
    position: relative;
    transition: all 0.5s ease;
    ${({ selected }) => selected
    ? css `
                  &:after {
                      background: ${Color.blue};
                      border-radius: 6px;
                      bottom: 0;
                      content: "";
                      height: 6px;
                      position: absolute;
                      transition: all 0.5s ease;
                      width: 100%;
                  }
              `
    : css ``}
`;
export const StyledIconFolder = styled(IconFolder) `
    fill: ${({ selected }) => (selected ? Color.blue : Color.grey50)};
    margin-right: 4px;
`;
export const StyledIconSettings = styled(IconSettings) `
    fill: ${({ selected }) => (selected ? Color.blue : Color.grey50)};
    margin-right: 4px;
`;
export const StyledWizard = styled.div `
    display: flex;
    padding: 25px 0 0;
    position: relative;
    &:before {
        background: ${Color.lightGrey85};
        border-radius: 6px;
        bottom: 0;
        content: "";
        height: 6px;
        left: 0;
        position: absolute;
        right: 0;
    }
`;
export const Text = styled(FormattedMessage) `
    margin-left: 10px;
`;
