export const DELETING_LABEL = {
    defaultMessage: "Delete",
    id: "account.test.project.settings.set.webhook.actions.delete"
};
export const SETTINGS_LABEL = {
    defaultMessage: "Edit request",
    id: "account.test.project.settings.set.webhook.actions.edit.request"
};
export const TEST_STATUS_SELECT_PLACEHOLDER = {
    defaultMessage: "Test run status",
    id: "account.all.tests.filters.select.test.status.placeholder"
};
