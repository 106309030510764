import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { StyledButton } from "../../styled/Buttons";
const InvalidModuleBlock = (props) => (React.createElement(InvalidModuleElList, null, (props.data || []).map((item, index) => (React.createElement("li", { key: index }, item)))));
const InvalidJMeterModule = ({ modules, plugins }) => {
    const counts = (modules || []).length + (plugins || []).length;
    return (React.createElement(InvalidJMeterModuleBody, { counts: counts },
        React.createElement(InvalidModuleBlock, { data: plugins }),
        React.createElement(ScrollableModuleBlock, null,
            React.createElement(InvalidModuleBlock, { data: modules }))));
};
const UnsupportedJMeterModulesException = (props) => (React.createElement(React.Fragment, null,
    React.createElement(ExceptionText, null,
        React.createElement(FormattedMessage, { defaultMessage: "Your test plan contains elements we do not currently support.", id: "account.new.settings.file.unsupported.jmeter.modules.text1" }),
        React.createElement(TableHead, null,
            React.createElement(TableHeadColumnPlugins, null,
                React.createElement(FormattedMessage, { defaultMessage: "Plugins", id: "account.new.settings.file.unsupported.jmeter.modules.plugins.label" })),
            React.createElement(TableHeadColumnModules, null,
                React.createElement(FormattedMessage, { defaultMessage: "Modules", id: "account.new.settings.file.unsupported.jmeter.modules.modules.label" }))),
        React.createElement(ListSection, null, (props.invalidModulesInfo || []).map((invalidModule, index) => (React.createElement(InvalidJMeterModule, Object.assign({ key: index }, invalidModule))))),
        React.createElement(FormattedMessage, { defaultMessage: "You can find full list of supported packages and plugins", id: "account.new.settings.file.unsupported.jmeter.modules.text2" }),
        React.createElement(Link, { to: "/account/supported-jmeter-modules", target: "_blank" },
            React.createElement(FormattedMessage, { defaultMessage: "here", id: "account.new.settings.file.unsupported.jmeter.modules.link" }))),
    React.createElement(ButtonSection, null,
        React.createElement(StyledButton, { className: "close_modal_window", theme: { width: "auto!important" } },
            React.createElement(FormattedMessage, { defaultMessage: "Close", id: "account.new.settings.file.unsupported.jmeter.modules.button" })))));
const ButtonSection = styled.div `
    display: flex;
    justify-content: center;
`;
const ExceptionText = styled.div `
    line-height: 30px;
    margin: 30px 0;
`;
const InvalidJMeterModuleBody = styled.div `
    box-shadow: 0 0 8px rgb(0 64 140 / 15%);
    border-radius: 5px;
    display: flex;
    margin-bottom: 15px;
    min-height: ${({ counts }) => (counts > 2 ? "90px" : "auto")}
    padding: 15px 0 15px 15px;
`;
const InvalidModuleElList = styled.ol `
    display: flex;
    flex: 1;
    flex-direction: column;
    padding-left: 20px;
`;
const ListSection = styled.div `
    display: flex;
    flex-direction: column;
`;
const ScrollableModuleBlock = styled.div `
    flex: 1;
    position: relative;
    overflow-y: auto;
    > ol {
        left: 0;
        padding-right: 10px;
        position: absolute;
        top: 0;
        width: 100%;
    }
`;
const TableHead = styled.div `
    border-radius: 5px;
    box-shadow: 0 0 8px rgb(0 64 140 / 15%);
    display: flex;
    margin-bottom: 10px;
    padding: 5px 10px;
`;
const TableHeadColumn = styled.div `
    color: #396496;
    flex: 1;
    font-weight: 500;
`;
const TableHeadColumnPlugins = styled(TableHeadColumn) `
    padding-left: 10px;
`;
const TableHeadColumnModules = styled(TableHeadColumn) `
    padding-left: 30px;
`;
export default UnsupportedJMeterModulesException;
