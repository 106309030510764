import { call, put, takeEvery } from "redux-saga/effects";
import { slaReportProfilesRequest, slaReportProfilesSuccess, slaReportProfilesFailure } from "../actions/slaReportProfiles";
import { getResponseErrorData } from "../../helpers/errors";
import { getSlaReportProfiles } from "../../services/testSrv";
function* slaReportProfileListFlow() {
    try {
        const { data } = yield call(getSlaReportProfiles);
        yield put(slaReportProfilesSuccess({ content: data }));
    }
    catch (e) {
        yield put(slaReportProfilesFailure(getResponseErrorData(e)));
    }
}
export function* slaReportProfilesFlow() {
    yield takeEvery(slaReportProfilesRequest, slaReportProfileListFlow);
}
