import styled from "styled-components";
import { Color, FontBodySmallBold, H3 } from "@boomq/uikit";
export const BlockJmxWarn = styled.div `
    align-items: center;
    display: flex;
`;
export const BlockTitleText = styled(H3) `
    margin-right: 24px;
`;
export const WarnText = styled.div `
    ${FontBodySmallBold}
    color: ${Color.darkGrey35};
    margin-left: 8px;
`;
