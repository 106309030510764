const units = {
    min: { id: 'account.all.plate.min', defaultMessage: 'min' }
};
export const testSettings = {
    "SCALABILITY": [
        {
            id: 0,
            name: "stepCount",
            label: { id: "account.new.type.slider.step.count", defaultMessage: "Number of steps" },
            min: 1,
            max: 5,
            step: 1,
            tooltipText: {
                id: 'account.slider.tooltip.step.count',
                defaultMessage: 'The number of steps to increase the load to achieve maximum performance'
            }
        },
        {
            id: 1,
            name: "stepLength",
            label: { id: "account.new.type.slider.step.length", defaultMessage: "Step length" },
            min: 15,
            max: 60,
            step: 15,
            units: units.min,
            tooltipText: {
                id: 'account.slider.tooltip.step.length',
                defaultMessage: 'Duration of the stabilized load period'
            },
            type: "time"
        },
        {
            id: 2,
            name: "durationAddedOnLastStep",
            label: { id: "account.new.type.slider.max.duration", defaultMessage: "Duration at max load" },
            min: 5,
            max: 60,
            step: 5,
            units: units.min,
            tooltipText: {
                id: 'account.slider.tooltip.duration',
                defaultMessage: 'Test duration at maximum load when all VUsers are running'
            },
            type: "time"
        },
        {
            id: 3,
            name: "rampUp",
            label: { id: "account.new.type.slider.max.racing", defaultMessage: "Ramp-up time for step" },
            min: 0,
            max: 15,
            step: 1,
            units: units.min,
            tooltipText: {
                id: 'account.slider.tooltip.max.racing',
                defaultMessage: 'Time allocated to ramp-up VUsers started within the step. If set to 0, all VUsers will start simultaneously'
            },
            type: "time"
        },
        {
            id: 4,
            name: "usersPerStep",
            label: { id: "account.new.type.slider.max.user.count", defaultMessage: "Step VUsers increment" },
            min: 1,
            max: 20,
            step: 1,
            tooltipText: {
                id: 'account.slider.tooltip.max.user.count',
                defaultMessage: 'Number of VUsers started at one step'
            }
        },
        {
            id: 5,
            name: "rampDown",
            label: { id: "account.new.type.slider.output", defaultMessage: "Ramp-down time" },
            units: units.min,
            min: 0,
            max: 15,
            step: 1,
            tooltipText: {
                id: 'account.slider.tooltip.step.output',
                defaultMessage: 'Time to stop virtual users'
            },
            type: "time"
        }
    ],
    "STABLE": [
        {
            id: 6,
            name: "stepLength",
            label: { id: "account.new.type.slider.stable.duration", defaultMessage: "Duration" },
            min: 5,
            max: 60,
            step: 5,
            units: units.min,
            tooltipText: {
                id: 'account.slider.tooltip.duration',
                defaultMessage: 'Test duration at maximum load when all VUsers are running'
            },
            type: "time"
        },
        {
            id: 7,
            name: "rampUp",
            label: { id: "account.new.type.slider.stable.racing", defaultMessage: "Ramp-up time" },
            min: 0,
            max: 15,
            step: 1,
            units: units.min,
            tooltipText: {
                id: 'account.slider.tooltip.stable.racing',
                defaultMessage: 'Time allocated to start all VUsers. If set to 0, all VUsers will start simultaneously'
            },
            type: "time"
        },
        {
            id: 8,
            name: "usersPerStep",
            label: { id: "account.new.type.slider.stable.user.count", defaultMessage: "Number of VUsers" },
            min: 1,
            max: 100,
            step: 1,
            tooltipText: {
                id: 'account.slider.tooltip.stable.user.count',
                defaultMessage: 'One VUser simulates load comparable to 10 real users (since there is no think times). Ie if your website ususally has 50 concurrent users, set the value to 5 VUsers'
            }
        },
        {
            id: 9,
            name: "rampDown",
            label: { id: "account.new.type.slider.output", defaultMessage: "Ramp-down time" },
            units: units.min,
            min: 0,
            max: 15,
            step: 1,
            tooltipText: {
                id: 'account.slider.tooltip.step.output',
                defaultMessage: 'Time to stop virtual users'
            },
            type: "time"
        }
    ],
    "JMX": [
        {
            id: 7,
            name: "initialDelay",
            label: { id: "account.new.type.slider.jmx.delay", defaultMessage: "Start delay" },
            units: units.min,
            min: 0,
            max: 1200,
            step: 1,
            tooltipText: {
                id: "account.new.type.slider.jmx.delay",
                defaultMessage: "Start delay"
            },
            type: "time"
        },
        {
            id: 8,
            name: "stepLength",
            label: { id: "account.new.type.slider.stable.duration", defaultMessage: "Duration" },
            min: 5,
            max: 1200,
            step: 5,
            units: units.min,
            tooltipText: {
                id: 'account.slider.tooltip.duration',
                defaultMessage: 'Test duration at maximum load when all VUsers are running'
            },
            type: "time"
        },
        {
            id: 9,
            name: "rampUp",
            label: { id: "account.new.type.slider.stable.racing", defaultMessage: "Ramp-up time" },
            min: 0,
            max: 15,
            step: 1,
            units: units.min,
            tooltipText: {
                id: 'account.slider.tooltip.stable.racing',
                defaultMessage: 'Time allocated to start all VUsers. If set to 0, all VUsers will start simultaneously'
            },
            type: "time"
        },
        {
            id: 10,
            name: "usersPerStep",
            label: { id: "account.new.type.slider.stable.user.count", defaultMessage: "Number of VUsers" },
            min: 1,
            max: 100,
            step: 1,
            tooltipText: {
                id: 'account.slider.tooltip.stable.user.count',
                defaultMessage: 'One VUser simulates load comparable to 10 real users (since there is no think times). Ie if your website ususally has 50 concurrent users, set the value to 5 VUsers'
            }
        },
        {
            id: 11,
            name: "rampDown",
            label: { id: "account.new.type.slider.output", defaultMessage: "Ramp-down time" },
            units: units.min,
            min: 0,
            max: 15,
            step: 1,
            tooltipText: {
                id: 'account.slider.tooltip.step.output',
                defaultMessage: 'Time to stop virtual users'
            },
            type: "time"
        }
    ],
    "CHART": [
        {
            id: 12,
            name: "startTimeLine",
            label: { defaultMessage: "Start time period", id: "account.chart.timeline.start.label" },
            units: units.min,
            min: 0,
            max: 0,
            step: 1,
            tooltipText: { defaultMessage: "Start time period", id: "account.chart.timeline.start.label" },
        },
        {
            id: 13,
            name: "endTimeLine",
            label: { defaultMessage: "End time period", id: "account.chart.timeline.end.label" },
            units: units.min,
            min: 0,
            max: 0,
            step: 1,
            tooltipText: { defaultMessage: "End time period", id: "account.chart.timeline.end.label" },
        }
    ]
};
