import { API_ERROR_TITLE, TEST_PROJECT_DETAILS_DESCRIPTION, TEST_PROJECT_DETAILS_TITLE } from "./intl";
import { showError } from "./showError";
import { getTestProjectErrorByDescription } from "../../../data/content/errorMessages";
export function* saveTestProjectFailureFlow(action) {
    var _a;
    const exceptionDesc = getTestProjectErrorByDescription((_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.message);
    const params = exceptionDesc
        ? {
            modalInfo: {
                description: exceptionDesc,
                title: API_ERROR_TITLE
            }
        }
        : {};
    yield* showError(params);
}
export function* testProjectDetailsFailureFlow() {
    yield* showError({
        modalInfo: {
            description: TEST_PROJECT_DETAILS_DESCRIPTION,
            title: TEST_PROJECT_DETAILS_TITLE
        }
    });
}
