import styled, { css } from "styled-components";
import { Color } from "../styled/colorPallete";
import { IconArrowDown } from "../Icons";
import { FontBodyBigMedium } from "../styled/typography";
export const SpoilerBody = styled.div `
    display: ${({ isOpened }) => (isOpened ? "flex" : "none")};
    flex-direction: column;
`;
export const SpoilerDefaultArrow = styled(IconArrowDown) `
    cursor: pointer;
    fill: ${Color.blue}
    position: absolute;
    right: 24px;
    top: 16px;
    transform: rotate(-90deg);
    transition: all .3s ease;
    ${({ isopened }) => isopened === "true"
    ? css `
                  transform: rotate(0deg);
              `
    : css ``}
`;
export const SpoilerDefaultHeader = styled.div `
    ${FontBodyBigMedium}
    background: transparent;
    border-bottom: 1px solid;
    border-color: ${Color.lightGrey95};
    color: ${Color.darkGrey5};
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: 16px 64px 16px 24px;
    position: relative;
`;
