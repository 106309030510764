import styled, { css } from "styled-components";
import { Color, FontBodyBigBold, IconArrowDown } from "@boomq/uikit";
import { media } from "../../styled/response";
export const HeaderLanguage = styled.div `
    position: relative;
    ${({ theme }) => (theme ? css(theme) : css ``)}
    ${media.tablet} {
        border-bottom: 1px solid #e4ebf2;
    }
`;
export const PlateText = styled.p `
    ${FontBodyBigBold}
    color: ${Color.darkGrey5};
    line-height: 23px;
    margin-left: 8px;
    text-transform: uppercase;
    width: 30px;
`;
export const IconArrow = styled(IconArrowDown) `
    fill: ${Color.grey50};
    margin-left: 4px;
`;
export const IconWrapper = styled.div `
    display: flex;
    justify-content: flex-end;
    ${media.tablet} {
        flex: 1 0 auto;
    }
`;
export const LanguageButton = styled.button `
    align-items: center;
    background: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    height: 100%;
    outline: none;
    ${media.tablet} {
        padding: 8px 32px;
        width: 100%;
    }
`;
export const IconFlag = styled.img `
    height: 20px;
    width: 20px;
`;
export const StyledLanguagePlate = styled.div `
    border-radius: 5px;
    box-shadow: 0 0 15px rgba(23, 128, 224, 0.25);
    display: flex;
    flex-direction: column;
    margin-top: 4px;
    z-index: 9;
    ${media.tablet} {
        border-radius: 0;
    }
`;
export const LanguagePlateButton = styled.button `
    background: ${Color.white};
    border: 1px solid transparent;
    border-top-color: #f2f2f2;
    color: #bdbdbd;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    min-width: 86px;
    outline: none;
    padding: 6px 14px;
    text-align: left;
    text-transform: uppercase;
    transition: all 0.3s ease;
    &:first-child {
        border: none;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        ${media.tablet} {
            border-radius: 0;
            border: 1ps solid #e4ebf2;
        }
    }
    &:last-child {
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        ${media.tablet} {
            border-radius: 0;
            border-bottom: none;
        }
    }
    &:hover {
        color: ${Color.blue};
    }
    ${media.tablet} {
        border-top-color: #e4ebf2;
        color: #6b8cae;
        margin: 0 32px;
        padding: 8px 0;
    }
`;
