import React, { useEffect, useRef, useState } from "react";
import { Bar, ProgressBarWrapper } from "./styled";
export const ProgressBar = ({ startTime, totalDuration }) => {
    const interval = useRef();
    const [width, setWidth] = useState("0");
    const update = () => {
        const now = Date.now();
        const width = ((now - startTime) * 100) / (1000 * totalDuration);
        return setWidth(`${width}%`);
    };
    useEffect(() => {
        interval.current = setInterval(update, 1000);
        return () => (interval.current ? clearInterval(interval.current) : undefined);
    }, []);
    return (React.createElement(ProgressBarWrapper, null,
        React.createElement(Bar, { progress: width })));
};
