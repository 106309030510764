import React, { FC, useState } from "react";
import { FormattedMessage } from "react-intl";
import { isEmptyArray } from "@boomq/utils";
import { Checkbox } from "@boomq/uikit";
import {
    TeamMembersItemTemplateAdd as CommonTeamMembersItemTemplateAdd,
    TeamMembersItemTemplateAddHandlers as CommonTeamMembersItemTemplateAddHandlers,
    TeamMembersItemTemplateEditorProps,
    excludeItemFromArray,
    formatEmail
} from "@boomq/common";
import { TeamMembersItemAdd } from "./styled";
import { PermissionsHandlers, PermissionsProps } from "../TeamMembersItemTemplateEdit";
import { ManageUsersBlock, ManageUsersPermissions, ManageUsersTitle } from "../TeamMembersItemTemplateEdit/styled";
import { TEAM_MEMBER_MANAGE_USERS_TITLE } from "../TeamMembersItemTemplateEdit/intl";
import { ListItem, TeamMemberMode, TeamMemberPermission } from "../../../../helpers/types";

export interface TeamMembersItemTemplateAddProps extends TeamMembersItemTemplateEditorProps, PermissionsProps {
    manageUsersItems: ListItem<TeamMemberPermission, { disabled?: boolean }>[];
    permissionItems: ListItem<TeamMemberPermission>[];
    teamId: string;
    userId: string;
}

export interface TeamMembersItemTemplateAddHandlers
    extends CommonTeamMembersItemTemplateAddHandlers,
    PermissionsHandlers {
    onToggleMode: (mode: TeamMemberMode) => void;
}

const getManageUsersPermissionsStateValue = (permissionList: TeamMemberPermission[], manageUsersItems): any[] => {
    const manageUsers: TeamMemberPermission[] = manageUsersItems.map(({ value }) => value);

    return Array.isArray(permissionList)
        ? permissionList.filter((permission: any) => manageUsers.includes(permission))
        : [];
};

const isCheckedPermission = (permissions, permissionItem): boolean => permissions.includes(permissionItem.value);

const renderManageUsersPermissions = ({
    manageUsersItems,
    manageUsersPermissionList = [],
    onToggle
}: PermissionsProps & PermissionsHandlers): JSX.Element | JSX.Element[] =>
    isEmptyArray(manageUsersItems) ? (
        <></>
    ) : (
        <ManageUsersPermissions>
            {manageUsersItems.map((manageUsersItem) => (
                <Checkbox
                    key={manageUsersItem.value}
                    checked={isCheckedPermission(manageUsersPermissionList, manageUsersItem)}
                    disabled={manageUsersItem.disabled}
                    label={manageUsersItem.text}
                    name={manageUsersItem.value}
                    onToggle={onToggle}
                    value={manageUsersItem.value}
                />
            ))}
        </ManageUsersPermissions>
    );

export const TeamMembersItemTemplateAdd: FC<TeamMembersItemTemplateAddProps & TeamMembersItemTemplateAddHandlers> = ({
    manageUsersItems,
    onToggleMode,
    ...restProps
}: TeamMembersItemTemplateAddProps & TeamMembersItemTemplateAddHandlers): JSX.Element => {
    const [manageUsersPermissionList, setManageUsersPermissionList] = useState<TeamMemberPermission[]>(
        getManageUsersPermissionsStateValue(restProps.permissionList, manageUsersItems)
    );

    const clickAddButtonHandler = ({ email, id, permissionList, userDisplayName }): void =>
        typeof restProps.onAdd === "function"
            ? restProps.onAdd({
                email: formatEmail(email),
                id,
                permissionList: [...permissionList, ...manageUsersPermissionList],
                userDisplayName
            })
            : undefined;

    const addPermission = (permission): void =>
        setManageUsersPermissionList([...manageUsersPermissionList, permission]);

    const excludePermission = (permission): void =>
        setManageUsersPermissionList(excludeItemFromArray(manageUsersPermissionList, permission));

    const toggleManageUsersPermissionHandler = ({ value }): void =>
        manageUsersPermissionList.includes(value) ? excludePermission(value) : addPermission(value);

    return (
        <TeamMembersItemAdd>
            <CommonTeamMembersItemTemplateAdd {...restProps} onAdd={clickAddButtonHandler}>
                <ManageUsersBlock>
                    <ManageUsersTitle>
                        <FormattedMessage {...TEAM_MEMBER_MANAGE_USERS_TITLE} />
                    </ManageUsersTitle>

                    {renderManageUsersPermissions({
                        manageUsersItems,
                        manageUsersPermissionList,
                        onToggle: toggleManageUsersPermissionHandler
                    })}
                </ManageUsersBlock>
            </CommonTeamMembersItemTemplateAdd>
        </TeamMembersItemAdd>
    );
};
