import { load as convertYamlToObj } from "js-yaml";
import { getUniqueId, isEmpty, objectToArray, unEscaping, unEscapingObj } from "@boomq/utils";
import { TEST_PROJECT_TRANSACTION_DEFAULT_NAME } from "./constants";
import { getTestProjectParamValue } from "./common";
import { parseHttpRequestDefaults } from "./configurationElements";
import { prepareSlaGroupList } from "./sla";
import { testProfilePercMin } from "../applicationParams";
import { generateQueryParams } from "../generators";
import { parseTestProjectParameters } from "../requestParameters";
import { ExtractorType } from "../types";
import { getExtractorMatchByMatchType } from "../extractResponse";
import { testSettings } from "../../data/content/testSettings";
const requestUnEscaping = (request) => (Object.assign(Object.assign({}, request), { url: unEscaping(request.url || ""), body: unEscaping(request.body || ""), headers: unEscapingObj(request.headers || {}), params: unEscapingObj(request.params || {}) }));
const getTestParams = (data) => {
    const testParams = {};
    ((testSettings && testSettings.SCALABILITY) || []).forEach((setting) => {
        data &&
            !isEmpty(data[setting.name]) &&
            (testParams[setting.name] = Number(setting.type === "time" ? data[setting.name] / 60 : data[setting.name]));
    });
    return testParams;
};
export const getStartTimeLine = (steps) => Math.min(...(steps || []).reduce((res, step) => [...res, step.initialDelay || 0], []));
export const getEndTimeLine = (steps) => Math.max(...(steps || []).reduce((res, step) => [
    ...res,
    (step.initialDelay || 0) + (step.rampUp || 0) + (step.rampDown || 0) + (step.stepLength || 0)
], []));
export const parseCsvParameterNames = (data = []) => (data[0] && data[0].join(",")) || "";
const getTestProjectTransactionName = (index, name) => isEmpty(name) ? `${TEST_PROJECT_TRANSACTION_DEFAULT_NAME} ${index + 1}` : name;
const prepareTestProjectRequestGroup = (groupId, index, resourceConfiguration) => (Object.assign({ id: groupId, index: index + 1, isOpened: true }, prepareTestProjectRequestGroupResources(resourceConfiguration)));
const prepareManualRequestsTestProjectTransactionName = (name = "", groupId, index, resourceConfiguration) => (Object.assign(Object.assign({}, prepareTestProjectRequestGroup(groupId, index, resourceConfiguration)), { name: getTestProjectTransactionName(index, name) }));
const prepareTestProjectGroupRequestCommonParams = (request, groupId, isUnEscapingRequired) => ({
    id: request.id || getUniqueId(),
    groupId,
    requestUrl: request.url,
    method: request.method,
    body: { TEXT: request.body },
    headers: (request.headers && objectToArray(request.headers, "key", "value")) || [],
    extractResponse: prepareExtractors(request.extractors || {}, isUnEscapingRequired),
    queryParams: (request.params && objectToArray(request.params, "key", "value")) ||
        (request.url.search(/\?/) > -1 && generateQueryParams(request.url)) ||
        [],
    timerList: request.timerList || [],
    responsePreview: request.responsePreview
});
const prepareTestProjectGroupRequests = (groupId, requests, isUnEscapingRequired) => (requests || []).reduce((res, request) => {
    const unEscapedRequest = isUnEscapingRequired ? requestUnEscaping(request) : request;
    const requestTimers = getTimersFromRequest(unEscapedRequest);
    return {
        requests: [
            ...res.requests,
            prepareTestProjectGroupRequestCommonParams(Object.assign(Object.assign({}, unEscapedRequest), { timerList: Object.keys(requestTimers) }), groupId, isUnEscapingRequired)
        ],
        timers: Object.assign(Object.assign({}, res.timers), requestTimers)
    };
}, { requests: [], timers: {} });
export const prepareCommonTestProjectData = (testProjectData, testProjectYamlData, isUnEscapingRequired) => (Object.assign(Object.assign({ comment: "", contentModelVersion: getTestProjectParamValue(testProjectData, "contentModelVersion"), currentVersion: testProjectData.currentVersion, httpRequestDefaults: parseHttpRequestDefaults(testProjectYamlData.httpRequestDefaults), projectName: testProjectData.projectName, projectId: testProjectData.projectId }, prepareTestProjectRequestGroupRunnerLocations(testProjectYamlData.resourceConfiguration)), { settingsId: testProjectData.settingsId, sla: prepareSlaGroupList(testProjectYamlData.slaGroupList), testType: testProjectData.testType, versions: testProjectData.versions }));
const getTimersFromRequest = (request) => (request.timerList || []).reduce((res, timer) => (Object.assign(Object.assign({}, res), { [timer.id]: timer })), {});
const prepareTestProjectRequestGroupRunnerLocations = (resourceConfiguration) => {
    const { cloudTestRunLocationRequirements } = resourceConfiguration || {};
    return cloudTestRunLocationRequirements && Array.isArray(cloudTestRunLocationRequirements.testRunLocationIds)
        ? {
            runnerLocations: cloudTestRunLocationRequirements.testRunLocationIds
        }
        : {};
};
const prepareTestProjectRequestGroupRunnerResources = ({ testRunnerIds }) => Array.isArray(testRunnerIds)
    ? {
        runnerResources: testRunnerIds
    }
    : {};
const prepareTestProjectRequestGroupResources = (resourceConfiguration) => resourceConfiguration
    ? Object.assign({}, prepareTestProjectRequestGroupRunnerResources(resourceConfiguration)) : {};
const prepareDefaultTimer = (testProjectDetailsData, testProjectYamlData) => (Object.assign(Object.assign({}, testProjectDetailsData.configDefaultTimer), testProjectYamlData.defaultTimer));
export const prepareRequestsTestProjectData = (testProjectData, isUnEscapingRequired) => (Object.assign(Object.assign({}, getTestParams(testProjectData)), (testProjectData.groups || []).reduce((res, group, index) => {
    const groupId = getUniqueId();
    const { requests, timers } = prepareTestProjectGroupRequests(groupId, group.requests, isUnEscapingRequired);
    return {
        extractorParameters: [],
        groups: [
            ...res.groups,
            Object.assign(Object.assign({}, prepareManualRequestsTestProjectTransactionName(group.name, groupId, index, group.resourceConfiguration)), { perc: group.perc || testProfilePercMin })
        ],
        requests: [...res.requests, ...requests],
        timers: Object.assign(Object.assign({}, res.timers), timers)
    };
}, { extractorParameters: [], groups: [], requests: [], timers: {} })));
export const parseRequestsTestProjectFromYaml = (testProjectData, isUnEscapingRequired) => {
    const testProjectYamlData = convertYamlToObj(testProjectData.yaml);
    return Object.assign(Object.assign(Object.assign({}, prepareCommonTestProjectData(testProjectData, testProjectYamlData, isUnEscapingRequired)), { defaultTimer: prepareDefaultTimer(testProjectData, testProjectYamlData), requestParameters: parseTestProjectParameters(testProjectYamlData.parameters) }), prepareRequestsTestProjectData(testProjectYamlData, isUnEscapingRequired));
};
const prepareExtractors = (extractors = {}, isUnEscapingRequired) => Object.keys(extractors || {}).reduce((res, extractorName) => [
    ...res,
    {
        id: getUniqueId(),
        variable: extractorName,
        params: prepareExtractExpressionByType(extractors[extractorName].type, extractors[extractorName].extractorParameters, isUnEscapingRequired),
        match: getExtractorMatchByMatchType(extractors[extractorName].match),
        type: extractors[extractorName].type
    }
], []);
const prepareExtractExpressionByType = (extractorType, parameters, isUnEscapingRequired) => {
    const unEscapedParameters = isUnEscapingRequired ? unEscapingObj(parameters) : parameters;
    switch (extractorType) {
        case ExtractorType.Boundary:
            return {
                leftBorder: unEscapedParameters.left,
                rightBorder: unEscapedParameters.right,
                fieldToCheck: unEscapedParameters.fieldToCheck
            };
        case ExtractorType.RegEx:
            return {
                re: unEscapedParameters.re,
                groupNumber: unEscapedParameters.group,
                fieldToCheck: unEscapedParameters.fieldToCheck
            };
        case ExtractorType.JsonPath:
            return { jsonpath: unEscapedParameters.jsonpath };
        case ExtractorType.XPath:
            return { xpath: unEscapedParameters.xpath };
        case ExtractorType.XPath2:
            return { xpath: unEscapedParameters.expression };
        default: {
            return {};
        }
    }
};
