import styled from "styled-components";
import { Color, FontBodyBigMedium, FontBodyMediumBold, IconDelete } from "@boomq/uikit";
export const ActionSection = styled.div `
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`;
export const DeleteButton = styled.div `
    ${FontBodyMediumBold}
    align-items: flex-end;
    color: ${Color.blue};
    cursor: pointer;
    display: flex;
`;
export const DownloadCsvFileLink = styled.span `
    cursor: pointer;
    font-weight: 500;
    text-decoration: underline;
`;
export const ParameterHeader = styled.div `
    ${FontBodyBigMedium}
    background: transparent;
    border-bottom: 1px solid;
    color: ${({ isExistFiles }) => (isExistFiles ? Color.darkGrey5 : Color.grey50)};
    display: flex;
    justify-content: space-between;
    padding: 16px 64px 16px 24px;
    border-color: ${({ isOpened }) => (isOpened ? Color.lightGrey95 : "transparent")};
`;
export const StyledIconDelete = styled(IconDelete) `
    fill: ${Color.blue};
    height: 20px;
    margin-right: 6px;
    width: 20px;
`;
