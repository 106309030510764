var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { keyValueTheme } from "./styled";
import { KeyValue } from "../../../../components/account/KeyValue";
export const JMeterConfigPropertyContainer = (_a) => {
    var { data, onChange, onDelete, propertyNameInputLabel, propertyValueInputLabel, propertyNameInputPlaceholder, propertyValueInputPlaceholder } = _a, restProps = __rest(_a, ["data", "onChange", "onDelete", "propertyNameInputLabel", "propertyValueInputLabel", "propertyNameInputPlaceholder", "propertyValueInputPlaceholder"]);
    const deletePropertyHandler = () => onDelete === null || onDelete === void 0 ? void 0 : onDelete(data === null || data === void 0 ? void 0 : data.id);
    return (React.createElement(KeyValue, { onChange: onChange, onDelete: deletePropertyHandler, labelKey: propertyNameInputLabel, labelValue: propertyValueInputLabel, placeholderKey: propertyNameInputPlaceholder, placeholderValue: propertyValueInputPlaceholder, theme: keyValueTheme, value: data }));
};
