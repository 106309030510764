var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { IconRemoveSection, RemoveButton, RequestParam, StyledCheckbox, StyledInputWithParameters } from "./styled";
import { IconDeleteGrey } from "../../styled/StyledIcons";
import { NEW_ITEM_ID } from "../../../helpers/routes/constants";
const renderButtonDelete = (disabled, itemId = "", onDelete) => {
    const clickHandler = () => (typeof onDelete === "function" ? onDelete(itemId) : undefined);
    return disabled || (itemId || "").includes(NEW_ITEM_ID) ? (React.createElement(React.Fragment, null)) : (React.createElement(RemoveButton, { onClick: clickHandler },
        React.createElement(IconDeleteGrey, null)));
};
export const DefaultCookie = (_a) => {
    var { data, disabled, httpsOnlyCheckboxLabel = "HTTPS only", onChange, onDelete, placeholderDomain = "Domain", placeholderName = "Name", placeholderPath = "Path", placeholderValue = "Value", theme } = _a, rest = __rest(_a, ["data", "disabled", "httpsOnlyCheckboxLabel", "onChange", "onDelete", "placeholderDomain", "placeholderName", "placeholderPath", "placeholderValue", "theme"]);
    const blurValidationFunc = (newValue = "") => (newValue || "").trim();
    const changeHandler = (field, newValue) => typeof onChange === "function" ? onChange(data, field, newValue) : undefined;
    const toggleHandler = () => changeHandler("secure", !data.secure);
    return (React.createElement(RequestParam, { theme: theme },
        React.createElement(StyledInputWithParameters, Object.assign({ disabled: disabled, error: data.error, label: placeholderName, value: data }, rest, { onBlurFormatFunc: blurValidationFunc, onChange: changeHandler, paramName: "name", placeholder: placeholderName })),
        React.createElement(StyledInputWithParameters, Object.assign({ disabled: disabled, error: data.error, label: placeholderValue, value: data }, rest, { onChange: changeHandler, paramName: "value", placeholder: placeholderValue })),
        React.createElement(StyledInputWithParameters, Object.assign({ disabled: disabled, label: placeholderDomain, value: data }, rest, { onChange: changeHandler, paramName: "domain", placeholder: placeholderDomain })),
        React.createElement(StyledInputWithParameters, Object.assign({ disabled: disabled, label: placeholderPath, value: data }, rest, { onChange: changeHandler, paramName: "path", placeholder: placeholderPath })),
        React.createElement(StyledCheckbox, { checked: data.secure, disabled: disabled, label: httpsOnlyCheckboxLabel, name: "httpsOnly", onToggle: toggleHandler }),
        React.createElement(IconRemoveSection, null, renderButtonDelete(disabled, data.id, onDelete))));
};
