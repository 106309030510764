import React from "react";
import { Spoiler } from "@boomq/uikit";
import { AxisYSelect, ChartSettingsRow } from "./styled";
export const ChartCommonSettings = ({ axisLeftY, axisRightY, axisLeftYItemList, axisRightYItemList, chartSettingsHeader, endTime, onChangeSetting, startTime }) => {
    const changeLeftAxisYHandler = (data) => onChangeSetting({ axisLeftY: data.value });
    const changeRightAxisYHandler = (data) => onChangeSetting({ axisRightY: data.value });
    return (React.createElement(Spoiler, { header: chartSettingsHeader },
        React.createElement(ChartSettingsRow, null,
            React.createElement(AxisYSelect, { defaultValue: axisLeftY, items: axisLeftYItemList, label: "Left axis-Y", onChange: changeLeftAxisYHandler, placeholder: "select left axis-Y" }),
            React.createElement(AxisYSelect, { defaultValue: axisRightY, items: axisRightYItemList, label: "Right axis-Y", onChange: changeRightAxisYHandler, placeholder: "select right axis-Y" }))));
};
