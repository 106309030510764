var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { useIntl } from "@boomq/utils";
import { TeamsItemTemplate } from "../../../components/account/TeamsItemTemplate";
import { TEAMS_ITEM_MEMBERS_TEXT } from "./intl";
const WrappedTeamsItemTemplate = (_a) => {
    var { team } = _a, restProps = __rest(_a, ["team"]);
    const { formatMessage } = useIntl();
    const { teamMemberCount } = team || {};
    const membersCountText = formatMessage(TEAMS_ITEM_MEMBERS_TEXT, { teamMemberCount });
    return React.createElement(TeamsItemTemplate, Object.assign({ membersCountText: membersCountText, team: team }, restProps));
};
export default WrappedTeamsItemTemplate;
