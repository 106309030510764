import React, { Component } from "react";
import { connect } from "react-redux";
import { getUnique } from "@boomq/utils";
import { Padding } from "../../styled/Wrappers";
import { sizes, withResize } from "../../styled/response";
import Pagination from "../../common/Pagination";
import { AllTestsPanel } from "../../account/AllTestsPanel";
import { StatusLegend } from "../../account/StatusLegend";
import { TestItem } from "../../account/TestItem";
import { messages } from "../../../data/content/endedTestTitle";
import { showPlate, tasksRequest } from "../../../redux/actions/admin/tasks";
import { getListOfTasks, getTasksCurrentPage, getTasksTotalPages } from "../../../redux/reducers/admin/tasks";
const connector = connect((state) => ({
    currentPage: getTasksCurrentPage(state),
    listOfTasks: getListOfTasks(state),
    totalPages: getTasksTotalPages(state)
}), { tasksRequest, showPlate });
class Tasks extends Component {
    constructor() {
        super(...arguments);
        this.clickHandler = (page) => this.props.tasksRequest({ page });
    }
    componentDidMount() {
        const { currentPage } = this.props;
        this.props.tasksRequest({ page: currentPage });
    }
    render() {
        const { currentPage, listOfTasks, totalPages, windowSize: { width } } = this.props;
        const statuses = listOfTasks ? listOfTasks.map(({ state }) => state) : [];
        return (React.createElement(Padding, null,
            React.createElement(StatusLegend, { statuses: getUnique(statuses) }),
            width > sizes.tablet && React.createElement(AllTestsPanel, { messages: messages }),
            Array.isArray(listOfTasks) &&
                listOfTasks.map((task) => React.createElement(TestItem, { key: task.id, task: task, width: width })),
            totalPages > 1 && (React.createElement(Pagination, { currentPage: currentPage, totalPages: totalPages, clickHandler: this.clickHandler }))));
    }
}
export default withResize(connector(Tasks));
