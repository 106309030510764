import React from "react";
import { connect } from "react-redux";
import { useIntl } from "@boomq/utils";
import { DragAndDropIcon, DragAndDropIconBlock, Transaction, TransactionHeader, TransactionElBody } from "./styled";
import { ControllerButtonDelete } from "../ControllerButtonDelete";
import { ControllerChildrenCounter } from "../ControllerChildrenCounter";
import { Actions } from "../RequestHttpElement";
import { TransactionToggler } from "../TransactionToggler";
import { EditableTitle } from "../../../common/EditableTitle";
import { formatIntlMessage } from "../../../../helpers/intl";
import { nameValidator } from "../../../../helpers/validators";
import { deleteEditorElementByPath, setElementField } from "../../../../redux/actions/editor";
import { getEditorTreeData, getElementById } from "../../../../redux/reducers/editor";
const connector = connect((state, ownProps) => ({
    nodeData: getElementById(state, ownProps.node.id),
    treeData: getEditorTreeData(state)
}), {
    onChange: setElementField,
    onDelete: deleteEditorElementByPath
});
const TransactionElement = ({ changeActiveDnD, children, expanded, node, nodeData, onChange, onDelete, onTogglerClick, path, treeData }) => {
    const { formatMessage } = useIntl();
    const blurHandler = () => (typeof changeActiveDnD === "function" ? changeActiveDnD(true) : undefined);
    const changeTransactionNameHandler = (value) => onChange({ field: "name", id: node.id, value });
    const focusHandler = () => (typeof changeActiveDnD === "function" ? changeActiveDnD(false) : undefined);
    const toggleTransactionStateHandler = () => onTogglerClick(!expanded);
    const validateFunc = (value) => {
        const { isValid, error } = nameValidator(value);
        return { isValid, error: formatIntlMessage(error, formatMessage) };
    };
    return (React.createElement(Transaction, { "data-id": node.id },
        React.createElement(DragAndDropIconBlock, null,
            React.createElement(DragAndDropIcon, null)),
        React.createElement(TransactionElBody, null,
            React.createElement(TransactionHeader, null,
                React.createElement(TransactionToggler, { isExpanded: expanded, onChange: toggleTransactionStateHandler }),
                React.createElement(ControllerChildrenCounter, { node: node }),
                React.createElement(EditableTitle, { onBlur: blurHandler, onChange: changeTransactionNameHandler, onFocus: focusHandler, validateFunc: validateFunc, value: nodeData.name }),
                React.createElement(Actions, null,
                    React.createElement(ControllerButtonDelete, { onClick: onDelete, path: path, treeData: treeData })))),
        children));
};
export default connector(TransactionElement);
