import React from "react";
import { AuthHeader, AuthRootPlateWrapper, AuthWrapper } from "@boomq/common";
import { AuthorizePlateContainer } from "../../../containers/website/AuthorizePlate";
import { ChangePasswordPlateContainer } from "../../../containers/website/ChangePasswordPlate";
import logo from "../../../assets/images/logo.svg";

const RootForm = (props) => {
    const getPlate = () => {
        switch (props.location?.pathname) {
            case "/authorize":
                return <AuthorizePlateContainer />;
            case "/change-password":
            case "/reset-password":
                return <ChangePasswordPlateContainer />;
            default:
                return <AuthorizePlateContainer />;
        }
    };

    return (
        <AuthWrapper>
            <AuthHeader logo={logo} />

            <AuthRootPlateWrapper>{getPlate()}</AuthRootPlateWrapper>
        </AuthWrapper>
    );
};

export default RootForm;
