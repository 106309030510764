import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { DataTableList, DataTableSection, StyledPagination, paginationTheme } from "./styled";
import Pagination from "../Pagination";
import { isEmpty } from "../../../helpers/utils";
export const DataTable = (props) => {
    const [currentPage, setCurrentPage] = useState(0);
    const { items, pageSize } = props;
    const clickHandler = (num) => setCurrentPage(Number(num));
    const getTotalPages = (items, pageSize) => !isEmpty(items) && pageSize > 0 ? Math.ceil(items.length / pageSize) : 0;
    const getFilteredItems = (items, page, pageSize) => getTotalPages(items, pageSize) > 0
        ? items.filter((item, index) => index >= page * pageSize && index < (page + 1) * pageSize)
        : items;
    const DataTableWrapper = (props) => {
        return (React.createElement(DataTableSection, null,
            !props.isPagingTop && props.children,
            props.totalPageCount > 1 && (React.createElement(StyledPagination, { theme: paginationTheme },
                React.createElement(Pagination, { clickHandler: clickHandler, currentPage: currentPage, pageSize: Number(props.pageSize || 0), totalPages: props.totalPageCount }))),
            props.isPagingTop && props.children));
    };
    return (React.createElement(DataTableWrapper, Object.assign({}, props, { totalPageCount: getTotalPages(items, pageSize) }), isEmpty(items) ? (React.createElement(FormattedMessage, { id: "account.data.table.empty", defaultMessage: "no data yet" })) : (React.createElement(DataTableList, null, getFilteredItems(items, currentPage, pageSize).map((item, index) => typeof props.itemTemplate === "function" ? (React.createElement(props.itemTemplate, Object.assign({}, item, { key: item.id }))) : (React.createElement("div", { key: index }, item)))))));
};
