import React from "react";
import { useIntl } from "@boomq/utils";
import { LINK_GO_GRAFANA } from "./intl";
import { StyledLink, TooltipLinkContainer } from "./styled";
export const CustomTooltipLink = ({ tooltipPosition, url }) => {
    const { formatMessage } = useIntl();
    const linkGoGrafana = formatMessage(LINK_GO_GRAFANA);
    return (React.createElement(TooltipLinkContainer, { left: tooltipPosition.x, top: tooltipPosition.y },
        React.createElement(StyledLink, { href: `${url}&viewPanel=15`, target: "_blank", rel: "noreferrer noopener" }, linkGoGrafana)));
};
