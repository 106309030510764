import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getTimerListWithDefaultTimer } from "../../../../helpers/requestTimers";
import { RequestTimers } from "../../../../components/account/TestProject/RequestTimers";
import { withResize } from "../../../../components/styled/response";
import { newRequestAddTimer, newRequestChangeTimer, newRequestDeleteFieldParam } from "../../../../redux/actions/newRequest";
import { getNewRequestTimerList } from "../../../../redux/reducers/newRequest";
import { getDefaultTimerSettings } from "../../../../redux/reducers/newTest";
const getTimerList = (state) => {
    const timerList = getNewRequestTimerList(state) || [];
    const defaultTimerSettings = getDefaultTimerSettings(state);
    return getTimerListWithDefaultTimer(timerList, defaultTimerSettings);
};
const connector = connect((state) => ({
    timerList: getTimerList(state)
}), { onAdd: newRequestAddTimer, onChange: newRequestChangeTimer, onDelete: newRequestDeleteFieldParam });
export default withResize(withRouter(connector(RequestTimers)));
