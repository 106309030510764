import React from "react";
import { FormattedMessage } from "react-intl";
import Card from "./Card";
import { WELCOME_ALL_TESTS_PROJECTS_SUBTITLE, WELCOME_ALL_TESTS_PROJECTS_TITLE } from "./intl";
import { StyledCardsWrapper, StyledContainer, StyledSubtitle, StyledTitle } from "./styled";
import { CARDS_CONTENT } from "../../../models/welcomeCardsData";
export const AllTestProjectsEmpty = ({ onClickhandlerCardButton }) => {
    return (React.createElement(StyledContainer, null,
        React.createElement(StyledTitle, null,
            React.createElement(FormattedMessage, Object.assign({}, WELCOME_ALL_TESTS_PROJECTS_TITLE))),
        React.createElement(StyledSubtitle, null,
            React.createElement(FormattedMessage, Object.assign({}, WELCOME_ALL_TESTS_PROJECTS_SUBTITLE))),
        React.createElement(StyledCardsWrapper, null, CARDS_CONTENT.map((card, index) => (React.createElement(Card, { key: index, title: card.title, text: card.text, buttonLabel: card.buttonLabel, onClickCardButton: () => onClickhandlerCardButton(index) }))))));
};
