var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { convertMsToSec } from "../date";
import { RequestRandomTimerType, RequestTimerParams, RequestTimerType } from "../types";
import { getTimerType } from "../../models/requestTimer";
export const getTimersFromRequest = (request, groupId) => (request.timerList || []).reduce((res, timer) => (Object.assign(Object.assign({}, res), { [timer.id]: Object.assign(Object.assign({}, prepareTimerFromDtoByType(timer)), { groupId }) })), {});
const prepareConstantTimer = (_a) => {
    var { durationMs } = _a, restParams = __rest(_a, ["durationMs"]);
    return (Object.assign({ duration: convertMsToSec(durationMs), [RequestTimerParams.Type]: RequestTimerType.Constant }, restParams));
};
const prepareRandomTimer = (_a) => {
    var { lowerLimitMs, upperLimitMs } = _a, restParams = __rest(_a, ["lowerLimitMs", "upperLimitMs"]);
    return (Object.assign(Object.assign({}, restParams), { lowerLimit: convertMsToSec(lowerLimitMs), randomTimerType: RequestRandomTimerType.Uniform, [RequestTimerParams.Type]: RequestTimerType.Random, upperLimit: convertMsToSec(upperLimitMs) }));
};
const timerConvertFuncToDtoByType = {
    [RequestTimerType.Constant]: prepareConstantTimer,
    [RequestRandomTimerType.Uniform]: prepareRandomTimer
};
export const prepareTimerFromDtoByType = (timerParams) => {
    const timerConvertFunc = timerConvertFuncToDtoByType[getTimerType(timerParams)];
    return typeof timerConvertFunc === "function" ? timerConvertFunc(timerParams) : timerParams;
};
export const prepareTimerList = (timerList) => (timerList || []).map(prepareTimerFromDtoByType);
const getDefaultTimerData = (defaultTimerData) => defaultTimerData ? prepareTimerFromDtoByType(defaultTimerData) : {};
export const getDefaultTimersData = (defaultTimers) => Array.isArray(defaultTimers) ? getDefaultTimerData(defaultTimers[0]) : {};
export const getDefaultTimerFromYaml = (testProjectDetailsData, testProjectYamlData) => (Object.assign(Object.assign({}, getDefaultTimerData(testProjectDetailsData.configDefaultTimer)), getDefaultTimersData(testProjectYamlData.timers)));
