var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { dump as convertObjToYaml } from "js-yaml";
import { arrayToObject, getUnique, getUniqueId, getValueByPath, isEmpty, isEmptyArray, round } from "@boomq/utils";
import { TIME_COEFFICIENT } from "./constants";
import { getContentModelVersion } from "./generators2";
import { convertSecToMs } from "../date";
import { getExtractorMatchByMatchType } from "../extractResponse";
import { getLoadProfileParams } from "../loadProfile";
import { TIMEOUT_TIME_COEFFICIENT } from "../parsers";
import { getRequestById } from "../request";
import { prepareCsvParameters, prepareLiteralParameters } from "../requestParameters";
import { getTimerTypeValuesByType, getTimerValueByType, isEmptyTimerParams } from "../requestTimers";
import { getSlaRequestName, getSlaRestrictionType, getSlaTransactionFilterType, getSlaTransactionName, SLA_REQUEST_NAME_PARAM_NAME, SLA_RESTRICTION_TYPE_PARAM_NAME, SLA_TARGET_TYPE_PARAM_NAME, SLA_TRANSACTION_FILTER_TYPE_PARAM_NAME, SLA_TRANSACTION_NAME_PARAM_NAME, SLA_TYPE_PARAM_NAME } from "../sla";
import { ExtractorType, TestType, RequestTimerParams } from "../types";
import { getTimerType } from "../../models/requestTimer";
import { SlaStatus, SlaTargetType, SlaTransactionFilterType, SlaType } from "../../models/sla";
import { ThreadGroupController } from "../../models/threadGroupEditor";
const generateExtractorParametersByType = (extractorType, value) => {
    switch (extractorType) {
        case ExtractorType.Boundary:
            return { left: value.leftBorder, right: value.rightBorder, fieldToCheck: value.fieldToCheck };
        case ExtractorType.RegEx:
            return { re: value.re, group: value.groupNumber, fieldToCheck: value.fieldToCheck };
        case ExtractorType.JsonPath:
            return { jsonpath: value.jsonpath };
        case ExtractorType.XPath:
            return { xpath: value.xpath };
        default: {
            return {};
        }
    }
};
const generateExtractors = (extractors = []) => extractors.reduce((res, extractor) => !isEmpty(extractor.variable)
    ? Object.assign(Object.assign({}, res), { [extractor.variable]: {
            match: getExtractorMatchByMatchType(extractor.match),
            type: extractor.type,
            extractorParameters: generateExtractorParametersByType(extractor.type, extractor.params)
        } }) : res, {});
export const generateGroupsPercentages = (groups = []) => {
    const enabledGroups = groups.filter((group) => group.enabled);
    const enabledGroupsCount = enabledGroups.length;
    const averagePercentage = Math.floor(100 / enabledGroupsCount);
    const lastEnabledGroup = enabledGroups[enabledGroupsCount - 1];
    const lastGroupPercentage = 100 - averagePercentage * (enabledGroupsCount - 1);
    return groups.map((group, index) => group.enabled
        ? Object.assign(Object.assign({}, group), { perc: group.id === lastEnabledGroup.id ? lastGroupPercentage : averagePercentage }) : group);
};
const getRampPointValue = (points, time, usersPerStep) => points.filter((point) => point.start === time || point.finish === time).length > 1 ? usersPerStep : 0;
const getPointUsersByTime = (points, time) => {
    const filteredPointsByTime = points.filter((point) => time >= point.start && time <= point.finish);
    const pointsByGroups = filteredPointsByTime.reduce((res, point) => {
        const usersPerStep = point.usersPerStep
            ? getRampPointValue(points, time, point.usersPerStep)
            : point.usersFunc(time);
        const pointGroupId = point.groupId || "";
        const usersPerStepByGroupId = res[pointGroupId] && usersPerStep < res[pointGroupId] ? res[pointGroupId] : usersPerStep;
        return Object.assign(Object.assign({}, res), { [pointGroupId]: usersPerStepByGroupId });
    }, {});
    return Object.values(pointsByGroups).reduce((res, usersPerStep) => res + usersPerStep, 0);
};
function getPointsByTime(points, time) {
    const isExistRampPoint = time > 0 &&
        points.filter((point) => !isEmpty(point.usersPerStep) && time >= point.start && time < point.finish).length > 0;
    return isExistRampPoint
        ? [
            { time, users: getPointUsersByTime(points, time) },
            { time: time - 1, users: getPointUsersByTime(points, time - 1) }
        ]
        : [{ time, users: getPointUsersByTime(points, time) }];
}
export function generatePreviewChartData(chartStepsData, timeLine, isMergedChart, params) {
    try {
        const chartSteps = chartStepsData.map((step) => (Object.assign(Object.assign({}, step), { timeLine })));
        return isMergedChart
            ? generateMergedStepsPreviewData(chartSteps, timeLine)
            : generateStepPreviewData(chartSteps[0]);
    }
    catch (e) {
        console.error(e);
        return [];
    }
}
const getFilteredPointsByTimeline = (timePoints = [], startTimeline = 0, endTimeline = 0) => {
    const filteredPointsByTimeline = (timePoints || []).filter((timePoint) => timePoint >= startTimeline && timePoint <= endTimeline);
    const filteredPointsByStartTimeline = Math.min(...timePoints) < startTimeline
        ? [startTimeline, ...filteredPointsByTimeline]
        : filteredPointsByTimeline;
    return Math.max(...timePoints) > endTimeline
        ? [...filteredPointsByTimeline, endTimeline]
        : filteredPointsByStartTimeline;
};
export function generateMergedStepsPreviewData(chartPreviewData, timelineData) {
    const timeCoefficient = 60;
    const timeCoefficientInSec = 60 * 60;
    const timeline = chartPreviewData.reduce((res, previewData) => [
        ...res,
        ...generatePreviewDataPoints(previewData, timeCoefficient)
    ], []);
    const timePoints = timeline.reduce((res, point) => [...res, point.start, point.finish], []);
    const uniqueTimePoints = getUnique(timePoints).map((point) => Number(point));
    const startTimeline = !isEmptyArray(timelineData) ? timelineData[0] : 0;
    const endTimeline = !isEmptyArray(timelineData) ? timelineData[1] : Math.max(...uniqueTimePoints);
    const startTimelineWithCoeff = timeCoefficient * startTimeline;
    const endTimelineWithCoeff = timeCoefficient * endTimeline;
    const filteredPointsByTimeline = getFilteredPointsByTimeline(uniqueTimePoints, startTimelineWithCoeff, endTimelineWithCoeff);
    return filteredPointsByTimeline
        .reduce((res, time) => [...res, ...getPointsByTime(timeline, time)], [])
        .sort((a, b) => a.time - b.time)
        .map((point) => ({ time: point.time / timeCoefficientInSec, users: point.users }));
}
export function generatePreviewDataPoints(params, sourceTimeCoefficient) {
    const result = [];
    const { groupId, initialDelay = 0, rampDown = 0, rampUp = 0, stepLength, timeLine = [], usersPerStep = 0 } = params;
    const startTimeLine = !isEmptyArray(timeLine) ? timeLine[0] : 0;
    const endTimeLine = !isEmptyArray(timeLine) ? timeLine[1] : (initialDelay || 0) + rampUp + stepLength + rampDown;
    const timeCoefficient = sourceTimeCoefficient || 1;
    const initialDelayWithCoeff = timeCoefficient * (initialDelay || 0);
    let rampUpWithCoeff = timeCoefficient * rampUp;
    let rampDownWithCoeff = timeCoefficient * rampDown;
    let stepLengthWithCoeff = timeCoefficient * stepLength;
    stepLengthWithCoeff = rampDown === 0 ? stepLengthWithCoeff - 1 : stepLengthWithCoeff;
    stepLengthWithCoeff = rampUp === 0 ? stepLengthWithCoeff - 1 : stepLengthWithCoeff;
    rampDownWithCoeff = rampDown === 0 ? 1 : rampDownWithCoeff;
    rampUpWithCoeff = rampUp === 0 ? 1 : rampUpWithCoeff;
    const rampUpTime = (initialDelay || 0) + rampUp;
    const holdTime = rampUpTime + stepLength;
    const rampDownFunction = (params) => {
        const { rampDown, rampUp, usersPerStep, initialDelay, stepLength } = params || {};
        return (time = 0) => rampDown === 0 || isEmpty(time)
            ? 0
            : round(((initialDelay || 0) + stepLength + rampUp + rampDown - time) * (usersPerStep / rampDown), 1);
    };
    const rampUpFunction = (params) => {
        const { initialDelay, rampUp, usersPerStep } = params;
        return (time = 0) => rampUp === 0 || isEmpty(time)
            ? 0
            : round((usersPerStep / rampUp) * (Number(time) - (initialDelay || 0)), 1);
    };
    if (startTimeLine >= (initialDelay || 0) + rampUp + stepLength + rampDown) {
        return result;
    }
    result.push({
        groupId,
        start: initialDelayWithCoeff,
        finish: initialDelayWithCoeff + rampUpWithCoeff,
        usersFunc: rampUpFunction({ rampUp: rampUpWithCoeff, usersPerStep, initialDelay: initialDelayWithCoeff }),
        usersPerStep: rampUp === 0 ? usersPerStep : undefined
    });
    holdTime > startTimeLine &&
        rampUpTime <= endTimeLine &&
        result.push({
            groupId,
            usersFunc: () => usersPerStep,
            start: initialDelayWithCoeff + rampUpWithCoeff,
            finish: initialDelayWithCoeff + rampUpWithCoeff + stepLengthWithCoeff
        });
    result.push({
        groupId,
        start: initialDelayWithCoeff + rampUpWithCoeff + stepLengthWithCoeff,
        finish: initialDelayWithCoeff + rampUpWithCoeff + stepLengthWithCoeff + rampDownWithCoeff,
        usersFunc: rampDownFunction({
            initialDelay: initialDelayWithCoeff,
            rampDown: rampDownWithCoeff,
            rampUp: rampUpWithCoeff,
            stepLength: stepLengthWithCoeff,
            usersPerStep
        }),
        usersPerStep: rampDown === 0 ? usersPerStep : undefined
    });
    return result;
}
export const generateRequestUrl = (url, queryParams) => {
    const baseUrl = url && url.split("?")[0];
    return isEmptyArray(queryParams)
        ? baseUrl
        : `${baseUrl}?${queryParams
            .map((param) => (param.key !== "" ? `${param.key}=${param.value}` : ""))
            .join("&")}`;
};
export function generateStepPreviewData(props) {
    const result = [];
    const { initialDelay = 0, rampDown = 0, rampUp = 0, stepLength = 0, usersPerStep = 0, timeLine = [] } = props;
    const startTimeLine = !isEmptyArray(timeLine) ? timeLine[0] : 0;
    const endTimeLine = !isEmptyArray(timeLine) ? timeLine[1] : (initialDelay || 0) + rampUp + stepLength + rampDown;
    const timeCoefficient = 1 / 60;
    const initialDelayWithCoeff = timeCoefficient * (initialDelay || 0);
    const startTimeLineWithCoeff = timeCoefficient * startTimeLine;
    const endTimeLineWithCoeff = timeCoefficient * endTimeLine;
    const rampUpTime = (initialDelay || 0) + rampUp;
    const holdTime = rampUpTime + stepLength;
    const rampDownTime = holdTime + rampDown;
    const rampDownFunction = ({ rampDown, rampUp, usersPerStep, initialDelay, stepLength, time }) => rampDown === 0 || isEmpty(time)
        ? 0
        : ((initialDelay || 0) + stepLength + rampUp + rampDown - time) * (usersPerStep / rampDown);
    const rampUpFunction = ({ initialDelay, rampUp, usersPerStep, time }) => rampUp === 0 || isEmpty(time) ? 0 : (usersPerStep / rampUp) * (Number(time) - (initialDelay || 0));
    const getHoldTimeValue = ({ holdTime, startTimeLine, endTimeLine }) => {
        const time = holdTime < endTimeLine ? holdTime : endTimeLine;
        return startTimeLine > time ? startTimeLine : time;
    };
    if (startTimeLine >= (initialDelay || 0) + rampUp + stepLength + rampDown) {
        return result;
    }
    endTimeLine < rampUpTime &&
        endTimeLine > initialDelay &&
        result.push({
            time: endTimeLineWithCoeff,
            users: rampUpFunction({ initialDelay, rampUp, usersPerStep, time: endTimeLine })
        });
    (startTimeLine < initialDelay || (startTimeLine >= (initialDelay || 0) && startTimeLine < rampUpTime)) &&
        initialDelay < endTimeLine &&
        result.push({
            time: startTimeLine > initialDelay ? startTimeLineWithCoeff : initialDelayWithCoeff,
            users: rampUpFunction({
                initialDelay,
                rampUp,
                usersPerStep,
                time: startTimeLine > initialDelay ? startTimeLine : initialDelay || 0
            })
        });
    holdTime > startTimeLine &&
        rampUpTime <= endTimeLine &&
        result.push({
            time: startTimeLine > rampUpTime ? startTimeLineWithCoeff : rampUpTime * timeCoefficient,
            users: usersPerStep
        }, {
            time: getHoldTimeValue({ startTimeLine, holdTime, endTimeLine }) * timeCoefficient,
            users: usersPerStep
        });
    startTimeLine >= holdTime &&
        startTimeLine < holdTime + rampDown &&
        result.push({
            time: startTimeLineWithCoeff,
            users: rampDownFunction({
                initialDelay,
                rampDown,
                rampUp,
                stepLength,
                time: startTimeLine,
                usersPerStep
            })
        });
    endTimeLine > holdTime &&
        endTimeLine < rampDownTime &&
        result.push({
            time: endTimeLineWithCoeff,
            users: rampDownFunction({
                initialDelay,
                rampDown,
                rampUp,
                stepLength,
                time: endTimeLine,
                usersPerStep
            })
        });
    rampDownTime > startTimeLine &&
        rampDownTime <= endTimeLine &&
        result.push({
            time: rampDownTime * timeCoefficient,
            users: rampDownFunction({
                initialDelay,
                rampDown,
                rampUp,
                stepLength,
                time: rampDownTime,
                usersPerStep
            })
        });
    return result;
}
export function generatePreviewData(props) {
    const result = [];
    const { loadType, rampDown = 0, rampUp = 0, stepLength = 0, usersPerStep = 0 } = props;
    let { durationAddedOnLastStep = 0, stepCount = 0 } = props;
    (!loadType || loadType === TestType.Stable) && (stepCount = 1) && (durationAddedOnLastStep = 0);
    for (let i = 0; i <= stepCount; i++) {
        const lastTime = result[result.length - 1] ? result[result.length - 1].time : 0;
        switch (i) {
            case 0:
                result.push({ time: 0, users: 0 });
                break;
            case stepCount:
                result.push({ time: lastTime + rampUp / 60, users: usersPerStep * i }, {
                    time: lastTime + rampUp / 60 + stepLength / 60 + durationAddedOnLastStep / 60,
                    users: usersPerStep * i
                });
                break;
            default:
                result.push({ time: lastTime + rampUp / 60, users: usersPerStep * i }, { time: lastTime + rampUp / 60 + stepLength / 60, users: usersPerStep * i });
                break;
        }
    }
    result.push({ time: result[result.length - 1].time + rampDown / 60, users: 0 });
    return result;
}
export const generateQueryParams = (url = "") => {
    const queryParamsPart = (url || "").split("?")[1];
    const queryParams = (queryParamsPart && queryParamsPart.replace(/([&]){1,}/g, "&").split("&")) || [];
    return queryParams.map((paramStr) => {
        const paramKeyValue = paramStr.split("=");
        const paramKeyValLen = paramKeyValue.length;
        return {
            id: getUniqueId(),
            key: paramKeyValue[0],
            value: paramKeyValLen > 1 ? Array(paramKeyValLen - 1).join("=") + paramKeyValue[paramKeyValLen - 1] : ""
        };
    });
};
export const getEnabledJMXProfiles = (JMXProfiles, groups) => Object.keys(JMXProfiles)
    .filter((JMXProfileId) => {
    const group = groups.find((group) => group.id === JMXProfileId);
    return !group || !group.enabled ? false : true;
})
    .reduce((res, JMXProfileId) => (Object.assign(Object.assign({}, res), { [JMXProfileId]: JMXProfiles[JMXProfileId] })), {});
export const getTestTotalDuration = (params) => {
    const { durationAddedOnLastStep = 0, initialDelay = 0, rampDown = 0, rampUp = 0, stepCount = 0, stepLength = 0 } = params || {};
    const totalDuration = durationAddedOnLastStep + rampDown + initialDelay + (rampUp + stepLength) * stepCount;
    return totalDuration > 0 ? totalDuration : 0;
};
const getStepParams = (step) => ({
    initDelaySec: step.initialDelay * TIME_COEFFICIENT,
    startupTimeSec: step.rampUp * TIME_COEFFICIENT,
    holdLoadTimeSec: step.stepLength * TIME_COEFFICIENT,
    shutdownTimeSec: step.rampDown * TIME_COEFFICIENT,
    startThreadsCount: step.usersPerStep
});
const prepareRequestManualTimers = (requestTimerList, timers) => (requestTimerList || []).map((timerId) => timers[timerId]);
const prepareResponsePreview = (responsePreview) => responsePreview ? { responsePreview } : {};
const generateTestProfileRequest = (request, timers = {}) => (Object.assign({ body: getValueByPath(request, "body.TEXT", ""), extractors: generateExtractors(request.extractResponse), headers: arrayToObject(request.headers, "key", "value"), method: request.method, params: arrayToObject(request.queryParams, "key", "value"), timerList: prepareRequestManualTimers(request.timerList, timers), url: request.requestUrl }, prepareResponsePreview(request.responsePreview)));
const generateTestProfileRequestsByGroup = (groupId, requests, timers) => ({
    requests: (requests || [])
        .filter((request) => request.groupId === groupId)
        .map((request) => generateTestProfileRequest(request, timers))
});
const prepareJMXTimers = (requestTimerList, timers) => (requestTimerList || []).reduce((res, timerId) => (timers[timerId] ? [...res, Object.assign({}, timers[timerId])] : res), []);
const prepareThreadGroupElements = (threadGroupElements, requests, timers) => threadGroupElements.map((groupElement) => {
    const request = getRequestById(requests, groupElement.id) || { timerList: [] };
    return groupElement.groupElementType === ThreadGroupController.Sampler
        ? Object.assign(Object.assign({}, groupElement), { timerList: prepareJMXTimers(request.timerList, timers) }) : Object.assign(Object.assign({}, groupElement), { children: prepareThreadGroupElements(groupElement.children, requests, timers) });
});
const getThreadGroupElementsByGroupId = (threadGroups, groupId, requests, timers) => threadGroups && threadGroups[groupId]
    ? Object.assign(Object.assign({}, threadGroups[groupId]), { threadGroupElements: prepareThreadGroupElements(threadGroups[groupId].threadGroupElements, requests, timers) }) : {};
const getThreadGroupSteps = (JMXProfile) => JMXProfile && !isEmptyArray(JMXProfile.steps)
    ? {
        steps: JMXProfile.steps.map((step) => (Object.assign({ name: step.stepName ? step.stepName : step.name }, getStepParams(step))))
    }
    : {};
const generateJMXTestProfileRequestGroup = (group, requests, JMXProfile, sourceJmxThreadGroups, timers) => {
    const { enabled = true, id } = group || {};
    return Object.assign(Object.assign(Object.assign(Object.assign({ displayTypeName: group.displayTypeName, id: isNaN(Number(id)) ? id : Number(id) }, getThreadGroupElementsByGroupId(sourceJmxThreadGroups, id, requests, timers)), getThreadGroupSteps(JMXProfile)), generateTestProfileRequestGroupRunnerResources(group.runnerResources)), { enabled, type: group.type });
};
const generateTestProjectContentJMX = (testProjectData) => {
    const { baseJmxFileLocation, groups, isRunThreadGroupsConsecutively, JMXProfiles, sourceJmxThreadGroups, requests, timers, JMXTimersSettings } = testProjectData || {};
    return Object.assign(Object.assign(Object.assign({}, generateTestProjectContentCommonParams(testProjectData)), generateTestProfileRequestGroupResourceLocations(testProjectData.runnerLocations)), { baseJmxFileLocation,
        isRunThreadGroupsConsecutively, isTimersDisabled: getValueByPath(JMXTimersSettings, "isTimersDisabled", false), multiplicationFactor: getValueByPath(JMXTimersSettings, "multiplicationFactor", 1), testType: TestType.JMX, threadGroups: (groups || []).map((group) => generateJMXTestProfileRequestGroup(group, requests, JMXProfiles ? JMXProfiles[group.id] : undefined, sourceJmxThreadGroups, timers)) });
};
const generateTestProfileRequestGroupResourceLocations = (runnerLocations) => runnerLocations
    ? {
        resourceConfiguration: {
            cloudTestRunLocationRequirements: {
                testRunLocationIds: !isEmptyArray(runnerLocations) ? [...runnerLocations] : []
            }
        }
    }
    : {};
const generateTestProfileRequestGroupRunnerResources = (runnerResources) => runnerResources
    ? {
        resourceConfiguration: {
            testRunnerIds: !isEmptyArray(runnerResources) ? [...runnerResources] : []
        }
    }
    : {};
const generateRequestsTestProfileRequestGroup = (group, requests, timers) => (Object.assign(Object.assign({ id: group.id, name: group.name, perc: Number(group.perc) || 0 }, generateTestProfileRequestsByGroup(group.id, requests, timers)), generateTestProfileRequestGroupRunnerResources(group.runnerResources)));
const generateRequestsTestProfileRequestGroups = ({ groups = [], requests = [], timers = {} }) => groups.map((group) => generateRequestsTestProfileRequestGroup(group, requests, timers));
const getLoadProfileParamsData = (data) => getLoadProfileParams().reduce((res, setting) => (Object.assign(Object.assign({}, res), (data && !isEmpty(data[setting.name])
    ? {
        [setting.name]: Number(setting.type === "time" ? data[setting.name] * TIME_COEFFICIENT : data[setting.name])
    }
    : {}))), {});
const generateDefaultTimer = (defaultTimerSettings) => {
    const { enabled = true } = defaultTimerSettings || {};
    const timerValues = getTimerValueByType(defaultTimerSettings, 0);
    const type = getTimerType(defaultTimerSettings);
    return isEmptyTimerParams(Object.assign({ [RequestTimerParams.Type]: type }, timerValues))
        ? {}
        : {
            defaultTimer: Object.assign(Object.assign({ enabled }, timerValues), getTimerTypeValuesByType(type))
        };
};
const generateThreadGroupController = (threadGroupElement, elementsData) => {
    const _a = elementsData[threadGroupElement.id], { groupId, id, name } = _a, restParams = __rest(_a, ["groupId", "id", "name"]);
    return {
        type: "CONTROLLER",
        id,
        typeDisplayName: "Transaction Controller",
        label: name,
        individualProperties: {
            type: "TRANSACTION",
            includeDurationOfAllElementsToGeneratedSampler: false
        },
        children: threadGroupElement.children.map((threadGroupChildrenElement) => generateThreadGroupElementByType({ threadGroupElement: threadGroupChildrenElement, elementsData })),
        timerList: []
    };
};
const generateThreadGroupSampler = (threadGroupElement, _a) => {
    var { id, name } = _a, restParams = __rest(_a, ["id", "name"]);
    const _b = generateTestProfileRequest(restParams), { assertions, extractors, timerList } = _b, individualPropertiesData = __rest(_b, ["assertions", "extractors", "timerList"]);
    return {
        assertions,
        individualProperties: Object.assign(Object.assign({}, individualPropertiesData), { type: "HTTP" }),
        id,
        extractors: [],
        label: name,
        timerList,
        type: "SAMPLER",
        typeDisplayName: "HTTP Request"
    };
};
const generateThreadGroupElementByType = ({ threadGroupElement, elementsData }) => threadGroupElement.type === ThreadGroupController.Controller
    ? generateThreadGroupController(threadGroupElement, elementsData)
    : generateThreadGroupSampler(threadGroupElement, elementsData[threadGroupElement.id]);
const generateThreadGroupData = ({ displayTypeName, enabled = true, id, name, perc, runnerLocations = [], runnerResources = [], type }) => ({
    boomqConfiguration: Object.assign(Object.assign(Object.assign({}, generateTestProjectResources(runnerResources)), generateTestProfileRequestGroupResourceLocations(runnerLocations)), { loadProfilePercent: perc }),
    enabled,
    id,
    label: name,
    type,
    typeDisplayName: displayTypeName
});
const generateThreadGroup = ({ elementsData, group, threadGroupTree }) => (Object.assign(Object.assign({}, generateThreadGroupData(group)), { threadGroupElements: threadGroupTree.map((threadGroupElement) => generateThreadGroupElementByType({ threadGroupElement, elementsData })) }));
const generateThreadGroups = ({ groups, treeData, elementsData, testProjectData }) => groups.map((group) => (Object.assign({}, generateThreadGroup({ elementsData, group, threadGroupTree: treeData[group.id] }))));
const generateLoadProfile = (testProjectData) => {
    const { stepCount = 1, usersPerStep = 1, stepLength = 1, rampUp = 0, rampDown = 0, durationAddedOnLastStep = 0 } = getLoadProfileParamsData(testProjectData);
    return {
        loadProfile: {
            loadProfileType: "BOOMQ",
            boomqProfileType: testProjectData.testType,
            stepCount,
            usersPerStep,
            stepLengthMs: convertSecToMs(stepLength),
            rampUpMs: convertSecToMs(rampUp),
            rampDownMs: convertSecToMs(rampDown),
            durationAddedOnLastStepMs: convertSecToMs(durationAddedOnLastStep)
        }
    };
};
const generateTestProjectContentRequests = (testProjectData) => ({
    boomqTestPlan: Object.assign(Object.assign(Object.assign({ loadProfileType: "PER_TEST" }, generateTestProjectContentCommonParams(testProjectData)), generateLoadProfile(testProjectData)), { threadGroups: generateThreadGroups({
            testProjectData,
            elementsData: testProjectData.elementsData,
            groups: testProjectData.groups,
            requests: testProjectData.requests,
            treeData: testProjectData.treeData,
            timers: testProjectData.timers
        }) })
});
const generateTestProfileParameters = (data) => [
    ...prepareLiteralParameters(data.literalParameters, {}),
    ...prepareCsvParameters(data.csvParameters, {}),
    ...(data.others || [])
];
const generateTestProjectResources = (resources) => isEmpty(resources) || isEmptyArray(resources) ? {} : { resourceConfiguration: { resourceIds: resources } };
export const prepareSlaListItem = (slaParams) => ({
    fromDate: slaParams.fromDate,
    [SLA_RESTRICTION_TYPE_PARAM_NAME]: getSlaRestrictionType(slaParams),
    operation: slaParams.operation,
    status: slaParams.status || SlaStatus.NotCounted,
    toDate: slaParams.toDate,
    value: slaParams.value
});
const getSlaTransactionParamsByFilterType = (slaItem) => getSlaTransactionFilterType(slaItem) === SlaTransactionFilterType.Name
    ? {
        [SLA_TRANSACTION_NAME_PARAM_NAME]: getSlaTransactionName(slaItem),
        [SLA_TRANSACTION_FILTER_TYPE_PARAM_NAME]: getSlaTransactionFilterType(slaItem)
    }
    : { [SLA_TRANSACTION_FILTER_TYPE_PARAM_NAME]: getSlaTransactionFilterType(slaItem) };
const prepareSlaRequest = (slaGroupList) => {
    const mergedSlaByTransactionRequest = (slaGroupList || []).reduce((res, slaItem) => (Object.assign(Object.assign({}, res), { [`${slaItem.transactionId}_${getSlaRequestName(slaItem)}`]: res[`${slaItem.transactionId}_${getSlaRequestName(slaItem)}`]
            ? Object.assign(Object.assign({}, res[`${slaItem.transactionId}_${slaItem.requestName}`]), { slaList: [
                    ...res[`${slaItem.transactionId}_${slaItem.requestName}`].slaList,
                    prepareSlaListItem(slaItem)
                ] }) : Object.assign(Object.assign({ [SLA_TYPE_PARAM_NAME]: SlaType.Request, [SLA_TARGET_TYPE_PARAM_NAME]: SlaTargetType.Request, transactionId: slaItem.transactionId }, getSlaTransactionParamsByFilterType(slaItem)), { [SLA_REQUEST_NAME_PARAM_NAME]: getSlaRequestName(slaItem), slaList: [prepareSlaListItem(slaItem)] }) })), {});
    return Object.keys(mergedSlaByTransactionRequest).reduce((res, transactionRequestName) => [...res, mergedSlaByTransactionRequest[transactionRequestName]], []);
};
const prepareSlaTransaction = (slaGroupList) => {
    const mergedSlaByTransaction = (slaGroupList || []).reduce((res, slaItem) => (Object.assign(Object.assign({}, res), { [slaItem.transactionId]: res[slaItem.transactionId]
            ? Object.assign(Object.assign({}, res[slaItem.transactionId]), { slaList: [...res[slaItem.transactionId].slaList, prepareSlaListItem(slaItem)] }) : {
            type: SlaType.Transaction,
            targetType: SlaTargetType.Transaction,
            transactionId: slaItem.transactionId,
            transactionName: slaItem.transactionName,
            slaList: [prepareSlaListItem(slaItem)]
        } })), {});
    return Object.keys(mergedSlaByTransaction).reduce((res, transactionId) => [...res, mergedSlaByTransaction[transactionId]], []);
};
const prepareSlaTest = (slaGroupList) => {
    const slaList = (slaGroupList || []).reduce((res, slaItem) => [...res, prepareSlaListItem(slaItem)], []);
    return slaList.length > 0
        ? [
            {
                type: SlaType.Test,
                targetType: SlaTargetType.Test,
                slaList
            }
        ]
        : [];
};
export const generateTestProjectSlaParams = (slaGroupList) => [
    ...prepareSlaTest(slaGroupList[SlaTargetType.Test]),
    ...prepareSlaTransaction(slaGroupList[SlaTargetType.Transaction]),
    ...prepareSlaRequest(slaGroupList[SlaTargetType.Request])
];
const generateHttpRequestDefaults = (httpRequestDefaults) => isEmpty(httpRequestDefaults)
    ? {}
    : {
        httpRequestDefaults: Object.assign(Object.assign({}, httpRequestDefaults), { connectTimeout: TIMEOUT_TIME_COEFFICIENT * httpRequestDefaults.connectTimeout, responseTimeout: TIMEOUT_TIME_COEFFICIENT * httpRequestDefaults.responseTimeout })
    };
const generateTestProjectContentCommonParams = (testProjectData) => (Object.assign(Object.assign({}, generateHttpRequestDefaults(testProjectData.httpRequestDefaults)), { parameters: generateTestProfileParameters(testProjectData.requestParameters), slaGroupList: generateTestProjectSlaParams(testProjectData.sla) }));
const generateTestProjectCommonParams = (testProjectData) => ({
    comment: "",
    contentFormat: "yaml",
    contentModelVersion: getContentModelVersion(testProjectData),
    labelSet: testProjectData.labelSet,
    requestCount: !isEmptyArray(testProjectData.requests) ? testProjectData.requests.length : 0,
    testType: testProjectData.testType
});
export const generateTestProjectJMX = (testProjectData) => (Object.assign(Object.assign({}, generateTestProjectCommonParams(testProjectData)), { projectName: testProjectData.projectName, content: convertObjToYaml(generateTestProjectContentJMX(testProjectData)), settingsId: testProjectData.settingsId, totalDuration: testProjectData.JMXTestDurationSec }));
export const generateTestProjectRequests = (testProjectData) => (Object.assign(Object.assign({}, generateTestProjectCommonParams(testProjectData)), { projectName: testProjectData.projectName, contentV2: generateTestProjectContentRequests(testProjectData), settingsId: testProjectData.settingsId, totalDuration: TIME_COEFFICIENT * getTestTotalDuration(testProjectData) }));
