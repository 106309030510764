export const ACTION_EDIT_TOOLTIP = {
    defaultMessage: "Edit",
    id: "account.request.group.editor.element.action.edit.tooltip"
};
export const REQUEST_BODY_LABEL = {
    defaultMessage: "Body",
    id: "account.request.group.editor.element.request.body.label"
};
export const REQUEST_NAME_LABEL = {
    defaultMessage: "Name",
    id: "account.request.group.editor.element.request.name.label"
};
export const REQUEST_URL_LABEL = {
    defaultMessage: "URL",
    id: "account.request.group.editor.element.request.url.label"
};
export const REQUEST_ASSERTIONS_TEXT = {
    defaultMessage: "{count} Assertions",
    id: "account.request.group.editor.element.request.assertions.text"
};
export const REQUEST_EXTRACT_FROM_RESPONSE_TEXT = {
    defaultMessage: "Extract from response",
    id: "account.request.group.editor.element.request.extract.from.response.text"
};
