import styled from "styled-components";
import { Block, Color, FontBodyMediumMedium, Spoiler, SpoilerDefaultArrow, SpoilerDefaultHeader } from "@boomq/uikit";
export const StyledSpoiler = styled(Spoiler) `
    ${Block}
    padding: 16px;
    ${SpoilerDefaultArrow} {
        fill: ${Color.darkGrey35};
        position: relative;
        right: 0;
        top: 0;
    }
    ${SpoilerDefaultHeader} {
        ${FontBodyMediumMedium}
        align-items: center;
        border-bottom: 0;
        color: ${Color.darkGrey35};
        padding: 0;
    }
`;
