import styled from "styled-components";
import { Color, IconDelete, IconSettings, Select } from "@boomq/uikit";
import { ActionWithTextStyles } from "../../../styled/Table";
const IconStyles = `
    cursor: pointer;
    fill: ${Color.blue};
    margin-left: 16px;
`;
export const Error = styled.div `
    align-items: center;
    cursor: help;
    display: flex;
    margin-left: 16px;
`;
export const StyledIconDelete = styled(IconDelete) `
    ${IconStyles}
`;
export const StyledIconSettings = styled(IconSettings) `
    ${IconStyles}
`;
export const StyledSelect = styled(Select) `
    max-width: 240px;
    min-width: 240px;
`;
export const Action = styled.div `
    ${ActionWithTextStyles}
`;
export const WebhookSection = styled.div `
    align-items: center;
    display: flex;
    margin-bottom: 16px;
`;
