export var GlobalParameter;
(function (GlobalParameter) {
    GlobalParameter["Csv"] = "CSV";
    GlobalParameter["Literal"] = "LITERAL";
})(GlobalParameter || (GlobalParameter = {}));
export var TestProjectRequestActionType;
(function (TestProjectRequestActionType) {
    TestProjectRequestActionType["Component"] = "COMPONENT";
    TestProjectRequestActionType["Icon"] = "ICON";
})(TestProjectRequestActionType || (TestProjectRequestActionType = {}));
