import { getValueByPath } from "@boomq/utils";
import { ELEMENT_FOR_EACH_REQUIRED_FIELDS, ELEMENT_IF_REQUIRED_FIELDS, ELEMENT_LOOP_REQUIRED_FIELDS, ELEMENT_WHILE_REQUIRED_FIELDS } from "./constants";
import { grpcRequestUrlValidator, requestUrlValidator } from "./url";
import { validatorByRequiredFields } from "./validators";
import { getThreadGroupElementControllerType } from "../threadGroup";
import { ControllerFlowControlType, SamplerType } from "../../models/threadGroupEditor";
const elementValidatorByRequiredFields = (elementData, paramNames) => {
    const { isValid, validatedData } = validatorByRequiredFields(elementData, paramNames);
    return isValid ? { isValid } : { isValid, error: validatedData.error };
};
const controllerForEachValidator = (elementData) => elementValidatorByRequiredFields(elementData, ELEMENT_FOR_EACH_REQUIRED_FIELDS);
const controllerIfValidator = (elementData) => elementValidatorByRequiredFields(elementData, ELEMENT_IF_REQUIRED_FIELDS);
const controllerLoopValidator = (elementData) => elementValidatorByRequiredFields(elementData, ELEMENT_LOOP_REQUIRED_FIELDS);
const controllerWhileValidator = (elementData) => elementValidatorByRequiredFields(elementData, ELEMENT_WHILE_REQUIRED_FIELDS);
const samplerGrpcValidator = (elementData) => grpcRequestUrlValidator(getValueByPath(elementData, "requestUrl"));
const samplerHttpValidator = (elementData) => requestUrlValidator(getValueByPath(elementData, "requestUrl"));
const validateFuncByControllerType = {
    [ControllerFlowControlType.ForEach]: controllerForEachValidator,
    [ControllerFlowControlType.If]: controllerIfValidator,
    [ControllerFlowControlType.Loop]: controllerLoopValidator,
    [ControllerFlowControlType.While]: controllerWhileValidator,
    [SamplerType.Grpc]: samplerGrpcValidator,
    [SamplerType.Http]: samplerHttpValidator
};
export const validateThreadGroupElement = (elementData) => {
    const controllerType = getThreadGroupElementControllerType(elementData);
    return Object.keys(validateFuncByControllerType).includes(controllerType)
        ? validateFuncByControllerType[controllerType](elementData)
        : { isValid: true };
};
