export var SlaReportTestFilterType;
(function (SlaReportTestFilterType) {
    SlaReportTestFilterType["LastTestRunsExpression"] = "LAST_TEST_RUNS_EXPRESSION";
    SlaReportTestFilterType["NotExpression"] = "NOT_EXPRESSION";
    SlaReportTestFilterType["TestDisplayStateExpression"] = "TEST_DISPLAY_STATE_EXPRESSION";
    SlaReportTestFilterType["TestIdExpression"] = "TEST_ID_EXPRESSION";
    SlaReportTestFilterType["TestLabelExpression"] = "TEST_LABEL_EXPRESSION";
    SlaReportTestFilterType["TimeWindowExpression"] = "TIME_WINDOW_EXPRESSION";
})(SlaReportTestFilterType || (SlaReportTestFilterType = {}));
export var SlaReportTestFilterTimeUnit;
(function (SlaReportTestFilterTimeUnit) {
    SlaReportTestFilterTimeUnit["Month"] = "MONTH";
    SlaReportTestFilterTimeUnit["Runs"] = "RUNS";
    SlaReportTestFilterTimeUnit["Week"] = "WEEK";
})(SlaReportTestFilterTimeUnit || (SlaReportTestFilterTimeUnit = {}));
export var SlaReportProfileAction;
(function (SlaReportProfileAction) {
    SlaReportProfileAction["Add"] = "onAdd";
    SlaReportProfileAction["Save"] = "onSave";
})(SlaReportProfileAction || (SlaReportProfileAction = {}));
export var SlaReportProfilesItemAction;
(function (SlaReportProfilesItemAction) {
    SlaReportProfilesItemAction["Delete"] = "onDelete";
    SlaReportProfilesItemAction["Edit"] = "onEdit";
})(SlaReportProfilesItemAction || (SlaReportProfilesItemAction = {}));
export var SlaReportType;
(function (SlaReportType) {
    SlaReportType["Chart"] = "CHART";
    SlaReportType["Table"] = "TABLE";
})(SlaReportType || (SlaReportType = {}));
export var SlaReportTableValueType;
(function (SlaReportTableValueType) {
    SlaReportTableValueType["BaselineDiff"] = "BASELINE_DIFF";
    SlaReportTableValueType["PreviousTestDiff"] = "PREVIOUS_TEST_DIFF";
    SlaReportTableValueType["Value"] = "VALUE";
})(SlaReportTableValueType || (SlaReportTableValueType = {}));
export var SlaReportTableDiffType;
(function (SlaReportTableDiffType) {
    SlaReportTableDiffType["Absolute"] = "ABSOLUTE";
    SlaReportTableDiffType["Relative"] = "RELATIVE";
})(SlaReportTableDiffType || (SlaReportTableDiffType = {}));
