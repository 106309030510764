import { DEFAULT_REQUEST_URL } from "./constants";
import { ControllerFlowControlType, ControllerType, SamplerType, ThreadGroupController, ThreadGroupImportDataController } from "./enums";
import { importDataFromFileIconByType } from "../importData";
import { EmptyElement } from "../../components/account/RequestGroupEditor/EmptyElement";
import { ForEachElement } from "../../components/account/RequestGroupEditor/ForEachElement";
import { IfElement } from "../../components/account/RequestGroupEditor/IfElement";
import { LoopElement } from "../../components/account/RequestGroupEditor/LoopElement";
import { RequestGrpcElement } from "../../components/account/RequestGroupEditor/RequestGrpcElement";
import { RequestHttpElement } from "../../components/account/RequestGroupEditor/RequestHttpElement";
import { TransactionElement } from "../../components/account/RequestGroupEditor/TransactionElement";
import { WhileElement } from "../../components/account/RequestGroupEditor/WhileElement";
import { generateTransactionName, getThreadGroupElementControllerType } from "../../helpers/threadGroup";
import { GrpcMethod, HttpMethod } from "../../models/request";
const getControllerDefaultParams = ({ controllerType, id, index }) => ({
    id,
    index,
    controllerType,
    type: ThreadGroupController.Controller
});
const getControllerDefaultParamsWithName = (params) => (Object.assign(Object.assign({}, getControllerDefaultParams(params)), { name: `${getThreadGroupElementControllerType(params)}-${params.id}` }));
const getTransactionDefaultParams = (params) => (Object.assign(Object.assign({}, getControllerDefaultParams(params)), { name: generateTransactionName(params.index) }));
const getSamplerDefaultParams = ({ controllerType, id }) => ({
    id,
    body: { TEXT: "" },
    controllerType,
    extractResponse: [],
    isCopyRequestNameFromUrl: true,
    name: "",
    requestUrl: "",
    timerList: [],
    type: ThreadGroupController.Sampler
});
const getSamplerGRPCDefaultParams = ({ controllerType, id }) => (Object.assign(Object.assign({}, getSamplerDefaultParams({ controllerType, id })), { fullMethod: "", isTls: true, metadata: [], method: GrpcMethod.Unary }));
const getSamplerHttpDefaultParams = ({ controllerType, id }) => (Object.assign(Object.assign({}, getSamplerDefaultParams({ controllerType, id })), { headers: [], method: HttpMethod.Get, name: DEFAULT_REQUEST_URL, queryParams: [], requestUrl: DEFAULT_REQUEST_URL }));
export const nodeDefaultParamsByType = {
    [ThreadGroupController.Controller]: {
        [ControllerType.Transaction]: getTransactionDefaultParams,
        [ControllerFlowControlType.If]: getControllerDefaultParamsWithName,
        [ControllerFlowControlType.Loop]: getControllerDefaultParamsWithName,
        [ControllerFlowControlType.While]: getControllerDefaultParamsWithName
    },
    [ThreadGroupController.Sampler]: {
        [SamplerType.Grpc]: getSamplerGRPCDefaultParams,
        [SamplerType.Http]: getSamplerHttpDefaultParams
    }
};
const nodeTemplatesByType = {
    [ThreadGroupController.Controller]: {
        Default: EmptyElement,
        [ControllerType.Transaction]: TransactionElement,
        [ControllerFlowControlType.ForEach]: ForEachElement,
        [ControllerFlowControlType.If]: IfElement,
        [ControllerFlowControlType.Loop]: LoopElement,
        [ControllerFlowControlType.While]: WhileElement
    },
    [ThreadGroupController.Sampler]: {
        Default: EmptyElement,
        [SamplerType.Grpc]: RequestGrpcElement,
        [SamplerType.Http]: RequestHttpElement
    }
};
const getNodeTemplatesControllerName = (type) => Object.keys(nodeTemplatesByType).includes(type) ? type : ThreadGroupController.Controller;
const getNodeTemplatesControllerTypeName = (nodeTemplatesByController, controllerType) => Object.keys(nodeTemplatesByController).includes(controllerType) ? controllerType : "Default";
const getNodeTemplateByControllerType = (type, controllerType) => {
    const nodeTemplatesByControllerType = nodeTemplatesByType[getNodeTemplatesControllerName(type)];
    return nodeTemplatesByControllerType[getNodeTemplatesControllerTypeName(nodeTemplatesByControllerType, controllerType)];
};
export const getNodeTemplateByType = (node) => {
    const { type, controllerType } = node || {};
    return getNodeTemplateByControllerType(type, controllerType);
};
export const getElements = () => [
    {
        children: [],
        controller: ThreadGroupController.Controller,
        controllerType: ControllerType.Transaction
    },
    {
        controller: ThreadGroupController.Sampler,
        controllerType: SamplerType.Http
    },
    {
        controller: ThreadGroupController.Sampler,
        controllerType: SamplerType.Grpc
    }
];
export const getControllerFlowControlElements = () => Object.keys(ControllerFlowControlType).map((flowControlTypeKey) => ({
    children: [],
    controller: ThreadGroupController.Controller,
    controllerType: ControllerFlowControlType[flowControlTypeKey]
}));
export const getImportDataElements = () => Object.keys(importDataFromFileIconByType).map((controllerType) => ({
    controller: ThreadGroupImportDataController.ImportDataFromFile,
    controllerType,
    icon: importDataFromFileIconByType[controllerType]
}));
