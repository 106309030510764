export const TIMER_CONSTANT_DURATION_PLACEHOLDER_MESSAGE = {
    defaultMessage: "Duration",
    id: "account.request.form.timer.duration.title"
};
export const TIMER_CONSTANT_TEXT_MESSAGE = {
    defaultMessage: "Constant",
    id: "account.request.form.timers.type.constant.title"
};
export const TIMER_RANDOM_LOWER_LIMIT_PLACEHOLDER_MESSAGE = {
    defaultMessage: "Lower limit",
    id: "account.request.form.timer.lower.limit.title"
};
export const TIMER_RANDOM_UPPER_LIMIT_PLACEHOLDER_MESSAGE = {
    defaultMessage: "Upper limit",
    id: "account.request.form.timer.upper.limit.title"
};
export const TIMER_RANDOM_TEXT_MESSAGE = {
    defaultMessage: "Random",
    id: "account.request.form.timers.type.random.title"
};
export const TIMER_RANDOM_GAUSSIAN_PLACEHOLDER_MESSAGE = {
    defaultMessage: "Gaussian",
    id: "account.request.form.timer.GAUSSIAN_RANDOM_TIMER.title"
};
export const TIMER_RANDOM_GAUSSIAN_TEXT_MESSAGE = {
    defaultMessage: "Gaussian",
    id: "account.request.form.timer.GAUSSIAN_RANDOM_TIMER.title"
};
export const TIMER_RANDOM_POISSON_PLACEHOLDER_MESSAGE = {
    defaultMessage: "Poisson",
    id: "account.request.form.timer.POISSON_RANDOM_TIMER.title"
};
export const TIMER_RANDOM_POISSON_TEXT_MESSAGE = {
    defaultMessage: "Poisson",
    id: "account.request.form.timer.POISSON_RANDOM_TIMER.title"
};
export const TIMER_RANDOM_UNIFORM_PLACEHOLDER_MESSAGE = {
    defaultMessage: "Uniform",
    id: "account.request.form.timer.UNIFORM_RANDOM_TIMER.title"
};
export const TIMER_RANDOM_UNIFORM_TEXT_MESSAGE = {
    defaultMessage: "Uniform",
    id: "account.request.form.timer.UNIFORM_RANDOM_TIMER.title"
};
