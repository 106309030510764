import styled from "styled-components";
import { ExceptionForm, ExceptionText } from "../../../common/ExceptionForm";
export const StyledExceptionForm = styled(ExceptionForm) `
    ${ExceptionText} {
        margin-bottom: 0;
    }
`;
export const InvalidPropertyNameList = styled.ul `
    display: flex;
    flex: 1;
    flex-direction: column;
    padding-left: 20px;
`;
export const InvalidPropertyName = styled.li `
    margin-top: 8px;
`;
export const ScrollableBlock = styled.div `
    flex: 1;
    margin-bottom: 30px;
    position: relative;
    overflow-y: auto;
    width: 100%;
    > ol {
        left: 0;
        padding-right: 10px;
        position: absolute;
        top: 0;
        width: 100%;
    }
`;
