import styled from "styled-components";
export const LiteralParam = styled.div `
    align-items: center;
    display: flex;
    margin-top: 22px;
    position: relative;
`;
export const UsedParameterSection = styled.div `
    min-width: 34px;
`;
