import { arrayToObject, getValueByPath } from "@boomq/utils";
import { GRPC_REQUEST_PROPERTIES_MODEL_SCHEMA_NAME } from "./constants";
import { parseRequestUrlParameters } from "./request";
import { convertSecToMs } from "../date";
import { formatGrpcRequestUrlByTls } from "../request";
import { getTestProjectModelElDefaultValues } from "../testProjectModelSchema";
import { RequestType } from "../../models/request";
const parseRequestUrlParametersByParams = ({ requestUrl, isTls }) => parseRequestUrlParameters(formatGrpcRequestUrlByTls(requestUrl, isTls));
const generateProtoFilesFromGrpcBufferByMethod = (grpcBuffer = [], method) => {
    const file = (grpcBuffer || []).find((file) => getValueByPath(file, "protoMethods", []).includes(method));
    return file ? [file.id] : [];
};
export const generateTestProfileGrpcRequest = ({ requestData, grpcBuffer, modelSchema }) => (Object.assign(Object.assign(Object.assign({}, getTestProjectModelElDefaultValues(modelSchema, GRPC_REQUEST_PROPERTIES_MODEL_SCHEMA_NAME)), parseRequestUrlParametersByParams(requestData)), { deadline: convertSecToMs(requestData.deadline), disableSSLCertVerification: true, fullMethod: requestData.fullMethod, metadata: arrayToObject(requestData.metadata, "key", "value"), protoFiles: generateProtoFilesFromGrpcBufferByMethod(grpcBuffer, requestData.fullMethod), type: RequestType.Grpc, typeDisplayName: "gRPC Request" }));
