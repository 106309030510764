import React from "react";
import { Select, DefaultArrowIcon, DefaultComboBoxBody } from "@boomq/uikit";
import { DEFAULT_VALUE } from "./constants";
import { LoadingStation } from "./styled";
import { SelectionType } from "../../../helpers/types";
const ResourceBodyTemplate = ({ isOpen, items, selectedItems }) => {
    const selectedItemIds = (selectedItems || []).map((selectedItem) => selectedItem.id, []);
    const bodyText = (items || [])
        .filter((item) => selectedItemIds.includes(item.id))
        .map((resource) => resource.bodyText)
        .join(", ");
    return (React.createElement(DefaultComboBoxBody, null,
        bodyText,
        React.createElement(DefaultArrowIcon, { isopen: isOpen })));
};
export const LoadGeneratorsSelection = (props) => (React.createElement(LoadingStation, { disabled: props.disabled, theme: props.theme },
    React.createElement(Select, { bodyTemplate: ResourceBodyTemplate, defaultValue: DEFAULT_VALUE, disabled: props.disabled, items: props.items, onChange: props.onChange, selectionType: SelectionType.Single, value: props.value })));
