export var SettingsSetAction;
(function (SettingsSetAction) {
    SettingsSetAction["Add"] = "onAdd";
    SettingsSetAction["Save"] = "onSave";
})(SettingsSetAction || (SettingsSetAction = {}));
export var SettingsSetInSidebarAction;
(function (SettingsSetInSidebarAction) {
    SettingsSetInSidebarAction["Add"] = "onAdd";
    SettingsSetInSidebarAction["ExtractFromProject"] = "onExtractFromProject";
    SettingsSetInSidebarAction["LinkProject"] = "onLinkProject";
    SettingsSetInSidebarAction["UnlinkProject"] = "onUnlinkProject";
})(SettingsSetInSidebarAction || (SettingsSetInSidebarAction = {}));
export var SettingsSetsItemAction;
(function (SettingsSetsItemAction) {
    SettingsSetsItemAction["Copy"] = "onCopy";
})(SettingsSetsItemAction || (SettingsSetsItemAction = {}));
export var SettingsSetInSidebarAddingType;
(function (SettingsSetInSidebarAddingType) {
    SettingsSetInSidebarAddingType["Add"] = "ADD";
    SettingsSetInSidebarAddingType["Extract"] = "EXTRACT";
})(SettingsSetInSidebarAddingType || (SettingsSetInSidebarAddingType = {}));
export var SettingsSetSystemMetricMonitoring;
(function (SettingsSetSystemMetricMonitoring) {
    SettingsSetSystemMetricMonitoring["Disk"] = "DISK";
    SettingsSetSystemMetricMonitoring["Docker"] = "DOCKER";
    SettingsSetSystemMetricMonitoring["Kernel"] = "KERNEL";
    SettingsSetSystemMetricMonitoring["Tcp"] = "TCP";
    SettingsSetSystemMetricMonitoring["Udp"] = "UDP";
})(SettingsSetSystemMetricMonitoring || (SettingsSetSystemMetricMonitoring = {}));
export var SettingsSetWizardItem;
(function (SettingsSetWizardItem) {
    SettingsSetWizardItem["Cookies"] = "COOKIES";
    SettingsSetWizardItem["JmeterConfigProperties"] = "JMETER_CONFIG_PROPERTIES";
    SettingsSetWizardItem["Monitoring"] = "MONITORING";
    SettingsSetWizardItem["OtherSettings"] = "OTHER_SETTINGS";
    SettingsSetWizardItem["Parameters"] = "PARAMETERS";
    SettingsSetWizardItem["UrlOverwrite"] = "URL_OVERWRITE";
    SettingsSetWizardItem["Webhooks"] = "WEBHOOKS";
})(SettingsSetWizardItem || (SettingsSetWizardItem = {}));
export var TestProjectsSettingsSetsWizardItem;
(function (TestProjectsSettingsSetsWizardItem) {
    TestProjectsSettingsSetsWizardItem["Projects"] = "PROJECTS";
    TestProjectsSettingsSetsWizardItem["ProjectsSettings"] = "PROJECTS_SETTINGS";
})(TestProjectsSettingsSetsWizardItem || (TestProjectsSettingsSetsWizardItem = {}));
