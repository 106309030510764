import { objectToParams } from "@boomq/utils";
import { instance } from "./authSrv";
const location = window.location;
const servicePort = location.port ? `:${location.port}` : "";
const serviceName = "project-srv";
export const getProjectSrvUrl = () => `${location.protocol}//${location.hostname}${servicePort}/${serviceName}`;
const prepareFormDataFile = (file) => {
    const formData = new FormData();
    formData.append("file", file);
    return formData;
};
const prepareFormDataJMXFiles = ({ jmx, support }) => {
    const formData = new FormData();
    formData.append("jmx", new Blob(jmx), jmx[0].name);
    support.forEach((file) => {
        formData.append("files", new Blob([file]), file.name);
    });
    return formData;
};
export const addTestProject = (testProjectParams) => instance.post(`${getProjectSrvUrl()}/project`, testProjectParams);
export const updateTestProject = ({ id, testProjectParams }) => instance.put(`${getProjectSrvUrl()}/project/${id}`, testProjectParams);
export const copyTestProject = (params) => instance.post(`${getProjectSrvUrl()}/project-copy`, params);
export const deleteTestProject = (id) => instance.delete(`${getProjectSrvUrl()}/project/${id}`);
export const getFileDownloadLink = (params) => instance.get(`${getProjectSrvUrl()}/file/downloadLink?${objectToParams(params)}`);
export const getTestProjects = (props) => instance.get(`${getProjectSrvUrl()}/project?sort=lastModified,desc${objectToParams(props, "&")}`);
export const getTestProjectsLabelsByQuery = (query) => instance.get(`${getProjectSrvUrl()}/project/label?query=${query}`);
export const getTestProjectModelSchema = (query) => instance.get(`${getProjectSrvUrl()}/modelSchema`);
export const getTestProjectDetails = (id) => instance.get(`${getProjectSrvUrl()}/project/${id}`);
export const getTestProjectVersion = ({ id, version }) => instance.get(`${getProjectSrvUrl()}/project/${id}/version/${version}`);
export const updateTestProjectVersionComment = ({ version, comment }) => instance.patch(`${getProjectSrvUrl()}/project/version/${version}`, { comment });
export const getTestProjectVersionDownloadLink = ({ id, version }) => instance.get(`${getProjectSrvUrl()}/project/${id}/version/${version}/downloadLink`);
export const createTestProjectJMX = (files, params) => instance.post(`${getProjectSrvUrl()}/project?${objectToParams(params)}`, prepareFormDataJMXFiles(files));
export const updateTestProjectByJMX = (id, files, params) => instance.put(`${getProjectSrvUrl()}/project/${id}${objectToParams(params, "?")}`, prepareFormDataJMXFiles(files));
export const createTestProjectSettingsSet = (params) => instance.post(`${getProjectSrvUrl()}/projectSettings`, params);
export const copyTestProjectSettingsSet = (params) => instance.post(`${getProjectSrvUrl()}/projectSettingsCopy?${objectToParams(params)}`);
export const extractSettingsFromProject = (params) => instance.post(`${getProjectSrvUrl()}/projectSettingsExtract?${objectToParams(params, "&")}`);
export const getTestProjectSettingsSetsLabelsByQuery = (query) => instance.get(`${getProjectSrvUrl()}/projectSettings/label?query=${query}`);
export const getTestProjectSettingsSetList = (props) => instance.get(`${getProjectSrvUrl()}/projectSettings?sort=id,desc${objectToParams(props, "&")}`);
export const getTestProjectSettingsSet = (id) => instance.get(`${getProjectSrvUrl()}/projectSettings/${id}`);
export const linkTestProjectToSettingsSet = ({ projectId, projectVersionId, settingId }) => instance.post(`${getProjectSrvUrl()}/projectsLinkedToSettings?settingId=${settingId}&projectId=${projectId}&projectVersionId=${projectVersionId}`);
export const unlinkTestProjectToSettingsSet = ({ projectId, projectVersionId }) => instance.delete(`${getProjectSrvUrl()}/projectsLinkedToSettings?projectId=${projectId}&projectVersionId=${projectVersionId}`);
export const getMonitoringSystemMetricsConfDownloadLinks = (projectSettingId) => instance.get(`${getProjectSrvUrl()}/file/systemMetricsMonitoringSetupLink?projectSettingId=${projectSettingId}`);
export const getMonitoringSystemMetricParts = () => instance.get(`${getProjectSrvUrl()}/systemMetricsMonitoringParts`);
export const updateTestProjectSettingsSet = ({ id, params }) => instance.put(`${getProjectSrvUrl()}/projectSettings/${id}`, params);
export const uploadCSVFile = ({ file }) => instance.post(`${getProjectSrvUrl()}/file`, prepareFormDataFile(file));
export const uploadFileV2 = ({ file }) => instance.post(`${getProjectSrvUrl()}/file/v2`, prepareFormDataFile(file));
