import styled from "styled-components";
import { Button, Color, FontBodyMediumMedium, H2Styles } from "@boomq/uikit";
export const StyledContainer = styled.div `
    margin: 0 auto;
    padding-top: 120px;
    max-width: 683px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
`;
export const StyledTitle = styled.h2 `
    ${H2Styles}
    font-size: 32px;
`;
export const StyledSubtitle = styled.p `
    ${FontBodyMediumMedium}
    padding: 28px 0 60px;
    line-height: 1.5;
`;
export const StyledButtonWrapper = styled.div `
    display: flex;
    gap: 20px;
`;
export const StyledButton = styled(Button) `
    border: solid 1px #5f8ffe;
    color: #5f8ffe;
    background: transparent;
    min-width: 180px;
    border-radius: 8px;
    padding: 10px;
    &:hover {
        color: ${Color.white};
        background: #5f8ffe;
    }
`;
