var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { stopEvent, useIntl } from "@boomq/utils";
import { AllReportsItem } from "../../../components/account/AllReportsItem";
import { EMPTY_LABELS_TEXT, prepareLabels } from "../../../helpers/labels";
import { ReportsItemAction, getActionItemsByTeamMemberPermissions, gridColumns, reportsItemActions } from "../../../models/reports";
import { addReportLabelRequest, addReportLabelWithCreatingRequest, copyReportRequest, deleteReportLabelRequest, deleteReportRequest, editReport, searchReportsLabelsByName } from "../../../redux/actions/reports";
import { getClientPermissionList } from "../../../redux/reducers/authorizePlate";
const connector = connect((state) => ({
    permissionList: getClientPermissionList(state)
}), {
    [ReportsItemAction.AddLabel]: addReportLabelRequest,
    [ReportsItemAction.AddLabelWithCreating]: addReportLabelWithCreatingRequest,
    [ReportsItemAction.Copy]: copyReportRequest,
    [ReportsItemAction.Delete]: deleteReportRequest,
    [ReportsItemAction.DeleteLabel]: deleteReportLabelRequest,
    [ReportsItemAction.Edit]: editReport,
    [ReportsItemAction.SearchLabels]: searchReportsLabelsByName
});
const AllReportsItemContainer = (_a) => {
    var { data, data: { id, labelSet = [], testIdSet = [] }, onAddLabel, onAddLabelWithCreating, onDeleteLabel, onSearchLabels, permissionList } = _a, restProps = __rest(_a, ["data", "data", "onAddLabel", "onAddLabelWithCreating", "onDeleteLabel", "onSearchLabels", "permissionList"]);
    const { formatMessage } = useIntl();
    const actionItems = getActionItemsByTeamMemberPermissions(permissionList);
    const addLabelHandler = (label) => onAddLabel({ id, label });
    const addLabelWithCreatingHandler = (name) => onAddLabelWithCreating({ id, name });
    const clickActionHandler = (e, actionName) => {
        stopEvent(e);
        const actionFunc = reportsItemActions[actionName];
        return typeof actionFunc === "function" ? actionFunc(restProps, id) : undefined;
    };
    const deleteLabelHandler = (label) => onDeleteLabel({ id, label: label.name });
    const searchLabels = (query) => onSearchLabels({ id, query });
    const emptyLabelsText = formatMessage(EMPTY_LABELS_TEXT);
    const gridHeaders = gridColumns.map(({ nameIntlMsg }) => formatMessage(nameIntlMsg));
    const labels = prepareLabels(labelSet);
    const testIds = (testIdSet || []).filter((testId) => !!testId).join(", ");
    return (React.createElement(AllReportsItem, Object.assign({ actionItems: actionItems, data: data, emptyLabelsText: emptyLabelsText, gridHeaders: gridHeaders, labels: labels, onAddLabel: addLabelHandler, onAddLabelWithCreating: addLabelWithCreatingHandler, onClickAction: clickActionHandler, onDeleteLabel: deleteLabelHandler, onSearchLabels: searchLabels, testIds: testIds }, restProps)));
};
export default withRouter(connector(AllReportsItemContainer));
