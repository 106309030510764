import { isEmpty } from "@boomq/utils";
import { initialState } from "./report";
const getStateParam = (state, paramName) => state && state.report ? state.report[paramName] : initialState[paramName];
export const getReportData = (state) => state.report;
export const getReportBlocks = (state) => getStateParam(state, "blocks");
const getReportChartList = (state) => getStateParam(state, "charts");
export const getReportId = (state) => getStateParam(state, "id");
export const getReportLastSelectedTestId = (state) => getStateParam(state, "lastSelectedTestId");
export const getReportName = (state) => getStateParam(state, "reportName");
const getReportSeriesList = (state) => getStateParam(state, "series");
const getReportTableList = (state) => getStateParam(state, "tables");
export const getReportTestList = (state) => getStateParam(state, "testList");
export const getReportChartSeriesList = (state, chartId) => getReportSeriesList(state).filter((serieData) => serieData.chartId === chartId);
export const getReportChartSeriesCount = (state, chartId) => getReportChartSeriesList(state, chartId).length;
export const getReportChartLegend = (state, chartId) => {
    const chartSerieList = getReportChartSeriesList(state, chartId);
    return chartSerieList
        .filter(({ displayProps }) => (displayProps === null || displayProps === void 0 ? void 0 : displayProps.color) && (displayProps === null || displayProps === void 0 ? void 0 : displayProps.title))
        .map(({ displayProps, id }) => ({
        color: displayProps === null || displayProps === void 0 ? void 0 : displayProps.color,
        id,
        type: "line",
        value: displayProps === null || displayProps === void 0 ? void 0 : displayProps.title
    }));
};
export const getReportChartDataById = (state, chartId) => {
    const reportChart = getReportChartList(state).find(({ id }) => id === chartId);
    return reportChart ? reportChart : {};
};
export const getReportTableDataById = (state, tableId) => {
    const reportTable = getReportTableList(state).find(({ id }) => id === tableId);
    return reportTable ? reportTable : {};
};
export const getThreadGroupListsByTestId = (state) => getStateParam(state, "threadGroupListsByTestId");
export const getReportChartSerieThreadGroupListByTestId = (state, testId) => {
    const threadGroupListsByTestId = getThreadGroupListsByTestId(state);
    return isEmpty(threadGroupListsByTestId[testId]) ? [] : threadGroupListsByTestId[testId];
};
export const getTransactionListsByTestId = (state) => getStateParam(state, "transactionListsByTestId");
export const getReportChartSerieTransactionListByTestId = (state, testId) => {
    const transactionListsByTestId = getTransactionListsByTestId(state);
    return isEmpty(transactionListsByTestId[testId]) ? [] : transactionListsByTestId[testId];
};
export const isExistTransactionListByTestId = (state, testId) => !isEmpty(getReportChartSerieTransactionListByTestId(state, testId));
