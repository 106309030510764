var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { TooltipBody, TooltipItem } from "./styled";
import { getIconByStatus } from "../CustomizedChartDot";
import { getTooltipLabel } from "../../../../helpers/slaReport/tooltipLabel";
export const CustomizedChartTooltip = (props) => {
    const { active, intl, payload, label } = props || {};
    const formatMessage = intl && typeof intl.formatMessage === "function" ? intl.formatMessage : () => "";
    return (active &&
        payload &&
        payload.length && (React.createElement(TooltipBody, { className: "recharts-default-tooltip" },
        React.createElement("p", { className: "recharts-tooltip-label" }, label),
        React.createElement("ul", { className: "recharts-tooltip-item-list" }, (payload || []).map((tooltipItem, index) => {
            const { color, name, payload, stroke, unit, value } = tooltipItem || {};
            const _a = getTooltipLabel(Object.assign(Object.assign({}, payload), { slaName: name, unit, value })), { values } = _a, rest = __rest(_a, ["values"]);
            return (React.createElement(TooltipItem, { className: "recharts-tooltip-item-list", color: color, key: index },
                getIconByStatus(Object.assign(Object.assign({}, payload), { stroke })),
                "\u00A0",
                React.createElement("span", null, formatMessage(Object.assign({}, rest), values))));
        })))));
};
