import styled from "styled-components";
import { Color, IconSpeedometer } from "@boomq/uikit";
export const tooltipTheme = {
    maxWidth: "400px",
    textAlign: "left"
};
export const StyledIconSpeedometer = styled(IconSpeedometer) `
    fill: ${Color.darkGrey5};
`;
export const TimersCount = styled.span `
    bottom: -10px;
    color: ${Color.darkGrey5};
    left: 22px;
    position: absolute;
`;
export const TimersIcon = styled.span `
    align-items: center;
    display: flex;
`;
export const TimersIconWrapper = styled.div `
    position: relative;
`;
