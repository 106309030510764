var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useRef } from "react";
import { useIntl } from "react-intl";
import { getValueByPath, isEmpty } from "@boomq/utils";
import { KEYCODE_ENTER } from "./constants";
import { IconError, IconErrorBlock, IconsBlock, Unit } from "./styled";
import { Tooltip } from "../Tooltip";
import { withError } from "../withError";
import { InputLabel, InputLabelText, StyledFieldSet, StyledInput, StyledInputWrapper, StyledLegend, StyledLegendSpan } from "../styled/Input";
const isExistUnit = (unit) => unit && unit.text;
const validateValue = (value) => (isEmpty(value) ? "" : String(value));
const Input = (_a) => {
    var { disabledStyle, enabled = true, error, hideError, isError, label, onBlur, onBlurFormatFunc, onChange, onFocus, onKeyDown, showError, inputValue, unit, placeholder, theme } = _a, rest = __rest(_a, ["disabledStyle", "enabled", "error", "hideError", "isError", "label", "onBlur", "onBlurFormatFunc", "onChange", "onFocus", "onKeyDown", "showError", "inputValue", "unit", "placeholder", "theme"]);
    const inputRef = useRef();
    const { formatMessage } = useIntl();
    const validateBlurValue = (value) => {
        const validatedValue = onBlurFormatFunc ? onBlurFormatFunc(value) : value;
        return validatedValue;
    };
    const blurValueHandler = (e) => {
        const value = typeof onBlurFormatFunc === "function" ? validateBlurValue(inputValue) : inputValue;
        typeof hideError === "function" && hideError();
        typeof onBlur === "function" && onBlur(validateValue(value), e);
    };
    const changeValueHandler = (e) => {
        const newValue = getValueByPath(e, "target.value", "");
        return typeof onChange === "function" ? onChange(newValue, e) : undefined;
    };
    const focusHandler = (e) => typeof onFocus === "function" ? onFocus(validateValue(inputValue), e) : undefined;
    const blurInput = () => {
        const ref = inputRef.current ? inputRef.current : {};
        return typeof ref.blur === "function" ? ref.blur() : undefined;
    };
    const checkEnterPressed = (keyCode) => (keyCode === KEYCODE_ENTER ? blurInput() : undefined);
    const keyDownHandler = (e) => {
        checkEnterPressed(e.keyCode);
        return typeof onKeyDown === "function" ? onKeyDown(e) : undefined;
    };
    return (React.createElement(StyledInputWrapper, { disabled: !enabled, disabledStyle: disabledStyle, theme: theme },
        React.createElement(StyledInput, Object.assign({ disabled: !enabled, disabledStyle: disabledStyle, isError: isError, isExistUnit: isExistUnit(unit), onBlur: blurValueHandler, onChange: changeValueHandler, onFocus: focusHandler, onKeyDown: keyDownHandler, placeholder: placeholder, ref: inputRef, type: "text", value: inputValue }, rest)),
        !isEmpty(label) && (React.createElement(InputLabel, { isError: isError },
            React.createElement(InputLabelText, null, label))),
        React.createElement(IconsBlock, null,
            isError && (React.createElement(Tooltip, { title: error },
                React.createElement(IconErrorBlock, { isExistUnit: isExistUnit(unit) },
                    React.createElement(IconError, null)))),
            isExistUnit(unit) && (React.createElement(Unit, { isError: isError, title: formatMessage(Object.assign({}, unit.title)) }, formatMessage(unit.text)))),
        React.createElement(StyledFieldSet, { isError: isError },
            React.createElement(StyledLegend, null,
                React.createElement(StyledLegendSpan, { isEmpty: !label }, label)))));
};
Input.defaultProps = {
    enabled: true,
    inputValue: ""
};
export default withError(Input);
