var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { FormattedMessage } from "react-intl";
import { ANOMALY_SETTINGS_LABEL } from "./intl";
import { AnomalySettingsLabel, SettingsWrapper, StyledIconSettings } from "./styled";
export const AnomalySettings = (_a) => {
    var { onClickAnomalySettings } = _a, restProps = __rest(_a, ["onClickAnomalySettings"]);
    return (React.createElement(SettingsWrapper, Object.assign({ onClick: onClickAnomalySettings }, restProps),
        React.createElement(StyledIconSettings, null),
        React.createElement(AnomalySettingsLabel, null,
            React.createElement(FormattedMessage, Object.assign({}, ANOMALY_SETTINGS_LABEL)))));
};
