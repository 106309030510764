import { connect } from "react-redux";
import { SlaFormContainer } from "../../SlaForm";
import { TestType } from "../../../../helpers/types";
import { getBoomqStableScalabilityCalculatedTestDuration, getJMXTestDuration, getSlaData } from "../../../../redux/reducers/newTest";
const getTestDurationByTestType = (state, ownProps) => ownProps.testType === TestType.JMX
    ? getJMXTestDuration(state)
    : getBoomqStableScalabilityCalculatedTestDuration(state, ownProps.testType);
const connector = connect((state, ownProps) => ({
    slaData: getSlaData(state),
    testDuration: getTestDurationByTestType(state, ownProps)
}), {});
export default connector(SlaFormContainer);
