import React, { useState } from "react";
import { connect } from "react-redux";
import { getUniqueId, isEmpty, useIntl } from "@boomq/utils";
import { DEFAULT_HEADERS_VALUE, DEFAULT_URL_VALUE } from "./constants";
import { REQUEST_HEADER_KEY_PLACEHOLDER, REQUEST_HEADER_VALUE_PLACEHOLDER, REQUEST_HEADERS_TITLE, REQUEST_URL_TITLE, WEBHOOK_SETTINGS_SAVE_BUTTON_LABEL } from "./intl";
import { WebhookAdditionalSettings } from "../../../../components/account/TestProjectSettingsSet/WebhookAdditionalSettings";
import { formatIntlMessage } from "../../../../helpers/intl";
import { requestUrlValidatorErrorText, webhookSettingsValidator } from "../../../../helpers/validators";
import { closeSiteModal } from "../../../../redux/actions/siteModal";
import { setWebhookItem } from "../../../../redux/actions/webhooks";
const connector = connect(null, {
    closeSiteModal,
    onSave: setWebhookItem
});
const generateHeaderNewItem = () => ({ id: getUniqueId(), key: "", value: "" });
const getHeadersInitialState = (headers) => headers ? [...headers, generateHeaderNewItem()] : DEFAULT_HEADERS_VALUE;
const getUrlInitialState = (url) => (url ? url : DEFAULT_URL_VALUE);
const WebhookAdditionalSettingsContainer = ({ closeSiteModal, headers, id, onSave, url }) => {
    const { formatMessage } = useIntl();
    const [requestUrl, setRequestUrl] = useState(getUrlInitialState(url));
    const [requestUrlErrorText, setRequestUrlErrorText] = useState(null);
    const [requestHeaders, setRequestHeaders] = useState(getHeadersInitialState(headers));
    const changeHeaders = (newHeaders) => newHeaders.some(({ key }) => isEmpty(key))
        ? setRequestHeaders(newHeaders)
        : setRequestHeaders([...newHeaders, generateHeaderNewItem()]);
    const changeHeaderHandler = (headerId, value) => changeHeaders(requestHeaders.map((header) => (header.id === headerId ? Object.assign(Object.assign(Object.assign({}, header), value), { error: null }) : header)));
    const changeUrlHandler = (value) => {
        setRequestUrl(value);
        setRequestUrlErrorText(requestUrlValidatorErrorText(value));
    };
    const deleteHeaderHandler = (headerId) => changeHeaders(requestHeaders.filter(({ id }) => id !== headerId));
    const save = () => {
        closeSiteModal();
        return onSave({ error: "", id, headers: requestHeaders.filter(({ key }) => !isEmpty(key)), url: requestUrl });
    };
    const validateSettings = () => webhookSettingsValidator({ url: requestUrl, headers: requestHeaders });
    const setErrors = ({ headers, urlErrorText }) => {
        setRequestHeaders(headers);
        setRequestUrlErrorText(urlErrorText);
    };
    const saveHandler = () => {
        const { isValid, validatedHeaderList, urlErrorText } = validateSettings();
        return isValid ? save() : setErrors({ headers: validatedHeaderList, urlErrorText });
    };
    const headerKeyPlaceholder = formatIntlMessage(REQUEST_HEADER_KEY_PLACEHOLDER, formatMessage);
    const headerValuePlaceholder = formatIntlMessage(REQUEST_HEADER_VALUE_PLACEHOLDER, formatMessage);
    const requestHeadersTitle = formatIntlMessage(REQUEST_HEADERS_TITLE, formatMessage);
    const requestUrlTitle = formatIntlMessage(REQUEST_URL_TITLE, formatMessage);
    const requestUrlError = requestUrlErrorText ? formatIntlMessage(requestUrlErrorText, formatMessage) : "";
    const saveButtonLabel = formatIntlMessage(WEBHOOK_SETTINGS_SAVE_BUTTON_LABEL, formatMessage);
    return (React.createElement(WebhookAdditionalSettings, { headerKeyPlaceholder: headerKeyPlaceholder, headerValuePlaceholder: headerValuePlaceholder, onChangeHeader: changeHeaderHandler, onChangeUrl: changeUrlHandler, onDeleteHeader: deleteHeaderHandler, onSave: saveHandler, requestHeaders: requestHeaders, requestHeadersTitle: requestHeadersTitle, requestUrl: requestUrl, requestUrlError: requestUrlError, requestUrlTitle: requestUrlTitle, saveButtonLabel: saveButtonLabel }));
};
export default connector(WebhookAdditionalSettingsContainer);
