import React from "react";
import { isEmptyArray } from "@boomq/utils";
import { UltimateThreadGroupStepCaption } from "../UltimateThreadGroupStepCaption";
import { ActionSection } from "./styled";
import { IconCopy, IconRemove } from "../../../styled/Icons";
export const UltimateThreadGroupStepCaptionEditMode = (props) => {
    const { id } = props.step;
    const stepCount = props.loadProfile && !isEmptyArray(props.loadProfile.steps) ? props.loadProfile.steps.length : 0;
    const copyStepInGroupHandler = () => typeof props.onCopy === "function" ? props.onCopy(id) : undefined;
    const deleteStepInGroupHandler = () => typeof props.onCopy === "function" ? props.onDelete(id) : undefined;
    return (React.createElement(UltimateThreadGroupStepCaption, Object.assign({}, props),
        React.createElement(ActionSection, null,
            React.createElement(IconCopy, { onClick: copyStepInGroupHandler }),
            stepCount > 1 && (React.createElement(IconRemove, { onClick: deleteStepInGroupHandler })))));
};
