import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { ButtonContainer, ButtonWrapper, CarouselButton, CarouselText, CarouselWrapper, Container, SlideWrapper, StyledIconLeft, StyledIconRight, Wrapper } from "./styled";
import { Slide } from "../Slide";
export const Carousel = ({ slides, texts }) => {
    const rootRef = useRef(null);
    const containerRef = useRef(null);
    const [activeSlide, setActiveSlide] = useState(0);
    const [currentText, setCurrentText] = useState(texts ? texts[0] : null);
    useEffect(() => {
        if (texts) {
            setCurrentText(texts[activeSlide]);
        }
    }, [activeSlide]);
    const goToSlide = (index) => {
        var _a;
        const containerEl = containerRef.current;
        const el = rootRef.current;
        setActiveSlide(index);
        containerEl === null || containerEl === void 0 ? void 0 : containerEl.scrollTo({
            left: ((_a = el === null || el === void 0 ? void 0 : el.clientWidth) !== null && _a !== void 0 ? _a : 0) * index,
            behavior: "smooth"
        });
    };
    const handleNext = () => {
        const newIndex = activeSlide + 1;
        if (newIndex < slides.length) {
            goToSlide(newIndex);
        }
    };
    const handlePrev = () => {
        const newIndex = activeSlide - 1;
        if (newIndex >= 0) {
            goToSlide(newIndex);
        }
    };
    return (React.createElement(CarouselWrapper, { ref: rootRef },
        React.createElement(Wrapper, null,
            React.createElement(Container, { ref: containerRef }, slides.map((slide, index) => (React.createElement(SlideWrapper, { key: index },
                React.createElement(Slide, { source: slide.source, type: slide.type })))))),
        React.createElement(ButtonContainer, null,
            React.createElement(CarouselText, null, currentText && React.createElement(FormattedMessage, Object.assign({}, currentText))),
            React.createElement(ButtonWrapper, null,
                React.createElement(CarouselButton, { onClick: handlePrev },
                    React.createElement(StyledIconLeft, { isActive: activeSlide !== 0 })),
                React.createElement(CarouselButton, { onClick: handleNext },
                    React.createElement(StyledIconRight, { isActive: activeSlide !== slides.length - 1 }))))));
};
