var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { FormattedMessage } from "react-intl";
import { Handle, Range } from "rc-slider";
import "rc-slider/assets/index.css";
import { isEmpty, round } from "@boomq/utils";
import { Button, HandleValue, ItemWrapper, SliderWrapper, StyledHelp, TextBlock, Units, Wrapper } from "./styled";
const RangeSlider = ({ changeHandler, label, slider: { value = [0, 1], min, max, step }, units, name, railStyle, tooltipText, trackStyle, handleStyle }) => {
    const getValue = (value) => {
        let newValue = [min, max];
        value[0] > newValue[0] && value[0] < newValue[1] && (newValue[0] = value[0]);
        value[1] > newValue[0] && value[1] < newValue[1] && (newValue[1] = value[1]);
        return value[0] === value[1] ? [min, max] : newValue;
    };
    const getHandleLabel = (value) => {
        const labelValue = value < min ? min : value;
        return labelValue > max ? max : labelValue;
    };
    const buttonHandlers = (type, newValue) => {
        switch (type) {
            case "increment":
                return changeHandler(name, [value[0], round(value[1] + step)]);
            case "decrement":
                return changeHandler(name, [round(value[0] - step), value[1]]);
            default:
                return changeHandler(name, getValue(newValue));
        }
    };
    const CustomHandle = (props) => {
        const { index, dragging } = props, restProps = __rest(props, ["index", "dragging"]);
        return (React.createElement(Handle, Object.assign({ dragging: dragging.toString(), key: index }, restProps),
            React.createElement(HandleValue, null, getHandleLabel(props.value))));
    };
    return (React.createElement(ItemWrapper, null,
        React.createElement(TextBlock, null,
            React.createElement(FormattedMessage, Object.assign({}, label)),
            units && (React.createElement(Units, null,
                "(",
                React.createElement(FormattedMessage, Object.assign({}, units)),
                ")")),
            !isEmpty(tooltipText) && React.createElement(StyledHelp, { tooltipText: tooltipText })),
        React.createElement(Wrapper, null,
            React.createElement(SliderWrapper, null,
                value[0] > min && React.createElement(Button, { onClick: () => buttonHandlers("decrement") }, "-"),
                React.createElement(Range, { allowCross: false, handle: CustomHandle, value: value, min: min, max: max, step: step, onChange: (newValue) => buttonHandlers(null, newValue), pushable: 1, marks: { [min]: `${min}`, [max]: `${max}` }, handleStyle: Object.assign({}, handleStyle), railStyle: Object.assign({}, railStyle), trackStyle: Object.assign({}, trackStyle) }),
                value[1] < max && React.createElement(Button, { onClick: () => buttonHandlers("increment") }, "+")))));
};
export default RangeSlider;
