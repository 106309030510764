var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { useSelector } from "react-redux";
import { convertDateToTimestamp, convertSecToMs, getFullDateLocaleTimeZone, useDuration, useIntl } from "@boomq/utils";
import { COMMENT_LENGTH } from "./constants";
import { EMPTY_COMMENT_MESSAGE, NO_DATA_MESSAGE } from "./intl";
import { TestItem } from "../../../components/account/TestItem";
import { prepareLabels } from "../../../helpers/labels";
import { getTestRoute } from "../../../helpers/routes";
import { getSlaStatusColor, getSlaStatusDescription } from "../../../helpers/sla";
import { statusColors, statusDescriptions } from "../../../helpers/testStatus";
import { getCurrentTestTypeText } from "../../../data/content/testTypeMessages";
import { getCurrentDateFormatLocale } from "../../../redux/reducers/authorizePlate";
const getCommentText = (comment, emptyCommentText) => {
    const commentLength = comment && comment.length > 0 ? comment.length : 0;
    const text = commentLength > 0 ? comment : emptyCommentText;
    return commentLength <= COMMENT_LENGTH ? text : `${comment.substr(0, COMMENT_LENGTH)}...`;
};
const TestItemContainer = (_a) => {
    var _b;
    var { comment, displayState, id, labelSet, startDate, testProject, testProjectId, testProjectVersion, testResult } = _a, restProps = __rest(_a, ["comment", "displayState", "id", "labelSet", "startDate", "testProject", "testProjectId", "testProjectVersion", "testResult"]);
    const { formatMessage } = useIntl();
    const dateFormatLocale = useSelector(getCurrentDateFormatLocale);
    const testResultSlaState = testResult === null || testResult === void 0 ? void 0 : testResult.testResultSlaState;
    const status = (_b = testResult === null || testResult === void 0 ? void 0 : testResult.slaResults) === null || _b === void 0 ? void 0 : _b.status;
    const commentText = getCommentText(comment);
    const projectName = testProject ? testProject.projectName : "";
    const startTime = convertDateToTimestamp(startDate);
    const testDate = startTime === 0 ? null : getFullDateLocaleTimeZone(dateFormatLocale, startTime);
    const testTypeMessage = testProjectVersion ? getCurrentTestTypeText(testProjectVersion.testType) : "";
    const totalDurationMs = testProjectVersion ? convertSecToMs(testProjectVersion.totalDuration) : 0;
    const durationText = useDuration(totalDurationMs);
    const emptyCommentText = formatMessage(EMPTY_COMMENT_MESSAGE);
    const noDataText = formatMessage(NO_DATA_MESSAGE);
    const startDateText = testDate ? durationText : noDataText;
    const slaStatusDescription = formatMessage(getSlaStatusDescription(status, testResultSlaState));
    const statusDescription = formatMessage(statusDescriptions(displayState));
    const testTypeText = testTypeMessage.title ? formatMessage(testTypeMessage.title) : "";
    return (React.createElement(TestItem, Object.assign({}, restProps, { commentText: commentText, displayState: displayState, emptyCommentText: emptyCommentText, labels: prepareLabels(labelSet), noDataText: noDataText, projectName: projectName, slaStatusColor: getSlaStatusColor(status, testResultSlaState), slaStatusDescription: slaStatusDescription, startDateText: startDateText, startTime: startTime, statusColor: statusColors(displayState), statusDescription: statusDescription, testDate: testDate, testLink: getTestRoute(testProjectId, id), testTypeText: testTypeText })));
};
export default TestItemContainer;
