import styled from "styled-components";
import { Color, FontBodyBigBold, FontBodyMediumMedium, IconPeople } from "@boomq/uikit";
import { withTruncatedText } from "../../styled/global";
import { IconTeam } from "../../styled/Icons";
const TeamImageBlockStyle = `
    height: 48px;
    margin-right: 16px;
    min-height: 48px;
    min-width: 48px;
    width: 48px;
`;
export const StyledIconPeople = styled(IconPeople) `
    fill: ${Color.grey50};
    margin-right: 8px;
`;
export const TeamData = styled.div `
    display: flex;
    flex-direction: column;
    width: calc(100% - 64px);
`;
export const TeamDefaultImage = styled(IconTeam) `
    ${TeamImageBlockStyle}
    fill: ${Color.darkGrey35};
    height: auto;
    min-height: auto;
    width: 48px;
`;
export const TeamImage = styled.img `
    ${TeamImageBlockStyle}
`;
export const TeamInfoBody = styled.div `
    display: flex;
`;
export const TeamInfoHeader = styled.div `
    display: flex;
`;
export const TeamInfoText = styled.div `
    ${FontBodyMediumMedium}
    align-items: center;
    color: ${Color.darkGrey35};
    display: flex;
`;
export const TeamItemHeader = styled.div `
    display: flex;
    width: 100%;
`;
export const TeamName = styled.div `
    ${FontBodyBigBold}
    ${withTruncatedText}
    color: ${Color.darkGrey5};
    flex: auto;
    margin-bottom: 8px;
`;
export const VerticalSeparator = styled.div `
    border-left: 1px solid ${Color.lightGrey85};
    margin: 2px 12px;
`;
