import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { StyledButton } from "../../../styled/Buttons";
const InvalidJMXFileException = () => (React.createElement(ExceptionBody, null,
    React.createElement(ExceptionText, null,
        React.createElement(FormattedMessage, { defaultMessage: "Sorry your JMX is corrupt.", id: "account.new.settings.file.invalid.text1" }),
        React.createElement("br", null),
        React.createElement(FormattedMessage, { defaultMessage: "Please try saving the JMX file again and re-uploading.", id: "account.new.settings.file.invalid.text2" })),
    React.createElement(StyledButton, { className: "close_modal_window", theme: buttonTheme },
        React.createElement(FormattedMessage, { defaultMessage: "Close", id: "account.new.settings.file.invalid.button" }))));
const buttonTheme = {
    width: "auto!important"
};
const ExceptionBody = styled.div `
    align-items: center;
    display: flex;
    flex-direction: column;
`;
const ExceptionText = styled.div `
    line-height: 30px;
    margin: 30px 0;
    text-align: center;
`;
export default InvalidJMXFileException;
