import styled from "styled-components";
import { withDisable } from "../../../styled/global";
export const CustomTextArea = styled.textarea `
    outline: none;
    background: ${({ disabled }) => (disabled ? "#f8f8f8" : "#fff")};
    box-sizing: border-box;
    border: none;
    border-radius: 30px;
    font-family: Arial;
    font-style: normal;
    font-weight: normal;
    line-height: 26px;
    font-size: 16px;
    min-width: 330px;
    min-height: 90px;
    padding: 0 17px;
    resize: none;
    width: 100%;
    &::placeholder {
        color: #91b0cf;
    }
`;
export const JsonViewerBlock = styled.div `
    margin-top: 16px;
    word-break: break-all;
`;
export const RequestBodyContent = styled.div `
    ${withDisable}
    margin-top: 20px;
`;
export const TextAreaSection = styled.div `
    background: ${({ disabled }) => (disabled ? "#f8f8f8" : "#fff")};
    border: 1px solid #91b0cf;
    border-radius: 30px;
    margin-top: 16px;
    padding: 13px;
`;
