import styled from "styled-components";
import { Color, FontBodyMediumMedium, IconSettings2 } from "@boomq/uikit";
export const IconWrapper = styled.div `
    align-items: center;
    background-color: #ffc306;
    border-radius: 50%;
    display: flex;
    position: absolute;
    height: 48px;
    justify-content: center;
    margin-right: 16px;
    left: 32px;
    top: -24px;
    width: 48px;
`;
export const ModalAnomalyWrapper = styled.div `
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    padding: 32px;
`;
export const ModalAnomalyTitle = styled.h5 `
    ${FontBodyMediumMedium}
`;
export const SettingsBlock = styled.div `
    align-items: center;
    display: flex;
    justify-content: space-between;
`;
export const SliderWrapper = styled.div `
    width: 260px;
`;
export const StyledIconSettings = styled(IconSettings2) `
    fill: ${Color.white};
    height: 24px;
    width: 23px;
`;
export const Text = styled.p `
    font-size: 14px;
    line-height: normal;
`;
export const dotStyle = {
    display: "none"
};
export const handleStyle = {
    borderColor: "#5f8ffe",
    backgroundColor: "#5f8ffe",
    width: "8px",
    height: "8px",
    marginTop: "-3px"
};
export const railStyle = {
    backgroundColor: "#d9d9d9",
    height: "2px"
};
export const switcherTheme = {
    marginRight: "200px"
};
export const trackStyle = {
    backgroundColor: "#5f8ffe",
    height: "2px"
};
