var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { InputText } from "@boomq/uikit";
import { SlaItemContainer } from "../../../containers/account/SlaItem";
export const SlaSystemMetricsItem = (_a) => {
    var { data, hostnameInputPlaceholder, onChangeHostname } = _a, restProps = __rest(_a, ["data", "hostnameInputPlaceholder", "onChangeHostname"]);
    return (React.createElement(SlaItemContainer, Object.assign({ data: data }, restProps),
        React.createElement(InputText, { enabled: !restProps.isDisabled, onBlur: onChangeHostname, placeholder: hostnameInputPlaceholder, value: data.hostname })));
};
