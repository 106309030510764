import React from "react";
import { Switcher } from "@boomq/uikit";
import { BlockTitleText } from "../Monitoring";
import { StyledBlock } from "../Wizard";
import { DefaultCookie } from "../../DefaultCookie";
import { Text } from "../../../styled/TestProject";
import { ClearEachIterationSection, defaultCookieTheme } from "../../../../containers/account/TestProject/RequestDefaultCookiesForm";
import { BlockContent, BlockTitle } from "../../../styled/Blocks";
export const DefaultCookies = (props) => (React.createElement(StyledBlock, null,
    React.createElement(BlockTitle, null,
        React.createElement(BlockTitleText, null, props.title)),
    React.createElement(BlockContent, null,
        (props.defaultCookies || []).map((cookie) => (React.createElement(DefaultCookie, { key: cookie.id, data: cookie, httpsOnlyCheckboxLabel: props.httpsOnlyCheckboxLabel, name: "defaultCookie", onChange: props.onChangeItem, onDelete: props.onDelete, parameters: props.parameterList, placeholderDomain: props.placeholderDomain, placeholderName: props.placeholderName, placeholderPath: props.placeholderPath, placeholderValue: props.placeholderValue, theme: defaultCookieTheme }))),
        React.createElement(ClearEachIterationSection, null,
            React.createElement(Switcher, { defaultChecked: props.clearEachIteration, name: "clearEachIteration", onChange: props.onToggleClearEachIteration }),
            React.createElement(Text, null, props.clearEachIterationSwitcherLabel)))));
