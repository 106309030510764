export var TabName;
(function (TabName) {
    TabName["Headers"] = "HEADERS";
    TabName["Body"] = "BODY";
    TabName["Cookies"] = "COOKIES";
})(TabName || (TabName = {}));
export const getRequestResponsePreviewTabItems = (responsePreviewData, isBodyBeautifyMode) => Object.keys(TabName).map((tabNameKey, index) => ({
    id: index,
    component: {
        elementName: `RequestResponse${tabNameKey}`,
        props: Object.assign(Object.assign({}, responsePreviewData), { isBodyBeautifyMode })
    },
    name: TabName[tabNameKey],
    title: tabNameKey
}));
