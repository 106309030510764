import { TestType } from "../types";
import { ProfileDistribution } from "../../models/testProject";
const getEnabledJMXGroups = (requestGroups) => requestGroups.filter((requestGroup) => (requestGroup.enabled ? true : false));
export const testProfileJMXValidator = (requestGroups = []) => getEnabledJMXGroups(requestGroups).length === 0
    ? {
        exceptionText: {
            defaultMessage: "You need at least one active request's group in the test to proceed",
            id: "account.test.profile.error.jmx.empty"
        },
        isValid: false
    }
    : { isValid: true };
const testProfileThreadGroupsSumPercentsValidator = (requestGroups) => {
    const sumPerc = requestGroups.reduce((sum, group) => (sum += Math.round(10000 * group.perc)), 0) / 10000;
    return sumPerc !== 100
        ? {
            exceptionText: {
                defaultMessage: "The sum of percentage of request's transactions must be 100%. Sum is {sumPerc} now",
                id: "account.test.profile.error.sum.percent.pages",
                values: {
                    sumPerc: sumPerc
                }
            },
            isValid: false
        }
        : { isValid: true };
};
const testProfileStableScalabilityPerentDistributionValidator = (requestGroups = [], requests = []) => {
    const { exceptionText: isExistRequestsExceptionText, isValid: isExistRequests } = testProfileStableScalabilityUsersDistributionValidator(requestGroups, requests);
    const { exceptionText: isValidThreadGroupsSumPercExceptionText, isValid: isValidThreadGroupsSumPerc } = testProfileThreadGroupsSumPercentsValidator(requestGroups);
    const isValid = isExistRequests && isValidThreadGroupsSumPerc;
    return isValid
        ? { isValid }
        : {
            exceptionText: isExistRequestsExceptionText || isValidThreadGroupsSumPercExceptionText,
            isValid
        };
};
const testProfileStableScalabilityUsersDistributionValidator = (requestGroups = [], requests = []) => requestGroups.length === 0 || !requests || requests.length === 0
    ? {
        exceptionText: {
            defaultMessage: "You need at least one request in the test to proceed",
            id: "account.test.profile.error.empty"
        },
        isValid: false
    }
    : { isValid: true };
const testProfileStableScalabilityValidatorByProfileDistribution = ({ profileDistribution, requests, threadGroups }) => {
    const enabledRequestGroups = (threadGroups || []).filter((group) => group.enabled);
    const enabledRequestGroupsIds = (enabledRequestGroups || []).map((group) => group.id);
    const enabledGroupsRequests = (requests || []).filter((request) => enabledRequestGroupsIds.includes(request.groupId));
    return profileDistribution === ProfileDistribution.Percent
        ? testProfileStableScalabilityPerentDistributionValidator(enabledRequestGroups, enabledGroupsRequests)
        : testProfileStableScalabilityUsersDistributionValidator(enabledRequestGroups, enabledGroupsRequests);
};
export const testProfileValidator = ({ profileDistribution, requests, testType, threadGroups = [] }) => {
    return testType === TestType.JMX
        ? testProfileJMXValidator(threadGroups)
        : testProfileStableScalabilityValidatorByProfileDistribution({ profileDistribution, threadGroups, requests });
};
