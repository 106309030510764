import { formatIntlMessage } from "../intl";
import { getSlaRequestName, getSlaRestrictionType, getSlaTargetType, getSlaThreadGroupName, getSlaTransactionName } from "../sla/slaParams";
import { isEmpty } from "../utils/isEmpty";
import { SlaTargetType } from "../../models/sla/enums";
export const generateSlaItemText = (slaItemTextSubstrings) => (slaItemTextSubstrings || []).filter((slaItemTextSubstring) => !isEmpty(slaItemTextSubstring)).join(", ");
const generateSlaRestrictionTypeFormattedMessage = (slaTypeItemData) => {
    const restrictionType = getSlaRestrictionType(slaTypeItemData);
    return {
        defaultMessage: restrictionType,
        id: `account.sla.report.profile.restriction.type.${restrictionType}.label`
    };
};
const getFormattedSlaRestrictionType = (slaTypeItemData, formatMessage) => formatIntlMessage(generateSlaRestrictionTypeFormattedMessage(slaTypeItemData), formatMessage);
const getFormattedSlaTargetType = (slaTypeItemData, formatMessage) => {
    const targetType = getSlaTargetType(slaTypeItemData);
    return formatIntlMessage({
        defaultMessage: targetType,
        id: `account.sla.report.profile.type.${targetType}.label`
    }, formatMessage);
};
const generateFormattedSlaTestItemText = (slaTypeItemData, formatMessage) => generateSlaItemText([
    getFormattedSlaTargetType(slaTypeItemData, formatMessage),
    getFormattedSlaRestrictionType(slaTypeItemData, formatMessage)
]);
const generateFormattedSlaThreadItemText = (slaTypeItemData, formatMessage) => generateSlaItemText([
    getSlaThreadGroupName(slaTypeItemData),
    getFormattedSlaRestrictionType(slaTypeItemData, formatMessage)
]);
const generateFormattedSlaTransactiontItemText = (slaTypeItemData, formatMessage) => generateSlaItemText([
    getSlaTransactionName(slaTypeItemData),
    generateFormattedSlaThreadItemText(slaTypeItemData, formatMessage)
]);
const generateFormattedSlaRequestItemText = (slaTypeItemData, formatMessage) => generateSlaItemText([
    getSlaRequestName(slaTypeItemData),
    generateFormattedSlaTransactiontItemText(slaTypeItemData, formatMessage)
]);
const slaTypeItemTextGeneratorByType = {
    [SlaTargetType.Request]: generateFormattedSlaRequestItemText,
    [SlaTargetType.Test]: generateFormattedSlaTestItemText,
    [SlaTargetType.ThreadGroup]: generateFormattedSlaThreadItemText,
    [SlaTargetType.Transaction]: generateFormattedSlaTransactiontItemText
};
export const generateSlaTypeItemFormattedText = (slaTypeItemData, formatMessage) => {
    const slaTargetType = getSlaTargetType(slaTypeItemData);
    return Object.keys(slaTypeItemTextGeneratorByType).includes(slaTargetType)
        ? slaTypeItemTextGeneratorByType[slaTargetType](slaTypeItemData, formatMessage)
        : generateFormattedSlaTestItemText(slaTypeItemData, formatMessage);
};
