import { getHours, getMinutes, getSeconds } from "./date";
import { TimeParts } from "./types";
export const getTimeSymbolIntlMessageByName = (timePartName) => ({
    defaultMessage: String(timePartName)[0],
    id: `time.${timePartName}`
});
export const getFormattedSymbolByLocale = (timePartName, intlFormatFunc) => {
    const timeSymbolIntlMessage = getTimeSymbolIntlMessageByName(timePartName);
    return typeof intlFormatFunc === "function"
        ? intlFormatFunc(timeSymbolIntlMessage)
        : timeSymbolIntlMessage.defaultMessage;
};
export const getFormattedTimeByLocale = (timestamp, intlFormatFunc) => {
    const timePartValues = {
        [TimeParts.Hour]: getHours(timestamp),
        [TimeParts.Min]: getMinutes(timestamp),
        [TimeParts.Sec]: getSeconds(timestamp)
    };
    const formattedTimelineArr = Object.keys(timePartValues).reduce((res, timePartName) => {
        const timePartValue = Number(timePartValues[timePartName]);
        return timePartValue > 0
            ? [...res, `${timePartValue} ${getFormattedSymbolByLocale(timePartName, intlFormatFunc)}`]
            : res;
    }, []);
    return formattedTimelineArr.length > 0 ? formattedTimelineArr.join(" ") : "0";
};
