import { select, put } from "redux-saga/effects";
import newTestActions from "../actions/newTest";
import { getTestProjectSettingsField } from "../reducers/newTest";
import { generatePreviewData } from "../../helpers/generators";
const { testProjectChangeParamsField } = newTestActions;
export function* chartPreviewGenerator() {
    const loadType = yield select(getTestProjectSettingsField, "loadType");
    const { rampDown, rampUp, durationAddedOnLastStep, stepCount, stepLength, usersPerStep } = yield select(getTestProjectSettingsField, loadType.name);
    const value = [
        {
            coords: generatePreviewData({
                loadType: loadType.name,
                rampDown,
                rampUp,
                durationAddedOnLastStep,
                stepCount,
                stepLength,
                usersPerStep
            })
        }
    ];
    yield put(testProjectChangeParamsField({ field: "chartData", value }));
}
