import { round } from "@boomq/utils";
export const generateTableCommonParams = () => ({
    withHeadings: true,
    content: []
});
const generateTableContentRow = ({ cells }) => {
    const formattedRowData = (cells || []).map(({ value }) => value);
    formattedRowData[formattedRowData.length - 1] = round(100 * formattedRowData[formattedRowData.length - 1], 1);
    return formattedRowData;
};
const generateTableContent = (rows) => (rows || []).map(generateTableContentRow);
const generateTableHeader = (columns) => (columns || []).map(({ name }) => name);
export const generateTableDataForSLA = ({ columns, rows }) => (Object.assign(Object.assign({}, generateTableCommonParams()), { content: [generateTableHeader(columns), ...generateTableContent(rows)] }));
