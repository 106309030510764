var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { useIntl } from "@boomq/utils";
import { Button, ButtonSize, Uploader } from "@boomq/uikit";
import { FILE_EXTENSIONS } from "./constants";
import { CLOSE_BUTTON_LABEL, UPLOADER_EMPTY_TEXT } from "./intl";
import { ButtonBlock } from "./styled";
import { withResize } from "../../../../components/styled/response";
import { formatIntlMessage } from "../../../../helpers/intl";
import newTestActions from "../../../../redux/actions/newTest";
import { getGrpcBuffer } from "../../../../redux/reducers/newTest";
const { removeGrpcProtoFile, uploadGrpcProtoFileRequest } = newTestActions;
const connector = connect((state) => ({
    files: getGrpcBuffer(state)
}), {
    onRemoveFile: removeGrpcProtoFile,
    onUploadFile: uploadGrpcProtoFileRequest
});
const GrpcBufferFormContainer = (_a) => {
    var { closeModalWindow } = _a, restProps = __rest(_a, ["closeModalWindow"]);
    const { formatMessage } = useIntl();
    const closeButtonLabel = formatIntlMessage(CLOSE_BUTTON_LABEL, formatMessage);
    const clickCloseButtonHandler = () => closeModalWindow === null || closeModalWindow === void 0 ? void 0 : closeModalWindow();
    const uploadFileHandler = (files) => restProps.onUploadFile(files);
    const removeFileHandler = (fileId) => restProps.onRemoveFile(fileId);
    return (React.createElement(React.Fragment, null,
        React.createElement(Uploader, { emptyText: UPLOADER_EMPTY_TEXT, extensions: FILE_EXTENSIONS, items: restProps.files, name: "files", onRemoveFile: removeFileHandler, onUploadFiles: uploadFileHandler }),
        React.createElement(ButtonBlock, null,
            React.createElement(Button, { onClick: clickCloseButtonHandler, size: ButtonSize.big }, closeButtonLabel))));
};
export default withResize(withRouter(connector(GrpcBufferFormContainer)));
