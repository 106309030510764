import { call, put, takeEvery } from "redux-saga/effects";
import { modelSchemaRequest, modelSchemaFailed, modelSchemaSuccess } from "../actions/testProject";
import { getTestProjectModelSchema } from "../../services/projectSrv";
export function* modelSchemaFlow() {
    try {
        const { data } = yield call(getTestProjectModelSchema);
        yield put(modelSchemaSuccess(data));
    }
    catch (e) {
        console.error("Test project model schema is not downloaded");
        yield put(modelSchemaFailed());
    }
}
export function* testProjectModelSchemaFlow() {
    yield takeEvery(modelSchemaRequest, modelSchemaFlow);
}
