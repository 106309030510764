var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { put, select, takeEvery } from "redux-saga/effects";
import { getValueByPath } from "@boomq/utils";
import { threadGroupEditorBackupKeyGenerationFlow } from "./threadGroupEditor";
import { changeTreeData, deleteEditorBackupData, deleteEditorElementByPath, refreshTreeData, resetEditorState, saveTreeData, setElement, setElementField, setElements, setExpandedKeys, setExtractFromResponseParameters, setFirstElementIdWithError, setForEachOutputVariableName, setForEachParameters, setTimers } from "../actions/editor";
import { hideLoader } from "../actions/loader";
import { newRequestChangeParams } from "../actions/newRequest";
import newTestActions from "../actions/newTest";
import { getSiteModal } from "../actions/siteModal";
import { getEditorGroupId, getEditorGroupName, getEditorTimers, getEditorTreeData, getElementById, getElements, getExtractFromResponseParameters, getForEachElementParametersByPath, getForEachParameters } from "../reducers/editor";
import { getRequestExtractParametersByRequest, getRequestParameterNames, getRequestTimers, getThreadGroupsElements, getThreadGroupElementsByGroupId, getThreadGroupsTree, getThreadGroupTreeById } from "../reducers/newTest";
import { RequestGrpcFormContainer } from "../../containers/account/TestProject/RequestGrpcForm";
import { RequestHttpFormContainer } from "../../containers/account/TestProject/RequestHttpForm";
import { requestFormModal } from "../../data/content/modals";
import { getExtractorParametersFromRequest, getParametersFromTree } from "../../helpers/extractResponse";
import { getThreadGroupElements } from "../../helpers/parsers";
import { getTimerListByIds } from "../../helpers/request";
import { generateBodyPreview } from "../../helpers/threadGroup";
import { deleteTreeBranchByPath, getPrevNextElementsByIdAndType, getThreadGroupEditorBackupData, setThreadGroupEditorBackupData } from "../../helpers/threadGroupEditor";
import { FormType } from "../../helpers/types";
import { threadGroupElementsValidator } from "../../helpers/validators";
import { ProfileSettingsMode } from "../../models/testProject";
import { ThreadGroupController } from "../../models/threadGroupEditor";
const { changeRequestGroup, setTestProjectElementsData, testProjectChangeParamsField } = newTestActions;
function* refreshTreeDataFlow({ payload }) {
    try {
        const { id } = payload || {};
        const threadGroupTree = yield select(getThreadGroupTreeById, id);
        const threadGroupElements = yield select(getThreadGroupElementsByGroupId, id);
        const timers = yield select(getRequestTimers);
        yield put(setElements(threadGroupElements));
        yield put(setTimers(timers));
        yield put(changeTreeData(threadGroupTree));
        yield put(hideLoader());
    }
    catch (e) {
        console.error(e);
    }
}
function* changeParametersByTreeData(treeData) {
    const elementsData = yield select(getElements);
    const { extractorParameters, forEachParameters } = getParametersFromTree({ extractorParameters: {}, forEachParameters: {} }, "", treeData, elementsData);
    yield put(setExtractFromResponseParameters(extractorParameters));
    yield put(setForEachParameters(forEachParameters));
}
function* changeTreeDataFlow(action) {
    try {
        const treeData = action.payload || [];
        return yield* changeParametersByTreeData(treeData);
    }
    catch (e) {
        console.error(e);
    }
}
function* deleteEditorElementFlow({ payload }) {
    try {
        const path = payload;
        const editorGroupId = yield select(getEditorGroupId);
        const editorTreeData = yield select(getEditorTreeData);
        const modifiedTreeData = deleteTreeBranchByPath(editorTreeData, path);
        const editorTreeElements = yield* getThreadGroupElementsByTreeData(editorGroupId, modifiedTreeData);
        yield put(setElements(editorTreeElements));
        yield put(changeTreeData(modifiedTreeData));
    }
    catch (e) {
        console.error(e);
    }
}
function* editRequestElementCommonFlow(dispatch, { payload }, element) {
    const { id, path } = payload || {};
    const _a = yield select(getElementById, id), { timerList } = _a, restRequestData = __rest(_a, ["timerList"]);
    const editorElements = yield select(getElements);
    const forEachParametersByPath = yield select(getForEachElementParametersByPath, path);
    const timers = yield select(getEditorTimers);
    const treeData = yield select(getEditorTreeData);
    const { prevNodes, nextNodes } = getPrevNextElementsByIdAndType(treeData, id, ThreadGroupController.Sampler);
    const requestParameters = prevNodes
        .map(({ id }) => editorElements[id])
        .reduce((res, requestEl) => [...res, ...getRequestExtractParametersByRequest(requestEl)], []);
    const usedRequestParameterNames = getRequestParameterNames(nextNodes.map(({ id }) => editorElements[id]));
    yield put(newRequestChangeParams(Object.assign(Object.assign({}, restRequestData), { error: {}, path, requestParameters: [...requestParameters, ...forEachParametersByPath], timerList: getTimerListByIds(timers, timerList), usedRequestParameterNames })));
    yield put(getSiteModal(requestFormModal({
        closeAction: () => dispatch({ type: newRequestChangeParams.toString() }),
        element,
        props: { formType: FormType.Edit }
    })));
}
export function* editRequestGrpcElementCommonFlow(dispatch, { payload }) {
    try {
        yield* editRequestElementCommonFlow(dispatch, { payload }, RequestGrpcFormContainer);
    }
    catch (e) {
        console.error(e);
    }
}
export function* editRequestHttpElementCommonFlow(dispatch, { payload }) {
    try {
        yield* editRequestElementCommonFlow(dispatch, { payload }, RequestHttpFormContainer);
    }
    catch (e) {
        console.error(e);
    }
}
export function* saveThreadGroupRequestCommonFlow(requestParams) {
    try {
        const _a = requestParams || {}, { timerList } = _a, restRequestNewData = __rest(_a, ["timerList"]);
        const { timerList: elementTimerList } = yield select(getElementById, restRequestNewData.id);
        const editorTimers = yield select(getEditorTimers);
        const editorExtractFromResponseParameters = yield select(getExtractFromResponseParameters);
        const filteredEditorTimers = Object.keys(editorTimers).reduce((res, timerId) => (elementTimerList.includes(timerId) ? res : Object.assign(Object.assign({}, res), { [timerId]: editorTimers[timerId] })), {});
        const newRequestTimers = timerList.reduce((res, timer) => (Object.assign(Object.assign({}, res), { [timer.id]: timer })), {});
        delete restRequestNewData.error;
        yield put(setTimers(Object.assign(Object.assign({}, filteredEditorTimers), newRequestTimers)));
        yield put(setExtractFromResponseParameters(Object.assign(Object.assign({}, editorExtractFromResponseParameters), { [restRequestNewData.id]: getExtractorParametersFromRequest(restRequestNewData, restRequestNewData.path) })));
        yield put(setElement(Object.assign(Object.assign({}, restRequestNewData), { bodyPreview: generateBodyPreview(restRequestNewData.body), errorProperties: {}, timerList: Object.keys(newRequestTimers) })));
    }
    catch (e) {
        console.error(e);
    }
}
function* saveThreadGroupsTree({ editorGroupId, editorTreeData }) {
    const threadGroupsTree = yield select(getThreadGroupsTree);
    yield put(testProjectChangeParamsField({
        field: "threadGroupsTree",
        value: Object.assign(Object.assign({}, threadGroupsTree), { [editorGroupId]: editorTreeData })
    }));
}
function* saveTreeElementsData({ editorGroupId, editorTreeElements }) {
    const elements = yield select(getThreadGroupsElements, editorGroupId);
    const filteredTreeElements = Object.keys(elements).reduce((res, elementId) => elements[elementId].groupId === editorGroupId ? res : Object.assign(Object.assign({}, res), { [elementId]: elements[elementId] }), {});
    yield put(setTestProjectElementsData(Object.assign(Object.assign({}, filteredTreeElements), editorTreeElements)));
    yield* saveTreeElementsTimers({ editorGroupId, editorTreeElements });
}
function* saveTreeElementsTimers({ editorGroupId, editorTreeElements }) {
    const editorTimers = yield select(getEditorTimers);
    const timers = yield select(getRequestTimers);
    const editorTreeElementsTimers = Object.keys(editorTreeElements).reduce((res, elementId) => (Object.assign(Object.assign({}, res), (editorTreeElements[elementId].timerList || []).reduce((resTimers, timerId) => (Object.assign(Object.assign({}, resTimers), { [timerId]: editorTimers[timerId] })), {}))), {});
    const filteredTreeElementsTimers = Object.keys(timers).reduce((res, timerId) => (timers[timerId].groupId === editorGroupId ? res : Object.assign(Object.assign({}, res), { [timerId]: timers[timerId] })), {});
    yield put(testProjectChangeParamsField({
        field: "timers",
        value: Object.assign(Object.assign({}, filteredTreeElementsTimers), editorTreeElementsTimers)
    }));
}
function* saveTreeDataFlow({ editorTreeElements, editorGroupId, editorTreeData }) {
    const groupName = yield select(getEditorGroupName);
    yield* saveThreadGroupsTree({ editorGroupId, editorTreeData });
    yield* saveTreeElementsData({ editorGroupId, editorTreeElements });
    yield put(changeRequestGroup({
        field: "name",
        id: editorGroupId,
        value: groupName
    }));
    yield put(testProjectChangeParamsField({
        field: "profileSettingsMode",
        value: ProfileSettingsMode.ThreadGroups
    }));
    yield* deleteThreadGroupEditorBackupInLocalStorage();
    yield put(resetEditorState());
}
function* setValidatedElementsData({ elPathsBeforeError, firstElementIdWithError, validatedElementsData }) {
    const expandedKeys = Object.keys(validatedElementsData).reduce((res, elementId) => elPathsBeforeError.includes(getValueByPath(validatedElementsData[elementId], "path"))
        ? Object.assign(Object.assign({}, res), { [elementId]: true }) : res, {});
    yield put(setElements(validatedElementsData));
    yield put(setExpandedKeys(expandedKeys));
    yield put(setFirstElementIdWithError(firstElementIdWithError));
}
function* getThreadGroupElementsByTreeData(groupId, treeData) {
    try {
        const editorElements = yield select(getElements);
        const { elementsData } = getThreadGroupElements({
            elementsData: {},
            extractorParameters: [],
            groupElements: treeData,
            groupId,
            requests: [],
            parentTransactionId: groupId,
            path: "",
            timers: {},
            transactions: []
        });
        const elementsIds = Object.keys(elementsData);
        return Object.keys(editorElements).reduce((res, elementId) => elementsIds.includes(elementId)
            ? Object.assign(Object.assign({}, res), { [elementId]: Object.assign(Object.assign(Object.assign({}, elementsData[elementId]), editorElements[elementId]), { path: elementsData[elementId].path }) }) : res, {});
    }
    catch (e) {
        return {};
    }
}
function* deleteThreadGroupEditorBackupInLocalStorage() {
    try {
        const threadGroupEditorBackupKey = yield* threadGroupEditorBackupKeyGenerationFlow();
        const currentEditorBackupData = getThreadGroupEditorBackupData();
        currentEditorBackupData === null || currentEditorBackupData === void 0 ? true : delete currentEditorBackupData[threadGroupEditorBackupKey];
        setThreadGroupEditorBackupData(currentEditorBackupData);
    }
    catch (e) {
        console.error(e);
    }
}
function* treeDataSavingFlow() {
    try {
        const editorTreeData = yield select(getEditorTreeData);
        const editorGroupId = yield select(getEditorGroupId);
        const editorTreeElements = yield* getThreadGroupElementsByTreeData(editorGroupId, editorTreeData);
        const { elPathsBeforeError, isValid, firstElementIdWithError, validatedElementsData } = threadGroupElementsValidator(editorTreeElements);
        return isValid
            ? yield* saveTreeDataFlow({ editorTreeElements, editorGroupId, editorTreeData })
            : yield* setValidatedElementsData({
                elPathsBeforeError,
                firstElementIdWithError,
                validatedElementsData
            });
    }
    catch (e) {
        console.error(e);
    }
}
function* setForEachOutputVariableNameFlow(action) {
    const outputVariableNameData = action && action.payload ? action.payload : {};
    const forEachParameters = yield select(getForEachParameters);
    const modifiedForEachParameters = Object.assign(Object.assign({}, forEachParameters), { [outputVariableNameData.id]: Object.assign(Object.assign({}, (forEachParameters[outputVariableNameData.id] || {})), { id: outputVariableNameData.id, name: outputVariableNameData.value }) });
    yield put(setElementField(outputVariableNameData));
    yield put(setForEachParameters(modifiedForEachParameters));
}
export function* threadGroupFlow() {
    yield takeEvery(changeTreeData, changeTreeDataFlow);
    yield takeEvery(deleteEditorBackupData, deleteThreadGroupEditorBackupInLocalStorage);
    yield takeEvery(deleteEditorElementByPath, deleteEditorElementFlow);
    yield takeEvery(refreshTreeData, refreshTreeDataFlow);
    yield takeEvery(saveTreeData, treeDataSavingFlow);
    yield takeEvery(setForEachOutputVariableName, setForEachOutputVariableNameFlow);
}
