import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { getUniqueId } from "@boomq/utils";
import { PFLBChart, PFLBChartWithIntl } from "@boomq/common";
import store from "../../../../redux";

export class PFLBChartConnectedToStore extends PFLBChart {
    constructor({ data, ...restParams }) {
        const pflbChartId = data?.id || getUniqueId();

        super({ data, pflbChartId, ...restParams });

        this._pflbChartId = pflbChartId;
    }

    render() {
        const rootNode = document.createElement("div");

        render(
            <Provider store={store}>
                <PFLBChartWithIntl
                    id={this.pflbChartId}
                    onToggleChartParamsSidebar={() => super.toggleChartParamsSidebar()}
                />
            </Provider>,
            rootNode
        );

        return super.render(rootNode);
    }
}
