import React, { FC } from "react";
import { connect } from "react-redux";
import { useIntl } from "@boomq/utils";
import { selectTeamRequest, setTitle } from "@boomq/common";
import { TEAMS_PAGE_SUBTITLE, TEAMS_PAGE_TITLE } from "./intl";
import { Teams, TeamsCommonProps, TeamsHandlers } from "../../../../components/pages/account/Teams";

const connector = connect(null, {
    onSelectTeam: selectTeamRequest,
    setTitle
});

const TeamsContainer: FC<TeamsCommonProps & TeamsHandlers> = (props: TeamsCommonProps & TeamsHandlers): JSX.Element => {
    const { formatMessage } = useIntl();

    const pageTitle = formatMessage(TEAMS_PAGE_TITLE);
    const pageSubtitle = formatMessage(TEAMS_PAGE_SUBTITLE);

    return (
        <Teams {...props} pageSubtitle={pageSubtitle} pageTitle={pageTitle} />
    );
};

export default connector(TeamsContainer);
