export const DETAILED_STATS_BUTTON_LABEL = {
    defaultMessage: "Detailed stats",
    id: "account.ended.test.button.details"
};
export const DETAILED_SYS_METRICS_LABEL = {
    defaultMessage: "Detailed system metrics stats",
    id: "account.ended.test.button.sys.metrics.details"
};
export const STOP_TEST_BUTTON_LABEL = {
    defaultMessage: "Stop Test",
    id: "account.test.details.stop.button"
};
export const STOPPING_TEXT = {
    defaultMessage: "Stopping...",
    id: "account.current.test.stopping"
};
export const TEST_PROJECT_BUTTON_LABEL = {
    defaultMessage: "Test",
    id: "account.ended.test.button.test.project"
};
export const TREND_BUTTON_LABEL = {
    defaultMessage: "Trend",
    id: "account.ended.test.button.trend"
};
