import { initialState } from "./newRequest";
const getStateParam = (state, paramName) => state && state.newRequest ? state.newRequest[paramName] : initialState[paramName];
export const getNewRequestAssertions = (state) => getStateParam(state, "assertions");
export const getNewRequestExtractResponse = (state) => getStateParam(state, "extractResponse");
export const getNewRequestField = (state, field) => getStateParam(state, field);
export const getNewRequestGroupId = (state) => getStateParam(state, "groupId");
export const getNewRequestId = (state) => getStateParam(state, "id");
export const getNewRequestIsShowResponseInAssertions = (state, field) => getStateParam(state, "isShowResponseInAssertions");
export const getNewRequestIsShowResponseInExtractors = (state, field) => getStateParam(state, "isShowResponseInExtractors");
export const getNewRequestParams = (state) => state.newRequest;
export const getNewRequestParamsBodyField = (field) => (state) => {
    const body = getStateParam(state, "body");
    return body ? body[field] : undefined;
};
export const getNewRequestParameters = (state) => getStateParam(state, "requestParameters");
export const getNewRequestParamsField = (field) => (state) => getStateParam(state, field);
export const getNewRequestResponsePreviewSourceInAssertions = (state) => getStateParam(state, "responsePreviewSourceInAssertions");
export const getNewRequestResponsePreviewSourceInExtractFromResponse = (state) => getStateParam(state, "responsePreviewSourceInExtractFromResponse");
export const getNewRequestResponsePreviewData = (state) => getStateParam(state, "responsePreviewData");
export const getNewRequestTimerList = (state) => getStateParam(state, "timerList");
export const getNewRequestTimerRequestLink = (state) => getStateParam(state, "timersRequestLink");
export const getNewRequestUsedExtractRequestParameterNames = (state) => getStateParam(state, "usedRequestParameterNames");
