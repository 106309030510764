const addZero = (num) => (String(num).length === 1 ? `0${num}` : String(num));
const timestampToDate = (paramName, timestamp) => {
    const date = typeof timestamp === "undefined" ? new Date() : new Date(timestamp);
    let result;
    switch (paramName) {
        case "day":
            result = date.getDate();
            break;
        case "month":
            result = date.getUTCMonth() + 1;
            break;
        case "year":
            result = date.getFullYear();
            break;
        case "hour":
            result = date.getUTCHours();
            break;
        case "minute":
            result = date.getMinutes();
            break;
        case "second":
            result = date.getSeconds();
            break;
        case "timeZoneHour":
            result = date.getHours();
            break;
    }
    return addZero(result);
};
export const getDay = (timestamp) => timestampToDate("day", timestamp);
export const getMonth = (timestamp) => timestampToDate("month", timestamp);
export const getYear = (timestamp) => timestampToDate("year", timestamp);
export const getHours = (timestamp) => timestampToDate("hour", timestamp);
export const getTimeZoneHours = (timestamp) => timestampToDate("timeZoneHour", timestamp);
export const getMinutes = (timestamp) => timestampToDate("minute", timestamp);
export const getSeconds = (timestamp) => timestampToDate("second", timestamp);
export const getTimeOnly = (timestamp) => `${getMinutes(timestamp)}:${getSeconds(timestamp)}`;
export const getFullTime = (timestamp) => `${getHours(timestamp)}:${getMinutes(timestamp)}:${getSeconds(timestamp)}`;
export const getDateOnly = (timestamp) => `${getDay(timestamp)}.${getMonth(timestamp)}.${getYear(timestamp)}`;
export const getFullDate = (timestamp) => `${getDay(timestamp)}.${getMonth(timestamp)}.${getYear(timestamp)} ${getHours(timestamp)}:${getMinutes(timestamp)}`;
export const getFullDateTime = (timestamp) => `${getFullDate(timestamp)}:${getSeconds(timestamp)}`;
