import { eventChannel, END } from "redux-saga";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import { SseEventType } from "../../models/testRun";
export function subscribeToSse(token, url) {
    return eventChannel((emitter) => {
        const controller = new AbortController();
        const { signal } = controller;
        fetchEventSource(url, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            signal,
            onmessage(event) {
                try {
                    const data = JSON.parse(event.data);
                    emitter({ type: SseEventType.Messege, data });
                }
                catch (error) {
                    emitter({ type: SseEventType.ParseError });
                }
            },
            onerror(error) {
                emitter({ type: SseEventType.ConnectionError, error });
                controller.abort();
                emitter(END);
            },
            onclose() {
                emitter({ type: SseEventType.Close });
                controller.abort();
                emitter(END);
            }
        });
        return () => {
            controller.abort();
            emitter(END);
        };
    });
}
