export const PARAMETER_ACTIONS_DELETE_TEXT = {
    defaultMessage: "Delete",
    id: "account.test.project.settings.set.parameters.csv.actions.delete.label"
};
export const PARAMETER_FILE_NAME_TEXT = {
    defaultMessage: "File: {fileName}",
    id: "account.new.test.project.parameters.import.csv.file.name.title"
};
export const PARAMETER_NAMES_TITLE_TEXT = {
    defaultMessage: "Parameters: {params}",
    id: "account.new.test.project.parameters.import.csv.parameters.title"
};
