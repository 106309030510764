import React from "react";
import { CartesianGrid, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { isEmpty, round, useResize } from "@boomq/utils";
import { Color } from "@boomq/uikit";
import { ResizeLineChart } from "./styled";
import { sizes } from "../../styled/response";
import { chartTimelineTickFormatter } from "../../../helpers/chart";
export const PreviewChart = ({ data, height, isShowLegend, legendPayload, min, max, minHeight = 250 }) => {
    const { width } = useResize();
    const getMinMaxTicks = (min, max) => {
        const res = isEmpty(min) ? [] : [min];
        return isEmpty(max) ? res : [...res, max];
    };
    const getTicks = (data = [], key, min, max) => {
        const linesData = Array.isArray(data) && data.reduce((res, line) => (line.coords ? [...res, ...line.coords] : res), []);
        return [
            ...new Set([...linesData.reduce((res, point) => [...res, point[key]], []), ...getMinMaxTicks(min, max)])
        ].sort((a, b) => a - b);
    };
    const getMinDomain = (dataMin) => (isEmpty(min) ? dataMin : min);
    const getMaxDomain = (dataMax) => (isEmpty(max) ? dataMax : max);
    const xAxisTicks = getTicks(data, "time", min, max);
    const yAxisTicks = getTicks(data, "users");
    return (React.createElement(ResponsiveContainer, { width: "100%", height: height || minHeight, minHeight: width <= sizes.tablet ? 120 : minHeight },
        React.createElement(ResizeLineChart, { margin: {
                bottom: 0,
                right: width <= sizes.giant ? 30 : 10,
                top: 0
            } },
            React.createElement(CartesianGrid, { strokeDasharray: "3 3" }),
            React.createElement(XAxis, { dataKey: "time", type: "number", domain: [(dataMin) => getMinDomain(dataMin), (dataMax) => getMaxDomain(dataMax)], tickCount: xAxisTicks.length, tickFormatter: chartTimelineTickFormatter, tickLine: false, tickMargin: 0.5, ticks: [...xAxisTicks] }),
            React.createElement(YAxis, { type: "number", allowDecimals: false, domain: [0, (dataMax) => round(1.1 * dataMax, 1) + 1], tickLine: false, ticks: [...yAxisTicks] }),
            React.createElement(Tooltip, { labelFormatter: (value) => `Time: ${chartTimelineTickFormatter(Number(value))}` }),
            Array.isArray(data) &&
                data.map((line, index) => line.coords && (React.createElement(Line, { key: index, connectNulls: true, data: line.coords, dataKey: "users", dot: false, name: "Users", stroke: line.params && line.params.color ? line.params.color : Color.blue, strokeWidth: 3, type: "linear" }))),
            isShowLegend && React.createElement(Legend, { payload: legendPayload }))));
};
