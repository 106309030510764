import React from "react";
import { FormattedMessage } from "react-intl";
import { CardButton, CardText, CardTitle, StyleCard } from "./styled";
const Card = ({ title, text, buttonLabel, onClickCardButton }) => {
    return (React.createElement(StyleCard, null,
        React.createElement(CardTitle, null,
            React.createElement(FormattedMessage, Object.assign({}, title))),
        React.createElement(CardText, null,
            React.createElement(FormattedMessage, Object.assign({}, text))),
        React.createElement(CardButton, { onClick: onClickCardButton },
            React.createElement(FormattedMessage, Object.assign({}, buttonLabel)))));
};
export default Card;
