import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { isEmpty, round } from "@boomq/utils";
import { Color, IconMinus, IconPlus } from "@boomq/uikit";
import { Button, ItemWrapper, SliderInputPanel, SliderWrapper, StyledHelp, StyledTypeSliderInput, TextBlock, Wrapper } from "./styled";
import { TypeSliderButtonType, TypeSliderInputType } from "../../../helpers/types";
export const TypeSlider = ({ changeHandler, disabled, handleStyle, label, name, railStyle, slider, slider: { value = 0, min, max, step }, tooltipText, trackStyle, units }) => {
    const [sliderValue, setSliderValue] = useState(value || 0);
    useEffect(() => {
        setSliderValue(slider.value);
    }, [slider]);
    const getValueByMin = (value) => (value < min ? min : value);
    const getValueByMax = (value) => (value > max ? max : value);
    const getValue = (value) => {
        const newValue = getValueByMin(value);
        return getValueByMax(newValue);
    };
    const changeSliderValue = (newValue, type) => {
        setSliderValue(newValue);
        changeHandler(name, newValue, type);
    };
    const changeSliderValueByButton = (value) => changeSliderValue(getValue(round(value)), TypeSliderInputType.Slider);
    const buttonHandlers = (type, count) => {
        switch (type) {
            case TypeSliderButtonType.Increment:
                return changeSliderValueByButton(sliderValue + step);
            case TypeSliderButtonType.Decrement:
                return changeSliderValueByButton(sliderValue - step);
            default:
                return changeSliderValueByButton(count);
        }
    };
    const changeSliderHandler = (count) => changeHandler(name, count, TypeSliderInputType.Slider);
    const changeSliderInputHandler = (value) => changeSliderValue(round(value), TypeSliderInputType.Input);
    const getUnits = (units) => units ? (React.createElement(React.Fragment, null,
        "(",
        React.createElement(FormattedMessage, Object.assign({}, units)),
        ")")) : (React.createElement(React.Fragment, null));
    return (React.createElement(ItemWrapper, null,
        React.createElement(TextBlock, null,
            React.createElement(FormattedMessage, Object.assign({}, label, { values: { units: getUnits(units) } })),
            !isEmpty(tooltipText) && React.createElement(StyledHelp, { tooltipText: tooltipText })),
        React.createElement(Wrapper, { disabled: disabled },
            React.createElement(SliderWrapper, null,
                React.createElement(SliderInputPanel, null,
                    React.createElement(Button, { onClick: () => buttonHandlers(TypeSliderButtonType.Decrement) },
                        React.createElement(IconMinus, { fill: Color.blue })),
                    React.createElement(StyledTypeSliderInput, { min: min, onBlur: changeSliderInputHandler, value: sliderValue }),
                    React.createElement(Button, { onClick: () => buttonHandlers(TypeSliderButtonType.Increment) },
                        React.createElement(IconPlus, { fill: Color.blue }))),
                React.createElement(Slider, { value: sliderValue, min: min, max: max, step: step, onChange: (count) => setSliderValue(count), onAfterChange: changeSliderHandler, marks: { [min]: `${min}`, [max]: `${max}` }, handleStyle: Object.assign({}, handleStyle), railStyle: Object.assign({}, railStyle), trackStyle: Object.assign({}, trackStyle) })))));
};
