import React from "react";
import { connect } from "react-redux";
import { useIntl } from "@boomq/utils";
import { EditableTitle } from "../../../common/EditableTitle";
import { titleTheme } from "../../../../components/styled/Common";
import { formatIntlMessage } from "../../../../helpers/intl";
import { nameValidator } from "../../../../helpers/validators";
import { slaReportProfileChangeParamsField } from "../../../../redux/actions/slaReportProfile";
import { getSlaReportProfileName } from "../../../../redux/reducers/slaReportProfile";
const connector = connect((state) => ({
    name: getSlaReportProfileName(state)
}), { onChange: slaReportProfileChangeParamsField });
const SlaReportProfileName = ({ name, onChange }) => {
    const { formatMessage } = useIntl();
    const changeHandler = (value) => onChange({ field: "name", value });
    const validateFunc = (value) => {
        const { isValid, error } = nameValidator(value);
        return { isValid, error: formatIntlMessage(error, formatMessage) };
    };
    return (React.createElement(EditableTitle, { editModeTheme: titleTheme, onChange: changeHandler, theme: titleTheme, validateFunc: validateFunc, value: name }));
};
export default connector(SlaReportProfileName);
