var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { getValueByPath } from "@boomq/utils";
import { TestType } from "../types";
import { slaByTestDurationValidator } from "../validators/sla";
import { testSettings } from "../../data/content/testSettings";
const TIME_COEFFICIENT = 60;
const getLoadProfileParamsFromList = (testSettings) => {
    const testParams = testSettings.reduce((res, testSetting) => (Object.assign(Object.assign({}, res), { [testSetting.name]: {
            name: testSetting.name,
            type: testSetting.type
        } })), {});
    return Object.keys(testParams).reduce((res, testParamName) => [...res, testParams[testParamName]], []);
};
export const getLoadProfileParams = () => getLoadProfileParamsFromList([...testSettings.SCALABILITY, ...testSettings.JMX]);
export const getJMXLoadProfileDurationParamNames = () => getLoadProfileParamsFromList(testSettings.JMX)
    .filter((profileParam) => profileParam.type === "time")
    .map((profileParam) => profileParam.name);
const calculateJMXTestStepDuration = (sum, paramValues = {}) => getLoadProfileParams().reduce((res, param) => {
    const paramValue = param.type === "time" ? paramValues[param.name] : 0;
    return isNaN(paramValue) ? res : res + paramValue * TIME_COEFFICIENT;
}, sum);
const getSlaWarningByTestDuration = (testProjectData, slaData) => {
    const { isValid, exceptionText } = slaByTestDurationValidator(testProjectData, slaData);
    return isValid ? undefined : exceptionText;
};
export const getSlaWarning = (testType, testProjectData, slaData) => testType === TestType.JMX ? undefined : getSlaWarningByTestDuration(testProjectData, slaData);
const calculateJMXTestStepVUserMins = (res, { rampDown, rampUp, stepLength, usersPerStep }) => {
    const stepDurationVUserMins = usersPerStep * stepLength;
    const rampUpVUserMins = (usersPerStep * rampUp) / 2;
    const rampDownVUserMins = (usersPerStep * rampDown) / 2;
    return res + rampDownVUserMins + rampUpVUserMins + stepDurationVUserMins;
};
const calculateJMXTestStepLoadProfileParams = (res, _a) => {
    var { usersPerStep } = _a, restParams = __rest(_a, ["usersPerStep"]);
    return ({
        JMXTestDurationSec: calculateJMXTestStepDuration(res.JMXTestDurationSec, restParams),
        JMXTestVUserMins: calculateJMXTestStepVUserMins(res.JMXTestVUserMins, Object.assign({ usersPerStep }, restParams)),
        JMXTestVUsers: res.JMXTestVUsers + Number(usersPerStep)
    });
};
const calculateJMXTestStepsLoadProfileParams = (sum, loadProfileItem = { steps: [] }) => loadProfileItem.steps.reduce((res, step) => calculateJMXTestStepLoadProfileParams(res, step), sum);
export const calculateJMXTestLoadProfileParams = (loadProfileData = []) => Object.keys(loadProfileData).reduce((res, loadProfileKey) => calculateJMXTestStepsLoadProfileParams(res, loadProfileData[loadProfileKey]), {
    JMXTestDurationSec: 0,
    JMXTestVUsers: 0,
    JMXTestVUserMins: 0
});
export const calculateLoadProfileParamsByTestType = (testType, loadProfileData, totalDuration) => testType === TestType.JMX
    ? Object.assign(Object.assign({}, calculateJMXTestLoadProfileParams(loadProfileData)), { JMXTestDurationSec: totalDuration }) : {};
export const calculateBoomqStableScalabilityTestDurationByPercentDistribution = ({ durationAddedOnLastStep = 0, initialDelay = 0, rampUp = 0, rampDown = 0, stepLength = 0, stepCount = 1 }) => TIME_COEFFICIENT * ((rampUp + stepLength) * stepCount + rampDown + durationAddedOnLastStep + initialDelay);
export const calculateBoomqStableScalabilityTestDurationByUsersDistribution = (groups) => (groups || []).reduce((res, group) => {
    const groupDuration = calculateBoomqStableScalabilityTestDurationByPercentDistribution(getValueByPath(group, "loadProfileSettings", {}));
    return res > groupDuration ? res : groupDuration;
}, 0);
export const calculateThreadGroupUsersCount = ({ usersPerStep = 0, stepCount = 0 } = {}) => stepCount * usersPerStep;
export const calculateBoomqStableScalabilityUsersCountByUsersDistribution = (groups) => (groups || []).reduce((res, group) => res + calculateThreadGroupUsersCount(getValueByPath(group, "loadProfileSettings", {})), 0);
