var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { useIntl } from "@boomq/utils";
import { STOP_BUTTON_LABEL, STOPPING_TEXT, TEST_DETAILS_LINK_LABEL } from "./intl";
import { RunningTestsItemTemplate } from "../../../../components/account/TestRuns/RunningTestsItemTemplate";
import { stopTestModal } from "../../../../data/content/modals";
import { formatIntlMessage } from "../../../../helpers/intl";
import { getTestRoute } from "../../../../helpers/routes";
import { isTestStopping } from "../../../../helpers/testStatus";
import { getModal } from "../../../../redux/actions/modal";
import { stopTestRequest } from "../../../../redux/actions/runningTests";
import { getClientPermissionList } from "../../../../redux/reducers/authorizePlate";
import { getIsStoppingTestById } from "../../../../redux/reducers/runningTests";
const connector = connect((state, ownProps) => ({
    isStopping: getIsStoppingTestById(state, ownProps.id),
    permissionList: getClientPermissionList(state)
}), { getModal, onClickStopButton: stopTestRequest });
const RunningTestsItemTemplateContainer = (_a) => {
    var { displayState, getModal, id, isStopping, onClickStopButton, testProjectId } = _a, restProps = __rest(_a, ["displayState", "getModal", "id", "isStopping", "onClickStopButton", "testProjectId"]);
    const { formatMessage } = useIntl();
    const clickStopButtonHandler = () => getModal(stopTestModal(() => onClickStopButton({ id, testProjectId })));
    const isTestRunStopping = isStopping || isTestStopping(displayState);
    const testDetailsLink = getTestRoute(testProjectId, id);
    const stopButtonLabel = formatIntlMessage(STOP_BUTTON_LABEL, formatMessage);
    const stoppingText = formatIntlMessage(STOPPING_TEXT, formatMessage);
    const testDetailsLinkLabel = formatIntlMessage(TEST_DETAILS_LINK_LABEL, formatMessage);
    return (React.createElement(RunningTestsItemTemplate, Object.assign({}, restProps, { isTestRunStopping: isTestRunStopping, onClickStopButton: clickStopButtonHandler, stopButtonLabel: stopButtonLabel, stoppingText: stoppingText, testDetailsLink: testDetailsLink, testDetailsLinkLabel: testDetailsLinkLabel })));
};
export default withRouter(connector(RunningTestsItemTemplateContainer));
