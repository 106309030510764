var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { getContrastColorByIndex, getUniqueArrayByParam, getUniqueId, getValueByPath, isEmpty } from "@boomq/utils";
import { SELECTION_TYPE, SLA_REPORT_FILTERS, TIME_UNIT } from "./constants";
import { generateSlaTypeIdFromData } from "./slaTypeIdFromData";
import { getFullDateLocaleTimeZone } from "../date";
import { SLA_FROM_DATE_PARAM_NAME, SLA_HOST_NAME_PARAM_NAME, SLA_REQUEST_NAME_PARAM_NAME, SLA_RESTRICTION_TYPE_PARAM_NAME, SLA_TO_DATE_PARAM_NAME, SLA_TARGET_TYPE_PARAM_NAME, SLA_THREAD_GROUP_NAME_PARAM_NAME, SLA_TRANSACTION_FILTER_TYPE_PARAM_NAME, SLA_TRANSACTION_NAME_PARAM_NAME, SLA_TYPE_PARAM_NAME } from "../sla/constant";
import { getSlaHostName, getSlaRequestName, getSlaThreadGroupName, getSlaTransactionFilterType, getSlaTransactionName } from "../sla/slaParams";
import { completedStatuses, statusDescriptions, statusTitles } from "../testStatus";
import { SlaReportTableDiffType, SlaReportTableValueType, SlaReportTestFilterType, SlaReportTestFilterTimeUnit } from "../types";
import { SlaTargetType, SlaTransactionFilterType } from "../../models/sla/enums";
export const getSlaReportChartDataBySelectedSlaTypes = (chartList, slaTypeList) => chartList.filter((chart) => (slaTypeList || []).includes(generateSlaTypeIdFromData(chart.slaType)));
export const getSlaReportSettingsFiltersSelectionType = (params) => {
    const { timeAmountFilter, testLabelFilter, testStatusFilter } = params || {};
    return (isEmpty(timeAmountFilter) || timeAmountFilter === 0) &&
        isEmpty(testStatusFilter) &&
        isEmpty(testLabelFilter)
        ? SELECTION_TYPE[0]
        : SELECTION_TYPE[1];
};
export const getSlaReportSettingsFiltersBySelectionType = ({ selectionTypeFilter, testIdsFilter, testLabelFilter, testStatusFilter, timeAmountFilter, timeUnitFilter }) => selectionTypeFilter === SELECTION_TYPE[0]
    ? { testIdsFilter }
    : { testLabelFilter, testStatusFilter, timeAmountFilter, timeUnitFilter };
const getSlaReportValueTypeItemLabel = (type) => ({
    defaultMessage: type,
    id: `account.sla.report.profile.table.value.type.${type}`
});
export const getSlaReportTableValueTypeList = () => [SlaReportTableValueType.Value, SlaReportTableValueType.PreviousTestDiff, SlaReportTableValueType.BaselineDiff].map((slaReportTableValueType) => ({
    id: slaReportTableValueType,
    title: getSlaReportValueTypeItemLabel(slaReportTableValueType),
    text: getSlaReportValueTypeItemLabel(slaReportTableValueType),
    value: slaReportTableValueType
}));
export const getSlaReportTableDiffTypeList = () => [
    SlaReportTableDiffType.Absolute,
    SlaReportTableDiffType.Relative
];
export const getPointLabel = ({ status, testId }) => `${testId}
    ${status}`;
export const getTestLabel = ({ displayState, id, startDate }) => `${id}_${getFullDateLocaleTimeZone(startDate)}_${displayState}`;
export const getTestStatusList = () => completedStatuses.map((status) => ({
    id: getUniqueId(),
    title: statusDescriptions(status),
    text: statusTitles(status),
    value: status
}));
export const getTimeUnitList = () => TIME_UNIT.map((value) => ({
    id: getUniqueId(),
    text: {
        defaultMessage: `last ${(value || "").toLowerCase()}`,
        id: `account.sla.report.profile.time.unit.${value}`
    },
    value
}));
export const isExistSettingsFiltersBySelectionType = ({ selectionTypeFilter, testIdsFilter, testLabelFilter, testStatusFilter, timeAmountFilter }) => selectionTypeFilter === SELECTION_TYPE[0]
    ? (testIdsFilter || []).length > 1
    : (!isEmpty(timeAmountFilter) && timeAmountFilter !== 0) ||
        !isEmpty(testStatusFilter) ||
        !isEmpty(testLabelFilter);
export const prepareClientTestList = (tests = []) => (tests || []).map((_a) => {
    var { comment, testProjectId, testProjectVersion, testProjectVersionId } = _a, restTestData = __rest(_a, ["comment", "testProjectId", "testProjectVersion", "testProjectVersionId"]);
    return ({
        comment,
        id: restTestData.id,
        startDate: restTestData.startDate,
        testProjectId,
        testProjectVersionId,
        text: getTestLabel(restTestData),
        totalDuration: testProjectVersion === null || testProjectVersion === void 0 ? void 0 : testProjectVersion.totalDuration,
        value: restTestData.id
    });
});
const generateLabelObjByName = (labelName) => ({
    id: labelName,
    label: labelName,
    text: labelName,
    value: labelName
});
export const prepareLabelListFromTests = (tests = []) => {
    const allTestsLabels = tests.reduce((res, test) => [...res, ...test.labelSet], []);
    return getUniqueArrayByParam(allTestsLabels, "label").map((labelItem) => generateLabelObjByName(labelItem.label));
};
const prepareSlaRequestParamsByFilterType = (slaType) => {
    const filterType = getSlaTransactionFilterType(slaType);
    return filterType === SlaTransactionFilterType.Name
        ? {
            [SLA_TRANSACTION_NAME_PARAM_NAME]: getSlaTransactionName(slaType),
            [SLA_TRANSACTION_FILTER_TYPE_PARAM_NAME]: filterType
        }
        : { [SLA_TRANSACTION_FILTER_TYPE_PARAM_NAME]: filterType };
};
const prepareSlaTypeItemData = (slaTypeItemData) => {
    const common = [
        SLA_FROM_DATE_PARAM_NAME,
        SLA_RESTRICTION_TYPE_PARAM_NAME,
        SLA_TARGET_TYPE_PARAM_NAME,
        SLA_TO_DATE_PARAM_NAME,
        SLA_TYPE_PARAM_NAME
    ].reduce((res, paramName) => (Object.assign(Object.assign({}, res), { [paramName]: slaTypeItemData[paramName] })), {});
    switch (slaTypeItemData.targetType) {
        case SlaTargetType.Request: {
            return Object.assign(Object.assign(Object.assign({}, common), prepareSlaRequestParamsByFilterType(slaTypeItemData)), { [SLA_REQUEST_NAME_PARAM_NAME]: getSlaRequestName(slaTypeItemData), [SLA_THREAD_GROUP_NAME_PARAM_NAME]: getSlaThreadGroupName(slaTypeItemData) });
        }
        case SlaTargetType.SystemMetricsHost: {
            return Object.assign(Object.assign({}, common), { [SLA_HOST_NAME_PARAM_NAME]: getSlaHostName(slaTypeItemData) });
        }
        case SlaTargetType.ThreadGroup: {
            return Object.assign(Object.assign({}, common), { [SLA_THREAD_GROUP_NAME_PARAM_NAME]: getSlaThreadGroupName(slaTypeItemData) });
        }
        case SlaTargetType.Transaction: {
            return Object.assign(Object.assign({}, common), { [SLA_THREAD_GROUP_NAME_PARAM_NAME]: getSlaThreadGroupName(slaTypeItemData), [SLA_TRANSACTION_NAME_PARAM_NAME]: getSlaTransactionName(slaTypeItemData) });
        }
        default: {
            return common;
        }
    }
};
const prepareFromDtoTestIdsFormTestIdsList = (testIdsList) => (testIdsList || []).map((testId) => ({ id: testId, value: testId }));
const prepareFromDtoTestLabelFilter = (testLabelSet) => Array.isArray(testLabelSet) ? testLabelSet.map(generateLabelObjByName) : [];
const prepareFromDtoTestStatusFilter = (testDisplayStateList) => Array.isArray(testDisplayStateList)
    ? (testDisplayStateList || []).map((displayState) => ({ value: displayState }))
    : [];
const prepareFromDtoFilterParamByType = (param = {}) => {
    const filterParam = param || {};
    switch (filterParam.type) {
        case SlaReportTestFilterType.TestIdExpression: {
            return { testIdsFilter: prepareFromDtoTestIdsFormTestIdsList(filterParam.testIdList) };
        }
        case SlaReportTestFilterType.NotExpression: {
            return {
                excludedTestIdsFilter: prepareFromDtoTestIdsFormTestIdsList(getValueByPath(filterParam, "expression.testIdList", []))
            };
        }
        case SlaReportTestFilterType.TestLabelExpression: {
            return { testLabelFilter: prepareFromDtoTestLabelFilter(filterParam.testLabelSet) };
        }
        case SlaReportTestFilterType.TestDisplayStateExpression: {
            return { testStatusFilter: prepareFromDtoTestStatusFilter(filterParam.testDisplayStateList) };
        }
        case SlaReportTestFilterType.LastTestRunsExpression: {
            return {
                timeAmountFilter: filterParam.amount,
                timeUnitFilter: SlaReportTestFilterTimeUnit.Runs
            };
        }
        case SlaReportTestFilterType.TimeWindowExpression: {
            return {
                timeAmountFilter: filterParam.amount,
                timeUnitFilter: filterParam.timeUnit
            };
        }
        default: {
            return {};
        }
    }
};
const prepareFromDtoTestLimit = (testLimit) => isEmpty(testLimit) ? {} : { timeUnitFilter: SlaReportTestFilterTimeUnit.Runs, timeAmountFilter: testLimit };
export const prepareFromDtoFilterParams = (params, testLimit) => (Object.assign(Object.assign({}, (params || []).reduce((res, param) => (Object.assign(Object.assign({}, res), prepareFromDtoFilterParamByType(param))), {})), prepareFromDtoTestLimit(testLimit)));
export const prepareProfileParams = (slaTypeList, selectedSlaTypes) => {
    const selectedSlaTypeIds = selectedSlaTypes.map((selectedSlaType) => selectedSlaType.id);
    return {
        slaTypeList: slaTypeList
            .filter((slaTypeItemData) => selectedSlaTypeIds.includes(slaTypeItemData.id))
            .map((slaTypeItemData) => prepareSlaTypeItemData(slaTypeItemData))
    };
};
export const prepareChartsParams = (slaTypeList, chartsParams) => ({
    chartProfileList: Object.keys(chartsParams || {}).map((chartIndex) => {
        const chartData = chartsParams[chartIndex] || {};
        const { chartId } = chartData || {};
        return Object.assign(Object.assign({}, prepareProfileParams(slaTypeList, chartData.slaTypes || [])), { chartId, orderInReport: Number(chartIndex) });
    })
});
export const prepareTableValueTypeParams = (tableParams) => {
    const slaReportValueType = getValueByPath(tableParams, "valueType.value");
    switch (slaReportValueType) {
        case SlaReportTableValueType.PreviousTestDiff: {
            return {
                valueType: SlaReportTableValueType.PreviousTestDiff,
                previousTestDiffType: getValueByPath(tableParams, "diffType")
            };
        }
        case SlaReportTableValueType.BaselineDiff: {
            return {
                valueType: SlaReportTableValueType.BaselineDiff,
                baselineDiffType: getValueByPath(tableParams, "diffType"),
                baselineTestId: getValueByPath(tableParams, "baselineTestId")
            };
        }
        default: {
            return {
                valueType: SlaReportTableValueType.Value
            };
        }
    }
};
export const prepareTableParams = (slaTypeList, tableParams) => {
    const { tableId } = tableParams || {};
    const selectedSlaTypes = getValueByPath(tableParams, "rowList", []).map((row) => row ? Object.assign(Object.assign({}, row.slaType), { id: generateSlaTypeIdFromData(row.slaType) }) : {});
    return Object.assign(Object.assign({ tableId }, prepareProfileParams(slaTypeList, selectedSlaTypes)), prepareTableValueTypeParams(tableParams));
};
export const prepareTablesParams = (slaTypeList, tablesParams) => ({
    tableProfileList: Object.keys(tablesParams || {}).map((tableIndex) => (Object.assign(Object.assign({}, prepareTableParams(slaTypeList, tablesParams[tableIndex])), { orderInReport: Number(tableIndex) })))
});
const getSlaTypeFromSlaTypeList = (slaType, slaTypeList, index) => {
    const slaTypeListItem = (slaTypeList || []).find((slaTypeItem) => [
        SLA_RESTRICTION_TYPE_PARAM_NAME,
        SLA_FROM_DATE_PARAM_NAME,
        SLA_TO_DATE_PARAM_NAME,
        SLA_HOST_NAME_PARAM_NAME,
        SLA_REQUEST_NAME_PARAM_NAME,
        SLA_TARGET_TYPE_PARAM_NAME,
        SLA_TRANSACTION_FILTER_TYPE_PARAM_NAME,
        SLA_TRANSACTION_NAME_PARAM_NAME,
        SLA_TYPE_PARAM_NAME
    ].every((paramName) => slaType[paramName] === slaTypeItem[paramName]));
    return slaTypeListItem ? [{ id: slaTypeListItem.id, color: getContrastColorByIndex(index) }] : [];
};
export const prepareSlaTypesFromChartList = (chartList, slaTypeList) => (chartList || []).reduce((res, chart) => [...res, ...getSlaTypeFromSlaTypeList(chart.slaType, slaTypeList, res.length)], []);
export const prepareSlaTypesFromTable = (rowList, slaTypeList) => (rowList || []).reduce((res, row) => [...res, ...getSlaTypeFromSlaTypeList(row.slaType, slaTypeList, res.length)], []);
const prepareDtoTestLimit = ({ timeUnitFilter, timeAmountFilter = 0 }) => (timeUnitFilter === SlaReportTestFilterTimeUnit.Runs ? timeAmountFilter : undefined);
const prepareDtoTimeUnitFilter = (timeUnit, timeAmount) => timeUnit === SlaReportTestFilterTimeUnit.Runs
    ? []
    : [{ type: SlaReportTestFilterType.TimeWindowExpression, amount: timeAmount, timeUnit }];
const prepareDtoTestIdList = (tests) => (tests || []).map((test) => test.value);
const prepateDtoTestDisplayStatusList = (testStatuses) => (testStatuses || []).map((testStatus) => testStatus.value);
const prepareDtoTestLabelFilter = (testLabelSet = []) => testLabelSet.map((testLabel) => testLabel.label);
const prepareDtoFilterParamByType = (params, slaReportFilter) => {
    switch (slaReportFilter) {
        case "excludedTestIdsFilter": {
            return [
                {
                    type: SlaReportTestFilterType.NotExpression,
                    expression: {
                        type: SlaReportTestFilterType.TestIdExpression,
                        testIdList: prepareDtoTestIdList(params.excludedTestIdsFilter)
                    }
                }
            ];
        }
        case "testIdsFilter": {
            return [
                {
                    type: SlaReportTestFilterType.TestIdExpression,
                    testIdList: prepareDtoTestIdList(params.testIdsFilter)
                }
            ];
        }
        case "testLabelFilter": {
            return [
                {
                    type: SlaReportTestFilterType.TestLabelExpression,
                    testLabelSet: prepareDtoTestLabelFilter(params.testLabelFilter)
                }
            ];
        }
        case "testStatusFilter": {
            return [
                {
                    type: SlaReportTestFilterType.TestDisplayStateExpression,
                    testDisplayStateList: prepateDtoTestDisplayStatusList(params.testStatusFilter)
                }
            ];
        }
        case "timeUnitFilter": {
            return params.timeAmountFilter > 0
                ? prepareDtoTimeUnitFilter(params.timeUnitFilter, params.timeAmountFilter)
                : [];
        }
        default: {
            return [];
        }
    }
};
export const prepareDtoFilterParams = (params = {}) => ({
    testFilterExpressionList: SLA_REPORT_FILTERS.reduce((res, slaReportFilter) => isEmpty((params || {})[slaReportFilter])
        ? res
        : [...res, ...prepareDtoFilterParamByType(params, slaReportFilter)], []),
    testLimit: prepareDtoTestLimit(params)
});
export const formatChartDatasetList = (chartDatasetList) => (chartDatasetList || []).map((chartDataset) => (Object.assign(Object.assign({}, chartDataset), { id: getUniqueId(), points: chartDataset.points.map((point) => (Object.assign(Object.assign({}, point), { slaResult: point.slaResult || 0 }))) })));
export const formatTableData = (_a) => {
    var { baselineDiffType, previousTestDiffType, tableId, valueType } = _a, rest = __rest(_a, ["baselineDiffType", "previousTestDiffType", "tableId", "valueType"]);
    return (Object.assign({ valueType: { id: valueType, value: valueType }, diffType: baselineDiffType || previousTestDiffType, tableId }, rest));
};
export const getOrderIndexListFromList = (list) => Array.isArray(list)
    ? list.reduce((res, listItem) => isEmpty(listItem.orderInReport) ? res : [...res, listItem.orderInReport], [])
    : [];
export const getNextOrderIndex = (indexList = [], diff = 0) => {
    const maxIndex = Array.isArray(indexList) && indexList.length > 0 ? Math.max(...indexList) : 0;
    return maxIndex + diff;
};
export const getOrderIndex = (indexList = [], itemOrder, diff = 0) => isEmpty(itemOrder) ? getNextOrderIndex(indexList, diff) : itemOrder;
const setTableRowHeight = (rowEl, height) => rowEl ? (rowEl.style.height = `${height}px`) : undefined;
const setScrollableTableRowHeight = (scrollableTable, slaTypeId, height) => {
    const slaTypeRowEl = scrollableTable && scrollableTable.current
        ? scrollableTable.current.querySelector(`#scrollableTable_${slaTypeId}`)
        : undefined;
    return slaTypeRowEl ? setTableRowHeight(slaTypeRowEl, height) : undefined;
};
export const calculateTableRowsHeight = (fixedTable, scrollableTable, rowList) => (rowList || []).forEach(({ slaType }) => {
    const slaTypeId = generateSlaTypeIdFromData(slaType);
    const slaTypeRowEl = fixedTable && fixedTable.current ? fixedTable.current.querySelector(`#fixedTable_${slaTypeId}`) : undefined;
    const { height } = slaTypeRowEl ? slaTypeRowEl.getBoundingClientRect() : { height: undefined };
    return height > 0 ? setScrollableTableRowHeight(scrollableTable, slaTypeId, height) : undefined;
});
const getSlaReportItemIdsByType = (profileList, paramIdName) => (profileList || []).reduce((res, _a) => {
    var { orderInReport } = _a, rest = __rest(_a, ["orderInReport"]);
    return (Object.assign(Object.assign({}, res), { [orderInReport]: rest[paramIdName] }));
}, {});
export const getSlaReportItemIds = (slaReportProfileData) => {
    const { chartProfileList, tableProfileList } = slaReportProfileData || {};
    return {
        chartIds: getSlaReportItemIdsByType(chartProfileList, "chartId"),
        tableIds: getSlaReportItemIdsByType(tableProfileList, "tableId")
    };
};
