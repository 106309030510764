import { FormattedMessage } from "react-intl";
import styled, { css } from "styled-components";
import { Block, Color, FontBodyBigBold, IconChartUp, IconCookies, IconHash, IconPapperClip, IconSettings, IconWebhook, withDisable } from "@boomq/uikit";
export const Button = styled.div `
    ${withDisable}
    ${FontBodyBigBold}
    align-items: center;
    background: transparent;
    border: none;
    color: ${({ selected }) => (selected ? Color.blue : Color.grey50)};
    cursor: pointer;
    display: flex;
    flex: 1;
    justify-content: center;
    line-height: normal;
    outline: none;
    padding-bottom: 11px;
    position: relative;
    transition: all 0.5s ease;
    ${({ selected }) => selected
    ? css `
                  &:after {
                      background: ${Color.blue};
                      border-radius: 6px;
                      bottom: 0;
                      content: "";
                      height: 6px;
                      position: absolute;
                      transition: all 0.5s ease;
                      width: 100%;
                  }
              `
    : css ``}
`;
const IconStyles = `
    margin-right: 4px;
`;
export const StyledBlock = styled.div `
    ${Block}
    margin-top: 16px;
`;
export const StyledIconChartUp = styled(IconChartUp) `
    fill: ${({ selected }) => (selected ? Color.blue : Color.grey50)};
    min-width: 24px;
    ${IconStyles}
`;
export const StyledIconCookies = styled(IconCookies) `
    fill: ${({ selected }) => (selected ? Color.blue : Color.grey50)};
    min-width: 24px;
    ${IconStyles}
`;
export const StyledIconHash = styled(IconHash) `
    fill: ${({ selected }) => (selected ? Color.blue : Color.grey50)};
    min-width: 24px;
    ${IconStyles}
`;
export const StyledIconPapperClip = styled(IconPapperClip) `
    fill: ${({ selected }) => (selected ? Color.blue : Color.grey50)};
    min-width: 24px;
    ${IconStyles}
`;
export const StyledIconSettings = styled(IconSettings) `
    fill: ${({ selected }) => (selected ? Color.blue : Color.grey50)};
    min-width: 25px;
    ${IconStyles}
`;
export const StyledIconWebhooks = styled(IconWebhook) `
    color: ${({ selected }) => (selected ? Color.blue : Color.grey50)};
    min-width: 24px;
    ${IconStyles}
`;
export const StyledWizard = styled.div `
    display: flex;
    padding: 25px 0 0;
    position: relative;
    &:before {
        background: ${Color.lightGrey85};
        border-radius: 6px;
        bottom: 0;
        content: "";
        height: 6px;
        left: 0;
        position: absolute;
        right: 0;
    }
`;
export const Text = styled(FormattedMessage) `
    margin-left: 10px;
`;
