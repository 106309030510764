import styled, { css } from "styled-components";
import { Block, Color, FontBodyMediumMedium } from "@boomq/uikit";
export const radioButtonTheme = {
    marginLeft: "12px",
    i: {
        marginRight: "8px"
    }
};
export const DistributionLabel = styled.div `
    ${FontBodyMediumMedium}
    color: ${Color.grey50};
`;
export const Panel = styled.div `
    ${Block}
    align-items: center;
    display: flex;
    min-height: 56px;
    padding: 16px;
    width: 100%;
    ${({ theme }) => (theme ? css(theme) : css ``)}
`;
