import styled from "styled-components";
import { SelectComboBox } from "@boomq/uikit";
import { PanelWrapper } from "../../styled/Wrappers";
export const PanelRow = styled.div `
    display: flex;
    ${SelectComboBox} {
        margin-right: 16px;
        max-width: 240px;
    }
`;
export const PanelWrapperWithMarginTop = styled(PanelWrapper) `
    margin-top: 24px;
`;
