import React from 'react';
import styled from 'styled-components';
const RoundLoader = ({ percent, children }) => (React.createElement(Element, null,
    React.createElement(Block, null, children),
    React.createElement(Svg, { viewBox: '0 0 110 110' },
        React.createElement(FirstCircle, { r: "45", cx: "50%", cy: "50%", fill: "none" }),
        React.createElement(SecondCircle, { r: "45", cx: "50%", cy: "50%", transform: "rotate(-90 55 55)", percent: percent }))));
const Element = styled.div `
    position: relative;
    width: 110px;
    height: 110px;
`;
const Block = styled.div `
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
const Svg = styled.svg `
    fill: none;
    stroke-width: 3;
`;
const FirstCircle = styled.circle `
    stroke: #E0E0E0;
`;
const SecondCircle = styled.circle `
    stroke: #176ED6;
    stroke-dasharray: ${(props) => 282.6 / 100 * Number(props.percent)} 282.6;
    transition: all 1s;
`;
export default RoundLoader;
