import React from "react";
import { IconGoogleAnalytics, IconHarFile, IconInsomnia, IconJMeter, IconPostman } from "@boomq/uikit";
import { DEFAULT_IMPORT_DATA_TO_TEST_PROJECT_TYPE_LIST } from "./constants";
import { ImportDataSourceType } from "./enums";
import { TestProjectDataSourceType } from "../testProject";
import { ImportAnalytics } from "../../components/account/TestProject/ImportAnalytics";
import { GrpcBufferFormContainer } from "../../containers/account/TestProject/GrpcBufferForm";
import { ImportDataFromFileToTestProjectContainer } from "../../containers/account/TestProject/ImportDataFromFileToTestProject";
import { ImportJmxFileContainer } from "../../containers/account/TestProject/ImportJmxFile";
import { TestType } from "../../helpers/types";
const importDataToTestProjectComponentByType = {
    [ImportDataSourceType.Google]: (props) => (React.createElement(ImportAnalytics, Object.assign({ importType: ImportDataSourceType.Google }, props))),
    [TestProjectDataSourceType.GrpcProtoBuffer]: GrpcBufferFormContainer,
    [ImportDataSourceType.Har]: ImportDataFromFileToTestProjectContainer,
    [ImportDataSourceType.Insomnia]: ImportDataFromFileToTestProjectContainer,
    [ImportDataSourceType.JMX]: ImportJmxFileContainer,
    [ImportDataSourceType.Postman]: ImportDataFromFileToTestProjectContainer
};
export const importDataFromFileIconByType = {
    [ImportDataSourceType.Har]: IconHarFile,
    [ImportDataSourceType.Insomnia]: IconInsomnia,
    [ImportDataSourceType.Postman]: IconPostman
};
export const importDataToTestProjectIconByType = Object.assign(Object.assign({}, importDataFromFileIconByType), { [ImportDataSourceType.Google]: IconGoogleAnalytics, [ImportDataSourceType.JMX]: IconJMeter });
export const importStatsTopUrlCountItems = [
    {
        id: 0,
        text: { defaultMessage: "Top 5", id: "account.top.button.5" },
        value: "5"
    },
    {
        id: 1,
        text: { defaultMessage: "Top 10", id: "account.top.button.10" },
        value: "10"
    },
    {
        id: 2,
        text: { defaultMessage: "Top 50", id: "account.top.button.50" },
        value: "50"
    }
];
const importDataToTestProjectTypesByTestType = {
    [TestType.JMX]: [ImportDataSourceType.JMX],
    [TestType.Scalability]: Object.values(ImportDataSourceType),
    [TestType.Stable]: Object.values(ImportDataSourceType)
};
export const getImportDataToTestProjectComponentBySourceType = (sourceType) => Object.keys(importDataToTestProjectComponentByType) ? importDataToTestProjectComponentByType[sourceType] : React.createElement(React.Fragment, null);
export const getImportDataToTestProjectTypesByTestType = (testType) => testType in importDataToTestProjectTypesByTestType
    ? importDataToTestProjectTypesByTestType[testType]
    : DEFAULT_IMPORT_DATA_TO_TEST_PROJECT_TYPE_LIST;
