import React from "react";
import { FormattedMessage } from "react-intl";
import { InputNumber } from "@boomq/uikit";
import { CONNECTION_TIMEOUT_HELP_TEXT, DEFAULT_HEADERS_TITLE, DEFAULT_TIMER_TITLE, OTHER_SETTINGS_TITLE, REQUEST_TIMEOUT_HELP_TEXT, TIMEOUT_TITLE } from "./intl";
import { BlockSubtitleText, DefaultHeadersSection, DefaultTimerSection, StyledBlock, StyledHelp, TimeoutSection, TimeSectionInput } from "./styled";
import { BlockContent } from "../../../styled/Blocks";
import { BlockTitleWithWarning } from "../BlockTitleWithWarning";
import { DefaultHeadersContainer } from "../../../../containers/account/TestProjectSettingsSet/DefaultHeaders";
import { DefaultTimer } from "../../../../containers/account/TestProjectSettingsSet/DefaultTimer";
import { getTimerInputUnit } from "../../../../helpers/requestTimers";
export const OtherSettings = ({ connectTimeout, connectionTimeoutLabel, onChange, requestTimeoutLabel, responseTimeout }) => {
    const changeValueHandler = (subfield, value) => typeof onChange === "function"
        ? onChange({ field: "httpRequestDefaults", subfield, value: Number(value) })
        : undefined;
    const changeConnectionTimeoutHandler = (value) => changeValueHandler("connectTimeout", value);
    const changeRequestTimeoutHandler = (value) => changeValueHandler("responseTimeout", value);
    return (React.createElement(StyledBlock, null,
        React.createElement(BlockTitleWithWarning, { title: OTHER_SETTINGS_TITLE }),
        React.createElement(BlockContent, null,
            React.createElement(BlockSubtitleText, null,
                React.createElement(FormattedMessage, Object.assign({}, DEFAULT_TIMER_TITLE))),
            React.createElement(DefaultTimerSection, null,
                React.createElement(DefaultTimer, null)),
            React.createElement(BlockSubtitleText, null,
                React.createElement(FormattedMessage, Object.assign({}, TIMEOUT_TITLE))),
            React.createElement(TimeoutSection, null,
                React.createElement(TimeSectionInput, null,
                    React.createElement(InputNumber, { decimalCount: 2, label: connectionTimeoutLabel, name: "connectTimeout", onBlur: changeConnectionTimeoutHandler, placeholder: connectionTimeoutLabel, unit: getTimerInputUnit(), value: connectTimeout }),
                    React.createElement(StyledHelp, { tooltipText: CONNECTION_TIMEOUT_HELP_TEXT })),
                React.createElement(TimeSectionInput, null,
                    React.createElement(InputNumber, { decimalCount: 2, label: requestTimeoutLabel, name: "responseTimeout", onBlur: changeRequestTimeoutHandler, placeholder: requestTimeoutLabel, unit: getTimerInputUnit(), value: responseTimeout }),
                    React.createElement(StyledHelp, { tooltipText: REQUEST_TIMEOUT_HELP_TEXT }))),
            React.createElement(BlockSubtitleText, null,
                React.createElement(FormattedMessage, Object.assign({}, DEFAULT_HEADERS_TITLE))),
            React.createElement(DefaultHeadersSection, null,
                React.createElement(DefaultHeadersContainer, null)))));
};
