import React, { useRef } from "react";
import { FormattedMessage } from "react-intl";
import { getRandomColor, getValueByPath, useIntl } from "@boomq/utils";
import { COPY_EMBED_CODE_BUTTON_LABEL } from "./intl";
import { ChartLegendLineIconSection, DeleteButton, DeleteChartButtonSection, DeleteSlaTypeButtonSection, EmbedCode, PreviewChartBlock, SlaReportChartBlock, SlaReportChartLegend, SlaReportChartSettings, SlaReportChartSettingsSlaTypes, SlaReportItemEmbedCodeSection, SlaReportItemPreviewSection, SlaReportLineLabel, SlaTypeItem } from "./styled";
import { AddSlaSelect } from "./AddSlaSelect";
import { getIconByStatus } from "../CustomizedChartDot";
import { ExcludeChartTestConfirmForm } from "../ExcludeChartTestConfirmForm";
import { SlaReportChart } from "../../../../containers/account/SlaReportProfile/SlaReportChart";
import { StyledButton } from "../../../styled/Buttons";
import { IconChartLegendLine } from "../../../styled/Icons";
import { getSlaStatusList } from "../../../../helpers/sla";
import { getSlaTypeColor, getSlaTypeById, generateSlaTypeIdFromData, generateSlaTypeItemFormattedTextWithTimeline } from "../../../../helpers/slaReport";
import { excludeSlaReportChartTestConfirmFormModal } from "../../../../data/content/modals";
const getUnselectedTypes = (slaList, selectedTypes) => {
    const selectedIds = selectedTypes.map((selectedType) => selectedType.id);
    return (slaList || []).filter((sla) => !(selectedIds || []).includes(sla.id));
};
const getSelectedTypes = (slaTypeList, selectedSlaTypes) => (selectedSlaTypes || []).map((selectedSlaType) => {
    const selectedSlaTypeData = getSlaTypeById(slaTypeList, selectedSlaType.id);
    return Object.assign(Object.assign({}, selectedSlaTypeData), { color: selectedSlaTypeData ? selectedSlaType.color : getRandomColor() });
});
const SlaReportProfileChart = (props) => {
    const slaReportChartData = props && props.slaReportChartData ? props.slaReportChartData : {};
    const { chartId } = slaReportChartData;
    const slaReportProfileId = getValueByPath(props.match, "params.sla_profile_id");
    const { formatMessage } = useIntl();
    const pseudoInputRef = useRef(null);
    const excludedTestIds = (props.excludedTestList || []).map((test) => test.value);
    const slaStatuses = getSlaStatusList();
    const selectedSlaTypes = getSelectedTypes(props.slaReportSlaTypeList, slaReportChartData.slaTypes);
    const unselectedSlaTypes = getUnselectedTypes(props.slaReportSlaTypeList, slaReportChartData.slaTypes);
    const copyEmbedCode = (e) => {
        e.preventDefault();
        e.stopPropagation();
        props.copyEmbedCodeRequest({
            inputEl: pseudoInputRef ? pseudoInputRef.current : null,
            slaReportProfileId,
            slaReportItemId: chartId,
            slaReportItemType: "chart"
        });
    };
    const deleteChart = () => props.deleteSlaReportProfileChart(props.slaReportChartIndex);
    const deleteSlaTypeHandler = (id) => props.deleteSlaReportProfileChartSlaType({ chartIndex: props.slaReportChartIndex, slaTypeId: id });
    const deleteTestHandler = (id) => props.getSiteModal(excludeSlaReportChartTestConfirmFormModal({
        element: ExcludeChartTestConfirmForm,
        id
    }));
    const getSlaTypeLabel = (slaTypeData) => (React.createElement(SlaReportLineLabel, null,
        React.createElement(ChartLegendLineIconSection, null,
            React.createElement(IconChartLegendLine, { color: getSlaTypeColor(slaTypeData) })),
        generateSlaTypeItemFormattedTextWithTimeline(slaTypeData, props.testDuration, formatMessage)));
    const getSlaTypes = (slaReportSlaTypes) => (slaReportSlaTypes || []).map((slaType) => (React.createElement(SlaTypeItem, { key: generateSlaTypeIdFromData(slaType) },
        getSlaTypeLabel(slaType),
        props.deleteSlaReportProfileChartSlaType && (React.createElement(DeleteSlaTypeButtonSection, null,
            React.createElement(DeleteButton, { onClick: () => deleteSlaTypeHandler(slaType.id) }, "\u00D7"))))));
    return (React.createElement(PreviewChartBlock, null,
        React.createElement(SlaReportItemPreviewSection, null,
            React.createElement(SlaReportChartBlock, null,
                React.createElement(SlaReportChart, { chartData: slaReportChartData.chartList, excludedTestIds: excludedTestIds, slaTypesData: slaReportChartData.slaTypes, minHeight: 450, onDeleteTest: deleteTestHandler, testDuration: props.testDuration }),
                React.createElement(SlaReportChartLegend, null, slaStatuses.map((status) => (React.createElement("span", { key: status },
                    getIconByStatus({ status }),
                    formatMessage({
                        defaultMessage: ` - ${status}`,
                        id: `account.sla.report.profile.chart.legend.status.desc.${status}`
                    })))))),
            React.createElement(SlaReportChartSettings, null,
                props.slaReportProfileAddSlaTypeByViewTypeRequest &&
                    unselectedSlaTypes &&
                    unselectedSlaTypes.length > 0 && (React.createElement(AddSlaSelect, Object.assign({}, props, { slaReportFilters: props.slaReportFilters, slaReportChartIndex: props.slaReportChartIndex, slaTypeList: unselectedSlaTypes }))),
                React.createElement(SlaReportChartSettingsSlaTypes, null, getSlaTypes(selectedSlaTypes)))),
        React.createElement(SlaReportItemEmbedCodeSection, null,
            React.createElement(EmbedCode, { ref: pseudoInputRef }),
            React.createElement(StyledButton, { disabled: !chartId, onClick: copyEmbedCode },
                React.createElement(FormattedMessage, Object.assign({}, COPY_EMBED_CODE_BUTTON_LABEL)))),
        props.deleteSlaReportProfileChart && (React.createElement(DeleteChartButtonSection, null,
            React.createElement(DeleteButton, { onClick: deleteChart }, "\u00D7")))));
};
export default SlaReportProfileChart;
