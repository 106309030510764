export const TIMEOUT_TITLE = {
    defaultMessage: "Timeouts",
    id: "account.test.project.settings.set.timeout.title"
};
export const CONNECTION_TIMEOUT_LABEL = {
    defaultMessage: "Connection timeout",
    id: "account.test.project.settings.set.timeout.connection.label"
};
export const CONNECTION_TIMEOUT_HELP_TEXT = {
    defaultMessage: "A default timeout before the connection is established to the server or the handshake is executed. If it doesn’t happen within the given time frame the request is marked as connection timeout.",
    id: "account.test.project.settings.set.timeout.connection.help.text"
};
export const DEFAULT_HEADERS_TITLE = {
    defaultMessage: "Default headers",
    id: "account.test.project.settings.set.default.headers.title"
};
export const DEFAULT_TIMER_TITLE = {
    defaultMessage: "Default timer",
    id: "account.test.project.settings.set.timers.title"
};
export const OTHER_SETTINGS_TITLE = {
    defaultMessage: "Other settings",
    id: "account.test.project.settings.set.other.settings.title"
};
export const REQUEST_TIMEOUT_LABEL = {
    defaultMessage: "Request timeout",
    id: "account.test.project.settings.set.timeout.request.label"
};
export const REQUEST_TIMEOUT_HELP_TEXT = {
    defaultMessage: "A default timeout before the entire body of the response from the server is received. If it doesn’t happen within the given time frame the request is marked as timeout.",
    id: "account.test.project.settings.set.timeout.request.help.text"
};
