import React from "react";
import { Button, StyledIconChartUp, StyledIconCookies, StyledIconHash, StyledIconPapperClip, StyledIconSettings, StyledIconWebhooks, StyledWizard, Text } from "./styled";
import { getTestProjectSettingsSetWizardItems } from "../../../../helpers/testProjectSettingsSet";
import { SettingsSetWizardItem } from "../../../../helpers/types";
const buttonIconsByWizardItem = {
    [SettingsSetWizardItem.Cookies]: StyledIconCookies,
    [SettingsSetWizardItem.JmeterConfigProperties]: StyledIconHash,
    [SettingsSetWizardItem.Monitoring]: StyledIconChartUp,
    [SettingsSetWizardItem.OtherSettings]: StyledIconSettings,
    [SettingsSetWizardItem.Parameters]: StyledIconSettings,
    [SettingsSetWizardItem.UrlOverwrite]: StyledIconPapperClip,
    [SettingsSetWizardItem.Webhooks]: StyledIconWebhooks
};
const getIntlMessageByWizardItem = (wizardItem) => ({
    defaultMessage: wizardItem,
    id: `account.all.test.project.settings.set.wizard.${wizardItem}.title`
});
const Wizard = ({ onChange, selectedWizardItem }) => {
    const isSelectedStep = (wizardItem) => selectedWizardItem === wizardItem;
    const selectWizardItem = (wizardItem) => typeof onChange === "function" ? onChange(wizardItem) : undefined;
    const getIconByWizardItem = (wizardItem) => {
        const IconComponent = buttonIconsByWizardItem[wizardItem];
        return React.createElement(IconComponent, { selected: isSelectedStep(wizardItem) });
    };
    return (React.createElement(StyledWizard, null, getTestProjectSettingsSetWizardItems().map((wizardItem) => (React.createElement(Button, { key: wizardItem, onClick: () => selectWizardItem(wizardItem), selected: isSelectedStep(wizardItem) },
        getIconByWizardItem(wizardItem),
        React.createElement(Text, Object.assign({}, getIntlMessageByWizardItem(wizardItem))))))));
};
export default Wizard;
