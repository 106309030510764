import styled, { css } from "styled-components";
import { IconClose, IconFile } from "../Icons";
import { Color } from "../styled/colorPallete";
import { withDisable } from "../styled/global";
import { media } from "../styled/response";
import { FontBodyMediumMedium, FontBodySmallMedium } from "../styled/typography";
export const ErrorMessage = styled.p `
    bottom: 0;
    color: ${Color.red};
    font-size: 12px;
    left: 0px;
    padding: 0 6px;
    position: absolute;
    transform: translateY(100%);
`;
export const FileWrapper = styled.div `
    ${withDisable};
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    position: relative;
    width: 100%;
    ${media.tablet} {
        margin-top: 20px;
    }
    ${({ theme }) => (theme ? css(theme) : css ``)}
`;
export const FilesList = styled.ul `
    display: flex;
    flex-direction: column;
`;
export const FileItem = styled.li `
    align-items: center;
    border-top: 1px solid ${Color.lightGrey95};
    display: flex;
    padding-bottom: 8px;
    padding-top: 8px;
    position: relative;
    &:first-child {
        border-top: 0;
        padding-top: 0;
    }
    &:last-child {
        padding-bottom: 0;
    }
`;
export const FileName = styled.p `
    ${FontBodySmallMedium}
    color: ${Color.darkGrey5};
    flex: auto;
    text-align: left;
`;
export const Label = styled.label `
    ${FontBodyMediumMedium}
    color: ${Color.grey50};
    padding: 24px;
    text-align: center;
    transition: all 0.3s ease-in-out;
    input[type="file"] {
        display: none;
    }
    ${({ isError }) => isError
    ? css `
                  border-color: ${Color.red};
                  color: ${Color.red};
              `
    : css ``}
    ${({ isExistUploadedFiles, isSingleFile }) => isExistUploadedFiles && isSingleFile
    ? css ``
    : css `
                  border: 2px dashed ${Color.grey75};
                  border-radius: 16px;
                  cursor: pointer;
              `}
    &:hover {
        border-color: ${Color.blue};
        color: ${Color.blue};
    }
    ${media.tablet} {
        padding: 20px;
    }
`;
export const StyledIconClose = styled(IconClose) `
    cursor: pointer;
    fill: ${Color.darkGrey35};
`;
export const StyledIconFile = styled(IconFile) `
    fill: ${Color.grey75};
    margin-right: 8px;
`;
