import styled from "styled-components";
import { Color } from "@boomq/uikit";
import { AddingSection } from "../../../styled/Buttons";
import { Link } from "../../../styled/global";
import { media } from "../../../styled/response";
import { Padding } from "../../../styled/Wrappers";
export const customFullRowTheme = { width: "100%" };
export const customRowBlockTheme = { width: "49%" };
export const customSelectedTestsTheme = { width: "300px" };
export const openedSpoilerCaptionTheme = {
    "> div": {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0
    }
};
export const slaReportSpoilerCaptionTheme = {
    boxShadow: "0 0 8px rgba(0, 64, 140, 0.15)",
    marginTop: "16px"
};
export const ButtonBlock = styled.div `
    align-items: flex-end;
    display: flex;
    flex: auto;
    justify-content: ${({ align }) => (align ? align : "flex-end")};
    margin-top: 10px;
    > a {
        color: #396496;
        cursor: pointer;
    }
`;
export const EmptyItemsText = styled.div `
    align-items: center;
    color: #bdbdbd;
    display: flex;
    font-weight: 600;
    justify-content: center;
    margin: 20px 0;
    text-align: center;
`;
export const NewSlaReportProfileItem = styled(AddingSection) `
    padding: 30px 0;
    width: 49%;
`;
export const NewSlaReportProfileItemSection = styled.div `
    display: flex;
    justify-content: space-between;
`;
export const Row = styled.div `
    display: flex;
    justify-content: ${({ align }) => (align ? align : "space-between")};
    margin: 5px 0;
`;
export const FilterTypeRoller = styled.div `
    display: flex;
    flex-direction: column;
    height: ${({ height }) => height}
    opacity: ${({ height }) => (height === "0px" ? 0 : 1)}
    overflow: ${({ overflow }) => overflow};
    transition: 1s;
    width: 100%;
`;
export const SelectedItemBody = styled.div `
    background: #fff;
    border: 1px solid #d6d6d6;
    border-radius: 100px;
    color: #757575;
    max-height: 46px;
    min-height: 46px;
    overflow: hidden;
    padding: 5px 60px 5px 30px;
    text-overflow: ellipsis;
    white-space: nowrap;
`;
export const SelectionCriteriaSwitchButton = styled(Link) `
    align-items: center;
    background: #d6d6d6;
    border-radius: 100px;
    box-shadow: 0 0px 2px rgba(0, 0, 0, 0.5);
    color: #396496;
    display: flex;
    padding: 3px 10px;
    font-weight: 500;
    &:hover {
        color: ${Color.blue};
    }
    > svg {
        margin-right: 4px;
    }
`;
export const SlaReportBlock = styled(Padding) `
    display: flex;
    flex-direction: column;
    padding-bottom: 120px;
    ${media.tablet} {
        padding: 0 1px;
    }
`;
export const SlaReportSpoilerBody = styled.div `
    background: #fff;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 20px;
`;
export const SpoilerCaption = styled.div `
    background: #4989d6;
    border-radius: 5px;
    color: #fff;
    padding: 15px;
`;
