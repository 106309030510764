import React from "react";
import { connect } from "react-redux";
import styled, { css } from "styled-components";
import { Spoiler } from "../../../common/Spoiler";
import newTestActions from "../../../../redux/actions/newTest";
const { changeRequestGroup } = newTestActions;
const connector = connect(null, { changeRequestGroup });
const RequestGroupTitle = ({ caption, children, onToggle, requestGroup }) => {
    const toggleRequestGroupHandler = (value) => typeof onToggle === "function" ? onToggle(value) : undefined;
    return (React.createElement(Group, { id: requestGroup.id, disabled: !requestGroup.enabled },
        React.createElement(Spoiler, { caption: caption, isOpened: requestGroup.isOpened, onlyArrowClick: true, openedCaptionTheme: openedCaptionTheme, toggle: toggleRequestGroupHandler }, children)));
};
const openedCaptionTheme = {
    "> h2": {
        borderBottomLeftRadius: "0",
        borderBottomRightRadius: "0"
    }
};
export const Group = styled.li `
    ${({ disabled }) => disabled
    ? css `
                  opacity: 0.6;
              `
    : css ``}
    background: rgba(73, 137, 214, 0.1);
    border: 1px solid #4989d6;
    border-radius: 10px;
    margin-bottom: 30px;
`;
export default connector(RequestGroupTitle);
