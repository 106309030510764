var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getValueByPath, isEmpty, isObject, upperFirstChar, useIntl } from "@boomq/utils";
import { DEFAULT_ACTIVE_TAB_NAME, NEW_TRANSACTION_ID } from "./constants";
import { BUTTON_REQUEST_NAME_COPY_PATH_TOOLTIP_TEXT, BUTTON_REQUEST_NAME_COPY_URL_TOOLTIP_TEXT, BUTTON_REQUEST_NAME_COPY_URL_WITHOUT_RESOLVE_PARAMETERS_TOOLTIP_TEXT, BUTTON_SAVE_LABEL, NEW_TRANSACTION_NAME_MESSAGE, REQUEST_NAME_LABEL } from "./intl";
import { RequestHttpForm } from "../../../../components/account/TestProject/RequestHttpForm";
import { withResize } from "../../../../components/styled/response";
import { copyRequestNameFromUrlWithoutResolveParameters, formatErrorMap, getRequestName, getRequestUrlPath } from "../../../../helpers/request";
import { FormType, TestType } from "../../../../helpers/types";
import { requestFormValidator } from "../../../../helpers/validators";
import { getHttpMethodItemsWithParams, requestJMXParamList, requestManualParamList } from "../../../../models/request";
import { newRequestChangeParamsField, saveHttpRequestSettings } from "../../../../redux/actions/newRequest";
import { closeSiteModal } from "../../../../redux/actions/siteModal";
import { getNewRequestField, getNewRequestParams, getNewRequestTimerList } from "../../../../redux/reducers/newRequest";
import { getRequestGroups, getRequestsExtractParameters } from "../../../../redux/reducers/newTest";
const checkError = (state) => {
    const errorProperties = getNewRequestField(state, "errorProperties");
    const requestParameters = getRequestsExtractParameters(state);
    const timerList = getNewRequestTimerList(state);
    return {
        errorForm: requestFormValidator({ requestParameters, timerList }),
        errorProperties
    };
};
const getProps = (state) => {
    const newRequestParams = getNewRequestParams(state);
    const requestUrl = getNewRequestField(state, "requestUrl");
    return {
        newRequestParams,
        requestName: newRequestParams.isCopyRequestNameFromUrl ? requestUrl : getRequestName(newRequestParams),
        requestUrl
    };
};
const connector = connect((state) => (Object.assign({ currentActiveTabName: getNewRequestField(state, "currentActiveTabName"), errorMap: checkError(state), groupId: getNewRequestField(state, "groupId"), method: getNewRequestField(state, "method"), requestGroups: getRequestGroups(state) }, getProps(state))), { onClose: closeSiteModal, onChange: newRequestChangeParamsField, onSave: saveHttpRequestSettings });
const getActiveTabName = (defaultActiveTabName) => defaultActiveTabName || DEFAULT_ACTIVE_TAB_NAME;
const getTabPropsByElementName = (elementName, tabProps) => {
    const params = tabProps && tabProps[elementName] ? tabProps[elementName] : {};
    return isObject(params) ? params : {};
};
const getRequestParamListByTabProps = (paramList, tabProps) => (paramList || []).map((param) => (Object.assign(Object.assign({}, param), { component: Object.assign(Object.assign({}, param.component), { props: Object.assign(Object.assign({}, param.component.props), getTabPropsByElementName(param.component.elementName, tabProps)) }) })));
const getRequestParamListByFormTypeTabProps = (paramList, formType, tabProps) => formType === FormType.View && Array.isArray(paramList)
    ? paramList.map((param) => (Object.assign(Object.assign({}, param), { component: Object.assign(Object.assign({}, param.component), { props: Object.assign(Object.assign(Object.assign({}, param.component.props), { mode: FormType.View }), getTabPropsByElementName(param.component.elementName, tabProps)) }) })))
    : getRequestParamListByTabProps(paramList, tabProps);
const getRequestParamListByType = (testType) => testType === TestType.JMX ? requestJMXParamList : requestManualParamList;
const getRequestTabItems = ({ formType, method, methodItems, tabProps, testType }) => {
    const paramList = getRequestParamListByFormTypeTabProps(getRequestParamListByType(testType), formType, tabProps);
    const currentMethod = methodItems.find((methodItem) => methodItem.value === method);
    return currentMethod && !isEmpty(currentMethod.excludeParams)
        ? paramList.filter((param) => Array.isArray(currentMethod.excludeParams) && !currentMethod.excludeParams.includes(param.name))
        : paramList;
};
const RequestHttpFormContainer = (_a) => {
    var { defaultActiveTabName, formType, method, onChange, onClose, onSave, tabProps } = _a, restProps = __rest(_a, ["defaultActiveTabName", "formType", "method", "onChange", "onClose", "onSave", "tabProps"]);
    const { formatMessage } = useIntl();
    const requestGroupItems = [
        { id: NEW_TRANSACTION_ID, name: formatMessage(NEW_TRANSACTION_NAME_MESSAGE) },
        ...restProps.requestGroups
    ];
    const methodItems = getHttpMethodItemsWithParams();
    const tabItems = getRequestTabItems({ formType, method, methodItems, tabProps, testType: restProps.testType }).map((tabItem) => (Object.assign(Object.assign({}, tabItem), { title: formatMessage(tabItem.title) })));
    const isDisabledInputs = restProps.isDisabledInputs || formType === FormType.View;
    const buttonCopyPathTooltipText = formatMessage(BUTTON_REQUEST_NAME_COPY_PATH_TOOLTIP_TEXT);
    const buttonCopyUrlTooltipText = formatMessage(BUTTON_REQUEST_NAME_COPY_URL_TOOLTIP_TEXT);
    const buttonCopyUrlWithoutResolveParametersTooltipText = formatMessage(BUTTON_REQUEST_NAME_COPY_URL_WITHOUT_RESOLVE_PARAMETERS_TOOLTIP_TEXT);
    const requestNameLabel = formatMessage(REQUEST_NAME_LABEL);
    const title = formatMessage({
        defaultMessage: `${upperFirstChar(formType)} request`,
        id: `account.request.form.${formType}.title`
    });
    const changeRequestParam = (field, value) => typeof onChange === "function" ? onChange({ field, value }) : undefined;
    const changeUrlHandler = (value, event) => {
        const requestUrl = getValueByPath(event, "target.value", "").trim();
        changeRequestParam("requestUrl", requestUrl);
        return getValueByPath(restProps.newRequestParams, "isCopyRequestNameFromUrl")
            ? changeRequestParam("name", requestUrl)
            : undefined;
    };
    const changeGroupHandler = ({ id }) => changeRequestParam("groupId", id);
    const changeMethodHandler = (item) => changeRequestParam("method", item.value);
    const changeNameHandler = (text) => {
        changeRequestParam("isCopyRequestNameFromUrl", false);
        changeRequestParam("name", text);
    };
    const changeNameByCopyPathHandler = () => changeNameHandler(getRequestUrlPath(restProps.requestUrl));
    const changeNameByCopyUrlHandler = () => changeNameHandler(restProps.requestUrl);
    const changeNameByCopyUrlWithoutResolveParametersHandler = () => changeNameHandler(copyRequestNameFromUrlWithoutResolveParameters(restProps.requestUrl));
    const changeTabHandler = (tabName) => changeRequestParam("currentActiveTabName", tabName);
    const close = () => (typeof onClose === "function" ? onClose() : undefined);
    const errorMap = formatErrorMap(restProps.errorMap, formatMessage);
    const save = () => (typeof onSave === "function" ? onSave() : undefined);
    const saveRequestHandler = () => {
        save();
        return typeof restProps.onSaveFunction === "function" ? restProps.onSaveFunction() : undefined;
    };
    return (React.createElement(RequestHttpForm, Object.assign({}, restProps, { defaultActiveTabName: getActiveTabName(defaultActiveTabName), buttonCopyPathTooltipText: buttonCopyPathTooltipText, buttonCopyUrlTooltipText: buttonCopyUrlTooltipText, buttonCopyUrlWithoutResolveParametersTooltipText: buttonCopyUrlWithoutResolveParametersTooltipText, buttonSaveLabel: formatMessage(BUTTON_SAVE_LABEL), errorMap: errorMap, formType: formType, isDisabledInputs: isDisabledInputs, method: method, methodItems: methodItems, onChangeMethod: changeMethodHandler, onChangeName: changeNameHandler, onChangeNameByCopyPath: changeNameByCopyPathHandler, onChangeNameByCopyUrl: changeNameByCopyUrlHandler, onChangeNameByCopyUrlWithoutResolveParameters: changeNameByCopyUrlWithoutResolveParametersHandler, onChangeRequestGroup: changeGroupHandler, onChangeTab: changeTabHandler, onChangeUrl: changeUrlHandler, onClose: close, onSave: saveRequestHandler, requestGroupItems: requestGroupItems, requestName: restProps.requestName, requestNameLabel: requestNameLabel, requestUrl: restProps.requestUrl, tabItems: tabItems, title: title })));
};
export default withResize(withRouter(connector(RequestHttpFormContainer)));
