import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Color } from "@boomq/uikit";
import { resetPasswordRequest } from "../../../redux/actions/admin/users";
import { Block, ItemPlate } from "../../routes/AdminPage";
const connector = connect(() => ({}), { onResetPassword: resetPasswordRequest });
const UserItem = (props) => {
    const { id, email, firstName, lastName, onResetPassword, resetUrl, type } = props;
    const resetUserPasswordHandler = () => onResetPassword(id);
    return (React.createElement(ItemPlate, null,
        React.createElement(Block, null,
            React.createElement("p", null,
                firstName,
                " ",
                lastName),
            React.createElement("p", null, email),
            React.createElement("p", null, type)),
        React.createElement(ResetUrlBlock, null,
            React.createElement(ResetUrlTextArea, { cols: "30", disabled: true, name: "resetUrl", placeholder: "Url for reset password...", rows: "3", value: resetUrl }),
            React.createElement(AdminButton, { onClick: resetUserPasswordHandler }, "Reset user password"))));
};
const AdminButton = styled.button `
    background: ${({ bg }) => (bg ? bg : Color.red)};
    border: none;
    border-radius: 5px;
    color: ${Color.white};
    cursor: pointer;
    margin-left: 16px;
    outline: none;
    padding: 10px 15px;
    transition: all 0.2s ease-in-out;
    &:active {
        transform: scale(0.9);
    }
`;
const ResetUrlBlock = styled(Block) `
    align-items: center;
    display: flex;
    justify-content: flex-end;
`;
const ResetUrlTextArea = styled.textarea `
    resize: none;
`;
export default connector(UserItem);
