import { fork, put, take } from "redux-saga/effects";
import { hideLoader, showLoader } from "../actions/loader";
function* showLoaderFlow() {
    while (true) {
        yield take(({ type }) => type.search(/REQUEST$/i) > -1);
        yield put(showLoader());
    }
}
function* hideLoaderFlow() {
    while (true) {
        yield take(({ type }) => type.search(/(FAILED|FAILURE|SUCCESS)$/i) > -1);
        yield put(hideLoader());
    }
}
export function* loadingFlow() {
    yield fork(showLoaderFlow);
    yield fork(hideLoaderFlow);
}
