import React, { useState } from "react";
import styled from "styled-components";
import { isEmpty, useIntl } from "@boomq/utils";
import { Help, InputNumber } from "@boomq/uikit";
import { DEFAULT_TIMER } from "../RequestTimers/constants";
import { CustomSelect } from "../../../common/CustomSelect";
import { Hint } from "../../../common/Hint";
import { Switcher } from "../../../common/Switcher";
import { withDisable } from "../../../styled/global";
import { IconRemove } from "../../../styled/Icons";
import { StyledTitle3 } from "../../../styled/Titles";
import { getOtherTimerType, getTimerInputUnit, getTimerListByMode, getTimerTypeItemsByParamName, getTimerTypeParamsByType, getTimerTypeValuesByType, getTimerValueParams, isEnabled, isShowRemoveButton } from "../../../../helpers/requestTimers";
import { FormType, RequestTimerParams } from "../../../../helpers/types";
import { getIsBoomqTimer, getTimerEnabled, getTimerType } from "../../../../models/requestTimer";
const customSelectTheme = {
    flex: 1,
    marginRight: "10px",
    minWidth: "200px",
    opacity: 1
};
const getTimerList = ({ timerList, newTimer, mode, isTimersDisabled }) => isTimersDisabled ? timerList : getTimerListByMode({ timerList, newTimer, mode });
const getTimerTypeByParamName = (timer, timerTypeParamName) => (timer ? timer[timerTypeParamName] : "");
const isEnabledInput = (timer, mode) => {
    const enabled = isEnabled(timer, mode);
    return enabled ? getTimerEnabled(timer) : false;
};
const IsEnabledSelectByKind = (timer, mode) => {
    const enabled = isEnabled(timer, mode) ? getTimerEnabled(timer) : false;
    return enabled ? getIsBoomqTimer(timer) : false;
};
export const RequestJMXTimers = ({ JMXTimersSettings, otherTimerList, timerList, timersRequestLink, mode, newRequestAddTimer, newRequestChangeTimer, newRequestDeleteFieldParam }) => {
    const { formatMessage } = useIntl();
    const [newTimer, setNewTimer] = useState(DEFAULT_TIMER);
    const blurTimerValueHandler = (timerId, key, value) => newRequestChangeTimer({ timerId, key, value });
    const changeNewTimerValue = (timerId, key, value) => {
        newRequestAddTimer(Object.assign(Object.assign({ timerId }, newTimer), { key, value }));
        setNewTimer(DEFAULT_TIMER);
    };
    const changeTimerValueHandler = (timer, key, value) => {
        !isEmpty(timer.error) && newRequestChangeTimer({ timerId: timer.id, key, value });
        return timer.isNewTimer && Number(value) > 0 ? changeNewTimerValue(timer.id, key, value) : undefined;
    };
    const changeTimerTypeHandler = (timerId, item, paramName, isNewTimer) => {
        const changedNewTimer = Object.assign(Object.assign({}, newTimer), { [paramName]: item.value });
        return isNewTimer
            ? setNewTimer(Object.assign({}, getTimerTypeValuesByType(changedNewTimer.timerType, changedNewTimer.randomTimerType)))
            : newRequestChangeTimer({ timerId, key: paramName, value: item.value });
    };
    const deleteTimerHandler = (id) => newRequestDeleteFieldParam({
        field: "timerList",
        id
    });
    const getOtherTimersHint = (timerList) => (React.createElement(OtherTimerList, null, timerList.map((timer) => {
        const timerType = getOtherTimerType(timer);
        return (React.createElement("li", { key: `hint_timer_${timer.id}` }, formatMessage({
            defaultMessage: timerType,
            id: `account.request.form.timer.${timerType}.title`
        })));
    })));
    const getPlaceholder = (timerType, valueInputName) => {
        const timerTypeMap = getTimerTypeItemsByParamName(RequestTimerParams.Type).find((type) => type.value === timerType);
        const placeholder = timerTypeMap && timerTypeMap.placeholder;
        return placeholder &&
            placeholder[valueInputName] &&
            placeholder[valueInputName].id &&
            typeof formatMessage === "function"
            ? formatMessage(placeholder[valueInputName])
            : "";
    };
    const getTimerValueBlockByType = (timer, mode) => getTimerValueParams(timer).map((timerValueParamName) => (React.createElement(InputNumber, { enabled: isEnabledInput(timer, mode), decimalCount: 2, disabledStyle: { backgroundColor: "#F8F8F8" }, error: timer.error, key: `${timer.id}_${timerValueParamName}`, name: `Timer_${timerValueParamName}`, onBlur: (value) => blurTimerValueHandler(timer.id, timerValueParamName, value), onChange: (value) => changeTimerValueHandler(timer, timerValueParamName, value), placeholder: getPlaceholder(getTimerType(timer), timerValueParamName), theme: customInputTheme, themeInput: customKeyValueInputTheme, unit: getTimerInputUnit(), value: timer[timerValueParamName] })));
    const getRequestsCountByTimerId = (timerId) => (timersRequestLink && timersRequestLink[timerId] ? timersRequestLink[timerId].requests : []).length;
    const getTimerTypeBlockByType = (timer, mode) => getTimerTypeParamsByType(getTimerType(timer)).map((timerTypeParamName) => (React.createElement(CustomSelect, { defaultValue: getTimerTypeByParamName(timer, timerTypeParamName), disabled: !IsEnabledSelectByKind(timer, mode), key: timerTypeParamName, items: getTimerTypeItemsByParamName(timerTypeParamName), onChange: (item) => changeTimerTypeHandler(timer.id, item, timerTypeParamName, timer.isNewTimer), theme: customSelectTheme })));
    const toggleEnabledHandler = (timerId, { target: { checked } }) => newRequestChangeTimer({ timerId, key: "enabled", value: checked });
    const getTimerActionsBlockByKind = (timer, mode) => timer.isBoomqTimer ? (React.createElement(IconSection, null, isShowRemoveButton(timer, mode, JMXTimersSettings.isTimersDisabled) && (React.createElement(ButtonRemove, { onClick: () => deleteTimerHandler(timer.id) },
        React.createElement(IconRemove, null))))) : (React.createElement(IconSection, null,
        React.createElement(Switcher, { defaultChecked: getTimerEnabled(timer), disabled: mode === FormType.View, name: "enabled", onClick: (e) => toggleEnabledHandler(timer.id, e) }),
        getRequestsCountByTimerId(timer.id) > 1 && (React.createElement(Help, { tooltipText: {
                defaultMessage: "This timer is used in {count} requests and it's changes will be effect to {count} requests.",
                id: "account.request.form.timer.jmx.hint",
                values: {
                    count: getRequestsCountByTimerId(timer.id)
                }
            } }))));
    return (React.createElement(React.Fragment, null,
        getTimerList({ timerList, newTimer, mode, isTimersDisabled: JMXTimersSettings.isTimersDisabled }).map((timer) => (React.createElement(RequestParam, { key: timer.id },
            getTimerTypeBlockByType(timer, mode),
            getTimerValueBlockByType(timer, mode),
            getTimerActionsBlockByKind(timer, mode)))),
        (otherTimerList || []).length > 0 && (React.createElement(RequestParam, null,
            React.createElement(StyledTitle3, null, formatMessage({
                defaultMessage: "Other timers...",
                id: "account.request.form.timer.other.title"
            })),
            React.createElement(Hint, { theme: customHintTheme, themeTooltip: customTooltipTheme, tooltipText: {
                    defaultMessage: "This request contains additional timers which will be run: {timers}",
                    id: "account.request.form.timer.other.hint",
                    values: {
                        timers: React.createElement("span", null, getOtherTimersHint(otherTimerList))
                    }
                } })))));
};
const customHintTheme = { marginLeft: "10px" };
const customInputTheme = { flex: 1 };
const customKeyValueInputTheme = { opacity: 1 };
const customTooltipTheme = { maxWidth: "300px", minWidth: "300px", textAlign: "left" };
const ButtonRemove = styled.div `
    cursor: pointer;
`;
const IconSection = styled.div `
    align-items: center;
    display: flex;
    min-width: 70px;
    > label {
        margin-right: 10px;
    }
`;
const OtherTimerList = styled.ul `
    list-style: disc;
    padding-left: 20px;
    & > li {
        list-style-type: disc;
    }
`;
const RequestParam = styled.div `
    ${withDisable}
    align-items: center;
    display: flex;
    margin-top: 20px;
    padding-left: 44px;
    width: 100%;
    > div {
        margin-right: 10px;
    }
`;
