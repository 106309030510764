import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
const ProfileSettingsEmptyContent = (props) => (React.createElement(EmptyContent, null,
    React.createElement(FormattedMessage, { defaultMessage: "You don\u2019t have any requests in this test yet. You can import data.\n                or add request manually:", id: "account.test.profile.empty.text" })));
const EmptyContent = styled.div `
    color: #bdbdbd;
    font-weight: 500;
    line-height: 30px;
    padding-bottom: 20px;
    text-align: center;
`;
export default ProfileSettingsEmptyContent;
