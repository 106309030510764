import { call, takeEvery } from "redux-saga/effects";
import clientActions from "../actions/client";
import { setDateFormatLocaleToLocalStorage } from "../../helpers/dateFormat";
const { setDateFormatLocale } = clientActions;
function* saveDateFormatLocaleToLocalStorage(action) {
    yield call(setDateFormatLocaleToLocalStorage, action.payload);
}
export function* dateFormatFlow() {
    yield takeEvery(setDateFormatLocale, saveDateFormatLocaleToLocalStorage);
}
