import { TestDisplayStatus, TestStatus } from "../../models/testRun";
export const completedStatuses = [
    TestDisplayStatus.Finished,
    TestDisplayStatus.Failed,
    TestDisplayStatus.Canceled
];
const preliminaryStatuses = [TestDisplayStatus.Initialization, TestDisplayStatus.Waiting];
const runningDisplayStatuses = [
    ...preliminaryStatuses,
    TestDisplayStatus.Running,
    TestDisplayStatus.TestStopping
];
const runningStatuses = [
    TestStatus.Created,
    TestStatus.TestProjectSettingsSelected,
    TestStatus.TestResourcesEstimated,
    TestStatus.ResourcesAllocated,
    TestStatus.Waiting,
    TestStatus.RetryingWaiting,
    TestStatus.TestPlansCreated,
    TestStatus.Running
];
export const getTestRunningDisplayStatesString = () => runningDisplayStatuses.join(",");
export const getTestRunningStatesString = () => runningStatuses.join(",");
export const getTestCompletedStatesString = () => completedStatuses.join(",");
export const isTestDuringExecution = (displayState) => [TestDisplayStatus.Running].includes(displayState);
export const isTestDuringPreparing = (displayState) => preliminaryStatuses.includes(displayState);
export const isTestCompleted = (displayState) => completedStatuses.includes(displayState);
export const isTestStopping = (displayState) => displayState === TestDisplayStatus.TestStopping;
export const statusColors = (displayStatus) => {
    switch (displayStatus) {
        case TestDisplayStatus.Finished:
            return "#6FCF97";
        case TestDisplayStatus.Initialization:
            return "#D5F637";
        case TestDisplayStatus.Running:
            return "#F6D365";
        case TestDisplayStatus.Waiting:
            return "#4FA9E8";
        case TestDisplayStatus.Failed:
            return "#F08C53";
        case TestDisplayStatus.Canceled:
            return "#CFCFCF";
        case TestDisplayStatus.TestStopping:
            return "#CFCFCF";
        default:
            return "#000000";
    }
};
export const statusTitles = (displayState) => {
    switch (displayState) {
        case TestDisplayStatus.Finished:
            return { id: "account.test.state.finished", defaultMessage: "Finished" };
        case TestDisplayStatus.Initialization:
            return { id: "account.test.state.init", defaultMessage: "Initialized" };
        case TestDisplayStatus.Running:
            return { id: "account.test.state.running", defaultMessage: "Running" };
        case TestDisplayStatus.Waiting:
            return { id: "account.test.state.waiting", defaultMessage: "Pending" };
        case TestDisplayStatus.Failed:
            return { id: "account.test.state.failed", defaultMessage: "Failed" };
        case TestDisplayStatus.Canceled:
            return { id: "account.test.state.cancelled", defaultMessage: "Canceled" };
        case TestDisplayStatus.TestStopping:
            return { id: "account.test.state.stopping", defaultMessage: "Stopping" };
        default:
            return { id: "account.test.state.unknown", defaultMessage: "Unknown status!" };
    }
};
export const statusNotifications = {
    [TestDisplayStatus.Canceled]: { id: "account.notification.failed", defaultMessage: "You canceled the test" },
    [TestDisplayStatus.Failed]: { id: "account.notification.failed", defaultMessage: "An error has occurred" },
    [TestDisplayStatus.Finished]: { id: "account.notification.finished", defaultMessage: "Your test is over" },
    [TestDisplayStatus.Running]: { id: "account.notification.running", defaultMessage: "Your test started" },
    [TestDisplayStatus.Waiting]: { id: "account.notification.waiting", defaultMessage: "Running your test is pending" }
};
export const statusDescriptions = (displayState) => {
    switch (displayState) {
        case TestDisplayStatus.Canceled:
            return {
                defaultMessage: "User himself canceled the test execution",
                id: "account.test.state.desc.cancelled"
            };
        case TestDisplayStatus.Failed:
            return { id: "account.test.state.desc.failed", defaultMessage: "Failed test" };
        case TestDisplayStatus.Finished:
            return { id: "account.test.state.desc.finished", defaultMessage: "Successfully completed" };
        case TestDisplayStatus.Initialization:
            return { id: "account.test.state.desc.init", defaultMessage: "Test is initialized" };
        case TestDisplayStatus.Running:
            return { id: "account.test.state.desc.running", defaultMessage: "Running" };
        case TestDisplayStatus.TestStopping:
            return {
                defaultMessage: "Stopping",
                id: "account.test.state.desc.stopping"
            };
        case TestDisplayStatus.Waiting:
            return { id: "account.test.state.desc.waiting", defaultMessage: "Pending" };
        default:
            return { id: "account.test.state.unknown", defaultMessage: "Unknown status!" };
    }
};
export const getFormattedTestStatusText = (testDisplayStatus, formatMessageFunc) => typeof formatMessageFunc === "function" ? formatMessageFunc(statusTitles(testDisplayStatus)) : testDisplayStatus;
export const getTestDisplayStatusList = (formatMessageFunc) => [...completedStatuses, ...preliminaryStatuses, TestDisplayStatus.Running].map((testDisplayStatus) => ({
    id: testDisplayStatus,
    text: getFormattedTestStatusText(testDisplayStatus, formatMessageFunc),
    value: testDisplayStatus
}));
