import React from "react";
import { ResponsePreview, ResponsePreviewSourceLabel, ResponsePreviewSourceSelect, ResponsePreviewSourceSelection, StyledIconPlay } from "./styled";
import { RequestResponsePreviewData } from "../RequestResponsePreviewData";
import { DashedSection } from "../../NewItemBlock/styled";
import { FormType } from "../../../../helpers/types";
export const RequestResponsePreview = ({ isShowResponse, mode, onChangeResponsePreviewSource, onToggleResponseShowing, responseBodyPreviewModeLabel, responsePreviewData, responsePreviewSource, responsePreviewSourceItems, responsePreviewText, responsePreviewTitle }) => (React.createElement(React.Fragment, null,
    React.createElement(ResponsePreview, null,
        React.createElement(DashedSection, null),
        React.createElement(ResponsePreviewSourceSelection, null,
            React.createElement(ResponsePreviewSourceLabel, { onClick: onToggleResponseShowing },
                React.createElement(StyledIconPlay, { isshowresponse: isShowResponse.toString() }),
                responsePreviewText),
            React.createElement(ResponsePreviewSourceSelect, { defaultValue: responsePreviewSource, disabled: mode === FormType.View, items: responsePreviewSourceItems, name: "response_preview", onChange: onChangeResponsePreviewSource })),
        React.createElement(DashedSection, null)),
    isShowResponse && responsePreviewSource && (React.createElement(RequestResponsePreviewData, { responseBodyPreviewModeLabel: responseBodyPreviewModeLabel, responsePreviewData: responsePreviewData, responsePreviewTitle: responsePreviewTitle }))));
