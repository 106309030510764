var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { FormattedMessage } from "react-intl";
import { Pagination } from "@boomq/uikit";
import { ALL_TEAMS_ITEM_MEMBERS_TITLE, ALL_TEAMS_ITEM_MEMBERS_ACTION_ADD_LABEL } from "./intl";
import { AddTeamMemberAction, StyledIconPlus, TeamMembersBody, TeamMembersHeader, TeamMembersHeaderTitle, TeamMembersPaginationWrapper } from "./styled";
import { LoaderBlock, StyledIconLoader } from "../../SettingsSetSidebar/styled";
const getCurrentPage = (value) => (value === undefined ? 0 : value);
const renderPagination = (props) => {
    const clickPageHandler = (page) => typeof props.onChangePage === "function"
        ? props.onChangePage({ teamId: props.teamId, page: Number(page) })
        : undefined;
    return props.itemsPagesCount && props.itemsPagesCount > 1 ? (React.createElement(TeamMembersPaginationWrapper, null,
        React.createElement(Pagination, { clickHandler: clickPageHandler, currentPage: getCurrentPage(props.currentPage), totalPages: props.itemsPagesCount }))) : (React.createElement(React.Fragment, null));
};
const renderChildren = (_a) => {
    var { children } = _a, restProps = __rest(_a, ["children"]);
    return React.isValidElement(children) ? React.cloneElement(children, restProps) : children;
};
const getIsShowLoader = (isLoading) => (isLoading ? true : false);
export const CommonTeamMembers = (props) => {
    const clickAddMemberHandler = () => typeof props.onClickAddMemberButton === "function" ? props.onClickAddMemberButton() : undefined;
    return (React.createElement(TeamMembersBody, null,
        React.createElement(TeamMembersHeader, null,
            React.createElement(TeamMembersHeaderTitle, null,
                React.createElement(FormattedMessage, Object.assign({}, ALL_TEAMS_ITEM_MEMBERS_TITLE))),
            React.createElement(AddTeamMemberAction, { isEnabled: !props.isShowAddingForm, onClick: clickAddMemberHandler },
                React.createElement(StyledIconPlus, null),
                React.createElement(FormattedMessage, Object.assign({}, ALL_TEAMS_ITEM_MEMBERS_ACTION_ADD_LABEL)))),
        renderChildren(props),
        renderPagination(props),
        React.createElement(LoaderBlock, { isShow: getIsShowLoader(props.isLoading) },
            React.createElement(StyledIconLoader, null))));
};
