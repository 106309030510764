import React from "react";
import { Link, withRouter } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import styled, { css } from "styled-components";
import Hide from "../../../animation/Hide";
import icon from "../../../../assets/images/icons/check.svg";
const TestProjectSaveNotification = ({ afterHidingFunc, isShowProjectLink, projectId, textTheme, theme }) => (React.createElement(Hide, { afterHidingFunc: afterHidingFunc, duration: "1", timeout: "20", theme: theme },
    React.createElement(TextSection, { onClick: () => typeof afterHidingFunc === "function" ? afterHidingFunc() : undefined, theme: textTheme },
        React.createElement(Check, { src: icon }),
        isShowProjectLink
            ?
                React.createElement(FormattedMessage, { defaultMessage: "Test is saved successfully. See {linkProject}", id: "account.new.test.project.saved.success.text_link", values: {
                        linkProject: React.createElement(Link, { to: `/account/tests/${projectId}` },
                            React.createElement(FormattedMessage, { defaultMessage: "here", id: "account.new.test.project.saved.success.link" }))
                    } })
            :
                React.createElement(FormattedMessage, { defaultMessage: "Test is saved successfully", id: "account.new.test.project.saved.success.text" }))));
const Check = styled.img `
    fill: #4989D6;
    margin-right: 5px;
`;
const TextSection = styled.div `
    background: #FFF;
    border-radius: 5px;
    box-shadow: 0 0 8px rgba(23, 128, 224, 0.25);
    cursor: pointer;
    display: flex;
    padding: 7px 10px;
    ${(props) => (props && props.theme) ? css(props.theme) : ""}
`;
export default withRouter(TestProjectSaveNotification);
