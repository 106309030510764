var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { connect } from "react-redux";
import { getValueByPath, useIntl } from "@boomq/utils";
import { THREAD_GROUP_PLACEHOLDER } from "./intl";
import { SlaThreadGroupItem } from "../../../components/account/SlaThreadGroupItem";
import { formatIntlMessage } from "../../../helpers/intl";
import { getSlaTestMetricRestrictionTypeItems, prepareTestProjectThreadGroupItems } from "../../../helpers/sla";
import { SlaTargetType } from "../../../models/sla";
import { getRequestGroups } from "../../../redux/reducers/newTest";
const getSlaThreadGroupItems = (state) => {
    const threadGroups = getRequestGroups(state);
    return prepareTestProjectThreadGroupItems(threadGroups);
};
const connector = connect((state) => ({
    threadGroupItems: getSlaThreadGroupItems(state)
}));
const SlaThreadGroupContainer = (_a) => {
    var { children, slaData, threadGroupItems } = _a, restProps = __rest(_a, ["children", "slaData", "threadGroupItems"]);
    const { formatMessage } = useIntl();
    const changeThreadGroupHandler = (id, data) => restProps.onDispatchAction({
        type: "update",
        payload: { id, field: SlaTargetType.ThreadGroup, type: SlaTargetType.ThreadGroup, data }
    });
    const threadGroupSelectPlaceholder = formatIntlMessage(THREAD_GROUP_PLACEHOLDER, formatMessage);
    return getValueByPath(slaData, SlaTargetType.ThreadGroup, []).map((slaItemData) => (React.createElement(SlaThreadGroupItem, Object.assign({ key: slaItemData.id }, restProps, { data: Object.assign({}, slaItemData), onChangeThreadGroup: (data) => changeThreadGroupHandler(slaItemData.id, data), restrictionTypeItems: getSlaTestMetricRestrictionTypeItems(SlaTargetType.ThreadGroup), threadGroupItems: threadGroupItems, threadGroupSelectPlaceholder: threadGroupSelectPlaceholder, type: SlaTargetType.ThreadGroup }))));
};
export default connector(SlaThreadGroupContainer);
