import styled from "styled-components";
import { Button, Color, FontBodyBigMedium, FontBodyMediumMedium } from "@boomq/uikit";
export const AttentionText = styled.div `
    ${FontBodyBigMedium}
    color: ${Color.darkGrey35};
    text-align: center;
`;
export const ButtonSection = styled.div `
    display: flex;
    justify-content: center;
`;
export const DontShowMeAgainBlock = styled.div `
    ${FontBodyMediumMedium}
    align-items: center;
    color: ${Color.darkGrey35};
    display: flex;
    justify-content: center;
    margin: 16px 0 8px;
`;
export const StyledButton = styled(Button) `
    width: 176px;
`;
