var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { forwardRef, useEffect, useState } from "react";
import { isEmpty } from "@boomq/utils";
import { DEFAULT_VALUE } from "./constants";
import { Input } from "../Input";
const getInitialStateValue = (value) => (isEmpty(value) ? DEFAULT_VALUE : value);
const InputText = (_a, _ref) => {
    var { onChange, value } = _a, rest = __rest(_a, ["onChange", "value"]);
    const [inputValue, setInputValue] = useState(getInitialStateValue(value));
    useEffect(() => {
        setInputValue(value || "");
    }, [value]);
    const changeValueHandler = (value, e) => {
        setInputValue(value);
        return typeof onChange === "function" ? onChange(value, e) : undefined;
    };
    return React.createElement(Input, Object.assign({}, rest, { inputValue: inputValue, onChange: changeValueHandler, ref: _ref }));
};
export default forwardRef(InputText);
