import { connect } from "react-redux";
import { SlaFormContainer } from "../../SlaForm";
import { getTestDetailsData, getTestDetailsSlaData, getTestDetailsSlaState } from "../../../../redux/reducers/testDetails";
const getTestDuration = (state) => {
    const { testProjectVersion } = getTestDetailsData(state);
    return testProjectVersion ? Math.ceil(testProjectVersion.totalDuration) : 0;
};
const connector = connect((state) => ({
    slaData: getTestDetailsSlaData(state),
    testDuration: getTestDuration(state),
    testResultSlaState: getTestDetailsSlaState(state)
}));
export default connector(SlaFormContainer);
