import styled, { css } from "styled-components";
import { Checkbox } from "@boomq/uikit";
import { InputWithParameters } from "../InputWithParameters";
export const IconRemoveSection = styled.div `
    margin-left: 16px;
    min-width: 24px;
`;
export const StyledInputWithParameters = styled(InputWithParameters) `
    margin-right: 16px;
`;
export const RemoveButton = styled.div `
    cursor: pointer;
`;
export const RequestParam = styled.div `
    align-items: center;
    display: flex;
    justify-content: space-around;
    position: relative;
    width: 100%;
    > input {
        margin-right: 20px;
    }
    ${({ theme }) => (theme ? css(theme) : css ``)}
`;
export const StyledCheckbox = styled(Checkbox) `
    white-space: nowrap;
`;
