import { REPORT_SYSTEM_METRIC_TITLE } from "./intl";
import { showError } from "./showError";
import { ExceptionForm } from "../../../components/common/ExceptionForm";
import { getReportErrorByExceptionMessage } from "../../../data/content/errorMessages";
export function* reportPFLBChartPreviewGenerationFailureFlow(action) {
    var _a;
    const error = getReportErrorByExceptionMessage((_a = action.payload) === null || _a === void 0 ? void 0 : _a.exceptionMessage);
    const params = error
        ? {
            element: ExceptionForm,
            props: {
                texts: [error]
            },
            theme: { alignItems: "center" },
            title: REPORT_SYSTEM_METRIC_TITLE,
            width: "600px"
        }
        : {};
    yield* showError(params);
}
