var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { useIntl } from "@boomq/utils";
import { COPY_SLA_TOOLTIP, DELETE_SLA_TOOLTIP, ERROR_MESSAGE, OPERATION_PLACEHOLDER, RESTRICTION_TYPE_PLACEHOLDER, VALUE_INPUT_PLACEHOLDER } from "./intl";
import { SlaItem } from "../../../components/account/SlaItem";
import { formatIntlMessage } from "../../../helpers/intl";
import { getSlaOperationItems, getSlaUnitByRestrictionType } from "../../../helpers/sla";
import { Mode } from "../../../models/sla";
const isShowActionButtonsByMode = (mode) => mode !== Mode.View;
const SlaItemContainer = ({ children, data, isDisabled, isShowSlaResult, mode, onDispatchAction, restrictionTypeItems, testDuration, testResultSlaState, testType, type }) => {
    const { formatMessage } = useIntl();
    const changeHandler = (field, newData) => onDispatchAction({ type: "update", payload: { id: data.id, field, type, data: newData } });
    const clickCopyHandler = () => onDispatchAction({ type: "copy", payload: { id: data.id, type } });
    const clickDeleteHandler = () => onDispatchAction({ type: "delete", payload: { id: data.id, type } });
    const changeOperationHandler = (data) => changeHandler("operation", data.value);
    const changeRestrictionTypeHandler = (data) => changeHandler("restrictionType", data.value);
    const changeTimelineHandler = (value) => {
        changeHandler("fromDate", value[0]);
        changeHandler("toDate", value[1]);
    };
    const changeValueHandler = (value) => changeHandler("value", value);
    const copyButtonTooltip = formatIntlMessage(COPY_SLA_TOOLTIP, formatMessage);
    const deleteButtonTooltip = formatIntlMessage(DELETE_SLA_TOOLTIP, formatMessage);
    const error = !data.isValid ? formatIntlMessage(ERROR_MESSAGE, formatMessage) : undefined;
    const operationSelectPlaceholder = formatIntlMessage(OPERATION_PLACEHOLDER, formatMessage);
    const restrictionTypeSelectPlaceholder = formatIntlMessage(RESTRICTION_TYPE_PLACEHOLDER, formatMessage);
    const valueInputPlaceholder = formatIntlMessage(VALUE_INPUT_PLACEHOLDER, formatMessage);
    const formattedRestrictionTypeItems = restrictionTypeItems.map((_a) => {
        var { intlMsg } = _a, restRestrictionTypeData = __rest(_a, ["intlMsg"]);
        return (Object.assign(Object.assign({}, restRestrictionTypeData), { text: formatIntlMessage(intlMsg, formatMessage) }));
    });
    return (React.createElement(SlaItem, { restrictionTypeItems: formattedRestrictionTypeItems, copyButtonTooltip: copyButtonTooltip, data: data, deleteButtonTooltip: deleteButtonTooltip, error: error, isDisabled: isDisabled, isShowActionButtons: isShowActionButtonsByMode(mode), isShowSlaResult: isShowSlaResult, onChangeOperation: changeOperationHandler, onChangeRestrictionType: changeRestrictionTypeHandler, onChangeTimeline: changeTimelineHandler, onChangeValue: changeValueHandler, onClickCopy: clickCopyHandler, onClickDelete: clickDeleteHandler, operationItems: getSlaOperationItems(), operationSelectPlaceholder: operationSelectPlaceholder, restrictionTypeSelectPlaceholder: restrictionTypeSelectPlaceholder, testDuration: testDuration, testResultSlaState: testResultSlaState, testType: testType, type: type, valueInputPlaceholder: valueInputPlaceholder, valueInputUnit: getSlaUnitByRestrictionType(type, data.restrictionType) }, children));
};
export default SlaItemContainer;
