var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { useIntl } from "@boomq/utils";
import { Checkbox, Tooltip } from "@boomq/uikit";
import { CHECKBOX_TOOLTIP } from "./intl";
import { ActionBlock } from "../TestRunsItem/styled";
import { TestItemContainer } from "../../../../containers/account/TestItem";
import { formatIntlMessage } from "../../../../helpers/intl";
const TestRunsItemWithComparingTemplate = (_a) => {
    var { endDate, onToggle } = _a, restProps = __rest(_a, ["endDate", "onToggle"]);
    const { formatMessage } = useIntl();
    const toggleHandler = () => onToggle === null || onToggle === void 0 ? void 0 : onToggle({
        endDate,
        id: restProps.id,
        startDate: restProps.startDate
    });
    const checkboxTooltip = formatIntlMessage(CHECKBOX_TOOLTIP, formatMessage);
    return (React.createElement(TestItemContainer, Object.assign({}, restProps),
        React.createElement(ActionBlock, null,
            React.createElement(Tooltip, { title: checkboxTooltip },
                React.createElement(Checkbox, { disabled: !(restProps === null || restProps === void 0 ? void 0 : restProps.startDate), checked: restProps.selected, onToggle: toggleHandler })))));
};
export default TestRunsItemWithComparingTemplate;
