export const APPLY_BUTTON_LABEL = {
    defaultMessage: "Apply",
    id: "account.report.editor.table.params.apply.button.label"
};
export const TABLE_TYPE_SELECT_LABEL = {
    defaultMessage: "Table type",
    id: "account.report.editor.table.params.type.select.label"
};
export const TABLE_TYPE_SELECT_PLACEHOLDER = {
    defaultMessage: "select table type",
    id: "account.report.editor.table.params.type.select.placeholder"
};
export const TEST_ID_SELECT_LABEL = {
    defaultMessage: "Test id",
    id: "account.report.editor.table.params.test.id.select.label"
};
export const TEST_ID_SELECT_PLACEHOLDER = {
    defaultMessage: "select test id",
    id: "account.report.editor.table.params.test.id.select.placeholder"
};
export const PFLB_TABLE_TITLE_TEMPLATE = {
    defaultMessage: "{typeName}, Test id: {testId}",
    id: "account.report.editor.table.params.title.template"
};
