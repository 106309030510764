var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { useIntl } from "@boomq/utils";
import { COMPARE_TESTS_BUTTON_LABEL, COMPARE_TESTS_BUTTON_TOOLTIP, TEST_LABEL_SELECT_PLACEHOLDER, TEST_PROJECT_SELECT_PLACEHOLDER, TEST_STATUS_SELECT_PLACEHOLDER, TEST_USER_SELECT_PLACEHOLDER } from "./intl";
import { AllTestsPanel } from "../../../components/account/AllTestsPanel";
import { isEmpty } from "../../../helpers/utils";
import { getTestDisplayStatusList } from "../../../helpers/testStatus";
import { formatIntlMessage } from "../../../helpers/intl";
const AllTestsPanelContainer = (_a) => {
    var { filtersData = {}, selectedTestIds, statuses, testLabels, testProjects, testUsers } = _a, restProps = __rest(_a, ["filtersData", "selectedTestIds", "statuses", "testLabels", "testProjects", "testUsers"]);
    const { formatMessage } = useIntl();
    const actionHandler = (actionName, payload) => { var _a; return (_a = restProps[actionName]) === null || _a === void 0 ? void 0 : _a.call(restProps, payload); };
    const changeFilter = (filterName, filterData) => actionHandler("onChangeFilter", { filterName, filterData });
    const changeTestLabelFilterHandler = (data) => {
        const value = isEmpty(data) ? [] : data;
        return changeFilter("testLabelFilter", value);
    };
    const changeTestProjectFilterHandler = (data) => {
        const value = isEmpty(data) ? [] : [data];
        return changeFilter("testProjectFilter", value);
    };
    const changeTestStatusFilterHandler = (data) => {
        const value = isEmpty(data) ? [] : [data];
        return changeFilter("testStatusFilter", value);
    };
    const changeTestUserFilterHandler = (data) => {
        const value = isEmpty(data) ? [] : data;
        return changeFilter("testUserFilter", value);
    };
    const clickCompareTestsHandler = () => actionHandler("onCompareTests", selectedTestIds);
    const compareTestsButtonLabel = formatIntlMessage(COMPARE_TESTS_BUTTON_LABEL, formatMessage);
    const compareTestsButtonTooltip = formatIntlMessage(COMPARE_TESTS_BUTTON_TOOLTIP, formatMessage);
    const testLabelSelectPlaceholder = formatIntlMessage(TEST_LABEL_SELECT_PLACEHOLDER, formatMessage);
    const testProjectSelectPlaceholder = formatIntlMessage(TEST_PROJECT_SELECT_PLACEHOLDER, formatMessage);
    const testStatusSelectPlaceholder = formatIntlMessage(TEST_STATUS_SELECT_PLACEHOLDER, formatMessage);
    const testUserSelectPlaceholder = formatIntlMessage(TEST_USER_SELECT_PLACEHOLDER, formatMessage);
    const testStatusItems = getTestDisplayStatusList(formatMessage);
    return (React.createElement(AllTestsPanel, { compareTestsButtonLabel: compareTestsButtonLabel, compareTestsButtonTooltip: compareTestsButtonTooltip, filtersData: filtersData, onChangeTestLabelFilter: changeTestLabelFilterHandler, onChangeTestProjectFilter: changeTestProjectFilterHandler, onChangeTestStatusFilter: changeTestStatusFilterHandler, onChangeTestUserFilter: changeTestUserFilterHandler, onClearTestLabelFilter: changeTestLabelFilterHandler, onClearTestProjectFilter: changeTestProjectFilterHandler, onClearTestStatusFilter: changeTestStatusFilterHandler, onClearTestUserFilter: changeTestUserFilterHandler, onCompareTests: clickCompareTestsHandler, selectedTestIds: selectedTestIds, statuses: statuses, testLabels: testLabels, testLabelSelectPlaceholder: testLabelSelectPlaceholder, testProjects: testProjects, testProjectSelectPlaceholder: testProjectSelectPlaceholder, testStatusItems: testStatusItems, testStatusSelectPlaceholder: testStatusSelectPlaceholder, testUserSelectPlaceholder: testUserSelectPlaceholder, testUsers: testUsers }));
};
export default AllTestsPanelContainer;
