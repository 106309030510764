import React from "react";
import { useIntl } from "@boomq/utils";
import { inputUnit } from "./intl";
import { AssertionCommonWithConditionTemplate } from "../../../../components/account/TestProject/AssertionCommonWithConditionTemplate";
import { getFormattedAssertionConditionComparisonItems } from "../../../../helpers/assertion";
export const AssertionSizeTemplateContainer = (props) => {
    const { formatMessage } = useIntl();
    const formattedAssertionConditionItems = getFormattedAssertionConditionComparisonItems(formatMessage);
    return (React.createElement(AssertionCommonWithConditionTemplate, Object.assign({}, props, { assertionConditionItems: formattedAssertionConditionItems, inputUnit: inputUnit })));
};
