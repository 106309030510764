import { IconCopy, IconDelete, IconEdit } from "@boomq/uikit";
import { ThreadGroupAction } from "./enums";
import { THREAD_GROUP_ACTION_COPY_TITLE, THREAD_GROUP_ACTION_DELETE_TITLE, THREAD_GROUP_ACTION_EDIT_TITLE } from "./intl";
const threadGroupActionIconByActionName = {
    [ThreadGroupAction.Copy]: IconCopy,
    [ThreadGroupAction.Delete]: IconDelete,
    [ThreadGroupAction.Edit]: IconEdit
};
const threadGroupActionTitleByActionName = {
    [ThreadGroupAction.Copy]: THREAD_GROUP_ACTION_COPY_TITLE,
    [ThreadGroupAction.Delete]: THREAD_GROUP_ACTION_DELETE_TITLE,
    [ThreadGroupAction.Edit]: THREAD_GROUP_ACTION_EDIT_TITLE
};
const getThreadGroupActionIconByActionName = (actionName) => threadGroupActionIconByActionName[actionName];
const getThreadGroupActionTitleIntlMessageByActionName = (actionName) => threadGroupActionTitleByActionName[actionName];
const getThreadGroupActionByKey = (actionKey) => {
    const actionName = ThreadGroupAction[actionKey];
    return {
        actionName,
        icon: getThreadGroupActionIconByActionName(actionName),
        titleIntlMessage: getThreadGroupActionTitleIntlMessageByActionName(actionName)
    };
};
export const getThreadGroupActions = () => Object.keys(ThreadGroupAction).map(getThreadGroupActionByKey);
export const requestGroupActions = [
    {
        action: "addRequestToGroupHandler",
        id: 0,
        name: { defaultMessage: "Add request", id: "account.test.profile.button.add.request" }
    },
    {
        action: "copyRequestGroupHandler",
        id: 1,
        name: { defaultMessage: "Copy transaction", id: "account.test.profile.button.copy.group" }
    },
    {
        action: "deleteRequestGroupHandler",
        id: 2,
        name: { defaultMessage: "Delete transaction", id: "account.test.profile.button.delete.group" }
    }
];
