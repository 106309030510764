import { put, select, takeEvery } from "redux-saga/effects";
import { isEmpty } from "../../helpers/utils";
import { getTestProjectSaveNotification, showTestProjectNotification } from "../actions/testProjectNotification";
import { getProjectId } from "../reducers/newTest";
function* testProjectSaveNotificationFlow(action) {
    try {
        const { getNotificationPayload, isShowProjectLink, projectId } = action && action.payload ? action.payload : {};
        const testProjectId = projectId ? projectId : yield select(getProjectId);
        const notificationPayload = typeof getNotificationPayload === "function"
            ? getNotificationPayload(testProjectId, isShowProjectLink)
            : {};
        !isEmpty(notificationPayload) && (yield put(showTestProjectNotification(notificationPayload)));
    }
    catch (e) {
        console.error(e);
    }
}
export function* testProjectNotificationFlow() {
    yield takeEvery(getTestProjectSaveNotification, testProjectSaveNotificationFlow);
}
