import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { ButtonSize } from "@boomq/uikit";
import { ATTENTION_TEXT, DONT_SHOW_ME_AGAIN_TEXT } from "./intl";
import { AttentionText, ButtonSection, DontShowMeAgainBlock, StyledButton } from "./styled";
import { CustomCheckbox } from "../../common/CustomCheckbox";
export const SettingsSetJMXLinkingAttention = (props) => {
    const [isDontShowAttentionAgain, setIsDontShowAttentionAgain] = useState(false);
    const submitHandler = () => typeof props.onSubmit === "function" ? props.onSubmit(isDontShowAttentionAgain) : undefined;
    const toggleIsShowMeAgainHandler = () => setIsDontShowAttentionAgain(!isDontShowAttentionAgain);
    return (React.createElement(React.Fragment, null,
        React.createElement(AttentionText, null,
            React.createElement(FormattedMessage, Object.assign({}, ATTENTION_TEXT))),
        React.createElement(DontShowMeAgainBlock, null,
            React.createElement(CustomCheckbox, { checked: isDontShowAttentionAgain, onToggle: toggleIsShowMeAgainHandler }),
            React.createElement(FormattedMessage, Object.assign({}, DONT_SHOW_ME_AGAIN_TEXT))),
        React.createElement(ButtonSection, null,
            React.createElement(StyledButton, { className: "close_modal_window", onClick: submitHandler, size: ButtonSize.medium }, "OK"))));
};
