import { IntlMessage } from "../../../helpers/types";

export const INVALID_LICENSE_FOR_INSTALLATION_TEST_CONCURRENT_COUNT: IntlMessage = {
    defaultMessage: "Unable to add test runner because the licensed limit for test runner count has been reached",
    id: "api.exception.message.INVALID_LICENSE_FOR_INSTALLATION_TEST_CONCURRENT_COUNT"
};

export const UNABLE_TO_ADD_TEST_RUNNER_LICENSE_LIMIT_REACHED: IntlMessage = {
    defaultMessage: "Unable to add test runner because the licensed limit for test runner count has been reached",
    id: "api.exception.message.UNABLE_TO_ADD_TEST_RUNNER_LICENSE_LIMIT_REACHED"
};
