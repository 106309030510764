import { combineActions, handleActions } from "redux-actions";
import { getValueByPath } from "@boomq/utils";
import authorizeActions from "../actions/authorize";
import { addSettingsSetInSidebar, changeSidebarFilterLabels, changeSidebarFilterName, hideSettingsSetSidebar, linkSettingsSetToNewTest, linkSettingsSetToTestProjectSuccess, linkSettingsSetToTestProjectInListSuccess, setSettingsSetList, setSettingsSetListCurrentPage, setSettingsSetListPageSize, settingsSetSidebarChangeParamsField, showSettingsSetSidebar, unlinkSettingsSetForNewTest, unlinkSettingsSetForTestProjectSuccess, unlinkSettingsSetForTestProjectInListSuccess } from "../actions/testProjectsSettingsSetSidebar";
const { logout } = authorizeActions;
export const DEFAULT_TEST_PROJECTS_SETTINGS_SETS_PAGE_SIZE = 9;
export const initialState = {
    content: [],
    filterLabels: [],
    filterName: "",
    isShowLoader: false,
    isShowSettingsSetSidebar: false,
    linkedSettingsSetData: {},
    number: 0,
    size: DEFAULT_TEST_PROJECTS_SETTINGS_SETS_PAGE_SIZE,
    testProjectData: {},
    totalElements: 0,
    totalPages: 0
};
export default handleActions({
    [addSettingsSetInSidebar]: (state) => (Object.assign(Object.assign({}, state), { isShowLoader: true })),
    [changeSidebarFilterLabels]: (state, action) => (Object.assign(Object.assign({}, state), { filterLabels: action && action.payload ? action.payload : [], isShowLoader: true })),
    [changeSidebarFilterName]: (state, action) => (Object.assign(Object.assign({}, state), { filterName: action && action.payload ? action.payload : [], isShowLoader: true })),
    [hideSettingsSetSidebar]: (state) => (Object.assign(Object.assign({}, state), { isShowSettingsSetSidebar: false, settingsSetSidebarProjectId: 0 })),
    [linkSettingsSetToNewTest]: (state, action) => (Object.assign(Object.assign({}, state), { testProjectData: Object.assign(Object.assign({}, state.testProjectData), { settingsId: getValueByPath(action, "payload") }) })),
    [combineActions(linkSettingsSetToTestProjectSuccess, linkSettingsSetToTestProjectInListSuccess)]: (state, action) => (Object.assign(Object.assign({}, state), { testProjectData: Object.assign(Object.assign({}, state.testProjectData), { settingsId: getValueByPath(action, "payload.settingsId"), projectVersionId: getValueByPath(action, "payload.projectVersionId") }) })),
    [logout]: () => (Object.assign({}, initialState)),
    [setSettingsSetListCurrentPage]: (state, action) => (Object.assign(Object.assign({}, state), { number: Number.isInteger(action.payload) ? action.payload : 0 })),
    [setSettingsSetListPageSize]: (state, action) => (Object.assign(Object.assign({}, state), { size: Number.isInteger(action.payload) ? action.payload : 0 })),
    [setSettingsSetList]: (state, action) => (Object.assign(Object.assign(Object.assign({}, state), action.payload), { isShowLoader: false })),
    [showSettingsSetSidebar]: (state, { payload }) => (Object.assign(Object.assign({}, initialState), { isShowLoader: true, isShowSettingsSetSidebar: true, size: state.size, testProjectData: payload })),
    [settingsSetSidebarChangeParamsField]: (state, action) => {
        const { field, subfield, value } = action && action.payload ? action.payload : {};
        return field && subfield
            ? Object.assign(Object.assign({}, state), { [field]: Object.assign(Object.assign({}, state[field]), { [subfield]: value }) }) : Object.assign(Object.assign({}, state), { [field]: value });
    },
    [combineActions(unlinkSettingsSetForNewTest, unlinkSettingsSetForTestProjectSuccess, unlinkSettingsSetForTestProjectInListSuccess)]: (state, action) => (Object.assign(Object.assign({}, state), { linkedSettingsSetData: {}, testProjectData: Object.assign(Object.assign({}, state.testProjectData), { settingsId: undefined, projectVersionId: getValueByPath(action, "payload.projectVersionId") }) }))
}, initialState);
const getStateParam = (state, paramName) => state && state.testProjectsSettingsSetSidebar
    ? state.testProjectsSettingsSetSidebar[paramName]
    : initialState[paramName];
export const getCurrentPage = (state) => getStateParam(state, "number");
export const getIsShowLoader = (state) => getStateParam(state, "isShowLoader");
export const getIsShowSettingsSetSidebar = (state) => getStateParam(state, "isShowSettingsSetSidebar");
export const getLinkedSettingsSetData = (state) => getStateParam(state, "linkedSettingsSetData");
export const getSettingsSetSidebarItems = (state) => getStateParam(state, "content");
export const getSettingsSetSidebarFilterLabels = (state) => getStateParam(state, "filterLabels");
export const getSettingsSetSidebarFilterName = (state) => getStateParam(state, "filterName");
export const getSettingsSetSidebarPageSize = (state) => getStateParam(state, "size");
export const getSettingsSetSidebarTestProjectData = (state) => getStateParam(state, "testProjectData");
export const getSettingsSetSidebarPagesCount = (state) => getStateParam(state, "totalPages");
export const getSettingsSetSidebarElementsCount = (state) => getStateParam(state, "totalElements");
