import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "@boomq/utils";
import {
    ConfirmForm,
    Profile as CommonProfile,
    ProfileUserAccountInfoContainer,
    authorizeActions,
    getClientInfo,
    getCurrentLanguage,
    getSecretTokenData,
    getSelectedTeamId,
    getSiteModal,
    replaceSecretTokenModal
} from "@boomq/common";
import { ProfileInfoBlock, StyledProfileUserInfo } from "./styled";

const { generateSecretTokenRequest, secretTokenRequest } = authorizeActions;

const getClientEmail = (clientInfo) => clientInfo?.email || "";

const getClientDisplayName = (clientInfo) => clientInfo?.displayName || "";

export const ProfilePageContainer = () => {
    const dispatch = useDispatch();

    const clientInfo = useSelector(getClientInfo);
    const currentLanguage = useSelector(getCurrentLanguage);
    const secretToken = useSelector(getSecretTokenData);
    const selectedTeamId = useSelector(getSelectedTeamId);

    useEffect(() => {
        const checkSelectedTeam = (selectedTeamId) => {
            !isEmpty(selectedTeamId) && dispatch(secretTokenRequest());
        };
        checkSelectedTeam(selectedTeamId);
    }, [selectedTeamId]);

    const replaceSecretToken = () =>
        secretToken && secretToken.length > 0
            ? dispatch(
                  getSiteModal(
                      replaceSecretTokenModal({
                          element: ConfirmForm,
                          confirmAction: () => dispatch(generateSecretTokenRequest())
                      })
                  )
              )
            : dispatch(generateSecretTokenRequest());

    return (
        <CommonProfile>
            <ProfileInfoBlock>
                <StyledProfileUserInfo email={getClientEmail(clientInfo)} name={getClientDisplayName(clientInfo)} />

                <ProfileUserAccountInfoContainer
                    currentLanguage={currentLanguage}
                    onReplaceSecretToken={replaceSecretToken}
                />
            </ProfileInfoBlock>
        </CommonProfile>
    );
};
