import { GRID_HEADER_CREATED_DATE, GRID_HEADER_EMPTY, GRID_HEADER_LABEL, GRID_HEADER_LAST_EDITED_BY, GRID_HEADER_LAST_MODIFIED_DATE, GRID_HEADER_NAME, GRID_HEADER_TEST_ID } from "./intl";
export var ReportsApiSortField;
(function (ReportsApiSortField) {
    ReportsApiSortField["Name"] = "NAME";
    ReportsApiSortField["UpdatedAt"] = "UPDATED_AT";
    ReportsApiSortField["CreatedAt"] = "CREATED_AT";
})(ReportsApiSortField || (ReportsApiSortField = {}));
export const gridColumns = [
    {
        align: "left",
        id: 0,
        flex: 1.5,
        headerAlign: "left",
        nameIntlMsg: GRID_HEADER_NAME
    },
    {
        flex: 1,
        id: 1,
        nameIntlMsg: GRID_HEADER_LABEL
    },
    {
        flex: 1,
        id: 2,
        nameIntlMsg: GRID_HEADER_TEST_ID
    },
    {
        flex: 1,
        id: 3,
        nameIntlMsg: GRID_HEADER_LAST_EDITED_BY
    },
    {
        flex: 1,
        id: 4,
        nameIntlMsg: GRID_HEADER_LAST_MODIFIED_DATE
    },
    {
        flex: 1,
        id: 5,
        nameIntlMsg: GRID_HEADER_CREATED_DATE
    },
    {
        id: 6,
        nameIntlMsg: GRID_HEADER_EMPTY,
        width: "100px"
    }
];
