import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { Color, FontBodyMediumBold, IconPlus } from "@boomq/uikit";
export const NewItemActionSectionStyles = `
    ${FontBodyMediumBold}
    align-items: center;
    color: ${Color.blue};
    display: flex;
    padding: 0 24px;
`;
export const NewItem = styled.div `
    align-items: center;
    display: flex;
`;
export const DashedSection = styled.div `
    align-items: center;
    border-bottom: 1px dashed ${Color.blue};
    display: flex;
    flex: auto;
`;
export const StyledIconPlus = styled(IconPlus) `
    fill: ${Color.blue};
    height: 20px;
    margin-right: 6px;
    width: 20px;
`;
export const TextSection = styled(NavLink) `
    ${NewItemActionSectionStyles}
    cursor: pointer;
`;
