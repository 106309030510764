var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { isEmpty } from "@boomq/utils";
import { DropDownItemList } from "./styled";
import { DefaultDropdownListItemTemplate } from "./DefaultDropdownListItemTemplate";
import { getClassName, getIsOpenedProp } from "./helpers";
export const DropdownList = (_a) => {
    var { className, isOpened, itemTemplate, items, onClickItem } = _a, restProps = __rest(_a, ["className", "isOpened", "itemTemplate", "items", "onClickItem"]);
    const clickItemHandler = (data) => onClickItem === null || onClickItem === void 0 ? void 0 : onClickItem(data);
    const ItemTemplate = itemTemplate ? itemTemplate : DefaultDropdownListItemTemplate;
    return getIsOpenedProp(isOpened) && !isEmpty(items) ? (React.createElement(DropDownItemList, Object.assign({ className: getClassName(className) }, restProps), (items || []).map((item) => (React.createElement(ItemTemplate, { key: item.id, data: item, onClick: clickItemHandler }))))) : (React.createElement(React.Fragment, null));
};
