import styled from "styled-components";
import { media, withDisable } from "@boomq/common";

export const customRailStyleTheme = { backgroundColor: "#ddd" };

export const ActionAddStep = styled.div`
    cursor: pointer;
    > svg {
        height: 10px;
        width: 10px;
    }
    > svg path {
        fill: #677897;
    }
`;

export const AddStepSection = styled.div`
    color: #677897;
    display: flex;
    justify-content: flex-end;
    padding: 10px;
`;

export const Content = styled.div`
    padding: 20px;
`;

export const LoadProfileSettingsSection = styled.div`
    flex: 1;
`;

export const LoadProfileStep = styled.div`
    margin-bottom: 10px;
`;

export const TypeSliders = styled.div<{ disabled }>`
    ${withDisable};
    flex: 1.5;
    ${media.giant} {
        margin-top: 20px;
    }
    ${media.tablet} {
        margin-top: 10px;
    }
`;
