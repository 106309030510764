import styled from "styled-components";
import { SidebarElement } from "../../SidebarElement";
export const TEST_PROJECT_LEFT_SIDEBAR_WIDTH = 300;
export const TEST_PROJECT_LEFT_SIDEBAR_MARGIN_LEFT = 24;
export const ScrollContainer = styled.div `
    margin-left: ${TEST_PROJECT_LEFT_SIDEBAR_MARGIN_LEFT}px;
    min-width: ${TEST_PROJECT_LEFT_SIDEBAR_WIDTH}px;
    overflow: auto;
    padding: 16px 8px;
    position: sticky;
    top: 0;
    width: ${TEST_PROJECT_LEFT_SIDEBAR_WIDTH}px;
`;
export const SidebarElementWithMargin = styled(SidebarElement) `
    margin-top: 12px;
`;
