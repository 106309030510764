var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { connect } from "react-redux";
import { SAVE_AS_BUTTON_TITLE } from "./intl";
import { StyledSaveAs, StyledSelect, TestProjectVersion } from "./styled";
import { VersionName } from "./VersionName";
import SaveAsTestProjectForm from "../SaveAsTestProjectForm";
import { getSiteModal } from "../../../../redux/actions/siteModal";
import { changeTestProjectVersion, changeTestProjectVersionName } from "../../../../redux/actions/testProjects";
import { getClientPermissionList } from "../../../../redux/reducers/authorizePlate";
import { getTestProjectSettingsField } from "../../../../redux/reducers/newTest";
import { isAllowedTestProjectActionByPermissions } from "../../../../helpers/testProject";
import { TestProjectAction } from "../../../../helpers/types";
const connector = connect((state) => ({
    currentVersion: getTestProjectSettingsField(state, "currentVersion"),
    permissionList: getClientPermissionList(state),
    versions: getTestProjectSettingsField(state, "versions")
}), {
    changeTestProjectVersion,
    onChangeVersionName: changeTestProjectVersionName,
    [TestProjectAction.SaveAs]: getSiteModal
});
const renderSaveAsButton = (props) => {
    const saveAsButtonHandler = () => typeof props[TestProjectAction.SaveAs] === "function"
        ? props[TestProjectAction.SaveAs]({
            component: {
                element: SaveAsTestProjectForm,
                props: {}
            },
            title: SAVE_AS_BUTTON_TITLE
        })
        : undefined;
    return isAllowedTestProjectActionByPermissions(props.permissionList, TestProjectAction.SaveAs) ? (React.createElement(StyledSaveAs, { title: "Save as", onClick: saveAsButtonHandler })) : (React.createElement(React.Fragment, null));
};
const listItemTemplate = (item, onChangeVersionName) => (React.createElement(VersionName, Object.assign({}, item, { onChange: onChangeVersionName })));
const TestProjectVersionSelector = (_a) => {
    var { changeTestProjectVersion, currentVersion, onChangeVersionName, versions } = _a, restProps = __rest(_a, ["changeTestProjectVersion", "currentVersion", "onChangeVersionName", "versions"]);
    const changeVersionHandler = ({ id }) => id !== currentVersion &&
        typeof changeTestProjectVersion === "function" &&
        changeTestProjectVersion({ value: id });
    return (React.createElement(TestProjectVersion, null,
        React.createElement(StyledSelect, { defaultValue: currentVersion, items: versions || [], listItemTemplate: (item) => listItemTemplate(item, onChangeVersionName), onChange: changeVersionHandler, openTop: true, textAttrName: "name", valueAttrName: "id" }),
        renderSaveAsButton(restProps)));
};
export default connector(TestProjectVersionSelector);
