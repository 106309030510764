import React from "react";
import { useIntl } from "@boomq/utils";
import { docsLinkTestRuns } from "./constans";
import { CHART_ANOMALIES_LABEL, CHART_METRICS_LABEL, ICON_INFO_TOOLTIP } from "./intl";
import { CustomLegend } from "../../../../components/common/AreaChartCustomComponents/CustomLegend";
import { anomalyClassifications } from "../../../../models/anomalyClassifications";
export const CustomLegendContainer = (props) => {
    const { formatMessage } = useIntl();
    const { payload } = props || {};
    const anomaliesData = anomalyClassifications.map((anomaly) => ({
        color: anomaly.anomalyColor,
        value: formatMessage(anomaly.anomalyDescription)
    }));
    const metricsData = payload && payload.map((entry) => ({ color: entry.color, value: entry.value }));
    const anomaliesLabel = formatMessage(CHART_ANOMALIES_LABEL);
    const metricsLabel = formatMessage(CHART_METRICS_LABEL);
    return (React.createElement(CustomLegend, { anomaliesData: anomaliesData, anomaliesLabel: anomaliesLabel, docsLink: docsLinkTestRuns, infoTooltip: ICON_INFO_TOOLTIP, metricsData: metricsData, metricsLabel: metricsLabel }));
};
