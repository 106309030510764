import styled from "styled-components";
import { Color } from "@boomq/uikit";
export const StyledAccount = styled.div `
    background: ${Color.lightGrey98};
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    position: relative;
    @media print {
        overflow: unset;
    }
`;
