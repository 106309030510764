import styled, { css } from "styled-components";
export const paginationTheme = { padding: 0 };
export const DataTableSection = styled.div `
    display: flex;
    flex-direction: column;
    height: 100%;
`;
export const DataTableList = styled.ul `
    flex: 0 0 auto;
    padding: 14px 5px 17px;
    ${({ scroll }) => (scroll ? css(scroll) : "")}
`;
export const StyledPagination = styled.div `
    flex: 1 0 auto;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding-bottom: 24px;
    ${({ theme }) => (theme ? css(theme) : "")}
`;
