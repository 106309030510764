import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { debounce } from "@boomq/utils";
import { ANOMALIES_DETECT_LABEL, ANOMALIES_SENSITIVITY_LABEL, ANOMALY_SETTINGS_TITLE } from "./intl";
import { ModalAnomalySettings } from "../../../components/account/ModalAnomalySettings";
import { SensitivityLevel } from "../../../models/anomalyClassifications";
import { setSensitivity, toggleAnomaly } from "../../../redux/actions/testAnomalies";
import { getAnomalyEnabled, getSensitivity } from "../../../redux/reducers/testAnomalies";
export const ModalAnomalySettingsContainer = () => {
    const dispatch = useDispatch();
    const { formatMessage } = useIntl();
    const isAnomalyEnabled = useSelector(getAnomalyEnabled);
    const sensitivity = useSelector(getSensitivity);
    const sensitivityLevelsArray = Object.values(SensitivityLevel);
    const currentSensitivityIndex = sensitivityLevelsArray.indexOf(sensitivity);
    const anomalySettingsTitle = formatMessage(ANOMALY_SETTINGS_TITLE);
    const anomaliesDetectLabel = formatMessage(ANOMALIES_DETECT_LABEL);
    const anomalySensitivityLabel = formatMessage(ANOMALIES_SENSITIVITY_LABEL);
    const debouncedSetSensitivity = useRef(debounce((value) => {
        dispatch(setSensitivity(sensitivityLevelsArray[value]));
    }, 500)).current;
    const handleSensitivityChange = (value) => {
        debouncedSetSensitivity(value);
    };
    const toggleHandler = () => {
        dispatch(toggleAnomaly(!isAnomalyEnabled));
    };
    return (React.createElement(ModalAnomalySettings, { anomalySettingsTitle: anomalySettingsTitle, anomaliesDetectLabel: anomaliesDetectLabel, anomalySensitivityLabel: anomalySensitivityLabel, isAnomalyEnabled: isAnomalyEnabled, currentSensitivityIndex: currentSensitivityIndex, onChangeSensitivity: handleSensitivityChange, sensitivityLevelsArray: sensitivityLevelsArray, onChangeToggle: toggleHandler }));
};
