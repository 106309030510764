import styled from "styled-components";
import { Color, FontBodyBigMedium } from "@boomq/uikit";
export const deadlineInputTheme = {
    marginRight: "44px"
};
export const DeadlineRow = styled.div `
    align-items: center;
    display: flex;
    margin-top: 20px;
    padding-left: 44px;
    width: 100%;
`;
export const DeadlineTitle = styled.div `
    ${FontBodyBigMedium}
    color: ${Color.darkGrey5};
    margin-right: 10px;
    max-width: 260px;
    min-width: 260px;
    padding-left: 12px;
    width: 260px;
`;
