import React from "react";
import { connect } from "react-redux";
import { useIntl } from "@boomq/utils";
import { getValueByPath } from "@boomq/utils";
import { INPUT_PLACEHOLDER } from "./intl";
import { ControllerButtonDelete } from "../ControllerButtonDelete";
import { ControllerChildrenCounter } from "../ControllerChildrenCounter";
import { DragAndDropIcon, DragAndDropIconBlock, Transaction, TransactionHeader, TransactionElBody } from "../TransactionElement/styled";
import { StyledInputWithParameters } from "../IfElement/styled";
import { Actions } from "../RequestHttpElement";
import { TransactionToggler } from "../TransactionToggler";
import { formatIntlMessage } from "../../../../helpers/intl";
import { getNodeDataError, isExistNodeDataErrorByParams } from "../../../../helpers/threadGroupEditor";
import { deleteEditorElementByPath, setElementField } from "../../../../redux/actions/editor";
import { getEditorParameterListByPath, getEditorTreeData, getElementById } from "../../../../redux/reducers/editor";
const connector = connect((state, ownProps) => ({
    nodeData: getElementById(state, ownProps.node.id),
    parameterList: getEditorParameterListByPath(state, ownProps.path),
    treeData: getEditorTreeData(state)
}), {
    onChange: setElementField,
    onDelete: deleteEditorElementByPath
});
const WhileElement = ({ changeActiveDnD, children, expanded, node, nodeData, onChange, onDelete, onTogglerClick, path, parameterList, treeData }) => {
    const { formatMessage } = useIntl();
    const blurHandler = () => (typeof changeActiveDnD === "function" ? changeActiveDnD(true) : undefined);
    const changeHandler = (field, value) => onChange({ field, id: node.id, value });
    const changeParamAsValue = (paramName, name) => {
        const value = getValueByPath(nodeData, paramName, "") + "${" + name + "}";
        return changeHandler(paramName, value);
    };
    const focusHandler = () => (typeof changeActiveDnD === "function" ? changeActiveDnD(false) : undefined);
    const toggleTransactionStateHandler = () => onTogglerClick(!expanded);
    const placeholder = formatIntlMessage(INPUT_PLACEHOLDER, formatMessage);
    return (React.createElement(Transaction, { "data-id": node.id, isExistError: isExistNodeDataErrorByParams(nodeData, ["condition"]) },
        React.createElement(DragAndDropIconBlock, null,
            React.createElement(DragAndDropIcon, null)),
        React.createElement(TransactionElBody, null,
            React.createElement(TransactionHeader, null,
                React.createElement(TransactionToggler, { isExpanded: expanded, onChange: toggleTransactionStateHandler }),
                React.createElement(ControllerChildrenCounter, { node: node }),
                "while",
                React.createElement(StyledInputWithParameters, { error: getNodeDataError(nodeData), onBlur: blurHandler, onChange: changeHandler, onChangeParamAsValue: changeParamAsValue, onFocus: focusHandler, paramName: "condition", parameters: parameterList, placeholder: placeholder, value: nodeData }),
                React.createElement(Actions, null,
                    React.createElement(ControllerButtonDelete, { onClick: onDelete, path: path, treeData: treeData })))),
        children));
};
export default connector(WhileElement);
