import { combineActions, handleActions } from "redux-actions";
import authorizeActions from "../actions/authorize";
import { clearTeamData, teamSuccess } from "../actions/team";
const { logout } = authorizeActions;
export const DEFAULT_TEAM_MEMBERS_PAGE_SIZE = 6;
export const initialState = {
    id: undefined,
    permissionList: [],
    size: DEFAULT_TEAM_MEMBERS_PAGE_SIZE,
    teamName: "",
    teamMemberCurrentPage: 0,
    teamMemberList: []
};
export default handleActions({
    [combineActions(clearTeamData, logout)]: () => (Object.assign({}, initialState)),
    [teamSuccess]: (state, action) => (Object.assign(Object.assign({}, state), action.payload))
}, initialState);
const getStateParam = (state, paramName) => (state && state.team ? state.team[paramName] : initialState[paramName]);
export const getTeamId = (state) => getStateParam(state, "id");
export const getTeamImage = (state) => getStateParam(state, "icon");
export const getTeamName = (state) => getStateParam(state, "teamName");
export const getTeamPermissionList = (state) => getStateParam(state, "permissionList");
