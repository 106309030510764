import styled from "styled-components";
import { IconPlus20Blue } from "../../styled/StyledIcons";
import { TableRows } from "../../styled/Table";
import { Padding } from "../../styled/Wrappers";
export const StyledPage = styled(Padding) `
    display: flex;
    flex-direction: column;
`;
export const StyledIconPlus = styled(IconPlus20Blue) `
    margin-right: 6px;
`;
export const StyledTableRows = styled(TableRows) `
    flex: auto;
`;
