var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { getValueByPath, useIntl } from "@boomq/utils";
import { HOST_NAME_PLACEHOLDER } from "./intl";
import { SlaSystemMetricsItem } from "../../../components/account/SlaSystemMetricsItem";
import { formatIntlMessage } from "../../../helpers/intl";
import { getSlaSystemMetricRestrictionTypeItems } from "../../../helpers/sla";
import { SlaTargetType } from "../../../models/sla";
const SlaSystemMetricsContainer = (_a) => {
    var { slaData } = _a, restProps = __rest(_a, ["slaData"]);
    const { formatMessage } = useIntl();
    const changeHostnameHandler = (id, value) => restProps.onDispatchAction({
        type: "update",
        payload: { id, field: "hostname", type: SlaTargetType.SystemMetricsHost, data: value }
    });
    const hostnameInputPlaceholder = formatIntlMessage(HOST_NAME_PLACEHOLDER, formatMessage);
    return getValueByPath(slaData, SlaTargetType.SystemMetricsHost, []).map((slaItemData) => (React.createElement(SlaSystemMetricsItem, Object.assign({ key: slaItemData.id }, restProps, { data: Object.assign({}, slaItemData), hostnameInputPlaceholder: hostnameInputPlaceholder, onChangeHostname: (value) => changeHostnameHandler(slaItemData.id, value), restrictionTypeItems: getSlaSystemMetricRestrictionTypeItems(), type: SlaTargetType.SystemMetricsHost }))));
};
export default SlaSystemMetricsContainer;
