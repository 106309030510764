var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { DashedSection, NewItem, StyledIconPlus, TextSection } from "./styled";
export const NewItemBlock = (_a) => {
    var { children, to } = _a, restProps = __rest(_a, ["children", "to"]);
    return (React.createElement(NewItem, Object.assign({}, restProps),
        React.createElement(DashedSection, null),
        React.createElement(TextSection, { to: to },
            React.createElement(StyledIconPlus, null),
            children),
        React.createElement(DashedSection, null)));
};
