var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { connect } from "react-redux";
import { useIntl, useResize } from "@boomq/utils";
import { WIZARD_TITLE1, WIZARD_TITLE2 } from "./intl";
import { Wizard } from "../../../../components/account/Wizard";
import { sizes } from "../../../../components/styled/response";
import { formatIntlMessage } from "../../../../helpers/intl";
import { TestType } from "../../../../helpers/types";
import { testProfileValidator } from "../../../../helpers/validators";
import newTestActions from "../../../../redux/actions/newTest";
import { getCurrentStep, getProfileDistribution, getRequestGroups, getRequests, getTestType, getThreadGroupsRequests } from "../../../../redux/reducers/newTest";
const { setStep } = newTestActions;
const getIsValidProp = (state) => {
    const profileDistribution = getProfileDistribution(state);
    const testType = getTestType(state);
    const threadGroups = getRequestGroups(state);
    const requests = testType === TestType.JMX ? getRequests(state) : getThreadGroupsRequests(state);
    const { isValid } = testProfileValidator({ profileDistribution, requests, testType, threadGroups });
    return isValid;
};
const connector = connect((state) => ({
    currentStep: getCurrentStep(state),
    isValid: getIsValidProp(state)
}), {
    onChangeStep: setStep
});
const TestProjectSettingsWizard = (_a) => {
    var { children, onChangeStep } = _a, restProps = __rest(_a, ["children", "onChangeStep"]);
    const { formatMessage } = useIntl();
    const { width } = useResize();
    const step1Label = formatIntlMessage(WIZARD_TITLE1, formatMessage);
    const step2Label = formatIntlMessage(WIZARD_TITLE2, formatMessage);
    return (React.createElement(React.Fragment, null,
        width > sizes.tablet && (React.createElement(Wizard, Object.assign({ step1Label: step1Label, step2Label: step2Label, onChangeStep: onChangeStep }, restProps))),
        children));
};
export default connector(TestProjectSettingsWizard);
