import React from "react";
import { Action, Actions, ProtocolLabel, MethodLabel, Plate, PlateText, UrlPath, UrlTitle } from "./styled";
import { TestProjectRequestActionType } from "../../../../helpers/types";
import { getHttpMethodItemsWithParams, getProtocolParams } from "../../../../models/request";
const getMethodColor = (method) => {
    const selectedMethod = getHttpMethodItemsWithParams().find((methodItem) => methodItem.value === method);
    return selectedMethod && selectedMethod.bgColor ? selectedMethod.bgColor : "#FFF";
};
const getActionName = (action) => action && action.actionName ? action.actionName : undefined;
const getActionParams = (action) => action && action.params ? action.params : {};
export const Request = (props) => {
    const { actions, controllerType, id, method = "GET", onClickAction, requestUrl, toTitle } = props;
    const { protocolLabel, protocolColor } = getProtocolParams(controllerType);
    const clickHandler = (id, params, name) => name && typeof onClickAction === "function" && onClickAction(name, id, params);
    return (React.createElement(Plate, { id: id, key: id },
        React.createElement(MethodLabel, { bgColor: getMethodColor(method) }, method),
        React.createElement(PlateText, null,
            toTitle && React.createElement(UrlTitle, { title: toTitle }, toTitle),
            React.createElement(UrlPath, null, requestUrl)),
        React.createElement(ProtocolLabel, { bgColor: protocolColor }, protocolLabel),
        Array.isArray(actions) && actions.length > 0 && (React.createElement(Actions, null, actions.map((action, index) => (React.createElement(Action, { key: `${action.actionName}_${index}`, onClick: () => clickHandler(id, getActionParams(action), getActionName(action)) }, action.type === TestProjectRequestActionType.Component ? (React.createElement(action.iconComponent, Object.assign({}, props))) : (React.createElement(action.icon, null)))))))));
};
