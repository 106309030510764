import { NavLink } from "react-router-dom";
import styled from "styled-components";
export const Item = styled(NavLink) `
    margin-left: 15px;
    color: #0663a3;
    font-size: 18px;
    &:first-child {
        margin-left: 0;
    }
    &.active {
        text-decoration: underline;
    }
`;
