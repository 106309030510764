export const FULL_METHOD_TOOLTIP_TEXT = {
    defaultMessage: "You have to upload proto files in gRPC buffer",
    id: "account.grpc.request.form.full.method.tooltip.text"
};
export const TLS_ENABLED_TOOLTIP_TEXT = {
    defaultMessage: "Connection is secure",
    id: "account.grpc.request.form.tls.enabled.tooltip.text"
};
export const TLS_DISABLED_TOOLTIP_TEXT = {
    defaultMessage: "Connection is not secure",
    id: "account.grpc.request.form.tls.disabled.tooltip.text"
};
