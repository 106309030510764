import React, { useRef } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { getValueByPath } from "@boomq/utils";
import { ICON_CLOSE_CLASS_NAME } from "./constants";
import { FormTitle, ModalPlate, ModalWrapper, StyledIconClose } from "./styled";
import { Popper } from "../../common/Popper";
import { closeSiteModal } from "../../../redux/actions/siteModal";
import { getSiteTitle, getModalComponent, getSiteModalVisibility, getCloseCallBack } from "../../../redux/reducers/siteModal";
const connector = connect((state) => ({
    component: getModalComponent(state),
    closeCallBack: getCloseCallBack(state),
    isVisible: getSiteModalVisibility(state),
    title: getSiteTitle(state)
}), { onClose: closeSiteModal });
const renderTitle = (title) => title && title.id ? (React.createElement(FormTitle, null,
    React.createElement(FormattedMessage, Object.assign({}, title)))) : (React.createElement(React.Fragment, null));
const ModalWindow = (props) => {
    const { component, isVisible, title } = props;
    const outsideModalRef = useRef(null);
    const closeModalWindow = (e) => {
        props.onClose();
        return typeof props.closeCallBack === "function" ? props.closeCallBack(e) : undefined;
    };
    const checkCloseModalWindow = (e) => typeof props.onClose === "function" ? closeModalWindow(e) : undefined;
    const clickCloseIconHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const iconClose = e && e.target ? e.target.closest(`.${ICON_CLOSE_CLASS_NAME}`) : {};
        return iconClose ? checkCloseModalWindow(e) : undefined;
    };
    const clickOutsideIconCloseHandler = (e) => `${e.target.className} ${getValueByPath(e.target.parentElement, "className", "")}`.includes(ICON_CLOSE_CLASS_NAME) || e.target === outsideModalRef.current
        ? checkCloseModalWindow(e)
        : undefined;
    return isVisible ? (React.createElement(Popper, { placement: "top-start", open: isVisible ? true : false },
        React.createElement(ModalWrapper, { ref: outsideModalRef, onClick: clickOutsideIconCloseHandler },
            React.createElement(ModalPlate, { width: component.width, height: component.height, theme: component.theme },
                React.createElement(StyledIconClose, { className: ICON_CLOSE_CLASS_NAME, onClick: clickCloseIconHandler }),
                renderTitle(title),
                React.createElement(component.element, Object.assign({}, component.props, { closeModalWindow: closeModalWindow })))))) : (React.createElement(React.Fragment, null));
};
export default connector(ModalWindow);
