import React, { FC, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditorJS from "@editorjs/editorjs";
import { isEmpty } from "@boomq/utils";
import {
    EDITORJS_HOLDER_ID,
    ReportContainer,
    createEditorInstance,
    createReportRequest,
    deleteReportPflbChart,
    deleteReportPflbTable,
    getIsAuthorize,
    getSelectedTeamId,
    initReport
} from "@boomq/common";
import { PFLBChartConnectedToStore } from "../../../../components/account/Report/PFLBChartPlugin";
import { PFLBTableConnectedToStore } from "../../../../components/account/Report/PFLBTablePlugin";

export const NewReportContainer: FC<any> = (props): JSX.Element => {
    const dispatch = useDispatch();

    const isAuthorized = useSelector(getIsAuthorize);
    const selectedTeamId = useSelector(getSelectedTeamId);

    const editorInstance = useRef<EditorJS>();

    useEffect(() => {
        const init = (): void => {
            editorInstance.current = createEditorInstance(
                EDITORJS_HOLDER_ID,
                { blocks: [] },
                {
                    pflbChart: {
                        class: PFLBChartConnectedToStore,
                        config: {
                            onDeleteBlock: (id) => dispatch(deleteReportPflbChart(id))
                        }
                    },
                    pflbTable: {
                        class: PFLBTableConnectedToStore,
                        config: {
                            onDeleteBlock: (id) => dispatch(deleteReportPflbTable(id)),
                            rows: 2,
                            cols: 3
                        },
                        inlineToolbar: true
                    }
                }
            );

            return dispatch(initReport());
        };

        const checkAuthorization = (): void => (isAuthorized && !isEmpty(selectedTeamId) ? init() : undefined);

        checkAuthorization();
    }, [isAuthorized, selectedTeamId]);

    useEffect(() => {
        return () => editorInstance.current?.destroy();
    }, []);

    const clickSaveHandler = (): Promise<any> | undefined =>
        editorInstance.current
            ?.save()
            .then((outputData) => dispatch(createReportRequest(outputData)))
            .catch(() => undefined);

    return (
        <ReportContainer
            isAuthorized={isAuthorized}
            onClickSaveButton={clickSaveHandler}
            selectedTeamId={selectedTeamId}
            {...props}
        />
    );
};
