import React from "react";
import { generateImageUrlByContentInBase64, isEmpty } from "@boomq/utils";
import { StyledIconPeople, TeamData, TeamDefaultImage, TeamImage, TeamInfoBody, TeamInfoHeader, TeamInfoText, TeamItemHeader, TeamName } from "./styled";
const renderImage = (teamImage) => !isEmpty(teamImage) && typeof teamImage === "string" ? (React.createElement(TeamImage, { src: generateImageUrlByContentInBase64(teamImage) })) : (React.createElement(TeamDefaultImage, null));
export const TeamsItemTemplate = (props) => {
    const { membersCountText, team: { icon, teamName } } = props || { team: {} };
    return (React.createElement(TeamItemHeader, null,
        renderImage(icon),
        React.createElement(TeamData, null,
            React.createElement(TeamInfoHeader, null,
                React.createElement(TeamName, null, teamName),
                props.children),
            React.createElement(TeamInfoBody, null,
                React.createElement(TeamInfoText, null,
                    React.createElement(StyledIconPeople, null),
                    membersCountText)))));
};
