import React from "react";
import { FormattedMessage } from "react-intl";
import { SELECT_TEAM_BUTTON_LABEL } from "./intl";
import { SelectTeamButton, StyledAddingIcon, TeamName } from "./styled";
import { TeamImagePreview } from "./TeamImagePreview";
export const TeamInfoData = ({ teamInfo }) => {
    const { id, icon, teamName } = teamInfo || {};
    return id > 0 ? (React.createElement(React.Fragment, null,
        React.createElement(TeamImagePreview, { icon: icon }),
        React.createElement(TeamName, { text: teamName, title: teamName }, teamName))) : (React.createElement(SelectTeamButton, null,
        React.createElement(StyledAddingIcon, null),
        React.createElement(FormattedMessage, Object.assign({}, SELECT_TEAM_BUTTON_LABEL))));
};
