import { handleActions } from "redux-actions";
import { resetRequest, resetSuccess, resetFailed } from "../actions/reset";
import { resetError } from "../actions/error";
const initialState = {
    data: "",
    error: ""
};
export default handleActions({
    [resetRequest]: (state) => (Object.assign(Object.assign({}, state), { data: "", error: "" })),
    [resetSuccess]: (state) => (Object.assign(Object.assign({}, state), { data: "SUCCESS" })),
    [resetFailed]: (state, { payload }) => (Object.assign(Object.assign({}, state), { error: payload.code || payload.status })),
    [resetError]: (state) => (Object.assign(Object.assign({}, state), { error: initialState.error }))
}, initialState);
export const getResetErrorCode = (state) => state.reset.error;
export const getResetData = (state) => state.reset.data;
