import { call, put, takeEvery } from "redux-saga/effects";
import { addTeamMemberInNewTeam, checkAuthorizedUserByTeamMemberPermissionsUpdating, excludeTeamMemberInNewTeam, initTeamMembersFlow, inviteLinkCopingFlow, refreshTeamMembers, updateTeamMemberFlow } from "./teamMembers";
import { getResponseErrorData } from "../../helpers/errors";
import { NEW_ITEM_ID } from "../../helpers/routes";
import { formatDataToTeamMemberDto } from "../../helpers/teams";
import { addTeamMember, addTeamMemberFailure, changeTeamMember, changeTeamMemberFailure, changeTeamMembersPage, copyInviteLink, excludeTeamMember, excludeTeamMemberFailure, initTeamMembers, setTeamMembersLoading, updateTeamMember } from "../actions/teamMembers";
import { deleteTeamMember, getTeamMembers, inviteTeamMembers, updateTeamMembers } from "../../services/authSrv";
function* refreshUserTeamMembersFlow(action) {
    yield* refreshTeamMembers(action, getTeamMembers);
}
function* addTeamMemberInExistUserTeam(teamId, data) {
    try {
        const teamMembersData = [formatDataToTeamMemberDto(data)];
        yield put(setTeamMembersLoading(true));
        yield call(inviteTeamMembers, teamId, teamMembersData);
        yield* refreshUserTeamMembersFlow({ payload: { teamId, page: 0 } });
    }
    catch (e) {
        yield put(setTeamMembersLoading(false));
        yield put(addTeamMemberFailure(getResponseErrorData(e)));
    }
}
function* teamMemberAddingFlow(action) {
    const { teamId, data } = action && action.payload ? action.payload : {};
    return teamId === NEW_ITEM_ID
        ? yield* addTeamMemberInNewTeam(data)
        : yield* addTeamMemberInExistUserTeam(teamId, data);
}
function* changeTeamMemberInExistUserTeam(teamId, data) {
    try {
        yield put(setTeamMembersLoading(true));
        yield* updateTeamMemberFlow({ teamId, data }, updateTeamMembers);
        yield* refreshUserTeamMembersFlow({ payload: { teamId } });
        yield* checkAuthorizedUserByTeamMemberPermissionsUpdating({ teamId, data });
    }
    catch (e) {
        yield put(setTeamMembersLoading(false));
        yield put(changeTeamMemberFailure(getResponseErrorData(e)));
    }
}
function* teamMemberChangingFlow(action) {
    const { teamId, data } = action && action.payload ? action.payload : {};
    return teamId === NEW_ITEM_ID
        ? yield put(updateTeamMember(data))
        : yield* changeTeamMemberInExistUserTeam(teamId, data);
}
function* userInviteLinkCopingFlow(action) {
    yield* inviteLinkCopingFlow(action, updateTeamMembers);
}
function* excludeTeamMemberInExistUserTeam(teamId, data) {
    try {
        const teamMemberToDeleting = data.id ? [data] : [];
        yield put(setTeamMembersLoading(true));
        yield call(deleteTeamMember, teamId, teamMemberToDeleting);
        yield* refreshUserTeamMembersFlow({ payload: { teamId, page: 0 } });
    }
    catch (e) {
        yield put(setTeamMembersLoading(false));
        yield put(excludeTeamMemberFailure(getResponseErrorData(e)));
    }
}
function* teamMemberExcludingFlow(action) {
    const { teamId, data } = action && action.payload ? action.payload : {};
    return teamId === NEW_ITEM_ID
        ? yield* excludeTeamMemberInNewTeam(data)
        : yield* excludeTeamMemberInExistUserTeam(teamId, data);
}
function* initUserTeamMembersFlow(action) {
    yield* initTeamMembersFlow(action, getTeamMembers);
}
export function* userTeamMembersFlow() {
    yield takeEvery(addTeamMember, teamMemberAddingFlow);
    yield takeEvery(changeTeamMember, teamMemberChangingFlow);
    yield takeEvery(changeTeamMembersPage, refreshUserTeamMembersFlow);
    yield takeEvery(copyInviteLink, userInviteLinkCopingFlow);
    yield takeEvery(excludeTeamMember, teamMemberExcludingFlow);
    yield takeEvery(initTeamMembers, initUserTeamMembersFlow);
}
