import { takeEvery, call, select, put } from "redux-saga/effects";
import { getValueByPath } from "@boomq/utils";
import authorizeActions from "../actions/authorize";
import { getSecretTokenData } from "../reducers/authorizePlate";
import { getResponseErrorData } from "../../helpers/errors";
import { createSecretToken, deleteSecretToken, getSecretToken } from "../../services/authSrv";
const { generateSecretTokenRequest, secretTokenFailure, secretTokenRequest, secretTokenSuccess } = authorizeActions;
function* deleteSecretTokenFlow() {
    const currentSecretToken = yield select(getSecretTokenData);
    return currentSecretToken && currentSecretToken.length > 0
        ? yield call(deleteSecretToken, currentSecretToken)
        : undefined;
}
export function* generateSecretToken() {
    try {
        const response = yield call(createSecretToken);
        return getValueByPath(response, "data.secretToken");
    }
    catch (e) {
        console.error(e);
    }
}
function* refreshSecretTokenFlow() {
    try {
        yield* deleteSecretTokenFlow();
        const secretToken = yield* generateSecretToken();
        yield put(secretTokenSuccess(secretToken));
    }
    catch (e) {
        yield put(secretTokenFailure(getResponseErrorData(e)));
    }
}
export function* getCurrentSecretToken() {
    try {
        const response = yield call(getSecretToken);
        return getValueByPath(response, "data.secretToken");
    }
    catch (e) {
        console.error(e);
    }
}
function* secretTokenRequestFlow() {
    try {
        const secretToken = yield* getCurrentSecretToken();
        yield put(secretTokenSuccess(secretToken));
    }
    catch (e) {
        yield put(secretTokenFailure(getResponseErrorData(e)));
    }
}
export function* secretTokenFlow() {
    yield takeEvery(generateSecretTokenRequest, refreshSecretTokenFlow);
    yield takeEvery(secretTokenRequest, secretTokenRequestFlow);
}
