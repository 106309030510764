var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { UserDefaultPhoto, UserInfoWrapper, UserLink, UserName, UserPhoto } from "./styled";
import { getClientInfo, getIsAuthorize } from "../../../redux/reducers/authorizePlate";
const connector = connect((state) => ({
    isAuthorized: getIsAuthorize(state),
    clientInfo: getClientInfo(state)
}), {});
const renderUserInfoIcon = ({ email, firstName, lastName, picture }) => (React.createElement(React.Fragment, null,
    picture ? React.createElement(UserPhoto, { src: picture, alt: "client" }) : React.createElement(UserDefaultPhoto, { alt: "client" }),
    React.createElement(UserName, null, firstName && lastName ? `${firstName} ${lastName}` : email)));
const UserInfo = (_a) => {
    var { bordered, clientInfo, isAuthorized, mobile, path, reverse } = _a, restProps = __rest(_a, ["bordered", "clientInfo", "isAuthorized", "mobile", "path", "reverse"]);
    return isAuthorized ? (React.createElement(UserLink, Object.assign({ to: path, bordered: bordered, mobile: String(mobile), reverse: reverse }, restProps), renderUserInfoIcon(clientInfo))) : (React.createElement(UserInfoWrapper, Object.assign({ bordered: bordered, mobile: String(mobile), reverse: reverse }, restProps), renderUserInfoIcon(clientInfo)));
};
export default withRouter(connector(UserInfo));
