import { put, call, all } from "redux-saga/effects";
import { push } from "connected-react-router";
import { AUTH_ERROR_CODES } from "./constants";
import { showError } from "./showError";
import authorizeActions from "../../actions/authorize";
import { removeAuthToken } from "../../../helpers/auth";
import { ExceptionForm } from "../../../components/common/ExceptionForm";
import { apiErrors, authErrors } from "../../../data/content/errorMessages";
import { clearTokenApi } from "../../../services/authSrv";
const { authFailure, logout } = authorizeActions;
export function* authFailureFlow(action) {
    const { code } = action && action.payload ? action.payload : {};
    const params = AUTH_ERROR_CODES.includes(code)
        ? {
            element: ExceptionForm,
            props: {
                texts: [
                    Object.assign({}, authErrors(code))
                ]
            },
            theme: { alignItems: "center" }
        }
        : {};
    yield* showError(params);
}
export function* reloadFailureFlow(action) {
    const { code } = action && action.payload ? action.payload : {};
    yield all([call(removeAuthToken), call(clearTokenApi), put(push("/"))]);
    yield put(logout());
    yield put(authFailure(apiErrors(code)));
    yield* showError();
}
