var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { IconHelpWrapper } from "./styled";
import { Tooltip } from "../Tooltip";
import { IconHelp } from "../Icons/Help";
import { Color } from "../styled/colorPallete";
export const Help = (_a) => {
    var { tooltipText, theme } = _a, rest = __rest(_a, ["tooltipText", "theme"]);
    return (React.createElement(Tooltip, { title: tooltipText, theme: theme },
        React.createElement(IconHelpWrapper, Object.assign({}, rest),
            React.createElement(IconHelp, { fill: Color.blue }))));
};
