import React, { useReducer } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getUniqueId, useIntl } from "@boomq/utils";
import { BUTTON_APPLY_LABEL, DEFAULT_HEADER_KEY_PLACEHOLDER, DEFAULT_HEADER_VALUE_PLACEHOLDER } from "./intl";
import { keyValueTheme } from "./styled";
import { KeyValue } from "../../../../components/account/KeyValue";
import { StyledButton } from "../../../../components/styled/Buttons";
import { withResize } from "../../../../components/styled/response";
import { SaveButtonSection } from "../../../../components/account/TestProject/RequestDefaultTimerSettingsForm/styled";
import { NEW_ITEM_ID } from "../../../../helpers/routes/constants";
import { headerItemListValidator } from "../../../../helpers/validators";
import { changeDefaultHeaders } from "../../../../redux/actions/testProjectDefaultSettings";
import { getDefaultHeaders, getRequestGlobalParametersList } from "../../../../redux/reducers/newTest";
const generateDefaultHeaderNewItem = () => ({
    id: `${NEW_ITEM_ID}_${getUniqueId()}`,
    key: "",
    value: ""
});
const connector = connect((state) => ({
    defaultHeadersFromStore: getDefaultHeaders(state),
    requestParametersList: getRequestGlobalParametersList(state)
}), { onChange: changeDefaultHeaders });
const reducer = (state, action) => {
    const { type, payload } = action || {};
    switch (type) {
        case "add": {
            return Array.isArray(state) && payload
                ? [...state, payload].filter((header) => header.key !== "" || header.value !== "")
                : state;
        }
        case "delete": {
            return Array.isArray(state) ? state.filter((header) => header.id !== payload) : [];
        }
        case "set": {
            return Array.isArray(payload) ? payload : state;
        }
        case "update": {
            return Array.isArray(state) && payload
                ? state
                    .map((header) => (header.id === payload.id ? Object.assign({ id: payload.id }, payload.value) : header))
                    .filter((header) => header.key !== "" || header.value !== "")
                : state;
        }
        default: {
            return state;
        }
    }
};
const RequestDefaultHeadersFormContainer = (props) => {
    const { formatMessage } = useIntl();
    const [defaultHeaders, dispatch] = useReducer(reducer, props.defaultHeadersFromStore);
    const changeHandler = (id, value) => id.includes(NEW_ITEM_ID)
        ? dispatch({ type: "add", payload: Object.assign(Object.assign({}, value), { id: getUniqueId() }) })
        : dispatch({ type: "update", payload: { id, value } });
    const clickApplyHandler = () => {
        const filteredHeaders = defaultHeaders.filter((header) => header.key !== "" || header.value !== "");
        const { isValid, validatedItemList } = headerItemListValidator(filteredHeaders);
        return isValid ? props.onChange(validatedItemList) : dispatch({ type: "set", payload: validatedItemList });
    };
    const deleteHandler = (id) => dispatch({ type: "delete", payload: id });
    const keyPlaceholder = formatMessage(DEFAULT_HEADER_KEY_PLACEHOLDER);
    const valuePlaceholder = formatMessage(DEFAULT_HEADER_VALUE_PLACEHOLDER);
    return (React.createElement(React.Fragment, null,
        [...defaultHeaders, generateDefaultHeaderNewItem()].map(({ error, id, key, value }) => (React.createElement(KeyValue, { error: error, key: id, name: "defaultHeader", onChange: (val) => changeHandler(id, val), onDelete: () => deleteHandler(id), parameters: props.requestParametersList, placeholderKey: keyPlaceholder, placeholderValue: valuePlaceholder, theme: keyValueTheme, value: { key, value } }))),
        React.createElement(SaveButtonSection, null,
            React.createElement(StyledButton, { onClick: clickApplyHandler }, formatMessage(BUTTON_APPLY_LABEL)))));
};
export default withResize(withRouter(connector(RequestDefaultHeadersFormContainer)));
