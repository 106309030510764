import React from "react";
import { FormattedMessage } from "react-intl";
import { WELCOME_ALL_TESTS_BUTTON_LABEL_ADD, WELCOME_ALL_TESTS_BUTTON_LABEL_GO, WELCOME_ALL_TESTS_SUBTITLE, WELCOME_ALL_TESTS_TITLE } from "./intl";
import { StyledButton, StyledButtonWrapper, StyledContainer, StyledSubtitle, StyledTitle } from "./styled";
export const AllTestsEmpty = ({ onClickAddNewTest, onClickGoToTests }) => {
    return (React.createElement(StyledContainer, null,
        React.createElement(StyledTitle, null,
            React.createElement(FormattedMessage, Object.assign({}, WELCOME_ALL_TESTS_TITLE))),
        React.createElement(StyledSubtitle, null,
            React.createElement(FormattedMessage, Object.assign({}, WELCOME_ALL_TESTS_SUBTITLE))),
        React.createElement(StyledButtonWrapper, null,
            React.createElement(StyledButton, { onClick: onClickAddNewTest },
                React.createElement(FormattedMessage, Object.assign({}, WELCOME_ALL_TESTS_BUTTON_LABEL_ADD))),
            React.createElement(StyledButton, { onClick: onClickGoToTests },
                React.createElement(FormattedMessage, Object.assign({}, WELCOME_ALL_TESTS_BUTTON_LABEL_GO))))));
};
