import { isEmpty, sortFuncByParamAndAlphabet } from "@boomq/utils";
import { DEFAULT_NEW_SLA_FORM_ITEM_TARGET_TYPE } from "./constant";
import { getOperationTextByValue } from "./operationTextByValue";
import { getSlaTransactionName } from "./slaParams";
import { getRequestName } from "../request";
import { SlaRestrictionType, SlaTargetType, SlaTransactionFilterType, getSlaColorBySlaStatus, getSlaColorBySlaTestResultSlaState, getSlaStatusDescriptionBySlaStatus, getSlaStatusDescriptionByTestResultSlaStatus, newSLAFormItemDataByTargetType, slaOperations, slaSystemMetricRestrictionTypes, slaTestMetricRestrictionTypes } from "../../models/sla";
import { TestResultSlaState } from "../../models/testRun";
const sortFuncForListByAlphabet = (a, b) => sortFuncByParamAndAlphabet(a, b, "text");
export const getNewSlaFormItemDataByTargetType = ({ type, testDuration }) => Object.keys(newSLAFormItemDataByTargetType).includes(type)
    ? newSLAFormItemDataByTargetType[type](testDuration)
    : newSLAFormItemDataByTargetType[DEFAULT_NEW_SLA_FORM_ITEM_TARGET_TYPE](testDuration);
export const getRequestItems = (requests) => {
    const uniqueRequestMap = (requests || []).reduce((res, { id, groupId, name, requestUrl }) => {
        const requestName = name || requestUrl;
        return res.requestNames.includes(requestName)
            ? res
            : {
                requestItems: [
                    ...res.requestItems,
                    {
                        id,
                        groupId,
                        text: requestName,
                        value: requestName
                    }
                ],
                requestNames: [...res.requestNames, requestName]
            };
    }, { requestItems: [], requestNames: [] });
    return uniqueRequestMap.requestItems.sort(sortFuncForListByAlphabet);
};
export const getSlaOperationItems = () => slaOperations.map((operation, index) => ({
    id: index,
    text: getOperationTextByValue(operation),
    value: operation
}));
const getRestrictionTypeLabel = (targetType, restrictionType) => targetType === SlaTargetType.Transaction && restrictionType === SlaRestrictionType.Throughput
    ? `${restrictionType}.${SlaTargetType.Transaction}`
    : restrictionType;
export const getSlaSystemMetricRestrictionTypeItems = () => slaSystemMetricRestrictionTypes.map((restrictionType, index) => ({
    id: index,
    intlMsg: {
        defaultMessage: restrictionType,
        id: `account.sla.form.system.metric.restriction.type.${restrictionType}.label`
    },
    value: restrictionType
}));
export const getSlaTestMetricRestrictionTypeItems = (targetType) => slaTestMetricRestrictionTypes.map((restrictionType, index) => ({
    id: index,
    intlMsg: {
        defaultMessage: restrictionType,
        id: `account.sla.form.test.metric.restriction.type.${getRestrictionTypeLabel(targetType, restrictionType)}.label`
    },
    value: restrictionType
}));
export const getSlaTypeTransactionName = (slaType) => {
    const transactionName = getSlaTransactionName(slaType);
    return transactionName ? `${transactionName} ` : "";
};
export const prepareTestProjectTransactionItems = (transactions) => (transactions || [])
    .map(({ groupId, id, name }) => ({
    id,
    groupId,
    text: name,
    value: name
}))
    .sort(sortFuncForListByAlphabet);
export const prepareTestProjectThreadGroupItems = (transactions) => (transactions || [])
    .map(({ id, name }) => ({
    id,
    text: name,
    value: id
}))
    .sort(sortFuncForListByAlphabet);
export const getTestProjectTransactionItems = (transactions, transactionIds) => isEmpty(transactionIds)
    ? []
    : prepareTestProjectTransactionItems(transactions.filter((transaction) => transactionIds.includes(transaction.id)));
const getThreadGroupItemsByIds = (threadGroupItems, threadGroupIds) => isEmpty(threadGroupIds) ? [] : threadGroupItems.filter((threadGroup) => threadGroupIds.includes(threadGroup.value));
export const getThreadGroupItemsByRequestName = ({ requestName, requests, threadGroupItems }) => {
    const threadGroupIds = (requests || [])
        .filter((request) => (request ? getRequestName(request) === requestName : false))
        .map((request) => request.groupId);
    return getThreadGroupItemsByIds(threadGroupItems, threadGroupIds);
};
export const getThreadGroupItemsByTransactionName = ({ threadGroupItems, transactions, transactionName }) => {
    const threadGroupIds = (transactions || [])
        .filter((transaction) => (transaction ? transaction.name === transactionName : false))
        .map((transaction) => transaction.groupId);
    return getThreadGroupItemsByIds(threadGroupItems, threadGroupIds);
};
export const getTransactionFilterTypeByValue = (value) => [SlaTransactionFilterType.AnyTransaction, SlaTransactionFilterType.NoTransaction].includes(value)
    ? value
    : SlaTransactionFilterType.Name;
export const getTransactionItemsByRequestName = ({ requestName, requests, transactions }) => {
    const transactionIds = (requests || [])
        .filter((request) => getRequestName(request) === requestName)
        .map((request) => request.transactionId);
    return getTestProjectTransactionItems(transactions, transactionIds);
};
export const getTransactionItems = (threadGroupsTransactions) => prepareTestProjectTransactionItems(threadGroupsTransactions);
export const isShowTestSlaResult = (result, testResultSlaState) => !isEmpty(result) || [TestResultSlaState.Processing, TestResultSlaState.Failed].includes(testResultSlaState);
export const getTestResultSlaStatusList = () => [
    TestResultSlaState.NoData,
    TestResultSlaState.Undefined
];
export const getSlaStatusColor = (status, testResultSlaState) => testResultSlaState === TestResultSlaState.Finished
    ? getSlaColorBySlaStatus(status)
    : getSlaColorBySlaTestResultSlaState(testResultSlaState);
export const getSlaStatusDescription = (status, testResultSlaState) => testResultSlaState === TestResultSlaState.Finished
    ? getSlaStatusDescriptionBySlaStatus(status)
    : getSlaStatusDescriptionByTestResultSlaStatus(testResultSlaState);
