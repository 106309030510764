import { TestDisplayStatus, TestStatus } from "./enums";
import { preliminaryStage1, preliminaryStage2, preliminaryStage3, preliminaryStage4, preliminaryStage5 } from "./preliminaryStage";
import { RunningTemplate } from "../../components/account/TestRuns/RunningTemplate";
import { TestRunsItemWithComparingTemplate } from "../../components/account/TestRuns/TestRunsItemWithComparingTemplate";
import { WaitingTemplate } from "../../components/account/TestRuns/WaitingTemplate";
const testRunsItemTemplateByStatus = {
    [TestDisplayStatus.Initialization]: WaitingTemplate,
    [TestDisplayStatus.Waiting]: WaitingTemplate,
    [TestDisplayStatus.Running]: RunningTemplate,
    [TestDisplayStatus.TestStopping]: RunningTemplate
};
const preliminaryStageDataByStatus = {
    [TestStatus.Created]: preliminaryStage1,
    [TestStatus.TestResourcesEstimated]: preliminaryStage2,
    [TestStatus.TestPlansCreated]: preliminaryStage3,
    [TestStatus.Waiting]: preliminaryStage3,
    [TestStatus.RetryingWaiting]: preliminaryStage3,
    [TestStatus.ResourcesAllocated]: preliminaryStage4,
    [TestStatus.Running]: preliminaryStage5
};
export const getTestRunsItemTemplateByStatus = (status) => status in testRunsItemTemplateByStatus ? testRunsItemTemplateByStatus[status] : TestRunsItemWithComparingTemplate;
export const getPreliminaryStageDataByStatus = (status) => status in preliminaryStageDataByStatus ? preliminaryStageDataByStatus[status] : preliminaryStage1;
export const getPreliminaryStages = () => [
    preliminaryStage1,
    preliminaryStage2,
    preliminaryStage3,
    preliminaryStage4,
    preliminaryStage5
];
