import { handleActions } from "redux-actions";
import { clearTestDataAnomalies, setCurrentTransaction, setSensitivity, setTestDataAnomalies, setTransactionDataAnomalies, setTransactionList, setUpdateChannelTest, setUpdateChannelTransaction, toggleAnomaly } from "../actions/testAnomalies";
import { SensitivityLevel } from "../../models/anomalyClassifications";
const initialState = {
    anomalyEnabled: false,
    currentTransaction: null,
    sensitivity: SensitivityLevel.Low,
    testDataAnomalies: [],
    transactionDataAnomalies: [],
    transactionList: [],
    updateChannelTest: undefined,
    updateChannelTransaction: undefined
};
export default handleActions({
    [clearTestDataAnomalies]: (state) => (Object.assign(Object.assign({}, state), { testDataAnomalies: [] })),
    [setCurrentTransaction]: (state, { payload }) => (Object.assign(Object.assign({}, state), { currentTransaction: payload })),
    [setSensitivity]: (state, { payload }) => (Object.assign(Object.assign({}, state), { sensitivity: payload })),
    [setTestDataAnomalies]: (state, { payload }) => (Object.assign(Object.assign({}, state), { testDataAnomalies: payload })),
    [setTransactionDataAnomalies]: (state, { payload }) => (Object.assign(Object.assign({}, state), { transactionDataAnomalies: payload })),
    [setTransactionList]: (state, { payload }) => (Object.assign(Object.assign({}, state), { transactionList: payload })),
    [setUpdateChannelTest]: (state, { payload }) => (Object.assign(Object.assign({}, state), { updateChannelTest: payload })),
    [setUpdateChannelTransaction]: (state, { payload }) => (Object.assign(Object.assign({}, state), { updateChannelTransaction: payload })),
    [toggleAnomaly]: (state, { payload }) => (Object.assign(Object.assign({}, state), { anomalyEnabled: payload }))
}, initialState);
export const getAnomalyEnabled = (state) => state.testAnomalies.anomalyEnabled;
export const getChannelTest = (state) => state.testAnomalies.updateChannelTest;
export const getChannelTransaction = (state) => state.testAnomalies.updateChannelTransaction;
export const getCurrentTransaction = (state) => state.testAnomalies.currentTransaction;
export const getTestDataAnomalies = (state) => state.testAnomalies.testDataAnomalies;
export const transactionDataAnomalies = (state) => state.testAnomalies.transactionDataAnomalies;
export const getSensitivity = (state) => state.testAnomalies.sensitivity;
export const getTransactionList = (state) => state.testAnomalies.transactionList;
