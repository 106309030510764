import styled from "styled-components";
import { Select } from "@boomq/uikit";
import { withDisable } from "../../../styled/global";
export const ButtonRemove = styled.div `
    cursor: pointer;
`;
export const IconSection = styled.div `
    min-width: 24px;
`;
export const RequestParam = styled.div `
    ${withDisable}
    align-items: center;
    display: flex;
    margin-top: 20px;
    padding-left: 44px;
    width: 100%;
    > div {
        margin-right: 10px;
    }
`;
export const TimerTypeSelect = styled(Select) `
    margin-right: 10px;
    max-width: 260px;
    min-width: 260px;
    opacity: 1;
`;
