var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { FormattedMessage } from "react-intl";
import { isEmptyArray } from "@boomq/utils";
import { Checkbox } from "@boomq/uikit";
import { TEAM_MEMBER_PERMISSIONS_TITLE } from "./intl";
import { EmailInput, NameInput, ParamsInputBlock, Permissions, PermissionsTitle } from "./styled";
import { formatEmail } from "../../../../helpers/utils";
const isCheckedPermission = (permissions, permissionItem) => permissions.includes(permissionItem.value);
const renderPermissions = ({ permissionItems, permissions, onTogglePermission }) => isEmptyArray(permissionItems) ? (React.createElement(React.Fragment, null)) : (React.createElement(Permissions, null, (permissionItems || []).map((permissionItem) => (React.createElement(Checkbox, { key: permissionItem.value, checked: isCheckedPermission(permissions, permissionItem), label: permissionItem.text, name: permissionItem.value, onToggle: onTogglePermission, value: permissionItem.value })))));
export const TeamMembersItemTemplateEditInputs = (_a) => {
    var { permissionItems, permissions } = _a, restProps = __rest(_a, ["permissionItems", "permissions"]);
    const actionHandler = (action, params) => typeof restProps[action] === "function" ? restProps[action](params) : undefined;
    const changeEmailHandler = (value) => actionHandler("onChangeEmail", formatEmail(value));
    const changeNameHandler = (value) => actionHandler("onChangeName", value);
    const togglePermissionHandler = ({ value }) => actionHandler("onTogglePermission", value);
    return (React.createElement(React.Fragment, null,
        React.createElement(ParamsInputBlock, null,
            React.createElement(NameInput, { label: restProps.nameInputLabel, onChange: changeNameHandler, placeholder: restProps.nameInputLabel, value: restProps.userDisplayName }),
            React.createElement(EmailInput, { error: restProps.emailError, label: restProps.emailInputLabel, onBlur: changeEmailHandler, placeholder: restProps.emailInputLabel, value: restProps.email })),
        React.createElement(PermissionsTitle, null,
            React.createElement(FormattedMessage, Object.assign({}, TEAM_MEMBER_PERMISSIONS_TITLE))),
        renderPermissions({ permissionItems, permissions, onTogglePermission: togglePermissionHandler })));
};
