import { isEmpty } from "@boomq/utils";
import { SLA_FROM_DATE_PARAM_NAME, SLA_HOST_NAME_PARAM_NAME, SLA_REQUEST_NAME_PARAM_NAME, SLA_RESTRICTION_TYPE_PARAM_NAME, SLA_TARGET_TYPE_PARAM_NAME, SLA_THREAD_GROUP_ID_PARAM_NAME, SLA_THREAD_GROUP_NAME_PARAM_NAME, SLA_TO_DATE_PARAM_NAME, SLA_TRANSACTION_FILTER_TYPE_PARAM_NAME, SLA_TRANSACTION_ID_PARAM_NAME, SLA_TRANSACTION_NAME_PARAM_NAME, SLA_TYPE_PARAM_NAME } from "./constant";
import { convertSecToMs } from "../date/convert";
const getSlaParamByName = (slaData, paramName, defaultValue) => slaData && !isEmpty(slaData[paramName]) ? slaData[paramName] : defaultValue;
export const getSlaFromDate = (slaData) => getSlaParamByName(slaData, SLA_FROM_DATE_PARAM_NAME);
export const getSlaFromDateInMs = (slaData) => convertSecToMs(getSlaFromDate(slaData));
export const getSlaToDate = (slaData) => getSlaParamByName(slaData, SLA_TO_DATE_PARAM_NAME);
export const getSlaToDateInMs = (slaData) => convertSecToMs(getSlaToDate(slaData));
export const getSlaRestrictionType = (slaData) => getSlaParamByName(slaData, SLA_RESTRICTION_TYPE_PARAM_NAME);
export const getSlaHostName = (slaData) => getSlaParamByName(slaData, SLA_HOST_NAME_PARAM_NAME);
export const getSlaRequestName = (slaData) => getSlaParamByName(slaData, SLA_REQUEST_NAME_PARAM_NAME);
export const getSlaTransactionFilterType = (slaData) => getSlaParamByName(slaData, SLA_TRANSACTION_FILTER_TYPE_PARAM_NAME);
export const getSlaThreadGroupId = (slaData) => (slaData ? slaData[SLA_THREAD_GROUP_ID_PARAM_NAME] : undefined);
export const getSlaThreadGroupName = (slaData) => getSlaParamByName(slaData, SLA_THREAD_GROUP_NAME_PARAM_NAME);
export const getSlaTransactionId = (slaData) => getSlaParamByName(slaData, SLA_TRANSACTION_ID_PARAM_NAME);
export const getSlaTransactionName = (slaData) => getSlaParamByName(slaData, SLA_TRANSACTION_NAME_PARAM_NAME);
export const getSlaTargetType = (slaData) => getSlaParamByName(slaData, SLA_TARGET_TYPE_PARAM_NAME);
export const getSlaType = (slaData) => getSlaParamByName(slaData, SLA_TYPE_PARAM_NAME);
