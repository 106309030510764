import React from "react";
import { getValueByPath, stopEvent } from "@boomq/utils";
import { Tooltip } from "@boomq/uikit";
import { TEAMS_ACTIONS_EDIT_TEAM_TOOLTIP } from "./intl";
import { FlexTableRowColumn, StyledIconEdit } from "./styled";
import { TeamsItemTemplate } from "../../../containers/account/TeamsItemTemplate";
import { ActionList, ActionListItem } from "../../styled/Table";
import { isExistTeamAdminPermission, isExistTeamManageUsersPermission } from "../../../helpers/teams";
const getTeamRoute = (props) => {
    const pathname = getValueByPath(props, "location.pathname");
    const teamId = getValueByPath(props, "team.id");
    return `${pathname}/${teamId}`;
};
const renderActionEdit = (props) => {
    const userTeamPermissions = getValueByPath(props.team, "userPermissions", []);
    const editTeamHandler = (e) => {
        stopEvent(e);
        return props.history && typeof props.history.push === "function"
            ? props.history.push(getTeamRoute(props))
            : undefined;
    };
    return isExistTeamAdminPermission([], userTeamPermissions) ||
        isExistTeamManageUsersPermission([], userTeamPermissions) ? (React.createElement(Tooltip, { title: TEAMS_ACTIONS_EDIT_TEAM_TOOLTIP },
        React.createElement(ActionListItem, { onClick: editTeamHandler },
            React.createElement(StyledIconEdit, null)))) : (React.createElement(React.Fragment, null));
};
export const AllTeamsItem = (props) => {
    const teamSelectionHandler = (e) => {
        stopEvent(e);
        return typeof props.onSelectTeam === "function" ? props.onSelectTeam(props.team) : undefined;
    };
    return (React.createElement(FlexTableRowColumn, { onClick: teamSelectionHandler },
        React.createElement(TeamsItemTemplate, Object.assign({}, props),
            React.createElement(ActionList, null, renderActionEdit(props)))));
};
