export var FormType;
(function (FormType) {
    FormType["Add"] = "add";
    FormType["Edit"] = "edit";
    FormType["View"] = "view";
    FormType["EditWithoutAdding"] = "EDIT_WITHOUT_ADDING";
})(FormType || (FormType = {}));
export var GlobalParameter;
(function (GlobalParameter) {
    GlobalParameter["Csv"] = "CSV";
    GlobalParameter["Literal"] = "LITERAL";
})(GlobalParameter || (GlobalParameter = {}));
export var ImportFileType;
(function (ImportFileType) {
    ImportFileType["har"] = "HAR";
    ImportFileType["insomnia"] = "INSOMNIA_JSON";
    ImportFileType["postman"] = "POSTMAN_JSON";
})(ImportFileType || (ImportFileType = {}));
export var MailToParamName;
(function (MailToParamName) {
    MailToParamName["bcc"] = "bcc";
    MailToParamName["body"] = "body";
    MailToParamName["cc"] = "cc";
    MailToParamName["subject"] = "subject";
})(MailToParamName || (MailToParamName = {}));
export var Mode;
(function (Mode) {
    Mode["EditResult"] = "EDIT_RESULT";
    Mode["View"] = "VIEW";
})(Mode || (Mode = {}));
export var RequestGroupType;
(function (RequestGroupType) {
    RequestGroupType["Ultimate"] = "ultimate";
    RequestGroupType["Ultimate2"] = "ULTIMATE";
    RequestGroupType["Arrivals"] = "ARRIVALS";
    RequestGroupType["Concurrency"] = "CONCURRENCY";
    RequestGroupType["Ordinary"] = "ORDINARY";
    RequestGroupType["SetUp"] = "SET_UP";
    RequestGroupType["TearDown"] = "TEAR_DOWN";
    RequestGroupType["Stepping"] = "STEPPING";
    RequestGroupType["FreeFormArrivals"] = "FREE_FORM_ARRIVALS";
})(RequestGroupType || (RequestGroupType = {}));
export var RequestTimerParams;
(function (RequestTimerParams) {
    RequestTimerParams["Id"] = "id";
    RequestTimerParams["ConstantValueParam"] = "duration";
    RequestTimerParams["Enabled"] = "enabled";
    RequestTimerParams["isBoomqTimer"] = "isBoomqTimer";
    RequestTimerParams["RandomValueParamMin"] = "lowerLimit";
    RequestTimerParams["RandomValueParamMax"] = "upperLimit";
    RequestTimerParams["RandomTimerType"] = "randomTimerType";
    RequestTimerParams["OtherTimerType"] = "otherTimerType";
    RequestTimerParams["Type"] = "timerType";
})(RequestTimerParams || (RequestTimerParams = {}));
export var RequestTimerType;
(function (RequestTimerType) {
    RequestTimerType["Constant"] = "CONSTANT";
    RequestTimerType["Other"] = "OTHER";
    RequestTimerType["Random"] = "RANDOM";
})(RequestTimerType || (RequestTimerType = {}));
export var RequestRandomTimerType;
(function (RequestRandomTimerType) {
    RequestRandomTimerType["Gaussian"] = "GAUSSIAN_RANDOM_TIMER";
    RequestRandomTimerType["Poisson"] = "POISSON_RANDOM_TIMER";
    RequestRandomTimerType["Uniform"] = "UNIFORM_RANDOM_TIMER";
})(RequestRandomTimerType || (RequestRandomTimerType = {}));
export var RequestOtherTimerType;
(function (RequestOtherTimerType) {
    RequestOtherTimerType["ConstantThroughput"] = "CONSTANT_THROUGHPUT_TIMER";
    RequestOtherTimerType["PreciseThroughput"] = "PRECISE_THROUGHPUT_TIMER";
    RequestOtherTimerType["Synchronizing"] = "SYNCHRONIZING_TIMER";
    RequestOtherTimerType["ThroughputShaping"] = "THROUGHPUT_SHAPING_TIMER";
})(RequestOtherTimerType || (RequestOtherTimerType = {}));
export var SelectionType;
(function (SelectionType) {
    SelectionType["Combine"] = "COMBINE";
    SelectionType["Multiple"] = "MULTIPLE";
    SelectionType["Single"] = "SINGLE";
})(SelectionType || (SelectionType = {}));
export var SlaOperation;
(function (SlaOperation) {
    SlaOperation["GreaterThen"] = "GREATER_THEN";
    SlaOperation["LessEquals"] = "LESS_EQUALS";
})(SlaOperation || (SlaOperation = {}));
export var SlaRestrictionType;
(function (SlaRestrictionType) {
    SlaRestrictionType["Average"] = "AVERAGE";
    SlaRestrictionType["CpuUsagePercentAverage"] = "CPU_USAGE_PERCENT_AVERAGE";
    SlaRestrictionType["ErrorRate"] = "ERROR_RATE";
    SlaRestrictionType["MemoryUsagePercentAverage"] = "MEMORY_USAGE_PERCENT_AVERAGE";
    SlaRestrictionType["Percentile"] = "PERCENTILE_95";
    SlaRestrictionType["Throughput"] = "THROUGHPUT";
})(SlaRestrictionType || (SlaRestrictionType = {}));
export var SlaUnit;
(function (SlaUnit) {
    SlaUnit["AVERAGE"] = "sec";
    SlaUnit["CPU_USAGE_PERCENT_AVERAGE"] = "%";
    SlaUnit["ERROR_RATE"] = "%";
    SlaUnit["MEMORY_USAGE_PERCENT_AVERAGE"] = "%";
    SlaUnit["PERCENTILE_95"] = "sec";
    SlaUnit["THROUGHPUT"] = "rps";
    SlaUnit["THROUGHPUT_TRANSACTION"] = "tps";
})(SlaUnit || (SlaUnit = {}));
export var SlaStatus;
(function (SlaStatus) {
    SlaStatus["Success"] = "SUCCESS";
    SlaStatus["Failure"] = "FAILURE";
    SlaStatus["NotCounted"] = "NOT_COUNTED";
})(SlaStatus || (SlaStatus = {}));
export var SlaTargetType;
(function (SlaTargetType) {
    SlaTargetType["Request"] = "REQUEST";
    SlaTargetType["Test"] = "TEST";
    SlaTargetType["Transaction"] = "TRANSACTION";
})(SlaTargetType || (SlaTargetType = {}));
export var SlaType;
(function (SlaType) {
    SlaType["Request"] = "REQUEST_SLA";
    SlaType["SystemMetricsHost"] = "SYSTEM_METRICS_HOST";
    SlaType["Test"] = "GENERAL_TEST_SLA";
    SlaType["Transaction"] = "TRANSACTION_SLA";
})(SlaType || (SlaType = {}));
export var SlaReportType;
(function (SlaReportType) {
    SlaReportType["Chart"] = "CHART";
    SlaReportType["Table"] = "TABLE";
})(SlaReportType || (SlaReportType = {}));
export var SlaReportTableValueType;
(function (SlaReportTableValueType) {
    SlaReportTableValueType["BaselineDiff"] = "BASELINE_DIFF";
    SlaReportTableValueType["PreviousTestDiff"] = "PREVIOUS_TEST_DIFF";
    SlaReportTableValueType["Value"] = "VALUE";
})(SlaReportTableValueType || (SlaReportTableValueType = {}));
export var SlaReportTableDiffType;
(function (SlaReportTableDiffType) {
    SlaReportTableDiffType["Absolute"] = "ABSOLUTE";
    SlaReportTableDiffType["Relative"] = "RELATIVE";
})(SlaReportTableDiffType || (SlaReportTableDiffType = {}));
export var SlaTransactionFilterType;
(function (SlaTransactionFilterType) {
    SlaTransactionFilterType["AnyTransaction"] = "ANY_TRANSACTION";
    SlaTransactionFilterType["NoTransaction"] = "NO_TRANSACTION";
    SlaTransactionFilterType["Name"] = "BY_NAME";
})(SlaTransactionFilterType || (SlaTransactionFilterType = {}));
export var TestResultFilesState;
(function (TestResultFilesState) {
    TestResultFilesState["Processing"] = "PROCESSING";
    TestResultFilesState["Finished"] = "FINISHED";
    TestResultFilesState["Failed"] = "FAILED";
})(TestResultFilesState || (TestResultFilesState = {}));
export var TestResultSlaState;
(function (TestResultSlaState) {
    TestResultSlaState["Processing"] = "PROCESSING";
    TestResultSlaState["Undefined"] = "UNDEFINED";
    TestResultSlaState["Finished"] = "FINISHED";
    TestResultSlaState["Failed"] = "FAILED";
})(TestResultSlaState || (TestResultSlaState = {}));
export var TestMode;
(function (TestMode) {
    TestMode["Test"] = "TEST";
    TestMode["Debug"] = "DEBUG_TEST";
})(TestMode || (TestMode = {}));
export var TestType;
(function (TestType) {
    TestType["Scalability"] = "SCALABILITY";
    TestType["Stable"] = "STABLE";
    TestType["JMX"] = "OWN_JMX";
})(TestType || (TestType = {}));
export var TestProjectRequestActionType;
(function (TestProjectRequestActionType) {
    TestProjectRequestActionType["Component"] = "COMPONENT";
    TestProjectRequestActionType["Icon"] = "ICON";
})(TestProjectRequestActionType || (TestProjectRequestActionType = {}));
