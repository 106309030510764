import React from "react";
import { RunningTestListItem, StoppingText, TestActionBlock, TestLink, TestText, TestTitle } from "./styled";
import { StopButton } from "../StopButton";
import { isAllowedTestDetailsActionByPermissions } from "../../../../helpers/testDetails";
import { TestDetailsAction } from "../../../../models/testRun";
export const RunningTestsItemTemplate = ({ children, isTestRunStopping, onClickStopButton, permissionList, stopButtonLabel, stoppingText, testDetailsLink, testDetailsLinkLabel, title }) => (React.createElement(RunningTestListItem, null,
    React.createElement(TestText, null,
        React.createElement(TestTitle, null, title),
        React.createElement(TestLink, { to: testDetailsLink }, testDetailsLinkLabel)),
    React.createElement(TestActionBlock, null,
        isTestRunStopping && React.createElement(StoppingText, null, stoppingText),
        children,
        !isTestRunStopping && isAllowedTestDetailsActionByPermissions(permissionList, TestDetailsAction.Stop) && (React.createElement(StopButton, { buttonLabel: stopButtonLabel, onClick: onClickStopButton })))));
