export const COMPARE_TESTS_BUTTON_LABEL = {
    defaultMessage: "Compare test runs",
    id: "account.all.tests.button.compare.label"
};
export const COMPARE_TESTS_BUTTON_TOOLTIP = {
    defaultMessage: "You need to select 2 test runs to compare",
    id: "account.all.tests.button.compare.tooltip"
};
export const TEST_LABEL_SELECT_PLACEHOLDER = {
    defaultMessage: "Label",
    id: "account.all.tests.filters.select.test.label.placeholder"
};
export const TEST_PROJECT_SELECT_PLACEHOLDER = {
    defaultMessage: "Test",
    id: "account.all.tests.filters.select.test.project.placeholder"
};
export const TEST_STATUS_SELECT_PLACEHOLDER = {
    defaultMessage: "Test run status",
    id: "account.all.tests.filters.select.test.status.placeholder"
};
export const TEST_USER_SELECT_PLACEHOLDER = {
    defaultMessage: "User",
    id: "account.all.tests.filters.select.test.user.placeholder"
};
