import styled from "styled-components";
export const Container = styled.div `
    display: flex;
    flex: 1;
    justify-content: center;
`;
export const Header = styled.header `
    align-items: center;
    background: #4989d6;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
    display: flex;
    justify-content: space-between;
    padding: 10px 16px;
    ${Container} {
        &:first-child {
            justify-content: flex-start;
        }
        &:last-child {
            justify-content: flex-end;
        }
    }
`;
export const LinkBack = styled.div `
    cursor: pointer;
`;
