import { handleActions } from "redux-actions";
import { DEFAULT_TEAMS_PAGE_SIZE } from "../../helpers/teams";
import authorizeActions from "../actions/authorize";
import { teamsChangeParamsField, teamsCurrentPage, teamsSuccess } from "../actions/teams";
const { logout } = authorizeActions;
export const initialState = {
    content: [],
    number: 0,
    numberOfElements: 0,
    size: DEFAULT_TEAMS_PAGE_SIZE,
    totalElements: 0,
    totalPages: 0
};
export default handleActions({
    [logout]: () => (Object.assign({}, initialState)),
    [teamsCurrentPage]: (state, action) => (Object.assign(Object.assign({}, state), { number: Number.isInteger(action.payload) ? action.payload : 0 })),
    [teamsChangeParamsField]: (state, action) => {
        const { field, subfield, value } = action && action.payload ? action.payload : {};
        return field && subfield
            ? Object.assign(Object.assign({}, state), { [field]: Object.assign(Object.assign({}, state[field]), { [subfield]: value }) }) : Object.assign(Object.assign({}, state), { [field]: value });
    },
    [teamsSuccess]: (state, action) => (Object.assign(Object.assign({}, state), action.payload))
}, initialState);
const getStateParam = (state, paramName) => (state && state.teams ? state.teams[paramName] : initialState[paramName]);
export const getTeamsCurrentPage = (state) => getStateParam(state, "number");
export const getTeamsCurrentPageElements = (state) => getStateParam(state, "numberOfElements");
export const getPrevPage = (state) => {
    const currentPage = getTeamsCurrentPage(state);
    return currentPage > 0 ? currentPage - 1 : currentPage;
};
export const getTeamItems = (state) => getStateParam(state, "content");
export const getTeamsSize = (state) => getStateParam(state, "size");
export const getTeamsCount = (state) => getStateParam(state, "totalElements");
export const getTeamsPagesCount = (state) => getStateParam(state, "totalPages");
