import { getValueByPath, isEmpty } from "@boomq/utils";
import { EXTRACTOR_JSON_REQUIRED_FIELDS, EXTRACTOR_REGEXP_REQUIRED_FIELDS, EXTRACTOR_REQUIRED_COMMON_FIELDS, EXTRACTOR_XPATH_REQUIRED_FIELDS } from "./constants";
import { INCORRECT_EXTRACTOR_PARAM } from "./intl";
import { parameterNameValidator } from "./parameters";
import { modifyErrorMap } from "./validators";
import { getExpressionParamsValue, getExpressionValue, getExtractorType, getExtractorVariableName } from "../extractResponse";
import { ExtractorType } from "../types";
export const modifyExtractorErrorMapByVariableName = (extractorData) => {
    const variableName = getValueByPath(extractorData, "variable", "");
    const { error } = isEmpty(variableName) ? { error: undefined } : parameterNameValidator(variableName);
    return modifyErrorMap(extractorData, "variable", error);
};
const extractorListItemDataValidatorByParamValue = (extractorData, paramName, paramValue) => isEmpty(paramValue)
    ? {
        isValid: false,
        validatedExtractor: modifyErrorMap(extractorData, paramName, INCORRECT_EXTRACTOR_PARAM)
    }
    : {
        validatedExtractor: modifyErrorMap(extractorData, paramName)
    };
const extractorListItemDataValidatorByParams = (res, extractorData, paramName, paramValue) => {
    const extractorValidatedData = extractorListItemDataValidatorByParamValue(extractorData, paramName, paramValue);
    return Object.assign(Object.assign({}, res), extractorValidatedData);
};
const extractorListItemDataValidatorByVariableName = (extractorData) => {
    const { isValid, error } = parameterNameValidator(getExtractorVariableName(extractorData));
    return {
        isValid,
        validatedExtractor: isValid ? extractorData : modifyErrorMap(extractorData, "variable", error)
    };
};
const extractorListItemCommonValidator = (extractorData) => {
    const { isValid, validatedExtractor } = extractorListItemDataValidatorByVariableName(extractorData);
    return EXTRACTOR_REQUIRED_COMMON_FIELDS.reduce((res, paramName) => extractorListItemDataValidatorByParams(res, extractorData, paramName, getExpressionValue(extractorData, paramName)), { isValid, validatedExtractor });
};
const extractorListItemAdvancedValidator = (extractorData, paramNames) => {
    const { isValid, validatedExtractor } = extractorListItemCommonValidator(extractorData);
    return (paramNames || []).reduce((res, paramName) => extractorListItemDataValidatorByParams(res, validatedExtractor, paramName, getExpressionParamsValue(extractorData, paramName)), {
        isValid,
        validatedExtractor
    });
};
const extractorListItemJsonPathValidator = (extractor) => extractorListItemAdvancedValidator(extractor, EXTRACTOR_JSON_REQUIRED_FIELDS);
const extractorListItemRegExpValidator = (extractor) => extractorListItemAdvancedValidator(extractor, EXTRACTOR_REGEXP_REQUIRED_FIELDS);
const extractorListItemXPathValidator = (extractor) => extractorListItemAdvancedValidator(extractor, EXTRACTOR_XPATH_REQUIRED_FIELDS);
const extractorListItemValidateFuncByType = {
    [ExtractorType.RegEx]: extractorListItemRegExpValidator,
    [ExtractorType.JsonPath]: extractorListItemJsonPathValidator,
    [ExtractorType.XPath]: extractorListItemXPathValidator,
    [ExtractorType.XPath2]: extractorListItemXPathValidator,
    [ExtractorType.Boundary]: extractorListItemCommonValidator
};
const extractorListItemValidatorByType = (extractor) => {
    const extractorType = getExtractorType(extractor);
    return Object.keys(extractorListItemValidateFuncByType).includes(extractorType)
        ? extractorListItemValidateFuncByType[extractorType](extractor)
        : {
            isValid: true,
            validatedExtractor: extractor
        };
};
const extractorListItemValidator = (res, extractor) => {
    const { isValid, validatedExtractor } = extractorListItemValidatorByType(extractor);
    return isValid
        ? Object.assign(Object.assign({}, res), { validatedExtractorList: [...res.validatedExtractorList, validatedExtractor] }) : Object.assign(Object.assign({}, res), { isValid, validatedExtractorList: [...res.validatedExtractorList, validatedExtractor] });
};
export const extractorListValidator = (extractorList) => {
    const { isValid, validatedExtractorList } = (extractorList || []).reduce(extractorListItemValidator, {
        isValid: true,
        validatedExtractorList: []
    });
    return {
        isValid,
        validatedExtractorList
    };
};
