var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { Handle, Range } from "rc-slider";
import "rc-slider/assets/index.css";
import { getValueByPath, useIntl } from "@boomq/utils";
import { InputNumber } from "@boomq/uikit";
import { TIMELINE_END_MESSAGE, TIMELINE_RANGE_SLIDER_LABEL, TIMELINE_START_MESSAGE } from "./intl";
import { HandleValue, SlaItemTimelineSection } from "./styled";
import { convertSecToMs, getFormattedTimeByLocale } from "../../../helpers/date";
export const SlaTimelineSlider = (props) => {
    const { formatMessage } = useIntl();
    const { disabled, data, max, min = 0, onChange, testDuration } = props;
    const value = [
        Math.floor(getValueByPath(data, "fromDate", 0)),
        Math.ceil(getValueByPath(data, "toDate", testDuration))
    ];
    const getValue = (value) => {
        let newValue = [min, max];
        value[0] > newValue[0] && value[0] < newValue[1] && (newValue[0] = value[0]);
        value[1] > newValue[0] && value[1] < newValue[1] && (newValue[1] = value[1]);
        return value[0] === value[1] ? [min, max] : newValue;
    };
    const changeHandler = (value) => (typeof onChange === "function" ? onChange(getValue(value)) : undefined);
    const changeStartValueHandler = (startValue) => changeHandler([startValue, value[1]]);
    const changeEndValueHandler = (endValue) => changeHandler([value[0], endValue]);
    const messageStart = formatMessage(TIMELINE_START_MESSAGE);
    const messageEnd = formatMessage(TIMELINE_END_MESSAGE);
    const getHandleLabel = (value) => {
        const labelValue = value < min ? min : value;
        return labelValue > max
            ? getFormattedTimeByLocale(convertSecToMs(max), formatMessage)
            : getFormattedTimeByLocale(convertSecToMs(labelValue), formatMessage);
    };
    const CustomHandle = (props) => {
        const { index, dragging } = props, restProps = __rest(props, ["index", "dragging"]);
        return (React.createElement(Handle, Object.assign({ dragging: dragging.toString(), key: index }, restProps),
            React.createElement(HandleValue, null, getHandleLabel(props.value))));
    };
    return (React.createElement(SlaItemTimelineSection, null,
        formatMessage(TIMELINE_RANGE_SLIDER_LABEL),
        React.createElement(Range, { allowCross: false, handle: CustomHandle, marks: { [min]: `${min}`, [max]: `${max}` }, max: max, min: min, name: "timeLine", onChange: changeHandler, step: 1, pushable: 1, value: value }),
        React.createElement(InputNumber, { decimalCount: -1, enabled: !disabled, isPositive: true, label: messageStart, onBlur: changeStartValueHandler, placeholder: messageStart, value: value[0] }),
        React.createElement(InputNumber, { decimalCount: -1, enabled: !disabled, isPositive: true, label: messageEnd, onBlur: changeEndValueHandler, placeholder: messageEnd, value: value[1] })));
};
