import styled from "styled-components";
import { Color } from "../styled/colorPallete";
import { withDisable } from "../styled/global";
import { FontBodySmallMedium } from "../styled/typography";
import { IconDropDownArrowLeft, IconDropDownArrowRight } from "../Icons";
export const Button = styled.div `
    align-items: center;
    display: flex;
    ${withDisable}
`;
export const StyledIconDropDownArrowLeft = styled(IconDropDownArrowLeft) `
    fill: ${Color.grey65};
`;
export const StyledIconDropDownArrowRight = styled(IconDropDownArrowRight) `
    fill: ${Color.grey65};
`;
export const StyledPagination = styled.div `
    width: 100%;
    flex: 0 1 auto;
    display: flex;
    justify-content: flex-end;
    .rc-pagination {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        &:after {
            display: none;
        }
        &-jump {
            &-next:after,
            &-prev:after {
                display: none;
            }
        }
        &-item {
            ${FontBodySmallMedium}
            background-color: transparent;
            color: ${Color.grey65};
            display: flex;
        }
        &-item-active {
            background-color: ${Color.white};
            box-shadow: 0px 0px 10px rgba(162, 170, 180, 0.25);
            color: ${Color.blue};
        }
        & > li {
            border: none;
            display: flex;
            height: auto;
            justify-content: center;
            line-height: normal;
            min-width: 24px;
            width: 24px;
        }
    }
`;
