import { handleActions } from "redux-actions";
import authorizeActions from "../actions/authorize";
import clientActions from "../actions/client";
import { refreshTestsLabelsSuccess, refreshTestsUsersSuccess } from "../actions/tests";
const { logout } = authorizeActions;
const { addClientTask, changeClientTasksFiltersRequest, clientTasksSuccess, removeClientTask, selectClientTasks, setCurrentPage, updateClientTask } = clientActions;
const DEFAULT_SIZE = 7;
export const initialState = {
    compare: [],
    content: [],
    currentPage: 0,
    filters: {},
    labelList: [],
    size: DEFAULT_SIZE,
    totalElements: 0,
    totalPages: 0,
    userList: []
};
const prepareStateData = (state, payload) => (Object.assign(Object.assign({}, state), { content: Array.isArray(payload.content) ? payload.content : state.content, currentPage: Number.isInteger(payload.number) ? payload.number : state.currentPage, totalElements: Number.isInteger(payload.totalElements) ? payload.totalElements : state.totalElements, totalPages: Number.isInteger(payload.totalPages) ? payload.totalPages : state.totalPages }));
const prepareFilterData = ({ payload }) => (payload ? { [payload.filterName]: payload.filterData } : {});
export default handleActions({
    [logout]: () => (Object.assign({}, initialState)),
    [changeClientTasksFiltersRequest]: (state, action) => (Object.assign(Object.assign({}, state), { filters: Object.assign(Object.assign({}, state.filters), prepareFilterData(action)) })),
    [clientTasksSuccess]: (state, action) => action && action.payload ? prepareStateData(state, action.payload) : state,
    [setCurrentPage]: (state, action) => action && Number.isInteger(action.payload) ? Object.assign(Object.assign({}, state), { currentPage: action.payload }) : state,
    [addClientTask]: (state, action) => (Object.assign(Object.assign({}, state), { content: [...state.content, action.payload] })),
    [updateClientTask]: (state, action) => (Object.assign(Object.assign({}, state), { content: action.payload
            ? state.content.map((task) => (task.id === action.payload.id ? Object.assign(Object.assign({}, task), action.payload) : task))
            : state.content })),
    [refreshTestsLabelsSuccess]: (state, action) => (Object.assign(Object.assign({}, state), { labelList: Array.isArray(action.payload) ? action.payload : state.labelList })),
    [refreshTestsUsersSuccess]: (state, action) => (Object.assign(Object.assign({}, state), { userList: Array.isArray(action.payload) ? action.payload : state.userList })),
    [removeClientTask]: (state, action) => (Object.assign(Object.assign({}, state), { content: action.payload ? state.content.filter((task) => task.id !== action.payload.id) : state.content })),
    [selectClientTasks]: (state, action) => (Object.assign(Object.assign({}, state), { compare: action.payload ? action.payload : initialState.compare }))
}, initialState);
const getStateParam = (state, paramName) => state && state.clientTasks ? state.clientTasks[paramName] : initialState[paramName];
export const getClientTasksCurrentPage = (state) => getStateParam(state, "currentPage");
export const getClientTasksFromStore = (state) => getStateParam(state, "content");
export const getClientTasksCount = (state) => getStateParam(state, "totalElements");
export const getClientTasksFilters = (state) => getStateParam(state, "filters");
export const getClientTasksLabelList = (state) => getStateParam(state, "labelList");
export const getClientTasksPageSize = (state) => getStateParam(state, "size");
export const getClientTasksPagesCount = (state) => getStateParam(state, "totalPages");
export const getClientTasksUserList = (state) => getStateParam(state, "userList");
export const getSelectedClientTests = (state) => getStateParam(state, "compare");
export const getSelectedClientTestIds = (state) => (state.clientTasks.compare || []).reduce((res, test) => (test.id > -1 ? [...res, test.id] : res), []);
