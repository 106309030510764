import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { useIntl } from "@boomq/utils";
import { RESPONSE_PREVIEW_TITLE_MESSAGE, RESPONSE_PREVIEW_MESSAGE, RESPONSE_BODY_PREVIEW_MODE_LABEL_MESSAGE } from "../../../../components/account/TestProject/RequestResponsePreview";
import { ExtractResponseParameters } from "../../../../components/account/TestProject/ExtractResponseParameters";
import { withResize } from "../../../../components/styled/response";
import { getExtractorFieldToCheckItems, getExtractorMatchTypeItems, getNewExtractorObj } from "../../../../helpers/extractResponse";
import { formatIntlMessage } from "../../../../helpers/intl";
import { getResponsePreviewSourceItems } from "../../../../models/extractFromResponse";
import { isEmpty } from "../../../../helpers/utils";
import { newRequestChangeExtractorField, newRequestChangeExtractorsResponsePreviewSource, newRequestChangeParamsField, newRequestDeleteFieldParam } from "../../../../redux/actions/newRequest";
import { getNewRequestExtractResponse, getNewRequestIsShowResponseInExtractors, getNewRequestResponsePreviewData, getNewRequestResponsePreviewSourceInExtractFromResponse } from "../../../../redux/reducers/newRequest";
import { getUsedExtractRequestParameterNames } from "../../../../redux/reducers/newTest";
const getExtractResponse = (state) => {
    const extractResponse = [...getNewRequestExtractResponse(state)];
    return extractResponse.filter((expression) => isEmpty(expression.variable)).length !== 0
        ? extractResponse
        : [...extractResponse, getNewExtractorObj()];
};
const connector = connect((state) => ({
    expressions: getExtractResponse(state),
    fieldToCheckItems: getExtractorFieldToCheckItems(),
    isShowResponse: getNewRequestIsShowResponseInExtractors(state),
    matchItems: getExtractorMatchTypeItems(),
    responsePreviewData: getNewRequestResponsePreviewData(state),
    responsePreviewSource: getNewRequestResponsePreviewSourceInExtractFromResponse(state),
    usedRequestParameterNames: getUsedExtractRequestParameterNames(state)
}), {
    newRequestChangeExtractorField,
    newRequestDeleteFieldParam,
    onChangeRequestFieldParam: newRequestChangeParamsField,
    onChangeRequestResponseSource: newRequestChangeExtractorsResponsePreviewSource
});
const responsePreviewSourceItems = getResponsePreviewSourceItems();
const ExtractResponseParametersContainer = (props) => {
    const { formatMessage } = useIntl();
    const changeResponsePreviewSourceHandler = ({ value }) => props.onChangeRequestResponseSource(value);
    const formattedResponsePreviewSourceItems = responsePreviewSourceItems.map((responsePreviewSourceItem) => (Object.assign(Object.assign({}, responsePreviewSourceItem), { text: formatIntlMessage(responsePreviewSourceItem.text, formatMessage) })));
    const responsePreviewText = formatIntlMessage(RESPONSE_PREVIEW_MESSAGE, formatMessage);
    const responsePreviewTitle = formatIntlMessage(RESPONSE_PREVIEW_TITLE_MESSAGE, formatMessage);
    const responseBodyPreviewModeLabel = formatIntlMessage(RESPONSE_BODY_PREVIEW_MODE_LABEL_MESSAGE, formatMessage);
    const toggleResponseShowingHandler = () => props.onChangeRequestFieldParam({ field: "isShowResponseInExtractors", value: !props.isShowResponse });
    return (React.createElement(ExtractResponseParameters, Object.assign({}, props, { onChangeResponsePreviewSource: changeResponsePreviewSourceHandler, onToggleResponseShowing: toggleResponseShowingHandler, responsePreviewSourceItems: formattedResponsePreviewSourceItems, responseBodyPreviewModeLabel: responseBodyPreviewModeLabel, responsePreviewText: responsePreviewText, responsePreviewTitle: responsePreviewTitle })));
};
export default withRouter(connector(withResize(ExtractResponseParametersContainer)));
