export const DEFAULT_TIMER_LABEL = {
    defaultMessage: "Default",
    id: "account.new.test.project.group.requests.default.timer.label"
};
export const REQUESTS_TIMER_OFF = { defaultMessage: " - off", id: "account.new.test.project.group.requests.timer.off" };
export const TIMER_LABEL = {
    defaultMessage: "Timer",
    id: "account.new.test.project.group.requests.timer.label"
};
export const TIMER_NONE_LABEL = {
    defaultMessage: "{label}: none {isTimerOn}",
    id: "account.new.test.project.group.requests.timer.none"
};
