import { select, call, put, all, takeEvery } from "redux-saga/effects";
import { push } from "connected-react-router";
import { getCurrentPathName, getFromLocalStorage, getMaxInArray, getValueByPath, getUniqueId, removeFromLocalStorage, setToLocalStorage } from "@boomq/utils";
import { chartPreviewGenerator } from "./testProjectChart";
import { changeRequestGroupsByAutoPercentages } from "./testProjectPercentDistribution";
import RestoreSettingsForm from "../../components/account/newTest/RestoreSettingsForm";
import { getResponseErrorData } from "../../helpers/errors";
import { generatePreviewData, generateTestProjectJMX, generateTestProjectRequests } from "../../helpers/generators";
import { addLabelToCurrentLabelSet, createLabel, formatLabelsForDto, getLabelByName, prepareLabels } from "../../helpers/labels";
import { getAllTestsRoute } from "../../helpers/routes";
import { getTestProjectDataFromLocalStorage, removeTestProjectDataFromLocalStorage, setTestProjectDataToLocalStorage } from "../../helpers/testProject";
import { formatThreadGroup, formatController, generateTransactionName } from "../../helpers/threadGroup";
import { TestMode, TestType } from "../../helpers/types";
import { generateDefaultTestProjectName, initialTestProjectSettings, initUsersDistributionSettings, ProfileDistribution } from "../../models/testProject";
import { ControllerType, ThreadGroupController } from "../../models/threadGroupEditor";
import { prepareVersionList } from "./testProjects";
import authorizeActions from "../actions/authorize";
import newTestActions from "../actions/newTest";
import { getSiteModal } from "../actions/siteModal";
import { testProjectDetailsSuccess, testProjectFailure } from "../actions/testProjects";
import { getActiveAction, getActiveUrl, getClientInfo, getCurrentDateFormatLocale } from "../reducers/authorizePlate";
import { getAllLabels, getBoomqStableScalabilityCalculatedTestDuration, getDefaultCookiesData, getDefaultHeaders, getDefaultTimerSettings, getGrpcBuffer, getHttpRequestDefaults, getIsLabelsFromProjectCopying, getIsModified, getLoadProfileParamsByTestType, getLoadProfiles, getMaxTransactionIndex, getProjectId, getProjectLabels, getProjectName, getProjectVersion, getRequests, getRequestGroups, getRequestParametersData, getRequestTimers, getSlaData, getTestLabels, getTestProjectSettings, getTestProjectSettingsField, getTestType, getThreadGroupTreeById, getThreadGroupsTree, getTransactions, getThreadGroupsElements, getProfileDistribution } from "../reducers/newTest";
import { getTestProjectModelSchema } from "../reducers/testProjectModelSchema";
import { addTestProject, updateTestProject } from "../../services/projectSrv";
import { createAndStartTest } from "../../services/testSrv";
import { getTestDuration } from "../../services/testPlanSrv";
const { setActiveAction } = authorizeActions;
const { addTestLabel, addThreadGroup, changeRequests, changeRequestGroups, clearNewTestSettings, copyRequestInGroup, copyRequestGroup, copyThreadGroup, deleteRequestGroup, deleteTestLabel, getTestSettingsLocalStorage, newTestNewProjectName, onUnmountNewTest, runTestRequest, saveTestProjectRequest, saveTestProjectSuccess, saveTestProjectFailure, saveTestSettingsLocalStorage, selectProfile, setStep, setTestProjectElementsData, startTestRequest, startTestSuccess, startTestFailure, testProjectChangeParamsData, testProjectChangeParamsField, testProjectChangeLoadProfileParams, toggleActiveGroups, toggleEnabledGroup, toggleLabelsFromProjectCopying } = newTestActions;
function* addTestLabelFlow(action) {
    const name = action && action.payload ? action.payload : {};
    const labelList = yield select(getAllLabels);
    const testLabels = yield select(getTestLabels);
    const existedLabel = getLabelByName(labelList, name);
    const newLabel = existedLabel ? existedLabel : createLabel(name, labelList.length);
    return existedLabel
        ? undefined
        : yield put(testProjectChangeParamsField({
            field: "testLabels",
            value: prepareLabels(addLabelToCurrentLabelSet(testLabels, newLabel))
        }));
}
function* generateBoomqStableScalabilityData() {
    const testType = yield select(getTestType);
    const testDuration = yield select(getBoomqStableScalabilityCalculatedTestDuration, testType);
    yield put(testProjectChangeParamsField({ field: "totalDuration", value: testDuration }));
}
function* copyRequestInGroupFlow({ payload: { id } }) {
    const requests = yield select(getRequests);
    const copiedRequest = requests.find((request) => request.id === id);
    yield put(changeRequests([...requests, Object.assign(Object.assign({}, copiedRequest), { id: getUniqueId() })]));
}
export function* changeRequestGroupsByProfileDistribution(requestGroups) {
    const profileDistribution = yield select(getProfileDistribution);
    return profileDistribution === ProfileDistribution.Percent
        ? yield* changeRequestGroupsByAutoPercentages(requestGroups)
        : yield put(changeRequestGroups(requestGroups));
}
export function* addThreadGroupFlow() {
    try {
        const [requestGroups, threadGroupsTree, transactions, elements, maxTransactionIndex] = yield all([
            select(getRequestGroups),
            select(getThreadGroupsTree),
            select(getTransactions),
            select(getThreadGroupsElements),
            select(getMaxTransactionIndex)
        ]);
        const groupId = getUniqueId();
        const threadGroupNextIndex = getMaxInArray(requestGroups, "index", 0) + 1;
        const newThreadGroup = Object.assign(Object.assign({}, formatThreadGroup({
            id: groupId,
            index: threadGroupNextIndex,
            name: `New group ${threadGroupNextIndex}`
        })), { chartData: [
                { coords: generatePreviewData(Object.assign(Object.assign({}, initUsersDistributionSettings), { loadType: TestType.Scalability })) }
            ], loadProfileSettings: initUsersDistributionSettings, perc: 0 });
        const transactionId = getUniqueId();
        const newTransaction = formatController({
            groupId,
            id: transactionId,
            index: maxTransactionIndex + 1,
            name: generateTransactionName(maxTransactionIndex + 1),
            path: "0"
        });
        yield* changeRequestGroupsByProfileDistribution([...requestGroups, newThreadGroup]);
        yield put(testProjectChangeParamsData({
            maxTransactionIndex: maxTransactionIndex + 1,
            threadGroupsTree: Object.assign(Object.assign({}, threadGroupsTree), { [groupId]: [
                    {
                        children: [],
                        controllerType: ControllerType.Transaction,
                        id: transactionId,
                        type: ThreadGroupController.Controller
                    }
                ] }),
            transactions: [...transactions, newTransaction]
        }));
        yield put(setTestProjectElementsData(Object.assign(Object.assign({}, elements), { [transactionId]: newTransaction })));
        return newThreadGroup;
    }
    catch (e) {
        console.error(e);
    }
}
const replaceIdsInThreadGroupTree = (threadGroupTree, idsForReplace) => threadGroupTree.map((threadGroup) => threadGroup.children
    ? Object.assign(Object.assign({}, threadGroup), { id: idsForReplace[threadGroup.id], children: replaceIdsInThreadGroupTree(threadGroup.children, idsForReplace) }) : Object.assign(Object.assign({}, threadGroup), { id: idsForReplace[threadGroup.id] }));
const replaceTransactionIdsInElements = (elements, idsForReplace) => Object.keys(elements).reduce((res, elementId) => (Object.assign(Object.assign({}, res), { [elementId]: elements[elementId].type === ThreadGroupController.Sampler
        ? Object.assign(Object.assign({}, elements[elementId]), { transactionId: idsForReplace[elements[elementId].transactionId] }) : elements[elementId] })), {});
function* copyThreadGroupFlow({ payload: { id } }) {
    try {
        const [requestGroups, threadGroupsTree, transactions, elements, threadGroupTree, timers] = yield all([
            select(getRequestGroups),
            select(getThreadGroupsTree),
            select(getTransactions),
            select(getThreadGroupsElements),
            select(getThreadGroupTreeById, id),
            select(getRequestTimers)
        ]);
        const groupId = getUniqueId();
        const copiedThreadGroup = requestGroups.find((group) => group.id === id);
        yield* changeRequestGroupsByProfileDistribution([
            ...requestGroups,
            Object.assign(Object.assign({}, copiedThreadGroup), { id: groupId, name: `copy ${copiedThreadGroup.name}` })
        ]);
        const { copiedElements, copiedTimers, idsForReplace } = Object.values(elements)
            .filter((element) => element.groupId === id)
            .reduce((res, element) => {
            const elementId = getUniqueId();
            const { copiedElTimerList, copiedElTimers } = (element.timerList || []).reduce((res, timerId) => {
                const timerNewId = getUniqueId();
                return {
                    copiedElTimerList: [...res.copiedElTimerList, timerNewId],
                    copiedElTimers: Object.assign(Object.assign({}, res.copiedElTimers), { [timerNewId]: Object.assign(Object.assign({}, timers[timerId]), { id: timerNewId }) })
                };
            }, { copiedElTimerList: [], copiedElTimers: {} });
            return {
                copiedElements: Object.assign(Object.assign({}, res.copiedElements), { [elementId]: Object.assign(Object.assign({}, element), { id: elementId, groupId, timerList: copiedElTimerList }) }),
                idsForReplace: Object.assign(Object.assign({}, res.idsForReplace), { [element.id]: elementId }),
                copiedTimers: Object.assign(Object.assign({}, res.copiedTimers), copiedElTimers)
            };
        }, {
            copiedElements: {},
            copiedTimers: {},
            idsForReplace: {}
        });
        yield put(setTestProjectElementsData(Object.assign(Object.assign({}, elements), replaceTransactionIdsInElements(copiedElements, idsForReplace))));
        yield put(testProjectChangeParamsField({
            field: "timers",
            value: Object.assign(Object.assign({}, timers), copiedTimers)
        }));
        yield put(testProjectChangeParamsField({
            field: "threadGroupsTree",
            value: Object.assign(Object.assign({}, threadGroupsTree), { [groupId]: replaceIdsInThreadGroupTree(threadGroupTree, idsForReplace) })
        }));
    }
    catch (e) {
        console.error(e);
    }
}
function* copyRequestGroupFlow({ payload: { id } }) {
    const groupId = getUniqueId();
    const [requestGroups, requests, threadGroupsTree, threadGroupTree] = yield all([
        select(getRequestGroups),
        select(getRequests),
        select(getThreadGroupsTree),
        select(getThreadGroupTreeById, id)
    ]);
    const copiedRequestGroup = requestGroups.find((group) => group.id === id);
    const copiedRequests = requests
        .filter((request) => request.groupId === id)
        .reduce((res, request) => [
        ...res,
        Object.assign(Object.assign({}, request), { id: getUniqueId(), groupId })
    ], []);
    yield* changeRequestGroupsByProfileDistribution([
        ...requestGroups,
        Object.assign(Object.assign({}, copiedRequestGroup), { id: groupId, name: `copy ${copiedRequestGroup.name}` })
    ]);
    yield put(changeRequests([...requests, ...copiedRequests]));
    yield put(testProjectChangeParamsField({
        field: "threadGroupsTree",
        value: Object.assign(Object.assign({}, threadGroupsTree), { [groupId]: threadGroupTree })
    }));
}
function* testRunningFlow() {
    try {
        const currentSerializedData = yield select(getTestProjectSettingsField, "serializedData");
        const serializedData = yield* serializeTestProjectData();
        let projectId = yield select(getProjectId);
        (currentSerializedData !== serializedData || !projectId) && (yield* saveTestProjectFlow());
        projectId = yield select(getProjectId);
        const versionId = yield select(getProjectVersion);
        projectId && (yield* startTestFlow({ payload: { projectId, versionId } }));
    }
    catch (e) {
        const response = e && e.response ? e.response : {};
        const data = response.data || {};
        yield put(saveTestProjectFailure({ code: data.errorCode, message: data.message, status: response.status }));
    }
}
function* addProject(testProjectParams, currentVersions) {
    const { data: { id, version } } = yield call(addTestProject, testProjectParams);
    return {
        currentVersion: getMaxInArray(version, "id"),
        projectId: id,
        versions: prepareVersionList(version)
    };
}
function* updateProject(projectId, testProjectParams, currentVersions) {
    const { data } = yield call(updateTestProject, { testProjectParams, id: projectId });
    return {
        currentVersion: data ? data.id : "",
        projectId,
        versions: [...currentVersions, ...prepareVersionList([data])]
    };
}
export function* saveTestProjectFlow(action) {
    try {
        const { postAction, testType } = action && action.payload ? action.payload : {};
        const { actionType, payload } = postAction && postAction.success ? postAction.success : {};
        const testProjectParams = yield* prepareTestProjectParamsWitTotalDuration({ testType });
        const testProjectId = yield select(getProjectId);
        const currentVersions = yield select(getTestProjectSettingsField, "versions");
        const { projectId, currentVersion, versions } = testProjectId
            ? yield* updateProject(testProjectId, testProjectParams, currentVersions)
            : yield* addProject(testProjectParams, currentVersions);
        const serializedData = yield* serializeTestProjectData();
        yield put(saveTestProjectSuccess({ projectId, currentVersion, serializedData, versions }));
        actionType && (yield put({ type: actionType, payload }));
    }
    catch (e) {
        const response = e && e.response ? e.response : {};
        const data = response.data || {};
        yield put(saveTestProjectFailure({ code: data.errorCode, message: data.message, status: response.status }));
    }
}
function* deleteRequestGroupFlow({ payload: { id } }) {
    const [elements, requestGroups, requests, threadGroupsTree] = yield all([
        select(getThreadGroupsElements),
        select(getRequestGroups),
        select(getRequests),
        select(getThreadGroupsTree)
    ]);
    const elementsData = Object.keys(elements).reduce((res, elementId) => getValueByPath(elements[elementId], "groupId") === id ? res : Object.assign(Object.assign({}, res), { [elementId]: elements[elementId] }), {});
    const newRequestGroups = requestGroups.filter((group) => group.id !== id);
    const newRequests = requests.filter((request) => request.groupId !== id);
    const newThreadGroupsTree = Object.keys(threadGroupsTree)
        .filter((threadGroupId) => threadGroupId !== id)
        .reduce((res, threadGroupId) => (Object.assign(Object.assign({}, res), { [threadGroupId]: threadGroupsTree[threadGroupId] })), {});
    yield put(changeRequests(newRequests));
    yield put(testProjectChangeParamsField({
        field: "threadGroupsTree",
        value: newThreadGroupsTree
    }));
    yield put(setTestProjectElementsData(elementsData));
    yield* changeRequestGroupsByProfileDistribution(newRequestGroups);
}
function* deleteTestLabelFlow(action) {
    try {
        const { id } = action && action.payload ? action.payload : {};
        const projectLabels = yield select(getProjectLabels);
        const testLabels = yield select(getTestLabels);
        return projectLabels.some((label) => label.id === id)
            ? yield put(testProjectChangeParamsField({
                field: "projectLabels",
                value: projectLabels.filter((label) => label.id !== id)
            }))
            : yield put(testProjectChangeParamsField({
                field: "testLabels",
                value: testLabels.filter((label) => label.id !== id)
            }));
    }
    catch (e) {
        console.error(e);
    }
}
function* redirectToTestRuns() {
    return getCurrentPathName() !== getAllTestsRoute() ? yield put(push(getAllTestsRoute())) : undefined;
}
function* startTestFlow(action) {
    try {
        const { projectId, versionId } = action && action.payload ? action.payload : {};
        const isDebugMode = yield select(getTestProjectSettingsField, "isDebugMode");
        const testComment = yield select(getTestProjectSettingsField, "testComment");
        const labels = yield select(getAllLabels);
        const response = yield call(createAndStartTest, {
            comment: testComment,
            labelSet: formatLabelsForDto(labels),
            projectId,
            projectVersionId: versionId,
            testMode: isDebugMode ? TestMode.Debug : TestMode.Test,
            testProjectId: projectId,
            testProjectVersionId: versionId
        });
        const { id } = response && response.data ? response.data : {};
        yield put(startTestSuccess({ id }));
        yield* redirectToTestRuns();
    }
    catch (e) {
        const response = e && e.response ? e.response : {};
        const data = response.data || {};
        yield put(startTestFailure({
            code: data.errorCode,
            description: data.description,
            message: data.exceptionMessage,
            status: response.status
        }));
    }
}
export function* prepareTestProjectParams(params) {
    const [{ loadType, comment, contentModelVersion }, defaultCookies, defaultHeaders, defaultTimer, grpcBuffer, elementsData, treeData, groups, JMXProfiles, projectName, requests, requestParameters, slaData, timers, testType, httpRequestDefaults, profileDistribution] = yield all([
        select(getTestProjectSettings),
        select(getDefaultCookiesData),
        select(getDefaultHeaders),
        select(getDefaultTimerSettings),
        select(getGrpcBuffer),
        select(getThreadGroupsElements),
        select(getThreadGroupsTree),
        select(getRequestGroups),
        select(getLoadProfiles),
        select(getProjectName),
        select(getRequests),
        select(getRequestParametersData),
        select(getSlaData),
        select(getRequestTimers),
        select(getTestType),
        select(getHttpRequestDefaults),
        select(getProfileDistribution)
    ]);
    const baseJmxFileLocation = yield select(getTestProjectSettingsField, "baseJmxFileLocation");
    const isRunThreadGroupsConsecutively = yield select(getTestProjectSettingsField, "isRunThreadGroupsConsecutively");
    const labelSet = yield select(getTestProjectSettingsField, "labelSet");
    const runnerLocations = yield select(getTestProjectSettingsField, "runnerLocations");
    const runnerResources = yield select(getTestProjectSettingsField, "runnerResources");
    const sourceJmxThreadGroups = yield select(getTestProjectSettingsField, "sourceJmxThreadGroups");
    const JMXTestDurationSec = yield select(getTestProjectSettingsField, "JMXTestDurationSec");
    const JMXTimersSettings = yield select(getTestProjectSettingsField, "JMXTimersSettings");
    const settingsId = yield select(getTestProjectSettingsField, "settingsId");
    const testProjectSettings = yield select(getTestProjectSettingsField, loadType.name);
    const testProjectModelSchema = yield select(getTestProjectModelSchema);
    const testProjectTestType = params && params.testType ? params.testType : testType;
    const testProjectCommonParams = {
        comment,
        contentModelVersion,
        defaultCookies,
        defaultHeaders,
        elementsData,
        groups,
        grpcBuffer,
        httpRequestDefaults,
        labelSet,
        profileDistribution,
        projectName: params && params.projectName ? params.projectName : projectName,
        requests,
        requestParameters,
        runnerLocations,
        runnerResources,
        settingsId,
        sla: slaData,
        testType: testProjectTestType,
        timers,
        treeData
    };
    return testProjectTestType === TestType.JMX
        ? generateTestProjectJMX(Object.assign(Object.assign({}, testProjectCommonParams), { baseJmxFileLocation,
            isRunThreadGroupsConsecutively,
            JMXProfiles,
            sourceJmxThreadGroups,
            JMXTestDurationSec,
            JMXTimersSettings }))
        : generateTestProjectRequests(Object.assign(Object.assign(Object.assign({}, testProjectCommonParams), testProjectSettings), { defaultTimer, loadType: loadType.name }), testProjectModelSchema);
}
function* prepareTestProjectParamsWitTotalDuration() {
    const testProjectParams = yield* prepareTestProjectParams();
    try {
        const isDebugMode = yield select(getTestProjectSettingsField, "isDebugMode");
        const testMode = isDebugMode ? TestMode.Debug : TestMode.Test;
        const { data } = yield call(getTestDuration, testMode, testProjectParams);
        return data ? Object.assign(Object.assign({}, testProjectParams), { totalDuration: data.totalDuration }) : testProjectParams;
    }
    catch (e) {
        return testProjectParams;
    }
}
function* setTestProjectDataFromLocalStorageBySaveTypeAuth(data) {
    yield put(testProjectDetailsSuccess(Object.assign(Object.assign({}, data), { isModified: true })));
}
function* setTestProjectDataFromLocalStorageBySaveTypeLocalTemp(data) {
    const clientInfo = yield select(getClientInfo);
    return data.saveType === "localTemp" && data.userData === !!clientInfo.id
        ? yield put(getSiteModal({
            component: {
                element: RestoreSettingsForm,
                props: { data }
            }
        }))
        : undefined;
}
function* setTestProjectDataFromLocalStorageBySaveType(data) {
    removeTestProjectDataFromLocalStorage();
    return data.saveType === "auth"
        ? yield* setTestProjectDataFromLocalStorageBySaveTypeAuth(data)
        : yield* setTestProjectDataFromLocalStorageBySaveTypeLocalTemp(data);
}
function* setActiveActionFromLocalStorage(activeActionName) {
    yield call(removeFromLocalStorage, "activeAction");
    yield put(setActiveAction(activeActionName));
}
function* checkActiveActionFromLocalStorage() {
    try {
        const activeActionLocalStorage = JSON.parse(getFromLocalStorage, "activeAction");
        return activeActionLocalStorage ? yield* setActiveActionFromLocalStorage(activeActionLocalStorage) : undefined;
    }
    catch (e) {
        return;
    }
}
export function* getTestSettingsFlow() {
    try {
        const testProjectDataFromLocalStorage = getTestProjectDataFromLocalStorage();
        yield* checkActiveActionFromLocalStorage();
        return testProjectDataFromLocalStorage
            ? yield* setTestProjectDataFromLocalStorageBySaveType(testProjectDataFromLocalStorage)
            : undefined;
    }
    catch (e) {
        console.error(e);
    }
}
function* saveTestSettingsFlow({ payload: { saveType } }) {
    try {
        const [newTestSettings, activeAction, activeUrl, clientInfo] = yield all([
            select(getTestProjectSettings),
            select(getActiveAction),
            select(getActiveUrl),
            select(getClientInfo)
        ]);
        const { autoPercentages, chartData, comment, defaultTimer, elementsData, groups, isLabelsFromProjectCopying, JMXProfiles, labelSet, loadType, projectName, previewJMXChart, profileDistribution, requestParameters, requests, runnerLocations, runnerResources, sla, step, testLabels, testType, threadGroupsTree, timers } = newTestSettings;
        setTestProjectDataToLocalStorage(Object.assign({ autoPercentages,
            chartData,
            comment,
            defaultTimer,
            elementsData,
            groups,
            isLabelsFromProjectCopying,
            JMXProfiles,
            labelSet, loadType: loadType.name, profileDistribution,
            projectName,
            previewJMXChart,
            requests,
            requestParameters,
            runnerLocations,
            runnerResources,
            saveType,
            sla,
            step,
            testLabels,
            testType,
            threadGroupsTree,
            timers, userData: !!clientInfo.id }, newTestSettings[loadType.name]));
        activeAction && setToLocalStorage("activeAction", JSON.stringify(activeAction));
        activeUrl && setToLocalStorage("activeUrl", activeUrl);
    }
    catch (e) {
        console.error(e);
    }
}
export function* selectProfileFlow() {
    const testType = yield select(getTestType);
    return testType === TestType.JMX ? undefined : yield* generateBoomqStableScalabilityData();
}
export function* serializeTestProjectDataByParams({ defaultCookies, defaultHeaders, defaultTimer, elementsData, groups, httpRequestDefaults, isLabelsFromProjectCopying, labelSet, profileDistribution, projectName, requestParameters, requests, runnerLocations, runnerResources, settingsId, sla, testLabels, testType, threadGroupsTree, JMXTimersSettings }) {
    let loadProfileParams = yield select(getLoadProfileParamsByTestType, testType);
    testType === TestType.JMX &&
        (loadProfileParams = Object.keys(loadProfileParams).reduce((res, groupId) => (Object.assign(Object.assign({}, res), { [groupId]: { steps: loadProfileParams[groupId].steps } })), {}));
    return JSON.stringify(Object.assign(Object.assign({}, loadProfileParams), { defaultCookies,
        defaultHeaders,
        defaultTimer,
        elementsData,
        groups,
        httpRequestDefaults,
        isLabelsFromProjectCopying,
        labelSet,
        profileDistribution,
        projectName,
        requestParameters,
        requests,
        runnerLocations,
        runnerResources,
        settingsId,
        sla,
        testLabels,
        testType,
        threadGroupsTree,
        JMXTimersSettings }));
}
export function* serializeTestProjectData() {
    try {
        const params = yield select(getTestProjectSettings);
        return yield* serializeTestProjectDataByParams(params);
    }
    catch (e) {
        console.error(e);
    }
}
function* setStepFlow(action) {
    const stepNumber = action ? Number(action.payload) : 0;
    return stepNumber === 2 ? yield* selectProfileFlow() : undefined;
}
export function* initTestProjectParamsFlow() {
    try {
        const locale = yield select(getCurrentDateFormatLocale);
        yield put(newTestNewProjectName({ projectName: generateDefaultTestProjectName(locale) }));
        yield* getTestSettingsFlow();
    }
    catch (e) {
        console.error(e);
    }
}
function* refreshProjectLabels() {
    try {
        const labelSet = yield select(getTestProjectSettingsField, "labelSet");
        yield put(testProjectChangeParamsField({ field: "projectLabels", value: prepareLabels(labelSet) }));
    }
    catch (e) {
        yield put(testProjectFailure(getResponseErrorData(e)));
    }
}
function* deleteLabelsFromProjectCopied() {
    return yield put(testProjectChangeParamsField({ field: "projectLabels", value: [] }));
}
function* toggleEnabledGroupFlow() {
    try {
        const requestGroups = yield select(getRequestGroups);
        return yield* changeRequestGroupsByProfileDistribution(requestGroups);
    }
    catch (e) {
        console.error(e);
    }
}
function* toggleActiveGroupsFlow(action) {
    try {
        const { enabled } = action ? action.payload : {};
        return enabled ? yield* toggleEnabledGroupFlow() : undefined;
    }
    catch (e) {
        console.error(e);
    }
}
function* toggleLabelsFromProjectCopyingFlow() {
    try {
        const isLabelsFromProjectCopying = yield select(getIsLabelsFromProjectCopying);
        return isLabelsFromProjectCopying
            ? yield* refreshProjectLabels()
            : yield* deleteLabelsFromProjectCopied(isLabelsFromProjectCopying);
    }
    catch (e) {
        console.error(e);
    }
}
export function* isTestProjectModified() {
    const projectName = "test";
    const testProjectParams = yield select(getTestProjectSettings);
    const currentSerializedData = yield select(getTestProjectSettingsField, "serializedData");
    const defaultSerializedData = yield* serializeTestProjectDataByParams(Object.assign(Object.assign({}, initialTestProjectSettings), { projectName }));
    const serializedData = yield* serializeTestProjectDataByParams(Object.assign(Object.assign({}, testProjectParams), { projectName }));
    const isModified = yield select(getIsModified);
    return ![currentSerializedData, defaultSerializedData].includes(serializedData) || isModified;
}
export function* checkNewTestTemporaryCopySaving() {
    const isModified = yield* isTestProjectModified();
    return isModified ? yield* saveTestSettingsFlow({ payload: { saveType: "localTemp" } }) : undefined;
}
function* checkNewTestTemporaryCopySavingByPathName() {
    return getCurrentPathName().includes("account") ? yield* checkNewTestTemporaryCopySaving() : undefined;
}
function* unmountNewTestPageFlow() {
    yield* checkNewTestTemporaryCopySavingByPathName();
    yield put(clearNewTestSettings());
}
export function* newTestFlow() {
    yield takeEvery(addTestLabel, addTestLabelFlow);
    yield takeEvery(addThreadGroup, addThreadGroupFlow);
    yield takeEvery(copyThreadGroup, copyThreadGroupFlow);
    yield takeEvery(copyRequestInGroup, copyRequestInGroupFlow);
    yield takeEvery(copyRequestGroup, copyRequestGroupFlow);
    yield takeEvery(runTestRequest, testRunningFlow);
    yield takeEvery(saveTestProjectRequest, saveTestProjectFlow);
    yield takeEvery(deleteRequestGroup, deleteRequestGroupFlow);
    yield takeEvery(deleteTestLabel, deleteTestLabelFlow);
    yield takeEvery(getTestSettingsLocalStorage, getTestSettingsFlow);
    yield takeEvery(onUnmountNewTest, unmountNewTestPageFlow);
    yield takeEvery(saveTestSettingsLocalStorage, saveTestSettingsFlow);
    yield takeEvery(selectProfile, selectProfileFlow);
    yield takeEvery(setStep, setStepFlow);
    yield takeEvery(startTestRequest, startTestFlow);
    yield takeEvery(testProjectChangeLoadProfileParams, chartPreviewGenerator);
    yield takeEvery(toggleActiveGroups, toggleActiveGroupsFlow);
    yield takeEvery(toggleEnabledGroup, toggleEnabledGroupFlow);
    yield takeEvery(toggleLabelsFromProjectCopying, toggleLabelsFromProjectCopyingFlow);
}
