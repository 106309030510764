export const ADD_ACCOUNT_ACTION_LABEL = {
    defaultMessage: "...Add account",
    id: "account.test.profile.import.analytics.add.account"
};
export const IMPORT_BUTTON_LABEL = {
    defaultMessage: "Import",
    id: "account.test.profile.button.import"
};
export const REFRESH_SITE_LIST_ACTION_LABEL = {
    defaultMessage: "...refresh site list",
    id: "account.test.profile.import.analytics.refresh.sites"
};
export const ACCOUNT_SELECTION_SELECT_PLACEHOLDER = {
    defaultMessage: "select account",
    id: "account.test.profile.import.analytics.add.account.placeholder"
};
export const SITE_SELECTION_SELECT_PLACEHOLDER = {
    defaultMessage: "select site",
    id: "account.test.profile.import.analytics.select.site.placeholder"
};
