import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { SlaReportProfileTable } from "../../../../components/account/SlaReportProfile/SlaReportProfileTable";
import { copyEmbedCodeRequest, deleteSlaReportProfileTable, deleteSlaReportProfileTableSlaType, slaReportProfileAddSlaTypeByViewTypeRequest, slaReportProfileChangeBaselineTestRequest, slaReportProfileTableChangeFieldRequest } from "../../../../redux/actions/slaReportProfile";
import { getSiteModal } from "../../../../redux/actions/siteModal";
import { getCurrentLanguage } from "../../../../redux/reducers/languagePlate";
const connector = connect((state) => ({ currentLanguage: getCurrentLanguage(state) }), {
    copyEmbedCodeRequest,
    deleteSlaReportProfileTable,
    deleteSlaReportProfileTableSlaType,
    getSiteModal,
    slaReportProfileAddSlaTypeByViewTypeRequest,
    slaReportProfileChangeBaselineTestRequest,
    slaReportProfileTableChangeFieldRequest
});
export default withRouter(connector(SlaReportProfileTable));
