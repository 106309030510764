var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { copyElementDataToClipboard, isEmpty, stopEvent, useIntl } from "@boomq/utils";
import { BLOCK_TITLE, DATE_FORMAT_LOCALE_LABEL, EMAIL_NOTIFICATION_LABEL, SAVE_BUTTON_LABEL, SECRET_TOKEN_LABEL, SECRET_TOKEN_REPLACE_BUTTON_LABEL } from "./intl";
import { ProfileUserAccountInfo } from "../../../../components/account/Profile/ProfileUserAccountInfo";
import { generateDateFormatLocaleSelectPlaceholderByLocale, generateDateFormatSelectItems } from "../../../../helpers/dateFormat/dateFormatSelectItems";
import clientActions from "../../../../redux/actions/client";
import { getClientInfo, getCurrentDateFormatLocale, getSecretTokenData } from "../../../../redux/reducers/authorizePlate";
export const ProfileUserAccountInfoContainer = (_a) => {
    var { currentLanguage, onReplaceSecretToken } = _a, restProps = __rest(_a, ["currentLanguage", "onReplaceSecretToken"]);
    const { formatMessage } = useIntl();
    const dispatch = useDispatch();
    const clientInfo = useSelector(getClientInfo);
    const dateFormatLocale = useSelector(getCurrentDateFormatLocale);
    const secretToken = useSelector(getSecretTokenData);
    const pseudoInputRef = useRef(null);
    const [emailNotification, setEmailNotification] = useState(isEmpty(clientInfo.notificationEnabled) ? true : clientInfo.notificationEnabled);
    useEffect(() => {
        const checkEmailNotification = (notificationEnabled) => isEmpty(notificationEnabled) ? undefined : setEmailNotification(notificationEnabled);
        checkEmailNotification(clientInfo.notificationEnabled);
    }, [clientInfo.notificationEnabled]);
    const changeEmailNotificationHandler = (event) => { var _a; return setEmailNotification((_a = event === null || event === void 0 ? void 0 : event.target) === null || _a === void 0 ? void 0 : _a.checked); };
    const changeDateFormatLocaleHandler = ({ value }) => dispatch(clientActions.setDateFormatLocale(value));
    const copySecretToken = (event) => {
        stopEvent(event);
        copyElementDataToClipboard(pseudoInputRef ? pseudoInputRef.current : null);
    };
    const replaceSecretToken = (event) => {
        stopEvent(event);
        return onReplaceSecretToken === null || onReplaceSecretToken === void 0 ? void 0 : onReplaceSecretToken();
    };
    const saveHandler = (event) => {
        stopEvent(event);
        return dispatch(clientActions.changeParamsRequest({
            authorityList: clientInfo.authorityList,
            email: clientInfo.email,
            id: clientInfo.id,
            language: currentLanguage.toUpperCase(),
            notificationEnabled: emailNotification
        }));
    };
    const isEnabledSecretTokenCopyButton = Number(secretToken === null || secretToken === void 0 ? void 0 : secretToken.length) > 0;
    const dateFormatLocaleSelectItems = generateDateFormatSelectItems();
    const dateFormatLocaleSelectPlaceholder = generateDateFormatLocaleSelectPlaceholderByLocale(dateFormatLocale);
    const dateFormatLocaleLabel = formatMessage(DATE_FORMAT_LOCALE_LABEL);
    const emailNotificationLabel = formatMessage(EMAIL_NOTIFICATION_LABEL);
    const saveButtonLabel = formatMessage(SAVE_BUTTON_LABEL);
    const secretTokenLabel = formatMessage(SECRET_TOKEN_LABEL);
    const secretTokenReplaceButtonLabel = formatMessage(SECRET_TOKEN_REPLACE_BUTTON_LABEL);
    const title = formatMessage(BLOCK_TITLE);
    return (React.createElement(ProfileUserAccountInfo, Object.assign({ emailNotification: emailNotification, dateFormatLocaleLabel: dateFormatLocaleLabel, dateFormatLocaleSelectItems: dateFormatLocaleSelectItems || [], dateFormatLocaleSelectPlaceholder: dateFormatLocaleSelectPlaceholder, emailNotificationLabel: emailNotificationLabel, isEnabledSecretTokenCopyButton: isEnabledSecretTokenCopyButton, onChangeDateFormatLocale: changeDateFormatLocaleHandler, onChangeEmailNotification: changeEmailNotificationHandler, onCopySecretToken: copySecretToken, onReplaceSecretToken: replaceSecretToken, onSave: saveHandler, ref: pseudoInputRef, saveButtonLabel: saveButtonLabel, secretToken: secretToken, secretTokenReplaceButtonLabel: secretTokenReplaceButtonLabel, secretTokenLabel: secretTokenLabel, title: title }, restProps)));
};
