export const COPY_SLA_TOOLTIP = {
    defaultMessage: "Copy SLA",
    id: "account.sla.form.actions.copy.tooltip"
};
export const DELETE_SLA_TOOLTIP = {
    defaultMessage: "Delete SLA",
    id: "account.sla.form.actions.delete.tooltip"
};
export const ERROR_MESSAGE = {
    defaultMessage: "All fields must be filled",
    id: "account.sla.form.validation.error"
};
export const OPERATION_PLACEHOLDER = {
    defaultMessage: "Operation",
    id: "account.sla.form.operation.placeholder"
};
export const RESTRICTION_TYPE_PLACEHOLDER = {
    defaultMessage: "Restriction type",
    id: "account.sla.form.restriction.type.placeholder"
};
export const VALUE_INPUT_PLACEHOLDER = {
    defaultMessage: "Value",
    id: "account.sla.form.value.placeholder"
};
