import React from "react";
import { FormattedMessage } from "react-intl";
import { ButtonSize } from "@boomq/uikit";
import { CardButton, CardColumnWrapper, CardContainer, CardText, CardTitle, IconsBlock } from "./styled";
const WelcomeCard = ({ title, text, buttonLabel, icons, onClickCardButton }) => {
    return (React.createElement(CardContainer, null,
        React.createElement(CardColumnWrapper, null,
            React.createElement(CardTitle, null,
                React.createElement(FormattedMessage, Object.assign({}, title))),
            React.createElement(CardText, null,
                React.createElement(FormattedMessage, Object.assign({}, text)))),
        React.createElement(CardColumnWrapper, null,
            React.createElement(IconsBlock, null, icons.map((icon, index) => (React.createElement("div", { key: index }, icon())))),
            React.createElement(CardButton, { onClick: onClickCardButton, size: ButtonSize.big },
                React.createElement(FormattedMessage, Object.assign({}, buttonLabel))))));
};
export default WelcomeCard;
