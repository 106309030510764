import { getValueByPath, isEmpty } from "@boomq/utils";
import { formatController, generateTransactionName, getThreadGroupElementName } from "../threadGroup";
import { ControllerFlowControlType, ControllerType, ThreadGroupController } from "../../models/threadGroupEditor";
const generateDefaultTransactionName = ({ creationIndex }) => generateTransactionName(creationIndex);
const getElementIndividualPropertyValue = (groupElement, paramName) => getValueByPath(groupElement, `individualProperties.${paramName}`, "");
const getThreadGroupTransactionName = (groupElement) => {
    const transactionName = getThreadGroupElementName(groupElement);
    return isEmpty(transactionName) ? generateDefaultTransactionName(groupElement) : transactionName;
};
export const parseTransaction = ({ groupElement, groupId, path, elementId }) => formatController({
    groupId,
    id: elementId,
    index: groupElement.creationIndex,
    name: getThreadGroupTransactionName(groupElement),
    path,
    controllerType: ControllerType.Transaction,
    type: ThreadGroupController.Controller
});
export const parseForEachElement = (data) => (Object.assign(Object.assign({}, parseTransaction(data)), { endIndexInclusive: getElementIndividualPropertyValue(data.groupElement, "endIndexInclusive"), outputVariableName: getElementIndividualPropertyValue(data.groupElement, "outputVariableName"), startIndexExclusive: getElementIndividualPropertyValue(data.groupElement, "startIndexExclusive"), variablePrefix: getElementIndividualPropertyValue(data.groupElement, "variablePrefix"), controllerType: ControllerFlowControlType.ForEach }));
export const parseIfElement = (data) => (Object.assign(Object.assign({}, parseTransaction(data)), { condition: getElementIndividualPropertyValue(data.groupElement, "condition"), controllerType: ControllerFlowControlType.If }));
export const parseLoopElement = (data) => (Object.assign(Object.assign({}, parseTransaction(data)), { controllerType: ControllerFlowControlType.Loop, infinite: getElementIndividualPropertyValue(data.groupElement, "infinite"), loopCount: getElementIndividualPropertyValue(data.groupElement, "loopCount") }));
export const parseWhileElement = (data) => (Object.assign(Object.assign({}, parseTransaction(data)), { condition: getElementIndividualPropertyValue(data.groupElement, "condition"), controllerType: ControllerFlowControlType.While }));
