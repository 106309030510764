import { ComponentType } from "react";
import {
    AllReportsContainer,
    commonRoutes,
    ALL_TESTS_ROUTE,
    NEW_ITEM_ID,
    NEW_TEST_ROUTE,
    PROFILE_ROUTE,
    REPORTS_ROUTE,
    REPORT_ID_PARAM_NAME,
    SLA_REPORT_ID_PARAM_NAME,
    SLA_REPORT_PROFILES_ROUTE,
    TEAM_ID_PARAM_NAME,
    TEAMS_ROUTE,
    TEST_ID_PARAM_NAME,
    TEST_PROJECT_ID_PARAM_NAME,
    TEST_PROJECT_VERSION_PARAM_NAME,
    TEST_PROJECTS_ROUTE
} from "@boomq/common";
import { NewTest } from "../../components/pages/account/NewTest";
import { AllSlaReportProfilesContainer } from "../../containers/account/AllSlaReportProfiles";
import { NewReportContainer } from "../../containers/pages/account/NewReport";
import { ProfilePageContainer } from "../../containers/pages/account/Profile";
import { ReportContainer } from "../../containers/pages/account/Report";
import { SlaReportProfile } from "../../containers/pages/account/SlaReportProfile";
import { TeamContainer } from "../../containers/pages/account/Team";
import { TeamsContainer } from "../../containers/pages/account/Teams";
import { TestDetailsContainer } from "../../containers/account/TestDetails";
import { TestProjectContainer } from "../../containers/pages/account/TestProject";
import { Welcome } from "../../components/pages/account/Welcome";
import { AdminTeamPageContainer } from "../../containers/pages/admin/Team";
import { AdminTeamsPageContainer } from "../../containers/pages/admin/Teams";

// ToDo: DELETE
export type NavigationRoute = {
    exact: boolean;
    path: string;
    component: ComponentType<any>;
};

const getCommonRoutesByName = (commonRoutes, routeNames): NavigationRoute[] =>
    commonRoutes ? commonRoutes[routeNames] : {};

const getAccountCommonRoutes = (commonRoutes): NavigationRoute[] =>
    getCommonRoutesByName(commonRoutes, "accountCommonRoutes");

const getAccountRoutesByPermissions = (commonRoutes): NavigationRoute[] =>
    getCommonRoutesByName(commonRoutes, "accountRoutesByPermissions");

const getAdminCommonRoutes = (commonRoutes): NavigationRoute[] => getCommonRoutesByName(commonRoutes, "adminRoutes");

const getNotFoundCommonRoutes = (commonRoutes): NavigationRoute[] =>
    getCommonRoutesByName(commonRoutes, "notFoundRoutes");

export const accountRoutesByPermissions: NavigationRoute[] = [
    { exact: false, path: NEW_TEST_ROUTE, component: NewTest },
    { exact: true, path: `${REPORTS_ROUTE}/${NEW_ITEM_ID}`, component: NewReportContainer },
    { exact: false, path: `${REPORTS_ROUTE}/:${REPORT_ID_PARAM_NAME}`, component: ReportContainer },
    {
        exact: false,
        path: `${TEST_PROJECTS_ROUTE}/:${TEST_PROJECT_ID_PARAM_NAME}/:${TEST_PROJECT_VERSION_PARAM_NAME}`,
        component: TestProjectContainer
    },
    { exact: false, path: `${TEST_PROJECTS_ROUTE}/:${TEST_PROJECT_ID_PARAM_NAME}`, component: TestProjectContainer },
    ...getAccountRoutesByPermissions(commonRoutes),
    {
        exact: false,
        path: `${ALL_TESTS_ROUTE}/:${TEST_PROJECT_ID_PARAM_NAME}/:${TEST_ID_PARAM_NAME}`,
        component: TestDetailsContainer
    },
    { exact: true, path: SLA_REPORT_PROFILES_ROUTE, component: AllSlaReportProfilesContainer },
    { exact: false, path: `${SLA_REPORT_PROFILES_ROUTE}/:${SLA_REPORT_ID_PARAM_NAME}`, component: SlaReportProfile },
    { exact: false, path: REPORTS_ROUTE, component: AllReportsContainer }
];

export const accountCommonRoutes: NavigationRoute[] = [
    { exact: true, path: "", component: Welcome },
    { exact: false, path: PROFILE_ROUTE, component: ProfilePageContainer },
    { exact: true, path: TEAMS_ROUTE, component: TeamsContainer },
    { exact: true, path: `${TEAMS_ROUTE}/:${TEAM_ID_PARAM_NAME}`, component: TeamContainer },
    ...getAccountCommonRoutes(commonRoutes),
    ...getNotFoundCommonRoutes(commonRoutes)
];

export const adminRoutes: NavigationRoute[] = [
    { exact: true, path: TEAMS_ROUTE, component: AdminTeamsPageContainer },
    { exact: true, path: `${TEAMS_ROUTE}/:${TEAM_ID_PARAM_NAME}`, component: AdminTeamPageContainer },
    ...getAdminCommonRoutes(commonRoutes)
];
