var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useState } from "react";
import { stopEvent } from "@boomq/utils";
import { DEFAULT_EMPTY_LABELS_TEXT, DEFAULT_INPUT_VALUE } from "./constants";
import { AddLabelLi, AutoCompleteInput, LabelList, LabelsBody, NoLabel, StyledIconCheck, StyledIconClose, StyledIconPlus } from "./styled";
import { AutoComplete } from "../AutoComplete";
import { LabelAddDropdownTemplate } from "../LabelAddDropdownTemplate";
import { Label } from "../Label";
const getNoLabelText = (emptyLabelsText) => typeof emptyLabelsText === "string" ? emptyLabelsText : DEFAULT_EMPTY_LABELS_TEXT;
const getSearchedItems = (inputValue, searchedItems) => inputValue.length > 0 && Array.isArray(searchedItems) && searchedItems.length > 0 ? searchedItems : [];
export const Labels = (_a) => {
    var { emptyLabelsText, labels = [], minLength, onDelete } = _a, rest = __rest(_a, ["emptyLabelsText", "labels", "minLength", "onDelete"]);
    const [isShowAddLabelInput, setIsShowAddLabelInput] = useState(false);
    const [inputValue, setInputValue] = useState(DEFAULT_INPUT_VALUE);
    const [searchedItems, setSearchedItems] = useState(getSearchedItems(inputValue, rest.searchedItems));
    useEffect(() => {
        setSearchedItems(getSearchedItems(inputValue, rest.searchedItems));
    }, [rest.searchedItems]);
    const isValidLabelName = () => inputValue.trim().length > 0;
    const addNewLabel = () => {
        setIsShowAddLabelInput(false);
        setSearchedItems([]);
        return typeof rest.addLabelWithCreatingMethod === "function"
            ? rest.addLabelWithCreatingMethod(inputValue)
            : undefined;
    };
    const addNewLabelHandler = (e) => {
        stopEvent(e);
        return isValidLabelName() ? addNewLabel() : undefined;
    };
    const cancelLabelAdding = (e) => {
        stopEvent(e);
        setIsShowAddLabelInput(false);
        setSearchedItems([]);
    };
    const changeInputHandler = (value) => setInputValue(value);
    const clickAddLabelHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsShowAddLabelInput(true);
    };
    const keyDownHandler = (e) => e.keyCode === 13 ? addNewLabelHandler(e) : undefined;
    const searchFunc = (value) => {
        setInputValue(value);
        return typeof rest.searchLabelsMethod === "function" ? rest.searchLabelsMethod(value) : undefined;
    };
    const selectItemHandler = (item) => {
        setIsShowAddLabelInput(false);
        return typeof rest.addLabelMethod === "function" ? rest.addLabelMethod(item) : undefined;
    };
    const renderAddLabelButton = () => {
        const LabelAddingButtonTemplate = rest.labelAddingButtonTemplate;
        return LabelAddingButtonTemplate ? (React.createElement(LabelAddingButtonTemplate, { onClick: clickAddLabelHandler })) : (React.createElement(StyledIconPlus, { onClick: clickAddLabelHandler }));
    };
    const renderAddLabel = () => isShowAddLabelInput ? (React.createElement(React.Fragment, null,
        React.createElement(AutoComplete, { autoFocus: true, dropdownTemplate: LabelAddDropdownTemplate, inputTemplate: AutoCompleteInput, minLength: minLength, onChangeInput: changeInputHandler, onKeyDown: keyDownHandler, onSelectItem: selectItemHandler, searchedItems: searchedItems, searchFunc: searchFunc }),
        React.createElement(StyledIconCheck, { onClick: addNewLabelHandler }),
        React.createElement(StyledIconClose, { onClick: cancelLabelAdding }))) : (renderAddLabelButton());
    const renderNoLabel = () => isShowAddLabelInput ? (React.createElement(React.Fragment, null)) : (React.createElement(NoLabel, { onClick: clickAddLabelHandler }, getNoLabelText(emptyLabelsText)));
    const renderLabelList = () => (labels || []).length > 0 ? (React.createElement(LabelList, null,
        labels.map((label) => (React.createElement(Label, Object.assign({ key: label.id }, label, { onDelete: onDelete })))),
        React.createElement(AddLabelLi, null, renderAddLabel()))) : (React.createElement(React.Fragment, null,
        renderNoLabel(),
        renderAddLabel()));
    return React.createElement(LabelsBody, Object.assign({}, rest), renderLabelList());
};
