import { handleActions } from "redux-actions";
import { getValueByPath } from "@boomq/utils";
import { newRequestChangeParams, newRequestChangeParamsField, newRequestChangeAssertionsResponsePreviewSource, newRequestChangeExtractorsResponsePreviewSource, saveGenerationQueryParams, saveGenerationRequestUrl } from "../../actions/newRequest";
import { getHttpMethodItemWithParamsByValue } from "../../../helpers/request/http";
export const initialState = {
    assertions: [],
    body: {
        TEXT: ""
    },
    currentActiveTabName: "",
    deadline: 60,
    errorProperties: {},
    extractResponse: [],
    fullMethod: "",
    groupId: -1,
    headers: [],
    isCopyRequestName: true,
    isShowResponseInAssertions: false,
    isShowResponseInExtractors: false,
    isTls: true,
    method: "GET",
    queryParams: [],
    requestParameters: [],
    requestUrl: "https://",
    responsePreviewData: {},
    responsePreviewSourceInAssertions: "",
    responsePreviewSourceInExtractFromResponse: "",
    timersRequestLink: {},
    timerList: [],
    usedRequestParameterNames: []
};
export default handleActions({
    [newRequestChangeParamsField]: (state, action) => {
        const newState = Object.assign({}, state);
        const { field, subfield, value } = action.payload || {};
        const excludeParams = field === "method"
            ? getValueByPath(getHttpMethodItemWithParamsByValue(value), "excludeParams", [])
            : [];
        field &&
            (newState[field] = subfield
                ? Object.assign(Object.assign({}, newState[field]), { [subfield]: value }) : value);
        field && excludeParams.forEach((param) => (newState[param] = initialState[param]));
        return newState;
    },
    [newRequestChangeParams]: (state, action) => (Object.assign(Object.assign({}, initialState), action.payload)),
    [newRequestChangeAssertionsResponsePreviewSource]: (state, { payload }) => (Object.assign(Object.assign({}, state), { responsePreviewSourceInAssertions: payload, isShowResponseInAssertions: true })),
    [newRequestChangeExtractorsResponsePreviewSource]: (state, { payload }) => (Object.assign(Object.assign({}, state), { responsePreviewSourceInExtractFromResponse: payload, isShowResponseInExtractors: true })),
    [saveGenerationQueryParams]: (state, action) => action.payload && Array.isArray(action.payload) ? Object.assign(Object.assign({}, state), { queryParams: action.payload }) : state,
    [saveGenerationRequestUrl]: (state, action) => action.payload
        ? Object.assign(Object.assign({}, state), { requestUrl: action.payload }) : Object.assign(Object.assign({}, state), { requestUrl: initialState.requestUrl })
}, Object.assign({}, initialState));
