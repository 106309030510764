import { ACCOUNT_ROUTE, ADMIN_ROUTE, ALL_TESTS_ROUTE, AUTHORIZE_ROUTE, CHANGE_PASSWORD_ROUTE, NEW_ITEM_ID, NEW_TEST_ROUTE, PROFILE_ROUTE, REPORTS_ROUTE, SLA_REPORT_PROFILES_ROUTE, TEAMS_ROUTE, TEST_PROJECT_SETTINGS_SET_ID_PARAM_NAME, TEST_PROJECT_SETTINGS_SET_ROUTE, TEST_PROJECTS_ROUTE } from "./constants";
import { AllTestProjectSettingsSets } from "../../components/account/AllTestProjectSettingsSets";
import Resources from "../../components/pages/admin/Resources";
import Tasks from "../../components/pages/admin/Tasks";
import Users from "../../components/pages/admin/Users";
import NotFound from "../../components/pages/website/NotFound";
import { UserAgreementDoc } from "../../components/pages/website/UserAgreementDoc";
import { AllTestsContainer } from "../../containers/pages/account/AllTests";
import { AllTestProjectsContainer } from "../../containers/account/AllTestProjects";
import { TestProjectSettingsSetContainer } from "../../containers/pages/account/TestProjectSettingsSet";
const websiteRoutes = [{ exact: false, path: "terms", component: UserAgreementDoc }];
const accountCommonRoutes = [];
const accountRoutesByPermissions = [
    { exact: true, path: ALL_TESTS_ROUTE, component: AllTestsContainer },
    { exact: true, path: TEST_PROJECTS_ROUTE, component: AllTestProjectsContainer },
    { exact: true, path: TEST_PROJECT_SETTINGS_SET_ROUTE, component: AllTestProjectSettingsSets },
    {
        exact: true,
        path: `${TEST_PROJECT_SETTINGS_SET_ROUTE}/:${TEST_PROJECT_SETTINGS_SET_ID_PARAM_NAME}`,
        component: TestProjectSettingsSetContainer
    }
];
const notFoundRoutes = [{ exact: false, path: "*", component: NotFound }];
const adminLinks = [
    { to: "/users", text: "Users" },
    { to: "/tasks", text: "Tests" },
    { to: "/teams", text: "Teams" },
    { to: "/resources", text: "Resources" }
];
const adminRoutes = [
    { exact: false, path: "users", component: Users },
    { exact: false, path: "tasks", component: Tasks },
    { exact: false, path: "resources", component: Resources },
    { exact: false, path: "*", component: NotFound }
];
export const getAccountRoute = () => `/${ACCOUNT_ROUTE}`;
export const getAdminRoute = () => `/${ADMIN_ROUTE}`;
export const getAllTestsRoute = () => `${getAccountRoute()}/${ALL_TESTS_ROUTE}`;
export const getAuthorizeRoute = () => `/${AUTHORIZE_ROUTE}`;
export const getChangePasswordRoute = () => `/${CHANGE_PASSWORD_ROUTE}`;
export const getNewReportRoute = () => `${getReportsRoute()}/${NEW_ITEM_ID}`;
export const getNewTestRoute = () => `${getAccountRoute()}/${NEW_TEST_ROUTE}`;
export const getProfileRoute = () => `${getAccountRoute()}/${PROFILE_ROUTE}`;
export const getReportsRoute = () => `${getAccountRoute()}/${REPORTS_ROUTE}`;
export const getSlaReportProfilesRoute = () => `${getAccountRoute()}/${SLA_REPORT_PROFILES_ROUTE}`;
export const getTeamsRoute = () => `${getAccountRoute()}/${TEAMS_ROUTE}`;
export const getTestProjectsRoute = () => `${getAccountRoute()}/${TEST_PROJECTS_ROUTE}`;
export const getTestProjectSettingsSetsRoute = () => `${getAccountRoute()}/${TEST_PROJECT_SETTINGS_SET_ROUTE}`;
export const getTestRoute = (testProjectId, testId) => `${getAllTestsRoute()}/${testProjectId}/${testId}`;
export const getNewTeamRoute = () => `${getTeamsRoute()}/${NEW_ITEM_ID}`;
export const commonRoutes = {
    accountCommonRoutes,
    accountRoutesByPermissions,
    adminLinks,
    adminRoutes,
    notFoundRoutes,
    websiteRoutes
};
