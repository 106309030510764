export const TABLE_HEADER_NAME = {
    defaultMessage: "Name",
    id: "account.team.members.table.header.name"
};
export const TABLE_HEADER_EMAIL = {
    defaultMessage: "Email",
    id: "account.team.members.table.header.email"
};
export const TABLE_HEADER_PERMISSIONS = {
    defaultMessage: "Permissions",
    id: "account.team.members.table.header.permissions"
};
export const tableHeaderColumns = [
    {
        isLong: true,
        text: TABLE_HEADER_NAME
    },
    {
        text: TABLE_HEADER_EMAIL
    },
    {
        text: TABLE_HEADER_PERMISSIONS
    },
    {
        text: { id: "title.empty", defaultMessage: " " }
    }
];
