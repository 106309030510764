import { handleActions } from "redux-actions";
import { isEmpty } from "@boomq/utils";
import { addJmeterConfigPropertyItem, changeJmeterConfigPropertyItem, deleteJmeterConfigPropertyItem, setJmeterConfigPropertyItems } from "../../actions/jmeterConfigProperties";
export const initialState = {
    items: []
};
export default handleActions({
    [addJmeterConfigPropertyItem]: (state, { payload }) => (Object.assign(Object.assign({}, state), { items: [...state.items, payload] })),
    [changeJmeterConfigPropertyItem]: (state, { payload }) => (Object.assign(Object.assign({}, state), { items: state.items
            .map((item) => (item.id === payload.id ? Object.assign(Object.assign({}, item), payload) : item))
            .filter(({ key, value }) => !isEmpty(key) || !isEmpty(value)) })),
    [deleteJmeterConfigPropertyItem]: (state, { payload }) => payload
        ? Object.assign(Object.assign({}, state), { items: [...state.items.filter((propertyItem) => propertyItem.id !== payload)] }) : state,
    [setJmeterConfigPropertyItems]: (state, { payload }) => (Object.assign(Object.assign({}, state), { items: payload }))
}, initialState);
