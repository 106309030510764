import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { isEmpty, isExistObjectInArray } from "@boomq/utils";
import newTestActions from "../actions/newTest";
import { changeRequestParametersField, changeRequestParameters, downloadCsvFileFailure, downloadCsvFileSuccess, downloadSettingsSetCsvFileRequest, downloadTestProjectCsvFileRequest, saveRequestParameters, showRequestParametersForm, uploadCsvFileRequest, uploadCsvFileSuccess, uploadCsvFileFailure } from "../actions/requestParameters";
import { closeSiteModal, getSiteModal } from "../actions/siteModal";
import { getRequestLiteralParameters, getRequestParametersImportCsv, getRequestParametersOthers } from "../reducers/requestParameters";
import { getProjectId, getProjectVersion, getRequestParametersData } from "../reducers/newTest";
import { getSettingsSetId } from "../reducers/testProjectSettingsSet";
import { RequestParametersForm } from "../../components/account/TestProject/RequestParametersForm";
import { requestParametersFormModal } from "../../data/content/modals";
import { getResponseErrorData } from "../../helpers/errors";
import { generateContentFromFilePreview } from "../../helpers/requestParameters";
import { getRequestParametersFormActiveTabNameByType } from "../../models/requestParameters";
import { getFileDownloadLink, uploadCSVFile } from "../../services/projectSrv";
const { testProjectChangeParamsField } = newTestActions;
function* changeRequestParametersFieldFlow({ payload: { field, subfield, value } }) {
    const csvParameters = yield select(getRequestParametersImportCsv);
    return field === "csvParameters" && subfield === "files" && isEmpty(value)
        ? yield put(changeRequestParametersField({
            field: "csvParameters",
            value: Object.assign(Object.assign({}, csvParameters), { fileContent: [], location: "" })
        }))
        : undefined;
}
function* downloadCsvFile(params) {
    try {
        const { data } = yield call(getFileDownloadLink, params);
        data && data.downloadLink && window.open(data.downloadLink);
        yield put(downloadCsvFileSuccess());
    }
    catch (e) {
        yield put(downloadCsvFileFailure(e));
    }
}
function* settingsSetCsvFileDownloadingFlow(action) {
    try {
        const fileLocation = action && action.payload ? action.payload : {};
        const projectSettingId = yield select(getSettingsSetId);
        yield* downloadCsvFile({ fileLocation, projectSettingId });
    }
    catch (e) {
        yield put(downloadCsvFileFailure(e));
    }
}
function* testProjectCsvFileDownloadingFlow(action) {
    try {
        const fileLocation = action && action.payload ? action.payload : {};
        const projectId = yield select(getProjectId);
        const projectVersionId = yield select(getProjectVersion);
        yield* downloadCsvFile({ fileLocation, projectId, projectVersionId });
    }
    catch (e) {
        yield put(downloadCsvFileFailure(e));
    }
}
function* saveRequestParametersFlow() {
    const [literalParameters, csvParameters, others] = yield all([
        select(getRequestLiteralParameters),
        select(getRequestParametersImportCsv),
        select(getRequestParametersOthers)
    ]);
    yield put(testProjectChangeParamsField({
        field: "requestParameters",
        value: { csvParameters, literalParameters, others },
        isModified: true
    }));
    yield put(closeSiteModal());
    yield put(changeRequestParameters());
}
function* uploadCsvFileFlow({ payload, payload: { id, files, hasHeaders } }) {
    try {
        const { data: { id: fileId, preview = [], location } } = yield call(uploadCSVFile, { file: files[0] });
        const csvParameters = yield select(getRequestParametersImportCsv);
        const csvParameter = csvParameters.find((parameter) => parameter.id === id);
        const delimiter = isEmpty(csvParameter) ? "," : csvParameter.delimiter;
        const content = generateContentFromFilePreview(preview, delimiter);
        const isExistCsvParameter = isExistObjectInArray(csvParameters, "id", id);
        const parameterNames = hasHeaders
            ? (!isEmpty(content) && content[0]) || []
            : (!isEmpty(content) && Array(content[0].length).fill("")) || [];
        const fileContent = hasHeaders ? content.slice(1) : content;
        const newCsvParameters = isExistCsvParameter
            ? csvParameters.map((parameter) => parameter.id === id
                ? Object.assign(Object.assign({}, parameter), { files: [
                        {
                            id: fileId,
                            name: files[0].name
                        }
                    ], location,
                    fileContent,
                    parameterNames,
                    preview }) : parameter)
            : [
                Object.assign(Object.assign({}, payload), { fileContent, files: [
                        {
                            id: fileId,
                            name: files[0].name
                        }
                    ], isSavedCsvFile: false, location,
                    parameterNames,
                    preview }),
                ...csvParameters
            ];
        yield put(uploadCsvFileSuccess(newCsvParameters));
    }
    catch (e) {
        yield put(uploadCsvFileFailure(getResponseErrorData(e)));
    }
}
function* showRequestParametersFormFlow({ payload }) {
    try {
        const testProjectRequestParameters = yield select(getRequestParametersData);
        yield put(changeRequestParameters(testProjectRequestParameters));
        yield put(getSiteModal(requestParametersFormModal({
            element: RequestParametersForm,
            activeTabName: getRequestParametersFormActiveTabNameByType(payload.value)
        })));
    }
    catch (e) {
        return;
    }
}
export function* requestParametersFlow() {
    yield takeEvery(changeRequestParametersField, changeRequestParametersFieldFlow);
    yield takeEvery(downloadSettingsSetCsvFileRequest, settingsSetCsvFileDownloadingFlow);
    yield takeEvery(downloadTestProjectCsvFileRequest, testProjectCsvFileDownloadingFlow);
    yield takeEvery(saveRequestParameters, saveRequestParametersFlow);
    yield takeEvery(showRequestParametersForm, showRequestParametersFormFlow);
    yield takeEvery(uploadCsvFileRequest, uploadCsvFileFlow);
}
