var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useMemo, useState } from "react";
import { connect } from "react-redux";
import { useIntl } from "@boomq/utils";
import { getFormattedReportChartMetricTypeItemsByChartType } from "../../../../helpers/reportEditor";
import { getReportChartSerieTemplateByChartType } from "../../../../models/reportEditor/chart";
import { generateReportPflbChartSeriePreviewRequest } from "../../../../redux/actions/report";
const connector = connect(null, {
    onClickApply: generateReportPflbChartSeriePreviewRequest
});
const ChartSerieParamsContainer = (_a) => {
    var _b, _c;
    var { aggregationIntervalItems, chartId, chartTypeItems, id, metricType, onClickApply, testList } = _a, restProps = __rest(_a, ["aggregationIntervalItems", "chartId", "chartTypeItems", "id", "metricType", "onClickApply", "testList"]);
    const [testId, setTestId] = useState(restProps.testId);
    const [type, setType] = useState(restProps.type);
    const { formatMessage } = useIntl();
    const metricTypeItems = useMemo(() => getFormattedReportChartMetricTypeItemsByChartType(type, formatMessage), [type]);
    const changeChartSerieTestHandler = (data) => setTestId(data === null || data === void 0 ? void 0 : data.value);
    const changeChartTypeHandler = (data) => setType(data.value);
    const ChartSerieTemplateComponent = getReportChartSerieTemplateByChartType(type);
    return (React.createElement(ChartSerieTemplateComponent, Object.assign({}, restProps, { id: id, aggregationIntervalItems: aggregationIntervalItems, axisUnit: (_b = restProps.displayProps) === null || _b === void 0 ? void 0 : _b.axisUnit, chartId: chartId, chartTypeItems: chartTypeItems, metricType: metricType, metricTypeItems: metricTypeItems, onChangeChartSerieTest: changeChartSerieTestHandler, onChangeChartType: changeChartTypeHandler, onClickApply: onClickApply, testId: testId, testList: testList, type: type, unit: (_c = restProps.displayProps) === null || _c === void 0 ? void 0 : _c.unit })));
};
export default connector(ChartSerieParamsContainer);
