export const COMMENT_AREA_PLACEHOLDER_MESSAGE = {
    defaultMessage: "Additional comment",
    id: "account.new.settings.comment.placeholder"
};
export const TEST_DESCRIPTION_SUBTITLE_MESSAGE = {
    defaultMessage: "You will be able to see this information in the test run details, all tests list and trend reports when selection specific test run",
    id: "account.new.settings.comment.tooltip"
};
export const TEST_DESCRIPTION_TITLE_MESSAGE = {
    defaultMessage: "Test run description",
    id: "account.new.settings.comment"
};
