import { put, select, takeEvery } from "redux-saga/effects";
import { push } from "connected-react-router";
import { deepCompare } from "@boomq/utils";
import { THREAD_GROUP_EDITOR_BACKUP_ITERATION_COUNT, THREAD_GROUP_EDITOR_BACKUP_TIMEOUT_MS } from "./constants";
import { loopWithStopAction } from "../core";
import { addElement, deleteEditorBackupData, setElement, onUnmountEditor, showImportDataToThreadGroupForm } from "../../actions/editor";
import newTestActions from "../../actions/newTest";
import { closeSiteModal, getSiteModal } from "../../actions/siteModal";
import { getClientId, getSelectedTeamId } from "../../reducers/authorizePlate";
import { getEditorData, getEditorGroupId, getEditorTreeData, getElements } from "../../reducers/editor";
import { getMaxTransactionIndex, getProfileSettingsMode, getThreadGroupElementsByGroupId, getThreadGroupTreeById } from "../../reducers/newTest";
import { ConfirmForm } from "../../../components/common/ConfirmForm";
import { RestoreEditorDataForm } from "../../../components/account/newTest/RestoreEditorDataForm";
import { importDataFromFileToThreadGroupModalForm } from "../../../helpers/importData";
import { getThreadGroupElementControllerType } from "../../../helpers/threadGroup";
import { formatThreadGroupElementParamsByType, generateThreadGroupEditorBackupKey, getThreadGroupEditorBackupData, setThreadGroupEditorBackupData } from "../../../helpers/threadGroupEditor";
import { ProfileSettingsMode } from "../../../models/testProject";
import { dataLossConfirmForm, ControllerType } from "../../../models/threadGroupEditor";
const { editRequestGroup, testProjectChangeParamsData, testProjectChangeParamsField } = newTestActions;
function* editorElementAddingFlow({ payload }) {
    try {
        const groupId = yield select(getEditorGroupId);
        const maxTransactionIndex = yield select(getMaxTransactionIndex);
        yield put(setElement(Object.assign(Object.assign({}, formatThreadGroupElementParamsByType(Object.assign(Object.assign({}, payload), { index: maxTransactionIndex + 1 }))), { groupId })));
        return getThreadGroupElementControllerType(payload) === ControllerType.Transaction
            ? yield put(testProjectChangeParamsData({
                maxTransactionIndex: maxTransactionIndex + 1
            }))
            : undefined;
    }
    catch (e) {
        return;
    }
}
function* isThreadGroupEditorTreeDataChanging(editorGroupId) {
    try {
        const editorTreeData = yield select(getEditorTreeData);
        const threadGroupTreeData = yield select(getThreadGroupTreeById, editorGroupId);
        return !deepCompare(editorTreeData, threadGroupTreeData);
    }
    catch (e) {
        return false;
    }
}
function* isThreadGroupEditorElementsChanging(editorGroupId) {
    try {
        const editorTreeElements = yield select(getElements);
        const threadGroupElements = yield select(getThreadGroupElementsByGroupId, editorGroupId);
        return !deepCompare(editorTreeElements, threadGroupElements);
    }
    catch (e) {
        return false;
    }
}
function* checkThreadGroupEditorDataChanging() {
    try {
        const editorGroupId = yield select(getEditorGroupId);
        const isEditorTreeDataChanging = yield* isThreadGroupEditorTreeDataChanging(editorGroupId);
        return isEditorTreeDataChanging ? true : yield* isThreadGroupEditorElementsChanging(editorGroupId);
    }
    catch (e) {
        return false;
    }
}
export function* cancelEditThreadGroupTreeDataFlow(dispatch) {
    try {
        const isThreadGroupEditorDataChanging = yield* checkThreadGroupEditorDataChanging();
        return isThreadGroupEditorDataChanging
            ? yield put(getSiteModal(dataLossConfirmForm({
                element: ConfirmForm,
                cancelAction: () => dispatch(closeSiteModal()),
                confirmAction: () => {
                    dispatch(deleteEditorBackupData());
                    dispatch(testProjectChangeParamsField({
                        field: "profileSettingsMode",
                        value: ProfileSettingsMode.ThreadGroups
                    }));
                }
            })))
            : yield put(testProjectChangeParamsField({
                field: "profileSettingsMode",
                value: ProfileSettingsMode.ThreadGroups
            }));
    }
    catch (e) {
        console.error(e);
    }
}
function goToPathname({ dispatch, pathname, unblock }) {
    unblock === null || unblock === void 0 ? void 0 : unblock();
    dispatch(push(pathname));
}
export function* leaveThreadGroupTreeEditorFlow(params) {
    try {
        const isThreadGroupEditorDataChanging = yield* checkThreadGroupEditorDataChanging();
        const profileSettingsMode = yield select(getProfileSettingsMode);
        return profileSettingsMode === ProfileSettingsMode.ThreadGroupEditor && isThreadGroupEditorDataChanging
            ? yield put(getSiteModal(dataLossConfirmForm({
                element: ConfirmForm,
                cancelAction: () => params === null || params === void 0 ? void 0 : params.dispatch(closeSiteModal()),
                confirmAction: () => {
                    params === null || params === void 0 ? void 0 : params.dispatch(closeSiteModal());
                    params === null || params === void 0 ? void 0 : params.dispatch(deleteEditorBackupData());
                    goToPathname(params);
                }
            })))
            : goToPathname(params);
    }
    catch (e) {
        console.error(e);
    }
}
function* threadGroupEditorInitializationFlow() {
    try {
        const threadGroupEditorBackupKey = yield* threadGroupEditorBackupKeyGenerationFlow();
        const currentEditorBackupData = getThreadGroupEditorBackupData();
        return (currentEditorBackupData === null || currentEditorBackupData === void 0 ? void 0 : currentEditorBackupData[threadGroupEditorBackupKey])
            ? yield* setThreadGroupEditorData(currentEditorBackupData === null || currentEditorBackupData === void 0 ? void 0 : currentEditorBackupData[threadGroupEditorBackupKey])
            : yield* threadGroupEditorBackupFlow();
    }
    catch (e) {
        return;
    }
}
function* setThreadGroupEditorData(editorData) {
    yield put(yield put(getSiteModal({
        component: {
            element: RestoreEditorDataForm,
            props: { editorData },
            width: "940px"
        }
    })));
    yield* threadGroupEditorBackupFlow();
}
function* threadGroupEditorBackupFlow() {
    yield* loopWithStopAction({
        delayTime: THREAD_GROUP_EDITOR_BACKUP_TIMEOUT_MS,
        isDelayBeforeStart: true,
        loopFunc: addThreadGroupEditorBackupInLocalStorage,
        maxCount: THREAD_GROUP_EDITOR_BACKUP_ITERATION_COUNT,
        stopAction: onUnmountEditor
    });
}
export function* threadGroupEditorBackupKeyGenerationFlow() {
    const clientId = yield select(getClientId);
    const editorData = yield select(getEditorData);
    const teamId = yield select(getSelectedTeamId);
    return generateThreadGroupEditorBackupKey({
        clientId,
        groupId: editorData === null || editorData === void 0 ? void 0 : editorData.groupId,
        teamId
    });
}
function modifyThreadGroupEditorBackupByKey(threadGroupEditorBackupKey, editorData) {
    const currentEditorBackupData = getThreadGroupEditorBackupData();
    const modifiedEditorBackupData = Object.assign(Object.assign({}, currentEditorBackupData), { [threadGroupEditorBackupKey]: editorData });
    setThreadGroupEditorBackupData(modifiedEditorBackupData);
}
function* addThreadGroupEditorBackupInLocalStorage() {
    try {
        const editorData = yield select(getEditorData);
        const threadGroupEditorBackupKey = yield* threadGroupEditorBackupKeyGenerationFlow();
        modifyThreadGroupEditorBackupByKey(threadGroupEditorBackupKey, editorData);
        return { isContinuous: true };
    }
    catch (e) {
        console.error(e);
    }
}
function* showImportDataToThreadGroupFormFlow({ payload }) {
    try {
        const threadGroupEditorGroupId = yield select(getEditorGroupId);
        yield put(getSiteModal(importDataFromFileToThreadGroupModalForm({
            groupId: threadGroupEditorGroupId,
            sourceType: payload.value
        })));
    }
    catch (e) {
        return;
    }
}
export function* threadGroupEditorFlow() {
    yield takeEvery(addElement, editorElementAddingFlow);
    yield takeEvery(editRequestGroup, threadGroupEditorInitializationFlow);
    yield takeEvery(showImportDataToThreadGroupForm, showImportDataToThreadGroupFormFlow);
}
