import styled from "styled-components";
import { withDisable } from "../../../styled/global";
import { IconPlus20Blue } from "../../../styled/StyledIcons";
import { ActionWithTextStyles } from "../../../styled/Table";
export const customInputTheme = {
    marginRight: "16px",
    maxWidth: "280px"
};
export const Action = styled.div `
    ${ActionWithTextStyles}
    margin-top: 16px;
`;
export const Actions = styled.div `
    ${withDisable}
    display: flex;
    justify-content: flex-start;
`;
export const RemoveButton = styled.div `
    align-items: center;
    cursor: pointer;
    display: flex;
`;
export const StyledIconPlus = styled(IconPlus20Blue) `
    margin-right: 6px;
`;
export const UrlOverwriteItem = styled.div `
    align-items: center;
    display: flex;
    margin-top: 22px;
    &:first-child {
        margin-top: 0;
    }
`;
