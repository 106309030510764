import React, { Component } from 'react';
import { Transition } from 'react-transition-group';
const duration = 400;
const defaultStyle = {
    in: { opacity: 0 },
    inDown: { opacity: 0, transform: 'translate(0, -100%)' },
    inLeft: { opacity: 0, transform: 'translate(-50%, 0)' },
    inRight: { opacity: 0, transform: 'translate(50%, 0)' },
    inUp: { opacity: 0, height: '100%', transform: 'translate(0, 100px)' }
};
const transitionStyles = {
    in: {
        entered: { opacity: 1 },
        entering: { opacity: 0 }
    },
    inDown: {
        entered: { opacity: 1, transform: 'translate(0, 0)' },
        entering: { opacity: 0, transform: 'translate(0, -100%)' }
    },
    inLeft: {
        entered: { opacity: 1, transform: 'translate(0, 0)' },
        entering: { opacity: 0, transform: 'translate(-50%, 0)' }
    },
    inRight: {
        entered: { opacity: 1, transform: 'translate(0, 0)' },
        entering: { opacity: 0, transform: 'translate(50%, 0)' }
    },
    inUp: {
        entered: { opacity: 1, transform: 'translate(0, 0)' },
        entering: { opacity: 0, transform: 'translate(0, 100px)' }
    }
};
class Fade extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            in: false
        };
    }
    componentDidMount() {
        this.setState({ in: true });
    }
    render() {
        const { in: inProp } = this.state;
        const { children, animation, style } = this.props;
        return (React.createElement(Transition, { in: inProp, timeout: duration }, (state) => (React.createElement("div", { style: Object.assign(Object.assign(Object.assign(Object.assign({}, style), { transition: `all ${duration}ms linear` }), defaultStyle[animation]), transitionStyles[animation][state]) }, children))));
    }
}
export default Fade;
