var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { forwardRef } from "react";
import { InputNumber } from "@boomq/uikit";
import { isEmpty } from "../../../helpers/utils";
const DEFAULT_MAX_VALUE = 99999;
const DECIMAL_COUNT = -1;
const TypeSliderInput = (_a, _ref) => {
    var { disabled, max, min, name, onBlur, onChange, onKeyDown, value = 0 } = _a, rest = __rest(_a, ["disabled", "max", "min", "name", "onBlur", "onChange", "onKeyDown", "value"]);
    const blurValidationFunc = (value) => (value < min ? min : value);
    const getMax = () => (isEmpty(max) ? DEFAULT_MAX_VALUE : max);
    return (React.createElement(InputNumber, Object.assign({ decimalCount: DECIMAL_COUNT, disabled: disabled, max: getMax(), min: 0, name: name, onBlur: onBlur, onBlurFormatFunc: blurValidationFunc, onChange: onChange, onKeyDown: onKeyDown, ref: _ref, value: value }, rest)));
};
export default forwardRef(TypeSliderInput);
