import React from "react";
import { FormattedMessage } from "react-intl";
import { HAS_URL_OVERWRITE_NO, HAS_URL_OVERWRITE_YES, TABLE_HEADER_LABEL, TABLE_HEADER_NAME, TABLE_HEADER_PARAMETER_COUNT, TABLE_HEADER_URL_OVERWRITE } from "../../../data/content/testProjectSettingsSets";
import { StyledLabels } from "./styled";
import { sizes } from "../../styled/response";
import { ActionList, ActionListItem, ClickableTableRow, TableRowCol, TableRowLink, TableRowColText } from "../../styled/Table";
import { prepareLabels } from "../../../helpers/labels";
import { getSettingsSetActionIconByName } from "../../../helpers/testProjectSettingsSets";
import { SettingsSetsItemAction } from "../../../helpers/types";
const getUrlOverwriteValue = (hasUrlOverwriteValue) => hasUrlOverwriteValue ? HAS_URL_OVERWRITE_YES : HAS_URL_OVERWRITE_NO;
const renderActions = (props) => {
    const { setting: { id } } = props || {};
    const actionHandler = (e, actionName) => {
        e.stopPropagation();
        e.preventDefault();
        return typeof props[actionName] === "function" ? props[actionName]({ id }) : undefined;
    };
    return (props.actionItems || []).map(({ id, actionName }) => {
        const Icon = getSettingsSetActionIconByName(actionName);
        return (React.createElement(ActionListItem, { key: id, onClick: (e) => actionHandler(e, actionName) },
            React.createElement(Icon, null)));
    });
};
export const AllTestProjectSettingsSetsItem = (props) => {
    const { addLabelRequest, addLabelWithCreatingRequest, deleteLabelRequest, emptyLabelsText, searchLabelsByName, setting: { id, labelSet, parameterCount, hasUrlOverwrite, name, searchedLabels = [] }, width } = props || {};
    const addLabelHandler = (label) => addLabelRequest({ id, label });
    const addLabelWithCreatingHandler = (name) => addLabelWithCreatingRequest({ id, name });
    const deleteLabelHandler = (label) => deleteLabelRequest({ id, label: label.name });
    const searchLabels = (query) => searchLabelsByName({ id, query });
    const labels = prepareLabels(labelSet);
    return (React.createElement(ClickableTableRow, null,
        React.createElement(TableRowLink, { to: { pathname: `/account/test-settings/${id}`, state: { step: 1 } } },
            React.createElement(TableRowCol, { long: true },
                width <= sizes.tablet && (React.createElement(TableRowColText, { blue: true },
                    React.createElement(FormattedMessage, Object.assign({}, TABLE_HEADER_NAME)))),
                React.createElement(TableRowColText, { blue: true }, name)),
            React.createElement(TableRowCol, { long: true },
                width <= sizes.tablet && (React.createElement(TableRowColText, null,
                    React.createElement(FormattedMessage, Object.assign({}, TABLE_HEADER_LABEL)))),
                React.createElement(StyledLabels, { labels: labels, addLabelMethod: addLabelHandler, addLabelWithCreatingMethod: addLabelWithCreatingHandler, emptyLabelsText: emptyLabelsText, minLength: 3, onDelete: deleteLabelHandler, searchLabelsMethod: searchLabels, searchedItems: searchedLabels })),
            React.createElement(TableRowCol, null,
                width <= sizes.tablet && (React.createElement(TableRowColText, null,
                    React.createElement(FormattedMessage, Object.assign({}, TABLE_HEADER_PARAMETER_COUNT)))),
                React.createElement(TableRowColText, null, parameterCount)),
            React.createElement(TableRowCol, null,
                width <= sizes.tablet && (React.createElement(TableRowColText, null,
                    React.createElement(FormattedMessage, Object.assign({}, TABLE_HEADER_URL_OVERWRITE)))),
                React.createElement(TableRowColText, null,
                    React.createElement(FormattedMessage, Object.assign({}, getUrlOverwriteValue(hasUrlOverwrite))))),
            React.createElement(ActionList, null, renderActions(props)))));
};
