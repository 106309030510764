import styled from "styled-components";
import { Color, IconLoader } from "@boomq/uikit";
import { getZIndex } from "../../../components/styled/global";
const SIDEBAR_WIDTH = "460px";
export const LoaderBlock = styled.div `
    align-items: center;
    background: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(4px);
    display: ${({ isShow }) => (isShow ? "flex" : "none")};
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: ${getZIndex("Loader")};
`;
export const Sidebar = styled.div `
    background: ${Color.white};
    box-shadow: 0px 0px 8px rgba(0, 64, 140, 0.15);
    display: flex;
    flex-direction: column;
    height: calc(100% - 60px);
    position: fixed;
    right: ${({ isShow }) => (isShow ? "0px" : "-110%")};
    transition: all 0.3s ease-in-out;
    width: ${SIDEBAR_WIDTH};
    z-index: ${getZIndex("Sidebar")};
`;
export const StyledIconLoader = styled(IconLoader) `
    fill: ${Color.blue};
    height: 48px;
    width: 48px;
`;
