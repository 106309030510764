import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { ButtonSize, Tooltip } from "@boomq/uikit";
import { NEXT_BUTTON_LABEL, PROFILE_DISTRIBUTION_LABEL, PROFILE_DISTRIBUTION_PERCENT_RADIO_BUTTON_LABEL, PROFILE_DISTRIBUTION_USERS_RADIO_BUTTON_LABEL, SAVE_TEST_BUTTON_LABEL } from "./intl";
import { groupListStyle, profileDistributionPanelTheme, ProfileSettingsBlock, StyledAccountSectionTitle, StyledButtonWithMargin, StyledTestSettingsActionsPanel, TooltipEl } from "./styled";
import { ProfileDistributionPanel } from "../ProfileDistribution";
import { TestType } from "../../../../helpers/types";
import { testProfileValidator } from "../../../../helpers/validators";
import { isEmpty } from "../../../../helpers/utils";
import Fade from "../../../animation/Fade";
import { ProfileSettingsButtons } from "../../../../containers/account/TestProject/ProfileSettingsButtons";
import { TestProjectNotification } from "../TestProjectNotification";
import TestProjectVersionSelector from "../TestProjectVersionSelector";
import { getTestProjectSaveNotification } from "../../../../redux/actions/testProjectNotification";
import { profileSettingsNotification } from "../../../../data/content/notifications";
const isNewTestPage = (location) => location && location.pathname === "/account/new-test";
export class CommonProfileSettings extends Component {
    constructor() {
        super(...arguments);
        this.activeActionStart = (action) => {
            action && this[action.type] && this[action.type](action.payload);
            action && this[action.type] && this.props.setActiveAction();
        };
        this.saveTestProjectHandler = () => this.props.onSave({
            postAction: {
                success: {
                    actionType: getTestProjectSaveNotification.toString(),
                    payload: {
                        isShowProjectLink: isNewTestPage(this.props.location),
                        getNotificationPayload: (projectId, isShowProjectLink) => profileSettingsNotification({
                            action: this.props.hideTestProjectNotification,
                            linkProject: isShowProjectLink ? (React.createElement(Link, { to: `/account/tests/${projectId}` },
                                React.createElement(FormattedMessage, { defaultMessage: "here", id: "account.new.test.project.saved.success.link" }))) : undefined
                        })
                    }
                }
            }
        });
        this.selectTestProfileHandler = () => {
            const { elementsData, projectId, projectName, onClickNext, profileDistribution, requests, testType, threadGroups } = this.props;
            const { isValid } = testProfileValidator({ profileDistribution, requests, testType, threadGroups });
            return isValid ? onClickNext({ elementsData, groups: threadGroups, projectName, projectId }) : undefined;
        };
    }
    componentDidMount() {
        const sectionCollection = document.getElementsByTagName("section");
        !isEmpty(sectionCollection) && (sectionCollection[0].style.maxHeight = "calc(100% - 60px)");
        this.activeActionStart(this.props.activeAction);
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        nextProps.activeAction !== this.props.activeAction && this.activeActionStart(nextProps.activeAction);
    }
    componentWillUnmount() {
        const sectionCollection = document.getElementsByTagName("section");
        !isEmpty(sectionCollection) && sectionCollection[0].style.removeProperty("max-height");
    }
    render() {
        const { autoPercentages, intl: { formatMessage }, isAuthorized, location, onChangeAutoPercentages, onChangeDistribution, permissionList, profileDistribution, requests, testType, threadGroups } = this.props;
        const { isValid, exceptionText } = testProfileValidator({
            profileDistribution,
            requests,
            testType,
            threadGroups
        });
        return (React.createElement(ProfileSettingsBlock, null,
            React.createElement(StyledAccountSectionTitle, null,
                React.createElement(FormattedMessage, { id: "account.test.profile.section.title", defaultMessage: "Setup test requests" }),
                React.createElement(ProfileSettingsButtons, { testType: testType })),
            testType !== TestType.JMX && (React.createElement(ProfileDistributionPanel, { autoPercentages: autoPercentages, label: formatMessage(PROFILE_DISTRIBUTION_LABEL), onChange: onChangeDistribution, onChangeAutoPercentages: onChangeAutoPercentages, percentRadioButtonLabel: formatMessage(PROFILE_DISTRIBUTION_PERCENT_RADIO_BUTTON_LABEL), permissionList: permissionList, profileDistribution: profileDistribution, theme: profileDistributionPanelTheme, threadGroups: threadGroups, usersRadioButtonLabel: formatMessage(PROFILE_DISTRIBUTION_USERS_RADIO_BUTTON_LABEL), value: profileDistribution })),
            React.createElement(Fade, { animation: "inRight", style: groupListStyle }, this.props.children),
            React.createElement(StyledTestSettingsActionsPanel, null,
                React.createElement(TestProjectNotification, null),
                !isNewTestPage(location) && React.createElement(TestProjectVersionSelector, null),
                isAuthorized && (React.createElement(StyledButtonWithMargin, { disabled: !isValid, onClick: this.saveTestProjectHandler, size: ButtonSize.medium },
                    formatMessage(SAVE_TEST_BUTTON_LABEL),
                    !isValid && (React.createElement(Tooltip, { title: exceptionText },
                        React.createElement(TooltipEl, null))))),
                React.createElement(StyledButtonWithMargin, { disabled: !isValid, onClick: this.selectTestProfileHandler, size: ButtonSize.medium },
                    formatMessage(NEXT_BUTTON_LABEL),
                    !isValid && (React.createElement(Tooltip, { title: exceptionText },
                        React.createElement(TooltipEl, null)))))));
    }
}
