import React from "react";
import { RequestGroupJMX, RequestGroupTitle } from "@boomq/common";
import {
    DEFAULT_CHANGE_REQUEST_GROUP,
    DEFAULT_REQUEST_GROUP,
    DEFAULT_THREAD_GROUP_MODE,
    DEFAULT_TOGGLE_ENABLED_GROUP
} from "./constants";
import { getThreadGroupTitleTemplateByTypeAndMode } from "../../../../helpers/requestGroupJMX";
import { RequestGroupType } from "../../../../helpers/types";

const getGroupCaption = ({
    changeRequestGroup = DEFAULT_CHANGE_REQUEST_GROUP,
    mode = DEFAULT_THREAD_GROUP_MODE,
    requestGroup = DEFAULT_REQUEST_GROUP,
    toggleEnabledGroup = DEFAULT_TOGGLE_ENABLED_GROUP
}) => {
    const CaptionComponent = getThreadGroupTitleTemplateByTypeAndMode(RequestGroupType.Ultimate2, mode);

    const changeRequestGroupName = (value) => changeRequestGroup({ field: "name", id: requestGroup.id, value });

    const toggleRequestGroup = () => toggleEnabledGroup(requestGroup.id);

    return (
        <CaptionComponent
            requestGroup={requestGroup}
            onChangeName={changeRequestGroupName}
            onToggle={toggleRequestGroup}
        />
    );
};

export const SimpleThreadGroup = (props) => (
    <RequestGroupTitle {...props} caption={getGroupCaption(props)}>
        <RequestGroupJMX {...props} />
    </RequestGroupTitle>
);
