import React from "react";
import ReactDOM from "react-dom";
import { FormattedMessage } from "react-intl";
import styled, { css } from "styled-components";
import { setRef, getUniqueId, useForkRef, useIsFocusVisible } from "@boomq/utils";
import { Popper } from "./Popper";
const useControlled = ({ controlled, default: defaultProp }) => {
    const { current: isControlled } = React.useRef(controlled !== undefined);
    const [valueState, setValue] = React.useState(defaultProp);
    const value = isControlled ? controlled : valueState;
    const setValueIfUncontrolled = React.useCallback((newValue) => !isControlled && setValue(newValue), []);
    return [value, setValueIfUncontrolled];
};
let hystersisOpen = false;
let hystersisTimer = null;
const Tooltip = (props, ref) => {
    const { children, enterDelay = 0, enterTouchDelay = 700, id: idProp, leaveDelay = 0, leaveTouchDelay = 1500, open: openProp, placement = "top", theme, title } = props;
    const transitionDuration = 150;
    const [childNode, setChildNode] = React.useState();
    const ignoreNonTouchEvents = React.useRef(false);
    const closeTimer = React.useRef();
    const enterTimer = React.useRef();
    const leaveTimer = React.useRef();
    const touchTimer = React.useRef();
    const [openState, setOpenState] = useControlled({
        controlled: openProp,
        default: false
    });
    let open = openState;
    const [defaultId, setDefaultId] = React.useState();
    const id = idProp || defaultId;
    React.useEffect(() => (open && !defaultId && setDefaultId(`unique-tooltip-${getUniqueId()}`)) || undefined, [open, defaultId]);
    React.useEffect(() => {
        return () => {
            clearTimeout(closeTimer.current);
            clearTimeout(enterTimer.current);
            clearTimeout(leaveTimer.current);
            clearTimeout(touchTimer.current);
        };
    }, []);
    const handleOpen = (event) => {
        clearTimeout(hystersisTimer);
        hystersisOpen = true;
        setOpenState(true);
    };
    const handleEnter = (event) => {
        const childrenProps = children.props;
        event.type === "mouseover" &&
            childrenProps.onMouseOver &&
            event.currentTarget === childNode &&
            childrenProps.onMouseOver(event);
        if (ignoreNonTouchEvents.current && event.type !== "touchstart") {
            return;
        }
        childNode && childNode.removeAttribute("title");
        clearTimeout(enterTimer.current);
        clearTimeout(leaveTimer.current);
        enterDelay && !hystersisOpen && event.persist();
        enterDelay && !hystersisOpen
            ? (enterTimer.current = setTimeout(() => handleOpen(event), enterDelay))
            : handleOpen(event);
    };
    const { isFocusVisible, onBlurVisible, ref: focusVisibleRef } = useIsFocusVisible();
    const [childIsFocusVisible, setChildIsFocusVisible] = React.useState(false);
    const handleBlur = () => {
        childIsFocusVisible && setChildIsFocusVisible(false);
        childIsFocusVisible && onBlurVisible();
    };
    const handleFocus = (event) => {
        !childNode && setChildNode(event.currentTarget);
        if (isFocusVisible(event)) {
            setChildIsFocusVisible(true);
            handleEnter(event);
        }
        const childrenProps = children.props;
        childrenProps.onFocus && event.currentTarget === childNode && childrenProps.onFocus(event);
    };
    const handleClose = (event) => {
        clearTimeout(hystersisTimer);
        hystersisTimer = setTimeout(() => {
            hystersisOpen = false;
        }, 500);
        setOpenState(false);
        clearTimeout(closeTimer.current);
        closeTimer.current = setTimeout(() => (ignoreNonTouchEvents.current = false), transitionDuration);
    };
    const handleLeave = (event) => {
        const childrenProps = children.props;
        event.type === "blur" &&
            childrenProps.onBlur &&
            event.currentTarget === childNode &&
            childrenProps.onBlur(event);
        event.type === "blur" && handleBlur(event);
        event.type === "mouseleave" &&
            childrenProps.onMouseLeave &&
            event.currentTarget === childNode &&
            childrenProps.onMouseLeave(event);
        clearTimeout(enterTimer.current);
        clearTimeout(leaveTimer.current);
        event.persist();
        leaveTimer.current = setTimeout(() => {
            handleClose(event);
        }, leaveDelay);
    };
    const handleTouchStart = (event) => {
        ignoreNonTouchEvents.current = true;
        const childrenProps = children.props;
        childrenProps.onTouchStart && childrenProps.onTouchStart(event);
        clearTimeout(leaveTimer.current);
        clearTimeout(closeTimer.current);
        clearTimeout(touchTimer.current);
        event.persist();
        touchTimer.current = setTimeout(() => {
            handleEnter(event);
        }, enterTouchDelay);
    };
    const handleTouchEnd = (event) => {
        children.props.onTouchEnd && children.props.onTouchEnd(event);
        clearTimeout(touchTimer.current);
        clearTimeout(leaveTimer.current);
        event.persist();
        leaveTimer.current = setTimeout(() => {
            handleClose(event);
        }, leaveTouchDelay);
    };
    const handleUseRef = useForkRef(setChildNode, ref);
    const handleFocusRef = useForkRef(focusVisibleRef, handleUseRef);
    const handleOwnRef = React.useCallback((instance) => setRef(handleFocusRef, ReactDOM.findDOMNode(instance)), [handleFocusRef]);
    const handleRef = useForkRef(children.ref, handleOwnRef);
    ((Array.isArray(title) && title.length === 0) || title === "") && (open = false);
    const childrenProps = Object.assign({ "aria-describedby": open ? id : null }, children.props);
    childrenProps.onTouchStart = handleTouchStart;
    childrenProps.onTouchEnd = handleTouchEnd;
    childrenProps.onMouseOver = handleEnter;
    childrenProps.onMouseLeave = handleLeave;
    childrenProps.onFocus = handleFocus;
    childrenProps.onBlur = handleLeave;
    const getTitleKey = (item) => (item.key ? item.key : item.id);
    const getTitleText = (title) => (title.id ? React.createElement(FormattedMessage, Object.assign({}, title)) : title);
    const getTitleArrayText = (title) => title.map((item) => item.id && React.createElement(FormattedMessage, Object.assign({ key: getTitleKey(item) }, item)));
    return (React.createElement(React.Fragment, null,
        React.cloneElement(children, Object.assign({ ref: handleRef }, childrenProps)),
        React.createElement(Popper, { placement: placement, anchorEl: childNode, open: childNode ? open : false, id: childrenProps["aria-describedby"] }, title && (React.createElement(StyledTooltipBody, { theme: theme, titleArray: Array.isArray(title) }, Array.isArray(title) ? getTitleArrayText(title) : getTitleText(title))))));
};
const StyledTooltipBody = styled.div `
    background: #ffffff;
    border-radius: 5px;
    box-shadow: 0 0 8px rgba(23, 128, 224, 0.25);
    margin-bottom: 5px;
    max-width: 200px;
    padding: 5px 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    font-size: 14px;
    text-align: center;
    color: #b2b2b2;
    ${({ theme }) => css(theme)}
    ${({ titleArray }) => titleArray &&
    css `
            display: flex;
            flex-direction: column;
            width: max-content;
        `}
`;
export default React.forwardRef(Tooltip);
