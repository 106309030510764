import { takeEvery } from "redux-saga/effects";
import { teamsGettingFlow } from "../teams";
import { adminTeamsRequest } from "../../actions/admin/teams";
import { getAdminTeams } from "../../../services/authSrv";
function* adminTeamsGettingFlow(action) {
    yield* teamsGettingFlow(action, getAdminTeams);
}
export function* adminTeamsFlow() {
    yield takeEvery(adminTeamsRequest, adminTeamsGettingFlow);
}
