import { handleActions } from "redux-actions";
import { addHeader, changeHeader, deleteHeader, setHeaders } from "../actions/testProjectSettingsSetHeaders";
export const initialState = {
    items: []
};
export default handleActions({
    [addHeader]: (state, action) => (Object.assign(Object.assign({}, state), { items: action && action.payload ? [...state.items, action.payload] : state.items })),
    [changeHeader]: (state, action) => (Object.assign(Object.assign({}, state), { items: action && action.payload
            ? state.items
                .map((item) => item.id === action.payload.id ? Object.assign({ id: action.payload.id }, action.payload.value) : item)
                .filter((item) => item.key !== "" || item.value !== "")
            : state.items })),
    [deleteHeader]: (state, { payload }) => payload
        ? Object.assign(Object.assign({}, state), { items: [...state.items.filter((item) => item.id !== payload)] }) : state,
    [setHeaders]: (state, action) => (Object.assign(Object.assign({}, state), { items: action && Array.isArray(action.payload) ? action.payload : state.items }))
}, initialState);
const getStateParam = (state, paramName) => state && state.testProjectSettingsSetHeaders
    ? state.testProjectSettingsSetHeaders[paramName]
    : initialState[paramName];
export const getSettingsSetDefaultHeadersData = (state) => getStateParam(state, "items");
