import styled, { css } from "styled-components";
import { media } from "./response";
export const SectionTitle = styled.h2 `
    font-weight: 500;
    font-size: 36px;
    text-align: center;
    color: #396496;
    ${media.phone} {
        font-size: 26px;
    }
`;
export const StyledTitle3 = styled.h3 `
    font-weight: 500;
    line-height: normal;
    font-size: 18px;
    color: #396496;
`;
export const AccountSectionTitle = styled(StyledTitle3) `
    padding: 34px 0 26px;
    ${({ theme }) => (theme ? css(theme) : css ``)}
`;
export const FormTitle = styled(StyledTitle3) `
    font-size: 30px;
    text-align: center;
    width: 100%;
    ${media.tablet} {
        font-size: 25px;
    }
    ${({ theme }) => (theme ? css(theme) : css ``)}
`;
