var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { Select } from "@boomq/uikit";
import { getSelectTheme } from "../SlaItem";
import { SlaThreadGroupItem } from "../SlaThreadGroupItem";
export const SlaTransactionItem = (_a) => {
    var { children, data, onChangeTransaction, transactionItems, transactionSelectPlaceholder } = _a, restProps = __rest(_a, ["children", "data", "onChangeTransaction", "transactionItems", "transactionSelectPlaceholder"]);
    return (React.createElement(SlaThreadGroupItem, Object.assign({ data: data }, restProps),
        React.createElement(React.Fragment, null,
            children,
            React.createElement(Select, { autoSelect: true, disabled: restProps.isDisabled, items: transactionItems, onChange: onChangeTransaction, placeholder: transactionSelectPlaceholder, defaultValue: data.transactionName, theme: getSelectTheme(restProps.type, restProps.isShowSlaResult) }))));
};
