var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { forwardRef } from "react";
import { getValueByPath } from "@boomq/utils";
import { Button, ButtonSize } from "@boomq/uikit";
import { DRAG_DROP_SCOPE } from "./constants";
import { DropPointContentTemplate } from "./DropPointContentTemplate";
import "./Editor.css";
import { EditorActionsPanel, EditorWrapper, StyledBackButton, StyledButtonWithMargin, StyledTree, ThreadGroupDataBlock, ThreadGroupDataBlockHeader } from "./styled";
import { EditorLeftSidebarContainer } from "../../../../containers/account/RequestGroupEditor/EditorLeftSidebar";
import { ThreadGroupNameContainer } from "../../../../containers/account/RequestGroupEditor/ThreadGroupName";
import { getNodeTemplateByType } from "../../../../models/threadGroupEditor";
const nodeTemplateByType = (props) => {
    const NodeComponent = getNodeTemplateByType(getValueByPath(props, "node"));
    return NodeComponent ? React.createElement(NodeComponent, Object.assign({}, props)) : React.createElement(React.Fragment, null);
};
export const Editor = forwardRef((_a, _ref) => {
    var { cancelButtonLabel, expandedKeys, nodeClassNameFunc, onAdd, onCancel, onClickImportDataElement, onDragStart, onDragStartElement, onDropPointDragEnterValidation, onDropValidation, onReorder, onSave, onToggleExpandedElement, saveButtonLabel } = _a, restProps = __rest(_a, ["cancelButtonLabel", "expandedKeys", "nodeClassNameFunc", "onAdd", "onCancel", "onClickImportDataElement", "onDragStart", "onDragStartElement", "onDropPointDragEnterValidation", "onDropValidation", "onReorder", "onSave", "onToggleExpandedElement", "saveButtonLabel"]);
    return (React.createElement(EditorWrapper, { ref: _ref },
        React.createElement(EditorLeftSidebarContainer, { dragdropScope: DRAG_DROP_SCOPE, onClickImportDataElement: onClickImportDataElement, onDragStart: onDragStartElement }),
        React.createElement(ThreadGroupDataBlock, null,
            React.createElement(ThreadGroupDataBlockHeader, null,
                React.createElement(StyledBackButton, { onClick: onCancel }),
                React.createElement(ThreadGroupNameContainer, null)),
            React.createElement(StyledTree, { value: restProps.treeData, dragdropScope: DRAG_DROP_SCOPE, dropPointContentTemplate: DropPointContentTemplate, expandedKeys: expandedKeys, nodeClassNameFunc: nodeClassNameFunc, nodeKeyName: "id", nodeTemplate: nodeTemplateByType, onAdd: onAdd, onDropPointDragEnterValidation: onDropPointDragEnterValidation, onDragStart: onDragStart, onDropValidation: onDropValidation, onReorder: onReorder, onToggle: onToggleExpandedElement }),
            React.createElement(EditorActionsPanel, null,
                React.createElement(StyledButtonWithMargin, { onClick: onCancel, size: ButtonSize.medium }, cancelButtonLabel),
                React.createElement(Button, { onClick: onSave, size: ButtonSize.medium }, saveButtonLabel)))));
});
