import { SettingsSetInSidebarAction, TeamMemberPermission } from "../types";
const actionsByTeamMemberPermissionEdit = [
    SettingsSetInSidebarAction.Add,
    SettingsSetInSidebarAction.ExtractFromProject,
    SettingsSetInSidebarAction.LinkProject,
    SettingsSetInSidebarAction.UnlinkProject
];
const actionsByTeamMemberPermissions = {
    [TeamMemberPermission.Admin]: actionsByTeamMemberPermissionEdit,
    [TeamMemberPermission.Edit]: actionsByTeamMemberPermissionEdit,
    [TeamMemberPermission.Run]: actionsByTeamMemberPermissionEdit
};
const getSettingsSetInSidebarActionsByTeamMemberPermission = (permission) => {
    const actionsByPermission = actionsByTeamMemberPermissions[permission];
    return actionsByPermission ? actionsByPermission : [];
};
const getSettingsSetInSidebarActionsByTeamMemberPermissions = (permissions) => [
    ...new Set(permissions.reduce((res, permission) => [
        ...res,
        ...getSettingsSetInSidebarActionsByTeamMemberPermission(permission)
    ], []))
];
export const isAllowedSettingsSetInSidebarActionByPermissions = (permissionList = [], actionName) => {
    const actionsByPermissions = getSettingsSetInSidebarActionsByTeamMemberPermissions(permissionList);
    return actionsByPermissions.includes(actionName);
};
