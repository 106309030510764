var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { load as convertYamlToObj } from "js-yaml";
import { getUniqueId, getValueByPath, isEmpty } from "@boomq/utils";
import { TEST_PROJECT_TRANSACTION_DEFAULT_NAME } from "./constants";
import { getBoomqStableScalabilityThreadGroupData } from "./boomqStableScalability";
import { getTestProjectParamValue } from "./common";
import { parseConfigurationElementsFromBoomqTestPlan, parseHttpRequestDefaults } from "./configurationElements";
import { getThreadGroupsCommonData, prepareTestProjectGroupRequests, prepareTestProjectRequestGroup, prepareThreadGroupData } from "./threadGroup";
import { prepareSlaGroupList } from "./sla";
import { getUltimateThreadGroupDataVer1 } from "./ultimateThreadGroup1";
import { getUltimateThreadGroupDataVer2 } from "./ultimateThreadGroup2";
import { testProfilePercMin } from "../applicationParams";
import { RequestGroupType } from "../types";
import { testSettings } from "../../data/content/testSettings";
import { SupportingFileType } from "../../models/testProject";
const getTestParams = (data) => {
    const testParams = {};
    ((testSettings && testSettings.SCALABILITY) || []).forEach((setting) => {
        const settingName = setting.type === "time" ? `${setting.name}Ms` : setting.name;
        const multiplicationFactor = setting.type === "time" ? 1 / 60000 : 1;
        return data && !isEmpty(data[settingName])
            ? (testParams[setting.name] = Number(data[settingName] * multiplicationFactor))
            : undefined;
    });
    return testParams;
};
export const parseCsvParameterNames = (data = []) => (data[0] && data[0].join(",")) || "";
const getTestProjectTransactionName = (index, name) => isEmpty(name) ? `${TEST_PROJECT_TRANSACTION_DEFAULT_NAME} ${index + 1}` : name;
const prepareManualRequestsTestProjectTransactionName = (name = "", groupId, index, resourceConfiguration) => (Object.assign(Object.assign({}, prepareTestProjectRequestGroup(groupId, index, resourceConfiguration)), { name: getTestProjectTransactionName(index, name) }));
export const prepareCommonTestProjectData = (testProjectData, testProjectYamlData) => ({
    comment: "",
    contentModelVersion: getTestProjectParamValue(testProjectData, "contentModelVersion"),
    currentVersion: testProjectData.currentVersion,
    httpRequestDefaults: parseHttpRequestDefaults(testProjectYamlData.httpRequestDefaults),
    projectName: testProjectData.projectName,
    projectId: testProjectData.projectId,
    settingsId: testProjectData.settingsId,
    sla: prepareSlaGroupList(testProjectYamlData.slaGroupList),
    testType: testProjectData.testType,
    versions: testProjectData.versions
});
const prepareDefaultTimer = (testProjectDetailsData, testProjectYamlData) => (Object.assign(Object.assign({}, testProjectDetailsData.configDefaultTimer), testProjectYamlData.defaultTimer));
export const prepareRequestsTestProjectData = (testProjectData) => (Object.assign(Object.assign({}, getTestParams(testProjectData)), (testProjectData.groups || []).reduce((res, group, index) => {
    const groupId = getUniqueId();
    const { extractorParameters, requests, timers } = prepareTestProjectGroupRequests(groupId, group.requests);
    return {
        extractorParameters: [...res.extractorParameters, ...extractorParameters],
        groups: [
            ...res.groups,
            Object.assign(Object.assign({}, prepareManualRequestsTestProjectTransactionName(group.name, groupId, index, group.resourceConfiguration)), { perc: group.perc || testProfilePercMin })
        ],
        requests: [...res.requests, ...requests],
        timers: Object.assign(Object.assign({}, res.timers), timers)
    };
}, { extractorParameters: [], groups: [], requests: [], timers: {} })));
export const parseRequestsTestProjectFromYaml = (testProjectData) => {
    const testProjectYamlData = convertYamlToObj(testProjectData.yaml);
    const testPlanDataFromYaml = testProjectYamlData ? testProjectYamlData.timer : {};
    return Object.assign(Object.assign(Object.assign({}, prepareCommonTestProjectData(testProjectData, testPlanDataFromYaml)), { defaultTimer: prepareDefaultTimer(testProjectData, testPlanDataFromYaml) }), prepareRequestsTestProjectData(testPlanDataFromYaml));
};
const getOtherThreadGroupData = ({ res, index, group }) => {
    var _a;
    const { enabled = true, id } = group || {};
    const groupId = isEmpty(id) ? getUniqueId() : String(id);
    return Object.assign(Object.assign(Object.assign({}, res), getThreadGroupsCommonData({ res, index, group, groupId })), { groups: [
            ...res.groups,
            Object.assign(Object.assign({}, prepareThreadGroupData(groupId, index, group)), { displayTypeName: (_a = group.typeDisplayName) !== null && _a !== void 0 ? _a : "", enabled, type: group.type })
        ] });
};
export const getTestProjectDataByThreadGroupType = (res, index, group, loadProfileType) => {
    switch (group.type) {
        case RequestGroupType.BoomqStableScalability: {
            return getBoomqStableScalabilityThreadGroupData({
                res,
                index,
                group,
                loadProfileType
            });
        }
        case RequestGroupType.Ultimate: {
            return getUltimateThreadGroupDataVer1({
                res,
                index,
                group
            });
        }
        case RequestGroupType.Ultimate2: {
            return getUltimateThreadGroupDataVer2({
                res,
                index,
                group
            });
        }
        default: {
            return getOtherThreadGroupData({
                res,
                index,
                group
            });
        }
    }
};
const parseGrpcBuffer = (testProjectYamlData) => getValueByPath(testProjectYamlData, "supportingFilesV2", []).filter((file) => file.type === SupportingFileType.Proto);
const parseTestPlanData = (testProjectData) => {
    const testPlanData = getValueByPath(testProjectData, "boomqTestPlan", {});
    const _a = (testPlanData.threadGroups || []).reduce((res, threadGroup, index) => getTestProjectDataByThreadGroupType(res, index, threadGroup, testPlanData.loadProfileType), {
        extractorParameters: [],
        groups: [],
        JMXProfiles: {},
        maxTransactionIndex: 0,
        requests: [],
        timers: {},
        transactions: [],
        threadGroupsTree: {},
        elementsData: {}
    }), { requests } = _a, rest = __rest(_a, ["requests"]);
    return Object.assign(Object.assign({}, rest), { requests });
};
const parseResourceConfiguration = (testProjectYamlData) => {
    const testRunLocationIds = getValueByPath(testProjectYamlData, "resourceConfiguration.cloudTestRunLocationRequirements.testRunLocationIds", []);
    return Array.isArray(testRunLocationIds) ? testRunLocationIds : [];
};
export const getTestProjectCommonDataFromYaml = (testProjectData, testProjectYamlData) => (Object.assign(Object.assign({ comment: "", contentModelVersion: getTestProjectParamValue(testProjectData, "contentModelVersion"), currentVersion: getTestProjectParamValue(testProjectData, "currentVersion"), grpcBuffer: parseGrpcBuffer(testProjectYamlData), projectName: getTestProjectParamValue(testProjectData, "projectName", ""), projectId: getTestProjectParamValue(testProjectData, "projectId"), settingsId: getTestProjectParamValue(testProjectData, "settingsId"), runnerLocations: parseResourceConfiguration(testProjectYamlData), versions: getTestProjectParamValue(testProjectData, "versions", []) }, parseConfigurationElementsFromBoomqTestPlan(testProjectYamlData)), parseTestPlanData(testProjectYamlData)));
