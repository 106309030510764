var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { connect } from "react-redux";
import { getUniqueArrayByParam, getValueByPath, useIntl } from "@boomq/utils";
import { THREAD_GROUP_LIST_ITEM_ANY_THREAD_GROUP, TRANSACTION_PLACEHOLDER } from "./intl";
import { THREAD_GROUP_PLACEHOLDER } from "../SlaThreadGroup";
import { SlaTransactionItem } from "../../../components/account/SlaTransactionItem";
import { formatIntlMessage } from "../../../helpers/intl";
import { getSlaTestMetricRestrictionTypeItems, getThreadGroupItemsByTransactionName, getTransactionItems, prepareTestProjectThreadGroupItems } from "../../../helpers/sla";
import { SlaTargetType, SlaThreadGroupFilterType } from "../../../models/sla";
import { getRequestGroups, getThreadGroupsTransactions } from "../../../redux/reducers/newTest";
const getSlaThreadGroupItems = (state) => {
    const threadGroups = getRequestGroups(state);
    return prepareTestProjectThreadGroupItems(threadGroups);
};
const getSlaTransactionParams = (state) => {
    const transactions = getThreadGroupsTransactions(state);
    const transactionItems = getTransactionItems(transactions);
    return {
        transactions,
        transactionItems: getUniqueArrayByParam(transactionItems, "value")
    };
};
const connector = connect((state) => (Object.assign({ threadGroupItems: getSlaThreadGroupItems(state) }, getSlaTransactionParams(state))));
const SlaTransactionContainer = (_a) => {
    var { children, slaData, threadGroupItems, transactionItems, transactions } = _a, restProps = __rest(_a, ["children", "slaData", "threadGroupItems", "transactionItems", "transactions"]);
    const { formatMessage } = useIntl();
    const changeHandler = (id, field, newData) => restProps.onDispatchAction({
        type: "update",
        payload: { id, field, type: SlaTargetType.Transaction, data: newData }
    });
    const changeThreadGroupHandler = (id, data) => changeHandler(id, SlaTargetType.ThreadGroup, data);
    const changeTransactionHandler = (id, data) => changeHandler(id, SlaTargetType.Transaction, data);
    const getThreadGroupItems = (params) => [
        {
            id: SlaThreadGroupFilterType.AnyThreadGroup,
            text: formatMessage(THREAD_GROUP_LIST_ITEM_ANY_THREAD_GROUP),
            value: SlaThreadGroupFilterType.AnyThreadGroup
        },
        ...getThreadGroupItemsByTransactionName(params)
    ];
    const threadGroupSelectPlaceholder = formatIntlMessage(THREAD_GROUP_PLACEHOLDER, formatMessage);
    const transactionSelectPlaceholder = formatIntlMessage(TRANSACTION_PLACEHOLDER, formatMessage);
    return getValueByPath(slaData, SlaTargetType.Transaction, []).map((slaItemData) => (React.createElement(SlaTransactionItem, Object.assign({ key: slaItemData.id }, restProps, { data: Object.assign({}, slaItemData), onChangeThreadGroup: (data) => changeThreadGroupHandler(slaItemData.id, data), onChangeTransaction: (data) => changeTransactionHandler(slaItemData.id, data), restrictionTypeItems: getSlaTestMetricRestrictionTypeItems(SlaTargetType.Transaction), threadGroupItems: getThreadGroupItems({
            threadGroupItems,
            transactions,
            transactionName: slaItemData.transactionName
        }), threadGroupSelectPlaceholder: threadGroupSelectPlaceholder, transactionItems: transactionItems, transactionSelectPlaceholder: transactionSelectPlaceholder, type: SlaTargetType.Transaction }))));
};
export default connector(SlaTransactionContainer);
