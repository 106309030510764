import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { activationRequest } from "../../../redux/actions/activation";
const connector = connect(null, { activationRequest });
const ActivationPage = (props) => {
    const { match: { params: { code } } } = props;
    props.activationRequest({ code });
    return React.createElement(Text, null, props.text);
};
const Text = styled.p `
    margin-left: 20px;
    font-size: 36px;
    font-weight: 600;
    color: #396496;
`;
export default connector(ActivationPage);
