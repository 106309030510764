import React from "react";
import { Dot } from "recharts";
import { styleDot } from "./styled";
import { anomalyClassifications, getAnomalyDetails } from "../../../../models/anomalyClassifications";
export const CustomDot = (props) => {
    const { cx, cy, payload, onClick } = props;
    const { classification } = payload;
    const { anomalyColor } = getAnomalyDetails(classification);
    const isWarning = anomalyClassifications.some((anomaly) => anomaly.anomalyClassification === classification);
    const handleDotClick = () => (isWarning ? onClick === null || onClick === void 0 ? void 0 : onClick(cx, cy) : undefined);
    return (React.createElement(React.Fragment, null, isWarning && (React.createElement(Dot, { cx: cx, cy: cy, r: 5, fill: anomalyColor, onClick: handleDotClick, stroke: "none", style: styleDot }))));
};
