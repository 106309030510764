var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { convertHoursToMs, getMinutes, getSeconds, isEmpty, round } from "@boomq/utils";
const chartTimelineTickValues = (hours) => {
    const hoursInMs = round(convertHoursToMs(hours), 1);
    const hoursCount = Math.floor(hours);
    const minutesCount = getMinutes(hoursInMs);
    const secondsCount = getSeconds(hoursInMs);
    return {
        HOURS: hoursCount,
        MINUTES: minutesCount,
        SECONDS: secondsCount
    };
};
export const chartTimelineTickFormatter = (hours) => {
    const { HOURS, MINUTES, SECONDS } = chartTimelineTickValues(hours);
    return HOURS > 0 ? `${HOURS}:${MINUTES}:${SECONDS}` : `${MINUTES}:${SECONDS}`;
};
const getMinMaxTicks = (min, max) => {
    const res = isEmpty(min) ? [] : [min];
    return isEmpty(max) ? res : [...res, max];
};
export const getTicks = ({ data = [], pointsDataKey, axisDataKey, min, max }) => {
    const linesData = Array.isArray(data)
        ? data.reduce((res, line) => (line[pointsDataKey] ? [...res, ...line[pointsDataKey]] : res), [])
        : [];
    return [
        ...new Set([...linesData.reduce((res, point) => [...res, point[axisDataKey]], []), ...getMinMaxTicks(min, max)])
    ].sort((a, b) => a - b);
};
const filterDataByUnit = (data, unit) => data.filter((dataItem) => dataItem.unit === unit);
export const getTicksByUnit = (_a) => {
    var { data, unit } = _a, restParams = __rest(_a, ["data", "unit"]);
    return getTicks(Object.assign({ data: filterDataByUnit(data, unit) }, restParams));
};
