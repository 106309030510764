import React, { Component } from "react";
import PropTypes from "prop-types";
import { stopEvent } from "@boomq/utils";
import { TREE_NODE_KEY_DEFAULT_PARAM_NAME } from "./constants";
import { getAdditionalClassName, getTreeNodeKey, getUITreeNodeKey } from "./utils";
import { classNames } from "./ClassNames";
import DomHandler from "./DomHandler";
import ObjectUtils from "./ObjectUtils";
import { UITreeNodeToggler } from "../UITreeNodeToggler";
export class UITreeNode extends Component {
    constructor(props) {
        super(props);
        this._getTreeNodeKey = (node) => getTreeNodeKey(node, this.props.nodeKeyName);
        this.toggleTreeToggler = (event) => (this.isExpanded() ? this.collapse(event) : this.expand(event));
        this.onTogglerClick = (event) => (this.props.disabled ? undefined : this.toggleTreeToggler(event));
        this.onClick = this.onClick.bind(this);
        this.onDoubleClick = this.onDoubleClick.bind(this);
        this.onRightClick = this.onRightClick.bind(this);
        this.onTouchEnd = this.onTouchEnd.bind(this);
        this.onTogglerClick = this.onTogglerClick.bind(this);
        this.onNodeKeyDown = this.onNodeKeyDown.bind(this);
        this.propagateUp = this.propagateUp.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.onDragOver = this.onDragOver.bind(this);
        this.onDragEnter = this.onDragEnter.bind(this);
        this.onDragLeave = this.onDragLeave.bind(this);
        this.onDragStart = this.onDragStart.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.onDropPointDragOver = this.onDropPointDragOver.bind(this);
        this.onDropPointDragEnter = this.onDropPointDragEnter.bind(this);
        this.onDropPointDragLeave = this.onDropPointDragLeave.bind(this);
    }
    isLeaf() {
        return this.props.isNodeLeaf(this.props.node);
    }
    expand(event) {
        let expandedKeys = this.props.expandedKeys ? Object.assign({}, this.props.expandedKeys) : {};
        expandedKeys[this._getTreeNodeKey(this.props.node)] = true;
        this.props.onToggle({
            originalEvent: event,
            value: expandedKeys
        });
        this.invokeToggleEvents(event, true);
    }
    collapse(event) {
        let expandedKeys = Object.assign({}, this.props.expandedKeys);
        delete expandedKeys[this._getTreeNodeKey(this.props.node)];
        this.props.onToggle({
            originalEvent: event,
            value: expandedKeys
        });
        this.invokeToggleEvents(event, false);
    }
    invokeToggleEvents(event, expanded) {
        if (expanded) {
            if (this.props.onExpand) {
                this.props.onExpand({
                    originalEvent: event,
                    node: this.props.node
                });
            }
        }
        else {
            if (this.props.onCollapse) {
                this.props.onCollapse({
                    originalEvent: event,
                    node: this.props.node
                });
            }
        }
    }
    isExpanded() {
        return ((this.props.expandedKeys
            ? this.props.expandedKeys[this._getTreeNodeKey(this.props.node)] !== undefined
            : false) || this.props.node.expanded);
    }
    onNodeKeyDown(event) {
        if (this.props.disabled) {
            return;
        }
        const nodeElement = event.target.parentElement;
        if (!DomHandler.hasClass(nodeElement, "p-treenode")) {
            return;
        }
        switch (event.which) {
            case 40:
                const listElement = nodeElement.children[1];
                if (listElement) {
                    this.focusNode(listElement.children[0]);
                }
                else {
                    const nextNodeElement = nodeElement.nextElementSibling;
                    if (nextNodeElement) {
                        this.focusNode(nextNodeElement);
                    }
                    else {
                        let nextSiblingAncestor = this.findNextSiblingOfAncestor(nodeElement);
                        if (nextSiblingAncestor) {
                            this.focusNode(nextSiblingAncestor);
                        }
                    }
                }
                event.preventDefault();
                break;
            case 38:
                if (nodeElement.previousElementSibling) {
                    this.focusNode(this.findLastVisibleDescendant(nodeElement.previousElementSibling));
                }
                else {
                    let parentNodeElement = this.getParentNodeElement(nodeElement);
                    if (parentNodeElement) {
                        this.focusNode(parentNodeElement);
                    }
                }
                event.preventDefault();
                break;
            case 39:
                if (!this.isExpanded()) {
                    this.expand(event);
                }
                event.preventDefault();
                break;
            case 37:
                if (this.isExpanded()) {
                    this.collapse(event);
                }
                event.preventDefault();
                break;
            case 13:
                this.onClick(event);
                event.preventDefault();
                break;
            default:
                break;
        }
    }
    findNextSiblingOfAncestor(nodeElement) {
        let parentNodeElement = this.getParentNodeElement(nodeElement);
        if (parentNodeElement) {
            if (parentNodeElement.nextElementSibling)
                return parentNodeElement.nextElementSibling;
            else
                return this.findNextSiblingOfAncestor(parentNodeElement);
        }
        else {
            return null;
        }
    }
    findLastVisibleDescendant(nodeElement) {
        const childrenListElement = nodeElement.children[1];
        if (childrenListElement) {
            const lastChildElement = childrenListElement.children[childrenListElement.children.length - 1];
            return this.findLastVisibleDescendant(lastChildElement);
        }
        else {
            return nodeElement;
        }
    }
    getParentNodeElement(nodeElement) {
        const parentNodeElement = nodeElement.parentElement.parentElement;
        return DomHandler.hasClass(parentNodeElement, "p-treenode") ? parentNodeElement : null;
    }
    focusNode(element) {
        element.children[0].focus();
    }
    onClick(event) {
        if (this.props.onClick) {
            this.props.onClick({
                originalEvent: event,
                node: this.props.node
            });
        }
        if ((event.target.className &&
            event.target.className.constructor === String &&
            event.target.className.indexOf("p-tree-toggler") === 0) ||
            this.props.disabled) {
            return;
        }
        if (this.props.selectionMode && this.props.node.selectable !== false) {
            let selectionKeys;
            if (this.isCheckboxSelectionMode()) {
                const checked = this.isChecked();
                selectionKeys = this.props.selectionKeys ? Object.assign({}, this.props.selectionKeys) : {};
                if (checked) {
                    if (this.props.propagateSelectionDown)
                        this.propagateDown(this.props.node, false, selectionKeys);
                    else
                        delete selectionKeys[this._getTreeNodeKey(this.props.node)];
                    if (this.props.propagateSelectionUp && this.props.onPropagateUp) {
                        this.props.onPropagateUp({
                            originalEvent: event,
                            check: false,
                            selectionKeys: selectionKeys
                        });
                    }
                    if (this.props.onUnselect) {
                        this.props.onUnselect({
                            originalEvent: event,
                            node: this.props.node
                        });
                    }
                }
                else {
                    if (this.props.propagateSelectionDown)
                        this.propagateDown(this.props.node, true, selectionKeys);
                    else
                        selectionKeys[this._getTreeNodeKey(this.props.node)] = { checked: true };
                    if (this.props.propagateSelectionUp && this.props.onPropagateUp) {
                        this.props.onPropagateUp({
                            originalEvent: event,
                            check: true,
                            selectionKeys: selectionKeys
                        });
                    }
                    if (this.props.onSelect) {
                        this.props.onSelect({
                            originalEvent: event,
                            node: this.props.node
                        });
                    }
                }
            }
            else {
                const selected = this.isSelected();
                const metaSelection = this.nodeTouched ? false : this.props.metaKeySelection;
                if (metaSelection) {
                    let metaKey = event.metaKey || event.ctrlKey;
                    if (selected && metaKey) {
                        if (this.isSingleSelectionMode()) {
                            selectionKeys = null;
                        }
                        else {
                            selectionKeys = Object.assign({}, this.props.selectionKeys);
                            delete selectionKeys[this._getTreeNodeKey(this.props.node)];
                        }
                        if (this.props.onUnselect) {
                            this.props.onUnselect({
                                originalEvent: event,
                                node: this.props.node
                            });
                        }
                    }
                    else {
                        if (this.isSingleSelectionMode()) {
                            selectionKeys = this._getTreeNodeKey(this.props.node);
                        }
                        else if (this.isMultipleSelectionMode()) {
                            selectionKeys = !metaKey
                                ? {}
                                : this.props.selectionKeys
                                    ? Object.assign({}, this.props.selectionKeys) : {};
                            selectionKeys[this._getTreeNodeKey(this.props.node)] = true;
                        }
                        if (this.props.onSelect) {
                            this.props.onSelect({
                                originalEvent: event,
                                node: this.props.node
                            });
                        }
                    }
                }
                else {
                    if (this.isSingleSelectionMode()) {
                        if (selected) {
                            selectionKeys = null;
                            if (this.props.onUnselect) {
                                this.props.onUnselect({
                                    originalEvent: event,
                                    node: this.props.node
                                });
                            }
                        }
                        else {
                            selectionKeys = this._getTreeNodeKey(this.props.node);
                            if (this.props.onSelect) {
                                this.props.onSelect({
                                    originalEvent: event,
                                    node: this.props.node
                                });
                            }
                        }
                    }
                    else {
                        if (selected) {
                            selectionKeys = Object.assign({}, this.props.selectionKeys);
                            delete selectionKeys[this._getTreeNodeKey(this.props.node)];
                            if (this.props.onUnselect) {
                                this.props.onUnselect({
                                    originalEvent: event,
                                    node: this.props.node
                                });
                            }
                        }
                        else {
                            selectionKeys = this.props.selectionKeys ? Object.assign({}, this.props.selectionKeys) : {};
                            selectionKeys[this._getTreeNodeKey(this.props.node)] = true;
                            if (this.props.onSelect) {
                                this.props.onSelect({
                                    originalEvent: event,
                                    node: this.props.node
                                });
                            }
                        }
                    }
                }
            }
            if (this.props.onSelectionChange) {
                this.props.onSelectionChange({
                    originalEvent: event,
                    value: selectionKeys
                });
            }
        }
        this.nodeTouched = false;
    }
    onDoubleClick(event) {
        if (this.props.onDoubleClick) {
            this.props.onDoubleClick({
                originalEvent: event,
                node: this.props.node
            });
        }
    }
    onRightClick(event) {
        if (this.props.disabled) {
            return;
        }
        DomHandler.clearSelection();
        if (this.props.onContextMenuSelectionChange) {
            this.props.onContextMenuSelectionChange({
                originalEvent: event,
                value: this._getTreeNodeKey(this.props.node)
            });
        }
        if (this.props.onContextMenu) {
            this.props.onContextMenu({
                originalEvent: event,
                node: this.props.node
            });
        }
    }
    propagateUp(event) {
        let check = event.check;
        let selectionKeys = event.selectionKeys;
        let checkedChildCount = 0;
        let childPartialSelected = false;
        for (let child of this.props.node.children) {
            if (selectionKeys[this._getTreeNodeKey(child)] && selectionKeys[this._getTreeNodeKey(child)].checked)
                checkedChildCount++;
            else if (selectionKeys[this._getTreeNodeKey(child)] &&
                selectionKeys[this._getTreeNodeKey(child)].partialChecked)
                childPartialSelected = true;
        }
        if (check && checkedChildCount === this.props.node.children.length) {
            selectionKeys[this._getTreeNodeKey(this.props.node)] = { checked: true, partialChecked: false };
        }
        else {
            if (!check) {
                delete selectionKeys[this._getTreeNodeKey(this.props.node)];
            }
            if (childPartialSelected ||
                (checkedChildCount > 0 && checkedChildCount !== this.props.node.children.length))
                selectionKeys[this._getTreeNodeKey(this.props.node)] = { checked: false, partialChecked: true };
            else
                delete selectionKeys[this._getTreeNodeKey(this.props.node)];
        }
        if (this.props.propagateSelectionUp && this.props.onPropagateUp) {
            this.props.onPropagateUp(event);
        }
    }
    propagateDown(node, check, selectionKeys) {
        if (check)
            selectionKeys[this._getTreeNodeKey(node)] = { checked: true, partialChecked: false };
        else
            delete selectionKeys[this._getTreeNodeKey(node)];
        if (node.children && node.children.length) {
            for (let i = 0; i < node.children.length; i++) {
                this.propagateDown(node.children[i], check, selectionKeys);
            }
        }
    }
    isSelected() {
        if (this.props.selectionMode && this.props.selectionKeys)
            return this.isSingleSelectionMode()
                ? this.props.selectionKeys === this._getTreeNodeKey(this.props.node)
                : this.props.selectionKeys[this._getTreeNodeKey(this.props.node)] !== undefined;
        else
            return false;
    }
    isChecked() {
        return this.props.selectionKeys
            ? this.props.selectionKeys[this._getTreeNodeKey(this.props.node)] &&
                this.props.selectionKeys[this._getTreeNodeKey(this.props.node)].checked
            : false;
    }
    isPartialChecked() {
        return this.props.selectionKeys
            ? this.props.selectionKeys[this._getTreeNodeKey(this.props.node)] &&
                this.props.selectionKeys[this._getTreeNodeKey(this.props.node)].partialChecked
            : false;
    }
    isSingleSelectionMode() {
        return this.props.selectionMode && this.props.selectionMode === "single";
    }
    isMultipleSelectionMode() {
        return this.props.selectionMode && this.props.selectionMode === "multiple";
    }
    isCheckboxSelectionMode() {
        return this.props.selectionMode && this.props.selectionMode === "checkbox";
    }
    isEnabledDragDrop(event) {
        return event.dataTransfer.types.includes(this.props.dragdropScope.toLocaleLowerCase());
    }
    onTouchEnd() {
        this.nodeTouched = true;
    }
    onDropPoint(event, position) {
        stopEvent(event);
        if (this.props.node.droppable !== false) {
            const liElement = event.target.closest("li");
            DomHandler.removeClass(liElement, "p-treenode-dragover");
            DomHandler.removeClass(liElement, "p-treenode-droppoint-active");
            DomHandler.removeClass(liElement, "droppoint-valid");
            DomHandler.removeClass(liElement, "droppoint-invalid");
            if (this.props.onDropPoint) {
                this.props.onDropPoint({
                    originalEvent: event,
                    path: this.props.path,
                    index: this.props.index,
                    position
                });
            }
        }
    }
    onDropPointDragOver(event) {
        if (this.isEnabledDragDrop(event)) {
            event.dataTransfer.dropEffect = "move";
            event.preventDefault();
        }
    }
    onDropPointDragEnter(event) {
        if (this.isEnabledDragDrop(event)) {
            const liElement = event.target.closest("li");
            const liElementClassNameByValidation = this.props.onDropPointDragEnterValidation({
                dropNodeParent: this.props.parent,
                event
            })
                ? "droppoint-valid"
                : "droppoint-invalid";
            DomHandler.addClass(liElement, "p-treenode-droppoint-active");
            DomHandler.addClass(liElement, liElementClassNameByValidation);
        }
    }
    onDropPointDragLeave(event) {
        if (this.props.dragdropScope && this.props.node.droppable !== false) {
            const liElement = event.target.closest("li");
            DomHandler.removeClass(liElement, "p-treenode-dragover");
            DomHandler.removeClass(liElement, "p-treenode-droppoint-active");
            DomHandler.removeClass(liElement, "droppoint-valid");
            DomHandler.removeClass(liElement, "droppoint-invalid");
        }
    }
    onDrop(event) {
        if (this.props.dragdropScope && this.props.node.droppable !== false) {
            DomHandler.removeClass(this.contentElement, "p-treenode-dragover");
            this.removeClassFromParentElement(event);
            event.preventDefault();
            event.stopPropagation();
            if (this.props.onDrop) {
                this.props.onDrop({
                    originalEvent: event,
                    path: this.props.path,
                    index: this.props.index
                });
            }
        }
    }
    onDragOver(event) {
        if (this.isEnabledDragDrop(event) && this.props.node.droppable !== false) {
            event.dataTransfer.dropEffect = "move";
            event.preventDefault();
            event.stopPropagation();
        }
    }
    addClassToParentElement(event) {
        const parentLi = event && event.target ? event.target.closest("li") : undefined;
        return parentLi ? DomHandler.addClass(parentLi, "p-treenode-dragover") : undefined;
    }
    removeClassFromParentElement(event) {
        const parentLi = event && event.target ? event.target.closest("li.p-treenode") : undefined;
        return parentLi ? DomHandler.removeClass(parentLi, "p-treenode-dragover") : undefined;
    }
    onDragEnter(event) {
        if (this.isEnabledDragDrop(event) && this.props.node.droppable !== false) {
            DomHandler.addClass(this.contentElement, "p-treenode-dragover");
            this.addClassToParentElement(event);
        }
    }
    onDragLeave(event) {
        if (this.isEnabledDragDrop(event) && this.props.node.droppable !== false) {
            let rect = event.currentTarget.getBoundingClientRect();
            if (event.nativeEvent.x > rect.left + rect.width ||
                event.nativeEvent.x < rect.left ||
                event.nativeEvent.y >= Math.floor(rect.top + rect.height) ||
                event.nativeEvent.y < rect.top) {
                DomHandler.removeClass(this.contentElement, "p-treenode-dragover");
                this.removeClassFromParentElement(event);
            }
        }
    }
    onDragStart(event) {
        event.dataTransfer.setData("text", this.props.dragdropScope);
        event.dataTransfer.setData(this.props.dragdropScope, this.props.dragdropScope);
        if (this.props.onDragStart) {
            event.stopPropagation();
            this.props.onDragStart({
                originalEvent: event,
                path: this.props.path,
                index: this.props.index
            });
        }
    }
    onDragEnd(event) {
        if (this.props.onDragEnd) {
            event.stopPropagation();
            this.props.onDragEnd({
                originalEvent: event
            });
        }
    }
    renderLabel() {
        let content = React.createElement("span", { className: "p-treenode-label" }, this.props.node.label);
        if (this.props.nodeLabelTemplate) {
            const defaultContentOptions = {
                onTogglerClick: this.onTogglerClick,
                className: "p-treenode-label",
                element: content,
                props: this.props,
                expanded: this.isExpanded()
            };
            content = ObjectUtils.getJSXElement(this.props.nodeLabelTemplate, this.props.node, defaultContentOptions);
        }
        return content;
    }
    renderCheckbox() {
        if (this.isCheckboxSelectionMode() && this.props.node.selectable !== false) {
            const checked = this.isChecked();
            const partialChecked = this.isPartialChecked();
            const className = classNames("p-checkbox-box", {
                "p-highlight": checked,
                "p-indeterminate": partialChecked,
                "p-disabled": this.props.disabled
            });
            const icon = classNames("p-checkbox-icon p-c", { "pi pi-check": checked, "pi pi-minus": partialChecked });
            return (React.createElement("div", { className: "p-checkbox p-component" },
                React.createElement("div", { className: className, role: "checkbox", "aria-checked": checked },
                    React.createElement("span", { className: icon }))));
        }
        return null;
    }
    renderIcon(expanded) {
        let icon = this.props.node.icon || (expanded ? this.props.node.expandedIcon : this.props.node.collapsedIcon);
        if (icon) {
            let className = classNames("p-treenode-icon", icon);
            return React.createElement("span", { className: className });
        }
        return null;
    }
    renderToggler(expanded) {
        let content = (React.createElement(UITreeNodeToggler, { className: "p-tree-toggler p-link", tabIndex: -1, isExpanded: expanded, onChange: this.onTogglerClick }));
        if (this.props.togglerTemplate) {
            const defaultContentOptions = {
                onClick: this.onTogglerClick,
                element: content,
                props: this.props,
                expanded
            };
            content = ObjectUtils.getJSXElement(this.props.togglerTemplate, this.props.node, defaultContentOptions);
        }
        return content;
    }
    renderDropPointTemplate() {
        return this.props.dropPointContentTemplate
            ? ObjectUtils.getJSXElement(this.props.dropPointContentTemplate, {
                onDragOver: this.onDropPointDragOver,
                parent: this.props.parent
            })
            : undefined;
    }
    renderDropPoint(position, isLast) {
        if (this.props.dragdropScope) {
            const className = classNames("p-treenode-droppoint", this.props.node.className, {
                last: isLast
            });
            return (React.createElement("li", { className: className, onDrop: (event) => this.onDropPoint(event, position), onDragOver: this.onDropPointDragOver, onDragEnter: this.onDropPointDragEnter, onDragLeave: this.onDropPointDragLeave }, this.renderDropPointTemplate()));
        }
        return null;
    }
    renderContent() {
        const selected = this.isSelected();
        const checked = this.isChecked();
        const expanded = this.isExpanded();
        const className = classNames("p-treenode-content", this.props.node.className, {
            "p-treenode-selectable": this.props.selectionMode && this.props.node.selectable !== false,
            "p-highlight": this.isCheckboxSelectionMode() ? checked : selected,
            "p-highlight-contextmenu": this.props.contextMenuSelectionKey &&
                this.props.contextMenuSelectionKey === this._getTreeNodeKey(this.props.node),
            "p-disabled": this.props.disabled
        });
        const tabIndex = this.props.disabled ? undefined : 0;
        return (React.createElement("div", { ref: (el) => (this.contentElement = el), className: className, style: this.props.node.style, onClick: this.onClick, onDoubleClick: this.onDoubleClick, onContextMenu: this.onRightClick, onTouchEnd: this.onTouchEnd, draggable: this.props.dragdropScope && this.props.node.draggable !== false && !this.props.disabled, onDrop: this.onDrop, onDragOver: this.onDragOver, onDragEnter: this.onDragEnter, onDragLeave: this.onDragLeave, onDragStart: this.onDragStart, onDragEnd: this.onDragEnd, tabIndex: tabIndex, onKeyDown: this.onNodeKeyDown, role: "treeitem", "aria-posinset": this.props.index + 1, "aria-expanded": expanded, "aria-selected": checked || selected }, this.props.nodeTemplate ? (ObjectUtils.getJSXElement(this.props.nodeTemplate, {
            changeActiveDnD: this.props.changeActiveDnD,
            children: this.renderChildren(),
            expanded,
            index: this.props.index,
            node: this.props.node,
            path: this.props.path,
            onTogglerClick: this.onTogglerClick
        })) : (React.createElement(React.Fragment, null,
            this.renderToggler(expanded),
            this.renderCheckbox(),
            this.renderIcon(expanded),
            this.renderLabel(),
            this.renderChildren()))));
    }
    renderChildren() {
        if (this.props.node.children && this.props.node.children.length && this.isExpanded()) {
            return (React.createElement("ul", { className: "p-treenode-children", role: "group" }, this.props.node.children.map((childNode, index) => {
                return (React.createElement(UITreeNode, { key: getUITreeNodeKey(childNode, this.props.nodeKeyName), changeActiveDnD: this.props.changeActiveDnD, className: getAdditionalClassName(childNode, this.props.nodeClassNameFunc), node: childNode, parent: this.props.node, index: index, last: index === this.props.node.children.length - 1, path: this.props.path + "-" + index, disabled: this.props.disabled, dropPointContentTemplate: this.props.dropPointContentTemplate, selectionMode: this.props.selectionMode, selectionKeys: this.props.selectionKeys, onSelectionChange: this.props.onSelectionChange, metaKeySelection: this.props.metaKeySelection, nodeClassNameFunc: this.props.nodeClassNameFunc, nodeKeyName: this.props.nodeKeyName, propagateSelectionDown: this.props.propagateSelectionDown, propagateSelectionUp: this.props.propagateSelectionUp, contextMenuSelectionKey: this.props.contextMenuSelectionKey, onContextMenuSelectionChange: this.props.onContextMenuSelectionChange, onContextMenu: this.props.onContextMenu, onExpand: this.props.onExpand, onCollapse: this.props.onCollapse, onSelect: this.props.onSelect, onUnselect: this.props.onUnselect, expandedKeys: this.props.expandedKeys, onToggle: this.props.onToggle, onPropagateUp: this.propagateUp, nodeTemplate: this.props.nodeTemplate, nodeLabelTemplate: this.props.nodeLabelTemplate, togglerTemplate: this.props.togglerTemplate, isNodeLeaf: this.props.isNodeLeaf, dragdropScope: this.props.dragdropScope, onDragStart: this.props.onDragStart, onDragEnd: this.props.onDragEnd, onDrop: this.props.onDrop, onDropPoint: this.props.onDropPoint, onDropPointDragEnterValidation: this.props.onDropPointDragEnterValidation }));
            })));
        }
        return null;
    }
    renderNode() {
        const content = this.renderContent();
        const className = classNames("p-treenode", {
            "p-treenode-leaf": this.isLeaf(),
            "p-treenode-with-children": this.props.node.children && this.props.node.children.length && this.isExpanded()
        }, this.props.node.className, this.props.className);
        return (React.createElement("li", { className: className, style: this.props.node.style }, content));
    }
    render() {
        const node = this.renderNode();
        if (this.props.dragdropScope && !this.props.disabled) {
            const beforeDropPoint = this.renderDropPoint(-1);
            const afterDropPoint = this.props.last ? this.renderDropPoint(1, true) : null;
            return (React.createElement(React.Fragment, null,
                beforeDropPoint,
                node,
                afterDropPoint));
        }
        else {
            return node;
        }
    }
}
UITreeNode.defaultProps = {
    changeActiveDnD: null,
    className: "",
    node: null,
    index: null,
    last: null,
    parent: null,
    path: null,
    disabled: false,
    dropPointContentTemplate: null,
    selectionMode: null,
    selectionKeys: null,
    contextMenuSelectionKey: null,
    metaKeySelection: true,
    expandedKeys: null,
    propagateSelectionUp: true,
    propagateSelectionDown: true,
    dragdropScope: null,
    ariaLabel: null,
    ariaLabelledBy: null,
    nodeKeyName: TREE_NODE_KEY_DEFAULT_PARAM_NAME,
    nodeTemplate: null,
    nodeLabelTemplate: null,
    togglerTemplate: null,
    isNodeLeaf: null,
    onSelect: null,
    onUnselect: null,
    onExpand: null,
    onCollapse: null,
    onToggle: null,
    onSelectionChange: null,
    onContextMenuSelectionChange: null,
    onPropagateUp: null,
    onDragStart: null,
    onDragEnd: null,
    onDrop: null,
    onDropPoint: null,
    onDropPointDragEnterValidation: null,
    onContextMenu: null,
    onNodeClick: null,
    onNodeDoubleClick: null
};
UITreeNode.propTypes = {
    changeActiveDnD: PropTypes.func,
    className: PropTypes.string,
    node: PropTypes.object,
    index: PropTypes.number,
    last: PropTypes.bool,
    parent: PropTypes.object,
    path: PropTypes.string,
    disabled: PropTypes.bool,
    selectionMode: PropTypes.string,
    selectionKeys: PropTypes.any,
    contextMenuSelectionKey: PropTypes.any,
    metaKeySelection: PropTypes.bool,
    expandedKeys: PropTypes.object,
    propagateSelectionUp: PropTypes.bool,
    propagateSelectionDown: PropTypes.bool,
    dragdropScope: PropTypes.string,
    ariaLabel: PropTypes.string,
    ariaLabelledBy: PropTypes.string,
    nodeKeyName: PropTypes.string,
    nodeTemplate: PropTypes.any,
    nodeLabelTemplate: PropTypes.any,
    togglerTemplate: PropTypes.func,
    isNodeLeaf: PropTypes.func,
    onSelect: PropTypes.func,
    onUnselect: PropTypes.func,
    onExpand: PropTypes.func,
    onCollapse: PropTypes.func,
    onToggle: PropTypes.func,
    onSelectionChange: PropTypes.func,
    onContextMenuSelectionChange: PropTypes.func,
    onPropagateUp: PropTypes.func,
    onDragStart: PropTypes.func,
    onDragEnd: PropTypes.func,
    onDrop: PropTypes.func,
    onDropPoint: PropTypes.func,
    onDropPointDragEnterValidation: PropTypes.func,
    onContextMenu: PropTypes.func,
    onNodeClick: PropTypes.func,
    onNodeDoubleClick: PropTypes.func
};
