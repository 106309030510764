var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { Button, WizardBlock } from "./styled";
import { ButtonIcon } from "./ButtonIcon";
export const Wizard = (_a) => {
    var { currentStep, isValid, onChangeStep, step1Label, step2Label } = _a, restProps = __rest(_a, ["currentStep", "isValid", "onChangeStep", "step1Label", "step2Label"]);
    const isActive = (step) => currentStep === step;
    return (React.createElement(WizardBlock, Object.assign({}, restProps),
        React.createElement(Button, { active: isActive(1), checked: isValid, onClick: () => onChangeStep === null || onChangeStep === void 0 ? void 0 : onChangeStep(1) },
            React.createElement(ButtonIcon, { active: isActive(1), checked: isValid }),
            step1Label),
        React.createElement(Button, { active: isActive(2), checked: isValid, onClick: () => onChangeStep === null || onChangeStep === void 0 ? void 0 : onChangeStep(2) },
            React.createElement(ButtonIcon, { active: isActive(2) }),
            step2Label)));
};
