import { handleActions } from "redux-actions";
import { addCookie, changeCookie, changeCookieParamsField, deleteCookie, setCookies, setCookiesData } from "../actions/testProjectSettingsSetCookies";
export const initialState = {
    clearEachIteration: false,
    id: "",
    items: [],
    label: ""
};
export default handleActions({
    [addCookie]: (state, action) => (Object.assign(Object.assign({}, state), { items: action && action.payload ? [...state.items, action.payload] : state.items })),
    [changeCookie]: (state, action) => (Object.assign(Object.assign({}, state), { items: action && action.payload
            ? state.items.map((item) => (item.id === action.payload.id ? action.payload : item))
            : state.items })),
    [changeCookieParamsField]: (state, { payload }) => {
        const { field, value } = payload || {};
        return field ? Object.assign(Object.assign({}, state), { [field]: value }) : state;
    },
    [deleteCookie]: (state, { payload }) => payload
        ? Object.assign(Object.assign({}, state), { items: [...state.items.filter((item) => item.id !== payload)] }) : state,
    [setCookies]: (state, action) => (Object.assign(Object.assign({}, state), { items: action && Array.isArray(action.payload) ? action.payload : state.items })),
    [setCookiesData]: (state, action) => (Object.assign(Object.assign({}, state), (action.payload ? action.payload : {})))
}, initialState);
export const getSettingsSetDefaultCookiesData = (state) => state && state.testProjectSettingsSetCookies ? state.testProjectSettingsSetCookies : initialState;
const getStateParam = (state, paramName) => getSettingsSetDefaultCookiesData(state)[paramName];
export const getSettingsSetDefaultCookieItems = (state) => getStateParam(state, "items");
export const getSettingsSetDefaultCookiesClearEachIteration = (state) => getStateParam(state, "clearEachIteration");
