var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { call, put, takeEvery } from "redux-saga/effects";
import { clearTeamDataFlow, createNewTeam, updateExistTeam } from "../team";
import { getResponseErrorData } from "../../../helpers/errors";
import { NEW_ITEM_ID } from "../../../helpers/routes";
import { TeamMemberPermission } from "../../../helpers/types";
import { createAdminTeam, getAdminTeam, updateAdminTeam } from "../../../services/authSrv";
import { teamFailure, teamSavingFailure, teamSuccess } from "../../actions/team";
import { adminTeamRequest, adminTeamSavingRequest } from "../../actions/admin/team";
function* refreshTeamData(id) {
    try {
        const { data } = yield call(getAdminTeam, id);
        yield put(teamSuccess(Object.assign(Object.assign({}, data), { id, permissionList: [TeamMemberPermission.Admin] })));
    }
    catch (e) {
        yield put(teamFailure(getResponseErrorData(e)));
    }
}
export function* adminTeamGettingDataFlow(action) {
    try {
        const { id } = action && action.payload ? action.payload : {};
        return id === NEW_ITEM_ID ? yield* clearTeamDataFlow() : yield* refreshTeamData(id);
    }
    catch (e) {
        yield put(teamFailure(getResponseErrorData(e)));
    }
}
function* createNewAdminTeam(params) {
    yield* createNewTeam(createAdminTeam, params);
}
function* updateExistAdminTeam(params) {
    yield* updateExistTeam(updateAdminTeam, params);
}
function* adminTeamSavingFlow(action) {
    try {
        const _a = action && action.payload ? action.payload : {}, { id } = _a, restParams = __rest(_a, ["id"]);
        return id === NEW_ITEM_ID ? yield* createNewAdminTeam(restParams) : yield* updateExistAdminTeam(action.payload);
    }
    catch (e) {
        yield put(teamSavingFailure(getResponseErrorData(e)));
    }
}
export function* adminTeamFlow() {
    yield takeEvery(adminTeamRequest, adminTeamGettingDataFlow);
    yield takeEvery(adminTeamSavingRequest, adminTeamSavingFlow);
}
