import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { Color, FontBodyMediumBold } from "@boomq/uikit";
import { withDisable } from "../global";
import { media } from "../response";
export const AddingIcon = styled.i `
    position: relative;
    display: block;
    width: 12px;
    height: 12px;
    margin-right: 12px;
    &:before,
    &:after {
        content: "";
        display: block;
        width: 12px;
        height: 2px;
        background: #bdbdbd;
        border-radius: 3px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: all 0.3s ease;
    }
    &:after {
        transform: translate(-50%, -50%) rotate(90deg);
    }
`;
export const AddingSection = styled.div `
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    font-size: 14px;
    color: #bdbdbd;
    border: 2px dashed #e0e0e0;
    border-radius: 5px;
    margin: 10px 0;
    transition: all 0.3s ease;
    &:hover {
        color: ${Color.blue};
        border-color: ${Color.blue};
        ${AddingIcon} {
            &:before,
            &:after {
                background: ${Color.blue};
            }
        }
    }
    &:active {
        transform: scale(0.98);
    }
    ${media.tablet} {
        margin-top: 0;
    }
`;
export const StyledButton = styled.button `
    ${withDisable};
    cursor: pointer;
    display: inline-block;
    font-style: normal;
    font-weight: 500;
    line-height: ${(props) => (!props || !props.bordered ? "27px" : "18px")};
    font-size: 18px;
    font-family: Montserrat;
    padding: 12px 30px;
    outline: none;
    border-radius: 100px;
    transition: all 0.3s ease;
    color: ${(props) => !props || (!props.bordered && !props.textButton) ? Color.white : Color.blue};
    background: ${(props) => !props || (!props.bordered && !props.textButton) ? Color.blue : "transparent"};
    border: 2px solid ${(props) => (!props || !props.bordered ? "transparent" : Color.blue)};
    min-width: 140px;
    text-align: center;
    user-select: none;
    &:hover {
        color: ${(props) => (!props || !props.bordered ? Color.blue : Color.white)};
        background: ${(props) => (!props || !props.bordered ? Color.white : Color.blue)};
        box-shadow: 0 0 15px rgba(0, 55, 120, 0.3);
        border-color: transparent;
        ${(props) => props && props.textButton
    ? css `
                      border: 2px solid ${Color.blue};
                  `
    : ""}
    }
    &:hover svg {
        fill: ${(props) => (!props || !props.bordered ? Color.blue : Color.white)};
    }
    &:active {
        transform: scale(0.9);
    }
    ${media.giant} {
        padding: 12px 30px;
    }
    ${media.desktop} {
        font-size: 16px;
        padding: 10px 20px;
    }
    ${(props) => (props && props.theme ? css(props.theme) : "")}
`;
export const StyledButtonWithLink = StyledButton.withComponent("a");
export const StyledButtonWithRoute = StyledButton.withComponent(Link);
export const StyledAdminButton = styled(Link) `
    ${FontBodyMediumBold}
    border: 2px solid ${Color.darkGrey35};
    border-radius: 6px;
    color: ${Color.darkGrey35};
    padding: 6px 12px;
    transition: all 0.3s ease-in-out;
    &:active {
        transform: scale(0.9);
    }
    &:hover {
        border-color: ${Color.blue};
        color: ${Color.blue};
    }
`;
export const StyledStopButton = styled.button `
    background: transparent;
    border: none;
    color: ${Color.red};
    font-size: 14px;
    font-weight: normal;
    line-height: normal;
    margin-top: 15px;
    outline: none;
    text-decoration-line: underline;
`;
