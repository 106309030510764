import styled from "styled-components";
import { Color, IconLoader } from "@boomq/uikit";
export const LoaderBlock = styled.div `
    align-items: center;
    display: flex;
    justify-content: center;
`;
export const RunningTestList = styled.ul `
    margin-bottom: 24px;
    margin-top: 8px;
`;
export const StyledIconLoader = styled(IconLoader) `
    fill: ${Color.blue};
    height: 16px;
    width: 16px;
`;
