import { connect } from "react-redux";
import {
    AllSlaReportProfiles,
    editSlaReportProfile,
    getClientPermissionList,
    getIsAuthorize,
    getIsShowLoader,
    getSelectedTeamId,
    getSlaReportProfilesCurrentPage,
    getSlaReportProfiles,
    getSlaReportProfilesPagesCount,
    setCurrentPage,
    setTitle,
    slaReportProfilesRequest,
    withResize
} from "@boomq/common";
import { SlaReportProfilesItemAction } from "../../../helpers/types";

const connector = connect(
    (state) => ({
        currentPage: getSlaReportProfilesCurrentPage(state),
        isAuthorized: getIsAuthorize(state),
        isShowLoader: getIsShowLoader(state),
        isSlaReportProfileEnabled: true,
        permissionList: getClientPermissionList(state),
        selectedTeamId: getSelectedTeamId(state),
        slaReportProfiles: getSlaReportProfiles(state),
        slaReportProfilesPagesCount: getSlaReportProfilesPagesCount(state)
    }),
    {
        [SlaReportProfilesItemAction.Add]: editSlaReportProfile,
        onChangePage: setCurrentPage,
        onRefresh: slaReportProfilesRequest,
        setTitle
    }
);

export default withResize(connector(AllSlaReportProfiles));
