export const THREAD_GROUP_ACTION_COPY_TITLE = {
    defaultMessage: "Copy",
    id: "account.test.profile.thread.group.actions.copy"
};
export const THREAD_GROUP_ACTION_DELETE_TITLE = {
    defaultMessage: "Delete",
    id: "account.test.profile.thread.group.actions.delete"
};
export const THREAD_GROUP_ACTION_EDIT_TITLE = {
    defaultMessage: "Edit",
    id: "account.test.profile.thread.group.actions.edit"
};
