import { handleActions } from 'redux-actions';
import { rolesFailure, rolesSuccess, rolesRequest } from '../../actions/admin/roles';
const initialState = {
    error: null,
    isFetching: false,
    roles: []
};
export default handleActions({
    [rolesRequest]: (state) => (Object.assign(Object.assign({}, state), { isFetching: true, roles: [] })),
    [rolesSuccess]: (state, action) => (Object.assign(Object.assign({}, state), { isFetching: false, roles: action.payload })),
    [rolesFailure]: (state, action) => (Object.assign(Object.assign({}, state), { isFetching: false, error: action.payload }))
}, initialState);
export const getListOfRoles = state => state.roles.roles;
export const getRolesLoading = state => state.roles.isFetching;
