import React from "react";
import { getValueByPath } from "@boomq/utils";
import { UltimateThreadGroup as CommonUltimateThreadGroup, RequestGroupTitle } from "@boomq/common";
import {
    DEFAULT_CHANGE_REQUEST_GROUP,
    DEFAULT_REQUEST_GROUP,
    DEFAULT_THREAD_GROUP_MODE,
    DEFAULT_TOGGLE_ENABLED_GROUP
} from "./constants";
import { UltimateThreadGroupLoadProfileStep } from "../UltimateThreadGroupLoadProfileStep";
import { getThreadGroupTitleTemplateByTypeAndMode } from "../../../../helpers/requestGroupJMX";
import { Mode, RequestGroupType } from "../../../../helpers/types";

const getGroupCaption = ({
    changeRequestGroup = DEFAULT_CHANGE_REQUEST_GROUP,
    mode = DEFAULT_THREAD_GROUP_MODE,
    requestGroup = DEFAULT_REQUEST_GROUP,
    toggleEnabledGroup = DEFAULT_TOGGLE_ENABLED_GROUP
}): JSX.Element => {
    const CaptionComponent: any = getThreadGroupTitleTemplateByTypeAndMode(RequestGroupType.Ultimate2, mode);

    const changeRequestGroupName = (value): void => changeRequestGroup({ field: "name", id: requestGroup.id, value });

    const toggleRequestGroup = (): void => toggleEnabledGroup(requestGroup.id);

    return (
        <CaptionComponent
            requestGroup={requestGroup}
            onChangeName={changeRequestGroupName}
            onToggle={toggleRequestGroup}
        />
    );
};

const UltimateThreadGroup = (props): JSX.Element => {
    const { onChangeLoadProfileSetting, loadProfile, mode, requestGroup, settings } = props;

    const changeThreadGroupStep = ({ stepId, field, value }): void =>
        typeof onChangeLoadProfileSetting === "function"
            ? props.onChangeLoadProfileSetting({
                groupId: requestGroup.id,
                stepId,
                settingName: field,
                value
            })
            : undefined;

    const copyThreadGroupStep = (id): void =>
        props && typeof props.copyStepInGroup === "function"
            ? props.copyStepInGroup({ groupId: requestGroup.id, stepId: id })
            : undefined;

    const deleteThreadGroupStep = (id): void =>
        props && typeof props.copyStepInGroup === "function"
            ? props.deleteStepInGroup({ groupId: requestGroup.id, stepId: id })
            : undefined;

    const isDisabledSettings = (): boolean => (mode === Mode.View ? true : false);

    return (
        <RequestGroupTitle {...props} caption={getGroupCaption(props)}>
            <CommonUltimateThreadGroup {...props}>
                {getValueByPath(loadProfile, "steps", []).map((step) => (
                    <UltimateThreadGroupLoadProfileStep
                        handleStyle={props.handleStyle}
                        isDisabledSettings={isDisabledSettings()}
                        key={step.id}
                        loadProfile={loadProfile}
                        onChange={changeThreadGroupStep}
                        onCopy={copyThreadGroupStep}
                        onDelete={deleteThreadGroupStep}
                        step={step}
                        testSettings={settings}
                        trackStyle={props.trackStyle}
                    />
                ))}
            </CommonUltimateThreadGroup>
        </RequestGroupTitle>
    );
};

export default UltimateThreadGroup;
