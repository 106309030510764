import React, { useEffect } from "react";
import { connect } from "react-redux";
import { UsersPaginationWrapper } from "./styled";
import UserItem from "../../../admin/UserItem";
import Pagination from "../../../common/Pagination";
import { Padding } from "../../../styled/Wrappers";
import { usersRequest } from "../../../../redux/actions/admin/users";
import { getListOfUsers, getUsersCurrentPage, getUsersTotalPages } from "../../../../redux/reducers/admin/users";
const connector = connect((state) => ({
    currentPage: getUsersCurrentPage(state),
    listOfUsers: getListOfUsers(state),
    totalPages: getUsersTotalPages(state)
}), { onInit: usersRequest, onChangePage: usersRequest });
const Users = ({ listOfUsers, currentPage, onInit, onChangePage, totalPages }) => {
    useEffect(() => {
        const init = () => onInit === null || onInit === void 0 ? void 0 : onInit({ page: 0 });
        init();
    }, []);
    const clickHandler = (page) => onChangePage === null || onChangePage === void 0 ? void 0 : onChangePage({ page });
    return (React.createElement(Padding, null,
        Array.isArray(listOfUsers) && listOfUsers.map((user) => React.createElement(UserItem, Object.assign({ key: user.id }, user))),
        totalPages > 1 && (React.createElement(UsersPaginationWrapper, null,
            React.createElement(Pagination, { currentPage: currentPage, totalPages: totalPages, clickHandler: clickHandler })))));
};
export default connector(Users);
