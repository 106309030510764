var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { assertionListValidator } from "./assertion";
import { extractorListValidator } from "./extractor";
import { headerItemListValidator } from "./header";
import { parameterNameListValidator } from "./parameters";
import { requestTimersValidator } from "./timers";
import { requestGrpcParamList } from "../../models/request/grpc";
import { requestManualParamList } from "../../models/request/http";
const requestAssertionsSectionValidator = (assertions) => {
    const _a = assertionListValidator(assertions), { isValid } = _a, restData = __rest(_a, ["isValid"]);
    return Object.assign({ isValidAssertions: isValid, assertionsSectionName: requestManualParamList[4].name }, restData);
};
const requestExtractorsSectionValidator = (extractResponse) => {
    const _a = extractorListValidator(extractResponse), { isValid } = _a, restData = __rest(_a, ["isValid"]);
    return Object.assign({ isValidExtractors: isValid, extractorsSectionName: requestManualParamList[3].name }, restData);
};
const requestHeadersSectionValidator = (headers) => {
    const _a = headerItemListValidator(headers), { isValid } = _a, restData = __rest(_a, ["isValid"]);
    return Object.assign({ isValidHeaders: isValid, headersSectionName: requestManualParamList[1].name }, restData);
};
const getInvalidParamsSectionName = (validatedSections) => Object.keys(validatedSections).find((validatedSectionName) => !validatedSections[validatedSectionName]);
export const requestValidator = ({ assertions, extractResponse, headers, metadata }) => {
    const { assertionsSectionName, isValidAssertions, validatedAssertionList } = requestAssertionsSectionValidator(assertions);
    const { extractorsSectionName, isValidExtractors, validatedExtractorList } = requestExtractorsSectionValidator(extractResponse);
    const { headersSectionName, isValidHeaders, validatedItemList: validatedHeaderList } = requestHeadersSectionValidator(headers);
    const { isValidHeaders: isValidMetadata, validatedItemList: validatedMetadataList } = requestHeadersSectionValidator(metadata);
    return {
        invalidParamsSectionName: getInvalidParamsSectionName({
            [assertionsSectionName]: isValidAssertions,
            [extractorsSectionName]: isValidExtractors,
            [headersSectionName]: isValidHeaders,
            [requestGrpcParamList[0].name]: isValidMetadata
        }),
        isValid: isValidAssertions && isValidExtractors && isValidHeaders,
        validatedAssertionList,
        validatedExtractorList,
        validatedHeaderList,
        validatedMetadataList
    };
};
export const requestFormValidator = ({ requestParameters = [], timerList }) => {
    const requestParameterNames = requestParameters
        .filter((parameter) => parameter.name !== "")
        .map((parameter) => parameter.name);
    const validationResult = parameterNameListValidator(requestParameterNames);
    return validationResult.isValid ? requestTimersValidator(timerList) : validationResult;
};
