import styled, { css } from "styled-components";
import ReactSVG from "react-svg";
export const HintButton = styled.button `
    background: transparent;
    border-radius: 50%;
    border: 1px solid #000;
    cursor: help;
    font-size: 10px;
    font-weight: 600;
    height: 16px;
    line-height: 16px;
    min-width: 16px;
    outline: none;
    width: 16px;
    ${(props) => (props && props.theme ? css(props.theme) : "")}
`;
export const HintIcon = styled(ReactSVG) `
    cursor: help;
    line-height: 0px;
    ${(props) => (props && props.theme ? css(props.theme) : "")}
`;
