import React from "react";
import { useIntl } from "@boomq/utils";
import { Switcher } from "@boomq/uikit";
import { RUN_TEST_BUTTON_TITLE_MESSAGE, SWITCHER_LABEL_MESSAGE } from "./intl";
import { TestModeSwitcherSection } from "./styled";
const getComponentTitle = (formatMessage) => typeof formatMessage === "function" ? formatMessage(RUN_TEST_BUTTON_TITLE_MESSAGE) : "";
export const TestModeSwitcher = (props) => {
    const { formatMessage } = useIntl();
    const toggleHandler = (e) => e && e.target && props && typeof props.onToggle === "function" ? props.onToggle(e.target.checked) : undefined;
    return (React.createElement(TestModeSwitcherSection, { title: getComponentTitle(formatMessage) },
        React.createElement(Switcher, { onChange: toggleHandler, defaultChecked: props.value }),
        formatMessage(SWITCHER_LABEL_MESSAGE)));
};
