import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { isEmpty } from "@boomq/utils";
import {
    EDITORJS_HOLDER_ID,
    ReportContainer as CommonReportContainer,
    deleteReportPflbChart,
    deleteReportPflbTable,
    getIsAuthorize,
    getReportBlocks,
    getSelectedTeamId,
    refreshReportDataRequest,
    updateReportRequest,
    useEditorJS
} from "@boomq/common";
import { PFLBChartConnectedToStore } from "../../../../components/account/Report/PFLBChartPlugin";
import { PFLBTableConnectedToStore } from "../../../../components/account/Report/PFLBTablePlugin";

export const ReportContainer: FC<any> = (props): JSX.Element => {
    const { params } = useRouteMatch<any>();

    const dispatch = useDispatch();

    const blocks = useSelector(getReportBlocks);
    const isAuthorized = useSelector(getIsAuthorize);
    const selectedTeamId = useSelector(getSelectedTeamId);

    const { editorInstance, initEditorJS } = useEditorJS(blocks);

    useEffect(() => {
        const init = (): void => {
            initEditorJS({
                holderId: EDITORJS_HOLDER_ID,
                initialData: { blocks: [] },
                extendedTools: {
                    pflbChart: {
                        class: PFLBChartConnectedToStore,
                        config: {
                            onDeleteBlock: (id) => dispatch(deleteReportPflbChart(id))
                        }
                    },
                    pflbTable: {
                        class: PFLBTableConnectedToStore,
                        config: {
                            onDeleteBlock: (id) => dispatch(deleteReportPflbTable(id)),
                            rows: 2,
                            cols: 3
                        },
                        inlineToolbar: true
                    }
                }
            });

            return dispatch(refreshReportDataRequest(params?.report_id));
        };

        const checkAuthorization = (): void => (isAuthorized && !isEmpty(selectedTeamId) ? init() : undefined);

        checkAuthorization();
    }, [isAuthorized, selectedTeamId]);

    const clickSaveHandler = (): Promise<any> | undefined =>
        editorInstance.current
            ?.save()
            .then((outputData) => dispatch(updateReportRequest({ id: params.report_id, ...outputData })))
            .catch(() => undefined);

    return (
        <CommonReportContainer
            isAuthorized={isAuthorized}
            onClickSaveButton={clickSaveHandler}
            selectedTeamId={selectedTeamId}
            {...props}
        />
    );
};
