import { call, put, race, take, takeEvery } from "redux-saga/effects";
import { TEST_DETAILS_MAX_REQUEST_COUNT, TEST_DETAILS_TIMEOUT_MS } from "./constants";
import { refreshTestRunsDataFlow } from "../allTests";
import { loopWithStopAction } from "../core";
import { testDetailsRequestFlow } from "../testDetails";
import clientActions from "../../actions/client";
import { stopTestRequest, stopTestFailure, stopTestSuccess } from "../../actions/runningTests";
import { closeModal } from "../../actions/modal";
import { setTestDetailsData, stopTestInDetailsRequest, testDetailsRequestStop } from "../../actions/testDetails";
import { getResponseErrorData } from "../../../helpers/errors";
import { isTestStopping } from "../../../helpers/testStatus";
import { getClientTest, stopClientTest } from "../../../services/testSrv";
function* stopTest(params) {
    const { id } = params || {};
    yield put(closeModal());
    try {
        const { data } = yield call(stopClientTest, id);
        yield put(stopTestSuccess({ id }));
        return data;
    }
    catch (e) {
        yield put(stopTestFailure(Object.assign({ id }, getResponseErrorData(e))));
    }
}
function* refreshTestDetailsDataByParams(isContinuous, testId) {
    return isContinuous ? undefined : yield* testDetailsRequestFlow({ payload: { testId } });
}
function* getStoppingTestDetails(params) {
    var _a;
    const { testId } = params || {};
    const { testDetailsResponse, stopAction } = testId
        ? yield race({
            resultLogFilesResponse: call(getClientTest, testId),
            stopAction: take(testDetailsRequestStop)
        })
        : { stopAction: true };
    const displayState = (_a = testDetailsResponse === null || testDetailsResponse === void 0 ? void 0 : testDetailsResponse.data) === null || _a === void 0 ? void 0 : _a.displayState;
    const isContinuous = stopAction || !isTestStopping(displayState) ? false : true;
    yield* refreshTestDetailsDataByParams(isContinuous, testId);
    return {
        isContinuous
    };
}
function* getStoppingTestDetailsWithDelay(testId) {
    yield* loopWithStopAction({
        delayTime: TEST_DETAILS_TIMEOUT_MS,
        isDelayBeforeStart: true,
        loopFunc: getStoppingTestDetails,
        maxCount: TEST_DETAILS_MAX_REQUEST_COUNT,
        params: { testId },
        stopAction: testDetailsRequestStop
    });
}
function* stopTestInDetails(action) {
    try {
        const { id, testProjectId } = action && action.payload ? action.payload : {};
        const data = yield* stopTest(action.payload);
        const displayState = data === null || data === void 0 ? void 0 : data.displayState;
        yield put(setTestDetailsData({ displayState, id, testProjectId }));
        return isTestStopping(displayState)
            ? yield* getStoppingTestDetailsWithDelay(id)
            : yield* testDetailsRequestFlow({ payload: { testId: id } });
    }
    catch (e) {
        console.error(e);
    }
}
function* stopTestInRunningTests(action) {
    try {
        yield* stopTest(action.payload);
        yield put(clientActions.getTestRunsDataWithSocketRequestStop());
        yield* refreshTestRunsDataFlow();
    }
    catch (e) {
        console.error(e);
    }
}
export function* runningTestsFlow() {
    yield takeEvery(stopTestInDetailsRequest, stopTestInDetails);
    yield takeEvery(stopTestRequest, stopTestInRunningTests);
}
