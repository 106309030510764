import styled from "styled-components";
export const ActionSection = styled.div `
    align-items: center;
    cursor: pointer;
    display: flex;
    position: absolute;
    right: 45px;
    top: 17px;
    > svg {
        margin-right: 10px;
    }
    > svg path {
        fill: #fff;
    }
`;
