import { handleActions } from "redux-actions";
import { LOCATION_CHANGE } from "connected-react-router";
export const initialState = {
    currentLocation: null,
    previousLocation: null,
};
export default handleActions({
    [LOCATION_CHANGE]: (state, action) => (Object.assign(Object.assign({}, state), { previousLocation: state.currentLocation, currentLocation: action.payload.location.pathname }))
}, initialState);
export const getPreviousRouterLocation = (state) => state.location.previousLocation;
