import React from "react";
import { ButtonSize } from "@boomq/uikit";
import { getClassNameByParams, ApplyButton, StyledIconClose, StyledIconSettings, StyledSidebar, TableHeader, TableTypeSelect, TestIdSelect, Title } from "./styled";
import { EditableTitle } from "../../../common/EditableTitle";
export const PFLBTableSettings = ({ applyButtonLabel, isShowParamsSidebar, onChangeTableTitle, onChangeTableType, onChangeTestId, onClickApply, onClickSidebarCloseIcon, onToggleSidebar, tableTitle, tableType, tableTypeItems, tableTypeSelectLabel, tableTypeSelectPlaceholder, testId, testIdSelectLabel, testIdSelectPlaceholder, testList }) => (React.createElement(React.Fragment, null,
    React.createElement(TableHeader, { className: "table-header" },
        React.createElement(StyledIconSettings, { className: "settings-button", onClick: onToggleSidebar }),
        React.createElement(EditableTitle, { onBlur: onChangeTableTitle, value: tableTitle })),
    React.createElement(StyledSidebar, { className: getClassNameByParams(isShowParamsSidebar) },
        React.createElement(StyledIconClose, { onClick: onClickSidebarCloseIcon }),
        React.createElement(Title, null, tableTitle),
        React.createElement(TestIdSelect, { defaultValue: testId, items: testList, label: testIdSelectLabel, name: "testId", onChange: onChangeTestId, placeholder: testIdSelectPlaceholder, titleAttrName: "comment" }),
        React.createElement(TableTypeSelect, { defaultValue: tableType, items: tableTypeItems, label: tableTypeSelectLabel, onChange: onChangeTableType, placeholder: tableTypeSelectPlaceholder }),
        React.createElement(ApplyButton, { disabled: !tableType || !testId, size: ButtonSize.medium, onClick: onClickApply }, applyButtonLabel))));
