import { handleActions } from "redux-actions";
import authorizeActions from "../../actions/authorize";
import clientActions from "../../actions/client";
import { ImportDataSourceType } from "../../../models/importData";
const { logout } = authorizeActions;
const { websitesSuccess } = clientActions;
export const initialState = {
    [ImportDataSourceType.Google]: {},
    YANDEX: {}
};
export default handleActions({
    [logout]: () => (Object.assign({}, initialState)),
    [websitesSuccess]: (state, { payload }) => (Object.assign(Object.assign({}, state), Object.keys(state).reduce((res, providerType) => (Object.assign(Object.assign({}, res), { [providerType]: payload && payload.data && providerType === payload.type
            ? Object.assign(Object.assign({}, state[providerType]), { [payload.identityUserId]: payload.data }) : state[providerType] })), {})))
}, initialState);
