import React from "react";
import { DescBlock, PseudoInput, StyledLabel, StyledRadio } from "./styled";
const Radio = ({ checked, children, defaultChecked, disabled, name, onClick, theme, value }) => {
    const changeHandler = () => undefined;
    const clickHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();
        return typeof onClick === "function" ? onClick(value) : undefined;
    };
    return (React.createElement(StyledLabel, { onClick: clickHandler, theme: theme },
        React.createElement(PseudoInput, { type: "radio", name: name, checked: checked, defaultChecked: defaultChecked, disabled: disabled, onChange: changeHandler }),
        React.createElement(StyledRadio, { disabled: disabled }),
        React.createElement(DescBlock, null, children)));
};
export default Radio;
