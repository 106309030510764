import styled from "styled-components";
import { IconCheck, IconClose, IconPlus } from "../Icons";
import { InputText } from "../InputText";
import { Color } from "../styled/colorPallete";
import { FontBodyMediumBold, FontCaption } from "../styled/typography";
export const AutoCompleteInput = styled(InputText) `
    ${FontCaption}
    border-radius: 8px;
    padding: 3px 6px 3px 8px;
`;
export const AddLabelLi = styled.li `
    align-items: center;
    display: flex;
`;
export const LabelsBody = styled.div `
    align-items: center;
    display: flex;
    justify-content: flex-start;
`;
export const LabelList = styled.ul `
    display: flex;
    flex-wrap: wrap;
    li {
        margin-right: 4px;
        margin-bottom: 8px;
    }
`;
export const NoLabel = styled.div `
    ${FontBodyMediumBold}
    color: ${Color.darkGrey35};
`;
export const StyledIconCheck = styled(IconCheck) `
    cursor: pointer;
    fill: ${Color.blue};
    margin-left: 4px;
    min-width: 16px
    width: 16px;
`;
export const StyledIconClose = styled(IconClose) `
    cursor: pointer;
    fill: ${Color.blue};
    margin-left: 4px;
    min-width: 16px
    width: 16px;
`;
export const StyledIconPlus = styled(IconPlus) `
    fill: ${Color.darkGrey35};
    height: 24px;
    min-height: 24px;
    min-width: 24px;
    padding: 4px;
    width: 24px;
`;
