import { handleActions } from 'redux-actions';
import { tasksFailure, tasksRequest, tasksSuccess, taskUpdate, stopTaskRequest, stopTaskSuccess, stopTaskFailure, createTaskSuccess, restartTaskRequest, restartTaskSuccess, restartTaskFailure, showPlate } from '../../actions/admin/tasks';
const initialState = {
    currentPage: 0,
    error: null,
    isFetching: false,
    isPlateVisible: false,
    isRestarting: [],
    isStopping: [],
    tasks: [],
    totalPages: 0
};
export default handleActions({
    [tasksRequest]: (state, action) => (Object.assign(Object.assign({}, state), { currentPage: action.payload.page, isFetching: true, tasks: [] })),
    [tasksSuccess]: (state, action) => (Object.assign(Object.assign(Object.assign({}, state), { isFetching: false }), action.payload)),
    [tasksFailure]: (state, action) => (Object.assign(Object.assign({}, state), { isFetching: false, error: action.payload })),
    [stopTaskRequest]: (state, action) => (Object.assign(Object.assign({}, state), { isStopping: [...state.isStopping, action.payload.id] })),
    [stopTaskSuccess]: (state, action) => (Object.assign(Object.assign({}, state), { isStopping: state.isStopping.filter(item => item !== action.payload) })),
    [stopTaskFailure]: (state, action) => (Object.assign(Object.assign({}, state), { isStopping: state.isStopping.filter(item => item !== action.payload) })),
    [restartTaskRequest]: (state, action) => (Object.assign(Object.assign({}, state), { isRestarting: [...state.isRestarting, action.payload.id] })),
    [restartTaskSuccess]: (state, action) => (Object.assign(Object.assign({}, state), { isRestarting: state.isRestarting.filter(item => item !== action.payload) })),
    [restartTaskFailure]: (state, action) => (Object.assign(Object.assign({}, state), { isRestarting: state.isRestarting.filter(item => item !== action.payload) })),
    [taskUpdate]: (state, action) => (Object.assign(Object.assign({}, state), { tasks: state.tasks.map(task => {
            if (task.id !== action.payload.id) {
                return Object.assign({}, task);
            }
            return Object.assign({}, action.payload.data);
        }) })),
    [createTaskSuccess]: (state, action) => (Object.assign(Object.assign({}, state), { isPlateVisible: false, tasks: [action.payload.task, ...state.tasks] })),
    [showPlate]: (state) => (Object.assign(Object.assign({}, state), { isPlateVisible: true }))
}, initialState);
export const getListOfTasks = state => state.tasks.tasks;
export const getTasksCurrentPage = state => state.tasks.currentPage;
export const getTasksTotalPages = state => state.tasks.totalPages;
export const getTasksLoading = state => state.tasks.isFetching;
export const getTasksStopping = state => state.tasks.isStopping;
export const getTasksRestarting = state => state.tasks.isRestarting;
