var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { connect } from "react-redux";
import { Route } from "react-router-dom";
import { isEmptyArray } from "@boomq/utils";
import { AccessDenied } from "../AccessDenied";
import { getClientPermissionList, getClientRole } from "../../../redux/reducers/authorizePlate";
import { Role } from "../../../helpers/types";
const connector = connect((state) => ({
    roles: getClientRole(state),
    permissionList: getClientPermissionList(state)
}), {});
const RouteByPermissions = (_a) => {
    var { component, permissionList, roles } = _a, restProps = __rest(_a, ["component", "permissionList", "roles"]);
    const componentByPermissions = isEmptyArray(permissionList) && !roles.includes(Role.Admin) ? AccessDenied : component;
    return React.createElement(Route, Object.assign({}, restProps, { component: componentByPermissions }));
};
export default connector(RouteByPermissions);
