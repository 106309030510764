export const TEST_DETAILS_CHART_ERRORS_LABEL = {
    defaultMessage: "Errors",
    id: "account.chart.errors"
};
export const TEST_DETAILS_CHART_REQUESTS_LABEL = {
    defaultMessage: "Requests",
    id: "account.chart.requests"
};
export const TEST_DETAILS_CHART_REQUESTS_ERRORS_LABEL = {
    defaultMessage: "Requests/Errors",
    id: "account.chart.requests.errors"
};
export const TEST_DETAILS_CHART_RESPONSE_LABEL = {
    defaultMessage: "Response time",
    id: "account.chart.response"
};
export const TEST_DETAILS_CHART_VUSERS_LABEL = {
    defaultMessage: "VUsers",
    id: "account.chart.users"
};
