import React from "react";
import { FormattedMessage } from "react-intl";
import { SETTINGS_SETS_SIDEBAR_ACTIONS_ADD, SETTINGS_SETS_SIDEBAR_ACTIONS_EXTRACT } from "./intl";
import { Action, Actions, StyledIconPlus, VerticalSeparator } from "./styled";
import { isAllowedSettingsSetInSidebarActionByPermissions } from "../../../helpers/settingsSetSidebar";
import { SettingsSetInSidebarAction } from "../../../helpers/types";
export const SettingsSetSidebarActions = (props) => {
    const onSetIsShowSettingsSetAdding = (value) => typeof props.onSetIsShowSettingsSetAdding === "function"
        ? props.onSetIsShowSettingsSetAdding(value)
        : undefined;
    const clickAddNewSettingsSetHandler = () => onSetIsShowSettingsSetAdding(true);
    const clickExtractFromProjectHandler = () => {
        const extractFromProjectFunc = props[SettingsSetInSidebarAction.ExtractFromProject];
        onSetIsShowSettingsSetAdding(false);
        return typeof extractFromProjectFunc === "function"
            ? extractFromProjectFunc()
            : undefined;
    };
    return isAllowedSettingsSetInSidebarActionByPermissions(props.permissionList, SettingsSetInSidebarAction.Add) ? (React.createElement(Actions, null,
        React.createElement(Action, { onClick: clickAddNewSettingsSetHandler },
            React.createElement(StyledIconPlus, null),
            React.createElement(FormattedMessage, Object.assign({}, SETTINGS_SETS_SIDEBAR_ACTIONS_ADD))),
        React.createElement(VerticalSeparator, null),
        React.createElement(Action, { onClick: clickExtractFromProjectHandler },
            React.createElement(FormattedMessage, Object.assign({}, SETTINGS_SETS_SIDEBAR_ACTIONS_EXTRACT))))) : (React.createElement(React.Fragment, null));
};
