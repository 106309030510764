import React, { Component } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { ErrorPlate } from "../ErrorPlate";
import { passwordWithConfirmValidator } from "../../../helpers/validators";
import { AuthPlateWrapper, AuthBlock, AuthBlockTitle, AuthButtonBlock, AuthErrorMessage, AuthForm, AuthInput, AuthLabel, AuthSectionTitle } from "../../styled/Auth";
import { StyledButton } from "../../styled/Buttons";
import { recoveryRequest } from "../../../redux/actions/reset";
import { getErrorCode } from "../../../redux/reducers/recovery";
import { apiErrors } from "../../../data/content/errorMessages";
const connector = connect((state) => ({
    errorCode: getErrorCode(state)
}), { recoveryRequest });
class RecoveryPlate extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            confirmPassword: "",
            errors: {},
            password: ""
        };
        this.changeHandler = ({ target: { name, value } }) => this.setState({ [name]: value });
        this.submitHandler = (event) => {
            event.preventDefault();
            const { code } = this.props;
            const { password, confirmPassword } = this.state;
            const { isValid, errors } = passwordWithConfirmValidator({ password, confirmPassword });
            this.setState((state) => (Object.assign(Object.assign({}, state), { errors })));
            return isValid ? this.props.recoveryRequest({ code, password }) : undefined;
        };
    }
    render() {
        const { errorCode, intl: { formatMessage }, type } = this.props;
        const { password, confirmPassword, errors } = this.state;
        return (React.createElement(AuthPlateWrapper, null,
            React.createElement(AuthBlockTitle, { type: type },
                React.createElement(AuthSectionTitle, null,
                    React.createElement(FormattedMessage, { id: "website.recovery.title", defaultMessage: "Password recovery" }))),
            React.createElement(AuthBlock, { type: type },
                React.createElement(AuthForm, { onSubmit: this.submitHandler },
                    React.createElement(AuthLabel, null,
                        React.createElement(AuthInput, { isError: errors.password, name: "password", value: password, onChange: this.changeHandler, type: "password", placeholder: formatMessage({
                                defaultMessage: "Password",
                                id: "website.authorize.password"
                            }) }),
                        errors.password && (React.createElement(AuthErrorMessage, null,
                            React.createElement(FormattedMessage, Object.assign({}, errors.password))))),
                    React.createElement(AuthLabel, null,
                        React.createElement(AuthInput, { isError: errors.confirmPassword, name: "confirmPassword", value: confirmPassword, onChange: this.changeHandler, type: "password", placeholder: formatMessage({
                                defaultMessage: "Confirm password",
                                id: "website.registration.password.confirm"
                            }) }),
                        errors.confirmPassword && (React.createElement(AuthErrorMessage, null,
                            React.createElement(FormattedMessage, Object.assign({}, errors.confirmPassword))))),
                    React.createElement(AuthButtonBlock, null,
                        React.createElement(StyledButton, { type: "submit" },
                            React.createElement(FormattedMessage, { id: "website.recovery.button", defaultMessage: "Restore" }))))),
            errorCode && (React.createElement(ErrorPlate, null,
                React.createElement(FormattedMessage, Object.assign({}, apiErrors(errorCode)))))));
    }
}
const WrappedRecoveryPlate = (props) => {
    const { formatMessage } = useIntl();
    return React.createElement(RecoveryPlate, Object.assign({}, props, { intl: { formatMessage } }));
};
export default connector(WrappedRecoveryPlate);
