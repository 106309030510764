var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useIntl } from "@boomq/utils";
import { CONFIRM_PASSWORD_INPUT_PLACEHOLDER, PASSWORD_INPUT_PLACEHOLDER } from "./intl";
import { TOOLTIP_MESSAGE } from "../RegisterForm/intl";
import { ChangePasswordForm } from "../../../components/website/ChangePasswordForm";
import { passwordWithConfirmValidator } from "../../../helpers/validators";
import clientActions from "../../../redux/actions/client";
import { resetError } from "../../../redux/actions/error";
export const ChangePasswordFormContainer = (_a) => {
    var { errorDesc, className } = _a, restProps = __rest(_a, ["errorDesc", "className"]);
    const [errors, setErrors] = useState({});
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const dispatch = useDispatch();
    const { formatMessage } = useIntl();
    useEffect(() => {
        return () => dispatch(resetError());
    }, []);
    const changePassHandler = (e) => (e && e.target ? setPassword(e.target.value) : undefined);
    const changeConfirmPassHandler = (e) => (e && e.target ? setConfirmPassword(e.target.value) : undefined);
    const submitHandler = (event) => {
        event.preventDefault();
        const { isValid, errors } = passwordWithConfirmValidator({ password, confirmPassword });
        setErrors(errors);
        return isValid ? dispatch(clientActions.changePasswordRequest({ password })) : undefined;
    };
    const apiError = errorDesc ? formatMessage(errorDesc) : "";
    const confirmPassInputPlaceholder = formatMessage(CONFIRM_PASSWORD_INPUT_PLACEHOLDER);
    const errorConfirmPass = errors.confirmPassword ? formatMessage(errors.confirmPassword) : "";
    const errorPass = errors.password ? formatMessage(errors.password) : "";
    const passwordInputPlaceholder = formatMessage(PASSWORD_INPUT_PLACEHOLDER);
    const passwordTooltip = formatMessage(TOOLTIP_MESSAGE);
    return (React.createElement(ChangePasswordForm, Object.assign({}, restProps, { apiError: apiError, confirmPassword: confirmPassword, confirmPassInputPlaceholder: confirmPassInputPlaceholder, errorConfirmPass: errorConfirmPass, errorPass: errorPass, onChangeConfirmPass: changeConfirmPassHandler, onChangePassword: changePassHandler, onSubmit: submitHandler, password: password, passwordInputPlaceholder: passwordInputPlaceholder, passwordTooltip: passwordTooltip, className: className })));
};
