var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { FormattedMessage } from "react-intl";
import { JMETER_UNSUPPORTED_PROPERTIES_LINK_TEXT, JMETER_UNSUPPORTED_PROPERTIES_TEXT } from "./intl";
import { InvalidPropertyNameList, InvalidPropertyName, ScrollableBlock } from "./styled";
import { ExceptionForm } from "../../../common/ExceptionForm";
import { supportedJMeterConfigPropertiesDocsLink } from "../../../../helpers/applicationParams";
export const UnsupportedJMeterPropertiesException = (_a) => {
    var { invalidProperties } = _a, restProps = __rest(_a, ["invalidProperties"]);
    return (React.createElement(ExceptionForm, Object.assign({}, restProps, { texts: [
            Object.assign(Object.assign({}, JMETER_UNSUPPORTED_PROPERTIES_TEXT), { values: {
                    link: (React.createElement("a", { href: supportedJMeterConfigPropertiesDocsLink, target: "_blank", rel: "noopener noreferrer" },
                        React.createElement(FormattedMessage, Object.assign({}, JMETER_UNSUPPORTED_PROPERTIES_LINK_TEXT))))
                } })
        ] }), Array.isArray(invalidProperties) && invalidProperties.length > 0 && (React.createElement(ScrollableBlock, null,
        React.createElement(InvalidPropertyNameList, null, (invalidProperties || []).map(({ name }, index) => (React.createElement(InvalidPropertyName, { key: index }, name))))))));
};
