import styled from "styled-components";
export const UnsupportedExtractorType = styled.div `
    align-items: center;
    display: flex;
    flex: auto;
    justify-content: center;
    text-align: center;
    > span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`;
