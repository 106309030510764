import styled, { css } from "styled-components";
export const IconClose = styled.i `
    cursor: pointer;
    position: relative;
    display: block;
    transition: all 0.3s ease-in-out;
    ${(props) => props.modal
    ? css `
                  position: absolute;
                  top: 50px;
                  right: 50px;
              `
    : ""}
    &:before, &:after {
        content: "";
        display: block;
        border-radius: 5px;
        ${(props) => (props.bg && props.bg.length > 0 ? `background: ${props.bg}` : "")};
        position: absolute;
        top: 50%;
        left: 50%;
        transition: all 0.3s ease-in-out;
    }
    &:hover {
        &:before,
        &:after {
            ${(props) => props.hover && props.hover.length > 0 ? `background: ${props.hover}` : ""};
        }
    }
    &:before {
        transform: translate(-50%, -50%) rotate(45deg);
    }
    &:after {
        transform: translate(-50%, -50%) rotate(-45deg);
    }
    ${(props) => props.size === "sm"
    ? css `
                  width: 14px;
                  height: 14px;
                  &:before,
                  &:after {
                      width: 19px;
                      height: 2px;
                  }
              `
    : ""}
    ${(props) => props.size === "md"
    ? css `
                  width: 20px;
                  height: 20px;
                  &:before,
                  &:after {
                      width: 25px;
                      height: 3px;
                  }
              `
    : ""}
    ${(props) => props.size === "lg"
    ? css `
                  width: 30px;
                  height: 30px;
                  &:before,
                  &:after {
                      width: 40px;
                      height: 4px;
                  }
              `
    : ""}
    ${(props) => props.width
    ? css `
                  width: ${props.width}px;
                  height: ${props.width}px;
                  &:before,
                  &:after {
                      width: ${props.width}px;
                      height: 2px;
                  }
              `
    : ""}
    &:active {
        transform: scale(0.9);
    }
    ${(props) => (props.theme ? css(props.theme) : "")}
`;
IconClose.defaultProps = {
    bg: "#BDBDBD",
    hover: "#4F4F4F",
    size: "lg"
};
