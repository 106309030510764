import styled, { css } from "styled-components";
import { Color } from "../styled/colorPallete";
import { IconLoader } from "../Icons";
export const Loader = styled.div `
    bottom: 0;
    display: flex;
    height: 24px;
    justify-content: center;
    position: absolute;
    width: 100%;
`;
export const ScrollContainer = styled.div `
    overflow-y: auto;
    ${({ theme }) => (theme ? css(theme) : css ``)}
`;
export const StyledIconLoader = styled(IconLoader) `
    fill: ${Color.blue};
`;
