export const metaTags = {
    en: [
        {
            "content": "Boomq platform for load and performance testing enables dev and QA teams to run scalable and continuous testing for website, mobile, api and software",
            "name": "description"
        },
        {
            "content": "performance testing, load testing, continuous integration tools,website testing ,performance testing tools, load testing tools, load test,website performance test, website load testing, continuous testing",
            "name": "keywords"
        }
    ],
    ru: [
        {
            "content": "Платформа Boomq для тестирования нагрузки и производительности позволяет командам разработчиков и QA выполнять масштабируемое и непрерывное тестирование для веб-сайтов, мобильных устройств, api и программного обеспечения",
            "name": "description"
        },
        {
            "content": "тестирование производительности, тестирование нагрузки, инструменты непрерывной интеграции, тестирование веб-сайтов, инструменты тестирования производительности, инструменты тестирования нагрузки, тестирование нагрузки, тестирование производительности сайта, тестирование нагрузки на веб-сайт, непрерывное тестирование",
            "name": "keywords"
        }
    ]
};