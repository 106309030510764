import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { SlaReportProfileChart } from "../../../../components/account/SlaReportProfile/SlaReportProfileChart";
import { copyEmbedCodeRequest, deleteSlaReportProfileChart, deleteSlaReportProfileChartSlaType, slaReportProfileAddSlaTypeByViewTypeRequest } from "../../../../redux/actions/slaReportProfile";
import { getSiteModal } from "../../../../redux/actions/siteModal";
const connector = connect(null, {
    copyEmbedCodeRequest,
    deleteSlaReportProfileChart,
    deleteSlaReportProfileChartSlaType,
    getSiteModal,
    slaReportProfileAddSlaTypeByViewTypeRequest
});
export default withRouter(connector(SlaReportProfileChart));
