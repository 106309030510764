import { isEmpty } from "@boomq/utils";
import { ASSERTION_COMMON_REQUIRED_FIELDS, ASSERTION_RESPONSE_BODY_JSON_PATH_REQUIRED_FIELDS, ASSERTION_VALUE_REQUIRED_FIELDS } from "./constants";
import { INCORRECT_ASSERTION_PARAM } from "./intl";
import { modifyErrorMap } from "./validators";
import { getAssertionResponseBodyJsonWithoutValueConditionList, getAssertionValue } from "../assertion";
import { AssertionTemplateType } from "../../models/assertion/enums";
import { getAssertionTemplateTypeByParams } from "../../models/assertion/assertion";
const assertionListItemDataValidatorByParamValue = (assertionData, paramName, paramValue) => isEmpty(paramValue)
    ? {
        isValid: false,
        validatedAssertion: modifyErrorMap(assertionData, paramName, INCORRECT_ASSERTION_PARAM)
    }
    : {
        validatedAssertion: modifyErrorMap(assertionData, paramName)
    };
const assertionListItemDataValidatorByParams = (res, assertionData, paramName, paramValue) => {
    const assertionValidatedData = assertionListItemDataValidatorByParamValue(assertionData, paramName, paramValue);
    return Object.assign(Object.assign({}, res), assertionValidatedData);
};
const assertionListItemParamsValidator = (assertionData, paramNames) => (paramNames || []).reduce((res, paramName) => assertionListItemDataValidatorByParams(res, res.validatedAssertion, paramName, getAssertionValue(assertionData, paramName)), {
    isValid: true,
    validatedAssertion: assertionData
});
const assertionListItemDurationValidator = (assertion) => assertionListItemParamsValidator(assertion, [
    ...ASSERTION_COMMON_REQUIRED_FIELDS,
    ...ASSERTION_VALUE_REQUIRED_FIELDS
]);
const assertionListItemResponseValidator = (assertion) => assertionListItemParamsValidator(assertion, [
    ...ASSERTION_COMMON_REQUIRED_FIELDS,
    ...ASSERTION_VALUE_REQUIRED_FIELDS
]);
const getResponseBodyJsonRequiredFields = (assertion) => assertion && getAssertionResponseBodyJsonWithoutValueConditionList().includes(assertion.condition)
    ? [...ASSERTION_COMMON_REQUIRED_FIELDS, ...ASSERTION_RESPONSE_BODY_JSON_PATH_REQUIRED_FIELDS]
    : [
        ...ASSERTION_COMMON_REQUIRED_FIELDS,
        ...ASSERTION_VALUE_REQUIRED_FIELDS,
        ...ASSERTION_RESPONSE_BODY_JSON_PATH_REQUIRED_FIELDS
    ];
const assertionListItemResponseBodyJsonValidator = (assertion) => assertionListItemParamsValidator(assertion, getResponseBodyJsonRequiredFields(assertion));
const assertionListItemSizeValidator = (assertion) => assertionListItemParamsValidator(assertion, [
    ...ASSERTION_COMMON_REQUIRED_FIELDS,
    ...ASSERTION_VALUE_REQUIRED_FIELDS
]);
const assertionListItemValidateFuncByTemplateType = {
    [AssertionTemplateType.Duration]: assertionListItemDurationValidator,
    [AssertionTemplateType.Response]: assertionListItemResponseValidator,
    [AssertionTemplateType.ResponseBody]: assertionListItemResponseValidator,
    [AssertionTemplateType.ResponseBodyJson]: assertionListItemResponseBodyJsonValidator,
    [AssertionTemplateType.ResponseBodyXPath2]: assertionListItemResponseValidator,
    [AssertionTemplateType.Size]: assertionListItemSizeValidator
};
export const assertionListItemValidatorByType = (assertion) => {
    const assertionTemplateType = getAssertionTemplateTypeByParams(assertion);
    return Object.keys(assertionListItemValidateFuncByTemplateType).includes(assertionTemplateType)
        ? assertionListItemValidateFuncByTemplateType[assertionTemplateType](assertion)
        : {
            isValid: true,
            validatedAssertion: assertion
        };
};
const assertionListItemValidator = (res, assertion) => {
    const { isValid, validatedAssertion } = assertionListItemValidatorByType(assertion);
    return isValid
        ? Object.assign(Object.assign({}, res), { validatedAssertionList: [...res.validatedAssertionList, Object.assign(Object.assign({}, validatedAssertion), { error: {} })] }) : Object.assign(Object.assign({}, res), { isValid, validatedAssertionList: [...res.validatedAssertionList, validatedAssertion] });
};
export const assertionListValidator = (assertionList) => {
    const { isValid, validatedAssertionList } = (assertionList || []).reduce(assertionListItemValidator, {
        isValid: true,
        validatedAssertionList: []
    });
    return {
        isValid,
        validatedAssertionList
    };
};
