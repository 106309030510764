import { ExtractorBoundaryTemplate } from "../../components/account/TestProject/ExtractorBoundaryTemplate";
import { ExtractorJsonPathTemplate } from "../../components/account/TestProject/ExtractorJsonPathTemplate";
import { ExtractorRegExpTemplate } from "../../components/account/TestProject/ExtractorRegExpTemplate";
import { ExtractorXPathTemplate } from "../../components/account/TestProject/ExtractorXPathTemplate";
import { DEFAULT_EXTRACTOR_GROUP_NUMBER, DEFAULT_EXTRACTOR_FIELD_TO_CHECK } from "./constants";
import { EXTRACTOR_FIELD_TO_CHECK_BODY_MESSAGE, EXTRACTOR_FIELD_TO_CHECK_REQUEST_HEADERS_MESSAGE, EXTRACTOR_FIELD_TO_CHECK_RESPONSE_CODE_MESSAGE, EXTRACTOR_FIELD_TO_CHECK_RESPONSE_HEADERS_MESSAGE, EXTRACTOR_FIELD_TO_CHECK_RESPONSE_MESSAGE_MESSAGE, EXTRACTOR_FIELD_TO_URL_MESSAGE, EXTRACTOR_MATCH_TYPE_ALL_MATCHES_MESSAGE, EXTRACTOR_MATCH_TYPE_RANDOM_MATCH_MESSAGE, EXTRACTOR_MATCH_TYPE_SPECIFIC_MATCH_MESSAGE, EXTRACT_TYPE_BOUNDARY_TEXT_MESSAGE, EXTRACT_TYPE_PLACEHOLDER_FIELD_TO_CHECK_MESSAGE, EXTRACT_TYPE_PLACEHOLDER_GROUP_NUMBER_MESSAGE, EXTRACT_TYPE_PLACEHOLDER_JSONPATH_MESSAGE, EXTRACT_TYPE_PLACEHOLDER_LEFT_BORDER_MESSAGE, EXTRACT_TYPE_PLACEHOLDER_RE_MESSAGE, EXTRACT_TYPE_PLACEHOLDER_RIGHT_BORDER_MESSAGE, EXTRACT_TYPE_PLACEHOLDER_XPATH_MESSAGE, RESPONSE_PREVIEW_FROM_HAR_MESSAGE } from "./intl";
import { ExtractorFieldToCheck, ExtractorMatchType, ExtractorType } from "../../helpers/types";
export var ResponsePreviewSource;
(function (ResponsePreviewSource) {
    ResponsePreviewSource["Har"] = "HAR";
})(ResponsePreviewSource || (ResponsePreviewSource = {}));
export const extractorTemplatesByType = {
    [ExtractorType.Boundary]: ExtractorBoundaryTemplate,
    [ExtractorType.JsonPath]: ExtractorJsonPathTemplate,
    [ExtractorType.RegEx]: ExtractorRegExpTemplate,
    [ExtractorType.XPath]: ExtractorXPathTemplate,
    [ExtractorType.XPath2]: ExtractorXPathTemplate
};
export const extractorFieldToCheckItems = [
    {
        id: 0,
        text: EXTRACTOR_FIELD_TO_CHECK_BODY_MESSAGE,
        value: ExtractorFieldToCheck.Body
    },
    {
        id: 1,
        text: EXTRACTOR_FIELD_TO_CHECK_REQUEST_HEADERS_MESSAGE,
        value: ExtractorFieldToCheck.RequestHeaders
    },
    {
        id: 2,
        text: EXTRACTOR_FIELD_TO_CHECK_RESPONSE_CODE_MESSAGE,
        value: ExtractorFieldToCheck.ResponseCode
    },
    {
        id: 3,
        text: EXTRACTOR_FIELD_TO_CHECK_RESPONSE_HEADERS_MESSAGE,
        value: ExtractorFieldToCheck.ResponseHeaders
    },
    {
        id: 4,
        text: EXTRACTOR_FIELD_TO_CHECK_RESPONSE_MESSAGE_MESSAGE,
        value: ExtractorFieldToCheck.ResponseMessage
    },
    {
        id: 5,
        text: EXTRACTOR_FIELD_TO_URL_MESSAGE,
        value: ExtractorFieldToCheck.Url
    }
];
export const extractorMatchTypeItems = [
    {
        id: 0,
        text: EXTRACTOR_MATCH_TYPE_SPECIFIC_MATCH_MESSAGE,
        value: ExtractorMatchType.Specific
    },
    {
        id: 1,
        text: EXTRACTOR_MATCH_TYPE_ALL_MATCHES_MESSAGE,
        value: ExtractorMatchType.All
    },
    {
        id: 2,
        text: EXTRACTOR_MATCH_TYPE_RANDOM_MATCH_MESSAGE,
        value: ExtractorMatchType.Random
    }
];
export const grpcExtractTypes = [
    {
        id: 0,
        expressionValue: {
            re: "",
            groupNumber: DEFAULT_EXTRACTOR_GROUP_NUMBER,
            fieldToCheck: DEFAULT_EXTRACTOR_FIELD_TO_CHECK
        },
        placeholder: {
            re: EXTRACT_TYPE_PLACEHOLDER_RE_MESSAGE,
            groupNumber: EXTRACT_TYPE_PLACEHOLDER_GROUP_NUMBER_MESSAGE,
            fieldToCheck: EXTRACT_TYPE_PLACEHOLDER_FIELD_TO_CHECK_MESSAGE
        },
        text: "Regex",
        value: ExtractorType.RegEx
    },
    {
        id: 1,
        expressionValue: { jsonpath: "" },
        placeholder: {
            jsonpath: EXTRACT_TYPE_PLACEHOLDER_JSONPATH_MESSAGE
        },
        text: "jSONPath",
        value: ExtractorType.JsonPath
    },
    {
        id: 2,
        expressionValue: { leftBorder: "", rightBorder: "", fieldToCheck: DEFAULT_EXTRACTOR_FIELD_TO_CHECK },
        placeholder: {
            leftBorder: EXTRACT_TYPE_PLACEHOLDER_LEFT_BORDER_MESSAGE,
            rightBorder: EXTRACT_TYPE_PLACEHOLDER_RIGHT_BORDER_MESSAGE,
            fieldToCheck: EXTRACT_TYPE_PLACEHOLDER_FIELD_TO_CHECK_MESSAGE
        },
        text: EXTRACT_TYPE_BOUNDARY_TEXT_MESSAGE,
        value: ExtractorType.Boundary
    }
];
export const httpExtractTypes = [
    ...grpcExtractTypes,
    {
        id: 3,
        expressionValue: { xpath: "", namespacesAliases: [] },
        placeholder: {
            xpath: EXTRACT_TYPE_PLACEHOLDER_XPATH_MESSAGE
        },
        text: "XPath",
        value: ExtractorType.XPath2
    }
];
export const responsePreviewSourceItems = [
    {
        id: 0,
        text: RESPONSE_PREVIEW_FROM_HAR_MESSAGE,
        value: ResponsePreviewSource.Har
    }
];
export const getResponsePreviewSourceItems = () => responsePreviewSourceItems;
