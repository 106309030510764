import styled from "styled-components";
const CommonFontStyle = `
    font-family: Montserrat;
    font-style: normal;
`;
export const FontRegular = `
    font-weight: 400;
`;
export const FontMedium = `
    font-weight: 500;
`;
export const FontBold = `
    font-weight: 600;
`;
export const FontBodyExtraBig = `
    font-size: 20px;
`;
export const FontBodyBig = `
    font-size: 18px;
`;
export const FontBodyMedium = `
    font-size: 16px;
`;
export const FontBodySmall = `
    font-size: 14px;
`;
export const FontBodyExtraBigRegular = `
    ${CommonFontStyle}
    ${FontBodyExtraBig}
    ${FontRegular}
`;
export const FontBodyExtraBigMedium = `
    ${CommonFontStyle}
    ${FontBodyExtraBig}
    ${FontMedium}
`;
export const FontBodyBigRegular = `
    ${CommonFontStyle}
    ${FontBodyBig}
    ${FontRegular}
`;
export const FontBodyBigMedium = `
    ${CommonFontStyle}
    ${FontBodyBig}
    ${FontMedium}
`;
export const FontBodyBigBold = `
    ${CommonFontStyle}
    ${FontBodyBig}
    ${FontBold}
`;
export const FontBodyMediumRegular = `
    ${CommonFontStyle}
    ${FontBodyMedium}
    ${FontRegular}
`;
export const FontBodyMediumMedium = `
    ${CommonFontStyle}
    ${FontBodyMedium}
    ${FontMedium}
`;
export const FontBodyMediumBold = `
    ${CommonFontStyle}
    ${FontBodyMedium}
    ${FontBold}
`;
export const FontBodySmallRegular = `
    ${CommonFontStyle}    
    ${FontBodySmall}
    ${FontRegular}
`;
export const FontBodySmallMedium = `
    ${CommonFontStyle}    
    ${FontBodySmall}
    ${FontMedium}
`;
export const FontBodySmallBold = `
    ${CommonFontStyle}
    ${FontBodySmall}
    ${FontBold}
`;
export const FontCaption = `
    ${CommonFontStyle}    
    font-size: 12px;
    ${FontBold}
`;
export const H1Styles = `
    ${CommonFontStyle}
    font-size: 36px;
    ${FontBold}
`;
export const H2Styles = `
    ${CommonFontStyle}
    font-size: 24px;
    ${FontBold}
`;
export const H3Styles = `
    ${CommonFontStyle}
    font-size: 20px;
    ${FontMedium}
`;
export const H1 = styled.h1 `
    ${H1Styles}
`;
export const H2 = styled.h2 `
    ${H2Styles}
`;
export const H3 = styled.h3 `
    ${H3Styles}
`;
