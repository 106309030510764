import React from "react";
import { Link, withRouter } from "react-router-dom";
import { Container, Header, LinkBack } from "./styled";
import { HeaderBurgerMenu } from "../HeaderBurgerMenu";
import arrow from "../../../assets/images/icons/arrow_white.svg";
import { getAllTestsRoute } from "../../../helpers/routes/routes";
const renderLinkBack = (pathname) => {
    const testDetailsPath = getAllTestsRoute();
    return pathname.substring(0, testDetailsPath.length) === testDetailsPath ? (React.createElement(LinkBack, { onClick: () => this.props.history.goBack() },
        React.createElement("img", { src: arrow, alt: "arrow" }))) : (React.createElement(React.Fragment, null));
};
const HeaderMobile = (props) => {
    const { isAuthorized, location: { pathname }, logo } = props || {};
    return (React.createElement(Header, null,
        React.createElement(Container, null, renderLinkBack(pathname)),
        React.createElement(Container, null,
            React.createElement(Link, { to: "/" },
                React.createElement("img", { src: logo, alt: "logo" }))),
        React.createElement(Container, null,
            React.createElement(HeaderBurgerMenu, { isAuthorized: isAuthorized }))));
};
export default withRouter(HeaderMobile);
