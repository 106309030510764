import { getFromLocalStorage, isEmpty, removeFromLocalStorage, setToLocalStorage } from "@boomq/utils";
import { TEST_PROJECT_DATA_LOCAL_STORAGE_PARAM_NAME } from "./constants";
import { getThreadGroupElementControllerType, getThreadGroupElementType } from "../threadGroup";
import { TeamMemberPermission, TestProjectAction } from "../types";
import { getImportDataToTestProjectTypesByTestType, importDataToTestProjectIconByType } from "../../models/importData";
import { getRequestParameterTypesByTestType, requestParameterIconByType } from "../../models/requestParameters";
import { TestProjectDataSourceType, requestGroupsActions, testProjectSidebarElementIconByType } from "../../models/testProject";
import { getTestProjectDefaultSettingsTypesByTestType, testProjectDefaultSettingsIconByType } from "../../models/testProjectDefaultSettings";
const actionsByTeamMemberPermissionEdit = [
    TestProjectAction.Add,
    TestProjectAction.AddRequest,
    TestProjectAction.ChangeAutoPercentages,
    TestProjectAction.Edit,
    TestProjectAction.Save,
    TestProjectAction.SaveAs
];
const actionsByTeamMemberPermissionRun = [...actionsByTeamMemberPermissionEdit, TestProjectAction.RunTest];
const actionsByTeamMemberPermissions = {
    [TeamMemberPermission.Admin]: actionsByTeamMemberPermissionRun,
    [TeamMemberPermission.Edit]: actionsByTeamMemberPermissionEdit,
    [TeamMemberPermission.Run]: actionsByTeamMemberPermissionRun
};
const getTestProjectActionsByTeamMemberPermission = (permission) => {
    const actionsByPermission = actionsByTeamMemberPermissions[permission];
    return actionsByPermission ? actionsByPermission : [];
};
const getTestProjectActionsByTeamMemberPermissions = (permissions) => [
    ...new Set((permissions || []).reduce((res, permission) => [
        ...res,
        ...getTestProjectActionsByTeamMemberPermission(permission)
    ], []))
];
export const getTestProjectDataFromLocalStorage = () => {
    try {
        const testProjectData = getFromLocalStorage(TEST_PROJECT_DATA_LOCAL_STORAGE_PARAM_NAME);
        return testProjectData ? JSON.parse(testProjectData) : undefined;
    }
    catch (e) {
        return;
    }
};
export const isExistTestProjectDataInLocalStorage = () => getTestProjectDataFromLocalStorage() ? true : false;
export const removeTestProjectDataFromLocalStorage = () => {
    try {
        removeFromLocalStorage(TEST_PROJECT_DATA_LOCAL_STORAGE_PARAM_NAME);
    }
    catch (e) {
        console.error(e);
    }
};
export const setTestProjectDataToLocalStorage = (params) => {
    try {
        setToLocalStorage(TEST_PROJECT_DATA_LOCAL_STORAGE_PARAM_NAME, JSON.stringify(params));
    }
    catch (e) {
        console.error(e);
    }
};
export const isAllowedTestProjectActionByPermissions = (permissionList = [], actionName) => {
    const actionsByPermissions = getTestProjectActionsByTeamMemberPermissions(permissionList);
    return (actionsByPermissions || []).includes(actionName);
};
const getElementsFromMapByGetterFuncValue = (elementsMap, getterFunc, value) => elementsMap && typeof getterFunc === "function"
    ? Object.keys(elementsMap).reduce((res, elementId) => (getterFunc === null || getterFunc === void 0 ? void 0 : getterFunc(elementsMap[elementId])) === value ? [...res, elementsMap[elementId]] : res, [])
    : [];
export const getElementsFromMapByControllerType = (elementsMap, type) => getElementsFromMapByGetterFuncValue(elementsMap, getThreadGroupElementControllerType, type);
export const getElementsFromMapByType = (elementsMap, type) => getElementsFromMapByGetterFuncValue(elementsMap, getThreadGroupElementType, type);
const formatSidebarElementCommonData = (type) => ({
    id: type,
    value: type
});
const formatImportDataToTestProjectSidebarElement = (sourceType) => (Object.assign(Object.assign({}, formatSidebarElementCommonData(sourceType)), { icon: importDataToTestProjectIconByType[sourceType], intlName: {
        defaultMessage: sourceType,
        id: `account.new.settings.sidebar.import.data.${sourceType}.name`
    } }));
export const getImportDataToTestProjectSidebarElements = (testType) => [
    ...getImportDataToTestProjectTypesByTestType(testType).map(formatImportDataToTestProjectSidebarElement),
    Object.assign(Object.assign({}, formatSidebarElementCommonData(TestProjectDataSourceType.GrpcProtoBuffer)), { icon: testProjectSidebarElementIconByType[TestProjectDataSourceType.GrpcProtoBuffer], intlName: {
            defaultMessage: TestProjectDataSourceType.GrpcProtoBuffer,
            id: `account.new.settings.sidebar.import.data.${TestProjectDataSourceType.GrpcProtoBuffer}.name`
        } })
];
const formatTestProjectDefaultSettingsSidebarElement = (defaultSettingsType) => (Object.assign(Object.assign({}, formatSidebarElementCommonData(defaultSettingsType)), { icon: testProjectDefaultSettingsIconByType[defaultSettingsType], intlName: {
        defaultMessage: defaultSettingsType,
        id: `account.new.settings.sidebar.default.settings.${defaultSettingsType}.name`
    } }));
export const getTestProjecDefaultSettingsSidebarElementsByTestType = (testType) => getTestProjectDefaultSettingsTypesByTestType(testType).map(formatTestProjectDefaultSettingsSidebarElement);
const formatRequestParametersSidebarElement = (parameterType) => (Object.assign(Object.assign({}, formatSidebarElementCommonData(parameterType)), { icon: requestParameterIconByType[parameterType], intlName: {
        defaultMessage: parameterType,
        id: `account.new.settings.sidebar.parameters.${parameterType}.name`
    } }));
export const getRequestParametersSidebarElementsByTestType = (testType) => getRequestParameterTypesByTestType(testType).map(formatRequestParametersSidebarElement);
export const getActionButtonsByTestType = (ownProps) => requestGroupsActions.filter((button) => isEmpty(button.testTypes) || (button.testTypes || []).includes(ownProps.testType));
