import Table from "@editorjs/table";
import { stopEvent } from "@boomq/utils";
import "./resizableTable.css";
export class ResizableTable extends Table {
    constructor(params) {
        super(params);
        this.CSS = {
            cell: "tc-cell",
            columnToolbox: "tc-toolbox--column",
            minColWidth: 34,
            resizableTable: "pflb-resizable-table",
            resizeCol: "tc-resize-column",
            resizeColActive: "active",
            resizeColBlock: "tc-resize-column-block",
            resizeColMode: "resize-col-mode",
            resizeColWidth: 5,
            row: "tc-row",
            rowWithColsWidth: "with-columns-width",
            table: "tc-table",
            wrapper: "tc-wrap"
        };
        this.resizeColIndexAttrName = "data-index";
        this._wrappedTable = this.generateTable();
        this.isResizeMode = false;
        this.nextColElement = null;
        this.nextColWidth = null;
        this.tableObserver = null;
        this.tableRowWithColWidthObserver = null;
        this.tableToolboxColumnObserver = null;
        this.toolboxColumnLeftMargin = null;
    }
    destroy() {
        var _a, _b, _c, _d, _e, _f, _g;
        (_a = this._wrappedTable) === null || _a === void 0 ? void 0 : _a.removeEventListener("mousedown", (event) => this.mouseDownListener(event));
        (_b = this._wrappedTable) === null || _b === void 0 ? void 0 : _b.removeEventListener("mousemove", (event) => this.mouseMoveListener(event));
        (_c = this._wrappedTable) === null || _c === void 0 ? void 0 : _c.removeEventListener("mouseover", (event) => this.mouseOverListener(event));
        (_d = this._wrappedTable) === null || _d === void 0 ? void 0 : _d.removeEventListener("mouseup", () => this.mouseUpListener());
        window.removeEventListener("resize", () => this.refreshResizeColPosition.call(this));
        (_e = this.tableObserver) === null || _e === void 0 ? void 0 : _e.disconnect();
        (_f = this.tableRowWithColWidthObserver) === null || _f === void 0 ? void 0 : _f.disconnect();
        (_g = this.tableToolboxColumnObserver) === null || _g === void 0 ? void 0 : _g.disconnect();
    }
    render() {
        return this.renderResizableTable();
    }
    renderResizableTable() {
        const wrappedTable = this._wrappedTable;
        setTimeout(() => this.addResizeColumnBlock(wrappedTable));
        return this._wrappedTable;
    }
    generateTable(colsWidth = []) {
        const wrappedTable = super.render();
        const table = this.getTableElement(wrappedTable);
        const firstRowCols = this.getFirstRowColsFromTable(table);
        const firstRowColsWidth = (colsWidth === null || colsWidth === void 0 ? void 0 : colsWidth.length) > 0 ? colsWidth : this.generateColsWidthByColCount((firstRowCols === null || firstRowCols === void 0 ? void 0 : firstRowCols.length) || 0);
        table === null || table === void 0 ? void 0 : table.classList.add(this.CSS.resizableTable);
        (firstRowColsWidth || []).forEach((colWidth, index) => {
            try {
                this.setElementStyle(firstRowCols === null || firstRowCols === void 0 ? void 0 : firstRowCols[index], { width: colWidth });
            }
            catch (error) {
                console.warn("Maybe no first row");
            }
        });
        this.addListeners(wrappedTable);
        return wrappedTable;
    }
    setElementStyle(element, styles) {
        return element && styles ? this.setExistedElementStyle(element, styles) : undefined;
    }
    setExistedElementStyle(element, styles) {
        Object.keys(styles).forEach((cssRule) => {
            element.style[cssRule] = styles[cssRule];
        });
    }
    generateColsWidthByColCount(colCount) {
        return Array(colCount)
            .fill("")
            .map((col, index) => this.calcColWidthByColCount(colCount, index));
    }
    calcColWidthByColCount(colCount, index) {
        return colCount - 1 === index ? "" : `${Math.floor(100 / colCount)}%`;
    }
    regenerateTableByData(data) {
        super.data = data;
        this.destroy();
        this._wrappedTable = this.generateTable(data === null || data === void 0 ? void 0 : data.colsWidth);
        return this.renderResizableTable();
    }
    addListeners(table) {
        table === null || table === void 0 ? void 0 : table.addEventListener("mousedown", (event) => this.mouseDownListener(event));
        table === null || table === void 0 ? void 0 : table.addEventListener("mousemove", (event) => this.mouseMoveListener(event));
        table === null || table === void 0 ? void 0 : table.addEventListener("mouseover", (event) => this.mouseOverListener(event));
        table === null || table === void 0 ? void 0 : table.addEventListener("mouseup", () => this.mouseUpListener());
        window.addEventListener("resize", () => this.refreshResizeColPosition());
        this.addTableObserver(table);
        this.addTableRowWithColWidthObserver(table);
        this.addToolboxColumnObserver(table);
    }
    mouseDownListener(event) {
        const element = event.target;
        return (element === null || element === void 0 ? void 0 : element.classList.contains(this.CSS.resizeCol)) ? this.setTableResizeMode(event) : undefined;
    }
    mouseMoveListener(event) {
        return this.currentColElement ? this.setCurrentColWidth(event) : undefined;
    }
    mouseOverListener(event) {
        const element = event.target;
        stopEvent(event);
        return (element === null || element === void 0 ? void 0 : element.classList.contains(this.CSS.cell))
            ? this.setHoveredElement(element)
            : this.setHoveredElementFromTable(event);
    }
    setHoveredElement(hoveredElement) {
        this.hoveredEl = hoveredElement;
    }
    setHoveredElementFromTable(event) {
        const firstRowCols = this.getFirstRowColsFromTable(this._wrappedTable);
        const firstRowHoveredCol = Array.from(firstRowCols || []).find((firstRowCol) => {
            const { x, width } = firstRowCol.getBoundingClientRect();
            return event.x >= x && event.x <= x + width;
        });
        return firstRowHoveredCol ? this.setHoveredElement(firstRowHoveredCol) : undefined;
    }
    hideColumnToolbox() {
        const columnToolboxElement = this.getColumnToolboxElement();
        this.setElementStyle(columnToolboxElement, { opacity: 0 });
    }
    getColumnToolboxElement() {
        return this._wrappedTable.querySelector(`.${this.CSS.columnToolbox}`);
    }
    calculateToolboxParamsByHoveredElement(leftMargin, colIndex, element) {
        return (element === null || element === void 0 ? void 0 : element.previousSibling)
            ? this.addPreviousSiblingWidth(leftMargin, colIndex, element.previousSibling)
            : leftMargin;
    }
    setToolboxPosition(leftMargin) {
        const toolboxColumn = this.getColumnToolboxElement();
        const toolboxColumnWidth = (toolboxColumn === null || toolboxColumn === void 0 ? void 0 : toolboxColumn.getBoundingClientRect().width) || 0;
        const toolboxColumnCurrentLeftMargin = (toolboxColumn === null || toolboxColumn === void 0 ? void 0 : toolboxColumn.offsetLeft) || 0;
        const transformLeftMargin = leftMargin - toolboxColumnCurrentLeftMargin - toolboxColumnWidth / 2;
        this.setElementStyle(toolboxColumn, { opacity: 1, transform: `translateX(${transformLeftMargin}px)` });
    }
    addPreviousSiblingWidth(leftMargin, colIndex, element) {
        var _a, _b;
        const prevSiblingElement = element;
        const width = ((_b = (_a = prevSiblingElement === null || prevSiblingElement === void 0 ? void 0 : prevSiblingElement.getBoundingClientRect) === null || _a === void 0 ? void 0 : _a.call(prevSiblingElement)) === null || _b === void 0 ? void 0 : _b.width) || 0;
        return this.calculateToolboxParamsByHoveredElement(leftMargin + width, colIndex + 1, prevSiblingElement);
    }
    mouseUpListener() {
        var _a, _b, _c, _d;
        (_c = (_b = (_a = this.resizeColElement) === null || _a === void 0 ? void 0 : _a.classList) === null || _b === void 0 ? void 0 : _b.remove) === null || _c === void 0 ? void 0 : _c.call(_b, this.CSS.resizeColActive);
        (_d = this.getTableElement(this._wrappedTable)) === null || _d === void 0 ? void 0 : _d.classList.remove(this.CSS.resizeColMode);
        this.isResizeMode = false;
        this.toolboxColumnLeftMargin = null;
        this.currentColElement = null;
        this.currentColWidth = null;
        this.nextColElement = null;
        this.nextColWidth = null;
        this.pageX = null;
        this.resizeColElement = null;
        this.refreshResizeColPosition();
    }
    addTableObserver(wrappedTable) {
        const tableElement = this.getTableElement(wrappedTable);
        this.tableObserver = new MutationObserver((mutationRecords) => this.onTableRowsChanged(mutationRecords));
        return tableElement ? this.tableObserver.observe(tableElement, { childList: true }) : undefined;
    }
    addTableRowWithColWidthObserver(table) {
        this.tableRowWithColWidthObserver = new MutationObserver((mutationRecords) => this.onTableRowColsChanged(mutationRecords));
        const firstRow = this.getFirstRowFromTable(table);
        firstRow === null || firstRow === void 0 ? void 0 : firstRow.classList.add(this.CSS.rowWithColsWidth);
        return firstRow ? this.tableRowWithColWidthObserver.observe(firstRow, { childList: true }) : undefined;
    }
    addToolboxColumnObserver(table) {
        this.tableToolboxColumnObserver = new MutationObserver((mutationRecords) => this.onTableToolboxColumnStyleChanged(mutationRecords));
        const toolboxColumn = table.querySelector(`.${this.CSS.columnToolbox}`);
        this.tableToolboxColumnObserver.observe(toolboxColumn, { attributes: true, attributeFilter: ["style"] });
    }
    setTableResizeMode(event) {
        var _a, _b, _c, _d;
        stopEvent(event);
        this.hideColumnToolbox();
        this.currentColElement = this.getCurrentColElement(event);
        this.nextColElement = (_a = this.currentColElement) === null || _a === void 0 ? void 0 : _a.nextElementSibling;
        this.pageX = event.pageX;
        this.resizeColElement = event.target;
        (_b = this.resizeColElement) === null || _b === void 0 ? void 0 : _b.classList.add(this.CSS.resizeColActive);
        this.isResizeMode = true;
        this.currentColWidth = (_c = this.currentColElement) === null || _c === void 0 ? void 0 : _c.offsetWidth;
        this.nextColWidth = this.nextColElement ? this.nextColElement.offsetWidth : this.nextColWidth;
        (_d = this.getTableElement(this._wrappedTable)) === null || _d === void 0 ? void 0 : _d.classList.add(this.CSS.resizeColMode);
        this.checkPrevCol();
    }
    setCurrentColWidth(event) {
        var _a;
        const currentColWidth = this.currentColWidth || 0;
        const diffX = event.pageX - (this.pageX || 0);
        const nextColWidth = this.nextColWidth || 0;
        const newWidth = nextColWidth - diffX <= this.CSS.minColWidth
            ? currentColWidth - this.CSS.minColWidth + nextColWidth
            : currentColWidth + diffX;
        const firstRowCols = this.getFirstRowColsFromTable(this._wrappedTable);
        const newCurrentColWidth = newWidth > this.CSS.minColWidth ? newWidth : this.CSS.minColWidth;
        const prevResizeCol = (_a = this.resizeColElement) === null || _a === void 0 ? void 0 : _a.previousSibling;
        const prevResizeColLeft = (prevResizeCol === null || prevResizeCol === void 0 ? void 0 : prevResizeCol.offsetLeft) || 0;
        this.setElementStyle(this.currentColElement, { width: this.formatColWidth(newCurrentColWidth) });
        this.setElementStyle(this.resizeColElement, { left: `${prevResizeColLeft + newCurrentColWidth}px` });
        return newCurrentColWidth === this.CSS.minColWidth
            ? undefined
            : this.checkNextCol((firstRowCols === null || firstRowCols === void 0 ? void 0 : firstRowCols.length) || 0, nextColWidth - diffX);
    }
    getTableElement(wrappedTable) {
        return wrappedTable === null || wrappedTable === void 0 ? void 0 : wrappedTable.querySelector(`.${this.CSS.table}`);
    }
    formatColWidth(width) {
        const tableWidth = this.getTableWidth(this._wrappedTable) || 0;
        return tableWidth > 0 ? `${(100 * width) / tableWidth}%` : `${width}px`;
    }
    getTableWidth(wrappedTable) {
        var _a;
        return ((_a = wrappedTable === null || wrappedTable === void 0 ? void 0 : wrappedTable.querySelector(`.${this.CSS.table}`).getBoundingClientRect()) === null || _a === void 0 ? void 0 : _a.width) || 0;
    }
    refreshResizeColPosition() {
        var _a;
        let leftMargin = 0;
        const firstRowCols = this.getFirstRowColsFromTable(this._wrappedTable);
        const resizeColDivs = (((_a = this.getResizeColumnBlock()) === null || _a === void 0 ? void 0 : _a.children) || []);
        Array.from(resizeColDivs).forEach((resizeColDiv, index) => {
            var _a, _b, _c;
            const width = ((_c = (_b = (_a = firstRowCols === null || firstRowCols === void 0 ? void 0 : firstRowCols[index]) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect) === null || _b === void 0 ? void 0 : _b.call(_a)) === null || _c === void 0 ? void 0 : _c.width) || 0;
            leftMargin += width;
            this.setElementStyle(resizeColDiv, { left: `${leftMargin - this.CSS.resizeColWidth}px` });
        });
    }
    onTableRowsChanged(mutationRecords) {
        var _a, _b;
        const removedNode = (_b = (_a = mutationRecords === null || mutationRecords === void 0 ? void 0 : mutationRecords[0]) === null || _a === void 0 ? void 0 : _a.removedNodes) === null || _b === void 0 ? void 0 : _b[0];
        return (removedNode === null || removedNode === void 0 ? void 0 : removedNode.classList.contains(this.CSS.rowWithColsWidth))
            ? this.addColsWidthToFirstRow(removedNode)
            : undefined;
    }
    addColsWidthToFirstRow(removedNode) {
        var _a;
        const firstRow = this.getFirstRowFromTable(this._wrappedTable);
        const firstRowCols = this.getFirstRowColsFromTable(this._wrappedTable);
        const removedRowColsWidth = Array.from(removedNode.children).map((rowCol) => {
            var _a;
            const rowColElement = rowCol;
            return ((_a = rowColElement === null || rowColElement === void 0 ? void 0 : rowColElement.style) === null || _a === void 0 ? void 0 : _a.width) || "";
        });
        removedRowColsWidth.forEach((removedRowColWidth, index) => {
            try {
                this.setElementStyle(firstRowCols === null || firstRowCols === void 0 ? void 0 : firstRowCols[index], { width: removedRowColWidth });
            }
            catch (error) {
                console.warn("Maybe no first row");
            }
        });
        firstRow === null || firstRow === void 0 ? void 0 : firstRow.classList.add(this.CSS.rowWithColsWidth);
        (_a = this.tableRowWithColWidthObserver) === null || _a === void 0 ? void 0 : _a.disconnect();
        this.addTableRowWithColWidthObserver(this._wrappedTable);
    }
    replaceResizeColumnBlock() {
        var _a;
        const resizeColList = this.generateResizeColList(this._wrappedTable);
        return (_a = this.getResizeColumnBlock()) === null || _a === void 0 ? void 0 : _a.replaceChildren(...resizeColList);
    }
    getResizeColumnBlock() {
        var _a, _b;
        return (_b = (_a = this._wrappedTable) === null || _a === void 0 ? void 0 : _a.querySelector) === null || _b === void 0 ? void 0 : _b.call(_a, `.${this.CSS.resizeColBlock}`);
    }
    onTableRowColsChanged(mutationRecords) {
        var _a, _b;
        const removedNode = (_b = (_a = mutationRecords === null || mutationRecords === void 0 ? void 0 : mutationRecords[0]) === null || _a === void 0 ? void 0 : _a.removedNodes) === null || _b === void 0 ? void 0 : _b[0];
        return removedNode ? this.replaceResizeColumnBlockAfterRemoveCol() : this.replaceResizeColumnBlock();
    }
    onTableToolboxColumnStyleChanged(mutationRecords) {
        var _a;
        const toolboxColumnElement = (_a = mutationRecords === null || mutationRecords === void 0 ? void 0 : mutationRecords[0]) === null || _a === void 0 ? void 0 : _a.target;
        const toolboxColumnLeftMargin = toolboxColumnElement === null || toolboxColumnElement === void 0 ? void 0 : toolboxColumnElement.style.left;
        return this.isResizeMode || this.toolboxColumnLeftMargin === toolboxColumnLeftMargin || !this.hoveredEl
            ? undefined
            : this.updateColumnToolboxPosition(toolboxColumnLeftMargin);
    }
    updateColumnToolboxPosition(toolboxColumnLeftMargin) {
        var _a, _b, _c;
        this.hideColumnToolbox();
        const leftMargin = this.calculateToolboxParamsByHoveredElement(0, 0, this.hoveredEl);
        this.toolboxColumnLeftMargin = toolboxColumnLeftMargin;
        const cellWidth = ((_c = (_b = (_a = this.hoveredEl) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect) === null || _b === void 0 ? void 0 : _b.call(_a)) === null || _c === void 0 ? void 0 : _c.width) || 0;
        return this.setToolboxPosition(leftMargin + cellWidth / 2);
    }
    replaceResizeColumnBlockAfterRemoveCol() {
        const firstRowCols = this.getFirstRowColsFromTable(this._wrappedTable);
        const firstRowLastCol = firstRowCols === null || firstRowCols === void 0 ? void 0 : firstRowCols[firstRowCols.length - 1];
        this.setElementStyle(firstRowLastCol, { width: "auto" });
        return this.replaceResizeColumnBlock();
    }
    getCurrentColElement(event) {
        var _a, _b, _c, _d, _e;
        const currentColIndex = (_a = event === null || event === void 0 ? void 0 : event.target) === null || _a === void 0 ? void 0 : _a.getAttribute(this.resizeColIndexAttrName);
        return (_e = (_d = (_c = (_b = event === null || event === void 0 ? void 0 : event.target) === null || _b === void 0 ? void 0 : _b.closest(`.${this.CSS.wrapper}`)) === null || _c === void 0 ? void 0 : _c.querySelector(`.${this.CSS.row}`)) === null || _d === void 0 ? void 0 : _d.children) === null || _e === void 0 ? void 0 : _e[currentColIndex];
    }
    checkPrevCol() {
        var _a, _b, _c, _d;
        const prevColElement = (_a = this.currentColElement) === null || _a === void 0 ? void 0 : _a.previousSibling;
        const prevColWidth = ((_c = (_b = prevColElement === null || prevColElement === void 0 ? void 0 : prevColElement.getBoundingClientRect) === null || _b === void 0 ? void 0 : _b.call(prevColElement)) === null || _c === void 0 ? void 0 : _c.width) || 0;
        return ((_d = this.currentColElement) === null || _d === void 0 ? void 0 : _d.previousSibling) ? this.setPrevColWidth(prevColWidth) : undefined;
    }
    getFirstRowFromTable(table) {
        var _a;
        return (_a = table === null || table === void 0 ? void 0 : table.querySelector) === null || _a === void 0 ? void 0 : _a.call(table, `.${this.CSS.row}`);
    }
    getFirstRowColsFromTable(table) {
        var _a;
        return (_a = this.getFirstRowFromTable(table)) === null || _a === void 0 ? void 0 : _a.children;
    }
    checkNextCol(firstRowColsCount, width) {
        var _a;
        return this.nextColElement &&
            Number((_a = this.resizeColElement) === null || _a === void 0 ? void 0 : _a.getAttribute(this.resizeColIndexAttrName)) !== firstRowColsCount - 2
            ? this.setNextColWidth(width)
            : undefined;
    }
    setPrevColWidth(width) {
        var _a;
        const currentColPrevSiblingElement = (_a = this.currentColElement) === null || _a === void 0 ? void 0 : _a.previousSibling;
        this.setElementStyle(currentColPrevSiblingElement, {
            width: width <= this.CSS.minColWidth ? this.formatColWidth(this.CSS.minColWidth) : this.formatColWidth(width)
        });
    }
    setNextColWidth(width) {
        this.setElementStyle(this.nextColElement, {
            width: width <= this.CSS.minColWidth ? this.formatColWidth(this.CSS.minColWidth) : this.formatColWidth(width)
        });
    }
    addResizeColumnBlock(wrappedTable) {
        const resizeColumnWidthBlock = document.createElement("div");
        const tableWrapDiv = wrappedTable === null || wrappedTable === void 0 ? void 0 : wrappedTable.querySelector(`.${this.CSS.wrapper}`);
        const resizeColList = this.generateResizeColList(wrappedTable);
        resizeColumnWidthBlock.classList.add(this.CSS.resizeColBlock);
        resizeColList.forEach((resizeColDiv) => {
            resizeColumnWidthBlock.appendChild(resizeColDiv);
        });
        tableWrapDiv.appendChild(resizeColumnWidthBlock);
    }
    generateResizeColList(wrappedTable) {
        const firstRowCols = this.getFirstRowColsFromTable(wrappedTable);
        const firstRowColsLength = (firstRowCols === null || firstRowCols === void 0 ? void 0 : firstRowCols.length) || 0;
        let leftMargin = 0;
        return Array.from(firstRowCols || []).reduce((res, rowCol, index) => {
            var _a, _b;
            const width = ((_b = (_a = rowCol === null || rowCol === void 0 ? void 0 : rowCol.getBoundingClientRect) === null || _a === void 0 ? void 0 : _a.call(rowCol)) === null || _b === void 0 ? void 0 : _b.width) || 0;
            leftMargin += width;
            return index < firstRowColsLength - 1 ? [...res, this.generateResizeColDiv(index, leftMargin)] : res;
        }, []);
    }
    generateResizeColDiv(index, left) {
        const resizeColDiv = document.createElement("div");
        resizeColDiv.classList.add(this.CSS.resizeCol);
        resizeColDiv.setAttribute(this.resizeColIndexAttrName, `${index}`);
        resizeColDiv.style.left = `${left - this.CSS.resizeColWidth}px`;
        return resizeColDiv;
    }
    save() {
        const firstRow = this.getFirstRowFromTable(this._wrappedTable);
        const colsWidth = (firstRow === null || firstRow === void 0 ? void 0 : firstRow.classList.contains(this.CSS.rowWithColsWidth))
            ? Array.from((firstRow === null || firstRow === void 0 ? void 0 : firstRow.children) || []).map((firstRowCol) => {
                const firstRowColElement = firstRowCol;
                return (firstRowColElement === null || firstRowColElement === void 0 ? void 0 : firstRowColElement.style.width) || "";
            })
            : [];
        return Object.assign(Object.assign({}, super.save()), { colsWidth });
    }
}
