import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { isEmpty, useIntl } from "@boomq/utils";
import { PAGE_TITLE_MESSAGE } from "./intl";
import { EditorContainer } from "../../../account/RequestGroupEditor/Editor";
import { TestProjectLeftSidebarContainer } from "../../../account/TestProject/TestProjectLeftSidebarContainer";
import { TestProjectNameContainer } from "../../../account/TestProject/TestProjectName";
import { TestProjectSettingsWizardContainer } from "../../../account/TestProject/TestProjectSettingsWizard";
import { TestProjectSettingsSetSidebarContainer } from "../../../account/TestProjectSettingsSetSidebar";
import { TestProjectContentWrapper, TestProjectPageWrapper } from "../../../../components/styled/Wrappers";
import { BackLinkButton } from "../../../../components/styled/BackLinkButton";
import { BlockHeaderWithMarginTop } from "../../../../components/styled/Common";
import { getTestProjectsRoute } from "../../../../helpers/routes";
import { getSiteTitle } from "../../../../helpers/title";
import { ProfileSettingsMode } from "../../../../models/testProject";
import newTestActions from "../../../../redux/actions/newTest";
import { testProjectDetailsRequest } from "../../../../redux/actions/testProjects";
import { getProfileSettingsMode } from "../../../../redux/reducers/newTest";
const { clearNewTestSettings } = newTestActions;
const connector = connect((state) => ({ profileSettingsMode: getProfileSettingsMode(state) }), {
    onAuthorized: testProjectDetailsRequest,
    onUnmount: clearNewTestSettings
});
const TestProjectCommonContainer = ({ children, history: { location: { state } }, isAuthorized, match: { params }, selectedTeamId, onAuthorized, onUnmount, profileSettingsMode }) => {
    const { formatMessage } = useIntl();
    useEffect(() => {
        document.title = getSiteTitle(formatMessage(PAGE_TITLE_MESSAGE));
        return () => onUnmount();
    }, []);
    useEffect(() => {
        const checkAuthorization = () => isAuthorized && !isEmpty(selectedTeamId) && params.test_project_id
            ? onAuthorized({
                id: params === null || params === void 0 ? void 0 : params.test_project_id,
                step: state && state.step ? Number(state.step) : 1,
                version: params && params.test_project_version ? Number(params.test_project_version) : undefined
            })
            : undefined;
        checkAuthorization();
    }, [params.test_project_id, isAuthorized, selectedTeamId]);
    return (React.createElement(React.Fragment, null, profileSettingsMode === ProfileSettingsMode.ThreadGroupEditor ? (React.createElement(EditorContainer, null)) : (React.createElement(TestProjectPageWrapper, null,
        React.createElement(TestProjectLeftSidebarContainer, null),
        React.createElement(TestProjectContentWrapper, null,
            React.createElement(BlockHeaderWithMarginTop, null,
                React.createElement(BackLinkButton, { to: getTestProjectsRoute() }),
                React.createElement(TestProjectNameContainer, null)),
            React.createElement(TestProjectSettingsWizardContainer, null,
                children,
                React.createElement(TestProjectSettingsSetSidebarContainer, null)))))));
};
export default withRouter(connector(TestProjectCommonContainer));
