import React, { Dispatch, FC, forwardRef, Ref, SetStateAction, useState } from "react";
import {
    generateNewMemberData,
    tableHeaderColumns,
    TableRows,
    CommonTeamMembers,
    CommonTeamMembersProps,
    CommonTeamMembersHandlers
} from "@boomq/common";
import { ScrollContainer, TeamMembersTableHeader } from "./styled";
import { TeamMembersItem } from "../TeamMembersItem";
import { TeamMembersItemTemplateAdd } from "../../../../containers/account/Team/TeamMembersItemTemplateAdd";

export interface Props extends CommonTeamMembersProps {
    isExistAdminAccess?: boolean;
    isShowAddingForm?: boolean;
    ref: Ref<HTMLDivElement>;
    teamId: string;
}

export interface Handlers extends CommonTeamMembersHandlers {
    onCopyLink?: (data) => undefined | boolean;
    onDelete?: (data: any) => void;
    setIsShowAddingForm?: Dispatch<SetStateAction<boolean>>;
}

const renderNewTeamMember = ({
    isExistAdminAccess,
    isShowAddingForm,
    setIsShowAddingForm,
    teamId,
    onAdd
}: Props & Handlers): JSX.Element => {
    const addMember = (data): void => (typeof onAdd === "function" ? onAdd(data) : undefined);

    const addMemberHandler = (data): void => {
        addMember({ teamId, data });

        return setIsShowAddingForm ? setIsShowAddingForm(false) : undefined;
    };

    const cancelAddingHandler = (): void => (setIsShowAddingForm ? setIsShowAddingForm(false) : undefined);

    return isShowAddingForm ? (
        <TeamMembersItemTemplateAdd
            {...generateNewMemberData()}
            isExistAdminAccess={isExistAdminAccess}
            onAdd={addMemberHandler}
            onCancel={cancelAddingHandler}
        />
    ) : (
        <></>
    );
};

export const TeamMembers: FC<Props & Handlers> = forwardRef(
    ({ onCopyLink, onDelete, ...restProps }: Props & Handlers, _ref): JSX.Element => {
        const [isShowAddingForm, setIsShowAddingForm] = useState(false);

        const clickAddMemberHandler = (): void => setIsShowAddingForm(true);

        return (
            <CommonTeamMembers
                {...restProps}
                isShowAddingForm={isShowAddingForm}
                onClickAddMemberButton={clickAddMemberHandler}
            >
                {renderNewTeamMember({ ...restProps, isShowAddingForm, setIsShowAddingForm })}

                <TeamMembersTableHeader columns={tableHeaderColumns} />

                <ScrollContainer ref={_ref}>
                    <TableRows>
                        {(restProps.items || []).map((teamMember) => (
                            <TeamMembersItem
                                key={teamMember.id}
                                {...teamMember}
                                isExistAdminAccess={restProps.isExistAdminAccess}
                                onCopyLink={onCopyLink}
                                onDelete={onDelete}
                                onSave={restProps.onChange}
                                teamId={restProps.teamId}
                            />
                        ))}
                    </TableRows>
                </ScrollContainer>
            </CommonTeamMembers>
        );
    }
);
