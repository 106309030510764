import React from "react";
import { FormattedMessage } from "react-intl";
import { isEmpty, round } from "@boomq/utils";
import { LoadTypeChart, LoadTypeSliders, LoadTypeSlidersWrapper, PreviewChart } from "@boomq/common";
import { StyledLoadTypeErrorMessage } from "./styled";
import { TypeSlider } from "../TypeSlider";

export const LoadTypeSettings = (props) => {
    const { chartData, disabled, handleStyle, loadProfileData, settings, slaWarn, trackStyle } = props;

    const changeHandler = typeof props.onChange === "function" ? props.onChange : () => undefined;

    const getTypeSliderValue = (sliderName) =>
        loadProfileData && !isEmpty(loadProfileData[sliderName]) ? round(loadProfileData[sliderName], 100) : 0;

    return (
        <LoadTypeSlidersWrapper disabled={disabled}>
            <LoadTypeSliders>
                {(settings || []).map((item) => (
                    <TypeSlider
                        key={item.id}
                        changeHandler={changeHandler}
                        disabled={disabled}
                        label={item.label}
                        slider={{
                            value: getTypeSliderValue(item.name),
                            min: item.min,
                            max: item.max,
                            step: item.step
                        }}
                        units={item.units}
                        name={item.name}
                        handleStyle={handleStyle ? handleStyle[item.name] : undefined}
                        trackStyle={trackStyle ? trackStyle[item.name] : undefined}
                        tooltipText={item.tooltipText}
                    />
                ))}

                {!isEmpty(slaWarn) && (
                    <StyledLoadTypeErrorMessage>
                        <FormattedMessage {...slaWarn} />
                    </StyledLoadTypeErrorMessage>
                )}
            </LoadTypeSliders>

            <LoadTypeChart>
                <PreviewChart data={chartData} pointsDataKey="coords" xAxisDataKey="time" yAxisDataKey="users" />
            </LoadTypeChart>
        </LoadTypeSlidersWrapper>
    );
};
