export const THREAD_GROUP_EDITOR_RESTORE_DATA_TEXT = {
    defaultMessage: "You are editing a thread group already. Would you like to continue editing?",
    id: "account.request.group.editor.modal.restore.data.text"
};
export const CANCEL_BUTTON_LABEL = {
    defaultMessage: "Continue without data restoring",
    id: "account.request.group.editor.modal.restore.data.button.cancel"
};
export const CONFIRM_BUTTON_LABEL = {
    defaultMessage: "Continue editing",
    id: "account.request.group.editor.modal.restore.data.button.confirm"
};
