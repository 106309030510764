export var TeamImageMode;
(function (TeamImageMode) {
    TeamImageMode["Delete"] = "DELETE";
    TeamImageMode["Edit"] = "EDIT";
    TeamImageMode["View"] = "VIEW";
})(TeamImageMode || (TeamImageMode = {}));
export var TeamAccessMode;
(function (TeamAccessMode) {
    TeamAccessMode["Admin"] = "ADMIN";
    TeamAccessMode["ManageUsers"] = "MANAGE_USERS";
})(TeamAccessMode || (TeamAccessMode = {}));
export var TeamMemberMode;
(function (TeamMemberMode) {
    TeamMemberMode["Add"] = "ADD";
    TeamMemberMode["Edit"] = "EDIT";
    TeamMemberMode["EditWithRole"] = "EDIT_WITH_ROLE";
    TeamMemberMode["View"] = "VIEW";
})(TeamMemberMode || (TeamMemberMode = {}));
export var TeamMemberPermission;
(function (TeamMemberPermission) {
    TeamMemberPermission["Admin"] = "ADMIN";
    TeamMemberPermission["Delete"] = "DELETE";
    TeamMemberPermission["Edit"] = "EDIT";
    TeamMemberPermission["ManageUsers"] = "MANAGE_USERS";
    TeamMemberPermission["OnlyOrganisation"] = "MANAGE_USERS_IN_ORG";
    TeamMemberPermission["Run"] = "RUN";
    TeamMemberPermission["ShareViewOnlyReports"] = "SHARE_VIEW_ONLY_REPORTS";
    TeamMemberPermission["View"] = "VIEW";
})(TeamMemberPermission || (TeamMemberPermission = {}));
export var TeamsItemAction;
(function (TeamsItemAction) {
    TeamsItemAction["Edit"] = "EDIT";
})(TeamsItemAction || (TeamsItemAction = {}));
