import React from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { Switcher } from "@boomq/uikit";
import { IconWrapper, ModalAnomalyTitle, ModalAnomalyWrapper, SettingsBlock, SliderWrapper, StyledIconSettings, Text, dotStyle, handleStyle, railStyle, switcherTheme, trackStyle } from "./styled";
import { SLIDER_MARKS, SLIDER_MIN, SLIDER_STEP } from "../../../models/anomalyClassifications";
export const ModalAnomalySettings = ({ anomalySettingsTitle, anomaliesDetectLabel, anomalySensitivityLabel, currentSensitivityIndex, isAnomalyEnabled, onChangeSensitivity, onChangeToggle, sensitivityLevelsArray }) => (React.createElement(ModalAnomalyWrapper, null,
    React.createElement(IconWrapper, null,
        React.createElement(StyledIconSettings, null)),
    React.createElement(ModalAnomalyTitle, null, anomalySettingsTitle),
    React.createElement(SettingsBlock, null,
        React.createElement(Text, null, anomaliesDetectLabel),
        React.createElement(Switcher, { defaultChecked: isAnomalyEnabled, name: "isAnomalyEnabled", onChange: onChangeToggle, theme: switcherTheme })),
    React.createElement(SettingsBlock, null,
        React.createElement(Text, null, anomalySensitivityLabel),
        React.createElement(SliderWrapper, null,
            React.createElement(Slider, { defaultValue: currentSensitivityIndex, dotStyle: dotStyle, handleStyle: handleStyle, marks: SLIDER_MARKS, max: sensitivityLevelsArray.length - 1, min: SLIDER_MIN, onChange: onChangeSensitivity, railStyle: railStyle, step: SLIDER_STEP, trackStyle: trackStyle })))));
