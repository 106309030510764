import React, { FC, useRef } from "react";
import { copyElementDataToClipboard } from "@boomq/utils";
import { Tooltip } from "@boomq/uikit";
import {
    ActionListItem,
    CommonTeamMembersItemTemplateView,
    CommonTeamMembersItemTemplateViewProps,
    isEmpty
} from "@boomq/common";
import {
    StyledActionList,
    HiddenDiv,
    StyledIconDelete,
    StyledIconEdit,
    StyledIconMail,
    StyledIconShareLink,
    StyledMailToLink
} from "./styled";
import { HashMap, TeamMemberMode } from "../../../../helpers/types";

export interface TeamMembersItemTemplateViewProps extends CommonTeamMembersItemTemplateViewProps {
    copyInviteLinkTooltip: string;
    deleteTeamMemberTooltip: string;
    editTeamMemberTooltip: string;
    sendInviteLinkTooltip: string;
    subject: string;
}

export interface TeamMembersItemTemplateViewHandlers {
    onCopyLink?: (data) => undefined | boolean;
    onDelete?: (data: any) => void;
    onToggleMode: (mode: TeamMemberMode) => void;
}

const InviteUrlActions = (props): JSX.Element => {
    const { email, id, permissionList, teamId, userDisplayName, userId } = props || {};
    const pseudoDivRef = useRef(null);

    const copyLink = (): void => {
        copyElementDataToClipboard(pseudoDivRef.current);

        return props.onCopyLink({
            email,
            id,
            permissionList,
            teamId,
            userDisplayName,
            userId
        });
    };

    const clickInviteLinkHandler = (e: React.MouseEvent): void => {
        e.stopPropagation();
        e.preventDefault();

        return pseudoDivRef && typeof props.onCopyLink === "function" ? copyLink() : undefined;
    };

    return isEmpty(props.inviteUrl) ? (
        <></>
    ) : (
        <>
            <HiddenDiv ref={pseudoDivRef}>{props.inviteUrl}</HiddenDiv>

            <Tooltip title={props.sendInviteLinkTooltip}>
                <StyledMailToLink email={props.email} subject={props.subject} body={props.inviteUrl}>
                    <StyledIconMail />
                </StyledMailToLink>
            </Tooltip>

            <Tooltip title={props.copyInviteLinkTooltip}>
                <ActionListItem onClick={clickInviteLinkHandler}>
                    <StyledIconShareLink />
                </ActionListItem>
            </Tooltip>
        </>
    );
};

export const TeamMembersItemTemplateView: FC<TeamMembersItemTemplateViewProps & TeamMembersItemTemplateViewHandlers> = (
    props: TeamMembersItemTemplateViewProps & TeamMembersItemTemplateViewHandlers
): JSX.Element => {
    const { id, teamId } = props || {};

    const actionHandler = (e: React.MouseEvent, action: any, params?: string | HashMap<string | number>): void => {
        e.stopPropagation();
        e.preventDefault();

        return typeof props[action] === "function" ? props[action](params) : undefined;
    };

    const clickDeleteHandler = (e: React.MouseEvent): void =>
        typeof props.onDelete === "function" ? props.onDelete({ teamId, data: { id } }) : undefined;

    const clickEditHandler = (e: React.MouseEvent): void => actionHandler(e, "onToggleMode", TeamMemberMode.Edit);

    return (
        <>
            <CommonTeamMembersItemTemplateView {...props} />

            <StyledActionList>
                <InviteUrlActions {...props} />

                <Tooltip title={props.editTeamMemberTooltip}>
                    <ActionListItem onClick={clickEditHandler}>
                        <StyledIconEdit />
                    </ActionListItem>
                </Tooltip>

                <Tooltip title={props.deleteTeamMemberTooltip}>
                    <ActionListItem onClick={clickDeleteHandler}>
                        <StyledIconDelete />
                    </ActionListItem>
                </Tooltip>
            </StyledActionList>
        </>
    );
};
