export const AVG_RESP_TIME = "currentAvgRespTime";
export const CLASSIFICATION = "classification";
export const ERRORS = "totalErrors";
export const PCT_95_RESP_TIME = "currentPct95RespTime";
export const THROUGHPUT = "currentThroughput";
export const TIMELINE_ERRORS = "errorCount";
export const TIMELINE_PCT_95_RESP_TIME = "responseTime95Perc";
export const TIMELINE_THREADS = "activeThreads";
export const TIMELINE_THROUGHPUT = "throughput";
export const TIMELINE_TIME = "time";
export const USERS = "currentThreads";
