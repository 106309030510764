export const END_INDEX_INPUT_PLACEHOLDER = {
    defaultMessage: "end index",
    id: "account.request.group.editor.element.for.each.end.index.placeholder"
};
export const INPUT_VARIABLE_INPUT_PLACEHOLDER = {
    defaultMessage: "input variable",
    id: "account.request.group.editor.element.for.each.input.variable.placeholder"
};
export const OUTPUT_VARIABLE_INPUT_PLACEHOLDER = {
    defaultMessage: "output variable",
    id: "account.request.group.editor.element.for.each.output.variable.placeholder"
};
export const START_INDEX_INPUT_PLACEHOLDER = {
    defaultMessage: "start index",
    id: "account.request.group.editor.element.for.each.start.index.placeholder"
};
