import React, { useEffect } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { getValueByPath, isEmpty } from "@boomq/utils";
import { RANGE_SLIDER_LABEL, RANGE_SLIDER_UNITS_LABEL, SWITCHER_MERGE_GROUPS_LABEL } from "./intl";
import { PreviewChart } from "../../PreviewChart";
import RangeSlider from "../../../common/RangeSlider";
import { Switcher } from "../../../common/Switcher";
import { rangeSliderRailStyleTheme, ChartSection, SwitchChartMergeGroupsSection, SettingsWrapper } from "../../../styled/TestProject";
import newTestActions from "../../../../redux/actions/newTest";
import { getIsMergedChartThreadGroups, getLoadProfilesChartLegend, getPreviewThreadGroupsChartTimeline, getTestProjectSettingsField } from "../../../../redux/reducers/newTest";
const { changePreviewThreadGroupsChartField, refreshThreadGroupsChartPreview } = newTestActions;
const connector = connect((state) => ({
    isMergedChartThreadGroups: getIsMergedChartThreadGroups(state),
    chartData: getTestProjectSettingsField(state, "chartData"),
    chartLegend: getLoadProfilesChartLegend(state),
    timeLine: getPreviewThreadGroupsChartTimeline(state)
}), { onChange: changePreviewThreadGroupsChartField, onInit: refreshThreadGroupsChartPreview });
const getSliderParams = (props) => ({
    min: Math.floor(getValueByPath(props, "timeLine.min", 0)),
    max: Math.ceil(getValueByPath(props, "timeLine.max", 1)),
    step: 1,
    value: [
        Math.floor(getValueByPath(props, "timeLine.value.0", 0)),
        Math.ceil(getValueByPath(props, "timeLine.value.1", 1))
    ]
});
const getSwitcherValue = (props) => isEmpty(props.isMergedChartThreadGroups) ? false : props.isMergedChartThreadGroups;
const getTimelineValue = (props, num) => getValueByPath(props, `timeLine.value.${num}`, 0) / 60;
const PreviewThreadGroupsChart = (props) => {
    useEffect(() => {
        const init = () => props === null || props === void 0 ? void 0 : props.onInit();
        init();
    }, []);
    const changePreviewChartDataHandler = (field, value) => props.onChange({
        field,
        value: field === "timeLine" ? Object.assign(Object.assign({}, props.timeLine), { value }) : value
    });
    const toggleSwitcherHandler = (e) => e && e.target ? changePreviewChartDataHandler("isMergedChartGroups", e.target.checked) : undefined;
    return (React.createElement(SettingsWrapper, null,
        React.createElement(ChartSection, null,
            React.createElement(SwitchChartMergeGroupsSection, null,
                React.createElement(Switcher, { defaultChecked: getSwitcherValue(props), onClick: toggleSwitcherHandler }),
                React.createElement(FormattedMessage, Object.assign({}, SWITCHER_MERGE_GROUPS_LABEL))),
            React.createElement(PreviewChart, { data: props.chartData, isShowLegend: true, legendPayload: props.chartLegend, min: getTimelineValue(props, 0), max: getTimelineValue(props, 1), minHeight: 450 }),
            React.createElement(RangeSlider, { changeHandler: changePreviewChartDataHandler, label: RANGE_SLIDER_LABEL, railStyle: rangeSliderRailStyleTheme, slider: getSliderParams(props), units: RANGE_SLIDER_UNITS_LABEL, name: "timeLine" }))));
};
export default connector(PreviewThreadGroupsChart);
