import React from "react";
import { connect } from "react-redux";
import { ConfirmForm } from "../../common/ConfirmForm";
import { testProjectDetailsSuccess } from "../../../redux/actions/testProjects";
const connector = connect(null, { testProjectDetailsSuccess });
const RestoreSettingsForm = ({ data, testProjectDetailsSuccess }) => React.createElement(ConfirmForm, { confirmButton: {
        action: () => testProjectDetailsSuccess(Object.assign(Object.assign({}, data), { isModified: true })),
        theme: { width: "40%!important" },
        title: {
            defaultMessage: "Continue editing",
            id: "account.new.test.project.restore.button.confirm"
        }
    }, cancelButton: {
        theme: { width: "40%!important" },
        title: {
            defaultMessage: "Create new",
            id: "account.new.test.project.restore.button.cancel"
        }
    }, text: {
        description: [{
                defaultMessage: "You are editing a test already. Would you like to continue editing or create a new test?",
                id: "account.new.test.project.restore.text"
            }]
    } });
export default connector(RestoreSettingsForm);
