import React from "react";
import { DataGrid } from "@boomq/uikit";
import { StyledPage } from "./styled";
import { TestProjectsSettingsSetsWizard } from "../TestProjectsSettingsSetsWizard";
import { NewTest } from "../../styled/AllTests";
import { AllTestProjectsItemContainer } from "../../../containers/account/AllTestProjectsItem";
import { AllTestProjectsPanelContainer } from "../../../containers/account/AllTestProjectsPanel";
import { AllTestProjectsSettingsSetSidebarContainer } from "../../../containers/account/AllTestProjectsSettingsSetSidebar";
import { isAllowedTestProjectActionByPermissions } from "../../../helpers/testProject";
import { TestProjectAction, TestProjectsSettingsSetsWizardItem } from "../../../helpers/types";
export const AllTestProjects = ({ currentPage, filterLabels, gridColumns, isShowLoader, labelList, newTestBlockText, onChangeFilter, onChangePage, permissionList, testProjects, testProjectsPagesCount }) => (React.createElement(React.Fragment, null,
    React.createElement(StyledPage, null,
        React.createElement(TestProjectsSettingsSetsWizard, { selectedWizardItem: TestProjectsSettingsSetsWizardItem.Projects }),
        isShowLoader ? (React.createElement(React.Fragment, null)) : (React.createElement(React.Fragment, null,
            React.createElement(AllTestProjectsPanelContainer, { onChangeFilter: onChangeFilter, filterLabels: filterLabels, labelList: labelList }),
            isAllowedTestProjectActionByPermissions(permissionList, TestProjectAction.Add) && (React.createElement(NewTest, { to: "/account/new-test" }, newTestBlockText)),
            React.createElement(DataGrid, { currentPage: currentPage, columns: gridColumns, items: testProjects, itemTemplate: AllTestProjectsItemContainer, onChangePage: onChangePage, pagesCount: testProjectsPagesCount }),
            React.createElement(AllTestProjectsSettingsSetSidebarContainer, null))))));
