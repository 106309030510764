import styled from "styled-components";
import { Color, Help, IconLock, IconUnlock, InputText, Select } from "@boomq/uikit";
import { getZIndex } from "../../../styled/global";
const iconStyles = `
    fill: ${Color.grey50};
    height: 20px;
    width: 20px;
`;
export const FullMethodSelect = styled(Select) `
    margin-left: 12px;
    width: 100%;
`;
export const RequestUrl = styled.div `
    align-items: center;
    display: flex;
    position: relative;
    width: 100%;
`;
export const StyledHelp = styled(Help) `
    align-items: center;
    margin-left: 6px;
`;
export const StyledIconLock = styled(IconLock) `
    ${iconStyles}
`;
export const StyledIconUnlock = styled(IconUnlock) `
    ${iconStyles}
`;
export const StyledInputText = styled(InputText) `
    padding-left: 32px;
`;
export const TlsTogglerWrapper = styled.div `
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    left: 24px;
    position: absolute;
    z-index: ${getZIndex("RequestGrpcFormTlsToggler")};
`;
