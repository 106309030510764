import { SLA_HOST_NAME_PARAM_NAME, SLA_REQUEST_NAME_PARAM_NAME, SLA_RESTRICTION_TYPE_PARAM_NAME, SLA_TARGET_TYPE_PARAM_NAME, SLA_THREAD_GROUP_ID_PARAM_NAME, SLA_THREAD_GROUP_NAME_PARAM_NAME, SLA_TRANSACTION_FILTER_TYPE_PARAM_NAME, SLA_TRANSACTION_NAME_PARAM_NAME, SLA_TYPE_PARAM_NAME } from "../sla/constant";
import { getSlaRequestName, getSlaRestrictionType, getSlaThreadGroupId, getSlaThreadGroupName, getSlaTransactionFilterType, getSlaTransactionName } from "../sla/slaParams";
import { SlaStatus, SlaTargetType, SlaThreadGroupFilterType, SlaTransactionFilterType, SlaType } from "../../models/sla/enums";
export const prepareSlaListItem = (slaParams) => ({
    fromDate: slaParams.fromDate,
    [SLA_RESTRICTION_TYPE_PARAM_NAME]: getSlaRestrictionType(slaParams),
    operation: slaParams.operation,
    status: slaParams.status || SlaStatus.NotCounted,
    toDate: slaParams.toDate,
    value: slaParams.value
});
const getSlaTransactionParamsByFilterType = (slaItem) => getSlaTransactionFilterType(slaItem) === SlaTransactionFilterType.Name
    ? {
        [SLA_TRANSACTION_NAME_PARAM_NAME]: getSlaTransactionName(slaItem),
        [SLA_TRANSACTION_FILTER_TYPE_PARAM_NAME]: getSlaTransactionFilterType(slaItem)
    }
    : { [SLA_TRANSACTION_FILTER_TYPE_PARAM_NAME]: getSlaTransactionFilterType(slaItem) };
const prepareSlaTransactionId = (slaItem) => `${slaItem.threadGroupId}_${slaItem.transactionId}`;
const prepareSlaRequestId = (slaItem) => `${prepareSlaTransactionId(slaItem)}_${getSlaRequestName(slaItem)}`;
const prepareSlaThreadGroupData = (slaItem) => getSlaThreadGroupId(slaItem) === SlaThreadGroupFilterType.AnyThreadGroup
    ? {
        [SLA_THREAD_GROUP_ID_PARAM_NAME]: null,
        [SLA_THREAD_GROUP_NAME_PARAM_NAME]: null
    }
    : {
        [SLA_THREAD_GROUP_ID_PARAM_NAME]: getSlaThreadGroupId(slaItem),
        [SLA_THREAD_GROUP_NAME_PARAM_NAME]: getSlaThreadGroupName(slaItem)
    };
const prepareSlaSystemMetricsHost = (slaGroupList) => {
    const mergedSlaByHostname = (slaGroupList || []).reduce((res, slaItem) => (Object.assign(Object.assign({}, res), { [slaItem.hostname]: res[slaItem.hostname]
            ? Object.assign(Object.assign({}, res[slaItem.hostname]), { slaList: [...res[slaItem.hostname].slaList, prepareSlaListItem(slaItem)] }) : {
            type: SlaType.SystemMetricsHost,
            targetType: SlaTargetType.SystemMetricsHost,
            [SLA_HOST_NAME_PARAM_NAME]: slaItem.hostname,
            slaList: [prepareSlaListItem(slaItem)]
        } })), {});
    return Object.keys(mergedSlaByHostname).reduce((res, hostname) => [...res, mergedSlaByHostname[hostname]], []);
};
const prepareSlaRequest = (slaGroupList) => {
    const mergedSlaByTransactionRequest = (slaGroupList || []).reduce((res, slaItem) => {
        const slaRequestId = prepareSlaRequestId(slaItem);
        return Object.assign(Object.assign({}, res), { [slaRequestId]: res[slaRequestId]
                ? Object.assign(Object.assign({}, res[slaRequestId]), { slaList: [...res[slaRequestId].slaList, prepareSlaListItem(slaItem)] }) : Object.assign(Object.assign(Object.assign({ [SLA_TYPE_PARAM_NAME]: SlaType.Request, [SLA_TARGET_TYPE_PARAM_NAME]: SlaTargetType.Request, transactionId: slaItem.transactionId }, getSlaTransactionParamsByFilterType(slaItem)), { [SLA_REQUEST_NAME_PARAM_NAME]: getSlaRequestName(slaItem), slaList: [prepareSlaListItem(slaItem)] }), prepareSlaThreadGroupData(slaItem)) });
    }, {});
    return Object.keys(mergedSlaByTransactionRequest).reduce((res, transactionRequestName) => [...res, mergedSlaByTransactionRequest[transactionRequestName]], []);
};
const prepareSlaThreadGroup = (slaGroupList) => {
    const mergedSlaByThreadGroup = (slaGroupList || []).reduce((res, slaItem) => (Object.assign(Object.assign({}, res), { [slaItem.threadGroupId]: res[slaItem.threadGroupId]
            ? Object.assign(Object.assign({}, res[slaItem.threadGroupId]), { slaList: [...res[slaItem.threadGroupId].slaList, prepareSlaListItem(slaItem)] }) : Object.assign({ type: SlaType.ThreadGroup, targetType: SlaTargetType.ThreadGroup, slaList: [prepareSlaListItem(slaItem)] }, prepareSlaThreadGroupData(slaItem)) })), {});
    return Object.keys(mergedSlaByThreadGroup).reduce((res, threadGroupId) => [...res, mergedSlaByThreadGroup[threadGroupId]], []);
};
const prepareSlaTransaction = (slaGroupList) => {
    const mergedSlaByTransaction = (slaGroupList || []).reduce((res, slaItem) => {
        const slaTransactionId = prepareSlaTransactionId(slaItem);
        return Object.assign(Object.assign({}, res), { [slaTransactionId]: res[slaTransactionId]
                ? Object.assign(Object.assign({}, res[slaTransactionId]), { slaList: [...res[slaTransactionId].slaList, prepareSlaListItem(slaItem)] }) : Object.assign({ type: SlaType.Transaction, targetType: SlaTargetType.Transaction, transactionId: slaItem.transactionId, transactionName: slaItem.transactionName, slaList: [prepareSlaListItem(slaItem)] }, prepareSlaThreadGroupData(slaItem)) });
    }, {});
    return Object.keys(mergedSlaByTransaction).reduce((res, transactionId) => [...res, mergedSlaByTransaction[transactionId]], []);
};
const prepareSlaTest = (slaGroupList) => {
    const slaList = (slaGroupList || []).reduce((res, slaItem) => [...res, prepareSlaListItem(slaItem)], []);
    return slaList.length > 0
        ? [
            {
                type: SlaType.Test,
                targetType: SlaTargetType.Test,
                slaList
            }
        ]
        : [];
};
export const generateTestProjectSlaParams = (slaGroupList) => [
    ...prepareSlaTest(slaGroupList[SlaTargetType.Test]),
    ...prepareSlaThreadGroup(slaGroupList[SlaTargetType.ThreadGroup]),
    ...prepareSlaTransaction(slaGroupList[SlaTargetType.Transaction]),
    ...prepareSlaRequest(slaGroupList[SlaTargetType.Request]),
    ...prepareSlaSystemMetricsHost(slaGroupList[SlaTargetType.SystemMetricsHost])
];
