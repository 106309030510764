var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { Button, ButtonSize, Tabs } from "@boomq/uikit";
import { FULL_METHOD_TOOLTIP_TEXT } from "./intl";
import { FullMethodSelect, RequestUrl, StyledHelp, StyledInputText } from "./styled";
import { TlsToggler } from "./TlsToggler";
import { BodyText } from "../BodyText";
import { Metadata } from "../Metadata";
import { RequestName } from "../RequestHttpForm/RequestName";
import { customHintTheme, customTitleTheme, ButtonSection, ButtonWithHintSection, RequestBlock, RequestNameLabel, RequestSection, StyledSelect } from "../RequestHttpForm/styled";
import { RequestMethodBodyTemplate } from "../RequestHttpForm/RequestMethodBodyTemplate";
import { FormTitle } from "../../../styled/Titles";
import { Hint } from "../../../common/Hint";
import { AssertionsContainer } from "../../../../containers/account/TestProject/Assertions";
import { ExtractResponseParametersContainer } from "../../../../containers/account/TestProject/ExtractResponseParameters";
import { RequestGrpcTimersContainer } from "../../../../containers/account/TestProject/RequestGrpcTimers";
import { RequestJMXTimers } from "../../../../containers/account/TestProject/RequestJMXTimers";
import { FormType } from "../../../../helpers/types";
import { getGrpcAssertionTypeItems } from "../../../../models/assertion";
import { grpcExtractTypes } from "../../../../models/extractFromResponse";
import warning from "../../../../assets/images/icons/warn.svg";
const requestParamElements = {
    Assertions: (props) => (React.createElement(AssertionsContainer, Object.assign({}, props, { assertionTypeItems: getGrpcAssertionTypeItems() }))),
    BodyText,
    ExtractResponseParameters: (props) => (React.createElement(ExtractResponseParametersContainer, Object.assign({}, props, { extractTypes: grpcExtractTypes }))),
    Metadata,
    RequestTimers: RequestGrpcTimersContainer,
    RequestJMXTimers
};
export const RequestGrpcForm = (_a) => {
    var { isDisabledInputs, onChangeName, onToggleTls, requestName, requestNameLabel, isTls } = _a, restProps = __rest(_a, ["isDisabledInputs", "onChangeName", "onToggleTls", "requestName", "requestNameLabel", "isTls"]);
    const { errorForm, errorProperties } = restProps.errorMap || { errorForm: {}, errorProperties: {} };
    const { error, isValid } = errorForm || {};
    return (React.createElement(React.Fragment, null,
        React.createElement(FormTitle, { theme: customTitleTheme }, restProps.title),
        React.createElement(RequestBlock, null,
            React.createElement(RequestSection, null,
                React.createElement(StyledSelect, { bodyTemplate: RequestMethodBodyTemplate, defaultValue: restProps.method, disabled: isDisabledInputs, items: restProps.methodItems, onChange: restProps.onChangeMethod }),
                React.createElement(RequestUrl, null,
                    React.createElement(TlsToggler, { onToggle: onToggleTls, value: isTls }),
                    React.createElement(StyledInputText, { enabled: !isDisabledInputs, error: errorProperties.requestUrl, name: "requestUrl", onChange: restProps.onChangeUrl, value: restProps.requestUrl }))),
            React.createElement(RequestSection, null,
                React.createElement(RequestNameLabel, null, restProps.fullMethodLabel),
                React.createElement(FullMethodSelect, { defaultValue: restProps.fullMethod, disabled: isDisabledInputs, error: errorProperties.fullMethod, items: restProps.fullMethodItems, onChange: restProps.onChangeFullMethod, placeholder: restProps.fullMethodSelectPlaceholder }),
                React.createElement(StyledHelp, { tooltipText: FULL_METHOD_TOOLTIP_TEXT })),
            React.createElement(RequestSection, null,
                React.createElement(RequestName, Object.assign({}, restProps, { isDisabledInputs: isDisabledInputs, onChange: onChangeName, requestName: requestName, requestNameLabel: requestNameLabel })))),
        React.createElement(Tabs, { currentActiveTabName: restProps.currentActiveTabName, defaultActiveTabName: restProps.defaultActiveTabName, elements: requestParamElements, items: restProps.tabItems, name: "requestParams", onChangeTab: restProps.onChangeTab }),
        restProps.formType !== FormType.View && (React.createElement(ButtonSection, null,
            React.createElement(ButtonWithHintSection, null,
                React.createElement(Button, { disabled: !isValid, onClick: restProps.onSave, size: ButtonSize.small }, restProps.buttonSaveLabel),
                !isValid && React.createElement(Hint, { icon: warning, theme: customHintTheme, tooltipText: error }))))));
};
