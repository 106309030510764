import React from "react";
import { Pagination } from "@boomq/uikit";
import { AllTeamsPaginationWrapper } from "./styled";
export const AllTeamsPagination = (props) => {
    const changePage = (pageNum) => typeof props.onChangePage === "function" ? props.onChangePage(Number(pageNum)) : undefined;
    const clickPageHandler = (pageNum) => {
        changePage(pageNum);
        return typeof props.onRefreshItems === "function" ? props.onRefreshItems({ page: pageNum }) : undefined;
    };
    return props.itemsPagesCount > 1 ? (React.createElement(AllTeamsPaginationWrapper, null,
        React.createElement(Pagination, { totalPages: props.itemsPagesCount, currentPage: props.currentPage, clickHandler: clickPageHandler }))) : (React.createElement(React.Fragment, null));
};
