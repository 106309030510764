import { isEmpty, parseRequestUrlToParameters } from "@boomq/utils";
import { PARAMETER_IN_PROTOCOL_HTTPS_REG_EXP_MASK, PARAMETER_REG_EXP_MASK } from "../../models/request/constants";
export const copyRequestNameFromUrlWithoutResolveParameters = (requestUrl) => (requestUrl || "")
    .replace(PARAMETER_IN_PROTOCOL_HTTPS_REG_EXP_MASK, "$1param.param/")
    .replace(PARAMETER_REG_EXP_MASK, "$1");
export const formatErrorMap = (errorMap = { errorProperties: {} }, formatMessage) => (Object.assign(Object.assign({}, errorMap), { errorProperties: Object.keys(errorMap.errorProperties).reduce((res, propertyKey) => (Object.assign(Object.assign({}, res), { [propertyKey]: isEmpty(errorMap.errorProperties[propertyKey])
            ? undefined
            : formatMessage(errorMap.errorProperties[propertyKey]) })), {}) }));
export const getTimerListByIds = (timers, timerIds) => Object.keys(timers || {}).reduce((res, timerId) => (timerIds.includes(timerId) ? [...res, timers[timerId]] : res), []);
export const getRequestAssertionsCount = (assertions = []) => Array.isArray(assertions) ? assertions.length : 0;
export const getRequestId = (request) => request && request.id ? request.id : undefined;
export const getRequestName = (request) => request && request.name ? request.name : "";
export const getRequestTimerList = (request) => request && Array.isArray(request.timerList) ? request.timerList : [];
export const getRequestsByGroupId = (requests, groupId) => requests.filter((request) => request.groupId === groupId);
export const getRequestById = (requests, id) => requests.find((request) => request.id === id);
export const getRequestByName = (requests, name) => requests.find((request) => getRequestName(request) === name);
export const getRequestTimersCount = (timerList = []) => Array.isArray(timerList) ? timerList.length : 0;
export const getRequestUrlPath = (requestUrl) => {
    const { path } = parseRequestUrlToParameters(requestUrl);
    return path;
};
export const getRequestsWithTimerListByGroupId = (requests, timers, groupId) => requests
    .filter((request) => request.groupId === groupId)
    .map((request) => (Object.assign(Object.assign({}, request), { timerList: getTimerListByIds(timers, request.timerList) })));
export const isExistRequestInList = (requests, request) => {
    const existedRequest = getRequestById(requests, request.id);
    return isEmpty(existedRequest) ? false : true;
};
