import { getAssertionFilter, getAssertionType } from "./assertionParams";
import { ASSERTION_RESPONSE_DEFAULT_TEMPLATE_TYPE, ASSERTION_RESPONSE_BODY_DEFAULT_TEMPLATE_TYPE } from "./constants";
import { AssertionCondition, AssertionConditionComparison, AssertionConditionExist, AssertionConditionNull, AssertionConditionXPath2, AssertionDurationType, AssertionFilter, AssertionResponseType, AssertionSizeType, AssertionTemplateType } from "./enums";
import { ASSERTION_CONDITION_COMPARISON_EQUALS, ASSERTION_CONDITION_COMPARISON_GREATER_OR_EQUALS, ASSERTION_CONDITION_COMPARISON_GREATER_THAN, ASSERTION_CONDITION_COMPARISON_LESS_OR_EQUALS, ASSERTION_CONDITION_COMPARISON_LESS_THAN, ASSERTION_CONDITION_COMPARISON_NOT_EQUALS, ASSERTION_CONDITION_EQUALS, ASSERTION_CONDITION_EXISTS, ASSERTION_CONDITION_MATCHES_XPATH2, ASSERTION_CONDITION_NOT_EQUALS, ASSERTION_CONDITION_NOT_EXISTS, ASSERTION_CONDITION_NOT_MATCHES_XPATH2, ASSERTION_CONDITION_NOT_NULL, ASSERTION_CONDITION_NOT_SUBSTRING, ASSERTION_CONDITION_NULL, ASSERTION_CONDITION_REGEXP_CONTAINS, ASSERTION_CONDITION_REGEXP_MATCHES, ASSERTION_CONDITION_REGEXP_NOT_CONTAINS, ASSERTION_CONDITION_REGEXP_NOT_MATCHES, ASSERTION_CONDITION_SUBSTRING, ASSERTION_FILTER_JSON_PATH, ASSERTION_FILTER_NULL, ASSERTION_FILTER_XPATH, ASSERTION_TYPE_REQUEST_BODY, ASSERTION_TYPE_REQUEST_HEADERS, ASSERTION_TYPE_REQUEST_METADATA, ASSERTION_TYPE_RESPONSE_BODY, ASSERTION_TYPE_RESPONSE_BODY_SIZE, ASSERTION_TYPE_RESPONSE_CODE, ASSERTION_TYPE_RESPONSE_CODE_SIZE, ASSERTION_TYPE_RESPONSE_DOCUMENT, ASSERTION_TYPE_RESPONSE_DURATION, ASSERTION_TYPE_RESPONSE_FULL_SIZE, ASSERTION_TYPE_RESPONSE_HEADERS, ASSERTION_TYPE_RESPONSE_HEADERS_SIZE, ASSERTION_TYPE_RESPONSE_MESSAGE, ASSERTION_TYPE_RESPONSE_MESSAGE_SIZE, ASSERTION_TYPE_RESPONSE_METADATA, ASSERTION_TYPE_RESPONSE_METADATA_SIZE, ASSERTION_TYPE_URL_SAMPLED } from "./intl";
import { AssertionDurationTemplateContainer } from "../../containers/account/TestProject/AssertionDurationTemplate";
import { AssertionResponseTemplateContainer } from "../../containers/account/TestProject/AssertionResponseTemplate";
import { AssertionResponseBodyTemplateContainer } from "../../containers/account/TestProject/AssertionResponseBodyTemplate";
import { AssertionResponseBodyJsonTemplateContainer } from "../../containers/account/TestProject/AssertionResponseBodyJsonTemplate";
import { AssertionResponseBodyXPath2TemplateContainer } from "../../containers/account/TestProject/AssertionResponseBodyXPath2Template";
import { AssertionSizeTemplateContainer } from "../../containers/account/TestProject/AssertionSizeTemplate";
export var ResponsePreviewSource;
(function (ResponsePreviewSource) {
    ResponsePreviewSource["Har"] = "HAR";
})(ResponsePreviewSource || (ResponsePreviewSource = {}));
export const assertionTemplatesByType = {
    [AssertionTemplateType.Duration]: AssertionDurationTemplateContainer,
    [AssertionTemplateType.Response]: AssertionResponseTemplateContainer,
    [AssertionTemplateType.ResponseBody]: AssertionResponseBodyTemplateContainer,
    [AssertionTemplateType.ResponseBodyJson]: AssertionResponseBodyJsonTemplateContainer,
    [AssertionTemplateType.ResponseBodyXPath2]: AssertionResponseBodyXPath2TemplateContainer,
    [AssertionTemplateType.Size]: AssertionSizeTemplateContainer
};
const assertionTypeResponseCommonItems = [
    {
        id: AssertionResponseType.ResponseBody,
        text: ASSERTION_TYPE_RESPONSE_BODY,
        value: AssertionResponseType.ResponseBody
    },
    {
        id: AssertionResponseType.ResponseTikaExtract,
        text: ASSERTION_TYPE_RESPONSE_DOCUMENT,
        value: AssertionResponseType.ResponseTikaExtract
    },
    {
        id: AssertionResponseType.UrlSampled,
        text: ASSERTION_TYPE_URL_SAMPLED,
        value: AssertionResponseType.UrlSampled
    },
    {
        id: AssertionResponseType.ResponseCode,
        text: ASSERTION_TYPE_RESPONSE_CODE,
        value: AssertionResponseType.ResponseCode
    },
    {
        id: AssertionResponseType.ResponseMessage,
        text: ASSERTION_TYPE_RESPONSE_MESSAGE,
        value: AssertionResponseType.ResponseMessage
    },
    {
        id: AssertionResponseType.RequestData,
        text: ASSERTION_TYPE_REQUEST_BODY,
        value: AssertionResponseType.RequestData
    }
];
const assertionTypeGrpcResponseItems = [
    {
        id: AssertionResponseType.ResponseHeaders,
        text: ASSERTION_TYPE_RESPONSE_METADATA,
        value: AssertionResponseType.ResponseHeaders
    },
    {
        id: AssertionResponseType.RequestHeaders,
        text: ASSERTION_TYPE_REQUEST_METADATA,
        value: AssertionResponseType.RequestHeaders
    }
];
const assertionTypeHttpResponseItems = [
    {
        id: AssertionResponseType.ResponseHeaders,
        text: ASSERTION_TYPE_RESPONSE_HEADERS,
        value: AssertionResponseType.ResponseHeaders
    },
    {
        id: AssertionResponseType.RequestHeaders,
        text: ASSERTION_TYPE_REQUEST_HEADERS,
        value: AssertionResponseType.RequestHeaders
    }
];
const assertionTypeDurationItems = [
    {
        id: AssertionDurationType.Duration,
        text: ASSERTION_TYPE_RESPONSE_DURATION,
        value: AssertionDurationType.Duration
    }
];
const assertionTypeSizeCommonItems = [
    {
        id: AssertionSizeType.ResponseBody,
        text: ASSERTION_TYPE_RESPONSE_BODY_SIZE,
        value: AssertionSizeType.ResponseBody
    },
    {
        id: AssertionSizeType.ResponseCode,
        text: ASSERTION_TYPE_RESPONSE_CODE_SIZE,
        value: AssertionSizeType.ResponseCode
    },
    {
        id: AssertionSizeType.FullResponse,
        text: ASSERTION_TYPE_RESPONSE_FULL_SIZE,
        value: AssertionSizeType.FullResponse
    },
    {
        id: AssertionSizeType.ResponseMessage,
        text: ASSERTION_TYPE_RESPONSE_MESSAGE_SIZE,
        value: AssertionSizeType.ResponseMessage
    }
];
const assertionTypeGrpcSizeItems = [
    {
        id: AssertionSizeType.ResponseHeaders,
        text: ASSERTION_TYPE_RESPONSE_METADATA_SIZE,
        value: AssertionSizeType.ResponseHeaders
    }
];
const assertionTypeHttpSizeItems = [
    {
        id: AssertionSizeType.ResponseHeaders,
        text: ASSERTION_TYPE_RESPONSE_HEADERS_SIZE,
        value: AssertionSizeType.ResponseHeaders
    }
];
const assertionFilterItems = [
    {
        id: 0,
        text: ASSERTION_FILTER_NULL,
        value: AssertionFilter.NoFilter
    },
    {
        id: 1,
        text: ASSERTION_FILTER_JSON_PATH,
        value: AssertionFilter.JsonPath
    },
    {
        id: 2,
        text: ASSERTION_FILTER_XPATH,
        value: AssertionFilter.XPath2
    }
];
const assertionResponseConditionCheckEqualsItems = [
    {
        id: AssertionCondition.Equals,
        text: ASSERTION_CONDITION_EQUALS,
        value: AssertionCondition.Equals
    },
    {
        id: AssertionCondition.NotEquals,
        text: ASSERTION_CONDITION_NOT_EQUALS,
        value: AssertionCondition.NotEquals
    }
];
const assertionResponseConditionCheckItems = [
    {
        id: AssertionCondition.NotSubstring,
        text: ASSERTION_CONDITION_NOT_SUBSTRING,
        value: AssertionCondition.NotSubstring
    },
    {
        id: AssertionCondition.Substring,
        text: ASSERTION_CONDITION_SUBSTRING,
        value: AssertionCondition.Substring
    }
];
const assertionConditionComparisonItems = [
    {
        id: AssertionConditionComparison.GreaterThan,
        text: ASSERTION_CONDITION_COMPARISON_GREATER_THAN,
        value: AssertionConditionComparison.GreaterThan
    },
    {
        id: AssertionConditionComparison.LessThan,
        text: ASSERTION_CONDITION_COMPARISON_LESS_THAN,
        value: AssertionConditionComparison.LessThan
    },
    {
        id: AssertionConditionComparison.Equals,
        text: ASSERTION_CONDITION_COMPARISON_EQUALS,
        value: AssertionConditionComparison.Equals
    },
    {
        id: AssertionConditionComparison.NotEquals,
        text: ASSERTION_CONDITION_COMPARISON_NOT_EQUALS,
        value: AssertionConditionComparison.NotEquals
    },
    {
        id: AssertionConditionComparison.GreaterOrEquals,
        text: ASSERTION_CONDITION_COMPARISON_GREATER_OR_EQUALS,
        value: AssertionConditionComparison.GreaterOrEquals
    },
    {
        id: AssertionConditionComparison.LessOrEquals,
        text: ASSERTION_CONDITION_COMPARISON_LESS_OR_EQUALS,
        value: AssertionConditionComparison.LessOrEquals
    }
];
const assertionResponseConditionExists = [
    {
        id: AssertionConditionExist.Exists,
        text: ASSERTION_CONDITION_EXISTS,
        value: AssertionConditionExist.Exists
    },
    {
        id: AssertionConditionExist.NotExists,
        text: ASSERTION_CONDITION_NOT_EXISTS,
        value: AssertionConditionExist.NotExists
    }
];
const assertionResponseConditionNull = [
    {
        id: AssertionConditionNull.Null,
        text: ASSERTION_CONDITION_NULL,
        value: AssertionConditionNull.Null
    },
    {
        id: AssertionConditionNull.NotNull,
        text: ASSERTION_CONDITION_NOT_NULL,
        value: AssertionConditionNull.NotNull
    }
];
const assertionResponseBodyXPath2ConditionItems = [
    {
        id: AssertionConditionXPath2.MatchesXPath2,
        text: ASSERTION_CONDITION_MATCHES_XPATH2,
        value: AssertionConditionXPath2.MatchesXPath2
    },
    {
        id: AssertionConditionXPath2.NotMatchesXPath2,
        text: ASSERTION_CONDITION_NOT_MATCHES_XPATH2,
        value: AssertionConditionXPath2.NotMatchesXPath2
    }
];
const assertionResponseConditionRegExpMatchesItems = [
    {
        id: AssertionCondition.RegexpMatches,
        text: ASSERTION_CONDITION_REGEXP_MATCHES,
        value: AssertionCondition.RegexpMatches
    },
    {
        id: AssertionCondition.NotRegexpMatches,
        text: ASSERTION_CONDITION_REGEXP_NOT_MATCHES,
        value: AssertionCondition.NotRegexpMatches
    }
];
const assertionResponseConditionRegExpItems = [
    {
        id: AssertionCondition.RegexpContains,
        text: ASSERTION_CONDITION_REGEXP_CONTAINS,
        value: AssertionCondition.RegexpContains
    },
    {
        id: AssertionCondition.NotRegexpContains,
        text: ASSERTION_CONDITION_REGEXP_NOT_CONTAINS,
        value: AssertionCondition.NotRegexpContains
    }
];
const getAssertionResponseConditionRegExpMatchesItems = () => assertionResponseConditionRegExpMatchesItems;
const getAssertionResponseConditionRegExpItems = () => [
    ...assertionResponseConditionRegExpItems,
    ...getAssertionResponseConditionRegExpMatchesItems()
];
const getAssertionResponseConditionCheckEqualsItems = () => assertionResponseConditionCheckEqualsItems;
export const getAssertionResponseConditionCheckItems = () => [
    ...assertionResponseConditionCheckItems,
    ...getAssertionResponseConditionCheckEqualsItems()
];
export const getAssertionConditionComparisonItems = () => assertionConditionComparisonItems;
const getAssertionResponseConditionExistsItems = () => assertionResponseConditionExists;
const getAssertionResponseConditionNullItems = () => assertionResponseConditionNull;
const getAssertionConditionValuesFromList = (list) => list.map(({ value }) => value);
export const getAssertionResponseConditionExistsValues = () => getAssertionConditionValuesFromList(getAssertionResponseConditionExistsItems());
export const getAssertionResponseConditionNullValues = () => getAssertionConditionValuesFromList(getAssertionResponseConditionNullItems());
export const getAssertionResponseBodyJsonPathConditionItems = () => [
    ...getAssertionResponseConditionCheckEqualsItems(),
    ...getAssertionResponseConditionExistsItems(),
    ...getAssertionResponseConditionNullItems(),
    ...getAssertionResponseConditionRegExpMatchesItems()
];
export const getAssertionResponseBodyXPath2ConditionItems = () => assertionResponseBodyXPath2ConditionItems;
export const getAssertionResponseConditionItems = () => [
    ...getAssertionResponseConditionRegExpItems(),
    ...getAssertionResponseConditionCheckItems()
];
export const getAssertionFilterItems = () => assertionFilterItems;
const getAssertionTypeValues = (enumObj) => Object.values(enumObj);
const isAssertionTypeDuration = (typeValue) => typeValue === AssertionDurationType.Duration;
const isAssertionTypeSize = (typeValue) => getAssertionTypeValues(AssertionSizeType).includes(typeValue);
export const isAssertionTypeResponse = (typeValue) => getAssertionTypeValues(AssertionResponseType).includes(typeValue);
export const getGrpcAssertionTypeItems = () => [
    ...assertionTypeResponseCommonItems,
    ...assertionTypeGrpcResponseItems,
    ...assertionTypeDurationItems,
    ...assertionTypeSizeCommonItems,
    ...assertionTypeGrpcSizeItems
];
export const getHttpAssertionTypeItems = () => [
    ...assertionTypeResponseCommonItems,
    ...assertionTypeHttpResponseItems,
    ...assertionTypeDurationItems,
    ...assertionTypeSizeCommonItems,
    ...assertionTypeHttpSizeItems
];
const getAssertionResponseBodyTemplateTypeByFilter = (filter) => {
    const templateType = filter === AssertionFilter.JsonPath
        ? AssertionTemplateType.ResponseBodyJson
        : ASSERTION_RESPONSE_BODY_DEFAULT_TEMPLATE_TYPE;
    return filter === AssertionFilter.XPath2 ? AssertionTemplateType.ResponseBodyXPath2 : templateType;
};
const getAssertionResponseTemplateTypeByParams = (typeValue, filter) => typeValue === AssertionResponseType.ResponseBody
    ? getAssertionResponseBodyTemplateTypeByFilter(filter)
    : ASSERTION_RESPONSE_DEFAULT_TEMPLATE_TYPE;
export const getAssertionTemplateTypeByParams = (assertion) => {
    const filter = getAssertionFilter(assertion);
    const type = getAssertionType(assertion);
    const templateType = isAssertionTypeDuration(type)
        ? AssertionTemplateType.Duration
        : getAssertionResponseTemplateTypeByParams(type, filter);
    return isAssertionTypeSize(type) ? AssertionTemplateType.Size : templateType;
};
export const getAssertionTemplateByParams = (assertion) => assertionTemplatesByType[getAssertionTemplateTypeByParams(assertion)];
