export const SETTINGS_SET_ACTIONS_SAVE = {
    defaultMessage: "Save",
    id: "account.test.project.settings.set.actions.save"
};
export const SETTINGS_SET_PAGE_TITLE = {
    defaultMessage: "Settings set",
    id: "account.test.project.settings.set.page.title"
};
export const SETTINGS_SET_INCORRECT_SECTION = {
    defaultMessage: "section",
    id: "account.test.project.settings.set.incorrect.settings.section"
};
export const SETTINGS_SET_INCORRECT_SECTIONS = {
    defaultMessage: "sections",
    id: "account.test.project.settings.set.incorrect.settings.sections"
};
