import styled from "styled-components";
import { media } from "../../../styled/response";
export const ActionAddStep = styled.div `
    cursor: pointer;
    > svg {
        height: 10px;
        width: 10px;
    }
    > svg path {
        fill: #677897;
    }
`;
export const AddStepSection = styled.div `
    color: #677897;
    display: flex;
    justify-content: flex-end;
    padding: 10px;
`;
export const ChartBlock = styled.div `
    flex: 1;
    position: relative;
`;
export const LoadProfileSettingsSection = styled.div `
    flex: 1;
`;
export const ScrollingChart = styled.div `
    position: sticky;
    top: 0;
`;
export const SwitchChartSection = styled.div `
    align-items: center;
    color: #396496;
    display: flex;
    font-size: 14px;
    font-weight: 600;
    left: 15%;
    line-height: normal;
    position: absolute;
    top: 10px;
    z-index: 99;
    > label {
        margin-right: 10px;
    }
    ${media.tablet} {
        left: 20%;
    }
    ${media.phone} {
        left: 25%;
    }
`;
export const TimeLineSection = styled.div `
    padding: 30px 0 30px 60px;
`;
