import { takeEvery } from "redux-saga/effects";
import {
    editRequestGrpcElement,
    editRequestHttpElement,
    editRequestGrpcElementCommonFlow,
    editRequestHttpElementCommonFlow
} from "@boomq/common";
import store from "../index";

function* editRequestGrpcElementFlow(action) {
    try {
        yield* editRequestGrpcElementCommonFlow(store.dispatch, action);
    } catch (e) {
        console.error(e);
    }
}

function* editRequestHttpElementFlow(action) {
    try {
        yield* editRequestHttpElementCommonFlow(store.dispatch, action);
    } catch (e) {
        console.error(e);
    }
}

export function* threadGroupFlow() {
    yield takeEvery(editRequestGrpcElement, editRequestGrpcElementFlow);
    yield takeEvery(editRequestHttpElement, editRequestHttpElementFlow);
}
