import React from "react";
import { FormattedMessage } from "react-intl";
import { BLOCK_TITLE_WARN_TEXT } from "./intl";
import { BlockJmxWarn, BlockTitleText, WarnText } from "./styled";
import { BlockTitle } from "../../../styled/Blocks";
import { IconWarning } from "../../../styled/Icons";
export const BlockTitleWithWarning = (props) => (React.createElement(BlockTitle, null,
    React.createElement(BlockTitleText, null,
        React.createElement(FormattedMessage, Object.assign({}, props.title))),
    React.createElement(BlockJmxWarn, null,
        React.createElement(IconWarning, null),
        React.createElement(WarnText, null,
            React.createElement(FormattedMessage, Object.assign({}, BLOCK_TITLE_WARN_TEXT))))));
