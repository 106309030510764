import styled, { css } from "styled-components";
import { Color } from "../styled/colorPallete";
import { media } from "../styled/response";
import { FontBodyMediumBold } from "../styled/typography";
export const RadioInput = styled.input `
    display: none;
`;
export const TabLabel = styled.label `
    display: flex;
    flex: 1;
    &:first-child {
        ${media.tablet} {
            margin-top: 0;
        }
    }
    ${media.tablet} {
        margin-top: 20px;
        width: 100%;
    }
    ${(props) => css(props.theme)}
`;
export const TabTitle = styled.div `
    ${FontBodyMediumBold}
    align-items: center;
    box-sizing: border-box;
    color: ${Color.grey50};
    cursor: pointer;
    display: flex;
    justify-content: center;
    line-height: normal;
    padding-bottom: 6px;
    padding-top: 6px;
    transition: all 0.3s ease-in-out;
    width: 100%;
`;
