import React, { FC } from "react";
import { connect } from "react-redux";
import { useIntl } from "@boomq/utils";
import {
    getTeamMemberPermissionMessageByValue,
    getTeamMemberPermissionMessageByModeAndValue,
    getClientRole
} from "@boomq/common";
import {
    TeamMembersItemTemplateAdd
    // TeamMembersItemTemplateAddHandlers,
    // TeamMembersItemTemplateAddProps
} from "../../../../components/account/Team/TeamMembersItemTemplateAdd";
import {
    getTeamMemberPermissionItemsByAdminAccess,
    getTeamMemberManageUsersPermissionItemsByRoles
} from "../../../../helpers/teams";
import { Mode } from "../../../../helpers/types";

const connector = connect((state) => ({
    roles: getClientRole(state)
}));

const WrappedTeamMembersItemTemplateAdd: FC<
any
// TeamMembersItemTemplateAddProps & TeamMembersItemTemplateAddHandlers
> = ({ isExistAdminAccess, roles, ...restProps }: any): JSX.Element => {
    // }: TeamMembersItemTemplateAddProps & TeamMembersItemTemplateAddHandlers): JSX.Element => {
    const { formatMessage } = useIntl();

    const permissionItems = getTeamMemberPermissionItemsByAdminAccess(isExistAdminAccess).map((permissionItem) => ({
        ...permissionItem,
        text: formatMessage(getTeamMemberPermissionMessageByValue(permissionItem.value))
    }));

    const manageUsersItems = getTeamMemberManageUsersPermissionItemsByRoles(roles).map((permissionItem) => ({
        ...permissionItem,
        text: formatMessage(getTeamMemberPermissionMessageByModeAndValue(Mode.Edit, permissionItem.value))
    }));

    return (
        <TeamMembersItemTemplateAdd
            {...restProps}
            manageUsersItems={manageUsersItems}
            permissionItems={permissionItems}
        />
    );
};

export default connector(WrappedTeamMembersItemTemplateAdd);
