import styled from "styled-components";
import { Color } from "@boomq/uikit";
export const Plate = styled.div `
    background: #eb0000;
    color: ${Color.white};
    font-size: 12px;
    font-weight: 600;
    left: -20px;
    position: fixed;
    text-align: center;
    top: 19px;
    transform: rotate(-30deg);
    width: 130px;
    z-index: 999;
    @media print {
        display: none;
    }
`;
