import styled from "styled-components";
import { Block, Color, FontBodyBigMedium, InputNumber } from "@boomq/uikit";

export const percentInputTheme = {
    minWidth: "100px",
    maxWidth: "100px",
    marginRight: "16px"
};

export const Actions = styled.ul`
    display: flex;
`;

export const Action = styled.li`
    margin-left: 16px;
`;

export const LoadGenerators = styled.div`
    align-items: center;
    display: flex;
    width: fit-content;
`;

export const LoadGeneratorsLabel = styled.div`
    ${FontBodyBigMedium}
    color: ${Color.grey50};
    margin-right: 8px;
`;

export const PercentInput = styled(InputNumber)`
    align-items: center;
    color: ${Color.grey75};
    border: 1px solid ${Color.grey75};
    border-radius: 12px;
    display: flex;
`;

export const Separator = styled.i`
    display: flex;
    width: 100%;
    height: 1px;
    background: ${Color.lightGrey85};
    margin-top: 24px;
`;

export const ThreadGroupBody = styled.li`
    ${Block}
    color: ${Color.darkGrey5};
    display: flex;
    flex-direction: column;
    list-style: none;
    margin-top: 16px;
    padding: 16px;
`;

export const ThreadGroupInfo = styled.div`
    display: flex;
    flex-direction: column;
`;
