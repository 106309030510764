import styled, { css, keyframes } from "styled-components";
import { getZIndex } from "../../styled/global";
export const rotate = keyframes `
    100% {
        transform: rotate(360deg);
    }
`;
export const StyledLoader = styled.div `
    align-items: center;
    backdrop-filter: blur(4px);
    background: rgba(255, 255, 255, 0.6);
    border-radius: inherit;
    bottom: 0;
    display: ${({ isShow }) => (isShow ? "flex" : "none")};
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: ${getZIndex("Loader")};
`;
export const LoaderImg = styled.img `
    ${(props) => props.size === "md"
    ? css `
                  width: 60px;
              `
    : css ``};
    ${(props) => props.size === "sm"
    ? css `
                  width: 30px;
              `
    : css ``};
    height: auto;
    animation: ${rotate} 1s infinite linear;
`;
