import { Block, Color } from "@boomq/uikit";
import styled from "styled-components";
import { BlockContent } from "../Blocks";
import { ActionsPanel } from "../Common";
import { withDisable } from "../global";
import { media } from "../response";
export const LoadTypeChart = styled.div `
    flex: 1;
`;
export const LoadTypeErrorMessage = styled.div `
    color: ${Color.red};
    display: flex;
    font-size: 14px;
    line-height: 24px;
    text-align: justify;
`;
export const LoadTypeSliders = styled.div `
    ${withDisable};
    flex: 1.5;
    ${media.giant} {
        margin-top: 20px;
    }
    ${media.tablet} {
        margin-top: 10px;
    }
`;
export const LoadTypeSlidersWrapper = styled(BlockContent) `
    ${withDisable};
    display: flex;
    ${media.giant} {
        flex-direction: column-reverse;
    }
    ${media.tablet} {
        margin-top: 30px;
    }
`;
export const TestSettingsActionsPanel = styled(ActionsPanel) `
    padding-left: 24px;
    padding-right: 32px;
`;
export const TestSettingsBlock = styled.div `
    ${Block}
    margin: 24px 0px;
    position: relative;
`;
