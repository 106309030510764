import styled from "styled-components";
export const Background = styled.div `
    align-items: center;
    background: rgba(9, 50, 100, 0.2);
    backdrop-filter: blur(4px);
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 9999;
`;
