export const loadTypesMessages = [
    {
        defaultChecked: true,
        description: {
            defaultMessage: "Long tests, 2 hours or more",
            id: "account.new.settings.type.stable.desc"
        },
        name: "STABLE",
        title: {
            defaultMessage: "Stable",
            id: "account.new.settings.type.stable"
        },
        tooltip: {
            defaultMessage: "Test with a fixed number of users and one period of stable load",
            id: "account.test.details.load.stable.tooltip"
        }
    },
    {
        defaultChecked: false,
        description: {
            defaultMessage: "Gradual increase in load",
            id: "account.new.settings.type.max.desc"
        },
        name: "SCALABILITY",
        title: { defaultMessage: "Maximum", id: "account.new.settings.type.max" },
        tooltip: {
            defaultMessage: "Test with multiple periods of stable load and a gradual increase in the number of users for each period",
            id: "account.test.details.load.max.tooltip"
        }
    }
];
export const getCurrentTestTypeText = (testType) => {
    switch (testType) {
        case "STABLE":
        case "SCALABILITY": {
            return getLoadTypeByTestType(testType);
        }
        case "OWN_JMX": {
            return {
                title: {
                    defaultMessage: "JMX",
                    id: "account.test.details.test.type.OWN_JMX"
                },
                tooltip: {
                    defaultMessage: "Test is based on JMX import",
                    id: "account.test.details.test.type.OWN_JMX.tooltip"
                }
            };
        }
        default: {
            return {
                title: {
                    defaultMessage: " ",
                    id: "title.empty"
                },
                tooltip: {
                    defaultMessage: " ",
                    id: "title.empty"
                }
            };
        }
    }
};
export const getLoadTypeByTestType = (testType) => {
    const loadType = loadTypesMessages.find((loadTypeMessage) => loadTypeMessage.name === testType);
    return loadType ? loadType : loadTypesMessages[0];
};
