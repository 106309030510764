import { uploadFileLocally } from "@boomq/utils";
export const uploadByFile = (file) => {
    return new Promise((resolve, reject) => {
        uploadFileLocally(file, (url) => resolve({
            success: 1,
            file: {
                url
            }
        }), () => reject({
            success: 0,
            file: {
                url: ""
            }
        }));
    });
};
