import React, { FC, useEffect } from "react";
import { AllTeamsContainer, getSiteTitle, TeamsSubtitle } from "@boomq/common";
import { TeamsSectionBlock } from "./styled";

export interface TeamsCommonProps {
    pageSubtitle: string;
    pageTitle: string;
}

export interface TeamsHandlers {
    onSelectTeam?: (teamId: string) => void;
    setTitle: (title: string | JSX.Element) => void;
}

export const Teams: FC<TeamsCommonProps & TeamsHandlers> = (props: TeamsCommonProps & TeamsHandlers): JSX.Element => {
    useEffect(() => {
        props.setTitle("account.all.teams.page.title");
        document.title = getSiteTitle(props.pageTitle);
    }, []);

    return (
        <TeamsSectionBlock>
            {props.pageSubtitle && <TeamsSubtitle>{props.pageSubtitle}</TeamsSubtitle>}

            <AllTeamsContainer onSelectTeam={props.onSelectTeam} />
        </TeamsSectionBlock>
    );
};
