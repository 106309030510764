import React from "react";
import { CartesianGrid, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { getValueByPath, isEmpty, round, useResize } from "@boomq/utils";
import { Color } from "@boomq/uikit";
import { ResizeLineChart, legendWrapperStyle } from "./styled";
import { CustomizedPFLBChartTooltip } from "../CustomizedPFLBChartTooltip";
import { sizes } from "../../../styled/response";
import { chartTimelineTickFormatter, getTicks } from "../../../../helpers/chart";
export const PFLBChartPreview = ({ yAxes, data, displayPropsKey = "params", height, isShowLegend, legendPayload, min, max, minHeight = 250, pointsDataKey = "coords", tooltipMetricsLabels, xAxisDataKey = "", yAxisDataKey }) => {
    const { width } = useResize();
    const getMinDomain = (dataMin) => (isEmpty(min) ? dataMin : min);
    const getMaxDomain = (dataMax) => (isEmpty(max) ? dataMax : max);
    const getYAxisIdByDataKey = (lineData) => {
        const yAxisData = yAxes.find((axisData) => axisData.dataKey === lineData.dataKey);
        return yAxisData && yAxisData.yAxisId ? yAxisData.yAxisId : yAxes[0].yAxisId;
    };
    const xAxisTicks = getTicks({ data, axisDataKey: xAxisDataKey, min, max, pointsDataKey });
    return (React.createElement(React.Fragment, null,
        React.createElement(ResponsiveContainer, { width: "100%", height: height || minHeight, minHeight: width <= sizes.tablet ? 120 : minHeight },
            React.createElement(ResizeLineChart, { margin: {
                    bottom: 0,
                    right: width <= sizes.giant ? 30 : 10,
                    top: 0
                } },
                React.createElement(CartesianGrid, { strokeDasharray: "3 3" }),
                React.createElement(XAxis, { dataKey: xAxisDataKey, type: "number", domain: [(dataMin) => getMinDomain(dataMin), (dataMax) => getMaxDomain(dataMax)], tickCount: xAxisTicks.length, tickFormatter: chartTimelineTickFormatter, tickLine: false, tickMargin: 0.5, ticks: [...xAxisTicks] }),
                (yAxes || []).map((axis) => (React.createElement(YAxis, Object.assign({ key: axis.dataKey, allowDecimals: false, domain: [0, (dataMax) => round(1.1 * dataMax, 1) + 1], tickLine: true, type: "number" }, axis)))),
                React.createElement(Tooltip, { content: React.createElement(CustomizedPFLBChartTooltip, { tooltipMetricsLabels: tooltipMetricsLabels }) }),
                Array.isArray(data) &&
                    data.map((line, index) => line[pointsDataKey] && (React.createElement(Line, { key: index, connectNulls: true, data: line[pointsDataKey], dataKey: line.dataKey || yAxisDataKey, dot: false, name: line.metricType, stroke: getValueByPath(line, `${displayPropsKey}.color`, Color.blue), strokeWidth: 3, style: { stroke: getValueByPath(line, `${displayPropsKey}.color`, Color.blue) }, type: "linear", yAxisId: getYAxisIdByDataKey(line) }))))),
        isShowLegend && React.createElement(Legend, { payload: legendPayload, wrapperStyle: legendWrapperStyle })));
};
