import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Pagination } from "@boomq/uikit";
import { NewSlaReportProfile, PaginationWrapper, SlaReportProfileList, StyledPage } from "./styled";
import { AllSlaReportProfilesItem } from "../../../containers/account/AllSlaReportProfilesItem";
import { TableHeader } from "../../common/TableHeader";
import { AddingIcon } from "../../styled/Buttons";
import { sizes } from "../../styled/response";
import { isAllowedSlaReportProfileActionByPermissions } from "../../../helpers/slaReportProfile";
import { SlaReportProfileAction } from "../../../helpers/types";
import { tableHeaderColumns } from "../../../data/content/slaReportProfile";
const renderNewSlaReportProfile = (props) => {
    const clickHandler = () => typeof props[SlaReportProfileAction.Add] === "function" ? props[SlaReportProfileAction.Add]() : undefined;
    return isAllowedSlaReportProfileActionByPermissions(props.permissionList, SlaReportProfileAction.Add) ? (React.createElement(NewSlaReportProfile, { onClick: clickHandler },
        React.createElement(AddingIcon, null),
        React.createElement(FormattedMessage, { id: "account.sla.report.profiles.button.new.trend", defaultMessage: "Add new trend report" }))) : (React.createElement(React.Fragment, null));
};
export const AllSlaReportProfiles = (props) => {
    const checkAuthorization = () => props.isAuthorized && props.isSlaReportProfileEnabled && props.selectedTeamId ? props.onRefresh({}) : undefined;
    useEffect(() => {
        props.setTitle("account.header.title.sla.report.profiles");
        checkAuthorization();
    }, [props.isAuthorized, props.isSlaReportProfileEnabled, props.selectedTeamId]);
    const { currentPage, slaReportProfiles, slaReportProfilesPagesCount, windowSize: { width } } = props;
    const clickPageHandler = (num) => {
        props.onChangePage(Number(num));
        props.onRefresh({ page: num, size: 7 });
    };
    return (React.createElement(StyledPage, null,
        width > sizes.tablet && React.createElement(TableHeader, { columns: tableHeaderColumns, theme: {} }),
        props.isSlaReportProfileEnabled && (React.createElement(React.Fragment, null,
            renderNewSlaReportProfile(props),
            React.createElement(SlaReportProfileList, null, (slaReportProfiles || []).map((slaReportProfile) => (React.createElement(AllSlaReportProfilesItem, { width: width, key: slaReportProfile.id, slaReportProfile: slaReportProfile })))),
            Boolean(slaReportProfiles && slaReportProfiles.length && slaReportProfilesPagesCount > 1) && (React.createElement(PaginationWrapper, null,
                React.createElement(Pagination, { totalPages: slaReportProfilesPagesCount, currentPage: currentPage, clickHandler: clickPageHandler })))))));
};
