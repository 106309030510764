var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { isEmpty, useIntl } from "@boomq/utils";
import { Checkbox, Labels } from "@boomq/uikit";
import { ADD_LABEL_MESSAGE, LABELS_CHECKBOX_LABEL_MESSAGE, LABELS_TITLE_MESSAGE } from "./intl";
import { AddLabelLink, StyledH3, StyledIconPlus, TestLabels, TestLabelsTitle } from "./styled";
import newTestActions from "../../../../redux/actions/newTest";
import { getAllLabels, getIsLabelsFromProjectCopying } from "../../../../redux/reducers/newTest";
const { addTestLabel, deleteTestLabel, toggleLabelsFromProjectCopying } = newTestActions;
const connector = connect((state) => ({
    isLabelsFromProjectCopying: getIsLabelsFromProjectCopying(state),
    labels: getAllLabels(state)
}), { onAddLabel: addTestLabel, onDeleteLabel: deleteTestLabel, onToggleCheckbox: toggleLabelsFromProjectCopying });
const AddLabelTemplate = (_a) => {
    var { addLabelText } = _a, restProps = __rest(_a, ["addLabelText"]);
    return (React.createElement(AddLabelLink, Object.assign({}, restProps),
        React.createElement(StyledIconPlus, null),
        addLabelText));
};
const TestLabelsSelection = (props) => {
    const { formatMessage } = useIntl();
    const addLabelHandler = (name) => props.onAddLabel(name);
    const deleteLabelHandler = (name) => props.onDeleteLabel(name);
    const toggleHandler = () => props.onToggleCheckbox(!props.isLabelsFromProjectCopying);
    return (React.createElement(React.Fragment, null,
        React.createElement(TestLabelsTitle, null,
            React.createElement(StyledH3, null, formatMessage(LABELS_TITLE_MESSAGE)),
            React.createElement(Checkbox, { checked: props.isLabelsFromProjectCopying, disabled: props.disabled, label: formatMessage(LABELS_CHECKBOX_LABEL_MESSAGE), name: "copyLabelsFromProject", onToggle: toggleHandler })),
        React.createElement(TestLabels, { disabled: props.disabled, isExistLabels: !isEmpty(props.labels) },
            React.createElement(Labels, { addLabelWithCreatingMethod: addLabelHandler, emptyLabelsText: "", labelAddingButtonTemplate: (props) => (React.createElement(AddLabelTemplate, Object.assign({ addLabelText: formatMessage(ADD_LABEL_MESSAGE) }, props))), labels: props.labels, minLength: 99999, onDelete: deleteLabelHandler }))));
};
export default withRouter(connector(TestLabelsSelection));
