import React from "react";
import { connect } from "react-redux";
import { useIntl } from "@boomq/utils";
import { Tooltip } from "@boomq/uikit";
import { TIMER_LABEL, TIMER_LABEL_DEFAULT, TIMER_LABEL_OFF } from "./intl";
import { StyledIconSpeedometer, TimersCount, TimersIcon, TimersIconWrapper, tooltipTheme } from "./styled";
import { getEnabledTimerList, getTimerFromListByType, getTimerListWithDefaultTimer } from "../../../../helpers/requestTimers";
import { RequestTimerParams, RequestTimerType } from "../../../../helpers/types";
import { getTimerType } from "../../../../models/requestTimer";
import { getDefaultTimerSettings } from "../../../../redux/reducers/newTest";
const getTimerText = (constantTimer, randomTimer, multiplicationFactor) => constantTimer
    ? `${multiplicationFactor * constantTimer.duration} sec`
    : `${multiplicationFactor * randomTimer.lowerLimit}...${multiplicationFactor * randomTimer.upperLimit} sec`;
const renderTimerText = (timerList, multiplicationFactor) => {
    const enabledTimerList = getEnabledTimerList(timerList);
    const constantTimer = getTimerFromListByType(enabledTimerList, RequestTimerType.Constant);
    const randomTimer = getTimerFromListByType(enabledTimerList, RequestTimerType.Random);
    return (constantTimer || randomTimer) && isNaN(multiplicationFactor) && Number(multiplicationFactor) > 0
        ? getTimerText(constantTimer, randomTimer, multiplicationFactor)
        : "";
};
const connector = connect((state) => ({
    defaultTimer: getDefaultTimerSettings(state)
}));
const getTimerValueLabel = (timer, value) => (timer.enabled && !isNaN(value) ? value : 0);
const renderTimerCounter = (timersCount) => timersCount > 1 ? React.createElement(TimersCount, null, timersCount) : React.createElement(React.Fragment, null);
const RequestTimerIcon = ({ defaultTimer, multiplicationFactor, timerList }) => {
    const { formatMessage } = useIntl();
    const timerListWithDefaultTimer = getTimerListWithDefaultTimer(timerList, defaultTimer) || [];
    const timersCount = timerListWithDefaultTimer.length;
    const getIsTimerOnLabel = (timer) => (timer.enabled ? "" : formatMessage(TIMER_LABEL_OFF));
    const getTimerLabel = (timer, index) => timer.isDefaultTimer ? formatMessage(TIMER_LABEL_DEFAULT) : `${formatMessage(TIMER_LABEL)} ${index}`;
    const getTimerTooltipText = (index, timer) => {
        switch (getTimerType(timer)) {
            case RequestTimerType.Constant: {
                return {
                    defaultMessage: "{label}: Constant timer, {duration} sec {isTimerOn}",
                    id: `account.new.test.project.group.requests.timer.${RequestTimerType.Constant}`,
                    values: {
                        duration: getTimerValueLabel(timer, timer.duration),
                        label: getTimerLabel(timer, index),
                        isTimerOn: getIsTimerOnLabel(timer)
                    }
                };
            }
            case RequestTimerType.Random: {
                return {
                    defaultMessage: "{label}: Random timer, {value} sec {isTimerOn}",
                    id: `account.new.test.project.group.requests.timer.${RequestTimerType.Random}`,
                    values: {
                        [RequestTimerParams.Type]: timer.randomTimerType,
                        isTimerOn: getIsTimerOnLabel(timer),
                        label: getTimerLabel(timer, index),
                        value: `${getTimerValueLabel(timer, timer.lowerLimit)}...${getTimerValueLabel(timer, timer.upperLimit)}`
                    }
                };
            }
            case RequestTimerType.Other: {
                return {
                    defaultMessage: "{label}: {type} {isTimerOn}",
                    id: `account.new.test.project.group.requests.timer.${timer.otherTimerType}`,
                    values: {
                        type: timer.otherTimerType,
                        isTimerOn: getIsTimerOnLabel(timer),
                        label: getTimerLabel(timer, index)
                    }
                };
            }
            default: {
                return {
                    defaultMessage: "{label}: none {isTimerOn}",
                    id: "account.new.test.project.group.requests.timer.none",
                    values: {
                        isTimerOn: getIsTimerOnLabel(timer),
                        label: getTimerLabel(timer, index)
                    }
                };
            }
        }
    };
    const getTimersTooltipText = (timerList) => timerList.map((timer, index) => (Object.assign({ key: index }, getTimerTooltipText(index, timer))));
    return timersCount === 0 ? (React.createElement(React.Fragment, null)) : (React.createElement(TimersIconWrapper, null,
        React.createElement(Tooltip, { title: getTimersTooltipText(timerListWithDefaultTimer), theme: tooltipTheme },
            React.createElement(TimersIcon, null,
                React.createElement(StyledIconSpeedometer, null))),
        renderTimerCounter(timersCount),
        renderTimerText(timerList, multiplicationFactor)));
};
export default connector(RequestTimerIcon);
