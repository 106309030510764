import styled from "styled-components";
import { Color, IconDelete, IconEdit } from "@boomq/uikit";
import { TableRow } from "../../styled/Table";
export const FlexTableRowColumn = styled(TableRow) `
    cursor: pointer;
    display: flex;
    flex-direction: column;
    padding: 16px;
    width: 100%;
`;
export const StyledIconDelete = styled(IconDelete) `
    fill: ${Color.blue};
`;
export const StyledIconEdit = styled(IconEdit) `
    fill: ${Color.blue};
`;
