import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ProfileSettingsButtons } from "../../../../components/account/TestProject/ProfileSettingsButtons";
import { getActionButtonsByTestType } from "../../../../helpers/testProject";
import newTestActions from "../../../../redux/actions/newTest";
import { getSiteModal } from "../../../../redux/actions/siteModal";
import { showTestProjectSettingsSetSidebar } from "../../../../redux/actions/testProjectsSettingsSetSidebar";
import { getClientPermissionList } from "../../../../redux/reducers/authorizePlate";
import { getAutoPercentages } from "../../../../redux/reducers/newTest";
const { changeAutoPercentages, deleteGroups, toggleActiveGroups, toggleGroups } = newTestActions;
const connector = connect((state, ownProps) => ({
    actionButtons: getActionButtonsByTestType(ownProps),
    autoPercentages: getAutoPercentages(state),
    permissionList: getClientPermissionList(state)
}), {
    changeAutoPercentages,
    deleteGroups,
    getSiteModal,
    showTestProjectSettingsSetSidebar,
    toggleGroups,
    toggleActiveGroups
});
export default withRouter(connector(ProfileSettingsButtons));
