import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { ACCESS_DENIED_TITLE_MESSAGE } from "./intl";
import { EmptyContent } from "../../account/EmptyContent";
import { getTeamsRoute } from "../../../helpers/routes";
export const AccessDenied = () => (React.createElement(EmptyContent, { messages: {
        title: Object.assign(Object.assign({}, ACCESS_DENIED_TITLE_MESSAGE), { values: {
                selectTeamLink: (React.createElement(Link, { to: getTeamsRoute() },
                    React.createElement(FormattedMessage, { defaultMessage: "choose a team", id: "account.access.denied.select.team.link" })))
            } })
    } }));
