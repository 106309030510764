import { generateTableCommonParams } from "./generateTableData";
import { calculateThreadGroupUsersCount } from "../../loadProfile";
import { ProfileDistribution } from "../../../models/testProject";
const generateTableCommonHeaderForPlannedTestLoadProfile = () => ["Group name"];
const generateTableHeaderByPercentDistribution = () => ["%"];
const generateTableRowsByPercentDistribution = (groups) => groups.map(({ name, perc }) => [name, perc]);
const generateTableDataByPercentDistribution = (groups) => (Object.assign(Object.assign({}, generateTableCommonParams()), { content: [
        [...generateTableCommonHeaderForPlannedTestLoadProfile(), ...generateTableHeaderByPercentDistribution()],
        ...generateTableRowsByPercentDistribution(groups)
    ] }));
const generateTableHeaderByVUserDistribution = () => ["VUsers"];
const generateTableRowsByVUserDistribution = (groups) => groups.map(({ name, loadProfileSettings }) => [name, calculateThreadGroupUsersCount(loadProfileSettings)]);
const generateTableDataByVUserDistribution = (groups) => (Object.assign(Object.assign({}, generateTableCommonParams()), { content: [
        [...generateTableCommonHeaderForPlannedTestLoadProfile(), ...generateTableHeaderByVUserDistribution()],
        ...generateTableRowsByVUserDistribution(groups)
    ] }));
const tableDataGeneratorByProfileDistribution = {
    [ProfileDistribution.Percent]: generateTableDataByPercentDistribution,
    [ProfileDistribution.Users]: generateTableDataByVUserDistribution
};
export const generateTableDataForPlannedTestLoadProfile = (testProjectData) => {
    const { groups, profileDistribution } = testProjectData || {};
    return Object.keys(tableDataGeneratorByProfileDistribution).includes(profileDistribution)
        ? tableDataGeneratorByProfileDistribution[profileDistribution](groups)
        : {};
};
