var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { stopEvent, useEventListener } from "@boomq/utils";
import { Popper } from "@boomq/uikit";
import { HeaderLanguage, IconArrow, IconFlag, IconWrapper, LanguageButton, LanguagePlateButton, PlateText, StyledLanguagePlate } from "./styled";
import flags from "../../../assets/images/icons/languages";
import { languages } from "../../../models/language";
import { setLanguage } from "../../../redux/actions/language";
import { getCurrentLanguage } from "../../../redux/reducers/languagePlate";
const connector = connect((state) => ({
    currentLanguage: getCurrentLanguage(state)
}), { onChangeLanguage: setLanguage });
const LanguagePlate = (_a) => {
    var { currentLanguage, onChangeLanguage, theme } = _a, restProps = __rest(_a, ["currentLanguage", "onChangeLanguage", "theme"]);
    const [isShowModal, setIsShowModal] = useState(false);
    const langRef = useRef();
    const langButtonsRef = useRef();
    const clickLanguageButtonHandler = (event) => {
        stopEvent(event);
        setIsShowModal(!isShowModal);
    };
    const outSideClickHandler = (e) => langRef.current &&
        langButtonsRef.current &&
        !langRef.current.contains(e.target) &&
        !langButtonsRef.current.contains(e.target)
        ? setIsShowModal(false)
        : undefined;
    const [addEventListener, removeEventListener] = useEventListener(document, "click", outSideClickHandler);
    useEffect(() => {
        addEventListener();
        return removeEventListener;
    }, []);
    const languageHandler = (event) => {
        const language = event.target.innerText.toLowerCase();
        stopEvent(event);
        setIsShowModal(false);
        return typeof onChangeLanguage === "function" ? onChangeLanguage(language) : undefined;
    };
    return (React.createElement(HeaderLanguage, Object.assign({ ref: langRef, theme: theme }, restProps),
        React.createElement(LanguageButton, { onClick: clickLanguageButtonHandler },
            React.createElement(IconFlag, { src: flags[currentLanguage] }),
            React.createElement(PlateText, null, currentLanguage),
            React.createElement(IconWrapper, null,
                React.createElement(IconArrow, null))),
        React.createElement(Popper, { anchorEl: langRef.current, open: isShowModal, placement: "bottom-start" },
            React.createElement(StyledLanguagePlate, { ref: langButtonsRef }, languages.map((language) => (React.createElement(LanguagePlateButton, { key: language, onClick: languageHandler }, language)))))));
};
export default connector(LanguagePlate);
