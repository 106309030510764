export const DEFAULT_MAX_LENGTH = 20;
export const DEFAULT_MIN_DIGITS = 1;
export const DEFAULT_MIN_LENGTH = 8;
export const DEFAULT_MIN_LOWERCASE = 1;
export const DEFAULT_MIN_SYMBOLS = 1;
export const DEFAULT_MIN_UPPERCASE = 1;
export const LOWERCASE = "abcdefghijklmnopqrstuvwxyz";
export const DIGIT = "0123456789";
export const SYMBOL = "!\"#$%&'()*+,-./:;<=>?@[\\]^_`{|}~ ";
export const UPPERCASE = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
