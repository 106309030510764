import React from "react";
import { FormattedMessage } from "react-intl";
import { Button, ButtonSize } from "@boomq/uikit";
import { BUTTON_LABEL } from "./intl";
import { ExceptionText } from "./styled";
export const ExceptionForm = ({ children, closeModalWindow, onClickCloseButton, texts }) => {
    const closeModalWindowHandler = () => {
        closeModalWindow === null || closeModalWindow === void 0 ? void 0 : closeModalWindow();
        return onClickCloseButton === null || onClickCloseButton === void 0 ? void 0 : onClickCloseButton();
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(ExceptionText, null, (texts || []).map((text, index) => (React.createElement("div", { key: typeof text === "object" && text.id ? text.id : index }, typeof text === "object" && text.id ? React.createElement(FormattedMessage, Object.assign({}, text)) : text)))),
        children,
        React.createElement(Button, { onClick: closeModalWindowHandler, size: ButtonSize.big },
            React.createElement(FormattedMessage, Object.assign({}, BUTTON_LABEL)))));
};
