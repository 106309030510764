import styled, { css } from "styled-components";
import { Color, FontBodyMediumMedium, InputText, StyledInputWrapper } from "@boomq/uikit";
const TitleStyles = `
    ${FontBodyMediumMedium}
    color: ${Color.darkGrey35};
    margin: 16px 0;
`;
export const EmailInput = styled(InputText) `
    max-width: 220px;
    ${({ error }) => error && error.id
    ? css `
                  border-color: ${Color.red};
                  &:focus {
                      border-color: ${Color.red};
                  }
                  & + label {
                      color: ${Color.red};
                  }
              `
    : css ``}
`;
export const NameInput = styled(InputText) `
    max-width: 220px;
`;
export const ParamsInputBlock = styled.div `
    border-bottom: 1px solid ${Color.lightGrey95};
    display: flex;
    justify-content: flex-start;
    padding-bottom: 16px;
    ${StyledInputWrapper} {
        margin-right: 16px;
        width: auto;
    }
`;
export const Permissions = styled.div `
    display: flex;
`;
export const PermissionsTitle = styled.div `
    ${TitleStyles}
`;
