import styled from "styled-components";
import { IconRemoveSection } from "../../KeyValue";
export const keyValueTheme = { marginTop: "20px" };
export const RequestHeader = styled.div `
    align-items: center;
    display: flex;
    margin-top: 20px;
    ${IconRemoveSection} {
        min-width: 0px;
    }
`;
