import { AxiosPromise } from "axios";
import { getAuthSrvUrl, instance } from "@boomq/common";
import { HashMap } from "../helpers/types";

type AuthParams = {
    password: string;
    time: string;
};

export type LoginParams = {
    login: string;
} & AuthParams;

export type RegistrationParams = {
    email: string;
} & AuthParams;

const getTimeHeader = (time: string): HashMap<HashMap<string>> => ({ headers: { "X-Client-Date": time } });

export const apiClientLogin = ({ login, password, time }: LoginParams): AxiosPromise => {
    const formData: FormData = new FormData();

    formData.append("username", login);
    formData.append("password", password);
    formData.append("submit", "Login");

    return instance.post(`${getAuthSrvUrl()}/login`, formData, getTimeHeader(time));
};
