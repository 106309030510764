import styled from "styled-components";
import { Block } from "@boomq/uikit";
export const customNewSLASelectTheme = { width: "100%" };
export const ChartLegendLineIconSection = styled.div `
    margin-right: 4px;
    min-width: 14px;
`;
export const DeleteButton = styled.div `
    color: #396496;
    cursor: pointer;
    font-size: 30px;
`;
export const DeleteChartButtonSection = styled.div `
    position: absolute;
    right: 10px;
    top: 0;
`;
export const DeleteSlaTypeButtonSection = styled.div `
    display: flex;
    flex: auto;
    justify-content: flex-end;
    margin: 0 5px;
`;
export const EmbedCode = styled.div `
    height: 1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    top: -9999px;
    width: 1px;
    z-index: -1;
`;
export const NewSlaTypeSection = styled.div `
    align-items: center;
    display: flex;
    min-width: 25%;
`;
export const PreviewChartBlock = styled.div `
    ${Block}
    display: flex;
    flex-direction: column;
    margin: 11px 0;
    padding: 34px;
    position: relative;
`;
export const SlaReportChartBlock = styled.div `
    display: flex;
    flex-direction: column;
    width: 100%;
`;
export const SlaReportChartLegend = styled.div `
    align-items: center;
    display: flex;
    justify-content: space-evenly;
    margin-top: 10px;
    span {
        align-items: center;
        display: flex;
    }
`;
export const SlaReportChartSettings = styled.div `
    display: flex;
    flex-direction: column;
    max-height: 450px;
    min-width: 25%;
`;
export const SlaReportChartSettingsSlaTypes = styled.div `
    display: flex;
    flex-direction: column;
    overflow-y: auto;
`;
export const SlaReportItemEmbedCodeSection = styled.div `
    display: flex;
    justify-content: flex-end;
`;
export const SlaReportItemPreviewSection = styled.div `
    display: flex;
`;
export const SlaReportLineLabel = styled.div `
    align-items: center;
    display: flex;
`;
export const SlaTypeItem = styled.div `
    align-items: center;
    color: #396496;
    display: flex;
    margin-top: 10px;
`;
export const SlaTypeListItem = styled.div `
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;
