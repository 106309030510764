var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { forwardRef } from "react";
import { generateMailToLink } from "@boomq/utils";
const MailToLink = (_a, _ref) => {
    var { bcc, body, cc, children, email, subject, title } = _a, restProps = __rest(_a, ["bcc", "body", "cc", "children", "email", "subject", "title"]);
    return (React.createElement("a", Object.assign({ href: generateMailToLink({ bcc, body, cc, email, subject }), ref: _ref, title: title }, restProps), children));
};
export default forwardRef(MailToLink);
