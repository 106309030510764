import styled from "styled-components";
import { Button, Color, FontBodyBigMedium, IconExternalLink, Select, StyledInputWrapper } from "@boomq/uikit";
export const keyValueTheme = { marginTop: "20px" };
export const ButtonSection = styled.div `
    display: flex;
    justify-content: center;
`;
export const RequestUrl = styled.div `
    align-items: center;
    display: flex;
    ${StyledInputWrapper} {
        margin-left: 12px;
    }
`;
export const RequestUrlTitle = styled.div `
    ${FontBodyBigMedium}
    color: ${Color.darkGrey5};
`;
export const HeadersTitle = styled(RequestUrlTitle) `
    margin-top: 16px;
`;
export const StyledButton = styled(Button) `
    margin-top: 16px;
    position: relative;
`;
export const StyledIconExternalLink = styled(IconExternalLink) `
    cursor: pointer;
    fill: ${Color.blue};
    margin-left: 16px;
`;
export const StyledSelect = styled(Select) `
    max-width: 240px;
    min-width: 240px;
`;
export const WebhookSettings = styled.div `
    display: flex;
    flex: auto;
    flex-direction: column;
`;
