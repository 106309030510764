import { JMETER_UNSUPPORTED_PROPERTIES_EXCEPTION_MESSAGE } from "./constants";
import { JMETER_UNSUPPORTED_PROPERTIES_TITLE } from "./intl";
import { showError } from "./showError";
import { UnsupportedJMeterPropertiesException } from "../../../components/account/TestProjectSettingsSet/UnsupportedJMeterPropertiesException";
import { ExceptionText } from "../../../components/common/ExceptionForm";
export function* testProjectSettingsSetSavingFailureFlow(action) {
    const { data, exceptionMessage } = (action === null || action === void 0 ? void 0 : action.payload) || {};
    const params = exceptionMessage === JMETER_UNSUPPORTED_PROPERTIES_EXCEPTION_MESSAGE
        ? {
            element: UnsupportedJMeterPropertiesException,
            props: {
                invalidProperties: (data === null || data === void 0 ? void 0 : data.invalidProperties) || []
            },
            theme: {
                alignItems: "center",
                [`${ExceptionText}`]: {
                    marginBottom: 0,
                    textAlign: "left"
                }
            },
            title: JMETER_UNSUPPORTED_PROPERTIES_TITLE,
            width: "630px"
        }
        : {};
    return yield* showError(params);
}
