var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { convertParamsNameByComplianceMap, getUniqueId } from "@boomq/utils";
import { getExtractorMatchByMatchType } from "../extractResponse";
import { ExtractorType } from "../types";
const parseExtractorBoundary = (_a) => {
    var { fieldToCheck } = _a, restParams = __rest(_a, ["fieldToCheck"]);
    return (Object.assign({ fieldToCheck }, convertParamsNameByComplianceMap({
        leftBoundary: "leftBorder",
        rightBoundary: "rightBorder"
    }, restParams)));
};
const parseExtractorRegex = (_a) => {
    var { fieldToCheck, groupNumber } = _a, restParams = __rest(_a, ["fieldToCheck", "groupNumber"]);
    return (Object.assign({ groupNumber,
        fieldToCheck }, convertParamsNameByComplianceMap({
        regularExpression: "re"
    }, restParams)));
};
const parseExtractorJsonPath = (params) => convertParamsNameByComplianceMap({ jsonPathExpression: "jsonpath" }, params);
const parseExtractorXPath = (params) => convertParamsNameByComplianceMap({ expression: "xpath", namespacesAliases: "namespacesAliases" }, params);
const extractorParsersByType = {
    [ExtractorType.Boundary]: parseExtractorBoundary,
    [ExtractorType.RegEx]: parseExtractorRegex,
    [ExtractorType.JsonPath]: parseExtractorJsonPath,
    [ExtractorType.XPath]: parseExtractorXPath,
    [ExtractorType.XPath2]: parseExtractorXPath
};
const parseExtractorParamsByType = (type, params) => typeof extractorParsersByType[type] === "function" ? extractorParsersByType[type](params) : {};
const parseExtractor = (_a) => {
    var { defaultValue, id, label, matchNumber, type, typeDisplayName, variableName } = _a, restParams = __rest(_a, ["defaultValue", "id", "label", "matchNumber", "type", "typeDisplayName", "variableName"]);
    return ({
        defaultValue,
        id: id || getUniqueId(),
        label,
        match: getExtractorMatchByMatchType(matchNumber),
        params: parseExtractorParamsByType(type, restParams),
        type,
        typeDisplayName,
        variable: variableName
    });
};
export const parseExtractors = (extractors = []) => (extractors || []).reduce((res, extractor) => [...res, parseExtractor(extractor)], []);
