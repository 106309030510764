import { Color, FontBodyMediumBold, FontBodyMediumMedium } from "@boomq/uikit";
import styled from "styled-components";
export const Headers = styled.ul `
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    max-height: 200px;
    overflow-y: auto;
`;
export const Header = styled.li `
    display: flex;
    margin-top: 8px;
`;
export const HeaderName = styled.p `
    ${FontBodyMediumBold}
    color: ${Color.grey50};
    white-space: nowrap;
`;
export const HeaderValue = styled.p `
    ${FontBodyMediumMedium}
    color: ${Color.orange};
    margin-left: 8px;
    word-break: break-all;
`;
