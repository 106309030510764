const getMimeTypeFromString = (str = "") => {
    const matchRes = (str || "").match(/:(.*?);/);
    return Array.isArray(matchRes) && matchRes.length > 1 ? matchRes[1] : "";
};
const getBase64StringMimeTypeAndContent = (base64String) => {
    try {
        const arr = (base64String || "").split(",");
        const mimeType = getMimeTypeFromString(arr[0]);
        return Array.isArray(arr) && arr.length === 2 ? { mimeType, content: arr[1] } : { mimeType, content: arr[0] };
    }
    catch (e) {
        return { mimeType: "", content: "" };
    }
};
export const convertDataUrlToFile = (base64String, fileName) => {
    try {
        const { mimeType, content } = getBase64StringMimeTypeAndContent(base64String);
        const byteCharacters = atob(content);
        const byteCharactersCount = byteCharacters.length;
        const u8Array = new Uint8Array(byteCharactersCount);
        const byteArray = u8Array.map((item, index) => byteCharacters.charCodeAt(index));
        return new File([byteArray], String(fileName), { type: mimeType });
    }
    catch (e) {
        return new File([], String(fileName));
    }
};
