import React, { useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { useIntl } from "@boomq/utils";
import { InputNumber } from "@boomq/uikit";
import { StyledButton } from "../../../styled/Buttons";
import { Switcher } from "../../../common/Switcher";
import newTestActions from "../../../../redux/actions/newTest";
import { showTestProjectNotification, hideTestProjectNotification } from "../../../../redux/actions/testProjectNotification";
import { getTestProjectSettingsField } from "../../../../redux/reducers/newTest";
const { applyJmxTimersSettings } = newTestActions;
const connector = connect((state) => ({
    JMXTimersSettings: getTestProjectSettingsField(state, "JMXTimersSettings")
}), {
    applyJmxTimersSettings,
    showTestProjectNotification,
    hideTestProjectNotification
});
const RequestJMXTimersSettingsForm = (props) => {
    const { formatMessage } = useIntl();
    const { isTimersDisabled, multiplicationFactor } = props.JMXTimersSettings || {};
    const isTimersEnabled = !isTimersDisabled;
    const [multiplicationCoeff, setMultiplicationCoeff] = useState(multiplicationFactor);
    const [isTimersOn, setIsTimersOn] = useState(isTimersEnabled);
    const applyTimersSettings = () => props.applyJmxTimersSettings({
        isTimersDisabled: !isTimersOn,
        prevIsTimersDisabled: isTimersDisabled,
        multiplicationFactor: Number(multiplicationCoeff),
        prevMultiplicationFactor: Number(multiplicationFactor)
    });
    const clickApplyHandler = () => isTimersEnabled !== isTimersOn || multiplicationFactor !== multiplicationCoeff
        ? applyTimersSettings()
        : undefined;
    return (React.createElement(React.Fragment, null,
        React.createElement(Settings, null,
            React.createElement(Setting, null,
                React.createElement(Text, null, formatMessage({
                    defaultMessage: "On/off timers",
                    id: "account.modal.timers.settings.enabled.text"
                })),
                React.createElement(Switcher, { defaultChecked: isTimersOn, name: "isTimersOn", onClick: () => setIsTimersOn(!isTimersOn) })),
            React.createElement(Setting, null,
                React.createElement(Text, null, formatMessage({
                    defaultMessage: "Multiplication coefficient",
                    id: "account.modal.timers.settings.multiplication.coefficient.text"
                })),
                React.createElement(InputNumber, { decimalCount: 2, enabled: isTimersOn, isPositive: true, onChange: (value) => setMultiplicationCoeff(value), placeholder: formatMessage({
                        defaultMessage: "Multiplication coefficient",
                        id: "account.modal.timers.settings.multiplication.coefficient.placeholder"
                    }), value: multiplicationCoeff, theme: { maxWidth: "200px" } }))),
        React.createElement(SaveButtonSection, null,
            React.createElement(StyledButton, { className: "close_modal_window", onClick: clickApplyHandler }, formatMessage({
                defaultMessage: "Apply",
                id: "account.modal.timers.settings.button"
            })))));
};
const Setting = styled.div `
    align-items: center;
    display: flex;
    margin: 15px 0;
`;
const Settings = styled.div `
    display: flex;
    flex-direction: column;
    margin: 20px 0;
`;
const SaveButtonSection = styled.div `
    display: flex;
    justify-content: center;
    > button {
        width: auto !important;
    }
`;
const Text = styled.p `
    color: #396496;
    font-size: 18px;
    font-weight: 600;
    max-width: 260px;
    min-width: 260px;
    line-height: normal;
`;
export default connector(RequestJMXTimersSettingsForm);
