import styled from "styled-components";
import { Button } from "@boomq/uikit";

export const ButtonSection = styled.div`
    display: flex;
    justify-content: flex-end;
    position: relative;
    width: 100%;
`;

export const StyledButton = styled(Button)`
    position: relative;
`;

export const StyledButtonWithMargin = styled(Button)`
    margin-right: 16px;
`;

export const TooltipEl = styled.div`
    cursor: help;
    height: 100%;
    left: 0;
    pointer-events: auto;
    position: absolute;
    top: 0;
    width: 100%;
`;
