import styled from "styled-components";
import { Block, Color, FontBodyMediumMedium, IconCopy, IconDelete, InputNumber, StyledInputWrapper } from "@boomq/uikit";
import { TestType } from "../../../helpers/types";
import { SlaTargetType } from "../../../models/sla";
const selectWidthByType = {
    [SlaTargetType.Request]: "calc(20% - 25px)",
    [SlaTargetType.SystemMetricsHost]: "calc(33% - 25px)",
    [SlaTargetType.Test]: "40%",
    [SlaTargetType.ThreadGroup]: "calc(33% - 25px)",
    [SlaTargetType.Transaction]: "calc(25% - 25px)"
};
const selectWidthByTypeAndResult = {
    [SlaTargetType.Request]: "calc(18% - 30px)",
    [SlaTargetType.SystemMetricsHost]: "calc(30% - 25px)",
    [SlaTargetType.Test]: "40%",
    [SlaTargetType.ThreadGroup]: "calc(30% - 25px)",
    [SlaTargetType.Transaction]: "calc(22% - 30px)"
};
const getSelectWidthByType = (type, isShowSlaResult) => isShowSlaResult ? selectWidthByTypeAndResult[type] : selectWidthByType[type];
export const getSelectTheme = (type, isShowSlaResult) => ({
    minWidth: getSelectWidthByType(type, isShowSlaResult),
    width: getSelectWidthByType(type, isShowSlaResult)
});
export const slaOperationTheme = { minWidth: "80px", width: "80px" };
export const Actions = styled.ul `
    align-items: center;
    display: flex;
`;
export const ActionButton = styled.li `
    cursor: pointer;
    margin-left: 4px;
    padding: 0 4px;
`;
export const ErrorMessage = styled.p `
    bottom: 0px;
    color: #eb5757;
    font-size: 12px;
    left: 16px;
    position: absolute;
`;
export const SlaItemBlock = styled.div `
    ${Block}
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    min-width: 100%;
    width: 100%;
`;
const SlaItemParamsStyles = `
    display: flex;
    width: 100%;
`;
export const SlaItemParamsRow = styled.div `
    ${SlaItemParamsStyles}
    border-bottom: ${({ testType }) => (testType === TestType.JMX ? "0px" : "1px")} solid ${Color.lightGrey95};
    padding: 16px;
    position: relative;
    > div {
        margin-left: 16px;
        &:first-child {
            margin-left: 0px;
        }
    }
`;
export const SlaItemSection = styled.div `
    display: flex;
    margin-top: 16px;
    position: relative;
`;
export const SlaItemTimelineRow = styled.div `
    ${SlaItemParamsStyles}
    ${FontBodyMediumMedium}
    align-items: center;
    color: ${Color.darkGrey5};
    padding: 32px 16px;
    ${StyledInputWrapper} {
        flex: 1;
        margin-left: 16px;
    }
`;
export const StyledIconCopy = styled(IconCopy) `
    fill: ${Color.blue};
`;
export const StyledIconDelete = styled(IconDelete) `
    fill: ${Color.blue};
`;
export const StyledInputNumber = styled(InputNumber) `
    flex: auto;
`;
