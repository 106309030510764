import { put } from "redux-saga/effects";
import { API_ERROR_TITLE } from "./intl";
import { getModal } from "../../actions/modal";
import { getSiteModal } from "../../actions/siteModal";
import { apiErrors } from "../../../data/content/errorMessages";
function* checkElement(params) {
    let { element, props, theme, title, width } = params || {};
    return element
        ? yield put(getSiteModal({
            component: { element, props, theme, width },
            title
        }))
        : undefined;
}
export function* showError(params) {
    let { code, element } = params || {};
    const modalInfo = !element && !(params === null || params === void 0 ? void 0 : params.modalInfo)
        ? {
            description: apiErrors(code),
            title: API_ERROR_TITLE
        }
        : params === null || params === void 0 ? void 0 : params.modalInfo;
    yield* checkElement(params);
    return modalInfo ? yield put(getModal(modalInfo)) : undefined;
}
