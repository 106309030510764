import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { getUniqueId, useIntl } from "@boomq/utils";
import { JMETER_CONFIG_PROPERTIES_LINK_TEXT, JMETER_CONFIG_PROPERTIES_TEXT, JMETER_CONFIG_PROPERTIES_TITLE, PROPERTY_NAME_INPUT_LABEL, PROPERTY_NAME_INPUT_PLACEHOLDER, PROPERTY_VALUE_INPUT_LABEL, PROPERTY_VALUE_INPUT_PLACEHOLDER } from "./intl";
import { BlockJMeterPropertiesHelp, HelpLink, StyledIconHelp, SupportedJMeterModulesText } from "./styled";
import { JMeterConfigPropertyContainer } from "../JMeterConfigProperty";
import { BlockContent, BlockTitle } from "../../../../components/styled/Blocks";
import { BlockTitleText } from "../../../../components/account/TestProjectSettingsSet/BlockTitleWithWarning/styled";
import { StyledBlock } from "../../../../components/account/TestProjectSettingsSet/Wizard/styled";
import { supportedJMeterConfigPropertiesDocsLink } from "../../../../helpers/applicationParams";
import { formatIntlMessage } from "../../../../helpers/intl";
import { addJmeterConfigPropertyItem, changeJmeterConfigPropertyItem, deleteJmeterConfigPropertyItem } from "../../../../redux/actions/jmeterConfigProperties";
import { getJmeterConfigPropertyItems } from "../../../../redux/reducers/jmeterConfigProperties";
const generateJMeterConfigPropertiesNewItem = () => ({ id: getUniqueId(), key: "", value: "" });
const connector = connect((state) => ({
    propertyItems: getJmeterConfigPropertyItems(state)
}), {
    onAdd: addJmeterConfigPropertyItem,
    onChange: changeJmeterConfigPropertyItem,
    onDelete: deleteJmeterConfigPropertyItem
});
const JMeterConfigPropertiesContainer = ({ onAdd, onChange, onDelete, propertyItems }) => {
    const { formatMessage } = useIntl();
    const changePropertyHandler = (modifiedProperty) => (propertyItems || []).some(({ id }) => id === (modifiedProperty === null || modifiedProperty === void 0 ? void 0 : modifiedProperty.id))
        ? onChange(modifiedProperty)
        : onAdd(modifiedProperty);
    const propertyNameInputLabel = formatIntlMessage(PROPERTY_NAME_INPUT_LABEL, formatMessage);
    const propertyNameInputPlaceholder = formatIntlMessage(PROPERTY_NAME_INPUT_PLACEHOLDER, formatMessage);
    const propertyValueInputLabel = formatIntlMessage(PROPERTY_VALUE_INPUT_LABEL, formatMessage);
    const propertyValueInputPlaceholder = formatIntlMessage(PROPERTY_VALUE_INPUT_PLACEHOLDER, formatMessage);
    return (React.createElement(StyledBlock, null,
        React.createElement(BlockTitle, null,
            React.createElement(BlockTitleText, null,
                React.createElement(FormattedMessage, Object.assign({}, JMETER_CONFIG_PROPERTIES_TITLE))),
            React.createElement(BlockJMeterPropertiesHelp, null,
                React.createElement(StyledIconHelp, null),
                React.createElement(SupportedJMeterModulesText, null,
                    React.createElement(FormattedMessage, Object.assign({}, JMETER_CONFIG_PROPERTIES_TEXT, { values: {
                            link: (React.createElement(HelpLink, { href: supportedJMeterConfigPropertiesDocsLink, target: "_blank", rel: "noopener noreferrer" },
                                React.createElement(FormattedMessage, Object.assign({}, JMETER_CONFIG_PROPERTIES_LINK_TEXT))))
                        } }))))),
        React.createElement(BlockContent, null, [...propertyItems, generateJMeterConfigPropertiesNewItem()].map((propertyItem) => (React.createElement(JMeterConfigPropertyContainer, { key: propertyItem.id, data: propertyItem, onChange: changePropertyHandler, onDelete: onDelete, propertyNameInputLabel: propertyNameInputLabel, propertyNameInputPlaceholder: propertyNameInputPlaceholder, propertyValueInputLabel: propertyValueInputLabel, propertyValueInputPlaceholder: propertyValueInputPlaceholder }))))));
};
export default connector(JMeterConfigPropertiesContainer);
