var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { TeamInfoWrapper, TeamLink } from "./styled";
import { TeamInfoData } from "./TeamInfoData";
import { getIsAuthorize, getSelectedTeamData } from "../../../redux/reducers/authorizePlate";
const connector = connect((state) => ({
    isAuthorize: getIsAuthorize(state),
    teamInfo: getSelectedTeamData(state)
}), {});
const TeamInfo = (_a) => {
    var { isAuthorize, path, teamInfo } = _a, restProps = __rest(_a, ["isAuthorize", "path", "teamInfo"]);
    return isAuthorize ? (React.createElement(TeamLink, Object.assign({ to: path }, restProps),
        React.createElement(TeamInfoData, { teamInfo: teamInfo }))) : (React.createElement(TeamInfoWrapper, Object.assign({}, restProps),
        React.createElement(TeamInfoData, { teamInfo: teamInfo })));
};
export default withRouter(connector(TeamInfo));
