export const THREAD_GROUP_LIST_ITEM_ANY_THREAD_GROUP = {
    defaultMessage: "Any thread group",
    id: "account.sla.form.ANY_THREAD_GROUP"
};
export const THREAD_GROUP_PLACEHOLDER = {
    defaultMessage: "Thread group",
    id: "account.sla.form.thread.group.placeholder"
};
export const TRANSACTION_PLACEHOLDER = {
    defaultMessage: "Transaction",
    id: "account.sla.form.transaction.placeholder"
};
