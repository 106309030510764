import React, { FC } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { getValueByPath, useIntl } from "@boomq/utils";
import { NEW_ITEM_ID, setTitle } from "@boomq/common";
import { TEAMS_ACTIONS_CREATE, TEAMS_PAGE_SUBTITLE, TEAMS_PAGE_TITLE } from "./intl";
import { TeamsCommonProps } from "../../../../components/pages/account/Teams";
import { AdminTeams, AdminTeamsHandlers } from "../../../../components/pages/admin/Teams";

const connector = connect(null, {
    setTitle
});

const getTeamRouteById = (location, teamId): string => `${getValueByPath(location, "pathname")}/${teamId}`;

const getNewTeamRoute = (location): string => getTeamRouteById(location, NEW_ITEM_ID);

const getTeamRoute = (location, teamData): string => getTeamRouteById(location, getValueByPath(teamData, "id"));

const TeamsContainer: FC<TeamsCommonProps & AdminTeamsHandlers & RouteComponentProps> = ({
    history,
    location,
    ...restProps
}: TeamsCommonProps & AdminTeamsHandlers & RouteComponentProps): JSX.Element => {
    const { formatMessage } = useIntl();

    const pageTitle = formatMessage(TEAMS_PAGE_TITLE);
    const pageSubtitle = formatMessage(TEAMS_PAGE_SUBTITLE);
    const buttonCreateLabel = formatMessage(TEAMS_ACTIONS_CREATE);

    const redirectToRoute = (route): void =>
        history && typeof history.push === "function" ? history.push(route) : undefined;

    const clickHandler = (): void => redirectToRoute(getNewTeamRoute(location));

    const selectTeamHandler = (teamData): void => redirectToRoute(getTeamRoute(location, teamData));

    return (
        <AdminTeams
            {...restProps}
            buttonCreateLabel={buttonCreateLabel}
            onClickButtonCreate={clickHandler}
            onSelectTeam={selectTeamHandler}
            pageSubtitle={pageSubtitle}
            pageTitle={pageTitle}
        />
    );
};

export default connector(TeamsContainer);
