var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { call, put, select, takeEvery } from "redux-saga/effects";
import { getFromLocalStorage, getMaxInArray, getValueByPath, setToLocalStorage } from "@boomq/utils";
import { SettingsSetJMXLinkingAttention } from "../../containers/account/SettingsSetJMXLinkingAttention";
import { settingsSetLinkingJmxFormModal } from "../../data/content/modals";
import { getSiteModal } from "../actions/siteModal";
import { addSettingsSetInSidebar, changeSidebarFilterLabels, changeSidebarFilterName, extractSettingsFromTestProject, linkSettingsSetToNewTest, linkSettingsSetToTestProjectFailure, linkSettingsSetToTestProjectRequest, linkSettingsSetToTestProjectSuccess, linkSettingsSetToTestProjectInListFailure, linkSettingsSetToTestProjectInListRequest, linkSettingsSetToTestProjectInListSuccess, refreshTestProjectSettingsSetList, setSettingsSetList, setSettingsSetListCurrentPage, setSettingsSetListPageSize, settingsSetSidebarChangeParamsField, showSettingsSetSidebar, showTestProjectsSettingsSetSidebar, submitJmxLinkingAttention, unlinkSettingsSetForTestProjectFailure, unlinkSettingsSetForTestProjectRequest, unlinkSettingsSetForTestProjectSuccess, unlinkSettingsSetForTestProjectInListFailure, unlinkSettingsSetForTestProjectInListRequest, unlinkSettingsSetForTestProjectInListSuccess } from "../actions/testProjectsSettingsSetSidebar";
import { getClientInfo } from "../reducers/authorizePlate";
import { getConfigHttpRequestDefaults } from "../reducers/config";
import { getProjectId, getProjectName, getProjectVersion, getSettingsId, getTestProjectSettings, getTestType } from "../reducers/newTest";
import { getTestProjectModelSchema } from "../reducers/testProjectModelSchema";
import { getSettingsSetSidebarElementsCount, getSettingsSetSidebarFilterLabels, getSettingsSetSidebarFilterName, getSettingsSetSidebarItems, getSettingsSetSidebarPageSize, getSettingsSetSidebarTestProjectData, initialState as settingsSetSidebarInitialState } from "../reducers/testProjectsSettingsSetSidebar";
import { getTestProjectById } from "../reducers/testProjects";
import { saveTestProjectDetails } from "../sagas/testProjects";
import { getNewTestProjectSettingsSetData } from "../sagas/testProjectSettingsSet";
import { refreshTestProjectSettingsSetsLabels } from "../sagas/testProjectSettingsSets";
import { getResponseErrorData } from "../../helpers/errors";
import { generateSettingsSetDtoParamsData } from "../../helpers/generators";
import { generateExtractedSettingsSetName } from "../../helpers/testProjectSettingsSet";
import { TestType } from "../../helpers/types";
import { createTestProjectSettingsSet, extractSettingsFromProject, getTestProjectDetails, getTestProjectSettingsSet, getTestProjectSettingsSetList, linkTestProjectToSettingsSet, unlinkTestProjectToSettingsSet } from "../../services/projectSrv";
function* addSettingsSetInSidebarFlow(data) {
    const settingsSetSidebarItems = yield select(getSettingsSetSidebarItems);
    const elementsCount = yield select(getSettingsSetSidebarElementsCount);
    const totalElements = elementsCount + 1;
    const totalPages = Math.ceil(totalElements / settingsSetSidebarInitialState.size);
    yield put(setSettingsSetList({
        content: [Object.assign(Object.assign({}, data), { labelSet: [] }), ...settingsSetSidebarItems],
        totalElements,
        totalPages
    }));
}
function* extractSettingsFromTestProjectFlow() {
    const { projectId, projectName, projectVersionId } = yield select(getSettingsSetSidebarTestProjectData);
    yield put(settingsSetSidebarChangeParamsField({
        field: "isShowLoader",
        value: true
    }));
    const { data } = yield call(extractSettingsFromProject, {
        newSettingsName: generateExtractedSettingsSetName(projectName),
        projectId,
        versionId: projectVersionId
    });
    yield* addSettingsSetInSidebarFlow(data);
    yield* testProjectSettingsSetsLinkingFlow({ payload: data.id });
}
function* getSettingsSetListByPageAndSize(page, size) {
    const labels = yield select(getSettingsSetSidebarFilterLabels);
    const name = yield select(getSettingsSetSidebarFilterName);
    const pageSize = size > -1 ? size : yield select(getSettingsSetSidebarPageSize);
    const { data } = yield call(getTestProjectSettingsSetList, {
        label: labels.map((label) => label.name),
        name,
        page,
        size: pageSize
    });
    return data;
}
export function* refreshSettingsSetList(page = settingsSetSidebarInitialState.number) {
    try {
        const data = yield* getSettingsSetListByPageAndSize(page);
        yield put(setSettingsSetList(data));
    }
    catch (e) {
        yield put(setSettingsSetList({}));
    }
}
function* refreshSidebarSettingsSetList(currentPage, size) {
    const settingsSetList = yield select(getSettingsSetSidebarItems);
    const _a = yield* getSettingsSetListByPageAndSize(currentPage, size), { content } = _a, rest = __rest(_a, ["content"]);
    yield put(setSettingsSetListCurrentPage(currentPage));
    yield put(setSettingsSetList(Object.assign(Object.assign({}, rest), { content: [...settingsSetList, ...content] })));
}
function* refreshSettingsSetListByFilterFlow() {
    yield* refreshSettingsSetList();
}
function* refreshTestProjectSettingsSetListFlow(action) {
    try {
        const { currentPage, size } = action && action.payload ? action.payload : {};
        return currentPage === 0
            ? yield put(setSettingsSetListPageSize(size))
            : yield* refreshSidebarSettingsSetList(currentPage, size);
    }
    catch (e) {
        console.error(e);
    }
}
function* settingsSetInSidebarAddingFlow(action) {
    try {
        const name = action && action.payload ? action.payload : "";
        const configHttpRequestDefaults = yield select(getConfigHttpRequestDefaults);
        const modelSchema = yield select(getTestProjectModelSchema);
        const newTestProjectSettingsSetData = yield* getNewTestProjectSettingsSetData();
        const newSettingsSetData = generateSettingsSetDtoParamsData(Object.assign(Object.assign({}, newTestProjectSettingsSetData), { configHttpRequestDefaults,
            modelSchema,
            name }));
        const { data } = yield call(createTestProjectSettingsSet, newSettingsSetData);
        yield* addSettingsSetInSidebarFlow(data);
    }
    catch (e) {
        yield put(setSettingsSetList({}));
    }
}
function* refreshLinkedTestProjectSettingsSet() {
    const { settingsId } = yield select(getSettingsSetSidebarTestProjectData);
    const { data } = settingsId
        ? yield call(getTestProjectSettingsSet, settingsId)
        : { data: settingsSetSidebarInitialState.linkedSettingsSetData };
    yield put(settingsSetSidebarChangeParamsField({
        field: "linkedSettingsSetData",
        value: data
    }));
}
function* showSettingsSetSidebarFlow(testProjectData) {
    yield put(showSettingsSetSidebar(testProjectData));
    yield* refreshLinkedTestProjectSettingsSet();
    yield* refreshTestProjectSettingsSetsLabels();
    yield* refreshSettingsSetList();
}
function* showTestProjectsSettingsSetSidebarFlow(action) {
    try {
        const projectId = getValueByPath(action, "payload.id");
        const { projectName, settingsId, testType, version } = yield select(getTestProjectById, projectId);
        const lastVersionId = getMaxInArray(version, "id");
        return yield* showSettingsSetSidebarFlow({
            projectId,
            projectName,
            projectVersionId: lastVersionId,
            settingsId,
            testType
        });
    }
    catch (e) {
        yield put(setSettingsSetList({}));
    }
}
export function* showTestProjectSettingsSetSidebarFlow() {
    try {
        const projectId = yield select(getProjectId);
        const projectName = yield select(getProjectName);
        const projectVersionId = yield select(getProjectVersion);
        const settingsId = yield select(getSettingsId);
        const testType = yield select(getTestType);
        return yield* showSettingsSetSidebarFlow({ projectId, projectName, projectVersionId, settingsId, testType });
    }
    catch (e) {
        yield put(setSettingsSetList({}));
    }
}
const isDontShowJMXLinkingAttention = (userId) => {
    const isDontShowSettingSetJMXLinkingAttention = JSON.parse(getFromLocalStorage("isDontShowSettingSetJMXLinkingAttention"));
    return Array.isArray(isDontShowSettingSetJMXLinkingAttention)
        ? isDontShowSettingSetJMXLinkingAttention.includes(userId)
        : false;
};
function* checkTestTypeByLinking(testType) {
    const { id: userId } = yield select(getClientInfo);
    return testType === TestType.JMX && !isDontShowJMXLinkingAttention(userId)
        ? yield put(getSiteModal(settingsSetLinkingJmxFormModal({ element: SettingsSetJMXLinkingAttention })))
        : undefined;
}
function* testProjectToSettingsSetLinking(action) {
    const id = action && action.payload ? action.payload : undefined;
    const { projectId, projectVersionId, testType } = yield select(getSettingsSetSidebarTestProjectData);
    yield call(linkTestProjectToSettingsSet, {
        projectId,
        projectVersionId,
        settingId: id
    });
    yield* checkTestTypeByLinking(testType);
    const _a = (projectId ? yield call(getTestProjectDetails, projectId) : null).data, _b = _a === void 0 ? {} : _a, { version } = _b, restData = __rest(_b, ["version"]);
    return Object.assign(Object.assign({}, restData), { projectId, projectVersionId: getMaxInArray(version, "id"), projectVersions: version, settingsId: id });
}
function* testProjectSettingsSetsLinkingFlow(action) {
    try {
        const testProjectData = yield* testProjectToSettingsSetLinking(action);
        const currentTestProjectData = yield select(getTestProjectSettings);
        yield* saveTestProjectDetails(Object.assign(Object.assign({}, testProjectData), { preparedTestProjectVersionData: Object.assign(Object.assign({}, currentTestProjectData), { currentVersion: testProjectData.projectVersionId }) }));
        yield put(linkSettingsSetToTestProjectSuccess(testProjectData));
        yield* refreshLinkedTestProjectSettingsSet();
    }
    catch (e) {
        yield put(linkSettingsSetToTestProjectFailure(getResponseErrorData(e)));
    }
}
function* testProjectInListSettingsSetsLinkingFlow(action) {
    try {
        const _a = yield* testProjectToSettingsSetLinking(action), { projectVersions } = _a, restProjectData = __rest(_a, ["projectVersions"]);
        yield put(linkSettingsSetToTestProjectInListSuccess(Object.assign(Object.assign({}, restProjectData), { version: projectVersions })));
        yield* refreshLinkedTestProjectSettingsSet();
    }
    catch (e) {
        yield put(linkSettingsSetToTestProjectInListFailure(getResponseErrorData(e)));
    }
}
const addUserToIsDontShowAttention = (userId) => {
    const isDontShowSettingSetJMXLinkingAttention = JSON.parse(getFromLocalStorage("isDontShowSettingSetJMXLinkingAttention"));
    const isDontShowAttentionAgain = Array.isArray(isDontShowSettingSetJMXLinkingAttention)
        ? [...new Set([...isDontShowSettingSetJMXLinkingAttention, userId])]
        : [userId];
    setToLocalStorage("isDontShowSettingSetJMXLinkingAttention", JSON.stringify(isDontShowAttentionAgain));
};
function* jmxLinkingAttentionSubmittingFlow(action) {
    const isDontShowAttentionAgain = action ? action.payload : false;
    const { id } = yield select(getClientInfo);
    return isDontShowAttentionAgain && id > 0 ? addUserToIsDontShowAttention(id) : undefined;
}
function* unlinkTestProjectSettingsSet() {
    const { projectId, projectVersionId } = yield select(getSettingsSetSidebarTestProjectData);
    yield call(unlinkTestProjectToSettingsSet, {
        projectId,
        projectVersionId
    });
    const _a = (projectId ? yield call(getTestProjectDetails, projectId) : null).data, _b = _a === void 0 ? {} : _a, { version } = _b, restData = __rest(_b, ["version"]);
    return Object.assign(Object.assign({}, restData), { projectId, projectVersionId: getMaxInArray(version, "id"), projectVersions: version });
}
function* testProjectSettingsSetsUnlinkingFlow() {
    try {
        const testProjectData = yield* unlinkTestProjectSettingsSet();
        const currentTestProjectData = yield select(getTestProjectSettings);
        yield* saveTestProjectDetails(Object.assign(Object.assign({}, testProjectData), { preparedTestProjectVersionData: Object.assign(Object.assign({}, currentTestProjectData), { currentVersion: testProjectData.projectVersionId }) }));
        yield put(unlinkSettingsSetForTestProjectSuccess(testProjectData));
    }
    catch (e) {
        yield put(unlinkSettingsSetForTestProjectFailure(getResponseErrorData(e)));
    }
}
function* testProjectInListSettingsSetsUnlinkingFlow() {
    try {
        const _a = yield* unlinkTestProjectSettingsSet(), { projectVersions } = _a, restProjectData = __rest(_a, ["projectVersions"]);
        yield put(unlinkSettingsSetForTestProjectInListSuccess(Object.assign(Object.assign({}, restProjectData), { version: projectVersions })));
    }
    catch (e) {
        yield put(unlinkSettingsSetForTestProjectInListFailure(getResponseErrorData(e)));
    }
}
export function* testProjectsSettingsSetSidebarFlow() {
    yield takeEvery(addSettingsSetInSidebar, settingsSetInSidebarAddingFlow);
    yield takeEvery(changeSidebarFilterLabels, refreshSettingsSetListByFilterFlow);
    yield takeEvery(changeSidebarFilterName, refreshSettingsSetListByFilterFlow);
    yield takeEvery(extractSettingsFromTestProject, extractSettingsFromTestProjectFlow);
    yield takeEvery(linkSettingsSetToNewTest, refreshLinkedTestProjectSettingsSet);
    yield takeEvery(linkSettingsSetToTestProjectRequest, testProjectSettingsSetsLinkingFlow);
    yield takeEvery(linkSettingsSetToTestProjectInListRequest, testProjectInListSettingsSetsLinkingFlow);
    yield takeEvery(refreshTestProjectSettingsSetList, refreshTestProjectSettingsSetListFlow);
    yield takeEvery(showTestProjectsSettingsSetSidebar, showTestProjectsSettingsSetSidebarFlow);
    yield takeEvery(submitJmxLinkingAttention, jmxLinkingAttentionSubmittingFlow);
    yield takeEvery(unlinkSettingsSetForTestProjectRequest, testProjectSettingsSetsUnlinkingFlow);
    yield takeEvery(unlinkSettingsSetForTestProjectInListRequest, testProjectInListSettingsSetsUnlinkingFlow);
}
