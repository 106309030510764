import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { useIntl } from "@boomq/utils";
import { IMPORT_JMX_BUTTON_LABEL, JMX_FILE_UPLOADER_EMPTY_TEXT, SUPPORTING_FILES_EMPTY_TEXT } from "./intl";
import { ImportJmxFile } from "../../../../components/account/TestProject/ImportJmxFile";
import { formatIntlMessage } from "../../../../helpers/intl";
import { ImportDataSourceType } from "../../../../models/importData";
import { importJmxFileRequest, importTestProjectDataChangeParamsField } from "../../../../redux/actions/importTestProjectData";
import { getImportedFileBySourceType } from "../../../../redux/reducers/importTestProjectData";
const connector = connect((state) => ({
    files: getImportedFileBySourceType(state, ImportDataSourceType.JMX)
}), {
    onClickImportButton: importJmxFileRequest,
    onChangeImportTestProjectField: importTestProjectDataChangeParamsField
});
const getFilesData = (data, fieldName) => (data && Array.isArray(data[fieldName]) ? data[fieldName] : []);
const ImportJmxFileContainer = ({ files, onChangeImportTestProjectField, onClickImportButton }) => {
    const { formatMessage } = useIntl();
    const changeHandler = (field, newFiles) => onChangeImportTestProjectField({
        field: ImportDataSourceType.JMX,
        value: {
            files: Object.assign(Object.assign({}, files), { [field]: Array.isArray(newFiles) && newFiles.length > 0 ? newFiles : [] }),
            data: {}
        }
    });
    const changeJMXFilesHandler = (files) => changeHandler("jmx", files);
    const changeSupportingFilesHandler = (files) => changeHandler("support", files);
    const importFileHandler = () => onClickImportButton();
    const jmxFiles = getFilesData(files, "jmx");
    const importJMXButtonLabel = formatIntlMessage(IMPORT_JMX_BUTTON_LABEL, formatMessage);
    const jmxFileUploaderEmptyText = formatIntlMessage(JMX_FILE_UPLOADER_EMPTY_TEXT, formatMessage);
    const supportingFilesEmptyText = formatIntlMessage(SUPPORTING_FILES_EMPTY_TEXT, formatMessage);
    return (React.createElement(ImportJmxFile, { importJMXButtonLabel: importJMXButtonLabel, isImportButtonDisabled: jmxFiles === 0, jmxFiles: jmxFiles, jmxFileUploaderEmptyText: jmxFileUploaderEmptyText, onChangeJMXFiles: changeJMXFilesHandler, onChangeSupportingFiles: changeSupportingFilesHandler, onClickImportButton: importFileHandler, supportingFiles: getFilesData(files, "support"), supportingFilesEmptyText: supportingFilesEmptyText }));
};
export default withRouter(connector(ImportJmxFileContainer));
