export enum TestDetailsAction {
    ChangeSla = "onChangeSla",
    CreateTrend = "onCreateTrend",
    Stop = "onStop"
}

export enum TestResultFilesState {
    Processing = "PROCESSING",
    Finished = "FINISHED",
    Failed = "FAILED"
}

export enum TestMode {
    Test = "TEST",
    Debug = "DEBUG_TEST"
}

export enum TestDisplayStatus {
    Canceled = "CANCELED",
    Failed = "FAILED",
    Finished = "FINISHED",
    Initialization = "INITIALIZATION",
    Running = "RUNNING",
    TestStopping = "TEST_STOPPING",
    Waiting = "WAITING"
}

export enum TestStatus {
    Canceled = "CANCELED",
    Created = "CREATED",
    Failed = "FAILED",
    Finished = "FINISHED",
    RetryingWaiting = "RETRYING_WAITING",
    ResourcesAllocated = "RESOURCES_ALLOCATED",
    Running = "RUNNING",
    TestPlansCreated = "TEST_PLANS_CREATED",
    TestResourcesEstimated = "TEST_RESOURCES_ESTIMATED",
    TestStopping = "TEST_STOPPING",
    Waiting = "WAITING"
}

export enum TestType {
    Scalability = "SCALABILITY",
    Stable = "STABLE",
    JMX = "OWN_JMX"
}
