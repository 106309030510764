export const ACCOUNT_LINKING_URL_TEXT = {
    defaultMessage: "To link your account please proceed to this URL below:",
    id: "account.test.profile.import.analytics.add.account.auth.link"
};

export const ACCOUNT_LINKING_CODE_TEXT = {
    defaultMessage: "paste the code below when prompted:",
    id: "account.test.profile.import.analytics.add.account.auth.code"
};

export const CODE_ENTER_BUTTON_LABEL = {
    defaultMessage: "I entered code",
    id: "account.test.profile.import.analytics.add.account.auth.code.enter.button"
};

export const CODE_ENTER_TEXT = {
    defaultMessage: 'After you are done please click "I entered code"',
    id: "account.test.profile.import.analytics.add.account.auth.code.enter"
};

export const COPY_CODE_BUTTON_LABEL = {
    defaultMessage: "copy code",
    id: "account.test.profile.import.analytics.add.account.auth.copy"
};

export const COPIED_CODE_BUTTON_LABEL = {
    defaultMessage: "code copied",
    id: "account.test.profile.import.analytics.add.account.auth.copied"
};
