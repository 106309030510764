import React from "react";
import { FormattedMessage } from "react-intl";
import { sizes } from "../../styled/response";
import { ActionList, ActionListItem, ClickableTableRow, TableRowCol, TableRowLink, TableRowColText } from "../../styled/Table";
import { getActionIconByName } from "../../../helpers/slaReportProfiles";
import { SlaReportProfilesItemAction } from "../../../helpers/types";
const renderActions = (props) => {
    const { slaReportProfile: { id } } = props || {};
    const actionHandler = (e, actionName) => {
        e.stopPropagation();
        e.preventDefault();
        return typeof props[actionName] === "function" ? props[actionName]({ id }) : undefined;
    };
    return (props.actionItems || []).map(({ id, actionName }) => {
        const Icon = getActionIconByName(actionName);
        return (React.createElement(ActionListItem, { key: id, onClick: (e) => actionHandler(e, actionName) },
            React.createElement(Icon, null)));
    });
};
export const AllSlaReportProfilesItem = (props) => {
    const { slaReportProfile: { id, name, projectName }, width } = props || {};
    return (React.createElement(ClickableTableRow, null,
        React.createElement(TableRowLink, { to: `/account/sla-report-profiles/${id}` },
            React.createElement(TableRowCol, null,
                width <= sizes.tablet && (React.createElement(TableRowColText, { blue: true },
                    React.createElement(FormattedMessage, { id: "account.all.test.project.header.name", defaultMessage: "Name" }))),
                React.createElement(TableRowColText, { blue: true }, name)),
            React.createElement(TableRowCol, null,
                width <= sizes.tablet && (React.createElement(TableRowColText, { blue: true },
                    React.createElement(FormattedMessage, { id: "account.all.test.project.header.project.name", defaultMessage: "Project name" }))),
                React.createElement(TableRowColText, { blue: true }, projectName)),
            React.createElement(ActionList, null, renderActions(props)))));
};
