var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { DEFAULT_IS_OPENED_VALUE } from "./constants";
import { SpoilerDefaultArrow, SpoilerDefaultHeader } from "./styled";
export const DefaultHeaderTemplate = (_a) => {
    var { isOpened = DEFAULT_IS_OPENED_VALUE, onClick } = _a, restProps = __rest(_a, ["isOpened", "onClick"]);
    return (React.createElement(SpoilerDefaultHeader, { onClick: onClick },
        restProps.header,
        React.createElement(SpoilerDefaultArrow, { className: "spoiler_arrow_button", isopened: isOpened.toString() })));
};
