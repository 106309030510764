import { combineActions, handleActions } from "redux-actions";
import { DEFAULT_TEAM_MEMBERS_PAGE_SIZE } from "../../helpers/teams";
import authorizeActions from "../actions/authorize";
import { clearTeamData } from "../actions/team";
import { setTeamMembers, setTeamMembersLoading, updateTeamMember } from "../actions/teamMembers";
const { logout } = authorizeActions;
export const initialState = {
    content: [],
    isLoading: false,
    number: 0,
    numberOfElements: 0,
    size: DEFAULT_TEAM_MEMBERS_PAGE_SIZE,
    totalElements: 0,
    totalPages: 0
};
export default handleActions({
    [combineActions(clearTeamData, logout)]: () => (Object.assign({}, initialState)),
    [setTeamMembers]: (state, action) => (Object.assign(Object.assign({}, state), (action && action.payload ? action.payload : {}))),
    [setTeamMembersLoading]: (state, action) => (Object.assign(Object.assign({}, state), { isLoading: action && action.payload ? action.payload : initialState.isLoading })),
    [updateTeamMember]: (state, action) => {
        const data = action && action.payload ? action.payload : {};
        return Object.assign(Object.assign({}, state), { content: state.content.map((teamMember) => teamMember.id === data.id ? Object.assign(Object.assign({}, teamMember), data) : teamMember) });
    }
}, initialState);
const getStateParam = (state, paramName) => state && state.teamMembers ? state.teamMembers[paramName] : initialState[paramName];
export const getTeamMemberList = (state) => getStateParam(state, "content");
export const getTeamMembersIsLoading = (state) => getStateParam(state, "isLoading");
export const getTeamMembersCurrentPage = (state) => getStateParam(state, "number");
export const getTeamMembersSize = (state) => getStateParam(state, "size");
export const getTeamMembersCount = (state) => getStateParam(state, "totalElements");
export const getTeamMembersPagesCount = (state) => getStateParam(state, "totalPages");
