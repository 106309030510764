import { isEmpty } from "@boomq/utils";
import { COOKIE_REQUIRED_FIELDS } from "./constants";
import { headerItemListValidator } from "./header";
import { EMPTY_LITERAL_PARAMETER_NAME, INCORRECT_SETTINGS_SET_NAME_EMPTY, INCORRECT_TEST_PROJECT_SETTINGS_SET, INCORRECT_URL_OVERWRITE_URL, INCORRECT_WEBHOOK, INCORRECT_WEBHOOK_ITEM, INCORRECT_WEBHOOK_TEST_STATUS } from "./intl";
import { parameterNameValidator, parametersValidator } from "./parameters";
import { requestTimerValidator } from "./timers";
import { requestUrlValidator, requestUrlValidatorErrorText } from "./url";
import { validatorByRequiredFields } from "./validators";
import { OTHER_SETTINGS_TITLE } from "../../components/account/TestProjectSettingsSet/OtherSettings/intl";
import { PARAMETERS_TITLE } from "../../components/account/TestProjectSettingsSet/Parameters/intl";
import { URL_OVERWRITE_TITLE } from "../../components/account/TestProjectSettingsSet/UrlOverwrite/intl";
import { DEFAULT_COOKIES_TITLE } from "../../containers/account/TestProjectSettingsSet/DefaultCookies/intl";
import { WEBHOOKS_TITLE } from "../../containers/account/TestProjectSettingsSet/Webhooks/intl";
const checkEmptyParameterName = ({ key, value }) => isEmpty(key) && !isEmpty(value)
    ? {
        isValid: false,
        error: EMPTY_LITERAL_PARAMETER_NAME
    }
    : { isValid: true };
const checkParameterName = ({ key, value }) => {
    const { isValid, error } = parameterNameValidator(key);
    return isValid || isEmpty(value) ? { isValid: true } : { isValid, error };
};
export const literalParameterValidator = ({ key, value }) => {
    const { isValid, error } = checkEmptyParameterName({ key, value });
    return isValid ? checkParameterName({ key, value }) : { isValid, error };
};
export const cookieValidatorByRequiredFields = (item) => validatorByRequiredFields(item, COOKIE_REQUIRED_FIELDS);
const defaultCookieItemValidator = (res, item) => {
    const { isValid, validatedData } = cookieValidatorByRequiredFields(item);
    return {
        isValid: isValid ? res.isValid : false,
        validatedItemList: [...res.validatedItemList, validatedData]
    };
};
export const defaultCookieItemListValidator = (itemList) => itemList.reduce(defaultCookieItemValidator, { isValid: true, validatedItemList: [] });
export const urlOverwriteValidator = (items) => {
    const invalidUrl = (items || [])
        .reduce((res, item) => [...res, item.urlToRewrite, item.updatedUrl], [])
        .find((url) => !requestUrlValidator(url).isValid);
    return isEmpty(invalidUrl) && invalidUrl !== ""
        ? {
            isValid: true
        }
        : {
            isValid: false,
            error: INCORRECT_URL_OVERWRITE_URL
        };
};
export const urlOverwriteItemFieldValidator = (url) => isEmpty(url) ? undefined : requestUrlValidatorErrorText(url);
export const urlOverwriteItemListValidator = (itemList) => {
    const validatedItemList = itemList.map((item) => (Object.assign(Object.assign({}, item), { error: {
            urlToRewrite: urlOverwriteItemFieldValidator(item.urlToRewrite),
            updatedUrl: urlOverwriteItemFieldValidator(item.updatedUrl)
        } })));
    return {
        isValid: !validatedItemList.some(({ error }) => error && (!isEmpty(error.urlToRewrite) || !isEmpty(error.updatedUrl))),
        validatedItemList
    };
};
const webhookValidator = ({ testDisplayStateSet, url }) => !isEmpty(testDisplayStateSet) && requestUrlValidator(url).isValid;
const webhookErrorText = (data) => (webhookValidator(data) ? "" : INCORRECT_WEBHOOK_ITEM);
const webhookTestStatusErrorText = ({ testDisplayStateSet }) => isEmpty(testDisplayStateSet) ? INCORRECT_WEBHOOK_TEST_STATUS : "";
export const webhookListValidator = (items) => items.map((item) => (Object.assign(Object.assign({}, item), { error: webhookErrorText(item), testStatusError: webhookTestStatusErrorText(item) })));
const isInvalidWebhookItem = (item) => (isEmpty(item) ? false : !isEmpty(item.error));
export const webhookItemListValidator = (items) => {
    const validatedItemList = webhookListValidator(items);
    const isExistInvalidWebhook = (validatedItemList || []).some(isInvalidWebhookItem);
    return {
        isValid: !isExistInvalidWebhook,
        validatedItemList
    };
};
export const webhooksValidator = (items) => {
    const isExistInvalidWebhook = (items || []).some(isInvalidWebhookItem);
    return isExistInvalidWebhook
        ? {
            isValid: false,
            error: INCORRECT_WEBHOOK
        }
        : {
            isValid: true
        };
};
const wizardTitles = [
    PARAMETERS_TITLE,
    URL_OVERWRITE_TITLE,
    OTHER_SETTINGS_TITLE,
    DEFAULT_COOKIES_TITLE,
    WEBHOOKS_TITLE
];
const settingsSetOtherSettingsSectionValidator = ({ defaultHeaders, defaultTimer }) => {
    const { isValid: isValidDefaultHeaders } = headerItemListValidator(defaultHeaders);
    const { isValid: isValidTimerSettings } = requestTimerValidator(defaultTimer);
    return {
        isValid: isValidDefaultHeaders && isValidTimerSettings
    };
};
export const settingsSetNameValidator = (name) => isEmpty(name)
    ? {
        isValid: false,
        error: INCORRECT_SETTINGS_SET_NAME_EMPTY
    }
    : {
        isValid: true
    };
export const testProjectSettingsSetValidator = ({ csvParameters, literalParameters, defaultCookies, defaultHeaders, defaultTimer = {}, urlOverwriteItems, webhookItems }) => {
    const { isValid: isValidDefaultCookies } = defaultCookieItemListValidator(defaultCookies);
    const { isValid: isValidRequestParameters } = parametersValidator({
        csvParameters,
        parameters: literalParameters
    });
    const { isValid: isValidUrlOverwrite } = urlOverwriteValidator(urlOverwriteItems);
    const { isValid: isValidWebhooks } = webhooksValidator(webhookItems);
    const { isValid: isValidOtherSettings } = settingsSetOtherSettingsSectionValidator({
        defaultHeaders,
        defaultTimer
    });
    const isValid = isValidDefaultCookies &&
        isValidOtherSettings &&
        isValidRequestParameters &&
        isValidUrlOverwrite &&
        isValidWebhooks;
    return isValid
        ? {
            isValid,
            error: undefined
        }
        : {
            isValid,
            error: Object.assign(Object.assign({}, INCORRECT_TEST_PROJECT_SETTINGS_SET), { values: [
                    isValidRequestParameters,
                    isValidUrlOverwrite,
                    isValidOtherSettings,
                    isValidDefaultCookies,
                    isValidWebhooks
                ].reduce((res, isValidWizardItem, index) => isValidWizardItem ? res : [...res, wizardTitles[index]], []) })
        };
};
export const webhookSettingsValidator = ({ url, headers }) => {
    const { isValid: isValidUrl, error } = requestUrlValidator(url);
    const { isValid: isValidHeaders, validatedItemList: validatedHeaderList } = headerItemListValidator(headers);
    return {
        isValid: isValidUrl && isValidHeaders,
        validatedHeaderList,
        urlErrorText: error
    };
};
