import React from "react";
import styled from "styled-components";
export const IconParameters = (props) => React.createElement(IconWrapper, Object.assign({}, props), "P");
const IconWrapper = styled.div `
    align-items: center;
    display: flex;
    font-size: 28px;
    height: 24px;
    justify-content: center;
    width: 24px;
`;
