import { Help } from "@boomq/uikit";
import styled, { css } from "styled-components";
import { AuthInput } from "../../styled/Auth";
export const passwordTooltipTheme = {
    maxWidth: "700px",
    textAlign: "left"
};
export const PassInput = styled(AuthInput) `
    ${({ passwordTooltip }) => passwordTooltip && passwordTooltip.length > 0
    ? css `
                  padding-right: 34px;
              `
    : css ``}
`;
export const StyledHelp = styled(Help) `
    position: absolute;
    right: 12px;
    top: calc(50% - 12px);
`;
