import { handleActions } from "redux-actions";
import { getMetricsFromItems } from "../../helpers/testProjectSettingsSet";
import { monitoringSystemMetricSavingRequest, monitoringSystemMetricChangingSuccess, setMonitoringSystemMetricsData } from "../actions/monitoring";
export const initialState = {
    systemMetricPartItems: [],
    systemMetrics: [],
    systemMonitoringTarTelegrafLocation: "",
    systemMonitoringTelegrafLocation: "",
};
export default handleActions({
    [monitoringSystemMetricSavingRequest]: (state, { payload }) => (Object.assign(Object.assign({}, state), { systemMetrics: payload && Array.isArray(payload.selectedMetrics)
            ? getMetricsFromItems(payload.selectedMetrics)
            : state.systemMetrics })),
    [monitoringSystemMetricChangingSuccess]: (state, { payload }) => (Object.assign(Object.assign({}, state), payload)),
    [setMonitoringSystemMetricsData]: (state, { payload }) => (Object.assign(Object.assign({}, state), payload))
}, initialState);
const getStateParam = (state, paramName) => state && state.monitoring ? state.monitoring[paramName] : initialState[paramName];
export const getSystemMetricsData = (state) => state;
export const getSystemMetricPartItems = (state) => getStateParam(state, "systemMetricPartItems");
export const getSystemMetrics = (state) => getStateParam(state, "systemMetrics");
export const getSystemMonitoringTarTelegrafLocation = (state) => getStateParam(state, "systemMonitoringTarTelegrafLocation");
export const getSystemMonitoringTelegrafLocation = (state) => getStateParam(state, "systemMonitoringTelegrafLocation");
