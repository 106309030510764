export const EXTRACTOR_FIELD_TO_CHECK_BODY_MESSAGE = {
    defaultMessage: "Body",
    id: "account.request.form.extract.response.field.to.check.value.body.title"
};
export const EXTRACTOR_FIELD_TO_CHECK_REQUEST_HEADERS_MESSAGE = {
    defaultMessage: "Request headers",
    id: "account.request.form.extract.response.field.to.check.value.request.headers.title"
};
export const EXTRACTOR_FIELD_TO_CHECK_RESPONSE_CODE_MESSAGE = {
    defaultMessage: "Response code",
    id: "account.request.form.extract.response.field.to.check.value.response.code.title"
};
export const EXTRACTOR_FIELD_TO_CHECK_RESPONSE_HEADERS_MESSAGE = {
    defaultMessage: "Response headers",
    id: "account.request.form.extract.response.field.to.check.value.response.headers.title"
};
export const EXTRACTOR_FIELD_TO_CHECK_RESPONSE_MESSAGE_MESSAGE = {
    defaultMessage: "Response message",
    id: "account.request.form.extract.response.field.to.check.value.response.message.title"
};
export const EXTRACTOR_FIELD_TO_URL_MESSAGE = {
    defaultMessage: "Url",
    id: "account.request.form.extract.response.field.to.check.value.url.title"
};
export const EXTRACTOR_MATCH_TYPE_ALL_MATCHES_MESSAGE = {
    defaultMessage: "All matches",
    id: "account.request.form.extract.response.match.type.value.all.title"
};
export const EXTRACTOR_MATCH_TYPE_RANDOM_MATCH_MESSAGE = {
    defaultMessage: "Random match",
    id: "account.request.form.extract.response.match.type.value.random.title"
};
export const EXTRACTOR_MATCH_TYPE_SPECIFIC_MATCH_MESSAGE = {
    defaultMessage: "Specific match",
    id: "account.request.form.extract.response.match.type.value.custom.title"
};
export const EXTRACT_TYPE_PLACEHOLDER_RE_MESSAGE = {
    defaultMessage: "Regular Expression",
    id: "account.request.form.extract.response.regex.value.re.title"
};
export const EXTRACT_TYPE_PLACEHOLDER_GROUP_NUMBER_MESSAGE = {
    defaultMessage: "Group's number",
    id: "account.request.form.extract.response.regex.value.group.title"
};
export const EXTRACT_TYPE_PLACEHOLDER_FIELD_TO_CHECK_MESSAGE = {
    defaultMessage: "Field to check",
    id: "account.request.form.extract.response.regex.value.field.to.check.title"
};
export const EXTRACT_TYPE_PLACEHOLDER_JSONPATH_MESSAGE = {
    defaultMessage: "jSONPath Expression",
    id: "account.request.form.extract.response.jsonPath.value.title"
};
export const EXTRACT_TYPE_PLACEHOLDER_XPATH_MESSAGE = {
    defaultMessage: "XPath query",
    id: "account.request.form.extract.response.xpath.value.title"
};
export const EXTRACT_TYPE_PLACEHOLDER_LEFT_BORDER_MESSAGE = {
    defaultMessage: "Left border",
    id: "account.request.form.extract.response.boundary.value.left.title"
};
export const EXTRACT_TYPE_PLACEHOLDER_RIGHT_BORDER_MESSAGE = {
    defaultMessage: "Right border",
    id: "account.request.form.extract.response.boundary.value.right.title"
};
export const EXTRACT_TYPE_BOUNDARY_TEXT_MESSAGE = {
    defaultMessage: "Boundary",
    id: "account.request.form.extract.response.boundary.title"
};
export const RESPONSE_PREVIEW_FROM_HAR_MESSAGE = {
    defaultMessage: "HAR",
    id: "account.request.form.extract.response.preview.source.item.name.HAR"
};
