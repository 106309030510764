import { combineActions, handleActions } from "redux-actions";
import authorizeActions from "../actions/authorize";
import { setIsRunningTestsLoading, setRunningTestsData, stopTestRequest, stopTestSuccess, stopTestFailure } from "../actions/runningTests";
import clientActions from "../actions/client";
import { stopTestInDetailsRequest } from "../actions/testDetails";
export const initialState = {
    content: [],
    isFetching: [],
    isLoading: false,
    prevRunningTestsCount: null
};
export default handleActions({
    [authorizeActions.logout]: () => (Object.assign({}, initialState)),
    [combineActions(stopTestInDetailsRequest, stopTestRequest)]: (state, action) => (Object.assign(Object.assign({}, state), { isFetching: action.payload ? [...state.isFetching, action.payload.id] : state.isFetching })),
    [clientActions.getTestRunsData]: (state) => (Object.assign(Object.assign({}, state), { isLoading: true })),
    [setIsRunningTestsLoading]: (state, { payload }) => (Object.assign(Object.assign({}, state), { isLoading: payload })),
    [setRunningTestsData]: (state, { payload }) => {
        var _a;
        return (Object.assign(Object.assign({}, state), { content: payload, isLoading: false, prevRunningTestsCount: (_a = state.content) === null || _a === void 0 ? void 0 : _a.length }));
    },
    [stopTestFailure]: (state, action) => (Object.assign(Object.assign({}, state), { isFetching: action.payload
            ? state.isFetching.filter((item) => item !== action.payload.id)
            : state.isFetching })),
    [stopTestSuccess]: (state, action) => (Object.assign(Object.assign({}, state), { content: state.content.filter(({ id }) => id !== action.payload.id), isFetching: action.payload
            ? state.isFetching.filter((item) => item !== action.payload.id)
            : state.isFetching }))
}, initialState);
const getStateParam = (state, paramName) => { var _a, _b; return (_b = (_a = state.runningTests) === null || _a === void 0 ? void 0 : _a[paramName]) !== null && _b !== void 0 ? _b : initialState[paramName]; };
export const getStopStatus = (state) => getStateParam(state, "isFetching");
export const getIsRunningTestsLoading = (state) => getStateParam(state, "isLoading");
export const getIsStoppingTestById = (state, testId) => {
    const stoppingTests = getStopStatus(state);
    return (stoppingTests || []).some((id) => id === testId);
};
export const getRunningTests = (state) => getStateParam(state, "content");
export const getPreviousRunningTestsCount = (state) => getStateParam(state, "prevRunningTestsCount");
