import { fork } from "redux-saga/effects";
import { commonRootSaga } from "@boomq/common";
import { allTestsFlow } from "./sagas/allTests";
import { authFlow } from "./sagas/auth";
import { clientDataFlow } from "./sagas/client";
import { errorFlow } from "./sagas/error";
import { importTestProjectDataFlow } from "./sagas/importTestProjectData";
import { loginFlow } from "./sagas/login";
import { newRequestFlow } from "./sagas/newRequest";
import { newTestFlow } from "./sagas/newTest";
import { testProjectJmxFlow } from "./sagas/testProjectJmx";
import { testProjectsSettingsSetSidebarFlow } from "./sagas/testProjectsSettingsSetSidebar";
import { threadGroupFlow } from "./sagas/threadGroup";
import { threadGroupEditorFlow } from "./sagas/threadGroupEditor";

export default function* () {
    yield fork(commonRootSaga);
    yield fork(allTestsFlow);
    yield fork(authFlow);
    yield fork(clientDataFlow);
    yield fork(errorFlow);
    yield fork(importTestProjectDataFlow);
    yield fork(loginFlow);
    yield fork(newRequestFlow);
    yield fork(newTestFlow);
    yield fork(testProjectJmxFlow);
    yield fork(testProjectsSettingsSetSidebarFlow);
    yield fork(threadGroupFlow);
    yield fork(threadGroupEditorFlow);
}
