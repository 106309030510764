import { takeEvery, call, select, put, all } from "redux-saga/effects";
import { push } from "connected-react-router";
import { getCurrentPathName, isHttpProtocolSecure, removeCookie } from "@boomq/utils";
import { checkNewTestTemporaryCopySaving, isTestProjectModified } from "./newTest";
import authorizeActions from "../actions/authorize";
import { configRequest } from "../actions/config";
import { hideLoader } from "../actions/loader";
import { reloadFailure, reloadRequest, reloadSuccess } from "../actions/reload";
import { getSiteModal } from "../actions/siteModal";
import { modelSchemaRequest } from "../actions/testProject";
import { testProjectDetailsSuccess } from "../actions/testProjects";
import { getIsAuthorize } from "../reducers/authorizePlate";
import { getPreviousRouterLocation } from "../reducers/location";
import { getAuthCookie, removeAuthToken, setAuthToken } from "../../helpers/auth";
import { getResponseErrorData } from "../../helpers/errors";
import { getNewTestRoute } from "../../helpers/routes";
import { getTestProjectDataFromLocalStorage } from "../../helpers/testProject";
import { clearTokenApi, getAuthorizeUser, getAuthProviders, setTokenApi } from "../../services/authSrv";
import { ConfirmForm } from "../../components/common/ConfirmForm";
import { unsavedTestProjectAttentionModal } from "../../data/content/modals";
import { isExistTestProjectDataInLocalStorage, removeTestProjectDataFromLocalStorage } from "../../helpers/testProject";
const { authSuccess, checkAuthorization, logout, redirectToUnsavedTestProject, saveToken, setAuthProviders, updateAuthProvidersRequest } = authorizeActions;
export function* loginActions(token) {
    yield all([
        call(setTokenApi, token),
        put(saveToken(token)),
        call(setAuthToken, token, isHttpProtocolSecure() ? { secure: true } : undefined),
        put(configRequest()),
        put(modelSchemaRequest())
    ]);
}
export function* logoutActions() {
    yield all([call(removeAuthToken), call(clearTokenApi), call(removeCookie, "boomq_auth")]);
    yield put(push("/authorize"));
}
export function* reloadFlow(tokenFromCookie) {
    yield put(reloadRequest());
    yield call(setTokenApi, tokenFromCookie);
    try {
        const response = yield call(getAuthorizeUser);
        const data = response && response.data ? response.data : {};
        const currentPathName = getCurrentPathName();
        const prevLocation = yield select(getPreviousRouterLocation);
        yield* updateAuthProvidersFlow();
        yield put(authSuccess(data));
        yield put(reloadSuccess(data));
        yield* loginActions(tokenFromCookie);
        return (prevLocation || "").includes("account") && currentPathName !== prevLocation
            ? yield put(push(prevLocation))
            : undefined;
    }
    catch (e) {
        yield put(reloadFailure(getResponseErrorData(e)));
    }
}
function* updateAuthProvidersFlow() {
    const { data } = yield call(getAuthProviders);
    yield put(setAuthProviders(data));
    yield put(hideLoader());
}
export function* checkAuthorizationFlow() {
    const isAuthorize = yield select(getIsAuthorize);
    const tokenFromCookie = yield call(getAuthCookie);
    return !isAuthorize && tokenFromCookie ? yield* reloadFlow(tokenFromCookie) : undefined;
}
function* redirectToUnsavedTestProjectFlow() {
    try {
        const testProjectDataFromlocalStorage = getTestProjectDataFromLocalStorage();
        removeTestProjectDataFromLocalStorage();
        yield put(push(getNewTestRoute()));
        return testProjectDataFromlocalStorage
            ? yield put(testProjectDetailsSuccess(Object.assign(Object.assign({}, testProjectDataFromlocalStorage), { isModified: true })))
            : undefined;
    }
    catch (e) {
        console.error(e);
    }
}
export function* checkUnsavedTestProjectBeforeLogout(store) {
    const isModified = yield* isTestProjectModified();
    return isModified || isExistTestProjectDataInLocalStorage()
        ? yield put(getSiteModal(unsavedTestProjectAttentionModal({
            element: ConfirmForm,
            cancelAction: () => store.dispatch(redirectToUnsavedTestProject()),
            confirmAction: () => store.dispatch(logout())
        })))
        : yield put(logout());
}
export function* unauthorizedUserLogout() {
    yield* checkNewTestTemporaryCopySaving();
    yield put(logout());
}
export function* authFlow() {
    yield takeEvery(checkAuthorization, checkAuthorizationFlow);
    yield takeEvery(logout, logoutActions);
    yield takeEvery(redirectToUnsavedTestProject, redirectToUnsavedTestProjectFlow);
    yield takeEvery(updateAuthProvidersRequest, updateAuthProvidersFlow);
}
