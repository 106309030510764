import styled from "styled-components";
import { Block } from "@boomq/uikit";

export const TeamsSectionBlock = styled.div`
    ${Block}
    display: flex;
    flex-direction: column;
    height: calc(100% - 60px);
    margin-bottom: 0px;
    margin-left: 32px;
    margin-right: 32px;
    margin-top: 24px;
    max-height: calc(100% - 60px);
    padding: 12px;
`;
