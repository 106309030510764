import { Color, FontBodyMediumBold, getZIndex, IconCopy, IconDropDownArrowDown, Select, StyledInputWrapper } from "@boomq/uikit";
import styled from "styled-components";
import { IconCopyPath, IconCopyURLWithFormattedParams } from "../../../styled/Icons";
export const customHintTheme = {
    position: "absolute",
    right: "-33px",
    top: "28%"
};
export const customTitleTheme = {
    alignItems: "center",
    display: "flex",
    justifyContent: "center"
};
const IconActionStyles = `
    fill: ${Color.blue};
`;
const IconActionWithTextStyles = `
    ${IconActionStyles}
    text {
        stroke: ${Color.blue};
    }
`;
export const ActionIcon = styled.span `
    align-items: center;
    cursor: pointer;
    display: flex;
`;
export const ArrowIcon = styled(IconDropDownArrowDown) `
    fill: ${Color.darkGrey5};
    pointer-events: none;
    position: absolute;
    right: 12px;
    top: 6px;
    transform: rotate(${({ isopen }) => (isopen ? 180 : 0)}deg);
    transition: transform 0.5s;
    z-index: ${({ isopen }) => getZIndex(isopen ? "CustomSelectOpenedIcon" : "CustomSelectClosedIcon")};
`;
export const ButtonSection = styled.div `
    align-items: flex-end;
    display: flex;
    flex: auto;
    justify-content: center;
    margin-top: 24px;
    position: relative;
`;
export const ButtonWithHintSection = styled.div `
    position: relative;
`;
export const RequestBlock = styled.div `
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    position: relative;
`;
export const RequestMethodBody = styled.div `
    background: ${({ bgColor }) => (bgColor ? bgColor : Color.white)};
    border: 1px solid;
    border-color: ${({ bgColor }) => (bgColor ? bgColor : Color.white)}
    border-radius: 12px;
    color: ${Color.darkGrey5};
    max-height: 36px;
    min-height: 36px;
    padding: 8px 36px 8px 8px;
`;
export const RequestNameActionsSection = styled.div `
    align-items: center;
    display: flex;
    margin-left: 6px;
`;
export const RequestNameLabel = styled.span `
    ${FontBodyMediumBold}
    align-items: center;
    color: ${Color.grey50};
    display: flex;
    max-width: 130px;
    min-width: 130px;
    width: 130px;
`;
export const RequestSection = styled.div `
    display: flex;
    margin-top: 20px;
    ${StyledInputWrapper} {
        margin-left: 12px;
    }
`;
export const StyledIconCopy = styled(IconCopy) `
    ${IconActionStyles}
`;
export const StyledIconCopyPath = styled(IconCopyPath) `
    ${IconActionWithTextStyles}
`;
export const StyledIconCopyURLWithFormattedParams = styled(IconCopyURLWithFormattedParams) `
    ${IconActionWithTextStyles}
`;
export const StyledSelect = styled(Select) `
    min-width: 130px;
`;
