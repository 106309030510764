import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { excludeArrayFromArrayByParam, isEmpty } from "@boomq/utils";
import { customSelectedTestsTheme, EmptyItemsText, NewSlaReportProfileItem, NewSlaReportProfileItemSection, SelectedItemBody } from "../SlaReport/styled";
import { Link } from "../../../styled/global";
import { CustomSelect } from "../../../common/CustomSelect";
import { AddingIcon } from "../../../styled/Buttons";
import { SPOILER_NAME } from "../../../../helpers/slaReport";
import { SelectionType } from "../../../../helpers/types";
import { addSlaReportChart, addSlaReportTable, changeExcludedTestIds, slaReportProfileChangeParamsField } from "../../../../redux/actions/slaReportProfile";
import { getClientTestDuration, getSearchClientTestList, getSlaReportCharts, getSlaReportExcludedTestIdsFilter, getSlaReportItemIndexList, getSlaReportSlaTypeList, getSlaReportTables } from "../../../../redux/reducers/slaReportProfile";
import { SlaReportProfileChart } from "../../../../containers/account/SlaReportProfile/SlaReportProfileChart";
import { SlaReportProfileTable } from "../../../../containers/account/SlaReportProfile/SlaReportProfileTable";
const connector = connect((state) => ({
    searchClientTestList: getSearchClientTestList(state),
    slaReportCharts: getSlaReportCharts(state),
    slaReportExcludedTestIdsFilter: getSlaReportExcludedTestIdsFilter(state),
    slaReportSlaTypeList: getSlaReportSlaTypeList(state),
    slaReportTables: getSlaReportTables(state),
    slaReportItemIndexList: getSlaReportItemIndexList(state),
    testDuration: getClientTestDuration(state)
}), {
    addSlaReportChart,
    addSlaReportTable,
    changeExcludedTestIds,
    slaReportProfileChangeParamsField
});
const getSearchTestList = (props) => props && Array.isArray(props.searchClientTestList) ? props.searchClientTestList : [];
const getSearchTestIdsFilter = (props) => {
    const excludedTests = props.slaReportExcludedTestIdsFilter || [];
    const searchTestList = getSearchTestList(props);
    return searchTestList.length === excludedTests.length
        ? []
        : excludeArrayFromArrayByParam(searchTestList, excludedTests, "value");
};
const SelectedTestsBodyTemplate = (searchClientTestList, selectedItems) => (React.createElement(SelectedItemBody, null,
    React.createElement(FormattedMessage, { defaultMessage: "{selectedTestCount} of {testCount} test runs", id: "account.sla.report.profile.charts.selected.tests", values: { selectedTestCount: (selectedItems || []).length, testCount: (searchClientTestList || []).length } })));
const SlaReportItems = (props) => {
    const searchTestList = getSearchTestList(props);
    const excludedTestList = props.slaReportExcludedTestIdsFilter || [];
    const searchTestIdsFilter = getSearchTestIdsFilter(props);
    const changeExcludedTestIdsFilterHandler = (data) => props.changeExcludedTestIds(data);
    const getNextIndex = () => {
        const indexList = Array.isArray(props.slaReportItemIndexList) ? props.slaReportItemIndexList : [];
        return isEmpty(indexList) ? 0 : Math.max(...indexList) + 1;
    };
    const EmptySlaTypesCriteriaSelectionRouteText = () => {
        const clickHandler = () => props.slaReportProfileChangeParamsField({
            field: "openedSpoilerName",
            value: SPOILER_NAME[0]
        });
        return (React.createElement(Link, { onClick: clickHandler },
            React.createElement(FormattedMessage, { defaultMessage: "adjust criteria", id: "account.sla.report.profile.settings.sla.types.empty.criteria.text" })));
    };
    return isEmpty(props.slaReportSlaTypeList) ? (React.createElement(EmptyItemsText, null,
        React.createElement(FormattedMessage, { defaultMessage: "Test run selection criteria haven\u2019t returned any test runs with SLAs. Please {criteria} or add SLAs.", id: "account.sla.report.profile.settings.sla.types.empty", values: { criteria: React.createElement(EmptySlaTypesCriteriaSelectionRouteText, null) } }))) : (React.createElement(React.Fragment, null,
        React.createElement(CustomSelect, { bodyTemplate: (selectedItems) => SelectedTestsBodyTemplate(props.searchClientTestList, selectedItems), isAutoPosition: true, items: searchTestList, name: "SearchTests", onChange: changeExcludedTestIdsFilterHandler, position: { offsetTop: 60 }, selectionType: SelectionType.Multiple, theme: customSelectedTestsTheme, titleAttrName: "comment", value: searchTestIdsFilter }),
        searchTestIdsFilter.length === 0 ? (React.createElement(EmptyItemsText, null,
            React.createElement(FormattedMessage, { defaultMessage: "Please select at least one test run", id: "accoutn.sla.report.profile.empty.selected.test" }))) : (React.createElement(React.Fragment, null,
            React.createElement(NewSlaReportProfileItemSection, null,
                React.createElement(NewSlaReportProfileItem, { onClick: () => props.addSlaReportChart(getNextIndex()) },
                    React.createElement(AddingIcon, null),
                    React.createElement(FormattedMessage, { id: "account.sla.report.profile.charts.add", defaultMessage: "Add chart" })),
                React.createElement(NewSlaReportProfileItem, { onClick: () => props.addSlaReportTable(getNextIndex()) },
                    React.createElement(AddingIcon, null),
                    React.createElement(FormattedMessage, { id: "account.sla.report.profile.tables.add", defaultMessage: "Add table" }))),
            Array.isArray(props.slaReportItemIndexList) ? (props.slaReportItemIndexList.map((slaReportItemIndex) => props.slaReportTables[slaReportItemIndex] ? (React.createElement(SlaReportProfileTable, { key: slaReportItemIndex, testDuration: props.testDuration, excludedTestList: excludedTestList, slaReportTableIndex: slaReportItemIndex, slaReportTableData: props.slaReportTables[slaReportItemIndex], slaReportTableTestList: searchTestList, slaReportSlaTypeList: props.slaReportSlaTypeList })) : (props.slaReportCharts[slaReportItemIndex] && (React.createElement(SlaReportProfileChart, { key: slaReportItemIndex, testDuration: props.testDuration, excludedTestList: excludedTestList, slaReportChartIndex: slaReportItemIndex, slaReportChartData: props.slaReportCharts[slaReportItemIndex], slaReportSlaTypeList: props.slaReportSlaTypeList }))))) : (React.createElement(React.Fragment, null))))));
};
export default connector(SlaReportItems);
