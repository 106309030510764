import React from "react";
import { Tooltip } from "@boomq/uikit";
import { ActionIcon, RequestNameActionsSection, StyledIconCopy, StyledIconCopyPath, StyledIconCopyURLWithFormattedParams } from "./styled";
import { FormType } from "../../../../helpers/types";
export const RequestNameActions = (props) => props.formType === FormType.View ? (React.createElement(React.Fragment, null)) : (React.createElement(RequestNameActionsSection, null,
    React.createElement(Tooltip, { title: props.buttonCopyUrlTooltipText },
        React.createElement(ActionIcon, { onClick: props.onChangeNameByCopyUrl },
            React.createElement(StyledIconCopy, null))),
    React.createElement(Tooltip, { title: props.buttonCopyPathTooltipText },
        React.createElement(ActionIcon, { onClick: props.onChangeNameByCopyPath },
            React.createElement(StyledIconCopyPath, null))),
    React.createElement(Tooltip, { title: props.buttonCopyUrlWithoutResolveParametersTooltipText },
        React.createElement(ActionIcon, { onClick: props.onChangeNameByCopyUrlWithoutResolveParameters },
            React.createElement(StyledIconCopyURLWithFormattedParams, null)))));
