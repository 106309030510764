import styled from "styled-components";
import { Button, Color, FontBodyBigMedium, H2Styles, IconClose, IconDelete, Select } from "@boomq/uikit";
import { NewItemButton } from "../../NewItemButton";
export const getClassNameByParams = (isShowParamsSidebar) => {
    const className = "pflb-sidebar-settings";
    return isShowParamsSidebar ? `${className} show` : className;
};
export const timeInputTheme = {
    "max-width": "206px"
};
export const ApplyButton = styled(Button) `
    margin-left: 6px;
`;
export const AxisYSelect = styled(Select) `
    min-width: 206px;
`;
export const ButtonSection = styled.div `
    display: flex;
    justify-content: center;
    margin-top: 6px;
`;
export const ChartParamsRow = styled.div `
    display: flex;
    margin-top: 12px;
`;
export const ChartSettingsRow = styled(ChartParamsRow) `
    justify-content: space-between;
`;
export const ChartAggregationIntervalSelect = styled(Select) `
    margin-top: 12px;
`;
export const ChartMetricTypeSelect = styled(Select) `
    margin-top: 12px;
`;
export const ChartTypeSelect = styled(Select) `
    margin-top: 12px;
`;
export const NewChartSerieButton = styled(NewItemButton) `
    margin-top: 16px;
`;
export const SpoilerHeader = styled.div `
    ${FontBodyBigMedium}
    border-bottom: 1px solid;
    border-color: ${Color.lightGrey95};
    color: ${({ titleColor }) => (titleColor ? titleColor : Color.darkGrey5)};
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: 16px 64px 16px 24px;
    position: relative;
`;
export const StyledIconDelete = styled(IconDelete) `
    cursor: pointer;
    fill: ${Color.blue};
    height: 20px;
    margin-left: 8px;
    width: 20px;
`;
export const StyledIconClose = styled(IconClose) `
    cursor: pointer;
    position: absolute;
    right: 4px;
    top: 4px;
`;
export const StyledSidebar = styled.div `
    right: 0;
    visibility: hidden;
`;
export const TestIdSelect = styled(Select) `
    width: 100%;
`;
export const Title = styled.h2 `
    ${H2Styles}
    display: flex;
    justify-content: flex-start;
    width: 100%;
`;
