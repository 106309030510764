import { connect } from "react-redux";
import { SimpleThreadGroup } from "../../../../components/account/TestProject/SimpleThreadGroup";
import { getSiteModal, newRequestChangeParams, newTestActions } from "@boomq/common";

const { changeRequestGroup, toggleEnabledGroup } = newTestActions;

const connector = connect(null, {
    changeRequestGroup,
    getSiteModal,
    newRequestChangeParams,
    toggleEnabledGroup
});

export default connector(SimpleThreadGroup);
