import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { customNewRequestFormButtonTheme, Content, NewRequestSection } from "./styled";
import { ConfirmForm } from "../../../common/ConfirmForm";
import { Request } from "../Request";
import { RequestHttpFormContainer } from "../../../../containers/account/TestProject/RequestHttpForm";
import { getGroupCaption } from "../RequestGroupCaption";
import { RequestGroupTitle } from "../RequestGroupTitle";
import { StyledButton } from "../../../styled/Buttons";
import { isAllowedTestProjectActionByPermissions } from "../../../../helpers/testProject";
import { FormType, TestProjectAction } from "../../../../helpers/types";
import { deleteRequestWithParamatersInGroupModal, isConfirmedModalForm, requestFormModal } from "../../../../data/content/modals";
import { Mode } from "../../../../models/sla";
import { newRequestChangeParams } from "../../../../redux/actions/newRequest";
import newTestActions from "../../../../redux/actions/newTest";
import { getSiteModal } from "../../../../redux/actions/siteModal";
import { getUsedExtractParametersByRequest } from "../../../../redux/reducers/newTest";
const { changeRequestGroup, copyRequestInGroup, deleteRequestInGroup } = newTestActions;
const connector = connect(null, {
    changeRequestGroup,
    copyRequestInGroup,
    deleteRequestInGroup,
    getSiteModal,
    newRequestChangeParams
});
const renderNewRequestSection = (props, onShowRequestForm) => {
    const addRequestInGroupHandler = () => {
        props.newRequestChangeParams({ groupId: props.requestGroup.id });
        onShowRequestForm(FormType.Add);
    };
    return !props.isHideAddButton &&
        isAllowedTestProjectActionByPermissions(props.permissionList, TestProjectAction.Edit) ? (React.createElement(NewRequestSection, null,
        React.createElement(StyledButton, { onClick: addRequestInGroupHandler, name: "newRequestForm", theme: customNewRequestFormButtonTheme },
            React.createElement(FormattedMessage, { defaultMessage: "Add request", id: "account.test.profile.button.add.request" })))) : (React.createElement(React.Fragment, null));
};
const RequestGroup = (props) => {
    const isExistUsedParametersInRequest = (id) => {
        const request = props.requestGroup.requests.find((request) => request.id === id);
        const requestExtractParameters = request.extractResponse || [];
        const usedExtractParameters = getUsedExtractParametersByRequest(props.requestGroup.requests)(request.groupId)(request.id);
        return (requestExtractParameters.reduce((res, parameter) => (res += usedExtractParameters.indexOf(parameter.variable) > -1 ? 1 : 0), 0) > 0);
    };
    const copyRequestHandler = (id) => props.copyRequestInGroup({ id });
    const confirmDeleteHandler = (e, id) => isConfirmedModalForm(e) && props.deleteRequestInGroup({ id });
    const deleteRequestHandler = (id) => isExistUsedParametersInRequest(id)
        ? props.getSiteModal(deleteRequestWithParamatersInGroupModal({
            element: ConfirmForm,
            closeAction: (e) => confirmDeleteHandler(e, id)
        }))
        : props.deleteRequestInGroup({ id });
    const showRequestFormHandler = (formType = FormType.Add, params) => props.getSiteModal(requestFormModal({
        closeAction: () => props.newRequestChangeParams(),
        element: RequestHttpFormContainer,
        props: Object.assign(Object.assign({}, params), { formType })
    }));
    const showEditRequestForm = (editedRequest, params) => {
        props.newRequestChangeParams(Object.assign({}, editedRequest));
        showRequestFormHandler(FormType.Edit, params);
    };
    const editRequestHandler = (id, params) => {
        const editedRequest = props.requestGroup.requests.find((request) => request.id === id);
        return editedRequest ? showEditRequestForm(editedRequest, params) : undefined;
    };
    const requestActions = {
        copyRequestHandler,
        deleteRequestHandler,
        editRequestHandler
    };
    const clickActionHandler = (actionName, id, params) => requestActions && typeof requestActions[actionName] === "function" && requestActions[actionName](id, params);
    const getGroupCaptionByPermissions = (props) => isAllowedTestProjectActionByPermissions(props.permissionList, TestProjectAction.Edit)
        ? getGroupCaption(props)
        : getGroupCaption(Object.assign(Object.assign({}, props), { mode: Mode.View }));
    return (React.createElement(RequestGroupTitle, Object.assign({}, props, { caption: getGroupCaptionByPermissions(props) }),
        React.createElement(Content, null,
            props.requestGroup && Array.isArray(props.requestGroup.requests) && (React.createElement("ul", null, props.requestGroup.requests.map((request) => (React.createElement(Request, Object.assign({ actions: props.requestActions, key: request.id, onClickAction: clickActionHandler }, request)))))),
            renderNewRequestSection(props, showRequestFormHandler))));
};
export default connector(RequestGroup);
