import styled from "styled-components";
import { Button, Color } from "@boomq/uikit";
import { TEST_PROJECT_LEFT_SIDEBAR_MARGIN_LEFT, TEST_PROJECT_LEFT_SIDEBAR_WIDTH } from "../TestProjectLeftSidebar";
import { NewItemButton, NewItem } from "../../NewItemButton";
import { media } from "../../../styled/response";
import { TestSettingsActionsPanel } from "../../../styled/TestSettings";
import { StyledTitle3 } from "../../../styled/Titles";
import { WRAPPER_PADDING_LEFT_RIGHT } from "../../../styled/Wrappers";
const ACTIONS_PANEL_EXCLUDED_WIDTH = TEST_PROJECT_LEFT_SIDEBAR_MARGIN_LEFT + TEST_PROJECT_LEFT_SIDEBAR_WIDTH + 2 * WRAPPER_PADDING_LEFT_RIGHT;
export const groupListStyle = { width: "100%" };
export const profileDistributionPanelTheme = {
    marginTop: "16px"
};
export const AutoPercentagesSection = styled.div `
    align-items: center;
    display: flex;
    flex: auto;
    justify-content: flex-end;
    position: relative;
    &:first-child {
        margin-top: 0;
    }
    ${media.tablet} {
        flex-direction: column;
        align-items: flex-start;
    }
`;
export const NewThreadGroupButton = styled(NewItemButton) `
    ${NewItem} {
        background: ${Color.lightGrey98};
    }
    margin-bottom: 100px;
    margin-top: 30px;
`;
export const ProfileSettingsBlock = styled.div `
    align-items: center;
    display: flex;
    flex-direction: column;
`;
export const StyledButtonWithMargin = styled(Button) `
    margin-right: 16px;
    position: relative;
`;
export const Text = styled.p `
    color: ${Color.blue};
    font-size: 16px;
    font-weight: 600;
    margin: 0 5px;
    line-height: normal;
`;
export const ThreadGroupList = styled.ul `
    display: flex;
    flex-direction: column;
`;
export const JMXTestProfileSection = styled(ThreadGroupList) `
    margin-top: 16px;
    padding-bottom: 100px;
`;
export const TooltipEl = styled.div `
    cursor: help;
    height: 100%;
    left: 0;
    pointer-events: auto;
    position: absolute;
    top: 0;
    width: 100%;
`;
export const StyledAccountSectionTitle = styled(StyledTitle3) `
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    width: 100%;
`;
export const StyledTestSettingsActionsPanel = styled(TestSettingsActionsPanel) `
    position: fixed;
    width: calc(100% - ${ACTIONS_PANEL_EXCLUDED_WIDTH}px);
`;
