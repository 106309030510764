var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { forwardRef, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { FormattedMessage } from "react-intl";
import { isEmpty, useIntl } from "@boomq/utils";
import { withError } from "@boomq/uikit";
import { StyledInput } from "../../styled/Input";
const isExistUnit = (unit) => unit && unit.text;
const getNumberValue = (value) => (isNaN(value) ? 0 : Number(value));
const InputNumber = forwardRef((_a, _ref) => {
    var { decimalCount, enabled, disabledStyle, key, inputTheme, max, min, hideError, isError, onBlur, onBlurFormatFunc, onChange, onFocus, onKeyDown, isPositive, placeholder, placeholderTheme, showError, theme, value, unit } = _a, rest = __rest(_a, ["decimalCount", "enabled", "disabledStyle", "key", "inputTheme", "max", "min", "hideError", "isError", "onBlur", "onBlurFormatFunc", "onChange", "onFocus", "onKeyDown", "isPositive", "placeholder", "placeholderTheme", "showError", "theme", "value", "unit"]);
    const { formatMessage } = useIntl();
    const [inputValue, setInputValue] = useState(getNumberValue(value));
    useEffect(() => {
        setInputValue(getNumberValue(value));
    }, [value]);
    const validateBlurValue = (value) => {
        const formattedValue = onBlurFormatFunc(value);
        setInputValue(formattedValue);
        return formattedValue;
    };
    const blurValueHandler = () => {
        const value = typeof onBlurFormatFunc === "function" ? validateBlurValue(inputValue) : inputValue;
        typeof hideError === "function" && hideError();
        typeof onBlur === "function" && onBlur(getNumberValue(value));
    };
    const changeValueHandler = (e) => {
        let newValue = e.target.value.replace && e.target.value.replace(",", ".");
        newValue = !isNaN(newValue) ? newValue : inputValue;
        isPositive && (newValue = newValue >= 0 ? newValue : -newValue);
        !isEmpty(max) && (newValue = Number(newValue) > max ? max : newValue);
        !isEmpty(min) && (newValue = Number(newValue) < min ? min : newValue);
        const decimalPosition = newValue.indexOf && newValue.indexOf(".");
        !isEmpty(decimalCount) &&
            decimalPosition > -1 &&
            newValue.substring &&
            (newValue = newValue.substring(0, decimalPosition + decimalCount + 1));
        newValue !== inputValue && setInputValue(newValue);
        typeof onChange === "function" && onChange(getNumberValue(newValue));
    };
    const focusHandler = (e) => {
        typeof showError === "function" && showError(e);
        typeof onFocus === "function" && onFocus(inputValue, e);
    };
    const keyDownHandler = (e) => typeof onKeyDown === "function" ? onKeyDown(e, inputValue) : undefined;
    return (React.createElement(StyledInputNumber, { disabled: !enabled, disabledStyle: disabledStyle, isError: isError, isExistUnit: isExistUnit(unit), inputTheme: inputTheme, theme: theme },
        React.createElement(StyledInput, Object.assign({ disabled: !enabled, disabledStyle: disabledStyle, onBlur: blurValueHandler, onChange: changeValueHandler, onFocus: focusHandler, onKeyDown: keyDownHandler, placeholder: placeholder, ref: _ref, type: "text", value: inputValue }, rest)),
        isExistUnit(unit) && (React.createElement(Unit, { title: formatMessage(Object.assign({}, unit.title)) },
            React.createElement(FormattedMessage, Object.assign({}, unit.text))))));
});
const StyledInputNumber = styled.div `
    border: 1px solid ${({ isError }) => (isError ? "#eb5757" : "#d6d6d6")};
    border-radius: 30px;
    padding-right: ${({ isExistUnit }) => (isExistUnit ? "45px" : "0px")};
    position: relative;
    width: 100%;
    > input {
        border: none;
        height: 100%;
        &:focus {
            box-shadow: none;
        }
        ${({ isExistUnit }) => isExistUnit
    ? css `
                      padding-right: 0;
                  `
    : css ``};
        ${(props) => (props && props.inputTheme ? css(props.inputTheme) : "")}
    }
    &:focus {
        box-shadow: 0 0 15px rgba(0, 55, 120, 0.3);
    }
    ${({ disabled, disabledStyle }) => (disabled && disabledStyle ? css(disabledStyle) : ``)}
    ${(props) => (props && props.theme ? css(props.theme) : "")}
`;
const Unit = styled.div `
    align-items: center;
    color: #91b0cf;
    cursor: pointer;
    display: flex;
    height: 100%;
    position: absolute;
    right: 10px;
    top: 0;
`;
export default withError(InputNumber);
