import styled from "styled-components";
import { Color, FontBodyMediumMedium } from "@boomq/uikit";
import { NewItemBlock } from "../../account/NewItemBlock";
export const AllTestsBlockTitle = styled.h2 `
    ${FontBodyMediumMedium}
    align-items: center;
    color: ${Color.darkGrey35};
    display: flex;
    justify-content: space-between;
`;
export const CompareButtonSection = styled.div `
    align-items: center;
    display: flex;
    justify-content: flex-end;
`;
export const NewTest = styled(NewItemBlock) `
    margin-bottom: 10px;
    margin-top: 16px;
`;
