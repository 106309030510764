import React from "react";
import { FormattedMessage } from "react-intl";
import { InputText, Radio, Switcher } from "@boomq/uikit";
import { FIELD_DELIMITER_LABEL_TEXT, HAS_HEADERS_LABEL_TEXT, INDEPENDENT_LIST_PER_THREAD_LABEL_TEXT, ORDER_RANDOM_LABEL_TEXT, ORDER_SEQUENTIAL_LABEL_TEXT, ORDER_TITLE_TEXT, PREVIEW_TITLE_TEXT, RECYCLE_LABEL_TEXT } from "./intl";
import { FileContentSection, InputSection, Setting, SettingsLeftSection, SettingsRightSection, SettingsSection, RadioButton, SwitcherText, Text } from "./styled";
import { StyledLabel } from "../../styled/Input";
import { ParameterCsvPreviewTable } from "../ParameterCsvPreviewTable";
export const ParameterCsvSettings = ({ delimiter, fileContent, hasHeaders, id, independentListPerThread, onChangeCsvHeader, onChangeParam, parameterNames, randomOrder, recycle, usedRequestParameterNames }) => {
    const changeHandler = (name, value) => onChangeParam({ id, name, value });
    const changeDelimiterHandler = (value) => changeHandler("delimiter", value);
    const changeHasHeadersHandler = (e) => changeHandler(e.target.name, e.target.checked);
    const changeHeaderHandler = (index, value) => onChangeCsvHeader({ id, index, value });
    const changeRecycleHandler = (e) => changeHandler(e.target.name, e.target.checked);
    const changeIndependentListPerThreadHandler = (e) => changeHandler(e.target.name, e.target.checked);
    const clickRandomOrderHandler = () => changeHandler("randomOrder", true);
    const clickSequentialOrderHandler = () => changeHandler("randomOrder", false);
    return (React.createElement(SettingsSection, null,
        React.createElement(SettingsLeftSection, null,
            React.createElement(StyledLabel, null,
                React.createElement(Text, null,
                    React.createElement(FormattedMessage, Object.assign({}, FIELD_DELIMITER_LABEL_TEXT))),
                React.createElement(InputSection, null,
                    React.createElement(InputText, { name: "delimiter", onChange: changeDelimiterHandler, value: delimiter }))),
            React.createElement(Setting, null,
                React.createElement(Switcher, { defaultChecked: hasHeaders, name: "hasHeaders", onChange: changeHasHeadersHandler }),
                React.createElement(SwitcherText, null,
                    React.createElement(FormattedMessage, Object.assign({}, HAS_HEADERS_LABEL_TEXT)))),
            React.createElement(Setting, null,
                React.createElement(Switcher, { defaultChecked: recycle, name: "recycle", onChange: changeRecycleHandler }),
                React.createElement(SwitcherText, null,
                    React.createElement(FormattedMessage, Object.assign({}, RECYCLE_LABEL_TEXT)))),
            React.createElement(Setting, null,
                React.createElement(Switcher, { defaultChecked: independentListPerThread, name: "independentListPerThread", onChange: changeIndependentListPerThreadHandler }),
                React.createElement(SwitcherText, null,
                    React.createElement(FormattedMessage, Object.assign({}, INDEPENDENT_LIST_PER_THREAD_LABEL_TEXT)))),
            React.createElement(Setting, null,
                React.createElement(Text, null,
                    React.createElement(FormattedMessage, Object.assign({}, ORDER_TITLE_TEXT))),
                React.createElement(RadioButton, null,
                    React.createElement(Radio, { name: `randomOrder_${id}`, checked: randomOrder, onClick: clickRandomOrderHandler },
                        React.createElement(FormattedMessage, Object.assign({}, ORDER_RANDOM_LABEL_TEXT)))),
                React.createElement(RadioButton, null,
                    React.createElement(Radio, { name: `randomOrder_${id}`, checked: !randomOrder, onClick: clickSequentialOrderHandler },
                        React.createElement(FormattedMessage, Object.assign({}, ORDER_SEQUENTIAL_LABEL_TEXT)))))),
        React.createElement(SettingsRightSection, null,
            React.createElement(FileContentSection, null,
                React.createElement(Text, null,
                    React.createElement(FormattedMessage, Object.assign({}, PREVIEW_TITLE_TEXT))),
                React.createElement(ParameterCsvPreviewTable, { fileContent: fileContent, hasHeaders: hasHeaders, onChangeParameterName: changeHeaderHandler, parameterNames: parameterNames, usedRequestParameterNames: usedRequestParameterNames })))));
};
