import { getContrastColorByIndex, isEmpty } from "@boomq/utils";
import { getContrastTextColorByColor } from "../color";
export const createLabel = (label, labelsCount) => ({
    color: getContrastColorByIndex(labelsCount),
    label
});
const formatLabelForDto = ({ color, label }) => ({ color, label });
export const formatLabelsForDto = (labels) => labels.map(formatLabelForDto);
export const generateApiFilterByLabels = (labelNames) => isEmpty(labelNames) ? "" : `&${labelNames.map((labelName) => `label=${labelName}`).join("&")}`;
export const getLabelByName = (labelSet, labelName) => labelSet.find((labelItem) => labelItem.label === labelName);
export const getLabelSetNames = (labelSet = []) => (labelSet || []).map((labelSetItem) => labelSetItem.label);
const prepareLabel = (labelSetItem) => (Object.assign(Object.assign({}, labelSetItem), { id: labelSetItem.label, name: labelSetItem.label, textColor: getContrastTextColorByColor(labelSetItem.color) }));
export const prepareLabels = (labelSet = []) => (labelSet || []).map(prepareLabel);
export const addLabelToCurrentLabelSet = (labelSet, labelItem) => {
    const labelSetNames = getLabelSetNames(labelSet);
    return labelSetNames.includes(labelItem.label) ? labelSet : [...labelSet, labelItem];
};
