export const ELEMENTS_BLOCK_TITLE = {
    defaultMessage: "Elements",
    id: "account.request.group.editor.elements.dnd.title"
};
export const FLOW_CONTROL_ELEMENTS_BLOCK_TITLE = {
    defaultMessage: "Flow control",
    id: "account.request.group.editor.elements.flow.control.title"
};
export const IMPORT_DATA_ELEMENTS_BLOCK_TITLE = {
    defaultMessage: "Import data",
    id: "account.request.group.editor.elements.import.data.title"
};
