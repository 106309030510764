export var MenuItemType;
(function (MenuItemType) {
    MenuItemType["Custom"] = "CUSTOM";
    MenuItemType["Dropdown"] = "DROPDOWN";
    MenuItemType["Single"] = "SINGLE";
})(MenuItemType || (MenuItemType = {}));
export var MenuLinkType;
(function (MenuLinkType) {
    MenuLinkType["External"] = "EXTERNAL";
    MenuLinkType["Nav"] = "NAV";
    MenuLinkType["None"] = "NONE";
})(MenuLinkType || (MenuLinkType = {}));
