import { handleActions } from "redux-actions";
import { showResourcePlate, hideResourcePlate, resourcesFailure, resourcesRequest, resourcesSuccess, addResourceSuccess, changeResourceSuccess, deleteResourceSuccess } from "../../actions/admin/resources";
import { isEmpty } from "../../../helpers/utils";
const initialState = {
    currentPage: 0,
    error: null,
    isFetching: false,
    isPlateVisible: false,
    resources: [],
    totalPages: 0
};
const getValues = ({ currentPage, resources, totalPages }) => isEmpty(resources)
    ? initialState
    : {
        currentPage,
        resources,
        totalPages
    };
export default handleActions({
    [showResourcePlate]: (state) => (Object.assign(Object.assign({}, state), { isPlateVisible: true })),
    [hideResourcePlate]: (state) => (Object.assign(Object.assign({}, state), { isPlateVisible: false })),
    [resourcesRequest]: (state) => (Object.assign(Object.assign({}, state), { isFetching: true, resources: [] })),
    [resourcesSuccess]: (state, action) => (Object.assign(Object.assign(Object.assign({}, state), { isFetching: false }), getValues(action.payload || {}))),
    [resourcesFailure]: (state, action) => (Object.assign(Object.assign({}, state), { isFetching: false, error: action.payload })),
    [addResourceSuccess]: (state, action) => (Object.assign(Object.assign({}, state), { resources: action.payload })),
    [changeResourceSuccess]: (state, action) => (Object.assign(Object.assign({}, state), { resources: state.resources.map((resource) => {
            if (resource.id === action.payload.id) {
                return Object.assign(Object.assign({}, resource), action.payload.params);
            }
            return resource;
        }) })),
    [deleteResourceSuccess]: (state, action) => (Object.assign(Object.assign({}, state), { resources: state.resources.filter((resource) => resource.id !== action.payload.id) }))
}, initialState);
export const getListOfResources = (state) => state.resources.resources;
export const getResourcesLoading = (state) => state.resources.isFetching;
export const getPlateVisibility = (state) => state.resources.isPlateVisible;
