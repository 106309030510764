import styled, { css } from "styled-components";
import { getZIndex } from "../../styled/global";
export const Bar = styled.div `
    background: linear-gradient(90deg, #55b7ff 0%, #176dd5 53.41%);
    box-shadow: 0 0 8px rgba(0, 64, 140, 0.15);
    height: 6px;
    left: 0;
    position: absolute;
    top: 0;
    transition: all 2s linear;
    width: 0;
    ${({ progress }) => progress
    ? css `
                  width: ${progress};
              `
    : css ``}
`;
export const ProgressBarWrapper = styled.div `
    background: #dadada;
    border-radius: 5px 5px 0 0;
    box-shadow: 0 0 8px rgba(0, 64, 140, 0.15);
    height: 6px;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    z-index: ${getZIndex("TestRunProgressBar")};
`;
