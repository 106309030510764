export const TIMELINE_END_MESSAGE = {
    defaultMessage: "End",
    id: "account.sla.form.timeline.end.placeholder"
};
export const TIMELINE_RANGE_SLIDER_LABEL = {
    defaultMessage: "Time(sec)",
    id: "account.sla.form.timeline.range.slider.label"
};
export const TIMELINE_START_MESSAGE = {
    defaultMessage: "Start",
    id: "account.sla.form.timeline.start.placeholder"
};
