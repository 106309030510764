import { handleActions, combineActions } from "redux-actions";
import authorizeActions from "../actions/authorize";
import { clearLoginError, loginRequest, loginSuccess, loginFailed } from "../actions/login";
import { resetError } from "../actions/error";
const { logout } = authorizeActions;
export const initialState = {
    data: [],
    error: ""
};
export default handleActions({
    [clearLoginError]: (state) => (Object.assign(Object.assign({}, state), { error: "" })),
    [combineActions(loginRequest, logout)]: () => (Object.assign({}, initialState)),
    [loginSuccess]: (state, action) => (Object.assign(Object.assign({}, state), { data: action.payload })),
    [loginFailed]: (state, action) => (Object.assign(Object.assign({}, state), { error: action.payload.code })),
    [resetError]: (state) => (Object.assign(Object.assign({}, state), { error: initialState.error }))
}, initialState);
export const getErrorCode = (state) => state && state.login ? state.login.error : initialState.error;
