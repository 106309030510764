var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { useIntl } from "@boomq/utils";
import { InputNumber } from "@boomq/uikit";
import { DEADLINE_INPUT_PLACEHOLDER, DEADLINE_LABEL } from "./intl";
import { deadlineInputTheme, DeadlineRow, DeadlineTitle } from "./styled";
import { getTimerInputUnit, getTimerListWithDefaultTimer } from "../../../../helpers/requestTimers";
import { RequestTimers } from "../../../../components/account/TestProject/RequestTimers";
import { withResize } from "../../../../components/styled/response";
import { newRequestAddTimer, newRequestChangeParamsField, newRequestChangeTimer, newRequestDeleteFieldParam } from "../../../../redux/actions/newRequest";
import { getNewRequestField, getNewRequestTimerList } from "../../../../redux/reducers/newRequest";
import { getDefaultTimerSettings } from "../../../../redux/reducers/newTest";
const getTimerList = (state) => {
    const timerList = getNewRequestTimerList(state) || [];
    const defaultTimerSettings = getDefaultTimerSettings(state);
    return getTimerListWithDefaultTimer(timerList, defaultTimerSettings);
};
const connector = connect((state) => ({
    deadline: getNewRequestField(state, "deadline"),
    timerList: getTimerList(state)
}), {
    onAdd: newRequestAddTimer,
    onChange: newRequestChangeTimer,
    onChangeDeadline: newRequestChangeParamsField,
    onDelete: newRequestDeleteFieldParam
});
const RequestGrpcTimers = (_a) => {
    var { deadline, onChangeDeadline } = _a, restProps = __rest(_a, ["deadline", "onChangeDeadline"]);
    const { formatMessage } = useIntl();
    const deadlineLabel = formatMessage(DEADLINE_LABEL);
    const deadlineInputPlaceholder = formatMessage(DEADLINE_INPUT_PLACEHOLDER);
    const changeHandler = (value) => onChangeDeadline({ field: "deadline", value });
    return (React.createElement(React.Fragment, null,
        React.createElement(DeadlineRow, null,
            React.createElement(DeadlineTitle, null, deadlineLabel),
            React.createElement(InputNumber, { decimalCount: 2, isPositive: true, name: "deadline", onBlur: changeHandler, placeholder: deadlineInputPlaceholder, theme: deadlineInputTheme, unit: getTimerInputUnit(), value: deadline })),
        React.createElement(RequestTimers, Object.assign({}, restProps))));
};
export default withResize(withRouter(connector(RequestGrpcTimers)));
