import styled from "styled-components";
import { Button } from "@boomq/uikit";
export const ButtonsBlock = styled.div `
    display: flex;
`;
export const ButtonCancel = styled(Button) `
    margin-left: 12px;
`;
export const TeamMembersItemInputsBlock = styled.div `
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 16px;
`;
