import styled, { css } from "styled-components";
import { withDisable } from "../../styled/global";
import { media } from "../../styled/response";
export const customSelectTheme = { width: "100%" };
export const LoadingStation = styled.div `
    ${withDisable};
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin-right: 50px;
    max-width: 200px;
    paddingBottom: 0;
    position: relative;
    ${media.tablet} {
        flex-direction: column;
    }
    ${({ theme }) => (theme ? css(theme) : css ``)}
`;
