import styled from "styled-components";
import { Link } from "react-router-dom";
import { withShadow } from "@boomq/uikit";
export const LogoWrapper = styled(Link) `
    width: 50px;
    transition: all 0.1s ease-in-out;
    &:active {
        transform: scale(0.9);
    }
`;
export const Logo = styled.img `
    width: 100%;
    height: auto;
`;
export const StyledHeader = styled.header `
    ${withShadow}
    align-items: center;
    border-radius: 0 0 5px 5px;
    border-bottom: 1px solid rgb(0, 64, 140, 0.3);
    display: flex;
    justify-content: space-between;
    min-height: 64px;
    padding: 0 34px;
`;
export const Buttons = styled.div `
    display: flex;
    flex: 0 0 auto;
`;
export const LogoutButton = styled.button `
    outline: none;
    border: none;
    padding: 10px 20px;
    background: #0663a3;
    margin-left: 20px;
    color: #ffffff;
    font-size: 16px;
    border-radius: 5px;
    &:first-child {
        margin-left: 0;
    }
`;
export const LKButton = LogoutButton.withComponent(Link);
