import { call, put, select, takeEvery } from "redux-saga/effects";
import { push } from "connected-react-router";
import { excludeArrayFromArray } from "@boomq/utils";
import { addReportLabelFailure, addReportLabelRequest, addReportLabelWithCreatingRequest, deleteReportLabelFailure, deleteReportLabelRequest, editReport, reportsFailure, reportsRequest, reportsSuccess, searchReportsLabelsByName, setFilterLabels, setLabelList, setReportsSearchedLabels } from "../actions/reports";
import { getCurrentPage, getReports, getReportsFilterLabels, getReportsLabelList, getReportsPageSize, getReportsSortData } from "../reducers/reports";
import { getResponseErrorData } from "../../helpers/errors";
import { addLabelToCurrentLabelSet, createLabel, getLabelByName, prepareLabels } from "../../helpers/labels";
import { getReportsRoute } from "../../helpers/routes";
import { apiGetReports, apiGetReportsLabelsByQuery, apiUpdateReport } from "../../services/reportSrv";
function* editReportFlow(action) {
    var _a;
    try {
        const id = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.id;
        yield put(push({
            pathname: `${getReportsRoute()}/${id}`
        }));
    }
    catch (e) {
        console.error(e);
    }
}
function* getReportsData() {
    const currentPage = yield select(getCurrentPage);
    const sortData = yield select(getReportsSortData);
    const size = yield select(getReportsPageSize);
    const params = {
        pagination: {
            pageNumber: currentPage,
            pageSize: size
        },
        sort: sortData
    };
    return yield call(apiGetReports, params);
}
function* reportListFlow() {
    try {
        const { data } = yield* getReportsData();
        yield put(reportsSuccess(data));
    }
    catch (e) {
        yield put(reportsFailure(getResponseErrorData(e)));
    }
}
function* getReportFromListById(id) {
    const reports = yield select(getReports);
    const report = reports.find((settingsSet) => settingsSet.id === id);
    return report ? report : {};
}
function* addReportLabelFlow(action) {
    try {
        const { id, label } = action && action.payload ? action.payload : {};
        const labelList = yield select(getReportsLabelList);
        const reportParams = yield* getReportFromListById(id);
        const reportWithLabel = Object.assign(Object.assign({}, reportParams), { labelSet: addLabelToCurrentLabelSet(reportParams.labelSet, label) });
        yield call(apiUpdateReport, id, reportWithLabel);
        yield put(setLabelList(prepareLabels(addLabelToCurrentLabelSet(labelList, label))));
        yield* reportListFlow();
    }
    catch (e) {
        yield put(addReportLabelFailure(getResponseErrorData(e)));
    }
}
function* addReportLabelWithCreatingFlow(action) {
    const { id, name } = action && action.payload ? action.payload : {};
    const labelList = yield select(getReportsLabelList);
    const existedLabel = getLabelByName(labelList, name);
    const newLabel = existedLabel ? existedLabel : createLabel(name, labelList.length);
    yield* addReportLabelFlow({ payload: { id, label: newLabel } });
}
function* updateReportsLabels() {
    const { data: labelList } = yield call(apiGetReportsLabelsByQuery, "");
    yield put(setLabelList(prepareLabels(labelList)));
}
function* updateReportsFilterLabelsByLabelList() {
    const filterLabels = yield select(getReportsFilterLabels);
    const labelList = yield select(getReportsLabelList);
    const labels = labelList.map((labelItem) => labelItem.label);
    const filterLabelsByLabelList = filterLabels.filter((filterLabelItem) => labels.includes(filterLabelItem.label));
    yield put(setFilterLabels(filterLabelsByLabelList));
}
function* deleteReportLabelFlow(action) {
    try {
        const { id, label } = action && action.payload ? action.payload : {};
        const report = yield* getReportFromListById(id);
        const reportWithoutLabel = Object.assign(Object.assign({}, report), { labelSet: report.labelSet.filter((labelSetItem) => labelSetItem.label !== label) });
        yield call(apiUpdateReport, id, reportWithoutLabel);
        yield* updateReportsLabels();
        yield* updateReportsFilterLabelsByLabelList();
        yield* reportListFlow();
    }
    catch (e) {
        yield put(deleteReportLabelFailure(getResponseErrorData(e)));
    }
}
function* labelsSearchingFlow(action) {
    const { id, query } = action && action.payload ? action.payload : {};
    const { labelSet = [] } = yield* getReportFromListById(id);
    try {
        const { data } = yield call(apiGetReportsLabelsByQuery, query);
        const searchedLabels = prepareLabels(excludeArrayFromArray(data, labelSet));
        yield put(setReportsSearchedLabels({ id, searchedLabels }));
    }
    catch (e) {
        yield put(setReportsSearchedLabels({ id, searchedLabels: [] }));
    }
}
export function* reportsFlow() {
    yield takeEvery(reportsRequest, reportListFlow);
    yield takeEvery(editReport, editReportFlow);
    yield takeEvery(addReportLabelRequest, addReportLabelFlow);
    yield takeEvery(addReportLabelWithCreatingRequest, addReportLabelWithCreatingFlow);
    yield takeEvery(deleteReportLabelRequest, deleteReportLabelFlow);
    yield takeEvery(searchReportsLabelsByName, labelsSearchingFlow);
}
