var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { handleActions } from "redux-actions";
import { getValueByPath, isEmpty } from "@boomq/utils";
import { importDataToTestProjectSuccess, importJmxFileSuccess } from "../../actions/importTestProjectData";
import newTestActions from "../../actions/newTest";
import { testProjectVersionDataSuccess } from "../../actions/testDetails";
import { changeDefaultCookies, changeDefaultHeaders } from "../../actions/testProjectDefaultSettings";
import { saveAsTestProjectSuccess, setTestProjectVersionName, testProjectSuccess, testProjectDetailsSuccess } from "../../actions/testProjects";
import { linkSettingsSetToNewTest, linkSettingsSetToTestProjectSuccess, unlinkSettingsSetForNewTest, unlinkSettingsSetForTestProjectSuccess } from "../../actions/testProjectsSettingsSetSidebar";
import { getLoadTypeByTestType } from "../../../data/content/testTypeMessages";
import { generatePreviewData } from "../../../helpers/generators/chart";
import { TestType } from "../../../helpers/types/test";
import { initScalabilitySettings, initSettings, initialTestProjectSettings, ProfileSettingsMode } from "../../../models/testProject";
const { changeRequests, changePreviewThreadGroupsChartField, changeDistribution, changeJmxLoadProfile, changeJmxPreviewChartField, changeRequestGroup, changeRequestGroups, changeThreadGroupPercent, clearNewTestSettings, saveTestProjectSuccess, deleteGroups, deleteRequestInGroup, editRequestGroup, newTestNewProjectName, removeGrpcProtoFile, selectProfile, setStep, setTestProjectElementsData, startTestRequest, startTestSuccess, testProjectChangeLoadProfileParams, testProjectChangeLoadType, testProjectChangeParamsData, testProjectChangeParamsField, threadGroupChangeParamsField, threadGroupChangeLoadProfileParams, toggleActiveGroups, toggleEnabledGroup, toggleGroups, toggleLabelsFromProjectCopying, toggleLoadProfileSettings, uploadGrpcProtoFileSuccess } = newTestActions;
const getPayloadValue = (action, paramName, defaultValue) => action && action.payload && action.payload[paramName] ? action.payload[paramName] : defaultValue;
export default handleActions({
    [changeRequests]: (state, action) => Array.isArray(action.payload) ? Object.assign(Object.assign({}, state), { requests: action.payload, isModified: true }) : state,
    [changePreviewThreadGroupsChartField]: (state, action) => {
        const { field, value } = action && action.payload ? action.payload : {};
        return isEmpty(field)
            ? state
            : Object.assign(Object.assign({}, state), { previewThreadGroupsChart: Object.assign(Object.assign({}, state.previewThreadGroupsChart), { [field]: value }), isModified: true });
    },
    [changeDefaultCookies]: (state, action) => action && action.payload
        ? Object.assign(Object.assign({}, state), { defaultCookies: action.payload }) : state,
    [changeDefaultHeaders]: (state, action) => action && Array.isArray(action.payload)
        ? Object.assign(Object.assign({}, state), { defaultHeaders: action.payload }) : state,
    [changeDistribution]: (state, action) => {
        const { groups } = state.groups.reduce((res, group) => !res.isExistExpandedFirstEnabledGroup && group.enabled
            ? {
                groups: [...res.groups, Object.assign(Object.assign({}, group), { isExpanded: true })],
                isExistExpandedFirstEnabledGroup: true
            }
            : Object.assign(Object.assign({}, res), { groups: [...res.groups, group] }), { groups: [], isExistExpandedFirstEnabledGroup: false });
        return Object.assign(Object.assign({}, state), { profileDistribution: action.payload ? action.payload : initialTestProjectSettings.profileDistribution, groups });
    },
    [changeJmxPreviewChartField]: (state, action) => {
        const { field, value } = getValueByPath(action, "payload", {});
        return Object.assign(Object.assign({}, state), { previewJMXChart: Object.assign(Object.assign({}, state.previewJMXChart), { [field]: value }) });
    },
    [changeJmxLoadProfile]: (state, action) => {
        const { groupId, loadProfile } = action && action.payload ? action.payload : {};
        return groupId
            ? Object.assign(Object.assign({}, state), { JMXProfiles: Object.assign(Object.assign({}, state.JMXProfiles), { [groupId]: loadProfile }) }) : state;
    },
    [changeRequestGroup]: (state, action) => {
        const { id, field, value } = action && action.payload ? action.payload : {};
        return id && field
            ? Object.assign(Object.assign({}, state), { groups: [
                    ...state.groups.map((group) => (group.id === id ? Object.assign(Object.assign({}, group), { [field]: value }) : group))
                ], isModified: true }) : state;
    },
    [changeRequestGroups]: (state, action) => Array.isArray(action.payload) ? Object.assign(Object.assign({}, state), { groups: action.payload, isModified: true }) : state,
    [changeThreadGroupPercent]: (state, action) => {
        const { id, value } = action && action.payload ? action.payload : {};
        return id
            ? Object.assign(Object.assign({}, state), { autoPercentages: false, groups: [...state.groups.map((group) => (group.id === id ? Object.assign(Object.assign({}, group), { perc: value }) : group))], isModified: true }) : state;
    },
    [clearNewTestSettings]: () => (Object.assign({}, initialTestProjectSettings)),
    [saveTestProjectSuccess]: (state, action) => (Object.assign(Object.assign({}, state), { currentVersion: getPayloadValue(action, "currentVersion", ""), isModified: false, isSaved: true, projectId: getPayloadValue(action, "projectId", null), serializedData: getPayloadValue(action, "serializedData", ""), versions: getPayloadValue(action, "versions", []) })),
    [setTestProjectElementsData]: (state, { payload }) => (Object.assign(Object.assign({}, state), { elementsData: payload })),
    [deleteGroups]: (state) => (Object.assign(Object.assign({}, state), { groups: initialTestProjectSettings.groups, requests: initialTestProjectSettings.requests, testType: TestType.Stable, isModified: true, isSaved: false })),
    [deleteRequestInGroup]: (state, { payload }) => payload && payload.id
        ? Object.assign(Object.assign({}, state), { requests: state.requests.filter((request) => request.id !== payload.id), isModified: true }) : state,
    [editRequestGroup]: (state) => (Object.assign(Object.assign({}, state), { profileSettingsMode: ProfileSettingsMode.ThreadGroupEditor })),
    [importDataToTestProjectSuccess]: (state, { payload }) => {
        const { sourceType, testType } = payload || {};
        return Object.assign(Object.assign({}, state), { sourceType, testType });
    },
    [importJmxFileSuccess]: (state, action) => (Object.assign(Object.assign({}, state), { JMXProfiles: action && action.payload ? action.payload : initialTestProjectSettings.JMXProfiles, testType: TestType.JMX })),
    [linkSettingsSetToNewTest]: (state, action) => (Object.assign(Object.assign({}, state), { settingsId: action ? action.payload : undefined })),
    [linkSettingsSetToTestProjectSuccess]: (state, action) => {
        const { projectId, settingsId } = action.payload ? action.payload : {};
        return Object.assign(Object.assign({}, state), { settingsId: state.projectId === projectId ? settingsId : state.settingsId });
    },
    [selectProfile]: (state, action) => (Object.assign(Object.assign(Object.assign({}, state), action.payload), { step: 2, isModified: true, isSaved: false })),
    [setStep]: (state, action) => (Number.isInteger(action.payload) ? Object.assign(Object.assign({}, state), { step: action.payload }) : state),
    [newTestNewProjectName]: (state, { payload: { projectName } }) => (Object.assign(Object.assign({}, state), { projectName })),
    [removeGrpcProtoFile]: (state, { payload }) => (Object.assign(Object.assign({}, state), { grpcBuffer: state.grpcBuffer.filter((file) => file.id !== payload) })),
    [saveAsTestProjectSuccess]: (state, action) => (Object.assign(Object.assign({}, state), { isSavedAs: true, savedProjectId: action && action.payload ? action.payload.id : null })),
    [setTestProjectVersionName]: (state, { payload: { id, value } }) => (Object.assign(Object.assign({}, state), { versions: state.versions.map((version) => version.id === id ? Object.assign(Object.assign({}, version), { name: value, title: value }) : version) })),
    [startTestRequest]: (state, action) => (Object.assign(Object.assign(Object.assign({}, state), action.payload), { isSaved: false })),
    [startTestSuccess]: (state) => (Object.assign(Object.assign({}, state), initialTestProjectSettings)),
    [testProjectChangeParamsData]: (state, { payload }) => (isEmpty(payload) ? state : Object.assign(Object.assign({}, state), payload)),
    [testProjectChangeParamsField]: (state, { payload }) => {
        const { field, subfield, value, isModified = false, isSaved = false } = payload || {};
        const isTestSettings = Object.keys(Object.assign(Object.assign({}, initSettings), initScalabilitySettings)).includes(field);
        const newState = isTestSettings
            ? Object.assign(Object.assign({}, state), { [state.loadType.name]: Object.assign(Object.assign({}, state[state.loadType.name]), { [field]: value }), isModified,
                isSaved }) : Object.assign(Object.assign({}, state), { isModified, isSaved, [field]: value });
        field && subfield && (newState[field] = Object.assign(Object.assign({}, state[field]), { [subfield]: value }));
        return field ? newState : state;
    },
    [testProjectChangeLoadProfileParams]: (state, { payload }) => {
        const { field, value } = payload || {};
        const loadTypeName = state.loadType.name;
        return !isEmpty(payload)
            ? Object.assign(Object.assign({}, state), { [loadTypeName]: Object.assign(Object.assign({}, state[loadTypeName]), { [field]: value }), isModified: true }) : state;
    },
    [testProjectChangeLoadType]: (state, { payload }) => (Object.assign(Object.assign({}, state), { isModified: true, loadType: isEmpty(payload) ? state.loadType : payload, testType: isEmpty(payload) ? state.testType : payload.name })),
    [testProjectVersionDataSuccess]: (state, action) => (Object.assign(Object.assign({}, state), action.payload)),
    [testProjectSuccess]: (state, action) => {
        const { comment, projectName, projectId } = action.payload || {};
        return Object.assign(Object.assign({}, initialTestProjectSettings), { chartData: [
                {
                    coords: generatePreviewData(Object.assign({}, initSettings))
                }
            ], comment: comment || initialTestProjectSettings.comment, projectName: projectName || "", projectId: projectId || initialTestProjectSettings.projectId });
    },
    [testProjectDetailsSuccess]: (state, action) => {
        const _a = action.payload || {}, { comment, groups, isModified = false, projectId, projectName, requests, requestParameters, serializedData, step, testType = TestType.Stable } = _a, restParams = __rest(_a, ["comment", "groups", "isModified", "projectId", "projectName", "requests", "requestParameters", "serializedData", "step", "testType"]);
        return Object.assign(Object.assign(Object.assign({}, initialTestProjectSettings), restParams), { comment: comment || initialTestProjectSettings.comment, groups: groups || initialTestProjectSettings.groups, isModified, loadType: getLoadTypeByTestType(testType), projectId: projectId || initialTestProjectSettings.projectId, projectName: projectName || "", requests: requests || initialTestProjectSettings.requests, requestParameters: requestParameters || initialTestProjectSettings.requestParameters, serializedData: serializedData || initialTestProjectSettings.serializedData, step: step || initialTestProjectSettings.step, testType: testType || initialTestProjectSettings.testType });
    },
    [threadGroupChangeParamsField]: (state, { payload }) => {
        const { field, groupId, value } = payload || {};
        return Object.assign(Object.assign({}, state), { groups: state.groups.map((group) => (group.id === groupId ? Object.assign(Object.assign({}, group), { [field]: value }) : group)) });
    },
    [threadGroupChangeLoadProfileParams]: (state, { payload }) => (Object.assign(Object.assign({}, state), { groups: state.groups.map((group) => group.id === payload.groupId
            ? Object.assign(Object.assign({}, group), { loadProfileSettings: Object.assign(Object.assign({}, group.loadProfileSettings), { [payload.field]: payload.value }) }) : group) })),
    [toggleActiveGroups]: (state, action) => (Object.assign(Object.assign({}, state), { groups: state.groups.map((group) => (Object.assign(Object.assign({}, group), getGroupParamsByEnabled(group, action)))), isModified: true, isSaved: false })),
    [toggleEnabledGroup]: (state, action) => {
        const id = action && action.payload ? action.payload : {};
        return Object.assign(Object.assign({}, state), { groups: state.groups.map((group) => group.id === id ? Object.assign(Object.assign({}, group), getGroupParamsByToggleEnabled(group)) : group), isModified: true, isSaved: false });
    },
    [toggleGroups]: (state, action) => (Object.assign(Object.assign({}, state), { groups: [...state.groups.map((group) => (Object.assign(Object.assign({}, group), { isOpened: getIsOpened(action.payload) })))], isModified: true, isSaved: false })),
    [toggleLabelsFromProjectCopying]: (state, action) => (Object.assign(Object.assign({}, state), { isLabelsFromProjectCopying: action.payload })),
    [toggleLoadProfileSettings]: (state, action) => (Object.assign(Object.assign({}, state), { groups: state.groups.map((group) => group.id === action.payload ? Object.assign(Object.assign({}, group), { isExpanded: !group.isExpanded }) : group) })),
    [unlinkSettingsSetForNewTest]: (state) => (Object.assign(Object.assign({}, state), { settingsId: undefined })),
    [unlinkSettingsSetForTestProjectSuccess]: (state, action) => {
        const { projectId } = action.payload ? action.payload : {};
        return Object.assign(Object.assign({}, state), { settingsId: state.projectId === projectId ? undefined : state.settingsId });
    },
    [uploadGrpcProtoFileSuccess]: (state, action) => (Object.assign(Object.assign({}, state), { grpcBuffer: action && action.payload ? [...state.grpcBuffer, action.payload] : state.grpcBuffer }))
}, initialTestProjectSettings);
const getGroupParamsByEnabled = (group, action) => {
    const enabled = action && action.payload ? action.payload.enabled : true;
    return {
        enabled,
        isExpanded: enabled ? group.isExpanded : false
    };
};
const getGroupParamsByToggleEnabled = (group) => {
    const enabled = !group.enabled;
    return {
        enabled,
        isExpanded: enabled ? group.isExpanded : false
    };
};
const getIsOpened = (payload = { isOpened: true }) => payload.isOpened;
