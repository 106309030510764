var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { Button, ButtonSize } from "@boomq/uikit";
import { StyledTeamActionsPanel, StyledTeamSectionBlock } from "./styled";
import { TeamInfoBlock } from "../TeamInfoBlock";
const renderChildren = (_a) => {
    var { children } = _a, restProps = __rest(_a, ["children"]);
    return React.isValidElement(children) ? React.cloneElement(children, restProps) : children;
};
export const TeamAdminAccessTemplate = (props) => (React.createElement(React.Fragment, null,
    React.createElement(StyledTeamSectionBlock, null,
        React.createElement(TeamInfoBlock, Object.assign({}, props)),
        renderChildren(props)),
    React.createElement(StyledTeamActionsPanel, null,
        React.createElement(Button, { size: ButtonSize.medium, onClick: props.onSave }, props.buttonSaveLabel))));
