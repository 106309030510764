import { Link } from "react-router-dom";
import styled from "styled-components";
import { Button, Color } from "@boomq/uikit";
import { BurgerMenu, BurgerMenuButton, BurgerMenuButtonItem, BurgerMenuSidebar } from "../../common/BurgerMenu";
import { MenuListItemLink, MenuListItemNavLink } from "../../common/MenuListItem";
import { media } from "../../styled/response";
export const StyledBurgerMenu = styled(BurgerMenu) `
    display: flex;
    justify-content: flex-end;
    top: 0;
    ${MenuListItemLink}, ${MenuListItemNavLink} {
        color: #6b8cae;
        padding: 8px 0;
    }
    .menu_list_item_icon {
        fill: ${Color.blue};
        margin-right: 8px;
    }
    ${BurgerMenuButtonItem} {
        background: ${Color.white};
    }
    ${BurgerMenuButton}.opened ${BurgerMenuButtonItem} {
        background: ${Color.blue};
    }
    ${BurgerMenuSidebar} {
        width: 50%;
    }
    ${media.phone} {
        ${BurgerMenuSidebar} {
            align-items: center;
            padding-left: 0;
            width: 100%;
        }
    }
`;
const StyledButton = styled(Button) `
    background: #055ffc;
    border-radius: 7px;
    color: ${Color.white};
    margin-top: 28px;
    padding: 12px 64px;
`;
export const StyledButtonWithRoute = StyledButton.withComponent(Link);
