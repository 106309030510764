import React from "react";
import styled, { css } from "styled-components";
const Switcher = ({ disabled, name, onClick, defaultChecked, theme }) => (React.createElement(Label, null,
    React.createElement("input", { type: "checkbox", checked: defaultChecked, disabled: disabled, name: name || "checkbox", onChange: onClick }),
    React.createElement(StyledCheckbox, { theme: theme })));
const StyledCheckbox = styled.span `
    cursor: pointer;
    position: relative;
    display: block;
    width: 42px;
    height: 22px;
    background: #E0E0E0;
    border-radius: 30px;
    transition: all .3s ease-in-out;
    &:before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        background: #fff;
        border-radius: 50%;
    
        position: absolute;
        left: 3px;
        top: 50%;
        transform: translateY(-50%);
        transition: all .3s ease-in-out;
    }
    ${(props) => (props && props.theme) ? css(props.theme) : ""}
`;
const Label = styled.label `
    input[type="checkbox"] {
        display: none;
        &:checked + ${StyledCheckbox}:before {
            background: #6FCF97;
            left: 23px;
        }
    }
`;
export default Switcher;
