export const JMX_FILE_UPLOADER_EMPTY_TEXT = {
    defaultMessage: "+ Click to upload JMX file",
    id: "account.test.profile.import.form.jmx.file.empty"
};
export const IMPORT_JMX_BUTTON_LABEL = {
    defaultMessage: "Import",
    id: "account.test.profile.button.import"
};
export const SUPPORTING_FILES_EMPTY_TEXT = {
    defaultMessage: "+ Click to upload supporting files",
    id: "account.test.profile.import.form.jmx.support.files.empty"
};
