import React from "react";
import { Tooltip } from "@boomq/uikit";
import { Action, Error, StyledIconDelete, StyledIconSettings, StyledSelect, WebhookSection } from "./styled";
import { Actions } from "../UrlOverwrite/styled";
import { IconErrorTriangle } from "../../../styled/Icons";
import { SelectionType } from "../../../../helpers/types";
export const Webhook = ({ deletingLabel, enabled, error, errorTestStatus, onChangeTestStatus, onDelete, onShowWebhookSettings, settingsLabel, testDisplayStateSet, testStatusItems, testStatusSelectPlaceholder }) => (React.createElement(WebhookSection, null,
    React.createElement(StyledSelect, { disabled: !enabled, error: errorTestStatus, items: testStatusItems, label: testStatusSelectPlaceholder, onChange: onChangeTestStatus, placeholder: testStatusSelectPlaceholder, selectionType: SelectionType.Multiple, value: testDisplayStateSet }),
    React.createElement(Actions, null,
        React.createElement(Action, { onClick: onShowWebhookSettings },
            React.createElement(StyledIconSettings, null),
            settingsLabel),
        React.createElement(Action, { onClick: onDelete },
            React.createElement(StyledIconDelete, null),
            deletingLabel),
        error && (React.createElement(Tooltip, { title: error },
            React.createElement(Error, null,
                React.createElement(IconErrorTriangle, null)))))));
