import { isEmpty } from "@boomq/utils";
import { parameterNameValidator } from "./parameters";
export const headerItemValidator = (data = {}) => {
    const key = (data === null || data === void 0 ? void 0 : data.key) || "";
    const keyWithoutParams = (key || "").replace(/\${([\w-]+)}/g, "$1");
    const { isValid, error } = isEmpty(key) ? { isValid: true, error: null } : parameterNameValidator(keyWithoutParams);
    return {
        isValid,
        validatedData: Object.assign(Object.assign({}, data), { error: {
                key: error
            } })
    };
};
export const headerItemListValidator = (headers) => (headers || []).reduce((res, headerItem) => {
    const { isValid, validatedData } = headerItemValidator(headerItem);
    return {
        isValid: isValid ? res.isValid : false,
        validatedItemList: [...res.validatedItemList, validatedData]
    };
}, {
    isValid: true,
    validatedItemList: []
});
