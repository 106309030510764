import React from "react";
import { FormattedMessage } from "react-intl";
import { InputText } from "@boomq/uikit";
import { URL_OVERWRITE_ACTIONS_ADD, URL_OVERWRITE_TITLE } from "./intl";
import { customInputTheme, Action, Actions, RemoveButton, StyledIconPlus, UrlOverwriteItem } from "./styled";
import { BlockTitleWithWarning } from "../BlockTitleWithWarning";
import { StyledBlock } from "../Wizard/styled";
import { BlockContent } from "../../../styled/Blocks";
import { IconDeleteGrey } from "../../../styled/StyledIcons";
import { urlOverwriteItemFieldValidator } from "../../../../helpers/validators";
const getFieldError = (item, fieldName) => item && item.error ? item.error[fieldName] : undefined;
export const UrlOverwrite = ({ items = [], onAdd, onChange, onDelete, updatedUrlLabel, urlToRewriteLabel }) => {
    const changeValueHandler = (id, field, value) => typeof onChange === "function"
        ? onChange({
            error: {
                [field]: urlOverwriteItemFieldValidator(value)
            },
            field,
            id,
            value
        })
        : undefined;
    const changeUrlToRewriteHandler = (id, value) => changeValueHandler(id, "urlToRewrite", value);
    const changeUpdatedUrlHandler = (id, value) => changeValueHandler(id, "updatedUrl", value);
    const deleteItemHandler = (id) => (typeof onDelete === "function" ? onDelete(id) : undefined);
    const clickAddNewUrlOverwriteItemHandler = () => (typeof onAdd === "function" ? onAdd() : undefined);
    return (React.createElement(StyledBlock, null,
        React.createElement(BlockTitleWithWarning, { title: URL_OVERWRITE_TITLE }),
        React.createElement(BlockContent, null,
            items.map((item) => (React.createElement(UrlOverwriteItem, { key: item.id },
                React.createElement(InputText, { error: getFieldError(item, "urlToRewrite"), label: urlToRewriteLabel, name: "urlToRewrite", onBlur: (value) => changeUrlToRewriteHandler(item.id, value), placeholder: urlToRewriteLabel, theme: customInputTheme, value: item.urlToRewrite }),
                React.createElement(InputText, { error: getFieldError(item, "updatedUrl"), label: updatedUrlLabel, name: "updatedUrl", onBlur: (value) => changeUpdatedUrlHandler(item.id, value), placeholder: updatedUrlLabel, theme: customInputTheme, value: item.updatedUrl }),
                React.createElement(RemoveButton, { onClick: () => deleteItemHandler(item.id) },
                    React.createElement(IconDeleteGrey, null))))),
            React.createElement(Actions, null,
                React.createElement(Action, { onClick: clickAddNewUrlOverwriteItemHandler },
                    React.createElement(StyledIconPlus, null),
                    React.createElement(FormattedMessage, Object.assign({}, URL_OVERWRITE_ACTIONS_ADD)))))));
};
