import React from "react";
import { connect } from "react-redux";
import { useIntl } from "@boomq/utils";
import { CONNECTION_TIMEOUT_LABEL, REQUEST_TIMEOUT_LABEL } from "./intl";
import { OtherSettings } from "../../../../components/account/TestProjectSettingsSet/OtherSettings";
import { testProjectSettingsSetChangeParamsField } from "../../../../redux/actions/testProjectSettingsSet";
import { getSettingsSetHttpRequestDefaultsData } from "../../../../redux/reducers/testProjectSettingsSet";
const connector = connect((state) => (Object.assign({}, getSettingsSetHttpRequestDefaultsData(state))), {
    onChange: testProjectSettingsSetChangeParamsField
});
const WrappedOtherSettings = (props) => {
    const { formatMessage } = useIntl();
    const connectionTimeoutLabel = formatMessage(CONNECTION_TIMEOUT_LABEL);
    const requestTimeoutLabel = formatMessage(REQUEST_TIMEOUT_LABEL);
    return (React.createElement(OtherSettings, Object.assign({}, props, { connectionTimeoutLabel: connectionTimeoutLabel, requestTimeoutLabel: requestTimeoutLabel })));
};
export default connector(WrappedOtherSettings);
