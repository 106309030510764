import isURL from "validator/es/lib/isURL";
import { formatGrpcRequestUrlByTls } from "../request";
import { isEmpty } from "../utils/isEmpty";
import { PARAMETER_IN_PROTOCOL_HTTPS_REG_EXP_MASK, PARAMETER_REG_EXP_MASK } from "../../models/request/constants";
export const isValidUrlWithParameters = (url = "") => {
    try {
        const urlWithoutParameters = url
            .replace(PARAMETER_IN_PROTOCOL_HTTPS_REG_EXP_MASK, "$1param.param/")
            .replace(PARAMETER_REG_EXP_MASK, "param")
            .replace(/:\/\/localhost/, "://127.0.0.1")
            .replace(/ /g, "");
        return !isEmpty(urlWithoutParameters) && isURL(urlWithoutParameters);
    }
    catch (e) {
        return false;
    }
};
export const requestUrlValidatorErrorText = (url) => {
    let error;
    isEmpty(url) && (error = { id: "validation.url.require", defaultMessage: "Required field" });
    !isEmpty(url) &&
        !isValidUrlWithParameters(url) &&
        (error = { id: "validation.url.invalid", defaultMessage: "Not a valid URL" });
    !isEmpty(url) &&
        url.search(/(http|https):\/\//i) === -1 &&
        (error = {
            id: "validation.url.invalid.protocol",
            defaultMessage: "Please supply a protocol for the URL, ie http:// or https://"
        });
    return error;
};
export const grpcRequestUrlValidator = (url) => {
    const error = requestUrlValidatorErrorText(formatGrpcRequestUrlByTls(url));
    return { isValid: isEmpty(error), error };
};
export const requestUrlValidator = (url) => {
    const error = requestUrlValidatorErrorText(url);
    return { isValid: isEmpty(error), error };
};
export const requestUrlListValidator = (urlList) => {
    const validationUrlList = urlList.map((urlItem) => (Object.assign(Object.assign({}, urlItem), { error: requestUrlValidator(urlItem.newBaseUrl).error })));
    return {
        isValid: validationUrlList.some((validateUrlItem) => !isEmpty(validateUrlItem.error)),
        validationUrlList
    };
};
