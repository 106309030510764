import styled from "styled-components";
import { Color, IconInfo } from "@boomq/uikit";
export const IconDot = styled.div `
    background-color: ${(props) => props.bg};
    border-radius: 50%;
    height: 10px;
    margin-right: 5px;
    width: 10px;
`;
export const LegendList = styled.ul `
    align-items: center;
    display: flex;
    flex-wrap: wrap;
`;
export const LegendItem = styled.li `
    margin-right: 10px;
`;
export const LegendListItem = styled(LegendItem) `
    align-items: center;
    display: flex;
`;
export const LegendValue = styled.span `
    color: ${(props) => props.color};
`;
export const StyledIconInfo = styled(IconInfo) `
    fill: ${Color.grey65};
`;
export const InfoLink = styled.a `
    &:hover {
        ${StyledIconInfo} {
            fill: ${Color.blue};
        }
    }
`;
