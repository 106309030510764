export const NOTIFICATION_LINKPROJECT_HERE_TEXT = {
    defaultMessage: "here",
    id: "account.new.test.project.saved.success.link"
};

export const RUN_TEST_BUTTON_LABEL = {
    defaultMessage: "Run Test",
    id: "account.new.settings.button.run"
};

export const SAVE_TEST_BUTTON_LABEL = {
    defaultMessage: "Save",
    id: "account.test.profile.button.save"
};
