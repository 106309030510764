var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { getUniqueId, getValueByPath, isEmpty, isEmptyArray, objectToArray, unEscapeComponent } from "@boomq/utils";
import { TIMEOUT_TIME_COEFFICIENT } from "./constants";
import { generateContentFromFilePreview, getPreviewByHasHeaders, parseCsvFileParam } from "../requestParameters";
import { ConfigurationElementType } from "../types";
const getPreviewLocationDataByFileId = (files, fileId) => {
    const file = files.find(({ id }) => id === fileId);
    return file ? Object.assign(Object.assign({}, file), { location: file.link }) : {};
};
export const parseRandomCsvDatasetConfigElement = (csvParameter, supportingFiles = []) => {
    const _a = csvParameter || {}, { delimiter, fileId, firstLineIsCsvHeader, rewindOnEndOfList, variablesNames } = _a, rest = __rest(_a, ["delimiter", "fileId", "firstLineIsCsvHeader", "rewindOnEndOfList", "variablesNames"]);
    const { location = "", name = "", preview = [] } = getPreviewLocationDataByFileId(supportingFiles, fileId);
    return [
        Object.assign(Object.assign({ delimiter }, rest), { fileContent: generateContentFromFilePreview(getPreviewByHasHeaders(preview, firstLineIsCsvHeader), delimiter), files: parseCsvFileParam([{ id: fileId, name }]), hasHeaders: firstLineIsCsvHeader, isOpened: false, isSavedCsvFile: true, location, parameterNames: variablesNames, preview, recycle: rewindOnEndOfList })
    ];
};
const parseUserDefinedVariable = ({ description, name, value }) => ({
    id: getUniqueId(),
    key: name,
    value: unEscapeComponent(value),
    description
});
export const parseUserDefinedVariablesElement = ({ id, label, variables }) => isEmpty(variables)
    ? []
    : variables.map((variableData) => (Object.assign({ label, parameterId: id }, parseUserDefinedVariable(variableData))));
const parseUserDefinedCookie = (data) => (Object.assign(Object.assign({}, data), { id: data.id ? data.id : getUniqueId() }));
const parseUserDefinedCookies = (items = []) => (items || []).map(parseUserDefinedCookie);
const parseHttpCookieManager = (_a) => {
    var { userDefinedCookies } = _a, restParams = __rest(_a, ["userDefinedCookies"]);
    return [
        Object.assign(Object.assign({}, restParams), { items: parseUserDefinedCookies(userDefinedCookies) })
    ];
};
export const parseHttpCookieManagerElement = (data) => (isEmpty(data) ? [] : parseHttpCookieManager(data));
const parseHttpHeaderManager = (params) => objectToArray(getValueByPath(params, "headers", {}), "key", "value").map((header) => (Object.assign(Object.assign({}, header), { configElementId: getValueByPath(params, "id"), id: getUniqueId(), label: getValueByPath(params, "label", "") })));
export const parseHttpHeaderManagerElement = (data) => (isEmpty(data) ? [] : parseHttpHeaderManager(data));
export const parseHttpRequestDefaults = (params) => {
    const _a = params || {}, { connectTimeout, responseTimeout } = _a, restParams = __rest(_a, ["connectTimeout", "responseTimeout"]);
    return Object.assign(Object.assign({}, (restParams || {})), { connectTimeout: (connectTimeout || 0) / TIMEOUT_TIME_COEFFICIENT, responseTimeout: (responseTimeout || 0) / TIMEOUT_TIME_COEFFICIENT });
};
export const parseHttpRequestDefaultsElement = (data) => (isEmpty(data) ? [] : [parseHttpRequestDefaults(data)]);
const parserByConfigElementType = {
    [ConfigurationElementType.HttpCookieManager]: parseHttpCookieManagerElement,
    [ConfigurationElementType.HttpHeaderManager]: parseHttpHeaderManagerElement,
    [ConfigurationElementType.HttpRequestDefaults]: parseHttpRequestDefaultsElement,
    [ConfigurationElementType.RandomCsvDatasetConfig]: parseRandomCsvDatasetConfigElement,
    [ConfigurationElementType.UserDefinedVariables]: parseUserDefinedVariablesElement
};
const parseConfigElementByType = (res, configElement, supportingFiles) => (Object.assign(Object.assign({}, res), { [configElement.type]: [
        ...res[configElement.type],
        ...parserByConfigElementType[configElement.type](configElement, supportingFiles)
    ] }));
const parseConfigElement = (res, configElement, supportingFiles = []) => {
    const configElementType = getValueByPath(configElement, "type");
    return Object.keys(parserByConfigElementType).includes(configElementType)
        ? parseConfigElementByType(res, configElement, supportingFiles)
        : Object.assign(Object.assign({}, res), { others: [...res.others, configElement] });
};
export const parseConfigurationElements = (configurationElements, supportingFiles = []) => {
    const _a = (configurationElements || []).reduce((res, configElement) => parseConfigElement(res, configElement, supportingFiles), {
        [ConfigurationElementType.HttpCookieManager]: [],
        [ConfigurationElementType.HttpHeaderManager]: [],
        [ConfigurationElementType.HttpRequestDefaults]: [],
        [ConfigurationElementType.RandomCsvDatasetConfig]: [],
        [ConfigurationElementType.UserDefinedVariables]: [],
        others: []
    }), { others } = _a, restParams = __rest(_a, ["others"]);
    const defaultCookiesList = restParams[ConfigurationElementType.HttpCookieManager];
    const defaultHeaders = restParams[ConfigurationElementType.HttpHeaderManager];
    const httpRequestDefaultsList = restParams[ConfigurationElementType.HttpRequestDefaults];
    return {
        defaultCookies: isEmptyArray(defaultCookiesList) ? {} : defaultCookiesList[0],
        defaultHeaders,
        httpRequestDefaults: isEmptyArray(httpRequestDefaultsList) ? {} : httpRequestDefaultsList[0],
        others,
        requestParameters: {
            csvParameters: restParams[ConfigurationElementType.RandomCsvDatasetConfig],
            literalParameters: restParams[ConfigurationElementType.UserDefinedVariables]
        }
    };
};
export const parseConfigurationElementsFromBoomqTestPlan = (testProjectYamlData) => {
    const configurationElementsData = getValueByPath(testProjectYamlData, "boomqTestPlan.configurationElements", []);
    const supportingFilesData = getValueByPath(testProjectYamlData, "supportingFiles", []);
    return parseConfigurationElements(configurationElementsData, supportingFilesData);
};
