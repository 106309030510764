import styled, { css } from "styled-components";
import { Color } from "../colorPallete";
import { withBorder, withDisable, withShadow } from "../global";
import { media } from "../response";
import { FontBodyMediumMedium, FontCaption } from "../typography";
export const InputLabel = styled.label `
    ${FontCaption}
    color: ${({ isError }) => (isError ? Color.red : Color.darkGrey35)};
    left: 8px;
    max-width: calc(100% - 16px);
    overflow: hidden;
    padding: 0 4px;
    position: absolute;
    top: 0px;
    transform: translateY(-50%);
    white-space: nowrap;
    z-index: 1;
`;
export const InputLabelText = styled.div `
    overflow: hidden;
    white-space: nowrap;
`;
export const StyledErrorMessage = styled.p `
    position: absolute;
    bottom: -16px;
    left: 30px;
    color: #eb5757;
    font-size: 12px;
    ${media.phone} {
        left: 0;
    }
`;
export const StyledFieldSet = styled.fieldset `
    ${withBorder}
    border-color: ${({ isError }) => (isError ? Color.red : Color.grey75)};
    inset: -6px 0px 0px;
    margin: 0px;
    padding: 0px 8px;
    position: absolute;
    text-align: left;
`;
const getMarginRightByParams = (isError, isExistUnit) => {
    const unitPadding = isExistUnit ? 45 : 0;
    return isError ? `${unitPadding + 36}px` : `${unitPadding + 12}px`;
};
export const StyledInput = styled.input `
    ${withDisable}
    ${FontBodyMediumMedium}
    background: ${Color.white};
    border: none;
    box-sizing: border-box;
    box-shadow: inset 0 0 0 20px ${Color.white} !important; // rule for :autofill replacement
    color: ${Color.grey50};
    margin-bottom: 8px;
    margin-left: 12px;
    margin-right: ${({ isError, isExistUnit }) => getMarginRightByParams(isError, isExistUnit)};
    margin-top: 8px;
    outline: none;
    position: relative;
    transition: all 0.1s ease-in-out;
    width: 100%;
    z-index: 1;
    &::placeholder {
        ${({ placeholderTheme }) => placeholderTheme
    ? css(placeholderTheme)
    : css `
                      color: ${Color.grey65};
                  `}
    }
    &:focus {
        border-color: ${Color.blue};
        ${withShadow}
        color: ${Color.darkGrey5};
    }
    ${({ disabled, disabledStyle }) => (disabled && disabledStyle ? css(disabledStyle) : ``)}
    ${(props) => (props && props.theme ? css(props.theme) : "")}
`;
export const StyledInputWrapper = styled.div `
    display: flex;
    position: relative;
    width: 100%;
    ${({ disabled, disabledStyle }) => (disabled && disabledStyle ? css(disabledStyle) : css ``)}
    ${({ theme }) => (theme ? css(theme) : css ``)}
`;
export const StyledLabel = styled.label `
    align-items: center;
    display: flex;
    margin-top: 19px;
    position: relative;
    &:first-child {
        margin-top: 0;
    }
    ${media.tablet} {
        flex-direction: column;
        align-items: flex-start;
    }
    ${(props) => (props && props.theme ? css(props.theme) : "")}
`;
export const StyledLegend = styled.legend `
    display: block;
    font-size: 0.75em;
    height: 12px;
    overflow: hidden;
    max-width: 100%;
    width: auto;
`;
export const StyledLegendSpan = styled.span `
    display: inline-block;
    opacity: 0;
    padding-left: ${({ isEmpty }) => (isEmpty ? 0 : 4)}px;
    padding-right: ${({ isEmpty }) => (isEmpty ? 0 : 4)}px;
    visibility: visible;
`;
export const Unit = styled.div `
    ${FontBodyMediumMedium}
    align-items: center;
    color: ${({ isError }) => (isError ? Color.red : Color.grey75)};
    cursor: pointer;
    display: flex;
    height: 100%;
    position: absolute;
    right: 10px;
    top: 0;
`;
