var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { getUniqueId, getValueByPath, isEmpty } from "@boomq/utils";
import { EXTRACTOR_TYPE_PARAM_NAME, EXTRACTOR_VARIABLE_NAME_PARAM_NAME } from "./constants";
import { ExtractorUnsupportedTypeTemplate } from "../../components/account/TestProject/ExtractorUnsupportedTypeTemplate";
import { formatIntlMessage } from "../intl";
import { getPathByIndex } from "../parsers/common";
import { getThreadGroupElementControllerType } from "../threadGroup";
import { ExtractorMatchType, ExtractorType } from "../types";
import { modifyExtractorErrorMapByVariableName } from "../validators/extractor";
import { DEFAULT_EXTRACTOR_FIELD_TO_CHECK, DEFAULT_EXTRACTOR_GROUP_NUMBER, DEFAULT_EXTRACTOR_MATCH_SPECIFIC_VALUE, extractorFieldToCheckItems, extractorMatchTypeItems, extractorTemplatesByType, httpExtractTypes } from "../../models/extractFromResponse";
import { ControllerFlowControlType, SamplerType } from "../../models/threadGroupEditor/enums";
export const getExtractorTemplateByType = (type) => Object.keys(extractorTemplatesByType).includes(type)
    ? extractorTemplatesByType[type]
    : ExtractorUnsupportedTypeTemplate;
export const getExpressionParamsValue = (expression, paramName) => paramName ? String(expression.params[paramName]) : "";
export const getExpressionValue = (expression, field, subfield) => {
    const fieldValue = expression[field];
    return subfield ? fieldValue[subfield] : fieldValue;
};
export const getExtractorFieldToCheckItems = () => extractorFieldToCheckItems;
export const getExtractorMatchTypeItems = () => extractorMatchTypeItems;
export const getFormattedExtractorMatchTypeItems = (intlFormatFunc) => getExtractorMatchTypeItems().map((matchTypeItem) => (Object.assign(Object.assign({}, matchTypeItem), { text: formatIntlMessage(matchTypeItem.text, intlFormatFunc) })));
export const validateExtractorMatchValue = (value) => (value || "").length === 0 || Number(value) < 1 ? DEFAULT_EXTRACTOR_MATCH_SPECIFIC_VALUE : value;
export const getExtractorMatchByMatchType = (extractorParams) => extractorParams.matchType === ExtractorMatchType.Specific
    ? { matchType: extractorParams.matchType, value: validateExtractorMatchValue(extractorParams.value) }
    : { matchType: extractorParams.matchType };
export const getNewExtractorObj = () => ({
    id: getUniqueId(),
    variable: "",
    type: ExtractorType.RegEx,
    params: { re: "", groupNumber: DEFAULT_EXTRACTOR_GROUP_NUMBER, fieldToCheck: DEFAULT_EXTRACTOR_FIELD_TO_CHECK },
    match: {
        matchType: ExtractorMatchType.Random
    }
});
export const getPlaceholderMap = (extractType, paramName) => {
    const extractTypeMap = httpExtractTypes.find((type) => type.value === extractType);
    return extractTypeMap && getValueByPath(extractTypeMap, `placeholder.${paramName}`)
        ? extractTypeMap.placeholder[paramName]
        : { id: "title.empty", defaultMessage: "" };
};
const changeExtractorType = (extractor, extractorType) => {
    const extractTypeMap = httpExtractTypes.find((type) => type.value === extractorType);
    return extractTypeMap
        ? Object.assign(Object.assign({}, extractor), { params: extractTypeMap.expressionValue, type: extractorType }) : Object.assign(Object.assign({}, extractor), { type: extractorType });
};
const changeExtractorFieldValue = (extractor, { field, subfield, value }) => {
    const extractorData = field && subfield
        ? Object.assign(Object.assign({}, extractor), { [field]: Object.assign(Object.assign({}, extractor[field]), { [subfield]: value }) }) : Object.assign(Object.assign({}, extractor), { [field]: value });
    return modifyExtractorErrorMapByVariableName(extractorData);
};
const changeExtractor = (extractor, { field, subfield, value }) => field === "type"
    ? changeExtractorType(extractor, value)
    : changeExtractorFieldValue(extractor, { field, subfield, value });
export const changeCurrentExtractor = (extractors, _a) => {
    var { id } = _a, rest = __rest(_a, ["id"]);
    return (extractors || []).map((extractor) => (extractor.id === id ? changeExtractor(extractor, Object.assign({}, rest)) : extractor));
};
export const addExtractorToCurrentList = (extractors, params) => [
    ...extractors,
    changeExtractor(getNewExtractorObj(), params)
];
const prepareExtractorParameter = ({ id, variable }, path) => ({ id, name: variable, path });
const prepareForEachParameter = ({ id, outputVariableName }, path) => ({ id, name: outputVariableName, path });
export const getExtractorParametersFromRequest = (request, path) => (request.extractResponse || []).map((extractor) => prepareExtractorParameter(extractor, path));
const getNodeDataById = (nodesData, nodeId) => (nodesData && nodesData[nodeId] ? nodesData[nodeId] : {});
const getExtractorParameters = (path, node, nodesData) => ({
    extractorParameters: getExtractorParametersFromRequest(getNodeDataById(nodesData, node.id), path)
});
const getForEachParameters = (path, node, nodesData) => ({
    forEachParameters: prepareForEachParameter(getNodeDataById(nodesData, node.id), path)
});
const parametersGettingFuncByControllerType = {
    [SamplerType.Http]: getExtractorParameters,
    [ControllerFlowControlType.ForEach]: getForEachParameters
};
const getParametersByNodeControllerType = (path, node, nodesData) => {
    const elementControllerType = getThreadGroupElementControllerType(node || {});
    return Object.keys(parametersGettingFuncByControllerType).includes(elementControllerType)
        ? parametersGettingFuncByControllerType[elementControllerType](path, node, nodesData)
        : [];
};
const getParametersFromTreeNode = (res, path, node, nodesData) => {
    const { extractorParameters, forEachParameters } = getParametersByNodeControllerType(path, node, nodesData);
    const parameters = isEmpty(extractorParameters)
        ? res
        : Object.assign(Object.assign({}, res), { extractorParameters: Object.assign(Object.assign({}, res.extractorParameters), { [node.id]: extractorParameters }) });
    return isEmpty(forEachParameters)
        ? parameters
        : Object.assign(Object.assign({}, parameters), { forEachParameters: Object.assign(Object.assign({}, parameters.forEachParameters), { [node.id]: forEachParameters }) });
};
export const getParametersFromTree = (parameters, parentPath, treeData, nodesData) => (treeData || []).reduce((res, node, index) => {
    const path = getPathByIndex(parentPath, index);
    const resParameters = getParametersFromTreeNode(res, path, node, nodesData);
    return isEmpty(node.children)
        ? resParameters
        : getParametersFromTree(resParameters, path, node.children, nodesData);
}, parameters);
export const getExtractorType = (extractor) => getValueByPath(extractor, EXTRACTOR_TYPE_PARAM_NAME);
export const getExtractorVariableName = (extractor) => getValueByPath(extractor, EXTRACTOR_VARIABLE_NAME_PARAM_NAME);
