export const TIMELINE_RANGE_SLIDER_LABEL = {
    defaultMessage: "Time",
    id: "account.new.test.project.jmx.chart.timeline.range.slider.label"
};
export const TIMELINE_RANGE_SLIDER_UNITS = {
    defaultMessage: "min",
    id: "account.new.test.project.jmx.chart.timeline.range.slider.units"
};
export const MERGE_GROUPS_SWITCHER_LABEL = {
    defaultMessage: "Merge groups",
    id: "account.new.test.project.jmx.chart.merge.groups"
};
