import { Link } from "react-router-dom";
import styled from "styled-components";
import { WebsiteWrapper } from "../../../styled/Wrappers";
import { SectionTitle } from "../../../styled/Titles";
export const List = styled.ul `
    color: #677897;
    line-height: 25px;
    li {
        list-style-type: disc;
    }
`;
export const StyledLink = styled(Link) `
    text-decoration: underline;
`;
export const StyledSectionTitle = styled(SectionTitle) `
    margin-top: 30px;
`;
export const StyledWebsiteWrapper = styled(WebsiteWrapper) `
    padding: 50px 0;
`;
export const TextBlock = styled.div `
    color: #677897;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    line-height: 25px;
    margin-top: 25px;
`;
export const TextSection = styled.div `
    > ${TextBlock}:first-child {
        margin-top: 0;
    }
    > ${TextBlock} {
        line-height: 25px;
        margin-top: 25px;
    }
`;
