import { handleActions } from 'redux-actions';
import { getModal, closeModal } from '../actions/modal';
export const initialState = {
    buttons: null,
    closeCallBack: null,
    description: null,
    isVisible: false,
    title: null
};
export default handleActions({
    [getModal]: (state, action) => (Object.assign(Object.assign({}, state), { buttons: action.payload.buttons, closeCallBack: action.payload.closeCallBack, description: action.payload.description, isVisible: true, title: action.payload.title })),
    [closeModal]: state => (Object.assign(Object.assign({}, state), { isVisible: false }))
}, initialState);
export const getVisibility = state => state.modal.isVisible;
export const getTitle = state => state.modal.title;
export const getDescription = state => state.modal.description;
export const getButtons = state => state.modal.buttons;
export const getCloseCallBack = state => state.modal.closeCallBack;
