import styled from "styled-components";
import { Checkbox } from "@boomq/uikit";
import { StyledInputWithParameters } from "../IfElement/styled";
export const ForEachTextSection = styled.span `
    min-width: 70px;
    width: 70px;
`;
export const StyledCheckbox = styled(Checkbox) `
    margin-right: 8px;
`;
export const StyledIndexInputWithParameters = styled(StyledInputWithParameters) `
    min-width: 15%;
    width: 15%;
`;
