var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { Button, ButtonSize, InputText, Tabs } from "@boomq/uikit";
import { customHintTheme, customTitleTheme, ButtonSection, ButtonWithHintSection, RequestBlock, RequestSection, StyledSelect } from "./styled";
import { RequestMethodBodyTemplate } from "./RequestMethodBodyTemplate";
import { RequestName } from "./RequestName";
import { BodyText } from "../BodyText";
import { HeaderParameters } from "../HeaderParameters";
import { QueryParameters } from "../QueryParameters";
import { FormTitle } from "../../../styled/Titles";
import { Hint } from "../../../common/Hint";
import { AssertionsContainer } from "../../../../containers/account/TestProject/Assertions";
import { ExtractResponseParametersContainer } from "../../../../containers/account/TestProject/ExtractResponseParameters";
import { RequestTimers } from "../../../../containers/account/TestProject/RequestTimers";
import { RequestJMXTimers } from "../../../../containers/account/TestProject/RequestJMXTimers";
import { FormType } from "../../../../helpers/types";
import { getHttpAssertionTypeItems } from "../../../../models/assertion";
import { httpExtractTypes } from "../../../../models/extractFromResponse";
import warning from "../../../../assets/images/icons/warn.svg";
const requestParamElements = {
    Assertions: (props) => (React.createElement(AssertionsContainer, Object.assign({}, props, { assertionTypeItems: getHttpAssertionTypeItems() }))),
    BodyText,
    ExtractResponseParameters: (props) => (React.createElement(ExtractResponseParametersContainer, Object.assign({}, props, { extractTypes: httpExtractTypes }))),
    HeaderParameters,
    QueryParameters,
    RequestTimers,
    RequestJMXTimers
};
export const RequestHttpForm = (_a) => {
    var { isDisabledInputs, onChangeName, requestName, requestNameLabel } = _a, restProps = __rest(_a, ["isDisabledInputs", "onChangeName", "requestName", "requestNameLabel"]);
    const { errorForm, errorProperties } = restProps.errorMap || { errorForm: {}, errorProperties: {} };
    const { error, isValid } = errorForm || {};
    return (React.createElement(React.Fragment, null,
        React.createElement(FormTitle, { theme: customTitleTheme }, restProps.title),
        React.createElement(RequestBlock, null,
            React.createElement(RequestSection, null,
                React.createElement(StyledSelect, { bodyTemplate: RequestMethodBodyTemplate, defaultValue: restProps.method, disabled: isDisabledInputs, items: restProps.methodItems, onChange: restProps.onChangeMethod }),
                React.createElement(InputText, { enabled: !isDisabledInputs, error: errorProperties.requestUrl, name: "requestUrl", onChange: restProps.onChangeUrl, value: restProps.requestUrl })),
            React.createElement(RequestSection, null,
                React.createElement(RequestName, Object.assign({}, restProps, { isDisabledInputs: isDisabledInputs, onChange: onChangeName, requestName: requestName, requestNameLabel: requestNameLabel })))),
        React.createElement(Tabs, { currentActiveTabName: restProps.currentActiveTabName, defaultActiveTabName: restProps.defaultActiveTabName, elements: requestParamElements, items: restProps.tabItems, name: "requestParams", onChangeTab: restProps.onChangeTab }),
        restProps.formType !== FormType.View && (React.createElement(ButtonSection, null,
            React.createElement(ButtonWithHintSection, null,
                React.createElement(Button, { disabled: !isValid, onClick: restProps.onSave, size: ButtonSize.small }, restProps.buttonSaveLabel),
                !isValid && React.createElement(Hint, { icon: warning, theme: customHintTheme, tooltipText: error }))))));
};
