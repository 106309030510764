import React from "react";
import { getValueByPath, useIntl } from "@boomq/utils";
import { InputNumber, InputText, Select } from "@boomq/uikit";
import { ExtractorCommonTemplate } from "../ExtractorCommonTemplate";
import { getExpressionParamsValue, getExpressionValue, getPlaceholderMap } from "../../../../helpers/extractResponse";
import { FormType } from "../../../../helpers/types";
const ExtractorRegExpTemplate = (props) => {
    const { formatMessage } = useIntl();
    const { expression, fieldToCheckItems = [], mode, onChange } = props || {};
    const changeExtractExpressionHandler = ({ field, subfield, value }) => getExpressionValue(expression, field, subfield) !== value && typeof onChange === "function"
        ? onChange({
            id: expression.id,
            field,
            subfield,
            value
        })
        : undefined;
    const changeFieldToCheckHandler = (item) => changeExtractExpressionHandler({
        field: "params",
        subfield: "fieldToCheck",
        value: item.value
    });
    const changeInputValue = (paramName, value) => changeExtractExpressionHandler({
        field: "params",
        subfield: paramName,
        value
    });
    const changeGroupNumberHandler = (value) => changeInputValue("groupNumber", value);
    const changeRegularExpressionHandler = (value) => changeInputValue("re", value);
    const getPlaceholder = (extractType, paramName) => formatMessage(getPlaceholderMap(extractType, paramName));
    return (React.createElement(ExtractorCommonTemplate, Object.assign({}, props, { onChange: changeExtractExpressionHandler }),
        React.createElement(InputText, { enabled: mode !== FormType.View, error: getValueByPath(expression, "error.re"), name: "Expression_re", onBlur: changeRegularExpressionHandler, placeholder: getPlaceholder(expression.type, "re"), value: getExpressionParamsValue(expression, "re") }),
        React.createElement(InputNumber, { decimalCount: -1, enabled: mode !== FormType.View, name: "Expression_groupNumber", onBlur: changeGroupNumberHandler, placeholder: getPlaceholder(expression.type, "groupNumber"), value: getExpressionParamsValue(expression, "groupNumber") }),
        React.createElement(Select, { defaultValue: getExpressionParamsValue(expression, "fieldToCheck"), disabled: mode === FormType.View, items: fieldToCheckItems, name: `Expression_fieldToCheck`, onChange: changeFieldToCheckHandler })));
};
export default ExtractorRegExpTemplate;
