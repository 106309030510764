import { Color } from "@boomq/uikit";
const CONTRAST_TEXT_COLORS_BY_COLOR_PALETTE = {
    "#1d1d1d": Color.white,
    "#ebce2b": Color.black,
    "#702c8c": Color.white,
    "#db6917": Color.white,
    "#96cde6": Color.black,
    "#ba1c30": Color.white,
    "#c0bd7f": Color.black,
    "#7f7e80": Color.white,
    "#5fa641": Color.white,
    "#d485b2": Color.white,
    "#4277b6": Color.white,
    "#df8461": Color.white,
    "#463397": Color.white,
    "#e1a11a": Color.black,
    "#91218c": Color.white,
    "#e8e948": Color.black,
    "#7e1510": Color.white,
    "#92ae31": Color.black,
    "#6f340d": Color.white,
    "#d32b1e": Color.white,
    "#2b3514": Color.white
};
export const getContrastTextColorByColor = (contrastColor) => {
    const contrastTextColor = CONTRAST_TEXT_COLORS_BY_COLOR_PALETTE[contrastColor];
    return contrastTextColor ? contrastTextColor : Color.black;
};
