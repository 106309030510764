import React, { FC } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getThreadGroupChartDataByGroupId, getThreadGroupLoadProfileSettingsByGroupId, newTestActions } from "@boomq/common";
import { LoadTypeSettings } from "../../../components/account/LoadTypeSettings";
import { testSettings } from "../../../data/content/testSettings";
import { TestType } from "../../../helpers/types";

const { threadGroupChangeLoadProfileParams } = newTestActions;

const connector = connect(
    (state, ownProps: any) => ({
        chartData: getThreadGroupChartDataByGroupId(state, ownProps.groupId),
        settings: testSettings[TestType.Scalability],
        loadProfileData: getThreadGroupLoadProfileSettingsByGroupId(state, ownProps.groupId)
    }),
    { onChange: threadGroupChangeLoadProfileParams }
);

const LoadTypeSettingsContainer: FC<any> = ({ onChange, ...restProps }: any): JSX.Element => {
    const changeHandler = (params): void => onChange({ ...params, groupId: restProps.groupId });

    return <LoadTypeSettings {...restProps} onChange={changeHandler} />;
};

export default connector(withRouter(LoadTypeSettingsContainer));
