import React, { Component } from "react";
import styled, { css } from "styled-components";
import ReactSVG from "react-svg";
import { deepCompare, excludeArrayFromArray, isEmpty, isEmptyArray, useIntl } from "@boomq/utils";
import { withError } from "@boomq/uikit";
import { withDisable, getZIndex } from "../../styled/global";
import { IconClose } from "../../styled/Icons/Close";
import { SelectionType } from "../../../helpers/types";
import arrow from "../../../assets/images/icons/arrow.svg";
const OPTION_LI_HEIGHT = "40";
const DEFAULT_OPTION_LI_MAX_COUNT = 11;
const DEFAULT_OPTION_UL_MAX_HEIGHT = `${OPTION_LI_HEIGHT * DEFAULT_OPTION_LI_MAX_COUNT}px`;
const DEFAULT_TEXT_ATTR_NAME = "text";
const DEFAULT_TITLE_ATTR_NAME = "title";
const DEFAULT_VALUE_ATTR_NAME = "value";
const DEFAULT_TEXT = {
    defaultMessage: " ",
    id: "title.empty"
};
const getItemById = (items, id) => (items || []).find((item) => item.id === id) || {};
const getMaxItemCount = (itemsCount) => itemsCount + 1 <= DEFAULT_OPTION_LI_MAX_COUNT ? itemsCount + 1 : DEFAULT_OPTION_LI_MAX_COUNT;
class CustomSelect extends Component {
    constructor() {
        super(...arguments);
        this.defaultBodyTemplate = ({ items, selectedItemIds, disabled, isError, placeholder }) => (React.createElement(DefaultComboBoxBody, { disabled: disabled, isError: isError, title: this.getComboBoxBodyTitle() }, isEmptyArray(selectedItemIds)
            ? this.getFormatMessage(placeholder)
            : this.getComboBoxBodyText(items, selectedItemIds)));
        this.getTextAttrName = () => this.props.textAttrName || DEFAULT_TEXT_ATTR_NAME;
        this.getTitleAttrName = () => this.props.titleAttrName || DEFAULT_TITLE_ATTR_NAME;
        this.getValueAttrName = () => this.props.valueAttrName || DEFAULT_VALUE_ATTR_NAME;
        this.getFormatMessage = (message) => this.props.intl && this.props.intl.formatMessage && message && message.id
            ? this.props.intl.formatMessage(message)
            : message;
        this.getInitialState = (items, value, defaultValue) => {
            const defaultItem = this.getItemByValue(items, defaultValue);
            const defaultItemIds = defaultItem && !isEmpty(defaultItem.id) ? [defaultItem.id] : [];
            const valueItemIds = Array.isArray(value)
                ? value.reduce((res, valueItem) => (valueItem && !isEmpty(valueItem.id) ? [...res, valueItem.id] : res), [])
                : [];
            return isEmptyArray(valueItemIds) ? defaultItemIds : valueItemIds;
        };
        this.getItemByValue = (items, value) => value && Array.isArray(items) ? items.find((item) => item[this.getValueAttrName()] === value) : null;
        this.getText = (item) => {
            const textAttrName = this.getTextAttrName();
            const textValue = item && item[textAttrName] ? item[textAttrName] : DEFAULT_TEXT;
            return typeof item === "string" ? item : this.getFormatMessage(textValue);
        };
        this.getTitle = (item) => {
            const titleAttrName = this.getTitleAttrName();
            const titleValue = item && item[titleAttrName] ? item[titleAttrName] : this.getText(item);
            return typeof item === "string" ? item : this.getFormatMessage(titleValue);
        };
        this.state = {
            isOpen: false,
            maxItemCount: this.props.maxItemCount || DEFAULT_OPTION_LI_MAX_COUNT,
            openTop: this.props.openTop,
            selectedItemIds: this.getInitialState(this.props.items, this.props.value, this.props.defaultValue)
        };
        this.isNeedRefreshSelectedItemIds = (nextProps, props) => {
            const res = nextProps.value !== props.value &&
                Array.isArray(nextProps.value) &&
                !deepCompare(nextProps.value, props.value);
            return res ? res : nextProps.defaultValue !== props.defaultValue;
        };
        this.isNeedRefreshItems = (nextProps, props) => {
            const res = nextProps.items !== props.items && !deepCompare(nextProps.items, props.items);
            return res ? res : nextProps.defaultValue !== props.defaultValue;
        };
        this.maxItemCountCalculationByMaxHeight = (topHeight, bottomHeight) => {
            const maxHeight = topHeight > bottomHeight ? topHeight : bottomHeight;
            this.setState({ maxItemCount: Math.floor(maxHeight / OPTION_LI_HEIGHT) });
        };
        this.openTopCalculation = () => {
            const section = document.querySelector("#root section");
            const { offsetTop = 0, offsetBottom = 0 } = this.props.position || {};
            const sectionHeight = section ? section.clientHeight - offsetBottom : 0;
            const { bottom, top } = this.customSelectRef.getBoundingClientRect();
            const bottomHeight = sectionHeight - bottom;
            const topHeight = top - offsetTop;
            const maxItemsHeight = this.state.maxItemCount * OPTION_LI_HEIGHT;
            const isOpenBottom = bottomHeight > maxItemsHeight;
            const isOpenTop = topHeight > maxItemsHeight ? true : topHeight > bottomHeight;
            this.setState({ openTop: isOpenBottom ? false : isOpenTop });
            topHeight < maxItemsHeight &&
                bottomHeight < maxItemsHeight &&
                this.maxItemCountCalculationByMaxHeight(topHeight, bottomHeight);
        };
        this.openTopCalculationByItemsCount = (itemsCount) => {
            this.setState({
                maxItemCount: getMaxItemCount(itemsCount)
            });
            this.openTopCalculation();
        };
        this.getItemText = (item) => this.getFormatMessage((item || {})[this.getTextAttrName()] || "");
        this.getItemTitle = (item) => this.getFormatMessage((item || {})[this.getTitleAttrName()] || "");
        this.getComboBoxBodyText = (items = [], selectedItemIds) => this.getSelectedItems(items, selectedItemIds)
            .map((item) => this.getItemText(item))
            .join(", ");
        this.getComboBoxBodyTitle = () => this.isEmptyComboBoxText()
            ? this.getFormatMessage(this.props.placeholder)
            : this.getSelectedItemsTitle(this.props.items, this.state.selectedItemIds);
        this.getListItem = (item) => typeof this.props.listItemTemplate === "function" ? (this.props.listItemTemplate(item)) : (React.createElement(ItemText, null, this.getText(item)));
        this.getSelectedItemIdsByType = (itemIds, type) => itemIds.filter((itemId) => getItemById(this.props.items, itemId).type === type);
        this.getSelectedItems = (items, selectedItemIds) => Array.isArray(items) ? items.filter((item) => selectedItemIds.includes(item.id)) : [];
        this.getSelectedItemsTitle = (items, selectedItemIds) => this.getSelectedItems(items, selectedItemIds)
            .map((selectedItem) => this.getItemTitle(selectedItem) || this.getItemText(selectedItem))
            .join(", ");
        this.onChangeItems = (items, value) => this.setState({ selectedItemIds: this.getInitialState(items, null, value) });
        this.isEmptyComboBoxText = () => isEmptyArray(this.state.selectedItemIds);
        this.isExistPlaceholder = () => !isEmpty(this.props.placeholder);
        this.isSelectedItem = (id) => this.state.selectedItemIds.includes(id);
        this.isShowCheckBox = (type) => {
            switch (type) {
                case SelectionType.Multiple: {
                    return true;
                }
                case SelectionType.Combine: {
                    return true;
                }
                default: {
                    return false;
                }
            }
        };
        this.onBlurProp = () => typeof this.props.onBlur === "function" &&
            this.props.onBlur(this.getSelectedItems(this.props.items, this.state.selectedItemIds));
        this.onChangeProp = (value) => typeof this.props.onChange === "function" && this.props.onChange(value);
        this.setSelectedItemIds = (value, itemType) => this.setState({ selectedItemIds: itemType === "action" ? [] : value });
        this.addSelectedItem = (selectedItemIds, item) => {
            const newSelectedItemIds = [...selectedItemIds, item.id];
            this.setSelectedItemIds(newSelectedItemIds, item.type);
            this.onChangeProp(this.getSelectedItems(this.props.items, newSelectedItemIds));
        };
        this.deleteSelectedItem = (selectedItemIds, item) => {
            const newSelectedItemIds = excludeArrayFromArray(selectedItemIds, [item.id]);
            this.setSelectedItemIds(newSelectedItemIds, item.type);
            this.onChangeProp(this.getSelectedItems(this.props.items, newSelectedItemIds));
        };
        this.toggleMultipleItem = (e, item) => {
            const selectedItemIds = this.props.selectionType === SelectionType.Combine
                ? this.getSelectedItemIdsByType(this.state.selectedItemIds, SelectionType.Multiple)
                : this.state.selectedItemIds;
            this.isSelectedItem(item.id)
                ? this.deleteSelectedItem(selectedItemIds, item)
                : this.addSelectedItem(selectedItemIds, item);
        };
        this.selectSingleItem = (item) => {
            this.setState({ isOpen: false });
            this.setSelectedItemIds([item.id], item.type);
            this.onChangeProp(item);
        };
        this.selectCombineItem = (e, item) => item.type === SelectionType.Multiple ? this.toggleMultipleItem(e, item) : this.selectSingleItem(item);
        this.selectItem = (e, item) => {
            e.stopPropagation();
            e.preventDefault();
            typeof this.props.hideError === "function" && this.props.hideError();
            switch (this.props.selectionType) {
                case SelectionType.Multiple: {
                    return this.toggleMultipleItem(e, item);
                }
                case SelectionType.Combine: {
                    return this.selectCombineItem(e, item);
                }
                default: {
                    return this.selectSingleItem(item);
                }
            }
        };
        this.onClearProp = () => typeof this.props.onClear === "function" && this.props.onClear();
        this.onCloseItemListProp = () => {
            typeof this.props.onCloseItemList === "function" && this.props.onCloseItemList();
            this.onBlurProp();
        };
        this.onOpenItemListProp = (e) => typeof this.props.onOpenItemList === "function" && this.props.onOpenItemList();
        this.clearComboBoxHandler = (e) => {
            e.preventDefault();
            e.stopPropagation();
            this.setState({ isOpen: false, selectedItemIds: [] });
            this.onClearProp();
        };
        this.toggleHandler = (e) => {
            !this.state.isOpen && typeof this.props.showError === "function" && this.props.showError(e);
            !this.state.isOpen && this.props.isAutoPosition && this.openTopCalculation();
            this.setState({ isOpen: !this.state.isOpen });
            this.state.isOpen ? this.onCloseItemListProp() : this.onOpenItemListProp(e);
        };
        this.outSideClick = (e) => {
            const isOutSideClick = this.customSelectRef && !this.customSelectRef.contains(e.target);
            isOutSideClick && typeof this.props.hideError === "function" && this.props.hideError();
            isOutSideClick && this.setState({ isOpen: false });
            isOutSideClick && this.onCloseItemListProp();
        };
        this.outSideClickHandler = (e) => (this.state.isOpen ? this.outSideClick(e) : undefined);
    }
    componentDidMount() {
        this.props.isAutoPosition && this.openTopCalculation();
    }
    UNSAFE_componentWillMount() {
        document.addEventListener("click", this.outSideClickHandler, false);
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { defaultValue, items, value } = nextProps;
        const { isAutoPosition, items: propItems } = this.props;
        isAutoPosition && propItems.length !== items.length && this.openTopCalculationByItemsCount(items.length);
        this.isNeedRefreshItems(nextProps, this.props) && this.onChangeItems(items, defaultValue);
        this.isNeedRefreshSelectedItemIds(nextProps, this.props) &&
            this.setSelectedItemIds(this.getInitialState(items, value, defaultValue));
    }
    componentWillUnmount() {
        document.removeEventListener("click", this.outSideClickHandler, false);
    }
    render() {
        const { bodyTemplate, disabled, isShowClear, items, theme, themeBody, themeList, themeListItem } = this.props;
        const isOpenFlag = this.state.isOpen ? "true" : "";
        return (React.createElement(ComboBox, { disabled: disabled, onClick: this.toggleHandler, ref: (node) => (this.customSelectRef = node), theme: theme },
            isShowClear && React.createElement(ClearIcon, { size: "sm", onClick: this.clearComboBoxHandler }),
            React.createElement(ArrowIcon, { src: arrow, isopen: isOpenFlag }),
            React.createElement(ComboBoxBody, { isopen: isOpenFlag, isPlaceholder: this.isEmptyComboBoxText() && this.isExistPlaceholder(), theme: themeBody }, bodyTemplate
                ? bodyTemplate(this.getSelectedItems(this.props.items, this.state.selectedItemIds))
                : this.defaultBodyTemplate(Object.assign(Object.assign({}, this.props), { selectedItemIds: this.state.selectedItemIds }))),
            React.createElement(OptionList, null,
                React.createElement(OptionUl, { isopen: isOpenFlag, maxItemCount: this.state.maxItemCount, theme: themeList, openTop: this.state.openTop }, Array.isArray(items) ? (items.map((item) => (React.createElement(OptionLi, { key: item.id, onClick: (e) => this.selectItem(e, item), openTop: this.state.openTop, title: this.getTitle(item), theme: themeListItem },
                    this.isShowCheckBox(this.props.selectionType) && (React.createElement(CheckBoxSection, null,
                        React.createElement(CheckBoxInput, { checked: this.isSelectedItem(item.id), readOnly: true, type: "checkbox" }))),
                    item.component ? (React.createElement(item.component.element, Object.assign({}, item.component.props))) : (this.getListItem(item)))))) : (React.createElement(React.Fragment, null))))));
    }
}
const getColor = (color) => (color ? "#FFF" : "#396496");
const ArrowIcon = styled(ReactSVG) `
    color: #396496;
    font-size: 20px;
    path {
        fill: #396496;
    }
    pointer-events: none;
    position: absolute;
    right: 28px;
    top: 10px;
    z-index: ${(props) => getZIndex(props.isopen ? "CustomSelectOpenedIcon" : "CustomSelectClosedIcon")};
`;
const ClearIcon = styled(IconClose) `
    color: #396496;
    height: 12px;
    position: absolute;
    right: 44px;
    top: 18px;
    width: 12px;
    z-index: ${getZIndex("CustomSelectOpenedIcon")};
    &:before,
    &:after {
        background: #396496;
        width: 12px;
    }
`;
const CheckBoxSection = styled.label `
    cursor: pointer;
    height: 16px;
    margin-right: 7px;
    min-width: 16px;
    width: 16px;
`;
const CheckBoxInput = styled.input `
    cursor: pointer;
    height: 100%;
    pointer-events: none;
    width: 100%;
`;
const ComboBox = styled.div `
    ${withDisable};
    cursor: pointer;
    min-width: 150px;
    position: relative;
    ${(props) => css(props.theme)}
`;
const ComboBoxBody = styled.div `
    color: ${(props) => (props.isPlaceholder ? "#757575" : getColor(props.bgColor))};
    cursor: pointer;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 500;
    line-height: 36px;
    max-height: 46px;
    min-height: 46px;
    overflow: hidden;
    position: relative;
    width: 100%;
    ${(props) => props.isopen &&
    css `
            z-index: ${getZIndex("CustomSelectBody")};
        `}
    ${(props) => css(props.theme)}
`;
export const DefaultComboBoxBody = styled.div `
    background: ${({ disabled }) => (disabled ? "#F8F8F8" : "#FFF")};
    border: 1px solid ${({ isError }) => (isError ? "#EB5757" : "#D6D6D6")};
    border-radius: 100px;
    max-height: 46px;
    min-height: 46px;
    overflow: hidden;
    padding: 5px 60px 5px 30px;
    text-overflow: ellipsis;
    white-space: nowrap;
`;
const ItemText = styled.div `
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;
const OptionList = styled.div `
    position: relative;
    width: 100%;
`;
const OptionLi = styled.li `
    align-items: center;
    border-bottom: 1px solid #f2f2f2;
    color: #396496;
    display: flex;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    min-height: ${OPTION_LI_HEIGHT}px;
    padding: 10px 15px;
    &:last-child {
        border: 0;
    }
    &:hover {
        background: #f5f6f7;
        cursor: pointer;
    }
    ${(props) => props.openTop
    ? css `
                  &:first-child:hover {
                      border-top-left-radius: 10px;
                      border-top-right-radius: 10px;
                  }
              `
    : css `
                  &:last-child:hover {
                      border-bottom-left-radius: 10px;
                      border-bottom-right-radius: 10px;
                  }
              `}
    ${(props) => css(props.theme)}
`;
const OptionUl = styled.ul `
    background: #fff;
    border: 1px solid #d6d6d6;
    display: ${({ isopen }) => (isopen ? "flex" : "none")};
    flex-direction: column;
    max-height: ${(props) => props.maxItemCount > -1 ? `${OPTION_LI_HEIGHT * props.maxItemCount}px` : DEFAULT_OPTION_UL_MAX_HEIGHT};
    overflow: auto;
    position: absolute;
    width: 100%;
    ${(props) => props.openTop
    ? css `
                  border-top-left-radius: 10px;
                  border-top-right-radius: 10px;
                  border-bottom: 0;
                  padding-bottom: 23px;
                  bottom: 23px;
              `
    : css `
                  border-bottom-left-radius: 10px;
                  border-bottom-right-radius: 10px;
                  border-top: 0;
                  padding-top: 23px;
                  top: -23px;
              `}
    z-index: ${getZIndex("CustomSelectUl")};
    ${(props) => css(props.theme)}
`;
const WrappedCustomSelect = (props) => {
    const { formatMessage } = useIntl();
    return React.createElement(CustomSelect, Object.assign({}, props, { intl: { formatMessage } }));
};
export default withError(WrappedCustomSelect);
