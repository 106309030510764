import { FOR_EACH_CONTROLLER_MODEL_SCHEMA_NAME, IF_CONTROLLER_MODEL_SCHEMA_NAME, LOOP_CONTROLLER_MODEL_SCHEMA_NAME, TRANSACTION_CONTROLLER_MODEL_SCHEMA_NAME, WHILE_CONTROLLER_MODEL_SCHEMA_NAME } from "./constants";
import { ControllerFlowControlType, ControllerType } from "../../models/threadGroupEditor/enums";
import { getTestProjectModelElDefaultValues } from "../testProjectModelSchema";
import { getThreadGroupElementControllerType } from "../threadGroup";
const generateTransactionData = (modelSchema) => (Object.assign(Object.assign({}, getTestProjectModelElDefaultValues(modelSchema, TRANSACTION_CONTROLLER_MODEL_SCHEMA_NAME)), { type: ControllerType.Transaction }));
const generateForEachElementData = (modelSchema, { endIndexInclusive, outputVariableName, startIndexExclusive, variablePrefix }) => (Object.assign(Object.assign({}, getTestProjectModelElDefaultValues(modelSchema, FOR_EACH_CONTROLLER_MODEL_SCHEMA_NAME)), { endIndexInclusive,
    outputVariableName,
    startIndexExclusive,
    variablePrefix, type: ControllerFlowControlType.ForEach }));
const generateIfElementData = (modelSchema, { condition }) => (Object.assign(Object.assign({}, getTestProjectModelElDefaultValues(modelSchema, IF_CONTROLLER_MODEL_SCHEMA_NAME)), { condition, type: ControllerFlowControlType.If }));
const generateLoopElementData = (modelSchema, { loopCount, infinite }) => (Object.assign(Object.assign({}, getTestProjectModelElDefaultValues(modelSchema, LOOP_CONTROLLER_MODEL_SCHEMA_NAME)), { loopCount, type: ControllerFlowControlType.Loop }));
const generateWhileElementData = (modelSchema, { condition }) => (Object.assign(Object.assign({}, getTestProjectModelElDefaultValues(modelSchema, WHILE_CONTROLLER_MODEL_SCHEMA_NAME)), { condition, type: ControllerFlowControlType.While }));
const individualPropertiesByType = {
    [ControllerType.Transaction]: generateTransactionData,
    [ControllerFlowControlType.ForEach]: generateForEachElementData,
    [ControllerFlowControlType.If]: generateIfElementData,
    [ControllerFlowControlType.Loop]: generateLoopElementData,
    [ControllerFlowControlType.While]: generateWhileElementData
};
const getControllerType = (element) => {
    const controllerType = getThreadGroupElementControllerType(element);
    return controllerType ? controllerType : ControllerType.Transaction;
};
export const generateControllerIndividualPropertiesByType = (modelSchema, params) => {
    const type = getControllerType(params);
    return Object.keys(individualPropertiesByType).includes(type)
        ? individualPropertiesByType[type](modelSchema, params)
        : {};
};
