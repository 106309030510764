import styled from "styled-components";
import { IconCheck } from "../Icons";
import { Color } from "../styled/colorPallete";
import { withDisable } from "../styled/global";
export const Label = styled.div `
    margin-left: 4px;
`;
export const StyledCheckBox = styled.div `
    ${withDisable}
    align-items: center;
    cursor: pointer;
    display: flex;
    padding: 5px;
`;
export const PseudoLabel = styled.div `
    border: 2px solid ${Color.blue};
    border-radius: 2px;
    display: flex;
    height: 16px;
    min-width: 16px;
    position: relative;
    width: 16px;
`;
export const StyledIconCheck = styled(IconCheck) `
    fill: ${Color.white};
    height: 12px;
    opacity: 0;
    width: auto;
`;
export const PseudoInput = styled.input `
    display: none;
    &:checked + ${PseudoLabel} {
        background: ${Color.blue};
        ${StyledIconCheck} {
            opacity: 1;
        }
    }
`;
