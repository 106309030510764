import React from "react";
import { FormattedMessage } from "react-intl";
import { PARAMETER_ACTIONS_DELETE_TEXT, PARAMETER_FILE_NAME_TEXT, PARAMETER_NAMES_TITLE_TEXT } from "./intl";
import { ActionSection, DeleteButton, DownloadCsvFileLink, ParameterHeader, StyledIconDelete } from "./styled";
import { getDownloadableCsvFileName, isExistCsvFiles } from "../../../helpers/requestParameters";
const getParameterNames = (parameterNames = []) => parameterNames.filter((parameter) => parameter !== "").join(", ");
const renderActionSectionByExistFiles = (isExistFiles, onDelete) => isExistFiles ? (React.createElement(ActionSection, null,
    React.createElement(DeleteButton, { onClick: onDelete },
        React.createElement(StyledIconDelete, null),
        React.createElement(FormattedMessage, Object.assign({}, PARAMETER_ACTIONS_DELETE_TEXT))))) : (React.createElement(React.Fragment, null));
const CsvFileName = (props) => props.isSavedCsvFile ? (React.createElement(DownloadCsvFileLink, { onClick: props.onClickDownloadCsvFileLink }, getDownloadableCsvFileName(props.files))) : (getDownloadableCsvFileName(props.files));
export const ParameterCsvHeader = (props) => {
    const isExistFiles = isExistCsvFiles(props.files);
    return (React.createElement(ParameterHeader, { isOpened: props.isOpened, isExistFiles: isExistFiles },
        React.createElement("div", null,
            React.createElement(FormattedMessage, Object.assign({}, PARAMETER_NAMES_TITLE_TEXT, { values: {
                    params: getParameterNames(props.parameterNames)
                } })),
            React.createElement("br", null),
            React.createElement(FormattedMessage, Object.assign({}, PARAMETER_FILE_NAME_TEXT, { values: {
                    fileName: React.createElement(CsvFileName, Object.assign({}, props))
                } }))),
        renderActionSectionByExistFiles(isExistFiles, props.onDelete)));
};
