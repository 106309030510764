import { call, put, take } from "redux-saga/effects";
import { getResponseErrorData } from "../../helpers/errors";
import { recoveryRequest, recoverySuccess, recoveryFailed } from "../actions/reset";
import { recoveryClientPassword } from "../../services/authSrv";
import authorizeActions from "../actions/authorize";
import { loginActions } from "./auth";
const { authSuccess } = authorizeActions;
export function* recoveryFlow() {
    while (true) {
        const action = yield take(recoveryRequest);
        try {
            if (action.type === recoveryRequest.toString()) {
                const { data, headers: { authorization: token } } = yield call(recoveryClientPassword, action.payload);
                yield put(recoverySuccess(data));
                yield put(authSuccess(data));
                yield call(loginActions, token);
            }
        }
        catch (e) {
            yield put(recoveryFailed(getResponseErrorData(e)));
        }
    }
}
