import React from "react";
import { TooltipBody, TooltipItem } from "../../SlaReportProfile/CustomizedChartTooltip";
import { chartTimelineTickFormatter } from "../../../../helpers/chart";
export const CustomizedPFLBChartTooltip = (props) => {
    const { active, payload, label, tooltipMetricsLabels } = props || {};
    return (active &&
        payload &&
        payload.length && (React.createElement(TooltipBody, { className: "recharts-default-tooltip" },
        React.createElement("p", { className: "recharts-tooltip-label" },
            "Time: ",
            chartTimelineTickFormatter(label)),
        React.createElement("ul", { className: "recharts-tooltip-item-list" }, (payload || []).map((tooltipItem, index) => {
            const { color, name, value } = tooltipItem || {};
            return (React.createElement(TooltipItem, { className: "recharts-tooltip-item-list", color: color, key: index },
                React.createElement("span", null,
                    tooltipMetricsLabels[name],
                    ": ",
                    value)));
        })))));
};
