var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { connect } from "react-redux";
import { useIntl } from "@boomq/utils";
import { TEAM_IMAGE_EDITING_TITLE, TEAM_MEMBERS_TITLE, TEAM_NAME_SUBTITLE, TEAM_NAME_TITLE, TEAM_PAGE_TITLE } from "../TeamAdminAccessTemplate";
import { TeamManageUsersAccessTemplate } from "../../../../components/account/Team/TeamManageUsersAccessTemplate";
import { AccessDenied } from "../../../../components/common/AccessDenied";
import { TEAM_ID_PARAM_NAME } from "../../../../helpers/routes";
import { setTitle } from "../../../../redux/actions/accountTitle";
import { clearTeamData } from "../../../../redux/actions/team";
import { changeTeamMembersPage } from "../../../../redux/actions/teamMembers";
import { getTeamImage, getTeamName } from "../../../../redux/reducers/team";
const connector = connect((state) => ({
    image: getTeamImage(state),
    name: getTeamName(state)
}), {
    setTitle,
    onChangeTeamMembersPage: changeTeamMembersPage,
    onUnmount: clearTeamData
});
const TeamManageUsersAccessTemplateContainer = (_a) => {
    var { children, image, intl, name, teamMembers } = _a, restProps = __rest(_a, ["children", "image", "intl", "name", "teamMembers"]);
    const { formatMessage } = useIntl();
    const pageTitle = formatMessage(TEAM_PAGE_TITLE);
    const teamImageTitle = formatMessage(TEAM_IMAGE_EDITING_TITLE);
    const teamMembersTitle = formatMessage(TEAM_MEMBERS_TITLE, { teamMembersCount: restProps.teamMembersCount });
    const teamNameSubtitle = formatMessage(TEAM_NAME_SUBTITLE);
    const teamNameTitle = formatMessage(TEAM_NAME_TITLE);
    return restProps.isExistManageUsers ? (React.createElement(TeamManageUsersAccessTemplate, Object.assign({}, restProps, { pageTitle: pageTitle, teamImage: image, teamImageTitle: teamImageTitle, teamMembersTitle: teamMembersTitle, teamName: name, teamNameSubtitle: teamNameSubtitle, teamNameTitle: teamNameTitle }), children)) : (React.createElement(AccessDenied, null));
};
export default connector(TeamManageUsersAccessTemplateContainer);
