import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { FormattedMessage } from "react-intl";
import { StyledButton } from "../../styled/Buttons";
import { getVisibility, getTitle, getDescription, getButtons, getCloseCallBack } from "../../../redux/reducers/modal";
import { CSSTransition } from 'react-transition-group';
import { closeModal } from '../../../redux/actions/modal';
import { media } from "../../styled/response";
const connector = connect(state => ({
    buttons: getButtons(state),
    closeCallBack: getCloseCallBack(state),
    description: getDescription(state),
    isVisible: getVisibility(state),
    title: getTitle(state)
}), { closeModal });
const ModalWindow = (props) => {
    const closeHandler = () => {
        props.closeModal();
        if (props.closeCallBack) {
            props.closeCallBack();
        }
    };
    return (React.createElement(CSSTransition, { in: props.isVisible, timeout: 300, classNames: 'fade', unmountOnExit: true },
        React.createElement(ModalWrapper, null,
            React.createElement(ModalContent, null,
                props.title && React.createElement(ModalTitle, null,
                    React.createElement(FormattedMessage, Object.assign({}, props.title))),
                props.description &&
                    React.createElement(ModalDescription, null,
                        React.createElement(FormattedMessage, Object.assign({}, props.description))),
                React.createElement(ModalButtons, null,
                    props.buttons && props.buttons.map(({ text, action }) => (React.createElement(StyledButton, { key: text.id, onClick: action, bordered: true },
                        React.createElement(FormattedMessage, Object.assign({}, text))))),
                    React.createElement(StyledButton, { onClick: closeHandler, bordered: true },
                        React.createElement(FormattedMessage, { id: "account.modal.stop.button.close", defaultMessage: "Close" })))))));
};
const ModalWrapper = styled.div `
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(10px);
    &.fade-enter {
        opacity: 0.01;
    }
    &.fade-enter.fade-enter-active {
        opacity: 1;
        transition: all .3s ease-in-out;
    }
    &.fade-exit {
        opacity: 1;
        transition: all .3s ease-in-out;
    }
    &.fade-exit.fade-exit-active {
        opacity: 0.01;
    }
    z-index: 99999;
`;
const ModalContent = styled.div `
  max-width: 520px;
  margin: auto;
  padding: 0 20px;
`;
const ModalTitle = styled.h3 `
  font-size: 30px;
  text-align: center;
  color: #4989D6;
`;
const ModalDescription = styled.p `
  font-size: 18px;
  text-align: center;
  color: #4F4F4F;
  margin-top: 32px;
`;
const ModalButtons = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 36px;
  ${StyledButton} {
    flex: 1;
    margin-left: 24px;
    &:first-child {
        margin-left: 0;
        ${media.tablet} {
            margin-top: 0;      
        }
    }
    ${media.tablet} {
        margin-left: 0;
        margin-top: 15px;
        width: 100%;      
    }
  }
  ${media.tablet} {
    flex-direction: column;
  }
`;
export default connector(ModalWindow);
