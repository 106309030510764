import styled from "styled-components";
export const ActionAddStep = styled.div `
    cursor: pointer;
    > svg {
        height: 10px;
        width: 10px;
    }
    > svg path {
        fill: #677897;
    }
`;
export const AddStepSection = styled.div `
    color: #677897;
    display: flex;
    justify-content: flex-end;
    padding: 10px;
`;
export const ChartBlock = styled.div `
    flex: 1;
    position: relative;
`;
export const LoadProfileSettingsSection = styled.div `
    flex: 1;
`;
export const ScrollingChart = styled.div `
    position: sticky;
    top: 0;
`;
export const TimeLineSection = styled.div `
    padding: 30px 0 30px 60px;
`;
