import { call, put, takeEvery } from "redux-saga/effects";
import { getValueByPath } from "@boomq/utils";
import {
    authorizeActions,
    formatEmail,
    getAuthorizeUser,
    getAuthProviders,
    getResponseErrorData,
    getWorldTime,
    loginActions,
    loginRequest,
    loginSuccess,
    loginFailed
} from "@boomq/common";
import { apiClientLogin } from "../../services/authSrv";
import { timeServerUrl } from "../../helpers/applicationParams";

const { authSuccess, setAuthProviders } = authorizeActions;

export function* getCurrentTime() {
    try {
        const worldTimeResponse = yield call(getWorldTime, timeServerUrl);
        const { utc_datetime } = worldTimeResponse && worldTimeResponse.data ? worldTimeResponse.data : {};

        return new Date(utc_datetime).toISOString();
    } catch (e) {
        return new Date().toISOString();
    }
}

function* loginSuccessFlow(token) {
    yield call(loginActions, token);

    const { data } = yield call(getAuthorizeUser);
    const { data: authProviders } = yield call(getAuthProviders);

    yield put(authSuccess(data));
    yield put(loginSuccess(data));
    yield put(setAuthProviders(authProviders));
}

function* loginRequestFlow(action) {
    try {
        const time = yield* getCurrentTime();
        const authData = { ...action.payload, login: formatEmail(getValueByPath(action.payload, "login")) };
        const response = yield call(apiClientLogin, { ...authData, time });
        const token = response && response.headers ? response.headers.authorization : undefined;

        return token ? yield* loginSuccessFlow(token) : undefined;
        // token && (yield call(updateUserParams, data));
    } catch (e) {
        const { status } = getResponseErrorData(e);
        const code = status === 409 ? "USER_NOT_FOUND" : status;

        yield put(loginFailed({ code, status }));
    }
}

export function* loginFlow() {
    yield takeEvery(loginRequest, loginRequestFlow);
}
