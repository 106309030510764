import React from "react";
import ReactDOM from "react-dom";
import { setRef, useForkRef } from "@boomq/utils";
const getContainer = (container) => {
    container = (typeof container === "function") ? container() : container;
    return ReactDOM.findDOMNode(container);
};
const useEnhancedEffect = (typeof window !== "undefined") ? React.useLayoutEffect : React.useEffect;
const Portal = (props, ref) => {
    const { children, container, disablePortal = false, onRendered } = props;
    const [mountNode, setMountNode] = React.useState(null);
    const handleRef = useForkRef(React.isValidElement(children) ? children.ref : null, ref);
    useEnhancedEffect(() => ((!disablePortal &&
        setMountNode(getContainer(container) || document.body)) || undefined), [container, disablePortal]);
    useEnhancedEffect(() => {
        if (mountNode && !disablePortal) {
            setRef(ref, mountNode);
            return () => setRef(ref, null);
        }
        return undefined;
    }, [ref, mountNode, disablePortal]);
    useEnhancedEffect(() => ((onRendered && (mountNode || disablePortal) &&
        onRendered()) || undefined), [onRendered, mountNode, disablePortal]);
    if (disablePortal) {
        return (React.isValidElement(children))
            ? React.cloneElement(children, { ref: handleRef, })
            : children;
    }
    return mountNode ? ReactDOM.createPortal(children, mountNode) : mountNode;
};
export default React.forwardRef(Portal);
