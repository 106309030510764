export const BLOCK_TITLE = {
    defaultMessage: "Your Account",
    id: "account.user.profile.block.title"
};
export const DATE_FORMAT_LOCALE_LABEL = {
    defaultMessage: "Date format",
    id: "account.user.profile.date.format.locale.label"
};
export const EMAIL_NOTIFICATION_LABEL = {
    defaultMessage: "Receive notification by email when a test is started",
    id: "account.user.profile.email.notification"
};
export const SAVE_BUTTON_LABEL = {
    defaultMessage: "Save",
    id: "account.user.profile.button"
};
export const SECRET_TOKEN_REPLACE_BUTTON_LABEL = {
    defaultMessage: "Replace",
    id: "account.user.profile.button.replace.secret.token"
};
export const SECRET_TOKEN_LABEL = {
    defaultMessage: "API token",
    id: "account.user.profile.secret.token"
};
