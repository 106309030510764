import { Color, IconPlay, Select } from "@boomq/uikit";
import styled from "styled-components";
import { NewItemActionSectionStyles } from "../../NewItemBlock/styled";
export const ResponsePreview = styled.div `
    align-items: center;
    display: flex;
    margin-bottom: 24px;
    margin-top: 24px;
`;
export const ResponsePreviewSourceLabel = styled.div `
    align-items: center;
    cursor: pointer;
    display: flex;
`;
export const ResponsePreviewSourceSelection = styled.div `
    ${NewItemActionSectionStyles}
`;
export const ResponsePreviewSourceSelect = styled(Select) `
    margin-left: 24px;
`;
export const StyledIconPlay = styled(IconPlay) `
    fill: ${Color.blue};
    margin-right: 6px;
    transform: rotate(${({ isshowresponse }) => (isshowresponse === "true" ? 90 : 0)}deg);
`;
