export const COOKIE_REQUIRED_FIELDS = ["name", "value"];
export const ASSERTION_COMMON_REQUIRED_FIELDS = ["condition", "type"];
export const ASSERTION_VALUE_REQUIRED_FIELDS = ["value"];
export const ASSERTION_RESPONSE_BODY_JSON_PATH_REQUIRED_FIELDS = ["jsonPath"];
export const EXTRACTOR_REQUIRED_COMMON_FIELDS = ["variable"];
export const EXTRACTOR_REGEXP_REQUIRED_FIELDS = ["re"];
export const EXTRACTOR_JSON_REQUIRED_FIELDS = ["jsonpath"];
export const EXTRACTOR_XPATH_REQUIRED_FIELDS = ["xpath"];
export const ELEMENT_FOR_EACH_REQUIRED_FIELDS = ["variablePrefix", "outputVariableName"];
export const ELEMENT_IF_REQUIRED_FIELDS = ["condition"];
export const ELEMENT_LOOP_REQUIRED_FIELDS = ["loopCount"];
export const ELEMENT_WHILE_REQUIRED_FIELDS = ["condition"];
export const IMPORT_STATS_DIFF_DAY_COUNT = 30;
