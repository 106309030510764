import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getUniqueId, useIntl } from "@boomq/utils";
import { DEFAULT_HEADER_KEY_PLACEHOLDER, DEFAULT_HEADER_VALUE_PLACEHOLDER } from "./intl";
import { keyValueTheme } from "./styled";
import { withResize } from "../../../../components/styled/response";
import { KeyValue } from "../../../../components/account/KeyValue";
import { NEW_ITEM_ID } from "../../../../helpers/routes";
import { headerItemValidator } from "../../../../helpers/validators";
import { addHeader, changeHeader, deleteHeader } from "../../../../redux/actions/testProjectSettingsSetHeaders";
import { getRequestGlobalParametersList } from "../../../../redux/reducers/requestParameters";
import { getSettingsSetDefaultHeadersData } from "../../../../redux/reducers/testProjectSettingsSetHeaders";
const generateDefaultHeaderNewItem = () => ({
    id: `${NEW_ITEM_ID}_${getUniqueId()}`,
    key: "",
    value: ""
});
const getHeaderParams = (state) => [
    ...getSettingsSetDefaultHeadersData(state),
    generateDefaultHeaderNewItem()
];
const connector = connect((state) => ({
    defaultHeaders: getHeaderParams(state),
    parameterList: getRequestGlobalParametersList(state)
}), { onAdd: addHeader, onChange: changeHeader, onDelete: deleteHeader });
const DefaultHeadersContainer = (props) => {
    const { formatMessage } = useIntl();
    const changeHandler = (id, value) => {
        const { validatedData } = headerItemValidator(value);
        return id.includes(NEW_ITEM_ID)
            ? props.onAdd(Object.assign(Object.assign({}, validatedData), { id: getUniqueId() }))
            : props.onChange({ id, value: validatedData });
    };
    const deleteHandler = (id) => props.onDelete(id);
    const keyPlaceholder = formatMessage(DEFAULT_HEADER_KEY_PLACEHOLDER);
    const valuePlaceholder = formatMessage(DEFAULT_HEADER_VALUE_PLACEHOLDER);
    return (React.createElement(React.Fragment, null, (props.defaultHeaders || []).map(({ error, id, key, value }) => (React.createElement(KeyValue, { error: error, key: id, name: "defaultHeader", onChange: (value) => changeHandler(id, value), onDelete: () => deleteHandler(id), parameters: props.parameterList, placeholderKey: keyPlaceholder, placeholderValue: valuePlaceholder, theme: keyValueTheme, value: { key, value } })))));
};
export default withResize(withRouter(connector(DefaultHeadersContainer)));
