import styled from "styled-components";
import { Color, IconAlertCircle } from "@boomq/uikit";
import { ACTIONS_PANEL_PADDING_TOP_BOTTOM } from "../../../styled/Common";
import { Padding } from "../../../styled/Wrappers";
export const IconErrorBlock = styled.div `
    align-items: center;
    cursor: help;
    display: flex;
    height: calc(100% - ${2 * ACTIONS_PANEL_PADDING_TOP_BOTTOM}px);
    position: absolute;
`;
export const StyledIconAlertCircle = styled(IconAlertCircle) `
    fill: ${Color.blue};
`;
export const StyledPage = styled(Padding) `
    display: flex;
    flex-direction: column;
    height: fit-content;
    margin-bottom: 90px;
`;
