import styled from "styled-components";
import { withDisable } from "../../../styled/global";
export const ButtonRemove = styled.div `
    align-items: center;
    cursor: pointer;
    display: flex;
`;
export const RequestSection = styled.div `
    display: flex;
`;
export const RequestParam = styled.div `
    ${withDisable}
    align-items: center;
    display: flex;
    margin-top: 20px;
    width: 100%;
    > div {
        margin-right: 10px;
        &:last-child {
            margin-right: 0px;
        }
    }
`;
