var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { NewItem, NewItemSection, StyledIconPlus } from "./styled";
export const NewItemButton = (_a) => {
    var { children, onClick } = _a, rest = __rest(_a, ["children", "onClick"]);
    const clickHandler = () => (typeof onClick === "function" ? onClick() : undefined);
    return (React.createElement(NewItemSection, Object.assign({}, rest),
        React.createElement(NewItem, { onClick: clickHandler },
            React.createElement(StyledIconPlus, null),
            children)));
};
