export var ConfigurationElementType;
(function (ConfigurationElementType) {
    ConfigurationElementType["CsvDatasetConfig"] = "CSV_DATASET_CONFIG";
    ConfigurationElementType["HttpAuthManager"] = "HTTP_AUTH_MANAGER";
    ConfigurationElementType["HttpCookieManager"] = "HTTP_COOKIE_MANAGER";
    ConfigurationElementType["HttpHeaderManager"] = "HTTP_HEADER_MANAGER";
    ConfigurationElementType["HttpRequestDefaults"] = "HTTP_REQUEST_DEFAULTS";
    ConfigurationElementType["RandomCsvDatasetConfig"] = "RANDOM_CSV_DATASET_CONFIG";
    ConfigurationElementType["UserDefinedVariables"] = "USER_DEFINED_VARIABLES";
})(ConfigurationElementType || (ConfigurationElementType = {}));
