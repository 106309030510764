import styled, { css } from "styled-components";
import { Block, Color, FontBodyMediumMedium, FontBodySmallMedium, IconDropDownArrowDown, getZIndex } from "@boomq/uikit";
import { getHttpMethodItemColor } from "../../../../models/request";
export const Assertions = styled.div `
    margin: 0 16px;
`;
export const Actions = styled.div `
    display: flex;
    flex: auto;
    justify-content: flex-end;
`;
export const ArrowIcon = styled(IconDropDownArrowDown) `
    fill: ${Color.darkGrey5};
    pointer-events: none;
    position: absolute;
    right: 12px;
    top: 6px;
    transform: rotate(${({ isopen }) => (isopen ? 180 : 0)}deg);
    transition: transform 0.5s;
    z-index: ${({ isopen }) => getZIndex(isopen ? "CustomSelectOpenedIcon" : "CustomSelectClosedIcon")};
`;
export const MethodStyles = `
    align-items: center;
    border: 1px solid;
    border-radius: 12px;
    color: ${Color.darkGrey5};
    display: flex;
    min-width: 110px;
    padding: 8px;
    width: 110px;
`;
export const HttpMethod = styled.div `
    ${MethodStyles}
    background: ${({ method }) => getHttpMethodItemColor(method)};
    border-color: ${({ method }) => getHttpMethodItemColor(method)};
`;
export const RequestElBodyColumn = styled.div `
    ${FontBodySmallMedium}
    color: ${Color.darkGrey5};
    display: flex;
    flex-direction: column;
`;
export const RequestSettings = styled.div `
    align-items: flex-end;
    display: flex;
    height: 100%;
`;
export const RequestMethodBody = styled.div `
    background: ${({ bgColor }) => (bgColor ? bgColor : Color.white)};
    border: 1px solid;
    border-color: ${({ bgColor }) => (bgColor ? bgColor : Color.white)};
    border-radius: 12px;
    color: ${Color.darkGrey5};
    max-height: 36px;
    min-height: 36px;
    padding: 8px 36px 8px 8px;
`;
export const RequestBodySection = styled.div `
    ${FontBodyMediumMedium}
    display: flex;
    margin-top: 4px;
    width: fit-content;
`;
export const RequestParamLabel = styled.span `
    margin-right: 6px;
`;
export const RequestBodyText = styled.span `
    word-break: break-all;
`;
export const RequestBodyTitle = styled(RequestParamLabel) `
    min-width: 50px;
    width: 50px;
`;
export const RequestData = styled.div `
    color: ${({ isExistError }) => (isExistError ? Color.red : Color.darkGrey5)};
    display: flex;
    width: 100%;
    word-break: break-all;
    > span {
        display: flex;
        margin-right: 6px;
        min-width: 50px;
        width: 50px;
    }
`;
export const RequestElBody = styled.div `
    ${Block}
    ${({ isExistError }) => isExistError
    ? css `
                  box-shadow: 0px 0px 8px rgba(255 89 89 / 85%);
              `
    : css ``}
    cursor: pointer;
    display: flex;
    padding: 12px;
    position: relative;
    width: 100%;
`;
export const RequestInfo = styled.div `
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    width: 100%;
`;
export const RequestSection = styled.div `
    ${FontBodyMediumMedium}
    display: flex;
`;
export const TransactionRequest = styled.li `
    align-items: center;
    display: flex;
    margin-top: 16px;
`;
