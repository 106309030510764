import React from "react";
import { useHistory } from "react-router-dom";
import { AllTestsEmpty } from "../../../components/account/AllTestsEmty";
export const AllTestsEmptyContainer = () => {
    const history = useHistory();
    const addNewTest = () => {
        history.push("/account/new-test");
    };
    const goToTests = () => {
        history.push("/account/tests");
    };
    return (React.createElement(AllTestsEmpty, { onClickAddNewTest: addNewTest, onClickGoToTests: goToTests }));
};
