export enum Color {
    black = "#000",
    blue = "#5F8FFE",
    blue2 = "#3572FF",
    green = "#17D663",
    lightBlue = "#69a5ff",
    lightRed = "#ff4f22",
    red = "#ff3000",
    white = "#fff"
}

export enum MediaBreakpoint {
    XS = 480,
    SM = 576,
    MD = 768,
    XMD = 980,
    LG = 992,
    XL = 1366,
    XXL = 1600
}
