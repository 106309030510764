export const gridColumns = [
    {
        align: "left",
        id: 0,
        flex: 1.5,
        headerAlign: "left",
        nameIntlMsg: { id: "account.all.test.project.header.name", defaultMessage: "Name" }
    },
    {
        flex: 1,
        id: 1,
        nameIntlMsg: { id: "account.all.test.project.header.label", defaultMessage: "Label" }
    },
    {
        flex: 1,
        id: 2,
        nameIntlMsg: { id: "account.all.test.project.header.date", defaultMessage: "Last modified date" }
    },
    {
        flex: 1,
        id: 3,
        nameIntlMsg: { id: "account.all.test.project.header.type", defaultMessage: "Load Type" }
    },
    {
        flex: 1,
        id: 4,
        nameIntlMsg: { id: "account.all.test.project.header.request.count", defaultMessage: "Request count" }
    },
    {
        flex: 1,
        id: 5,
        nameIntlMsg: { id: "account.all.test.project.header.duration", defaultMessage: "Duration" }
    },
    {
        id: 6,
        nameIntlMsg: { id: "title.empty", defaultMessage: " " },
        width: "100px"
    }
];
