import { handleActions } from "redux-actions";
import { resetError } from "../../actions/error";
import { registrationRequest, registrationSuccess, registrationFailed, setAgreement } from "../../actions/registration";
export const initialState = {
    agreement: false,
    data: {},
    error: {}
};
export default handleActions({
    [registrationRequest]: (state) => (Object.assign(Object.assign({}, state), { data: {}, error: "" })),
    [registrationSuccess]: (state, action) => (Object.assign(Object.assign({}, state), { data: action.payload })),
    [registrationFailed]: (state, action) => (Object.assign(Object.assign({}, state), { error: Object.assign({}, action.payload) })),
    [resetError]: (state) => (Object.assign(Object.assign({}, state), { error: initialState.error })),
    [setAgreement]: (state, { payload }) => (Object.assign(Object.assign({}, state), { agreement: payload }))
}, initialState);
