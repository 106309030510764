export function debounce(func, wait) {
    let timerId;
    function debounced(...args) {
        if (timerId !== undefined) {
            clearTimeout(timerId);
        }
        timerId = setTimeout(() => {
            func.apply(this, args);
        }, wait);
    }
    return debounced;
}
