import React from "react";
import { useIntl } from "react-intl";
import { isEmpty } from "@boomq/utils";
import { REQUEST_NAME_LABEL } from "./intl";
import { RequestData, RequestParamLabel, RequestSection } from "./styled";
export const RequestName = ({ url, name }) => {
    const { formatMessage } = useIntl();
    return (url === name || isEmpty(name) ? (React.createElement(React.Fragment, null)) : (React.createElement(RequestSection, null,
        React.createElement(RequestData, null,
            React.createElement(RequestParamLabel, null, formatMessage(REQUEST_NAME_LABEL)),
            name))));
};
