import styled from "styled-components";
import { media } from "../styled/response";
export const DropDownItemList = styled.ul `
    box-shadow: 0 0 15px rgba(23, 128, 224, 0.25);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    left: 50%;
    position: absolute;
    top: 30px;
    transform: translateX(-50%);
    z-index: 999;
    ${media.tablet} {
        border-radius: 0;
        box-shadow: inherit;
        left: inherit;
        position: inherit;
        top: inherit;
        transform: inherit;
    }
`;
export const DropDownItem = styled.li `
    cursor: pointer;
    background: #ffffff;
    border: 1px solid transparent;
    border-top-color: #f2f2f2;
    outline: none;
    text-align: left;
    font-weight: 500;
    line-height: 23px;
    font-size: 16px;
    color: #bdbdbd;
    min-width: 70px;
    padding: 6px 14px;
    transition: all 0.3s ease;
    &:first-child {
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        border: none;
        ${media.tablet} {
            border-radius: 0;
            border: 1ps solid #e4ebf2;
        }
    }
    &:last-child {
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        ${media.tablet} {
            border-radius: 0;
            border-bottom: none;
        }
    }
    &:hover {
        color: #6b8cae;
    }
    ${media.tablet} {
        color: #6b8cae;
        border-top-color: #e4ebf2;
        padding: 8px 0;
        margin: 0 32px;
    }
`;
