import { call, put, select } from "redux-saga/effects";
import { changeTeamMemberFailure, copyInviteLinkFailure, setTeamMembers, setTeamMembersLoading, updateTeamMember } from "../actions/teamMembers";
import { setPermissionList } from "../actions/teams";
import { getClientInfo, getSelectedTeamData } from "../reducers/authorizePlate";
import { getTeamId } from "../reducers/team";
import { getTeamMemberList, getTeamMembersCurrentPage, getTeamMembersSize } from "../reducers/teamMembers";
import { NEW_ITEM_ID } from "../../helpers/routes";
import { TeamMemberPermission } from "../../helpers/types";
import { isEmpty } from "../../helpers/utils";
export function* addTeamMemberInNewTeam(data) {
    try {
        const currentTeamMembers = yield select(getTeamMemberList);
        yield put(setTeamMembers({ content: [...currentTeamMembers, data] }));
    }
    catch (e) {
        console.error(e);
    }
}
export function* checkAuthorizedUserByTeamMemberPermissionsUpdating({ teamId, data }) {
    const { id } = yield select(getClientInfo);
    const { id: selectedTeamId } = yield select(getSelectedTeamData);
    const { permissionList, userId } = data || {};
    return Number(teamId) === Number(selectedTeamId) && Number(id) === Number(userId)
        ? yield put(setPermissionList(permissionList))
        : undefined;
}
export function* excludeTeamMemberInNewTeam(data) {
    try {
        const currentTeamMembers = yield select(getTeamMemberList);
        const filteredTeamMembers = currentTeamMembers.filter((teamMember) => teamMember.id !== data.id);
        yield put(setTeamMembers({ content: filteredTeamMembers }));
    }
    catch (e) {
        console.error(e);
    }
}
export function* failTeamMemberChanging() {
    yield put(setTeamMembersLoading(false));
    yield put(changeTeamMemberFailure({ exceptionMessage: "DENIED_TO_DELETE_LAST_ADMIN_FROM_TEAM" }));
}
export function* getTeamMembersData({ teamId, page, pageSize }, teamMembersGettingFunc) {
    const size = pageSize ? pageSize : yield select(getTeamMembersSize);
    const teamMembersPage = isEmpty(page) ? yield select(getTeamMembersCurrentPage) : page;
    return teamMembersGettingFunc
        ? yield call(teamMembersGettingFunc, { teamId, page: teamMembersPage, size })
        : undefined;
}
export function* refreshTeamMembers(action, teamMembersGettingFunc) {
    try {
        yield put(setTeamMembersLoading(true));
        const { data } = yield* getTeamMembersData(action.payload, teamMembersGettingFunc);
        yield put(setTeamMembers(Object.assign(Object.assign({}, data), { isLoading: false })));
    }
    catch (e) {
        yield put(setTeamMembersLoading(false));
    }
}
export function* updateTeamMemberFlow({ teamId, data }, teamMemberUpdatingFunc) {
    const teamMembers = yield select(getTeamMemberList);
    const teamMembersPermissions = teamMembers.reduce((res, teamMember) => teamMember.id === data.id ? [...res, ...data.permissionList] : [...res, ...teamMember.permissionList], []);
    return teamMembersPermissions.includes(TeamMemberPermission.Admin)
        ? yield call(teamMemberUpdatingFunc, teamId, [data])
        : yield* failTeamMemberChanging();
}
export function* initTeamMembersFlow(action, teamMembersGettingFunc) {
    try {
        yield put(setTeamMembersLoading(true));
        const { size, teamId } = action && action.payload ? action.payload : {};
        return teamId === NEW_ITEM_ID
            ? undefined
            : yield* refreshTeamMembers({ payload: { pageSize: size, teamId } }, teamMembersGettingFunc);
    }
    catch (e) {
        yield put(setTeamMembersLoading(false));
    }
}
export function* inviteLinkCopingFlow(action, teamMemberUpdatingFunc) {
    try {
        const teamMemberData = action && action.payload ? action.payload : {};
        const teamId = yield select(getTeamId);
        yield put(setTeamMembersLoading(true));
        const { data } = yield* updateTeamMemberFlow({
            data: Object.assign(Object.assign({}, teamMemberData), { updateInviteExpiration: true }),
            teamId
        }, teamMemberUpdatingFunc);
        const { inviteUrl } = Array.isArray(data) && data.length > 0 ? data[0] : {};
        yield put(setTeamMembersLoading(false));
        return inviteUrl ? yield put(updateTeamMember(data[0])) : yield put(copyInviteLinkFailure());
    }
    catch (e) {
        yield put(setTeamMembersLoading(false));
        yield put(copyInviteLinkFailure());
    }
}
