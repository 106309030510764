import styled, { css } from "styled-components";
import { IconEdit } from "../Icons";
import { Color } from "../styled/colorPallete";
export const StyledValue = styled.div `
    display: flex;
    ${({ theme }) => (theme ? css(theme) : css ``)}
`;
export const StyledIconEdit = styled(IconEdit) `
    cursor: pointer;
    fill: ${Color.blue};
    margin-left: 5px;
    min-width: 22px;
`;
export const Value = styled.div `
    display: flex;
`;
