import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Check, TextSection } from "./styled";
import Hide from "../../../animation/Hide";
import icon from "../../../../assets/images/icons/check.svg";
import { getTestProjectNotificationParams, getTestProjectNotificationVisibility } from "../../../../redux/reducers/testProjectNotification";
const connector = connect((state) => ({
    params: getTestProjectNotificationParams(state),
    isVisible: getTestProjectNotificationVisibility(state)
}));
const TestProjectNotification = ({ isVisible, params }) => {
    const { afterHidingFunc, text, textTheme, theme } = params;
    return isVisible ? (React.createElement(Hide, { afterHidingFunc: afterHidingFunc, duration: "1", timeout: "20", theme: theme },
        React.createElement(TextSection, { onClick: () => (typeof afterHidingFunc === "function" ? afterHidingFunc() : undefined), theme: textTheme },
            React.createElement(Check, { src: icon }),
            text && React.createElement(FormattedMessage, Object.assign({}, text))))) : (React.createElement(React.Fragment, null));
};
export default withRouter(connector(TestProjectNotification));
