import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { getResponseErrorData } from "../../../helpers/errors";
import { hideResourcePlate, addResourceRequest, addResourceSuccess, addResourceFailure, changeResourceRequest, changeResourceSuccess, changeResourceFailure, deleteResourceRequest, deleteResourceSuccess, deleteResourceFailure } from "../../actions/admin/resources";
import { getListOfResources } from "../../reducers/admin/resources";
import { addTestRunnerResource, changeTestRunnerResource, deleteTestRunnerResource } from "../../../services/testRunnerSrv";
function* addResourceFlow({ payload: { params } }) {
    try {
        const { data } = yield call(addTestRunnerResource, params);
        const currentResources = yield select(getListOfResources);
        yield all([put(addResourceSuccess([...currentResources, data])), put(hideResourcePlate())]);
    }
    catch (e) {
        yield put(addResourceFailure(getResponseErrorData(e)));
    }
}
function* changeResourceFlow({ payload: { id, params } }) {
    try {
        const { data } = yield call(changeTestRunnerResource, id, params);
        yield put(changeResourceSuccess({ id, params: data }));
    }
    catch (e) {
        yield put(changeResourceFailure());
    }
}
function* deleteResourceFlow({ payload: { id } }) {
    try {
        yield call(deleteTestRunnerResource, id);
        yield put(deleteResourceSuccess({ id }));
    }
    catch (e) {
        yield put(deleteResourceFailure());
    }
}
export function* resourceFlow() {
    yield takeEvery(addResourceRequest, addResourceFlow);
    yield takeEvery(changeResourceRequest, changeResourceFlow);
    yield takeEvery(deleteResourceRequest, deleteResourceFlow);
}
