export const GRID_HEADER_NAME = { id: "account.reports.header.name", defaultMessage: "Name" };
export const GRID_HEADER_LABEL = { id: "account.reports.header.label", defaultMessage: "Label" };
export const GRID_HEADER_TEST_ID = { id: "account.reports.header.test.id", defaultMessage: "Test id" };
export const GRID_HEADER_LAST_EDITED_BY = {
    id: "account.reports.header.last.edited.by",
    defaultMessage: "Last edited by"
};
export const GRID_HEADER_LAST_MODIFIED_DATE = {
    id: "account.reports.header.last.modified.date",
    defaultMessage: "Last modified date"
};
export const GRID_HEADER_CREATED_DATE = {
    id: "account.reports.header.created.date",
    defaultMessage: "Created date"
};
export const GRID_HEADER_EMPTY = {
    id: "title.empty",
    defaultMessage: " "
};
