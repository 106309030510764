import styled from "styled-components";
import { Color } from "../styled/colorPallete";
import { IconDropDownArrowDown, IconDropDownArrowRight } from "../Icons";
const togglerIconStyle = `
    cursor: pointer;
    fill: ${Color.grey65};
`;
export const ExpandedIcon = styled(IconDropDownArrowDown) `
    ${togglerIconStyle}
`;
export const CollapsedIcon = styled(IconDropDownArrowRight) `
    ${togglerIconStyle}
`;
export const TogglerLine = styled.div `
    background: ${Color.lightGrey95};
    height: calc(100% - 72px);
    left: 24px;
    position: absolute;
    top: 48px;
    width: 1px;
`;
