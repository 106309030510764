import { getUniqueId, isEmpty } from "@boomq/utils";
import { TEST_PROJECT_REQUEST_GROUP_DEFAULT_NAME } from "./constants";
import { getTestProjectParamValue } from "./common";
import { getControllerTypeFromThreadGroupElementData, getThreadGroupElements } from "./threadGroupElements";
import { formatThreadGroupTreeSampler, prepareTestProjectGroupRequest } from "./threadGroupSampler";
import { getThreadGroupElementType, getThreadGroupName } from "../threadGroup";
import { ThreadGroupController } from "../../models/threadGroupEditor";
const formatThreadGroupTree = (threadGroupElements) => (threadGroupElements || []).map((threadGroupElement) => getThreadGroupElementType(threadGroupElement) === ThreadGroupController.Controller
    ? {
        id: threadGroupElement.id,
        children: formatThreadGroupTree(threadGroupElement.children),
        controllerType: getControllerTypeFromThreadGroupElementData(threadGroupElement),
        type: threadGroupElement.type
    }
    : formatThreadGroupTreeSampler(threadGroupElement));
export const getThreadGroupsCommonData = ({ res, index, group, groupId }) => {
    const { extractorParameters, maxTransactionIndex, requests, timers, transactions, elementsData } = getThreadGroupElements({
        elementsData: {},
        extractorParameters: [],
        groupElements: group.threadGroupElements,
        groupId,
        maxTransactionIndex: res.maxTransactionIndex,
        requests: [],
        parentElementId: getUniqueId(),
        path: "",
        timers: {},
        transactions: []
    });
    return {
        extractorParameters: { [groupId]: extractorParameters },
        maxTransactionIndex,
        requests: [...res.requests, ...requests],
        timers: Object.assign(Object.assign({}, res.timers), timers),
        transactions: [...res.transactions, ...transactions],
        threadGroupsTree: Object.assign(Object.assign({}, res.threadGroupsTree), { [groupId]: formatThreadGroupTree(group.threadGroupElements) }),
        elementsData: Object.assign(Object.assign({}, res.elementsData), elementsData)
    };
};
const getTestRunnerIds = (runnerIds) => Array.isArray(runnerIds) ? runnerIds : [];
export const prepareTestProjectGroupRequests = (groupId, requests) => (requests || []).reduce((res, request) => prepareTestProjectGroupRequest(res, request, groupId), {
    extractorParameters: [],
    requests: [],
    timers: {}
});
const prepareThreadGroupResources = (resourceConfiguration) => resourceConfiguration ? { runnerResources: getTestRunnerIds(resourceConfiguration.testRunnerIds) } : {};
export const prepareTestProjectRequestGroup = (groupId, index, groupData) => {
    const { resourceConfiguration } = getTestProjectParamValue(groupData, "boomqConfiguration", {});
    return Object.assign({ id: groupId, index: index + 1, isOpened: true }, prepareThreadGroupResources(resourceConfiguration));
};
const prepareThreadGroupName = (index, groupData) => {
    const groupName = getThreadGroupName(groupData);
    return isEmpty(groupName) ? `${TEST_PROJECT_REQUEST_GROUP_DEFAULT_NAME} ${index + 1}` : groupName;
};
export const prepareThreadGroupData = (groupId, index, groupData) => (Object.assign(Object.assign({}, prepareTestProjectRequestGroup(groupId, index, groupData)), { name: prepareThreadGroupName(index, groupData) }));
