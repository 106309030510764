var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { getValueByPath, parseRequestUrlToParameters } from "@boomq/utils";
import { generateAssertions } from "./assertions";
import { DEFAULT_SAMPLER_CONTROLLER_TYPE } from "./constants";
import { generateExtractors } from "./extractors";
import { generateTestProfileGrpcRequest } from "./grpcRequest";
import { generateTestProfileHttpRequest } from "./httpRequest";
import { prepareTimers } from "./timers";
import { getThreadGroupElementControllerType } from "../threadGroup";
import { RequestType } from "../../models/request/enums";
const generatorByControllerType = {
    [RequestType.Grpc]: generateTestProfileGrpcRequest,
    [RequestType.Http]: generateTestProfileHttpRequest
};
const prepareRequestManualTimers = (requestTimerList, timers) => (requestTimerList || []).map((timerId) => timers[timerId]);
export const prepareResponsePreview = (responsePreview) => responsePreview ? { responsePreview } : {};
export const parseRequestUrlParameters = (url) => {
    const _a = parseRequestUrlToParameters(url), { queryParams } = _a, restParams = __rest(_a, ["queryParams"]);
    return restParams;
};
const generateTestProfileRequestCommonData = ({ requestData, modelSchema, timers = {} }) => (Object.assign({ assertions: generateAssertions(requestData.assertions, modelSchema), body: getValueByPath(requestData, "body.TEXT", ""), bodyParameters: [], extractors: generateExtractors(requestData.extractResponse, modelSchema), method: requestData.method, timerList: prepareTimers(requestData.timerList, timers, modelSchema) }, prepareResponsePreview(requestData.responsePreview)));
const generateRequestDataByControllerType = (params) => {
    const controllerType = getThreadGroupElementControllerType(params.requestData);
    return Object.keys(generatorByControllerType).includes(controllerType)
        ? generatorByControllerType[controllerType](params)
        : generatorByControllerType[DEFAULT_SAMPLER_CONTROLLER_TYPE](params);
};
export const generateTestProfileRequestByType = (params) => (Object.assign(Object.assign({}, generateRequestDataByControllerType(params)), generateTestProfileRequestCommonData(params)));
