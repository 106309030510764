import React from "react";
import { connect } from "react-redux";
import { IntlProvider } from "react-intl";
import PFLBTableContainer from "./PFLBTable";
import { dictionaryRu, dictionaryEn } from "../../../../data/dictionaries";
import { getCurrentLanguage } from "../../../../redux/reducers/languagePlate";
const dictionaries = { ru: dictionaryRu, en: dictionaryEn };
const connector = connect((state) => ({
    currentLanguage: getCurrentLanguage(state)
}));
const PFLBTableWithIntl = ({ currentLanguage, id, isShowParamsSidebar, onInitData, onRefresh, onToggleTableParamsSidebar }) => (React.createElement(IntlProvider, { locale: currentLanguage, messages: dictionaries[currentLanguage] },
    React.createElement(PFLBTableContainer, { id: id, isShowParamsSidebar: isShowParamsSidebar, onInitData: onInitData, onRefresh: onRefresh, onToggleTableParamsSidebar: onToggleTableParamsSidebar })));
export default connector(PFLBTableWithIntl);
