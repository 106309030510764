import { load as convertYamlToObj } from "js-yaml";
import { getTestProjectDataByLoadProfileType } from "./loadProfile";
import { getTestProjectCommonDataFromYaml } from "./parsers2";
import { prepareSlaGroupList } from "./sla";
import { getDefaultTimerFromYaml } from "./timers";
export const parseTestProjectComposedByUser = (testProjectData, testProjectYamlData) => {
    const testPlanDataFromYaml = testProjectYamlData ? testProjectYamlData.boomqTestPlan : {};
    return Object.assign(Object.assign(Object.assign({}, getTestProjectCommonDataFromYaml(testProjectData, testProjectYamlData)), { defaultTimer: getDefaultTimerFromYaml(testProjectData, testPlanDataFromYaml), sla: prepareSlaGroupList(testProjectYamlData.slaGroupList) }), getTestProjectDataByLoadProfileType(testPlanDataFromYaml));
};
export const parseTestProjectComposedByUserFromYaml = (testProjectData) => {
    const testProjectYamlData = convertYamlToObj(testProjectData.yaml);
    return parseTestProjectComposedByUser(testProjectData, testProjectYamlData);
};
