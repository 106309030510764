var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { isEmpty } from "@boomq/utils";
import { IconRemoveSection, RemoveButton, RequestParam, StyledInputWithParameters } from "./styled";
import { IconDeleteGrey } from "../../styled/StyledIcons";
const changeErrorField = (error, field) => (Object.assign(Object.assign({}, (error || {})), { [field]: undefined }));
export const KeyValue = (_a) => {
    var { disabled, labelKey = "", labelValue = "", onDelete, placeholderKey = "Key", placeholderValue = "Value", theme, value } = _a, rest = __rest(_a, ["disabled", "labelKey", "labelValue", "onDelete", "placeholderKey", "placeholderValue", "theme", "value"]);
    const blurFormatFunc = (newValue = "") => (newValue || "").trim();
    const changeHandler = (field, newValue) => typeof rest.onChange === "function"
        ? rest.onChange(Object.assign(Object.assign({}, value), { [field]: newValue, error: changeErrorField(rest.error, field) }))
        : undefined;
    return (React.createElement(RequestParam, { theme: theme },
        React.createElement(StyledInputWithParameters, Object.assign({ disabled: disabled, label: labelKey || placeholderKey, value: value }, rest, { onBlurFormatFunc: blurFormatFunc, onChange: changeHandler, paramName: "key", placeholder: placeholderKey })),
        React.createElement(StyledInputWithParameters, Object.assign({ disabled: disabled, label: labelValue || placeholderValue, value: value }, rest, { onChange: changeHandler, paramName: "value", placeholder: placeholderValue })),
        React.createElement(IconRemoveSection, null, !disabled && (!isEmpty(value.key) || !isEmpty(value.value)) && (React.createElement(RemoveButton, { onClick: onDelete },
            React.createElement(IconDeleteGrey, null))))));
};
