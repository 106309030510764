const getCurrentLocation = () => (window && window.location ? window.location : {});
export const getCurrentPathName = () => {
    const { pathname } = getCurrentLocation();
    return pathname ? pathname : "";
};
export const getHostUrl = () => {
    const { host, protocol } = getCurrentLocation();
    return `${protocol}//${host}`;
};
export const isHttpProtocolSecure = () => {
    const { protocol } = getCurrentLocation();
    return protocol === "https:";
};
