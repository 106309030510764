import { takeEvery, call, put } from "redux-saga/effects";
import { getHostUrl, getValueByPath, isEmpty } from "@boomq/utils";
import { userDelete, changeUserTariffRequest, changeUserTariffSuccess, changeUserTariffFailure, changeStateRequest, changeStateSuccess, changeStateFailure, changeRoleRequest, changeRoleSuccess, changeRoleFailure, deleteUserRequest, deleteUserSuccess, deleteUserFailure, resetPasswordFailure, resetPasswordRequest, resetPasswordSuccess } from "../../actions/admin/users";
import { closeModal } from "../../actions/modal";
import { changeUserTariff, changeUserState, changeUserRole, deleteUser } from "../../../helpers/admin.api";
import { resetAdminUserPassword } from "../../../services/authSrv";
function* changeTariff({ payload: { id, tariff } }) {
    try {
        yield call(changeUserTariff, id, tariff);
        yield put(changeUserTariffSuccess());
    }
    catch (e) {
        yield put(changeUserTariffFailure(e));
    }
}
function* changeState({ payload: { id, state } }) {
    try {
        yield call(changeUserState, id, state);
        yield put(changeStateSuccess());
    }
    catch (e) {
        yield put(changeStateFailure(e));
    }
}
function* changeRole({ payload: { id, role } }) {
    try {
        yield call(changeUserRole, id, role);
        yield put(changeRoleSuccess());
    }
    catch (e) {
        yield put(changeRoleFailure(e));
    }
}
function* deleteUserFlow({ payload: { id } }) {
    try {
        yield put(closeModal());
        yield call(deleteUser, id);
        yield put(deleteUserSuccess(id));
        yield put(userDelete(id));
    }
    catch (e) {
        yield put(deleteUserFailure(id));
    }
}
function* resetUserPassword(userId) {
    try {
        const { data } = yield call(resetAdminUserPassword, userId);
        const resetUrl = `${getHostUrl()}${data}`;
        yield put(resetPasswordSuccess({ userId, resetUrl }));
    }
    catch (e) {
        yield put(resetPasswordFailure(e));
    }
}
function* resetPasswordFlow(action) {
    try {
        const userId = getValueByPath(action, "payload");
        return isEmpty(userId) ? yield put(resetPasswordFailure()) : yield* resetUserPassword(userId);
    }
    catch (e) {
        yield put(resetPasswordFailure(e));
    }
}
export function* userFlow() {
    yield takeEvery(changeUserTariffRequest, changeTariff);
    yield takeEvery(changeStateRequest, changeState);
    yield takeEvery(changeRoleRequest, changeRole);
    yield takeEvery(deleteUserRequest, deleteUserFlow);
    yield takeEvery(resetPasswordRequest, resetPasswordFlow);
}
