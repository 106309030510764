import styled, { css } from "styled-components";
import { SwitcherDirection } from "./Switcher";
import { Color } from "../styled/colorPallete";
export const StyledCheckbox = styled.span `
    background: ${Color.lightGrey85};
    border-radius: 16px;
    cursor: pointer;
    height: ${({ direction }) => (direction === SwitcherDirection.Vertical ? "40px" : "20px")};
    display: block;
    position: relative;
    transition: all 0.3s ease-in-out;
    width: ${({ direction }) => (direction === SwitcherDirection.Vertical ? "20px" : "40px")};
    &:before {
        background: ${Color.white};
        border-radius: 50%;
        content: "";
        display: block;
        height: 16px;
        left: 2px;
        position: absolute;
        top: ${({ direction }) => (direction === SwitcherDirection.Vertical ? "22px" : "2px")};
        transition: all 0.3s ease-in-out;
        width: 16px;
    }
    ${({ theme }) => (theme ? css(theme) : css ``)}
`;
export const PseudoInput = styled.input `
    display: none;
    &:checked + ${StyledCheckbox} {
        background: ${Color.green};
        &:before {
            ${({ direction }) => direction === SwitcherDirection.Vertical
    ? css `top: 2px;`
    : css `left: 22px;`};
        }
    }
`;
