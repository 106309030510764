import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import {
    addTeamMember,
    calculateTeamMemberItemsCount,
    changeTeamMember,
    changeTeamMembersPage,
    copyInviteLink,
    excludeTeamMember,
    getIsAuthorize,
    getTeamId,
    getTeamMemberList,
    getTeamMembersCurrentPage,
    getTeamMembersIsLoading,
    getTeamMembersPagesCount,
    initTeamMembers
} from "@boomq/common";
import { TeamMembers } from "../../../../components/account/Team/TeamMembers";

const connector = connect(
    (state) => ({
        currentPage: getTeamMembersCurrentPage(state),
        isAuthorized: getIsAuthorize(state),
        isLoading: getTeamMembersIsLoading(state),
        items: getTeamMemberList(state),
        itemsPagesCount: getTeamMembersPagesCount(state),
        teamIdFromStore: getTeamId(state)
    }),
    {
        onAdd: addTeamMember,
        onChange: changeTeamMember,
        onChangePage: changeTeamMembersPage,
        onCopyLink: copyInviteLink,
        onDelete: excludeTeamMember,
        onInit: initTeamMembers
    }
);

const TeamMembersContainer = (props): JSX.Element => {
    const teamMemberListEl = useRef(null);

    const onInit = (params): void =>
        props.teamId === props.teamIdFromStore && typeof props.onInit === "function" ? props.onInit(params) : undefined;

    useEffect(() => {
        onInit({
            page: props.currentPage,
            size: calculateTeamMemberItemsCount(teamMemberListEl.current),
            teamId: props.teamId
        });
    }, [props.teamIdFromStore]);

    return <TeamMembers {...props} ref={teamMemberListEl} />;
};

export default connector(TeamMembersContainer);
