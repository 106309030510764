import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFullDateLocaleTimeZone, useEventListener, useIntl } from "@boomq/utils";
import { ANOMALIES_DETAILS_TITLE } from "./intl";
import { TestDetailsTransaction } from "../../../../components/account/TestDetailsTransaction";
import { ANOMALIES_DETAILS_LABELS, getAnomalyDetails, getAnomalyTransactionDetailsData } from "../../../../models/anomalyClassifications";
import { getGraphDataTransactionAnomalies, refreshTransactionList, setCurrentTransaction, setTransactionDataAnomalies, setTransactionList, stopGraphDataTransactionSse } from "../../../../redux/actions/testAnomalies";
import { getCurrentDateFormatLocale } from "../../../../redux/reducers/authorizePlate";
import { getAnomalyEnabled, getCurrentTransaction, getSensitivity, transactionDataAnomalies, getTransactionList } from "../../../../redux/reducers/testAnomalies";
export const TestDetailsTransactionContainer = ({ chartResponseLabel, chartVUsersLabel, leftYAxisResponseTimeMaxLength, rightYAxisValMaxLength, testDetails }) => {
    const [isVisibleTooltipLink, setIsVisibleTooltipLink] = useState(false);
    const [placeholderSelect, setPlaceholderSelect] = useState("");
    const [tooltipLinkPosition, setTooltipLinkPosition] = useState({ x: 0, y: 0 });
    const sensitivityRef = useRef();
    const testIdRef = useRef(testDetails === null || testDetails === void 0 ? void 0 : testDetails.id);
    const transactionRef = useRef();
    const dispatch = useDispatch();
    const { formatMessage } = useIntl();
    const chartData = useSelector(transactionDataAnomalies) || [];
    const currentTransaction = useSelector(getCurrentTransaction);
    const dateFormatLocale = useSelector(getCurrentDateFormatLocale);
    const isAnomalyEnabled = useSelector(getAnomalyEnabled);
    const sensitivity = useSelector(getSensitivity);
    const transactionList = useSelector(getTransactionList) || [];
    const handleVisibilityChange = () => {
        const { threadGroupName, transactionName } = transactionRef.current || {};
        testIdRef.current > 0 && document.visibilityState === "visible" && transactionRef.current
            ? dispatch(getGraphDataTransactionAnomalies({
                testId: testIdRef.current,
                threadGroupName,
                transactionName,
                sensitivity: sensitivityRef.current
            }))
            : dispatch(stopGraphDataTransactionSse());
    };
    const [addEventListener, removeEventListener] = useEventListener(document, "visibilitychange", handleVisibilityChange);
    const getGraphDataWithAnomalies = (data) => {
        transactionRef.current = data;
        const { threadGroupName, transactionName } = transactionRef.current || {};
        dispatch(stopGraphDataTransactionSse());
        data && dispatch(setCurrentTransaction(data));
        return testIdRef.current > 0 && transactionRef.current
            ? dispatch(getGraphDataTransactionAnomalies({
                testId: testIdRef.current,
                threadGroupName,
                transactionName,
                sensitivity
            }))
            : undefined;
    };
    const refreshTransactionListByParams = (testProjectId, testProjectVersionId) => testProjectId && testProjectVersionId
        ? dispatch(refreshTransactionList({
            projectId: testProjectId,
            projectVersion: testProjectVersionId
        }))
        : undefined;
    const setSelectPlaceholderByTransactionList = (transactionList) => { var _a; return setPlaceholderSelect(((_a = transactionList === null || transactionList === void 0 ? void 0 : transactionList[0]) === null || _a === void 0 ? void 0 : _a.transactionName) || ""); };
    useEffect(() => {
        const { testProjectId, testProjectVersionId } = testDetails;
        refreshTransactionListByParams(testProjectId, testProjectVersionId);
    }, [testDetails.testProjectId, testDetails.testProjectVersionId]);
    useEffect(() => {
        sensitivityRef.current = sensitivity;
        testIdRef.current = testDetails === null || testDetails === void 0 ? void 0 : testDetails.id;
        setSelectPlaceholderByTransactionList(transactionList);
        getGraphDataWithAnomalies(currentTransaction);
    }, [transactionList, testDetails.id, sensitivity]);
    useEffect(() => {
        addEventListener();
        return () => {
            dispatch(stopGraphDataTransactionSse());
            dispatch(setCurrentTransaction(null));
            dispatch(setTransactionDataAnomalies([]));
            dispatch(setTransactionList([]));
            removeEventListener();
        };
    }, []);
    const anomalyChartData = chartData.map((data) => {
        const { anomalyColor, anomalyDescription } = getAnomalyDetails(data === null || data === void 0 ? void 0 : data.classification);
        return Object.assign(Object.assign({}, data), { anomalyColor,
            anomalyDescription });
    });
    const anomalyTransactionDetailsData = getAnomalyTransactionDetailsData(anomalyChartData);
    const anomalyTransactionDetails = (anomalyTransactionDetailsData || []).map((data) => ({
        anomalyDescription: formatMessage(data),
        createdAt: getFullDateLocaleTimeZone(dateFormatLocale, testDetails.startDate)
    }));
    const handleDotClick = (cx, cy) => {
        setIsVisibleTooltipLink(!isVisibleTooltipLink);
        setTooltipLinkPosition({ x: cx, y: cy - 50 });
    };
    const handleGraphClick = () => isVisibleTooltipLink ? setIsVisibleTooltipLink(!isVisibleTooltipLink) : undefined;
    const detailsTitle = formatMessage(ANOMALIES_DETAILS_TITLE);
    const anomalyDetailsLabels = ANOMALIES_DETAILS_LABELS.map((label) => formatMessage(label));
    return (React.createElement(TestDetailsTransaction, { anomalyDetailsLabels: anomalyDetailsLabels, anomalyTransactionDetails: anomalyTransactionDetails, chartData: anomalyChartData, chartResponseLabel: chartResponseLabel, chartVUsersLabel: chartVUsersLabel, detailsTitle: detailsTitle, isAnomalyEnabled: isAnomalyEnabled, isVisibleTooltipLink: isVisibleTooltipLink, leftYAxisResponseTimeMaxLength: leftYAxisResponseTimeMaxLength, onChangeDataTransaction: getGraphDataWithAnomalies, onClickDot: handleDotClick, onClickGraph: handleGraphClick, placeholderSelect: placeholderSelect, rightYAxisValMaxLength: rightYAxisValMaxLength, startDate: testDetails.startDate, testDetails: testDetails, tooltipLinkPosition: tooltipLinkPosition, transactionList: transactionList }));
};
