import styled, { css } from "styled-components";
import { Block, Color, FontBodyMediumMedium, IconDnD } from "@boomq/uikit";
const DRAG_AND_DROP_ICON_MARGIN_LEFT = 8;
const DRAG_AND_DROP_ICON_WIDTH = 16;
export const DragAndDropIcon = styled(IconDnD) `
    fill: ${Color.grey75};
    margin-right: 8px;
`;
export const DragAndDropIconBlock = styled.div `
    align-items: center;
    display: none;
    margin-left: ${DRAG_AND_DROP_ICON_MARGIN_LEFT + DRAG_AND_DROP_ICON_WIDTH}px;
    width: 0px;
    &:hover ${DragAndDropIcon} {
        width: ${DRAG_AND_DROP_ICON_WIDTH}px;
        margin-left: ${DRAG_AND_DROP_ICON_MARGIN_LEFT}px;
    }
`;
export const Transaction = styled.div `
    ${Block}
    ${({ isExistError }) => isExistError
    ? css `
                  box-shadow: 0px 0px 8px rgba(255 89 89 / 85%);
              `
    : css ``}
    display: flex;
    flex-direction: column;
    height: max-content;
    padding: 16px 12px;
    width: 100%;
`;
export const TransactionHeader = styled.div `
    ${FontBodyMediumMedium}
    align-items: center;
    display: flex;
    width: 100%;
`;
export const TransactionElBody = styled.div `
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    width: 100%;
`;
