import styled from "styled-components";
import { Color, FontBodyBigMedium, FontBodyMediumMedium } from "@boomq/uikit";
import { MenuListItem, MenuListItemLink, MenuListItemNavLink } from "../../common/MenuListItem";
import { media } from "../../styled/response";
export const StyledMenuListItem = styled(MenuListItem) `
    ${FontBodyBigMedium}
    align-items: center;
    color: ${Color.grey65};
    display: flex;
    justify-content: center;
    padding: 0 14px;
    position: relative;
    transition: all 0.5s ease;
    .menu_list_item_icon {
        fill: ${Color.grey65};
        margin-right: 6px;
    }
    &:active {
        ${MenuListItemLink}, ${MenuListItemNavLink} {
            transform: scale(0.85);
        }
    }
    &:hover {
        .menu_list_item_icon {
            fill: ${Color.blue};
        }
    }
    .menu__nav-img {
        height: 38px;
    }
    &.active {
        color: ${Color.blue};

        &:before {
            height: 100%;
        }
        .menu_list_item_icon {
            fill: ${Color.blue};
        }
        ${MenuListItemLink}, ${MenuListItemNavLink} {
            color: ${Color.blue};
        }
    }
`;
export const StyledNav = styled.nav `
    display: flex;
    & > :first-child {
        ${FontBodyMediumMedium}
        background-color: #5f8ffe;
        padding: 8px 8px 8px 0;
        margin-right: 16px;
        border-radius: 8px;
        display: flex;
        transition: all 0.3s ease;
        & > :last-child {
            color: ${Color.white};
        }
        .menu_list_item_icon {
            fill: ${Color.white};
        }
        :active {
            transform: scale(0.9);
        }
    }
    ${media.desktop} {
        & > :not(:first-child) {
            span {
                display: none;
            }
        }
    }
`;
