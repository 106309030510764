var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { useIntl } from "@boomq/utils";
import { InputNumber, Select, Switcher } from "@boomq/uikit";
import { TIMER_TYPE_LABEL } from "./intl";
import { DefaultTimerParamValue } from "./styled";
import { getTimerInputUnit, getTimerTypeItemsByParamName, getTimerValueParams } from "../../../../helpers/requestTimers";
import { requestTimerValidator } from "../../../../helpers/validators";
import { RequestTimerParams } from "../../../../helpers/types";
import { getTimerType } from "../../../../models/requestTimer";
export const DefaultTimer = (props) => {
    const _a = props || {}, { enabled, onChange, onChangeTimerType } = _a, restProps = __rest(_a, ["enabled", "onChange", "onChangeTimerType"]);
    const { formatMessage } = useIntl();
    const changeValueHandler = (subfield, value) => typeof onChange === "function" ? onChange({ field: "defaultTimer", subfield, value }) : undefined;
    const changeTimerTypeHandler = (item) => typeof onChangeTimerType === "function" ? onChangeTimerType(item.value) : undefined;
    const getFormattedTimerValueLabel = (timerValueParamName) => formatMessage({
        defaultMessage: timerValueParamName,
        id: `account.modal.default.timer.settings.${timerValueParamName}.text`
    });
    const toggleIsEnabledHandler = () => changeValueHandler("enabled", !enabled);
    const defaultTimerTypeItems = getTimerTypeItemsByParamName(RequestTimerParams.Type).map((defaultTimerTypeItem) => (Object.assign(Object.assign({}, defaultTimerTypeItem), { placeholder: Object.keys(defaultTimerTypeItem.placeholder).reduce((res, placeholderItemName) => (Object.assign(Object.assign({}, res), { [placeholderItemName]: formatMessage(defaultTimerTypeItem.placeholder[placeholderItemName]) })), {}), text: formatMessage(defaultTimerTypeItem.text) })));
    const timerTypeLabel = formatMessage(TIMER_TYPE_LABEL);
    const renderTimerValueBlockByType = (timer) => {
        const { error } = requestTimerValidator(timer);
        return getTimerValueParams(timer).map((timerValueParamName) => (React.createElement(DefaultTimerParamValue, { key: timerValueParamName },
            React.createElement(InputNumber, { decimalCount: 2, enabled: enabled, error: error, label: getFormattedTimerValueLabel(timerValueParamName), name: `Timer_${timerValueParamName}`, onBlur: (value) => changeValueHandler(timerValueParamName, Number(value)), placeholder: getFormattedTimerValueLabel(timerValueParamName), unit: getTimerInputUnit(), value: timer[timerValueParamName] }))));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Switcher, { defaultChecked: enabled, name: "enabled", onChange: toggleIsEnabledHandler }),
        React.createElement(Select, { defaultValue: getTimerType(restProps), disabled: !enabled, items: defaultTimerTypeItems, label: timerTypeLabel, onChange: changeTimerTypeHandler, placeholder: timerTypeLabel }),
        renderTimerValueBlockByType(props)));
};
