import { IconCsvFile } from "@boomq/uikit";
import { DEFAULT_ACTIVE_TAB_NAME_FROM, DEFAULT_REQUEST_PARAMETER_TYPE_LIST } from "./constants";
import { RequestParameter } from "./enums";
import { CSV_PARAMETERS_TAB_LABEL, LITERAL_PARAMETERS_TAB_LABEL } from "./intl";
import { LiteralParameters } from "../../components/account/LiteralParameters";
import { IconParameters } from "../../components/styled/Icons";
import { ImportParametersCsvContainer } from "../../containers/account/TestProject/ImportParametersCsv";
import { TestType } from "../../helpers/types";
const activeTabNameByType = {
    [RequestParameter.Csv]: "csvParameters",
    [RequestParameter.Literal]: "literalParameters"
};
export const requestParameterIconByType = {
    [RequestParameter.Csv]: IconCsvFile,
    [RequestParameter.Literal]: IconParameters
};
const requestParametersByTestType = {
    [TestType.JMX]: [],
    [TestType.Scalability]: Object.values(RequestParameter),
    [TestType.Stable]: Object.values(RequestParameter)
};
export const requestParameterTabItems = [
    {
        id: 0,
        component: {
            elementName: "LiteralParameters",
            props: {}
        },
        name: "literalParameters",
        title: LITERAL_PARAMETERS_TAB_LABEL
    },
    {
        id: 1,
        component: {
            elementName: "ImportParametersCsv",
            props: {}
        },
        name: "csvParameters",
        title: CSV_PARAMETERS_TAB_LABEL
    }
];
export const requestParametersElements = {
    ImportParametersCsv: ImportParametersCsvContainer,
    LiteralParameters
};
export const getRequestParametersFormActiveTabNameByType = (type) => type in activeTabNameByType ? activeTabNameByType[type] : DEFAULT_ACTIVE_TAB_NAME_FROM;
export const getRequestParameterTypesByTestType = (testType) => testType in requestParametersByTestType
    ? requestParametersByTestType[testType]
    : DEFAULT_REQUEST_PARAMETER_TYPE_LIST;
