import { ComponentType } from "react";
import { getThreadGroupTitleTemplateByTypeAndMode as getCommonThreadGroupTitleTemplateByTypeAndMode } from "@boomq/common";
import { RequestGroupJMXCaption } from "../../components/account/TestProject/RequestGroupJMXCaption";
import { RequestGroupJMXCaptionEditMode } from "../../components/account/TestProject/RequestGroupJMXCaptionEditMode";
import { Mode, RequestGroupType } from "../types";

const threadGroupCaptionTemplates: any = {
    [RequestGroupType.Ultimate2]: {
        [Mode.View]: RequestGroupJMXCaption,
        [Mode.Edit]: RequestGroupJMXCaptionEditMode
    },
    [RequestGroupType.SimpleThreadGroup]: {
        [Mode.View]: RequestGroupJMXCaption,
        [Mode.Edit]: RequestGroupJMXCaptionEditMode
    }
};

export const getThreadGroupTitleTemplateByTypeAndMode = (type, mode): ComponentType =>
    getCommonThreadGroupTitleTemplateByTypeAndMode(type, mode, threadGroupCaptionTemplates);
