import styled from "styled-components";
export const customButtonSaveTheme = {
    lineHeight: "17px",
    width: "auto!important"
};
export const customHintTheme = {
    left: "calc(50% + 75px)",
    position: "absolute",
    top: "28%"
};
export const customTabListTheme = { marginBottom: "16px" };
export const ButtonBlock = styled.div `
    display: flex;
    flex: auto;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: 24px;
`;
export const ButtonWithHintSection = styled.div `
    display: flex;
    justify-content: center;
    position: relative;
`;
