import React, { FC } from "react";
import { connect } from "react-redux";
import { useIntl } from "@boomq/utils";
import {
    getClientRole,
    getTeamMemberPermissionMessageByValue,
    getTeamMemberPermissionMessageByModeAndValue
} from "@boomq/common";
import {
    TeamMembersItemTemplateEdit,
    TeamMembersItemTemplateEditHandlers,
    TeamMembersItemTemplateEditProps
} from "../../../../components/account/Team/TeamMembersItemTemplateEdit";
import {
    getTeamMemberPermissionItemsByAdminAccess,
    getTeamMemberManageUsersPermissionItemsByRoles
} from "../../../../helpers/teams";
import { Mode } from "../../../../helpers/types";

const connector = connect((state) => ({
    roles: getClientRole(state)
}));

const WrappedTeamMembersItemTemplateEdit: FC<
    TeamMembersItemTemplateEditProps & TeamMembersItemTemplateEditHandlers
> = ({
    isExistAdminAccess,
    roles,
    ...restProps
}: TeamMembersItemTemplateEditProps & TeamMembersItemTemplateEditHandlers): JSX.Element => {
    const { formatMessage } = useIntl();

    const permissionItems = getTeamMemberPermissionItemsByAdminAccess(isExistAdminAccess).map((permissionItem) => ({
        ...permissionItem,
        text: formatMessage(getTeamMemberPermissionMessageByValue(permissionItem.value))
    }));

    const manageUsersItems = getTeamMemberManageUsersPermissionItemsByRoles(roles).map((permissionItem) => ({
        ...permissionItem,
        text: formatMessage(getTeamMemberPermissionMessageByModeAndValue(Mode.Edit, permissionItem.value))
    }));

    return (
        <TeamMembersItemTemplateEdit
            {...restProps}
            manageUsersItems={manageUsersItems}
            permissionItems={permissionItems}
        />
    );
};

export default connector(WrappedTeamMembersItemTemplateEdit);
