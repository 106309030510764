import React from "react";
import { CommonProfileSettingsContainer, NewThreadGroupButton } from "@boomq/common";
import { ThreadGroups } from "./ThreadGroups";

export const ProfileSettings = ({
    newThreadGroupButtonLabel,
    onAddThreadGroup,
    threadGroups,
    loadGeneratorLabel,
    profileDistribution
}) => (
    <CommonProfileSettingsContainer>
        <ThreadGroups
            loadGeneratorLabel={loadGeneratorLabel}
            profileDistribution={profileDistribution}
            threadGroups={threadGroups}
        />

        <NewThreadGroupButton onClick={onAddThreadGroup}>{newThreadGroupButtonLabel}</NewThreadGroupButton>
    </CommonProfileSettingsContainer>
);
