var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState } from "react";
import { Switcher, Tabs } from "@boomq/uikit";
import { DEFAULT_REQUEST_RESPONSE_REVIEW_TAB } from "./constants";
import { Body, BodyModeSwitcherLabel, BodyPreviewModeSwitcherWrapper, RequestResponsePreview, Title } from "./styled";
import { RequestResponseBody } from "../RequestResponseBody";
import { RequestResponseCookies } from "../RequestResponseCookies";
import { RequestResponseHeaders } from "../RequestResponseHeaders";
import { getRequestResponsePreviewTabItems, TabName } from "../../../../models/requestResponsePreview";
const responsePreviewElements = {
    RequestResponseBody,
    RequestResponseCookies,
    RequestResponseHeaders
};
export const BodyPreviewModeSwitcher = (_a) => {
    var { label } = _a, restProps = __rest(_a, ["label"]);
    return (React.createElement(BodyPreviewModeSwitcherWrapper, null,
        React.createElement(Switcher, Object.assign({}, restProps)),
        React.createElement(BodyModeSwitcherLabel, null, label)));
};
export const RequestResponsePreviewData = ({ responseBodyPreviewModeLabel, responsePreviewData, responsePreviewTitle }) => {
    const [tabName, setTabName] = useState(String(TabName.Headers));
    const [isBodyBeautifyMode, setIsBodyBeautifyMode] = useState(true);
    const toggleBodyBeautifyModeHandler = () => setIsBodyBeautifyMode(!isBodyBeautifyMode);
    return (React.createElement(RequestResponsePreview, null,
        React.createElement(Title, null,
            responsePreviewTitle,
            tabName === TabName.Body && (React.createElement(BodyPreviewModeSwitcher, { name: "bodyPreviewMode", defaultChecked: isBodyBeautifyMode, label: responseBodyPreviewModeLabel, onChange: toggleBodyBeautifyModeHandler }))),
        React.createElement(Body, null,
            React.createElement(Tabs, { defaultActiveTabName: DEFAULT_REQUEST_RESPONSE_REVIEW_TAB, elements: responsePreviewElements, items: getRequestResponsePreviewTabItems(responsePreviewData, isBodyBeautifyMode), name: "responsePreview", onChangeTab: setTabName }))));
};
