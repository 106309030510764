import React from "react";
import { Select } from "@boomq/uikit";
import { getSelectTheme, slaOperationTheme, ErrorMessage, SlaItemBlock, SlaItemParamsRow, SlaItemSection, SlaItemTimelineRow, StyledInputNumber } from "./styled";
import { SlaItemButtons } from "./SlaItemButtons";
import { SlaResult } from "../SlaResult";
import { SlaTimelineSlider } from "../SlaTimelineSlider";
export const SlaItem = ({ children, copyButtonTooltip, data, deleteButtonTooltip, error, isDisabled, isShowActionButtons, isShowSlaResult, onChangeOperation, onChangeRestrictionType, onChangeTimeline, onChangeValue, onClickCopy, onClickDelete, operationItems, operationSelectPlaceholder, restrictionTypeItems, restrictionTypeSelectPlaceholder, testDuration, testResultSlaState, testType, type, valueInputPlaceholder, valueInputUnit }) => (React.createElement(SlaItemSection, null,
    React.createElement(SlaItemBlock, null,
        React.createElement(SlaItemParamsRow, { testType: testType },
            children,
            React.createElement(Select, { disabled: isDisabled, items: restrictionTypeItems, onChange: onChangeRestrictionType, placeholder: restrictionTypeSelectPlaceholder, defaultValue: data.restrictionType, theme: getSelectTheme(type, isShowSlaResult) }),
            React.createElement(Select, { disabled: isDisabled, items: operationItems, onChange: onChangeOperation, placeholder: operationSelectPlaceholder, defaultValue: data.operation, theme: slaOperationTheme }),
            React.createElement(StyledInputNumber, { decimalCount: 2, enabled: !isDisabled, isPositive: true, onBlur: onChangeValue, placeholder: valueInputPlaceholder, targetType: type, value: data.value, unit: valueInputUnit }),
            isShowSlaResult && React.createElement(SlaResult, Object.assign({}, data, { testResultSlaState: testResultSlaState })),
            !data.isValid && React.createElement(ErrorMessage, null, error)),
        React.createElement(SlaItemTimelineRow, null,
            React.createElement(SlaTimelineSlider, { data: data, min: 0, max: testDuration, onChange: onChangeTimeline, testDuration: testDuration }))),
    React.createElement(SlaItemButtons, { copySlaTooltip: copyButtonTooltip, deleteSlaTooltip: deleteButtonTooltip, isShowActionButtons: isShowActionButtons, onClickCopy: onClickCopy, onClickDelete: onClickDelete })));
