import React from "react";
import { RequestBodyText } from "./styled";
import { isJson, parseJson, stopEvent } from "@boomq/utils";
import { JsonViewer } from "../../../common/JsonViewer";
export const RequestBody = ({ bodyText, bodyPreview }) => {
    const parsedJson = parseJson(bodyText);
    const clickHandler = (event) => stopEvent(event);
    return isJson(bodyText) ? (React.createElement("div", { onClick: clickHandler },
        React.createElement(JsonViewer, { collapsed: true, src: parsedJson, iconStyle: "triangle", enableClipboard: false }))) : (React.createElement(RequestBodyText, null, bodyPreview));
};
