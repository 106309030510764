import React from "react";
import { FormattedMessage } from "react-intl";
import { ActionAddStep, AddStepSection, ChartBlock, LoadProfileSettingsSection } from "./styled";
import { RequestGroupJMX } from "../RequestGroupJMX";
import { StyledAccountSectionTitle } from "../RequestGroupJMX/styled";
import { UltimateThreadGroupChart } from "../UltimateThreadGroupChart";
import { IconAdd } from "../../../styled/Icons";
import { SettingsWrapper } from "../../../styled/TestProject";
import { Mode } from "../../../../models/sla";
const DEFAULT_PROPS = {
    requestGroup: {},
    testSettings: {}
};
export const UltimateThreadGroup = (props = DEFAULT_PROPS) => {
    const { onChangeLoadProfileChartData, loadProfile, mode, requestGroup } = props;
    const addStepHandler = () => props.addStepInGroup({ groupId: requestGroup.id });
    const changeChartDataHandler = (params) => typeof onChangeLoadProfileChartData === "function"
        ? onChangeLoadProfileChartData(Object.assign({ groupId: requestGroup.id }, params))
        : undefined;
    return (React.createElement(RequestGroupJMX, Object.assign({}, props),
        React.createElement(StyledAccountSectionTitle, null,
            React.createElement(FormattedMessage, { defaultMessage: "Group's load profile", id: "account.new.test.project.jmx.group.load.profile.title" })),
        React.createElement(SettingsWrapper, null,
            React.createElement(LoadProfileSettingsSection, null,
                props.children,
                mode === Mode.Edit && (React.createElement(AddStepSection, null,
                    React.createElement(ActionAddStep, { onClick: addStepHandler },
                        React.createElement(IconAdd, null),
                        React.createElement(FormattedMessage, { defaultMessage: "Add step", id: "account.new.test.project.jmx.group.add.step" }))))),
            React.createElement(ChartBlock, null,
                React.createElement(UltimateThreadGroupChart, { loadProfile: loadProfile, onChange: changeChartDataHandler })))));
};
