import React, { Component } from "react";
import { connect } from "react-redux";
import { withResize } from "../../styled/response";
import { Padding } from "../../styled/Wrappers";
import { getListOfResources } from "../../../redux/reducers/admin/resources";
import { resourcesRequest, showResourcePlate } from "../../../redux/actions/admin/resources";
import ResourceItem from "../../admin/ResourceItem";
import { AdminButton } from "../../routes/AdminPage";
import AddResourceItem from "../../admin/AddResourceItem";
import { getPlateVisibility } from "../../../redux/reducers/admin/resources";
const connector = connect((state) => ({
    isPlateVisible: getPlateVisibility(state),
    listOfResources: getListOfResources(state)
}), { resourcesRequest, showResourcePlate });
class Resources extends Component {
    componentDidMount() {
        this.props.resourcesRequest();
    }
    render() {
        const { listOfResources, isPlateVisible } = this.props;
        return (React.createElement(Padding, null,
            !isPlateVisible && (React.createElement(AdminButton, { bg: "#6fcf97", style: { marginTop: "20px" }, onClick: () => this.props.showResourcePlate() }, "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u0440\u0435\u0441\u0443\u0440\u0441")),
            isPlateVisible && React.createElement(AddResourceItem, null),
            Array.isArray(listOfResources) &&
                listOfResources.map((resource) => React.createElement(ResourceItem, Object.assign({ key: resource.id }, resource)))));
    }
}
export default withResize(connector(Resources));
