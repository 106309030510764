import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEventListener } from "@boomq/utils";
import { ResponseTimeChart } from "../../../../components/account/TestDetailsBlocks/ResponseTimeChart";
import { getAnomalyDetails } from "../../../../models/anomalyClassifications";
import { getTestDataAnomalies, getSensitivity, getAnomalyEnabled } from "../../../../redux/reducers/testAnomalies";
import { clearTestDataAnomalies, getGraphDataTestAnomalies, stopGraphDataTestSse } from "../../../../redux/actions/testAnomalies";
export const ResponseTimeChartContainer = ({ chartData, chartResponseLabel, chartVUsersLabel, leftYAxisResponseTimeMaxLength, rightYAxisValMaxLength, testDetails }) => {
    const [isVisibleTooltipLink, setIsVisibleTooltipLink] = useState(false);
    const [tooltipLinkPosition, setTooltipLinkPosition] = useState({ x: 0, y: 0 });
    const testIdRef = useRef(testDetails === null || testDetails === void 0 ? void 0 : testDetails.id);
    const dispatch = useDispatch();
    const sensitivity = useSelector(getSensitivity);
    const isAnomalyEnabled = useSelector(getAnomalyEnabled);
    const anomaliesData = useSelector(getTestDataAnomalies);
    const handleVisibilityChange = () => testIdRef.current > 0 && document.visibilityState === "visible" && isAnomalyEnabled
        ? dispatch(getGraphDataTestAnomalies({ testId: testIdRef.current, sensitivity }))
        : dispatch(stopGraphDataTestSse());
    const [addEventListener, removeEventListener] = useEventListener(document, "visibilitychange", handleVisibilityChange);
    useEffect(() => {
        testIdRef.current = testDetails === null || testDetails === void 0 ? void 0 : testDetails.id;
        const getGraphDataWithAnomalies = () => {
            dispatch(stopGraphDataTestSse());
            return testIdRef.current > 0 && isAnomalyEnabled
                ? dispatch(getGraphDataTestAnomalies({ testId: testIdRef.current, sensitivity }))
                : undefined;
        };
        getGraphDataWithAnomalies();
    }, [isAnomalyEnabled, testDetails.id, sensitivity]);
    useEffect(() => {
        addEventListener();
        return () => {
            dispatch(stopGraphDataTestSse());
            dispatch(clearTestDataAnomalies());
            removeEventListener();
        };
    }, []);
    const handleDotClick = (cx, cy) => {
        setIsVisibleTooltipLink(!isVisibleTooltipLink);
        setTooltipLinkPosition({ x: cx, y: cy - 50 });
    };
    const handleGraphClick = () => {
        isVisibleTooltipLink && setIsVisibleTooltipLink(!isVisibleTooltipLink);
    };
    const chartDataWithAnomalies = anomaliesData.map((data) => {
        const { classification } = data;
        const { anomalyDescription, anomalyColor } = getAnomalyDetails(classification);
        return Object.assign(Object.assign({}, data), { anomalyDescription,
            anomalyColor });
    });
    const dataForGraph = isAnomalyEnabled ? chartDataWithAnomalies : chartData;
    return (React.createElement(ResponseTimeChart, { chartData: dataForGraph, isAnomalyEnabled: isAnomalyEnabled, chartResponseLabel: chartResponseLabel, chartVUsersLabel: chartVUsersLabel, isShowAnomalyLegend: true, isVisibleTooltipLink: isVisibleTooltipLink, leftYAxisResponseTimeMaxLength: leftYAxisResponseTimeMaxLength, onClickDot: handleDotClick, onClickGraph: handleGraphClick, rightYAxisValMaxLength: rightYAxisValMaxLength, testDetails: testDetails, tooltipLinkPosition: tooltipLinkPosition }));
};
