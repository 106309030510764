import React from "react";
import { connect } from "react-redux";
import { isEmpty, useIntl } from "@boomq/utils";
import { DELETING_LABEL, SETTINGS_LABEL, TEST_STATUS_SELECT_PLACEHOLDER } from "./intl";
import { Webhook } from "../../../../components/account/TestProjectSettingsSet/Webhook";
import { WebhookAdditionalSettingsContainer } from "../WebhookAdditionalSettings";
import { formatIntlMessage } from "../../../../helpers/intl";
import { getWebhookTestDisplayStatusList } from "../../../../helpers/webhook";
import { webhookAdditionalSettingsModal } from "../../../../data/content/modals";
import { getSiteModal } from "../../../../redux/actions/siteModal";
import { deleteWebhookItem, setWebhookItem } from "../../../../redux/actions/webhooks";
const connector = connect(null, {
    onChange: setWebhookItem,
    onDelete: deleteWebhookItem,
    onShowModal: getSiteModal
});
const WebhookContainer = ({ error, headers, id, onChange, onDelete, onShowModal, testDisplayStateSet, testStatusError, url }) => {
    const { formatMessage } = useIntl();
    const changeTestStatusHandler = (value) => onChange({ error: "", id, testDisplayStateSet: value, testStatusError: "" });
    const deleteWebhookHandler = () => onDelete(id);
    const showWebhookSettingsHandler = () => onShowModal(webhookAdditionalSettingsModal({
        element: WebhookAdditionalSettingsContainer,
        headers,
        id,
        url
    }));
    const testStatusItems = getWebhookTestDisplayStatusList(formatMessage);
    const deletingLabel = formatIntlMessage(DELETING_LABEL, formatMessage);
    const errorMsg = isEmpty(error) ? "" : formatIntlMessage(error, formatMessage);
    const settingsLabel = formatIntlMessage(SETTINGS_LABEL, formatMessage);
    const testStatusSelectError = isEmpty(testStatusError) ? "" : formatIntlMessage(testStatusError, formatMessage);
    const testStatusSelectPlaceholder = formatIntlMessage(TEST_STATUS_SELECT_PLACEHOLDER, formatMessage);
    return (React.createElement(Webhook, { deletingLabel: deletingLabel, enabled: true, error: errorMsg, errorTestStatus: testStatusSelectError, onChangeTestStatus: changeTestStatusHandler, onDelete: deleteWebhookHandler, onShowWebhookSettings: showWebhookSettingsHandler, settingsLabel: settingsLabel, testDisplayStateSet: testDisplayStateSet, testStatusItems: testStatusItems, testStatusSelectPlaceholder: testStatusSelectPlaceholder }));
};
export default connector(WebhookContainer);
