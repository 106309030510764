import React from "react";
import { ButtonSize, InputText } from "@boomq/uikit";
import { keyValueTheme, ButtonSection, HeadersTitle, RequestUrl, RequestUrlTitle, StyledButton, WebhookSettings } from "./styled";
import { KeyValue } from "../../KeyValue";
export const WebhookAdditionalSettings = ({ headerKeyPlaceholder, headerValuePlaceholder, isDisabled, onChangeUrl, onChangeHeader, onDeleteHeader, onSave, requestHeaders, requestHeadersTitle, requestUrl, requestUrlError, requestUrlTitle, saveButtonLabel }) => (React.createElement(React.Fragment, null,
    React.createElement(WebhookSettings, null,
        React.createElement(RequestUrl, null,
            React.createElement(RequestUrlTitle, null, requestUrlTitle),
            React.createElement(InputText, { enabled: !isDisabled, error: requestUrlError, name: "requestUrl", onBlur: onChangeUrl, value: requestUrl })),
        React.createElement(HeadersTitle, null, requestHeadersTitle),
        (requestHeaders || []).map((header) => (React.createElement(KeyValue, { error: header.error, key: header.id, name: header.name, onChange: (value) => onChangeHeader(header.id, value), onDelete: () => onDeleteHeader(header.id), placeholderKey: headerKeyPlaceholder, placeholderValue: headerValuePlaceholder, theme: keyValueTheme, value: header })))),
    React.createElement(ButtonSection, null,
        React.createElement(StyledButton, { onClick: onSave, size: ButtonSize.medium }, saveButtonLabel))));
