var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import styled from "styled-components";
import { IconErrorTriangle } from "../../../styled/Icons/ErrorTriangle";
import { IconFillTriangle } from "../../../styled/Icons/FillTriangle";
import { getColorByName } from "../../../../helpers/color/colorByName";
import { generateSlaTypeIdFromData } from "../../../../helpers/slaReport/slaTypeIdFromData";
import { SlaStatus } from "../../../../models/sla/enums";
const getRenderDotKey = (testId, slaType = {}) => `${testId}_${generateSlaTypeIdFromData(slaType)}`;
export const getIconByStatus = (params) => {
    const { cx = 10, cy = 5, stroke, slaType, status, testId } = params;
    switch (status) {
        case SlaStatus.NotCounted: {
            return React.createElement(IconErrorTriangle, { key: getRenderDotKey(testId, slaType), x: cx - 10, y: 386 });
        }
        case SlaStatus.Success: {
            return (React.createElement(StyledFillTriangle, { key: getRenderDotKey(testId, slaType), x: cx - 5, y: cy - 5, fill: getColorByName("green"), stroke: stroke }));
        }
        default: {
            return (React.createElement(ReverseStyledFillTriangle, { key: getRenderDotKey(testId, slaType), x: cx - 5, y: cy - 5, fill: getColorByName("red"), stroke: stroke }));
        }
    }
};
export const CustomizedChartDot = (_a) => {
    var { payload } = _a, rest = __rest(_a, ["payload"]);
    return getIconByStatus(Object.assign(Object.assign({}, payload), rest));
};
const StyledFillTriangle = styled(IconFillTriangle) `
    path {
        stroke-width: 1.5;
        transform: scale(0.9);
        transform-origin: center;
    }
`;
const ReverseStyledFillTriangle = styled(StyledFillTriangle) `
    path {
        transform: scale(0.9) rotate(180deg);
    }
`;
