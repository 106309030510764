import React from "react";
import styled from "styled-components";
import { isEmpty } from "@boomq/utils";
import Tooltip from "../../../common/Tooltip";
import { getFullDateLocaleTimeZone } from "../../../../helpers/date";
const getHintComment = (comment) => isEmpty(comment) ? (React.createElement(React.Fragment, null)) : (React.createElement(Tooltip, { title: comment },
    React.createElement(TickHintG, null,
        React.createElement("circle", { x: "0", y: "0", cy: "10", r: "7", stroke: "#000", strokeWidth: "1", fill: "#fff", cx: "80" }),
        React.createElement("text", { x: "0", y: "0", dx: "77", dy: "14" }, "?"))));
const getTickDesc = (axisTickData, x, y) => (React.createElement("g", { transform: `translate(${x - 60},${y})` },
    React.createElement("text", { x: 0, y: 0, dx: 40, dy: 16 }, axisTickData.testId),
    getHintComment(axisTickData.testComment),
    React.createElement("text", { x: 0, y: 0, dy: 32 }, getFullDateLocaleTimeZone(axisTickData.testStartDate))));
export const CustomizedChartAxisTick = (props) => {
    const { x, y, payload, ticksData, title } = props || {};
    const axisTickData = ticksData[payload.value] || {};
    const clickHandler = () => (typeof props.onDelete === "function" ? props.onDelete(axisTickData.testId) : undefined);
    return (React.createElement(React.Fragment, null,
        props.onDelete && (React.createElement("g", { transform: `translate(${x - 60},${y})` },
            React.createElement(DeleteButtonG, { onClick: clickHandler },
                React.createElement("title", null, title),
                React.createElement("line", { x1: "25", y1: "15", x2: "35", y2: "5", stroke: "black", strokeWidth: "2" }),
                React.createElement("line", { x1: "25", y1: "5", x2: "35", y2: "15", stroke: "black", strokeWidth: "2" })))),
        getTickDesc(axisTickData, x, y)));
};
const DeleteButtonG = styled.g `
    cursor: pointer;
`;
const TickHintG = styled.g `
    cursor: help;
    font-size: 10px;
    font-weight: 600;
`;
