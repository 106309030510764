import styled from "styled-components";
import { Block, Color, FontBodyBigMedium, Help, SelectComboBox } from "@boomq/uikit";
const SectionStyle = `
    display: flex;
    margin-top: 24px;
`;
export const BlockSubtitleText = styled.div `
    ${FontBodyBigMedium}
    color: ${Color.darkGrey5};
    margin-top: 32px;
`;
export const DefaultHeadersSection = styled.div `
    ${SectionStyle}
    flex-direction: column;
    margin-top: 4px;
`;
export const DefaultTimerSection = styled.div `
    align-items: center;
    ${SectionStyle}
    ${SelectComboBox} {
        margin-left: 16px;
        max-width: 280px;
        width: 280px;
    }
`;
export const StyledBlock = styled.div `
    ${Block}
    margin-top: 16px;
`;
export const StyledHelp = styled(Help) `
    margin-left: 16px;
`;
export const TimeoutSection = styled.div `
    ${SectionStyle}
    flex-direction: column;
    > div {
        max-width: 280px;
        &:first-child {
            margin-bottom: 16px;
        }
    }
`;
export const TimeSectionInput = styled.div `
    align-items: center;
    display: flex;
`;
