var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { FormattedMessage } from "react-intl";
import { Help, Radio, Switcher } from "@boomq/uikit";
import { DistributionLabel, Panel, radioButtonTheme } from "./styled";
import { AutoPercentagesSection } from "../CommonProfileSettings";
import { Text } from "../../../styled/TestProject";
import { ProfileDistribution } from "../../../../models/testProject";
import { isAllowedTestProjectActionByPermissions } from "../../../../helpers/testProject";
import { TestProjectAction } from "../../../../helpers/types/testProject";
const renderAutoPercentages = (props) => {
    const changeAutoPercentagesHandler = ({ target }) => props[TestProjectAction.ChangeAutoPercentages]({ value: target.checked });
    return (props.profileDistribution === ProfileDistribution.Percent &&
        props.threadGroups.length > 1 &&
        isAllowedTestProjectActionByPermissions(props.permissionList, TestProjectAction.ChangeAutoPercentages) ? (React.createElement(AutoPercentagesSection, null,
        React.createElement(Switcher, { defaultChecked: props.autoPercentages, name: "autoPercentages", onChange: changeAutoPercentagesHandler }),
        React.createElement(Text, null,
            React.createElement(FormattedMessage, { defaultMessage: "Distribute users automatically", id: "account.test.profile.button.auto.percentages" })),
        React.createElement(Help, { tooltipText: {
                defaultMessage: "If enabled users are evenly distributed between transactions",
                id: "account.test.profile.button.auto.percentages.hint"
            } }))) : React.createElement(React.Fragment, null));
};
export const ProfileDistributionPanel = (_a) => {
    var { label, onChange, percentRadioButtonLabel, theme, usersRadioButtonLabel, value } = _a, restProps = __rest(_a, ["label", "onChange", "percentRadioButtonLabel", "theme", "usersRadioButtonLabel", "value"]);
    const clickPercentHandler = () => onChange(ProfileDistribution.Percent);
    const clickUsersHandler = () => onChange(ProfileDistribution.Users);
    const isChecked = (itemValue) => value === itemValue;
    return (React.createElement(Panel, { theme: theme },
        React.createElement(DistributionLabel, null, label),
        React.createElement(Radio, { name: "distribution", checked: isChecked(ProfileDistribution.Percent), onClick: clickPercentHandler, theme: radioButtonTheme }, percentRadioButtonLabel),
        React.createElement(Radio, { name: "distribution", checked: isChecked(ProfileDistribution.Users), onClick: clickUsersHandler, theme: radioButtonTheme }, usersRadioButtonLabel),
        renderAutoPercentages(restProps)));
};
