var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { forwardRef } from "react";
import { ScrollContainer } from "./styled";
import { AllTeamsItem } from "../AllTeamsItem";
import { AllTeamsPagination } from "../AllTeamsPagination";
import { TableRows } from "../../styled/Table";
export const AllTeams = forwardRef((props, _ref) => {
    const { items } = props, restProps = __rest(props, ["items"]);
    return (React.createElement(React.Fragment, null,
        React.createElement(ScrollContainer, { ref: _ref },
            React.createElement(TableRows, null, (items || []).map((team) => (React.createElement(AllTeamsItem, Object.assign({ key: team.id }, restProps, { team: team })))))),
        React.createElement(AllTeamsPagination, Object.assign({}, props))));
});
