import React from "react";
import { connect } from "react-redux";
import { getValueByPath } from "@boomq/utils";
import { SettingsSetSidebar } from "../../../components/account/SettingsSetSidebar";
import { NewTestSettingsSetSidebarActions } from "../../../components/account/NewTestSettingsSetSidebarActions";
import { addSettingsSetInSidebar, changeSidebarFilterLabels, changeSidebarFilterName, hideSettingsSetSidebar, linkSettingsSetToNewTest, refreshTestProjectSettingsSetList, unlinkSettingsSetForNewTest } from "../../../redux/actions/testProjectsSettingsSetSidebar";
import { getClientPermissionList, getIsAuthorize } from "../../../redux/reducers/authorizePlate";
import { getTestProjectSettingsSetsLabelList } from "../../../redux/reducers/testProjectSettingsSets";
import { getCurrentPage, getIsShowLoader, getIsShowSettingsSetSidebar, getLinkedSettingsSetData, getSettingsSetSidebarFilterLabels, getSettingsSetSidebarFilterName, getSettingsSetSidebarItems, getSettingsSetSidebarPagesCount, getSettingsSetSidebarPageSize, getSettingsSetSidebarTestProjectData } from "../../../redux/reducers/testProjectsSettingsSetSidebar";
import { SettingsSetInSidebarAction } from "../../../helpers/types";
import { excludeItemFromArrayByParamValue } from "../../../helpers/utils";
const getSettingsSetSidebarContentData = (state) => {
    const linkedSettingsSetData = getLinkedSettingsSetData(state);
    const settingsSetSidebarItems = getSettingsSetSidebarItems(state);
    return {
        linkedSettingsSetData,
        settingsSetSidebarItems: excludeItemFromArrayByParamValue(settingsSetSidebarItems, "id", linkedSettingsSetData.id)
    };
};
const getTestProjectName = (state) => {
    const testProjectData = getSettingsSetSidebarTestProjectData(state);
    return getValueByPath(testProjectData, "projectName", "");
};
const connector = connect((state) => (Object.assign(Object.assign({}, getSettingsSetSidebarContentData(state)), { currentPage: getCurrentPage(state), filterLabels: getSettingsSetSidebarFilterLabels(state), filterName: getSettingsSetSidebarFilterName(state), isAuthorized: getIsAuthorize(state), isShowLoader: getIsShowLoader(state), isShowSettingsSetSidebar: getIsShowSettingsSetSidebar(state), permissionList: getClientPermissionList(state), size: getSettingsSetSidebarPageSize(state), testProjectName: getTestProjectName(state), testProjectSettingsSetsLabelList: getTestProjectSettingsSetsLabelList(state), totalPages: getSettingsSetSidebarPagesCount(state) })), {
    [SettingsSetInSidebarAction.Add]: addSettingsSetInSidebar,
    changeSidebarFilterLabels,
    changeSidebarFilterName,
    hideSettingsSetSidebar,
    [SettingsSetInSidebarAction.LinkProject]: linkSettingsSetToNewTest,
    onLoadItems: refreshTestProjectSettingsSetList,
    [SettingsSetInSidebarAction.UnlinkProject]: unlinkSettingsSetForNewTest
});
const NewTestSettingsSetSidebarContainer = (props) => (React.createElement(SettingsSetSidebar, Object.assign({}, props),
    React.createElement(NewTestSettingsSetSidebarActions, null)));
export default connector(NewTestSettingsSetSidebarContainer);
