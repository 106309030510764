export const NO_DATA_MESSAGE = {
    defaultMessage: "no data yet",
    id: "account.all.plate.no.data"
};
export const TEST_PROJECTS_HEADER_DATE = {
    defaultMessage: "Last modified date",
    id: "account.all.test.project.header.date"
};
export const TEST_PROJECTS_HEADER_DURATION = {
    defaultMessage: "Duration",
    id: "account.all.test.project.header.duration"
};
export const TEST_PROJECTS_HEADER_NAME = { defaultMessage: "Name", id: "account.all.test.project.header.name" };
export const TEST_PROJECTS_HEADER_REQUEST_COUNT = {
    defaultMessage: "Request count",
    id: "account.all.test.project.header.request.count"
};
export const TEST_PROJECTS_HEADER_TYPE = { defaultMessage: "Load type", id: "account.all.test.project.header.type" };
