import styled from "styled-components";
import { Color, FontBodySmallMedium } from "@boomq/uikit";
export const Action = styled.div `
    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: 12px;
    padding: 0 4px;
    position: relative;
`;
export const Actions = styled.div `
    display: flex;
`;
export const ProtocolLabel = styled.div `
    ${FontBodySmallMedium};
    background: ${(props) => (props && props.bgColor ? props.bgColor : `${Color.white}`)};
    border-radius: 5px;
    color: ${Color.white};
    padding: 6px 7px;
`;
export const MethodLabel = styled.div `
    background: ${(props) => (props && props.bgColor ? props.bgColor : `${Color.white}`)};
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    color: ${Color.darkGrey5};
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    left: 0;
    padding-left: 25px;
    position: absolute;
    top: 0;
    width: 150px;
`;
export const Plate = styled.li `
    align-items: center;
    background: #ffffff;
    box-shadow: 0 0 8px rgba(0, 64, 140, 0.1);
    border-radius: 5px;
    color: #505050;
    display: flex;
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
    margin-top: 20px;
    padding: 10px 25px 10px 170px;
    position: relative;
    &:first-child {
        margin-top: 0;
    }
`;
export const PlateText = styled.div `
    flex: 1;
    position: relative;
`;
export const UrlPath = styled.p `
    font-size: 16px;
    word-break: break-all;
`;
export const UrlTitle = styled.p `
    color: #bdbdbd;
    font-size: 12px;
`;
