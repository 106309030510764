import React from "react";
import { isEmpty } from "@boomq/utils";
import { ParameterCsv } from "../ParameterCsv";
import { generateContentFromFilePreview, generateParameterNamesByHasHeaders } from "../../../helpers/requestParameters";
const changeFileContentParameterNames = (parameter, name, value) => {
    const getParamValueByName = (paramName) => (name === paramName ? value : parameter[paramName]);
    const delimiter = getParamValueByName("delimiter");
    const hasHeaders = getParamValueByName("hasHeaders");
    const fileContent = generateContentFromFilePreview(parameter.preview, delimiter);
    return {
        fileContent: hasHeaders ? fileContent.slice(1) : fileContent,
        parameterNames: generateParameterNamesByHasHeaders(fileContent, hasHeaders)
    };
};
const changeParam = (parameter, name, value) => ["delimiter", "hasHeaders"].includes(name)
    ? changeFileContentParameterNames(parameter, name, value)
    : {
        fileContent: parameter.fileContent,
        parameterNames: parameter.parameterNames
    };
export const ImportParametersCsv = ({ onChangeCsvHeader, onChangeField, onClickDownloadCsvFile, onUploadCsvFile, requestParametersCsv = [], usedRequestParameterNames }) => {
    const changeParamHandler = ({ id, name, value }) => {
        const newCsvParameters = [
            ...(requestParametersCsv || []).map((csvParameter) => csvParameter.id === id
                ? Object.assign(Object.assign(Object.assign({}, csvParameter), changeParam(csvParameter, name, value)), { [name]: value }) : csvParameter)
        ].filter(({ files }) => !isEmpty(files));
        return onChangeField({ field: "csvParameters", value: newCsvParameters });
    };
    return requestParametersCsv.map((parameterCsv) => (React.createElement(ParameterCsv, Object.assign({ key: parameterCsv.id, fileLocation: parameterCsv.location, onChangeCsvHeader: onChangeCsvHeader, onChangeFile: onUploadCsvFile, onChangeParam: changeParamHandler, onClickDownloadCsvFile: onClickDownloadCsvFile, usedRequestParameterNames: usedRequestParameterNames }, parameterCsv))));
};
