import styled from "styled-components";
import { DEFAULT_LABEL_COLOR, DEFAULT_TEXT_COLOR } from "./constants";
import { IconClose } from "../Icons";
import { FontCaption } from "../styled/typography";
export const LabelBody = styled.li `
    align-items: center;
    background: ${({ color }) => (color ? color : DEFAULT_LABEL_COLOR)}
    border-radius: 8px;
    display: flex;
    justify-content: flex-start;
    padding: 3.5px 6px 3.5px 8px;
`;
export const LabelName = styled.div `
    ${FontCaption}
    color: ${({ textcolor }) => (textcolor ? textcolor : DEFAULT_TEXT_COLOR)};
`;
export const StyledIconClose = styled(IconClose) `
    cursor: pointer;
    fill: ${({ textcolor }) => (textcolor ? textcolor : DEFAULT_TEXT_COLOR)};
    height: 16px;
    margin-left: 6px;
    width: 16px;
`;
