var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useRef, useState } from "react";
import { getValueByPath, isEmpty, stopEvent } from "@boomq/utils";
import { getClassNameByMode, getValueFromProps } from "./helpers";
import { StyledTitle, TitleInput } from "./styled";
export const EditableTitle = (_a) => {
    var { className, onBlur, onChange, onClick, onFocus, onToggle, placeholder, validateFunc, value } = _a, restProps = __rest(_a, ["className", "onBlur", "onChange", "onClick", "onFocus", "onToggle", "placeholder", "validateFunc", "value"]);
    const [errorText, setErrorText] = useState("");
    const [isEdit, setIsEdit] = useState(false);
    const [inputValue, setInputValue] = useState(getValueFromProps(value));
    const titleInputRef = useRef(null);
    const focusTitleInput = () => titleInputRef && titleInputRef.current && typeof titleInputRef.current.focus === "function"
        ? titleInputRef.current.focus()
        : undefined;
    useEffect(() => {
        focusTitleInput();
    });
    useEffect(() => {
        setInputValue(getValueFromProps(value));
    }, [value]);
    const changeHandler = (event) => {
        setInputValue(getValueByPath(event, "target.value", ""));
        setErrorText("");
    };
    const toggleMode = (value) => {
        setIsEdit(value);
        return typeof onToggle === "function" ? onToggle(value) : undefined;
    };
    const clickInputHandler = (event) => stopEvent(event);
    const clickTitleHandler = (event) => {
        stopEvent(event);
        toggleMode(true);
        return typeof onClick === "function" ? onClick(event) : undefined;
    };
    const getInputPlaceholderValue = () => placeholder !== null && placeholder !== void 0 ? placeholder : "";
    const getInputPlaceholder = () => (isEmpty(errorText) ? getInputPlaceholderValue() : errorText);
    const toggleModeHandler = () => toggleMode(!isEdit);
    const validateValue = (value) => typeof validateFunc === "function" ? validateFunc(value) : { isValid: true, error: "" };
    const changeValue = (value) => {
        toggleModeHandler();
        return typeof onChange === "function" ? onChange(value) : undefined;
    };
    const setError = (error) => {
        setErrorText(error);
        return focusTitleInput();
    };
    const blur = (value) => (typeof onBlur === "function" ? onBlur(value) : undefined);
    const blurHandler = () => {
        const { isValid, error } = validateValue(inputValue);
        blur(inputValue);
        return isValid ? changeValue(inputValue) : setError(error);
    };
    const focusHandler = (event) => (typeof onFocus === "function" ? onFocus(event) : undefined);
    const escape = () => {
        toggleModeHandler();
        setErrorText("");
        setInputValue(getValueFromProps(value));
    };
    const checkEscape = (event) => event && event.key === "Escape" ? escape() : undefined;
    const submitHandler = (event) => event && event.key === "Enter" ? blurHandler() : checkEscape(event);
    return isEdit ? (React.createElement(TitleInput, Object.assign({}, restProps, { className: getClassNameByMode(isEdit, className), isExistError: !isEmpty(errorText), ref: titleInputRef, onBlur: blurHandler, onChange: changeHandler, onClick: clickInputHandler, onFocus: focusHandler, onKeyDown: submitHandler, placeholder: getInputPlaceholder(), theme: restProps.editModeTheme, value: inputValue }))) : (React.createElement(StyledTitle, Object.assign({}, restProps, { className: getClassNameByMode(isEdit, className), onClick: clickTitleHandler, theme: restProps.theme }), inputValue));
};
