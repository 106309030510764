import { getUniqueId, getValueByPath, isEmpty, stringTrimByLength } from "@boomq/utils";
import { BODY_PREVIEW_LENGTH } from "./constants";
import { RequestGroupType } from "../types/testProjectJmx";
import { ControllerType, ThreadGroupController } from "../../models/threadGroupEditor/enums";
export const formatThreadGroup = ({ displayTypeName = "", id, index = 0, name = "", type = RequestGroupType.BoomqStableScalability }) => ({
    displayTypeName,
    enabled: true,
    id: isEmpty(id) ? getUniqueId() : id,
    index,
    name,
    type
});
export const formatController = ({ controllerType, groupId, id, index, name = "", path = "", type }) => ({
    groupId: isEmpty(groupId) ? getUniqueId() : groupId,
    id: isEmpty(id) ? getUniqueId() : id,
    index,
    name,
    path,
    controllerType: controllerType || ControllerType.Transaction,
    type: type || ThreadGroupController.Controller
});
export const generateBodyPreview = (body) => body && typeof body.TEXT === "string" && body.TEXT.length > BODY_PREVIEW_LENGTH
    ? stringTrimByLength(body.TEXT, BODY_PREVIEW_LENGTH, "...")
    : getValueByPath(body, "TEXT", "");
export const generateTransactionName = (index) => `Transaction ${index}`;
export const getThreadGroupElementControllerType = (element) => getValueByPath(element, "controllerType");
export const getThreadGroupElementId = (element) => getValueByPath(element, "id", null);
export const getThreadGroupElementPath = (element) => getValueByPath(element, "path", "");
export const getThreadGroupElementName = (element, defaultValue = "") => element ? element.label : defaultValue;
export const getThreadGroupName = (groupData) => getValueByPath(groupData, "label", "");
export const getThreadGroupElementType = (element) => getValueByPath(element, "type", null);
