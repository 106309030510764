import { SlaReportTestFilterTimeUnit } from "../types";
export const EMBED_CODE_VARIABLE_NAME = "pflb";
export const SELECTION_TYPE = ["testIds", "search"];
export const SPOILER_NAME = ["settings", "charts"];
export const TIME_UNIT = [
    SlaReportTestFilterTimeUnit.Week,
    SlaReportTestFilterTimeUnit.Month,
    SlaReportTestFilterTimeUnit.Runs
];
export const SLA_REPORT_FILTERS = [
    "excludedTestIdsFilter",
    "testIdsFilter",
    "testLabelFilter",
    "testStatusFilter",
    "timeAmountFilter",
    "timeUnitFilter"
];
