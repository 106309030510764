import styled, { css } from "styled-components";
import { FontBodySmallMedium, Color } from "@boomq/uikit";
export const TitleWrapper = styled.div `
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    margin-top: 16px;
    padding-left: 16px;
    position: relative;
    ${({ theme }) => (theme ? css(theme) : css ``)}
`;
export const TitleText = styled.h3 `
    ${FontBodySmallMedium}    
    color: ${Color.grey50};
    flex: ${({ long }) => (long ? 1.5 : 1)};
    line-height: normal;
    text-align: ${({ align }) => (align ? align : "left")};
    word-break: break-word;
    &:last-child {
        max-width: 168px;
        min-width: 168px;
    }
`;
