export const CLEAR_EACH_ITERATION_SWITCHER_LABEL = {
    defaultMessage: "Clear cookies each iteration",
    id: "account.default.cookies.clear.each.iteration.switcher.label"
};
export const DEFAULT_COOKIE_DOMAIN_PLACEHOLDER = {
    defaultMessage: "Domain",
    id: "account.default.cookies.domain.placeholder"
};
export const DEFAULT_COOKIE_NAME_PLACEHOLDER = {
    defaultMessage: "Name",
    id: "account.default.cookies.name.placeholder"
};
export const DEFAULT_COOKIE_PATH_PLACEHOLDER = {
    defaultMessage: "Path",
    id: "account.default.cookies.path.placeholder"
};
export const DEFAULT_COOKIE_VALUE_PLACEHOLDER = {
    defaultMessage: "Value",
    id: "account.default.cookies.value.placeholder"
};
export const HTTPS_ONLY_CHECKBOX_LABEL = {
    defaultMessage: "HTTPS only",
    id: "account.default.cookies.https.only.checkbox.label"
};
