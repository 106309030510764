import { IconCopy, IconEdit, IconRemove } from "../../components/styled/Icons";
import { RequestManualTimerIcon } from "../../components/account/TestProject/RequestManualTimerIcon";
import { TestProjectRequestActionType } from "../../helpers/types";
export const requestActions = [
    {
        actionName: "editRequestHandler",
        iconComponent: RequestManualTimerIcon,
        params: { defaultActiveTabName: "timers" },
        type: TestProjectRequestActionType.Component
    },
    {
        actionName: "copyRequestHandler",
        icon: IconCopy,
        type: TestProjectRequestActionType.Icon
    },
    {
        actionName: "editRequestHandler",
        icon: IconEdit,
        type: TestProjectRequestActionType.Icon
    },
    {
        actionName: "deleteRequestHandler",
        icon: IconRemove,
        type: TestProjectRequestActionType.Icon
    }
];
export const bodyParamList = [
    {
        id: 0,
        component: {
            elementName: "BodyKeyValue",
            props: {}
        },
        name: "KEY_VALUE",
        title: {
            defaultMessage: "Key Value",
            id: "account.request.form.body.key_value.title"
        }
    },
    {
        id: 1,
        component: {
            elementName: "BodyText",
            props: {}
        },
        name: "TEXT",
        title: {
            defaultMessage: "Text",
            id: "account.request.form.body.text.title"
        }
    },
    {
        id: 2,
        component: {
            elementName: "BodyContentFromFile",
            props: {}
        },
        name: "FILE",
        title: {
            defaultMessage: "Content from file",
            id: "account.request.form.body.file.title"
        }
    },
    {
        id: 3,
        component: {
            elementName: "AttachBinaryFiles",
            props: {}
        },
        name: "ATTACH",
        title: {
            defaultMessage: "Attach binary files",
            id: "account.request.form.body.attach.title"
        }
    }
];
