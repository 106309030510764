export const ANOMALIES_DETECT_LABEL = {
    defaultMessage: "Detect anomalies within a test",
    id: "account.test.details.chart.anomaly.settings.detect.label"
};
export const ANOMALY_SETTINGS_TITLE = {
    defaultMessage: "Anomaly detection",
    id: "account.test.details.chart.anomaly.settings.title"
};
export const ANOMALIES_SENSITIVITY_LABEL = {
    defaultMessage: "Set up anomalies detection sensitivity ",
    id: "account.test.details.chart.anomaly.settings.sensitivity.label"
};
