var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { getUniqueId, getValueByPath } from "@boomq/utils";
import { AssertionFilter, AssertionResponseType } from "../../models/assertion/enums";
const parseAssertionResponse = (_a) => {
    var { values } = _a, restParams = __rest(_a, ["values"]);
    return (Object.assign({ value: getValueByPath(values, "0") }, restParams));
};
const parseAssertionResponseBody = (params) => (Object.assign({ filter: AssertionFilter.NoFilter }, parseAssertionResponse(params)));
const parseAssertionResponseBodyJson = (_a) => {
    var { type } = _a, restParams = __rest(_a, ["type"]);
    return (Object.assign({ filter: AssertionFilter.JsonPath, type: AssertionResponseType.ResponseBody }, restParams));
};
const parseAssertionResponseBodyXPath2 = (_a) => {
    var { type } = _a, restParams = __rest(_a, ["type"]);
    return (Object.assign({ filter: AssertionFilter.XPath2, type: AssertionResponseType.ResponseBody }, restParams));
};
const assertionParseFuncByType = {
    [AssertionResponseType.ResponseBodyJson]: parseAssertionResponseBodyJson,
    [AssertionResponseType.ResponseBodyXPath2]: parseAssertionResponseBodyXPath2,
    [AssertionResponseType.RequestData]: parseAssertionResponse,
    [AssertionResponseType.RequestHeaders]: parseAssertionResponse,
    [AssertionResponseType.ResponseBody]: parseAssertionResponseBody,
    [AssertionResponseType.ResponseCode]: parseAssertionResponse,
    [AssertionResponseType.ResponseHeaders]: parseAssertionResponse,
    [AssertionResponseType.ResponseMessage]: parseAssertionResponse,
    [AssertionResponseType.ResponseTikaExtract]: parseAssertionResponse,
    [AssertionResponseType.UrlSampled]: parseAssertionResponse
};
const parseAssertionParamsByType = (type, params) => Object.keys(assertionParseFuncByType).includes(type) ? assertionParseFuncByType[type](params) : params;
const parseAssertion = (_a) => {
    var { id, label, type, typeDisplayName } = _a, restParams = __rest(_a, ["id", "label", "type", "typeDisplayName"]);
    return (Object.assign({ id: id || getUniqueId(), label,
        type,
        typeDisplayName }, parseAssertionParamsByType(type, restParams)));
};
export const parseAssertions = (assertions = []) => (assertions || []).reduce((res, assertion) => [...res, parseAssertion(assertion)], []);
