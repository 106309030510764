var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState } from "react";
import { Button, ButtonSize, ButtonType } from "@boomq/uikit";
import { DEFAULT_NAME } from "./constants";
import { ButtonCancel, ButtonsBlock, TeamMembersItemInputsBlock } from "./styled";
import { TeamMembersItemTemplateEditInputs } from "../TeamMembersItemTemplateEditInputs";
import { excludeItemFromArray, formatEmail } from "../../../../helpers/utils";
import { emailValidator } from "../../../../helpers/validators";
const getInputTextStateValue = (value) => (typeof value === "string" ? value : DEFAULT_NAME);
const getPermissionsStateValue = (permissionList) => Array.isArray(permissionList) ? permissionList : [];
const renderChildren = (_a) => {
    var { children, onConfirm } = _a, restProps = __rest(_a, ["children", "onConfirm"]);
    return (React.isValidElement(children) ? React.cloneElement(children, restProps) : children);
};
export const TeamMembersItemTemplateEditor = (props) => {
    const [userDisplayName, setUserDisplayName] = useState(getInputTextStateValue(props.userDisplayName));
    const [email, setEmail] = useState(getInputTextStateValue(props.email));
    const [permissionList, setPermissionList] = useState(getPermissionsStateValue(props.permissionList));
    const [emailValidationData, setEmailValidationData] = useState({ isValid: true, error: undefined });
    const actionHandler = (e, action, params) => {
        e.stopPropagation();
        e.preventDefault();
        return typeof props[action] === "function" ? props[action](params) : undefined;
    };
    const changeEmailHandler = (value) => {
        const emailValidationData = emailValidator(value);
        setEmailValidationData(emailValidationData);
        setEmail(value);
    };
    const changeNameHandler = (value) => setUserDisplayName(value);
    const clickAddButtonHandler = (e) => {
        const userEmail = formatEmail(email);
        const emailValidationData = emailValidator(userEmail);
        setEmailValidationData(emailValidationData);
        return emailValidationData.isValid
            ? actionHandler(e, "onConfirm", { id: props.id, email: userEmail, permissionList, userDisplayName })
            : undefined;
    };
    const clickCancelButtonHandler = (e) => actionHandler(e, "onCancel");
    const addPermission = (permission) => setPermissionList([...permissionList, permission]);
    const excludePermission = (permission) => setPermissionList(excludeItemFromArray(permissionList, permission));
    const togglePermissionHandler = (value) => permissionList.includes(value) ? excludePermission(value) : addPermission(value);
    return (React.createElement(React.Fragment, null,
        React.createElement(TeamMembersItemInputsBlock, null,
            React.createElement(TeamMembersItemTemplateEditInputs, { email: email, emailError: emailValidationData.error, emailInputLabel: props.emailInputLabel, nameInputLabel: props.nameInputLabel, permissionItems: props.permissionItems, permissions: permissionList, onChangeEmail: changeEmailHandler, onChangeName: changeNameHandler, onTogglePermission: togglePermissionHandler, userDisplayName: userDisplayName }),
            renderChildren(props)),
        React.createElement(ButtonsBlock, null,
            React.createElement(Button, { disabled: !emailValidationData.isValid, onClick: clickAddButtonHandler, size: ButtonSize.small }, props.confirmButtonLabel),
            React.createElement(ButtonCancel, { onClick: clickCancelButtonHandler, size: ButtonSize.small, styletype: ButtonType.outlinedHighlight }, props.cancelButtonLabel))));
};
