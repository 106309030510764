import * as React from "react";
import styled, { css } from "styled-components";
import ReactSVG from "react-svg";
import arrow_down from "../../../assets/images/icons/arrow_down.svg";
class Spoiler extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            isOpen: this.props.isOpened || false
        };
        this.toggleClick = (e) => {
            const { isOpen } = this.state;
            const { onlyArrowClick, toggle } = this.props;
            const isClick = onlyArrowClick ? this.isArrowButton(e) : true;
            isClick && this.setState({ isOpen: !isOpen });
            isClick && toggle && toggle(!isOpen);
        };
        this.isArrowButton = (e) => e && !!e.target.closest("div.spoiler_arrow_button");
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        this.props.isOpened !== nextProps.isOpened && this.setState({ isOpen: nextProps.isOpened });
    }
    render() {
        const { isOpen } = this.state;
        const { caption, captionColor, captionTheme, openedCaptionTheme, theme } = this.props;
        return (React.createElement(StyledSpoiler, { theme: theme },
            React.createElement(Caption, { captionColor: captionColor, isopen: isOpen.toString(), onClick: (e) => this.toggleClick(e), openedTheme: openedCaptionTheme, theme: captionTheme },
                caption,
                React.createElement(Arrow, { className: "spoiler_arrow_button", isopen: isOpen.toString(), src: arrow_down })),
            isOpen && this.props.children ? this.props.children : React.createElement(React.Fragment, null)));
    }
}
const Caption = styled.div `
    position: relative;
    ${(props) => props.captionColor &&
    css `
            span {
                color: ${props.captionColor};
            }
        `}
    ${({ theme }) => theme && css(theme)}
    ${({ isopen, openedTheme }) => isopen === "true" && openedTheme && css(openedTheme)}
`;
const Arrow = styled(ReactSVG) `
    cursor: pointer;
    height: 20px;
    margin: 0px 3px;
    position: absolute;
    right: 20px;
    top: calc(50% - 10px);
    transform: rotate(-90deg);
    width: 20px;
    svg {
        width: 20px;
        height: 20px;
        path {
            fill: #fff;
        }
    }
    ${(props) => props.isopen === "true" &&
    css `
            transform: rotate(0deg);
        `}
`;
const StyledSpoiler = styled.div `
    ${({ theme }) => (theme ? css(theme) : css ``)}
`;
export default Spoiler;
