import styled from "styled-components";
import { Select, SelectComboBox } from "@boomq/uikit";
import { media } from "../../../styled/response";
export const AccountSelect = styled(Select) `
    min-width: 100%;
    width: 100%;
`;
export const ButtonSection = styled.div `
    display: flex;
    flex: none;
    justify-content: flex-end;
    margin-left: 10px;
    margin-top: 20px;
    > button {
        width: auto !important;
    }
    ${media.giant} {
        margin-left: 0;
    }
`;
export const ComboBoxSection = styled.div `
    display: grid;
    grid-template-columns: 120px calc(100% - 120px);
    ${SelectComboBox} {
        margin-right: 10px;
        min-width: 110px;
        ${media.tablet} {
            margin-right: 0;
        }
    }
    ${media.desktop} {
        margin-top: 20px;
        grid-template-columns: calc(100% - 244px) 244px;
    }
    ${media.tablet} {
        grid-template-columns: 100%;
    }
`;
export const DateRangeStatistics = styled.div `
    flex: none;
    min-width: 244px;
    position: relative;
    ${media.desktop} {
        .DateRangePicker {
            width: 100%;
        }
    }
    ${media.tablet} {
        margin-top: 20px;
        .DateRangePicker,
        .DateRangePickerInput__withBorder {
            text-align: center;
        }
        .DateInput {
            border-radius: 100%;
            width: 190px;
        }
    }
`;
export const ImportAnalyticsParams = styled.div `
    display: grid;
    grid-template-columns: 318px calc(100% - 318px);
    margin-top: 20px;
    width: 100%;
    ${media.desktop} {
        grid-template-columns: 100%;
    }
`;
export const ImportAnalyticsSection = styled.div `
    display: flex;
    flex-direction: column;
    justify-content: center;
`;
export const SiteSelection = styled.div `
    margin-right: 10px;
    position: relative;
    ${media.desktop} {
        margin-right: 0;
    }
`;
