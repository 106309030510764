import { put, take } from "redux-saga/effects";
import { authorizeActions, isEmpty, newTestActions, showError, takeError as commonTakeError } from "@boomq/common";
import { API_ERROR_TITLE } from "./intl";
import { getApiErrorMessageByExceptionMessage } from "../../../data/content/errorMessages";

const { logout } = authorizeActions;
const { startTestFailure } = newTestActions;

const getStartTestErrorMessage = (action) => {
    const { message } = action.payload ?? {};
    const apiError = getApiErrorMessageByExceptionMessage(message);

    return isEmpty(apiError)
        ? undefined
        : {
              modalInfo: {
                  description: apiError,
                  title: API_ERROR_TITLE
              }
          };
};

function* startTestFailureFlow(action) {
    return yield* showError(getStartTestErrorMessage(action));
}

function* checkErrorByExceptionMessage(action) {
    const { exceptionMessage } = action.payload ?? {};
    const apiError = getApiErrorMessageByExceptionMessage(exceptionMessage);

    return isEmpty(apiError)
        ? yield* commonTakeError(action)
        : yield* showError({
              modalInfo: {
                  description: apiError,
                  title: API_ERROR_TITLE
              }
          });
}

function* takeError(action) {
    const { type } = action ? action : {};

    switch (type) {
        case startTestFailure.toString():
            return yield* startTestFailureFlow(action);
        default:
            return yield* checkErrorByExceptionMessage(action);
    }
}

export function* errorFlow() {
    while (true) {
        const action = yield take((action) => action.type.search(/FAILURE$/i) > -1);
        const { status } = action && action.payload ? action.payload : {};

        status === 401 ? yield put(logout()) : yield* takeError(action);
    }
}
