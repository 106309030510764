import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { useIntl } from "@boomq/utils";
import { H3 } from "@boomq/uikit";
import { COMMENT_AREA_MAX_LENGTH } from "./constants";
import { COMMENT_AREA_PLACEHOLDER_MESSAGE, TEST_DESCRIPTION_SUBTITLE_MESSAGE, TEST_DESCRIPTION_TITLE_MESSAGE } from "./intl";
import { Comment, CommentArea, CommentLength, CommentSubtitle, CommentTitle } from "./styled";
import newTestActions from "../../../redux/actions/newTest";
import { getTestProjectSettingsField } from "../../../redux/reducers/newTest";
const connector = connect((state) => ({
    testComment: getTestProjectSettingsField(state, "testComment")
}), { onChange: newTestActions.testProjectChangeParamsField });
const CommentSelection = ({ disabled, onChange, testComment }) => {
    const { formatMessage } = useIntl();
    const changeHandler = (event) => onChange({
        field: "testComment",
        value: event.target.value,
        isModified: true
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(CommentTitle, null,
            React.createElement(H3, null, formatMessage(TEST_DESCRIPTION_TITLE_MESSAGE)),
            React.createElement(CommentSubtitle, null, formatMessage(TEST_DESCRIPTION_SUBTITLE_MESSAGE))),
        React.createElement(Comment, { disabled: disabled },
            React.createElement(CommentArea, { maxLength: COMMENT_AREA_MAX_LENGTH, onChange: changeHandler, placeholder: formatMessage(COMMENT_AREA_PLACEHOLDER_MESSAGE), value: testComment }),
            React.createElement(CommentLength, null,
                testComment.length,
                "/",
                COMMENT_AREA_MAX_LENGTH))));
};
export default withRouter(connector(CommentSelection));
