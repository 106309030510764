import styled, { css } from "styled-components";
import { withDisable } from "./global";
import { media } from "./response";
export const StyledErrorMessage = styled.p `
    position: absolute;
    bottom: -16px;
    left: 30px;
    color: #eb5757;
    font-size: 12px;
    ${media.phone} {
        left: 0;
    }
`;
export const StyledInput = styled.input `
    ${withDisable};
    background: #ffffff;
    border: 1px solid ${(props) => (props && props.isError ? "#EB5757" : "#91B0CF")};
    border-radius: 30px;
    box-sizing: border-box;
    color: #396496;
    font-size: 16px;
    font-weight: normal;
    outline: none;
    padding: 13px 28px;
    transition: all 0.1s ease-in-out;
    width: 100%;
    &::placeholder {
        ${({ placeholderTheme }) => placeholderTheme
    ? css(placeholderTheme)
    : css `
                font-weight: normal;
                font-size: 16px;
                color: #91b0cf;
            `}
    }
    &:focus {
        box-shadow: 0 0 15px rgba(0, 55, 120, 0.3);
    }
    ${({ disabled, disabledStyle }) => (disabled && disabledStyle ? css(disabledStyle) : ``)}
    ${(props) => (props && props.theme ? css(props.theme) : "")}
`;
export const StyledLabel = styled.label `
    align-items: center;
    display: flex;
    margin-top: 19px;
    position: relative;
    &:first-child {
        margin-top: 0;
    }
    ${media.tablet} {
        flex-direction: column;
        align-items: flex-start;
    }
    ${(props) => (props && props.theme ? css(props.theme) : "")}
`;
