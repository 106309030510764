export const CONDITION_SELECT_PLACEHOLDER = {
    defaultMessage: "select condition",
    id: "account.request.form.assertion.condition.select.placeholder"
};
export const TYPE_SELECT_PLACEHOLDER = {
    defaultMessage: "select scope",
    id: "account.request.form.assertion.type.select.placeholder"
};
export const VALUE_INPUT_PLACEHOLDER = {
    defaultMessage: "value",
    id: "account.request.form.assertion.value.input.placeholder"
};
