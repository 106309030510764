var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { deepCompareArrays, isEmpty, isEmptyArray } from "@boomq/utils";
import { Button, ButtonType, Label, Select, Tooltip, ButtonSize } from "@boomq/uikit";
import { SETTINGS_SET_MONITORING_BUTTON_CANCEL_LABEL, SETTINGS_SET_MONITORING_BUTTON_GENERATE_LABEL, SETTINGS_SET_MONITORING_SECTION_ADD_METRICS_TITLE, SETTINGS_SET_MONITORING_SECTION_CONFIG_METRICS_TITLE, SETTINGS_SET_MONITORING_SECTION_CONFIG_FILES_TITLE, SETTINGS_SET_MONITORING_TITLE } from "./intl";
import { AddingSystemMetricsSection, BlockTitleText, ButtonsSection, ConfigFileIconLink, ConfigFileLink, ConfigFileLinks, ConfigFileName, ConfigFileNameLink, ErrorBlock, SectionTitle, SelectedMetrics, SelectedSystemMetricsSection, StyledBlock, StyledBlockContent, StyledButton, StyledIconAlertCircle, StyledIconDownload } from "./styled";
import { BlockTitle } from "../../../styled/Blocks";
import { isAllowedSettingsSetActionByPermissions } from "../../../../helpers/testProjectSettingsSet";
import { SelectionType, SettingsSetAction } from "../../../../helpers/types";
const renderConfigFileBodyByLink = (props, fileName, fileLinkTypeName, isValid) => {
    const clickHandler = () => typeof props.onDownloadFileConfig === "function" ? props.onDownloadFileConfig(fileLinkTypeName) : undefined;
    return props[fileLinkTypeName] ? (React.createElement(React.Fragment, null,
        React.createElement(ConfigFileNameLink, { disabled: !isValid, href: props[fileLinkTypeName], rel: "noopener noreferrer", target: "_blank" }, fileName),
        React.createElement(ConfigFileIconLink, { disabled: !isValid, href: props[fileLinkTypeName], rel: "noopener noreferrer", target: "_blank" },
            React.createElement(StyledIconDownload, { disabled: !isValid })))) : (React.createElement(React.Fragment, null,
        React.createElement(ConfigFileName, { disabled: !isValid, onClick: clickHandler }, fileName),
        React.createElement(StyledIconDownload, { disabled: !isValid, onClick: clickHandler })));
};
const renderConfigFile = (fileName, fileLinkTypeName, fileLocation, isValid, props) => isEmpty(fileLocation) ? (React.createElement(React.Fragment, null)) : (React.createElement(ConfigFileLink, null, renderConfigFileBodyByLink(props, fileName, fileLinkTypeName, isValid)));
const renderConfigFileLinks = (_a) => {
    var { selectedMetricsState, selectedSystemMetrics, systemMonitoringTelegrafLocation } = _a, rest = __rest(_a, ["selectedMetricsState", "selectedSystemMetrics", "systemMonitoringTelegrafLocation"]);
    const isValid = deepCompareArrays(getMetricsFromItems(selectedMetricsState), selectedSystemMetrics);
    return isEmpty(selectedSystemMetrics) || isEmpty(systemMonitoringTelegrafLocation) ? (React.createElement(React.Fragment, null)) : (React.createElement(ConfigFileLinks, null,
        React.createElement(SectionTitle, null,
            React.createElement(FormattedMessage, Object.assign({}, SETTINGS_SET_MONITORING_SECTION_CONFIG_FILES_TITLE))),
        renderConfigFile("system_monitoring.tar.gz", "systemMonitoringTarTelegrafLocation", systemMonitoringTelegrafLocation, isValid, rest)));
};
const renderSaveButton = ({ errorText, isValid, onSave }) => isValid ? (React.createElement(Button, { size: ButtonSize.medium, onClick: onSave },
    React.createElement(FormattedMessage, Object.assign({}, SETTINGS_SET_MONITORING_BUTTON_GENERATE_LABEL)))) : (React.createElement(Tooltip, { title: errorText },
    React.createElement(ErrorBlock, null,
        React.createElement(StyledIconAlertCircle, null),
        React.createElement(Button, { disabled: true, size: ButtonSize.medium },
            React.createElement(FormattedMessage, Object.assign({}, SETTINGS_SET_MONITORING_BUTTON_GENERATE_LABEL))))));
const renderSelectedMetrics = ({ clickCancelChangesHandler, clickDeleteHandler, clickSavingHandler, errorText, isValid, permissionList, selectedMetricsState, selectedSystemMetrics }) => isEmptyArray(selectedSystemMetrics) && isEmptyArray(selectedMetricsState) ? (React.createElement(React.Fragment, null)) : (React.createElement(SelectedSystemMetricsSection, null,
    React.createElement(SectionTitle, null,
        React.createElement(FormattedMessage, Object.assign({}, SETTINGS_SET_MONITORING_SECTION_CONFIG_METRICS_TITLE))),
    React.createElement(SelectedMetrics, null, (selectedMetricsState || []).map((systemMetric) => (React.createElement(Label, { id: systemMetric.id, key: systemMetric.id, name: systemMetric.text, title: systemMetric.title, onDelete: clickDeleteHandler })))),
    isAllowedSettingsSetActionByPermissions(permissionList, SettingsSetAction.Save) && (React.createElement(ButtonsSection, null,
        renderSaveButton({ errorText, isValid, onSave: clickSavingHandler }),
        React.createElement(StyledButton, { onClick: clickCancelChangesHandler, styletype: ButtonType.outlinedHighlight },
            React.createElement(FormattedMessage, Object.assign({}, SETTINGS_SET_MONITORING_BUTTON_CANCEL_LABEL)))))));
const getMetricsFromItems = (metricItems) => metricItems.map((metricItem) => metricItem.value);
const getSelectedMetricsStateValue = (metricItems, selectedMetricsState) => metricItems.filter((metricItem) => selectedMetricsState.includes(metricItem.value));
const getSelectedMetricsStateNewValue = (systemMetricsMonitoringItems, selectedMetricsState, selectedMetrics) => {
    const metrics = isEmptyArray(selectedMetricsState) ? selectedMetrics : getMetricsFromItems(selectedMetricsState);
    return getSelectedMetricsStateValue(systemMetricsMonitoringItems, metrics);
};
export const Monitoring = (_a) => {
    var { errorText, isValid, onSaveSystemMetric, permissionList, selectedSystemMetrics, settingsSetId, systemMetricsMonitoringItems, systemMetricsMonitoringSelectLabel, systemMetricsMonitoringSelectPlaceholder } = _a, rest = __rest(_a, ["errorText", "isValid", "onSaveSystemMetric", "permissionList", "selectedSystemMetrics", "settingsSetId", "systemMetricsMonitoringItems", "systemMetricsMonitoringSelectLabel", "systemMetricsMonitoringSelectPlaceholder"]);
    const [selectedMetricsState, setSelectedMetricsState] = useState(getSelectedMetricsStateValue(systemMetricsMonitoringItems, selectedSystemMetrics));
    useEffect(() => {
        setSelectedMetricsState(getSelectedMetricsStateNewValue(systemMetricsMonitoringItems, selectedMetricsState, selectedSystemMetrics));
    }, [selectedSystemMetrics, systemMetricsMonitoringItems]);
    const clickSavingHandler = () => typeof onSaveSystemMetric === "function"
        ? onSaveSystemMetric({ settingsSetId, selectedMetrics: selectedMetricsState })
        : undefined;
    const clickCancelChangesHandler = () => setSelectedMetricsState(getSelectedMetricsStateValue(systemMetricsMonitoringItems, selectedSystemMetrics));
    const clickDeleteHandler = (data) => setSelectedMetricsState(selectedMetricsState.filter((selectedMetric) => selectedMetric.id !== data.id));
    const changeSystemMetricMonitoringHandler = (value) => setSelectedMetricsState(value);
    return (React.createElement(StyledBlock, null,
        React.createElement(BlockTitle, null,
            React.createElement(BlockTitleText, null,
                React.createElement(FormattedMessage, Object.assign({}, SETTINGS_SET_MONITORING_TITLE)))),
        React.createElement(StyledBlockContent, null,
            React.createElement(AddingSystemMetricsSection, null,
                React.createElement(SectionTitle, null,
                    React.createElement(FormattedMessage, Object.assign({}, SETTINGS_SET_MONITORING_SECTION_ADD_METRICS_TITLE))),
                React.createElement(Select, { items: systemMetricsMonitoringItems, label: systemMetricsMonitoringSelectLabel, onBlur: changeSystemMetricMonitoringHandler, placeholder: systemMetricsMonitoringSelectPlaceholder, selectionType: SelectionType.Multiple, value: selectedMetricsState })),
            renderSelectedMetrics({
                clickCancelChangesHandler,
                clickDeleteHandler,
                clickSavingHandler,
                errorText,
                isValid,
                permissionList,
                selectedMetricsState,
                selectedSystemMetrics
            }),
            renderConfigFileLinks(Object.assign(Object.assign({}, rest), { selectedMetricsState, selectedSystemMetrics })))));
};
