import { getFromLocalStorage, setToLocalStorage } from "@boomq/utils";
import { THREAD_GROUP_EDITOR_BACKUP_LOCAL_STORAGE_PARAM_NAME } from "./constants";
export const getThreadGroupEditorBackupData = () => {
    try {
        const backupData = getFromLocalStorage(THREAD_GROUP_EDITOR_BACKUP_LOCAL_STORAGE_PARAM_NAME) || "";
        return JSON.parse(backupData);
    }
    catch (error) {
        return {};
    }
};
export const setThreadGroupEditorBackupData = (data) => setToLocalStorage(THREAD_GROUP_EDITOR_BACKUP_LOCAL_STORAGE_PARAM_NAME, JSON.stringify(data));
