var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { getValueByPath } from "@boomq/utils";
import { getAssertionTemplateByParams } from "../../../../models/assertion";
export const AssertionContainer = (_a) => {
    var { assertion, onDelete } = _a, restProps = __rest(_a, ["assertion", "onDelete"]);
    const id = getValueByPath(assertion, "id");
    const changeField = (field, value) => assertion && assertion[field] !== value
        ? restProps.onChangeField({
            id,
            field,
            value
        })
        : undefined;
    const changeConditionHandler = ({ value }) => changeField("condition", value);
    const changeTypeHandler = ({ value }) => changeField("type", value);
    const changeValueHandler = (value) => changeField("value", value);
    const deleteHandler = () => onDelete({ field: "assertions", id });
    const AssertionTemplate = getAssertionTemplateByParams(assertion);
    return (React.createElement(AssertionTemplate, Object.assign({}, restProps, { assertion: assertion, onChangeCondition: changeConditionHandler, onChangeType: changeTypeHandler, onChangeValue: changeValueHandler, onDelete: deleteHandler })));
};
