import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { useIntl } from "@boomq/utils";
import {
    getIsAuthorize,
    getProfileDistribution,
    getRequestGroups,
    newTestActions,
    withResize,
    NEW_THREAD_GROUP
} from "@boomq/common";
import { LOAD_GENERATOR_LABEL } from "./intl";
import { ProfileSettings } from "../../../../components/account/TestProject/ProfileSettings";

const connector = connect(
    (state) => ({
        isAuthorized: getIsAuthorize(state),
        profileDistribution: getProfileDistribution(state),
        threadGroups: getRequestGroups(state)
    }),
    {
        onAddThreadGroup: newTestActions.addThreadGroup
    }
);

const ProfileSettingsContainer = (props) => {
    const { formatMessage } = useIntl();

    const loadGeneratorLabel = formatMessage(LOAD_GENERATOR_LABEL);
    const newThreadGroupButtonLabel = formatMessage(NEW_THREAD_GROUP);

    return (
        <ProfileSettings
            loadGeneratorLabel={loadGeneratorLabel}
            newThreadGroupButtonLabel={newThreadGroupButtonLabel}
            {...props}
        />
    );
};

export default withRouter(withResize(connector(ProfileSettingsContainer)));
