import React from "react";
import { useSelector } from "react-redux";
import { useIntl } from "@boomq/utils";
import { ANOMALY_LABEL_TOOLTIP } from "./intl";
import { getAnomalyEnabled } from "../../../../redux/reducers/testAnomalies";
import { CustomTooltip } from "../../../../components/common/AreaChartCustomComponents/CustomTooltip";
export const CustomTooltipContainer = (props) => {
    const { active, payload, label } = props || {};
    const { formatMessage } = useIntl();
    const isAnomalyEnabled = useSelector(getAnomalyEnabled);
    const anomalyLabel = formatMessage(ANOMALY_LABEL_TOOLTIP);
    if (active && payload && payload.length) {
        const data = payload[0].payload;
        return (React.createElement(CustomTooltip, { active: active, payload: payload, label: label, data: data, isAnomalyEnabled: isAnomalyEnabled, anomalyLabel: anomalyLabel }));
    }
    return null;
};
