import { mergeMap } from "@boomq/utils";
export const getPassRulesErrorsMapByParam = ({ isValidDigit, isValidMaxLength, isValidMinLength, isValidLowercase, isValidSymbol, isValidUppercase }) => isValidDigit && isValidMinLength && isValidMaxLength && isValidLowercase && isValidSymbol && isValidUppercase
    ? {}
    : {
        password: {
            defaultMessage: "Password must be 8+ characters with at least one uppercase letter, one digit, and one special character.",
            id: "validation.password.not.valid"
        }
    };
export const passwordRulesErrors = (violationList) => {
    const defaultValidationData = {
        isValidDigit: true,
        isValidMaxLength: true,
        isValidMinLength: true,
        isValidLowercase: true,
        isValidSymbol: true,
        isValidUppercase: true
    };
    const validationData = (violationList || []).reduce((res) => (Object.assign({}, res)), {});
    return getPassRulesErrorsMapByParam(mergeMap(defaultValidationData, validationData));
};
