import styled from "styled-components";
import { Color, FontBodyMediumBold, IconDropDownArrowLeft, IconDropDownArrowRight } from "@boomq/uikit";
export const CarouselWrapper = styled.div `
    position: relative;
    width: 100%;
`;
export const Wrapper = styled.div `
    width: 100%;
`;
export const Container = styled.div `
    position: relative;
    width: 100%;
    display: flex;
    gap: 15px;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    overflow: hidden;
`;
export const SlideWrapper = styled.div `
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    width: 100%;
    scroll-snap-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
`;
export const ButtonContainer = styled.div `
    display: flex;
    justify-content: space-between;
    padding-top: 28px;
`;
export const ButtonWrapper = styled.div `
    display: flex;
    justify-content: space-between;
    width: 80px;
`;
export const CarouselButton = styled.div `
    width: 38px;
    height: 38px;
    border: 3px solid rgba(219, 224, 239, 0.6);
    background-color: transparent;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    &:active {
        transform: scale(0.9);
    }
`;
export const StyledIconLeft = styled(IconDropDownArrowLeft) `
    fill: ${(props) => (props.isActive ? `${Color.darkGrey95}` : `${Color.grey65}`)};
`;
export const StyledIconRight = styled(IconDropDownArrowRight) `
    fill: ${(props) => (props.isActive ? `${Color.darkGrey95}` : `${Color.grey65}`)};
`;
export const CarouselText = styled.p `
    ${FontBodyMediumBold}
    max-width: 300px;
`;
