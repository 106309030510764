var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { dragOver, drop, getFileExtension, getUniqueId, isEmpty, stopEvent, useIntl } from "@boomq/utils";
import { DEFAULT_EMPTY_TEXT, DEFAULT_UPLOADED_FILES } from "./constants";
import { ErrorMessage, FileItem, FilesList, FileName, FileWrapper, Label, StyledIconClose, StyledIconFile } from "./styled";
const isExistError = (error) => (isEmpty(error) ? false : true);
const generateAcceptByExtensions = (extensions = []) => (extensions || []).map((extension) => `.${extension}`).join(",");
const getClassNameByError = (error) => (isEmpty(error) ? "" : "error");
const renderDefaultPlaceholder = (maxFileSize, formatMessage) => (React.createElement(React.Fragment, null,
    formatMessage(DEFAULT_EMPTY_TEXT),
    maxFileSize &&
        `(
        ${formatMessage({
            defaultMessage: "max.",
            id: "account.new.settings.file.max.size"
        })}
        ${maxFileSize}Mb
        )`));
const renderEmptyText = (emptyText, formatMessage) => emptyText && emptyText.id ? formatMessage(emptyText) : emptyText;
const renderPlaceholder = ({ emptyText, formatMessage, maxFileSize }) => emptyText ? renderEmptyText(emptyText, formatMessage) : renderDefaultPlaceholder(maxFileSize, formatMessage);
class Uploader extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            error: {}
        };
        this.checkAcceptExtensions = (files) => {
            const formattedExtensions = isEmpty(this.props.extensions)
                ? []
                : this.props.extensions.map((extension) => String(extension).toLowerCase());
            const isValid = isEmpty(formattedExtensions)
                ? true
                : files.every((file) => formattedExtensions.includes(getFileExtension(String(file.name)).toLowerCase()));
            return isValid
                ? { isValid }
                : {
                    isValid,
                    error: {
                        defaultMessage: "File extension is not supported. Supported extensions: {extensions}",
                        id: "account.new.settings.file.error.extension.not.supported",
                        values: {
                            extensions: this.props.extensions.join(", ")
                        }
                    }
                };
        };
        this.checkMaxFileSize = (files) => {
            const isValid = this.props.maxFileSize
                ? files.every((file) => file.size < 1048576 * this.props.maxFileSize)
                : true;
            return isValid
                ? { isValid }
                : {
                    isValid,
                    error: {
                        defaultMessage: "Maximum allowed file size for upload exceeded",
                        id: "account.new.settings.file.error.max.size"
                    }
                };
        };
        this.checkUploadFiles = (files) => {
            const { isValid, error } = this.checkMaxFileSize(files);
            return isValid ? this.checkAcceptExtensions(files) : { isValid, error };
        };
        this.dropFileHandler = (e) => this.uploadFileHandler({ target: { files: drop(e) } });
        this.formatFiles = (files = []) => files.map((file) => {
            file.id = getUniqueId();
            return file;
        });
        this.changeFiles = (newFiles) => typeof this.props.onChangeFiles === "function" ? this.props.onChangeFiles(newFiles) : undefined;
        this.removeFile = (event, fileId) => {
            var _a, _b;
            const newFiles = this.props.items.filter((file) => file.id !== fileId);
            stopEvent(event);
            this.changeFiles(newFiles);
            return (_b = (_a = this.props) === null || _a === void 0 ? void 0 : _a.onRemoveFile) === null || _b === void 0 ? void 0 : _b.call(_a, fileId);
        };
        this.uploadFile = (files = []) => {
            var _a, _b;
            const newFiles = [...this.props.items, ...this.formatFiles(files)];
            this.setState({
                error: {}
            });
            this.changeFiles(newFiles);
            return (_b = (_a = this.props) === null || _a === void 0 ? void 0 : _a.onUploadFiles) === null || _b === void 0 ? void 0 : _b.call(_a, this.formatFiles(files));
        };
        this.uploadFileHandler = (event) => {
            const files = [...event.target.files];
            const { isValid, error } = this.checkUploadFiles(files);
            event.target.value = null;
            return isValid ? this.uploadFile(files) : this.setState({ error });
        };
    }
    render() {
        const _a = this.props, { disable, emptyText, extensions, intl: { formatMessage }, items, maxFileSize, name, isSingleFile, onRemoveFile, onUploadFile, theme } = _a, restProps = __rest(_a, ["disable", "emptyText", "extensions", "intl", "items", "maxFileSize", "name", "isSingleFile", "onRemoveFile", "onUploadFile", "theme"]);
        const { error } = this.state;
        const accept = generateAcceptByExtensions(extensions);
        const uploadedFiles = items || DEFAULT_UPLOADED_FILES;
        return (React.createElement(FileWrapper, Object.assign({ disabled: disable, theme: theme }, restProps),
            React.createElement(Label, { className: getClassNameByError(error), isError: isExistError(error), isExistUploadedFiles: uploadedFiles.length > 0, isSingleFile: isSingleFile, onDragOver: dragOver, onDrop: this.dropFileHandler },
                uploadedFiles.length === 0 && renderPlaceholder({ emptyText, formatMessage, maxFileSize }),
                (uploadedFiles.length === 0 || !isSingleFile) && (React.createElement("input", { type: "file", accept: accept, multiple: !isSingleFile, name: name, onChange: this.uploadFileHandler })),
                React.createElement(FilesList, null, uploadedFiles.map((file) => (React.createElement(FileItem, { key: file.id },
                    React.createElement(StyledIconFile, null),
                    React.createElement(FileName, { isSingleFile: isSingleFile }, file.name),
                    React.createElement(StyledIconClose, { onClick: (event) => this.removeFile(event, file.id) })))))),
            error && error.id && (React.createElement(ErrorMessage, null,
                React.createElement(FormattedMessage, Object.assign({}, error))))));
    }
}
const WrappedUploader = (props) => {
    const { formatMessage } = useIntl();
    return React.createElement(Uploader, Object.assign({ intl: { formatMessage } }, props));
};
export default WrappedUploader;
