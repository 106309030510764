import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { AllTestProjectsEmpty } from "../../../components/account/AllTestProjectsEmpty";
import { ImportDataSourceType } from "../../../models/importData";
import { CARDS_CONTENT } from "../../../models/welcomeCardsData";
import { showImportDataToTestProjectForm } from "../../../redux/actions/importTestProjectData";
export const AllTestProjectsEmptyContainer = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const handleCardButtonClick = (index) => {
        if (index === CARDS_CONTENT.length - 1) {
            dispatch(showImportDataToTestProjectForm({ value: ImportDataSourceType.JMX }));
        }
        history.push("/account/new-test");
    };
    return React.createElement(AllTestProjectsEmpty, { onClickhandlerCardButton: handleCardButtonClick });
};
