import React from "react";
import { Select } from "@boomq/uikit";
import { GridContainer, GridHeader, GridItem, SectionDetails, SectionGraph, StyledBlock, StyledDescriptionBlock, Title, TransactionSelectionBlock, customSelectTheme } from "./styled";
import { ResponseTimeChart } from "../TestDetailsBlocks/ResponseTimeChart";
export const TestDetailsTransaction = ({ anomalyDetailsLabels, anomalyTransactionDetails, chartData, chartResponseLabel, chartVUsersLabel, detailsTitle, isAnomalyEnabled, isVisibleTooltipLink, leftYAxisResponseTimeMaxLength, onChangeDataTransaction, onClickDot, onClickGraph, placeholderSelect, rightYAxisValMaxLength, testDetails, tooltipLinkPosition, transactionList }) => (React.createElement(StyledBlock, null,
    React.createElement(SectionGraph, null,
        React.createElement(TransactionSelectionBlock, null,
            React.createElement(Select, { items: transactionList, onChange: onChangeDataTransaction, placeholder: placeholderSelect, theme: customSelectTheme })),
        React.createElement(ResponseTimeChart, { chartData: chartData, chartResponseLabel: chartResponseLabel, chartVUsersLabel: chartVUsersLabel, isAnomalyEnabled: isAnomalyEnabled, isShowAnomalyLegend: true, isVisibleTooltipLink: isVisibleTooltipLink, leftYAxisResponseTimeMaxLength: leftYAxisResponseTimeMaxLength, onClickDot: onClickDot, onClickGraph: onClickGraph, rightYAxisValMaxLength: rightYAxisValMaxLength, testDetails: testDetails, tooltipLinkPosition: tooltipLinkPosition })),
    React.createElement(SectionDetails, null,
        React.createElement(Title, null, detailsTitle),
        React.createElement(StyledDescriptionBlock, null,
            React.createElement(GridContainer, null,
                anomalyDetailsLabels.map((label, index) => (React.createElement(GridHeader, { key: index }, label))),
                isAnomalyEnabled &&
                    (anomalyTransactionDetails || []).map((item, index) => (React.createElement(React.Fragment, { key: index },
                        React.createElement(GridItem, null, item.createdAt),
                        React.createElement(GridItem, null, item.anomalyDescription)))))))));
