import { useEffect, useRef } from "react";
import { isEmpty } from "@boomq/utils";
import { createEditorInstance } from "../../models/reportEditor/editor";
export const useEditorJS = (blocks) => {
    const editorInstance = useRef();
    const isEditorInstanceBlocksRendered = useRef(false);
    const initEditorJS = ({ holderId, initialData, extendedTools }) => {
        editorInstance.current = isEmpty(editorInstance.current)
            ? createEditorInstance(holderId, initialData, extendedTools)
            : editorInstance.current;
    };
    useEffect(() => {
        const setEditorInstanceBlocks = (blocks) => { var _a; return !isEditorInstanceBlocksRendered.current ? (_a = editorInstance.current) === null || _a === void 0 ? void 0 : _a.render({ blocks }) : undefined; };
        const checkBlocks = (blocks) => {
            var _a;
            return editorInstance.current
                ? (_a = editorInstance.current) === null || _a === void 0 ? void 0 : _a.isReady.then(() => {
                    setEditorInstanceBlocks(blocks);
                    isEditorInstanceBlocksRendered.current = true;
                })
                : undefined;
        };
        checkBlocks(blocks);
    }, [blocks]);
    useEffect(() => {
        var _a;
        return (_a = editorInstance.current) === null || _a === void 0 ? void 0 : _a.destroy;
    }, []);
    return { editorInstance, initEditorJS };
};
