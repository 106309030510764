const convertStringToBoolean = (value) => (value === "true" ? true : false);
const getFieldDefaultValue = (fieldData = { defaultValue: null }) => {
    var _a;
    const value = (_a = fieldData.defaultValue) !== null && _a !== void 0 ? _a : "";
    return ["true", "false"].includes(value) ? convertStringToBoolean(value) : value;
};
const isFieldRequired = (fieldData = { required: false }) => (fieldData && fieldData.required ? true : false);
const getRequiredFieldsData = (properties = {}) => Object.keys(properties).reduce((res, fieldName) => isFieldRequired(properties[fieldName])
    ? Object.assign(Object.assign({}, res), { [fieldName]: getFieldDefaultValue(properties[fieldName]) }) : res, {});
export const getTestProjectModelElDefaultValues = (modelSchema = {}, elName) => modelSchema && modelSchema[elName]
    ? getRequiredFieldsData(modelSchema[elName].properties || {})
    : {};
