import { handleActions } from "redux-actions";
import { DEFAULT_REPORTS_PAGE_SIZE, DEFAULT_REPORTS_SORT_DATA } from "./constants";
import authorizeActions from "../../actions/authorize";
import { setCurrentPage, setFilterLabels, setLabelList, setReportsSearchedLabels, reportsSuccess } from "../../actions/reports";
export const initialState = {
    content: [],
    currentPage: 0,
    filterLabels: [],
    labelList: [],
    numberOfElements: 0,
    size: DEFAULT_REPORTS_PAGE_SIZE,
    sortData: DEFAULT_REPORTS_SORT_DATA,
    totalElements: 0,
    totalPages: 0
};
export default handleActions({
    [authorizeActions.logout]: () => (Object.assign({}, initialState)),
    [setCurrentPage]: (state, action) => (Object.assign(Object.assign({}, state), { currentPage: Number.isInteger(action.payload) ? action.payload : 0 })),
    [setFilterLabels]: (state, action) => (Object.assign(Object.assign({}, state), { filterLabels: action && Array.isArray(action.payload) ? action.payload : initialState.filterLabels })),
    [setLabelList]: (state, action) => (Object.assign(Object.assign({}, state), { labelList: action && Array.isArray(action.payload) ? action.payload : initialState.labelList })),
    [setReportsSearchedLabels]: (state, action) => {
        const { id, searchedLabels } = action && action.payload ? action.payload : {};
        return Object.assign(Object.assign({}, state), { content: state.content.map((testProject) => testProject.id === id ? Object.assign(Object.assign({}, testProject), { searchedLabels }) : testProject) });
    },
    [reportsSuccess]: (state, action) => (Object.assign(Object.assign({}, state), action.payload))
}, initialState);
