import styled from "styled-components";
import { Color, IconDelete, IconEdit, IconMail, IconShareLink, MailToLink } from "@boomq/uikit";
import { ActionList } from "@boomq/common";

const IconStyles = `
    fill: ${Color.blue};
`;

export const HiddenDiv = styled.div`
    left: -99999px;
    opacity: 0;
    position: absolute;
    top: -99999px;
`;

export const StyledActionList = styled(ActionList)`
    max-width: 128px;
    min-width: 128px;
`;

export const StyledIconDelete = styled(IconDelete)`
    ${IconStyles}
`;

export const StyledIconEdit = styled(IconEdit)`
    ${IconStyles}
`;

export const StyledIconMail = styled(IconMail)`
    ${IconStyles}
`;

export const StyledIconShareLink = styled(IconShareLink)`
    ${IconStyles}
`;

export const StyledMailToLink = styled(MailToLink)`
    align-items: center;
    display: flex;
    padding: 0 4px;
`;
