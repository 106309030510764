import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { RequestTimerType } from "../../../../helpers/types";
import { RequestJMXTimers } from "../../../../components/account/TestProject/RequestJMXTimers";
import { withResize } from "../../../../components/styled/response";
import { getTimerType } from "../../../../models/requestTimer";
import { newRequestAddTimer, newRequestChangeTimer, newRequestDeleteFieldParam } from "../../../../redux/actions/newRequest";
import { getNewRequestField, getNewRequestTimerList } from "../../../../redux/reducers/newRequest";
import { getTestProjectSettingsField } from "../../../../redux/reducers/newTest";
const getRequestTimerList = (timerList) => (timerList || []).reduce((res, timer) => [RequestTimerType.Constant, RequestTimerType.Random].includes(getTimerType(timer))
    ? Object.assign(Object.assign({}, res), { timerList: [...res.timerList, timer] }) : Object.assign(Object.assign({}, res), { otherTimerList: [...res.otherTimerList, timer] }), { timerList: [], otherTimerList: [] });
const connector = connect((state) => (Object.assign(Object.assign({}, getRequestTimerList(getNewRequestTimerList(state))), { JMXTimersSettings: getTestProjectSettingsField(state, "JMXTimersSettings"), timersRequestLink: getNewRequestField(state, "timersRequestLink") })), { newRequestAddTimer, newRequestChangeTimer, newRequestDeleteFieldParam });
export default withResize(withRouter(connector(RequestJMXTimers)));
