import React, { useEffect } from "react";
import { connect } from "react-redux";
import { isEmpty, useIntl, useResize } from "@boomq/utils";
import { Pagination, Select } from "@boomq/uikit";
import { PAGE_TITLE, TABLE_ACTION_ADD_SETTINGS_SET, TABLE_FILTER_LABEL_PLACEHOLDER } from "./intl";
import { StyledIconPlus, StyledPage, StyledTableRows } from "./styled";
import { TestProjectsSettingsSetsWizard } from "../TestProjectsSettingsSetsWizard";
import { AllTestProjectSettingsSetsItem } from "../../../containers/account/AllTestProjectSettingsSetsItem";
import { TableHeader } from "../../common/TableHeader";
import { sizes } from "../../styled/response";
import { MassActionLink, MassActionsPanel, PaginationWrapper } from "../../styled/Table";
import { tableHeaderColumns } from "../../../data/content/testProjectSettingsSets";
import { EMPTY_LABELS_TEXT } from "../../../helpers/labels";
import { isAllowedSettingsSetActionByPermissions } from "../../../helpers/testProjectSettingsSet";
import { getSiteTitle } from "../../../helpers/title";
import { SelectionType, SettingsSetAction, TestProjectsSettingsSetsWizardItem } from "../../../helpers/types";
import { setTitle } from "../../../redux/actions/accountTitle";
import { setProjectSettingsSetsCurrentPage, testProjectSettingsSetsChangeFilterLabelsRequest, testProjectSettingsSetsRequest } from "../../../redux/actions/testProjectSettingsSets";
import { getClientPermissionList, getIsAuthorize, getSelectedTeamId } from "../../../redux/reducers/authorizePlate";
import { getIsShowLoader } from "../../../redux/reducers/loader";
import { getCurrentPage, getTestProjectSettingsSetsData, getTestProjectSettingsSetsFilterLabels, getTestProjectSettingsSetsLabelList, getTestProjectSettingsSetsPagesCount } from "../../../redux/reducers/testProjectSettingsSets";
const connector = connect((state) => ({
    currentPage: getCurrentPage(state),
    filterLabels: getTestProjectSettingsSetsFilterLabels(state),
    isAuthorized: getIsAuthorize(state),
    isShowLoader: getIsShowLoader(state),
    permissionList: getClientPermissionList(state),
    selectedTeamId: getSelectedTeamId(state),
    testProjectSettingsSetsLabelList: getTestProjectSettingsSetsLabelList(state),
    testProjectSettingsSets: getTestProjectSettingsSetsData(state),
    testProjectSettingsSetsPagesCount: getTestProjectSettingsSetsPagesCount(state)
}), {
    setTitle,
    onChangeLabels: testProjectSettingsSetsChangeFilterLabelsRequest,
    onChangePage: setProjectSettingsSetsCurrentPage,
    onRefresh: testProjectSettingsSetsRequest
});
const AllTestProjectSettingsSets = (props) => {
    const { formatMessage } = useIntl();
    const { width } = useResize();
    useEffect(() => {
        const checkAuthorization = () => props.isAuthorized && !isEmpty(props.selectedTeamId) ? props.onRefresh({ page: currentPage }) : undefined;
        props === null || props === void 0 ? void 0 : props.setTitle("account.header.title.settings");
        document.title = getSiteTitle(formatMessage(PAGE_TITLE));
        checkAuthorization();
    }, [props.isAuthorized, props.selectedTeamId]);
    const { currentPage, filterLabels, isShowLoader, onChangeLabels, testProjectSettingsSets, testProjectSettingsSetsLabelList, testProjectSettingsSetsPagesCount } = props;
    const changeLabelsFilterHandler = (data) => onChangeLabels(data);
    const clickPageHandler = (num) => {
        props.onChangePage(Number(num));
        props.onRefresh({ page: num });
    };
    const emptyLabelsText = formatMessage(EMPTY_LABELS_TEXT);
    return (React.createElement(StyledPage, null,
        React.createElement(TestProjectsSettingsSetsWizard, { selectedWizardItem: TestProjectsSettingsSetsWizardItem.ProjectsSettings }),
        isShowLoader ? (React.createElement(React.Fragment, null)) : (React.createElement(React.Fragment, null,
            React.createElement(MassActionsPanel, null,
                isAllowedSettingsSetActionByPermissions(props.permissionList, SettingsSetAction.Add) && (React.createElement(MassActionLink, { to: "/account/test-settings/new" },
                    React.createElement(StyledIconPlus, null),
                    formatMessage(TABLE_ACTION_ADD_SETTINGS_SET))),
                React.createElement(Select, { intlFormatFunc: formatMessage, items: testProjectSettingsSetsLabelList, onBlur: changeLabelsFilterHandler, placeholder: TABLE_FILTER_LABEL_PLACEHOLDER, selectionType: SelectionType.Multiple, textAttrName: "name", titleAttrName: "name", valueAttrName: "name", value: filterLabels })),
            width > sizes.tablet && React.createElement(TableHeader, { columns: tableHeaderColumns }),
            React.createElement(StyledTableRows, null, (testProjectSettingsSets || []).map((setting) => (React.createElement(AllTestProjectSettingsSetsItem, { emptyLabelsText: emptyLabelsText, key: setting.id, setting: setting, width: width })))),
            Boolean(testProjectSettingsSets &&
                testProjectSettingsSets.length &&
                testProjectSettingsSetsPagesCount > 1) && (React.createElement(PaginationWrapper, null,
                React.createElement(Pagination, { totalPages: testProjectSettingsSetsPagesCount, currentPage: currentPage, clickHandler: clickPageHandler })))))));
};
export default connector(AllTestProjectSettingsSets);
