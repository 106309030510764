import styled from "styled-components";
import { Color, IconRegExp } from "@boomq/uikit";
export const tooltipTheme = {
    maxWidth: "400px",
    textAlign: "left"
};
export const StyledIconRegExp = styled(IconRegExp) `
    fill: ${Color.darkGrey5};
`;
export const ExtractorsCount = styled.span `
    bottom: -10px;
    color: ${Color.darkGrey5};
    left: 24px;
    position: absolute;
`;
export const ExtractorsIcon = styled.span `
    align-items: center;
    display: flex;
`;
export const ExtractorsIconWithCounter = styled.div `
    margin-left: 8px;
    position: relative;
`;
