import { handleActions } from "redux-actions";
import { getUniqueId, isEmpty } from "@boomq/utils";
import { changeRequestParameterCsvHeader, changeRequestParameters, changeRequestParametersField, deleteRequestLiteralParameter, uploadCsvFileSuccess } from "../actions/requestParameters";
import { saveTestProjectSettingsSetSuccess } from "../actions/testProjectSettingsSet";
import { getRequestsExtractParameters } from "../reducers/newTest";
export const initialState = {
    csvParameters: [],
    literalParameters: [],
    others: []
};
export default handleActions({
    [changeRequestParameterCsvHeader]: (state, { payload }) => {
        const { id, index, value } = payload || {};
        return !isEmpty(id) && !isEmpty(index)
            ? Object.assign(Object.assign({}, state), { csvParameters: state.csvParameters.map((parameter) => (parameter.id === id &&
                    (parameter.parameterNames[index] = value) &&
                    (parameter.parameterNames = [...parameter.parameterNames]) &&
                    parameter) ||
                    parameter) }) : state;
    },
    [changeRequestParameters]: (state, { payload = { csvParameters: [], literalParameters: [] } }) => (Object.assign(Object.assign({}, state), payload)),
    [changeRequestParametersField]: (state, { payload }) => {
        const { id, field, subfield, value } = payload || {};
        return subfield
            ? Object.assign(Object.assign({}, state), { [field]: state[field].map((parameter) => (parameter.id === id && (parameter[subfield] = value) && parameter) || parameter) }) : Object.assign(Object.assign({}, state), { [field]: Array.isArray(value) ? value : state[field] });
    },
    [deleteRequestLiteralParameter]: (state, { payload }) => {
        const { id } = payload || {};
        return id
            ? Object.assign(Object.assign({}, state), { literalParameters: [
                    ...state.literalParameters.filter((requestLiteralParameter) => requestLiteralParameter.id !== id)
                ] }) : state;
    },
    [saveTestProjectSettingsSetSuccess]: (state) => (Object.assign(Object.assign({}, state), { csvParameters: state.csvParameters.map((csvParameter) => (Object.assign(Object.assign({}, csvParameter), { isSavedCsvFile: true }))) })),
    [uploadCsvFileSuccess]: (state, { payload }) => (Object.assign(Object.assign({}, state), { csvParameters: payload }))
}, initialState);
const getStateParam = (state, paramName) => state && state.requestParameters ? state.requestParameters[paramName] : initialState[paramName];
export const getRequestLiteralParameters = (state) => getStateParam(state, "literalParameters");
export const getRequestParametersImportCsv = (state) => getStateParam(state, "csvParameters");
export const getRequestParametersOthers = (state) => getStateParam(state, "others");
export const getRequestGlobalParametersList = (state) => [
    ...getRequestLiteralParameters(state).map((parameter) => ({ id: parameter.id, name: parameter.key })),
    ...getRequestParametersImportCsv(state).reduce((res, parameter) => [
        ...res,
        ...parameter.parameterNames.reduce((res, parameterName) => [...res, { id: getUniqueId(), name: parameterName }], [])
    ], [])
];
export const getAllRequestParametersList = (state) => [
    ...getRequestGlobalParametersList(state),
    ...getRequestsExtractParameters(state)
];
