import { st1, st2, st3, st4, st5 } from "../../assets/images/illustrations/check_status";
export const preliminaryStage1 = {
    img: st1,
    index: 0,
    percent: 20,
    title: {
        defaultMessage: "Collection of Statistics",
        id: "account.current.step.first"
    }
};
export const preliminaryStage2 = {
    img: st2,
    index: 1,
    percent: 40,
    title: {
        defaultMessage: "Creating the profile",
        id: "account.current.step.second"
    }
};
export const preliminaryStage3 = {
    img: st3,
    index: 2,
    percent: 60,
    title: {
        defaultMessage: "Creating scripts",
        id: "account.current.step.third"
    }
};
export const preliminaryStage4 = {
    img: st4,
    index: 3,
    percent: 80,
    title: {
        defaultMessage: "Resources allocating",
        id: "account.current.step.fourth"
    }
};
export const preliminaryStage5 = {
    img: st5,
    index: 4,
    percent: 100,
    title: {
        defaultMessage: "Running the test",
        id: "account.current.step.fifth"
    }
};
