import { call, delay, put, race, select, take, takeEvery } from "redux-saga/effects";
import { getValueByPath, isEmpty } from "@boomq/utils";
import { TEST_DETAILS_RESULT_LOG_FILES_MAX_REQUEST_COUNT, TEST_DETAILS_RESULT_LOG_FILES_TIMEOUT_MS, TEST_DETAILS_SOCKET_MAX_REQUEST_COUNT, TEST_DETAILS_SOCKET_TIMEOUT_MS } from "./constants";
import { defaultLoopSaga, loopWithStopAction } from "../core";
import { getGraphData, getTestDetailsForPlateWithSocket, setTestDetailsData, testDetailsFailure, testDetailsRequest, testDetailsSuccess, testDetailsForPlateWithSocketRequestStop, testProjectVersionDataFailure, testProjectVersionDataRequest, testProjectVersionDataSuccess, testResultLogFilesRequestStop, testResultLogFileDownloadLinkRequest, testResultLogFileDownloadLinkFailure, updateTestResultSlaFailure, updateTestResultSlaRequest, updateTestResultSlaSuccess } from "../../actions/testDetails";
import { getRequests } from "../../reducers/newTest";
import { getTestDetailsData } from "../../reducers/testDetails";
import { downloadTestProjectVersionData } from "../../sagas/testProjects";
import { getFormattedUTCDate } from "../../../helpers/date";
import { getResponseErrorData } from "../../../helpers/errors";
import { generateTestProjectSlaParams } from "../../../helpers/generators";
import { prepareSlaGroupList } from "../../../helpers/parsers";
import { prepareResultLogFiles } from "../../../helpers/testDetails";
import { formatStatsResult, formatStatsTime } from "../../../helpers/testDetailsStats";
import { isTestCompleted, isTestDuringExecution } from "../../../helpers/testStatus";
import { TestResultFilesState } from "../../../helpers/types";
import { getSysMetricsGrafanaUrl, getTestGrafanaUrl } from "../../../services/grafanaSrv";
import { getTestResult } from "../../../services/timescaleSrv";
import { getClientTest, getTestResultFiles, getTestResultFileDownloadLink, updateTestResultSla } from "../../../services/testSrv";
function* getTestDetailedStatsUrl({ currentGrafanaUrl, currentId, data }) {
    return !currentGrafanaUrl || currentId !== data.id ? yield* testDetailedStatsFlow(data) : currentGrafanaUrl;
}
function* getSysMetricsDetailedStatsUrl({ currentGrafanaSysMetricsUrl, currentId, currentSettingsId, data }) {
    const { settingsId } = data && data.testProjectVersion ? data.testProjectVersion : {};
    return !currentGrafanaSysMetricsUrl || currentId !== data.id || currentSettingsId !== settingsId
        ? yield* testDetailedSysMetricsStatsFlow(data)
        : currentGrafanaSysMetricsUrl;
}
function* refreshTestData(data) {
    const { grafanaTestUrl: currentGrafanaUrl, grafanaSysMetricsUrl: currentGrafanaSysMetricsUrl, id: currentId, testProjectVersion } = yield select(getTestDetailsData);
    const { settingsId: currentSettingsId } = testProjectVersion || {};
    const slaGroupList = getValueByPath(data, "testResult.slaResults.slaGroupList", []);
    const testResultFilesState = getValueByPath(data, "testResult.testResultFilesState");
    const grafanaTestUrl = yield* getTestDetailedStatsUrl({ currentGrafanaUrl, currentId, data });
    const grafanaSysMetricsUrl = yield* getSysMetricsDetailedStatsUrl({
        currentGrafanaSysMetricsUrl,
        currentId,
        currentSettingsId,
        data
    });
    yield put(testDetailsSuccess(Object.assign(Object.assign({}, data), { result: data.result ? formatStatsResult(data.result) : data.result, sla: prepareSlaGroupList(slaGroupList), timeline: data.result ? formatStatsTime(data.result.timeline) : [], grafanaTestUrl,
        grafanaSysMetricsUrl })));
    return data.id && isTestCompleted(data.displayState) && testResultFilesState !== TestResultFilesState.Failed
        ? yield* testDetailedResultLogFilesFlow(data)
        : undefined;
}
export function* testDetailsRequestFlow(action) {
    try {
        const data = yield* testDetailsDataFlow(action) || {};
        return yield* refreshTestData(data);
    }
    catch (e) {
        yield put(testDetailsFailure(getResponseErrorData(e)));
    }
}
function* testDetailsDataFlow(action) {
    try {
        const { testId } = action && action.payload ? action.payload : {};
        const { data } = yield call(getClientTest, testId);
        const result = yield* testDetailedResultFlow(data);
        return Object.assign(Object.assign({}, (data || {})), { result });
    }
    catch (e) {
        yield put(testDetailsFailure(getResponseErrorData(e)));
    }
}
function* testDetailedResultFlow(data) {
    try {
        const { id, startDate, endDate } = data || {};
        const resultResponse = startDate
            ? yield call(getTestResult, {
                testId: id,
                testStartDate: startDate,
                testEndDate: endDate ? endDate : getFormattedUTCDate()
            })
            : undefined;
        return resultResponse && resultResponse.data ? resultResponse.data : {};
    }
    catch (e) {
        yield put(testDetailsFailure(getResponseErrorData(e)));
    }
}
export const defineIsContinuous = ({ count, maxRequestCount, stopRequest }) => count > maxRequestCount || stopRequest ? false : true;
export function* setDelayByParams(isContinuous, delayTime) {
    return isContinuous ? yield delay(delayTime) : undefined;
}
function* getTestResultLogFiles(params) {
    const { testId } = params;
    let isContinuous = true;
    let resultLogFiles = [];
    try {
        const { resultLogFilesResponse, stopAction } = testId
            ? yield race({
                resultLogFilesResponse: call(getTestResultFiles, testId),
                stopAction: take(testResultLogFilesRequestStop)
            })
            : { stopAction: true };
        resultLogFiles = getValueByPath(resultLogFilesResponse, "data", []);
        isContinuous = stopAction ? false : true;
    }
    catch (e) {
        console.error(e);
    }
    return {
        data: resultLogFiles,
        isContinuous: isContinuous ? resultLogFiles.length === 0 : false
    };
}
function* testDetailedResultLogFiles({ testId }) {
    const resultLogFiles = yield* defaultLoopSaga({
        delayTime: TEST_DETAILS_RESULT_LOG_FILES_TIMEOUT_MS,
        maxCount: TEST_DETAILS_RESULT_LOG_FILES_MAX_REQUEST_COUNT,
        testId
    }, getTestResultLogFiles);
    const { data } = yield call(getClientTest, testId);
    yield put(testDetailsSuccess(Object.assign(Object.assign({}, data), { resultLogFiles: prepareResultLogFiles(resultLogFiles || []) })));
}
function* testDetailedResultLogFilesFlow({ id }) {
    try {
        yield* loopWithStopAction({
            loopSaga: testDetailedResultLogFiles,
            params: { testId: id },
            stopAction: testResultLogFilesRequestStop
        });
    }
    catch (e) {
        yield put(testDetailsFailure(getResponseErrorData(e)));
    }
}
function* testDetailedStatsFlow(data) {
    try {
        const { endDate, id, startDate } = data || {};
        const testEndTime = endDate ? endDate : getFormattedUTCDate();
        const response = startDate && id
            ? yield call(getTestGrafanaUrl, { testEndTime, testId: id, testStartTime: startDate })
            : null;
        const { redirectUrl } = response && response.data ? response.data : {};
        return redirectUrl;
    }
    catch (e) {
        yield put(testDetailsFailure(getResponseErrorData(e)));
    }
}
function* testDetailedSysMetricsStatsFlow(data) {
    try {
        const { endDate, testProjectVersion, startDate } = data || {};
        const { settingsId } = testProjectVersion || {};
        const testEndTime = endDate ? endDate : getFormattedUTCDate();
        const response = startDate && settingsId
            ? yield call(getSysMetricsGrafanaUrl, { settingsId, testEndTime, testStartTime: startDate })
            : null;
        const { redirectUrl } = response && response.data ? response.data : {};
        return redirectUrl;
    }
    catch (e) {
        yield put(testDetailsFailure(getResponseErrorData(e)));
        return "";
    }
}
function* graphDataFlow(action) {
    try {
        const data = yield* testDetailsDataFlow(action) || {};
        return data.testProject && data.testProject.id > -1 && isTestCompleted(data.displayState)
            ? yield* refreshTestData(data)
            : yield put(setTestDetailsData({
                result: data.result ? formatStatsResult(data.result) : data.result,
                timeline: data.result ? formatStatsTime(data.result.timeline) : []
            }));
    }
    catch (e) {
        console.error(e);
    }
}
function* testResultLogFileDownloadLinkFlow(action) {
    try {
        const params = action && action.payload ? action.payload : {};
        const { data } = yield call(getTestResultFileDownloadLink, params);
        window.open(data);
        yield put(testDetailsSuccess());
    }
    catch (e) {
        yield put(testResultLogFileDownloadLinkFailure(getResponseErrorData(e)));
    }
}
function* getTestDetailsTestProjectVersionData() {
    const { testProjectId, testProjectVersion, testProjectVersionId } = yield select(getTestDetailsData);
    const { testType } = testProjectVersion || {};
    const { elementsData = {}, groups, requests, transactions } = yield* downloadTestProjectVersionData({
        id: testProjectId,
        testType,
        version: testProjectVersionId
    });
    return { elementsData, groups, requests, testType, transactions };
}
function* testProjectVersionDataFlow() {
    try {
        const testDetailsRequests = yield select(getRequests);
        const testDetailsTestProjectVersionData = isEmpty(testDetailsRequests)
            ? yield* getTestDetailsTestProjectVersionData()
            : {};
        yield put(testProjectVersionDataSuccess(testDetailsTestProjectVersionData));
    }
    catch (e) {
        yield put(testProjectVersionDataFailure(getResponseErrorData(e)));
    }
}
function* updateTestResultSlaFlow(action) {
    try {
        const { testId, slaData } = action && action.payload ? action.payload : {};
        const { data } = yield call(updateTestResultSla, {
            testId,
            params: generateTestProjectSlaParams(slaData)
        });
        const slaGroupList = getValueByPath(data, "slaResults.slaGroupList", []);
        yield put(updateTestResultSlaSuccess({
            sla: prepareSlaGroupList(slaGroupList),
            testResultSlaState: data.testResultSlaState
        }));
    }
    catch (e) {
        yield put(updateTestResultSlaFailure(getResponseErrorData(e)));
    }
}
function* setTestDetails(data) {
    const { displayState: currentDisplayState } = yield select(getTestDetailsData);
    return currentDisplayState !== data.displayState && isTestDuringExecution(data.displayState)
        ? yield* refreshTestData(data)
        : yield put(testDetailsSuccess(data));
}
function* setTestDetailsByParams(isContinuous, data) {
    return isContinuous ? yield* setTestDetails(data) : undefined;
}
export function* getTestDetails({ testId }) {
    try {
        const { testDetailsResponse, stopAction } = testId
            ? yield race({
                testDetailsResponse: call(getClientTest, testId),
                stopAction: take(testDetailsForPlateWithSocketRequestStop)
            })
            : { stopAction: true };
        const isContinuous = stopAction ? false : true;
        yield* setTestDetailsByParams(isContinuous, testDetailsResponse.data);
        return { isContinuous };
    }
    catch (e) {
        console.error(e);
    }
}
function* testDetailsForPlateWithSocketFlow(action) {
    try {
        const { testId } = action && action.payload ? action.payload : {};
        yield* loopWithStopAction({
            delayTime: TEST_DETAILS_SOCKET_TIMEOUT_MS,
            isDelayBeforeStart: true,
            loopFunc: getTestDetails,
            maxCount: TEST_DETAILS_SOCKET_MAX_REQUEST_COUNT,
            params: { testId },
            stopAction: testDetailsForPlateWithSocketRequestStop
        });
    }
    catch (e) {
        yield put(testDetailsFailure(getResponseErrorData(e)));
    }
}
export function* testDetailsFlow() {
    yield takeEvery(testDetailsRequest, testDetailsRequestFlow);
    yield takeEvery(getGraphData, graphDataFlow);
    yield takeEvery(getTestDetailsForPlateWithSocket, testDetailsForPlateWithSocketFlow);
    yield takeEvery(testResultLogFileDownloadLinkRequest, testResultLogFileDownloadLinkFlow);
    yield takeEvery(testProjectVersionDataRequest, testProjectVersionDataFlow);
    yield takeEvery(updateTestResultSlaRequest, updateTestResultSlaFlow);
}
