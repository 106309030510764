import { objectToParams } from "@boomq/utils";
import { instance } from "./authSrv";
const location = window.location;
const servicePort = location.port ? `:${location.port}` : "";
const serviceName = "test-runner-srv";
export const getTestRunnerSrvUrl = () => `${location.protocol}//${location.hostname}${servicePort}/${serviceName}`;
export const addTestRunnerResource = (params) => instance.post(`${getTestRunnerSrvUrl()}/testRunner`, params);
export const changeTestRunnerResource = (id, params) => instance.put(`${getTestRunnerSrvUrl()}/testRunner/${id}`, params);
export const deleteTestRunnerResource = (id) => instance.delete(`${getTestRunnerSrvUrl()}/testRunner/${id}`);
export const getTestRunnerResource = (id) => instance.get(`${getTestRunnerSrvUrl()}/testRunner/${id}`);
export const getTestRunnerLocations = () => instance.get(`${getTestRunnerSrvUrl()}/testRunLocation`);
export const getTestRunnerResources = (props) => instance.get(`${getTestRunnerSrvUrl()}/testRunner?sort=id,desc${objectToParams(props)}`);
export const getTestStartEstimates = (testId) => instance.get(`${getTestRunnerSrvUrl()}/testStartEstimates?testId=${testId}`);
