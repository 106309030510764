import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getUniqueId, useIntl } from "@boomq/utils";
import { keyValueTheme } from "./styled";
import { KeyValue } from "../../KeyValue";
import { withResize } from "../../../styled/response";
import { FormType } from "../../../../helpers/types";
import { newRequestChangeParamsField, newRequestDeleteFieldParam } from "../../../../redux/actions/newRequest";
import { getNewRequestField } from "../../../../redux/reducers/newRequest";
import { getComboBoxRequestParametersList } from "../../../../redux/reducers/newTest";
const connector = connect((state) => ({
    metadata: getMetadata(state),
    requestUrl: getNewRequestField(state, "requestUrl"),
    requestParametersList: getComboBoxRequestParametersList(state)
}), { newRequestChangeParamsField, newRequestDeleteFieldParam });
const getMetadata = (state) => [...getNewRequestField(state, "metadata"), { id: getUniqueId(), key: "", value: "" }];
const Metadata = (props) => {
    const { formatMessage } = useIntl();
    const changeHandler = (val, id) => {
        const newMetadata = [
            ...props.metadata.map((metadataItem) => (metadataItem.id === id && Object.assign(Object.assign({}, metadataItem), val)) || metadataItem)
        ].filter((metadataItem) => metadataItem.key !== "" || metadataItem.value !== "");
        props.newRequestChangeParamsField({
            field: props.name,
            value: newMetadata
        });
    };
    const deleteHandler = (id) => props.newRequestDeleteFieldParam({ field: props.name, id });
    const keyPlaceholder = formatMessage({
        defaultMessage: "Key",
        id: "account.request.form.key.placeholder"
    });
    const valuePlaceholder = formatMessage({
        defaultMessage: "Value",
        id: "account.request.form.value.placeholder"
    });
    return (props.metadata || []).map((metadata) => (React.createElement(KeyValue, { disabled: props.mode === FormType.View, key: metadata.id, name: props.name, onChange: (val) => changeHandler(val, metadata.id), onDelete: () => deleteHandler(metadata.id), parameters: props.requestParametersList, placeholderKey: keyPlaceholder, placeholderValue: valuePlaceholder, theme: keyValueTheme, value: { key: metadata.key, value: metadata.value } })));
};
export default withResize(withRouter(connector(Metadata)));
