import { IntlMessage } from "../../../helpers/types";
import {
    INVALID_LICENSE_FOR_INSTALLATION_TEST_CONCURRENT_COUNT,
    UNABLE_TO_ADD_TEST_RUNNER_LICENSE_LIMIT_REACHED
} from "./intl";

const API_ERROR_MESSAGES_BY_EXCEPTION_MESSAGE = {
    INVALID_LICENSE_FOR_INSTALLATION_TEST_CONCURRENT_COUNT,
    UNABLE_TO_ADD_TEST_RUNNER_LICENSE_LIMIT_REACHED
};

export const getApiErrorMessageByExceptionMessage = (exceptionMessage: string): IntlMessage =>
    API_ERROR_MESSAGES_BY_EXCEPTION_MESSAGE[exceptionMessage];
