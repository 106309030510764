var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import styled from "styled-components";
import { withDisable } from "../../styled/global";
import check from "../../../assets/images/icons/check.svg";
const CustomCheckbox = (_a) => {
    var { checked, disabled, label, name, onToggle, theme } = _a, rest = __rest(_a, ["checked", "disabled", "label", "name", "onToggle", "theme"]);
    const changeHandler = () => undefined;
    const clickHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();
        typeof onToggle === "function" && onToggle(e);
    };
    return (React.createElement(StyledCheckBox, Object.assign({ disabled: disabled, onClick: clickHandler, theme: theme }, rest),
        React.createElement(PseudoInput, { checked: checked, name: name, onChange: changeHandler, type: "checkbox" }),
        React.createElement(PseudoLabel, null),
        React.createElement(Label, null, label)));
};
const Label = styled.div `
    margin-left: 4px;
`;
const StyledCheckBox = styled.div `
    ${withDisable}
    align-items: center;
    cursor: pointer;
    display: flex;
    padding: 5px;
`;
const PseudoLabel = styled.div `
    border: 2px solid #4989d6;
    border-radius: 4px;
    height: 22px;
    min-width: 22px;
    position: relative;
    width: 22px;
    &:after {
        background: url(${check}) center center no-repeat;
        content: "";
        display: block;
        height: 18px;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        transition: all 0.3s ease-in-out;
        width: 18px;
    }
`;
const PseudoInput = styled.input `
    display: none;
    &:checked + ${PseudoLabel}:after {
        opacity: 1;
    }
`;
export default CustomCheckbox;
