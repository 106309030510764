import styled from "styled-components";
import { NewItemButton } from "../NewItemButton";
export const customTabListTheme = {
    margin: "32px 0 16px"
};
export const ButtonSection = styled.div `
    display: flex;
    justify-content: center;
    margin-top: 20px;
`;
export const Sla = styled.div `
    display: flex;
    flex-direction: column;
    min-height: 100%;
`;
export const SlaDataSection = styled.div `
    flex: auto;
`;
export const StyledNewItemButton = styled(NewItemButton) `
    margin-top: 16px;
`;
