var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { getContrastColorByIndex, isEmpty, isExistObjectInArray, getObjectIndexInArray, getValuesFromArrayByKeyMask, getValuesFromStringByMask, getUniqueId, getValueByPath } from "@boomq/utils";
import { getConfigDefaultTimer, getConfigHttpRequestDefaults } from "../config";
import { getNewRequestGroupId, getNewRequestId, getNewRequestParameters, getNewRequestUsedExtractRequestParameterNames } from "../newRequest";
import { getEnabledJMXProfiles } from "../../../helpers/generators/testProjectOwnJmx";
import { calculateBoomqStableScalabilityTestDurationByPercentDistribution, calculateBoomqStableScalabilityTestDurationByUsersDistribution } from "../../../helpers/loadProfile";
import { getElementsFromMapByControllerType, getElementsFromMapByType } from "../../../helpers/testProject";
import { TestType } from "../../../helpers/types/test";
import { PARAMETER_REG_EXP_MASK } from "../../../models/request/constants";
import { initialTestProjectSettings, initScalabilitySettings, initSettings, ProfileDistribution } from "../../../models/testProject";
import { ControllerType, ThreadGroupController } from "../../../models/threadGroupEditor/enums";
const getStateParam = (state, paramName) => state && state.newTest ? state.newTest[paramName] : initialTestProjectSettings[paramName];
const getGroupName = (group) => (group ? group.name : "");
const getGroupNameByGroupId = (state, id) => state && state.newTest && Array.isArray(state.newTest.groups)
    ? getGroupName(state.newTest.groups.find((group) => group.id === id))
    : "";
export const getTestProjectLoadProfileSettings = (settings) => Object.keys(Object.assign(Object.assign({}, initSettings), initScalabilitySettings)).reduce((result, setting) => (!isEmpty(settings[setting]) ? Object.assign(Object.assign({}, result), { [setting]: settings[setting] }) : result), {});
export const getAllTimersWithRequestCount = (state) => (getRequests(state) || []).reduce((res, request) => (request.timerList || []).reduce((sumRes, timerId) => (Object.assign(Object.assign({}, sumRes), { [timerId]: sumRes[timerId] ? [...sumRes[timerId], request.id] : [request.id] })), res), {});
export const getAutoPercentages = (state) => getStateParam(state, "autoPercentages");
export const getDefaultCookiesData = (state) => getStateParam(state, "defaultCookies");
export const getDefaultCookieItems = (state) => {
    const defaultCookiesData = getDefaultCookiesData(state);
    return defaultCookiesData && Array.isArray(defaultCookiesData.items) ? defaultCookiesData.items : [];
};
export const getDefaultCookiesParams = (state) => {
    const _a = getDefaultCookiesData(state), { items } = _a, restData = __rest(_a, ["items"]);
    return restData ? restData : {};
};
export const getDefaultHeaders = (state) => getTestProjectSettingsField(state, "defaultHeaders");
export const getDefaultTimerSettings = (state) => (Object.assign(Object.assign({}, getConfigDefaultTimer(state)), getTestProjectSettingsField(state, "defaultTimer")));
export const getHttpRequestDefaults = (state) => (Object.assign(Object.assign({}, getConfigHttpRequestDefaults(state)), getTestProjectSettingsField(state, "httpRequestDefaults")));
export const getIsLabelsFromProjectCopying = (state) => getStateParam(state, "isLabelsFromProjectCopying");
export const getIsModified = (state) => getStateParam(state, "isModified");
export const getIsSaved = (state) => getStateParam(state, "isSaved");
export const getIsSavedAs = (state) => getStateParam(state, "isSavedAs");
export const getTestProjectSettings = (state) => state.newTest;
export const getCurrentStep = (state) => getStateParam(state, "step");
export const getPreviewJMXChartData = (state) => getStateParam(state, "previewJMXChart");
export const getIsMergedJMXChartSteps = (state) => {
    const previewChartData = getPreviewJMXChartData(state);
    return previewChartData ? previewChartData.isMergedJMXChartSteps : false;
};
export const getIsMergedJMXChartGroups = (state) => {
    const previewChartData = getPreviewJMXChartData(state);
    return previewChartData ? previewChartData.isMergedJMXChartGroups : false;
};
export const getJMXTestDuration = (state) => getStateParam(state, "JMXTestDurationSec");
export const getPreviewJMXChartTimeline = (state) => {
    const previewChartData = getPreviewJMXChartData(state);
    return previewChartData ? previewChartData.timeLine : initialTestProjectSettings.previewJMXChart.timeLine;
};
const getThreadGroupsChartData = (state) => getStateParam(state, "previewThreadGroupsChart");
export const getIsMergedChartThreadGroups = (state) => {
    const previewChartData = getThreadGroupsChartData(state);
    return previewChartData
        ? previewChartData.isMergedChartGroups
        : initialTestProjectSettings.previewThreadGroupsChart.isMergedChartGroups;
};
export const getPreviewThreadGroupsChartTimeline = (state) => {
    const previewChartData = getThreadGroupsChartData(state);
    return previewChartData ? previewChartData.timeLine : initialTestProjectSettings.previewThreadGroupsChart.timeLine;
};
export const getProjectLabels = (state) => getStateParam(state, "projectLabels");
export const getTestLabels = (state) => getStateParam(state, "testLabels");
export const getAllLabels = (state) => [...getProjectLabels(state), ...getTestLabels(state)];
export const getLoadProfiles = (state) => getStateParam(state, "JMXProfiles");
export const getLoadProfileByGroupId = (state, groupId) => {
    const JMXProfiles = getLoadProfiles(state);
    return JMXProfiles && JMXProfiles[groupId] ? state.newTest.JMXProfiles[groupId] : {};
};
export const getLoadProfileParamsByTestType = (state, testType) => {
    switch (testType) {
        case TestType.JMX: {
            return getLoadProfiles(state);
        }
        case TestType.Scalability:
        case TestType.Stable: {
            return getTestProjectSettingsField(state, testType);
        }
        default: {
            return {};
        }
    }
};
export const getLoadProfilesChartLegend = (state) => state && state.newTest && state.newTest.JMXProfiles && state.newTest.groups
    ? Object.keys(getEnabledJMXProfiles(state.newTest.JMXProfiles, state.newTest.groups)).reduce((res, groupId, index) => {
        const JMXProfile = state.newTest.JMXProfiles[groupId] || {};
        return [
            ...res,
            {
                color: Array.isArray(JMXProfile.chartData) && JMXProfile.chartData.length > 0
                    ? JMXProfile.chartData[0].params.color
                    : getContrastColorByIndex(index),
                groupId,
                type: "line",
                value: getGroupNameByGroupId(state, groupId)
            }
        ];
    }, [])
    : [];
export const getMaxTransactionIndex = (state) => getStateParam(state, "maxTransactionIndex");
export const getNewTestSettingsField = (field) => (state) => getStateParam(state, field);
export const getTestProjectSettingsField = (state, field) => state && state.newTest ? state.newTest[field] : undefined;
export const getProfileDistribution = (state) => getStateParam(state, "profileDistribution");
export const getProjectId = (state) => getStateParam(state, "projectId");
export const getProjectName = (state) => getStateParam(state, "projectName");
export const getProjectVersion = (state) => getStateParam(state, "currentVersion");
export const getRequestGroups = (state) => getStateParam(state, "groups");
export const getSettingsId = (state) => getStateParam(state, "settingsId");
export const getRequestGroupById = (state, groupId) => {
    const groups = getRequestGroups(state);
    return Array.isArray(groups) ? groups.find(({ id }) => id === groupId) : {};
};
export const getJMXTransactions = (state) => getStateParam(state, "transactions");
export const getThreadGroupsRequests = (state) => {
    const elements = getThreadGroupsElements(state);
    return getElementsFromMapByType(elements, ThreadGroupController.Sampler);
};
export const getThreadGroupsTransactions = (state) => {
    const elements = getThreadGroupsElements(state);
    const testType = getTestType(state);
    return testType === TestType.JMX
        ? getJMXTransactions(state)
        : getElementsFromMapByControllerType(elements, ControllerType.Transaction);
};
export const getRequests = (state) => getStateParam(state, "requests");
export const getRequestDataById = (state, id) => {
    const requests = getRequests(state);
    return requests.find((request) => request.id === id);
};
export const getRequestTimers = (state) => getStateParam(state, "timers");
export const getRequestsCount = (state) => getRequests(state).length;
export const getRequestParametersData = (state) => getStateParam(state, "requestParameters");
export const getSlaData = (state) => getStateParam(state, "sla");
export const getSlaDataByType = (state, type) => getSlaData(state)[type];
export const getSlaGroupList = (state) => state && state.newTest
    ? Object.keys(state.newTest.sla).reduce((res, slaTargetType) => [...res, ...state.newTest.sla[slaTargetType]], [])
    : [];
export const getSavedProjectId = (state) => getStateParam(state, "savedProjectId");
export const getRequestLiteralParameters = (state) => getStateParam(state, "requestParameters").literalParameters;
export const getRequestParametersImportCsv = (state) => getStateParam(state, "requestParameters").csvParameters;
export const getRequestGlobalParametersList = (state) => [
    ...getRequestLiteralParameters(state).map((parameter) => ({ id: parameter.id, name: parameter.key })),
    ...getRequestParametersImportCsv(state).reduce((res, parameter) => [
        ...res,
        ...parameter.parameterNames.reduce((res, parameterName) => [...res, { id: getUniqueId(), name: parameterName }], [])
    ], [])
];
export const getComboBoxRequestParametersList = (state) => [
    ...getRequestGlobalParametersList(state),
    ...getRequestExtractParametersByGroup(state)
];
export const getAllRequestParametersList = (state) => [
    ...getRequestGlobalParametersList(state),
    ...getRequestsExtractParameters(state)
];
export const getAllUsedRequestParameterNames = (state) => getRequestParameterNames(getRequests(state));
export const getUsedExtractParametersByRequest = (requests) => (groupId) => (requestId) => getRequestParameterNames(getRequestParametersByGroupIdRequestIndex({
    groupId,
    requestId,
    requests,
    type: "next"
}));
export const getUsedExtractRequestParameterNames = (state) => {
    const testType = getTestType(state);
    return testType === TestType.JMX
        ? getUsedExtractParametersByRequest(getRequests(state))(getNewRequestGroupId(state))(getNewRequestId(state))
        : getNewRequestUsedExtractRequestParameterNames(state);
};
const getRequestExtractParametersByGroup = (state) => {
    const testType = getTestType(state);
    return testType === TestType.JMX
        ? getRequestParametersByGroupIdRequestIndex({
            requestId: state.newRequest.id,
            requests: state.newTest.requests,
            groupId: state.newRequest.groupId
        }).reduce((res, request) => [...res, ...getRequestExtractParametersByRequest(request)], [])
        : getNewRequestParameters(state);
};
export const getRequestsExtractParameters = (state) => [
    ...state.newTest.requests.reduce((res, request) => [...res, ...getRequestExtractParametersByRequest(request)], []),
    ...(!isExistObjectInArray(getRequests(state), "id", getNewRequestId(state))
        ? getRequestExtractParametersByRequest(state.newRequest)
        : [])
];
export const getRequestExtractParametersByRequest = (request) => request.extractResponse.reduce((res, extract) => [...res, { id: getUniqueId(), name: extract.variable }], []);
const getRequestsByGroup = (requests = [], groupId) => requests.filter((request) => request.groupId === groupId);
const getRequestParametersByGroupIdRequestIndex = ({ requests, groupId, requestId, type }) => {
    const requestsByGroup = getRequestsByGroup(requests, groupId);
    const currentRequestIndex = getObjectIndexInArray(requestsByGroup, "id", requestId);
    switch (currentRequestIndex) {
        case -1:
            return type === "next" ? [] : requestsByGroup.slice(0);
        default:
            return type === "next"
                ? requestsByGroup.slice(currentRequestIndex + 1)
                : requestsByGroup.slice(0, currentRequestIndex);
    }
};
const getTransactionsByGroup = (transactions = [], groupId) => transactions.filter((transaction) => transaction.groupId === groupId);
export const getRequestParameterNames = (requests = []) => requests.reduce((res, request) => [
    ...res,
    ...getValuesFromStringByMask(request.requestUrl, PARAMETER_REG_EXP_MASK, 1),
    ...getValuesFromStringByMask(request.body.TEXT, PARAMETER_REG_EXP_MASK, 1),
    ...getValuesFromArrayByKeyMask(request.headers, "key", /^\${([\w-_ ]+)}$/, 1),
    ...getValuesFromArrayByKeyMask(request.metadata, "key", /^\${([\w-_ ]+)}$/, 1)
], []);
export const getProfileSettingsMode = (state) => getStateParam(state, "profileSettingsMode");
export const getGrpcBuffer = (state) => getStateParam(state, "grpcBuffer");
const generateGrpcMethodItem = (fileData, method) => ({
    id: `${fileData.id}_${fileData.name}_${method}`,
    fileId: fileData.id,
    fileName: fileData.name,
    text: method,
    value: method
});
const getGrpcMethodItemsFromFile = (fileData) => getValueByPath(fileData, "protoMethods", []).map((method) => generateGrpcMethodItem(fileData, method));
export const getGrpcMethodItems = (state) => getGrpcBuffer(state).reduce((res, file) => [...res, ...getGrpcMethodItemsFromFile(file)], []);
export const getTestType = (state) => getStateParam(state, "testType");
export const getThreadGroupsTree = (state) => getStateParam(state, "threadGroupsTree");
export const getThreadGroupTreeById = (state, id) => {
    const threadGroupsTree = getThreadGroupsTree(state);
    const threadGroupIds = Object.keys(threadGroupsTree);
    return threadGroupIds && threadGroupIds.includes(id) ? threadGroupsTree[id] : {};
};
export const getThreadGroupById = (state, id) => {
    const threadGroups = getRequestGroups(state);
    const threadGroup = threadGroups.find((threadGroup) => threadGroup.id === id);
    return isEmpty(threadGroup) ? {} : threadGroup;
};
export const getThreadGroupRequestsByGroupId = (state, groupId) => {
    const requests = getRequests(state);
    const threadGroupRequests = getRequestsByGroup(requests, groupId);
    return isEmpty(threadGroupRequests) ? [] : threadGroupRequests;
};
export const getThreadGroupTransactionsByGroupId = (state, groupId) => {
    const transactions = getThreadGroupsTransactions(state);
    const threadGroupTransactions = getTransactionsByGroup(transactions, groupId);
    return isEmpty(threadGroupTransactions) ? [] : threadGroupTransactions;
};
export const getThreadGroupsElements = (state) => getStateParam(state, "elementsData");
export const getThreadGroupElementsByGroupId = (state, groupId) => {
    const elements = getThreadGroupsElements(state);
    return Object.keys(elements).reduce((res, elementId) => elements[elementId].groupId === groupId ? Object.assign(Object.assign({}, res), { [elementId]: elements[elementId] }) : res, {});
};
export const getThreadGroupChartDataByGroupId = (state, groupId) => {
    const group = getRequestGroupById(state, groupId);
    return group && group.chartData ? group.chartData : [];
};
export const getThreadGroupLoadProfileSettingsByGroupId = (state, groupId) => {
    const group = getRequestGroupById(state, groupId);
    return group && group.loadProfileSettings ? group.loadProfileSettings : {};
};
export const getTransactions = (state) => state.newTest.transactions;
const getBoomqStableScalabilityTestDurationByPercentDistribution = (state, testType) => {
    const loadProfileData = testType === TestType.Scalability
        ? getTestProjectSettingsField(state, TestType.Scalability)
        : getTestProjectSettingsField(state, TestType.Stable);
    return Math.ceil(calculateBoomqStableScalabilityTestDurationByPercentDistribution(loadProfileData));
};
const getBoomqStableScalabilityTestDurationByUsersDistribution = (state) => Math.ceil(calculateBoomqStableScalabilityTestDurationByUsersDistribution(getRequestGroups(state)));
export const getBoomqStableScalabilityCalculatedTestDuration = (state, testType) => getProfileDistribution(state) === ProfileDistribution.Percent
    ? getBoomqStableScalabilityTestDurationByPercentDistribution(state, testType)
    : getBoomqStableScalabilityTestDurationByUsersDistribution(state);
