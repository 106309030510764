import React from "react";
import { FormattedMessage } from "react-intl";
import { Action, Actions, SETTINGS_SETS_SIDEBAR_ACTIONS_ADD, StyledIconPlus } from "../SettingsSetSidebarActions";
import { isAllowedSettingsSetInSidebarActionByPermissions } from "../../../helpers/settingsSetSidebar";
import { SettingsSetInSidebarAction } from "../../../helpers/types";
export const NewTestSettingsSetSidebarActions = (props) => {
    const onSetIsShowSettingsSetAdding = (value) => typeof props.onSetIsShowSettingsSetAdding === "function"
        ? props.onSetIsShowSettingsSetAdding(value)
        : undefined;
    const clickAddNewSettingsSetHandler = () => onSetIsShowSettingsSetAdding(true);
    return isAllowedSettingsSetInSidebarActionByPermissions(props.permissionList, SettingsSetInSidebarAction.Add) ? (React.createElement(Actions, null,
        React.createElement(Action, { onClick: clickAddNewSettingsSetHandler },
            React.createElement(StyledIconPlus, null),
            React.createElement(FormattedMessage, Object.assign({}, SETTINGS_SETS_SIDEBAR_ACTIONS_ADD))))) : (React.createElement(React.Fragment, null));
};
