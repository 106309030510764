export const JMETER_CONFIG_PROPERTIES_TEXT = {
    defaultMessage: "You can see supported JMeter config properties {link}",
    id: "account.test.project.settings.set.tool.config.jmeter.title.text"
};
export const JMETER_CONFIG_PROPERTIES_LINK_TEXT = {
    defaultMessage: "here",
    id: "account.test.project.settings.set.tool.config.jmeter.title.text.link"
};
export const JMETER_CONFIG_PROPERTIES_TITLE = {
    defaultMessage: "JMeter properties",
    id: "account.test.project.settings.set.tool.config.jmeter.title"
};
export const PROPERTY_NAME_INPUT_LABEL = {
    defaultMessage: "Property name",
    id: "account.test.project.settings.set.tool.config.jmeter.property.name.label"
};
export const PROPERTY_NAME_INPUT_PLACEHOLDER = {
    defaultMessage: "enter property name",
    id: "account.test.project.settings.set.tool.config.jmeter.property.name.placeholder"
};
export const PROPERTY_VALUE_INPUT_LABEL = {
    defaultMessage: "Property value",
    id: "account.test.project.settings.set.tool.config.jmeter.property.value.label"
};
export const PROPERTY_VALUE_INPUT_PLACEHOLDER = {
    defaultMessage: "enter property value",
    id: "account.test.project.settings.set.tool.config.jmeter.property.value.placeholder"
};
