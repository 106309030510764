export const SETTINGS_SET_MONITORING_HELP_LABEL = {
    defaultMessage: "How to use?",
    id: "account.test.project.settings.set.monitoring.help.label"
};
export const SETTINGS_SET_MONITORING_SECTION_ADD_METRICS_TITLE = {
    defaultMessage: "Add metrics",
    id: "account.test.project.settings.set.monitoring.section.add.metrics.title"
};
export const SETTINGS_SET_MONITORING_SECTION_CONFIG_METRICS_TITLE = {
    defaultMessage: "Configured metrics",
    id: "account.test.project.settings.set.monitoring.section.config.metrics.title"
};
export const SETTINGS_SET_MONITORING_SECTION_CONFIG_FILES_TITLE = {
    defaultMessage: "Configuration files",
    id: "account.test.project.settings.set.monitoring.section.config.files.title"
};
export const SETTINGS_SET_MONITORING_BUTTON_GENERATE_LABEL = {
    defaultMessage: "Generate and save",
    id: "account.test.project.settings.set.monitoring.generate.save.button.label"
};
export const SETTINGS_SET_MONITORING_BUTTON_CANCEL_LABEL = {
    defaultMessage: "Cancel",
    id: "account.test.project.settings.set.monitoring.cancel.button.label"
};
export const SETTINGS_SET_MONITORING_TITLE = {
    defaultMessage: "Monitoring",
    id: "account.test.project.settings.set.wizard.monitoring.title"
};
