import React from "react";
import { LoaderBlock, RunningTestList, StyledIconLoader } from "./styled";
import { TestRunsItem } from "../TestRunsItem";
import { AllTestsBlockTitle } from "../../../styled/AllTests";
export const RunningTests = ({ isLoading, runningTests, title }) => (React.createElement(React.Fragment, null,
    isLoading && (React.createElement(LoaderBlock, null,
        React.createElement(StyledIconLoader, null))),
    !isLoading && Array.isArray(runningTests) && runningTests.length > 0 && (React.createElement(React.Fragment, null,
        React.createElement(AllTestsBlockTitle, null, title),
        React.createElement(RunningTestList, null, runningTests.map((test) => (React.createElement(TestRunsItem, { key: test.id, task: test }))))))));
