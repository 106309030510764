var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { getValueByPath } from "@boomq/utils";
import { FilterSelect } from "./styled";
import { AssertionCommonWithConditionTemplate } from "../AssertionCommonWithConditionTemplate";
import { FormType } from "../../../../helpers/types";
export const AssertionResponseBodyTemplate = (_a) => {
    var { assertionFilterItems, children, filterSelectPlaceholder, onChangeFilter } = _a, restProps = __rest(_a, ["assertionFilterItems", "children", "filterSelectPlaceholder", "onChangeFilter"]);
    return (React.createElement(AssertionCommonWithConditionTemplate, Object.assign({}, restProps),
        React.createElement(FilterSelect, { defaultValue: getValueByPath(restProps.assertion, "filter"), disabled: restProps.mode === FormType.View, dropdownAutoPositionMinItemCount: 3, isAutoPosition: true, items: assertionFilterItems, name: "Assertion_filter", onChange: onChangeFilter, placeholder: filterSelectPlaceholder })));
};
