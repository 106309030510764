import { useEffect, useRef } from "react";
const getTarget = (target) => (target && target.current ? target.current : target);
export const useEventSelectListener = (target, callbackClickFunc) => {
    const prevTarget = useRef(getTarget(target));
    let isSelecting = false;
    const callbackClickFuncHandler = () => typeof callbackClickFunc === "function" ? callbackClickFunc() : undefined;
    const setIsSelecting = (value) => {
        isSelecting = value;
    };
    const selectStartHandler = () => setIsSelecting(true);
    const mouseDownHandler = () => {
        const selection = document.getSelection();
        return selection && typeof selection.empty === "function" ? selection.empty() : undefined;
    };
    const mouseUpHandler = () => {
        const selection = document.getSelection();
        return isSelecting && selection && !selection.isCollapsed ? setIsSelecting(false) : callbackClickFuncHandler();
    };
    const removeListeners = (target) => {
        getTarget(target).removeEventListener("selectstart", selectStartHandler);
        getTarget(target).removeEventListener("mousedown", mouseDownHandler);
        getTarget(target).removeEventListener("mouseup", mouseUpHandler);
    };
    const setEventListeners = (target) => {
        getTarget(target).addEventListener("selectstart", selectStartHandler);
        getTarget(target).addEventListener("mousedown", mouseDownHandler);
        getTarget(target).addEventListener("mouseup", mouseUpHandler);
    };
    useEffect(() => {
        const removeEventListenersByTarget = (target) => getTarget(target) ? removeListeners(target) : undefined;
        const setEventListenersByTarget = (target) => (getTarget(target) ? setEventListeners(target) : undefined);
        removeEventListenersByTarget(prevTarget.current);
        prevTarget.current = getTarget(target);
        setEventListenersByTarget(target);
    }, [target]);
    useEffect(() => {
        return () => removeListeners(target);
    }, []);
    const isExistSelectedData = () => {
        const selection = document.getSelection();
        return selection ? !selection.isCollapsed : false;
    };
    return {
        isExistSelectedData
    };
};
