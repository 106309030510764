import styled from "styled-components";
import ReactJson from "react-json-view";
import { Color, FontBodyMediumMedium } from "@boomq/uikit";
export const StyledReactJson = styled(ReactJson) `
    ${FontBodyMediumMedium}
    .brace-row > span {
        font-weight: 500!important;
    }

    .icon-container + span + span {
        font-weight: 500!important;
    }

    .object-key {
        color: ${Color.green}!important;
    }

    .variable-value > div {
        color: ${Color.orange}!important;
    }    
`;
