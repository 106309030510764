import { handleActions } from "redux-actions";
import { hideLoader, showLoader } from "../actions/loader";
import newTestActions from "../actions/newTest";
const { editRequestGroup } = newTestActions;
export const initialState = {
    requestCount: 0
};
export default handleActions({
    [editRequestGroup]: (state) => (Object.assign(Object.assign({}, state), { requestCount: state.requestCount + 1 })),
    [hideLoader]: (state, { payload }) => {
        const decreaseSize = payload > 0 ? payload : 1;
        return Object.assign(Object.assign({}, state), { requestCount: state.requestCount - decreaseSize < 0 ? 0 : state.requestCount - decreaseSize });
    },
    [showLoader]: (state, { payload }) => {
        const increaseSize = payload > 0 ? payload : 1;
        return Object.assign(Object.assign({}, state), { requestCount: state.requestCount + increaseSize });
    }
}, initialState);
export const getIsShowLoader = (state) => {
    const requestCount = state && state.loader ? state.loader.requestCount : initialState.requestCount;
    return requestCount === 0 ? false : true;
};
