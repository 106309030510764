import { select, put, all, takeEvery } from "redux-saga/effects";
import { getValueByPath } from "@boomq/utils";
import { generatePreviewChartDataForSteps, getEnabledJMXProfiles } from "../../helpers/generators";
import { getStartTimeLine, getEndTimeLine } from "../../helpers/parsers";
import newTestActions from "../actions/newTest";
import { getIsMergedJMXChartGroups, getIsMergedJMXChartSteps, getLoadProfileByGroupId, getLoadProfiles, getPreviewJMXChartTimeline, getRequestGroups } from "../reducers/newTest";
const { changeJmxLoadProfile, changeJmxLoadProfileChartData, changeJmxPreviewChartField, refreshOwnJmxChartPreview, testProjectChangeParamsField } = newTestActions;
const getTimeLineFirstValue = (value, minValue, maxValue) => {
    const newValue = value >= maxValue ? minValue : value;
    return newValue < minValue ? minValue : newValue;
};
const getTimeLineSecondValue = (value, minValue, maxValue) => {
    const newValue = value <= minValue ? maxValue : value;
    return newValue > maxValue ? maxValue : newValue;
};
export function getLoadProfileTimeLine(loadProfile) {
    const steps = getValueByPath(loadProfile, "steps", []);
    const timeLineMaxValue = getEndTimeLine(steps);
    const timeLineMinValue = getStartTimeLine(steps);
    return loadProfile.timeLine
        ? {
            min: timeLineMinValue,
            max: timeLineMaxValue,
            value: [
                getTimeLineFirstValue(getValueByPath(loadProfile, "timeLine.value.0"), timeLineMinValue, timeLineMaxValue),
                getTimeLineSecondValue(getValueByPath(loadProfile, "timeLine.value.1"), timeLineMinValue, timeLineMaxValue)
            ]
        }
        : {};
}
export const generateChartDataWithTimeLine = (newLoadProfile) => {
    const { chartData, isMergedChart, steps, timeLine } = newLoadProfile || {};
    const params = Array.isArray(chartData) && chartData.length > 0 ? chartData[0].params : {};
    const startTimeLine = timeLine && Array.isArray(timeLine.value) ? timeLine.value[0] : 0;
    const endTimeLine = timeLine && Array.isArray(timeLine.value) ? timeLine.value[1] : 0;
    const chartSteps = steps.filter((step) => (step.initialDelay || 0) + step.rampUp + step.stepLength + step.rampDown > startTimeLine &&
        (!step.initialDelay || step.initialDelay < endTimeLine));
    return isMergedChart
        ? [{ coords: generatePreviewChartDataForSteps(chartSteps, timeLine.value, true), params }]
        : chartSteps.map((step) => ({ coords: generatePreviewChartDataForSteps([step], timeLine.value), params }));
};
function* changeJmxLoadProfileChartDataFlow(action) {
    const { field, groupId, subfield, value } = action && action.payload ? action.payload : {};
    const loadProfile = yield select(getLoadProfileByGroupId, groupId);
    const newLoadProfile = groupId
        ? Object.assign(Object.assign({}, loadProfile), { [field]: subfield ? Object.assign(Object.assign({}, loadProfile[field]), { [subfield]: value }) : value }) : loadProfile;
    newLoadProfile.chartData &&
        (newLoadProfile.chartData = generateChartDataWithTimeLine(Object.assign(Object.assign({}, newLoadProfile), { steps: loadProfile.steps })));
    yield put(changeJmxLoadProfile({ groupId, loadProfile: newLoadProfile }));
}
function* getEnabledJMXProfilesData() {
    try {
        const [JMXProfiles, groups] = yield all([select(getLoadProfiles), select(getRequestGroups)]);
        return getEnabledJMXProfiles(JMXProfiles, groups);
    }
    catch (e) {
        console.error(e);
    }
}
export function* generateJMXPreviewChartTimeLine() {
    try {
        const JMXProfiles = yield* getEnabledJMXProfilesData();
        const timeLineValues = Object.keys(JMXProfiles).reduce((res, JMXProfileId) => [
            ...res,
            getStartTimeLine(JMXProfiles[JMXProfileId].steps),
            getEndTimeLine(JMXProfiles[JMXProfileId].steps)
        ], []);
        const startTimeLine = timeLineValues.length > 0 ? Math.min(...timeLineValues) : 0;
        const endTimeLine = timeLineValues.length > 0 ? Math.max(...timeLineValues) : 0;
        yield put(changeJmxPreviewChartField({
            field: "timeLine",
            value: { min: startTimeLine, max: endTimeLine, value: [startTimeLine, endTimeLine] }
        }));
    }
    catch (e) {
        console.error(e);
    }
}
function* JMXPreviewChartFlow(action) {
    const { field } = action && action.payload ? action.payload : {};
    const isMergedJMXChartGroups = yield select(getIsMergedJMXChartGroups);
    const isExistMergedGroups = field === "isMergedJMXChartSteps" && isMergedJMXChartGroups ? true : false;
    return isExistMergedGroups ? undefined : yield* generateJMXPreviewChart(action.payload);
}
const generateChartDataByMergedGroups = (JMXProfiles, timelineValue) => {
    const mergedSteps = Object.keys(JMXProfiles).reduce((res, JMXProfileId) => [
        ...res,
        ...JMXProfiles[JMXProfileId].steps.map((stepData) => (Object.assign(Object.assign({}, stepData), { groupId: JMXProfileId })))
    ], []);
    return [
        {
            coords: generatePreviewChartDataForSteps(mergedSteps, timelineValue, true),
            params: {}
        }
    ];
};
const generateChartDataByMergedSteps = (JMXProfiles, timelineValue) => Object.keys(JMXProfiles).reduce((res, JMXProfileId) => [
    ...res,
    {
        coords: generatePreviewChartDataForSteps(JMXProfiles[JMXProfileId].steps, timelineValue, true),
        params: JMXProfiles[JMXProfileId].chartData[0].params
    }
], []);
const generateChartDataByMergeParams = ({ JMXProfiles, isMergedChartGroups, timeline }) => isMergedChartGroups
    ? generateChartDataByMergedGroups(JMXProfiles, timeline.value)
    : generateChartDataByMergedSteps(JMXProfiles, timeline.value);
const generateChartDataJMXProfiles = (JMXProfiles, timeline) => Object.keys(JMXProfiles).reduce((res, JMXProfileId) => [
    ...res,
    ...JMXProfiles[JMXProfileId].steps.map((step) => ({
        coords: generatePreviewChartDataForSteps([step], timeline.value),
        params: JMXProfiles[JMXProfileId].chartData[0].params
    }))
], []);
function* generateJMXPreviewChart(params) {
    try {
        const { field, value } = params || {};
        const isMergedChartGroups = field === "isMergedJMXChartGroups" ? value : yield select(getIsMergedJMXChartGroups);
        const isMergedChartSteps = field === "isMergedChartSteps" ? value : yield select(getIsMergedJMXChartSteps);
        const timeline = field === "timeLine" ? value : yield select(getPreviewJMXChartTimeline);
        const JMXProfiles = yield* getEnabledJMXProfilesData();
        const chartData = isMergedChartSteps || isMergedChartGroups
            ? generateChartDataByMergeParams({
                JMXProfiles,
                isMergedChartGroups,
                timeline
            })
            : generateChartDataJMXProfiles(JMXProfiles, timeline);
        yield put(testProjectChangeParamsField({ field: "chartData", value: chartData }));
    }
    catch (e) {
        console.error(e);
    }
}
export function* testProjectJmxChartFlow() {
    yield takeEvery(changeJmxLoadProfileChartData, changeJmxLoadProfileChartDataFlow);
    yield takeEvery(changeJmxPreviewChartField, JMXPreviewChartFlow);
    yield takeEvery(refreshOwnJmxChartPreview, generateJMXPreviewChartTimeLine);
}
