export const apiErrors = (errorCode) => {
    switch (errorCode) {
        case "BAD_ACTIVATION_CODE":
            return { id: "api.error.code.BAD_ACTIVATION_CODE", defaultMessage: "Activation code is not valid" };
        case "BAD_CREDENTIAL":
            return { id: "api.error.code.BAD_CREDENTIAL", defaultMessage: "Incorrect email or password" };
        case "BAD_PASSWORD_RESET_CODE":
            return { id: "api.error.code.BAD_PASSWORD_RESET_CODE", defaultMessage: "Reset code is not valid" };
        case "BAD_REQUEST":
            return { id: "api.error.code.BAD_REQUEST", defaultMessage: "The request to the server was not correct" };
        case "DAILY_LIMIT_EXCEEDED":
            return {
                defaultMessage: "You exceed test starting daily limit for your tariff",
                id: "api.error.code.DAILY_LIMIT_EXCEEDED"
            };
        case "EMPTY_ANALYTICS":
            return { id: "api.error.code.EMPTY_ANALYTICS", defaultMessage: "Analytics is empty" };
        case "FAILED_IMPORT_FILE":
            return {
                defaultMessage: "The file is invalid",
                id: "api.error.code.FAILED_IMPORT_FILE"
            };
        case "HAR_INVALID_VERSION":
            return {
                id: "api.error.exceptionMessage.HAR_INVALID_VERSION",
                defaultMessage: "har file's version is not supported"
            };
        case "GET_ANALYTICS_FAILED":
            return {
                defaultMessage: "There were problems with collecting analytics",
                id: "api.error.code.GET_ANALYTICS_FAILED"
            };
        case "INVALID_CONTENT":
            return {
                id: "api.error.exceptionMessage.INVALID_CONTENT",
                defaultMessage: "Har file's content is invalid"
            };
        case "INVALID_PARAMETER":
            return {
                defaultMessage: "Not the right parameters were transferred",
                id: "api.error.code.INVALID_PARAMETER"
            };
        case "INTERNAL_SERVER_ERROR":
            return {
                id: "api.error.code.INTERNAL_SERVER_ERROR",
                defaultMessage: "Oops... something went wrong. We're on it and will fix it shortly."
            };
        case "INVALID_OR_EXPIRED_CODE":
            return { id: "api.error.code.INVALID_OR_EXPIRED_CODE", defaultMessage: "Session interrupted" };
        case "INVALID_TARIFF":
            return { id: "api.error.code.INVALID_TARIFF", defaultMessage: "You do not have a suitable rate" };
        case "JMX_FILE_EMPTY":
            return { id: "api.error.code.JMX_FILE_EMPTY", defaultMessage: "The file with the load was empty" };
        case "JMX_FILE_INVALID":
            return {
                defaultMessage: "The file with the load turned out to be correct",
                id: "api.error.code.JMX_FILE_INVALID"
            };
        case "MAPPING_ERROR":
            return {
                id: "api.error.exceptionMessage.MAPPING_ERROR",
                defaultMessage: "Har file's content is incorrect"
            };
        case "MULTIPLY_TEST_FOR_ONE_SITE":
            return {
                defaultMessage: "You can not run multiple tests for one site",
                id: "api.error.code.MULTIPLY_TEST_FOR_ONE_SITE"
            };
        case "NOT_FOUND":
            return { id: "api.error.code.NOT_FOUND", defaultMessage: "The requested data was not found" };
        case "RESET_PASSWORD_TOKEN_NOT_FOUND":
            return {
                id: "api.error.code.RESET_PASSWORD_TOKEN_NOT_FOUND",
                defaultMessage: "Unfortunately, change password time is expired. Please login or request a password change again."
            };
        case "RESOURCE_NOT_FOUND":
            return { id: "api.error.code.RESOURCE_NOT_FOUND", defaultMessage: "No free resources found" };
        case "TARIFF_NOT_FOUND":
            return { id: "api.error.code.TARIFF_NOT_FOUND", defaultMessage: "Tariff not found" };
        case "TEST_DATA_COLLECTION_FAILS":
            return { id: "api.error.code.TEST_DATA_COLLECTION_FAILS", defaultMessage: "Failed to get load data" };
        case "TEST_NOT_FOUND":
            return { id: "api.error.code.TEST_NOT_FOUND", defaultMessage: "The test was not found" };
        case "TEST_START_ERROR":
            return { id: "api.error.code.TEST_START_ERROR", defaultMessage: "Test failed to start" };
        case "TEST_STOP_ERROR":
            return { id: "api.error.code.TEST_STOP_ERROR", defaultMessage: "The test could not be stopped" };
        case "USER_ALREADY_EXIST":
            return {
                id: "api.error.code.USER_ALREADY_EXIST",
                defaultMessage: "You already have an account. Please sign-in."
            };
        case "USER_NOT_FOUND":
            return { id: "api.error.code.USER_NOT_FOUND", defaultMessage: "User is not found" };
        default:
            return { id: "api.error.code.unknown", defaultMessage: "Something went wrong" };
    }
};
