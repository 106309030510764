import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { isEmptyArray } from "@boomq/utils";
import {
    CommonProfileSettingsContainer,
    ProfileSettingsEmptyContent,
    getRequestsWithTimerListByGroupId,
    getRequestGroups,
    getRequests,
    getRequestTimers,
    JMXTestProfileSection,
    media,
    requestJMXActions
} from "@boomq/common";
import { SimpleThreadGroup } from "../../../../containers/account/TestProject/SimpleThreadGroup";
import { UltimateThreadGroup } from "../../../../containers/account/TestProject/UltimateThreadGroup";
import { testSettings } from "../../../../data/content/testSettings";
import { RequestGroupType } from "../../../../helpers/types";

const connector = connect((state) => ({
    requestGroups: getRequestGroups(state),
    requests: getRequests(state),
    timers: getRequestTimers(state)
}));

const getRequestGroupByType = (props, group) =>
    group.type === RequestGroupType.Ultimate2 ? (
        <UltimateThreadGroup
            key={group.id}
            requestActions={requestJMXActions}
            requestGroup={{
                ...group,
                requests: getRequestsWithTimerListByGroupId(props.requests, props.timers, group.id)
            }}
            testSettings={testSettings}
        />
    ) : (
        <SimpleThreadGroup
            key={group.id}
            requestActions={requestJMXActions}
            requestGroup={{
                ...group,
                requests: getRequestsWithTimerListByGroupId(props.requests, props.timers, group.id)
            }}
        />
    );

const ProfileSettingsJMX = ({ requestGroups, ...rest }) => (
    <CommonProfileSettingsContainer requestGroups={requestGroups} testSettings={testSettings} {...rest}>
        <JMXTestProfileSection>
            {!isEmptyArray(requestGroups) ? (
                <StyledProfile>{requestGroups.map((group) => getRequestGroupByType(rest, group))}</StyledProfile>
            ) : (
                <ProfileSettingsEmptyContent testSettings={testSettings} {...rest} />
            )}
        </JMXTestProfileSection>
    </CommonProfileSettingsContainer>
);

const StyledProfile = styled.ul`
    height: 100%;
    width: 100%;
    ${media.tablet} {
        padding: 0 12px 12px;
    }
`;

export default withRouter(connector(ProfileSettingsJMX));
