import { initialState } from "./reports";
const getStateParam = (state, paramName) => state && state.reports ? state.reports[paramName] : initialState[paramName];
export const getCurrentPage = (state) => getStateParam(state, "currentPage");
export const getCurrentPageElements = (state) => getStateParam(state, "numberOfElements");
export const getPrevPage = (state) => {
    const currentPage = getCurrentPage(state);
    return currentPage > 0 ? currentPage - 1 : currentPage;
};
export const getReports = (state) => getStateParam(state, "content");
export const getReportById = (state, id) => getReports(state).find((report) => report.id === id);
export const getReportsCount = (state) => getStateParam(state, "totalElements");
export const getReportsFilterLabels = (state) => getStateParam(state, "filterLabels");
export const getReportsLabelList = (state) => getStateParam(state, "labelList");
export const getReportsPageSize = (state) => getStateParam(state, "size");
export const getReportsPagesCount = (state) => getStateParam(state, "totalPages");
export const getReportsSortData = (state) => getStateParam(state, "sortData");
