import styled from "styled-components";
import { Button, Color, DefaultArrowIcon, DefaultComboBoxBody, PseudoInput, Select, StyledCheckbox, Switcher, withDisable } from "@boomq/uikit";
import { IconBell, IconCalendar, IconCopy, IconShield } from "@boomq/uikit2";
import { media } from "../../../styled/response";
export const dateFormatSelectListTheme = { borderRadius: "8px" };
export const dateFormatSelectListItemTheme = Object.assign(Object.assign({}, dateFormatSelectListTheme), { color: Color.grey65, fontSize: "12px" });
export const ProfileBlockStyles = `
    border-radius: 8px;
    box-shadow: 0 0 8px rgba(125, 129, 156, 0.15);
    display: flex;
    flex: auto;
    flex-direction: column;
    justify-content: space-between;
`;
const MobileBlockStyles = `
    @media (max-width: 576px) {
        align-items: flex-start;
        flex-direction: column;
        gap: 4px;
        width: 100%;
    }
`;
const ButtonStyles = `
    border-radius: 8px;
    font-size: 12px;
    height: 32px;
    line-height: normal;
    min-width: 90px;
    padding: 6px;
    width: 90px;
`;
const ButtonStylesWithMedia = `
    ${ButtonStyles}
    ${media.desktop} {
        ${ButtonStyles}
    }
    ${media.tablet} {
        ${ButtonStyles}
    }
`;
export const ProfileUserPrimaryButton = styled(Button) `
    ${ButtonStylesWithMedia}
    background: ${({ theme }) => { var _a; return (_a = theme === null || theme === void 0 ? void 0 : theme.palette) === null || _a === void 0 ? void 0 : _a.primary11; }};
    color: #fff;
    &:hover {
        background: ${({ theme }) => { var _a; return (_a = theme === null || theme === void 0 ? void 0 : theme.palette) === null || _a === void 0 ? void 0 : _a.primary11; }};
    }
`;
export const DateFormatSelect = styled(Select) `
    flex: auto;
    margin-right: 96px;
    max-height: 32px;
    min-height: 32px;
    min-width: 290px;
    ${DefaultArrowIcon} {
        fill: #7f8198;
        top: 4px;
    }
    ${DefaultComboBoxBody} {
        border-radius: 8px;
        color: #7f8198;
        font-size: 12px;
        line-height: 16px;
        max-height: 32px;
        min-height: 32px;
    }
    ${media.tablet} {
        min-width: auto;
    }
    @media (max-width: 576px) {
        width: calc(100% - 96px);
    }
`;
export const IconApiToken = styled(IconShield) `
    fill: #c8cad9;
    margin-right: 12px;
    min-width: 16px;
`;
export const IconDateFormat = styled(IconCalendar) `
    fill: #c8cad9;
    margin-right: 12px;
    min-width: 16px;
`;
export const IconNotification = styled(IconBell) `
    fill: #c8cad9;
    margin-right: 12px;
    min-width: 16px;
`;
export const InfoBlock = styled.div `
    ${ProfileBlockStyles}
    background: #fff;
    color: #272837;
    max-width: 33%;
    padding: 24px;
    width: auto;
`;
export const InputWithCopy = styled.div `
    align-items: center;
    border: 1px solid ${Color.grey75};
    border-radius: 8px;
    display: flex;
    flex: auto;
    height: 32px;
    margin-right: 6px;
    max-width: 100%;
    min-height: 32px;
    min-width: 290px;
    padding-bottom: 8px;
    padding-left: 12px;
    padding-right: 32px;
    padding-top: 8px;
    position: relative;
    width: 100%;
    ${media.tablet} {
        max-width: calc(100% - 96px);
        min-width: auto;
    }
`;
export const NotificationSwitcher = styled(Switcher) `
    ${StyledCheckbox} {
        height: 16px;
        width: 30px;
    }
    ${StyledCheckbox}:before {
        height: 12px;
        top: 50%;
        transform: translateY(-50%);
        width: 12px;
    }
    ${PseudoInput}:checked + ${StyledCheckbox}:before {
        left: 16px;
    }
`;
export const ProfileButtonWrapper = styled.div `
    align-content: center;
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
`;
export const ProfileLabel = styled.div `
    align-items: center;
    display: flex;
`;
export const ProfileRow = styled.div `
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
    position: relative;
    width: 100%;
    &:first-child {
        padding-top: 0;
    }
    ${MobileBlockStyles}
`;
export const ProfileUserAccountInfoRow = styled.label `
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
    position: relative;
    width: 100%;
    &:first-child {
        padding-top: 0;
    }
`;
export const ProfileDateFormatRow = styled(ProfileUserAccountInfoRow) `
    ${MobileBlockStyles}
`;
export const SecretTokenButton = styled(Button) `
    ${ButtonStylesWithMedia}
    border: 1px solid ${({ theme }) => { var _a; return (_a = theme === null || theme === void 0 ? void 0 : theme.palette) === null || _a === void 0 ? void 0 : _a.primary11; }};
    color: ${({ theme }) => { var _a; return (_a = theme === null || theme === void 0 ? void 0 : theme.palette) === null || _a === void 0 ? void 0 : _a.primary11; }};
    &:hover {
        border-color: ${({ theme }) => { var _a; return (_a = theme === null || theme === void 0 ? void 0 : theme.palette) === null || _a === void 0 ? void 0 : _a.primary111; }};
        color: ${({ theme }) => { var _a; return (_a = theme === null || theme === void 0 ? void 0 : theme.palette) === null || _a === void 0 ? void 0 : _a.primary111; }};
    }
`;
export const SecretTokenIconCopy = styled(IconCopy) `
    fill: ${({ disabled, theme }) => { var _a; return (disabled ? "#c8cad9" : (_a = theme === null || theme === void 0 ? void 0 : theme.palette) === null || _a === void 0 ? void 0 : _a.primary11); }};
`;
export const SecretTokenValue = styled.p `
    color: #7f8198;
    font-family: "Montserrat";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    min-width: 100%;
    outline: none;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all 0.3s ease-in-out;
    white-space: nowrap;
    width: 100%;
    &:focus {
        border-color: #176ed6;
    }
    ${media.phone} {
        min-width: 100%;
    }
`;
export const SecretTokenSection = styled.div `
    display: flex;
    flex: auto;
    max-width: calc(100% - 110px);
    width: 100%;
    @media (max-width: 576px) {
        max-width: 100%;
    }
`;
export const StyledCopyButton = styled.div `
    ${withDisable}
    cursor: pointer;
    display: flex;
    padding: 6px;
    position: absolute;
    right: 8px;
`;
export const ProfileText = styled.p `
    color: #272837;
    font-family: "Roboto", "Montserrat";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;
export const ProfileBlockTitle = styled(ProfileText) `
    font-size: 16px;
`;
export const DateFormatText = styled(ProfileText) `
    margin-right: 4px;
    min-width: 78px;
`;
export const NotificationText = styled(ProfileText) `
    flex: auto;
    margin-right: 16px;
`;
export const SecretTokenText = styled(ProfileText) `
    margin-right: 4px;
    min-width: 78px;
`;
export const UserAccountInfoBlock = styled(InfoBlock) `
    min-width: 546px;
`;
