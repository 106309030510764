import styled from "styled-components";
import { Block, Button, Color, FontBodyMediumMedium } from "@boomq/uikit";
import { withDisable } from "./global";
import { media } from "./response";
import { CollapsibleBlock } from "../common/CollapsibleBlock";
export const rangeSliderRailStyleTheme = { backgroundColor: "#ddd" };
export const ChartSection = styled.div `
    flex: 1;
    position: relative;
`;
export const CollapsibleBlockWithMargin = styled(CollapsibleBlock) `
    margin-top: 16px;
`;
export const GroupName = styled.h2 `
    align-items: center;
    background: #4989d6;
    border-radius: 9px;
    color: #fff;
    display: flex;
    justify-content: start;
    min-height: 66px;
    padding: 15px 60px 15px 20px;
    position: relative;
`;
export const SettingsWrapper = styled.div `
    ${withDisable};
    display: flex;
    padding: 40px 20px 20px 20px;
    ${media.giant} {
        flex-direction: column-reverse;
    }
    ${media.tablet} {
        padding-top: 30px;
    }
`;
export const SidebarButton = styled(Button) `
    ${Block}
    ${FontBodyMediumMedium}
    color: ${Color.darkGrey5};
    padding: 12px;
    &:hover {
        background: ${Color.white};
    }
`;
export const SwitchChartSection = styled.div `
    align-items: center;
    color: #396496;
    display: flex;
    font-size: 14px;
    font-weight: 600;
    left: 15%;
    line-height: normal;
    position: absolute;
    top: 10px;
    z-index: 99;
    > label {
        margin-right: 10px;
    }
    ${media.tablet} {
        left: 20%;
    }
    ${media.phone} {
        left: 25%;
    }
`;
export const SwitchChartMergeGroupsSection = styled(SwitchChartSection) `
    color: ${Color.blue};
    font-size: 14px;
    font-weight: 600;
    left: 80px;
    line-height: normal;
    top: 5px;
`;
export const Text = styled.p `
    color: ${Color.blue};
    font-size: 16px;
    font-weight: 600;
    margin: 0 5px;
    line-height: normal;
`;
