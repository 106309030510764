import React, { useEffect, useRef } from "react";
import { useResize } from "@boomq/utils";
import { SlaReportTableFixedCells } from "../../../../containers/account/SlaReportProfile/SlaReportTableFixedCells";
import { SlaReportTableScrollableCells } from "../../../../containers/account/SlaReportProfile/SlaReportTableScrollableCells";
import { calculateTableRowsHeight } from "../../../../helpers/slaReport";
export const SlaReportTable = ({ baselineTestId, currentLanguage, excludedTestList, isShowAbsoluteValue, onAddSla, onDeleteSla, onDeleteTest, slaTypeList, tableData, tableTestList, testDuration }) => {
    const { rowList } = tableData || {};
    const { width } = useResize();
    const fixedTable = useRef();
    const scrollableTable = useRef();
    useEffect(() => {
        calculateTableRowsHeight(fixedTable, scrollableTable, rowList);
    }, [currentLanguage, rowList, width]);
    const excludedTestIds = (excludedTestList || []).map((excludedTest) => excludedTest.value);
    const filteredTableTestList = (tableTestList || []).filter((test) => !(excludedTestIds || []).includes(test.id));
    const filteredTableData = Object.assign(Object.assign({}, tableData), { rowList: (tableData.rowList || []).map((row) => (Object.assign(Object.assign({}, row), { cellList: (row.cellList || []).filter((cell) => !(excludedTestIds || []).includes(cell.testId)) }))) });
    return (React.createElement(React.Fragment, null,
        React.createElement(SlaReportTableFixedCells, { ref: fixedTable, baselineTestId: baselineTestId, onAddSla: onAddSla, onDeleteSla: onDeleteSla, onDeleteTest: onDeleteTest, slaTypeList: slaTypeList, tableData: filteredTableData, tableTestList: filteredTableTestList, testDuration: testDuration }),
        React.createElement(SlaReportTableScrollableCells, { ref: scrollableTable, baselineTestId: baselineTestId, isShowAbsoluteValue: isShowAbsoluteValue, onDeleteTest: onDeleteTest, tableData: filteredTableData, tableTestList: filteredTableTestList, testDuration: testDuration })));
};
