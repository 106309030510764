import React from "react";
import { connect } from "react-redux";
import { ACCEPT_MODAL_TEXT_MESSAGE, ACCEPT_MODAL_TITLE_MESSAGE, AUTH_MODAL_TITLE_MESSAGE } from "./intl";
import { PrivateComponent } from "./styled";
import { ConfirmForm } from "../ConfirmForm";
import { CONFIRMID } from "../../../helpers/applicationParams";
import { getSiteModal } from "../../../redux/actions/siteModal";
import { clearLoginError } from "../../../redux/actions/login";
import { getIsAuthorize } from "../../../redux/reducers/authorizePlate";
const connector = connect((state) => ({
    isAuthorized: getIsAuthorize(state)
}), { clearLoginError, getSiteModal });
const PrivateLink = (props) => {
    const checkAcceptRequirement = () => (props.requireAccept ? showAcceptFormHandler() : showAuthModal());
    const runPrivateAction = () => !props.disabled && typeof props.privateAction === "function" ? props.privateAction() : undefined;
    const clickHandler = () => (props.isAuthorized ? runPrivateAction(props) : checkAcceptRequirement(props));
    const confirmHandler = (e) => {
        const confirmButton = e.target.closest("button[id]");
        confirmButton && confirmButton.id === CONFIRMID && showAuthModal();
    };
    const showAcceptFormHandler = () => props.getSiteModal({
        component: {
            element: ConfirmForm,
            props: {
                text: {
                    description: [ACCEPT_MODAL_TEXT_MESSAGE]
                }
            }
        },
        closeCallBack: confirmHandler,
        title: ACCEPT_MODAL_TITLE_MESSAGE
    });
    const showAuthModal = () => props.getSiteModal({
        component: {
            element: props.authElement,
            props: {
                activeAction: props.privateActionName ? { type: props.privateActionName } : null,
                activeUrl: props.activeUrl,
                isSaveSettings: props.isSaveSettings,
                title: AUTH_MODAL_TITLE_MESSAGE,
                type: "modal"
            },
            height: "90%",
            theme: { maxHeight: "610px" },
            width: "550px"
        },
        closeCallBack: props.clearLoginError,
        title: null
    });
    return (React.createElement(PrivateComponent, { disabled: props.disabled, onClick: clickHandler }, props.children));
};
export default connector(PrivateLink);
