import React from "react";
import { Tooltip } from "@boomq/uikit";
import { InfoLink, LegendItem, LegendList, StyledIconInfo } from "./styled";
import { LegendItemList } from "./LegendItemList";
export const CustomLegend = ({ anomaliesData, anomaliesLabel, docsLink, infoTooltip, metricsData, metricsLabel }) => (React.createElement(React.Fragment, null,
    React.createElement(LegendList, null,
        React.createElement(LegendItem, null,
            metricsLabel,
            ":"),
        metricsData && React.createElement(LegendItemList, { data: metricsData })),
    React.createElement(LegendList, null,
        React.createElement(LegendItem, null,
            anomaliesLabel,
            ":"),
        anomaliesData && React.createElement(LegendItemList, { data: anomaliesData }),
        React.createElement(Tooltip, { title: infoTooltip },
            React.createElement(InfoLink, { href: docsLink, rel: "noopener noreferrer", target: "_blank" },
                React.createElement(StyledIconInfo, null))))));
