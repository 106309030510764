import styled from "styled-components";
import { Color, FontBodySmallMedium } from "@boomq/uikit";
export const HandleValue = styled.div `
    color: #4989d6;
    font-size: 12px;
    font-weight: 600;
    line-height: normal;
    max-width: max-content;
    min-width: max-content;
    position: absolute;
    top: -30px;
    width: max-content;
`;
export const SlaItemTimelineSection = styled.div `
    align-items: center;
    display: flex;
    .rc-slider {
        flex: 2;
        margin: 0 16px;
        padding: 4px 0;
    }
    .rc-slider-rail {
        background-color: ${Color.lightGrey95};
        border-radius: 8px;
        height: 6px;
        top: 50%;
        transform: translateY(-50%);
    }
    .rc-slider-track {
        background: ${Color.blue};
        border-radius: 8px;
        height: 6px;
    }
    .rc-slider-handle {
        background-color: ${Color.blue};
        border: none;
        height: 16px;
        margin-left: 0px;
        width: 16px;
    }
    .rc-slider-handle-1 > div {
        transform: translateX(-90%);
    }
    .rc-slider-handle-2 > div {
        transform: translateX(20%);
    }
    .rc-slider-dot {
        display: none;
    }
    .rc-slider-mark {
        span {
            &:first-child {
                left: 0 !important;
            }
            &:last-child {
                right: 0;
                left: auto !important;
            }
        }
    }
    .rc-slider-mark-text {
        ${FontBodySmallMedium}
        color: ${Color.grey65};
        line-height: normal;
        margin: 0 !important;
        transform: none !important;
        width: min-content !important;
    }
`;
