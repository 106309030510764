import React, { useEffect, useState } from "react";
import { isEmpty, uploadFileLocally } from "@boomq/utils";
import { Uploader } from "@boomq/uikit";
import { IMAGE_FILE_EXTENSIONS } from "./constants";
import { Action, BlockTitle, imageUploaderTheme, StyledIconCancel, TeamImageTitle } from "./styled";
import { EmptyImage } from "./EmptyImage";
import { TeamImagePreview } from "./TeamImagePreview";
import { TeamImageMode } from "../../../../helpers/types";
const getStateImage = (image) => (image ? image : "");
export const TeamImageBlock = (props) => {
    const [image, setImage] = useState(getStateImage(props.image));
    const [mode, setMode] = useState(TeamImageMode.View);
    useEffect(() => {
        setImage(getStateImage(props.image));
    }, [props.image]);
    const changeImage = (imageFile) => {
        var _a;
        uploadFileLocally(imageFile, (result) => setImage(result));
        setMode(TeamImageMode.View);
        return (_a = props.onUploadImage) === null || _a === void 0 ? void 0 : _a.call(props, imageFile);
    };
    const clickHandler = () => setMode(TeamImageMode.View);
    const onChange = (files) => (files && files[0] ? changeImage(files[0]) : undefined);
    const onDelete = () => {
        var _a;
        setMode(TeamImageMode.Delete);
        return (_a = props.onDeleteImage) === null || _a === void 0 ? void 0 : _a.call(props);
    };
    const onEdit = () => setMode(TeamImageMode.Edit);
    return (React.createElement(React.Fragment, null,
        React.createElement(BlockTitle, null,
            React.createElement(TeamImageTitle, null, props.teamImageTitle),
            mode === TeamImageMode.Edit && (React.createElement(Action, { onClick: clickHandler },
                React.createElement(StyledIconCancel, null),
                props.teamImageButtonCancelLabel))),
        props.isExistAdminAccess && (mode !== TeamImageMode.View || isEmpty(image)) ? (React.createElement(Uploader, { emptyText: React.createElement(EmptyImage, { teamImageEmptyText: props.teamImageEmptyText }), extensions: IMAGE_FILE_EXTENSIONS, items: [], maxFileSize: "1", onUploadFiles: onChange, isSingleFile: true, theme: imageUploaderTheme })) : (React.createElement(TeamImagePreview, { image: image, onDelete: onDelete, onEdit: onEdit }))));
};
