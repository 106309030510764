export const JMETER_UNSUPPORTED_PROPERTIES_LINK_TEXT = {
    defaultMessage: "supported list",
    id: "account.test.project.settings.set.tool.config.unsupported.properties.exception.link.text"
};
export const JMETER_UNSUPPORTED_PROPERTIES_TEXT = {
    defaultMessage: "You have used unsupported properties. Please correspond to {link} and adjust the following properties: ",
    id: "account.test.project.settings.set.tool.config.unsupported.properties.exception.text"
};
export const JMETER_UNSUPPORTED_PROPERTIES_TITLE = {
    defaultMessage: "Attention",
    id: "account.test.project.settings.set.tool.config.unsupported.properties.exception.title"
};
