import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Tooltip } from "@boomq/uikit";
import { ADMIN_BUTTON_LABEL, ICON_HELP_TOOLTIP } from "./intl";
import { HelpLink, LogoImage, LogoLink, StyledHeader, StyledIconHelp, StyledNavBar, UserButtonsBlock } from "./styled";
import { Logout } from "../Logout";
import { StyledAdminButton } from "../../styled/Buttons";
import { getClientRole, isExistSelectedTeam } from "../../../redux/reducers/authorizePlate";
import { getAdminRoute } from "../../../helpers/routes";
import { Role } from "../../../helpers/types/auth";
import { menuOptions } from "../../../models/accountNavBar";
const connector = connect((state) => ({
    isDisabledMenu: !isExistSelectedTeam(state),
    roles: getClientRole(state)
}));
const Header = ({ children, docsLink, isDisabledMenu, logo, roles = [] }) => (React.createElement(StyledHeader, null,
    React.createElement(LogoLink, { to: "/" },
        React.createElement(LogoImage, { src: logo, alt: "logo" })),
    React.createElement(StyledNavBar, { disabled: isDisabledMenu, menuOptions: menuOptions }),
    React.createElement(UserButtonsBlock, null,
        Array.isArray(roles) && roles.includes(Role.Admin) && (React.createElement(StyledAdminButton, { to: getAdminRoute() },
            React.createElement(FormattedMessage, Object.assign({}, ADMIN_BUTTON_LABEL)))),
        React.createElement(Tooltip, { title: ICON_HELP_TOOLTIP },
            React.createElement(HelpLink, { href: docsLink, rel: "noopener noreferrer", target: "_blank" },
                React.createElement(StyledIconHelp, null))),
        children,
        React.createElement(Logout, null))));
export default withRouter(connector(Header));
