import { getSlaFromDate, getSlaRestrictionType, getSlaRequestName, getSlaTargetType, getSlaThreadGroupName, getSlaToDate, getSlaTransactionFilterType, getSlaTransactionName } from "../sla/slaParams";
export const generateSlaTypeIdFromData = (slaTypeData) => [
    getSlaTargetType(slaTypeData),
    getSlaRestrictionType(slaTypeData),
    getSlaTransactionFilterType(slaTypeData),
    getSlaThreadGroupName(slaTypeData),
    getSlaTransactionName(slaTypeData),
    getSlaRequestName(slaTypeData),
    getSlaFromDate(slaTypeData),
    getSlaToDate(slaTypeData)
]
    .join("_")
    .replace(/[~`!@#$%^:&?*()-+=/"'.,;<>{}\]\[|\\ ]/gi, "");
