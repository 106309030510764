import styled, { css } from "styled-components";
import { Color } from "../styled/colorPallete";
import { withDisable } from "../styled/global";
export const DescBlock = styled.div `
    user-select: none;
`;
export const PseudoInput = styled.input `
    ${withDisable}
    display: none;
    &:checked + i {
        &:after {
            background: ${Color.blue};
        }
    }
`;
export const StyledLabel = styled.label `
    align-items: center;
    cursor: pointer;
    display: flex;
    ${({ theme }) => (theme ? css(theme) : css ``)}
`;
export const StyledRadio = styled.i `
    ${withDisable}
    border: 2px solid ${Color.blue};
    border-radius: 30px;
    display: block;
    flex: 0 0 auto;
    height: 16px;
    position: relative;
    width: 16px;
    &:after {
        content: "";
        background: transparent;
        border-radius: 50%;
        display: block;
        height: 8px;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: all 0.3s ease-in-out;
        width: 8px;
    }
`;
