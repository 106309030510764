import React, { FC, useEffect } from "react";
import { Button, ButtonSize } from "@boomq/uikit";
import { AdminAllTeamsContainer, getSiteTitle, TeamActionsPanel, TeamsSubtitle } from "@boomq/common";
import { AdminTeamsSectionBlock } from "./styled";
import { TeamsCommonProps } from "../../account/Teams";

export interface AdminTeamsHandlers {
    onClickButtonCreate: () => void;
    onCreateNewTeam: () => void;
    onSelectTeam: (data: any) => void;
    setTitle: (title: string | JSX.Element) => void;
}

interface TeamsProps extends TeamsCommonProps {
    buttonCreateLabel: string;
}

const renderPageSubtitle = (props): JSX.Element =>
    props.pageSubtitle ? <TeamsSubtitle>{props.pageSubtitle}</TeamsSubtitle> : <></>;

export const AdminTeams: FC<TeamsProps & AdminTeamsHandlers> = (
    props: TeamsProps & AdminTeamsHandlers
): JSX.Element => {
    useEffect(() => {
        props.setTitle("account.all.teams.page.title");
        document.title = getSiteTitle(props.pageTitle);
    }, []);

    return (
        <>
            <AdminTeamsSectionBlock>
                {renderPageSubtitle(props)}

                <AdminAllTeamsContainer onSelectTeam={props.onSelectTeam}/>
            </AdminTeamsSectionBlock>

            <TeamActionsPanel>
                <Button size={ButtonSize.medium} onClick={props.onClickButtonCreate}>
                    {props.buttonCreateLabel}
                </Button>
            </TeamActionsPanel>
        </>
    );
};
