import styled from "styled-components";
export const StyledImage = styled.img `
    border: 3px solid rgba(219, 224, 239, 0.6);
    border-radius: 15px;
    max-width: 100%;
`;
export const StyledVideo = styled.video `
    border: 3px solid rgba(219, 224, 239, 0.6);
    border-radius: 15px;
    max-width: 100%;
`;
