import React, { Component } from "react";
import { ButtonWrapper, Input, InputWrapper, ResourceParams, Text } from "../styled/admin";
import { AdminButton, ItemPlate } from "../../routes/AdminPage";
import { addResourceRequest } from "../../../redux/actions/admin/resources";
import { connect } from "react-redux";
const connector = connect(null, { addResourceRequest });
class AddResourceItem extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            ip: "0.0.0.0",
            isValid: false,
            location: "",
            name: "",
            port: 2376,
            cpuCount: 0,
            ram: 0
        };
        this.changeHandler = ({ target: { name, value } }) => this.setState({ [name]: value });
    }
    render() {
        const { name, ip, location, port, cpuCount, ram } = this.state;
        return (React.createElement(ItemPlate, null,
            React.createElement(ResourceParams, null,
                React.createElement(InputWrapper, null,
                    React.createElement(Text, null, "name:"),
                    React.createElement(Input, { type: "text", name: "name", value: name, onChange: this.changeHandler })),
                React.createElement(InputWrapper, null,
                    React.createElement(Text, null, "location:"),
                    React.createElement(Input, { type: "text", name: "location", value: location, onChange: this.changeHandler })),
                React.createElement(InputWrapper, null,
                    React.createElement(Text, null, "ip:"),
                    React.createElement(Input, { type: "text", name: "ip", value: ip, onChange: this.changeHandler })),
                React.createElement(InputWrapper, null,
                    React.createElement(Text, null, "port:"),
                    React.createElement(Input, { type: "number", name: "port", value: port, onChange: this.changeHandler })),
                React.createElement(InputWrapper, null,
                    React.createElement(Text, null, "CPU(core count):"),
                    React.createElement(Input, { type: "number", name: "cpuCount", value: cpuCount, onChange: this.changeHandler })),
                React.createElement(InputWrapper, null,
                    React.createElement(Text, null, "RAM(Mb):"),
                    React.createElement(Input, { type: "number", name: "ram", value: ram, onChange: this.changeHandler }))),
            React.createElement(ButtonWrapper, null,
                React.createElement(AdminButton, { bg: "#6FCF97", onClick: () => this.props.addResourceRequest({
                        params: {
                            ip,
                            location,
                            name,
                            port,
                            cpuCount,
                            ram
                        }
                    }) }, "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C"))));
    }
}
export default connector(AddResourceItem);
