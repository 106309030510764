import { getUniqueId, isEmpty } from "@boomq/utils";
import { prepareTestProjectGroupRequests, prepareThreadGroupData } from "./threadGroup";
import { parseSteps, parseTestProjectLoadProfile } from "./ultimateThreadGroup2";
export const getUltimateThreadGroupDataVer1 = ({ res, index, group }) => {
    const { enabled = true, id } = group || {};
    const groupId = isEmpty(id) ? getUniqueId() : String(id);
    const steps = parseSteps(group.steps);
    const { extractorParameters, requests, timers } = prepareTestProjectGroupRequests(groupId, group.requests);
    return Object.assign(Object.assign({}, res), { extractorParameters: [...res.extractorParameters, ...extractorParameters], groups: [
            ...res.groups,
            Object.assign(Object.assign({}, prepareThreadGroupData(groupId, index, group)), { enabled })
        ], JMXProfiles: Object.assign(Object.assign({}, res.JMXProfiles), parseTestProjectLoadProfile(steps, groupId)), requests: [...res.requests, ...requests], timers: Object.assign(Object.assign({}, res.timers), timers) });
};
