import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { ACCOUNT_HEADER_EXIT_LABEL } from "./intl";
import { LogoutMobileWrapper, StyledIconLogout } from "./styled";
import { MobileHeaderText } from "../TeamInfoMobile";
import authorizeActions from "../../../redux/actions/authorize";
const { logoutWithCheckingOfUnsavedTestProject } = authorizeActions;
const connector = connect(null, { onClick: logoutWithCheckingOfUnsavedTestProject });
const Logout = (props) => {
    const clickHandler = () => props.onClick();
    return (React.createElement(LogoutMobileWrapper, { onClick: clickHandler },
        React.createElement(StyledIconLogout, { alt: "exit-icon" }),
        React.createElement(MobileHeaderText, null,
            React.createElement(FormattedMessage, Object.assign({}, ACCOUNT_HEADER_EXIT_LABEL)))));
};
export default withRouter(connector(Logout));
