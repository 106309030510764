import { handleActions } from "redux-actions";
import authorizeActions from "../actions/authorize";
import { clientTestProjectListSuccess } from "../actions/clientTestProjectList";
const { logout } = authorizeActions;
export const initialState = [];
export default handleActions({
    [logout]: () => (Object.assign({}, initialState)),
    [clientTestProjectListSuccess]: (state, action) => action.payload || []
}, initialState);
export const getClientTestProjectList = (state) => (state ? state.clientTestProjectList : []);
