import styled from "styled-components";
import { Color } from "@boomq/uikit";
import { TitleText } from "../../common/TableHeader";
import { media } from "../../styled/response";
export const StyledPage = styled.div `
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 34px;
    ${TitleText}:last-child {
        max-width: 128px;
        min-width: 128px;
    }
    ${media.tablet} {
        padding: 0 12px;
    }
`;
export const Sidebar = styled.div `
    background: ${Color.white};
    box-shadow: 0px 0px 8px rgba(0, 64, 140, 0.15);
    position: absolute;
    right: ${({ isShow }) => (isShow ? "0px" : "-110%")};
    transition: all 0.3s ease-in-out;
`;
