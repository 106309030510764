import styled, { css } from "styled-components";
import { Block, Color, H2, IconClose } from "@boomq/uikit";
export const FormTitle = styled(H2) `
    color: ${Color.darkGrey5};
    margin-bottom: 24px;
    text-align: center;
`;
export const ModalWrapper = styled.div `
    align-items: center;    
    background: rgba(9, 50, 100, 0.2);
    backdrop-filter: blur(4px);
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 9999;
`;
export const ModalPlate = styled.div `
    ${Block}
    display: flex;
    flex-direction: column;
    margin: 0 30px;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 72px 60px 60px;
    position: relative;
    ${({ width }) => width
    ? css `
                  width: ${width};
              `
    : css ``}
    ${({ height }) => height
    ? css `
                  height: ${height};
              `
    : css ``}
    ${({ theme }) => (theme ? css(theme) : css ``)}
`;
export const StyledIconClose = styled(IconClose) `
    cursor: pointer;
    fill: ${Color.grey50};
    position: absolute;
    right: 24px;
    top: 24px;
`;
