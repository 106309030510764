var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { select, put, takeEvery } from "redux-saga/effects";
import { excludeArrayFromArray, getUniqueId } from "@boomq/utils";
import { testProfilePercMin } from "../../helpers/applicationParams";
import { addAssertionToCurrentList, changeCurrentAssertion } from "../../helpers/assertion";
import { addExtractorToCurrentList, changeCurrentExtractor } from "../../helpers/extractResponse";
import { generateQueryParams, generateRequestUrl } from "../../helpers/generators";
import { getRequestById, getRequestId, getRequestTimerList } from "../../helpers/request";
import { modifyTimerListByType, modifyTimerListByValue, prepareTimerByType } from "../../helpers/requestTimers";
import { RequestTimerParams, TestType } from "../../helpers/types";
import { ASSERTION_NEW_ITEM_ID_PREFIX, getAssertionId } from "../../models/assertion";
import { ResponsePreviewSource } from "../../models/extractFromResponse";
import { getTimerId, getTimerType } from "../../models/requestTimer";
import { newRequestAddTimer, newRequestChangeAssertionField, newRequestChangeExtractorField, newRequestChangeParams, newRequestChangeParamsField, newRequestChangeParamsWithEditableTimers, newRequestChangeAssertionsResponsePreviewSource, newRequestChangeExtractorsResponsePreviewSource, newRequestChangeTimer, newRequestDeleteFieldParam, saveGenerationRequestUrl, saveGenerationQueryParams } from "../actions/newRequest";
import newTestActions from "../actions/newTest";
import { getNewRequestAssertions, getNewRequestExtractResponse, getNewRequestField, getNewRequestTimerRequestLink, getNewRequestTimerList } from "../reducers/newRequest";
import { getAllTimersWithRequestCount, getTestProjectSettingsField, getTestType } from "../reducers/newTest";
const { applyJmxTimersSettings, testProjectChangeParamsField } = newTestActions;
export function getModifiedTimersData({ newRequestParams, requests, timers }) {
    const request = getRequestById(requests, getRequestId(newRequestParams)) || {};
    const newRequestTimerIds = getRequestTimerList(newRequestParams).map(getTimerId);
    const timersForDeleting = excludeArrayFromArray(getRequestTimerList(request), newRequestTimerIds);
    return (timersForDeleting || []).length > 0
        ? Object.keys(timers).reduce((res, timerId) => (timersForDeleting.includes(timerId) ? res : Object.assign(Object.assign({}, res), { [timerId]: timers[timerId] })), {})
        : timers;
}
export function* modifyTimers({ newRequestParams, requests, timers }) {
    const newTimers = getModifiedTimersData({ newRequestParams, requests, timers });
    yield put(testProjectChangeParamsField({
        field: "timers",
        value: (newRequestParams.timerList || []).reduce((res, timer) => (Object.assign(Object.assign({}, res), { [timer.id]: timer })), newTimers)
    }));
}
export function getRequestSettings({ newRequestParams, requests }) {
    const groupId = newRequestParams.groupId === -1 ? getUniqueId() : newRequestParams.groupId;
    const timersRequestLink = newRequestParams && newRequestParams.timersRequestLink ? newRequestParams.timersRequestLink : {};
    const modifiedTimersRequestLink = Object.keys(timersRequestLink).reduce((res, timerId) => timersRequestLink[timerId].isModified ? Object.assign(Object.assign({}, res), { [timerId]: timersRequestLink[timerId] }) : res, {});
    const modifiedTimersRequestCount = Object.keys(modifiedTimersRequestLink).reduce((sum, timerId) => sum + (modifiedTimersRequestLink[timerId].requests || []).length, 0);
    delete newRequestParams.timersRequestLink;
    const newRequestParamsWithTimers = Object.assign(Object.assign({}, newRequestParams), { timerList: (newRequestParams.timerList || []).map((timer) => timer.id) });
    const newRequests = newRequestParams.id
        ? [
            ...requests.map((item) => item.id === newRequestParams.id ? Object.assign(Object.assign({}, newRequestParamsWithTimers), { groupId }) : item)
        ]
        : [
            ...requests,
            Object.assign(Object.assign({}, newRequestParamsWithTimers), { id: getUniqueId(), groupId, perc: testProfilePercMin, toTitle: "" })
        ];
    return {
        groupId,
        newRequests,
        modifiedTimersRequestCount
    };
}
const getNewTimerList = (_a) => {
    var { timerList, timerId, randomTimerType, key, value } = _a, restParams = __rest(_a, ["timerList", "timerId", "randomTimerType", "key", "value"]);
    return modifyTimerList({
        timerList: [...timerList, prepareTimerByType(timerId, getTimerType(restParams), randomTimerType)],
        timerId,
        key,
        value
    });
};
const modifyTimerList = ({ timerList, timerId, key, value }) => key === RequestTimerParams.Type
    ? modifyTimerListByType({ timerList, timerId, key, [RequestTimerParams.Type]: value })
    : modifyTimerListByValue({ timerList, timerId, key, value });
function* newRequestAddTimerFlow(action) {
    try {
        const { timerId, randomTimerType, key, value } = action && action.payload ? action.payload : {};
        const timerList = yield select(getNewRequestTimerList);
        yield put(newRequestChangeParamsField({
            field: "timerList",
            value: getNewTimerList({
                timerList,
                timerId,
                [RequestTimerParams.Type]: getTimerType(action.payload),
                randomTimerType,
                key,
                value
            })
        }));
    }
    catch (e) {
        console.error(e);
    }
}
export function* checkTimersEnabled() {
    const testType = yield select(getTestType);
    const { isTimersDisabled, multiplicationFactor } = yield select(getTestProjectSettingsField, "JMXTimersSettings");
    testType === TestType.JMX &&
        isTimersDisabled &&
        (yield put(applyJmxTimersSettings({
            prevIsTimersDisabled: isTimersDisabled,
            isTimersDisabled: !isTimersDisabled,
            prevMultiplicationFactor: multiplicationFactor,
            multiplicationFactor
        })));
}
function* newRequestChangeTimerFlow(action) {
    try {
        const { timerId, key, value } = action && action.payload ? action.payload : {};
        const timerList = yield select(getNewRequestTimerList);
        const timersRequestLink = yield select(getNewRequestTimerRequestLink);
        yield put(newRequestChangeParamsField({
            field: "timerList",
            value: modifyTimerList({ timerList, timerId, key, value })
        }));
        timersRequestLink[timerId] &&
            !timersRequestLink[timerId].isModified &&
            (yield put(newRequestChangeParamsField({
                field: "timersRequestLink",
                value: Object.assign(Object.assign({}, timersRequestLink), { [timerId]: Object.assign(Object.assign({}, timersRequestLink[timerId]), { isModified: true }) })
            })));
    }
    catch (e) {
        console.error(e);
    }
}
function* newRequestChangeParamsWithEditableTimersFlow(action) {
    const request = action && action.payload ? action.payload : {};
    const timerList = request && Array.isArray(request.timerList) ? request.timerList : [];
    const timerIds = timerList.reduce((res, timer) => [...res, timer.id], []);
    const timersWithRequestCount = yield select(getAllTimersWithRequestCount);
    yield put(newRequestChangeParams(Object.assign(Object.assign({}, request), { timersRequestLink: timerIds.reduce((res, timerId) => (Object.assign(Object.assign({}, res), { [timerId]: { requests: timersWithRequestCount[timerId] } })), {}) })));
}
export function* newRequestChangeParamsFieldFlow(action) {
    const { field, value } = action && action.payload ? action.payload : {};
    const requestUrl = yield select(getNewRequestField, "requestUrl");
    field === "requestUrl" && (yield put(saveGenerationQueryParams(generateQueryParams(value))));
    field === "queryParams" && (yield put(saveGenerationRequestUrl(generateRequestUrl(requestUrl, value))));
}
export function* newRequestDeleteFieldParamFlow(action) {
    const { field, id } = action && action.payload ? action.payload : {};
    const fieldValues = field ? yield select(getNewRequestField, field) : [];
    const newFieldValue = Array.isArray(fieldValues)
        ? [...fieldValues.filter((fieldValue) => fieldValue.id !== id)]
        : fieldValues;
    field && (yield put(newRequestChangeParamsField({ field, value: newFieldValue })));
}
function* newRequestChangeAssertionFieldFlow(action) {
    try {
        const payload = action && action.payload ? action.payload : {};
        const currentAssertions = yield select(getNewRequestAssertions);
        yield put(newRequestChangeParamsField({
            field: "assertions",
            value: getAssertionId(payload).includes(ASSERTION_NEW_ITEM_ID_PREFIX)
                ? addAssertionToCurrentList(currentAssertions, payload)
                : changeCurrentAssertion(currentAssertions, payload)
        }));
    }
    catch (e) {
        console.error(e);
    }
}
function* newRequestChangeExtractorFieldFlow(action) {
    try {
        const payload = action && action.payload ? action.payload : {};
        const currentExtractResponse = yield select(getNewRequestExtractResponse);
        const isExistExtractorInCurrentList = currentExtractResponse.find((extractor) => extractor.id === payload.id);
        const newExtractResponse = isExistExtractorInCurrentList
            ? changeCurrentExtractor(currentExtractResponse, payload)
            : addExtractorToCurrentList(currentExtractResponse, payload);
        yield put(newRequestChangeParamsField({
            field: "extractResponse",
            value: newExtractResponse
        }));
    }
    catch (e) {
        console.error(e);
    }
}
function* updateResponsePreviewDataFromHar() {
    const responsePreviewDataFromHar = yield select(getNewRequestField, "responsePreview");
    yield put(newRequestChangeParamsField({ field: "responsePreviewData", value: responsePreviewDataFromHar }));
}
function* newRequestChangeResponsePreviewSourceFlow({ payload }) {
    try {
        return payload === ResponsePreviewSource.Har ? yield* updateResponsePreviewDataFromHar() : undefined;
    }
    catch (e) {
        console.error(e);
    }
}
export function* setRequestValidatedData({ errorProperties, invalidParamsSectionName, validatedAssertionList, validatedExtractorList = [], validatedHeaderList = [], validatedMetadataList = [], }) {
    yield put(newRequestChangeParamsField({ field: "assertions", value: validatedAssertionList }));
    yield put(newRequestChangeParamsField({ field: "extractResponse", value: validatedExtractorList }));
    yield put(newRequestChangeParamsField({ field: "errorProperties", value: errorProperties }));
    yield put(newRequestChangeParamsField({ field: "headers", value: validatedHeaderList }));
    yield put(newRequestChangeParamsField({ field: "metadata", value: validatedMetadataList }));
    return invalidParamsSectionName
        ? yield put(newRequestChangeParamsField({ field: "currentActiveTabName", value: invalidParamsSectionName }))
        : undefined;
}
export function* newRequestFlow() {
    yield takeEvery(newRequestAddTimer, newRequestAddTimerFlow);
    yield takeEvery(newRequestChangeAssertionField, newRequestChangeAssertionFieldFlow);
    yield takeEvery(newRequestChangeExtractorField, newRequestChangeExtractorFieldFlow);
    yield takeEvery(newRequestChangeParamsField, newRequestChangeParamsFieldFlow);
    yield takeEvery(newRequestChangeAssertionsResponsePreviewSource, newRequestChangeResponsePreviewSourceFlow);
    yield takeEvery(newRequestChangeExtractorsResponsePreviewSource, newRequestChangeResponsePreviewSourceFlow);
    yield takeEvery(newRequestChangeTimer, newRequestChangeTimerFlow);
    yield takeEvery(newRequestChangeParamsWithEditableTimers, newRequestChangeParamsWithEditableTimersFlow);
    yield takeEvery(newRequestDeleteFieldParam, newRequestDeleteFieldParamFlow);
}
