import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AllTeams } from "../../../components/account/AllTeams";
import { calculateTeamItemsCount } from "../../../helpers/teams";
import { teamsCurrentPage, teamsRequest } from "../../../redux/actions/teams";
import { getClientPermissionList, getClientRole, getIsAuthorize } from "../../../redux/reducers/authorizePlate";
import { getIsShowLoader } from "../../../redux/reducers/loader";
import { getTeamsCurrentPage, getTeamItems, getTeamsPagesCount } from "../../../redux/reducers/teams";
const connector = connect((state) => ({
    items: getTeamItems(state),
    itemsPagesCount: getTeamsPagesCount(state),
    currentPage: getTeamsCurrentPage(state),
    isAuthorized: getIsAuthorize(state),
    isShowLoader: getIsShowLoader(state),
    permissions: getClientPermissionList(state),
    roles: getClientRole(state)
}), {
    onChangePage: teamsCurrentPage,
    onRefreshItems: teamsRequest
});
const AllTeamsContainer = (props) => {
    const teamListEl = useRef(null);
    useEffect(() => {
        const onAuthorized = (params) => props.isAuthorized && typeof props.onRefreshItems === "function" ? props.onRefreshItems(params) : undefined;
        onAuthorized({ page: props.currentPage, size: calculateTeamItemsCount(teamListEl.current) });
    }, [props.isAuthorized]);
    return React.createElement(AllTeams, Object.assign({}, props, { ref: teamListEl }));
};
export default withRouter(connector(AllTeamsContainer));
