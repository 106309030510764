import styled from "styled-components";
import { TableHeader, TitleText } from "@boomq/common";

export const ScrollContainer = styled.div`
    flex: auto;
    overflow: auto;
    padding: 4px 12px 12px;
`;

export const TeamMembersTableHeader = styled(TableHeader)`
    margin: 16px 12px 0;
    ${TitleText}:last-child {
        max-width: 138px;
        min-width: 138px;
    }
`;
