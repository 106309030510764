var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { ElementBlock } from "./styled";
import { DragAndDropIcon } from "../TransactionElement/styled";
import { formatDragStartNode } from "../../../../helpers/threadGroupEditor";
export const EditorElement = (_a) => {
    var { dragdropScope, node } = _a, restProps = __rest(_a, ["dragdropScope", "node"]);
    const onDragStart = (event) => {
        event.dataTransfer.setData("text", dragdropScope);
        event.dataTransfer.setData(dragdropScope, dragdropScope);
        event.dataTransfer.setData("node", JSON.stringify(formatDragStartNode(node)));
        event.dataTransfer.setData(node.type, node.type);
        return typeof restProps.onDragStart === "function" ? restProps.onDragStart(event) : undefined;
    };
    return (React.createElement(ElementBlock, { dragdropScope: dragdropScope, draggable: true, onDragStart: onDragStart },
        React.createElement(DragAndDropIcon, null),
        node.name));
};
