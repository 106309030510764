import styled, { css } from "styled-components";
import { Color } from "@boomq/uikit";
export const ButtonSection = styled.div `
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 12px;
    button {
        margin: 0 16px;
    }
`;
export const Description = styled.div `
    z-index: 999;
    background: ${Color.white};
    color: #b2b2b2;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    margin: 20px 0;
    padding: 5px 10px;
    text-align: center;
    ${(props) => css(props.theme)}
    ${({ left }) => left
    ? css `
                  left: ${left}px;
              `
    : css ``}
    ${({ top }) => top
    ? css `
                  top: ${top}px;
              `
    : css ``}
`;
