import { handleActions } from "redux-actions";
import authorizeActions from "../actions/authorize";
import { setProjectSettingsSetsCurrentPage, setSearchedLabels, testProjectSettingsSetsChangeParamsField, testProjectSettingsSetsSuccess } from "../actions/testProjectSettingsSets";
const { logout } = authorizeActions;
export const DEFAULT_TEST_PROJECT_SETTINGS_SETS_PAGE_SIZE = 8;
export const initialState = {
    content: [],
    filterLabels: [],
    labelList: [],
    number: 0,
    numberOfElements: 0,
    size: DEFAULT_TEST_PROJECT_SETTINGS_SETS_PAGE_SIZE,
    totalElements: 0,
    totalPages: 0
};
export default handleActions({
    [logout]: () => (Object.assign({}, initialState)),
    [setSearchedLabels]: (state, action) => {
        const { id, searchedLabels } = action && action.payload ? action.payload : {};
        return Object.assign(Object.assign({}, state), { content: state.content.map((settingSet) => settingSet.id === id ? Object.assign(Object.assign({}, settingSet), { searchedLabels }) : settingSet) });
    },
    [setProjectSettingsSetsCurrentPage]: (state, action) => (Object.assign(Object.assign({}, state), { number: Number.isInteger(action.payload) ? action.payload : 0 })),
    [testProjectSettingsSetsChangeParamsField]: (state, action) => {
        const { field, subfield, value } = action && action.payload ? action.payload : {};
        return field && subfield
            ? Object.assign(Object.assign({}, state), { [field]: Object.assign(Object.assign({}, state[field]), { [subfield]: value }) }) : Object.assign(Object.assign({}, state), { [field]: value });
    },
    [testProjectSettingsSetsSuccess]: (state, action) => (Object.assign(Object.assign({}, state), action.payload))
}, initialState);
const getStateParam = (state, paramName) => state && state.testProjectSettingsSets ? state.testProjectSettingsSets[paramName] : initialState[paramName];
export const getCurrentPage = (state) => getStateParam(state, "number");
export const getCurrentPageElements = (state) => getStateParam(state, "numberOfElements");
export const getPrevPage = (state) => {
    const currentPage = getCurrentPage(state);
    return currentPage > 0 ? currentPage - 1 : currentPage;
};
export const getTestProjectSettingsSetsData = (state) => getStateParam(state, "content");
export const getTestProjectSettingsSetsFilterLabels = (state) => getStateParam(state, "filterLabels");
export const getTestProjectSettingsSetsLabelList = (state) => getStateParam(state, "labelList");
export const getTestProjectSettingsSetsCount = (state) => getStateParam(state, "totalElements");
export const getTestProjectSettingsSetsPagesCount = (state) => getStateParam(state, "totalPages");
