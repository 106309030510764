import { isEmpty } from "../utils";
import { INCORRECT_TEAM_NAME_EMPTY } from "./intl";
export const teamNameValidator = (name) => isEmpty(name)
    ? {
        isValid: false,
        error: INCORRECT_TEAM_NAME_EMPTY
    }
    : {
        isValid: true
    };
