import ReactSVG from "react-svg";
import styled, { css } from "styled-components";
import { hoverWithShadow } from "../../styled/global";
export const ArrowImage = styled(ReactSVG) `
    fill: #176ed6;
    ${({ right }) => right &&
    css `
            transform: rotate(-90deg);
        `}
    ${({ left }) => left &&
    css `
            transform: rotate(90deg);
        `}
`;
export const Button = styled.button `
    cursor: pointer;
    width: 41px;
    height: 34px;
    background: #ffffff;
    box-shadow: 0 0 8px rgba(0, 64, 140, 0.15);
    border-radius: 5px;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    font-size: 14px;
    color: #396496;
    margin-left: 8px;
    outline: none;
    border: none;
    transition: all 0.3s ease-in-out;
    ${({ isDisabled }) => isDisabled &&
    css `
            cursor: not-allowed;
            pointer-events: none;
            background: #f6f6f6;
        `}
    &:first-child {
        margin-left: 0;
    }
    ${hoverWithShadow};
`;
export const StyledPagination = styled.div `
    width: 100%;
    flex: 0 1 auto;
    display: flex;
    justify-content: flex-end;
    padding: 0 5px;
    .rc-pagination {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        &:after {
            display: none;
        }
        &-jump {
            &-next:after,
            &-prev:after {
                display: none;
            }
        }
        & > li {
            height: auto;
            border: none;
            margin: 5px;
        }
    }
`;
