import styled from "styled-components";
import { Block, Button, Color, FontBodyBigMedium, FontBodyMediumBold, FontBodyMediumMedium, H3, IconAlertCircle, IconDownload, withDisable } from "@boomq/uikit";
import { BlockContent } from "../../../styled/Blocks";
const ConfigFileNameStyles = `
    ${FontBodyMediumBold}
    color: ${Color.blue};
    cursor: pointer;
    margin-right: 6px;
`;
export const AddingSystemMetricsSection = styled.div `
    display: flex;
    flex-direction: column;
    margin-right: 16px;
    width: 236px;
`;
export const BlockTitleText = styled(H3) `
    margin-right: 24px;
`;
export const ButtonsSection = styled.div `
    display: flex;
`;
export const ConfigFileIconLink = styled.a `
    ${withDisable}
`;
export const ConfigFileLinks = styled.ul `
    border-left: 1px solid ${Color.lightGrey85};
    margin-top: 4px;
    padding-left: 16px;
`;
export const ConfigFileLink = styled.li `
    align-items: center;
    display: flex;
    margin-top: 16px;
`;
export const ConfigFileName = styled.span `
    ${withDisable}
    ${ConfigFileNameStyles}
`;
export const ConfigFileNameLink = styled.a `
    ${withDisable}
    ${ConfigFileNameStyles}
`;
export const ErrorBlock = styled.div `
    align-items: center;
    cursor: help;
    display: flex;
    justify-content: center;
    position: relative;
`;
export const HowToUseSection = styled.div `
    ${FontBodyMediumMedium}
    align-items: center;
    color: ${Color.blue};
    display: flex;
`;
export const SectionTitle = styled.div `
    ${FontBodyBigMedium}
    color: ${Color.darkGrey5};
    margin-bottom: 16px;
`;
export const SelectedSystemMetricsSection = styled.div `
    border-left: 1px solid ${Color.lightGrey85};
    margin-right: 16px;
    padding-left: 16px;
`;
export const SelectedMetrics = styled.ul `
    display: flex;
    margin-bottom: 20px;
    > li {
        margin-right: 8px;
        margin-bottom: 4px;
    }
`;
export const StyledBlock = styled.div `
    ${Block}
    margin-top: 16px;
`;
export const StyledBlockContent = styled(BlockContent) `
    display: flex;
`;
export const StyledButton = styled(Button) `
    margin-left: 8px;
`;
export const StyledIconAlertCircle = styled(IconAlertCircle) `
    fill: ${Color.blue};
    position: absolute;
`;
export const StyledIconDownload = styled(IconDownload) `
    ${withDisable}
    cursor: pointer;
    fill: ${Color.blue};
    height: 20px;
    min-width: 20px;
    width: 20px;
`;
