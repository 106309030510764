export const ASSERTION_CONDITION_REGEXP_CONTAINS = {
    defaultMessage: "Regexp contains",
    id: "account.request.form.assertion.condition.regexp.contains"
};
export const ASSERTION_CONDITION_REGEXP_MATCHES = {
    defaultMessage: "Regexp matches",
    id: "account.request.form.assertion.condition.regexp.matches"
};
export const ASSERTION_CONDITION_REGEXP_NOT_CONTAINS = {
    defaultMessage: "Regexp not contains",
    id: "account.request.form.assertion.condition.regexp.not.contains"
};
export const ASSERTION_CONDITION_REGEXP_NOT_MATCHES = {
    defaultMessage: "Regexp not matches",
    id: "account.request.form.assertion.condition.regexp.not.matches"
};
export const ASSERTION_CONDITION_EQUALS = {
    defaultMessage: "Equals",
    id: "account.request.form.assertion.condition.equals"
};
export const ASSERTION_CONDITION_EXISTS = {
    defaultMessage: "Exists",
    id: "account.request.form.assertion.condition.exists"
};
export const ASSERTION_CONDITION_MATCHES_XPATH2 = {
    defaultMessage: "Matches",
    id: "account.request.form.assertion.condition.matches"
};
export const ASSERTION_CONDITION_NOT_MATCHES_XPATH2 = {
    defaultMessage: "Not matches",
    id: "account.request.form.assertion.condition.not.matches"
};
export const ASSERTION_CONDITION_NOT_EQUALS = {
    defaultMessage: "Not equals",
    id: "account.request.form.assertion.condition.not.equals"
};
export const ASSERTION_CONDITION_NOT_EXISTS = {
    defaultMessage: "Not exists",
    id: "account.request.form.assertion.condition.not.exists"
};
export const ASSERTION_CONDITION_NOT_NULL = {
    defaultMessage: "Is not null",
    id: "account.request.form.assertion.condition.not.null"
};
export const ASSERTION_CONDITION_NOT_SUBSTRING = {
    defaultMessage: "Not substring",
    id: "account.request.form.assertion.condition.not.substring"
};
export const ASSERTION_CONDITION_NULL = {
    defaultMessage: "Is null",
    id: "account.request.form.assertion.condition.null"
};
export const ASSERTION_CONDITION_SUBSTRING = {
    defaultMessage: "Substring",
    id: "account.request.form.assertion.condition.substring"
};
export const ASSERTION_FILTER_JSON_PATH = {
    defaultMessage: "jSONPath",
    id: "account.request.form.assertion.filter.jsonPath"
};
export const ASSERTION_FILTER_NULL = {
    defaultMessage: "No filter",
    id: "account.request.form.assertion.filter.null"
};
export const ASSERTION_FILTER_XPATH = {
    defaultMessage: "XPath",
    id: "account.request.form.assertion.filter.xpath"
};
export const ASSERTION_TYPE_URL_SAMPLED = {
    defaultMessage: "URL Sampled",
    id: "account.request.form.assertion.type.url.sampled"
};
export const ASSERTION_TYPE_REQUEST_BODY = {
    defaultMessage: "Request body",
    id: "account.request.form.assertion.type.request.body"
};
export const ASSERTION_TYPE_REQUEST_HEADERS = {
    defaultMessage: "Request headers",
    id: "account.request.form.assertion.type.request.headers"
};
export const ASSERTION_TYPE_REQUEST_METADATA = {
    defaultMessage: "Request metadata",
    id: "account.request.form.assertion.type.request.metadata"
};
export const ASSERTION_TYPE_RESPONSE_BODY = {
    defaultMessage: "Response body",
    id: "account.request.form.assertion.type.response.body"
};
export const ASSERTION_TYPE_RESPONSE_CODE = {
    defaultMessage: "Response code",
    id: "account.request.form.assertion.type.response.code"
};
export const ASSERTION_TYPE_RESPONSE_DOCUMENT = {
    defaultMessage: "Response document",
    id: "account.request.form.assertion.type.response.document"
};
export const ASSERTION_TYPE_RESPONSE_DURATION = {
    defaultMessage: "Response duration",
    id: "account.request.form.assertion.type.response.duration"
};
export const ASSERTION_TYPE_RESPONSE_HEADERS = {
    defaultMessage: "Response headers",
    id: "account.request.form.assertion.type.response.headers"
};
export const ASSERTION_TYPE_RESPONSE_METADATA = {
    defaultMessage: "Response metadata",
    id: "account.request.form.assertion.type.response.metadata"
};
export const ASSERTION_TYPE_RESPONSE_MESSAGE = {
    defaultMessage: "Response message",
    id: "account.request.form.assertion.type.response.message"
};
export const ASSERTION_TYPE_RESPONSE_BODY_SIZE = {
    defaultMessage: "Response body size",
    id: "account.request.form.assertion.type.response.body.size"
};
export const ASSERTION_TYPE_RESPONSE_CODE_SIZE = {
    defaultMessage: "Response code size",
    id: "account.request.form.assertion.type.response.code.size"
};
export const ASSERTION_TYPE_RESPONSE_FULL_SIZE = {
    defaultMessage: "Response full size",
    id: "account.request.form.assertion.type.response.full.size"
};
export const ASSERTION_TYPE_RESPONSE_HEADERS_SIZE = {
    defaultMessage: "Response headers size",
    id: "account.request.form.assertion.type.response.headers.size"
};
export const ASSERTION_TYPE_RESPONSE_METADATA_SIZE = {
    defaultMessage: "Response metadata size",
    id: "account.request.form.assertion.type.response.metadata.size"
};
export const ASSERTION_TYPE_RESPONSE_MESSAGE_SIZE = {
    defaultMessage: "Response message size",
    id: "account.request.form.assertion.type.response.message.size"
};
export const ASSERTION_CONDITION_COMPARISON_EQUALS = {
    defaultMessage: "=",
    id: "account.request.form.assertion.condition.comparison.equals"
};
export const ASSERTION_CONDITION_COMPARISON_NOT_EQUALS = {
    defaultMessage: "!=",
    id: "account.request.form.assertion.condition.comparison.not.equals"
};
export const ASSERTION_CONDITION_COMPARISON_GREATER_THAN = {
    defaultMessage: ">",
    id: "account.request.form.assertion.condition.comparison.greater.than"
};
export const ASSERTION_CONDITION_COMPARISON_LESS_THAN = {
    defaultMessage: "<",
    id: "account.request.form.assertion.condition.comparison.less.than"
};
export const ASSERTION_CONDITION_COMPARISON_GREATER_OR_EQUALS = {
    defaultMessage: ">=",
    id: "account.request.form.assertion.condition.comparison.greater.or.equals"
};
export const ASSERTION_CONDITION_COMPARISON_LESS_OR_EQUALS = {
    defaultMessage: "<=",
    id: "account.request.form.assertion.condition.comparison.less.or.equals"
};
