import styled from "styled-components";
import { media } from "../../styled/response";
export const ButtonWrapper = styled.div `
    display: flex;
    justify-content: flex-end;
`;
export const Input = styled.input `
    border: 1px solid;
    border-radius: 4px;
    padding: 5px;
`;
export const InputWrapper = styled.div `
    align-items: center;
    display: flex;
    padding: 5px 10px 5px 5px;
`;
export const ResourceParams = styled.div `
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    ${media.desktop} {
        flex-direction: column;
    }
`;
export const Text = styled.p `
    margin-right: 5px;
    ${media.desktop} {
        min-width: 150px;
    }
`;
