import { SlaTargetType } from "../../models/sla/enums";
export const DEFAULT_NEW_SLA_FORM_ITEM_TARGET_TYPE = SlaTargetType.Test;
export const SLA_FROM_DATE_PARAM_NAME = "fromDate";
export const SLA_TO_DATE_PARAM_NAME = "toDate";
export const SLA_RESTRICTION_TYPE_PARAM_NAME = "restrictionType";
export const SLA_HOST_NAME_PARAM_NAME = "hostname";
export const SLA_TARGET_TYPE_PARAM_NAME = "targetType";
export const SLA_TYPE_PARAM_NAME = "type";
export const SLA_REQUEST_NAME_PARAM_NAME = "requestName";
export const SLA_THREAD_GROUP_ID_PARAM_NAME = "threadGroupId";
export const SLA_THREAD_GROUP_NAME_PARAM_NAME = "threadGroupName";
export const SLA_TRANSACTION_FILTER_TYPE_PARAM_NAME = "transactionFilterType";
export const SLA_TRANSACTION_ID_PARAM_NAME = "transactionId";
export const SLA_TRANSACTION_NAME_PARAM_NAME = "transactionName";
