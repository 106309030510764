import styled from "styled-components";
import { Color } from "../styled/colorPallete";
export const Rate = styled.div `
    display: flex;
`;
export const RateIcon = styled.span `
    color: ${Color.gold};
    font-size: 24px;
    position: relative;
    &:after {
        content: "\\2606";
    }
    &:before {
        content: "\\2605";
        left: 0;
        overflow: hidden;
        position: absolute;
        top: 0;
        width: ${({ percent }) => (percent === undefined || percent === null ? 100 : percent)}%;
    }
`;
export const Rates = styled.div `
    display: flex;
`;
