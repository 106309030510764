import React from "react";
import { withRouter } from "react-router-dom";
import { useIntl } from "@boomq/utils";
import { Tabs } from "@boomq/uikit";
import { PARAMETERS_TITLE } from "./intl";
import { customTabListTheme } from "./styled";
import { BlockTitleWithWarning } from "../BlockTitleWithWarning";
import { StyledBlock } from "../Wizard/styled";
import { BlockContent } from "../../../styled/Blocks";
import { withResize } from "../../../styled/response";
import { getParameterItems } from "../../../../helpers/parameters";
import { parameterElements } from "../../../../helpers/testProjectSettingsSetParameters";
const Parameters = () => {
    const { formatMessage } = useIntl();
    const items = getParameterItems(formatMessage);
    return (React.createElement(StyledBlock, null,
        React.createElement(BlockTitleWithWarning, { title: PARAMETERS_TITLE }),
        React.createElement(BlockContent, null,
            React.createElement(Tabs, { defaultActiveTabName: "literalParameters", elements: parameterElements, items: items, name: "testProjectSettingsSetParameters", tabListTheme: customTabListTheme }))));
};
export default withResize(withRouter(Parameters));
