import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { isEmpty, useIntl } from "@boomq/utils";
import { PAGE_TITLE_MESSAGE } from "./intl";
import { BlockHeaderWithMarginTop } from "../../../../components/styled/Common";
import { TestProjectContentWrapper, TestProjectPageWrapper } from "../../../../components/styled/Wrappers";
import { EditorContainer } from "../../../account/RequestGroupEditor/Editor";
import { NewTestSettingsSetSidebarContainer } from "../../../account/NewTestSettingsSetSidebar";
import { TestProjectLeftSidebarContainer } from "../../../account/TestProject/TestProjectLeftSidebarContainer";
import { TestProjectNameContainer } from "../../../account/TestProject/TestProjectName";
import { TestProjectSettingsWizardContainer } from "../../../account/TestProject/TestProjectSettingsWizard";
import { AccessDenied } from "../../../../components/common/AccessDenied";
import { isAllowedTestProjectActionByPermissions } from "../../../../helpers/testProject";
import { getSiteTitle } from "../../../../helpers/title";
import { TestProjectAction } from "../../../../helpers/types";
import { ProfileSettingsMode } from "../../../../models/testProject";
import newTestActions from "../../../../redux/actions/newTest";
import { getClientPermissionList, getIsAuthorize, getSelectedTeamId } from "../../../../redux/reducers/authorizePlate";
import { getProfileSettingsMode } from "../../../../redux/reducers/newTest";
const { onAuthorizedNewTest, onUnmountNewTest } = newTestActions;
const connector = connect((state) => ({
    isAuthorized: getIsAuthorize(state),
    permissionList: getClientPermissionList(state),
    profileSettingsMode: getProfileSettingsMode(state),
    selectedTeamId: getSelectedTeamId(state)
}), {
    onAuthorized: onAuthorizedNewTest,
    onUnmount: onUnmountNewTest
});
const NewTestCommonContainer = ({ children, isAuthorized, selectedTeamId, onAuthorized, onUnmount, permissionList, profileSettingsMode }) => {
    const { formatMessage } = useIntl();
    useEffect(() => {
        document.title = getSiteTitle(formatMessage(PAGE_TITLE_MESSAGE));
        return () => onUnmount();
    }, []);
    useEffect(() => {
        const checkAuthorization = () => (isAuthorized && !isEmpty(selectedTeamId) ? onAuthorized() : undefined);
        checkAuthorization();
    }, [isAuthorized, selectedTeamId]);
    return isAllowedTestProjectActionByPermissions(permissionList, TestProjectAction.Add) ? (React.createElement(React.Fragment, null, profileSettingsMode === ProfileSettingsMode.ThreadGroupEditor ? (React.createElement(EditorContainer, null)) : (React.createElement(TestProjectPageWrapper, null,
        React.createElement(TestProjectLeftSidebarContainer, null),
        React.createElement(TestProjectContentWrapper, null,
            React.createElement(BlockHeaderWithMarginTop, null,
                React.createElement(TestProjectNameContainer, null)),
            React.createElement(TestProjectSettingsWizardContainer, null,
                children,
                React.createElement(NewTestSettingsSetSidebarContainer, null))))))) : (React.createElement(AccessDenied, null));
};
export default withRouter(connector(NewTestCommonContainer));
