import React, { useEffect } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import {
    AuthBlock,
    AuthBlockTitle,
    AuthButtonBlock,
    AuthPlateWrapper,
    AuthSectionTitle,
    AuthorizeFormContainer,
    ErrorPlate,
    StyledButton,
    authErrors,
    closeSiteModal,
    getErrorCode,
    getIsAuthorize,
    getSiteModal
} from "@boomq/common";
import { BUTTON_SIGN_IN_LABEL } from "./intl";

const getErrorDesc = (state) => {
    const errorCode = getErrorCode(state);

    return authErrors(errorCode);
};

const connector = connect(
    (state) => ({
        errorDesc: getErrorDesc(state),
        isAuthorized: getIsAuthorize(state)
    }),
    { closeSiteModal, getSiteModal }
);

const AuthorizePlateContainer = ({ closeSiteModal, errorDesc, isAuthorized, title, ...restProps }) => {
    const checkAuthorization = (isAuthorized) =>
        isAuthorized && restProps.type === "modal" ? closeSiteModal() : undefined;

    useEffect(() => {
        checkAuthorization(isAuthorized);
    }, [isAuthorized]);

    return (
        <AuthPlateWrapper>
            <AuthBlockTitle isError={errorDesc}>
                <AuthSectionTitle>
                    {title && title.id ? (
                        <FormattedMessage {...title} />
                    ) : (
                        <FormattedMessage id="website.authorize.title" defaultMessage="Sign in" />
                    )}
                </AuthSectionTitle>
            </AuthBlockTitle>

            <AuthBlock>
                <AuthorizeFormContainer {...restProps}>
                    <AuthButtonBlock>
                        <StyledButton type="submit">
                            <FormattedMessage {...BUTTON_SIGN_IN_LABEL} />
                        </StyledButton>
                    </AuthButtonBlock>
                </AuthorizeFormContainer>
            </AuthBlock>

            {errorDesc && errorDesc.id && (
                <ErrorPlate>
                    <FormattedMessage {...errorDesc} />
                </ErrorPlate>
            )}
        </AuthPlateWrapper>
    );
};

export default connector(AuthorizePlateContainer);
