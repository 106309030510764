import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getUniqueId, useIntl } from "@boomq/utils";
import { KeyValue } from "../../KeyValue";
import { withResize } from "../../../styled/response";
import { FormType } from "../../../../helpers/types";
import { newRequestChangeParamsField, newRequestDeleteFieldParam } from "../../../../redux/actions/newRequest";
import { getNewRequestField } from "../../../../redux/reducers/newRequest";
import { getComboBoxRequestParametersList } from "../../../../redux/reducers/newTest";
const getQueryParams = (state, mode) => {
    const queryParamsFromStore = getNewRequestField(state, "queryParams");
    return mode === FormType.View
        ? queryParamsFromStore
        : [...queryParamsFromStore, { id: getUniqueId(), key: "", value: "" }];
};
const connector = connect((state, ownProps) => ({
    queryParams: getQueryParams(state, ownProps.mode),
    requestUrl: getNewRequestField(state, "requestUrl"),
    requestParametersList: getComboBoxRequestParametersList(state)
}), { onChangeField: newRequestChangeParamsField, onDeleteField: newRequestDeleteFieldParam });
const keyValueTheme = { marginTop: "20px", opacity: "1" };
const QueryParameters = (props) => {
    const { formatMessage } = useIntl();
    const changeHandler = (val, id) => {
        const newQueryParams = [
            ...props.queryParams.map((queryParam) => (queryParam.id === id && Object.assign(Object.assign({}, queryParam), val)) || queryParam)
        ].filter((queryParam) => queryParam.key !== "" || queryParam.value !== "");
        return props.onChangeField({
            field: props.name,
            value: newQueryParams
        });
    };
    const deleteHandler = (id) => props.onDeleteField({ field: props.name, id });
    const keyPlaceholder = formatMessage({
        defaultMessage: "Key",
        id: "account.request.form.key.placeholder"
    });
    const valuePlaceholder = formatMessage({
        defaultMessage: "Value",
        id: "account.request.form.value.placeholder"
    });
    return (props.queryParams || []).map(({ id, key, value }) => (React.createElement(KeyValue, { disabled: props.mode === FormType.View, key: id, name: props.name, onChange: (val) => changeHandler(val, id), onDelete: () => deleteHandler(id), parameters: props.requestParametersList, placeholderKey: keyPlaceholder, placeholderValue: valuePlaceholder, theme: keyValueTheme, value: { key, value } })));
};
export default withResize(withRouter(connector(QueryParameters)));
