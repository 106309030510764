var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { forwardRef } from "react";
import { isEmpty, round } from "@boomq/utils";
import { HintIcon, IconArrowUp, StyledIconCheck, StyledIconClose, TableCellSlaResultContent, TableCellSlaResultWrapper } from "./styled";
import Tooltip from "../../../common/Tooltip";
import { IconArrow } from "../../../styled/Icons/Arrow";
import { getColorByName } from "../../../../helpers/color/colorByName";
import { getColorByRestrictionTypeAndColorName } from "../../../../helpers/slaReport/colorByRestrictionTypeAndColorName";
import { generateSlaTypeItemFormattedTextWithTimeline } from "../../../../helpers/slaReport/slaTypeItemFormattedTextWithTimeline";
import { getTooltipLabel } from "../../../../helpers/slaReport/tooltipLabel";
import { SlaReportTableDiffType, SlaReportTableValueType } from "../../../../helpers/types";
import { SlaStatus } from "../../../../models/sla/enums";
const CheckHint = forwardRef((props, ref) => {
    return (React.createElement(HintIcon, { ref: ref },
        React.createElement(StyledIconCheck, Object.assign({}, props))));
});
const CloseHint = forwardRef((props, ref) => {
    return (React.createElement(HintIcon, { ref: ref },
        React.createElement(StyledIconClose, Object.assign({}, props))));
});
const TableCellSlaResult = ({ baselineTestId, diffType, cell, intl: { formatMessage }, isBaselineTest, isShowAbsoluteValue, slaType, testDuration, unit, valueType }) => {
    const getSlaResultEmptyValue = () => formatMessage({ defaultMessage: "no value", id: "account.sla.report.profile.table.sla.result.empty" });
    const getCellSlaResultDiffIcon = (cellValue, restrictionType) => cellValue > 0 ? (React.createElement(IconArrowUp, { fill: getColorByRestrictionTypeAndColorName(restrictionType, "red") })) : (React.createElement(IconArrow, { fill: getColorByRestrictionTypeAndColorName(restrictionType, "green") }));
    const getCellSlaResultSign = (cellValue) => (cellValue > 0 ? "+" : "-");
    const getCellSlaResultText = (cellValue, unit) => Math.abs(round(cellValue, 100)) + " " + unit;
    const getCellDiffTypeSlaResultText = (cellValue, unit) => cellValue === 0
        ? formatMessage({ defaultMessage: "no difference", id: "account.sla.report.profile.table.no.difference" })
        : `${getCellSlaResultSign(cellValue)}${getCellSlaResultText(cellValue, unit)}`;
    const getCellSlaResultTextByDiffType = (diffType, diffValues, unit) => {
        const { diffValue, diffPercent } = diffValues || {};
        return diffType === SlaReportTableDiffType.Absolute
            ? getCellDiffTypeSlaResultText(diffValue, unit)
            : getCellDiffTypeSlaResultText(diffPercent, "%");
    };
    const getCellSlaResultDiffIconByBaselineTestDiffType = (diffType, { slaResultBaselineDiff, slaResultBaselineDiffPercent }, restrictionType) => {
        const value = diffType === SlaReportTableDiffType.Absolute ? slaResultBaselineDiff : slaResultBaselineDiffPercent;
        return value === 0 ? "" : getCellSlaResultDiffIcon(value, restrictionType);
    };
    const getCellSlaResultByBaselineTestDiffType = ({ diffType, cell = {}, restrictionType, unit }) => {
        const { slaResultBaselineDiff, slaResultBaselineDiffPercent } = cell || {};
        return (React.createElement(React.Fragment, null,
            getCellSlaResultTextByDiffType(diffType, { diffValue: slaResultBaselineDiff, diffPercent: slaResultBaselineDiffPercent }, unit),
            "\u00A0",
            getCellSlaResultDiffIconByBaselineTestDiffType(diffType, cell, restrictionType)));
    };
    const getCellSlaResultDiffIconByPreviousTestDiffType = (diffType, { slaResultPreviousTestDiff, slaResultPreviousTestDiffPercent }, restrictionType) => {
        const value = diffType === SlaReportTableDiffType.Absolute ? slaResultPreviousTestDiff : slaResultPreviousTestDiffPercent;
        return value === 0 ? "" : getCellSlaResultDiffIcon(value, restrictionType);
    };
    const getCellSlaResultPreviousTestParamsByDiffType = (diffType, { slaResultPreviousTestDiff, slaResultPreviousTestDiffPercent }, unit) => diffType === SlaReportTableDiffType.Absolute
        ? { value: slaResultPreviousTestDiff, valueUnit: unit }
        : { value: slaResultPreviousTestDiffPercent, valueUnit: "%" };
    const getCellSlaResultByPreviousTestDiffType = ({ diffType, cell = {}, isShowAbsoluteValue, restrictionType, unit }) => {
        const { value, valueUnit } = getCellSlaResultPreviousTestParamsByDiffType(diffType, cell, unit);
        const absValue = isShowAbsoluteValue ? `(${getCellSlaResultText(cell.slaResult, unit)})` : "";
        return isEmpty(value) && !isEmpty(cell.slaResult) ? (`${formatMessage({
            defaultMessage: "no prev.value",
            id: "account.sla.report.profile.table.no.previous.value"
        })} ${absValue}`) : (React.createElement(React.Fragment, null,
            `${getCellDiffTypeSlaResultText(value, valueUnit)} ${absValue}`,
            " ",
            getCellSlaResultDiffIconByPreviousTestDiffType(diffType, cell, restrictionType)));
    };
    const getCellSlaStatus = (cell = {}, slaType = {}, unit = "") => {
        const { slaResult, status } = cell || {};
        const tooltipText = getTooltipLabel(Object.assign(Object.assign({}, cell), { slaName: `SLA: ${generateSlaTypeItemFormattedTextWithTimeline(slaType, testDuration, formatMessage)}`, unit, value: slaResult }));
        switch (status) {
            case SlaStatus.Success: {
                return (React.createElement(Tooltip, { title: tooltipText },
                    React.createElement(CheckHint, { height: "11px", width: "14px", fill: getColorByName("green") })));
            }
            case SlaStatus.Failure: {
                return (React.createElement(Tooltip, { title: tooltipText },
                    React.createElement(CloseHint, { height: "11px", width: "11px", fill: getColorByName("red") })));
            }
            default: {
                return;
            }
        }
    };
    const getCellSlaResultByValueType = ({ valueType, diffType, cell = {}, isShowAbsoluteValue, restrictionType, unit, isBaselineTest }) => {
        const { slaResult } = cell || {};
        switch (valueType.value) {
            case SlaReportTableValueType.BaselineDiff: {
                return isBaselineTest
                    ? getCellSlaResultText(slaResult, unit)
                    : getCellSlaResultByBaselineTestDiffType({ cell, diffType, restrictionType, unit });
            }
            case SlaReportTableValueType.PreviousTestDiff: {
                return getCellSlaResultByPreviousTestDiffType({
                    cell,
                    diffType,
                    isShowAbsoluteValue,
                    restrictionType,
                    unit
                });
            }
            default: {
                return getCellSlaResultText(slaResult, unit);
            }
        }
    };
    const getCellValidatedSlaResult = (_a) => {
        var { cell } = _a, rest = __rest(_a, ["cell"]);
        return isEmpty(cell.slaResult) ? (getSlaResultEmptyValue()) : (React.createElement(React.Fragment, null,
            getCellSlaResultByValueType(Object.assign({ cell }, rest)),
            getCellSlaStatus(cell, slaType, unit)));
    };
    const { restrictionType } = slaType || {};
    const { value } = valueType || {};
    return (React.createElement(TableCellSlaResultWrapper, { isBaselineTest: isBaselineTest },
        React.createElement(TableCellSlaResultContent, null, value === SlaReportTableValueType.BaselineDiff && isEmpty(baselineTestId)
            ? getSlaResultEmptyValue()
            : getCellValidatedSlaResult({
                diffType,
                cell,
                isBaselineTest,
                isShowAbsoluteValue,
                restrictionType,
                unit,
                valueType
            }))));
};
export default TableCellSlaResult;
