var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useRef } from "react";
import { connect } from "react-redux";
import { useIntl } from "@boomq/utils";
import { stopEvent, useEventSelectListener } from "@boomq/utils";
import { LOAD_PROFILE_LABEL, UNIT_PERC, UNIT_VU } from "./intl";
import { CommonThreadGroup } from "../../../../components/account/TestProject/CommonThreadGroup";
import { getThreadGroupActions, ThreadGroupAction } from "../../../../models/threadGroup";
import { formatIntlMessage } from "../../../../helpers/intl";
import { calculateThreadGroupUsersCount } from "../../../../helpers/loadProfile";
import { nameValidator } from "../../../../helpers/validators";
import newTestActions from "../../../../redux/actions/newTest";
import { getProfileDistribution } from "../../../../redux/reducers/newTest";
const { changeRequestGroup, changeThreadGroupPercent, copyThreadGroup, deleteRequestGroup, editRequestGroup, toggleEnabledGroup, toggleLoadProfileSettings } = newTestActions;
const connector = connect((state) => ({
    profileDistribution: getProfileDistribution(state)
}), {
    onChangeThreadGroup: changeRequestGroup,
    onChangeThreadGroupPercent: changeThreadGroupPercent,
    [ThreadGroupAction.Copy]: copyThreadGroup,
    [ThreadGroupAction.Delete]: deleteRequestGroup,
    [ThreadGroupAction.Edit]: editRequestGroup,
    onToggleEnabled: toggleEnabledGroup,
    onToggleLoadProfileSettings: toggleLoadProfileSettings
});
const threadGroupActions = getThreadGroupActions();
const CommonThreadGroupContainer = (_a) => {
    var { onChangeThreadGroup, onChangeThreadGroupPercent, onToggleEnabled, onToggleLoadProfileSettings } = _a, restProps = __rest(_a, ["onChangeThreadGroup", "onChangeThreadGroupPercent", "onToggleEnabled", "onToggleLoadProfileSettings"]);
    const { formatMessage } = useIntl();
    const threadGroupRef = useRef(null);
    const { isExistSelectedData } = useEventSelectListener(threadGroupRef);
    const changeThreadGroup = (field, value) => onChangeThreadGroup({ field, id: restProps.id, value });
    const changeNameHandler = (value) => restProps.name !== value ? changeThreadGroup("name", value) : undefined;
    const changePercentHandler = (value) => Number(restProps.perc) !== Number(value) ? onChangeThreadGroupPercent({ id: restProps.id, value }) : undefined;
    const clickActionHandler = (actionName) => typeof restProps[actionName] === "function"
        ? restProps[actionName]({ id: restProps.id, name: restProps.name })
        : undefined;
    const clickThreadGroupHandler = () => isExistSelectedData() ? undefined : clickActionHandler(ThreadGroupAction.Edit);
    const clickHandler = (event) => stopEvent(event);
    const toggleEnabledHandler = (event) => {
        stopEvent(event);
        return onToggleEnabled(restProps.id);
    };
    const toggleLoadProfileSettingsHandler = (event) => {
        stopEvent(event);
        return onToggleLoadProfileSettings(restProps.id);
    };
    const validateNameFunc = (value) => {
        const { isValid, error } = nameValidator(value);
        return { isValid, error: formatIntlMessage(error, formatMessage) };
    };
    const formattedThreadGroupActions = threadGroupActions.map((_a) => {
        var { titleIntlMessage } = _a, restParams = __rest(_a, ["titleIntlMessage"]);
        return (Object.assign(Object.assign({}, restParams), { title: titleIntlMessage ? formatMessage(titleIntlMessage) : "" }));
    });
    const loadProfileLabel = formatIntlMessage(LOAD_PROFILE_LABEL, formatMessage);
    const unitVU = formatIntlMessage(UNIT_VU, formatMessage);
    return (React.createElement("div", { ref: threadGroupRef },
        React.createElement(CommonThreadGroup, Object.assign({}, restProps, { loadProfileLabel: loadProfileLabel, onActionClick: clickActionHandler, onChangeName: changeNameHandler, onChangePercent: changePercentHandler, onClickThreadGroup: clickThreadGroupHandler, onClick: clickHandler, onToggleEnabled: toggleEnabledHandler, onToggleLoadProfileSettings: toggleLoadProfileSettingsHandler, percInputUnit: UNIT_PERC, ref: threadGroupRef, threadGroupActions: formattedThreadGroupActions, unitVU: unitVU, users: calculateThreadGroupUsersCount(restProps.loadProfileSettings), validateNameFunc: validateNameFunc }))));
};
export default connector(CommonThreadGroupContainer);
