import { getUniqueId } from "@boomq/utils";
import { convertDateToTimestamp } from "../date";
import { TeamMemberPermission } from "../types";
import { TestDetailsAction } from "../../models/testRun";
const actionsByTeamMemberPermissionEdit = [
    TestDetailsAction.ChangeSla,
    TestDetailsAction.CreateTrend,
    TestDetailsAction.Stop
];
const actionsByTeamMemberPermissions = {
    [TeamMemberPermission.Admin]: actionsByTeamMemberPermissionEdit,
    [TeamMemberPermission.Edit]: actionsByTeamMemberPermissionEdit,
    [TeamMemberPermission.Run]: actionsByTeamMemberPermissionEdit
};
export const generateTestTitle = (projectName, startDate) => `${projectName}_${convertDateToTimestamp(startDate)}`;
const getSlaProfileActionsByTeamMemberPermission = (permission) => {
    const actionsByPermission = actionsByTeamMemberPermissions[permission];
    return actionsByPermission ? actionsByPermission : [];
};
const getSlaProfileActionsByTeamMemberPermissions = (permissions) => [
    ...new Set(permissions.reduce((res, permission) => [
        ...res,
        ...getSlaProfileActionsByTeamMemberPermission(permission)
    ], []))
];
export const isAllowedTestDetailsActionByPermissions = (permissionList = [], actionName) => {
    const actionsByPermissions = getSlaProfileActionsByTeamMemberPermissions(permissionList);
    return actionsByPermissions.includes(actionName);
};
export const prepareResultLogFiles = (logFiles) => logFiles.reduce((res, logFile) => (Object.assign(Object.assign({}, res), { [logFile.containerId]: res[logFile.containerId]
        ? [...res[logFile.containerId], Object.assign(Object.assign({}, logFile), { id: getUniqueId() })]
        : [Object.assign(Object.assign({}, logFile), { id: getUniqueId() })] })), {});
