import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getUniqueId, useIntl } from "@boomq/utils";
import { Help } from "@boomq/uikit";
import { DEFAULT_HEADER_HELP_MESSAGE, HEADER_PARAMETER_KEY_PLACEHOLDER, HEADER_PARAMETER_VALUE_PLACEHOLDER } from "./intl";
import { keyValueTheme, RequestHeader } from "./styled";
import { KeyValue } from "../../KeyValue";
import { withResize } from "../../../styled/response";
import { FormType } from "../../../../helpers/types";
import { newRequestChangeParamsField, newRequestDeleteFieldParam } from "../../../../redux/actions/newRequest";
import { getNewRequestField } from "../../../../redux/reducers/newRequest";
import { getComboBoxRequestParametersList, getDefaultHeaders } from "../../../../redux/reducers/newTest";
const getRequestHeaders = (state, mode) => {
    const headersFromStore = getNewRequestField(state, "headers");
    return mode === FormType.View ? headersFromStore : [...headersFromStore, { id: getUniqueId(), key: "", value: "" }];
};
const connector = connect((state, ownProps) => ({
    defaultHeaders: getDefaultHeaders(state),
    requestHeaders: getRequestHeaders(state, ownProps.mode),
    requestParametersList: getComboBoxRequestParametersList(state)
}), { onChangeField: newRequestChangeParamsField, onDeleteField: newRequestDeleteFieldParam });
const HeaderParameters = (props) => {
    const { formatMessage } = useIntl();
    const changeHandler = (val, id) => props.onChangeField({
        field: props.name,
        value: [
            ...props.requestHeaders.map((headerParam) => (headerParam.id === id && Object.assign(Object.assign({}, headerParam), val)) || headerParam)
        ].filter((headerParam) => headerParam.key !== "" || headerParam.value !== "")
    });
    const deleteHandler = (id) => props.onDeleteField({ field: props.name, id });
    const keyPlaceholder = formatMessage(HEADER_PARAMETER_KEY_PLACEHOLDER);
    const valuePlaceholder = formatMessage(HEADER_PARAMETER_VALUE_PLACEHOLDER);
    return (React.createElement(React.Fragment, null,
        props.defaultHeaders.map(({ id, key, value }) => (React.createElement(RequestHeader, { key: id },
            React.createElement(KeyValue, { disabled: true, name: props.name, placeholderKey: keyPlaceholder, placeholderValue: valuePlaceholder, value: { key, value } }),
            React.createElement(Help, { tooltipText: DEFAULT_HEADER_HELP_MESSAGE })))),
        (props.requestHeaders || []).map(({ error, id, key, value }) => (React.createElement(KeyValue, { disabled: props.mode === FormType.View, error: error, key: id, name: props.name, onChange: (val) => changeHandler(val, id), onDelete: () => deleteHandler(id), parameters: props.requestParametersList, placeholderKey: keyPlaceholder, placeholderValue: valuePlaceholder, theme: keyValueTheme, value: { key, value } })))));
};
export default withResize(withRouter(connector(HeaderParameters)));
