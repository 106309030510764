var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { useIntl } from "@boomq/utils";
import { TEST_PROJECT_LABEL_SELECT_PLACEHOLDER } from "./intl";
import { AllTestProjectsPanel } from "../../../components/account/AllTestProjectsPanel";
import { isEmpty } from "../../../helpers/utils";
const AllTestProjectsPanelContainer = (_a) => {
    var { filterLabels = [], labelList = [] } = _a, restProps = __rest(_a, ["filterLabels", "labelList"]);
    const { formatMessage } = useIntl();
    const actionHandler = (actionName, payload) => typeof restProps[actionName] === "function" ? restProps[actionName](payload) : undefined;
    const changeFilter = (filterData) => actionHandler("onChangeFilter", filterData);
    const changeTestProjectLabelFilterHandler = (data) => {
        const value = isEmpty(data) ? [] : data;
        return changeFilter(value);
    };
    const labelSelectPlaceholder = formatMessage(TEST_PROJECT_LABEL_SELECT_PLACEHOLDER);
    return (React.createElement(AllTestProjectsPanel, { filterLabels: filterLabels, labelItems: labelList, labelSelectPlaceholder: labelSelectPlaceholder, onChangeLabelFilter: changeTestProjectLabelFilterHandler, onClearLabelFilter: changeTestProjectLabelFilterHandler }));
};
export default AllTestProjectsPanelContainer;
