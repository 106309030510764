import React from "react";
import { customHintStepTheme, LoadProfileStepName } from "./styled";
import { Hint } from "../../../common/Hint";
import warning from "../../../../assets/images/icons/warn.svg";
export const UltimateThreadGroupStepCaption = (props) => {
    const { changed, name } = props.step || {};
    return (React.createElement(LoadProfileStepName, null,
        name,
        changed && (React.createElement(Hint, { icon: warning, theme: customHintStepTheme, tooltipText: {
                defaultMessage: "Step has settings with parameters as value. Value of this settings replaced by default",
                id: "account.new.test.project.jmx.group.load.profile.parameter.default"
            } })),
        props.children));
};
