import ru from "./ru.json";
import ruAllTests from "./ru_all_tests.json";
import ruAllTestProjects from "./ru_all_test_projects.json";
import ruApiError from "./ru_api_error.json";
import ruReport from "./ru_report.json";
import ruReportChart from "./ru_report_chart.json";
import ruReportTable from "./ru_report_table.json";
import ruReports from "./ru_reports.json";
import ruRequest from "./ru_request.json";
import ruRequestAssertion from "./ru_request_assertion.json";
import ruRequestGroupEditor from "./ru_request_group_editor.json";
import ruSettingsSetSidebar from "./ru_settings_set_sidebar.json";
import ruSla from "./ru_sla.json";
import ruSlaReport from "./ru_sla_report.json";
import ruTeam from "./ru_team.json";
import ruTeams from "./ru_teams.json";
import ruTestProject from "./ru_test_project.json";
import ruTestProjectSettingsSet from "./ru_test_project_settings_set.json";
import ruTime from "./ru_time.json";
import ruWelcome from "./ru_welcome.json";
import en from "./en.json";
import enAllTests from "./en_all_tests.json";
import enAllTestProjects from "./en_all_test_projects.json";
import enApiError from "./en_api_error.json";
import enReport from "./en_report.json";
import enReportChart from "./en_report_chart.json";
import enReportTable from "./en_report_table.json";
import enReports from "./en_reports.json";
import enRequest from "./en_request.json";
import enRequestAssertion from "./en_request_assertion.json";
import enRequestGroupEditor from "./en_request_group_editor.json";
import enSettingsSetSidebar from "./en_settings_set_sidebar.json";
import enSla from "./en_sla.json";
import enSlaReport from "./en_sla_report.json";
import enTeam from "./en_team.json";
import enTeams from "./en_teams.json";
import enTestProject from "./en_test_project.json";
import enTestProjectSettingsSet from "./en_test_project_settings_set.json";
import enTime from "./en_time.json";
import enWelcome from "./en_welcome.json";
const dictionaryRu = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, ru), ruAllTests), ruAllTestProjects), ruApiError), ruReport), ruReportChart), ruReportTable), ruReports), ruRequest), ruRequestAssertion), ruRequestGroupEditor), ruSettingsSetSidebar), ruSla), ruSlaReport), ruTeam), ruTeams), ruTestProject), ruTestProjectSettingsSet), ruTime), ruWelcome);
const dictionaryEn = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, en), enAllTests), enAllTestProjects), enApiError), enReport), enReportChart), enReportTable), enReports), enRequestAssertion), enRequestGroupEditor), enRequest), enSettingsSetSidebar), enSla), enSlaReport), enTeam), enTeams), enTestProject), enTestProjectSettingsSet), enTime), enWelcome);
export { dictionaryRu, dictionaryEn };
