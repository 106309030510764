import styled, { css } from "styled-components";
import { isEmpty } from "@boomq/utils";
import { Color, IconCheck, IconClose, IconEdit, InputIconError, InputText, Select } from "@boomq/uikit";
import { StyledTitle, TitleInput } from "../../../common/EditableTitle";
import { IconSaveAs } from "../../../styled/Icons";
const IconStyles = `
    height: 18px;
    margin: 0 4px;
    width: 18px;
`;
export const StyledIconEdit = styled(IconEdit) `
    cursor: pointer;
    display: none;
    fill: ${Color.darkGrey5};
    height: 18px;
    margin-left: 4px;
    width: 22px;
`;
export const EditableVersionName = styled.div `
    align-items: center;
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 100%;
    ${InputIconError} {
        height: 18px;
        width: 18px;
    }
    ${StyledTitle} {
        cursor: auto;
        display: block;
        font-size: unset;
        font-weight: unset;
        height: auto;
        overflow-x: hidden;
        pointer-events: none;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
        word-break: unset;
    }
    ${TitleInput} {
        background: ${Color.white};
        border: 1px solid ${Color.blue};
        font-size: 16px;
        min-width: 100%;
        padding: 0 56px 0 4px;
    }
    &:hover ${StyledIconEdit} {
        display: ${({ isEdit }) => (isEdit ? "none" : "block")};
    }
`;
export const InputIcons = styled.span `
    display: ${({ error, isEdit }) => (isEdit && !error ? "flex" : "none")};
    position: absolute;
    right: 4px;
    z-index: 999;
`;
export const StyledIconCheck = styled(IconCheck) `
    ${IconStyles}
    fill: ${Color.green};
`;
export const StyledIconClose = styled(IconClose) `
    ${IconStyles}
    fill: ${Color.red};
`;
export const StyledInputText = styled(InputText) `
    border-radius: 12px;
    margin: 0;
    ${({ isEdit }) => isEdit
    ? css `
                  background: ${Color.white};
                  border: 1px solid ${({ error }) => (isEmpty(error) ? Color.blue : Color.red)};
                  font-size: 16px;
                  min-width: 100%;
                  padding: 0 56px 0 4px;
              `
    : css `
                  background: transparent !important;
                  box-shadow: none !important;
                  padding: 0 0 0 2px;
                  pointer-events: none;
                  & ~ fieldset {
                      border: none;
                  }
              `}
`;
export const StyledSaveAs = styled(IconSaveAs) `
    cursor: pointer;
    fill: ${Color.blue};
    height: 22px;
    margin-left: 4px;
    min-width: 30px;
    width: 30px;
`;
export const StyledSelect = styled(Select) `
    max-width: 350px;
    min-width: 150px;
    width: calc(100% - 30px);
`;
export const TestProjectVersion = styled.div `
    align-items: center;
    display: flex;
    position: absolute;
    left: 24px;
    max-width: calc(50% - 160px);
    width: 100%;
`;
