export var ExtractorMatchType;
(function (ExtractorMatchType) {
    ExtractorMatchType["All"] = "ALL_MATCHES";
    ExtractorMatchType["Specific"] = "SPECIFIC_MATCH";
    ExtractorMatchType["Random"] = "RANDOM_MATCH";
})(ExtractorMatchType || (ExtractorMatchType = {}));
export var ExtractorFieldToCheck;
(function (ExtractorFieldToCheck) {
    ExtractorFieldToCheck["Body"] = "BODY";
    ExtractorFieldToCheck["ResponseHeaders"] = "RESPONSE_HEADERS";
    ExtractorFieldToCheck["RequestHeaders"] = "REQUEST_HEADERS";
    ExtractorFieldToCheck["Url"] = "URL";
    ExtractorFieldToCheck["ResponseCode"] = "RESPONSE_CODE";
    ExtractorFieldToCheck["ResponseMessage"] = "RESPONSE_MESSAGE";
})(ExtractorFieldToCheck || (ExtractorFieldToCheck = {}));
export var ExtractorScope;
(function (ExtractorScope) {
    ExtractorScope["All"] = "ALL";
    ExtractorScope["Children"] = "CHILDREN";
    ExtractorScope["Parent"] = "PARENT";
    ExtractorScope["Variable"] = "VARIABLE";
})(ExtractorScope || (ExtractorScope = {}));
export var ExtractorType;
(function (ExtractorType) {
    ExtractorType["RegEx"] = "REGEXP";
    ExtractorType["JsonPath"] = "JSON";
    ExtractorType["XPath"] = "XPATH";
    ExtractorType["XPath2"] = "XPATH2";
    ExtractorType["Boundary"] = "BOUNDARY";
})(ExtractorType || (ExtractorType = {}));
