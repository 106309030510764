export const LOAD_PROFILE_LABEL = {
    defaultMessage: "Load profile",
    id: "account.test.profile.thread.group.load.profile.text"
};
const UNIT_PERC_TEXT = {
    defaultMessage: "%",
    id: "account.test.profile.thread.group.unit.%.text"
};
const UNIT_PERC_TITLE = {
    defaultMessage: "%",
    id: "account.test.profile.thread.group.unit.%.title"
};
export const UNIT_PERC = { text: UNIT_PERC_TEXT, title: UNIT_PERC_TITLE };
export const UNIT_VU = {
    defaultMessage: "vu",
    id: "account.test.profile.thread.group.unit.VU.text"
};
