var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { stopEvent } from "@boomq/utils";
import { GridItem, GridRow, GridRowWithLink } from "./styled";
import { DefaultItemRowTemplate } from "./DefaultItemRowTemplate";
export const DefaultItemTemplate = (_a) => {
    var { data, onClickItem } = _a, restProps = __rest(_a, ["data", "onClickItem"]);
    const clickHandler = (event) => {
        stopEvent(event);
        return typeof onClickItem === "function" ? onClickItem(data) : undefined;
    };
    return data && data.link ? (React.createElement(GridItem, { onClick: clickHandler },
        React.createElement(GridRowWithLink, { to: data.link, windowWidth: restProps.windowWidth },
            React.createElement(DefaultItemRowTemplate, Object.assign({ data: data }, restProps))))) : (React.createElement(GridRow, { onClick: clickHandler, windowWidth: restProps.windowWidth },
        React.createElement(DefaultItemRowTemplate, Object.assign({ data: data }, restProps))));
};
