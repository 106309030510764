export var Mode;
(function (Mode) {
    Mode["Edit"] = "EDIT";
    Mode["EditResult"] = "EDIT_RESULT";
    Mode["View"] = "VIEW";
})(Mode || (Mode = {}));
export var SlaOperation;
(function (SlaOperation) {
    SlaOperation["GreaterThen"] = "GREATER_THEN";
    SlaOperation["LessEquals"] = "LESS_EQUALS";
})(SlaOperation || (SlaOperation = {}));
export var SlaRestrictionType;
(function (SlaRestrictionType) {
    SlaRestrictionType["Average"] = "AVERAGE";
    SlaRestrictionType["CpuUsagePercentAverage"] = "CPU_USAGE_PERCENT_AVERAGE";
    SlaRestrictionType["ErrorRate"] = "ERROR_RATE";
    SlaRestrictionType["MemoryUsagePercentAverage"] = "MEMORY_USAGE_PERCENT_AVERAGE";
    SlaRestrictionType["Percentile"] = "PERCENTILE_95";
    SlaRestrictionType["Throughput"] = "THROUGHPUT";
})(SlaRestrictionType || (SlaRestrictionType = {}));
export var SlaUnit;
(function (SlaUnit) {
    SlaUnit["AVERAGE"] = "sec";
    SlaUnit["CPU_USAGE_PERCENT_AVERAGE"] = "%";
    SlaUnit["ERROR_RATE"] = "%";
    SlaUnit["MEMORY_USAGE_PERCENT_AVERAGE"] = "%";
    SlaUnit["PERCENTILE_95"] = "sec";
    SlaUnit["THROUGHPUT"] = "rps";
    SlaUnit["THROUGHPUT_TRANSACTION"] = "tps";
})(SlaUnit || (SlaUnit = {}));
export var SlaStatus;
(function (SlaStatus) {
    SlaStatus["Success"] = "SUCCESS";
    SlaStatus["Failure"] = "FAILURE";
    SlaStatus["NotCounted"] = "NOT_COUNTED";
})(SlaStatus || (SlaStatus = {}));
export var SlaTargetType;
(function (SlaTargetType) {
    SlaTargetType["Request"] = "REQUEST";
    SlaTargetType["SystemMetricsHost"] = "SYSTEM_METRICS_HOST";
    SlaTargetType["Test"] = "TEST";
    SlaTargetType["ThreadGroup"] = "THREAD_GROUP";
    SlaTargetType["Transaction"] = "TRANSACTION";
})(SlaTargetType || (SlaTargetType = {}));
export var SlaType;
(function (SlaType) {
    SlaType["Request"] = "REQUEST_SLA";
    SlaType["SystemMetricsHost"] = "SYSTEM_METRICS_HOST";
    SlaType["Test"] = "GENERAL_TEST_SLA";
    SlaType["ThreadGroup"] = "THREAD_GROUP_SLA";
    SlaType["Transaction"] = "TRANSACTION_SLA";
})(SlaType || (SlaType = {}));
export var SlaTransactionFilterType;
(function (SlaTransactionFilterType) {
    SlaTransactionFilterType["AnyTransaction"] = "ANY_TRANSACTION";
    SlaTransactionFilterType["NoTransaction"] = "NO_TRANSACTION";
    SlaTransactionFilterType["Name"] = "BY_NAME";
})(SlaTransactionFilterType || (SlaTransactionFilterType = {}));
export var SlaThreadGroupFilterType;
(function (SlaThreadGroupFilterType) {
    SlaThreadGroupFilterType["AnyThreadGroup"] = "ANY_THREAD_GROUP";
})(SlaThreadGroupFilterType || (SlaThreadGroupFilterType = {}));
