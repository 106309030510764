import { isEmpty, getValueByPath } from "@boomq/utils";
import { INCORRECT_REQUIRED_FIELD, INCORRECT_VALUE_BY_ISO_8859_1 } from "./intl";
export const isExistNonISO88591Character = (str) => /[^\u0000-\u00ff]/g.test(str);
export const valueValidatorByISO88591 = (str) => {
    const isValid = !isExistNonISO88591Character(str);
    return {
        isValid,
        error: isValid ? undefined : INCORRECT_VALUE_BY_ISO_8859_1
    };
};
export const modifyErrorMap = (data, paramName, errorMessage) => (Object.assign(Object.assign({}, (data || {})), { error: Object.assign(Object.assign({}, getValueByPath(data, "error", {})), { [paramName]: errorMessage }) }));
const validatorByFieldValue = (data, fieldName, fieldValue) => isEmpty(fieldValue)
    ? {
        isValid: false,
        validatedData: modifyErrorMap(data, fieldName, INCORRECT_REQUIRED_FIELD)
    }
    : {
        validatedData: modifyErrorMap(data, fieldName)
    };
const validatorByField = (res, fieldName, fieldValue) => {
    const validatedData = validatorByFieldValue(res.validatedData, fieldName, fieldValue);
    return Object.assign(Object.assign({}, res), validatedData);
};
export const validatorByRequiredFields = (data, fieldNames) => (fieldNames || []).reduce((res, fieldName) => validatorByField(res, fieldName, getValueByPath(data, fieldName)), {
    isValid: true,
    validatedData: data
});
