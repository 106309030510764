import styled from "styled-components";
export const TooltipBody = styled.div `
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 10px;
`;
export const TooltipItem = styled.li `
    color: ${(props) => props.color};
    list-style: none;
    padding-top: 4px;
    padding-bottom: 4px;
`;
export const AnomalyDescription = styled.p `
    color: ${(props) => props.color};
`;
