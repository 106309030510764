import React, { useState } from "react";
import { round } from "@boomq/utils";
import { TypeSlider as CommonTypeSlider } from "@boomq/common";
import { TypeSliderInputType } from "../../../helpers/types";

const getStateMaxValue = (max, value) => (max < value ? round(1.1 * value, 1) : max);

const TypeSlider = (props) => {
    const slider = props.slider || {};

    const [maxValue, setMaxValue] = useState(getStateMaxValue(slider.max, slider.value));

    const changeSliderMaxValue = (value, type) =>
        type === TypeSliderInputType.Input ? setMaxValue(getStateMaxValue(slider.max, value)) : undefined;

    const changeHandler = (name, value, type) => {
        changeSliderMaxValue(value, type);

        return typeof props.changeHandler === "function" ? props.changeHandler({ field: name, value }) : undefined;
    };

    return <CommonTypeSlider {...props} changeHandler={changeHandler} slider={{ ...slider, max: maxValue }} />;
};

export default TypeSlider;
