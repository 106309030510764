import React, { Component } from "react";
import { connect } from "react-redux";
import { ButtonWrapper, Input, InputWrapper, ResourceParams, Text } from "../styled/admin";
import { AdminButton, ItemPlate } from "../../routes/AdminPage";
import { changeResourceRequest, deleteResourceRequest } from "../../../redux/actions/admin/resources";
const connector = connect(null, { changeResourceRequest, deleteResourceRequest });
class ResourceItem extends Component {
    constructor() {
        super(...arguments);
        this.state = Object.assign({}, this.props);
        this.changeHandler = (key, value) => this.setState({ isDirty: true, [key]: value });
    }
    render() {
        const { id, ip, name, location, port, cpuCount, ram, isDirty } = this.state;
        return (React.createElement(ItemPlate, null,
            React.createElement(ResourceParams, null,
                React.createElement(InputWrapper, null,
                    React.createElement(Text, null, "name:"),
                    React.createElement(Input, { type: "text", name: "name", value: name, onChange: ({ target }) => this.changeHandler(target.name, target.value) })),
                React.createElement(InputWrapper, null,
                    React.createElement(Text, null, "location:"),
                    React.createElement(Input, { type: "text", name: "location", value: location, onChange: ({ target }) => this.changeHandler(target.name, target.value) })),
                React.createElement(InputWrapper, null,
                    React.createElement(Text, null, "ip:"),
                    React.createElement(Input, { type: "text", name: "ip", value: ip, onChange: ({ target }) => this.changeHandler(target.name, target.value) })),
                React.createElement(InputWrapper, null,
                    React.createElement(Text, null, "port:"),
                    React.createElement(Input, { type: "number", name: "port", value: port, onChange: ({ target }) => this.changeHandler(target.name, target.value) })),
                React.createElement(InputWrapper, null,
                    React.createElement(Text, null, "CPU(core count):"),
                    React.createElement(Input, { type: "number", name: "cpuCount", value: cpuCount, onChange: ({ target }) => this.changeHandler(target.name, target.value) })),
                React.createElement(InputWrapper, null,
                    React.createElement(Text, null, "RAM(Mb):"),
                    React.createElement(Input, { type: "number", name: "ram", value: ram, onChange: ({ target }) => this.changeHandler(target.name, target.value) }))),
            React.createElement(ButtonWrapper, null,
                isDirty && (React.createElement(AdminButton, { bg: "#6FCF97", onClick: () => {
                        this.props.changeResourceRequest({
                            id,
                            params: {
                                ip,
                                name,
                                location,
                                port,
                                cpuCount,
                                ram
                            }
                        });
                        this.setState({ isDirty: false });
                    } }, "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C")),
                React.createElement(AdminButton, { onClick: () => this.props.deleteResourceRequest({ id }) }, "\u0423\u0434\u0430\u043B\u0438\u0442\u044C"))));
    }
}
export default connector(ResourceItem);
