import { connect } from "react-redux";
import { getCurrentStep, getTestType } from "@boomq/common";
import { TestProjectSettingsWizard } from "../../../../components/account/TestProjectSettingsWizard";

const connector = connect((state) => ({
    currentStep: getCurrentStep(state),
    testType: getTestType(state)
}));

export default connector(TestProjectSettingsWizard);
