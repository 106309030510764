import React, { FC, useState } from "react";
import { FormattedMessage } from "react-intl";
import { isEmptyArray } from "@boomq/utils";
import { Checkbox } from "@boomq/uikit";
import {
    TeamMembersItemTemplateEdit as CommonTeamMembersItemTemplateEdit,
    TeamMembersItemTemplateEditorProps,
    TeamMembersItemTemplateEditHandlers as CommonTeamMembersItemTemplateEditHandlers,
    excludeItemFromArray,
    formatEmail
} from "@boomq/common";
import { TEAM_MEMBER_MANAGE_USERS_TITLE } from "./intl";
import { ManageUsersBlock, ManageUsersPermissions, ManageUsersTitle, TeamMembersItemInputsBlock } from "./styled";
import { prepareChangedPermissionList } from "../../../../helpers/teams";
import { ListItem, Role, TeamMemberMode, TeamMemberPermission } from "../../../../helpers/types";

export interface PermissionsProps {
    manageUsersItems: ListItem<TeamMemberPermission, { disabled?: boolean }>[];
    manageUsersPermissionList: TeamMemberPermission[];
}

export interface PermissionsHandlers {
    onToggle: ({ value }: { value: TeamMemberPermission }) => void;
}

export interface TeamMembersItemTemplateEditProps extends TeamMembersItemTemplateEditorProps, PermissionsProps {
    isExistAdminAccess?: boolean;
    manageUsersItems: ListItem<TeamMemberPermission, { disabled?: boolean }>[];
    permissionItems: ListItem<TeamMemberPermission>[];
    roles?: Role[];
    teamId: string;
    userId: string;
}

export interface TeamMembersItemTemplateEditHandlers
    extends CommonTeamMembersItemTemplateEditHandlers,
    PermissionsHandlers {
    onToggleMode: (mode: TeamMemberMode) => void;
}

const getManageUsersPermissionsStateValue = (permissionList: any[]): any[] =>
    Array.isArray(permissionList) ? permissionList : [];

const isCheckedPermission = (permissions, permissionItem): boolean => permissions.includes(permissionItem.value);

const renderManageUsersPermissions = ({
    manageUsersItems,
    manageUsersPermissionList = [],
    onToggle
}: PermissionsProps & PermissionsHandlers): JSX.Element | JSX.Element[] =>
    isEmptyArray(manageUsersItems) ? (
        <></>
    ) : (
        <ManageUsersPermissions>
            {(manageUsersItems || []).map((manageUsersItem) => (
                <Checkbox
                    key={manageUsersItem.value}
                    checked={isCheckedPermission(manageUsersPermissionList, manageUsersItem)}
                    disabled={manageUsersItem.disabled}
                    label={manageUsersItem.text}
                    name={manageUsersItem.value}
                    onToggle={onToggle}
                    value={manageUsersItem.value}
                />
            ))}
        </ManageUsersPermissions>
    );

export const TeamMembersItemTemplateEdit: FC<
TeamMembersItemTemplateEditProps & TeamMembersItemTemplateEditHandlers
> = ({
    manageUsersItems,
    onToggleMode,
    teamId,
    userId,
    ...restProps
}: TeamMembersItemTemplateEditProps & TeamMembersItemTemplateEditHandlers): JSX.Element => {
    const [manageUsersPermissionList, setManageUsersPermissionList] = useState(
        getManageUsersPermissionsStateValue(restProps.permissionList)
    );

    // useEffect(() => {
    //     setManageUsersPermissionList(getManageUsersPermissionsStateValue(restProps.permissionList));
    // }, [restProps.permissionList]);

    const clickCancelButtonHandler = (): void =>
        typeof onToggleMode === "function" ? onToggleMode(TeamMemberMode.View) : undefined;

    const clickSaveButtonHandler = ({ email, permissionList, userDisplayName }): void => {
        clickCancelButtonHandler();

        return typeof restProps.onSave === "function"
            ? restProps.onSave({
                teamId,
                data: {
                    id: restProps.id,
                    userId,
                    teamId,
                    email: formatEmail(email),
                    permissionList: prepareChangedPermissionList(permissionList, manageUsersPermissionList),
                    userDisplayName
                }
            })
            : undefined;
    };

    const addPermission = (permission): void =>
        setManageUsersPermissionList([...manageUsersPermissionList, permission]);

    const excludePermission = (permission): void =>
        setManageUsersPermissionList(excludeItemFromArray(manageUsersPermissionList, permission));

    const toggleManageUsersPermissionHandler = ({ value }): void =>
        manageUsersPermissionList.includes(value) ? excludePermission(value) : addPermission(value);

    return (
        <TeamMembersItemInputsBlock>
            <CommonTeamMembersItemTemplateEdit
                {...restProps}
                onCancel={clickCancelButtonHandler}
                onSave={clickSaveButtonHandler}
            >
                <ManageUsersBlock>
                    <ManageUsersTitle>
                        <FormattedMessage {...TEAM_MEMBER_MANAGE_USERS_TITLE} />
                    </ManageUsersTitle>

                    {renderManageUsersPermissions({
                        manageUsersItems,
                        manageUsersPermissionList,
                        onToggle: toggleManageUsersPermissionHandler
                    })}
                </ManageUsersBlock>
            </CommonTeamMembersItemTemplateEdit>
        </TeamMembersItemInputsBlock>
    );
};
