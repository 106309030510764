var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import styled from "styled-components";
import { Fade } from "../../animation";
const ErrorPlate = (_a) => {
    var { children } = _a, restProps = __rest(_a, ["children"]);
    return (React.createElement(Fade, { animation: "inRight" },
        React.createElement(Plate, Object.assign({}, restProps),
            React.createElement(Text, null, children))));
};
const Plate = styled.div `
    background: #ffffff;
    box-shadow: 0 0 15px rgba(235, 87, 87, 0.7);
    border-radius: 5px;
    padding: 11px 29px;
    margin-top: 23px;
    width: 450px;
`;
const Text = styled.p `
    text-align: center;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    font-size: 16px;
    color: #eb5757;
`;
export default ErrorPlate;
