var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { select, put, takeEvery } from "redux-saga/effects";
import { generateMergedGroupsPreviewData, generatePreviewData } from "../../helpers/generators";
import { getTestTotalDuration } from "../../helpers/generators/generators";
import { TestType } from "../../helpers/types";
import newTestActions from "../actions/newTest";
import { getIsMergedChartThreadGroups, getRequestGroups, getThreadGroupLoadProfileSettingsByGroupId, getPreviewThreadGroupsChartTimeline } from "../reducers/newTest";
const { changePreviewThreadGroupsChartField, refreshThreadGroupsChartPreview, testProjectChangeParamsField, threadGroupChangeParamsField, threadGroupChangeLoadProfileParams } = newTestActions;
function* generatePreviewChartTimeLine() {
    try {
        const groups = yield select(getRequestGroups);
        const timeLineValues = (groups || []).reduce((res, { loadProfileSettings }) => [...res, getTestTotalDuration(loadProfileSettings)], []);
        const startTimeLine = 0;
        const endTimeLine = timeLineValues.length > 0 ? Math.max(...timeLineValues) : 0;
        yield put(changePreviewThreadGroupsChartField({
            field: "timeLine",
            value: { min: startTimeLine, max: endTimeLine, value: [startTimeLine, endTimeLine] }
        }));
    }
    catch (e) {
        console.error(e);
    }
}
function* threadGroupChartPreviewGenerator(action) {
    const { groupId } = action && action.payload ? action.payload : {};
    const loadProfileSettingsData = yield select(getThreadGroupLoadProfileSettingsByGroupId, groupId);
    const value = [{ coords: generatePreviewData(Object.assign(Object.assign({}, loadProfileSettingsData), { loadType: TestType.Scalability })) }];
    yield put(threadGroupChangeParamsField({ field: "chartData", groupId, value }));
}
const prepareGroupStepLoadProfile = (groupId, _a, durationAddedOnLastStep, index) => {
    var { stepLength, usersPerStep } = _a, restParams = __rest(_a, ["stepLength", "usersPerStep"]);
    return (Object.assign(Object.assign({}, restParams), { groupId, initialDelay: index * (restParams.rampUp + stepLength), stepLength: stepLength + durationAddedOnLastStep, usersPerStep: (index + 1) * usersPerStep }));
};
const prepareGroupStepsLoadProfile = (groupId, _a) => {
    var { durationAddedOnLastStep, stepCount } = _a, restParams = __rest(_a, ["durationAddedOnLastStep", "stepCount"]);
    return Array(stepCount)
        .fill(restParams)
        .map((loadProfileSettings, index) => index === stepCount - 1
        ? prepareGroupStepLoadProfile(groupId, loadProfileSettings, durationAddedOnLastStep, index)
        : prepareGroupStepLoadProfile(groupId, loadProfileSettings, 0, index));
};
function* generatePreviewMergedGroupsChart(groups, timeline) {
    const mergedGroups = (groups || []).reduce((res, { id, loadProfileSettings }) => [...res, ...prepareGroupStepsLoadProfile(id, loadProfileSettings)], []);
    const chartData = [
        {
            coords: generateMergedGroupsPreviewData(mergedGroups, timeline.value, true),
            params: {}
        }
    ];
    yield put(testProjectChangeParamsField({
        field: "chartData",
        value: chartData
    }));
}
function* generatePreviewGroupsChart(groups, timeline) {
    const chartData = groups.map(({ id, loadProfileSettings }) => ({
        coords: generateMergedGroupsPreviewData(prepareGroupStepsLoadProfile(id, loadProfileSettings), timeline.value),
        params: {}
    }));
    yield put(testProjectChangeParamsField({
        field: "chartData",
        value: chartData
    }));
}
function* generatePreviewChartWithTimeline({ payload }) {
    try {
        const { field, value } = payload || {};
        const isMergedChartGroups = field === "isMergedChartGroups" ? value : yield select(getIsMergedChartThreadGroups);
        const timeline = field === "timeLine" ? value : yield select(getPreviewThreadGroupsChartTimeline);
        const groups = yield select(getRequestGroups);
        return isMergedChartGroups
            ? yield* generatePreviewMergedGroupsChart(groups, timeline)
            : yield* generatePreviewGroupsChart(groups, timeline);
    }
    catch (e) {
        console.error(e);
    }
}
export function* testProjectUsersDistributionFlow() {
    yield takeEvery(changePreviewThreadGroupsChartField, generatePreviewChartWithTimeline);
    yield takeEvery(refreshThreadGroupsChartPreview, generatePreviewChartTimeLine);
    yield takeEvery(threadGroupChangeLoadProfileParams, threadGroupChartPreviewGenerator);
}
