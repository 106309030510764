export const BUTTON_APPLY_LABEL = {
    defaultMessage: "Apply",
    id: "account.modal.timers.settings.button"
};
export const DEFAULT_HEADER_KEY_PLACEHOLDER = {
    defaultMessage: "Key",
    id: "account.default.headers.key.placeholder"
};
export const DEFAULT_HEADER_VALUE_PLACEHOLDER = {
    defaultMessage: "Value",
    id: "account.default.headers.value.placeholder"
};
