export const SETTINGS_SETS_SIDEBAR_ACTIONS_LINK = {
    defaultMessage: "Link",
    id: "account.settings.set.sidebar.actions.link"
};
export const SETTINGS_SETS_SIDEBAR_ACTIONS_UNLINK = {
    defaultMessage: "Unlink",
    id: "account.settings.set.sidebar.actions.unlink"
};
export const SETTINGS_SETS_SIDEBAR_FILTER_NAME = {
    defaultMessage: "Settings name",
    id: "account.settings.set.sidebar.filter.name"
};
export const SETTINGS_SETS_SIDEBAR_TITLE = {
    defaultMessage: "{projectName} settings",
    id: "account.settings.set.sidebar.title"
};
