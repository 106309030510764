import styled from "styled-components";
import { Color } from "@boomq/uikit";
export const TooltipLinkContainer = styled.div `
    background-color: ${Color.white};
    border-radius: 5px;
    box-shadow: 0 0 8px ${Color.grey65};
    left: ${({ left }) => left}px;
    padding: 5px;
    position: absolute;
    top: ${({ top }) => top}px;
    transform: translateX(-35%);
    &:after {
        content: "";
        bottom: -9px;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid ${Color.white};
        height: 0;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        width: 0;
    }
    &:before {
        content: "";
        bottom: -11px;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-top: 12px solid rgba(204, 204, 204, 0.3);
        left: 50%;
        position: absolute;
        height: 0;
        transform: translateX(-50%);
        width: 0;
    }
`;
export const StyledLink = styled.a `
    color: ${Color.grey65};
    cursor: pointer;
    text-decoration: none;
    &:hover {
        color: ${Color.blue};
    }
`;
