import { IconRefresh } from "./IconRefresh";
import { IconTableOfContents } from "./IconTableOfContents";
import { NO_HEADER_BLOCKS_TEXT, SETTINGS_REFRESH_BUTTON_LABEL } from "./intl";
import { HEADER_LEVELS } from "../../../../models/reportEditor/constants";
import "./pflbTableOfContents.css";
export class PFLBTableOfContents {
    constructor({ api, block, data }) {
        this.CSS = {
            list: "pflb-table-of-contents",
            listItem: "pflb-toc-item",
            listItemMarginPrefix: "pflb-toc-margin-level"
        };
        this.api = api;
        this.blockApi = block;
        this.data = data;
        this.i18n = api.i18n;
    }
    static get toolbox() {
        return {
            title: "PFLB Table of contents",
            icon: IconTableOfContents
        };
    }
    render() {
        var _a;
        return ((_a = this.data) === null || _a === void 0 ? void 0 : _a.items) ? this.generateTableOfContentsByData(this.data.items) : this.generateTableOfContents();
    }
    generateTableOfContents() {
        const headingBlocks = this.getHeadingBlocks();
        this.data = {
            items: headingBlocks
        };
        return this.generateTableOfContentsByData(headingBlocks);
    }
    generateTableOfContentsByData(items) {
        const tableOfContentsElements = this.generateTableOfContentsElements(items);
        const list = document.createElement("ul");
        list.classList.add(this.CSS.list);
        return this.addListItems(list, tableOfContentsElements);
    }
    getHeadingBlocks() {
        var _a;
        const blocksCount = this.api.blocks.getBlocksCount();
        const headingBlocksData = Array(blocksCount)
            .fill("")
            .map((block, index) => this.api.blocks.getBlockByIndex(index))
            .filter((blockData) => (blockData === null || blockData === void 0 ? void 0 : blockData.name) === "header")
            .map((blockData) => this.formatHeadingBlockData(blockData));
        const { blocks } = headingBlocksData.reduce((res, blockData, index) => {
            let marginLevel = blockData.level > res.prevLevel ? res.marginLevel + 1 : res.marginLevel;
            marginLevel =
                blockData.level < res.prevLevel
                    ? this.getPrevLevel(res.blocks, index, marginLevel, blockData.level)
                    : marginLevel;
            marginLevel = blockData.level === 1 ? 1 : marginLevel;
            return {
                blocks: [
                    ...res.blocks,
                    Object.assign(Object.assign({}, blockData), { marginLevel })
                ],
                prevLevel: blockData.level,
                marginLevel
            };
        }, { blocks: [], marginLevel: 1, prevLevel: (_a = headingBlocksData === null || headingBlocksData === void 0 ? void 0 : headingBlocksData[0]) === null || _a === void 0 ? void 0 : _a.level });
        return blocks;
    }
    getPrevLevel(res, index, marginLevel, blockLevel) {
        const prevEl = res[index - 1];
        return (prevEl === null || prevEl === void 0 ? void 0 : prevEl.level) === 1 || index < 0 ? marginLevel : this.checkLevel(res, index, marginLevel, blockLevel);
    }
    checkLevel(res, index, marginLevel, blockLevel) {
        const prevEl = res[index - 1];
        return (prevEl === null || prevEl === void 0 ? void 0 : prevEl.level) === blockLevel
            ? prevEl.marginLevel
            : this.getPrevLevel(res, index - 1, marginLevel, blockLevel);
    }
    generateTableOfContentsElements(items) {
        return items.map(({ blockId, marginLevel, text }) => {
            const link = document.createElement("a");
            const listItem = document.createElement("li");
            link.href = `#${blockId}`;
            link.innerText = text !== null && text !== void 0 ? text : "";
            listItem.classList.add(this.CSS.listItem, `${this.CSS.listItemMarginPrefix}${marginLevel}`);
            listItem.setAttribute("contenteditable", "true");
            listItem.appendChild(link);
            return listItem;
        });
    }
    addListItems(parentElement, listItems) {
        listItems.forEach((element) => {
            parentElement.appendChild(element);
        });
        return listItems.length > 0 ? parentElement : this.addEmptyListItem(parentElement);
    }
    addEmptyListItem(parentElement) {
        const emptyListItem = document.createElement("li");
        emptyListItem.classList.add(this.CSS.listItem);
        emptyListItem.innerText = this.i18n.t(NO_HEADER_BLOCKS_TEXT);
        parentElement.appendChild(emptyListItem);
        return parentElement;
    }
    formatHeadingBlockData(blockData) {
        var _a;
        return {
            blockId: blockData === null || blockData === void 0 ? void 0 : blockData.id,
            level: this.getHeadingLevelFromBlockData(blockData),
            text: (_a = blockData === null || blockData === void 0 ? void 0 : blockData.holder) === null || _a === void 0 ? void 0 : _a.innerText
        };
    }
    getHeadingLevelFromBlockData(blockData) {
        const level = HEADER_LEVELS.filter((headerLevelNumber) => blockData.holder.innerHTML.includes(`<h${headerLevelNumber}`));
        return (level === null || level === void 0 ? void 0 : level[0]) || (HEADER_LEVELS === null || HEADER_LEVELS === void 0 ? void 0 : HEADER_LEVELS[0]);
    }
    renderSettings() {
        return [
            {
                label: this.i18n.t(SETTINGS_REFRESH_BUTTON_LABEL),
                icon: IconRefresh,
                closeOnActivate: true,
                onActivate: () => this.refreshTableOfContents()
            }
        ];
    }
    refreshTableOfContents() {
        const newData = this.getHeadingBlocks();
        return this.api.blocks.update(this.blockApi.id, { items: newData });
    }
    save() {
        return this.data;
    }
}
