import { connect } from "react-redux";
import { isEmpty } from "@boomq/utils";
import { ImportParametersCsv } from "../../../../components/account/ImportParametersCsv";
import { generateCsvParameterDefaultItem } from "../../../../helpers/requestParameters";
import { changeRequestParameterCsvHeader, changeRequestParametersField, downloadTestProjectCsvFileRequest, uploadCsvFileRequest } from "../../../../redux/actions/requestParameters";
import { getAllUsedRequestParameterNames } from "../../../../redux/reducers/newTest";
import { getRequestParametersImportCsv } from "../../../../redux/reducers/requestParameters";
const getParamtersCsv = (state) => {
    const csvParameters = getRequestParametersImportCsv(state);
    return isEmpty(csvParameters) || !isEmpty(csvParameters[0].files)
        ? [generateCsvParameterDefaultItem(), ...csvParameters]
        : csvParameters;
};
const connector = connect((state) => ({
    requestParametersCsv: getParamtersCsv(state),
    usedRequestParameterNames: getAllUsedRequestParameterNames(state)
}), {
    onChangeCsvHeader: changeRequestParameterCsvHeader,
    onChangeField: changeRequestParametersField,
    onClickDownloadCsvFile: downloadTestProjectCsvFileRequest,
    onUploadCsvFile: uploadCsvFileRequest
});
export default connector(ImportParametersCsv);
