import { UltimateThreadGroupStepCaption } from "../../components/account/TestProject/UltimateThreadGroupStepCaption";
import { UltimateThreadGroupStepCaptionEditMode } from "../../components/account/TestProject/UltimateThreadGroupStepCaptionEditMode";
import { RequestGroupType } from "../types";
import { Mode } from "../../models/sla";
const DEFAULT_THREAD_GROUP_TYPE = RequestGroupType.SimpleThreadGroup;
const DEFAULT_THREAD_GROUP_MODE = Mode.Edit;
const threadGroupCaptionTemplates = {};
const ultimateThreadGroupStepCaptionTemplates = {
    [Mode.View]: UltimateThreadGroupStepCaption,
    [Mode.Edit]: UltimateThreadGroupStepCaptionEditMode
};
const mergeTemplatesWithDefault = (templates = {}) => (Object.assign(Object.assign({}, threadGroupCaptionTemplates), templates));
const getThreadGroupKeyTypeByType = (templates, type) => Object.keys(templates).includes(type) ? type : DEFAULT_THREAD_GROUP_TYPE;
const getThreadGroupModeObjByType = (templates, type) => templates[getThreadGroupKeyTypeByType(templates, type)];
const getThreadGroupKeyModeFromObjByMode = (threadGroupObj, mode) => Object.keys(threadGroupObj).includes(mode) ? mode : DEFAULT_THREAD_GROUP_MODE;
const getThreadGroupComponentFromObjByMode = (threadGroupObj, mode) => threadGroupObj[getThreadGroupKeyModeFromObjByMode(threadGroupObj, mode)];
export const getThreadGroupTitleTemplateByTypeAndMode = (type, mode, templates) => {
    const threadGroupObj = getThreadGroupModeObjByType(mergeTemplatesWithDefault(templates), type);
    return getThreadGroupComponentFromObjByMode(threadGroupObj, mode);
};
const getUltimateThreadGroupKeyModeByMode = (mode) => Object.keys(ultimateThreadGroupStepCaptionTemplates).includes(mode) ? mode : DEFAULT_THREAD_GROUP_MODE;
export const getUltimateThreadGroupStepCaptionTemplateByMode = (mode) => ultimateThreadGroupStepCaptionTemplates[getUltimateThreadGroupKeyModeByMode(mode)];
