import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
    closeSiteModal,
    getClientPermissionList,
    getProfileDistribution,
    getRequests,
    getRequestGroups,
    getSiteModal,
    getSlaData,
    getSlaWarning,
    getTestProjectSettingsField,
    getThreadGroupsRequests,
    newTestActions,
    testProfileValidator
} from "@boomq/common";
import { TestSettings } from "../../../../components/account/TestProject/TestSettings";
import { TestType } from "../../../../helpers/types";

const { testProjectChangeParamsField } = newTestActions;

const getLoadProfileParams = (state, ownProps, testType) => ({
    loadProfileData:
        testType === TestType.Scalability
            ? getTestProjectSettingsField(state, TestType.Scalability)
            : getTestProjectSettingsField(state, TestType.Stable),
    settings: ownProps.testSettings ? ownProps.testSettings[testType] : []
});

const getProps = (state, ownProps) => {
    const groups = getRequestGroups(state);
    const testType = getTestProjectSettingsField(state, "testType");
    const requests = testType === TestType.JMX ? getRequests(state) : getThreadGroupsRequests(state);
    const slaData = getSlaData(state);
    const profileDistribution = getProfileDistribution(state);

    const { loadProfileData, settings } = getLoadProfileParams(state, ownProps, testType);

    return {
        loadProfileData,
        loadProfileError: testProfileValidator({ profileDistribution, requests, testType, threadGroups: groups }),
        profileDistribution,
        settings,
        slaData,
        slaWarn: getSlaWarning(testType, { groups, profileDistribution, ...loadProfileData }, slaData),
        testType
    };
};

const connector = connect(
    (state, ownProps) => ({ permissionList: getClientPermissionList(state), ...getProps(state, ownProps) }),
    {
        closeSiteModal,
        getSiteModal,
        testProjectChangeParamsField
    }
);

export default withRouter(connector(TestSettings));
