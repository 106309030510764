import { fork } from "redux-saga/effects";
import { activationFlow } from "./sagas/activation";
import { adminDataFlow } from "./sagas/admin/admin";
import { adminTeamFlow } from "./sagas/admin/team";
import { adminTeamMembersFlow } from "./sagas/admin/adminTeamMembers";
import { adminTeamsFlow } from "./sagas/admin/teams";
import { allTestsFlow } from "./sagas/allTests";
import { resourceFlow } from "./sagas/admin/resources";
import { userFlow } from "./sagas/admin/user";
import { authFlow } from "./sagas/auth";
import { clientDataFlow } from "./sagas/client";
import { clientTestProjectListFlow } from "./sagas/clientTestProjectList";
import { configFlow } from "./sagas/config";
import { dateFormatFlow } from "./sagas/dateFormat";
import { importTestProjectDataFlow } from "./sagas/importTestProjectData";
import { languageFlow } from "./sagas/language";
import { loadingFlow } from "./sagas/loader";
import { monitoringFlow } from "./sagas/monitoring";
import { newRequestFlow } from "./sagas/newRequest";
import { newTestFlow } from "./sagas/newTest";
import { recoveryFlow } from "./sagas/recovery";
import { requestParametersFlow } from "./sagas/requestParameters";
import { runningTestsFlow } from "./sagas/runningTests";
import { reportFlow } from "./sagas/report";
import { reportsFlow } from "./sagas/reports";
import { resetFlow } from "./sagas/reset";
import { teamFlow } from "./sagas/team";
import { teamsFlow } from "./sagas/teams";
import { testAnomalyFlow } from "./sagas/testAnomalies";
import { testDetailsFlow } from "./sagas/testDetails";
import { testProjectDefaultSettingsFlow } from "./sagas/testProjectDefaultSettings";
import { testProjectJmxFlow } from "./sagas/testProjectJmx";
import { testProjectJmxChartFlow } from "./sagas/testProjectJmxChart";
import { testProjectFlow } from "./sagas/testProject";
import { testProjectModelSchemaFlow } from "./sagas/testProjectModelSchema";
import { testProjectPercentDistributionFlow } from "./sagas/testProjectPercentDistribution";
import { testProjectUsersDistributionFlow } from "./sagas/testProjectUsersDistribution";
import { testProjectsFlow } from "./sagas/testProjects";
import { testProjectSettingsSetFlow } from "./sagas/testProjectSettingsSet";
import { testProjectSettingsSetsFlow } from "./sagas/testProjectSettingsSets";
import { testProjectsSettingsSetSidebarFlow } from "./sagas/testProjectsSettingsSetSidebar";
import { testProjectNotificationFlow } from "./sagas/testProjectNotification";
import { threadGroupFlow } from "./sagas/threadGroup";
import { threadGroupEditorFlow } from "./sagas/threadGroupEditor";
import { listener } from "./sagas/listener";
import { secretTokenFlow } from "./sagas/secretToken";
import { slaReportProfileFlow } from "./sagas/slaReportProfile";
import { slaReportProfilesFlow } from "./sagas/slaReportProfiles";
import { userTeamMembersFlow } from "./sagas/userTeamMembers";
export default function* () {
    yield fork(activationFlow);
    yield fork(adminDataFlow);
    yield fork(adminTeamFlow);
    yield fork(adminTeamMembersFlow);
    yield fork(adminTeamsFlow);
    yield fork(authFlow);
    yield fork(clientDataFlow);
    yield fork(clientTestProjectListFlow);
    yield fork(configFlow);
    yield fork(dateFormatFlow);
    yield fork(importTestProjectDataFlow);
    yield fork(languageFlow);
    yield fork(listener);
    yield fork(loadingFlow);
    yield fork(monitoringFlow);
    yield fork(newRequestFlow);
    yield fork(newTestFlow);
    yield fork(recoveryFlow);
    yield fork(reportFlow);
    yield fork(reportsFlow);
    yield fork(requestParametersFlow);
    yield fork(resetFlow);
    yield fork(resourceFlow);
    yield fork(runningTestsFlow);
    yield fork(secretTokenFlow);
    yield fork(slaReportProfilesFlow);
    yield fork(slaReportProfileFlow);
    yield fork(teamFlow);
    yield fork(teamsFlow);
    yield fork(testAnomalyFlow);
    yield fork(testDetailsFlow);
    yield fork(testProjectDefaultSettingsFlow);
    yield fork(testProjectJmxFlow);
    yield fork(testProjectJmxChartFlow);
    yield fork(testProjectNotificationFlow);
    yield fork(testProjectFlow);
    yield fork(testProjectModelSchemaFlow);
    yield fork(testProjectPercentDistributionFlow);
    yield fork(testProjectUsersDistributionFlow);
    yield fork(testProjectsFlow);
    yield fork(testProjectSettingsSetFlow);
    yield fork(testProjectSettingsSetsFlow);
    yield fork(testProjectsSettingsSetSidebarFlow);
    yield fork(threadGroupFlow);
    yield fork(threadGroupEditorFlow);
    yield fork(allTestsFlow);
    yield fork(userFlow);
    yield fork(userTeamMembersFlow);
}
