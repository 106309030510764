import React from "react";
import { Tooltip } from "@boomq/uikit";
import { ActionButton, Actions, StyledIconCopy, StyledIconDelete } from "./styled";
export const SlaItemButtons = ({ copySlaTooltip, deleteSlaTooltip, isShowActionButtons, onClickCopy, onClickDelete }) => isShowActionButtons ? (React.createElement(Actions, null,
    React.createElement(Tooltip, { title: copySlaTooltip },
        React.createElement(ActionButton, { onClick: onClickCopy },
            React.createElement(StyledIconCopy, null))),
    React.createElement(Tooltip, { title: deleteSlaTooltip },
        React.createElement(ActionButton, { onClick: onClickDelete },
            React.createElement(StyledIconDelete, null))))) : (React.createElement(React.Fragment, null));
