var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useRef } from "react";
import { useEventListener } from "@boomq/utils";
import { LoaderBlock, Sidebar, StyledIconLoader } from "./styled";
import { SettingsSetSidebarContent } from "../SettingsSetSidebarContent";
export const SettingsSetSidebar = (_a) => {
    var { children } = _a, restProps = __rest(_a, ["children"]);
    const sidebarRef = useRef();
    const hideSettingsSetSidebar = () => {
        removeEventListener();
        return typeof restProps.hideSettingsSetSidebar === "function" ? restProps.hideSettingsSetSidebar() : undefined;
    };
    const outSideClickHandler = (e) => sidebarRef && sidebarRef.current && !sidebarRef.current.contains(e.target)
        ? hideSettingsSetSidebar()
        : undefined;
    const outsideClickFn = useRef(outSideClickHandler);
    const [addEventListener, removeEventListener] = useEventListener(document, "click", outsideClickFn.current);
    const modifyEventListenerByIsShow = (isShow) => (isShow ? addEventListener() : removeEventListener());
    useEffect(() => {
        return hideSettingsSetSidebar;
    }, []);
    useEffect(() => {
        modifyEventListenerByIsShow(restProps.isShowSettingsSetSidebar);
    }, [restProps.isShowSettingsSetSidebar]);
    return (React.createElement(Sidebar, { isShow: restProps.isShowSettingsSetSidebar, ref: sidebarRef },
        React.createElement(SettingsSetSidebarContent, Object.assign({}, restProps), children),
        React.createElement(LoaderBlock, { isShow: restProps.isShowLoader },
            React.createElement(StyledIconLoader, null))));
};
