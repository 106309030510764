var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { getUniqueId } from "@boomq/utils";
import "./pflbChart.css";
const pflbChartSettingsIcon = `
    <svg width="24" height="24" fill="none" version="1.1" viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg">
        <path d="m4.9853 12.718 4.805-2.9903" stroke="currentColor" stroke-width="2" />
        <rect x="5" y="5" width="14" height="14" rx="4" stroke="currentColor" stroke-width="2" />
        <path d="m8.6258 9.6487 5.2261 4.3637" stroke="currentColor" stroke-width="2" />
        <path d="m12.716 13.914 3.8913-2.0848" stroke="currentColor" stroke-width="2" />
        <path d="m15.566 11.771 3.5094 2.4552" stroke="currentColor" stroke-width="2" />
    </svg>`;
export class PFLBChart {
    static get toolbox() {
        return {
            title: "PFLB chart",
            icon: pflbChartSettingsIcon
        };
    }
    constructor(_a) {
        var { pflbChartId } = _a, restParams = __rest(_a, ["pflbChartId"]);
        const { data, api, config } = restParams;
        this._config = config;
        this._data = data;
        this.api = api;
        this.isShowParamsSidebar = false;
        this.pflbChartId = pflbChartId || getUniqueId();
    }
    removed() {
        return this._config && typeof this._config.onDeleteBlock === "function"
            ? this._config.onDeleteBlock(this.pflbChartId)
            : undefined;
    }
    render(rootNode) {
        const resultNode = rootNode || document.createElement("div");
        resultNode.classList.add("pflb-chart");
        resultNode.id = this.getPFLBChartId();
        return resultNode;
    }
    getPFLBChartId() {
        return `pflb-chart-${this.pflbChartId}`;
    }
    renderSettings() {
        return [
            {
                label: this.api.i18n.t("Chart params"),
                icon: pflbChartSettingsIcon,
                isActive: this.isShowParamsSidebar,
                closeOnActivate: true,
                toggle: true,
                onActivate: () => this.toggleChartParamsSidebar()
            }
        ];
    }
    toggleChartParamsSidebar() {
        return this.isShowParamsSidebar ? this.hideChartParamsSidebar() : this.showChartParamsSidebar();
    }
    hideOtherBlocksParams() {
        const blocksCount = this.api.blocks.getBlocksCount();
        const currentBlockIndex = this.api.blocks.getCurrentBlockIndex();
        for (let i = 0; i < blocksCount; i++) {
            const block = this.api.blocks.getBlockByIndex(i);
            if (currentBlockIndex !== i) {
                block.call("hideChartParamsSidebar");
                block.call("hideTableParamsSidebar");
            }
        }
    }
    hideChartParamsSidebar() {
        const chartParamsSidebarDiv = this.getChartParamsSidebarDiv();
        this.isShowParamsSidebar = false;
        return chartParamsSidebarDiv ? chartParamsSidebarDiv.classList.remove("show") : undefined;
    }
    showChartParamsSidebar() {
        const chartParamsSidebarDiv = this.getChartParamsSidebarDiv();
        this.isShowParamsSidebar = true;
        this.hideOtherBlocksParams();
        return chartParamsSidebarDiv ? chartParamsSidebarDiv.classList.add("show") : undefined;
    }
    getChartParamsSidebarDiv() {
        return document.querySelector(`#${this.getPFLBChartId()} .pflb-sidebar-settings`);
    }
    save() {
        return {
            id: this.pflbChartId
        };
    }
}
