import React from "react";
import { useIntl } from "@boomq/utils";
import { ADD_SLA_SELECT_BODY_PLACEHOLDER, SLA_SELECT_PLACEHOLDER } from "./intl";
import { NewSlaTypeSection, SlaTypeListItem, customNewSLASelectTheme } from "./styled";
import { SelectedItemBody } from "../SlaReport";
import { CustomSelect } from "../../../common/CustomSelect";
import { formatIntlMessage } from "../../../../helpers/intl";
import { generateSlaTypeItemFormattedTextWithTimeline } from "../../../../helpers/slaReport";
import { SlaReportType } from "../../../../helpers/types";
export const AddSlaSelect = ({ slaReportChartIndex, slaReportProfileAddSlaTypeByViewTypeRequest, slaTypeList, testDuration }) => {
    const { formatMessage } = useIntl();
    const clickAddHandler = (data) => {
        slaReportProfileAddSlaTypeByViewTypeRequest({
            chartIndex: slaReportChartIndex,
            type: SlaReportType.Chart,
            value: data.id
        });
    };
    const getSlaBody = () => {
        const bodyText = formatIntlMessage(ADD_SLA_SELECT_BODY_PLACEHOLDER, formatMessage);
        return React.createElement(SelectedItemBody, { title: bodyText }, bodyText);
    };
    const getSlaItem = (slaItem) => {
        const slaItemText = generateSlaTypeItemFormattedTextWithTimeline(slaItem, testDuration, formatMessage);
        return React.createElement(SlaTypeListItem, { title: slaItemText }, slaItemText);
    };
    return (React.createElement(NewSlaTypeSection, null,
        React.createElement(CustomSelect, { items: slaTypeList, bodyTemplate: getSlaBody, listItemTemplate: getSlaItem, placeholder: formatMessage(SLA_SELECT_PLACEHOLDER), maxItemCount: 10, name: "ChartReportNewSLA", onChange: clickAddHandler, theme: customNewSLASelectTheme })));
};
