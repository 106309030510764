import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useIntl } from "@boomq/utils";
import { REPORT_EDITOR_SIDEBAR_SETTINGS_EMPTY_TEXT } from "./intl";
import { Report } from "../../../../components/pages/account/Report";
import { formatIntlMessage } from "../../../../helpers/intl";
import { clearReportData } from "../../../../redux/actions/report";
export const ReportContainer = (props) => {
    const { formatMessage } = useIntl();
    const dispatch = useDispatch();
    useEffect(() => {
        return () => dispatch(clearReportData());
    }, []);
    const sidebarSettingsEmptyText = formatIntlMessage(REPORT_EDITOR_SIDEBAR_SETTINGS_EMPTY_TEXT, formatMessage);
    return React.createElement(Report, Object.assign({ sidebarSettingsEmptyText: sidebarSettingsEmptyText }, props));
};
