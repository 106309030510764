export const REQUEST_PLACEHOLDER = {
    defaultMessage: "Request",
    id: "account.sla.form.request.placeholder"
};
export const TRANSACTION_LIST_ITEM_ANY_TRANSACTION = {
    defaultMessage: "Any transaction",
    id: "account.sla.form.request.ANY_TRANSACTION"
};
export const TRANSACTION_LIST_ITEM_NO_TRANSACTION = {
    defaultMessage: "Not in transaction",
    id: "account.sla.form.request.NO_TRANSACTION"
};
