var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useRef, useState } from "react";
import { stopEvent } from "@boomq/utils";
import { AutoCompleteBody, Loader, StyledIconLoader } from "./styled";
import { DropdownList } from "../DropdownList";
import { Input } from "../Input";
import { Popper } from "../Popper";
const DEFAULT_DELAY = 300;
const DEFAULT_MIN_LENGTH = 1;
const DEFAULT_SEARCHED_ITEMS = [];
let timeout;
const renderDefaultDropdownTemplate = (props) => (React.createElement(Popper, { placement: "bottom-start", anchorEl: props.inputRef.current, open: props.isOpenedDropdown },
    React.createElement(DropdownList, { isOpened: true, items: props.searchedItems, onChange: props.selectItemHandler })));
export const AutoComplete = (_a) => {
    var { delay = DEFAULT_DELAY, minLength = DEFAULT_MIN_LENGTH, searchedItems = DEFAULT_SEARCHED_ITEMS } = _a, rest = __rest(_a, ["delay", "minLength", "searchedItems"]);
    const [isOpenedDropdown, setIsOpenedDropdown] = useState(false);
    const [isSearching, setIsSearching] = useState(false);
    const inputRef = useRef(null);
    const search = (value) => {
        setIsSearching(true);
        return typeof rest.searchFunc === "function" ? rest.searchFunc(value) : undefined;
    };
    const searchWithDelay = (value) => {
        timeout = setTimeout(() => {
            search(value);
        }, delay);
    };
    const changeInput = (value) => (typeof rest.onChangeInput === "function" ? rest.onChangeInput(value) : undefined);
    const checkTimeout = () => (timeout ? clearTimeout(timeout) : undefined);
    const changeInputHandler = (value) => {
        checkTimeout();
        changeInput(value);
        return value.trim().length >= minLength ? searchWithDelay(value) : setIsSearching(false);
    };
    const clickInputHandler = (e) => {
        stopEvent(e);
        return typeof rest.onClick === "function" ? rest.onClick(e) : undefined;
    };
    const focusInputHandler = () => Array.isArray(searchedItems) && searchedItems.length > 0 ? setIsOpenedDropdown(true) : undefined;
    const blurInputHandler = (e) => {
        setIsOpenedDropdown(false);
        return typeof rest.onBlur === "function" ? rest.onBlur(e) : undefined;
    };
    const keyDownHandler = (e) => (typeof rest.onKeyDown === "function" ? rest.onKeyDown(e) : undefined);
    const checkAutoFocus = () => {
        const element = rest.autoFocus && inputRef && inputRef.current ? inputRef.current.querySelector("input") : undefined;
        return element && typeof element.focus === "function" ? element.focus() : undefined;
    };
    useEffect(() => {
        checkAutoFocus();
    }, []);
    useEffect(() => {
        setIsSearching(false);
        setIsOpenedDropdown(true);
    }, [searchedItems]);
    useEffect(() => {
    });
    useEffect(() => {
        return () => {
            checkTimeout();
        };
    }, []);
    const renderInput = () => rest.inputTemplate ? (React.createElement("div", { ref: inputRef },
        React.createElement(rest.inputTemplate, { autoComplete: "off", onChange: changeInputHandler, onClick: clickInputHandler, onKeyDown: keyDownHandler }))) : (React.createElement(Input, { ref: inputRef, name: rest.name, autoComplete: "off", disabled: rest.disabled, placeholder: rest.placeholder, onBlur: blurInputHandler, onChange: changeInputHandler, onClick: clickInputHandler, onFocus: focusInputHandler, onKeyDown: keyDownHandler, theme: rest.themeInput }));
    const closeDropDownHandler = () => setIsOpenedDropdown(false);
    const selectItemHandler = (item) => {
        setIsOpenedDropdown(false);
        return typeof rest.onSelectItem === "function" ? rest.onSelectItem(item) : undefined;
    };
    const renderDropdownTemplate = () => rest.dropdownTemplate ? (React.createElement(Popper, { placement: "bottom-start", anchorEl: inputRef.current, open: isOpenedDropdown },
        React.createElement(rest.dropdownTemplate, { searchedItems: searchedItems, onClose: closeDropDownHandler, onSelectItem: selectItemHandler }))) : (renderDefaultDropdownTemplate({
        inputRef,
        isOpenedDropdown,
        selectItemHandler,
        searchedItems
    }));
    const renderDropdown = () => (isOpenedDropdown ? renderDropdownTemplate() : React.createElement(React.Fragment, null));
    const renderLoader = () => isSearching ? (React.createElement(Loader, null,
        React.createElement(StyledIconLoader, null))) : (React.createElement(React.Fragment, null));
    return (React.createElement(AutoCompleteBody, null,
        renderInput(),
        renderLoader(),
        renderDropdown()));
};
