import React from "react";
import { ButtonSize } from "@boomq/uikit";
import { ApplyButton, ChartParamsRow, ChartSelectWithMarginTop } from "./styled";
export const ChartSerieCommonParams = ({ aggregationIntervalItems, aggregationIntervalTimeUnit, applyButtonLabel, axisItems, axisUnit, chartAggregationIntervalSelectLabel, chartAggregationIntervalSelectPlaceholder, chartAxisSelectLabel, chartAxisSelectPlaceholder, chartMetricTypeLabel, chartMetricTypePlaceholder, chartType, chartTypeItems, chartTypeSelectLabel, chartTypeSelectPlaceholder, children, isDisabledApplyButton, metricType, metricTypeItems, onChangeAggregattionInterval, onChangeAxis, onChangeChartMetricType, onChangeChartSerieTest, onChangeChartType, onClickApply, testId, testIdSelectLabel, testIdSelectPlaceholder, testList }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(ChartSelectWithMarginTop, { defaultValue: testId, items: testList, label: testIdSelectLabel, onChange: onChangeChartSerieTest, placeholder: testIdSelectPlaceholder, titleAttrName: "comment" }),
        React.createElement(ChartSelectWithMarginTop, { defaultValue: chartType, items: chartTypeItems, label: chartTypeSelectLabel, onChange: onChangeChartType, placeholder: chartTypeSelectPlaceholder }),
        children,
        React.createElement(ChartSelectWithMarginTop, { defaultValue: metricType, disabled: !chartType, items: metricTypeItems, label: chartMetricTypeLabel, onChange: onChangeChartMetricType, placeholder: chartMetricTypePlaceholder }),
        React.createElement(ChartSelectWithMarginTop, { defaultValue: aggregationIntervalTimeUnit, items: aggregationIntervalItems, label: chartAggregationIntervalSelectLabel, onChange: onChangeAggregattionInterval, placeholder: chartAggregationIntervalSelectPlaceholder }),
        React.createElement(ChartSelectWithMarginTop, { defaultValue: axisUnit, items: axisItems, label: chartAxisSelectLabel, onChange: onChangeAxis, placeholder: chartAxisSelectPlaceholder }),
        React.createElement(ChartParamsRow, null,
            React.createElement(ApplyButton, { disabled: isDisabledApplyButton, size: ButtonSize.medium, onClick: onClickApply }, applyButtonLabel))));
};
