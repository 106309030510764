import styled, { css } from "styled-components";
import { Color, FontBodyBigMedium } from "@boomq/uikit";
import { IconEdit } from "../../styled/Icons";
export const StyledTitle = styled.div `
    ${FontBodyBigMedium}
    align-items: center;
    border: 2px solid transparent;
    cursor: text;
    display: flex;
    height: fit-content;
    min-height: 28px;
    transition: padding 0.3s ease, width 0.3s ease;
    width: fit-content;
    word-break: break-all;
    &:hover {
        border: 2px solid ${Color.darkGrey5};
        border-radius: 12px;
        padding: 0 8px;
    }
    ${({ theme }) => (theme ? css(theme) : css ``)}
`;
export const StyledIconEdit = styled(IconEdit) `
    cursor: pointer;
    margin-left: 5px;
    min-width: 22px;
    path {
        fill: ${Color.white};
    }
`;
export const TitleInput = styled.input `
    ${FontBodyBigMedium}
    align-items: center;
    background: transparent;
    border: 2px solid ${Color.darkGrey5};
    border-radius: 12px;
    display: flex;
    height: fit-content;
    min-height: 28px;
    min-width: 360px;
    outline: none;
    padding: 0 8px;
    ${({ isExistError }) => isExistError
    ? css `
                  border-color: ${Color.red};
                  &::placeholder {
                      color: ${Color.red};
                  }
              `
    : css ``}
    ${({ theme }) => (theme ? css(theme) : css ``)}
`;
