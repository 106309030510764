import styled from "styled-components";
import { Color, FontBodyBigBold } from "@boomq/uikit";
import { ToolbarLink } from "../../website/ToolbarLink";
export const ItemLink = styled.a `
    ${FontBodyBigBold}
    align-items: center;
    color: ${Color.white};
    display: flex;
    transition: all 0.3s ease;
    &:hover {
        color: ${Color.blue};
    }
`;
export const ItemNavLink = ItemLink.withComponent(ToolbarLink);
export const ListItem = styled.li `
    display: flex;
    position: relative;
    .pflb-dropdown-list {
        display: none;
    }
    &:hover .pflb-dropdown-list {
        display: block;
    }
`;
