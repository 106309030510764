import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { getAvgRespTime, getDefaultStatsResult, getErrors, getPct95RespTime, getThroughput, getUsers } from "../../helpers/testDetailsStats";
const TestDetailsStats = (params) => {
    const currentAvgRespTime = getAvgRespTime(params);
    return (React.createElement(StatsList, null,
        React.createElement(ListItem, null,
            React.createElement(Title, null,
                React.createElement(FormattedMessage, { id: "account.test.details.max.load", defaultMessage: "VUsers" })),
            React.createElement(Text, null, getUsers(params))),
        React.createElement(ListItem, null,
            React.createElement(Title, null,
                React.createElement(FormattedMessage, { id: "account.test.details.throughput", defaultMessage: "Avg. Throughput" })),
            React.createElement(Text, null,
                getThroughput(params),
                " Requests/s")),
        React.createElement(ListItem, null,
            React.createElement(Title, null,
                React.createElement(FormattedMessage, { id: "account.test.details.errors", defaultMessage: "Total Error Count" })),
            React.createElement(Text, null, getErrors(params))),
        React.createElement(ListItem, null,
            React.createElement(Title, null,
                React.createElement(FormattedMessage, { id: "account.test.details.response.time", defaultMessage: "Avg. Response Time" })),
            React.createElement(Text, null,
                currentAvgRespTime > 1000 ? (currentAvgRespTime / 1000).toFixed(1) : currentAvgRespTime,
                "\u00A0",
                currentAvgRespTime > 1000 ? (React.createElement(FormattedMessage, { id: "account.all.plate.sec", defaultMessage: "sec" })) : (React.createElement(FormattedMessage, { id: "account.all.plate.ms", defaultMessage: "ms" })))),
        React.createElement(ListItem, null,
            React.createElement(Title, null,
                React.createElement(FormattedMessage, { id: "account.test.details.ninety.pct", defaultMessage: "95% Response Time" })),
            React.createElement(Text, null,
                getPct95RespTime(params),
                "\u00A0",
                React.createElement(FormattedMessage, { id: "account.all.plate.sec", defaultMessage: "sec" })))));
};
TestDetailsStats.defaultProps = getDefaultStatsResult();
const StatsList = styled.div `
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
`;
const ListItem = styled.div `
    text-align: center;
    padding: 35px 10px;
    &:nth-child(2) {
        border-left: 1px dashed #e0e0e0;
    }
    &:nth-child(3),
    &:nth-child(5) {
        border-top: 1px dashed #e0e0e0;
    }
    &:nth-child(4) {
        border-top: 1px dashed #e0e0e0;
        border-left: 1px dashed #e0e0e0;
    }
    &:last-child {
        grid-column: 1/3;
    }
`;
const Title = styled.h4 `
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    font-size: 14px;
    color: #bdbdbd;
`;
const Text = styled.p `
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    font-size: 16px;
    color: #396496;
    margin-top: 12px;
`;
export default TestDetailsStats;
