import { objectToParams } from "@boomq/utils";
import { instance } from "../services/authSrv";
const location = window.location;
const servicePort = location.port ? `:${location.port}` : "";
const serviceName = "import-srv";
export const getImportSrvUrl = () => `${location.protocol}//${location.hostname}${servicePort}/${serviceName}`;
export const uploadImportFile = (file, params) => {
    const formData = new FormData();
    formData.append("file", file);
    return instance.post(`${getImportSrvUrl()}/file${objectToParams(params, "?")}`, formData);
};
