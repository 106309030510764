import { SlaOperation } from "../../models/sla/enums";
export const getOperationTextByValue = (value) => {
    switch (value) {
        case SlaOperation.GreaterThen: {
            return ">";
        }
        case SlaOperation.LessEquals: {
            return "<=";
        }
        default: {
            return "";
        }
    }
};
