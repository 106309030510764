import { getHours, getMinutes, getSeconds, getTimeSymbolIntlMessageByName, TimeParts } from "../date";
import { useIntl } from "../useIntl";
export const useDuration = (timestamp) => {
    const { formatMessage } = useIntl();
    const hours = getHours(timestamp);
    const hoursText = hours !== "00" ? `${hours} ${formatMessage(getTimeSymbolIntlMessageByName(TimeParts.Hour))} ` : "";
    const minsText = `${getMinutes(timestamp)} ${formatMessage(getTimeSymbolIntlMessageByName(TimeParts.Min))}`;
    const secText = hours === "00"
        ? ` ${getSeconds(timestamp)} ${formatMessage(getTimeSymbolIntlMessageByName(TimeParts.Sec))}`
        : "";
    return `${hoursText}${minsText}${secText}`;
};
