export const authErrors = (errorCode) => {
    switch (errorCode) {
        case "PENDING_INVITATION_FOUND":
            return {
                defaultMessage: "You already have an account. Please sign-in.",
                id: "api.error.code.USER_ALREADY_EXIST"
            };
        case "RESET_PASSWORD_TOKEN_NOT_FOUND":
            return {
                defaultMessage: "Your link is invalid, please login or request a password change again.",
                id: "api.auth.error.code.RESET_PASSWORD_TOKEN_NOT_FOUND"
            };
        case "USER_ALREADY_CREATED":
            return {
                defaultMessage: "You already have an account. Please sign-in.",
                id: "api.error.code.USER_ALREADY_EXIST"
            };
        case "USER_ALREADY_LINKED":
            return {
                defaultMessage: "User is already registered. You can login by this account",
                id: "api.error.code.USER_ALREADY_LINKED"
            };
        case "USER_NAME_NOT_EQUAL":
            return {
                defaultMessage: "Incorrect email or password",
                id: "api.error.code.BAD_CREDENTIAL"
            };
        case "USER_NOT_FOUND":
            return {
                defaultMessage: "Incorrect email or password",
                id: "api.error.code.BAD_CREDENTIAL"
            };
        default:
            return;
    }
};
