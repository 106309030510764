import React, { useEffect } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { getValueByPath, isEmpty } from "@boomq/utils";
import { MERGE_GROUPS_SWITCHER_LABEL, TIMELINE_RANGE_SLIDER_LABEL, TIMELINE_RANGE_SLIDER_UNITS } from "./intl";
import { PreviewChart } from "../../PreviewChart";
import RangeSlider from "../../../common/RangeSlider";
import { Switcher } from "../../../common/Switcher";
import { rangeSliderRailStyleTheme, ChartSection, SettingsWrapper, SwitchChartMergeGroupsSection } from "../../../styled/TestProject";
import newTestActions from "../../../../redux/actions/newTest";
import { getIsMergedJMXChartGroups, getLoadProfilesChartLegend, getPreviewJMXChartTimeline, getTestProjectSettingsField } from "../../../../redux/reducers/newTest";
const { changeJmxPreviewChartField, refreshOwnJmxChartPreview } = newTestActions;
const connector = connect((state) => ({
    chartData: getTestProjectSettingsField(state, "chartData"),
    chartLegend: getLoadProfilesChartLegend(state),
    isMergedJMXChartGroups: getIsMergedJMXChartGroups(state),
    timeLine: getPreviewJMXChartTimeline(state)
}), { onChange: changeJmxPreviewChartField, onInit: refreshOwnJmxChartPreview });
const PreviewJMXChart = (props) => {
    useEffect(() => {
        const init = () => props === null || props === void 0 ? void 0 : props.onInit();
        init();
    }, []);
    const changePreviewChartData = (field, value) => props.onChange({ field, value });
    const toggleMergeGroups = (e) => e && e.target && changePreviewChartData("isMergedJMXChartGroups", e.target.checked);
    const changeTimelineRange = (field, value) => changePreviewChartData(field, Object.assign(Object.assign({}, props.timeLine), { value }));
    return (React.createElement(SettingsWrapper, null,
        React.createElement(ChartSection, null,
            React.createElement(SwitchChartMergeGroupsSection, null,
                React.createElement(Switcher, { defaultChecked: isEmpty(props.isMergedJMXChartGroups) ? false : props.isMergedJMXChartGroups, onClick: toggleMergeGroups }),
                React.createElement(FormattedMessage, Object.assign({}, MERGE_GROUPS_SWITCHER_LABEL))),
            React.createElement(PreviewChart, { data: props.chartData, isShowLegend: true, legendPayload: props.chartLegend, min: getValueByPath(props, "timeLine.value.0", 0) / 60, max: getValueByPath(props, "timeLine.value.1", 1) / 60, minHeight: 450 }),
            React.createElement(RangeSlider, { changeHandler: changeTimelineRange, label: TIMELINE_RANGE_SLIDER_LABEL, railStyle: rangeSliderRailStyleTheme, slider: {
                    min: Math.floor(getValueByPath(props, "timeLine.min", 0)),
                    max: Math.ceil(getValueByPath(props, "timeLine.max", 1)),
                    step: 1,
                    value: [
                        Math.floor(getValueByPath(props, "timeLine.value.0", 0)),
                        Math.ceil(getValueByPath(props, "timeLine.value.1", 1))
                    ]
                }, units: TIMELINE_RANGE_SLIDER_UNITS, name: "timeLine" }))));
};
export default connector(PreviewJMXChart);
