import moment from "moment";
import { DEFAULT_DATE_FORMAT_LOCALE, DEFAULT_DATE_FORMAT_LOCALE_OPTIONS, DEFAULT_FORMAT, LOCALE_TIME_ZONE } from "./constants";
import { formatDateByLocale } from "./format";
const getMomentInstanceByTimestamp = (timestamp) => timestamp ? moment(timestamp) : moment();
export const getFullDateLocaleTimeZone = (locale, timestamp) => {
    const date = typeof timestamp === "undefined" ? new Date() : new Date(timestamp);
    const actualLocale = locale || DEFAULT_DATE_FORMAT_LOCALE;
    const formattedDate = formatDateByLocale(date, actualLocale, DEFAULT_DATE_FORMAT_LOCALE_OPTIONS);
    return formattedDate ? formattedDate : getMomentInstanceByTimestamp(timestamp).format(DEFAULT_FORMAT);
};
export const getDateLocaleTimeZone = (timestamp) => (timestamp ? moment(timestamp) : moment()).format(LOCALE_TIME_ZONE);
