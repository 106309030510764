import { handleActions } from "redux-actions";
import clientActions from "../actions/client";
import { resetError } from "../actions/error";
const { changePasswordFailure, changePasswordRequest } = clientActions;
const initialState = {
    data: {},
    error: ""
};
export default handleActions({
    [changePasswordRequest]: (state) => (Object.assign(Object.assign({}, state), initialState)),
    [changePasswordFailure]: (state, action) => (Object.assign(Object.assign({}, state), { error: action.payload.code })),
    [resetError]: (state) => (Object.assign(Object.assign({}, state), { error: initialState.error }))
}, initialState);
export const getErrorCode = (state) => state.changePassword.error;
