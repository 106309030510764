var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { getValueByPath } from "@boomq/utils";
import { InputText } from "@boomq/uikit";
import { ConditionSelect } from "./styled";
import { AssertionCommonTemplate } from "../AssertionCommonTemplate";
import { FormType } from "../../../../helpers/types";
export const AssertionCommonWithConditionTemplate = (_a) => {
    var { assertionConditionItems, children, conditionSelectPlaceholder, inputUnit, onChangeCondition, onChangeValue, valuePlaceholder } = _a, restProps = __rest(_a, ["assertionConditionItems", "children", "conditionSelectPlaceholder", "inputUnit", "onChangeCondition", "onChangeValue", "valuePlaceholder"]);
    const { condition, error, value } = restProps.assertion || {};
    return (React.createElement(AssertionCommonTemplate, Object.assign({}, restProps),
        React.createElement(React.Fragment, null,
            children,
            React.createElement(ConditionSelect, { defaultValue: condition, disabled: restProps.mode === FormType.View, dropdownAutoPositionMinItemCount: 5, error: getValueByPath(error, "condition"), isAutoPosition: true, items: assertionConditionItems, name: "Assertion_condition", onChange: onChangeCondition, placeholder: conditionSelectPlaceholder }),
            React.createElement(InputText, { enabled: restProps.mode !== FormType.View, error: getValueByPath(error, "value"), name: "Assertion_value", onBlur: onChangeValue, placeholder: valuePlaceholder, unit: inputUnit, value: value }))));
};
