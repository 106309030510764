var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { useIntl } from "@boomq/utils";
import { TEST_DELAY_TEXT } from "./intl";
import { TestDelayText } from "./styled";
import { RunningTestsItemTemplateContainer } from "../../../../containers/account/TestRuns/RunningTestsItemTemplate";
import { formatIntlMessage } from "../../../../helpers/intl";
import { isTestStopping } from "../../../../helpers/testStatus";
const WaitingTemplate = (_a) => {
    var { projectName } = _a, restProps = __rest(_a, ["projectName"]);
    const { formatMessage } = useIntl();
    const isTestRunStopping = restProps.isStopping || isTestStopping(restProps.displayState);
    const testDelayText = formatIntlMessage(TEST_DELAY_TEXT, formatMessage);
    return (React.createElement(RunningTestsItemTemplateContainer, Object.assign({ title: projectName }, restProps), !isTestRunStopping && React.createElement(TestDelayText, null, testDelayText)));
};
export default WaitingTemplate;
