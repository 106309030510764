import { call } from "redux-saga/effects";
import { reportTableDataGenerationFailed } from "./reportTableDataGenerationFailed";
import { generateTableDataForSLA, generateTableParamsForSLAByRequest } from "../../../helpers/generators";
import { apiGenerateReportTablePreview } from "../../../services/reportSrv";
export function* generateTableDataForSLAByRequestFlow(params) {
    try {
        const tableParams = generateTableParamsForSLAByRequest(params);
        const { data } = yield call(apiGenerateReportTablePreview, tableParams);
        return generateTableDataForSLA(data);
    }
    catch (e) {
        return yield* reportTableDataGenerationFailed();
    }
}
