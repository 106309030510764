var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { isEmpty, useIntl } from "@boomq/utils";
import { SETTINGS_SET_ACTIONS_SAVE, SETTINGS_SET_INCORRECT_SECTION, SETTINGS_SET_INCORRECT_SECTIONS, SETTINGS_SET_PAGE_TITLE } from "./intl";
import { TestProjectSettingsSet } from "../../../../components/pages/account/TestProjectSettingsSet";
import { setTitle } from "../../../../redux/actions/accountTitle";
import { saveTestProjectSettingsSetRequest, testProjectSettingsSetRequest } from "../../../../redux/actions/testProjectSettingsSet";
import { setHeaders } from "../../../../redux/actions/testProjectSettingsSetHeaders";
import { getClientPermissionList, getIsAuthorize, getSelectedTeamId } from "../../../../redux/reducers/authorizePlate";
import { getRequestGlobalParametersList, getRequestParametersImportCsv } from "../../../../redux/reducers/requestParameters";
import { getSettingsSetDefaultTimerData } from "../../../../redux/reducers/testProjectSettingsSet";
import { getSettingsSetDefaultCookieItems } from "../../../../redux/reducers/testProjectSettingsSetCookies";
import { getSettingsSetDefaultHeadersData } from "../../../../redux/reducers/testProjectSettingsSetHeaders";
import { getUrlOverwriteItems } from "../../../../redux/reducers/urlOverwrite";
import { getWebhookItems } from "../../../../redux/reducers/webhooks";
import { formatIntlMessage } from "../../../../helpers/intl";
import { NEW_ITEM_ID } from "../../../../helpers/routes";
import { testProjectSettingsSetValidator } from "../../../../helpers/validators";
const connector = connect((state) => ({
    csvParameters: getRequestParametersImportCsv(state),
    defaultCookies: getSettingsSetDefaultCookieItems(state),
    defaultHeaders: getSettingsSetDefaultHeadersData(state),
    defaultTimer: getSettingsSetDefaultTimerData(state),
    isAuthorized: getIsAuthorize(state),
    literalParameters: getRequestGlobalParametersList(state),
    permissionList: getClientPermissionList(state),
    selectedTeamId: getSelectedTeamId(state),
    urlOverwriteItems: getUrlOverwriteItems(state),
    webhookItems: getWebhookItems(state)
}), {
    onRefresh: testProjectSettingsSetRequest,
    onSave: saveTestProjectSettingsSetRequest,
    onUnmount: setHeaders,
    setTitle
});
const formatErrorMessage = (error, formatMessage) => {
    const _a = error ? error : { values: [] }, { values } = _a, rest = __rest(_a, ["values"]);
    const incorrectTabNames = Array.isArray(values) ? values.map(formatMessage) : [];
    const incorrectSettingTabs = incorrectTabNames.length > 1
        ? formatMessage(SETTINGS_SET_INCORRECT_SECTIONS)
        : formatMessage(SETTINGS_SET_INCORRECT_SECTION);
    return formatMessage(rest, { incorrectSettingTabs, incorrectSettingTabNames: ` ${incorrectTabNames.join(", ")}` });
};
const getTestProjectSettingsSetId = (match) => { var _a; return ((_a = match === null || match === void 0 ? void 0 : match.params) === null || _a === void 0 ? void 0 : _a.test_project_settings_set) || NEW_ITEM_ID; };
const TestProjectSettingsSetContainer = (_a) => {
    var { isAuthorized, onRefresh, onSave, onUnmount, selectedTeamId } = _a, restProps = __rest(_a, ["isAuthorized", "onRefresh", "onSave", "onUnmount", "selectedTeamId"]);
    const { formatMessage } = useIntl();
    useEffect(() => {
        const checkAuthorization = () => isAuthorized && !isEmpty(selectedTeamId)
            ? onRefresh === null || onRefresh === void 0 ? void 0 : onRefresh(getTestProjectSettingsSetId(restProps.match))
            : undefined;
        checkAuthorization();
    }, [isAuthorized, selectedTeamId]);
    useEffect(() => {
        return () => onUnmount([]);
    }, []);
    const { isValid, error } = testProjectSettingsSetValidator(restProps);
    const saveHandler = () => (isValid ? onSave(getTestProjectSettingsSetId(restProps.match)) : undefined);
    const errorText = error ? formatErrorMessage(error, formatMessage) : "";
    const pageTitle = formatIntlMessage(SETTINGS_SET_PAGE_TITLE, formatMessage);
    const saveButtonLabel = formatIntlMessage(SETTINGS_SET_ACTIONS_SAVE, formatMessage);
    return (React.createElement(TestProjectSettingsSet, Object.assign({}, restProps, { errorText: errorText, isValid: isValid, onSave: saveHandler, pageTitle: pageTitle, saveButtonLabel: saveButtonLabel })));
};
export default connector(TestProjectSettingsSetContainer);
