var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useIntl } from "@boomq/utils";
import { ELAPSED_TIME_TEXT, ESTIMATED_START_TIME_TEXT } from "./intl";
import { PlateWithSocket } from "../../../../components/account/PlateWithSocket";
import { formatIntlMessage } from "../../../../helpers/intl";
import { getPreliminaryStageDataByStatus } from "../../../../models/testRun";
import { getTestDetailsForPlateWithSocket, testDetailsForPlateWithSocketRequestStop } from "../../../../redux/actions/testDetails";
const connector = connect(null, {
    onRefresh: getTestDetailsForPlateWithSocket,
    onUnmount: testDetailsForPlateWithSocketRequestStop
});
const WrappedPlateWithSocket = (_a) => {
    var _b;
    var { onRefresh, onUnmount } = _a, restProps = __rest(_a, ["onRefresh", "onUnmount"]);
    const { formatMessage } = useIntl();
    useEffect(() => {
        const getTestDetails = () => {
            var _a;
            const testId = (_a = restProps === null || restProps === void 0 ? void 0 : restProps.task) === null || _a === void 0 ? void 0 : _a.id;
            return testId > -1 ? onRefresh({ testId }) : undefined;
        };
        const stopTestDetailsRequest = () => (typeof onUnmount === "function" ? onUnmount() : undefined);
        getTestDetails();
        return stopTestDetailsRequest;
    }, []);
    const currentStageData = getPreliminaryStageDataByStatus((_b = restProps === null || restProps === void 0 ? void 0 : restProps.task) === null || _b === void 0 ? void 0 : _b.state);
    const elapsedTimeText = formatIntlMessage(ELAPSED_TIME_TEXT, formatMessage);
    const estimatedStartTimeText = formatIntlMessage(ESTIMATED_START_TIME_TEXT, formatMessage);
    return (React.createElement(PlateWithSocket, Object.assign({ currentStageData: currentStageData, elapsedTimeText: elapsedTimeText, estimatedStartTimeText: estimatedStartTimeText }, restProps)));
};
export default connector(WrappedPlateWithSocket);
