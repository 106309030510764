var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useIntl } from "@boomq/utils";
import { IMPORT_BUTTON_LABEL, generateIntlMsgUploaderEmptyTextBySourceType } from "./intl";
import { ImportDataFromFile } from "../../../../components/account/TestProject/ImportDataFromFile";
import { importTestProjectDataChangeParamsField, uploadImportFileRequest } from "../../../../redux/actions/importTestProjectData";
import { getImportedFileBySourceType } from "../../../../redux/reducers/importTestProjectData";
import { formatIntlMessage } from "../../../../helpers/intl";
const connector = connect((state, ownProps) => ({
    files: getImportedFileBySourceType(state, ownProps.sourceType)
}), {
    onChangeParamsField: importTestProjectDataChangeParamsField,
    onUploadFile: uploadImportFileRequest
});
const ImportDataFromFileContainer = (_a) => {
    var { groupId, onChangeParamsField, onClickImportButton, onUploadFile, path, sourceType } = _a, restProps = __rest(_a, ["groupId", "onChangeParamsField", "onClickImportButton", "onUploadFile", "path", "sourceType"]);
    const { formatMessage } = useIntl();
    const changeFilesHandler = (files) => files && files.length
        ? onUploadFile({
            files: [...files],
            groupId,
            sourceType
        })
        : onChangeParamsField({
            field: sourceType,
            value: { files: [], data: {} }
        });
    const clickImportButtonHandler = () => typeof onClickImportButton === "function" ? onClickImportButton({ groupId, path, sourceType }) : undefined;
    const importButtonLabel = formatIntlMessage(IMPORT_BUTTON_LABEL, formatMessage);
    const uploaderEmptyText = formatIntlMessage(generateIntlMsgUploaderEmptyTextBySourceType(sourceType), formatMessage);
    return (React.createElement(ImportDataFromFile, Object.assign({ importButtonLabel: importButtonLabel, onChangeFiles: changeFilesHandler, onClickImportButton: clickImportButtonHandler, uploaderEmptyText: uploaderEmptyText }, restProps)));
};
export default withRouter(connector(ImportDataFromFileContainer));
