import styled from "styled-components";
import { Color, FontBodyBigMedium } from "@boomq/uikit";
export const TestModeSwitcherSection = styled.div `
    ${FontBodyBigMedium}
    align-items: center;
    color: ${Color.darkGrey5};
    display: flex;
    margin-left: 8px;
    > label {
        margin-right: 12px;
    }
`;
