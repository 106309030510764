import { MIN_COUNT_IN_HOUR, MS_COUNT_IN_SEC, SEC_COUNT_IN_MIN } from "./constants";
import { isEmpty } from "../isEmpty";
import { round } from "../round";
export const convertDateToTimestamp = (dateTime) => !isEmpty(dateTime) ? new Date(dateTime).getTime() : 0;
export const convertUTCDateToTimestamp = (dateTime) => {
    const date = !isEmpty(dateTime) ? new Date(dateTime) : undefined;
    const timeZoneOffset = date ? date.getTimezoneOffset() : 0;
    return date ? date.getTime() - SEC_COUNT_IN_MIN * MS_COUNT_IN_SEC * timeZoneOffset : 0;
};
export const convertSecToMin = (seconds) => (isEmpty(seconds) ? seconds : seconds / SEC_COUNT_IN_MIN);
export const convertSecToMs = (seconds) => (!isEmpty(seconds) ? MS_COUNT_IN_SEC * seconds : seconds);
export const convertSecToHours = (seconds) => isEmpty(seconds) ? seconds : seconds / (SEC_COUNT_IN_MIN * MIN_COUNT_IN_HOUR);
export const convertMinToSec = (minutes) => (isEmpty(minutes) ? minutes : minutes * SEC_COUNT_IN_MIN);
export const convertMsToSec = (seconds) => (!isEmpty(seconds) ? seconds / MS_COUNT_IN_SEC : seconds);
export const convertHoursToSec = (hours) => round(hours * MIN_COUNT_IN_HOUR * SEC_COUNT_IN_MIN);
export const convertHoursToMs = (hours) => convertSecToMs(convertHoursToSec(hours));
