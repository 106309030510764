import React from "react";
export const IconPostman = (props) => (React.createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", version: "1.1", viewBox: "0 0 24 24" }, props),
    React.createElement("path", { d: "M19.1232 6.49913C18.0352 5.0902 16.5538 4.03557 14.8663 3.46863C13.1789 2.90169 11.3612 2.84791 9.64317 3.31407C7.92515 3.78024 6.38396 4.74542 5.21451 6.08755C4.04507 7.42968 3.2999 9.08846 3.07325 10.8541C2.8466 12.6198 3.14866 14.413 3.94121 16.007C4.73376 17.6009 5.98122 18.9241 7.5258 19.8091C9.07038 20.694 10.8427 21.1011 12.6186 20.9787C14.3946 20.8563 16.0943 20.2101 17.503 19.1216C21.4348 16.083 22.1604 10.4324 19.1229 6.49913H19.1232Z", fill: "#FF6C37" }),
    React.createElement("path", { d: "M11.2407 12.6648C11.2071 12.6639 11.175 12.6506 11.1507 12.6274L10.0707 11.5474C10.0464 11.5222 10.0328 11.4886 10.0328 11.4536C10.0328 11.4186 10.0464 11.3849 10.0707 11.3598C13.7531 7.67736 14.2557 7.84977 15.1256 8.63727C15.1552 8.66089 15.1718 8.69689 15.1706 8.73486C15.1763 8.77142 15.1622 8.80883 15.1332 8.83245L11.3383 12.6276C11.3116 12.6521 11.277 12.6653 11.2407 12.665V12.6648ZM10.3556 11.4574L11.2407 12.3425L14.8407 8.74245C14.7164 8.58668 14.5391 8.4822 14.3426 8.44899C14.1461 8.41578 13.9442 8.45618 13.7756 8.56245C13.1231 8.85495 12.0732 9.74736 10.3556 11.4574Z", fill: "#FF6C37" }),
    React.createElement("path", { d: "M12.3282 13.6176L11.2932 12.5601L15.0881 8.80994C16.1082 9.80753 14.5931 11.4725 12.3282 13.6173V13.6176Z", fill: "white" }),
    React.createElement("path", { d: "M12.3282 13.7526C12.295 13.75 12.2635 13.7369 12.2382 13.7152L11.1807 12.6802C11.1649 12.6418 11.1649 12.5986 11.1807 12.5601C11.1695 12.5286 11.1695 12.494 11.1807 12.4625L14.9756 8.66763C15.0305 8.61616 15.1157 8.61616 15.1705 8.66763C15.4194 8.89657 15.5558 9.22225 15.5454 9.56004C15.5454 10.5725 14.3903 11.8474 12.4255 13.7076C12.3993 13.7336 12.3647 13.7495 12.3279 13.7526H12.3282ZM11.4881 12.5601L12.3355 13.4073C13.8354 11.9898 15.2681 10.5273 15.2754 9.53725C15.2836 9.32738 15.2142 9.12184 15.0805 8.95985L11.4881 12.5601Z", fill: "#FF6C37" }),
    React.createElement("path", { d: "M10.198 11.4801L10.9481 12.2302C10.9529 12.2344 10.9568 12.2396 10.9594 12.2455C10.962 12.2513 10.9634 12.2576 10.9634 12.264C10.9634 12.2704 10.962 12.2767 10.9594 12.2825C10.9568 12.2883 10.9529 12.2935 10.9481 12.2977L9.36553 12.6352C9.3 12.6493 9.23335 12.6178 9.20185 12.5587C9.17035 12.4997 9.18216 12.4265 9.23053 12.3801L10.153 11.4652C10.153 11.4652 10.1831 11.4652 10.198 11.4801Z", fill: "white" }),
    React.createElement("path", { d: "M9.32806 12.7851C9.27806 12.7845 9.22905 12.7711 9.18572 12.7461C9.14239 12.7211 9.10618 12.6855 9.08056 12.6425C9.0215 12.5309 9.04259 12.3934 9.13315 12.305L10.0481 11.3825C10.0811 11.353 10.1238 11.3367 10.168 11.3367C10.2123 11.3367 10.255 11.353 10.288 11.3825L11.0381 12.1326C11.0825 12.1748 11.0999 12.2387 11.0831 12.2977C11.0662 12.3568 11.0167 12.4038 10.9557 12.4178L9.37306 12.7553L9.32806 12.7851ZM10.1682 11.6452L9.32075 12.4926L10.7233 12.1925L10.1682 11.6452Z", fill: "#FF6C37" }),
    React.createElement("path", { d: "M17.0533 6.62744C16.5105 6.10376 15.6577 6.08182 15.0885 6.57597C14.5192 7.07013 14.4219 7.91838 14.8652 8.52869C15.3084 9.139 16.144 9.31 16.7909 8.92244L15.7708 7.90235L17.0533 6.62744Z", fill: "white" }),
    React.createElement("path", { d: "M16.0407 9.26725C15.7288 9.26325 15.425 9.16757 15.1672 8.99213C14.9093 8.81669 14.7088 8.56924 14.5906 8.28063C14.3487 7.69056 14.4826 7.01332 14.9281 6.55994C15.2283 6.26518 15.6323 6.10004 16.0531 6.10004C16.4738 6.10004 16.8778 6.26518 17.1781 6.55994C17.2068 6.58413 17.2231 6.61985 17.2231 6.65753C17.223 6.67608 17.2189 6.69439 17.2112 6.71122C17.2034 6.72806 17.1921 6.74304 17.1781 6.75513L15.9631 7.90263L16.8856 8.81022C16.9005 8.82449 16.9117 8.84221 16.9183 8.86182C16.9248 8.88143 16.9264 8.90234 16.923 8.92272C16.9207 8.94238 16.9142 8.96132 16.9039 8.97818C16.8935 8.99505 16.8796 9.00943 16.8631 9.02031C16.6152 9.17522 16.3303 9.2607 16.0382 9.26781L16.0407 9.26725ZM16.0407 6.35716C15.5063 6.35997 15.0282 6.68144 14.8277 7.17278C14.6271 7.66413 14.7363 8.22972 15.1109 8.60716C15.4923 8.9905 16.0671 9.10075 16.5658 8.88475L15.6807 7.99966C15.6512 7.97603 15.6346 7.94003 15.6357 7.90207C15.6368 7.86691 15.6531 7.834 15.6807 7.81207L16.8507 6.63447C16.6181 6.45616 16.3338 6.35885 16.0407 6.35688V6.35716Z", fill: "#FF6C37" }),
    React.createElement("path", { d: "M17.0757 6.64996L15.7932 7.92486L16.8133 8.94496C16.916 8.88602 17.0116 8.81553 17.0982 8.73486C17.654 8.14424 17.6438 7.22511 17.0757 6.64996Z", fill: "white" }),
    React.createElement("path", { d: "M16.7905 9.05743C16.7737 9.05835 16.7568 9.05546 16.7413 9.04898C16.7257 9.04251 16.7118 9.03261 16.7005 9.02003L15.6804 7.99993C15.6509 7.97631 15.6343 7.94031 15.6354 7.90234C15.6366 7.86718 15.6529 7.83428 15.6804 7.81234L16.9553 6.55993C16.9676 6.54137 16.9844 6.52614 17.004 6.51561C17.0236 6.50507 17.0456 6.49956 17.0678 6.49956C17.0901 6.49956 17.112 6.50507 17.1317 6.51561C17.1513 6.52614 17.168 6.54137 17.1803 6.55993C17.33 6.70658 17.4489 6.88161 17.5301 7.07479C17.6113 7.26797 17.6531 7.4754 17.6531 7.68493C17.6531 7.89447 17.6113 8.1019 17.5301 8.29508C17.4489 8.48825 17.33 8.66329 17.1803 8.80993C17.092 8.90358 16.991 8.98434 16.8802 9.04984C16.8523 9.0639 16.8201 9.06662 16.7902 9.05743H16.7905ZM15.9656 7.90262L16.813 8.75003C16.8713 8.70917 16.9265 8.66405 16.9781 8.61503C17.2272 8.36659 17.3675 8.02944 17.3682 7.67762C17.3688 7.36277 17.254 7.05862 17.0456 6.82262L15.9656 7.90262Z", fill: "#FF6C37" }),
    React.createElement("path", { d: "M15.1931 8.89263C14.9822 8.69463 14.6539 8.69463 14.443 8.89263L11.0458 12.2823L11.6083 12.8524L15.2007 9.70235C15.3118 9.59632 15.3739 9.44923 15.3722 9.29566C15.3706 9.1421 15.3059 8.99613 15.1931 8.89235V8.89263Z", fill: "white" }),
    React.createElement("path", { d: "M11.5781 12.9876C11.5445 12.9868 11.5124 12.9735 11.4881 12.9502L10.918 12.3801C10.8937 12.355 10.8801 12.3213 10.8801 12.2863C10.8801 12.2513 10.8937 12.2177 10.918 12.1925L14.3155 8.80995C14.585 8.54107 15.0215 8.54107 15.2906 8.80995C15.4256 8.94495 15.4988 9.12917 15.4931 9.31985C15.4853 9.50942 15.4014 9.68773 15.2605 9.81485L11.6681 12.9649C11.642 12.9834 11.6099 12.9913 11.5781 12.9874V12.9876ZM11.1807 12.2823L11.5556 12.6648L15.0805 9.59717C15.1677 9.52123 15.2198 9.41267 15.2231 9.29707C15.2265 9.18148 15.1809 9.06842 15.0957 8.98967C14.9318 8.82654 14.6671 8.82654 14.5031 8.98967L11.1807 12.2823Z", fill: "#FF6C37" }),
    React.createElement("path", { d: "M8.41321 16.1449C8.40362 16.158 8.39844 16.1738 8.39844 16.1899C8.39844 16.2061 8.40362 16.2219 8.41321 16.2349L8.56312 16.8874C8.57606 16.9654 8.53865 17.043 8.46975 17.0843C8.43633 17.1039 8.39758 17.1123 8.35906 17.1084C8.32055 17.1045 8.28426 17.0885 8.25543 17.0627L7.22803 16.0727L10.4306 12.8448H11.5407L12.2908 13.5949C11.1207 14.6179 9.81608 15.4759 8.41321 16.1449Z", fill: "white" }),
    React.createElement("path", { d: "M8.34572 17.2475C8.26078 17.2458 8.17697 17.2109 8.12072 17.1499L7.13832 16.1675C7.10878 16.1438 7.09219 16.1078 7.09332 16.0699C7.09444 16.0347 7.11075 16.0018 7.13832 15.9799L10.3631 12.7475C10.3812 12.7283 10.4048 12.7152 10.4306 12.71H11.548C11.5771 12.6998 11.6089 12.6998 11.638 12.71L12.3881 13.4601C12.4171 13.4869 12.4312 13.5262 12.4255 13.565C12.4261 13.5837 12.4223 13.6022 12.4145 13.6191C12.4067 13.636 12.395 13.6509 12.3805 13.6626L12.3206 13.7152C11.1458 14.6946 9.85332 15.5235 8.47313 16.1826L8.61572 16.7901C8.62674 16.837 8.62717 16.8857 8.61696 16.9328C8.60675 16.9798 8.58616 17.024 8.55671 17.0621C8.52725 17.1002 8.48967 17.1312 8.44669 17.1529C8.40372 17.1746 8.35645 17.1864 8.30832 17.1875L8.34572 17.2475ZM7.43082 16.0699L8.31563 16.9623C8.32387 16.9696 8.33453 16.9737 8.34558 16.9737C8.35663 16.9737 8.36729 16.9696 8.37553 16.9623C8.38105 16.9546 8.38402 16.9454 8.38402 16.936C8.38402 16.9265 8.38105 16.9173 8.37553 16.9097L8.22563 16.2572C8.20144 16.1607 8.24813 16.0603 8.33813 16.0173C9.68122 15.3669 10.9404 14.556 12.088 13.6022L11.4729 12.9871H10.468L7.43082 16.0699Z", fill: "#FF6C37" }),
    React.createElement("path", { d: "M6.45578 16.8425L7.20588 16.0924L8.36097 17.2475L6.52356 17.12C6.45888 17.1153 6.40403 17.0694 6.38856 17.0059C6.3731 16.9423 6.40038 16.8765 6.45606 16.8427L6.45578 16.8425Z", fill: "white" }),
    React.createElement("path", { d: "M8.38308 17.3599L6.55299 17.2325C6.44442 17.2224 6.35105 17.1482 6.3128 17.0489C6.27455 16.9496 6.29789 16.8317 6.37299 16.7524L7.12308 16.0023C7.14825 15.978 7.18188 15.9644 7.21688 15.9644C7.25187 15.9644 7.2855 15.978 7.31067 16.0023L8.45817 17.1498C8.4908 17.1948 8.4908 17.255 8.45817 17.2997C8.44074 17.3279 8.4143 17.349 8.38308 17.3597V17.3599ZM6.55299 16.94L8.05289 17.0376L7.22799 16.265L6.55299 16.94Z", fill: "#FF6C37" }),
    React.createElement("path", { d: "M11.6456 12.9353L10.1305 13.1679L11.008 12.298L11.6456 12.9353Z", fill: "white" }),
    React.createElement("path", { d: "M10.1305 13.3099C10.079 13.3114 10.0321 13.2818 10.0104 13.2349C9.99445 13.2131 9.98584 13.1869 9.98584 13.1599C9.98584 13.1329 9.99445 13.1067 10.0104 13.0849L10.8803 12.2074C10.9352 12.156 11.0204 12.156 11.0752 12.2074L11.7131 12.8448C11.7258 12.865 11.7326 12.8884 11.7326 12.9123C11.7326 12.9361 11.7258 12.9596 11.7131 12.9798C11.7069 13.0044 11.6936 13.0267 11.675 13.044C11.6564 13.0613 11.6332 13.0729 11.6082 13.0774L10.1083 13.3099H10.1305ZM11.008 12.4924L10.5206 12.9798L11.3607 12.8524L11.008 12.4924Z", fill: "#FF6C37" }),
    React.createElement("path", { d: "M11.6456 12.9348L10.5881 13.0698C10.5598 13.0762 10.5302 13.0733 10.5036 13.0616C10.4771 13.0498 10.455 13.0299 10.4407 13.0046C10.4264 12.9794 10.4207 12.9502 10.4243 12.9214C10.4279 12.8926 10.4406 12.8657 10.4607 12.8448L11.0083 12.2972L11.6456 12.9348Z", fill: "white" }),
    React.createElement("path", { d: "M10.5579 13.2126C10.4581 13.2118 10.3667 13.1572 10.318 13.07C10.2894 13.0187 10.2783 12.9594 10.2864 12.9012C10.2945 12.843 10.3214 12.789 10.363 12.7474L10.9106 12.1998C10.9655 12.1484 11.0507 12.1484 11.1055 12.1998L11.7431 12.8374C11.7621 12.8554 11.7754 12.8785 11.7815 12.9039C11.7876 12.9293 11.7862 12.9559 11.7775 12.9805C11.7688 13.0051 11.7531 13.0267 11.7324 13.0426C11.7117 13.0585 11.6868 13.068 11.6607 13.07L10.6108 13.205L10.5579 13.2126ZM10.5579 12.935L11.353 12.8374L11.0006 12.485L10.5506 12.935H10.5579ZM17.0829 7.51254C17.0688 7.48887 17.0465 7.47121 17.0202 7.46292C16.9939 7.45462 16.9655 7.45627 16.9403 7.46754C16.9279 7.47383 16.9169 7.48255 16.9079 7.49317C16.8989 7.5038 16.8922 7.51612 16.888 7.52941C16.8796 7.55613 16.8824 7.5851 16.8953 7.61013C16.9479 7.72066 16.9333 7.85144 16.8579 7.94763C16.8382 7.96619 16.8298 7.99179 16.8298 8.01879C16.8301 8.04516 16.8401 8.07051 16.8579 8.08994C16.8772 8.09754 16.8986 8.09754 16.9178 8.08994C16.9353 8.09433 16.9536 8.09385 16.9708 8.08854C16.988 8.08324 17.0035 8.07331 17.0154 8.05985C17.0772 7.98423 17.1163 7.89269 17.1282 7.7958C17.1402 7.6989 17.1245 7.6006 17.0829 7.51226V7.51254Z", fill: "#FF6C37" })));
