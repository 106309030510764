import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { isEmpty } from "@boomq/utils";
import { Block } from "../styled/Block";
import { media, sizes } from "../styled/response";
import { FontBodyMediumBold, FontBodySmallMedium } from "../styled/typography";
import { Color } from "../styled/colorPallete";
export const Grid = styled.div `
    display: flex;
    flex-direction: column;
    height: 100%;
`;
export const GridItem = styled.li `
    ${Block}
    align-items: center;
    display: flex;
    margin-top: 8px;
    position: relative;
    transition: all 0.3s ease;
    &:hover {
        box-shadow: 0 0 10px rgba(0, 64, 140, 0.35);
    }
    ${media.tablet} {
        align-items: flex-start;
        flex-direction: column;
        padding: 24px 0 16px 24px;
    }
`;
export const GridRowCol = styled.div `
    display: flex;
    ${({ flex }) => isEmpty(flex)
    ? css ``
    : css `
                  flex: ${flex};
              `};
    justify-content: flex-start;
    word-break: break-all;
    &:last-child {
        ${media.tablet} {
            padding-bottom: 0;
        }
    }
    ${media.tablet} {
        padding-bottom: 20px;
    }
`;
export const GridRowColText = styled.p `
    ${FontBodyMediumBold}
    align-items: center;
    color: ${Color.darkGrey35}
    display: flex;
    ${({ flex }) => isEmpty(flex)
    ? css ``
    : css `
                  flex: ${flex};
              `};
    ${({ width }) => isEmpty(width)
    ? css ``
    : css `
                  width: ${width};
              `};
    line-height: normal;
    padding-right: 16px;
    &:first-child {
        margin-top: 0;
    }
    ${media.tablet} {
        margin-left: 10px;
    }
`;
const GridRowStyles = `
    padding: 16px;
    width: 100%;
`;
export const GridRow = styled(GridItem) `
    ${GridRowStyles}
    flex-direction: ${({ windowWidth }) => (windowWidth <= sizes.tablet ? "column" : "row")};
`;
export const GridRowWithLink = styled(Link) `
    ${GridRowStyles}
    display: flex;
    flex-direction: ${({ windowWidth }) => (windowWidth <= sizes.tablet ? "column" : "row")};
`;
export const HeaderColTitle = styled.div `
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    margin-top: 16px;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
`;
export const HeaderColTitleText = styled.h3 `
    ${FontBodySmallMedium}
    color: ${Color.grey50};
    ${({ flex }) => isEmpty(flex)
    ? css ``
    : css `
                  flex: ${flex};
              `};
    ${({ width }) => isEmpty(width)
    ? css ``
    : css `
                  width: ${width};
              `};
    line-height: normal;
    text-align: ${({ align }) => (align ? align : "left")};
    word-break: break-word;
`;
export const ItemList = styled.ul `
    flex: 0 0 auto;
    padding: 14px 0px 17px;
`;
export const PaginationWrapper = styled.div `
    flex: 1 0 auto;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding-bottom: 24px;
`;
