import React from "react";
import { FormattedMessage } from "react-intl";
import { useResize } from "@boomq/utils";
import { LabelSet, PreviewImg, ProjectName, StatusIndicate, StyledLink, StyledText, TestContent, TestDate, TestListItem, TestListItemData } from "./styled";
import { StyledLabel } from "../SettingsSetSidebarItem/styled";
import { TestSlaStatus } from "../TestSlaStatus";
import { sizes } from "../../styled/response";
import { getContrastTextColorByColor } from "../../../helpers/color";
import { isTestCompleted } from "../../../helpers/testStatus";
export const TestItem = ({ children, commentText, commentTitle, displayState, emptyCommentText, labels, noDataText, projectName, slaStatusColor, slaStatusDescription, startDateText, statusColor, statusDescription, testDate, testLink, testResult, userEmail }) => {
    const { width } = useResize();
    return (React.createElement(TestListItem, null,
        React.createElement(StyledLink, { to: testLink },
            React.createElement(TestListItemData, null,
                displayState && React.createElement(StatusIndicate, { title: statusDescription, bg: statusColor }),
                React.createElement(TestContent, { long: true },
                    width <= sizes.tablet && (React.createElement(StyledText, null,
                        React.createElement(FormattedMessage, { id: "account.all.header.test", defaultMessage: "Test" }))),
                    React.createElement(ProjectName, null, projectName),
                    isTestCompleted(displayState) && (React.createElement(TestSlaStatus, { color: slaStatusColor, description: slaStatusDescription }))),
                React.createElement(TestContent, null,
                    React.createElement(LabelSet, null, labels.map((label) => (React.createElement(StyledLabel, Object.assign({ key: label.id }, label, { textColor: getContrastTextColorByColor(label.color) })))))),
                React.createElement(TestContent, null,
                    React.createElement(StyledText, null, userEmail)),
                React.createElement(TestContent, null,
                    width <= sizes.tablet && (React.createElement(StyledText, null,
                        React.createElement(FormattedMessage, { id: "account.all.header.date", defaultMessage: "start date and time" }))),
                    React.createElement(StyledText, null, startDateText),
                    React.createElement(TestDate, null, testDate ? testDate : noDataText)),
                width > sizes.tablet && (React.createElement(React.Fragment, null,
                    React.createElement(TestContent, { title: commentTitle },
                        React.createElement(StyledText, null, commentText && commentText.length > 0 ? commentText : emptyCommentText)))),
                (testResult === null || testResult === void 0 ? void 0 : testResult.usersChartSvg) && testResult.usersChartSvg.length > 0 ? (React.createElement(PreviewImg, { dangerouslySetInnerHTML: { __html: testResult.usersChartSvg } })) : (React.createElement(PreviewImg, null))),
            children)));
};
