export const NEXT_BUTTON_LABEL = {
    defaultMessage: "Next",
    id: "account.test.profile.button.next"
};
export const NEW_THREAD_GROUP = {
    defaultMessage: "New thread group",
    id: "account.test.profile.button.new.thread.group"
};
export const SAVE_TEST_BUTTON_LABEL = {
    defaultMessage: "Save",
    id: "account.test.profile.button.save"
};
export const PROFILE_DISTRIBUTION_LABEL = {
    defaultMessage: "Profile distribution",
    id: "account.test.profile.distribution.label"
};
export const PROFILE_DISTRIBUTION_PERCENT_RADIO_BUTTON_LABEL = {
    defaultMessage: "Percent",
    id: "account.test.profile.distribution.percent.label"
};
export const PROFILE_DISTRIBUTION_USERS_RADIO_BUTTON_LABEL = {
    defaultMessage: "Users",
    id: "account.test.profile.distribution.users.label"
};
