import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useIntl } from "@boomq/utils";
import { ButtonSection, Description } from "../../../common/ConfirmForm";
import { StyledButton } from "../../../styled/Buttons";
import { StyledInput } from "../../../styled/Input";
import { CANCELID, CONFIRMID } from "../../../../helpers/applicationParams";
import { profileSettingsNotification } from "../../../../data/content/notifications";
import { getTestProjectSaveNotification, hideTestProjectNotification } from "../../../../redux/actions/testProjectNotification";
import { saveAsTestProjectRequest } from "../../../../redux/actions/testProjects";
const connector = connect(null, { hideTestProjectNotification, saveAsTestProjectRequest });
const SaveAsTestProjectForm = ({ hideTestProjectNotification, saveAsTestProjectRequest }) => {
    const { formatMessage } = useIntl();
    const [testProjectName, setTestProjectName] = useState("");
    const validation = () => !!testProjectName && testProjectName.trim().length > 0;
    const clickSaveAsHandler = () => saveAsTestProjectRequest({
        projectName: testProjectName,
        postAction: {
            success: {
                actionType: getTestProjectSaveNotification.toString(),
                payload: {
                    getNotificationPayload: (projectId) => profileSettingsNotification({
                        action: hideTestProjectNotification,
                        linkProject: (React.createElement(Link, { to: `/account/tests/${projectId}` }, formatMessage({
                            defaultMessage: "here",
                            id: "account.new.test.project.saved.success.link"
                        })))
                    })
                }
            }
        }
    });
    return (React.createElement("div", null,
        React.createElement(Description, null, formatMessage({
            defaultMessage: "Please provide new test name",
            id: "account.modal.new.test.project.version.save.text"
        })),
        React.createElement(StyledInput, { placeholder: formatMessage({
                defaultMessage: "Test's name",
                id: "account.modal.new.test.project.version.save.placeholder"
            }), value: testProjectName, onChange: (e) => setTestProjectName(e.target.value) }),
        React.createElement(ButtonSection, null,
            React.createElement(StyledButton, { className: "close_modal_window", id: CANCELID, theme: customButtonTheme }, formatMessage({
                defaultMessage: "Cancel",
                id: "account.modal.new.test.project.version.save.button.no"
            })),
            React.createElement(StyledButton, { className: "close_modal_window", disabled: !validation(), id: CONFIRMID, onClick: clickSaveAsHandler, theme: customButtonTheme }, formatMessage({
                defaultMessage: "Create project",
                id: "account.modal.new.test.project.version.save.button.yes"
            })))));
};
const customButtonTheme = {
    width: "40%!important"
};
export default connector(SaveAsTestProjectForm);
