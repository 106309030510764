import axios from "axios";
import { instance } from "../services/authSrv";
const prepareFormDataFile = (file) => {
    const formData = new FormData();
    formData.append("file", file);
    return formData;
};
export const getConfig = (configFile) => (configFile ? instance.get(configFile) : undefined);
export const markUnreadNotify = (id) => instance.post(`/bumq-client/notification?id=${id}`);
export const getSiteAnalytics = ({ top = 5, id, url }) => instance.get(`/api/v1/site/analytics?topUrl=${top}&siteId=${id}&siteUrl=${url}`);
export const sendClientRequest = (params) => instance.post(`/api/v1/client_request`, params);
export const setGrafanaCookie = () => instance.post(`/api/v1/grafana/auth`);
export const uploadCSVFile = ({ file, testProjectId }) => instance.post(`/project-srv/file?testProjectId=${testProjectId}`, prepareFormDataFile(file));
export const uploadJMXFileOLD = (file) => {
    const formData = new FormData();
    formData.append("file", file);
    return instance.post("/api/v2/testplan", formData);
};
export const uploadJMXFile = ({ jmx, support, testProjectId }) => {
    const formData = new FormData();
    formData.append("jmx", new Blob(jmx), jmx[0].name);
    support.forEach((file, index) => {
        formData.append(`files`, new Blob([file]), file.name);
    });
    return instance.post(`/api/v1/file?sourceType=OWN_JMX&testProjectId=${testProjectId}`, formData);
};
export const getTestProjectYamlFile = (url) => axios(url);
export const getTestDetailsReportLink = ({ testProjectId, taskId }) => instance.get(`/api/v1/file?testProjectId=${testProjectId}&taskId=${taskId}`);
