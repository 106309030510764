var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { forwardRef, useEffect, useState } from "react";
import { isEmpty } from "@boomq/utils";
import { Input } from "../Input";
const getNumberValue = (value) => (isNaN(value) ? 0 : Number(value));
const InputNumber = (_a, _ref) => {
    var { decimalCount, isPositive, max, min, onChange, value } = _a, rest = __rest(_a, ["decimalCount", "isPositive", "max", "min", "onChange", "value"]);
    const [inputValue, setInputValue] = useState(getNumberValue(value));
    useEffect(() => {
        setInputValue(getNumberValue(value));
    }, [value]);
    const changeValueHandler = (value) => {
        let newValue = (value || "").replace(",", ".");
        newValue = !isNaN(newValue) ? newValue : inputValue;
        isPositive && (newValue = newValue >= 0 ? newValue : -newValue);
        !isEmpty(max) && (newValue = Number(newValue) > max ? max : newValue);
        !isEmpty(min) && (newValue = Number(newValue) < min ? min : newValue);
        const decimalPosition = newValue.indexOf && newValue.indexOf(".");
        !isEmpty(decimalCount) &&
            decimalPosition > -1 &&
            newValue.substring &&
            (newValue = newValue.substring(0, decimalPosition + decimalCount + 1));
        newValue !== inputValue && setInputValue(newValue);
        typeof onChange === "function" && onChange(getNumberValue(newValue));
    };
    return React.createElement(Input, Object.assign({}, rest, { inputValue: inputValue, onChange: changeValueHandler, ref: _ref }));
};
export default forwardRef(InputNumber);
