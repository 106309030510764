var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { getTestRunsItemTemplateByStatus } from "../../../../models/testRun";
export const TestRunsItem = (_a) => {
    var _b, _c;
    var { task } = _a, restProps = __rest(_a, ["task"]);
    const TestRunsItemTemplate = getTestRunsItemTemplateByStatus(task === null || task === void 0 ? void 0 : task.displayState);
    return (task &&
        TestRunsItemTemplate && (React.createElement(TestRunsItemTemplate, Object.assign({}, restProps, task, { projectName: (_b = task.testProject) === null || _b === void 0 ? void 0 : _b.projectName, totalDuration: (_c = task.testProjectVersion) === null || _c === void 0 ? void 0 : _c.totalDuration }))));
};
