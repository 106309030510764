var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState } from "react";
import { useIntl } from "@boomq/utils";
import { DEFAULT_AGGREGATION_INTERVAL } from "./constants";
import { APPLY_BUTTON_LABEL, CHART_AGGREGATION_INTERVAL_SELECT_LABEL, CHART_AGGREGATION_INTERVAL_SELECT_PLACEHOLDER, CHART_AXIS_SELECT_LABEL, CHART_AXIS_SELECT_PLACEHOLDER, CHART_METRIC_TYPE_SELECT_LABEL, CHART_METRIC_TYPE_SELECT_PLACEHOLDER, CHART_TYPE_SELECT_LABEL, CHART_TYPE_SELECT_PLACEHOLDER, TEST_ID_SELECT_LABEL, TEST_ID_SELECT_PLACEHOLDER } from "./intl";
import { ChartSerieCommonParams } from "../../../../components/account/Report/ChartSerieCommonParams";
import { formatIntlMessage } from "../../../../helpers/intl";
import { generateReportChartTitle } from "../../../../helpers/reportEditor";
const getAggregationIntervalFromProps = (aggregationIntervalTimeUnit) => aggregationIntervalTimeUnit ? aggregationIntervalTimeUnit : DEFAULT_AGGREGATION_INTERVAL;
export const ChartSerieCommonParamsContainer = (_a) => {
    var { aggregationInterval, aggregationIntervalItems, chartId, chartSerieAxisItems, chartTypeItems, id, isDisabledApplyButton = false, metricTypeItems, onChangeChartSerieTest, onChangeChartType, onClickApply, testId, testList, type } = _a, restProps = __rest(_a, ["aggregationInterval", "aggregationIntervalItems", "chartId", "chartSerieAxisItems", "chartTypeItems", "id", "isDisabledApplyButton", "metricTypeItems", "onChangeChartSerieTest", "onChangeChartType", "onClickApply", "testId", "testList", "type"]);
    const [aggregationIntervalTimeUnit, setAggregationIntervalTimeUnit] = useState(getAggregationIntervalFromProps(restProps.aggregationIntervalTimeUnit));
    const [axisUnit, setAxisUnit] = useState(restProps.axisUnit);
    const [metricType, setMetricType] = useState(restProps.metricType);
    const [unit, setUnit] = useState(restProps.unit);
    const { formatMessage } = useIntl();
    const changeAggregationIntervalHandler = (data) => setAggregationIntervalTimeUnit(data.value);
    const changeAxisHandler = (data) => setAxisUnit(data.value);
    const changeChartSerieTestHandler = (data) => onChangeChartSerieTest === null || onChangeChartSerieTest === void 0 ? void 0 : onChangeChartSerieTest(data);
    const changeMetricTypeHandler = (data) => {
        setMetricType(data.value);
        setUnit(data.unit);
        return !axisUnit ? setAxisUnit(data.unit) : undefined;
    };
    const clickApplyHandler = () => onClickApply({
        aggregationIntervalTimeUnit,
        axisUnit,
        chartId,
        chartTitle: generateReportChartTitle({
            metricType,
            metricTypeItems
        }),
        id,
        metricType,
        testId,
        type,
        unit
    });
    const applyButtonLabel = formatIntlMessage(APPLY_BUTTON_LABEL, formatMessage);
    const chartAggregationIntervalSelectLabel = formatIntlMessage(CHART_AGGREGATION_INTERVAL_SELECT_LABEL, formatMessage);
    const chartAggregationIntervalSelectPlaceholder = formatIntlMessage(CHART_AGGREGATION_INTERVAL_SELECT_PLACEHOLDER, formatMessage);
    const chartAxisSelectLabel = formatIntlMessage(CHART_AXIS_SELECT_LABEL, formatMessage);
    const chartAxisSelectPlaceholder = formatIntlMessage(CHART_AXIS_SELECT_PLACEHOLDER, formatMessage);
    const chartMetricTypeSelectLabel = formatIntlMessage(CHART_METRIC_TYPE_SELECT_LABEL, formatMessage);
    const chartMetricTypeSelectPlaceholder = formatIntlMessage(CHART_METRIC_TYPE_SELECT_PLACEHOLDER, formatMessage);
    const chartTypeSelectLabel = formatIntlMessage(CHART_TYPE_SELECT_LABEL, formatMessage);
    const chartTypeSelectPlaceholder = formatIntlMessage(CHART_TYPE_SELECT_PLACEHOLDER, formatMessage);
    const testIdSelectLabel = formatIntlMessage(TEST_ID_SELECT_LABEL, formatMessage);
    const testIdSelectPlaceholder = formatIntlMessage(TEST_ID_SELECT_PLACEHOLDER, formatMessage);
    return (React.createElement(ChartSerieCommonParams, Object.assign({}, restProps, { aggregationInterval: aggregationInterval, aggregationIntervalItems: aggregationIntervalItems, aggregationIntervalTimeUnit: aggregationIntervalTimeUnit, applyButtonLabel: applyButtonLabel, axisItems: chartSerieAxisItems, axisUnit: axisUnit, chartAggregationIntervalSelectLabel: chartAggregationIntervalSelectLabel, chartAggregationIntervalSelectPlaceholder: chartAggregationIntervalSelectPlaceholder, chartAxisSelectLabel: chartAxisSelectLabel, chartAxisSelectPlaceholder: chartAxisSelectPlaceholder, chartMetricTypeLabel: chartMetricTypeSelectLabel, chartMetricTypePlaceholder: chartMetricTypeSelectPlaceholder, chartType: type, chartTypeItems: chartTypeItems, chartTypeSelectLabel: chartTypeSelectLabel, chartTypeSelectPlaceholder: chartTypeSelectPlaceholder, isDisabledApplyButton: isDisabledApplyButton || !type || !metricType, metricType: metricType, metricTypeItems: metricTypeItems, onClickApply: clickApplyHandler, onChangeAggregattionInterval: changeAggregationIntervalHandler, onChangeAxis: changeAxisHandler, onChangeChartMetricType: changeMetricTypeHandler, onChangeChartSerieTest: changeChartSerieTestHandler, onChangeChartType: onChangeChartType, testId: testId, testIdSelectLabel: testIdSelectLabel, testIdSelectPlaceholder: testIdSelectPlaceholder, testList: testList, type: type })));
};
