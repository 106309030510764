import React from "react";
import { FormattedMessage } from "react-intl";
import { getValueByPath } from "@boomq/utils";
import { ScrollingChart, TimeLineSection } from "./styled";
import { PreviewChart } from "../../PreviewChart";
import RangeSlider from "../../../common/RangeSlider";
import { Switcher } from "../../../common/Switcher";
import { SwitchChartSection } from "../../../styled/TestProject";
export const UltimateThreadGroupChart = (props) => {
    const { loadProfile, onChange } = props || {};
    const changeChartDataHandler = (field, value, subfield) => typeof onChange === "function" ? onChange({ field, subfield, value }) : undefined;
    const changeIsMergedChartHandler = (e) => e && e.target && changeChartDataHandler("isMergedChart", e.target.checked);
    const changeTimelineHandler = (field, value) => changeChartDataHandler(field, value, "value");
    return (React.createElement(ScrollingChart, null,
        getValueByPath(loadProfile, "steps", []).length > 1 && (React.createElement(SwitchChartSection, null,
            React.createElement(Switcher, { defaultChecked: getValueByPath(loadProfile, "isMergedChart", true), onClick: changeIsMergedChartHandler }),
            React.createElement(FormattedMessage, { defaultMessage: "Merge steps", id: "account.new.test.project.jmx.chart.merge.steps" }))),
        React.createElement(PreviewChart, { data: getValueByPath(loadProfile, "chartData", []), height: 350, min: getValueByPath(loadProfile, "timeLine.value.0", 0) / 60, max: getValueByPath(loadProfile, "timeLine.value.1", 1) / 60 }),
        React.createElement(TimeLineSection, null,
            React.createElement(RangeSlider, { changeHandler: changeTimelineHandler, label: {
                    defaultMessage: "Time",
                    id: "account.new.test.project.jmx.chart.timeline.range.slider.label"
                }, railStyle: { backgroundColor: "#ddd" }, slider: {
                    min: Math.floor(getValueByPath(loadProfile, "timeLine.min", 0)),
                    max: Math.ceil(getValueByPath(loadProfile, "timeLine.max", 1)),
                    step: 1,
                    value: [
                        Math.floor(getValueByPath(loadProfile, "timeLine.value.0", 0)),
                        Math.ceil(getValueByPath(loadProfile, "timeLine.value.1", 1))
                    ]
                }, units: {
                    defaultMessage: "min",
                    id: "account.new.test.project.jmx.chart.timeline.range.slider.units"
                }, name: "timeLine" }))));
};
