import * as React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import styled from "styled-components";
import { getSiteTitle } from "../../../helpers/title";
import { ModalWindow } from "../../account/ModalWindow";
import { WebSiteModalWindow } from "../../website/ModalWindow";
import Header from "../../admin/Header";
import { AdminWrapper } from "../../styled/Wrappers";
const AdminPage = (props) => {
    document.title = getSiteTitle("Admin");
    return (React.createElement(React.Fragment, null,
        React.createElement(Header, { logo: props.logo }),
        React.createElement(AdminWrapper, null,
            React.createElement(Switch, null,
                (props.routes || []).map(({ exact, path, component }) => (React.createElement(Route, { key: path, exact: exact, path: `/admin/${path}`, component: component }))),
                React.createElement(Redirect, { exact: true, from: "/admin", to: "/admin/users" })),
            React.createElement(ModalWindow, null),
            React.createElement(WebSiteModalWindow, null))));
};
export const Block = styled.div `
    flex: 1;
`;
export const ItemPlate = styled.div `
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    box-shadow: 0 0 8px rgba(0, 64, 140, 0.15);
    border-radius: 5px;
    padding: 21px 39px 21px 29px;
    margin-top: 20px;
`;
export const AdminButton = styled.button `
    cursor: pointer;
    background: ${({ bg }) => (bg ? bg : "#EB5757")};
    color: #ffffff;
    padding: 10px 15px;
    outline: none;
    border: none;
    border-radius: 5px;
    transition: all 0.2s ease-in-out;
    &:active {
        transform: scale(0.9);
    }
    &:last-child {
        margin-left: 20px;
    }
`;
export default AdminPage;
