import styled from "styled-components";
import { Color, FontBodyMediumBold, H3, IconPlus } from "@boomq/uikit";
import { withDisable } from "../../../styled/global";
export const AddLabelLink = styled.div `
    ${FontBodyMediumBold}
    align-items: center;
    color: ${Color.blue};
    cursor: pointer;
    display: flex;
`;
export const StyledH3 = styled(H3) `
    align-items: center;
    display: flex;
    margin-right: 24px;
`;
export const StyledIconPlus = styled(IconPlus) `
    fill: ${Color.blue}
    height: 20px;
    margin-right: 6px;
    width: 20px;
`;
export const TestLabels = styled.div `
    ${withDisable}
    display: flex;
    padding-bottom: ${({ isExistLabels }) => (isExistLabels ? 16 : 24)}px;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 24px;
`;
export const TestLabelsTitle = styled.div `
    border-bottom: 1px solid ${Color.lightGrey95};
    color: ${Color.darkGrey5};
    display: flex;
    padding: 40px 24px 24px;
`;
