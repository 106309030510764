import React, { useEffect } from "react";
import { useResize } from "@boomq/utils";
import { Grid, PaginationWrapper } from "./styled";
import { GridHeader } from "./GridHeader";
import { Pagination } from "../Pagination";
import { sizes } from "../styled/response";
import { GridItems } from "./GridItems";
export const DataGrid = ({ currentPage, columns, items, itemTemplate, onChangePage, onClickItem, onInit, onUnmount, pageSize, pagesCount }) => {
    useEffect(() => {
        const init = () => (typeof onInit === "function" ? onInit() : undefined);
        const unmount = () => (typeof onUnmount === "function" ? onUnmount() : undefined);
        init();
        return unmount;
    }, []);
    const { width } = useResize();
    return (React.createElement(Grid, null,
        width > sizes.tablet && React.createElement(GridHeader, { columns: columns }),
        React.createElement(GridItems, { columns: columns, items: items, ItemTemplate: itemTemplate, onClickItem: onClickItem, windowWidth: width }),
        items && items.length > 0 && pagesCount > 1 && (React.createElement(PaginationWrapper, null,
            React.createElement(Pagination, { totalPages: pagesCount, currentPage: currentPage, clickHandler: onChangePage, pageSize: pageSize })))));
};
