var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { getValueByPath } from "@boomq/utils";
import { InputText } from "@boomq/uikit";
import { JsonPath } from "./styled";
import { AssertionCommonTemplate } from "../AssertionCommonTemplate";
import { ConditionSelect } from "../AssertionCommonWithConditionTemplate";
import { FilterSelect } from "../AssertionResponseBodyTemplate";
import { getAssertionResponseBodyJsonWithoutValueConditionList } from "../../../../helpers/assertion";
import { FormType } from "../../../../helpers/types";
const renderValueInputByCondition = ({ condition, error, mode, onChangeValue, value, valuePlaceholder }) => getAssertionResponseBodyJsonWithoutValueConditionList().includes(condition) ? (React.createElement(React.Fragment, null)) : (React.createElement(InputText, { enabled: mode !== FormType.View, error: getValueByPath(error, "value"), name: "Assertion_value", onBlur: onChangeValue, placeholder: valuePlaceholder, value: value }));
export const AssertionResponseBodyJsonTemplate = (_a) => {
    var { assertionConditionItems, assertionFilterItems, conditionSelectPlaceholder, filterSelectPlaceholder, jsonPathInputPlaceholder, onChangeCondition, onChangeFilter, onChangeJsonPath } = _a, restProps = __rest(_a, ["assertionConditionItems", "assertionFilterItems", "conditionSelectPlaceholder", "filterSelectPlaceholder", "jsonPathInputPlaceholder", "onChangeCondition", "onChangeFilter", "onChangeJsonPath"]);
    const { condition, error, filter, jsonPath, value } = restProps.assertion || {};
    return (React.createElement(AssertionCommonTemplate, Object.assign({}, restProps),
        React.createElement(FilterSelect, { defaultValue: filter, disabled: restProps.mode === FormType.View, dropdownAutoPositionMinItemCount: 3, isAutoPosition: true, items: assertionFilterItems, name: "Assertion_filter", onChange: onChangeFilter, placeholder: filterSelectPlaceholder }),
        React.createElement(JsonPath, { enabled: restProps.mode !== FormType.View, error: getValueByPath(error, "jsonPath"), name: "Assertion_jsonPath", onChange: onChangeJsonPath, placeholder: jsonPathInputPlaceholder, value: jsonPath }),
        React.createElement(ConditionSelect, { defaultValue: condition, disabled: restProps.mode === FormType.View, dropdownAutoPositionMinItemCount: 5, error: getValueByPath(error, "condition"), isAutoPosition: true, items: assertionConditionItems, name: "Assertion_condition", onChange: onChangeCondition, placeholder: conditionSelectPlaceholder }),
        renderValueInputByCondition(Object.assign({ condition, error, value }, restProps))));
};
