const copyElementSelectedDataToClipboard = (element) => {
    try {
        const range = document.createRange();
        const selections = window.getSelection();
        selections && selections.removeAllRanges();
        range.selectNodeContents(element);
        selections && selections.addRange(range);
        document.execCommand("copy");
        selections && selections.removeAllRanges();
        return true;
    }
    catch (err) {
        console.error(err);
        return false;
    }
};
export const copyElementDataToClipboard = (element) => element ? copyElementSelectedDataToClipboard(element) : false;
