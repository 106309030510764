import React from "react";
import { RequestGroupJMXCaptionEditMode as CommonRequestGroupJMXCaptionEditMode } from "@boomq/common";
import { LoadGeneratorsSelectionContainer } from "../../../../containers/account/TestProject/LoadGeneratorsSelection";

const renderLoadGeneratorsSelection = (props) =>
    props.requestGroup && props.requestGroup.enabled ? <LoadGeneratorsSelectionContainer {...props} /> : <></>;

export const RequestGroupJMXCaptionEditMode = (props) => (
    <CommonRequestGroupJMXCaptionEditMode {...props}>
        {renderLoadGeneratorsSelection(props)}
    </CommonRequestGroupJMXCaptionEditMode>
);
