export const DEFAULT_AGGREGATION_INTERVAL_VALUE = 1;
export const REPORT_CHART_SERIE_PARAM_NAME_LIST = [
    "aggregationInterval",
    "aggregationIntervalTimeUnit",
    "hostName",
    "metricType",
    "threadGroupId",
    "testId",
    "transactionId",
    "type"
];
