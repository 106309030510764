var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { DropdownList } from "@boomq/uikit";
import { DropdownListItemTemplate } from "./DropdownListItemTemplate";
import { MenuListItemSingle } from "./Single";
const itemTemplate = ({ data }) => (React.createElement(DropdownListItemTemplate, { data: data }));
export const MenuListItemDropdown = (_a) => {
    var { items = [] } = _a, restProps = __rest(_a, ["items"]);
    const clickHandler = ({ item }) => item && item.to && restProps.history && typeof restProps.history.push === "function"
        ? restProps.history.push(item.to)
        : undefined;
    return (React.createElement(React.Fragment, null,
        React.createElement(MenuListItemSingle, Object.assign({}, restProps)),
        React.createElement(DropdownList, { className: "pflb-dropdown-list", isOpened: true, items: items, itemTemplate: itemTemplate, onClickItem: clickHandler })));
};
