import { select, put, all, takeEvery } from "redux-saga/effects";
import { getMaxInArray, getValueByPath, isEmpty } from "@boomq/utils";
import {
    checkTimersEnabled,
    closeSiteModal,
    newRequestChangeParams,
    newTestActions,
    generateGroupsPercentages,
    getExtractorVariableName,
    getNewRequestParams,
    getRequestSettings,
    getAutoPercentages,
    getRequestGroups,
    getRequestTimers,
    getRequests,
    grpcRequestUrlValidator,
    hideTestProjectNotification,
    isExistEnabledTimer,
    modifyTimers,
    requestTimersSettingsNotification,
    requestUrlValidator,
    requestValidator,
    saveGrpcRequestSettings,
    saveHttpRequestSettings,
    saveThreadGroupRequestCommonFlow,
    setRequestValidatedData,
    showTestProjectNotification,
    testProfilePercMin,
    validatorByRequiredFields
} from "@boomq/common";
import store from "../index";

const { changeRequestGroups, changeRequests } = newTestActions;

function* saveRequestSettingsData(newRequestParams) {
    try {
        const [autoPercentages, requests, requestGroups, timers] = yield all([
            select(getAutoPercentages),
            select(getRequests),
            select(getRequestGroups),
            select(getRequestTimers)
        ]);

        const { groupId, newRequests, modifiedTimersRequestCount } = getRequestSettings({ newRequestParams, requests });
        const groupIndex = requestGroups.length > 0 ? getMaxInArray(requestGroups, "index") + 1 : 1;
        const newRequestGroups = [
            ...requestGroups,
            {
                id: groupId,
                index: groupIndex,
                isOpened: true,
                name: "New group " + groupIndex,
                perc: testProfilePercMin,
                runnerResources: []
            }
        ];

        newRequestParams.groupId === -1 &&
            (yield put(
                changeRequestGroups(autoPercentages ? generateGroupsPercentages(newRequestGroups) : newRequestGroups)
            ));
        yield put(changeRequests(newRequests));
        yield* modifyTimers({ newRequestParams, requests, timers });
        yield put(newRequestChangeParams());

        isExistEnabledTimer(newRequestParams.timerList) && (yield* checkTimersEnabled());

        yield put(closeSiteModal());

        modifiedTimersRequestCount > 1 &&
            (yield put(
                showTestProjectNotification(
                    requestTimersSettingsNotification({
                        action: () => store.dispatch({ type: hideTestProjectNotification.toString() }),
                        timersCount: modifiedTimersRequestCount
                    })
                )
            ));

        yield* saveThreadGroupRequestCommonFlow(newRequestParams);
    } catch (e) {
        console.error(e);
    }
}

function* saveRequestSettingsFlow({ isValid: isInitDataValid, validatedData }) {
    const assertions = validatedData.assertions || [];
    const headers = validatedData.headers || [];
    const metadata = validatedData.metadata || [];
    const filteredExtractFromResponseParameters = (validatedData.extractResponse || []).filter(
        (parameter) => !isEmpty(getExtractorVariableName(parameter))
    );

    const { isValid, ...restValidatedData } = requestValidator({
        assertions,
        extractResponse: filteredExtractFromResponseParameters,
        headers,
        metadata
    });

    return isValid && isInitDataValid
        ? yield* saveRequestSettingsData({
              ...validatedData,
              assertions: restValidatedData.validatedAssertionList,
              extractResponse: restValidatedData.validatedExtractorList,
              headers: restValidatedData.validatedHeaderList,
              metadata: restValidatedData.validatedMetadataList
          })
        : yield* setRequestValidatedData({ errorProperties: validatedData.error, ...restValidatedData });
}

function* saveGrpcRequestSettingsFlow() {
    try {
        const newRequestParams = yield select(getNewRequestParams);

        const { error: urlError, isValid: isValidUrl } = grpcRequestUrlValidator(
            getValueByPath(newRequestParams, "requestUrl", "")
        );
        const { isValid, validatedData } = validatorByRequiredFields(newRequestParams, ["fullMethod"]);

        yield* saveRequestSettingsFlow({
            isValid: isValid && isValidUrl,
            validatedData: {
                ...validatedData,
                error: {
                    ...validatedData.error,
                    requestUrl: urlError
                }
            }
        });
    } catch (e) {
        console.error(e);
    }
}

function* saveHttpRequestSettingsFlow() {
    try {
        const newRequestParams = yield select(getNewRequestParams);

        const { error, isValid } = requestUrlValidator(getValueByPath(newRequestParams, "requestUrl", ""));

        yield* saveRequestSettingsFlow({
            isValid,
            validatedData: {
                ...newRequestParams,
                error: {
                    requestUrl: error
                }
            }
        });
    } catch (e) {
        console.error(e);
    }
}

export function* newRequestFlow() {
    yield takeEvery(saveHttpRequestSettings, saveHttpRequestSettingsFlow);
    yield takeEvery(saveGrpcRequestSettings, saveGrpcRequestSettingsFlow);
}
