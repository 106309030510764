import { call, select, takeEvery } from "redux-saga/effects";
import { setToLocalStorage } from "@boomq/utils";
import { setLanguage } from "../actions/language";
import { getClientInfo, getIsAuthorize } from "../reducers/authorizePlate";
import { languages } from "../../helpers/applicationParams";
import { formatEmail } from "../../helpers/utils";
import { changeUserParams } from "../../services/authSrv";
export function* setLanguageFlow(action) {
    const payload = action && action.payload ? action.payload : "";
    const isAuthorized = yield select(getIsAuthorize);
    const { email, firstName, id, lastName, notificationEnabled } = yield select(getClientInfo);
    const isExistLanguage = languages.indexOf(payload) > -1;
    isExistLanguage && setToLocalStorage("language", payload);
    isExistLanguage &&
        isAuthorized &&
        (yield call(changeUserParams, {
            email: formatEmail(email),
            firstName,
            id,
            language: payload.toUpperCase(),
            lastName,
            notificationEnabled
        }));
}
export function* languageFlow() {
    yield takeEvery(setLanguage, setLanguageFlow);
}
