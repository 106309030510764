var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { convertSecToHours, convertSize, getValueByPath, SizeUnit } from "@boomq/utils";
import { ReportChartAggregationInterval, ReportChartType } from "../../../models/reportEditor/enums";
const aggregationIntervalTimeUnitByInterval = {
    5: ReportChartAggregationInterval.Seconds5,
    10: ReportChartAggregationInterval.Seconds10
};
const getAggregationIntervalTimeUnitByValue = (aggregationInterval, aggregationIntervalTimeUnit) => aggregationInterval in aggregationIntervalTimeUnitByInterval
    ? aggregationIntervalTimeUnitByInterval[aggregationInterval]
    : aggregationIntervalTimeUnit;
export const reportChartSerieAggregationIntervalDataByInterval = ({ aggregationInterval, aggregationIntervalTimeUnit }) => ({
    aggregationInterval,
    aggregationIntervalTimeUnit: getAggregationIntervalTimeUnitByValue(aggregationInterval, aggregationIntervalTimeUnit)
});
const formatPoint = ({ timeAbsolute, timeRelativeSec, value }, pointUnit) => ({
    timeAbsolute,
    timeRelativeHour: convertSecToHours(timeRelativeSec),
    value,
    [`value${pointUnit}`]: value
});
const formatPointForSysMetricInBytes = (_a, pointUnit) => {
    var { value } = _a, restParams = __rest(_a, ["value"]);
    const formattedValue = convertSize(value, SizeUnit.Bytes, SizeUnit.Mb);
    return Object.assign(Object.assign({}, formatPoint(Object.assign({ value: formattedValue }, restParams), pointUnit)), { valueInBytes: value });
};
const reportChartSerieCommonDataParser = (_a) => {
    var _b, _c;
    var { datasets, formatPointFunc } = _a, restSerieData = __rest(_a, ["datasets", "formatPointFunc"]);
    const pointUnit = ((_b = restSerieData === null || restSerieData === void 0 ? void 0 : restSerieData.displayProps) === null || _b === void 0 ? void 0 : _b.axisUnit) || ((_c = restSerieData === null || restSerieData === void 0 ? void 0 : restSerieData.displayProps) === null || _c === void 0 ? void 0 : _c.unit);
    return Object.assign(Object.assign(Object.assign({}, restSerieData), reportChartSerieAggregationIntervalDataByInterval(restSerieData)), { dataKey: `value${pointUnit}`, legend: getValueByPath(datasets, "0.legend"), points: getValueByPath(datasets, "0.points", []).map((point) => formatPointFunc === null || formatPointFunc === void 0 ? void 0 : formatPointFunc(point, pointUnit)) });
};
const reportChartSerieLoadProfileParser = (_a) => {
    var { vuserType } = _a, restData = __rest(_a, ["vuserType"]);
    return (Object.assign(Object.assign({}, reportChartSerieCommonDataParser(Object.assign({ formatPointFunc: formatPoint }, restData))), { metricType: vuserType }));
};
const reportChartSerieSystemMetricParser = (_a) => {
    var { metricType, systemMetricFilter } = _a, restData = __rest(_a, ["metricType", "systemMetricFilter"]);
    const formatPointFunc = metricType.includes("_BYTES") ? formatPointForSysMetricInBytes : formatPoint;
    return Object.assign(Object.assign({}, reportChartSerieCommonDataParser(Object.assign({ formatPointFunc }, restData))), { hostName: (systemMetricFilter === null || systemMetricFilter === void 0 ? void 0 : systemMetricFilter.hostName) || "", metricType });
};
const reportChartSerieTestMetricParser = (_a) => {
    var { metricType } = _a, restData = __rest(_a, ["metricType"]);
    return (Object.assign(Object.assign({}, reportChartSerieCommonDataParser(Object.assign({ formatPointFunc: formatPoint }, restData))), { metricType }));
};
const reportChartSerieParserByChartType = {
    [ReportChartType.LoadProfile]: reportChartSerieLoadProfileParser,
    [ReportChartType.SystemMetric]: reportChartSerieSystemMetricParser,
    [ReportChartType.TestMetric]: reportChartSerieTestMetricParser,
    [ReportChartType.TransactionMetric]: reportChartSerieTestMetricParser
};
export const parseReportChartSerieData = (data) => (data === null || data === void 0 ? void 0 : data.type) in reportChartSerieParserByChartType
    ? reportChartSerieParserByChartType[data.type](data)
    : reportChartSerieCommonDataParser(data);
export const parseReportChartData = ({ id, displayProps, name, series }) => ({
    chart: Object.assign({ id, title: name }, displayProps),
    series: series.map((serieData) => (Object.assign({ chartId: id }, parseReportChartSerieData(serieData))))
});
export const parseReportCharts = (charts) => (charts || []).reduce((res, chartData) => {
    const { chart, series } = parseReportChartData(chartData);
    return {
        charts: [...res.charts, chart],
        series: [...res.series, ...series]
    };
}, { charts: [], series: [] });
