import { put, takeEvery } from "redux-saga/effects";
import { importDataToTestProjectModalForm, getSiteModal, showImportDataToTestProjectForm } from "@boomq/common";

function* showImportDataToTestProjectFormFlow({ payload }) {
    try {
        yield put(getSiteModal(importDataToTestProjectModalForm(payload.value)));
    } catch (e) {
        return;
    }
}

export function* importTestProjectDataFlow() {
    yield takeEvery(showImportDataToTestProjectForm, showImportDataToTestProjectFormFlow);
}
