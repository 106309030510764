import { initialState } from "./clientWebsites";
const getStateParam = (state, paramName) => state && state.clientWebsites ? state.clientWebsites[paramName] : initialState[paramName];
export const getWebsitesClientsByType = (state, type) => Object.keys(getStateParam(state, type));
const getWebsitesClientsDataByType = (state, type) => getStateParam(state, type);
export const getWebsitesByType = (state, type) => getWebsitesClientsByType(state, type).reduce((res, identityUserId) => {
    const userWebsitesData = getWebsitesClientsDataByType(state, type)[identityUserId];
    return Array.isArray(userWebsitesData) ? [...res, ...userWebsitesData] : res;
}, []);
export const getWebsitesFromStore = (state) => state.clientWebsites
    ? Object.keys(state.clientWebsites).reduce((res, providerType) => [...res, ...getWebsitesByType(state, providerType)], [])
    : [];
