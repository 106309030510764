import React, { FC } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getValueByPath, useIntl } from "@boomq/utils";
import { Select } from "@boomq/uikit";
import { newTestActions } from "@boomq/common";
import { RESOURCE_DEFAULT_TEXT, RESOURCE_TEXT } from "./intl";
import { LoadingStation } from "./styled";
import { Handlers, Props, ResourceType } from "./types";
import { ResourceBodyTemplate } from "./ResourceBodyTemplate";
import { SelectionType } from "../../../helpers/types";
import { getRunnerResources } from "../../../redux/reducers/runnerResources";

const connector = connect(
    (state) => ({
        resources: getRunnerResources(state)
    }),
    { onChangeRequestGroupField: newTestActions.changeRequestGroup }
);

const prepareHostUrl = (resource): string => {
    const { ip, port } = resource || {};
    const urlPort = port ? `:${port}` : "";

    return `${ip}${urlPort}`;
};

const LoadGeneratorsSelection: FC<Props & Handlers> = (props: Props & Handlers): JSX.Element => {
    const changeHandler = (resource): void =>
        props.onChangeRequestGroupField({
            field: "runnerResources",
            id: props.requestGroup?.id || "",
            value: resource.id === -1 ? [] : [resource.value]
        });

    const { formatMessage } = useIntl();

    const getDefaultText = (): string => formatMessage(RESOURCE_DEFAULT_TEXT);

    const getResourceText = (resource): string =>
        formatMessage(RESOURCE_TEXT, {
            resourceInfo: `${prepareHostUrl(resource)} - ${resource.allocatedUsers || 0}/${resource.maxUserCount || 0}`
        });

    const getValue = (runnerResources): any[] =>
        (runnerResources || []).map((runnerResource) => ({ id: runnerResource }));

    const prepareResource = (resource): ResourceType => ({
        id: resource.id,
        bodyText: prepareHostUrl(resource),
        text: getResourceText(resource),
        value: resource.id,
        type: SelectionType.Multiple
    });

    const getResourceList = (): ResourceType[] => [
        {
            id: -1,
            bodyText: getDefaultText(),
            text: getDefaultText(),
            value: "automatic",
            type: SelectionType.Single
        },
        ...(props.resources || []).map((resource) => prepareResource(resource))
    ];

    return (
        <LoadingStation theme={props.theme}>
            <Select
                bodyTemplate={ResourceBodyTemplate}
                defaultValue={"automatic"}
                disabled={!getValueByPath(props, "requestGroup.enabled", true)}
                items={getResourceList()}
                onChange={changeHandler}
                selectionType={SelectionType.Single}
                value={getValue(props.requestGroup ? props.requestGroup.runnerResources : [])}
            />
        </LoadingStation>
    );
};

export default withRouter(connector(LoadGeneratorsSelection));
