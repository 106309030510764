import { call, put, select, takeEvery } from "redux-saga/effects";
import { excludeArrayFromArray, isEmpty } from "@boomq/utils";
import { searchLabelsByName, setSearchedLabels, testProjectSettingsSetsChangeFilterLabelsRequest, testProjectSettingsSetsChangeParamsField, testProjectSettingsSetsRequest, testProjectSettingsSetsSuccess, testProjectSettingsSetsFailure } from "../actions/testProjectSettingsSets";
import { DEFAULT_TEST_PROJECT_SETTINGS_SETS_PAGE_SIZE, getTestProjectSettingsSetsFilterLabels, getTestProjectSettingsSetsLabelList } from "../reducers/testProjectSettingsSets";
import { getTestProjectSettingsSetFromListById } from "../sagas/testProjectSettingsSet";
import { getResponseErrorData } from "../../helpers/errors";
import { prepareLabels } from "../../helpers/labels";
import { getTestProjectSettingsSetsLabelsByQuery, getTestProjectSettingsSetList } from "../../services/projectSrv";
function* labelsSearchingFlow(action) {
    const { id, query } = action && action.payload ? action.payload : {};
    const { labelSet = [] } = yield* getTestProjectSettingsSetFromListById(id);
    try {
        const { data } = yield call(getTestProjectSettingsSetsLabelsByQuery, query);
        const searchedLabels = prepareLabels(excludeArrayFromArray(data, labelSet));
        yield put(setSearchedLabels({ id, searchedLabels }));
    }
    catch (e) {
        yield put(setSearchedLabels({ id, searchedLabels: [] }));
    }
}
export function* refreshTestProjectSettingsSetList(page, filterLabels = []) {
    try {
        const { data } = yield call(getTestProjectSettingsSetList, {
            label: filterLabels.map((label) => label.name),
            page,
            size: DEFAULT_TEST_PROJECT_SETTINGS_SETS_PAGE_SIZE
        });
        yield put(testProjectSettingsSetsSuccess(data));
    }
    catch (e) {
        yield put(testProjectSettingsSetsFailure(getResponseErrorData(e)));
    }
}
export function* updateTestProjectSettingsSetsLabels() {
    const { data: labelList } = yield call(getTestProjectSettingsSetsLabelsByQuery, "");
    yield put(testProjectSettingsSetsChangeParamsField({ field: "labelList", value: prepareLabels(labelList) }));
}
export function* updateTestProjectSettingsSetsFilterLabelsByLabelList() {
    const filterLabels = yield select(getTestProjectSettingsSetsFilterLabels);
    const labelList = yield select(getTestProjectSettingsSetsLabelList);
    const labels = labelList.map((labelItem) => labelItem.label);
    const filterLabelsByLabelList = filterLabels.filter((filterLabelItem) => labels.includes(filterLabelItem.label));
    yield put(testProjectSettingsSetsChangeParamsField({ field: "filterLabels", value: filterLabelsByLabelList }));
}
export function* refreshTestProjectSettingsSetsLabels() {
    try {
        const labelList = yield select(getTestProjectSettingsSetsLabelList);
        return isEmpty(labelList) ? yield* updateTestProjectSettingsSetsLabels() : undefined;
    }
    catch (e) {
        console.error(e);
    }
}
function* testProjectSettingsSetListFlow(action) {
    const { page } = action && action.payload ? action.payload : {};
    const filterLabels = yield select(getTestProjectSettingsSetsFilterLabels);
    yield* refreshTestProjectSettingsSetsLabels();
    yield* refreshTestProjectSettingsSetList(page, filterLabels);
}
function* testProjectSettingsSetsFilterLabelChangingFlow(action) {
    try {
        const filterLabels = action && action.payload ? action.payload : [];
        yield put(testProjectSettingsSetsChangeParamsField({ field: "filterLabels", value: filterLabels }));
        yield* refreshTestProjectSettingsSetList(0, filterLabels);
    }
    catch (e) {
        console.error(e);
    }
}
export function* testProjectSettingsSetsFlow() {
    yield takeEvery(searchLabelsByName, labelsSearchingFlow);
    yield takeEvery(testProjectSettingsSetsChangeFilterLabelsRequest, testProjectSettingsSetsFilterLabelChangingFlow);
    yield takeEvery(testProjectSettingsSetsRequest, testProjectSettingsSetListFlow);
}
