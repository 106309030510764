import { call, put, takeEvery } from "redux-saga/effects";
import { addTeamMemberInNewTeam, checkAuthorizedUserByTeamMemberPermissionsUpdating, excludeTeamMemberInNewTeam, initTeamMembersFlow, inviteLinkCopingFlow, refreshTeamMembers, updateTeamMemberFlow } from "../teamMembers";
import { getResponseErrorData } from "../../../helpers/errors";
import { NEW_ITEM_ID } from "../../../helpers/routes";
import { formatDataToTeamMemberDto } from "../../../helpers/teams";
import { adminAddTeamMember, adminChangeTeamMember, adminChangeTeamMembersPage, adminCopyInviteLink, adminExcludeTeamMember, adminInitTeamMembers } from "../../actions/admin/teamMembers";
import { addTeamMemberFailure, changeTeamMemberFailure, excludeTeamMemberFailure, setTeamMembersLoading, updateTeamMember } from "../../actions/teamMembers";
import { deleteAdminTeamMember, getAdminTeamMembers, inviteAdminTeamMember, updateAdminTeamMembers } from "../../../services/authSrv";
function* refreshAdminTeamMembersFlow(action) {
    yield* refreshTeamMembers(action, getAdminTeamMembers);
}
function* addTeamMemberInExistAdminTeam(teamId, data) {
    try {
        const teamMembersData = [formatDataToTeamMemberDto(data)];
        yield put(setTeamMembersLoading(true));
        yield call(inviteAdminTeamMember, teamId, teamMembersData);
        yield* refreshAdminTeamMembersFlow({ payload: { teamId, page: 0 } });
    }
    catch (e) {
        yield put(setTeamMembersLoading(false));
        yield put(addTeamMemberFailure(getResponseErrorData(e)));
    }
}
function* teamMemberAddingFlow(action) {
    const { teamId, data } = action && action.payload ? action.payload : {};
    return teamId === NEW_ITEM_ID
        ? yield* addTeamMemberInNewTeam(data)
        : yield* addTeamMemberInExistAdminTeam(teamId, data);
}
function* changeTeamMemberInExistAdminTeam(teamId, data) {
    try {
        yield put(setTeamMembersLoading(true));
        yield* updateTeamMemberFlow({ teamId, data }, updateAdminTeamMembers);
        yield* refreshAdminTeamMembersFlow({ payload: { teamId } });
        yield* checkAuthorizedUserByTeamMemberPermissionsUpdating({ teamId, data });
    }
    catch (e) {
        yield put(setTeamMembersLoading(false));
        yield put(changeTeamMemberFailure(getResponseErrorData(e)));
    }
}
function* teamMemberChangingFlow(action) {
    const { teamId, data } = action && action.payload ? action.payload : {};
    return teamId === NEW_ITEM_ID
        ? yield put(updateTeamMember(data))
        : yield* changeTeamMemberInExistAdminTeam(teamId, data);
}
function* userInviteLinkCopingFlow(action) {
    yield* inviteLinkCopingFlow(action, updateAdminTeamMembers);
}
function* excludeTeamMemberInExistAdminTeam(teamId, data) {
    try {
        const teamMemberToDeleting = data.id ? [data] : [];
        yield put(setTeamMembersLoading(true));
        yield call(deleteAdminTeamMember, teamId, teamMemberToDeleting);
        yield* refreshAdminTeamMembersFlow({ payload: { teamId, page: 0 } });
    }
    catch (e) {
        yield put(setTeamMembersLoading(false));
        yield put(excludeTeamMemberFailure(getResponseErrorData(e)));
    }
}
function* teamMemberExcludingFlow(action) {
    const { teamId, data } = action && action.payload ? action.payload : {};
    return teamId === NEW_ITEM_ID
        ? yield* excludeTeamMemberInNewTeam(data)
        : yield* excludeTeamMemberInExistAdminTeam(teamId, data);
}
function* initAdminTeamMembersFlow(action) {
    yield* initTeamMembersFlow(action, getAdminTeamMembers);
}
export function* adminTeamMembersFlow() {
    yield takeEvery(adminAddTeamMember, teamMemberAddingFlow);
    yield takeEvery(adminChangeTeamMember, teamMemberChangingFlow);
    yield takeEvery(adminChangeTeamMembersPage, refreshAdminTeamMembersFlow);
    yield takeEvery(adminCopyInviteLink, userInviteLinkCopingFlow);
    yield takeEvery(adminExcludeTeamMember, teamMemberExcludingFlow);
    yield takeEvery(adminInitTeamMembers, initAdminTeamMembersFlow);
}
