export const tableHeaderColumns = [
    {
        align: "left",
        text: { id: "account.all.sla.report.profile.header.name", defaultMessage: "Name" }
    },
    {
        align: "left",
        text: { id: "account.all.sla.report.profile.header.project.name", defaultMessage: "Test name" }
    },
    {
        text: { id: "title.empty", defaultMessage: " " }
    }
];
