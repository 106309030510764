export var RequestTimerParams;
(function (RequestTimerParams) {
    RequestTimerParams["Id"] = "id";
    RequestTimerParams["ConstantValueParam"] = "duration";
    RequestTimerParams["Enabled"] = "enabled";
    RequestTimerParams["isBoomqTimer"] = "isBoomqTimer";
    RequestTimerParams["RandomValueParamMin"] = "lowerLimit";
    RequestTimerParams["RandomValueParamMax"] = "upperLimit";
    RequestTimerParams["RandomTimerType"] = "randomTimerType";
    RequestTimerParams["OtherTimerType"] = "otherTimerType";
    RequestTimerParams["Type"] = "timerType";
})(RequestTimerParams || (RequestTimerParams = {}));
export var RequestTimerType;
(function (RequestTimerType) {
    RequestTimerType["Constant"] = "CONSTANT";
    RequestTimerType["Other"] = "OTHER";
    RequestTimerType["Random"] = "RANDOM";
})(RequestTimerType || (RequestTimerType = {}));
export var RequestRandomTimerType;
(function (RequestRandomTimerType) {
    RequestRandomTimerType["Gaussian"] = "GAUSSIAN_RANDOM_TIMER";
    RequestRandomTimerType["Poisson"] = "POISSON_RANDOM_TIMER";
    RequestRandomTimerType["Uniform"] = "UNIFORM_RANDOM_TIMER";
})(RequestRandomTimerType || (RequestRandomTimerType = {}));
export var RequestOtherTimerType;
(function (RequestOtherTimerType) {
    RequestOtherTimerType["ConstantThroughput"] = "CONSTANT_THROUGHPUT_TIMER";
    RequestOtherTimerType["PreciseThroughput"] = "PRECISE_THROUGHPUT_TIMER";
    RequestOtherTimerType["Synchronizing"] = "SYNCHRONIZING_TIMER";
    RequestOtherTimerType["ThroughputShaping"] = "THROUGHPUT_SHAPING_TIMER";
})(RequestOtherTimerType || (RequestOtherTimerType = {}));
