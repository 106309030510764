import React from "react";
import { TestProjectsSettingsSetsWizardItem } from "../../../helpers/types";
import { TEST_PROJECTS_SETTINGS_SETS_WIZARD_PROJECTS, TEST_PROJECTS_SETTINGS_SETS_WIZARD_SETTINGS } from "./intl";
import { Button, StyledIconFolder, StyledIconSettings, StyledWizard, Text } from "./styled";
const TestProjectsSettingsSetsWizard = ({ selectedWizardItem }) => {
    const isSelectedStep = (wizardItem) => selectedWizardItem === wizardItem;
    return (React.createElement(StyledWizard, null,
        React.createElement(Button, { selected: isSelectedStep(TestProjectsSettingsSetsWizardItem.Projects), to: "/account/tests" },
            React.createElement(StyledIconFolder, { selected: isSelectedStep(TestProjectsSettingsSetsWizardItem.Projects) }),
            React.createElement(Text, Object.assign({}, TEST_PROJECTS_SETTINGS_SETS_WIZARD_PROJECTS))),
        React.createElement(Button, { selected: isSelectedStep(TestProjectsSettingsSetsWizardItem.ProjectsSettings), to: "/account/test-settings" },
            React.createElement(StyledIconSettings, { selected: isSelectedStep(TestProjectsSettingsSetsWizardItem.ProjectsSettings) }),
            React.createElement(Text, Object.assign({}, TEST_PROJECTS_SETTINGS_SETS_WIZARD_SETTINGS)))));
};
export default TestProjectsSettingsSetsWizard;
