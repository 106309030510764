import styled from "styled-components";
export const customHintStepTheme = {
    marginLeft: "10px",
    "svg path": {
        fill: "#fff"
    }
};
export const LoadProfileStepName = styled.div `
    background: #4989d6;
    border-radius: 9px;
    color: #fff;
    display: flex;
    padding: 15px 20px;
`;
