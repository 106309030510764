import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { isEmpty, isJson, parseJson, useIntl } from "@boomq/utils";
import { TEXTAREA_PLACEHOLDER } from "./intl";
import { CustomTextArea, JsonViewerBlock, RequestBodyContent, TextAreaSection } from "./styled";
import { JsonViewer } from "../../../common/JsonViewer";
import { RESPONSE_BODY_PREVIEW_MODE_LABEL_MESSAGE } from "../RequestResponsePreview";
import { BodyPreviewModeSwitcher } from "../RequestResponsePreviewData";
import { FormType } from "../../../../helpers/types";
import { withResize } from "../../../styled/response";
import { newRequestChangeParamsField } from "../../../../redux/actions/newRequest";
import { getNewRequestParamsBodyField } from "../../../../redux/reducers/newRequest";
const connector = connect((state) => ({
    textParam: getNewRequestParamsBodyField("TEXT")(state)
}), { onChangeField: newRequestChangeParamsField });
const renderJsonViewer = ({ bodyText, onChange }) => {
    const parsedJson = parseJson(bodyText);
    const json = isEmpty(parsedJson) ? bodyText : parsedJson;
    return isJson(bodyText) && !["{}", "[]"].includes(bodyText) ? (React.createElement(JsonViewer, { src: json, iconStyle: "triangle", enableClipboard: false, onAdd: onChange, onEdit: onChange, onDelete: onChange })) : (bodyText);
};
const BodyText = ({ mode, onChangeField, textParam = "" }) => {
    const { formatMessage } = useIntl();
    const [isBodyBeautifyMode, setIsBodyBeautifyMode] = useState(false);
    const changeHandler = (val) => onChangeField({
        field: "body",
        subfield: "TEXT",
        value: val
    });
    const changeBodyJsonHandler = ({ updated_src }) => changeHandler(JSON.stringify(updated_src));
    const toggleBodyBeautifyModeHandler = () => setIsBodyBeautifyMode(!isBodyBeautifyMode);
    const responseBodyPreviewModeLabel = formatMessage(RESPONSE_BODY_PREVIEW_MODE_LABEL_MESSAGE);
    return (React.createElement(RequestBodyContent, null,
        React.createElement(BodyPreviewModeSwitcher, { name: "bodyPreviewMode", defaultChecked: isBodyBeautifyMode, label: responseBodyPreviewModeLabel, onChange: toggleBodyBeautifyModeHandler }),
        isBodyBeautifyMode ? (React.createElement(JsonViewerBlock, null, renderJsonViewer({ bodyText: textParam, onChange: changeBodyJsonHandler }))) : (React.createElement(TextAreaSection, { disabled: mode === FormType.View },
            React.createElement(CustomTextArea, { disabled: mode === FormType.View, onChange: (e) => changeHandler(e.target.value), placeholder: formatMessage(TEXTAREA_PLACEHOLDER), rows: "14", value: textParam || "" })))));
};
export default withResize(withRouter(connector(BodyText)));
