import { requestCommonParamList } from "./common";
import { DEFAULT_GRPC_METHOD_ITEM_COLOR } from "./constants";
import { GrpcMethod, GrpcMethodColor } from "./enums";
export const getGrpcMethodItemColor = (method) => GrpcMethodColor[method] ? GrpcMethodColor[method] : DEFAULT_GRPC_METHOD_ITEM_COLOR;
export const getGrpcMethodItemsWithParams = () => Object.keys(GrpcMethod).map((grpcMethod, index) => ({
    bgColor: getGrpcMethodItemColor(grpcMethod),
    id: index,
    text: grpcMethod,
    value: GrpcMethod[grpcMethod]
}));
export const requestGrpcParamList = [
    {
        id: 0,
        component: {
            elementName: "Metadata",
            props: {}
        },
        name: "metadata",
        title: { defaultMessage: "Metadata", id: "account.request.form.metadata.title" }
    },
    requestCommonParamList[2],
    {
        id: 3,
        component: {
            elementName: "ExtractResponseParameters",
            props: {}
        },
        name: "responseParams",
        title: { defaultMessage: "Extract from response", id: "account.request.form.extract.response.title" }
    },
    {
        id: 4,
        component: {
            elementName: "Assertions",
            props: {}
        },
        name: "assertions",
        title: { defaultMessage: "Assertions", id: "account.request.form.assertions.title" }
    },
    {
        id: 5,
        component: {
            elementName: "RequestTimers",
            props: {}
        },
        name: "timers",
        title: { defaultMessage: "Timers", id: "account.request.form.timers.title" }
    }
];
