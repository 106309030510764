import styled from "styled-components";
import { Color, IconArrowLeft, withShadow } from "@boomq/uikit";
import { getZIndex } from "../global";
export const ACTIONS_PANEL_PADDING_TOP_BOTTOM = 16;
const ACTIONS_PANEL_BORDER_TOP_RADIUS = 12;
export const ActionsPanel = styled.div `
    ${withShadow}
    align-items: center;
    background: ${Color.white};
    border-top-right-radius: ${ACTIONS_PANEL_BORDER_TOP_RADIUS}px;
    border-top-left-radius: ${ACTIONS_PANEL_BORDER_TOP_RADIUS}px;
    bottom: 0;
    display: flex;
    justify-content: center;
    padding-bottom: ${ACTIONS_PANEL_PADDING_TOP_BOTTOM}px;
    padding-top: ${ACTIONS_PANEL_PADDING_TOP_BOTTOM}px;
    z-index: ${getZIndex("ActionsPanel")};
`;
export const BackButton = styled(IconArrowLeft) `
    cursor: pointer;
    fill: ${Color.blue};
    &:hover {
        transform: scale(1.2);
        transition: all 0.3s ease-in-out;
    }
`;
export const titleTheme = {
    fontSize: "24px",
    fontWeight: 600,
    height: "34px",
    minHeight: "34px"
};
const BlockHeader = styled.div `
    align-items: center;
    display: flex;
`;
export const BlockHeaderWithMarginTop = styled(BlockHeader) `
    margin-top: 32px;
`;
export const PageFixedActionsPanel = styled(ActionsPanel) `
    position: fixed;
    width: calc(100% - 64px);
`;
