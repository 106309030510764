var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { arrayToObject, getUniqueId, getValueByPath, isEmpty } from "@boomq/utils";
import { DEFAULT_CONFIG_ELEMENT_DATA, DEFAULT_CSV_PARAMETER_FILE_ENCODING, DEFAULT_RANDOM_CSV_DATASET_CONFIG_LABEL_PREFIX, DEFAULT_HTTP_COOKIE_MANAGER_LABEL, DEFAULT_HTTP_HEADER_MANAGER_LABEL, DEFAULT_HTTP_REQUEST_DEFAULTS_LABEL, DEFAULT_USER_DEFINED_VARIABLES_LABEL, HTTP_COOKIE_MANAGER_MODEL_SCHEMA_NAME, HTTP_HEADER_MANAGER_MODEL_SCHEMA_NAME, HTTP_REQUEST_DEFAULTS_MODEL_SCHEMA_NAME, RANDOM_CSV_DATASET_CONFIG_MODEL_SCHEMA_NAME, USER_DEFINED_PARAMETERS_MODEL_SCHEMA_NAME, VARIABLE_MODEL_SCHEMA_NAME } from "./constants";
import { TIMEOUT_TIME_COEFFICIENT } from "../parsers/constants";
import { getTestProjectModelElDefaultValues } from "../testProjectModelSchema";
import { ConfigurationElementType } from "../types";
const prepareCsvFileData = (files = []) => Array.isArray(files)
    ? {
        fileId: getValueByPath(files, "0.id", ""),
        filename: getValueByPath(files, "0.name", ""),
        fileEncoding: DEFAULT_CSV_PARAMETER_FILE_ENCODING
    }
    : {};
const getConfigElementId = (id) => id || getUniqueId();
const getCsvDatasetLabel = (label, index) => label ? label : `${DEFAULT_RANDOM_CSV_DATASET_CONFIG_LABEL_PREFIX} ${index}`;
const generateRandomCsvDatasetConfig = (parameter, index = 0, modelSchema) => {
    const { delimiter, id, files, hasHeaders, independentListPerThread, label, location, preview, randomOrder, recycle, parameterNames } = parameter || {};
    return Object.assign(Object.assign(Object.assign(Object.assign({}, getTestProjectModelElDefaultValues(modelSchema, RANDOM_CSV_DATASET_CONFIG_MODEL_SCHEMA_NAME)), { delimiter, id: getConfigElementId(id) }), prepareCsvFileData(files)), { firstLineIsCsvHeader: hasHeaders, independentListPerThread, label: getCsvDatasetLabel(label, index), location,
        preview,
        randomOrder, rewindOnEndOfList: recycle, type: ConfigurationElementType.RandomCsvDatasetConfig, variablesNames: parameterNames });
};
export const prepareRandomCsvDatasetConfig = (csvParameters = [], modelSchema) => (csvParameters || []).reduce((res, csvParameter, index) => {
    const parameter = csvParameter || {};
    return [
        ...res,
        generateRandomCsvDatasetConfig(parameter, index, modelSchema)
    ];
}, []);
const getUserDefinedVariablesLabel = (data) => { var _a; return (_a = data === null || data === void 0 ? void 0 : data.label) !== null && _a !== void 0 ? _a : DEFAULT_USER_DEFINED_VARIABLES_LABEL; };
const generateUserDefinedVariable = ({ description, key, value }, modelSchema) => (Object.assign(Object.assign({}, getTestProjectModelElDefaultValues(modelSchema, VARIABLE_MODEL_SCHEMA_NAME)), { name: key, value,
    description }));
export const generateUserDefinedVariables = (variables, modelSchema) => isEmpty(variables)
    ? []
    : [
        Object.assign(Object.assign({}, getTestProjectModelElDefaultValues(modelSchema, USER_DEFINED_PARAMETERS_MODEL_SCHEMA_NAME)), { id: getConfigElementId(getValueByPath(variables, "0.id")), type: ConfigurationElementType.UserDefinedVariables, label: getUserDefinedVariablesLabel(variables || {}), variables: (variables || []).map((variableData) => generateUserDefinedVariable(variableData, modelSchema)) })
    ];
const getHttpCookieManagerLabel = (label) => label || DEFAULT_HTTP_COOKIE_MANAGER_LABEL;
const generateUserDefinedCookie = ({ domain, name, path, secure, value }) => ({
    domain,
    name,
    path,
    secure,
    value
});
const generateUserDefinedCookies = (items = []) => (items || []).map(generateUserDefinedCookie);
export const generateHttpCookieManager = (_a, modelSchema) => {
    var { id, label, items = [] } = _a, restParams = __rest(_a, ["id", "label", "items"]);
    return (Object.assign(Object.assign(Object.assign({}, getTestProjectModelElDefaultValues(modelSchema, HTTP_COOKIE_MANAGER_MODEL_SCHEMA_NAME)), restParams), { id: getConfigElementId(id), label: getHttpCookieManagerLabel(label), type: ConfigurationElementType.HttpCookieManager, userDefinedCookies: generateUserDefinedCookies(items) }));
};
const getHttpHeaderManagerLabel = (data) => { var _a; return (_a = data === null || data === void 0 ? void 0 : data.label) !== null && _a !== void 0 ? _a : DEFAULT_HTTP_HEADER_MANAGER_LABEL; };
export const generateHttpHeaderManager = (headers, modelSchema) => Array.isArray(headers) && headers.length > 0
    ? [
        Object.assign(Object.assign({}, getTestProjectModelElDefaultValues(modelSchema, HTTP_HEADER_MANAGER_MODEL_SCHEMA_NAME)), { id: getValueByPath(headers[0], "configElementId", getUniqueId()), label: getHttpHeaderManagerLabel(headers[0] || {}), type: ConfigurationElementType.HttpHeaderManager, headers: arrayToObject(headers, "key", "value") })
    ]
    : [];
const getHttpRequestDefaultsLabel = (data) => { var _a; return (_a = data === null || data === void 0 ? void 0 : data.label) !== null && _a !== void 0 ? _a : DEFAULT_HTTP_REQUEST_DEFAULTS_LABEL; };
export const generateHttpRequestDefaults = (data, modelSchema) => isEmpty(data)
    ? []
    : [
        Object.assign(Object.assign({}, getTestProjectModelElDefaultValues(modelSchema, HTTP_REQUEST_DEFAULTS_MODEL_SCHEMA_NAME)), { connectTimeout: TIMEOUT_TIME_COEFFICIENT * data.connectTimeout, id: getConfigElementId(data === null || data === void 0 ? void 0 : data.id), type: ConfigurationElementType.HttpRequestDefaults, label: getHttpRequestDefaultsLabel(data || {}), responseTimeout: TIMEOUT_TIME_COEFFICIENT * data.responseTimeout, saveResponseAsMd5Hash: data.saveResponseAsMd5Hash })
    ];
const generatorByConfigElementType = {
    [ConfigurationElementType.HttpRequestDefaults]: generateHttpRequestDefaults,
    [ConfigurationElementType.RandomCsvDatasetConfig]: generateRandomCsvDatasetConfig,
    [ConfigurationElementType.UserDefinedVariables]: generateUserDefinedVariables
};
const getConfigElementDataByType = (configElement) => {
    const configElementType = configElement === null || configElement === void 0 ? void 0 : configElement.type;
    return Object.keys(generatorByConfigElementType).includes(configElementType)
        ? generatorByConfigElementType[configElementType](configElement)
        : DEFAULT_CONFIG_ELEMENT_DATA;
};
export const generateConfigurationElements = (testProjectData, modelSchema) => {
    const { defaultCookies, defaultHeaders, httpRequestDefaults, requestParameters } = testProjectData || {};
    const { csvParameters, literalParameters } = requestParameters || {};
    const httpCookieManagerConfigElement = generateHttpCookieManager(defaultCookies, modelSchema);
    const httpHeaderManagerConfigElement = generateHttpHeaderManager(defaultHeaders, modelSchema);
    const httpRequestDefaultsConfigElement = generateHttpRequestDefaults(httpRequestDefaults, modelSchema);
    const randomCsvDatasetConfig = prepareRandomCsvDatasetConfig(csvParameters, modelSchema);
    const userDefinedVariablesConfigElement = generateUserDefinedVariables(literalParameters, modelSchema);
    return [
        httpCookieManagerConfigElement,
        ...httpHeaderManagerConfigElement,
        ...httpRequestDefaultsConfigElement,
        ...randomCsvDatasetConfig,
        ...userDefinedVariablesConfigElement
    ];
};
