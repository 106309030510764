import styled from "styled-components";
import { Button, Color } from "@boomq/uikit";

export const StyledButton = styled(Button)`
    background: transparent;
    &:hover {
        background: ${(props) => props.theme.hover?.background || Color.blue};
        border-color: ${(props) => props.theme.hover?.borderColor || Color.blue};
        color: ${(props) => props.theme.hover?.color || Color.white};
    }
`;
