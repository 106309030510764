import React from "react";
import { CommonTestDetails, TestDetailsSlaFormContainer, slaFormModal } from "@boomq/common";
import { customSlaTheme } from "./styled";
import { SlaButton } from "../TestProject/SlaButton";

export const TestDetails = (props): JSX.Element => {
    const { id = 0, testProjectVersion = {} } = props.testDetails ? props.testDetails : {};
    const { testType } = testProjectVersion || {};

    const saveSlaHandler = (slaData): void => props.updateTestResultSlaRequest({ testId: id, slaData });

    const showSlaFormHandler = (): void => {
        props.onRefreshTestProjectVersionData();
        props.getSiteModal(
            slaFormModal({
                element: TestDetailsSlaFormContainer,
                mode: props.slaFormMode,
                onSave: saveSlaHandler,
                testType
            })
        );
    };

    return (
        <CommonTestDetails {...props}>
            <SlaButton {...props} onShowSlaForm={showSlaFormHandler} theme={customSlaTheme} />
        </CommonTestDetails>
    );
};
