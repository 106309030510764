import React, { useState } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { getValueByPath, useIntl } from "@boomq/utils";
import { BUTTON_NEXT_LABEL, CRITERIA_TYPE_FILTER_BUTTON_LABEL, SPECIFIC_TESTS_TYPE_FILTER_BUTTON_LABEL, TESTS_FILTER_LABEL, TEST_LABEL_FILTER_LABEL, TEST_PROJECT_FILTER_LABEL, TEST_STATUS_FILTER_LABEL, TIME_FILTER_LABEL } from "./intl";
import { customFullRowTheme, customRowBlockTheme, ButtonBlock, Row, FilterTypeRoller, SelectionCriteriaSwitchButton } from "../SlaReport/styled";
import { IconSwitch } from "../../../styled/Icons";
import { CustomSelect } from "../../../common/CustomSelect";
import { InputNumber } from "../../../common/InputNumber";
import { StyledButton } from "../../../styled/Buttons";
import { getSlaReportSettingsFiltersBySelectionType, getTestStatusList, getTimeUnitList, isExistSettingsFiltersBySelectionType, SELECTION_TYPE, TIME_UNIT } from "../../../../helpers/slaReport";
import { SelectionType } from "../../../../helpers/types";
import { applySlaReportSettingsRequest, getTestListRequest, slaReportProfileChangeParamsField } from "../../../../redux/actions/slaReportProfile";
import { getClientTestProjectList } from "../../../../redux/reducers/clientTestProjectList";
import { getClientTestList, getSlaReportTestsLabelList, getSlaReportProfileCurrentSettingsFilters } from "../../../../redux/reducers/slaReportProfile";
const connector = connect((state) => ({
    clientTestList: getClientTestList(state),
    clientTestProjectList: getClientTestProjectList(state),
    labelList: getSlaReportTestsLabelList(state),
    slaReportProfileCurrentSettingsFilters: getSlaReportProfileCurrentSettingsFilters(state)
}), {
    applySlaReportSettingsRequest,
    getTestListRequest,
    slaReportProfileChangeParamsField
});
const statusList = getTestStatusList() || [];
const timeUnitFilterList = getTimeUnitList() || [];
const getRollerHeightByFilterType = (selectionTypeFilter, filterType) => {
    const height = selectionTypeFilter === SELECTION_TYPE[0] ? "90px" : "150px";
    return selectionTypeFilter === filterType ? height : "0px";
};
const isSelectedTestProject = (testProjectFilter) => testProjectFilter && testProjectFilter.value > -1;
const SlaReportSettings = (props) => {
    const { selectionTypeFilter, testProjectFilter, testIdsFilter = [], timeAmountFilter = 0, timeUnitFilter, testLabelFilter = [], testStatusFilter = [] } = props.slaReportProfileCurrentSettingsFilters || {};
    const { formatMessage } = useIntl();
    const [overflow, setOverflow] = useState("hidden");
    const applySlaReportSettingsHandler = () => props.applySlaReportSettingsRequest(Object.assign(Object.assign({}, getSlaReportSettingsFiltersBySelectionType({
        selectionTypeFilter,
        testIdsFilter,
        testLabelFilter,
        testStatusFilter,
        timeAmountFilter,
        timeUnitFilter
    })), { testProjectFilter }));
    const changeSlaReportSettingsFilter = (filterName, data) => props.slaReportProfileChangeParamsField({
        field: "currentSettingsFilters",
        subfield: filterName,
        value: data
    });
    const changeTestProjectHandler = (data) => {
        props.getTestListRequest({ projectId: getValueByPath(data, "value") });
        props.slaReportProfileChangeParamsField({
            field: "currentSettingsFilters",
            value: {
                selectionTypeFilter: SELECTION_TYPE[0],
                testIdsFilter: [],
                testProjectFilter: data,
                timeUnitFilter: TIME_UNIT[0]
            }
        });
    };
    const closeSelectListHandler = () => setOverflow("hidden");
    const openSelectListHandler = () => setOverflow("initial");
    return (React.createElement(React.Fragment, null,
        React.createElement(Row, null,
            React.createElement(CustomSelect, { isAutoPosition: true, items: props.clientTestProjectList, placeholder: formatMessage(TEST_PROJECT_FILTER_LABEL), name: "TestProject", onChange: changeTestProjectHandler, onCloseItemList: closeSelectListHandler, onOpenItemList: openSelectListHandler, position: { offsetTop: 60 }, theme: customFullRowTheme, value: [testProjectFilter] })),
        React.createElement(FilterTypeRoller, { height: getRollerHeightByFilterType(selectionTypeFilter, SELECTION_TYPE[0]), overflow: overflow },
            React.createElement(Row, null,
                React.createElement(CustomSelect, { disabled: !isSelectedTestProject(testProjectFilter), isAutoPosition: true, items: props.clientTestList, placeholder: formatMessage(TESTS_FILTER_LABEL), name: "Tests", onBlur: (data) => changeSlaReportSettingsFilter("testIdsFilter", data), onCloseItemList: closeSelectListHandler, onOpenItemList: openSelectListHandler, position: { offsetTop: 60 }, theme: customFullRowTheme, selectionType: SelectionType.Multiple, titleAttrName: "comment", value: testIdsFilter })),
            React.createElement(Row, { align: "center" },
                React.createElement(SelectionCriteriaSwitchButton, { onClick: () => changeSlaReportSettingsFilter("selectionTypeFilter", SELECTION_TYPE[1]) },
                    React.createElement(IconSwitch, { height: "12.2", width: "10" }),
                    React.createElement(FormattedMessage, Object.assign({}, CRITERIA_TYPE_FILTER_BUTTON_LABEL))))),
        React.createElement(FilterTypeRoller, { height: getRollerHeightByFilterType(selectionTypeFilter, SELECTION_TYPE[1]), overflow: overflow },
            React.createElement(Row, null,
                React.createElement(InputNumber, { decimalCount: 0, enabled: isSelectedTestProject(testProjectFilter), name: "timeAmountFilter", onChange: (value) => changeSlaReportSettingsFilter("timeAmountFilter", value), theme: customRowBlockTheme, value: timeAmountFilter }),
                React.createElement(CustomSelect, { defaultValue: timeUnitFilter, disabled: !isSelectedTestProject(testProjectFilter), items: timeUnitFilterList || [], name: "timeUnitFilter", onChange: (data) => changeSlaReportSettingsFilter("timeUnitFilter", data.value), onCloseItemList: closeSelectListHandler, onOpenItemList: openSelectListHandler, placeholder: formatMessage(TIME_FILTER_LABEL), theme: customRowBlockTheme })),
            React.createElement(Row, null,
                React.createElement(CustomSelect, { disabled: !isSelectedTestProject(testProjectFilter), items: props.labelList, name: "testLabelFilter", onBlur: (data) => changeSlaReportSettingsFilter("testLabelFilter", data), onCloseItemList: closeSelectListHandler, onOpenItemList: openSelectListHandler, placeholder: formatMessage(TEST_LABEL_FILTER_LABEL), selectionType: SelectionType.Multiple, theme: customRowBlockTheme, value: testLabelFilter }),
                React.createElement(CustomSelect, { disabled: !isSelectedTestProject(testProjectFilter), items: statusList, name: "testStatusFilter", onBlur: (data) => changeSlaReportSettingsFilter("testStatusFilter", data), onCloseItemList: closeSelectListHandler, onOpenItemList: openSelectListHandler, placeholder: formatMessage(TEST_STATUS_FILTER_LABEL), selectionType: SelectionType.Multiple, theme: customRowBlockTheme, value: testStatusFilter })),
            React.createElement(Row, { align: "center" },
                React.createElement(SelectionCriteriaSwitchButton, { onClick: () => changeSlaReportSettingsFilter("selectionTypeFilter", SELECTION_TYPE[0]) },
                    React.createElement(IconSwitch, { height: "12.2", width: "10" }),
                    React.createElement(FormattedMessage, Object.assign({}, SPECIFIC_TESTS_TYPE_FILTER_BUTTON_LABEL))))),
        React.createElement(ButtonBlock, null,
            React.createElement(StyledButton, { disabled: !isExistSettingsFiltersBySelectionType(Object.assign({ selectionTypeFilter }, props.slaReportProfileCurrentSettingsFilters)), onClick: applySlaReportSettingsHandler },
                React.createElement(FormattedMessage, Object.assign({}, BUTTON_NEXT_LABEL))))));
};
export default connector(SlaReportSettings);
