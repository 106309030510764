import React, { FC, useState } from "react";
import { StyledTableRow } from "./styled";
import { getTeamMembersItemTemplateByMode } from "../../../../helpers/teams";
import { TeamMemberMode, TeamMemberPermission } from "../../../../helpers/types";

export interface Props {
    children?: JSX.Element | JSX.Element[];
    id: string;
    email: string;
    mode?: TeamMemberMode;
    permissionList: TeamMemberPermission[];
    userDisplayName: string;
    width?: number;
}

const renderMemberByMode = (mode, props): JSX.Element => {
    const TeamMemberComponent = getTeamMembersItemTemplateByMode(mode);

    return <TeamMemberComponent {...props} />;
};

const getModeStateValue = (mode?: TeamMemberMode): TeamMemberMode => (mode ? mode : TeamMemberMode.View);

export const TeamMembersItem: FC<Props> = (props: Props): JSX.Element => {
    const [mode, setMode] = useState(getModeStateValue(props.mode));

    const clickEditHandler = (mode): void => setMode(mode);

    return <StyledTableRow>{renderMemberByMode(mode, { ...props, onToggleMode: clickEditHandler })}</StyledTableRow>;
};
