import { combineActions, handleActions } from "redux-actions";
import authorizeActions from "../../actions/authorize";
import { importDataToTestProjectSuccess, importDataFromFileToThreadGroupSuccess, importJmxFileSuccess, importTestProjectDataChangeParamsField, uploadImportFileFailure, uploadImportFileSuccess } from "../../actions/importTestProjectData";
import { isEmpty } from "../../../helpers/utils";
import { ImportDataSourceType } from "../../../models/importData";
export const initialState = {
    [ImportDataSourceType.Har]: {
        files: [],
        data: {}
    },
    [ImportDataSourceType.Insomnia]: {
        files: [],
        data: {}
    },
    [ImportDataSourceType.JMX]: {
        data: {},
        files: {
            jmx: [],
            support: []
        }
    },
    [ImportDataSourceType.Postman]: {
        files: [],
        data: {}
    }
};
export default handleActions({
    [authorizeActions.logout]: () => (Object.assign({}, initialState)),
    [combineActions(importDataToTestProjectSuccess, importDataFromFileToThreadGroupSuccess)]: (state, { payload }) => {
        const { sourceType } = payload || {};
        const newData = state[sourceType] ? { [sourceType]: initialState[sourceType] } : {};
        return Object.assign(Object.assign({}, state), newData);
    },
    [importJmxFileSuccess]: (state) => (Object.assign(Object.assign({}, state), { [ImportDataSourceType.JMX]: initialState[ImportDataSourceType.JMX] })),
    [importTestProjectDataChangeParamsField]: (state, { payload }) => {
        const { field, value } = payload || {};
        return field ? Object.assign(Object.assign({}, state), { [field]: value }) : state;
    },
    [uploadImportFileSuccess]: (state, { payload }) => {
        const { data, files, sourceType } = payload || {};
        const newData = state[sourceType]
            ? { [sourceType]: !isEmpty(data) && !isEmpty(files) ? { data, files } : state[sourceType] }
            : {};
        return Object.assign(Object.assign({}, state), newData);
    },
    [uploadImportFileFailure]: (state, { payload }) => {
        const { sourceType } = payload || {};
        const newData = state[sourceType] ? { [sourceType]: initialState[sourceType] } : {};
        return Object.assign(Object.assign({}, state), newData);
    }
}, initialState);
