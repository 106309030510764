import styled, { css } from "styled-components";
import { Color, InputText } from "@boomq/uikit";
export const customRequestParametersDropDownTheme = {
    background: Color.lightBlue10,
    border: `1px solid ${Color.grey75}`,
    borderBottomRightRadius: "12px",
    borderTopRightRadius: "12px",
    color: Color.blue,
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "500",
    justifyContent: "center",
    minWidth: "42px",
    padding: "4px 6px"
};
export const InputSection = styled.div `
    display: flex;
    position: relative;
    width: 100%;
`;
export const StyledInputText = styled(InputText) `
    ${({ isExistParameters }) => isExistParameters
    ? css `
                  border-top-right-radius: 0px;
                  border-bottom-right-radius: 0px;
                  & ~ fieldset {
                      border-top-right-radius: 0px;
                      border-bottom-right-radius: 0px;
                  }
              `
    : css ``}
`;
