export const BUTTON_REQUEST_NAME_COPY_PATH_TOOLTIP_TEXT = {
    defaultMessage: "Copy path",
    id: "account.request.form.request.name.button.copy.path"
};
export const BUTTON_REQUEST_NAME_COPY_URL_TOOLTIP_TEXT = {
    defaultMessage: "Copy URL",
    id: "account.request.form.request.name.button.copy.url"
};
export const BUTTON_REQUEST_NAME_COPY_URL_WITHOUT_RESOLVE_PARAMETERS_TOOLTIP_TEXT = {
    defaultMessage: "Copy URL without resolve parameters",
    id: "account.request.form.request.name.button.copy.without.resolve.parameters"
};
export const BUTTON_SAVE_LABEL = {
    defaultMessage: "Save",
    id: "account.test.profile.button.save"
};
export const NEW_TRANSACTION_NAME_MESSAGE = {
    defaultMessage: "New transaction",
    id: "account.request.form.group.default.item"
};
export const REQUEST_NAME_LABEL = {
    defaultMessage: "Request name",
    id: "account.request.form.request.name.label"
};
