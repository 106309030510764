import styled, { css } from "styled-components";
import { Color, FontBodyBigBold, FontBodyMediumBold, FontBodyMediumMedium, IconArrowDown, IconDelete, IconEdit, IconPeople, IconPlus, Labels } from "@boomq/uikit";
import { ActionListItem, PaginationWrapper, TableRow } from "../../../styled/Table";
export const AddTeamMemberAction = styled(ActionListItem) `
    ${FontBodyMediumBold}
    color: ${Color.blue};
    pointer-events: ${({ isEnabled }) => (isEnabled ? "auto" : "none")};
`;
export const FlexTableRowColumn = styled(TableRow) `
    display: flex;
    flex-direction: column;
    padding: 16px;
    width: 100%;
`;
export const StyledIconArrowDown = styled(IconArrowDown) `
    fill: ${Color.blue};
    transform: rotate(-90deg);
    transition: all 0.3s ease;
    ${({ isopened }) => isopened === "true"
    ? css `
                  transform: rotate(0deg);
              `
    : css ``}
`;
export const StyledIconDelete = styled(IconDelete) `
    fill: ${Color.blue};
`;
export const StyledIconEdit = styled(IconEdit) `
    fill: ${Color.blue};
`;
export const StyledIconPeople = styled(IconPeople) `
    fill: ${Color.grey50};
    margin-right: 8px;
`;
export const StyledIconPlus = styled(IconPlus) `
    fill: ${Color.blue};
    margin-right: 6px;
`;
export const StyledLabels = styled(Labels) `
    padding-right: 16px;
`;
export const TeamMembersBody = styled.div `
    display: flex;
    flex: auto;
    flex-direction: column;
    margin-top: 16px;
    overflow: hidden;
    position: relative;
    width: 100%;
`;
export const TeamMembersHeader = styled.div `
    align-items: center;
    border-top: 1px solid ${Color.lightGrey95};
    display: flex;
    margin: 0 12px;
    padding-bottom: 8px;
    padding-top: 16px;
`;
export const TeamMembersHeaderTitle = styled.div `
    ${FontBodyMediumMedium}
    color: ${Color.darkGrey35};
    margin-right: 16px;
`;
export const TeamMembersPaginationWrapper = styled(PaginationWrapper) `
    margin-left: 12px;
    margin-right: 12px;
    margin-bottom: 16px;
`;
export const TeamData = styled.div `
    display: flex;
    flex-direction: column;
    width: 100%;
`;
export const TeamImage = styled.div `
    background: gray;
    height: 48px;
    margin-right: 16px;
    min-height: 48px;
    min-width: 48px;
    width: 48px;
`;
export const TeamInfoBody = styled.div `
    display: flex;
`;
export const TeamInfoHeader = styled.div `
    display: flex;
`;
export const TeamInfoText = styled.div `
    ${FontBodyMediumMedium}
    align-items: center;
    color: ${Color.darkGrey35};
    display: flex;
`;
export const TeamItemHeader = styled.div `
    display: flex;
    width: 100%;
`;
export const TeamMembers = styled.div `
    border-top: 1px solid ${Color.lightGrey95};
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    width: 100%;
`;
export const TeamName = styled.div `
    ${FontBodyBigBold}
    color: ${Color.darkGrey5};
    display: flex;
    flex: auto;
    margin-bottom: 8px;
`;
export const VerticalSeparator = styled.div `
    border-left: 1px solid ${Color.lightGrey85};
    margin: 2px 12px;
`;
