export const CONNECT_TIMEOUT_TEXT = {
    defaultMessage: "Connection timeout",
    id: "account.modal.request.default.settings.connect.timeout.text"
};
export const RESPONSE_TIMEOUT_TEXT = {
    defaultMessage: "Response timeout",
    id: "account.modal.request.default.settings.response.timeout.text"
};
export const SAVE_RESPONSE_AS_MD5_HASH_TEXT = {
    defaultMessage: "Save response as MD5 hash",
    id: "account.modal.request.default.settings.save.response.md5.hash.text"
};
