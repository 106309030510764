var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { useIntl } from "@boomq/utils";
import { TEAM_MEMBER_ACTION_CANCEL, TEAM_MEMBER_ACTION_SAVE, TEAM_MEMBER_EMAIL_INPUT_LABEL, TEAM_MEMBER_NAME_INPUT_LABEL } from "./intl";
import { TeamMembersItemTemplateEditor } from "../../../../components/account/Team/TeamMembersItemTemplateEditor";
const WrappedTeamMembersItemTemplateEdit = (_a) => {
    var { onSave } = _a, restProps = __rest(_a, ["onSave"]);
    const { formatMessage } = useIntl();
    const saveButtonLabel = formatMessage(TEAM_MEMBER_ACTION_SAVE);
    const cancelButtonLabel = formatMessage(TEAM_MEMBER_ACTION_CANCEL);
    const emailInputLabel = formatMessage(TEAM_MEMBER_EMAIL_INPUT_LABEL);
    const nameInputLabel = formatMessage(TEAM_MEMBER_NAME_INPUT_LABEL);
    const teamMemberSavingHandler = (data) => (typeof onSave === "function" ? onSave(data) : undefined);
    return (React.createElement(TeamMembersItemTemplateEditor, Object.assign({}, restProps, { cancelButtonLabel: cancelButtonLabel, confirmButtonLabel: saveButtonLabel, emailInputLabel: emailInputLabel, nameInputLabel: nameInputLabel, onConfirm: teamMemberSavingHandler })));
};
export default WrappedTeamMembersItemTemplateEdit;
