var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { useIntl } from "@boomq/utils";
import { H3, TabListSection } from "@boomq/uikit";
import { TYPE_TEST_TITLE_MESSAGE } from "./intl";
import { Description, LoadProfileParams, StyledBlockContent, StyledTab } from "./styled";
import { BlockTitle } from "../../styled/Blocks";
import newTestActions from "../../../redux/actions/newTest";
import { getTestProjectSettingsField } from "../../../redux/reducers/newTest";
import { loadTypesMessages } from "../../../data/content/testTypeMessages";
const { testProjectChangeLoadType } = newTestActions;
const connector = connect((state) => ({
    loadType: getTestProjectSettingsField(state, "loadType")
}), { onChangeLoadType: testProjectChangeLoadType });
const TypeSelection = (_a) => {
    var { onChangeLoadType } = _a, restProps = __rest(_a, ["onChangeLoadType"]);
    const { formatMessage } = useIntl();
    const changeHandler = (value) => onChangeLoadType(value);
    return (React.createElement(LoadProfileParams, { disabled: restProps.disabled },
        React.createElement(BlockTitle, null,
            React.createElement(H3, null, formatMessage(TYPE_TEST_TITLE_MESSAGE))),
        React.createElement(StyledBlockContent, null,
            React.createElement(TabListSection, null, loadTypesMessages.map((type) => (React.createElement(StyledTab, { key: type.name, name: "loadType", onChange: () => changeHandler(type), checked: restProps.loadType.name === type.name, title: formatMessage(type.title) })))),
            React.createElement(Description, null, formatMessage(restProps.loadType.description))),
        restProps.children && React.cloneElement(restProps.children, Object.assign(Object.assign({}, restProps), { children: undefined }))));
};
export default withRouter(connector(TypeSelection));
