var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { isEmptyArray } from "@boomq/utils";
import { customInputTheme, getCustomInputNumberTheme, iconMenuListTheme, iconMenuTheme, ActionsSection, Percent, TestProfileInput } from "./styled";
import { DropDownList } from "../../../common/DropDownList";
import { EditableTitle } from "../../../common/EditableTitle";
import { IconMenu } from "../../../styled/Icons";
import { GroupName } from "../../../styled/TestProject";
import { testProfilePercMax, testProfilePercMin } from "../../../../helpers/applicationParams";
import { Mode } from "../../../../models/sla";
export const CaptionEditMode = ({ changeRequestGroup, children, isError, requestGroup, requestGroupActions }) => {
    const changeRequestGroupPercHandler = (value) => changeRequestGroup({
        field: "perc",
        id: requestGroup.id,
        value
    });
    return (React.createElement(GroupName, null,
        React.createElement(Percent, null,
            React.createElement(TestProfileInput, { decimalCount: 2, enabled: true, id: requestGroup.id, isPositive: true, key: "perc" + requestGroup.id, max: testProfilePercMax, min: testProfilePercMin, onBlur: changeRequestGroupPercHandler, inputTheme: customInputTheme, theme: getCustomInputNumberTheme(isError), value: requestGroup.perc }),
            "%"),
        React.createElement(EditableTitle, { key: "name" + requestGroup.id, value: requestGroup.name, onChange: (value) => changeRequestGroup({ field: "name", id: requestGroup.id, value }), theme: { flex: "auto" } }),
        children,
        !isEmptyArray(requestGroupActions) && (React.createElement(ActionsSection, null,
            React.createElement(DropDownList, { listTheme: iconMenuListTheme, items: requestGroupActions, paramName: "key", theme: iconMenuTheme },
                React.createElement(IconMenu, null))))));
};
export const CaptionViewMode = ({ requestGroup }) => (React.createElement(GroupName, null,
    React.createElement(Percent, null,
        requestGroup.perc,
        "%"),
    requestGroup.name));
const getDefaultGroupCaption = (props) => (props.mode === Mode.View ? CaptionViewMode(props) : CaptionEditMode(props));
export const getGroupCaption = (_a) => {
    var { caption } = _a, rest = __rest(_a, ["caption"]);
    return (caption ? caption(rest) : getDefaultGroupCaption(rest));
};
