var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { call, put, select, takeEvery } from "redux-saga/effects";
import { getValueByPath } from "@boomq/utils";
import { refreshTestProjectList, updateTestProjectsFilterLabelsByLabelList, updateTestProjectsLabels } from "./testProjects";
import newTestActions from "../actions/newTest";
import { addTestProjectLabelFailure, addTestProjectLabelRequest, addTestProjectLabelWithCreatingRequest, deleteTestProjectLabelFailure, deleteTestProjectLabelRequest } from "../actions/testProject";
import { setLabelList } from "../actions/testProjects";
import { getLoadProfileParamsByTestType, getTestType } from "../reducers/newTest";
import { getCurrentPage, getCurrentPageElements, getPrevPage, getTestProjects, getTestProjectsFilterLabels, getTestProjectsLabelList } from "../reducers/testProjects";
import { getResponseErrorData } from "../../helpers/errors";
import { generatePreviewData } from "../../helpers/generators";
import { addLabelToCurrentLabelSet, createLabel, getLabelByName, prepareLabels } from "../../helpers/labels";
import { updateTestProject, uploadFileV2 } from "../../services/projectSrv";
const { testProjectChangeParamsField, uploadGrpcProtoFileFailure, uploadGrpcProtoFileRequest, uploadGrpcProtoFileSuccess } = newTestActions;
export function* getTestProjectFromListById(id) {
    const testProjects = yield select(getTestProjects);
    const testProject = testProjects.find((settingsSet) => settingsSet.id === id);
    return testProject ? testProject : {};
}
function* labelAddingFlow(action) {
    try {
        const { id, label } = action && action.payload ? action.payload : {};
        const labelList = yield select(getTestProjectsLabelList);
        const _a = yield* getTestProjectFromListById(id), { searchedLabels, content, contentFormat, contentModelVersion } = _a, testProjectParams = __rest(_a, ["searchedLabels", "content", "contentFormat", "contentModelVersion"]);
        const testProjectWithLabel = Object.assign(Object.assign({}, testProjectParams), { labelSet: addLabelToCurrentLabelSet(testProjectParams.labelSet, label) });
        yield call(updateTestProject, { id, testProjectParams: testProjectWithLabel });
        yield put(setLabelList(prepareLabels(addLabelToCurrentLabelSet(labelList, label))));
        const currentPage = yield select(getCurrentPage);
        const filterLabels = yield select(getTestProjectsFilterLabels);
        yield* refreshTestProjectList(currentPage, filterLabels);
    }
    catch (e) {
        yield put(addTestProjectLabelFailure(getResponseErrorData(e)));
    }
}
function* labelAddingWithCreatingFlow(action) {
    const { id, name } = action && action.payload ? action.payload : {};
    const labelList = yield select(getTestProjectsLabelList);
    const existedLabel = getLabelByName(labelList, name);
    const newLabel = existedLabel ? existedLabel : createLabel(name, labelList.length);
    yield* labelAddingFlow({ payload: { id, label: newLabel } });
}
function* deleteLabelFlow(action) {
    try {
        const { id, label } = action && action.payload ? action.payload : {};
        const testProject = yield* getTestProjectFromListById(id);
        const currentPageElements = yield select(getCurrentPageElements);
        const pageForSettingsSetListRefreshing = currentPageElements === 1 ? yield select(getPrevPage) : yield select(getCurrentPage);
        const testProjectWithoutLabel = Object.assign(Object.assign({}, testProject), { labelSet: testProject.labelSet.filter((labelSetItem) => labelSetItem.label !== label) });
        yield call(updateTestProject, { id, testProjectParams: testProjectWithoutLabel });
        yield* updateTestProjectsLabels();
        yield* updateTestProjectsFilterLabelsByLabelList();
        const filterLabels = yield select(getTestProjectsFilterLabels);
        yield* refreshTestProjectList(pageForSettingsSetListRefreshing, filterLabels);
    }
    catch (e) {
        yield put(deleteTestProjectLabelFailure(getResponseErrorData(e)));
    }
}
function* grpcProtoFileUploadingFlow(action) {
    try {
        const { data } = yield call(uploadFileV2, { file: getValueByPath(action, "payload.0", {}) });
        yield put(uploadGrpcProtoFileSuccess(data));
    }
    catch (e) {
        yield put(uploadGrpcProtoFileFailure(getResponseErrorData(e)));
    }
}
export function* loadTypeSettingsChartGenerationFlow() {
    try {
        const testType = yield select(getTestType);
        const loadProfileParams = yield select(getLoadProfileParamsByTestType, testType);
        yield put(testProjectChangeParamsField({
            field: "chartData",
            value: [{ coords: generatePreviewData(loadProfileParams) }]
        }));
    }
    catch (e) {
        return;
    }
}
export function* testProjectFlow() {
    yield takeEvery(addTestProjectLabelRequest, labelAddingFlow);
    yield takeEvery(addTestProjectLabelWithCreatingRequest, labelAddingWithCreatingFlow);
    yield takeEvery(deleteTestProjectLabelRequest, deleteLabelFlow);
    yield takeEvery(uploadGrpcProtoFileRequest, grpcProtoFileUploadingFlow);
}
