import { arrayToParams } from "@boomq/utils";
import { getControllerTypeFromThreadGroupElementData } from "./threadGroupElements";
import { parseHttpRequest } from "./threadGroupSamplerHttp";
import { getExtractorParametersFromRequest } from "../extractResponse";
import { getThreadGroupElementName, getThreadGroupElementType } from "../threadGroup";
export const formatThreadGroupTreeSampler = (params) => ({
    id: params === null || params === void 0 ? void 0 : params.id,
    controllerType: getControllerTypeFromThreadGroupElementData(params),
    type: getThreadGroupElementType(params)
});
const getRequestUrlPort = (value) => {
    const port = value !== null && value !== void 0 ? value : "";
    return port === "" ? "" : `:${port}`;
};
const getRequestUrlProtocol = (value) => {
    const protocol = value || "";
    return protocol === "" ? "" : `${protocol}://`;
};
export const generateRequestUrlFromParams = (individualProperties) => {
    const { protocol = "", port = "", path = "", queryParameters, serverName = "" } = individualProperties || {};
    const url = `${getRequestUrlProtocol(protocol)}${serverName || ""}${getRequestUrlPort(port)}${path || ""}`;
    return `${url}${arrayToParams(queryParameters, "name", "value", "?")}`;
};
export const prepareTestProjectGroupRequest = (res, request, groupId) => parseHttpRequest({ res, groupElement: request, groupId });
export const prepareRequestData = ({ res, groupElement, parentElementId, parsedElementData, path, requestTimers }) => {
    const requestExtractorParameters = getExtractorParametersFromRequest(groupElement, path);
    return Object.assign(Object.assign({}, res), { extractorParameters: [...res.extractorParameters, ...requestExtractorParameters], requests: [
            ...res.requests,
            Object.assign(Object.assign({}, parsedElementData), { name: getThreadGroupElementName(groupElement), transactionId: parentElementId })
        ], elementsData: Object.assign(Object.assign({}, res.elementsData), { [groupElement.id]: Object.assign(Object.assign({}, parsedElementData), { name: getThreadGroupElementName(groupElement), path, transactionId: parentElementId }) }), timers: Object.assign(Object.assign({}, res.timers), requestTimers) });
};
