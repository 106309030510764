export const getRandomColor = (replaceMask) => {
    let colorCharacters = "0123456789ABCDEF";
    const color = Array(6).fill("0");
    replaceMask &&
        Object.keys(replaceMask).forEach((colorCharacter) => {
            colorCharacters.indexOf(replaceMask[colorCharacter]) > -1 &&
                (colorCharacters = colorCharacters.replace(colorCharacter, replaceMask[colorCharacter]));
        });
    return "#" + color.map(() => colorCharacters[Math.floor(Math.random() * 16)]).join("");
};
