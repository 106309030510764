import { TeamAccessMode, TeamMemberMode, TeamMemberPermission } from "../types";
export const ADMIN_PERMISSIONS = [TeamMemberPermission.Admin];
export const DEFAULT_TEAMS_ITEM_HEIGHT = 95;
export const DEFAULT_TEAMS_PAGINATION_HEIGHT = 50;
export const DEFAULT_TEAMS_PAGE_SIZE = 6;
export const DEFAULT_TEAM_MEMBERS_ITEM_HEIGHT = 64;
export const DEFAULT_TEAM_MEMBERS_PAGINATION_HEIGHT = 52;
export const DEFAULT_TEAM_MEMBERS_PAGE_SIZE = 5;
export const DEFAULT_TEAM_MEMBER_TEMPLATE_KEY = TeamMemberMode.View;
export const DEFAULT_TEAM_TEMPLATE_KEY = TeamAccessMode.ManageUsers;
export const MANAGE_USERS_PERMISSIONS = [TeamMemberPermission.OnlyOrganisation, TeamMemberPermission.ManageUsers];
export const SELECTED_TEAM_LOCAL_STORAGE_PARAM_NAME = "pflb_selected_team";
