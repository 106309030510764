import { IMPORT_STATS_DIFF_DAY_COUNT } from "./constants";
import { getDiffDayCount } from "../date";
export const importStatsValidator = ({ startDate, endDate, siteId }) => {
    const diffDayCount = getDiffDayCount(startDate, endDate);
    const errors = {};
    (!startDate || !endDate) &&
        (errors.dates = {
            defaultMessage: "Both dates of range are required.",
            id: "account.test.profile.error.dates.both.dates.required"
        });
    startDate &&
        endDate &&
        diffDayCount > IMPORT_STATS_DIFF_DAY_COUNT &&
        (errors.dates = {
            defaultMessage: "Date range can't exceed 30 days.",
            id: "account.test.profile.error.dates.range"
        });
    !siteId &&
        (errors.site = {
            defaultMessage: "Required field",
            id: "account.test.profile.error.site.require"
        });
    return { errors, isValid: !errors || (!errors.dates && !errors.site) };
};
