import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { Color, Block, FontBodyMediumBold } from "@boomq/uikit";
import { media } from "../response";
export const ActionStyles = `
    align-items: center;
    cursor: pointer;
    display: flex;
    padding: 0 4px;
`;
export const ActionListItem = styled.li `
    ${ActionStyles}
`;
export const ActionLink = styled(Link) `
    ${ActionStyles}
`;
export const ActionList = styled.ul `
    display: flex;
    flex: 1;
    justify-content: flex-end;
    max-width: 100px;
    min-width: 100px;
`;
export const ActionWithTextStyles = `
    ${FontBodyMediumBold}
    align-items: center;
    color: ${Color.blue}
    cursor: pointer;
    display: flex;
`;
export const MassAction = styled.div `
    ${ActionWithTextStyles}
`;
export const MassActionLink = styled(Link) `
    ${ActionWithTextStyles}
    margin-right: 16px;
`;
export const MassActionsPanel = styled.div `
    ${Block}
    align-items: center;
    display: flex;
    margin-top: 16px;
    padding: 8px 16px;
`;
export const PaginationWrapper = styled.div `
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: 12px;
`;
export const TableRow = styled.li `
    ${Block}
    align-items: center;
    display: flex;
    margin-top: 8px;
    position: relative;
    transition: all 0.3s ease;
    transition-delay: ${(props) => (props && props.delay ? props.delay : 0)}s;
    &:hover {
        box-shadow: 0 0 10px rgba(0, 64, 140, 0.35);
    }
    ${media.tablet} {
        align-items: flex-start;
        flex-direction: column;
        padding: 24px 0 16px 25px;
    }
`;
export const ClickableTableRow = styled(TableRow) `
    cursor: pointer;
`;
export const TableRowCol = styled.div `
    display: flex;
    flex: ${(props) => (props && props.long ? 1.5 : 1)};
    justify-content: flex-start;
    word-break: break-all;
    &:last-child {
        ${media.tablet} {
            padding-bottom: 0;
        }
    }
    ${media.tablet} {
        padding-bottom: 20px;
    }
    ${(props) => (props && props.theme ? css(props.theme) : css ``)}
`;
export const TableRowLink = styled(Link) `
    display: flex;
    padding: 16px;
    width: 100%;
`;
export const TableRowColText = styled.p `
    ${FontBodyMediumBold}
    align-items: center;
    color: ${({ blue }) => (blue ? Color.blue : Color.darkGrey35)}
    display: flex;
    line-height: normal;
    padding-right: 16px;
    &:first-child {
        margin-top: 0;
    }
    ${media.tablet} {
        margin-left: 10px;
    }
`;
export const TableRows = styled.ul `
    flex: 0 0 auto;
`;
