export var TestProjectDataSourceType;
(function (TestProjectDataSourceType) {
    TestProjectDataSourceType["GrpcProtoBuffer"] = "GRPC_PROTO_BUFFER";
})(TestProjectDataSourceType || (TestProjectDataSourceType = {}));
export var LoadProfileType;
(function (LoadProfileType) {
    LoadProfileType["PerGroup"] = "PER_GROUP";
    LoadProfileType["PerTest"] = "PER_TEST";
})(LoadProfileType || (LoadProfileType = {}));
export var ProfileDistribution;
(function (ProfileDistribution) {
    ProfileDistribution["Percent"] = "PERCENT";
    ProfileDistribution["Users"] = "USERS";
})(ProfileDistribution || (ProfileDistribution = {}));
export var ProfileSettingsMode;
(function (ProfileSettingsMode) {
    ProfileSettingsMode["ThreadGroups"] = "THREAD_GROUPS";
    ProfileSettingsMode["ThreadGroupEditor"] = "THREAD_GROUP_EDITOR";
})(ProfileSettingsMode || (ProfileSettingsMode = {}));
export var SupportingFileType;
(function (SupportingFileType) {
    SupportingFileType["Proto"] = "PROTO";
    SupportingFileType["Text"] = "TEXT";
})(SupportingFileType || (SupportingFileType = {}));
