import React from "react";
import { isEmpty, useIntl } from "@boomq/utils";
import { SlaResultBlock } from "./styled";
import { IconErrorTriangle, IconLoader } from "../../styled/Icons";
import { getSlaStatusColor } from "../../../helpers/sla";
import { TestResultSlaState } from "../../../models/testRun";
const getSlaResultFormattedTitle = (formatMessage, formatMessageData) => {
    const title = formatMessage(formatMessageData);
    return typeof title === "string" ? title : "";
};
const getSlaResultTitle = (formatMessage, testResultSlaState, result) => {
    switch (testResultSlaState) {
        case TestResultSlaState.Processing: {
            return getSlaResultFormattedTitle(formatMessage, {
                defaultMessage: "SLA is processing",
                id: "account.sla.form.test.result.sla.processing.title"
            });
        }
        case TestResultSlaState.Failed: {
            return getSlaResultFormattedTitle(formatMessage, {
                defaultMessage: "SLA is failed",
                id: "account.sla.form.test.result.sla.failed.title"
            });
        }
        default: {
            return String(result);
        }
    }
};
const renderSlaResultValue = (testResultSlaState, result) => {
    switch (testResultSlaState) {
        case TestResultSlaState.Processing: {
            return React.createElement(IconLoader, null);
        }
        case TestResultSlaState.Failed: {
            return React.createElement(IconErrorTriangle, null);
        }
        default: {
            return isEmpty(result) ? "" : String(result);
        }
    }
};
export const SlaResult = ({ result, status, testResultSlaState }) => {
    const { formatMessage } = useIntl();
    return (React.createElement(SlaResultBlock, { title: getSlaResultTitle(formatMessage, testResultSlaState, result), color: getSlaStatusColor(status, testResultSlaState) }, renderSlaResultValue(testResultSlaState, result)));
};
