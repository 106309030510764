import React from "react";
import { getValueByPath, useIntl } from "@boomq/utils";
import { InputText } from "@boomq/uikit";
import { ExtractorCommonTemplate } from "../ExtractorCommonTemplate";
import { getExpressionParamsValue, getExpressionValue, getPlaceholderMap } from "../../../../helpers/extractResponse";
import { FormType } from "../../../../helpers/types";
const ExtractorJsonPathTemplate = (props) => {
    const { formatMessage } = useIntl();
    const { expression, mode, onChange } = props || {};
    const changeExtractExpressionHandler = ({ field, subfield, value }) => getExpressionValue(expression, field, subfield) !== value && typeof onChange === "function"
        ? onChange({
            id: expression.id,
            field,
            subfield,
            value
        })
        : undefined;
    const changeInputValue = (paramName, value) => changeExtractExpressionHandler({
        field: "params",
        subfield: paramName,
        value
    });
    const changeJsonPathHandler = (value) => changeInputValue("jsonpath", value);
    const getPlaceholder = (extractType, paramName) => formatMessage(getPlaceholderMap(extractType, paramName));
    return (React.createElement(ExtractorCommonTemplate, Object.assign({}, props, { onChange: changeExtractExpressionHandler }),
        React.createElement(InputText, { enabled: mode !== FormType.View, error: getValueByPath(expression, "error.jsonpath"), name: "Expression_jsonpath", onBlur: changeJsonPathHandler, placeholder: getPlaceholder(expression.type, "jsonpath"), value: getExpressionParamsValue(expression, "jsonpath") })));
};
export default ExtractorJsonPathTemplate;
