var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { getValueByPath } from "@boomq/utils";
import { SlaItemContainer } from "../SlaItem";
import { getSlaTestMetricRestrictionTypeItems } from "../../../helpers/sla";
import { SlaTargetType } from "../../../models/sla";
export const SlaTestContainer = (_a) => {
    var { slaData } = _a, restProps = __rest(_a, ["slaData"]);
    return getValueByPath(slaData, SlaTargetType.Test, []).map((slaItemData) => (React.createElement(SlaItemContainer, Object.assign({ key: slaItemData.id }, restProps, { data: Object.assign({}, slaItemData), restrictionTypeItems: getSlaTestMetricRestrictionTypeItems(SlaTargetType.Test), type: SlaTargetType.Test }))));
};
