import React, { useState } from "react";
import { connect } from "react-redux";
import { useIntl } from "@boomq/utils";
import { InputNumber } from "@boomq/uikit";
import { CONNECT_TIMEOUT_TEXT, RESPONSE_TIMEOUT_TEXT, SAVE_RESPONSE_AS_MD5_HASH_TEXT } from "./intl";
import { ModalFormBodySettings, SaveButtonSection, Setting, Settings, Text } from "../RequestDefaultTimerSettingsForm/styled";
import { StyledButton } from "../../../styled/Buttons";
import { Switcher } from "../../../common/Switcher";
import newTestActions from "../../../../redux/actions/newTest";
import { getHttpRequestDefaults } from "../../../../redux/reducers/newTest";
import { getTimerInputUnit } from "../../../../helpers/requestTimers";
const { testProjectChangeParamsField } = newTestActions;
const connector = connect((state) => ({
    httpRequestDefaults: getHttpRequestDefaults(state)
}), {
    testProjectChangeParamsField
});
const HttpRequestDefaultsForm = ({ httpRequestDefaults, testProjectChangeParamsField }) => {
    const { formatMessage } = useIntl();
    const [connectTimeout, setConnectTimeout] = useState(httpRequestDefaults === null || httpRequestDefaults === void 0 ? void 0 : httpRequestDefaults.connectTimeout);
    const [responseTimeout, setResponseTimeout] = useState(httpRequestDefaults === null || httpRequestDefaults === void 0 ? void 0 : httpRequestDefaults.responseTimeout);
    const [isSaveResponseInMd5Hash, setIsSaveResponseInMd5Hash] = useState(httpRequestDefaults === null || httpRequestDefaults === void 0 ? void 0 : httpRequestDefaults.isSaveResponseInMd5Hash);
    const changeConnectTimeoutHandler = (value) => setConnectTimeout(value);
    const changeResponseTimeoutHandler = (value) => setResponseTimeout(value);
    const clickApplyHandler = () => testProjectChangeParamsField({
        field: "httpRequestDefaults",
        value: {
            connectTimeout,
            responseTimeout,
            isSaveResponseInMd5Hash
        }
    });
    const connectTimeoutPlaceholder = formatMessage(CONNECT_TIMEOUT_TEXT);
    const responseTimeoutPlaceholder = formatMessage(RESPONSE_TIMEOUT_TEXT);
    return (React.createElement(ModalFormBodySettings, null,
        React.createElement(Settings, null,
            React.createElement(Setting, null,
                React.createElement(Text, null, formatMessage(SAVE_RESPONSE_AS_MD5_HASH_TEXT)),
                React.createElement(Switcher, { defaultChecked: isSaveResponseInMd5Hash, name: "saveResponseInMd5Hash", onClick: () => setIsSaveResponseInMd5Hash(!isSaveResponseInMd5Hash) })),
            React.createElement(Setting, null,
                React.createElement(Text, null, formatMessage(CONNECT_TIMEOUT_TEXT)),
                React.createElement(InputNumber, { decimalCount: 2, enabled: true, name: "connectTimeout", onChange: changeConnectTimeoutHandler, placeholder: connectTimeoutPlaceholder, unit: getTimerInputUnit(), value: connectTimeout })),
            React.createElement(Setting, null,
                React.createElement(Text, null, formatMessage(RESPONSE_TIMEOUT_TEXT)),
                React.createElement(InputNumber, { decimalCount: 2, enabled: true, name: "connectTimeout", onChange: changeResponseTimeoutHandler, placeholder: responseTimeoutPlaceholder, unit: getTimerInputUnit(), value: responseTimeout }))),
        React.createElement(SaveButtonSection, null,
            React.createElement(StyledButton, { className: "close_modal_window", onClick: clickApplyHandler }, formatMessage({
                defaultMessage: "Apply",
                id: "account.modal.timers.settings.button"
            })))));
};
export default connector(HttpRequestDefaultsForm);
