import React, { useEffect, useState } from "react";
export const sizes = {
    desktop: 992,
    giant: 1200,
    phone: 480,
    tablet: 768
};
const customMediaQuery = (maxWidth) => `@media (max-width: ${maxWidth}em)`;
export const media = Object.keys(sizes).reduce((accumulator, label) => {
    const emSize = sizes[label] / 16;
    accumulator[label] = customMediaQuery(emSize);
    return accumulator;
}, {});
export const withResize = (WrappedComponent) => (props) => {
    const [width, setWidth] = useState(window.innerWidth);
    const resizeHandler = () => setWidth(window.innerWidth);
    useEffect(() => {
        window.addEventListener("resize", resizeHandler);
    }, []);
    useEffect(() => {
        return () => {
            window.removeEventListener("resize", resizeHandler);
        };
    }, []);
    return React.createElement(WrappedComponent, Object.assign({}, props, { windowSize: { width } }));
};
