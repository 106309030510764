import React from "react";
import styled from "styled-components";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { round } from "@boomq/utils";
import { CustomizedChartAxisTick } from "../CustomizedChartAxisTick";
import { CustomizedChartDot } from "../CustomizedChartDot";
import { CustomizedChartTooltip } from "../CustomizedChartTooltip";
import { getSlaUnitByRestrictionType } from "../../../../helpers/sla/slaUnit";
import { generateSlaTypeIdFromData } from "../../../../helpers/slaReport/slaTypeIdFromData";
import { generateSlaTypeItemFormattedText } from "../../../../helpers/slaReport/slaTypeItemFormattedText";
import { getSlaTimelineReportChartFormattedText } from "../../../../helpers/slaReport/slaTimelineReportChartFormattedText";
import { getSlaTypeColor } from "../../../../helpers/slaReport/slaTypeColor";
import { sizes } from "../../../styled/response";
const getSlaTypeUnitText = (slaType) => {
    const slaTypeUnit = getSlaUnitByRestrictionType(slaType.type, slaType.restrictionType);
    return slaTypeUnit ? slaTypeUnit.text : { defaultMessage: " ", id: "title.empty" };
};
const getPointName = (data, formatMessage) => {
    const { slaType, testDuration } = data || {};
    return `SLA: ${generateSlaTypeItemFormattedText(slaType, formatMessage)} ${getSlaTimelineReportChartFormattedText(slaType, testDuration, formatMessage)}`;
};
const getSlaTypeDataBySlaTypeId = (slaTypesData, slaTypeId) => slaTypesData.find((slaType) => slaType.id === slaTypeId);
const getTicksData = (data = []) => (data || []).reduce((res, slaTypeData) => (Object.assign(Object.assign({}, res), (slaTypeData.points || []).reduce((resPoint, point) => (Object.assign(Object.assign({}, resPoint), { [point.testId]: point })), res))), {});
const SlaReportChart = ({ chartData, excludedTestIds, height, intl: { formatMessage }, isShowLegend, legendPayload, minHeight = 250, onDeleteTest, slaTypesData, testDuration, windowSize }) => {
    const { width } = windowSize || {};
    return (React.createElement(ResponsiveContainer, { width: "100%", height: height || minHeight, minHeight: width <= sizes.tablet ? 120 : minHeight },
        React.createElement(ResizeLineChart, { margin: {
                bottom: 0,
                right: width <= sizes.giant ? 30 : 10,
                top: 0
            } },
            React.createElement(CartesianGrid, { strokeDasharray: "3 3" }),
            React.createElement(XAxis, { allowDuplicatedCategory: false, dataKey: "testId", height: 50, tick: React.createElement(CustomizedChartAxisTick, { onDelete: onDeleteTest, ticksData: getTicksData(chartData), title: formatMessage({
                        defaultMessage: "exclude test run",
                        id: "account.sla.report.profile.chart.exclude.test"
                    }) }), padding: { left: 60, right: 60 } }),
            React.createElement(YAxis, { type: "number", allowDecimals: false, domain: [0, (dataMax) => round(1.1 * dataMax, 1) + 1] }),
            React.createElement(Tooltip, { content: React.createElement(CustomizedChartTooltip, { intl: { formatMessage } }) }),
            Array.isArray(chartData) &&
                chartData.map((line) => {
                    const { slaType } = line || {};
                    const name = getPointName(line, formatMessage);
                    const stroke = getSlaTypeColor(getSlaTypeDataBySlaTypeId(slaTypesData, generateSlaTypeIdFromData(slaType)));
                    const unit = formatMessage(getSlaTypeUnitText(slaType));
                    const filteredPoints = (line.points || []).filter((point) => !(excludedTestIds || []).includes(point.testId));
                    return (line.points && (React.createElement(Line, { key: line.id, data: filteredPoints, activeDot: false, dot: React.createElement(CustomizedChartDot, { slaType: slaType }), name: name, type: "linear", dataKey: "slaResult", stroke: stroke, isAnimationActive: false, strokeWidth: 3, unit: unit })));
                }),
            isShowLegend && React.createElement(Legend, { payload: legendPayload }))));
};
const ResizeLineChart = styled(LineChart) `
    width: 100% !important;
    .recharts-surface {
        width: 100% !important;
    }
`;
export default SlaReportChart;
