import { DEFAULT_BLOCK, DEFAULT_EXTRACTED_TEST_PROJECT_SETTINGS_SET_NAME_PREFIX } from "./constants";
import { SYSTEM_METRIC_LABEL_BY_TYPE, SYSTEM_METRIC_TITLE_BY_TYPE } from "./intl";
import { Parameters } from "../../components/account/TestProjectSettingsSet/Parameters";
import { DefaultCookiesContainer } from "../../containers/account/TestProjectSettingsSet/DefaultCookies";
import { Monitoring } from "../../containers/account/TestProjectSettingsSet/Monitoring";
import { OtherSettings } from "../../containers/account/TestProjectSettingsSet/OtherSettings";
import { JMeterConfigPropertiesContainer } from "../../containers/account/TestProjectSettingsSet/JMeterConfigProperties";
import { UrlOverwrite } from "../../containers/account/TestProjectSettingsSet/UrlOverwrite";
import { WebhooksContainer } from "../../containers/account/TestProjectSettingsSet/Webhooks";
import { getMinutes, getTimeZoneHours } from "../date";
import { SettingsSetAction, SettingsSetWizardItem, TeamMemberPermission } from "../types";
const testProjectSettingsSetBlocks = {
    [SettingsSetWizardItem.Cookies]: DefaultCookiesContainer,
    [SettingsSetWizardItem.JmeterConfigProperties]: JMeterConfigPropertiesContainer,
    [SettingsSetWizardItem.Monitoring]: Monitoring,
    [SettingsSetWizardItem.OtherSettings]: OtherSettings,
    [SettingsSetWizardItem.Parameters]: Parameters,
    [SettingsSetWizardItem.UrlOverwrite]: UrlOverwrite,
    [SettingsSetWizardItem.Webhooks]: WebhooksContainer,
};
export const getTestProjectSettingsSetWizardItems = () => [
    SettingsSetWizardItem.Parameters,
    SettingsSetWizardItem.UrlOverwrite,
    SettingsSetWizardItem.OtherSettings,
    SettingsSetWizardItem.JmeterConfigProperties,
    SettingsSetWizardItem.Cookies,
    SettingsSetWizardItem.Webhooks,
    SettingsSetWizardItem.Monitoring
];
const actionsByTeamMemberPermissionEdit = [SettingsSetAction.Add, SettingsSetAction.Save];
const actionsByTeamMemberPermissions = {
    [TeamMemberPermission.Admin]: actionsByTeamMemberPermissionEdit,
    [TeamMemberPermission.Edit]: actionsByTeamMemberPermissionEdit,
    [TeamMemberPermission.Run]: actionsByTeamMemberPermissionEdit
};
const getSettingsSetActionsByTeamMemberPermission = (permission) => {
    const actionsByPermission = actionsByTeamMemberPermissions[permission];
    return actionsByPermission ? actionsByPermission : [];
};
const getSettingsSetActionsByTeamMemberPermissions = (permissions) => [
    ...new Set(permissions.reduce((res, permission) => [
        ...res,
        ...getSettingsSetActionsByTeamMemberPermission(permission)
    ], []))
];
export const isAllowedSettingsSetActionByPermissions = (permissionList = [], actionName) => {
    const actionsByPermissions = getSettingsSetActionsByTeamMemberPermissions(permissionList);
    return actionsByPermissions.includes(actionName);
};
const getTestProjectSettingsSetBlockKeyByName = (name) => Object.keys(testProjectSettingsSetBlocks).includes(name) ? name : DEFAULT_BLOCK;
export const getTestProjectSettingsSetBlockByName = (name) => testProjectSettingsSetBlocks[getTestProjectSettingsSetBlockKeyByName(name)];
export const generateExtractedSettingsSetName = (projectName) => `${DEFAULT_EXTRACTED_TEST_PROJECT_SETTINGS_SET_NAME_PREFIX}_${getTimeZoneHours()}:${getMinutes()}_from_${projectName}`;
export const prepareSystemMetricItems = (sysMetricList = []) => (sysMetricList || []).map((sysMetric) => generateSystemMetricByValueAndText(sysMetric, sysMetric));
const generateSystemMetricByValueAndText = (value, text, title) => ({
    id: value,
    text,
    title,
    value
});
const getSystemMetricLabelByType = (type) => Object.keys(SYSTEM_METRIC_LABEL_BY_TYPE).includes(type)
    ? SYSTEM_METRIC_LABEL_BY_TYPE[type]
    : SYSTEM_METRIC_LABEL_BY_TYPE.DEFAULT_VALUE;
const getSystemMetricTitleByType = (type) => Object.keys(SYSTEM_METRIC_TITLE_BY_TYPE).includes(type)
    ? SYSTEM_METRIC_TITLE_BY_TYPE[type]
    : SYSTEM_METRIC_TITLE_BY_TYPE.DEFAULT_VALUE;
const generateSystemMetricWithTextMessageByValue = (value, formatMessage) => generateSystemMetricByValueAndText(value, formatMessage(getSystemMetricLabelByType(value)), formatMessage(getSystemMetricTitleByType(value)));
export const generateSystemMetricWithTextMessageItems = (formatMessage, monitoringSysMetricParts) => (monitoringSysMetricParts || []).map((systemMetricValue) => generateSystemMetricWithTextMessageByValue(systemMetricValue, formatMessage));
export const getMetricsFromItems = (metricItems = []) => (metricItems || []).map((metricItem) => metricItem.value);
export const parseMonitoringDataFromDto = (systemMetricsMonitoring) => {
    const systemMetricsMonitoringData = systemMetricsMonitoring || {};
    return {
        systemMetrics: systemMetricsMonitoringData.parts || [],
        systemMetricsMonitoringEnabled: systemMetricsMonitoringData.systemMetricsMonitoringEnabled,
        systemMonitoringTelegrafLocation: systemMetricsMonitoringData.systemMonitoringTelegrafLocation
    };
};
