import React from "react";
import { Accordion, AccordionTab } from "@boomq/uikit";
import { getClassNameByParams, NewChartSerieButton, StyledIconClose, StyledSidebar, Title } from "./styled";
import { ChartCommonSettings } from "./ChartCommonSettings";
import { ChartSerieParamsHeader } from "./ChartSerieParamsHeader";
import { ChartSerieParamsContainer } from "../../../../containers/account/Report/ChartSerieParams";
export const PFLBChartSettings = ({ activeChartSerieIndexes, aggregationIntervalItems, axisLeftYItemList, axisRightYItemList, chartData, chartSerieAxisItems, chartSerieParamsHeader, chartSeries, chartSettingsHeader, chartTypeItems, id, isShowParamsSidebar, newChartSerieButtonLabel, onChangeCommonSetting, onClickAddChartSerie, onClickChartSerieHeader, onClickDeleteChartSerie, onClickSidebarCloseIcon, testList }) => (React.createElement(StyledSidebar, { className: getClassNameByParams(isShowParamsSidebar) },
    React.createElement(StyledIconClose, { onClick: onClickSidebarCloseIcon }),
    React.createElement(Title, null, chartData === null || chartData === void 0 ? void 0 : chartData.title),
    React.createElement(ChartCommonSettings, Object.assign({}, chartData, { axisLeftYItemList: axisLeftYItemList, axisRightYItemList: axisRightYItemList, chartSettingsHeader: chartSettingsHeader, onChangeSetting: onChangeCommonSetting })),
    React.createElement(Accordion, { activeIndexes: activeChartSerieIndexes }, chartSeries.map((chartSerieProps) => (React.createElement(AccordionTab, { key: chartSerieProps.id, id: chartSerieProps.id, headerTemplate: React.createElement(ChartSerieParamsHeader, { chartId: id, displayProps: chartSerieProps === null || chartSerieProps === void 0 ? void 0 : chartSerieProps.displayProps, id: chartSerieProps.id, name: chartSerieParamsHeader, onClickDeleteIcon: onClickDeleteChartSerie, onClickHeader: onClickChartSerieHeader }) },
        React.createElement(ChartSerieParamsContainer, Object.assign({ aggregationIntervalItems: aggregationIntervalItems, chartSerieAxisItems: chartSerieAxisItems, chartId: chartData.id, chartTypeItems: chartTypeItems, testList: testList }, chartSerieProps)))))),
    React.createElement(NewChartSerieButton, { onClick: onClickAddChartSerie }, newChartSerieButtonLabel)));
