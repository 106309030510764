export const RANGE_SLIDER_LABEL = {
    defaultMessage: "Time",
    id: "account.new.test.project.jmx.chart.timeline.range.slider.label"
};
export const RANGE_SLIDER_UNITS_LABEL = {
    defaultMessage: "min",
    id: "account.new.test.project.jmx.chart.timeline.range.slider.units"
};
export const SWITCHER_MERGE_GROUPS_LABEL = {
    defaultMessage: "Merge groups",
    id: "account.new.test.project.jmx.chart.merge.groups"
};
