export var AssertionFilter;
(function (AssertionFilter) {
    AssertionFilter["JsonPath"] = "JSON";
    AssertionFilter["NoFilter"] = "NO_FILTER";
    AssertionFilter["XPath2"] = "XPATH2";
})(AssertionFilter || (AssertionFilter = {}));
export var AssertionCondition;
(function (AssertionCondition) {
    AssertionCondition["Equals"] = "EQUALS";
    AssertionCondition["NotEquals"] = "NOT_EQUALS";
    AssertionCondition["NotRegexpContains"] = "NOT_REGEXP_CONTAINS";
    AssertionCondition["NotRegexpMatches"] = "NOT_REGEXP_MATCHES";
    AssertionCondition["NotSubstring"] = "NOT_SUBSTRING";
    AssertionCondition["RegexpContains"] = "REGEXP_CONTAINS";
    AssertionCondition["RegexpMatches"] = "REGEXP_MATCHES";
    AssertionCondition["Substring"] = "SUBSTRING";
})(AssertionCondition || (AssertionCondition = {}));
export var AssertionConditionComparison;
(function (AssertionConditionComparison) {
    AssertionConditionComparison["Equals"] = "EQUALS";
    AssertionConditionComparison["GreaterOrEquals"] = "GREATER_OR_EQUALS";
    AssertionConditionComparison["GreaterThan"] = "GREATER_THAN";
    AssertionConditionComparison["LessThan"] = "LESS_THAN";
    AssertionConditionComparison["LessOrEquals"] = "LESS_OR_EQUALS";
    AssertionConditionComparison["NotEquals"] = "NOT_EQUALS";
})(AssertionConditionComparison || (AssertionConditionComparison = {}));
export var AssertionConditionExist;
(function (AssertionConditionExist) {
    AssertionConditionExist["Exists"] = "EXISTS";
    AssertionConditionExist["NotExists"] = "NOT_EXISTS";
})(AssertionConditionExist || (AssertionConditionExist = {}));
export var AssertionConditionNull;
(function (AssertionConditionNull) {
    AssertionConditionNull["Null"] = "NULL";
    AssertionConditionNull["NotNull"] = "NOT_NULL";
})(AssertionConditionNull || (AssertionConditionNull = {}));
export var AssertionConditionXPath2;
(function (AssertionConditionXPath2) {
    AssertionConditionXPath2["MatchesXPath2"] = "MATCHES_XPATH2";
    AssertionConditionXPath2["NotMatchesXPath2"] = "NOT_MATCHES_XPATH2";
})(AssertionConditionXPath2 || (AssertionConditionXPath2 = {}));
export var AssertionDurationType;
(function (AssertionDurationType) {
    AssertionDurationType["Duration"] = "DURATION";
})(AssertionDurationType || (AssertionDurationType = {}));
export var AssertionResponseType;
(function (AssertionResponseType) {
    AssertionResponseType["RequestData"] = "REQUEST_DATA";
    AssertionResponseType["RequestHeaders"] = "REQUEST_HEADERS";
    AssertionResponseType["ResponseBody"] = "RESPONSE_BODY";
    AssertionResponseType["ResponseBodyJson"] = "RESPONSE_BODY_JSON";
    AssertionResponseType["ResponseBodyXPath2"] = "RESPONSE_BODY_XPATH2";
    AssertionResponseType["ResponseCode"] = "RESPONSE_CODE";
    AssertionResponseType["ResponseHeaders"] = "RESPONSE_HEADERS";
    AssertionResponseType["ResponseMessage"] = "RESPONSE_MESSAGE";
    AssertionResponseType["ResponseTikaExtract"] = "RESPONSE_TIKA_EXTRACT";
    AssertionResponseType["UrlSampled"] = "URL_SAMPLED";
})(AssertionResponseType || (AssertionResponseType = {}));
export var AssertionSizeType;
(function (AssertionSizeType) {
    AssertionSizeType["FullResponse"] = "FULL_RESPONSE_SIZE";
    AssertionSizeType["ResponseBody"] = "RESPONSE_BODY_SIZE";
    AssertionSizeType["ResponseCode"] = "RESPONSE_CODE_SIZE";
    AssertionSizeType["ResponseHeaders"] = "RESPONSE_HEADERS_SIZE";
    AssertionSizeType["ResponseMessage"] = "RESPONSE_MESSAGE_SIZE";
})(AssertionSizeType || (AssertionSizeType = {}));
export var AssertionTemplateType;
(function (AssertionTemplateType) {
    AssertionTemplateType["Duration"] = "DURATION";
    AssertionTemplateType["Response"] = "RESPONSE";
    AssertionTemplateType["ResponseBody"] = "RESPONSE_BODY";
    AssertionTemplateType["ResponseBodyJson"] = "RESPONSE_BODY_JSON";
    AssertionTemplateType["ResponseBodyXPath2"] = "RESPONSE_BODY_XPATH2";
    AssertionTemplateType["Size"] = "SIZE";
})(AssertionTemplateType || (AssertionTemplateType = {}));
