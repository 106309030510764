import styled from "styled-components";
import { Color, H2Styles, IconSettings } from "@boomq/uikit";
const axisEditableLabelTheme = {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    zIndex: 99
};
const axisYEditableLabelTheme = Object.assign(Object.assign({}, axisEditableLabelTheme), { cursor: "vertical-text", minWidth: "190px", position: "absolute", top: 0, transformOrigin: "left bottom", width: "190px" });
export const axisXEditableLabelTheme = Object.assign(Object.assign({}, axisEditableLabelTheme), { left: 0, marginTop: "10px", top: "100%", width: "100%" });
export const axisLeftYEditableLabelTheme = Object.assign(Object.assign({}, axisYEditableLabelTheme), { left: 0, transform: "translate(25px, 190px) rotate(-90deg)" });
export const axisRightYEditableLabelTheme = Object.assign(Object.assign({}, axisYEditableLabelTheme), { right: 0, transform: "translate(190px, 0) rotate(90deg)" });
export const ChartHeader = styled.div `
    align-items: center;
    display: flex;
    height: 34px;
    min-height: 34px;
    width: 100%;
    .editable-title {
        align-items: center;
        margin-left: 4px;
        visibility: visible;
        width: 100%;
        ${H2Styles}
    }
`;
export const StyledIconSettings = styled(IconSettings) `
    color: ${Color.blue};
    cursor: pointer;
`;
export const ChartPreviewWrapper = styled.div `
    display: flex;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 12px;
    position: relative;
    &:hover ${StyledIconSettings} {
        display: flex;
    }
    &:hover .editable-title {
        border: 1px solid ${Color.lightGrey95};
        &:hover {
            border: 2px solid ${Color.darkGrey5};
        }
    }
    @media print {
        &:hover .editable-title {
            border: none;
        }
    }
`;
