import React from "react";
import { connect } from "react-redux";
import { getCurrentLanguage, getVisibility, withResize } from "@boomq/common";
import { AccountPage as AccountPageComponent } from "../../../components/routes/AccountPage";
import logo from "../../../assets/images/logo.svg";
import logoWhite from "../../../assets/images/logo_white.svg";

const connector = connect((state) => ({
    currentLanguage: getCurrentLanguage(state),
    modalVisibility: getVisibility(state)
}));

const getDocsLinkByLanguage = (currentLanguage) => `/docs/${currentLanguage}/`;

const AccountPage = ({ currentLanguage, ...restProps }) => (
    <AccountPageComponent
        docsLink={getDocsLinkByLanguage(currentLanguage)}
        logo={logo}
        logoMobile={logoWhite}
        {...restProps}
    />
);

export default withResize(connector(AccountPage));
