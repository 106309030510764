import { Color, MediaBreakpoint } from "./enums";
import { HashMap } from "../../../helpers/types";

const padding1 = "clamp(16px, 2.5974025974vw, 40px)";

export const borderRadius8 = "8px";

export const palette = {
    primary1: Color.black,
    primary11: Color.blue,
    primary111: Color.blue2,
    primary2: Color.white,
    secondary1: Color.red,
    secondary2: Color.blue,
    secondary3: Color.lightRed,
    secondary4: Color.lightBlue,
    sidebar: "#d6d6d6"
};

export const typography = {
    fontFamily:
        '"TT Interphases Pro",Roboto,Sans-serif,Montserrat,-apple-system,BlinkMacSystemFont,Segoe UI, Oxygen-Sans,Ubuntu,Cantarell,Helvetica Neue,Helvetica,Arial',
    fontSize4: "clamp(13px, 1.038961039vw, 16px)"
};

export const theme = {
    borderRadius8,
    padding1,
    palette,
    typography
};

const customMediaQuery = (maxWidth: number): string => `@media (max-width: ${maxWidth}em)`;

export const media = Object.keys(MediaBreakpoint).reduce((res: HashMap<string>, sizeLabel: string): HashMap<string> => {
    const emSize: number = MediaBreakpoint[sizeLabel] / 16;

    res[sizeLabel.toLocaleLowerCase()] = customMediaQuery(emSize);

    return res;
}, {});
