import React from "react";
import { isEmptyArray } from "@boomq/utils";
import { SidebarElementWithMargin, ScrollContainer } from "./styled";
import { EditorElement } from "../EditorElement";
import { CollapsibleBlock } from "../../../common/CollapsibleBlock";
import { CollapsibleBlockWithMargin } from "../../../styled/TestProject";
export const EditorLeftSidebar = (props) => (React.createElement(ScrollContainer, null,
    React.createElement(CollapsibleBlock, { isOpened: true, title: props.elementsBlockTitle }, isEmptyArray(props.editorElementItems) ? (React.createElement(React.Fragment, null)) : (props.editorElementItems.map((item) => (React.createElement(EditorElement, { key: item.id, dragdropScope: props.dragdropScope, onDragStart: props.onDragStart, node: item }))))),
    React.createElement(CollapsibleBlockWithMargin, { isOpened: true, title: props.flowControlElementsBlockTitle }, isEmptyArray(props.flowControlElementItems) ? (React.createElement(React.Fragment, null)) : (props.flowControlElementItems.map((item) => (React.createElement(EditorElement, { key: item.id, dragdropScope: props.dragdropScope, onDragStart: props.onDragStart, node: item }))))),
    React.createElement(CollapsibleBlockWithMargin, { isOpened: true, title: props.importDataElementsBlockTitle }, isEmptyArray(props.importDataElementItems) ? (React.createElement(React.Fragment, null)) : (props.importDataElementItems.map((item) => (React.createElement(SidebarElementWithMargin, { key: item.id, data: item, onClick: props.onClickImportDataElement })))))));
