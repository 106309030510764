import React from "react";
import { isEmpty, useIntl } from "@boomq/utils";
import { PARAMETER_NAME_INPUT_LABEL_TEXT } from "./intl";
import { HeaderColumn, HeaderColumnContent, ParameterName, StyledInvalidParameterIndicator, Table, TableColumn, TableHead, usedParameterIndicatorTheme } from "./styled";
import { UsedParameterIndicator } from "../UsedParameterIndicator";
import { isValidParameterName } from "../../../helpers/validators/parameters";
const renderInvalidParameter = (paramName) => !isValidParameterName(paramName) ? React.createElement(StyledInvalidParameterIndicator, null) : React.createElement(React.Fragment, null);
const renderParameterName = ({ colIndex, hasHeaders, parameterNameLabel, parameterName, onChangeParameterName }) => hasHeaders ? (parameterName) : (React.createElement(ParameterName, { label: parameterNameLabel, onChange: (value) => onChangeParameterName(colIndex, value), placeholder: parameterNameLabel, value: parameterName }));
const renderUsedRequestParameterName = (usedRequestParameterNames, parameterName) => (usedRequestParameterNames || []).includes(parameterName) ? (React.createElement(UsedParameterIndicator, { theme: usedParameterIndicatorTheme })) : (React.createElement(React.Fragment, null));
const renderPreviewTableBody = (fileContent) => isEmpty(fileContent) ? (React.createElement(React.Fragment, null)) : (React.createElement("tbody", null, fileContent.map((row, rowIndex) => (React.createElement("tr", { key: rowIndex }, row.map((column, colIndex) => (React.createElement(TableColumn, { key: rowIndex + "_" + colIndex }, column))))))));
const renderPreviewTableHeader = ({ hasHeaders, onChangeParameterName, parameterNameLabel, parameterNames, usedRequestParameterNames }) => isEmpty(parameterNames) ? (React.createElement(React.Fragment, null)) : (React.createElement("thead", null,
    React.createElement(TableHead, { key: "0" }, parameterNames.map((parameterName, colIndex) => (React.createElement(HeaderColumn, { key: "header" + colIndex, hasHeaders: hasHeaders },
        React.createElement(HeaderColumnContent, null,
            renderUsedRequestParameterName(usedRequestParameterNames, parameterName),
            renderParameterName({
                colIndex,
                hasHeaders,
                parameterNameLabel,
                parameterName,
                onChangeParameterName
            }),
            renderInvalidParameter(parameterName))))))));
export const ParameterCsvPreviewTable = ({ fileContent, hasHeaders, onChangeParameterName, parameterNames, usedRequestParameterNames }) => {
    const { formatMessage } = useIntl();
    const parameterNameLabel = formatMessage(PARAMETER_NAME_INPUT_LABEL_TEXT);
    return isEmpty(parameterNames) && isEmpty(fileContent) ? (React.createElement(React.Fragment, null)) : (React.createElement(Table, null,
        renderPreviewTableHeader({
            hasHeaders,
            onChangeParameterName,
            parameterNameLabel,
            parameterNames,
            usedRequestParameterNames
        }),
        renderPreviewTableBody(fileContent)));
};
