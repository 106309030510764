var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { useResize } from "@boomq/utils";
import { Description, EstimatedStarTimeText, Plate, PlateList, PlateText, StyledTimer, Text } from "./styled";
import CheckStatusItem from "../CheckStatusItem";
import CheckStatusMobile from "../CheckStatusMobile";
import Timer from "../../common/Timer";
import { IconLoader } from "../../styled/Icons";
import { sizes } from "../../styled/response";
import { convertDateToTimestamp, getMinutes, getTimeZoneHours } from "../../../helpers/date";
import { isTestCompleted } from "../../../helpers/testStatus";
import { getPreliminaryStages } from "../../../models/testRun";
const getResourcesAllocatingText = ({ createDate, elapsedTimeText }) => {
    const startTime = convertDateToTimestamp(createDate);
    return (React.createElement(React.Fragment, null,
        React.createElement(Text, null, elapsedTimeText),
        React.createElement(StyledTimer, null,
            React.createElement(Timer, { startTime: startTime, type: "increase" }))));
};
const getTextByStatus = (_a) => {
    var { percent, displayState } = _a, restProps = __rest(_a, ["percent", "displayState"]);
    return !isTestCompleted(displayState) ? getResourcesAllocatingText(restProps) : `${percent}%`;
};
export const PlateWithSocket = ({ currentStageData, elapsedTimeText, estimatedStartTime, estimatedStartTimeText, isShowEstimatedTime, task }) => {
    const { width } = useResize();
    const _a = currentStageData || {}, { percent } = _a, restStageData = __rest(_a, ["percent"]);
    return (React.createElement(Plate, null,
        width > sizes.tablet ? (React.createElement(PlateList, null, (getPreliminaryStages() || []).map(({ img, index, title }) => {
            const startPercent = 20 * index;
            const finishPercent = startPercent + 20;
            return (React.createElement(CheckStatusItem, { img: img, key: index, isError: percent >= startPercent && percent < finishPercent && (task === null || task === void 0 ? void 0 : task.error), isFetching: percent >= startPercent && percent < finishPercent, isVerified: percent >= finishPercent, text: title }));
        }))) : (React.createElement(CheckStatusMobile, Object.assign({ percent: percent }, restStageData))),
        React.createElement(Description, null,
            React.createElement(PlateText, null, isShowEstimatedTime && (React.createElement(EstimatedStarTimeText, null,
                estimatedStartTimeText,
                "\u00A0",
                estimatedStartTime ? (`${getTimeZoneHours(estimatedStartTime)}:${getMinutes(estimatedStartTime)}`) : (React.createElement(IconLoader, null))))),
            React.createElement(PlateText, null, getTextByStatus(Object.assign(Object.assign({}, task), { elapsedTimeText, percent }))))));
};
