import { isEmpty } from "@boomq/utils";
import { DEFAULT_CONNECT_TIMEOUT_VALUE, DEFAULT_RESPONSE_TIMEOUT_VALUE, DEFAULT_TEST_PROJECT_SETTINGS_SET_NAME_PREFIX, DEFAULT_TIMER_TYPE, DEFAULT_TIMER_VALUE } from "./constants";
import { generateConfigurationElements } from "./configurationElements";
import { generateSupportingFilesFromCsvParameters } from "./generators2";
import { generateDefaultTimer } from "./timers";
import { generateToolConfiguration } from "./toolConfiguration";
import { getMinutes, getTimeZoneHours } from "../date";
import { RequestTimerParams } from "../types";
import { getTimerValueByType } from "../requestTimers";
import { prepareUrlOverwrite } from "../urlOverwrite";
import { prepareWebhooksData } from "../webhook";
import { getTimerType } from "../../models/requestTimer";
import { initialState as testProjectSettingsSetInitialState } from "../../redux/reducers/testProjectSettingsSet";
const getConfigHttpRequestDefaultsParamValue = (data = {}, paramName, defaultValue) => isEmpty(data[paramName]) ? defaultValue : data[paramName];
const prepareHttpRequestDefaultsDtoParamValue = (data = {}, paramName, defaultValue) => isEmpty(data[paramName]) ? defaultValue : data[paramName];
const generateSettingsSetName = () => `${DEFAULT_TEST_PROJECT_SETTINGS_SET_NAME_PREFIX}_${getTimeZoneHours()}:${getMinutes()}`;
export const generateNewSettingsSetData = ({ configDefaultTimerData = { type: DEFAULT_TIMER_TYPE }, defaultCookiesData, defaultHeadersData, httpRequestDefaultsData, webhooksData }) => {
    const defaultTimerType = getTimerType(configDefaultTimerData) || DEFAULT_TIMER_TYPE;
    return {
        defaultCookies: defaultCookiesData,
        defaultHeaders: defaultHeadersData,
        defaultTimer: Object.assign(Object.assign({}, getTimerValueByType(Object.assign(Object.assign({}, configDefaultTimerData), { [RequestTimerParams.Type]: defaultTimerType }), DEFAULT_TIMER_VALUE)), { enabled: true, [RequestTimerParams.Type]: defaultTimerType }),
        httpRequestDefaults: {
            connectTimeout: getConfigHttpRequestDefaultsParamValue(httpRequestDefaultsData, "connectTimeout", DEFAULT_CONNECT_TIMEOUT_VALUE),
            responseTimeout: getConfigHttpRequestDefaultsParamValue(httpRequestDefaultsData, "responseTimeout", DEFAULT_RESPONSE_TIMEOUT_VALUE)
        },
        webhookSettingsList: prepareWebhooksData(webhooksData),
        id: testProjectSettingsSetInitialState.id,
        name: generateSettingsSetName()
    };
};
const prepareHttpRequestDefaultsData = (httpRequestDefaults, configHttpRequestDefaults) => (Object.assign(Object.assign({}, configHttpRequestDefaults), { connectTimeout: prepareHttpRequestDefaultsDtoParamValue(httpRequestDefaults, "connectTimeout", configHttpRequestDefaults.connectTimeout), responseTimeout: prepareHttpRequestDefaultsDtoParamValue(httpRequestDefaults, "responseTimeout", configHttpRequestDefaults.responseTimeout) }));
export const generateSettingsSetDtoParamsData = ({ configHttpRequestDefaults, csvParameters, defaultCookies, defaultHeaders, defaultTimer, httpRequestDefaults, jmeterConfigProperties, labelSet, literalParameters, modelSchema, monitoringSystemMetrics, name, urlOverwriteItems, webhookItems }) => ({
    labelSet,
    name,
    content: Object.assign(Object.assign({ configurationElements: generateConfigurationElements({
            defaultCookies,
            defaultHeaders,
            httpRequestDefaults: prepareHttpRequestDefaultsData(httpRequestDefaults, configHttpRequestDefaults),
            requestParameters: { csvParameters, literalParameters }
        }, modelSchema), urlOverwrites: prepareUrlOverwrite(urlOverwriteItems) }, generateDefaultTimer(defaultTimer, modelSchema)), { modelVersion: "V_2_0_0", systemMetricsMonitoring: {
            systemMetricsMonitoringEnabled: isEmpty(monitoringSystemMetrics) ? false : true,
            parts: monitoringSystemMetrics
        }, supportingFiles: generateSupportingFilesFromCsvParameters(csvParameters), toolConfiguration: generateToolConfiguration({ jmeterConfigProperties }), webhookSettingsList: prepareWebhooksData(webhookItems) })
});
