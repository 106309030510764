export const CAROUSEL_WELCOME_TEXTS = [
    {
        defaultMessage: "Easily run tests with a stable ramp-up or find maximum performance with a stepped load.",
        id: "account.user.welcome.slide.text.workload.editor"
    },
    {
        defaultMessage: "Set up requests easily from scratch or import them from Insomnia or Postman. Test HTTP(-s), queues, and gRPC calls.",
        id: "account.user.welcome.slide.text.har"
    },
    {
        defaultMessage: "No need for your own infrastructure setup and costs, run tests from different locations.",
        id: "account.user.welcome.slide.text.locations"
    },
    {
        defaultMessage: "Have requests and transaction response times, infrastructure metrics, detailed error statistics at your fingertips. Compare test runs easily.",
        id: "account.user.welcome.slide.text.grafana"
    }
];
export const WELCOME_TITLE = {
    defaultMessage: "Welcome to PFLB platform!",
    id: "account.user.welcome.title"
};
export const WELCOME_TEXT = {
    defaultMessage: "Welcome to PFLB, a cloud-native performance testing platform that helps you check how your website or app handles the load and multiuser scenarios before going live. Check out our signature features below and start running your tests!",
    id: "account.user.welcome.text.cloud"
};
