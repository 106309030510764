import styled from "styled-components";
import { Color } from "@boomq/uikit";
export const ChildrenCounter = styled.div `
    border: 2px solid ${Color.blue};
    border-radius: 6px;
    color: ${Color.blue};
    height: 24px;
    line-height: 24px;
    margin-right: 8px;
    min-width: 24px;
    padding: 0 2px;
    text-align: center;
`;
