import { IconCopy, IconDelete, IconEdit } from "@boomq/uikit";
import { TeamMemberPermission } from "../../helpers/types";
export var ReportsItemAction;
(function (ReportsItemAction) {
    ReportsItemAction["AddLabel"] = "onAddLabel";
    ReportsItemAction["AddLabelWithCreating"] = "onAddLabelWithCreating";
    ReportsItemAction["Copy"] = "onCopy";
    ReportsItemAction["Delete"] = "onDelete";
    ReportsItemAction["DeleteLabel"] = "onDeleteLabel";
    ReportsItemAction["Edit"] = "onEdit";
    ReportsItemAction["SearchLabels"] = "onSearchLabels";
})(ReportsItemAction || (ReportsItemAction = {}));
const actionsByTeamMemberPermission = [ReportsItemAction.Edit];
const actionsByTeamMemberPermissions = {
    [TeamMemberPermission.Admin]: actionsByTeamMemberPermission,
    [TeamMemberPermission.Edit]: actionsByTeamMemberPermission,
    [TeamMemberPermission.Run]: actionsByTeamMemberPermission
};
const actionIconsByName = {
    [ReportsItemAction.Copy]: IconCopy,
    [ReportsItemAction.Delete]: IconDelete,
    [ReportsItemAction.Edit]: IconEdit
};
const getTestProjectsActionsByTeamMemberPermission = (permission) => {
    const actionsByPermission = actionsByTeamMemberPermissions[permission];
    return actionsByPermission ? actionsByPermission : [];
};
const getReportsActionsByTeamMemberPermissions = (permissions) => [
    ...new Set(permissions.reduce((res, permission) => [
        ...res,
        ...getTestProjectsActionsByTeamMemberPermission(permission)
    ], []))
];
const getActionIconByName = (actionName) => actionIconsByName[actionName];
export const getActionItemsByTeamMemberPermissions = (permissions) => getReportsActionsByTeamMemberPermissions(permissions).map((actionName) => ({
    id: actionName,
    actionName,
    Icon: getActionIconByName(actionName)
}));
const actionHandler = (props, actionName, params) => typeof props[actionName] === "function" ? props[actionName](params) : undefined;
export const reportsItemActions = {
    [ReportsItemAction.Copy]: (props, id) => actionHandler(props, ReportsItemAction.Copy, { id }),
    [ReportsItemAction.Delete]: (props, id) => actionHandler(props, ReportsItemAction.Delete, { id }),
    [ReportsItemAction.Edit]: (props, id) => actionHandler(props, ReportsItemAction.Edit, { id })
};
