import styled from "styled-components";
import { Color, IconLogout } from "@boomq/uikit";
import { LogoutWrapper } from "../Logout";
export const StyledIconLogout = styled(IconLogout) `
    fill: ${Color.blue};
    height: 40px;
    width: 40px;
`;
export const LogoutMobileWrapper = styled(LogoutWrapper) `
    margin-left: 0;
    padding: 8px 0;
`;
