var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getValueByPath, useIntl } from "@boomq/utils";
import { SETTINGS_SET_MONITORING_SYSTEM_METRICS_SELECT_LABEL, SETTINGS_SET_MONITORING_SYSTEM_METRICS_SELECT_PLACEHOLDER } from "./intl";
import { Monitoring } from "../../../../components/account/TestProjectSettingsSet/Monitoring";
import { generateSystemMetricWithTextMessageItems } from "../../../../helpers/testProjectSettingsSet";
import { monitoringSystemMetricSavingRequest, monitoringSystemMetricFileDownloadingRequest, monitoringSystemMetricPartsRefreshing } from "../../../../redux/actions/monitoring";
import { getClientPermissionList } from "../../../../redux/reducers/authorizePlate";
import { getSystemMetricPartItems, getSystemMetrics, getSystemMonitoringTarTelegrafLocation, getSystemMonitoringTelegrafLocation } from "../../../../redux/reducers/monitoring";
const connector = connect((state) => ({
    permissionList: getClientPermissionList(state),
    selectedSystemMetrics: getSystemMetrics(state),
    sysMetricPartItems: getSystemMetricPartItems(state),
    systemMonitoringTarTelegrafLocation: getSystemMonitoringTarTelegrafLocation(state),
    systemMonitoringTelegrafLocation: getSystemMonitoringTelegrafLocation(state)
}), {
    onSaveSystemMetric: monitoringSystemMetricSavingRequest,
    onDownloadFileConfig: monitoringSystemMetricFileDownloadingRequest,
    onLoadSystemMetricParts: monitoringSystemMetricPartsRefreshing
});
const getTestProjectSettingsSetId = (match) => getValueByPath(match, "params.test_project_settings_set", "new");
const WrappedMonitoring = (_a) => {
    var { onLoadSystemMetricParts, selectedSysMetricParts, sysMetricPartItems } = _a, rest = __rest(_a, ["onLoadSystemMetricParts", "selectedSysMetricParts", "sysMetricPartItems"]);
    const { formatMessage } = useIntl();
    const loadSystemMetricParts = () => typeof onLoadSystemMetricParts === "function" ? onLoadSystemMetricParts() : undefined;
    useEffect(() => {
        loadSystemMetricParts();
    }, []);
    const settingsSetId = getTestProjectSettingsSetId(rest.match);
    const systemMetricsMonitoringItems = generateSystemMetricWithTextMessageItems(formatMessage, sysMetricPartItems);
    const systemMetricsMonitoringSelectLabel = formatMessage(SETTINGS_SET_MONITORING_SYSTEM_METRICS_SELECT_LABEL);
    const systemMetricsMonitoringSelectPlaceholder = formatMessage(SETTINGS_SET_MONITORING_SYSTEM_METRICS_SELECT_PLACEHOLDER);
    return (React.createElement(Monitoring, Object.assign({}, rest, { settingsSetId: settingsSetId, systemMetricsMonitoringItems: systemMetricsMonitoringItems, systemMetricsMonitoringSelectLabel: systemMetricsMonitoringSelectLabel, systemMetricsMonitoringSelectPlaceholder: systemMetricsMonitoringSelectPlaceholder })));
};
export default connector(WrappedMonitoring);
