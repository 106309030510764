export const REQUEST_HEADERS_TITLE = {
    defaultMessage: "Headers",
    id: "account.test.project.settings.set.webhook.headers.title"
};
export const REQUEST_HEADER_KEY_PLACEHOLDER = {
    defaultMessage: "Key",
    id: "account.request.form.key.placeholder"
};
export const REQUEST_HEADER_VALUE_PLACEHOLDER = {
    defaultMessage: "Value",
    id: "account.request.form.value.placeholder"
};
export const REQUEST_URL_TITLE = {
    defaultMessage: "Url",
    id: "account.test.project.settings.set.webhook.url.title"
};
export const WEBHOOK_SETTINGS_SAVE_BUTTON_LABEL = {
    defaultMessage: "Save",
    id: "account.test.project.settings.set.webhook.save.button.label"
};
