import { handleActions } from "redux-actions";
import { getValueByPath, getUniqueId } from "@boomq/utils";
import { NEW_ITEM_ID } from "../../helpers/routes";
import { addUrlOverwriteItem, changeUrlOverwriteItemField, deleteUrlOverwriteItem, setUrlOverwriteItems } from "../actions/urlOverwrite";
export const initialState = {
    items: []
};
export default handleActions({
    [addUrlOverwriteItem]: (state) => (Object.assign(Object.assign({}, state), { items: [...state.items, { id: getUniqueId(), type: NEW_ITEM_ID, urlToRewrite: "", updatedUrl: "" }] })),
    [changeUrlOverwriteItemField]: (state, { payload }) => {
        const { error = {}, id, field, value } = payload || {};
        return Object.assign(Object.assign({}, state), { items: state.items.map((urlOverwriteItem) => urlOverwriteItem.id === id
                ? Object.assign(Object.assign({}, urlOverwriteItem), { error: Object.assign(Object.assign({}, getValueByPath(urlOverwriteItem, "error", {})), error), [field]: value }) : urlOverwriteItem) });
    },
    [deleteUrlOverwriteItem]: (state, { payload }) => payload
        ? Object.assign(Object.assign({}, state), { items: [...state.items.filter((urlOverwriteItem) => urlOverwriteItem.id !== payload)] }) : state,
    [setUrlOverwriteItems]: (state, { payload }) => (Object.assign(Object.assign({}, state), { items: payload }))
}, initialState);
const getStateParam = (state, paramName) => state && state.urlOverwrite ? state.urlOverwrite[paramName] : initialState[paramName];
export const getUrlOverwriteItems = (state) => getStateParam(state, "items");
