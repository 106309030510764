import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { isEmpty } from "@boomq/utils";
import { DEFAULT_TAB_NAME } from "./constants";
import { TabBodySection, TabListSection, TabSection } from "./styled";
import { Tab } from "../Tab";
const getInitialState = (props) => isEmpty(props.defaultActiveTabName) ? null : props.defaultActiveTabName;
class Tabs extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            currentTabName: getInitialState(this.props)
        };
        this.getActiveTab = (currentTabName, items) => items && items.find((item) => item.name === currentTabName);
        this.getActiveTabName = (currentTabName, defaultActiveTabName, items) => {
            const existTab = this.getActiveTab(currentTabName, items);
            return (existTab === null || existTab === void 0 ? void 0 : existTab.name) || defaultActiveTabName;
        };
        this.isCheckedTab = (tabItem, activeTabName) => !isEmpty(tabItem) && tabItem.name === activeTabName;
        this.toggleHandler = (tabName) => {
            this.setState({ currentTabName: tabName });
            return typeof this.props.onChangeTab === "function" ? this.props.onChangeTab(tabName) : undefined;
        };
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        nextProps.items &&
            this.props.items &&
            nextProps.items.length !== this.props.items.length &&
            isEmpty(this.getActiveTab(this.state.currentTabName, nextProps.items)) &&
            this.setState({ currentTabName: nextProps.defaultActiveTabName });
        return nextProps.currentActiveTabName &&
            ![this.props.currentActiveTabName, this.state.currentTabName].includes(nextProps.currentActiveTabName)
            ? this.setState({
                currentTabName: this.getActiveTabName(nextProps.currentActiveTabName, this.state.currentTabName, this.props.items)
            })
            : undefined;
    }
    render() {
        const { defaultActiveTabName, elements, items, listTheme, name, tabListTheme, theme } = this.props;
        const activeTabName = this.getActiveTabName(this.state.currentTabName, defaultActiveTabName, items);
        const currentTabBodyComp = this.getActiveTab(activeTabName, items);
        const TabTemplateComponent = this.props.tabTemplate;
        return (React.createElement(TabSection, { theme: theme },
            React.createElement(TabListSection, { theme: tabListTheme }, (items || []).map((item) => TabTemplateComponent ? (React.createElement(TabTemplateComponent, { checked: this.isCheckedTab(item, activeTabName), icon: item.icon, key: item.id, name: name || DEFAULT_TAB_NAME, onChange: () => this.toggleHandler(item.name), title: item.title })) : (React.createElement(Tab, { checked: this.isCheckedTab(item, activeTabName), icon: item.icon, key: item.id, name: name || DEFAULT_TAB_NAME, onChange: () => this.toggleHandler(item.name), title: item.title, theme: listTheme })))),
            React.createElement(TabBodySection, null,
                !elements && currentTabBodyComp && currentTabBodyComp.component && (React.createElement(currentTabBodyComp.component.element, Object.assign({}, currentTabBodyComp.component.props, { key: currentTabBodyComp.name, name: currentTabBodyComp.name }))),
                elements &&
                    currentTabBodyComp &&
                    currentTabBodyComp.component &&
                    React.createElement(elements[currentTabBodyComp.component.elementName], Object.assign(Object.assign({}, currentTabBodyComp.component.props), { key: currentTabBodyComp.name, name: currentTabBodyComp.name })))));
    }
}
export default withRouter(Tabs);
