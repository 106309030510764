import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Buttons, LKButton, Logo, LogoWrapper, LogoutButton, StyledHeader } from "./styled";
import NavBar from "../NavBar";
import authorizeActions from "../../../redux/actions/authorize";
const { logout } = authorizeActions;
const connector = connect(null, { logout });
const Header = (props) => (React.createElement(StyledHeader, null,
    React.createElement(LogoWrapper, { to: "/" },
        React.createElement(Logo, { src: props.logo })),
    React.createElement(NavBar, { logo: props.logo }),
    React.createElement(Buttons, null,
        React.createElement(LKButton, { to: "/account" }, "\u0412 \u043B\u0438\u0447\u043D\u044B\u0439 \u043A\u0430\u0431\u0438\u043D\u0435\u0442"),
        React.createElement(LogoutButton, { onClick: () => props.logout() }, "\u0412\u044B\u0439\u0442\u0438"))));
export default withRouter(connector(Header));
