export const ACCOUNT_ROUTE = "account";
export const ADMIN_ROUTE = "admin";
export const ALL_TESTS_ROUTE = "test-runs";
export const AUTHORIZE_ROUTE = "authorize";
export const CHANGE_PASSWORD_ROUTE = "change-password";
export const NEW_ITEM_ID = "new";
export const NEW_TEST_ROUTE = "new-test";
export const PROFILE_ROUTE = "profile";
export const REPORT_ROUTE = "report";
export const REPORT_ID_PARAM_NAME = "report_id";
export const REPORTS_ROUTE = "reports";
export const SLA_REPORT_ID_PARAM_NAME = "sla_profile_id";
export const SLA_REPORT_PROFILES_ROUTE = "sla-report-profiles";
export const TEAMS_ROUTE = "teams";
export const TEAM_ID_PARAM_NAME = "team_id";
export const TEST_ID_PARAM_NAME = "test_id";
export const TEST_PROJECT_ID_PARAM_NAME = "test_project_id";
export const TEST_PROJECT_VERSION_PARAM_NAME = "test_project_version";
export const TEST_PROJECTS_ROUTE = "tests";
export const TEST_PROJECT_SETTINGS_SET_ROUTE = "test-settings";
export const TEST_PROJECT_SETTINGS_SET_ID_PARAM_NAME = "test_project_settings_set";
