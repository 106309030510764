import { combineActions, handleActions } from "redux-actions";
import { DEFAULT_DATE_FORMAT_LOCALE } from "@boomq/utils";
import clientActions from "../../actions/client";
import authorizeActions from "../../actions/authorize";
import { updateNotify } from "../../actions/notifications";
import { setPermissionList, setSelectedTeam } from "../../actions/teams";
import { getDateFormatLocaleFromLocalStorage } from "../../../helpers/dateFormat";
const { authSuccess, logout, saveToken, setActiveAction, setActiveUrl, setAuthProviders, secretTokenSuccess } = authorizeActions;
const { changeParamsSuccess, setDateFormatLocale } = clientActions;
export const initialState = {
    accessToken: null,
    activeAction: null,
    activeUrl: null,
    authProviders: [],
    clientInfo: {},
    clientNotifications: [],
    isHeapTracked: false,
    jwt: null,
    permissionList: [],
    secretToken: "",
    selectedTeam: {},
    dateFormatLocale: getDateFormatLocaleFromLocalStorage() || DEFAULT_DATE_FORMAT_LOCALE
};
export default handleActions({
    [combineActions(authSuccess, changeParamsSuccess)]: (state, action) => (Object.assign(Object.assign({}, state), { clientInfo: action.payload || {} })),
    [logout]: () => (Object.assign({}, initialState)),
    [secretTokenSuccess]: (state, action) => (Object.assign(Object.assign({}, state), { secretToken: action.payload })),
    [setPermissionList]: (state, action) => (Object.assign(Object.assign({}, state), { permissionList: action && Array.isArray(action.payload) ? action.payload : [] })),
    [saveToken]: (state, action) => (Object.assign(Object.assign({}, state), { jwt: action.payload })),
    [setActiveAction]: (state, action) => (Object.assign(Object.assign({}, state), { activeAction: action.payload })),
    [setActiveUrl]: (state, action) => (Object.assign(Object.assign({}, state), { activeUrl: action.payload })),
    [setAuthProviders]: (state, action) => (Object.assign(Object.assign({}, state), { authProviders: action.payload })),
    [setSelectedTeam]: (state, action) => action && action.payload ? Object.assign(Object.assign({}, state), { selectedTeam: action.payload }) : state,
    [updateNotify]: (state, action) => (Object.assign(Object.assign({}, state), { clientNotifications: state.clientNotifications.map((notify) => {
            if (notify.id === action.payload) {
                return Object.assign(Object.assign({}, notify), { read: true });
            }
            return notify;
        }) })),
    [setDateFormatLocale]: (state, action) => (Object.assign(Object.assign({}, state), { dateFormatLocale: action.payload }))
}, initialState);
