var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { dump as convertObjToYaml } from "js-yaml";
import { arrayToObject, getValueByPath, isEmpty, isEmptyArray } from "@boomq/utils";
import { TIME_COEFFICIENT } from "./constants";
import { generateTestProfileRequestGroupResourceLocations, generateTestProjectCommonParams } from "./generators2";
import { prepareResponsePreview } from "./request";
import { generateTestProfileParameters } from "./parameters";
import { generateTestProjectSlaParams } from "./sla";
import { getExtractorMatchByMatchType } from "../extractResponse";
import { TIMEOUT_TIME_COEFFICIENT } from "../parsers/constants";
import { getRequestById } from "../request";
import { ExtractorType, TestType } from "../types";
import { ControllerType, SamplerType, ThreadGroupController } from "../../models/threadGroupEditor/enums";
const generateExtractorParametersByType = (extractorType, value) => {
    switch (extractorType) {
        case ExtractorType.Boundary:
            return { left: value.leftBorder, right: value.rightBorder, fieldToCheck: value.fieldToCheck };
        case ExtractorType.RegEx:
            return { re: value.re, group: value.groupNumber, fieldToCheck: value.fieldToCheck };
        case ExtractorType.JsonPath:
            return { jsonpath: value.jsonpath };
        case ExtractorType.XPath:
            return { xpath: value.xpath };
        default: {
            return {};
        }
    }
};
const generateExtractors = (extractors = []) => extractors.reduce((res, extractor) => !isEmpty(extractor.variable)
    ? Object.assign(Object.assign({}, res), { [extractor.variable]: {
            match: getExtractorMatchByMatchType(extractor.match),
            type: extractor.type,
            extractorParameters: generateExtractorParametersByType(extractor.type, extractor.params)
        } }) : res, {});
export const getEnabledJMXProfiles = (JMXProfiles, groups) => Object.keys(JMXProfiles)
    .filter((JMXProfileId) => {
    const group = groups.find((group) => group.id === JMXProfileId);
    return !group || !group.enabled ? false : true;
})
    .reduce((res, JMXProfileId) => (Object.assign(Object.assign({}, res), { [JMXProfileId]: JMXProfiles[JMXProfileId] })), {});
const getStepParams = (step) => ({
    initDelaySec: step.initialDelay * TIME_COEFFICIENT,
    startupTimeSec: step.rampUp * TIME_COEFFICIENT,
    holdLoadTimeSec: step.stepLength * TIME_COEFFICIENT,
    shutdownTimeSec: step.rampDown * TIME_COEFFICIENT,
    startThreadsCount: step.usersPerStep
});
const prepareRequestManualTimers = (requestTimerList, timers) => (requestTimerList || []).map((timerId) => timers[timerId]);
const generateTestProfileRequest = (request, timers = {}) => (Object.assign({ body: getValueByPath(request, "body.TEXT", ""), extractors: generateExtractors(request.extractResponse), headers: arrayToObject(request.headers, "key", "value"), method: request.method, params: arrayToObject(request.queryParams, "key", "value"), timerList: prepareRequestManualTimers(request.timerList, timers), url: request.requestUrl }, prepareResponsePreview(request.responsePreview)));
const prepareJMXTimers = (requestTimerList, timers) => (requestTimerList || []).reduce((res, timerId) => (timers[timerId] ? [...res, Object.assign({}, timers[timerId])] : res), []);
const prepareThreadGroupElements = (threadGroupElements, requests, timers) => threadGroupElements.map((groupElement) => {
    const request = getRequestById(requests, groupElement.id) || { timerList: [] };
    return groupElement.groupElementType === ThreadGroupController.Sampler
        ? Object.assign(Object.assign({}, groupElement), { timerList: prepareJMXTimers(request.timerList, timers) }) : Object.assign(Object.assign({}, groupElement), { children: prepareThreadGroupElements(groupElement.children, requests, timers) });
});
const getThreadGroupElementsByGroupId = (threadGroups, groupId, requests, timers) => threadGroups && threadGroups[groupId]
    ? Object.assign(Object.assign({}, threadGroups[groupId]), { threadGroupElements: prepareThreadGroupElements(threadGroups[groupId].threadGroupElements, requests, timers) }) : {};
const getThreadGroupSteps = (JMXProfile) => JMXProfile && !isEmptyArray(JMXProfile.steps)
    ? {
        steps: JMXProfile.steps.map((step) => (Object.assign({ name: step.stepName ? step.stepName : step.name }, getStepParams(step))))
    }
    : {};
const generateJMXTestProfileRequestGroup = (group, requests, JMXProfile, sourceJmxThreadGroups, timers) => {
    const { enabled = true, id } = group || {};
    return Object.assign(Object.assign(Object.assign(Object.assign({ displayTypeName: group.displayTypeName, id: isNaN(Number(id)) ? id : Number(id) }, getThreadGroupElementsByGroupId(sourceJmxThreadGroups, id, requests, timers)), getThreadGroupSteps(JMXProfile)), generateTestProfileRequestGroupRunnerResources(group.runnerResources)), { enabled, type: group.type });
};
const generateTestProjectContentJMX = (testProjectData) => {
    const { baseJmxFileLocation, groups, isRunThreadGroupsConsecutively, JMXProfiles, sourceJmxThreadGroups, requests, timers, JMXTimersSettings } = testProjectData || {};
    return Object.assign(Object.assign(Object.assign({}, generateTestProjectContentCommonParams(testProjectData)), generateTestProfileRequestGroupResourceLocations(testProjectData.runnerLocations)), { baseJmxFileLocation,
        isRunThreadGroupsConsecutively, isTimersDisabled: getValueByPath(JMXTimersSettings, "isTimersDisabled", false), multiplicationFactor: getValueByPath(JMXTimersSettings, "multiplicationFactor", 1), testType: TestType.JMX, threadGroups: (groups || []).map((group) => generateJMXTestProfileRequestGroup(group, requests, JMXProfiles ? JMXProfiles[group.id] : undefined, sourceJmxThreadGroups, timers)) });
};
const generateTestProfileRequestGroupRunnerResources = (runnerResources) => runnerResources
    ? {
        resourceConfiguration: {
            testRunnerIds: !isEmptyArray(runnerResources) ? [...runnerResources] : []
        }
    }
    : {};
const generateThreadGroupController = (threadGroupElement, elementsData) => {
    const _a = elementsData[threadGroupElement.id], { groupId, id, name } = _a, restParams = __rest(_a, ["groupId", "id", "name"]);
    return {
        type: ThreadGroupController.Controller,
        id,
        typeDisplayName: "Transaction Controller",
        label: name,
        individualProperties: {
            type: ControllerType.Transaction,
            includeDurationOfAllElementsToGeneratedSampler: false
        },
        children: threadGroupElement.children.map((threadGroupChildrenElement) => generateThreadGroupElementByType({ threadGroupElement: threadGroupChildrenElement, elementsData })),
        timerList: []
    };
};
const generateThreadGroupSampler = (_a) => {
    var { id, name } = _a, restParams = __rest(_a, ["id", "name"]);
    const _b = generateTestProfileRequest(restParams), { extractors, timerList } = _b, individualPropertiesData = __rest(_b, ["extractors", "timerList"]);
    return {
        id,
        individualProperties: Object.assign(Object.assign({}, individualPropertiesData), { type: SamplerType.Http }),
        extractors,
        label: name,
        timerList,
        type: ThreadGroupController.Sampler,
        typeDisplayName: "HTTP Request"
    };
};
const generateThreadGroupElementByType = ({ threadGroupElement, elementsData }) => threadGroupElement.type === ThreadGroupController.Controller
    ? generateThreadGroupController(threadGroupElement, elementsData)
    : generateThreadGroupSampler(elementsData[threadGroupElement.id]);
const generateHttpRequestDefaults = (httpRequestDefaults) => isEmpty(httpRequestDefaults)
    ? {}
    : {
        httpRequestDefaults: Object.assign(Object.assign({}, httpRequestDefaults), { connectTimeout: TIMEOUT_TIME_COEFFICIENT * httpRequestDefaults.connectTimeout, responseTimeout: TIMEOUT_TIME_COEFFICIENT * httpRequestDefaults.responseTimeout })
    };
const generateTestProjectContentCommonParams = (testProjectData) => (Object.assign(Object.assign({}, generateHttpRequestDefaults(testProjectData.httpRequestDefaults)), { parameters: generateTestProfileParameters(testProjectData.requestParameters), slaGroupList: generateTestProjectSlaParams(testProjectData.sla) }));
export const generateTestProjectJMX = (testProjectData) => (Object.assign(Object.assign({}, generateTestProjectCommonParams(testProjectData)), { projectName: testProjectData.projectName, content: convertObjToYaml(generateTestProjectContentJMX(testProjectData)), settingsId: testProjectData.settingsId, totalDuration: testProjectData.JMXTestDurationSec }));
