var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { useIntl } from "@boomq/utils";
import { CONDITION_SELECT_PLACEHOLDER, TYPE_SELECT_PLACEHOLDER, VALUE_INPUT_PLACEHOLDER } from "./intl";
import { RESPONSE_PREVIEW_TITLE_MESSAGE, RESPONSE_PREVIEW_MESSAGE, RESPONSE_BODY_PREVIEW_MODE_LABEL_MESSAGE } from "../../../../components/account/TestProject/RequestResponsePreview";
import { Assertions } from "../../../../components/account/TestProject/Assertions";
import { withResize } from "../../../../components/styled/response";
import { getFormattedAssertionTypeItems, getNewAssertionObj } from "../../../../helpers/assertion";
import { formatIntlMessage } from "../../../../helpers/intl";
import { ASSERTION_NEW_ITEM_ID_PREFIX, getAssertionId } from "../../../../models/assertion";
import { responsePreviewSourceItems } from "../../../../models/extractFromResponse";
import { newRequestChangeAssertionField, newRequestChangeAssertionsResponsePreviewSource, newRequestChangeParamsField, newRequestDeleteFieldParam } from "../../../../redux/actions/newRequest";
import { getNewRequestAssertions, getNewRequestIsShowResponseInAssertions, getNewRequestResponsePreviewData, getNewRequestResponsePreviewSourceInAssertions } from "../../../../redux/reducers/newRequest";
const getAssertions = (state) => {
    const assertions = getNewRequestAssertions(state);
    return assertions.some((assertion) => getAssertionId(assertion).includes(ASSERTION_NEW_ITEM_ID_PREFIX))
        ? assertions
        : [...assertions, getNewAssertionObj()];
};
const connector = connect((state) => ({
    assertions: getAssertions(state),
    isShowResponse: getNewRequestIsShowResponseInAssertions(state),
    responsePreviewData: getNewRequestResponsePreviewData(state),
    responsePreviewSource: getNewRequestResponsePreviewSourceInAssertions(state)
}), {
    onChangeField: newRequestChangeAssertionField,
    onChangeRequestFieldParam: newRequestChangeParamsField,
    onChangeRequestResponseSource: newRequestChangeAssertionsResponsePreviewSource,
    onDelete: newRequestDeleteFieldParam
});
const AssertionsContainer = (_a) => {
    var { assertionTypeItems } = _a, restProps = __rest(_a, ["assertionTypeItems"]);
    const { formatMessage } = useIntl();
    const formattedAssertionTypeItems = getFormattedAssertionTypeItems(assertionTypeItems, formatMessage);
    const conditionSelectPlaceholder = formatIntlMessage(CONDITION_SELECT_PLACEHOLDER, formatMessage);
    const typeSelectPlaceholder = formatIntlMessage(TYPE_SELECT_PLACEHOLDER, formatMessage);
    const valuePlaceholder = formatIntlMessage(VALUE_INPUT_PLACEHOLDER, formatMessage);
    const changeResponsePreviewSourceHandler = ({ value }) => restProps.onChangeRequestResponseSource(value);
    const formattedResponsePreviewSourceItems = responsePreviewSourceItems.map((responsePreviewSourceItem) => (Object.assign(Object.assign({}, responsePreviewSourceItem), { text: formatIntlMessage(responsePreviewSourceItem.text, formatMessage) })));
    const responsePreviewText = formatIntlMessage(RESPONSE_PREVIEW_MESSAGE, formatMessage);
    const responsePreviewTitle = formatIntlMessage(RESPONSE_PREVIEW_TITLE_MESSAGE, formatMessage);
    const responseBodyPreviewModeLabel = formatIntlMessage(RESPONSE_BODY_PREVIEW_MODE_LABEL_MESSAGE, formatMessage);
    const toggleResponseShowingHandler = () => restProps.onChangeRequestFieldParam({ field: "isShowResponseInAssertions", value: !restProps.isShowResponse });
    return (React.createElement(Assertions, Object.assign({}, restProps, { assertionTypeItems: formattedAssertionTypeItems, conditionSelectPlaceholder: conditionSelectPlaceholder, onChangeResponsePreviewSource: changeResponsePreviewSourceHandler, onToggleResponseShowing: toggleResponseShowingHandler, responsePreviewSourceItems: formattedResponsePreviewSourceItems, responseBodyPreviewModeLabel: responseBodyPreviewModeLabel, responsePreviewText: responsePreviewText, responsePreviewTitle: responsePreviewTitle, typeSelectPlaceholder: typeSelectPlaceholder, valuePlaceholder: valuePlaceholder })));
};
export default withRouter(connector(withResize(AssertionsContainer)));
