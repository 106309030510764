import { instance } from "../services/authSrv";
export const getUsersV1 = (page, size = 6) => instance.get(`/api/v1/admin/user?page=${page}&size=${size}`);
export const getUserSites = (id) => instance.get(`/api/v1/admin/user/${id}/site`);
export const changeUserTariff = (id, tariff) => instance.put(`/api/v1/admin/user/${id}/tariff/${tariff}`);
export const changeUserState = (id, state) => instance.put(`/api/v1/admin/user/${id}/state/${state}`);
export const changeUserRole = (id, role) => instance.put(`/api/v1/admin/user/${id}/role/${role}`);
export const deleteUser = (id) => instance.delete(`/api/v1/admin/user/${id}`);
export const getRoles = () => instance.get("/api/v1/admin/role");
export const getTariffs = (page) => instance.get(`/api/v1/admin/tariff?size=10&page=${page}`);
export const changeTariff = (id, params) => instance.put(`/api/v1/admin/tariff/${id}`, params);
