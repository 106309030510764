import React, { useState, useEffect } from "react";
import styled, { css, keyframes } from "styled-components";
const hide = keyframes `
    100% {
        opacity: 0;
        visibility: hidden;
    }
`;
const TIME_COEFFICIENT = 1000;
const Hide = (props) => {
    let timeOutFunc;
    const [isShow, setIsShow] = useState(true);
    const getTimeOut = (timeout) => timeout ? TIME_COEFFICIENT * Number(timeout) : 0;
    useEffect(() => {
        timeOutFunc = window.setTimeout(() => {
            setIsShow(false);
            typeof props.afterHidingFunc === "function" && props.afterHidingFunc();
        }, getTimeOut(props.timeout));
    }, []);
    useEffect(() => {
        return () => { window.clearTimeout(timeOutFunc); };
    }, []);
    return (React.createElement(HideEffect, Object.assign({}, props), isShow && props.children));
};
const HideEffect = styled.div `
    ${(props) => (props && props.theme) ? css(props.theme) : ""}
`;
export default Hide;
