import React from "react";
import { getValueByPath, isEmpty, useIntl } from "@boomq/utils";
import { InputNumber, InputText, Select } from "@boomq/uikit";
import { MATCH_INPUT_LABEL, VARIABLE_INPUT_LABEL } from "./intl";
import { MatchTypeSelect, MatchValueSection } from "./styled";
import { getExpressionValue, getExtractorMatchByMatchType, validateExtractorMatchValue } from "../../../../helpers/extractResponse";
import { formatIntlMessage } from "../../../../helpers/intl";
import { ExtractorMatchType, FormType } from "../../../../helpers/types";
const getErrorTextByValue = (expression) => {
    const variableError = getValueByPath(expression, "error.variable");
    const { isValid, error } = isEmpty(variableError) && isEmpty(expression.variable)
        ? { isValid: true, error: undefined }
        : { isValid: false, error: variableError };
    return isValid ? undefined : error;
};
const ExtractorCommonTemplate = ({ children, expression, extractTypes, match, matchItems = [], mode, onChange }) => {
    const { formatMessage } = useIntl();
    const formattedExtractTypes = extractTypes.map((extractType) => (Object.assign(Object.assign({}, extractType), { placeholder: Object.keys(extractType.placeholder).reduce((res, placeholderKey) => (Object.assign(Object.assign({}, res), { [placeholderKey]: formatIntlMessage(extractType.placeholder[placeholderKey], formatMessage) })), {}), text: formatIntlMessage(extractType.text, formatMessage) })));
    const changeExtractExpressionHandler = ({ field, subfield, value }) => getExpressionValue(expression, field, subfield) !== value && typeof onChange === "function"
        ? onChange({
            id: expression.id,
            field,
            subfield,
            value
        })
        : undefined;
    const changeExtractTypeHandler = (item) => changeExtractExpressionHandler({
        field: "type",
        value: item.value
    });
    const changeMatchTypeHandler = (item) => changeExtractExpressionHandler({
        field: "match",
        value: getExtractorMatchByMatchType(Object.assign(Object.assign({}, expression.match), { matchType: item.value }))
    });
    const changeMatchValueHandler = (value) => changeExtractExpressionHandler({
        field: "match",
        subfield: "value",
        value
    });
    const changeVariableHandler = (value) => changeExtractExpressionHandler({
        field: "variable",
        value
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(Select, { defaultValue: expression.type, disabled: mode === FormType.View, items: formattedExtractTypes, onChange: changeExtractTypeHandler }),
        React.createElement(InputText, { enabled: mode !== FormType.View, error: getErrorTextByValue(expression), name: "Variable", onBlur: changeVariableHandler, placeholder: formatMessage(VARIABLE_INPUT_LABEL), value: expression.variable }),
        children,
        React.createElement(MatchValueSection, null,
            React.createElement(MatchTypeSelect, { defaultValue: match.matchType, disabled: mode === FormType.View, items: matchItems, onChange: changeMatchTypeHandler }),
            match.matchType === ExtractorMatchType.Specific && (React.createElement(InputNumber, { decimalCount: -1, disabled: mode === FormType.View, enabled: true, name: "match", onBlur: changeMatchValueHandler, onBlurFormatFunc: validateExtractorMatchValue, placeholder: formatMessage(MATCH_INPUT_LABEL), value: Number(match.value) })))));
};
export default ExtractorCommonTemplate;
