import styled from "styled-components";
import { Color, IconDropDownArrowDown, IconDropDownArrowRight } from "@boomq/uikit";
const togglerIconStyle = `
    cursor: pointer;
    fill: ${Color.grey65};
    min-width: 24px;
`;
export const ExpandedTransactionIcon = styled(IconDropDownArrowDown) `
    ${togglerIconStyle}
`;
export const CollapsedTransactionIcon = styled(IconDropDownArrowRight) `
    ${togglerIconStyle}
`;
export const TogglerLine = styled.div `
    background: ${Color.lightGrey95};
    height: calc(100% - 72px);
    left: 24px;
    position: absolute;
    top: 48px;
    width: 1px;
`;
