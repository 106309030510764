import { getFromLocalStorage, setToLocalStorage } from "@boomq/utils";
import { SELECTED_TEAM_LOCAL_STORAGE_PARAM_NAME } from "./constants";
const getSelectedTeamDataFromLocalStorage = () => {
    try {
        const selectedTeamData = getFromLocalStorage(SELECTED_TEAM_LOCAL_STORAGE_PARAM_NAME);
        return selectedTeamData ? JSON.parse(selectedTeamData) : {};
    }
    catch (e) {
        return {};
    }
};
export const addTeamIdToLocalStorageSelectedTeamData = (userId, teamId) => {
    try {
        const modifiedSelectedTeamData = Object.assign(Object.assign({}, getSelectedTeamDataFromLocalStorage()), { [userId]: teamId });
        setToLocalStorage(SELECTED_TEAM_LOCAL_STORAGE_PARAM_NAME, JSON.stringify(modifiedSelectedTeamData));
    }
    catch (e) {
        console.error(e);
    }
};
export const getSelectedTeamIdFromLocalStorageByUserId = (userId) => getSelectedTeamDataFromLocalStorage()[userId];
