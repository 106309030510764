export const messages = [
    {
        isLong: true,
        text: { id: "account.all.header.project", defaultMessage: "Test" }
    },
    {
        text: { id: "account.all.header.label", defaultMessage: "Label" }
    },
    {
        text: { id: "account.all.header.user", defaultMessage: "User" }
    },
    {
        text: { id: "account.all.header.date", defaultMessage: "Start date and time" }
    },
    {
        text: { id: "account.all.header.comment", defaultMessage: "Comment" }
    },
    {
        text: { id: "title.empty", defaultMessage: " " }
    }
];
