import styled from "styled-components";
import { Color, FontBodyMediumMedium, FontBodySmallMedium, Help, StyledInputWrapper } from "@boomq/uikit";
import { TypeSliderInput } from "../TypeSliderInput";
import { withDisable, withShadow } from "../../styled/global";
import { media } from "../../styled/response";
export const Button = styled.div `
    background: ${Color.white};
    cursor: pointer;
    border: none;
    border-radius: 8px;
    ${withShadow}
    display: flex;
    margin: 0 4px;
    outline: none;
    padding: 2px 4px;
    transition: transform 0.1s ease-in-out;
    &:active {
        transform: scale(0.9);
    }
`;
export const ItemWrapper = styled.div `
    align-items: flex-start;
    display: flex;
    margin-top: 36px;
    user-select: none;
    &:first-child {
        margin-top: 0;
    }
    .rc-slider {
        padding: 4px 0;
    }
    .rc-slider-rail {
        background-color: ${Color.lightGrey95};
        border-radius: 8px;
        height: 6px;
        top: 50%;
        transform: translateY(-50%);
    }
    .rc-slider-track {
        background: ${Color.blue};
        border-radius: 8px;
        height: 6px;
    }
    .rc-slider-handle {
        background-color: ${Color.blue};
        border: none;
        height: 16px;
        margin-left: 0px;
        width: 16px;
    }
    .rc-slider-dot {
        display: none;
    }
    .rc-slider-mark {
        span {
            &:first-child {
                left: 0 !important;
            }
            &:last-child {
                right: 0;
                left: auto !important;
            }
        }
    }
    .rc-slider-mark-text {
        ${FontBodySmallMedium}
        color: ${Color.grey65};
        line-height: normal;
        margin: 0 !important;
        transform: none !important;
        width: min-content !important;
    }
    ${media.tablet} {
        align-items: flex-start;
        flex-direction: column;
    }
`;
export const SliderInputPanel = styled.div `
    align-items: center;
    display: flex;
    margin-bottom: 8px;
    width: 100%;
    ${StyledInputWrapper} {
        margin-left: 8px;
        margin-right: 8px;
    }
`;
export const SliderWrapper = styled.div `
    align-items: flex-center;
    display: flex;
    flex-direction: column;
    padding: 0 40px;
    position: relative;
    width: 100%;
`;
export const StyledHelp = styled(Help) `
    margin-left: 8px;
`;
export const StyledTypeSliderInput = styled(TypeSliderInput) `
    border-color: ${Color.lightGrey85}
    text-align: center;
`;
export const TextBlock = styled.div `
    ${FontBodyMediumMedium}
    align-items: center;
    color: ${Color.darkGrey5};
    display: flex;
    flex: 1;
    min-width: 250px;
    > button {
        margin-left: 3px;
    }
    ${media.tablet} {
        padding-left: 20px;
    }
`;
export const Wrapper = styled.div `
    ${withDisable}
    align-items: center;
    display: flex;
    flex: 3;
    width: 100%;
    ${media.tablet} {
        margin-top: 5px;
    }
`;
