export const APPLY_BUTTON_LABEL = {
    defaultMessage: "Apply",
    id: "account.report.editor.chart.serie.params.apply.button.label"
};
export const CHART_AGGREGATION_INTERVAL_SELECT_LABEL = {
    defaultMessage: "Aggregation interval",
    id: "account.report.editor.chart.serie.params.aggregation.interval.select.label"
};
export const CHART_AGGREGATION_INTERVAL_SELECT_PLACEHOLDER = {
    defaultMessage: "select aggregation interval",
    id: "account.report.editor.chart.serie.params.aggregation.interval.select.placeholder"
};
export const CHART_AXIS_SELECT_LABEL = {
    defaultMessage: "Axis",
    id: "account.report.editor.chart.serie.params.axis.select.label"
};
export const CHART_AXIS_SELECT_PLACEHOLDER = {
    defaultMessage: "select axis",
    id: "account.report.editor.chart.serie.params.axis.select.placeholder"
};
export const CHART_METRIC_TYPE_SELECT_LABEL = {
    defaultMessage: "Metric type",
    id: "account.report.editor.chart.serie.params.metric.type.select.label"
};
export const CHART_METRIC_TYPE_SELECT_PLACEHOLDER = {
    defaultMessage: "select metric type",
    id: "account.report.editor.chart.serie.params.metric.type.select.placeholder"
};
export const CHART_TYPE_SELECT_LABEL = {
    defaultMessage: "Chart type",
    id: "account.report.editor.chart.serie.params.chart.type.select.label"
};
export const CHART_TYPE_SELECT_PLACEHOLDER = {
    defaultMessage: "select chart type",
    id: "account.report.editor.chart.serie.params.chart.type.select.placeholder"
};
export const TEST_ID_SELECT_LABEL = {
    defaultMessage: "Test id",
    id: "account.report.editor.chart.serie.params.test.id.select.label"
};
export const TEST_ID_SELECT_PLACEHOLDER = {
    defaultMessage: "select test id",
    id: "account.report.editor.chart.serie.params.test.id.select.placeholder"
};
