import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { ACCOUNT_NAVBAR_ITEM_TEAMS_LABEL } from "./intl";
import { MobileHeaderText, StyledMobileIconPeople, StyledTeamInfo, TeamLink } from "./styled";
import { getSelectedTeamData } from "../../../redux/reducers/authorizePlate";
const connector = connect((state) => ({
    teamInfo: getSelectedTeamData(state)
}));
const TeamInfoMobile = ({ path, teamInfo }) => {
    const { id } = teamInfo || {};
    return id > 0 ? (React.createElement(StyledTeamInfo, { path: path, teamInfo: teamInfo })) : (React.createElement(TeamLink, { to: path },
        React.createElement(StyledMobileIconPeople, null),
        React.createElement(MobileHeaderText, null,
            React.createElement(FormattedMessage, Object.assign({}, ACCOUNT_NAVBAR_ITEM_TEAMS_LABEL)))));
};
export default withRouter(connector(TeamInfoMobile));
