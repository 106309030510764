var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { isEmpty, useIntl } from "@boomq/utils";
import { Button, ButtonSize, InfiniteScroll, Select } from "@boomq/uikit";
import { SETTINGS_SETS_SIDEBAR_ACTIONS_LINK, SETTINGS_SETS_SIDEBAR_ACTIONS_UNLINK, SETTINGS_SETS_SIDEBAR_FILTER_NAME, SETTINGS_SETS_SIDEBAR_TITLE } from "./intl";
import { ButtonBlock, customFilterLabelTheme, getCustomInfiniteScrollTheme, FiltersBlock, Separator, SidebarContent, StyledIconClose, StyledInputText, StyledNewSettingsSetInSidebar, Title } from "./styled";
import { TABLE_FILTER_LABEL_PLACEHOLDER } from "../AllTestProjectSettingsSets/intl";
import { SettingsSetSidebarItem } from "../SettingsSetSidebarItem";
import { isAllowedSettingsSetInSidebarActionByPermissions } from "../../../helpers/settingsSetSidebar";
import { SelectionType, SettingsSetInSidebarAction } from "../../../helpers/types";
const renderButtonBlock = (props, selectedSettingsSetId) => {
    const clickLinkHandler = () => selectedSettingsSetId && typeof props[SettingsSetInSidebarAction.LinkProject] === "function"
        ? props[SettingsSetInSidebarAction.LinkProject](selectedSettingsSetId)
        : undefined;
    const clickUnlinkHandler = () => selectedSettingsSetId && typeof props[SettingsSetInSidebarAction.UnlinkProject] === "function"
        ? props[SettingsSetInSidebarAction.UnlinkProject]()
        : undefined;
    return getStateSelectedSettingsSetId(props.linkedSettingsSetData) === selectedSettingsSetId &&
        selectedSettingsSetId > 0 ? (React.createElement(Button, { size: ButtonSize.big, onClick: clickUnlinkHandler },
        React.createElement(FormattedMessage, Object.assign({}, SETTINGS_SETS_SIDEBAR_ACTIONS_UNLINK)))) : (React.createElement(Button, { disabled: isEmpty(selectedSettingsSetId), size: ButtonSize.big, onClick: clickLinkHandler },
        React.createElement(FormattedMessage, Object.assign({}, SETTINGS_SETS_SIDEBAR_ACTIONS_LINK))));
};
const renderLinkedSettingsSet = (props, selectedSettingsSetId, selectSettingsSetHandler) => isEmpty(props.linkedSettingsSetData) ? (React.createElement(React.Fragment, null)) : (React.createElement(SettingsSetSidebarItem, Object.assign({}, props.linkedSettingsSetData, { selectedSettingsSetId: selectedSettingsSetId, onClick: selectSettingsSetHandler })));
const getStateSelectedSettingsSetId = (linkedSettingsSetData) => linkedSettingsSetData && linkedSettingsSetData.id ? linkedSettingsSetData.id : undefined;
export const SettingsSetSidebarContent = (_a) => {
    var { children } = _a, restProps = __rest(_a, ["children"]);
    const { formatMessage } = useIntl();
    const [isShowSettingsSetAdding, setIsShowSettingsSetAdding] = useState(false);
    const [selectedSettingsSetId, setSelectedSettingsSetId] = useState(getStateSelectedSettingsSetId(restProps.linkedSettingsSetData));
    useEffect(() => {
        setSelectedSettingsSetId(getStateSelectedSettingsSetId(restProps.linkedSettingsSetData));
    }, [restProps.linkedSettingsSetData]);
    const cancelNewSettingsSetHandler = () => setIsShowSettingsSetAdding(false);
    const changeLabelsFilterHandler = (data) => restProps.changeSidebarFilterLabels(data);
    const changeSettingsNameFilterHandler = (value) => restProps.changeSidebarFilterName(value);
    const clickHideHandler = () => restProps.hideSettingsSetSidebar();
    const newSettingsSetAddingHandler = (name) => {
        setIsShowSettingsSetAdding(false);
        return typeof restProps[SettingsSetInSidebarAction.Add] === "function"
            ? restProps[SettingsSetInSidebarAction.Add](name)
            : undefined;
    };
    const selectSettingsSetHandler = (id) => setSelectedSettingsSetId(id);
    const settingsSetNamePlaceholder = String(formatMessage(SETTINGS_SETS_SIDEBAR_FILTER_NAME));
    return (React.createElement(React.Fragment, null,
        React.createElement(SidebarContent, null,
            React.createElement(Title, null,
                React.createElement(FormattedMessage, Object.assign({}, SETTINGS_SETS_SIDEBAR_TITLE, { values: { projectName: restProps.testProjectName } }))),
            React.createElement(StyledIconClose, { onClick: clickHideHandler }),
            React.isValidElement(children) &&
                React.cloneElement(children, Object.assign(Object.assign({}, restProps), { onSetIsShowSettingsSetAdding: setIsShowSettingsSetAdding })),
            React.createElement(FiltersBlock, null,
                React.createElement(StyledInputText, { name: "Settings set name", onBlur: changeSettingsNameFilterHandler, placeholder: settingsSetNamePlaceholder, value: restProps.filterName }),
                React.createElement(Select, { intlFormatFunc: formatMessage, items: restProps.testProjectSettingsSetsLabelList, onBlur: changeLabelsFilterHandler, placeholder: TABLE_FILTER_LABEL_PLACEHOLDER, selectionType: SelectionType.Multiple, theme: customFilterLabelTheme, textAttrName: "name", titleAttrName: "name", valueAttrName: "name", value: restProps.filterLabels })),
            renderLinkedSettingsSet(restProps, selectedSettingsSetId, selectSettingsSetHandler),
            React.createElement(Separator, null),
            React.createElement(StyledNewSettingsSetInSidebar, { isShow: isShowSettingsSetAdding, onAdd: newSettingsSetAddingHandler, onCancel: cancelNewSettingsSetHandler, settingsSetNamePlaceholder: settingsSetNamePlaceholder }),
            React.createElement(InfiniteScroll, { currentPage: restProps.currentPage, itemHeight: 45, onLoadItems: restProps.onLoadItems, size: restProps.size, theme: getCustomInfiniteScrollTheme(restProps.linkedSettingsSetData), totalPages: restProps.totalPages },
                React.createElement(React.Fragment, null, restProps.settingsSetSidebarItems.map((settingsSet) => (React.createElement(SettingsSetSidebarItem, Object.assign({}, settingsSet, { key: settingsSet.id, selectedSettingsSetId: selectedSettingsSetId, onClick: selectSettingsSetHandler }))))))),
        isAllowedSettingsSetInSidebarActionByPermissions(restProps.permissionList, SettingsSetInSidebarAction.Add) && React.createElement(ButtonBlock, null, renderButtonBlock(restProps, selectedSettingsSetId))));
};
