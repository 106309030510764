import React from "react";
import {
    CommentSelection,
    PreviewJMXChart,
    PreviewThreadGroupsChart,
    slaFormModal,
    ProfileDistribution,
    TestLabelsSelection,
    TestProjectSlaFormContainer,
    TestSettingsActionsPanel,
    TestSettingsBlock
} from "@boomq/common";
import { SlaButton } from "../SlaButton";
import TypeSelection from "../../TypeSelection";
import { StartSelection } from "../../../../containers/account/TestProject/StartSelection";
import { TestType } from "../../../../helpers/types";

const isJMXTest = (testType) => testType === TestType.JMX;

const renderBlockByProfileDistribution = (props) =>
    props.profileDistribution === ProfileDistribution.Users ? (
        <PreviewThreadGroupsChart {...props} />
    ) : (
        <TypeSelection {...props} />
    );

const renderBlockByTestType = (props) =>
    isJMXTest(props.testType) ? <PreviewJMXChart {...props} /> : renderBlockByProfileDistribution(props);

export const TestSettings = (props) => {
    const saveSlaHandler = (slaData) => {
        props.testProjectChangeParamsField({ field: "sla", value: slaData });
        props.closeSiteModal();
    };

    const showSlaFormHandler = () =>
        props.getSiteModal(
            slaFormModal({
                element: TestProjectSlaFormContainer,
                onSave: saveSlaHandler,
                testType: props.testType
            })
        );

    return (
        <>
            <TestSettingsBlock>
                {renderBlockByTestType(props)}

                <CommentSelection />

                <TestLabelsSelection />
            </TestSettingsBlock>

            <TestSettingsActionsPanel>
                <SlaButton {...props} onShowSlaForm={showSlaFormHandler} />

                <StartSelection error={props.loadProfileError} permissionList={props.permissionList} />
            </TestSettingsActionsPanel>
        </>
    );
};
