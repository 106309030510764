import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import {
    ACCOUNT_WRAPPER_CLASS_NAME,
    AccountPage as CommonAccountPage,
    AccountWrapper,
    Header,
    HeaderMobile,
    ModalWindow,
    RouteByPermissions,
    TeamInfo,
    UserInfo,
    WebSiteModalWindow,
    getProfileRoute,
    getTeamsRoute,
    sizes
} from "@boomq/common";
import { StyledLanguagePlate } from "./styled";
import { accountCommonRoutes, accountRoutesByPermissions } from "../../../helpers/routes";

const AccountPage = (props) => {
    const { docsLink, isAuthorized, logo, logoMobile, modalVisibility, windowSize } = props || {};

    return (
        <CommonAccountPage>
            {windowSize && windowSize.width > sizes.tablet ? (
                <Header docsLink={docsLink} logo={logo}>
                    <StyledLanguagePlate />

                    <TeamInfo path={getTeamsRoute()} />

                    <UserInfo path={getProfileRoute()} />
                </Header>
            ) : (
                <HeaderMobile isAuthorized={isAuthorized} logo={logoMobile} />
            )}

            <AccountWrapper className={ACCOUNT_WRAPPER_CLASS_NAME} modal={modalVisibility}>
                <Switch>
                    {accountRoutesByPermissions.map(({ exact, path, component }) => (
                        <RouteByPermissions key={path} exact={exact} path={`/account/${path}`} component={component} />
                    ))}

                    {accountCommonRoutes.map(({ exact, path, component }) => (
                        <Route key={path} exact={exact} path={`/account/${path}`} component={component} />
                    ))}
                </Switch>

                <ModalWindow />
                <WebSiteModalWindow />
            </AccountWrapper>
        </CommonAccountPage>
    );
};

export default AccountPage;
