import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
    getIsAuthorize,
    getTestProjectSettingsField,
    hideTestProjectNotification,
    newTestActions
} from "@boomq/common";
import { StartSelection } from "../../../../components/account/TestProject/StartSelection";

const { runTestRequest, saveTestProjectRequest, testProjectChangeParamsField } = newTestActions;

const connector = connect(
    (state) => ({
        isAuthorized: getIsAuthorize(state),
        isDebugMode: getTestProjectSettingsField(state, "isDebugMode")
    }),
    {
        hideTestProjectNotification,
        onRunTest: runTestRequest,
        onSave: saveTestProjectRequest,
        testProjectChangeParamsField
    }
);

export default withRouter(connector(StartSelection));
