import React from "react";
import { customNewSLASelectTheme, DeleteRowButton, FixedTable, SlaTypeListItem, TableFixedCellSlaName, TableFixedCellSlaNameContent, TableHeaderFixedCell } from "./styled";
import { DeleteButton, HeaderCell, TableHeadRow, TestNameId } from "../SlaReportTable/styled";
import { TableCellSlaResult } from "../TableCellSlaResult";
import { HintComment } from "../HintComment";
import { CustomSelect } from "../../../common/CustomSelect";
import { SelectedItemBody } from "../SlaReport/styled";
import { getColorByName } from "../../../../helpers/color/colorByName";
import { IconClose } from "../../../styled/Icons/Close";
import { getFullDateLocaleTimeZone } from "../../../../helpers/date/localeTimeZone";
import { getSlaUnitTextByRestrictionType } from "../../../../helpers/sla/slaUnit";
import { generateSlaTypeIdFromData } from "../../../../helpers/slaReport/slaTypeIdFromData";
import { generateSlaTypeItemFormattedTextWithTimeline } from "../../../../helpers/slaReport/slaTypeItemFormattedTextWithTimeline";
import { SlaReportTableValueType } from "../../../../helpers/types";
const isBaselineTest = (baselineTest, valueType) => baselineTest && valueType && valueType.value === SlaReportTableValueType.BaselineDiff;
const SlaReportTableFixedCells = ({ baselineTestId, forwardedRef, intl, onAddSla, onDeleteSla, onDeleteTest, slaTypeList, tableData, tableTestList, testDuration }) => {
    const { diffType, rowList, valueType } = tableData || {};
    const baselineTest = tableTestList.find((test) => test.id === baselineTestId);
    const formatMessage = intl && typeof intl.formatMessage === "function" ? intl.formatMessage : () => undefined;
    const deleteTableSlaHandler = (rowId) => onDeleteSla(rowId);
    const deleteTableTestHandler = (testId) => onDeleteTest(testId);
    const selectNewSlaHandler = (data) => onAddSla(data);
    const getSlaBody = () => {
        const bodyText = formatMessage({
            defaultMessage: "add SLA",
            id: "account.sla.report.profile.table.select.sla.placeholder"
        });
        return React.createElement(SelectedItemBody, { title: bodyText }, bodyText);
    };
    const getSlaItem = (slaItem) => {
        const slaItemText = generateSlaTypeItemFormattedTextWithTimeline(slaItem, testDuration, formatMessage);
        return React.createElement(SlaTypeListItem, { title: slaItemText }, slaItemText);
    };
    const getSlaTypeItemFormattedUnit = (slaType) => formatMessage(getSlaUnitTextByRestrictionType(slaType.type, slaType.restrictionType, "text"));
    return (React.createElement(FixedTable, { ref: forwardedRef },
        React.createElement("thead", null,
            React.createElement(TableHeadRow, null,
                React.createElement(TableHeaderFixedCell, null, onAddSla && (slaTypeList || []).length > 0 ? (React.createElement(CustomSelect, { isAutoPosition: true, items: slaTypeList, bodyTemplate: getSlaBody, listItemTemplate: getSlaItem, maxItemCount: 10, name: "SlaReportTableNewSLA", onChange: selectNewSlaHandler, position: { offsetTop: 60 }, theme: customNewSLASelectTheme })) : (React.createElement("span", null, formatMessage({
                    defaultMessage: "SLA",
                    id: "account.sla.report.profile.table.sla.name.title"
                })))),
                isBaselineTest(baselineTest, valueType) && (React.createElement(HeaderCell, { key: baselineTest.id, isBaselineTest: true },
                    React.createElement(TestNameId, null,
                        onDeleteTest && (React.createElement(DeleteButton, { onClick: () => deleteTableTestHandler(baselineTest.id) },
                            React.createElement(IconClose, { bg: getColorByName("white"), width: "14" }))),
                        baselineTest.id,
                        React.createElement(HintComment, { comment: baselineTest.comment })),
                    React.createElement("div", null, getFullDateLocaleTimeZone(baselineTest.startDate)))))),
        React.createElement("tbody", null, Array.isArray(rowList) &&
            rowList.map((row) => {
                const slaType = row.slaType;
                const slaTypeId = generateSlaTypeIdFromData(slaType);
                const slaTypeUnit = getSlaTypeItemFormattedUnit(slaType);
                return (React.createElement("tr", { id: `fixedTable_${slaTypeId}`, key: slaTypeId },
                    React.createElement(TableFixedCellSlaName, null,
                        React.createElement(TableFixedCellSlaNameContent, null,
                            onDeleteSla && (React.createElement(DeleteRowButton, { onClick: () => deleteTableSlaHandler(slaTypeId) }, "\u00D7")),
                            generateSlaTypeItemFormattedTextWithTimeline(slaType, testDuration, formatMessage))),
                    isBaselineTest(baselineTest, valueType) ? ((row.cellList || []).map((cell) => cell.testId === baselineTestId && (React.createElement(TableCellSlaResult, { key: `cell_${slaTypeId}_${cell.testId}`, baselineTestId: baselineTestId, diffType: diffType, cell: cell, isBaselineTest: true, intl: intl, slaType: slaType, testDuration: testDuration, unit: slaTypeUnit, valueType: valueType })))) : (React.createElement(React.Fragment, null))));
            }))));
};
export default SlaReportTableFixedCells;
