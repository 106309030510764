var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { FormattedMessage } from "react-intl";
import { isEmpty } from "@boomq/utils";
import { DROP_DOWN_LIST_TITLE } from "./intl";
import { InputSection, customRequestParametersDropDownTheme, StyledInputText } from "./styled";
import { DropDownList } from "../../common/DropDownList";
const formatParamNameAsValue = (name) => "${" + name + "}";
export const InputWithParameters = (_a) => {
    var { disabled, error, name, onBlurFormatFunc, onChange, onChangeParamAsValue, label = "", placeholder = "", parameters, paramName, value } = _a, restProps = __rest(_a, ["disabled", "error", "name", "onBlurFormatFunc", "onChange", "onChangeParamAsValue", "label", "placeholder", "parameters", "paramName", "value"]);
    const getValueByParamName = (paramName) => { var _a; return String((_a = value === null || value === void 0 ? void 0 : value[paramName]) !== null && _a !== void 0 ? _a : ""); };
    const changeHandler = (newValue, e) => getValueByParamName(paramName) !== newValue ? onChange === null || onChange === void 0 ? void 0 : onChange(paramName, newValue) : undefined;
    const changeParamAsValueHandler = (paramName, name, value) => typeof onChangeParamAsValue === "function"
        ? onChangeParamAsValue(paramName, name, value)
        : changeHandler(formatParamNameAsValue(name));
    const getErrorTextByKey = (errorText) => typeof errorText === "object" && errorText.id ? React.createElement(FormattedMessage, Object.assign({}, errorText)) : errorText;
    const getErrorByKey = (key) => (error && error[key] ? getErrorTextByKey(error[key]) : undefined);
    return (React.createElement(InputSection, Object.assign({}, restProps),
        React.createElement(StyledInputText, { enabled: !disabled, error: getErrorByKey(paramName), isExistParameters: !isEmpty(parameters), label: label, name: `${name}_${paramName}`, onBlur: changeHandler, onBlurFormatFunc: onBlurFormatFunc, placeholder: placeholder, title: getValueByParamName(paramName), value: getValueByParamName(paramName) }),
        !isEmpty(parameters) && (React.createElement(DropDownList, { disabled: disabled, onChange: changeParamAsValueHandler, items: parameters, paramName: paramName, theme: customRequestParametersDropDownTheme, title: DROP_DOWN_LIST_TITLE }, "P"))));
};
