import React, { useEffect } from "react";
import { generateImageUrlByContentInBase64, isEmpty } from "@boomq/utils";
import { InputText } from "@boomq/uikit";
import { TEAM_TITLE_INTL_MSG } from "./intl";
import { TeamImageSection, TeamInfo, TeamNameSection, TeamNameSubtitle, TeamNameTitle } from "./styled";
import { TeamImageBlock } from "../TeamImageBlock";
import { getSiteTitle } from "../../../../helpers/title";
const getTeamImage = (props) => !isEmpty(props.teamImage) && typeof props.teamImage === "string" ? generateImageUrlByContentInBase64(props.teamImage) : "";
export const TeamInfoBlock = (props) => {
    useEffect(() => {
        props.setTitle(TEAM_TITLE_INTL_MSG);
        document.title = getSiteTitle(props.pageTitle);
    }, []);
    useEffect(() => {
        return () => (typeof props.onUnmount === "function" ? props.onUnmount() : undefined);
    }, []);
    return (React.createElement(TeamInfo, null,
        React.createElement(TeamNameSection, null,
            React.createElement(TeamNameTitle, null, props.teamNameTitle),
            React.createElement(TeamNameSubtitle, null, props.teamNameSubtitle),
            React.createElement(InputText, { enabled: props.isExistAdminAccess, error: props.teamNameError, onChange: props.onChangeName, placeholder: props.teamNameInputLabel, value: props.teamName })),
        React.createElement(TeamImageSection, null,
            React.createElement(TeamImageBlock, { image: getTeamImage(props), isExistAdminAccess: props.isExistAdminAccess, onDeleteImage: props.onDeleteImage, onUploadImage: props.onUploadImage, teamImageButtonCancelLabel: props.teamImageButtonCancelLabel, teamImageEmptySelectText: props.teamImageEmptySelectText, teamImageEmptyText: props.teamImageEmptyText, teamImageTitle: props.teamImageTitle }))));
};
