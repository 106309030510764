import styled from "styled-components";
export const customButtonTheme = { background: "#BAD4F3" };
export const customHintButtonTheme = {
    alignItems: "center",
    display: "flex",
    height: "100%",
    justifyContent: "center",
    position: "absolute",
    right: 0,
    top: "0!important",
    width: "100%",
    svg: {
        width: "42px",
        height: "30px"
    }
};
export const slaReportStyle = { width: "100%" };
export const ButtonBlock = styled.div `
    align-items: flex-end;
    display: flex;
    flex: auto;
    justify-content: ${({ align }) => (align ? align : "flex-end")};
    > a {
        color: #396496;
        cursor: pointer;
    }
`;
export const SlaReportProfileWrapper = styled.div `
    display: flex;
    justify-content: center;
`;
