import { handleActions } from "redux-actions";
import { authorizeActions, isEmpty } from "@boomq/common";
import { runnerResourcesSuccess } from "../actions/runnerResources";

const { logout } = authorizeActions;

export const initialState = {
    content: [],
    currentPage: 0,
    totalElements: 0,
    totalPages: 0
};

const getValues = ({ content, currentPage, totalElements, totalPages }) =>
    isEmpty(content)
        ? initialState
        : {
              content,
              currentPage,
              totalElements,
              totalPages
          };

export default handleActions(
    {
        [logout]: () => ({ ...initialState }),
        [runnerResourcesSuccess]: (state, action) => ({ ...state, ...getValues(action.payload || {}) })
    },
    initialState
);

const getStateParam = (state, paramName) =>
    state && state.runnerResources ? state.runnerResources[paramName] : initialState[paramName];

export const getRunnerResources = (state) => getStateParam(state, "content");
