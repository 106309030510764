import styled from "styled-components";
import { Color, FontBodyMediumMedium, FontBodyMediumRegular } from "@boomq/uikit";
import { withDisable } from "../../styled/global";
export const Comment = styled.div `
    ${withDisable}
    display: flex;
    margin: 24px 24px 0;
    position: relative;
    width: 50%;
`;
export const CommentArea = styled.textarea `
    ${FontBodyMediumRegular}
    border: 1px solid ${Color.lightGrey85};
    box-sizing: border-box;
    border-radius: 12px;
    color: ${Color.grey75};
    resize: none;
    outline: none;
    min-height: 100px;
    padding: 16px 8px 16px 17px;
    width: 100%;
`;
export const CommentLength = styled.div `
    ${FontBodyMediumRegular}
    bottom: 16px;
    color: ${Color.grey75};
    position: absolute;
    right: 8px;
`;
export const CommentSubtitle = styled.p `
    ${FontBodyMediumMedium}
    color: ${Color.darkGrey35};
    margin-top: 8px;
`;
export const CommentTitle = styled.div `
    border-bottom: 1px solid ${Color.lightGrey95};
    color: ${Color.darkGrey5};
    display: flex;
    flex-direction: column;
    padding: 40px 24px 20px;
`;
