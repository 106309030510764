var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { arrayToObject } from "@boomq/utils";
import { getThreadGroupElementControllerType } from "../threadGroup";
import { TestType } from "../types";
import { ControllerType } from "../../models/threadGroupEditor";
const prepareTransactionListFromStableScalability = ({ groupMap, elementsData }) => Object.keys(elementsData)
    .filter((elementId) => getThreadGroupElementControllerType(elementsData[elementId]) === ControllerType.Transaction)
    .map((elementId) => {
    var _a, _b, _c;
    return ({
        id: elementId,
        transactionName: (_a = elementsData === null || elementsData === void 0 ? void 0 : elementsData[elementId]) === null || _a === void 0 ? void 0 : _a.name,
        threadGroupName: groupMap[(_b = elementsData[elementId]) === null || _b === void 0 ? void 0 : _b.groupId],
        text: (_c = elementsData === null || elementsData === void 0 ? void 0 : elementsData[elementId]) === null || _c === void 0 ? void 0 : _c.name
    });
});
const prepareTransactionListFromJMX = ({ groupMap, transactions }) => (transactions || []).map(({ id, groupId, name }) => ({
    id,
    transactionName: name,
    threadGroupName: groupMap[groupId],
    text: name
}));
const transactionListGetterFuncByTestType = {
    [TestType.JMX]: prepareTransactionListFromJMX,
    [TestType.Scalability]: prepareTransactionListFromStableScalability,
    [TestType.Stable]: prepareTransactionListFromStableScalability
};
export const prepareTransactionListByTestType = (_a) => {
    var _b;
    var { groups, testType } = _a, restData = __rest(_a, ["groups", "testType"]);
    const groupMap = arrayToObject(groups, "id", "name");
    return Object.keys(transactionListGetterFuncByTestType).includes(testType)
        ? (_b = transactionListGetterFuncByTestType[testType]) === null || _b === void 0 ? void 0 : _b.call(transactionListGetterFuncByTestType, Object.assign({ groupMap }, restData))
        : [];
};
