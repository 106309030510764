import { Color, FontBodyMediumBold, FontBodyMediumMedium } from "@boomq/uikit";
import styled from "styled-components";
export const CookiesTable = styled.table `
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    width: 100%;
`;
export const CookiesTableHeader = styled.thead `
    margin-bottom: 16px;
    width: 100%;
`;
export const CookiesTableHeaderRow = styled.tr `
    display: flex;
    background: ${Color.lightGrey95};
    border-radius: 12px;
    width: 100%;
`;
export const CookiesTableHeaderCol = styled.td `
    ${FontBodyMediumMedium}
    color: ${Color.darkGrey5};
    min-width: 12%;
    padding: 8px;
    width: 12%;
`;
export const CookiesTableHeaderColValue = styled(CookiesTableHeaderCol) `
    width: 16%;
`;
export const CookiesTableBody = styled.tbody `
    max-height: 150px;
    overflow-y: auto;
`;
export const CookiesTableBodyCol = styled.td `
    ${FontBodyMediumBold}
    color: ${Color.grey50};
    min-width: 12%;
    padding: 0 8px;
    vertical-align: top;
    width: 12%;
    word-break: break-all;
`;
export const CookiesTableBodyColValue = styled(CookiesTableBodyCol) `
    color: ${Color.orange};
    width: 16%;
`;
