import styled from "styled-components";
import { Block } from "@boomq/uikit";
import { media } from "../response";
import { WizardBlock } from "../../account/Wizard";
export const ACCOUNT_WRAPPER_CLASS_NAME = "account-wrapper";
export const WRAPPER_PADDING_LEFT_RIGHT = 32;
export const AccountWrapper = styled.section `
    display: flex;
    position: relative;
    flex: 1 0 auto;
    height: calc(100% - 60px);
    width: 100%;
    overflow-x: hidden;
    overflow-y: ${(props) => (props.modal ? "hidden" : "auto")};
    > div:first-child {
        width: 100%;
    }
    @media print {
        height: auto;
    }
`;
export const AdminWrapper = styled(AccountWrapper) `
    height: calc(100% - 65px);
`;
export const Padding = styled.div `
    padding: 0 ${WRAPPER_PADDING_LEFT_RIGHT}px;
    ${media.tablet} {
        padding: 0 12px;
    }
`;
export const TestProjectContentWrapper = styled(Padding) `
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
    width: 100%;
    ${WizardBlock} {
        margin-top: 28px;
    }
`;
export const TestProjectPageWrapper = styled.div `
    display: flex;
`;
export const WebsiteWrapper = styled.div `
    max-width: 1140px;
    margin: 0 auto;
    ${media.giant} {
        max-width: 100%;
        margin: 0 40px;
    }
    ${media.tablet} {
        margin: 0 20px;
    }
`;
export const MainPageSectionWrapper = styled(WebsiteWrapper) `
    display: flex;
`;
export const PanelWrapper = styled.div `
    ${Block}
    display: flex;
    flex-direction: column;
    padding: 18px 16px 12px;
    position: relative;
`;
