export const CHART_SERIE_PARAMS_HEADER = {
    defaultMessage: "Chart",
    id: "account.report.editor.chart.serie.params.header"
};
export const CHART_SETTINGS_HEADER = {
    defaultMessage: "Chart Settings",
    id: "account.report.editor.chart.settings.header"
};
export const NEW_CHART_SERIE_BUTTON_LABEL = {
    defaultMessage: "Add new measure",
    id: "account.report.editor.chart.new.serie.button.label"
};
