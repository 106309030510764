import React from "react";
import { customSwitcherTheme, RequestGroupName } from "./styled";
import { Switcher } from "../../../common/Switcher";
import { GroupName } from "../../../styled/TestProject";
const DEFAULT_REQUEST_GROUP = {
    enabled: true,
    name: ""
};
export const RequestGroupJMXCaptionEditMode = ({ children, onToggle, requestGroup = DEFAULT_REQUEST_GROUP }) => {
    const toggleIsDisabledHandler = (event) => event && event.target && typeof onToggle === "function" ? onToggle(!event.target.checked) : false;
    return (React.createElement(GroupName, null,
        React.createElement(Switcher, { defaultChecked: requestGroup.enabled, onClick: toggleIsDisabledHandler, theme: customSwitcherTheme }),
        React.createElement(RequestGroupName, null, requestGroup.name),
        children));
};
