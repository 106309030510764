import { isEmpty, getUniqueId, getValueByPath } from "@boomq/utils";
import { getTimerRandomType, getTimerType } from "./requestTimerParams";
import { formatIntlMessage } from "../intl";
import { FormType, RequestRandomTimerType, RequestTimerParams, RequestTimerType } from "../types";
import { requestTimerValidator } from "../validators/timers";
import { timerValueParamsByType, timerTypeItemsByTypeParam, timerTypeParamsByType } from "../../models/requestTimer";
export const getEnabledTimerList = (timerList) => Array.isArray(timerList) ? timerList.filter(({ enabled }) => enabled) : [];
export const getTimerById = (timerList, id) => {
    const timer = timerList.find((timer) => timer.id === id);
    return timer ? timer : {};
};
export const getTimerFromListByType = (timerList = [], timerType) => Array.isArray(timerList) ? timerList.find((timer) => getTimerType(timer) === timerType) : undefined;
export const getTimerValueParamsByType = (type) => {
    const valueParams = timerValueParamsByType[type];
    return Array.isArray(valueParams) ? valueParams : [];
};
export const getTimerValueParams = (timer) => getTimerValueParamsByType(getTimerType(timer));
export const getTimerTypeParamsByType = (type) => {
    const typeParams = timerTypeParamsByType[type];
    return Array.isArray(typeParams) ? typeParams : [];
};
export const getTimerTypeItemsByParamName = (paramName) => {
    const typeItems = timerTypeItemsByTypeParam[paramName];
    return Array.isArray(typeItems) ? typeItems : [];
};
export const getFormattedTimerTypeItemsByParamName = (paramName, intlFormatFunc) => {
    const typeItems = getTimerTypeItemsByParamName(paramName);
    return typeItems.map((typeItem) => (Object.assign(Object.assign({}, typeItem), { placeholder: Object.keys(typeItem.placeholder).reduce((res, placeholderKey) => (Object.assign(Object.assign({}, res), { [placeholderKey]: formatIntlMessage(typeItem.placeholder[placeholderKey], intlFormatFunc) })), {}), text: formatIntlMessage(typeItem.text, intlFormatFunc) })));
};
const getTimerTypeDefaultParams = (type) => isEmpty(type) ? {} : { [RequestTimerParams.Type]: type };
const getTimerRandomTypeValuesByType = (randomTimerType) => ({
    [RequestTimerParams.Type]: RequestTimerType.Random,
    randomTimerType: randomTimerType || RequestRandomTimerType.Uniform
});
export const getTimerTypeValuesByType = (type, randomTimerType) => {
    switch (type) {
        case RequestTimerType.Random: {
            return getTimerRandomTypeValuesByType(randomTimerType);
        }
        default: {
            return getTimerTypeDefaultParams(type);
        }
    }
};
export const getTimerValueByType = (timer, defaultValue) => getTimerValueParamsByType(getTimerType(timer)).reduce((res, timerValueParamName) => (Object.assign(Object.assign({}, res), { [timerValueParamName]: getValueByPath(timer, timerValueParamName, defaultValue) })), {});
export const getTimerInitValueByType = (type) => getTimerValueByType({ [RequestTimerParams.Type]: type }, 0);
const isEmptyRandomTimerUpperLimit = (value) => isEmpty(value) || value === 0;
const prepareRandomTimerUpperLimit = (timer, key, value) => key === RequestTimerParams.RandomValueParamMin &&
    isEmptyRandomTimerUpperLimit(timer[RequestTimerParams.RandomValueParamMax])
    ? { [RequestTimerParams.RandomValueParamMax]: value }
    : {};
const validateTimer = (timer) => {
    const { isValid, error } = requestTimerValidator(timer);
    isValid && delete timer.error;
    return isValid ? timer : Object.assign(Object.assign({}, timer), { error });
};
const modifyRandomTimerValue = ({ timer, key, value }) => validateTimer(Object.assign(Object.assign(Object.assign({}, timer), prepareRandomTimerUpperLimit(timer, key, value)), { [key]: value }));
const modifyTimerByValue = ({ timer, key, value }) => getTimerType(timer) === RequestTimerType.Random
    ? modifyRandomTimerValue({ timer, key, value })
    : Object.assign(Object.assign({}, timer), { [key]: value });
export const prepareTimerByType = (timerId, type, randomTimerType) => (Object.assign(Object.assign({ id: timerId, enabled: true, isBoomqTimer: true }, getTimerTypeValuesByType(type, randomTimerType)), getTimerInitValueByType(type)));
export const modifyTimerListByType = ({ timerList, timerId, key, randomTimerType, timerType }) => (timerList || []).map((timer) => timer.id === timerId ? prepareTimerByType(timerId, timerType, randomTimerType) : timer);
export const modifyTimerListByValue = ({ timerList, timerId, key, value }) => (timerList || []).map((timer) => (timer.id === timerId ? modifyTimerByValue({ timer, key, value }) : timer));
export const getTimerInputUnit = () => ({
    text: {
        defaultMessage: "sec",
        id: "account.request.form.timer.unit.sec.text"
    },
    title: {
        defaultMessage: "seconds",
        id: "account.request.form.timer.unit.sec.title"
    }
});
const getDefaultTimerBySettings = (timerSettings) => isEmptyTimerParams(timerSettings)
    ? []
    : [
        Object.assign(Object.assign(Object.assign(Object.assign({ enabled: true }, timerSettings), { id: getUniqueId(), isDefaultTimer: true }), getTimerTypeValuesByType(getTimerType(timerSettings), getTimerRandomType(timerSettings))), getTimerValueByType(timerSettings, 0))
    ];
export const getTimerListByMode = ({ timerList = [], newTimer, mode }) => {
    const resTimerList = timerList || [];
    return mode === FormType.View
        ? resTimerList
        : [
            ...resTimerList,
            Object.assign(Object.assign(Object.assign({ id: getUniqueId(), enabled: true, isBoomqTimer: true, isNewTimer: true }, newTimer), { [RequestTimerParams.Type]: getTimerType(newTimer) }), getTimerInitValueByType(getTimerType(newTimer)))
        ];
};
export const getTimerListWithDefaultTimer = (timerList, defaultTimerSettings) => [
    ...getDefaultTimerBySettings(defaultTimerSettings),
    ...timerList
];
export const isDefaultTimer = (timer) => (timer ? timer.isDefaultTimer : false);
export const isEmptyTimerParams = (timer = {}) => isEmpty(timer) || isEmpty(getTimerType(timer)) || isEmpty(getTimerValueByType(timer));
export const isEnabled = (timer, mode) => mode === FormType.View || isDefaultTimer(timer) ? false : true;
export const isExistEnabledTimer = (timerList) => {
    const enabledTimer = timerList.find((timer) => timer.enabled);
    return !isEmpty(enabledTimer) ? true : false;
};
export const isShowRemoveButton = (timer, mode, isTimersDisabled) => !timer.isNewTimer && !timer.isDefaultTimer && mode !== FormType.View && !isTimersDisabled;
