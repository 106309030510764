import React from "react";
import { useIntl } from "@boomq/utils";
import { ACTIONS_LABEL } from "./intl";
import { iconMenuListTheme, StyledDetailsButtons, StyledIconMenu } from "./styled";
import { ConfirmForm } from "../../../common/ConfirmForm";
import { DropDownList } from "../../../common/DropDownList";
import { isAllowedTestProjectActionByPermissions } from "../../../../helpers/testProject";
import { TestProjectAction } from "../../../../helpers/types";
import { deleteRequestGroupsAttentionModal, isConfirmedModalForm } from "../../../../data/content/modals";
export const ProfileSettingsButtons = (props) => {
    const { formatMessage } = useIntl();
    const confirmDelete = (e) => (isConfirmedModalForm(e) ? props.deleteGroups() : undefined);
    const deleteGroups = () => props.getSiteModal(deleteRequestGroupsAttentionModal({
        element: ConfirmForm,
        closeAction: confirmDelete
    }));
    const requestGroupsActionsFunc = Object.assign(Object.assign({}, props), { deleteGroups });
    const clickActionButtonHandler = (paramName, name, value) => typeof requestGroupsActionsFunc[paramName] === "function" && requestGroupsActionsFunc[paramName](value);
    return isAllowedTestProjectActionByPermissions(props.permissionList, TestProjectAction.Edit) ? (React.createElement(StyledDetailsButtons, null, Array.isArray(props.actionButtons) && props.actionButtons.length > 0 && (React.createElement(DropDownList, { listTheme: iconMenuListTheme, items: props.actionButtons, onChange: clickActionButtonHandler, paramNameKeyName: "actionName", nameKeyName: "text", valueKeyName: "actionPayload" },
        formatMessage(ACTIONS_LABEL),
        React.createElement(StyledIconMenu, null))))) : (React.createElement(React.Fragment, null));
};
