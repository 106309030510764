import styled, { css } from "styled-components";
export const Check = styled.img `
    fill: #4989d6;
    margin-right: 5px;
`;
export const TextSection = styled.div `
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 0 8px rgba(23, 128, 224, 0.25);
    cursor: pointer;
    display: flex;
    padding: 7px 10px;
    ${(props) => (props && props.theme ? css(props.theme) : "")}
`;
