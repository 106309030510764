var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useIntl } from "@boomq/utils";
import { BUTTON_CREATE_LABEL, BUTTON_SAVE_LABEL, TEAM_IMAGE_ADDING_TITLE, TEAM_IMAGE_BUTTON_CANCEL_LABEL, TEAM_IMAGE_EDITING_TITLE, TEAM_IMAGE_EMPTY_TEXT, TEAM_MEMBERS_TITLE, TEAM_NAME_INPUT_LABEL, TEAM_NAME_SUBTITLE, TEAM_NAME_TITLE, TEAM_PAGE_TITLE } from "./intl";
import { TeamAdminAccessTemplate } from "../../../../components/account/Team/TeamAdminAccessTemplate";
import { AccessDenied } from "../../../../components/common/AccessDenied";
import { NEW_ITEM_ID, TEAM_ID_PARAM_NAME } from "../../../../helpers/routes";
import { teamNameValidator } from "../../../../helpers/validators";
import { getTeamImage, getTeamName } from "../../../../redux/reducers/team";
import { setTitle } from "../../../../redux/actions/accountTitle";
import { clearTeamData } from "../../../../redux/actions/team";
import { changeTeamMembersPage } from "../../../../redux/actions/teamMembers";
const connector = connect((state) => ({
    image: getTeamImage(state),
    name: getTeamName(state)
}), {
    onChangeTeamMembersPage: changeTeamMembersPage,
    onUnmount: clearTeamData,
    setTitle
});
const TeamAdminAccessTemplateContainer = (_a) => {
    var { children, image, intl, name, onSave, teamId, teamMembers } = _a, restProps = __rest(_a, ["children", "image", "intl", "name", "onSave", "teamId", "teamMembers"]);
    const { formatMessage } = useIntl();
    const [teamImage, setTeamImage] = useState(image);
    const [teamName, setTeamName] = useState(name);
    const [teamNameErrorIntlMessage, setTeamNameErrorIntlMessage] = useState(null);
    useEffect(() => {
        setTeamImage(image);
    }, [image]);
    useEffect(() => {
        setTeamName(name);
    }, [name]);
    const changeTeamNameHandler = (name) => {
        const { error } = teamNameValidator(name);
        setTeamName(name);
        setTeamNameErrorIntlMessage(error);
    };
    const deleteImageHandler = () => setTeamImage(null);
    const saveTeamHandler = () => {
        const { error, isValid } = teamNameValidator(teamName);
        return isValid ? onSave({ id: teamId, name: teamName, image: teamImage }) : setTeamNameErrorIntlMessage(error);
    };
    const uploadImageHandler = (imageFile) => setTeamImage(imageFile);
    const teamImageTitleIntlMessage = restProps.image ? TEAM_IMAGE_EDITING_TITLE : TEAM_IMAGE_ADDING_TITLE;
    const buttonSaveLabel = teamId === NEW_ITEM_ID ? formatMessage(BUTTON_CREATE_LABEL) : formatMessage(BUTTON_SAVE_LABEL);
    const pageTitle = formatMessage(TEAM_PAGE_TITLE);
    const teamImageButtonCancelLabel = formatMessage(TEAM_IMAGE_BUTTON_CANCEL_LABEL);
    const teamImageEmptyText = formatMessage(TEAM_IMAGE_EMPTY_TEXT);
    const teamImageTitle = formatMessage(teamImageTitleIntlMessage);
    const teamMembersTitle = formatMessage(TEAM_MEMBERS_TITLE, { teamMembersCount: restProps.teamMembersCount });
    const teamNameError = teamNameErrorIntlMessage ? formatMessage(teamNameErrorIntlMessage) : null;
    const teamNameInputLabel = formatMessage(TEAM_NAME_INPUT_LABEL);
    const teamNameSubtitle = formatMessage(TEAM_NAME_SUBTITLE);
    const teamNameTitle = formatMessage(TEAM_NAME_TITLE);
    return restProps.isExistAdminAccess ? (React.createElement(TeamAdminAccessTemplate, Object.assign({}, restProps, { buttonSaveLabel: buttonSaveLabel, onChangeName: changeTeamNameHandler, onDeleteImage: deleteImageHandler, onSave: saveTeamHandler, onUploadImage: uploadImageHandler, pageTitle: pageTitle, teamImage: teamImage, teamImageButtonCancelLabel: teamImageButtonCancelLabel, teamImageEmptyText: teamImageEmptyText, teamImageTitle: teamImageTitle, teamMembersTitle: teamMembersTitle, teamName: teamName, teamNameError: teamNameError, teamNameInputLabel: teamNameInputLabel, teamNameSubtitle: teamNameSubtitle, teamNameTitle: teamNameTitle }), children)) : (React.createElement(AccessDenied, null));
};
export default connector(TeamAdminAccessTemplateContainer);
