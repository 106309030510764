import { getOperationTextByValue } from "../sla/operationTextByValue";
import { SlaStatus } from "../../models/sla/enums";
export const getTooltipLabel = ({ status, slaName, slaOperation, slaTarget, unit, value }) => status === SlaStatus.NotCounted
    ? {
        defaultMessage: "{slaName}, no value",
        id: "account.sla.report.profile.chart.tooltip.no.value",
        values: {
            slaName
        }
    }
    : {
        defaultMessage: "{slaDesc}, Value: {slaValue}",
        id: "account.sla.report.profile.chart.tooltip.value",
        values: {
            slaDesc: `${slaName} ${getOperationTextByValue(slaOperation)} ${slaTarget} ${unit}`,
            slaValue: `${value} ${unit}`
        }
    };
