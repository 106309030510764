import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { HEADER_EXIT } from "./intl";
import { LogoutText, LogoutWrapper, StyledIconLogout } from "./styled";
import authorizeActions from "../../../redux/actions/authorize";
const { logoutWithCheckingOfUnsavedTestProject } = authorizeActions;
const connector = connect(null, { onClick: logoutWithCheckingOfUnsavedTestProject });
const Logout = (props) => {
    const clickHandler = () => props.onClick();
    return (React.createElement(LogoutWrapper, { onClick: clickHandler },
        React.createElement(StyledIconLogout, { alt: "exit-icon" }),
        React.createElement(LogoutText, null,
            React.createElement(FormattedMessage, Object.assign({}, HEADER_EXIT)))));
};
Logout.defaultProps = {
    mobile: false
};
export default withRouter(connector(Logout));
