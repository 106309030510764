export const ADD_SLA_SELECT_BODY_PLACEHOLDER = {
    defaultMessage: "select SLA",
    id: "account.sla.report.profile.chart.select.sla.placeholder"
};
export const COPY_EMBED_CODE_BUTTON_LABEL = {
    defaultMessage: "Copy embed code",
    id: "account.sla.report.profile.item.button.copy.embed.code.title"
};
export const SLA_SELECT_PLACEHOLDER = {
    defaultMessage: "select SLA",
    id: "account.sla.report.profile.chart.select.sla.placeholder"
};
