import styled from "styled-components";
import { Color, FontBodyMediumMedium } from "@boomq/uikit";

export const TeamMembersItemInputsBlockStyles = `
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
`;

export const ManageUsersBlock = styled.div`
    display: flex;
`;

export const ManageUsersPermissions = styled.div`
    display: flex;
`;

export const ManageUsersTitle = styled.div`
    ${FontBodyMediumMedium}
    color: ${Color.darkGrey35};
    margin: 16px 0;
`;

export const TeamMembersItemInputsBlock = styled.div`
    ${TeamMembersItemInputsBlockStyles}
    width: 100%;
`;
