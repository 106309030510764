export const uploadFileLocally = (file, resolveCallbackFunc, rejectCallbackFunc) => {
    try {
        const reader = new FileReader();
        reader.onload = (event) => { var _a; return resolveCallbackFunc === null || resolveCallbackFunc === void 0 ? void 0 : resolveCallbackFunc((_a = event.target) === null || _a === void 0 ? void 0 : _a.result); };
        reader.readAsDataURL(file);
    }
    catch (e) {
        return rejectCallbackFunc === null || rejectCallbackFunc === void 0 ? void 0 : rejectCallbackFunc(e);
    }
};
