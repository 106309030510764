import styled from "styled-components";
export const DeleteButton = styled.div `
    color: #396496;
    cursor: pointer;
    font-size: 30px;
    margin-right: 2px;
    padding: 3px;
`;
export const HeaderCell = styled.td `
    background: ${({ isBaselineTest }) => (isBaselineTest ? "#4989d6" : "#e0e0e0")};
    border: 1px solid #000;
    color: ${({ isBaselineTest }) => (isBaselineTest ? "#fff" : "#000")};
    min-width: 240px;
    padding: 5px;
    text-align: center;
    width: auto;
`;
export const TableCell = styled.td `
    border: 1px solid #000;
    padding: 10px;
`;
export const TableHeadRow = styled.tr `
    height: 66px;
    max-height: 66px;
    min-height: 66px;
`;
export const TestNameId = styled.div `
    align-items: center;
    display: flex;
    justify-content: center;
`;
