var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { render } from "react-dom";
import { IconTable } from "@codexteam/icons";
import { getUniqueId } from "@boomq/utils";
import { ResizableTable } from "../ResizableTablePlugin";
import { PFLBTableWithIntl } from "../../../../containers/account/Report/PFLBTable";
import "./pflbTable.css";
export class PFLBTable extends ResizableTable {
    static get toolbox() {
        return {
            title: "PFLB table",
            icon: IconTable
        };
    }
    constructor(_a) {
        var { pflbTableId } = _a, restParams = __rest(_a, ["pflbTableId"]);
        const { data, config, api } = restParams;
        super(restParams);
        this._config = config;
        this._data = data;
        this._api = api;
        this.isShowParamsSidebar = false;
        this.pflbTableId = pflbTableId || getUniqueId();
    }
    removed() {
        return this._config && typeof this._config.onDeleteBlock === "function"
            ? this._config.onDeleteBlock(this.pflbTableId)
            : undefined;
    }
    render() {
        const table = this.generatePFLBTable();
        const rootNode = document.createElement("div");
        rootNode.classList.add("pflb-table");
        rootNode.id = this.getPFLBTableId();
        return this.renderPFLBTableInNode(rootNode, table);
    }
    initTableData(reportTable) {
        setTimeout(() => this.refreshTableData(reportTable));
    }
    renderPFLBTableInNode(node, table) {
        render(React.createElement(PFLBTableWithIntl, { id: this.pflbTableId, isShowParamsSidebar: this.isShowParamsSidebar, onInitData: (reportTable) => this.initTableData(reportTable), onRefresh: (reportTable) => this.refreshTableData(reportTable), onToggleTableParamsSidebar: () => this.toggleTableParamsSidebar() }), node);
        node.appendChild(table);
        return node;
    }
    generatePFLBTable() {
        const table = super.render();
        table.id = this.getTableId();
        return table;
    }
    getPFLBTableId() {
        return `pflb-table-${this.pflbTableId}`;
    }
    getTableId() {
        return `table-${this.pflbTableId}`;
    }
    refreshTableData(reportTableData) {
        var _a;
        super.data = reportTableData.tableData;
        this._data = reportTableData;
        const table = super.regenerateTableByData(reportTableData.tableData);
        const tableNode = this._api.ui.nodes.redactor.querySelector(`#${this.getTableId()}`);
        table.id = this.getTableId();
        return (_a = tableNode === null || tableNode === void 0 ? void 0 : tableNode.replaceWith) === null || _a === void 0 ? void 0 : _a.call(tableNode, table);
    }
    toggleTableParamsSidebar() {
        return this.isShowParamsSidebar ? this.hideTableParamsSidebar() : this.showTableParamsSidebar();
    }
    hideOtherBlocksParams() {
        const blocksCount = this.api.blocks.getBlocksCount();
        const currentBlockIndex = this.api.blocks.getCurrentBlockIndex();
        for (let i = 0; i < blocksCount; i++) {
            const block = this.api.blocks.getBlockByIndex(i);
            if (currentBlockIndex !== i) {
                block.call("hideChartParamsSidebar");
                block.call("hideTableParamsSidebar");
            }
        }
    }
    hideTableParamsSidebar() {
        const tableParamsSidebarDiv = this.getTableParamsSidebarDiv();
        this.isShowParamsSidebar = false;
        return tableParamsSidebarDiv ? tableParamsSidebarDiv.classList.remove("show") : undefined;
    }
    showTableParamsSidebar() {
        const tableParamsSidebarDiv = this.getTableParamsSidebarDiv();
        this.isShowParamsSidebar = true;
        this.hideOtherBlocksParams();
        return tableParamsSidebarDiv ? tableParamsSidebarDiv.classList.add("show") : undefined;
    }
    getTableParamsSidebarDiv() {
        return document.querySelector(`#${this.getPFLBTableId()} .pflb-sidebar-settings`);
    }
    renderSettings() {
        return [
            {
                label: this._api.i18n.t("Table params"),
                icon: IconTable,
                isActive: this.isShowParamsSidebar,
                closeOnActivate: true,
                toggle: true,
                onActivate: () => this.toggleTableParamsSidebar()
            },
            ...super.renderSettings()
        ];
    }
    save() {
        return Object.assign(Object.assign({}, this._data), { tableData: super.save() });
    }
}
