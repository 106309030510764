import { Link } from "react-router-dom";
import styled from "styled-components";
import { IconClose } from "../../../styled/Icons";
import { media } from "../../../styled/response";
import { TestListItem } from "../../TestItem/styled";
export const StoppingText = styled.p `
    font-size: 18px;
    color: #396496;
    margin-left: 15px;
`;
export const TestActionBlock = styled.div `
    align-items: center;
    display: flex;
    ${media.tablet} {
        align-items: flex-start;
        flex-direction: column;
    }
`;
export const RunningTestListItem = styled(TestListItem) `
    align-items: center;
    justify-content: space-between;
    padding: 14px 16px;
    ${IconClose} {
        &:before,
        &:after {
            width: 30px;
            height: 3px;
        }
    }
    ${media.tablet} {
        flex-direction: column;
        align-items: flex-start;
    }
`;
export const TestLink = styled(Link) `
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    font-size: 14px;
    text-decoration: underline;
    color: #b2b2b2;
    margin-top: 7px;
    &:hover {
        text-decoration: none;
    }
    ${media.tablet} {
        margin-top: 15px;
    }
`;
export const TestText = styled.div `
    align-items: flex-start;
    display: flex;
    flex-direction: column;
`;
export const TestTitle = styled.h4 `
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    font-size: 16px;
    color: #396496;
`;
