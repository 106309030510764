var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
export const IconArrow = (_a) => {
    var { fill } = _a, rest = __rest(_a, ["fill"]);
    return (React.createElement("svg", Object.assign({ width: "10", height: "11", viewBox: "0 0 10 11" }, rest),
        React.createElement("path", { d: "M4.52 10.821l.009.01c.017.012.03.026.047.035.005.004.009.004.013.009.018.009.035.022.052.031.004 0 .009.005.009.005.017.008.034.017.056.026.004 0 .004 0 .009.005.017.009.038.013.06.022.004 0 .009 0 .009.005.021.004.038.013.06.013.009 0 .013 0 .022.005.017.004.034.004.051.008a.402.402 0 00.074.005c.026 0 .047 0 .073-.005a.209.209 0 00.052-.008c.009 0 .013 0 .022-.005l.06-.013c.004 0 .009 0 .009-.005.021-.004.039-.013.06-.022.004 0 .004 0 .009-.005.017-.009.039-.018.056-.026.004 0 .009-.005.009-.005a.318.318 0 00.051-.031c.005-.005.009-.005.013-.01.018-.013.035-.022.048-.035l.008-.009c.018-.018.04-.031.057-.054l4.265-4.422c.29-.3.29-.787 0-1.086-.29-.3-.76-.3-1.05 0L5.73 8.37V.769C5.73.344 5.397 0 4.991 0a.756.756 0 00-.743.77v7.605l-2.983-3.09c-.29-.299-.76-.299-1.05 0A.78.78 0 000 5.828a.78.78 0 00.216.54l4.244 4.396c.026.022.043.04.06.058z", fill: fill })));
};
