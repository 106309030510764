import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";
import ImageTool from "@editorjs/image";
import NestedList from "@editorjs/nested-list";
import Marker from "@editorjs/marker";
import Underline from "@editorjs/underline";
import { HEADER_LEVELS } from "./constants";
import { uploadByFile } from "./uploadByFile";
import { ResizableTable } from "../../components/account/Report/ResizableTablePlugin";
import { TestDisplayStatus } from "../testRun/enums";
import { PFLBAnchor } from "../../components/account/Report/PFLBAnchorPlugin";
import { PFLBAnchorLink } from "../../components/account/Report/PFLBAnchorLinkPlugin";
import { PFLBTableOfContents } from "../../components/account/Report/PFLBTableOfContentsPlugin";
export const reportTestStates = [TestDisplayStatus.Canceled, TestDisplayStatus.Finished];
export const createEditorInstance = (holderId, data, extendedTools) => {
    const editorInstance = new EditorJS({
        autofocus: true,
        holder: holderId,
        tools: Object.assign({ anchor: { class: PFLBAnchor }, anchorLink: {
                class: PFLBAnchorLink
            }, header: {
                class: Header,
                config: {
                    placeholder: "Enter a header",
                    levels: HEADER_LEVELS,
                    defaultLevel: HEADER_LEVELS[0]
                },
                inlineToolbar: true,
                shortcut: "CMD+SHIFT+H"
            }, image: {
                class: ImageTool,
                config: {
                    uploader: { uploadByFile }
                }
            }, list: {
                class: NestedList,
                config: {
                    defaultStyle: "unordered"
                },
                inlineToolbar: true,
                shortcut: "CMD+SHIFT+L"
            }, underline: {
                class: Underline,
                shortcut: "CMD+SHIFT+U"
            }, marker: {
                class: Marker,
                shortcut: "CMD+SHIFT+M"
            }, table: {
                class: ResizableTable,
                config: {
                    rows: 2,
                    cols: 3
                },
                inlineToolbar: true,
                shortcut: "CMD+SHIFT+T"
            }, tableOfContents: {
                class: PFLBTableOfContents
            } }, (extendedTools !== null && extendedTools !== void 0 ? extendedTools : {})),
        tunes: ["anchorLink"],
        data
    });
    return editorInstance;
};
