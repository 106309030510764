import styled from "styled-components";
import { IconClose } from "../Icons";
import { Color } from "../styled/colorPallete";
export const DropDownItemList = styled.ul `
    background: ${Color.white};
    border: 1px solid ${Color.grey50};
    border-radius: 12px;
    display: flex;
    flex-wrap: wrap;
    margin-top: 4px;
    padding: 12px 12px 4px 12px;
    width: 260px;
    li {
        cursor: pointer;
        margin-bottom: 8px;
        margin-right: 4px;
    }
`;
export const StyledIconClose = styled(IconClose) `
    cursor: pointer;
    fill: ${Color.grey50};
    height: 20px;
    position: absolute;
    right: 8px;
    top: 8px;
    width: 20px;
`;
