import { getUniqueId } from "@boomq/utils";
import { ADMIN_PERMISSIONS, DEFAULT_TEAMS_ITEM_HEIGHT, DEFAULT_TEAMS_PAGE_SIZE, DEFAULT_TEAMS_PAGINATION_HEIGHT, DEFAULT_TEAM_MEMBER_TEMPLATE_KEY, DEFAULT_TEAM_MEMBERS_ITEM_HEIGHT, DEFAULT_TEAM_MEMBERS_PAGE_SIZE, DEFAULT_TEAM_MEMBERS_PAGINATION_HEIGHT, DEFAULT_TEAM_TEMPLATE_KEY, MANAGE_USERS_PERMISSIONS } from "./constants";
import { Role, TeamAccessMode, TeamMemberMode } from "../types";
import { formatEmail } from "../utils";
import { TeamMembersItemTemplateAdd } from "../../containers/account/Team/TeamMembersItemTemplateAdd";
import { TeamMembersItemTemplateEdit } from "../../containers/account/Team/TeamMembersItemTemplateEdit";
import { CommonTeamMembersItemTemplateView } from "../../components/account/Team/TeamMembersItemTemplateView";
import { TeamAdminAccessTemplateContainer } from "../../containers/account/Team/TeamAdminAccessTemplate";
import { TeamManageUsersAccessTemplateContainer } from "../../containers/account/Team/TeamManageUsersAccessTemplate";
const teamMemberTemplateByMode = {
    [TeamMemberMode.Add]: TeamMembersItemTemplateAdd,
    [TeamMemberMode.View]: CommonTeamMembersItemTemplateView,
    [TeamMemberMode.Edit]: TeamMembersItemTemplateEdit
};
const teamTemplateByAccessMode = {
    [TeamAccessMode.Admin]: TeamAdminAccessTemplateContainer,
    [TeamAccessMode.ManageUsers]: TeamManageUsersAccessTemplateContainer
};
const calculateTableRowsCount = ({ defaultPageSize, itemHeight, paginationHeight, tableEl }) => {
    const { height } = tableEl && typeof tableEl.getBoundingClientRect === "function"
        ? tableEl.getBoundingClientRect()
        : { height: 0 };
    return height > 0 ? Math.floor((height - paginationHeight) / itemHeight) : defaultPageSize;
};
export const calculateTeamItemsCount = (tableEl) => calculateTableRowsCount({
    defaultPageSize: DEFAULT_TEAMS_PAGE_SIZE,
    itemHeight: DEFAULT_TEAMS_ITEM_HEIGHT,
    paginationHeight: DEFAULT_TEAMS_PAGINATION_HEIGHT,
    tableEl
});
export const calculateTeamMemberItemsCount = (tableEl) => calculateTableRowsCount({
    defaultPageSize: DEFAULT_TEAM_MEMBERS_PAGE_SIZE,
    itemHeight: DEFAULT_TEAM_MEMBERS_ITEM_HEIGHT,
    paginationHeight: DEFAULT_TEAM_MEMBERS_PAGINATION_HEIGHT,
    tableEl
});
export const generateListItemByValue = (value) => ({
    id: String(value),
    text: String(value),
    value
});
export const generateListItemsByValues = (values) => (values || []).map(generateListItemByValue);
export const formatTeamMemberPermissions = (permissions) => (permissions || []).map((permission) => `${permission[0]}${permission.substring(1).toLowerCase()}`).join(", ");
export const formatDataToTeamMemberDto = (data = {}) => {
    const { email = "", permissionList = [], userDisplayName = "" } = data || {};
    return {
        email: formatEmail(email),
        permissionList,
        userDisplayName
    };
};
export const generateNewMemberData = () => ({
    id: getUniqueId(),
    email: "",
    permissionList: [],
    userDisplayName: ""
});
export const getTeamAdminPermissions = () => ADMIN_PERMISSIONS;
export const isExistTeamAdminPermission = (roles, permissions) => (roles || []).includes(Role.Admin) ||
    (permissions || []).some((permission) => getTeamAdminPermissions().includes(permission));
export const isExistTeamManageUsersPermission = (roles, permissions) => (roles || []).includes(Role.Admin) ||
    (permissions || []).some((permission) => MANAGE_USERS_PERMISSIONS.includes(permission));
export const getTeamMemberPermissionMessageByModeAndValue = (mode, permissionValue) => ({
    defaultMessage: String(permissionValue),
    id: `account.team.member.permission.${mode}.${permissionValue}`
});
export const getTeamMemberPermissionMessageByValue = (permissionValue) => ({
    defaultMessage: String(permissionValue),
    id: `account.team.member.permission.${permissionValue}`
});
const prepareTeamMemberList = (members) => members.map((member) => ({
    email: formatEmail(member.email),
    permissionList: member.permissionList,
    userDisplayName: member.userDisplayName
}));
export const generateTeamDtoParams = ({ id, image, members, name }) => ({
    team: {
        id,
        teamName: name
    },
    teamImage: image,
    teamMemberList: prepareTeamMemberList(members)
});
export const generateNewTeamDtoParams = (params) => (Object.assign(Object.assign({}, generateTeamDtoParams(params)), { team: {
        teamName: params.name
    } }));
export const getTeamMembersItemComponentKey = (mode) => Object.keys(teamMemberTemplateByMode).includes(mode) ? mode : DEFAULT_TEAM_MEMBER_TEMPLATE_KEY;
export const getTeamMembersItemTemplateByMode = (mode) => teamMemberTemplateByMode[getTeamMembersItemComponentKey(mode)];
const getTeamTemplateComponentKey = (mode) => mode && Object.keys(teamTemplateByAccessMode).includes(mode) ? mode : DEFAULT_TEAM_TEMPLATE_KEY;
export const getTeamTemplateByAccessMode = (mode) => teamTemplateByAccessMode[getTeamTemplateComponentKey(mode)];
const getTeamAccessModeByRolesAndPermissions = (roles, permissions) => {
    const accessMode = isExistTeamManageUsersPermission(roles, permissions) ? TeamAccessMode.ManageUsers : undefined;
    return isExistTeamAdminPermission(roles, permissions) ? TeamAccessMode.Admin : accessMode;
};
export const getTeamTemplateByRolesAndPermissions = (roles, permissions) => getTeamTemplateByAccessMode(getTeamAccessModeByRolesAndPermissions(roles, permissions));
