export var TestProjectStableScalabilityDefaultSettingsType;
(function (TestProjectStableScalabilityDefaultSettingsType) {
    TestProjectStableScalabilityDefaultSettingsType["Cookies"] = "COOKIES";
    TestProjectStableScalabilityDefaultSettingsType["Headers"] = "HEADERS";
    TestProjectStableScalabilityDefaultSettingsType["HttpRequestSettings"] = "HTTP_REQUEST_SETTINGS";
    TestProjectStableScalabilityDefaultSettingsType["Timer"] = "TIMER";
})(TestProjectStableScalabilityDefaultSettingsType || (TestProjectStableScalabilityDefaultSettingsType = {}));
export var TestProjectOwnJMXDefaultSettingsType;
(function (TestProjectOwnJMXDefaultSettingsType) {
    TestProjectOwnJMXDefaultSettingsType["Timers"] = "TIMERS";
})(TestProjectOwnJMXDefaultSettingsType || (TestProjectOwnJMXDefaultSettingsType = {}));
