import { getUniqueId } from "@boomq/utils";
import { SettingsSetWizardItem } from "../types";
export const parseUrlOverwriteItem = ({ findUrl, replaceWithUrl }) => ({
    id: getUniqueId(),
    updatedUrl: replaceWithUrl,
    urlToRewrite: findUrl
});
export const parseUrlOverwrite = (projectParameters) => (projectParameters || [])
    .filter((projectParameter) => projectParameter.type === SettingsSetWizardItem.UrlOverwrite)
    .map((urlOverwriteItem) => parseUrlOverwriteItem(urlOverwriteItem.value));
const prepareUrlOverwriteItem = ({ updatedUrl, urlToRewrite }) => ({
    findUrl: urlToRewrite,
    replaceWithUrl: updatedUrl
});
export const prepareUrlOverwrite = (items) => (items || []).map((item) => prepareUrlOverwriteItem(item));
