import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
    SlaReportProfile,
    getClientPermissionList,
    getIsAuthorize,
    getSlaReportOpenedSpoilerName,
    getSlaReportSlaTypeList,
    saveSlaReportProfileRequest,
    withResize
} from "@boomq/common";
import { SlaReportProfileAction } from "../../../../helpers/types";

const connector = connect(
    (state) => ({
        isAuthorized: getIsAuthorize(state),
        isSlaReportProfileEnabled: true,
        openedSpoilerName: getSlaReportOpenedSpoilerName(state),
        permissionList: getClientPermissionList(state),
        slaTypeList: getSlaReportSlaTypeList(state)
    }),
    { [SlaReportProfileAction.Save]: saveSlaReportProfileRequest }
);

export default withResize(withRouter(connector(SlaReportProfile)));
