import styled from "styled-components";
import { Color, IconDelete, IconPlus } from "@boomq/uikit";
export const IconDeleteGrey = styled(IconDelete) `
    fill: ${Color.grey65};
`;
export const IconPlus20Blue = styled(IconPlus) `
    fill: ${Color.blue}
    height: 20px;
    width: 20px;
`;
