import { DEFAULT_MAX_LENGTH, DEFAULT_MIN_DIGITS, DEFAULT_MIN_LENGTH, DEFAULT_MIN_LOWERCASE, DEFAULT_MIN_SYMBOLS, DEFAULT_MIN_UPPERCASE, DIGIT, LOWERCASE, SYMBOL, UPPERCASE } from "./constants";
import { mergeMap } from "../mergeMap";
const defaultOptions = {
    maxLength: DEFAULT_MAX_LENGTH,
    minDigits: DEFAULT_MIN_DIGITS,
    minLength: DEFAULT_MIN_LENGTH,
    minLowercase: DEFAULT_MIN_LOWERCASE,
    minUppercase: DEFAULT_MIN_UPPERCASE,
    minSymbols: DEFAULT_MIN_SYMBOLS
};
const checkCharByTestChars = (testChars = "", currentValue, char) => (testChars || "").includes(char) ? currentValue + 1 : currentValue;
const checkLowercase = (currentValue, char) => checkCharByTestChars(LOWERCASE, currentValue, char);
const checkDigit = (currentValue, char) => checkCharByTestChars(DIGIT, currentValue, char);
const checkSymbol = (currentValue, char) => checkCharByTestChars(SYMBOL, currentValue, char);
const checkUppercase = (currentValue, char) => checkCharByTestChars(UPPERCASE, currentValue, char);
const analyzePassword = (password) => Array.from(password).reduce((res, char) => ({
    lowercaseCount: checkLowercase(res.lowercaseCount, char),
    digitCount: checkDigit(res.digitCount, char),
    symbolCount: checkSymbol(res.symbolCount, char),
    uppercaseCount: checkUppercase(res.uppercaseCount, char)
}), {
    uppercaseCount: 0,
    lowercaseCount: 0,
    digitCount: 0,
    symbolCount: 0
});
export const passwordValidator = (password = "", options = defaultOptions) => {
    const { lowercaseCount, digitCount, symbolCount, uppercaseCount } = analyzePassword(password);
    const analysisOptions = mergeMap(options || {}, defaultOptions);
    return {
        isValid: password.length <= analysisOptions.maxLength &&
            password.length >= analysisOptions.minLength &&
            lowercaseCount >= analysisOptions.minLowercase &&
            digitCount >= analysisOptions.minDigits &&
            symbolCount >= analysisOptions.minSymbols &&
            uppercaseCount >= analysisOptions.minUppercase,
        length: password.length,
        lowercaseCount,
        digitCount,
        symbolCount,
        uppercaseCount
    };
};
