import styled from "styled-components";
import { TeamSectionBlock } from "../../../account/Team/TeamInfoBlock";
import { BlockHeaderWithMarginTop, PageFixedActionsPanel } from "../../../styled/Common";
import { Padding } from "../../../styled/Wrappers";
export const ReportEmptySidebarSettings = styled.div `
    align-items: center;
`;
export const ReportFixedActionsPanel = styled(PageFixedActionsPanel) `
    width: calc(100% - 524px);
`;
export const ReportWrapper = styled(Padding) `
    display: flex;
    flex-direction: column;
    padding-bottom: 90px;
    padding-right: 428px;
    .pflb-sidebar-settings {
        z-index: 0;
    }
    @media print {
        padding: 0;
        ${BlockHeaderWithMarginTop},
        ${ReportEmptySidebarSettings},
        ${ReportFixedActionsPanel},
        .pflb-sidebar-settings.show,
        .codex-editor .pflb-sidebar-settings.show {
            display: none;
        }
        #editorjs {
            padding-left: 2px;
            padding-right: 0;
        }
        .codex-editor__redactor {
            display: flex;
            flex-direction: column;
            margin-right: 0;
            padding-bottom: 20px!important;
        }
    }
`;
export const StyledTeamSectionBlock = styled(TeamSectionBlock) `
    height: calc(100% - 120px);
    max-height: calc(100% - 120px);
`;
export const UnauthorizedUserTeamSectionBlock = styled(TeamSectionBlock) `
    height: auto;
    max-height: none;
    padding: 48px 32px;
`;
