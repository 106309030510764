import { IconLink } from "@codexteam/icons";
import { copyTextToClipboard } from "@boomq/utils";
import { BUTTON_TITLE } from "./intl";
import { ACCOUNT_WRAPPER_CLASS_NAME } from "../../../styled/Wrappers";
export class PFLBAnchorLink {
    constructor({ api }) {
        this.scrollTop = 70;
        this.api = api;
        this.clickCount = 0;
        this.isSelecting = false;
        this.api.listeners.on(this.api.ui.nodes.redactor, "mousedown", () => this.mousedownListener(), false);
        this.api.listeners.on(this.api.ui.nodes.redactor, "mouseup", (event) => this.mouseupListener(event), false);
        this.api.listeners.on(this.api.ui.nodes.redactor, "selectstart", () => this.selectstartListener(), false);
    }
    static get isTune() {
        return true;
    }
    mousedownListener() {
        var _a, _b;
        return (_b = (_a = document.getSelection()) === null || _a === void 0 ? void 0 : _a.empty) === null || _b === void 0 ? void 0 : _b.call(_a);
    }
    mouseupListener(event) {
        this.checkSelection();
        return event.target.tagName === "A" ? this.checkAnchorLink(event) : this.resetClickCount();
    }
    checkSelection() {
        const selection = document.getSelection();
        return this.isSelecting && selection && selection.isCollapsed ? this.setIsSelecting(false) : undefined;
    }
    selectstartListener() {
        this.setIsSelecting(true);
    }
    setIsSelecting(value) {
        this.isSelecting = value;
    }
    checkAnchorLink(event) {
        const href = event.target.getAttribute("href");
        this.clickCount++;
        return !this.isSelecting && href[0] === "#" ? setTimeout(() => this.checkDoubleClick(href), 300) : undefined;
    }
    checkDoubleClick(href) {
        return this.clickCount === 1 ? this.scrollIntoElement(href) : this.resetClickCount();
    }
    scrollIntoElement(href) {
        var _a, _b;
        const elementByDataId = this.getElementByDataId(href.replace("#", ""));
        const elementById = this.getElementById(href);
        const { top } = ((_a = (elementByDataId || elementById)) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect()) || { top: 0 };
        this.resetClickCount();
        return elementByDataId || elementById
            ? (_b = document
                .querySelector(`.${ACCOUNT_WRAPPER_CLASS_NAME}`)) === null || _b === void 0 ? void 0 : _b.scrollBy({ behavior: "smooth", top: top - this.scrollTop })
            : undefined;
    }
    getElementByDataId(dataId) {
        var _a;
        try {
            return (_a = document === null || document === void 0 ? void 0 : document.querySelector) === null || _a === void 0 ? void 0 : _a.call(document, `[data-id="${dataId}"]`);
        }
        catch (error) {
            return null;
        }
    }
    getElementById(href) {
        var _a;
        try {
            return (_a = document === null || document === void 0 ? void 0 : document.querySelector) === null || _a === void 0 ? void 0 : _a.call(document, href);
        }
        catch (error) {
            return null;
        }
    }
    resetClickCount() {
        this.clickCount = 0;
    }
    destroy() {
        this.api.listeners.off(this.api.ui.nodes.redactor, "mouseup", this.mouseupListener, false);
    }
    render() {
        return {
            icon: IconLink,
            title: this.api.i18n.t(BUTTON_TITLE),
            onActivate: () => this.handleClick(),
            name: "copy-link-to-block"
        };
    }
    handleClick() {
        const currentBlockIndex = this.api.blocks.getCurrentBlockIndex();
        const currentBlock = this.api.blocks.getBlockByIndex(currentBlockIndex);
        copyTextToClipboard(`#${currentBlock === null || currentBlock === void 0 ? void 0 : currentBlock.id}`);
        this.api.toolbar.toggleBlockSettings();
    }
}
