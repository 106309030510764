import React, { FC, useRef, useState } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { copyElementDataToClipboard } from "@boomq/utils";
import { authorizeActions, StyledButton } from "@boomq/common";
import {
    ACCOUNT_LINKING_CODE_TEXT,
    ACCOUNT_LINKING_URL_TEXT,
    CODE_ENTER_BUTTON_LABEL,
    CODE_ENTER_TEXT,
    COPIED_CODE_BUTTON_LABEL,
    COPY_CODE_BUTTON_LABEL
} from "./intl";
import { AuthCodeLink, Code, CopyButton, CopyCodeSection, StyledFormTitle } from "./styled";
import { Handlers, Props } from "./types";

const connector = connect(null, { onClickEnterCodeButton: authorizeActions.updateAuthProvidersRequest });

const OAuth2VerificationCodeForm: FC<Props & Handlers> = ({
    code,
    onClickEnterCodeButton,
    url
}: Props & Handlers): JSX.Element => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [isCodeCopied, setIsCodeCopied] = useState<boolean>(false);

    const clickCopyCodeHandler = (): void =>
        setIsCodeCopied(copyElementDataToClipboard(inputRef ? inputRef.current : null));

    const clickEnterCodeHandler = (): void =>
        typeof onClickEnterCodeButton === "function" ? onClickEnterCodeButton() : undefined;

    return (
        <StyledFormTitle>
            <FormattedMessage {...ACCOUNT_LINKING_URL_TEXT} />

            <div>
                <AuthCodeLink target="_blank" href={url}>
                    {url}
                </AuthCodeLink>
            </div>

            <FormattedMessage {...ACCOUNT_LINKING_CODE_TEXT} />

            <CopyCodeSection>
                <Code ref={inputRef}>{code}</Code>

                {isCodeCopied ? (
                    <CopyButton isCodeCopied={true}>
                        <FormattedMessage {...COPIED_CODE_BUTTON_LABEL} />
                    </CopyButton>
                ) : (
                    <CopyButton onClick={clickCopyCodeHandler}>
                        <FormattedMessage {...COPY_CODE_BUTTON_LABEL} />
                    </CopyButton>
                )}
            </CopyCodeSection>

            <FormattedMessage {...CODE_ENTER_TEXT} />

            <StyledButton className="close_modal_window" onClick={clickEnterCodeHandler}>
                <FormattedMessage {...CODE_ENTER_BUTTON_LABEL} />
            </StyledButton>
        </StyledFormTitle>
    );
};

export default connector(OAuth2VerificationCodeForm);
