import styled, { css } from "styled-components";
import { Color, FontBodySmallMedium, IconProfile } from "@boomq/uikit";
import { HeaderText } from "../../styled/Header";
import { media } from "../../styled/response";
import { ToolbarLink } from "../../website/ToolbarLink";
export const UserDefaultPhoto = styled(IconProfile) `
    fill: ${Color.darkGrey35};
    height: 16px;
    width: 16px;
`;
export const UserInfoWrapperStyle = ({ bordered, reverse }) => `
    align-items: center;    
    background: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    outline: none;
    transition: all 0.3s ease-in-out;
    ${reverse
    ? css `
                  flex-direction: row-reverse;
                  ${HeaderText} {
                      margin-left: 0;
                  }
              `
    : css ``}
    ${bordered
    ? css `
                  border: 2px solid ${Color.blue};
                  border-radius: 100px;
                  padding: 5px 20px;
                  transition: all 0.3s ease-in-out;
                  &:hover {
                      background: ${Color.blue};
                      & p${HeaderText} {
                          color: ${Color.white};
                      }
                  }
              `
    : css ``}
    &.active {
        background: rgba(255, 255, 255, 0.5);
    }
`;
export const UserName = styled(HeaderText) `
    ${FontBodySmallMedium}
    margin-left: 4px;
    ${media.giant} {
        display: none;
    }
`;
export const UserPhoto = styled.img `
    height: auto;
    max-width: 32px;
    min-width: 32px;
    width: 32px;
`;
export const UserLink = styled(ToolbarLink) `
    &:hover {
        ${UserName} {
            color: ${Color.blue};
        }
        ${UserDefaultPhoto} {
            fill: ${Color.blue};
        }
    }
    ${({ bordered, reverse }) => css `
            ${UserInfoWrapperStyle({ bordered, reverse })}
        `}
    ${({ mobile }) => mobile === "true"
    ? css `
                  padding: 8px 0;
                  ${UserDefaultPhoto} {
                      background: ${Color.blue};
                      border: 2px solid ${Color.blue};
                  }
                  ${HeaderText} {
                      color: #6b8cae;
                      font-size: 18px;
                      font-weight: 500;
                      line-height: 23px;
                      margin-left: 8px;
                      text-align: center;
                  }
              `
    : css `
                  margin-left: 16px;
              `}
`;
export const UserInfoWrapper = UserLink.withComponent("div");
