import { handleActions } from "redux-actions";
import { recoveryRequest, recoverySuccess, recoveryFailed } from "../actions/reset";
import { resetError } from "../actions/error";
const initialState = {
    data: {},
    error: ""
};
export default handleActions({
    [recoveryRequest]: (state) => (Object.assign(Object.assign({}, state), { data: {}, error: "" })),
    [recoverySuccess]: (state, action) => (Object.assign(Object.assign({}, state), { data: action.payload })),
    [recoveryFailed]: (state, action) => (Object.assign(Object.assign({}, state), { error: action.payload.code })),
    [resetError]: (state) => (Object.assign(Object.assign({}, state), { error: initialState.error }))
}, initialState);
export const getErrorCode = (state) => state.recovery.error;
