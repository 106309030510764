import { TEAM_MEMBER_PROCESS_TITLE } from "./intl";
import { showError } from "./showError";
import { ExceptionForm } from "../../../components/common/ExceptionForm";
import { getTeamErrorByExceptionMessage } from "../../../data/content/errorMessages";
export function* teamMemberProcessingFailureFlow(action) {
    const { exceptionMessage } = action && action.payload ? action.payload : {};
    const error = getTeamErrorByExceptionMessage(exceptionMessage);
    const params = error
        ? {
            element: ExceptionForm,
            props: {
                texts: error
            },
            theme: { alignItems: "center" },
            title: TEAM_MEMBER_PROCESS_TITLE,
            width: "970px"
        }
        : {};
    yield* showError(params);
}
