import { AuthBlock, AuthButtonBlock, AuthErrorMessage, AuthLabel, ChangePasswordFormContainer } from "@boomq/common";
import styled from "styled-components";

export const StyledChangePasswordForm = styled(ChangePasswordFormContainer)`
    ${AuthLabel} {
        margin-top: 36px;
    }
`;

export const StyledAuthBlock = styled(AuthBlock)`
    width: 450px;
`;

export const StyledAuthButtonBlock = styled(AuthButtonBlock)`
    margin-top: 40px;
`;
