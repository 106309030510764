import styled from "styled-components";
import { Block } from "@boomq/uikit";
import { media } from "../../styled/response";
export const customSelectTheme = {
    width: "100%"
};
export const GridContainer = styled.div `
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 2fr;
`;
export const GridItem = styled.div `
    font-size: 14px;
    padding: 12px;
`;
export const GridHeader = styled(GridItem) `
    border-bottom: 1px solid #f3f4f5;
    font-weight: 600;
    margin-bottom: 10px;
    padding: 20px 12px;
`;
export const StyledBlock = styled.div `
    ${Block}
    display: flex;
    grid-column: 1/3;
    margin: 35px 11px;
    padding-top: 35px;
    padding-bottom: 35px;
    ${media.desktop} {
        flex-direction: column;
        grid-column: 1/2;
    }
`;
export const StyledDescriptionBlock = styled.div `
    ${Block}
    height: 300px;
    margin-left: 56px;
    margin-right: 39px;
    margin-top: 25px;
`;
export const SectionDetails = styled.div `
    flex: 1;
`;
export const SectionGraph = styled(SectionDetails) `
    .recharts-responsive-container {
        margin-left: 2.5%;
        width: 95% !important;
    }
`;
export const Title = styled.h3 `
    color: #83909f;
    font-size: 14px;
    margin-left: 10px;
    margin-top: 10px;
`;
export const TransactionSelectionBlock = styled.div `
    align-items: center;
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    padding-left: 120px;
    padding-right: 80px;
`;
