var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { isEmpty } from "@boomq/utils";
import { ASSERTION_LABEL_DEFAULT_VALUE } from "./constants";
import { getAssertionResponseBodyJsonWithoutValueConditionList } from "../assertion";
import { getTestProjectModelElDefaultValues } from "../testProjectModelSchema";
import { getAssertionTemplateTypeByParams } from "../../models/assertion/assertion";
import { AssertionDurationType, AssertionFilter, AssertionResponseType, AssertionSizeType, AssertionTemplateType } from "../../models/assertion/enums";
const generateAssertionDuration = ({ condition, value }) => ({ condition, value });
const generateAssertionResponse = ({ condition, value }) => ({ condition, values: [value] });
const generateAssertionResponseBodyJson = ({ condition, jsonPath, value }) => getAssertionResponseBodyJsonWithoutValueConditionList().includes(condition)
    ? { condition, jsonPath, type: AssertionTemplateType.ResponseBodyJson }
    : { condition, jsonPath, type: AssertionTemplateType.ResponseBodyJson, value };
const generateAssertionResponseBodyXpath2 = ({ condition, value }) => ({
    condition,
    namespacesAliases: [],
    type: AssertionTemplateType.ResponseBodyXPath2,
    value
});
const generateAssertionSize = ({ condition, value }) => ({ condition, value });
const assertionGeneratorsByFilter = {
    [AssertionFilter.JsonPath]: generateAssertionResponseBodyJson,
    [AssertionFilter.XPath2]: generateAssertionResponseBodyXpath2
};
const generateAssertionResponseBody = (_a) => {
    var { filter } = _a, restParams = __rest(_a, ["filter"]);
    return Object.keys(assertionGeneratorsByFilter).includes(filter)
        ? assertionGeneratorsByFilter[filter](restParams)
        : generateAssertionResponse(restParams);
};
const assertionGeneratorsByTemplateType = {
    [AssertionTemplateType.Duration]: generateAssertionDuration,
    [AssertionTemplateType.Response]: generateAssertionResponse,
    [AssertionTemplateType.ResponseBody]: generateAssertionResponseBody,
    [AssertionTemplateType.Size]: generateAssertionSize
};
const assertionModelNameByType = {
    [AssertionDurationType.Duration]: "DurationAssertion",
    [AssertionResponseType.RequestData]: "RequestDataAssertion",
    [AssertionResponseType.RequestHeaders]: "RequestHeadersAssertion",
    [AssertionResponseType.ResponseBody]: "ResponseBodyAssertion",
    [AssertionResponseType.ResponseCode]: "ResponseCodeAssertion",
    [AssertionResponseType.ResponseHeaders]: "ResponseHeadersAssertion",
    [AssertionResponseType.ResponseMessage]: "ResponseMessageAssertion",
    [AssertionResponseType.ResponseTikaExtract]: "ResponseTikaExtractAssertion",
    [AssertionResponseType.UrlSampled]: "ResponseBodyAssertion",
    [AssertionSizeType.FullResponse]: "FullResponseSizeAssertion",
    [AssertionSizeType.ResponseBody]: "ResponseBodySizeAssertion",
    [AssertionSizeType.ResponseCode]: "ResponseCodeSizeAssertion",
    [AssertionSizeType.ResponseHeaders]: "ResponseHeadersSizeAssertion",
    [AssertionSizeType.ResponseMessage]: "ResponseMessageSizeAssertion"
};
const assertionResponseModelNameByFilter = {
    [AssertionFilter.JsonPath]: "ResponseBodyJsonAssertion",
    [AssertionFilter.XPath2]: "ResponseBodyXPath2Assertion"
};
const generateAssertionByType = (type, params) => {
    const templateType = getAssertionTemplateTypeByParams({ type });
    return typeof assertionGeneratorsByTemplateType[templateType] === "function"
        ? assertionGeneratorsByTemplateType[templateType](params)
        : {};
};
const getResponseBodyAssertionModelElNameByParams = ({ filter, type }) => Object.keys(assertionResponseModelNameByFilter).includes(filter)
    ? assertionResponseModelNameByFilter[filter]
    : assertionModelNameByType[type];
const getModelElNameByParams = ({ filter, type }) => type === AssertionResponseType.ResponseBody
    ? getResponseBodyAssertionModelElNameByParams({ filter, type })
    : assertionModelNameByType[type];
const getModelDefaultValuesByParams = (modelSchema, { filter, type }) => {
    const elData = getTestProjectModelElDefaultValues(modelSchema, getModelElNameByParams({ filter, type }));
    return isEmpty(elData) ? {} : elData;
};
const generateAssertionLabel = (id = "") => `${ASSERTION_LABEL_DEFAULT_VALUE}_${id}`;
const prepareAssertionLabel = (id, label) => (label ? label : generateAssertionLabel(id));
const generateAssertion = (_a, modelSchema) => {
    var { id, label = "", type, typeDisplayName = "" } = _a, restParams = __rest(_a, ["id", "label", "type", "typeDisplayName"]);
    return (Object.assign(Object.assign(Object.assign({}, getModelDefaultValuesByParams(modelSchema, Object.assign({ type }, restParams))), { id, label: prepareAssertionLabel(id, label), type,
        typeDisplayName }), generateAssertionByType(type, restParams)));
};
export const generateAssertions = (assertions = [], modelSchema) => (assertions || []).reduce((res, assertion) => [...res, generateAssertion(assertion, modelSchema)], []);
