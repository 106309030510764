import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getUniqueId, useIntl } from "@boomq/utils";
import { PARAMETER_NAME_PLACEHOLDER_TEXT, PARAMETER_VALUE_PLACEHOLDER_TEXT } from "./intl";
import { LiteralParam, UsedParameterSection } from "./styled";
import { KeyValue } from "../KeyValue";
import { withResize } from "../../styled/response";
import { UsedParameterIndicator } from "../UsedParameterIndicator";
import { literalParameterValidator } from "../../../helpers/validators/testPojectSettingsSet";
import { changeRequestParametersField, deleteRequestLiteralParameter } from "../../../redux/actions/requestParameters";
import { getAllUsedRequestParameterNames } from "../../../redux/reducers/newTest";
import { getRequestLiteralParameters } from "../../../redux/reducers/requestParameters";
const connector = connect((state) => ({
    literalParameters: getLiteralParameters(state),
    usedRequestParameterNames: getAllUsedRequestParameterNames(state)
}), { onChangeField: changeRequestParametersField, onDelete: deleteRequestLiteralParameter });
const getLiteralParameters = (state) => [
    ...getRequestLiteralParameters(state),
    { id: getUniqueId(), key: "", value: "" }
];
const validateParameterName = (params) => {
    const { isValid, error } = literalParameterValidator(params);
    return isValid ? undefined : { key: error };
};
const LiteralParameters = (props) => {
    const { formatMessage } = useIntl();
    const changeHandler = (val, id) => props.onChangeField({
        field: props.name,
        value: [
            ...props.literalParameters.map((literalParam) => (literalParam.id === id && Object.assign({ id }, val)) || literalParam)
        ].filter((literalParam) => literalParam.key !== "" || literalParam.value !== "")
    });
    const deleteHandler = (id) => props.onDelete({ id });
    const renderUsedParameterIndicator = (parameterName) => props.usedRequestParameterNames.includes(parameterName) ? React.createElement(UsedParameterIndicator, null) : undefined;
    const placeholderKey = formatMessage(PARAMETER_NAME_PLACEHOLDER_TEXT);
    const placeholderValue = formatMessage(PARAMETER_VALUE_PLACEHOLDER_TEXT);
    return (props.literalParameters || []).map((literalParam) => (React.createElement(LiteralParam, { key: literalParam.id },
        React.createElement(UsedParameterSection, null, renderUsedParameterIndicator(literalParam.key)),
        React.createElement(KeyValue, { error: validateParameterName(literalParam), name: props.name, onChange: (val) => changeHandler(val, literalParam.id), onDelete: () => deleteHandler(literalParam.id), placeholderKey: placeholderKey, placeholderValue: placeholderValue, value: { key: literalParam.key, value: literalParam.value } }))));
};
export default withResize(withRouter(connector(LiteralParameters)));
