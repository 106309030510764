export var TestProjectsItemAction;
(function (TestProjectsItemAction) {
    TestProjectsItemAction["AddLabel"] = "onAddLabel";
    TestProjectsItemAction["AddLabelWithCreating"] = "onAddLabelWithCreating";
    TestProjectsItemAction["Copy"] = "onCopy";
    TestProjectsItemAction["DeleteLabel"] = "onDeleteLabel";
    TestProjectsItemAction["Edit"] = "onEdit";
    TestProjectsItemAction["RedirectToLoadProfile"] = "onRedirectToLoadProfile";
    TestProjectsItemAction["SearchLabels"] = "onSearchLabels";
    TestProjectsItemAction["ShowSettingsSetSidebar"] = "onShowSettingsSetSidebar";
})(TestProjectsItemAction || (TestProjectsItemAction = {}));
export var TestProjectAction;
(function (TestProjectAction) {
    TestProjectAction["Add"] = "onAdd";
    TestProjectAction["AddRequest"] = "onAddRequest";
    TestProjectAction["ChangeAutoPercentages"] = "onChangeAutoPercentages";
    TestProjectAction["Edit"] = "onEdit";
    TestProjectAction["Save"] = "onSave";
    TestProjectAction["SaveAs"] = "onSaveAs";
    TestProjectAction["RunTest"] = "onRunTest";
})(TestProjectAction || (TestProjectAction = {}));
