export const ALL_TEST_RUNS_TITLE = {
    defaultMessage: "All test runs",
    id: "account.all.test.runs.title"
};
export const NEW_TEST_BUTTON_LABEL = {
    defaultMessage: "Add new test",
    id: "account.all.empty.button"
};
export const PAGE_TITLE = {
    defaultMessage: "Test runs",
    id: "account.navbar.all"
};
export const RUNNING_TESTS_TITLE = {
    defaultMessage: "Test runs in progress",
    id: "account.all.tests.running.title"
};
