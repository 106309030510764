import React from "react";
import { connect } from "react-redux";
import { useIntl } from "@boomq/utils";
import { EditableTitle } from "../../../../components/common/EditableTitle";
import { titleTheme } from "../../../../components/styled/Common";
import { formatIntlMessage } from "../../../../helpers/intl";
import { nameValidator } from "../../../../helpers/validators";
import newTestActions from "../../../../redux/actions/newTest";
import { getProjectName } from "../../../../redux/reducers/newTest";
const { testProjectChangeParamsField } = newTestActions;
const connector = connect((state) => ({
    projectName: getProjectName(state)
}), { onChangeTestProjectParamsField: testProjectChangeParamsField });
const TestProjectNameContainer = ({ onChangeTestProjectParamsField, projectName }) => {
    const { formatMessage } = useIntl();
    const changeProjectName = (value) => onChangeTestProjectParamsField === null || onChangeTestProjectParamsField === void 0 ? void 0 : onChangeTestProjectParamsField({ field: "projectName", value, isModified: true });
    const validateFunc = (value) => {
        const { isValid, error } = nameValidator(value);
        return { isValid, error: formatIntlMessage(error, formatMessage) };
    };
    return (React.createElement(EditableTitle, { onChange: changeProjectName, editModeTheme: titleTheme, theme: titleTheme, value: projectName, validateFunc: validateFunc }));
};
export default connector(TestProjectNameContainer);
