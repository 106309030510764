import { handleActions } from "redux-actions";
import authorizeActions from "../actions/authorize";
import { setCurrentPage, slaReportProfilesSuccess } from "../actions/slaReportProfiles";
const { logout } = authorizeActions;
export const initialState = {
    content: [],
    currentPage: 0,
    totalElements: 0,
    totalPages: 0
};
export default handleActions({
    [logout]: () => (Object.assign({}, initialState)),
    [setCurrentPage]: (state, action) => (Object.assign(Object.assign({}, state), { currentPage: Number.isInteger(action.payload) ? action.payload : 0 })),
    [slaReportProfilesSuccess]: (state, action) => (Object.assign(Object.assign({}, state), action.payload)),
}, initialState);
export const getSlaReportProfilesCurrentPage = (state) => state.slaReportProfiles.currentPage;
export const getSlaReportProfiles = (state) => state.slaReportProfiles.content;
export const getSlaReportProfilesCount = (state) => state.slaReportProfiles.totalElements;
export const getSlaReportProfilesPagesCount = (state) => state.slaReportProfiles.totalPages;
