import styled from "styled-components";
export const customHintTheme = {
    left: "calc(50% + 75px)",
    position: "absolute",
    top: "28%"
};
export const customSelectTheme = {
    width: "100%"
};
export const ButtonSection = styled.div `
    display: flex;
    justify-content: center;
    position: relative;
`;
export const ModalFormBodySettings = styled.div `
    display: flex;
    flex-direction: column;
    height: 90%;
`;
export const Setting = styled.div `
    align-items: center;
    display: flex;
    margin: 15px 0;
    min-height: 46px;
`;
export const Settings = styled.div `
    display: flex;
    flex-direction: column;
    margin: 20px 0 0;
`;
export const SaveButtonSection = styled.div `
    align-items: flex-end;
    display: flex;
    flex: auto;
    justify-content: center;
    > button {
        width: auto !important;
    }
`;
export const Text = styled.p `
    color: #396496;
    font-size: 18px;
    font-weight: 600;
    max-width: 260px;
    min-width: 260px;
    line-height: normal;
`;
