import React from "react";
import { connect } from "react-redux";
import { useIntl } from "@boomq/utils";
import { EditableTitle } from "../../../../components/common/EditableTitle";
import { titleTheme } from "../../../../components/styled/Common";
import { formatIntlMessage } from "../../../../helpers/intl";
import { nameValidator } from "../../../../helpers/validators";
import { testProjectSettingsSetChangeParamsField } from "../../../../redux/actions/testProjectSettingsSet";
import { getSettingsSetName } from "../../../../redux/reducers/testProjectSettingsSet";
const connector = connect((state) => ({
    name: getSettingsSetName(state)
}), { onChange: testProjectSettingsSetChangeParamsField });
const TestProjectSettingsSetNameContainer = ({ name, onChange }) => {
    const { formatMessage } = useIntl();
    const changeTestProjectSettingsSetNameHandler = (value) => onChange({ field: "name", value });
    const validateFunc = (value) => {
        const { isValid, error } = nameValidator(value);
        return { isValid, error: formatIntlMessage(error, formatMessage) };
    };
    return (React.createElement(EditableTitle, { onChange: changeTestProjectSettingsSetNameHandler, editModeTheme: titleTheme, theme: titleTheme, validateFunc: validateFunc, value: name }));
};
export default connector(TestProjectSettingsSetNameContainer);
