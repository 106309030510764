import { getUniqueId, isEmpty } from "@boomq/utils";
import { DEFAULT_SLA_ITEM_THREAD_GROUP, DEFAULT_SLA_ITEM_TRANSACTION } from "./constants";
import { getNewSlaFormItemDataByTargetType, getTransactionFilterTypeByValue } from "../../../helpers/sla";
import { SlaOperation, SlaStatus, SlaTargetType } from "../../../models/sla";
const getThreadGroupAdditionalValue = ({ id, text }) => {
    const threadGroupId = id || DEFAULT_SLA_ITEM_THREAD_GROUP;
    return {
        threadGroupId,
        threadGroupName: text
    };
};
const getTransactionAdditionalValue = ({ id, value }) => {
    const transactionName = value || DEFAULT_SLA_ITEM_TRANSACTION;
    return {
        transactionId: id,
        transactionName,
        transactionFilterType: getTransactionFilterTypeByValue(transactionName)
    };
};
const additionalValueByField = {
    [SlaTargetType.Transaction]: getTransactionAdditionalValue,
    [SlaTargetType.ThreadGroup]: getThreadGroupAdditionalValue
};
const getAdditionalValue = ({ field, data }) => Object.keys(additionalValueByField).includes(field) ? additionalValueByField[field](data) : { [field]: data };
const getStatusByParams = (operation, result, value) => {
    switch (operation) {
        case SlaOperation.GreaterThen: {
            return result > value ? SlaStatus.Success : SlaStatus.Failure;
        }
        case SlaOperation.LessEquals: {
            return result <= value ? SlaStatus.Success : SlaStatus.Failure;
        }
        default: {
            return SlaStatus.NotCounted;
        }
    }
};
const getStatus = (modifiedSlaItem) => {
    const { operation, result, status, value } = modifiedSlaItem;
    return {
        status: !isEmpty(result) ? getStatusByParams(operation, result, value) : status
    };
};
const resetParamsByField = {
    requestName: Object.assign(Object.assign({}, getThreadGroupAdditionalValue({})), getTransactionAdditionalValue({})),
    [SlaTargetType.Transaction]: getThreadGroupAdditionalValue({})
};
const resetValuesByField = ({ field }) => resetParamsByField[field] || {};
const updateSlaItem = (slaItem, payload) => {
    const newSlaItem = Object.assign(Object.assign(Object.assign(Object.assign({}, slaItem), { isValid: true }), getAdditionalValue(payload)), resetValuesByField(payload));
    return Object.assign(Object.assign({}, newSlaItem), getStatus(newSlaItem));
};
export const reducer = (state, action) => {
    const { type, payload } = action || {};
    switch (type) {
        case "set": {
            return Object.assign({}, payload);
        }
        case "update": {
            return state && state[payload.type]
                ? Object.assign(Object.assign({}, state), { [payload.type]: state[payload.type].map((slaItem) => slaItem.id === payload.id ? updateSlaItem(slaItem, payload) : slaItem) }) : state;
        }
        case "add": {
            return state && state[payload.type]
                ? Object.assign(Object.assign({}, state), { [payload.type]: [...state[payload.type], getNewSlaFormItemDataByTargetType(payload)] }) : state;
        }
        case "copy": {
            return state && state[payload.type]
                ? Object.assign(Object.assign({}, state), { [payload.type]: state[payload.type].reduce((res, slaItem) => slaItem.id === payload.id
                        ? [
                            ...res,
                            slaItem,
                            Object.assign(Object.assign({}, slaItem), { id: getUniqueId() })
                        ]
                        : [...res, slaItem], []) }) : state;
        }
        case "delete": {
            return state && state[payload.type]
                ? Object.assign(Object.assign({}, state), { [payload.type]: state[payload.type].filter((slaItem) => slaItem.id !== payload.id) }) : state;
        }
        default: {
            return state;
        }
    }
};
