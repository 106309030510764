import React from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { convertDateToTimestamp, getFullDateLocaleTimeZone, useDuration } from "@boomq/utils";
import { Help } from "@boomq/uikit";
import { COMMENT_TITLE, NO_COMMENT_MESSAGE, NO_DATA_MESSAGE, TIME_TOOLTIP_TEXT } from "./intl";
import { ContentWrapper, Img, ImgWrapper, Item, Text } from "./styled";
import { load, message, time } from "../../../assets/images/icons/test_details/index";
import { getCurrentTestTypeText } from "../../../data/content/testTypeMessages";
import { statusColors, statusTitles } from "../../../helpers/testStatus";
import { TestDisplayStatus } from "../../../models/testRun";
import { getCurrentDateFormatLocale } from "../../../redux/reducers/authorizePlate";
const isExistTimeData = (startTime, endTime) => startTime !== 0 && endTime !== 0;
export const TestDetailsInfo = ({ comment, displayState, endDate, startDate, testProjectVersion }) => {
    const { testType } = testProjectVersion ? testProjectVersion : {};
    const endTime = convertDateToTimestamp(endDate);
    const startTime = convertDateToTimestamp(startDate);
    const testTypeText = getCurrentTestTypeText(testType);
    const durationText = useDuration(endTime - startTime);
    const dateFormatLocale = useSelector(getCurrentDateFormatLocale);
    return (React.createElement(React.Fragment, null,
        React.createElement(Item, null,
            React.createElement(ContentWrapper, null,
                React.createElement(ImgWrapper, null,
                    React.createElement(Img, { src: load })),
                React.createElement(Text, null,
                    React.createElement(FormattedMessage, Object.assign({}, testTypeText.title))),
                React.createElement(Help, { tooltipText: Object.assign({}, testTypeText.tooltip) }))),
        React.createElement(Item, null,
            React.createElement(ContentWrapper, null,
                React.createElement(ImgWrapper, null,
                    React.createElement(Img, { src: message })),
                React.createElement(Text, null,
                    React.createElement(FormattedMessage, Object.assign({}, COMMENT_TITLE)))),
            React.createElement(Text, { color: "#BDBDBD", comment: true }, comment ? comment : React.createElement(FormattedMessage, Object.assign({}, NO_COMMENT_MESSAGE)))),
        React.createElement(Item, null,
            React.createElement(ContentWrapper, null,
                React.createElement(ImgWrapper, null,
                    React.createElement(Img, { src: time })),
                React.createElement(Text, null, isExistTimeData(startTime, endTime) && durationText),
                React.createElement(Help, { tooltipText: TIME_TOOLTIP_TEXT }))),
        React.createElement(Item, null,
            React.createElement(ContentWrapper, null,
                React.createElement(Text, { color: "#BDBDBD", desc: true },
                    startTime !== 0 && getFullDateLocaleTimeZone(dateFormatLocale, startTime),
                    startTime === 0 && endTime === 0 ? React.createElement(FormattedMessage, Object.assign({}, NO_DATA_MESSAGE)) : " - ",
                    endTime !== 0 && getFullDateLocaleTimeZone(dateFormatLocale, endTime)),
                React.createElement(Text, { status: true, color: statusColors(displayState) },
                    React.createElement(FormattedMessage, Object.assign({}, statusTitles(displayState))))))));
};
TestDetailsInfo.defaultProps = {
    testProjectVersion: { comment: "" },
    endDate: 0,
    maxUserCount: 0,
    startDate: 0,
    displayState: TestDisplayStatus.Initialization
};
