import moment from "moment";
import { isEmpty } from "@boomq/utils";
export const convertDateToISOString = (value) => value && typeof value.toISOString === "function" ? value.toISOString() : undefined;
export const getLocale = (locale) => (locale === "en-US" ? "en" : "en-gb");
export const getDateStateValue = (value) => (value ? moment(value) : null);
export function isBeforeDay(a, b) {
    if (!moment.isMoment(a) || !moment.isMoment(b)) {
        return false;
    }
    const aYear = a.year();
    const aMonth = a.month();
    const bYear = b.year();
    const bMonth = b.month();
    const isSameYear = aYear === bYear;
    const isSameMonth = aMonth === bMonth;
    if (isSameYear && isSameMonth) {
        return a.date() < b.date();
    }
    if (isSameYear) {
        return aMonth < bMonth;
    }
    return aYear < bYear;
}
const formatDateToDDMMYYYYString = (value) => (value ? value.format("DD-MM-YYYY").toString() : undefined);
export const isDayHighlighted = ({ day, endDate, highlightedDateRange, startDate }) => {
    const editableDate = startDate ? startDate.clone() : endDate.clone();
    const notEditableDate = startDate ? endDate : startDate;
    const highlightedDateRangeValue = startDate ? highlightedDateRange : -highlightedDateRange;
    const highlightedDate = editableDate ? editableDate.add(highlightedDateRangeValue, "d") : null;
    return (!isEmpty(highlightedDateRange) &&
        editableDate &&
        !notEditableDate &&
        highlightedDate &&
        formatDateToDDMMYYYYString(highlightedDate) === formatDateToDDMMYYYYString(day));
};
function isInclusivelyAfterDay(a, b) {
    if (!moment.isMoment(a) || !moment.isMoment(b)) {
        return false;
    }
    return !isBeforeDay(a, b);
}
export const isOutsideRange = (day) => isInclusivelyAfterDay(day, moment().add(1, "d"));
