import styled from "styled-components";
import { isEmpty } from "@boomq/utils";
import { Color, FontBodyBigMedium, IconClose, InputText, StyledInputWrapper } from "@boomq/uikit";
import { NewSettingsSetInSidebar } from "../NewSettingsSetInSidebar";
export const customFilterLabelTheme = {
    width: "100%"
};
export const customInfiniteScrollTheme = { height: "calc(100% - 130px)" };
export const getCustomInfiniteScrollTheme = (linkedSettingsSetData) => isEmpty(linkedSettingsSetData) ? { height: "calc(100% - 130px)" } : { height: "calc(100% - 175px)" };
export const ButtonBlock = styled.div `
    display: flex;
    justify-content: center;
    padding: 12px 0;
`;
export const FiltersBlock = styled.div `
    display: flex;
    margin-top: 16px;
    ${StyledInputWrapper} {
        margin-right: 8px;
    }
`;
export const Separator = styled.div `
    border-bottom: 1px solid ${Color.lightGrey85};
    margin-top: 16px;
`;
export const SidebarContent = styled.div `
    height: calc(100% - 66px);
    padding: 24px 24px 0 24px;
    position: relative;
`;
export const StyledIconClose = styled(IconClose) `
    cursor: pointer;
    fill: ${Color.grey65};
    position: absolute;
    right: 24px;
    top: 24px;
`;
export const StyledInputText = styled(InputText) `
    min-width: 218px;
    width: 218px;
`;
export const StyledNewSettingsSetInSidebar = styled(NewSettingsSetInSidebar) `
    display: ${({ isShow }) => (isShow ? "flex" : "none")};
`;
export const Title = styled.div `
    ${FontBodyBigMedium}
    color: ${Color.darkGrey5};
`;
