export const DEFAULT_HEADER_HELP_MESSAGE = {
    defaultMessage: "Default header. Test-wide settings available in Actions -> Headers menu. You can override the default value by adding the new value with the same header name.",
    id: "account.request.form.default.header.hint"
};
export const HEADER_PARAMETER_KEY_PLACEHOLDER = {
    defaultMessage: "Key",
    id: "account.request.form.key.placeholder"
};
export const HEADER_PARAMETER_VALUE_PLACEHOLDER = {
    defaultMessage: "Value",
    id: "account.request.form.value.placeholder"
};
