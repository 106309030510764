import styled from "styled-components";
import { IconAlertCircle } from "../Icons";
import { Color } from "../styled/colorPallete";
import { FontBodyMediumMedium } from "../styled/typography";
export const IconError = styled(IconAlertCircle) `
    fill: ${Color.red};
`;
export const IconErrorBlock = styled.span `
    align-items: center;
    cursor: help;
    display: flex;
    margin-right: 4px;
`;
export const IconsBlock = styled.div `
    display: flex;
    height: 100%;
    position: absolute;
    right: 6px;
    z-index: 2;
`;
export const Unit = styled.span `
    ${FontBodyMediumMedium}
    align-items: center;
    color: ${({ isError }) => (isError ? Color.red : Color.grey75)};
    cursor: pointer;
    display: flex;
    margin-right: 4px;
`;
