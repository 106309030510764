import { call, put, race, select, take, takeEvery } from "redux-saga/effects";
import { TEST_RUNS_MAX_REQUEST_COUNT, TEST_RUNS_TIMEOUT_MS } from "./constants";
import { clientTestProjectsFlow } from "../clientTestProjectList";
import { loopWithStopAction } from "../core";
import clientActions from "../../actions/client";
import { hideLoader, showLoader } from "../../actions/loader";
import { setIsRunningTestsLoading, setRunningTestsData } from "../../actions/runningTests";
import { genCompareUrlTestsFailure, initClientTests, refreshTestsLabelsFailure, refreshTestsLabelsSuccess, refreshTestsUsersFailure, refreshTestsUsersSuccess, toggleTest } from "../../actions/tests";
import { getClientTasksCurrentPage, getClientTasksFilters, getClientTasksPageSize, getSelectedClientTestIds, getSelectedClientTests, initialState } from "../../reducers/clientTasks";
import { getPreviousRunningTestsCount } from "../../reducers/runningTests";
import { getUserNames, prepareUserList } from "../../../helpers/clientTests";
import { getFormattedUTCDate } from "../../../helpers/date";
import { getResponseErrorData } from "../../../helpers/errors";
import { getLabelSetNames, prepareLabels } from "../../../helpers/labels";
import { getTestRunningDisplayStatesString } from "../../../helpers/testStatus";
import { getComparingTestsGrafanaUrl } from "../../../services/grafanaSrv";
import { getClientTests, getTestsLabelsByQuery, getTestUserList } from "../../../services/testSrv";
import { isEmpty } from "@boomq/utils";
const { changeClientTasksFiltersFailure, changeClientTasksFiltersRequest, clientTasksRequest, clientTasksFailure, clientTasksSuccess, selectClientTasks, setCurrentPage } = clientActions;
function* clientTasksFiltersFlow() {
    var _a, _b, _c;
    try {
        const { testStatusFilter } = yield select(getClientTasksFilters);
        const payload = ((_a = testStatusFilter === null || testStatusFilter === void 0 ? void 0 : testStatusFilter[0]) === null || _a === void 0 ? void 0 : _a.value)
            ? {
                page: 0,
                state: (_c = (_b = testStatusFilter === null || testStatusFilter === void 0 ? void 0 : testStatusFilter[0]) === null || _b === void 0 ? void 0 : _b.value) !== null && _c !== void 0 ? _c : ""
            }
            : { page: 0 };
        yield* tasksFlow({ payload });
    }
    catch (e) {
        yield put(changeClientTasksFiltersFailure(getResponseErrorData(e)));
    }
}
export function* tasksFlow(action) {
    try {
        const { displayState, page, size, state } = action && action.payload ? action.payload : {};
        const { testLabelFilter, testProjectFilter, testStatusFilter, testUserFilter } = yield select(getClientTasksFilters);
        const pageNumber = !isEmpty(page) ? page : yield select(getClientTasksCurrentPage);
        const pageSize = size ? size : yield select(getClientTasksPageSize);
        const testState = state ? state : testStatusFilter === null || testStatusFilter === void 0 ? void 0 : testStatusFilter[0].value;
        const response = yield call(getClientTests, {
            displayState,
            emailList: getUserNames(testUserFilter),
            labelNames: getLabelSetNames(testLabelFilter),
            page: pageNumber,
            projectId: testProjectFilter === null || testProjectFilter === void 0 ? void 0 : testProjectFilter[0].value,
            size: pageSize,
            state: testState
        });
        return response && response.data ? yield put(clientTasksSuccess(response.data)) : undefined;
    }
    catch (e) {
        yield put(clientTasksFailure(getResponseErrorData(e)));
    }
}
function* refreshTestsLabelsFlow() {
    try {
        const { data: labelList } = yield call(getTestsLabelsByQuery, "");
        yield put(refreshTestsLabelsSuccess(prepareLabels(labelList)));
    }
    catch (e) {
        yield put(refreshTestsLabelsFailure(getResponseErrorData(e)));
    }
}
function* refreshTestsUsersFlow() {
    try {
        const { data: userList } = yield call(getTestUserList);
        yield put(refreshTestsUsersSuccess(prepareUserList(userList)));
    }
    catch (e) {
        yield put(refreshTestsUsersFailure(getResponseErrorData(e)));
    }
}
function* checkRunningTestsDataChanging(runningTestsData) {
    try {
        const prevRunningTestsCount = yield select(getPreviousRunningTestsCount);
        return Array.isArray(runningTestsData) &&
            (runningTestsData.length > 0 || prevRunningTestsCount !== runningTestsData.length)
            ? yield* tasksFlow()
            : undefined;
    }
    catch (e) {
        return;
    }
}
function* getTestRunsDataFlow() {
    var _a, _b;
    try {
        const { runningTestsResponse, stopAction } = yield race({
            runningTestsResponse: call(getClientTests, { displayState: getTestRunningDisplayStatesString() }),
            stopAction: take(clientActions.getTestRunsDataWithSocketRequestStop)
        });
        const isContinuous = stopAction ? false : true;
        const runningTestsData = (_b = (_a = runningTestsResponse === null || runningTestsResponse === void 0 ? void 0 : runningTestsResponse.data) === null || _a === void 0 ? void 0 : _a.content) !== null && _b !== void 0 ? _b : [];
        yield* checkRunningTestsDataChanging(runningTestsData);
        yield put(setRunningTestsData(runningTestsData));
        return { isContinuous };
    }
    catch (e) {
        return yield put(setIsRunningTestsLoading(false));
    }
}
export function* refreshTestRunsDataFlow() {
    try {
        yield* loopWithStopAction({
            delayTime: TEST_RUNS_TIMEOUT_MS,
            loopFunc: getTestRunsDataFlow,
            maxCount: TEST_RUNS_MAX_REQUEST_COUNT,
            stopAction: clientActions.getTestRunsDataWithSocketRequestStop
        });
    }
    catch (e) {
        yield put(setIsRunningTestsLoading(false));
    }
}
function* initClientTestsFlow() {
    try {
        yield put(showLoader());
        yield put(setCurrentPage(initialState.currentPage));
        yield* tasksFlow();
        yield* clientTestProjectsFlow();
        yield* refreshTestsLabelsFlow();
        yield* refreshTestsUsersFlow();
        yield* refreshTestRunsDataFlow();
    }
    catch (e) {
        console.error(e);
    }
}
export function* selectClientTestsFlow(action) {
    try {
        const toggledTask = action && action.payload ? action.payload : {};
        const selectedClientTests = yield select(getSelectedClientTests);
        const selectedClientTestIds = yield select(getSelectedClientTestIds);
        const newSelectedTasks = selectedClientTestIds.includes(toggledTask.id)
            ? selectedClientTests.filter(({ id }) => id !== toggledTask.id)
            : [
                ...selectedClientTests,
                {
                    id: toggledTask.id,
                    startDate: toggledTask.startDate,
                    endDate: toggledTask.endDate
                }
            ];
        yield put(selectClientTasks(newSelectedTasks));
    }
    catch (e) {
        console.error(e);
    }
}
function* redirectToGrafanaUrl(redirectUrl) {
    yield put(hideLoader());
    window.open(redirectUrl);
}
export function* testComparing() {
    try {
        yield put(showLoader());
        const selectedClientTests = yield select(getSelectedClientTests);
        const firstTest = Array.isArray(selectedClientTests) ? selectedClientTests[0] : {};
        const secondTest = Array.isArray(selectedClientTests) ? selectedClientTests[1] : {};
        const { endDate: endDate1, id: id1, startDate: startDate1 } = firstTest || {};
        const { endDate: endDate2, id: id2, startDate: startDate2 } = secondTest || {};
        const firstTestEndTime = endDate1 ? endDate1 : getFormattedUTCDate();
        const secondTestEndTime = endDate2 ? endDate2 : getFormattedUTCDate();
        const response = startDate1 && id1 && startDate2 && id2
            ? yield call(getComparingTestsGrafanaUrl, {
                firstTestId: id1,
                firstTestStartTime: startDate1,
                firstTestEndTime,
                secondTestId: id2,
                secondTestStartTime: startDate2,
                secondTestEndTime
            })
            : null;
        const { redirectUrl } = response && response.data ? response.data : {};
        return redirectUrl ? yield* redirectToGrafanaUrl(redirectUrl) : yield put(genCompareUrlTestsFailure());
    }
    catch (e) {
        yield put(genCompareUrlTestsFailure(getResponseErrorData(e)));
    }
}
export function* allTestsFlow() {
    yield takeEvery(changeClientTasksFiltersRequest, clientTasksFiltersFlow);
    yield takeEvery(clientTasksRequest, tasksFlow);
    yield takeEvery(initClientTests, initClientTestsFlow);
    yield takeEvery(toggleTest, selectClientTestsFlow);
}
