var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { getValueByPath, useIntl } from "@boomq/utils";
import { FILTER_SELECT_PLACEHOLDER } from "./intl";
import { AssertionResponseBodyTemplate } from "../../../../components/account/TestProject/AssertionResponseBodyTemplate";
import { getFormattedAssertionFilterItems, getFormattedAssertionResponseConditionItems } from "../../../../helpers/assertion";
import { formatIntlMessage } from "../../../../helpers/intl";
export const AssertionResponseBodyTemplateContainer = (_a) => {
    var { children } = _a, restProps = __rest(_a, ["children"]);
    const { formatMessage } = useIntl();
    const changeFilterHandler = ({ value }) => restProps.onChangeField({
        id: getValueByPath(restProps.assertion, "id"),
        field: "filter",
        value
    });
    const filterSelectPlaceholder = formatIntlMessage(FILTER_SELECT_PLACEHOLDER, formatMessage);
    const formattedAssertionFilterItems = getFormattedAssertionFilterItems(formatMessage);
    const formattedAssertionConditionItems = getFormattedAssertionResponseConditionItems(formatMessage);
    return (React.createElement(AssertionResponseBodyTemplate, Object.assign({}, restProps, { assertionConditionItems: formattedAssertionConditionItems, assertionFilterItems: formattedAssertionFilterItems, filterSelectPlaceholder: filterSelectPlaceholder, onChangeFilter: changeFilterHandler })));
};
