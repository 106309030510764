import { Button, Color, IconCancelCircle, Tree } from "@boomq/uikit";
import styled from "styled-components";
import { ActionsPanel, BackButton, BlockHeaderWithMarginTop } from "../../../styled/Common";
export const EditorActionsPanel = styled(ActionsPanel) `
    margin-left: 8px;
    position: fixed;
    width: calc(100% - 380px);
`;
export const DroppableElementsArea = styled.div ``;
export const DroppableTransactionsArea = styled.div `
    flex: auto;
`;
export const DropPointTemplate = styled.span `
    height: 46px;
    position: absolute;
    top: 16px;
    width: 100%;
`;
export const EditorWrapper = styled.div `
    display: flex;
    min-height: 100%;
    overflow: auto;
    .p-tree-container {
        display: flex;
        flex-direction: column;
        min-height: 100%;
        padding-bottom: 90px;
    }
    .p-treenode-droppoint.last {
        flex: auto;
    }
`;
export const StyledBackButton = styled(BackButton) `
    margin-right: 12px;
`;
export const StyledButtonWithMargin = styled(Button) `
    margin-right: 16px;
`;
export const StyledIconCancelCircle = styled(IconCancelCircle) `
    fill: ${Color.lightGrey95};
    height: 38px;
    width: 38px;
`;
export const StyledTree = styled(Tree) `
    flex: auto;
`;
export const ThreadGroupDataBlock = styled.div `
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    margin-right: 24px;
    width: 100%;
`;
export const ThreadGroupDataBlockHeader = styled(BlockHeaderWithMarginTop) `
    margin-left: 8px;
`;
