export const COMMENT_TITLE = {
    defaultMessage: "Comment",
    id: "account.test.details.comment"
};
export const NO_COMMENT_MESSAGE = {
    defaultMessage: "No comment",
    id: "account.empty.comment"
};
export const NO_DATA_MESSAGE = {
    defaultMessage: "no data yet",
    id: "account.all.plate.no.data"
};
export const TIME_TOOLTIP_TEXT = {
    defaultMessage: "Total test duration",
    id: "account.test.details.time.tooltip"
};
