var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { forwardRef } from "react";
import { stopEvent } from "@boomq/utils";
import { Switcher, SwitcherDirection, Unit } from "@boomq/uikit";
import { Action, Actions, LoadProfileLabel, PercentInput, percentInputTheme, StyledIconArrowDown, switcherTheme, ThreadGroupContent, ThreadGroupInfo, VUsersValue } from "./styled";
import { EditableTitle } from "../../../common/EditableTitle";
import { ProfileDistribution } from "../../../../models/testProject";
const renderLoadProfile = (props) => props.profileDistribution === ProfileDistribution.Users ? (React.createElement(LoadProfileLabel, { onClick: props.onToggleLoadProfileSettings },
    props.loadProfileLabel,
    " ",
    React.createElement(StyledIconArrowDown, { isexpanded: String(props.isExpanded) }))) : (React.createElement(React.Fragment, null));
const renderProfileDistributionValue = (props) => props.profileDistribution === ProfileDistribution.Users ? (React.createElement(VUsersValue, null,
    props.users,
    " ",
    React.createElement(Unit, { title: props.unitVU }, props.unitVU))) : (React.createElement(PercentInput, { decimalCount: 2, enabled: props.enabled, isPositive: true, min: 0, onBlur: props.onChangePercent, onClick: props.onClick, theme: percentInputTheme, unit: props.percInputUnit, value: props.perc }));
const renderActions = (props) => props.threadGroupActions.map((threadGroupAction) => {
    const clickHandler = (event) => {
        stopEvent(event);
        props.onActionClick(threadGroupAction.actionName);
    };
    return (React.createElement(Action, { key: threadGroupAction.actionName, onClick: clickHandler },
        React.createElement(threadGroupAction.icon, { title: threadGroupAction.title })));
});
const CommonThreadGroup = (_a, _ref) => {
    var { children, enabled, isExpanded, onActionClick, onClick, onClickThreadGroup, onToggleEnabled, onToggleLoadProfileSettings, validateNameFunc } = _a, restProps = __rest(_a, ["children", "enabled", "isExpanded", "onActionClick", "onClick", "onClickThreadGroup", "onToggleEnabled", "onToggleLoadProfileSettings", "validateNameFunc"]);
    return (React.createElement(ThreadGroupContent, { disabled: !enabled, ref: _ref, onClick: onClickThreadGroup },
        React.createElement(Switcher, { direction: SwitcherDirection.Vertical, defaultChecked: enabled, name: "enabled", onClick: onToggleEnabled, theme: switcherTheme }),
        renderProfileDistributionValue(Object.assign(Object.assign({}, restProps), { enabled, onClick })),
        React.createElement(ThreadGroupInfo, { disabled: !enabled },
            React.createElement(EditableTitle, { key: "name" + restProps.id, value: restProps.name, onChange: restProps.onChangeName, onClick: onClick, validateFunc: validateNameFunc }),
            children,
            renderLoadProfile(Object.assign(Object.assign({}, restProps), { isExpanded, onToggleLoadProfileSettings }))),
        React.createElement(Actions, { disabled: !enabled }, renderActions(Object.assign(Object.assign({}, restProps), { onActionClick })))));
};
export default forwardRef(CommonThreadGroup);
