import { connect } from "react-redux";
import { getValueByPath } from "@boomq/utils";
import {
    getIsAuthorize,
    getLoadProfileByGroupId,
    getSiteModal,
    newRequestChangeParams,
    newTestActions
} from "@boomq/common";
import { UltimateThreadGroup } from "../../../../components/account/TestProject/UltimateThreadGroup";
import { HashMap } from "../../../../helpers/types";

const {
    addStepInGroup,
    changeJmxLoadProfileSetting,
    changeJmxLoadProfileChartData,
    changeRequestGroup,
    copyStepInGroup,
    deleteStepInGroup,
    toggleEnabledGroup
} = newTestActions;

interface Props {
    requestGroup: HashMap<any>;
}

const getProps = (state, ownProps): HashMap<any> => {
    const isAuthorized = getIsAuthorize(state);

    return {
        isAuthorized,
        loadProfile: getLoadProfileByGroupId(state, getValueByPath(ownProps.requestGroup, "id")),
        settings: ownProps.testSettings ? ownProps.testSettings.JMX : []
    };
};

const connector = connect((state, ownProps: Props) => getProps(state, ownProps), {
    addStepInGroup,
    onChangeLoadProfileChartData: changeJmxLoadProfileChartData,
    onChangeLoadProfileSetting: changeJmxLoadProfileSetting,
    changeRequestGroup,
    copyStepInGroup,
    deleteStepInGroup,
    newRequestChangeParams,
    getSiteModal,
    toggleEnabledGroup
});

export default connector(UltimateThreadGroup);
