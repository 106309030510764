import { handleActions } from "redux-actions";
import authorizeActions from "../actions/authorize";
import { setCurrentPage, setFilterLabels, setLabelList, setTestProjectsSearchedLabels, testProjectsSuccess } from "../actions/testProjects";
import { linkSettingsSetToTestProjectInListSuccess, unlinkSettingsSetForTestProjectInListSuccess } from "../actions/testProjectsSettingsSetSidebar";
const { logout } = authorizeActions;
export const defaultTestProjectsPageSize = 9;
export const initialState = {
    content: [],
    currentPage: 0,
    filterLabels: [],
    labelList: [],
    numberOfElements: 0,
    size: defaultTestProjectsPageSize,
    totalElements: 0,
    totalPages: 0
};
export default handleActions({
    [logout]: () => (Object.assign({}, initialState)),
    [linkSettingsSetToTestProjectInListSuccess]: (state, action) => {
        const { projectId, settingsId, version } = action.payload ? action.payload : {};
        return Object.assign(Object.assign({}, state), { content: state.content.map((project) => project.id === projectId ? Object.assign(Object.assign({}, project), { settingsId, version }) : project) });
    },
    [setCurrentPage]: (state, action) => (Object.assign(Object.assign({}, state), { currentPage: Number.isInteger(action.payload) ? action.payload : 0 })),
    [setFilterLabels]: (state, action) => (Object.assign(Object.assign({}, state), { filterLabels: action && Array.isArray(action.payload) ? action.payload : initialState.filterLabels })),
    [setLabelList]: (state, action) => (Object.assign(Object.assign({}, state), { labelList: action && Array.isArray(action.payload) ? action.payload : initialState.labelList })),
    [setTestProjectsSearchedLabels]: (state, action) => {
        const { id, searchedLabels } = action && action.payload ? action.payload : {};
        return Object.assign(Object.assign({}, state), { content: state.content.map((testProject) => testProject.id === id ? Object.assign(Object.assign({}, testProject), { searchedLabels }) : testProject) });
    },
    [testProjectsSuccess]: (state, action) => (Object.assign(Object.assign({}, state), action.payload)),
    [unlinkSettingsSetForTestProjectInListSuccess]: (state, action) => {
        const { projectId, version } = action.payload ? action.payload : {};
        return Object.assign(Object.assign({}, state), { content: state.content.map((project) => project.id === projectId ? Object.assign(Object.assign({}, project), { settingsId: undefined, version }) : project) });
    }
}, initialState);
const getStateParam = (state, paramName) => state && state.testProjects ? state.testProjects[paramName] : initialState[paramName];
export const getCurrentPage = (state) => getStateParam(state, "currentPage");
export const getCurrentPageElements = (state) => getStateParam(state, "numberOfElements");
export const getPrevPage = (state) => {
    const currentPage = getCurrentPage(state);
    return currentPage > 0 ? currentPage - 1 : currentPage;
};
export const getTestProjects = (state) => getStateParam(state, "content");
export const getTestProjectById = (state, id) => getTestProjects(state).find((testProject) => testProject.id === id);
export const getTestProjectsFilterLabels = (state) => getStateParam(state, "filterLabels");
export const getTestProjectsLabelList = (state) => getStateParam(state, "labelList");
export const getTestProjectsCount = (state) => getStateParam(state, "totalElements");
export const getTestProjectsPagesCount = (state) => getStateParam(state, "totalPages");
