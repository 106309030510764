var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { Component } from "react";
import { deepCompare, deepCompareArrays, excludeArrayFromArray, isEmpty, isEmptyArray, stopEvent } from "@boomq/utils";
import { DEFAULT_OPTION_LI_MAX_COUNT, DEFAULT_TEXT, DEFAULT_TEXT_ATTR_NAME, DEFAULT_TITLE_ATTR_NAME, DEFAULT_VALUE_ATTR_NAME, OPTION_LI_HEIGHT } from "./constants";
import { SelectItemType } from "./enums";
import { CheckBoxSection, CheckBoxInput, ComboBox, DefaultArrowIcon, DefaultClearIcon, DefaultComboBoxBody, ItemText, OptionLi, OptionUl, StyledIconErrorBlock } from "./styled";
import { InputLabel, InputLabelText } from "../styled/Input";
import { withError } from "../withError";
import { IconError } from "../Input/styled";
import { Popper } from "../Popper";
import { Tooltip } from "../Tooltip";
import { SelectionType } from "../../helpers/types";
const getIsPlaceholderByParams = (selectedItemIds, placeholder) => isEmptyArray(selectedItemIds) && placeholder;
const getItemById = (items, id) => (items || []).find((item) => item.id === id) || {};
const getMaxItemCount = (itemsCount) => itemsCount + 1 <= DEFAULT_OPTION_LI_MAX_COUNT ? itemsCount + 1 : DEFAULT_OPTION_LI_MAX_COUNT;
class CustomSelect extends Component {
    constructor() {
        super(...arguments);
        this.defaultBodyTemplate = ({ error, items, selectedItemIds, disabled, isError, isOpen, isShowClear, placeholder }) => (React.createElement(React.Fragment, null,
            React.createElement(DefaultComboBoxBody, { disabled: disabled, isEmptyItems: isEmpty(items), isError: isError, isopen: isOpen, isPlaceholder: getIsPlaceholderByParams(selectedItemIds, placeholder), isShowClear: isShowClear, title: this.getComboBoxBodyTitle() }, isEmptyArray(selectedItemIds)
                ? this.getFormatMessage(placeholder)
                : this.getComboBoxBodyText(items, selectedItemIds)),
            isShowClear && React.createElement(DefaultClearIcon, { size: "sm", onClick: this.clearComboBoxHandler }),
            isError && (React.createElement(Tooltip, { title: error },
                React.createElement(StyledIconErrorBlock, { isShowClear: isShowClear },
                    React.createElement(IconError, null)))),
            React.createElement(DefaultArrowIcon, { isopen: isOpen })));
        this.getTextAttrName = () => this.props.textAttrName || DEFAULT_TEXT_ATTR_NAME;
        this.getTitleAttrName = () => this.props.titleAttrName || DEFAULT_TITLE_ATTR_NAME;
        this.getValueAttrName = () => this.props.valueAttrName || DEFAULT_VALUE_ATTR_NAME;
        this.getFormatMessage = (message) => typeof this.props.intlFormatFunc === "function" && message && message.id
            ? this.props.intlFormatFunc(message)
            : message;
        this.checkAutoSelectItem = (autoSelect, items) => autoSelect && Array.isArray(items) && items.length === 1 ? this.selectSingleItem(items[0]) : undefined;
        this.getInitialState = ({ items, value, defaultValue }) => {
            const defaultItem = this.getItemByValue(items, defaultValue);
            const defaultItemIds = defaultItem && !isEmpty(defaultItem.id) ? [defaultItem.id] : [];
            const valueItemIds = Array.isArray(value)
                ? value.reduce((res, valueItem) => (valueItem && !isEmpty(valueItem.id) ? [...res, valueItem.id] : res), [])
                : [];
            return isEmptyArray(valueItemIds) ? defaultItemIds : valueItemIds;
        };
        this.getItemByValue = (items, value) => value && Array.isArray(items) ? items.find((item) => item[this.getValueAttrName()] === value) : null;
        this.getText = (item) => {
            const textAttrName = this.getTextAttrName();
            const textValue = item && item[textAttrName] ? item[textAttrName] : DEFAULT_TEXT;
            return typeof item === "string" ? item : this.getFormatMessage(textValue);
        };
        this.getTitle = (item) => {
            const titleAttrName = this.getTitleAttrName();
            const titleValue = item && item[titleAttrName] ? item[titleAttrName] : this.getText(item);
            return typeof item === "string" ? item : this.getFormatMessage(titleValue);
        };
        this.state = {
            isOpen: false,
            maxItemCount: this.props.maxItemCount || DEFAULT_OPTION_LI_MAX_COUNT,
            openTop: this.props.openTop,
            optionListWidth: 0,
            selectedItemIds: this.getInitialState(this.props)
        };
        this.isNeedRefreshSelectedItemIds = (nextProps, props) => {
            const res = nextProps.value !== props.value &&
                Array.isArray(nextProps.value) &&
                !deepCompare(nextProps.value, props.value);
            return res ? res : nextProps.defaultValue !== props.defaultValue;
        };
        this.isNeedRefreshItems = (nextProps, props) => {
            const res = nextProps.items !== props.items && !deepCompare(nextProps.items, props.items);
            return res ? res : nextProps.defaultValue !== props.defaultValue;
        };
        this.isValueChanged = (selectedItemIds) => !deepCompareArrays(selectedItemIds, this.getInitialState(this.props));
        this.maxItemCountCalculationByPosition = (bottomHeight, topHeight, maxItemsHeight) => {
            const height = this.state.openTop ? topHeight : bottomHeight;
            return height > 0 && height < maxItemsHeight
                ? this.setState({ maxItemCount: Math.floor(height / OPTION_LI_HEIGHT) })
                : undefined;
        };
        this.maxItemCountCalculationByMaxHeight = (topHeight, bottomHeight) => {
            const maxHeight = topHeight > bottomHeight ? topHeight : bottomHeight;
            this.setState({ maxItemCount: Math.floor(maxHeight / OPTION_LI_HEIGHT) });
        };
        this.setDropdownBottomPosition = (bottomHeight) => {
            this.setState({ openTop: false });
            this.maxItemCountCalculationByMaxHeight(0, bottomHeight);
        };
        this.setDropdownAutoPositionByParams = (bottomHeight, topHeight, maxItemsHeight) => {
            const minItemsHeight = (this.props.dropdownAutoPositionMinItemCount || 0) * OPTION_LI_HEIGHT;
            return minItemsHeight > 0 && bottomHeight > minItemsHeight
                ? this.setDropdownBottomPosition(bottomHeight)
                : this.setDropdownAutoPositionByMaxHeight(bottomHeight, topHeight, maxItemsHeight);
        };
        this.setDropdownAutoPositionByMaxHeight = (bottomHeight, topHeight, maxItemsHeight) => {
            const isOpenBottom = bottomHeight > maxItemsHeight;
            const isOpenTop = topHeight > maxItemsHeight ? true : topHeight > bottomHeight;
            this.setState({ openTop: isOpenBottom ? false : isOpenTop });
            return topHeight < maxItemsHeight && bottomHeight < maxItemsHeight
                ? this.maxItemCountCalculationByMaxHeight(topHeight, bottomHeight)
                : undefined;
        };
        this.maxHeightListCalculation = () => {
            const section = document.querySelector("#root");
            const { offsetTop = 0, offsetBottom = 0 } = this.props.position || {};
            const sectionHeight = section ? section.clientHeight - offsetBottom : 0;
            const { bottom, top } = this.customSelectRef.getBoundingClientRect();
            const bottomHeight = sectionHeight - bottom;
            const topHeight = top - offsetTop;
            const maxItemsHeight = this.state.maxItemCount * OPTION_LI_HEIGHT;
            return this.props.isAutoPosition
                ? this.setDropdownAutoPositionByParams(bottomHeight, topHeight, maxItemsHeight)
                : this.maxItemCountCalculationByPosition(bottomHeight, topHeight, maxItemsHeight);
        };
        this.maxHeightListCalculationByItemsCount = (itemsCount) => {
            this.setState({
                maxItemCount: getMaxItemCount(itemsCount)
            });
            this.maxHeightListCalculation();
        };
        this.refreshSelectedItemIds = ({ items, value, defaultValue }) => this.setSelectedItemIds(this.getInitialState({ items, value, defaultValue }));
        this.checkAutoSelectItemByRefreshedItems = (nextProps, props) => this.isNeedRefreshItems(nextProps, props)
            ? this.checkAutoSelectItem(nextProps.autoSelect, nextProps.items)
            : undefined;
        this.getItemText = (item) => this.getFormatMessage((item || {})[this.getTextAttrName()] || "");
        this.getItemTitle = (item) => this.getFormatMessage((item || {})[this.getTitleAttrName()] || "");
        this.getComboBoxBodyText = (items = [], selectedItemIds) => this.getSelectedItems(items, selectedItemIds)
            .map((item) => this.getItemText(item))
            .join(", ");
        this.getComboBoxBodyTitle = () => this.isEmptyComboBoxText()
            ? this.getFormatMessage(this.props.placeholder)
            : this.getSelectedItemsTitle(this.props.items, this.state.selectedItemIds);
        this.getListItem = (item) => typeof this.props.listItemTemplate === "function" ? (this.props.listItemTemplate(item)) : (React.createElement(ItemText, null, this.getText(item)));
        this.getSelectedItemIdsByType = (itemIds, type) => itemIds.filter((itemId) => getItemById(this.props.items, itemId).type === type);
        this.getSelectedItems = (items, selectedItemIds) => Array.isArray(items) ? items.filter((item) => selectedItemIds.includes(item.id)) : [];
        this.getSelectedItemsTitle = (items, selectedItemIds) => this.getSelectedItems(items, selectedItemIds)
            .map((selectedItem) => this.getItemTitle(selectedItem) || this.getItemText(selectedItem))
            .join(", ");
        this.onChangeItems = (items, value) => this.setState({
            selectedItemIds: this.getInitialState({
                items,
                value: null,
                defaultValue: value
            })
        });
        this.isEmptyComboBoxText = () => isEmptyArray(this.state.selectedItemIds);
        this.isExistPlaceholder = () => !isEmpty(this.props.placeholder);
        this.isSelectedItem = (id) => this.state.selectedItemIds.includes(id);
        this.isShowCheckBox = (type) => {
            switch (type) {
                case SelectionType.Multiple: {
                    return true;
                }
                case SelectionType.Combine: {
                    return true;
                }
                default: {
                    return false;
                }
            }
        };
        this.onBlurProp = () => typeof this.props.onBlur === "function" && this.isValueChanged(this.state.selectedItemIds)
            ? this.props.onBlur(this.getSelectedItems(this.props.items, this.state.selectedItemIds))
            : undefined;
        this.onChangeProp = (value) => (typeof this.props.onChange === "function" ? this.props.onChange(value) : undefined);
        this.getSelectedItemIdsByItemType = (selectedItemIds, itemType) => itemType === SelectItemType.Action ? [] : selectedItemIds;
        this.setSelectedItemIds = (value) => this.setState({ selectedItemIds: value });
        this.addSelectedItem = (selectedItemIds, item) => {
            const newSelectedItemIds = this.getSelectedItemIdsByItemType([...selectedItemIds, item.id], item.type);
            this.setSelectedItemIds(newSelectedItemIds);
            return this.isValueChanged(newSelectedItemIds)
                ? this.onChangeProp(this.getSelectedItems(this.props.items, newSelectedItemIds))
                : undefined;
        };
        this.deleteSelectedItem = (selectedItemIds, item) => {
            const newSelectedItemIds = this.getSelectedItemIdsByItemType(excludeArrayFromArray(selectedItemIds, [item.id]), item.type);
            this.setSelectedItemIds(newSelectedItemIds);
            return this.isValueChanged(newSelectedItemIds)
                ? this.onChangeProp(this.getSelectedItems(this.props.items, newSelectedItemIds))
                : undefined;
        };
        this.toggleMultipleItem = (e, item) => {
            const selectedItemIds = this.props.selectionType === SelectionType.Combine
                ? this.getSelectedItemIdsByType(this.state.selectedItemIds, SelectionType.Multiple)
                : this.state.selectedItemIds;
            this.isSelectedItem(item.id)
                ? this.deleteSelectedItem(selectedItemIds, item)
                : this.addSelectedItem(selectedItemIds, item);
        };
        this.selectSingleItem = (item) => {
            const newSelectedItemIds = this.getSelectedItemIdsByItemType([item.id], item.type);
            this.setState({ isOpen: false });
            this.setSelectedItemIds(newSelectedItemIds);
            return item.type === SelectItemType.Action || this.isValueChanged(newSelectedItemIds)
                ? this.onChangeProp(item)
                : undefined;
        };
        this.selectCombineItem = (e, item) => item.type === SelectionType.Multiple ? this.toggleMultipleItem(e, item) : this.selectSingleItem(item);
        this.selectItem = (e, item) => {
            e.stopPropagation();
            e.preventDefault();
            typeof this.props.hideError === "function" && this.props.hideError();
            switch (this.props.selectionType) {
                case SelectionType.Multiple: {
                    return this.toggleMultipleItem(e, item);
                }
                case SelectionType.Combine: {
                    return this.selectCombineItem(e, item);
                }
                default: {
                    return this.selectSingleItem(item);
                }
            }
        };
        this.onClearProp = () => typeof this.props.onClear === "function" && this.props.onClear();
        this.onCloseItemListProp = () => {
            typeof this.props.onCloseItemList === "function" && this.props.onCloseItemList();
            this.onBlurProp();
        };
        this.openItemList = () => {
            const { width } = this.customSelectRef.getBoundingClientRect();
            this.setState({ optionListWidth: width });
            return typeof this.props.onOpenItemList === "function" && this.props.onOpenItemList();
        };
        this.clearComboBoxHandler = (e) => {
            stopEvent(e);
            this.setState({ isOpen: false, selectedItemIds: [] });
            this.onClearProp();
        };
        this.toggleHandler = (e) => {
            !this.state.isOpen && typeof this.props.showError === "function" && this.props.showError(e);
            !this.state.isOpen && this.maxHeightListCalculation();
            this.setState({ isOpen: !this.state.isOpen });
            return this.state.isOpen ? this.onCloseItemListProp() : this.openItemList();
        };
        this.outSideClick = () => {
            this.setState({ isOpen: false });
            this.onCloseItemListProp();
            return typeof this.props.hideError === "function" ? this.props.hideError() : undefined;
        };
        this.outSideClickHandler = (e) => this.state.isOpen &&
            this.customSelectRef &&
            this.customSelectItemListRef &&
            !this.customSelectRef.contains(e.target) &&
            !this.customSelectItemListRef.contains(e.target)
            ? this.outSideClick(e)
            : undefined;
    }
    componentDidMount() {
        this.maxHeightListCalculation();
        this.checkAutoSelectItem(this.props.autoSelect, this.props.items);
    }
    UNSAFE_componentWillMount() {
        document.addEventListener("click", this.outSideClickHandler, true);
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { items } = nextProps;
        const { items: propItems } = this.props;
        propItems.length !== items.length && this.maxHeightListCalculationByItemsCount(items.length);
        this.isNeedRefreshItems(nextProps, this.props) && this.refreshSelectedItemIds(nextProps);
        this.isNeedRefreshSelectedItemIds(nextProps, this.props) && this.refreshSelectedItemIds(nextProps);
        this.checkAutoSelectItemByRefreshedItems(nextProps, this.props);
    }
    componentWillUnmount() {
        document.removeEventListener("click", this.outSideClickHandler, false);
    }
    render() {
        const _a = this.props, { bodyTemplate, disabled, isPlaceholder, isShowClear, items, label, onClear, theme, themeBody, themeList, themeListItem } = _a, restProps = __rest(_a, ["bodyTemplate", "disabled", "isPlaceholder", "isShowClear", "items", "label", "onClear", "theme", "themeBody", "themeList", "themeListItem"]);
        const isOpenFlag = this.state.isOpen ? "true" : "";
        return (React.createElement(React.Fragment, null,
            React.createElement(ComboBox, Object.assign({}, restProps, { disabled: disabled, isEmptyItems: isEmpty(items), isPlaceholder: isPlaceholder, isopen: isOpenFlag, onClick: this.toggleHandler, ref: (node) => (this.customSelectRef = node), theme: theme }),
                bodyTemplate
                    ? bodyTemplate(Object.assign(Object.assign({}, this.props), { isOpen: isOpenFlag, onClear: this.clearComboBoxHandler, selectedItems: this.getSelectedItems(this.props.items, this.state.selectedItemIds) }))
                    : this.defaultBodyTemplate(Object.assign(Object.assign({}, this.props), { isOpen: isOpenFlag, isPlaceholder: this.isEmptyComboBoxText() && this.isExistPlaceholder(), isShowClear, selectedItemIds: this.state.selectedItemIds, theme: themeBody })),
                !isEmpty(label) && (React.createElement(InputLabel, null,
                    React.createElement(InputLabelText, null, label)))),
            React.createElement(Popper, { placement: this.state.openTop ? "top-start" : "bottom-start", anchorEl: this.customSelectRef, open: isOpenFlag ? true : false },
                React.createElement(OptionUl, { isopen: isOpenFlag, maxItemCount: this.state.maxItemCount, theme: themeList, openTop: this.state.openTop, ref: (node) => (this.customSelectItemListRef = node), width: this.state.optionListWidth }, Array.isArray(items) ? (items.map((item) => (React.createElement(OptionLi, { key: item.id, onClick: (e) => this.selectItem(e, item), openTop: this.state.openTop, title: this.getTitle(item), theme: themeListItem },
                    this.isShowCheckBox(this.props.selectionType) && (React.createElement(CheckBoxSection, null,
                        React.createElement(CheckBoxInput, { checked: this.isSelectedItem(item.id), readOnly: true, type: "checkbox" }))),
                    item.component ? (React.createElement(item.component.element, Object.assign({}, item.component.props))) : (this.getListItem(item)))))) : (React.createElement(React.Fragment, null))))));
    }
}
export default withError(CustomSelect);
