import { put, takeEvery } from "redux-saga/effects";
import {
    JMXTimersSettingsFlow,
    getTestSettingsFlow,
    hideTestProjectNotification,
    loadTypeSettingsChartGenerationFlow,
    newTestActions,
    requestTimersSettingsNotification,
    showTestProjectNotification,
    hideLoader
} from "@boomq/common";
import store from "../index";
import { checkRunnerResourcesFlow } from "./runnerResources";

const { applyJmxTimersSettings, initLoadTypeSettings, onAuthorizedNewTest, onAuthorizedTestProject } = newTestActions;

function* applyJmxTimersSettingsFlow(action) {
    const modifiedTimersCount = yield* JMXTimersSettingsFlow(action);

    modifiedTimersCount > 0 &&
        (yield put(
            showTestProjectNotification(
                requestTimersSettingsNotification({
                    action: () => store.dispatch({ type: hideTestProjectNotification.toString() }),
                    timersCount: modifiedTimersCount
                })
            )
        ));
}

function* onAuthorizedNewTestFlow() {
    try {
        yield* getTestSettingsFlow();
        yield* checkRunnerResourcesFlow();
    } catch (e) {
        yield put(hideLoader());
    }
}

export function* newTestFlow() {
    yield takeEvery(applyJmxTimersSettings, applyJmxTimersSettingsFlow);
    yield takeEvery(initLoadTypeSettings, loadTypeSettingsChartGenerationFlow);
    yield takeEvery(onAuthorizedNewTest, onAuthorizedNewTestFlow);
    yield takeEvery(onAuthorizedTestProject, onAuthorizedNewTestFlow);
}
