import React, { FC, useEffect } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import {
    // CommonTeamPageProps,
    // CommonTeamPageHandlers,
    getIsAuthorize,
    getRouteTeamId,
    getTeamPermissionList,
    getTeamTemplateByRolesAndPermissions,
    isExistTeamAdminPermission,
    isExistTeamManageUsersPermission,
    teamRequest,
    teamSavingRequest,
    TEAM_ID_PARAM_NAME
} from "@boomq/common";
import { TeamMembersContainer } from "../../../account/Team/TeamMembers";

const connector = connect(
    (state) => ({
        isAuthorized: getIsAuthorize(state),
        permissionList: getTeamPermissionList(state)
    }),
    {
        onAuthorized: teamRequest,
        onSave: teamSavingRequest
    }
);

const TeamPageContainer: FC<any & RouteComponentProps<{ [TEAM_ID_PARAM_NAME]: string }>> = ({
    match,
    permissionList,
    ...restProps
}: any & RouteComponentProps<{ [TEAM_ID_PARAM_NAME]: string }>): JSX.Element => {
    const teamId = getRouteTeamId(match);

    const checkAuthorization = (): void =>
        restProps.isAuthorized ? restProps.onAuthorized({ id: teamId }) : undefined;

    useEffect(() => {
        checkAuthorization();
    }, [restProps.isAuthorized]);

    const isExistAdminAccess = isExistTeamAdminPermission([], permissionList);
    const isExistManageUsers = isExistTeamManageUsersPermission([], permissionList);

    const TeamTemplateComponent = getTeamTemplateByRolesAndPermissions([], permissionList);

    return (
        <TeamTemplateComponent
            {...restProps}
            isExistAdminAccess={isExistAdminAccess}
            isExistManageUsers={isExistManageUsers}
            teamId={teamId}
        >
            <TeamMembersContainer teamId={teamId} />
        </TeamTemplateComponent>
    );
};

export default connector(TeamPageContainer);
