import React, { useEffect, useRef, useState } from "react";
import { Loader, ScrollContainer, StyledIconLoader } from "./styled";
const getContainerHeight = (scrollContainerRef) => {
    const scrollContainer = scrollContainerRef.current;
    const { height } = scrollContainer && typeof scrollContainer.getBoundingClientRect === "function"
        ? scrollContainer.getBoundingClientRect()
        : 0;
    return height > -1 ? height : 0;
};
const calculateSize = (itemHeight, containerHeight) => {
    const itemsCount = containerHeight / itemHeight;
    return Math.floor(2 * itemsCount);
};
const checkPages = ({ currentPage, totalPages }) => totalPages - currentPage > 1;
const checkScroll = (scrollContainerRef) => {
    const scrollContainer = scrollContainerRef.current;
    return scrollContainer
        ? scrollContainer.scrollTop + getContainerHeight(scrollContainerRef) > 0.7 * scrollContainer.scrollHeight
        : false;
};
export const InfiniteScroll = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const scrollContainerRef = useRef(null);
    const loadItems = (currentPage, size) => {
        setIsLoading(true);
        return props.onLoadItems({ currentPage, size });
    };
    const getItemsByScroll = ({ currentPage, size }) => typeof props.onLoadItems === "function" ? loadItems(currentPage, size) : undefined;
    useEffect(() => {
        const size = calculateSize(props.itemHeight, getContainerHeight(scrollContainerRef));
        getItemsByScroll({ currentPage: 0, size });
    }, []);
    useEffect(() => {
        setIsLoading(false);
    }, [props.currentPage]);
    const scrollHandler = () => !isLoading && checkPages(props) && checkScroll(scrollContainerRef)
        ? getItemsByScroll({ currentPage: props.currentPage + 1, size: props.size })
        : undefined;
    return (React.createElement(ScrollContainer, { ref: scrollContainerRef, onScroll: scrollHandler, theme: props.theme },
        props.children,
        isLoading && (React.createElement(Loader, null,
            React.createElement(StyledIconLoader, null)))));
};
