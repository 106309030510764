import { getUniqueId, getValueByPath, isEmpty } from "@boomq/utils";
import { getTestProjectLoadProfile } from "./loadProfile";
import { getThreadGroupsCommonData, prepareThreadGroupData } from "./threadGroup";
import { generatePreviewData } from "../generators";
import { LoadProfileType } from "../../models/testProject";
import { TestType } from "../types";
import { initScalabilitySettings, initSettings } from "../../models/testProject";
const getThreadGroupDataByLoadProfilePerGroup = (loadProfileType, loadProfileData) => {
    const loadProfile = getTestProjectLoadProfile(loadProfileData);
    const defaultLoadProfile = Object.assign(Object.assign({}, initSettings), initScalabilitySettings);
    return loadProfileType === LoadProfileType.PerGroup
        ? {
            chartData: [{ coords: generatePreviewData(Object.assign(Object.assign({}, loadProfile), { loadType: TestType.Scalability })) }],
            loadProfileSettings: loadProfile
        }
        : {
            chartData: [{ coords: generatePreviewData(Object.assign(Object.assign({}, defaultLoadProfile), { loadType: TestType.Scalability })) }],
            loadProfileSettings: defaultLoadProfile
        };
};
export const getBoomqStableScalabilityThreadGroupData = ({ res, index, group, loadProfileType }) => {
    var _a;
    const { enabled = true, id, loadProfile } = group || {};
    const groupId = isEmpty(id) ? getUniqueId() : String(id);
    const commonParams = getThreadGroupsCommonData({ res, index, group, groupId });
    return Object.assign(Object.assign(Object.assign({}, res), commonParams), { groups: [
            ...res.groups,
            Object.assign(Object.assign(Object.assign({}, prepareThreadGroupData(groupId, index, group)), getThreadGroupDataByLoadProfilePerGroup(loadProfileType, loadProfile)), { perc: getValueByPath(group, "boomqConfiguration.loadProfilePercent", 0), displayTypeName: (_a = group.typeDisplayName) !== null && _a !== void 0 ? _a : "", enabled, type: group.type })
        ] });
};
