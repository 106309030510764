import React from "react";
import { ScrollableTable, Table } from "./styled";
import { DeleteButton, HeaderCell, TableHeadRow, TestNameId } from "../SlaReportTable/styled";
import { TableCellSlaResult } from "../TableCellSlaResult";
import { HintComment } from "../HintComment";
import { IconClose } from "../../../styled/Icons/Close";
import { getColorByName } from "../../../../helpers/color/colorByName";
import { getFullDateLocaleTimeZone } from "../../../../helpers/date/localeTimeZone";
import { getSlaUnitTextByRestrictionType } from "../../../../helpers/sla/slaUnit";
import { generateSlaTypeIdFromData } from "../../../../helpers/slaReport/slaTypeIdFromData";
import { SlaReportTableValueType } from "../../../../helpers/types";
const isBaselineTest = (baselineTestId, testId, valueType) => baselineTestId === testId && valueType && valueType.value === SlaReportTableValueType.BaselineDiff;
export const SlaReportTableScrollableCells = ({ baselineTestId, forwardedRef, intl, isShowAbsoluteValue, onDeleteTest, tableData, tableTestList, testDuration }) => {
    const { diffType, rowList, valueType } = tableData || {};
    const formatMessage = intl && typeof intl.formatMessage === "function" ? intl.formatMessage : () => undefined;
    const deleteTableTestHandler = (testId) => onDeleteTest(testId);
    const getSlaTypeItemFormattedUnit = (slaType) => formatMessage(getSlaUnitTextByRestrictionType(slaType.type, slaType.restrictionType, "text"));
    return (React.createElement(ScrollableTable, null,
        React.createElement(Table, { ref: forwardedRef },
            React.createElement("thead", null,
                React.createElement(TableHeadRow, null, (tableTestList || []).map((test) => !isBaselineTest(baselineTestId, test.id, valueType) && (React.createElement(HeaderCell, { key: test.id },
                    React.createElement(TestNameId, null,
                        onDeleteTest && (React.createElement(DeleteButton, { onClick: () => deleteTableTestHandler(test.id) },
                            React.createElement(IconClose, { bg: getColorByName("lightBlue"), width: "14" }))),
                        test.id,
                        React.createElement(HintComment, { comment: test.comment })),
                    React.createElement("div", null, getFullDateLocaleTimeZone(test.startDate))))))),
            React.createElement("tbody", null, Array.isArray(rowList) &&
                rowList.map((row) => {
                    const slaType = row.slaType;
                    const slaTypeId = generateSlaTypeIdFromData(slaType);
                    const slaTypeUnit = getSlaTypeItemFormattedUnit(slaType);
                    return (React.createElement("tr", { id: `scrollableTable_${slaTypeId}`, key: slaTypeId }, (row.cellList || []).map((cell) => !isBaselineTest(baselineTestId, cell.testId, valueType) && (React.createElement(TableCellSlaResult, { key: `cell_${slaTypeId}_${cell.testId}`, baselineTestId: baselineTestId, cell: cell, diffType: diffType, isShowAbsoluteValue: isShowAbsoluteValue, intl: intl, slaType: slaType, testDuration: testDuration, unit: slaTypeUnit, valueType: valueType })))));
                })))));
};
