import styled from "styled-components";
export const StyledPageStyle = `
    display: flex;
    flex-direction: column;
    margin-bottom: 90px;
    margin-top: 24px;
`;
export const StyledPageWithPadding = styled.div `
    ${StyledPageStyle}
`;
export const ScrollContainer = styled.div `
    flex: auto;
    overflow: auto;
    padding: 4px 12px 12px;
`;
