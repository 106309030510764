var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { connect } from "react-redux";
import { useIntl } from "@boomq/utils";
import { DEFAULT_SETTINGS_ELEMENTS_BLOCK_TITLE, IMPORT_DATA_ELEMENTS_BLOCK_TITLE, PARAMETERS_ELEMENTS_BLOCK_TITLE } from "./intl";
import { TestProjectLeftSidebar } from "../../../../components/account/TestProject/TestProjectLeftSidebar";
import { formatIntlMessage } from "../../../../helpers/intl";
import { getImportDataToTestProjectSidebarElements, getRequestParametersSidebarElementsByTestType, getTestProjecDefaultSettingsSidebarElementsByTestType } from "../../../../helpers/testProject";
import { showImportDataToTestProjectForm } from "../../../../redux/actions/importTestProjectData";
import { showRequestParametersForm } from "../../../../redux/actions/requestParameters";
import { showTestProjectDefaultSettingsForm } from "../../../../redux/actions/testProjectDefaultSettings";
import { getTestType } from "../../../../redux/reducers/newTest";
const connector = connect((state) => ({
    testType: getTestType(state)
}), {
    onClickDefaultSettinsElement: showTestProjectDefaultSettingsForm,
    onClickImportDataElement: showImportDataToTestProjectForm,
    onClickParametersElement: showRequestParametersForm
});
const TestProjectLeftSidebarContainer = ({ onClickDefaultSettinsElement, onClickImportDataElement, onClickParametersElement, testType }) => {
    const { formatMessage } = useIntl();
    const defaultSettingsElementsBlockTitle = formatIntlMessage(DEFAULT_SETTINGS_ELEMENTS_BLOCK_TITLE, formatMessage);
    const parametersElementsBlockTitle = formatIntlMessage(PARAMETERS_ELEMENTS_BLOCK_TITLE, formatMessage);
    const importDataElementsBlockTitle = formatIntlMessage(IMPORT_DATA_ELEMENTS_BLOCK_TITLE, formatMessage);
    const formatSidebarElement = (_a) => {
        var { intlName } = _a, restParams = __rest(_a, ["intlName"]);
        return (Object.assign(Object.assign({}, restParams), { name: formatIntlMessage(intlName, formatMessage) }));
    };
    const formattedDefaultSettingsElementItems = getTestProjecDefaultSettingsSidebarElementsByTestType(testType).map(formatSidebarElement);
    const formattedImportDataElementItems = getImportDataToTestProjectSidebarElements(testType).map(formatSidebarElement);
    const formattedRequestParametersElementItems = getRequestParametersSidebarElementsByTestType(testType).map(formatSidebarElement);
    return (React.createElement(TestProjectLeftSidebar, { defaultSettingsElementItems: formattedDefaultSettingsElementItems, defaultSettingsElementsBlockTitle: defaultSettingsElementsBlockTitle, importDataElementItems: formattedImportDataElementItems, importDataElementsBlockTitle: importDataElementsBlockTitle, onClickDefaultSettinsElement: onClickDefaultSettinsElement, onClickImportDataElement: onClickImportDataElement, onClickParametersElement: onClickParametersElement, parametersElementItems: formattedRequestParametersElementItems, parametersElementsBlockTitle: parametersElementsBlockTitle }));
};
export default connector(TestProjectLeftSidebarContainer);
