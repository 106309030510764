var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { connect } from "react-redux";
import { Route, Redirect } from 'react-router-dom';
import { getIsAuthorize } from "../../../redux/reducers/authorizePlate";
const connector = connect(state => ({
    isAuthorized: getIsAuthorize(state)
}), {});
const PublicRoute = (_a) => {
    var { allowed, clientRole, isAuthorized, component: Component, data, to } = _a, rest = __rest(_a, ["allowed", "clientRole", "isAuthorized", "component", "data", "to"]);
    return (React.createElement(Route, Object.assign({}, rest, { render: props => (isAuthorized
            ?
                React.createElement(Redirect, { to: to })
            :
                React.createElement(Component, Object.assign({ data: data }, props))) })));
};
export default connector(PublicRoute);
