import { Block, Color, FontBodyBigMedium } from "@boomq/uikit";
import styled from "styled-components";
const TITLE_BORDER_RADIUS = "12px";
export const Body = styled.div `
    height: 315px;
    max-height: 315px;
    min-height: 315px;
    padding: 24px;
`;
export const BodyModeSwitcherLabel = styled.div `
    ${FontBodyBigMedium}
    color: ${Color.darkGrey5};
    margin-left: 8px;
`;
export const BodyPreviewModeSwitcherWrapper = styled.div `
    align-items: center;
    display: flex;
    flex: auto;
    justify-content: flex-end;
`;
export const RequestResponsePreview = styled.div `
    ${Block}
`;
export const Title = styled.div `
    align-items: center;
    background: ${Color.lightGrey98};
    border-top-left-radius: ${TITLE_BORDER_RADIUS};
    border-top-right-radius: ${TITLE_BORDER_RADIUS};
    color: ${Color.grey50};
    display: flex;
    height: 52px;
    padding-left: 24px;
    padding-right: 24px;
`;
