import { takeEvery, call, put } from "redux-saga/effects";
import { usersRequest, usersSuccess, usersFailure } from "../../actions/admin/users";
import { tasksRequest, tasksSuccess, tasksFailure } from "../../actions/admin/tasks";
import { resourcesRequest, resourcesSuccess, resourcesFailure } from "../../actions/admin/resources";
import { getAdminUsers } from "../../../services/authSrv";
import { getClientTestsByAdmin } from "../../../services/testSrv";
import { getTestRunnerResources } from "../../../services/testRunnerSrv";
function* tasksRequestFlow(action) {
    try {
        const adminTasksResponse = yield call(getClientTestsByAdmin, { page: action.payload.page });
        const { content, number, totalPages } = adminTasksResponse && adminTasksResponse.data ? adminTasksResponse.data : {};
        yield put(tasksSuccess({ currentPage: number, tasks: content, totalPages }));
    }
    catch (e) {
        yield put(tasksFailure(e));
    }
}
function* resourcesRequestFlow() {
    try {
        const testRunnerResourcesResponse = yield call(getTestRunnerResources);
        const { content, number, totalPages } = testRunnerResourcesResponse && testRunnerResourcesResponse.data ? testRunnerResourcesResponse.data : {};
        yield put(resourcesSuccess({ currentPage: number, resources: Array.isArray(content) ? content : [], totalPages }));
    }
    catch (e) {
        yield put(resourcesFailure(e));
    }
}
function* usersRequestFlow(action) {
    try {
        const { data: { content: users, totalPages: userTotalPages } } = yield call(getAdminUsers, action.payload);
        yield put(usersSuccess({ users, totalPages: userTotalPages }));
    }
    catch (e) {
        yield put(usersFailure(e));
    }
}
export function* adminDataFlow() {
    yield takeEvery(usersRequest, usersRequestFlow);
    yield takeEvery(tasksRequest, tasksRequestFlow);
    yield takeEvery(resourcesRequest, resourcesRequestFlow);
}
