import styled, { css } from "styled-components";
import { COMBO_BOX_HEIGHT, DEFAULT_OPTION_UL_MAX_HEIGHT, OPTION_LI_HEIGHT } from "./constants";
import { IconClose, IconDropDownArrowDown } from "../Icons";
import { IconErrorBlock } from "../Input/styled";
import { Color } from "../styled/colorPallete";
import { withBorder, withDisable, withShadow, getZIndex } from "../styled/global";
import { FontBodyMediumMedium } from "../styled/typography";
const getComboBoxColorByIsOpen = (isOpen) => (isOpen ? Color.blue : Color.grey75);
const getColor = (color) => (color ? Color.white : Color.darkGrey5);
export const CheckBoxSection = styled.label `
    cursor: pointer;
    height: 16px;
    margin-right: 7px;
    min-width: 16px;
    width: 16px;
`;
export const CheckBoxInput = styled.input `
    cursor: pointer;
    height: 100%;
    pointer-events: none;
    width: 100%;
`;
export const ComboBox = styled.div `
    ${withDisable};
    cursor: pointer;
    max-height: ${COMBO_BOX_HEIGHT}px;
    min-height: ${COMBO_BOX_HEIGHT}px;
    min-width: 150px;
    opacity: 1;
    position: relative;
    text-align: left;
    transition: all 0.3s;
    ${({ disabled, isEmptyItems }) => !disabled && isEmptyItems
    ? css `
                  pointer-events: none;
              `
    : css ``}
    ${({ theme }) => (theme ? css(theme) : css ``)}
`;
export const ComboBoxBody = styled.div `
    ${FontBodyMediumMedium}
    color: ${({ bgColor, isPlaceholder }) => (isPlaceholder ? Color.grey65 : getColor(bgColor))};
    cursor: pointer;
    overflow: hidden;
    position: relative;
    width: 100%;
    ${({ isopen }) => isopen
    ? css `
                  z-index: ${getZIndex("CustomSelectBody")};
              `
    : css ``}
    ${({ theme }) => (theme ? css(theme) : css ``)};
`;
export const DefaultArrowIcon = styled(IconDropDownArrowDown) `
    fill: ${({ isopen }) => (isopen ? Color.blue : Color.grey75)};
    pointer-events: none;
    position: absolute;
    right: 12px;
    top: 6px;
    transform: rotate(${({ isopen }) => (isopen ? 180 : 0)}deg);
    transition: transform 0.5s;
    z-index: ${({ isopen }) => getZIndex(isopen ? "CustomSelectOpenedIcon" : "CustomSelectClosedIcon")};
`;
export const DefaultClearIcon = styled(IconClose) `
    fill: ${Color.grey75};
    height: 14px;
    position: absolute;
    right: 36px;
    top: 12px;
    width: 14px;
    z-index: ${getZIndex("CustomSelectOpenedIcon")};
`;
const getPaddingRightByParams = (isError, isShowClear) => {
    const paddingRight = isShowClear ? 56 : 36;
    return isError ? paddingRight + 24 : paddingRight;
};
export const DefaultComboBoxBody = styled.div `
    ${FontBodyMediumMedium}
    ${withDisable}
    background: ${({ isEmptyItems }) => (isEmptyItems ? Color.lightGrey98 : Color.white)};
    border: 1px solid ${({ isError, isopen }) => (isError ? Color.red : getComboBoxColorByIsOpen(isopen))};
    border-radius: 12px;
    color: ${({ bgColor, isPlaceholder }) => (isPlaceholder ? Color.grey65 : getColor(bgColor))};
    cursor: pointer;
    max-height: ${COMBO_BOX_HEIGHT}px;
    min-height: ${COMBO_BOX_HEIGHT}px;
    opacity: 1;
    overflow: hidden;
    padding-bottom: 8px;
    padding-left: 12px;
    padding-right: ${({ isError, isShowClear }) => getPaddingRightByParams(isError, isShowClear)}px;
    padding-top: 8px;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    ${({ isopen }) => isopen
    ? css `
                  ${withShadow}
              `
    : css ``}
    ${({ isEmptyItems }) => isEmptyItems
    ? css `
                  pointer-events: none;
              `
    : css ``};
    ${({ theme }) => (theme ? css(theme) : css ``)};
`;
export const StyledIconErrorBlock = styled(IconErrorBlock) `
    height: 100%;
    position: absolute;
    right: ${({ isShowClear }) => (isShowClear ? "46px" : "32px")};
    top: 0;
`;
export const ItemText = styled.div `
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;
export const OptionLi = styled.li `
    ${FontBodyMediumMedium}
    align-items: center;
    border-radius: 12px;
    color: ${Color.darkGrey35};
    cursor: pointer;
    display: flex;
    margin-top: 4px;
    padding: 4px 8px;
    &:hover {
        background: ${Color.lightBlue10};
        color: ${Color.blue};
    }
    ${({ theme }) => (theme ? css(theme) : css ``)}
`;
export const OptionUl = styled.ul `
    background: #fff;
    ${withBorder}
    display: ${({ isopen }) => (isopen ? "flex" : "none")};
    flex-direction: column;
    max-height: ${({ maxItemCount }) => maxItemCount > -1 ? `${OPTION_LI_HEIGHT * maxItemCount}px` : DEFAULT_OPTION_UL_MAX_HEIGHT};
    overflow: auto;
    padding: 0 4px 4px;
    position: absolute;
    ${({ openTop }) => openTop
    ? css `
                  bottom: 4px;
              `
    : css `
                  top: 4px;
              `}
    ${({ width }) => width && width > 0
    ? css `
                  width: ${width}px;
              `
    : css ``}
    z-index: ${getZIndex("CustomSelectUl")};
    ${({ theme }) => (theme ? css(theme) : css ``)}
`;
