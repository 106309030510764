import styled from "styled-components";
import { media } from "../../styled/response";
export const Description = styled.div `
    margin-top: 34px;
`;
export const Plate = styled.div `
    background: #ffffff;
    box-shadow: 0 0 8px rgba(0, 64, 140, 0.15);
    border-radius: 5px;
    padding: 64px 0 49px;
    ${media.tablet} {
        padding: 30px 0;
    }
`;
export const PlateList = styled.ul `
    display: flex;
`;
export const PlateText = styled.div `
    display: flex;
    font-style: normal;
    font-weight: bold;
    justify-content: center;
    line-height: normal;
    font-size: 36px;
    text-align: center;
    color: #4989d6;
    margin-top: 34px;
    ${media.tablet} {
        margin-top: 28px;
    }
`;
export const StyledTimer = styled.div `
    margin-left: 10px;
    position: relative;
    width: 160px;
    p {
        bottom: -33%;
        font-size: 36px;
        left: 0;
        position: absolute;
    }
`;
export const Text = styled.div `
    display: flex;
    font-size: 18px;
`;
export const EstimatedStarTimeText = styled(Text) `
    justify-content: center;
    width: 100%;
`;
