import { isEmpty } from "@boomq/utils";
import { BOOMQ_LOAD_PROFILE_MODEL_SCHEMA_NAME, TEST_PLAN_LOAD_PROFILE_MODEL_SCHEMA_NAME, TIME_COEFFICIENT } from "./constants";
import { convertSecToMs } from "../date";
import { getLoadProfileParams } from "../loadProfile";
import { getTestProjectModelElDefaultValues } from "../testProjectModelSchema";
import { LoadProfileType, ProfileDistribution } from "../../models/testProject";
const getLoadProfileParamsData = (data) => getLoadProfileParams().reduce((res, setting) => (Object.assign(Object.assign({}, res), (data && !isEmpty(data[setting.name])
    ? {
        [setting.name]: Number(setting.type === "time" ? data[setting.name] * TIME_COEFFICIENT : data[setting.name])
    }
    : {}))), {});
const generateLoadProfile = (testProjectData) => {
    const { stepCount = 1, usersPerStep = 1, stepLength = 1, rampUp = 0, rampDown = 0, durationAddedOnLastStep = 0 } = getLoadProfileParamsData(testProjectData);
    return {
        stepCount,
        usersPerStep,
        stepLengthMs: convertSecToMs(stepLength),
        rampUpMs: convertSecToMs(rampUp),
        rampDownMs: convertSecToMs(rampDown),
        durationAddedOnLastStepMs: convertSecToMs(durationAddedOnLastStep)
    };
};
const generateTestPlanLoadProfile = (testProjectData, modelSchema) => ({
    loadProfile: Object.assign(Object.assign(Object.assign({}, getTestProjectModelElDefaultValues(modelSchema, TEST_PLAN_LOAD_PROFILE_MODEL_SCHEMA_NAME)), generateLoadProfile(testProjectData)), { boomqProfileType: testProjectData.testType })
});
const generateBoomqLoadProfile = (testProjectData, modelSchema) => ({
    loadProfile: Object.assign(Object.assign({}, getTestProjectModelElDefaultValues(modelSchema, BOOMQ_LOAD_PROFILE_MODEL_SCHEMA_NAME)), generateLoadProfile(testProjectData))
});
export const generateLoadProfileTypeByDistribution = (profileDistribution) => profileDistribution === ProfileDistribution.Users ? LoadProfileType.PerGroup : LoadProfileType.PerTest;
export const generateTestLoadProfile = (testProjectData, modelSchema) => testProjectData && testProjectData.profileDistribution === ProfileDistribution.Percent
    ? generateTestPlanLoadProfile(testProjectData, modelSchema)
    : {};
export const generateThreadGroupLoadProfile = (profileDistribution, threadGroupData, modelSchema) => threadGroupData && profileDistribution === ProfileDistribution.Users
    ? generateBoomqLoadProfile(threadGroupData.loadProfileSettings, modelSchema)
    : {};
