import { cancel, delay, fork, takeEvery } from "redux-saga/effects";
import { isObject } from "@boomq/utils";
import { DEFAULT_IS_DELAY_BEFORE_START, DEFAULT_ITERATION_MAX_COUNT, DEFAULT_STOP_ACTION, DEFAULT_TIMEOUT_MS } from "./constants";
export function* cancelWorkerSaga(task) {
    yield cancel(task);
}
export function* setDelayByParams(isContinuous, delayTime) {
    return isContinuous ? yield delay(delayTime) : undefined;
}
function* checkIsDelayBeforeStart(isDelayBeforeStart, delayTime) {
    return isDelayBeforeStart ? yield delay(delayTime) : undefined;
}
export function* defaultLoopSaga(params, loopFunc) {
    const { delayTime, isDelayBeforeStart, maxCount } = params;
    yield* checkIsDelayBeforeStart(isDelayBeforeStart, delayTime);
    for (let i = 0; i < maxCount; i++) {
        let { isContinuous = true, data } = yield* loopFunc(params);
        if (!isContinuous) {
            return data;
        }
        yield* setDelayByParams(isContinuous, delayTime);
    }
}
const getParams = (params) => (isObject(params) ? params : {});
export function* loopWithStopAction({ delayTime = DEFAULT_TIMEOUT_MS, isDelayBeforeStart = DEFAULT_IS_DELAY_BEFORE_START, loopFunc = () => { }, loopSaga, maxCount = DEFAULT_ITERATION_MAX_COUNT, params = {}, stopAction = DEFAULT_STOP_ACTION }) {
    const workerTask = loopSaga
        ? yield fork(loopSaga, getParams(params))
        : yield fork(defaultLoopSaga, Object.assign({ delayTime,
            isDelayBeforeStart,
            maxCount }, getParams(params)), loopFunc);
    yield takeEvery(stopAction, cancelWorkerSaga, workerTask);
}
