import React from "react";
import { Fade /*, ProfileSettingsRequests*/ } from "@boomq/common";
import { ProfileSettingsContainer } from "../../../containers/account/TestProject/ProfileSettings";
import { ProfileSettingsJMX } from "../TestProject/ProfileSettingsJMX";
// import { RequestGroupCaptionRequests } from "../TestProject/RequestGroupCaptionRequests";
// import { ImportJmxFile } from "../../../containers/account/TestProject/ImportJmxFile";
import { TestSettingsContainer } from "../../../containers/account/TestProject/TestSettings";
import { testSettings } from "../../../data/content/testSettings";
import { TestType } from "../../../helpers/types";

const renderProfileSettingsByTestType = (testType): JSX.Element => {
    switch (testType) {
        case TestType.JMX: {
            return <ProfileSettingsJMX />;
        }
        default: {
            return <ProfileSettingsContainer />;
        }
    }
};

export const TestProjectSettingsWizard = (props): JSX.Element =>
    props.currentStep === 1 ? (
        renderProfileSettingsByTestType(props.testType)
    ) : (
        <Fade animation="inRight">
            <TestSettingsContainer {...props} testSettings={testSettings} />
        </Fade>
    );
