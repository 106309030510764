import React from "react";
import { FormattedMessage } from "react-intl";
import { SLIDES } from "./constans";
import { CAROUSEL_WELCOME_TEXTS, WELCOME_TEXT, WELCOME_TITLE } from "./intl";
import { Text, Title, SectionWelcome, WrapperContent, SectionCards } from "./styled";
import WelcomeCard from "./WelcomeCard";
import { Carousel } from "../../../common/Carousel";
import { CARDS_CONTENT } from "../../../../models/welcomeCardsData";
export const Welcome = ({ welcomeText = WELCOME_TEXT, onClickhandlerCardButton }) => {
    return (React.createElement(WrapperContent, null,
        React.createElement(SectionWelcome, null,
            React.createElement(Title, null,
                React.createElement(FormattedMessage, Object.assign({}, WELCOME_TITLE))),
            React.createElement(Text, null,
                React.createElement(FormattedMessage, Object.assign({}, welcomeText))),
            React.createElement(Carousel, { slides: SLIDES, texts: CAROUSEL_WELCOME_TEXTS })),
        React.createElement(SectionCards, null, CARDS_CONTENT.map((card, index) => (React.createElement(WelcomeCard, { buttonLabel: card.buttonLabel, icons: card.icons, onClickCardButton: () => onClickhandlerCardButton(index), key: index, text: card.text, title: card.title }))))));
};
