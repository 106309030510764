import { Color, FontBodyBigMedium, IconCheck } from "@boomq/uikit";
import styled, { css } from "styled-components";
export const Button = styled.button `
    ${FontBodyBigMedium}
    align-items: center;
    background: transparent;
    border: none;
    color: ${({ active }) => (active ? Color.darkGrey5 : Color.grey65)};
    display: flex;
    flex: 1;
    justify-content: flex-start;
    outline: none;
    padding-bottom: 12px;
    pointer-events: none;
    position: relative;
    transition: all 0.5s ease;
    &:hover {
        color: ${Color.darkGrey5};
    }
    ${({ active }) => active
    ? css `
                  pointer-events: auto;
                  color: ${Color.darkGrey5};
                  cursor: pointer;
                  &:after {
                      background: ${Color.blue};
                      bottom: 0;
                      content: "";
                      height: 3px;
                      position: absolute;
                      transition: all 0.5s ease;
                      width: 100%;
                  }
              `
    : css ``}
    ${({ checked }) => checked
    ? css `
                  pointer-events: auto;
                  cursor: pointer;
              `
    : css ``}
`;
export const ButtonIconCircle = styled.div `
    align-items: center;
    border: 2px solid;
    border-color: ${({ active }) => (active ? Color.green : Color.darkGrey35)};
    border-radius: 50%;
    display: flex;
    height: 18px;
    margin-right: 8px;
    width: 18px;
`;
export const StyledIconCheck = styled(IconCheck) `
    fill: ${Color.darkGrey5};
    height: 18px
    width: 18px;
`;
export const WizardBlock = styled.div `
    display: flex;
    position: relative;
    &:before {
        background: #e0e0e0;
        bottom: 0;
        content: "";
        height: 3px;
        left: 0;
        position: absolute;
        right: 0;
    }
`;
