var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { InputText } from "@boomq/uikit";
import { RequestNameLabel } from "./styled";
import { RequestNameActions } from "./RequestNameActions";
export const RequestName = (_a) => {
    var { isDisabledInputs, onChange, requestName, requestNameLabel } = _a, restProps = __rest(_a, ["isDisabledInputs", "onChange", "requestName", "requestNameLabel"]);
    return (React.createElement(React.Fragment, null,
        React.createElement(RequestNameLabel, null, requestNameLabel),
        React.createElement(InputText, { enabled: !isDisabledInputs, name: "requestName", onChange: onChange, value: requestName }),
        React.createElement(RequestNameActions, Object.assign({}, restProps))));
};
