import styled, { css } from "styled-components";
import { Color } from "../styled/colorPallete";
export const TabSection = styled.div `
    ${({ theme }) => (theme ? css(theme) : css ``)}
`;
export const TabBodySection = styled.div `
    display: flex;
    flex-direction: column;
`;
export const TabListSection = styled.div `
    border: 2px solid ${Color.lightGrey95};
    border-radius: 14px;
    display: flex;
    padding: 4px;
    ${({ theme }) => (theme ? css(theme) : css ``)}
    label {
        text-align: center;
        input:checked + div {
            background: ${Color.lightGrey95};
            border-radius: 12px;
            color: ${Color.darkGrey5};
        }
        > div:hover {
            color: ${Color.darkGrey5};
        }
    }
`;
