import { getUniqueId } from "../uniqueId";
export const objectToArray = (obj, keyParamName, valueParamName) => obj
    ? Object.keys(obj).reduce((res, key) => [
        ...res,
        {
            id: getUniqueId(),
            [keyParamName]: key,
            [valueParamName]: obj[key]
        }
    ], [])
    : [];
