var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { withRouter } from "react-router-dom";
import { useIntl } from "@boomq/utils";
import { ELEMENTS_BLOCK_TITLE, FLOW_CONTROL_ELEMENTS_BLOCK_TITLE, IMPORT_DATA_ELEMENTS_BLOCK_TITLE } from "./intl";
import { EditorLeftSidebar } from "../../../../components/account/RequestGroupEditor/EditorLeftSidebar";
import { formatIntlMessage } from "../../../../helpers/intl";
import { getThreadGroupEditorElementsWithIntlName, getThreadGroupEditorFlowControlElementsWithIntlName, getThreadGroupEditorImportDataElementsWithIntlName } from "../../../../helpers/threadGroupEditor";
const editorElementItems = getThreadGroupEditorElementsWithIntlName();
const editorFlowControlElementItems = getThreadGroupEditorFlowControlElementsWithIntlName();
const editorImportDataElementItems = getThreadGroupEditorImportDataElementsWithIntlName();
const formatEditorElements = (elements, intlFunc) => (elements || []).map((_a) => {
    var { intlName } = _a, rest = __rest(_a, ["intlName"]);
    return (Object.assign(Object.assign({}, rest), { name: formatIntlMessage(intlName, intlFunc) }));
});
const EditorLeftSidebarContainer = (props) => {
    const { formatMessage } = useIntl();
    const elementsBlockTitle = formatIntlMessage(ELEMENTS_BLOCK_TITLE, formatMessage);
    const flowControlElementsBlockTitle = formatIntlMessage(FLOW_CONTROL_ELEMENTS_BLOCK_TITLE, formatMessage);
    const importDataElementsBlockTitle = formatIntlMessage(IMPORT_DATA_ELEMENTS_BLOCK_TITLE, formatMessage);
    const formattedEditorElementItems = formatEditorElements(editorElementItems, formatMessage);
    const formattedEditorFlowControlElementItems = formatEditorElements(editorFlowControlElementItems, formatMessage);
    const formattedEditorImportDataElementItems = formatEditorElements(editorImportDataElementItems, formatMessage);
    return (React.createElement(EditorLeftSidebar, Object.assign({}, props, { editorElementItems: formattedEditorElementItems, elementsBlockTitle: elementsBlockTitle, flowControlElementItems: formattedEditorFlowControlElementItems, flowControlElementsBlockTitle: flowControlElementsBlockTitle, importDataElementItems: formattedEditorImportDataElementItems, importDataElementsBlockTitle: importDataElementsBlockTitle })));
};
export default withRouter(EditorLeftSidebarContainer);
