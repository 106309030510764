import styled from "styled-components";
export const ImportDataBlock = styled.div `
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 16px;
    label {
        flex: auto;
    }
    > button {
        margin-left: 16px;
    }
`;
