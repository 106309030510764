import React from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { useIntl } from "@boomq/utils";
import { DETAILED_STATS_BUTTON_LABEL, DETAILED_SYS_METRICS_LABEL, STOP_TEST_BUTTON_LABEL, STOPPING_TEXT, TEST_PROJECT_BUTTON_LABEL, TREND_BUTTON_LABEL } from "./intl";
import { ModalAnomalySettingsContainer } from "../../ModalAnomalySettings";
import { TestDetailsButtons } from "../../../../components/account/TestDetailsButtons";
import { anomalySettingsModalForm, stopTestModal } from "../../../../data/content/modals";
import { formatIntlMessage } from "../../../../helpers/intl";
import { getModal } from "../../../../redux/actions/modal";
import { getSiteModal } from "../../../../redux/actions/siteModal";
import { stopTestInDetailsRequest } from "../../../../redux/actions/testDetails";
import { slaReportProfileByTestRequest } from "../../../../redux/actions/slaReportProfile";
import { getClientPermissionList } from "../../../../redux/reducers/authorizePlate";
import { getTestDetailsData } from "../../../../redux/reducers/testDetails";
const connector = connect((state) => ({
    permissionList: getClientPermissionList(state),
    testDetails: getTestDetailsData(state)
}), { onShowModal: getModal, onStop: stopTestInDetailsRequest, onClickTrend: slaReportProfileByTestRequest });
const TestDetailsButtonsContainer = (props) => {
    const { children, isApplyAnomalySettings, permissionList, testDetails: { displayState, grafanaTestUrl, grafanaSysMetricsUrl, id, state, testProjectId, testProjectVersionId, testResult } } = props;
    const { testResultSlaState } = testResult || {};
    const dispatch = useDispatch();
    const { formatMessage } = useIntl();
    const clickTrendHandler = () => props.onClickTrend({ testProjectId, testId: id });
    const clickStopTestHandler = () => props.onShowModal(stopTestModal(() => props.onStop({ id, testProjectId })));
    const handleAnomalySettingsClick = () => dispatch(getSiteModal(anomalySettingsModalForm((props) => React.createElement(ModalAnomalySettingsContainer, Object.assign({}, props)))));
    const detailedStatsButtonLabel = formatIntlMessage(DETAILED_STATS_BUTTON_LABEL, formatMessage);
    const detailedSysMetricsButtonLabel = formatIntlMessage(DETAILED_SYS_METRICS_LABEL, formatMessage);
    const stopTestButtonLabel = formatIntlMessage(STOP_TEST_BUTTON_LABEL, formatMessage);
    const stoppingText = formatIntlMessage(STOPPING_TEXT, formatMessage);
    const testProjectButtonLabel = formatIntlMessage(TEST_PROJECT_BUTTON_LABEL, formatMessage);
    const trendButtonLabel = formatIntlMessage(TREND_BUTTON_LABEL, formatMessage);
    return (React.createElement(TestDetailsButtons, { detailedStatsButtonLabel: detailedStatsButtonLabel, detailedSysMetricsButtonLabel: detailedSysMetricsButtonLabel, displayState: displayState, grafanaTestUrl: grafanaTestUrl, grafanaSysMetricsUrl: grafanaSysMetricsUrl, isApplyAnomalySettings: isApplyAnomalySettings, onClickAnomalySettings: handleAnomalySettingsClick, onClickStopTest: clickStopTestHandler, onClickTrend: clickTrendHandler, permissionList: permissionList, state: state, stoppingText: stoppingText, stopTestButtonLabel: stopTestButtonLabel, testProjectButtonLabel: testProjectButtonLabel, testProjectId: testProjectId, testProjectVersionId: testProjectVersionId, testResultSlaState: testResultSlaState, trendButtonLabel: trendButtonLabel }, children));
};
export default withRouter(connector(TestDetailsButtonsContainer));
