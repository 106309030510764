import styled from "styled-components";
import { Color, FontBodyBigMedium, IconArrowDown, InputNumber, withDisable } from "@boomq/uikit";
export const percentInputTheme = {
    minWidth: "100px",
    maxWidth: "100px",
    marginRight: "16px"
};
export const nameInputEditModeTheme = {
    borderBottom: `1px solid ${Color.darkGrey5}`
};
export const switcherTheme = {
    marginRight: "6px",
    pointerEvents: "auto"
};
export const Actions = styled.ul `
    ${withDisable}
    display: flex;
`;
export const Action = styled.li `
    cursor: pointer;
    margin-left: 16px;
    svg {
        fill: ${Color.blue};
    }
`;
export const LoadProfileLabel = styled.div `
    ${FontBodyBigMedium}
    align-items: center;
    color: ${Color.grey50};
    cursor: pointer;
    display: flex;
    width: fit-content;
`;
export const PercentInput = styled(InputNumber) `
    align-items: center;
    color: ${Color.grey75};
    display: flex;
    margin-right: 30px;
    max-height: 32px;
    min-height: 32px;
`;
export const StyledIconArrowDown = styled(IconArrowDown) `
    fill: ${Color.blue};
    transform: rotate(${({ isexpanded }) => (isexpanded === "true" ? 0 : -90)}deg);
`;
export const ThreadGroupContent = styled.div `
    align-items: center;
    cursor: pointer;
    display: flex;
    pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
`;
export const ThreadGroupInfo = styled.div `
    ${withDisable}
    display: flex;
    flex: auto;
    flex-direction: column;
`;
export const VUsersValue = styled.div `
    align-items: center;
    color: ${Color.grey75};
    border: 1px solid ${Color.grey75};
    border-radius: 12px;
    display: flex;
    margin-right: 16px;
    max-height: 48px;
    max-width: 100px;
    min-height: 48px;
    min-width: 100px;
    padding: 8px 40px 8px 12px;
    position: relative;
    width: 100%;
`;
