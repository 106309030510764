import { requestCommonParamList } from "./common";
import { DEFAULT_HTTP_METHOD_ITEM_COLOR, DEFAULT_HTTP_METHOD_ITEM_EXCLUDE_PARAMS } from "./constants";
import { HttpMethod, HttpMethodColor } from "./enums";
import { requestGrpcParamList } from "./grpc";
const httpMethodItemExcludeParams = {
    [HttpMethod.Get]: ["body"],
    [HttpMethod.Head]: ["body"],
    [HttpMethod.Options]: ["body"]
};
export const getHttpMethodItemExcludeParams = (method) => httpMethodItemExcludeParams[method] ? httpMethodItemExcludeParams[method] : DEFAULT_HTTP_METHOD_ITEM_EXCLUDE_PARAMS;
export const getHttpMethodItemColor = (method) => HttpMethodColor[method] ? HttpMethodColor[method] : DEFAULT_HTTP_METHOD_ITEM_COLOR;
export const getHttpMethodItemsWithParams = () => Object.keys(HttpMethod).map((httpMethod, index) => ({
    bgColor: getHttpMethodItemColor(httpMethod),
    id: index,
    text: httpMethod,
    value: HttpMethod[httpMethod],
    excludeParams: getHttpMethodItemExcludeParams(HttpMethod[httpMethod])
}));
export const requestManualParamList = [
    requestCommonParamList[0],
    requestCommonParamList[1],
    requestCommonParamList[2],
    requestGrpcParamList[2],
    requestGrpcParamList[3],
    requestGrpcParamList[4]
];
