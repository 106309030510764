import { call, put, take } from "redux-saga/effects";
import { push } from "connected-react-router";
import { loginActions } from "./auth";
import { activationRequest, activationSuccess, activationFailure } from "../actions/activation";
import authorizeActions from "../actions/authorize";
import { getResponseErrorData } from "../../helpers/errors";
import { activateAccount } from "../../services/authSrv";
const { authSuccess, authFailure } = authorizeActions;
export function* activationFlow() {
    while (true) {
        const action = yield take(activationRequest);
        try {
            if (action.type === activationRequest.toString()) {
                const { data, headers: { authorization: token } } = yield call(activateAccount, action.payload.code);
                yield put(activationSuccess(data));
                yield put(authSuccess(data));
                yield call(loginActions, token);
            }
        }
        catch (e) {
            yield put(activationFailure(getResponseErrorData(e)));
            yield put(push("/authorize"));
            yield put(authFailure(getResponseErrorData(e)));
        }
    }
}
