import React from "react";
import { FormattedMessage } from "react-intl";
import { RESPONSE_TIME_TITLE, RESPONSE_TIME_TOOLTIP, TEST_CURRENT_STATS_TITLE, TEST_DETAILS_CHART_THROUGHPUT_TITLE, TEST_DETAILS_CHART_THROUGHPUT_TOOLTIP, TEST_DETAILS_SETTINGS_TITLE, TEST_LOGS_TITLE } from "./intl";
import { BlockWrapper, StyledBlock, StyledHelp, TestDetailsColumnInfoBlock, TestDetailsInfoBlock, TestDetailsInfoSection, Title } from "./styled";
import { ResponseTimeChart } from "./ResponseTimeChart";
import { ThroughputChart } from "./ThroughputChart";
import TestDetailsStats from "../TestDetailsStats";
import { TestDetailsInfo } from "../TestDetailsInfo";
import { TestLogs } from "../TestDetails/TestLogs";
import { IconLoader } from "../../styled/Icons";
import { sizes } from "../../styled/response";
import { isTestCompleted, isTestDuringExecution, isTestDuringPreparing } from "../../../helpers/testStatus";
import { TestResultFilesState } from "../../../helpers/types";
import { ResponseTimeChartContainer } from "../../../containers/account/TestDetails/ResponseTimeChart";
import { TestDetailsTransactionContainer } from "../../../containers/account/TestDetails/TestDetailsTransaction";
export const TestDetailsBlocks = ({ chartData, chartErrorsLabel, chartRequestsLabel, chartRequestsErrorsLabel, chartResponseLabel, chartVUsersLabel, isAnomaliesEnabled, isApplyAnomalySettings, leftYAxisResponseTimeMaxLength, leftYAxisValMaxLength, onClickResultLogFileLink, result, rightYAxisValMaxLength, testDetails, testDetails: { resultLogFiles, displayState, testResult }, windowSize: { width }, yAxisDataKey }) => (React.createElement(BlockWrapper, null,
    React.createElement(TestDetailsInfoBlock, { testDuringExecution: isTestDuringExecution(displayState) },
        React.createElement(TestDetailsInfoSection, null,
            React.createElement(Title, null,
                React.createElement(FormattedMessage, Object.assign({}, TEST_DETAILS_SETTINGS_TITLE))),
            React.createElement(TestDetailsInfo, Object.assign({}, testDetails))),
        isTestCompleted(displayState) && width >= sizes.desktop && (React.createElement(TestDetailsInfoSection, null,
            React.createElement(Title, null,
                React.createElement(FormattedMessage, Object.assign({}, TEST_LOGS_TITLE)),
                testResult && testResult.testResultFilesState === TestResultFilesState.Processing && (React.createElement(IconLoader, null))),
            React.createElement(TestLogs, { items: resultLogFiles, onClick: onClickResultLogFileLink })))),
    isTestCompleted(displayState) && width < sizes.desktop && (React.createElement(TestDetailsColumnInfoBlock, null,
        React.createElement(Title, null,
            React.createElement(FormattedMessage, Object.assign({}, TEST_LOGS_TITLE)),
            testResult && testResult.testResultFilesState === TestResultFilesState.Processing && (React.createElement(IconLoader, null))),
        React.createElement(TestLogs, { items: resultLogFiles, onClick: onClickResultLogFileLink }))),
    !isTestDuringPreparing(displayState) && (React.createElement(React.Fragment, null,
        isTestDuringExecution(displayState) && (React.createElement(StyledBlock, null,
            React.createElement(Title, null,
                React.createElement(FormattedMessage, Object.assign({}, TEST_CURRENT_STATS_TITLE))),
            React.createElement(TestDetailsStats, Object.assign({}, result)))),
        React.createElement(StyledBlock, null,
            React.createElement(Title, null,
                React.createElement(FormattedMessage, Object.assign({}, TEST_DETAILS_CHART_THROUGHPUT_TITLE)),
                React.createElement(StyledHelp, { tooltipText: TEST_DETAILS_CHART_THROUGHPUT_TOOLTIP }),
                isTestDuringExecution(displayState) && React.createElement(IconLoader, null)),
            React.createElement(ThroughputChart, { chartData: chartData, chartErrorsLabel: chartErrorsLabel, chartRequestsLabel: chartRequestsLabel, chartRequestsErrorsLabel: chartRequestsErrorsLabel, chartVUsersLabel: chartVUsersLabel, leftYAxisValMaxLength: leftYAxisValMaxLength, rightYAxisValMaxLength: rightYAxisValMaxLength, yAxisDataKey: yAxisDataKey })),
        React.createElement(StyledBlock, null,
            React.createElement(Title, null,
                React.createElement(FormattedMessage, Object.assign({}, RESPONSE_TIME_TITLE)),
                React.createElement(StyledHelp, { tooltipText: RESPONSE_TIME_TOOLTIP }),
                isTestDuringExecution(displayState) && React.createElement(IconLoader, null)),
            isApplyAnomalySettings ? (React.createElement(ResponseTimeChartContainer, { chartData: chartData, testDetails: testDetails, chartResponseLabel: chartResponseLabel, chartVUsersLabel: chartVUsersLabel, leftYAxisResponseTimeMaxLength: leftYAxisResponseTimeMaxLength, rightYAxisValMaxLength: rightYAxisValMaxLength })) : (React.createElement(ResponseTimeChart, { chartData: chartData, chartResponseLabel: chartResponseLabel, chartVUsersLabel: chartVUsersLabel, leftYAxisResponseTimeMaxLength: leftYAxisResponseTimeMaxLength, rightYAxisValMaxLength: rightYAxisValMaxLength }))),
        isApplyAnomalySettings && isAnomaliesEnabled && (React.createElement(TestDetailsTransactionContainer, { testDetails: testDetails, chartResponseLabel: chartResponseLabel, chartVUsersLabel: chartVUsersLabel, leftYAxisResponseTimeMaxLength: leftYAxisResponseTimeMaxLength, rightYAxisValMaxLength: rightYAxisValMaxLength }))))));
