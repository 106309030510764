import styled from "styled-components";
import { Color } from "@boomq/uikit";
import { IconPlus20Blue } from "../../styled/StyledIcons";
import { ActionWithTextStyles } from "../../styled/Table";
export const Action = styled.div `
    ${ActionWithTextStyles}
`;
export const Actions = styled.div `
    align-items: center;
    display: flex;
    margin-top: 16px;
`;
export const StyledIconPlus = styled(IconPlus20Blue) `
    margin-right: 6px;
`;
export const VerticalSeparator = styled.div `
    background: ${Color.lightGrey85};
    height: 100%;
    margin: 0 16px;
    padding: 8px 0;
    width: 1px;
`;
