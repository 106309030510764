import styled, { css } from "styled-components";
import { InputNumber } from "../../../common/InputNumber";
export const customInputTheme = {
    color: "#bdbdbd",
    fontFamily: "Montserrat",
    fontSize: "20px",
    fontWeight: "600",
    padding: "3px 12px"
};
export const getCustomInputNumberTheme = (isError) => ({
    border: `solid 1px ${isError ? "#eb5757" : "#b6b6b6"}`,
    borderRadius: "5px",
    marginRight: "4px",
    minWidth: "110px",
    width: "135px"
});
export const iconMenuListTheme = {
    left: 0,
    width: "max-content"
};
export const iconMenuTheme = {
    position: "absolute",
    right: "35px",
    "> div": {
        border: "none"
    }
};
export const ActionsSection = styled.div `
    min-width: 14px;
`;
export const Percent = styled.div `
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    font-weight: 600;
    line-height: normal;
    font-size: 20px;
    color: #fff;
    margin-right: 20px;
    min-width: 80px;
    ${({ theme }) => (theme ? css(theme) : css ``)}
`;
export const TestProfileInput = styled(InputNumber) `
    border-radius: 5px;
    margin-right: 4px;
    min-width: 110px;
    width: 135px;
`;
