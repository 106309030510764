import styled from "styled-components";
import { Link } from "react-router-dom";
import { Color, FontBodyMediumBold, IconEdit, Label, FontBodyMediumMedium } from "@boomq/uikit";
export const Action = styled.div `
    display: flex;
`;
export const NoLabel = styled.div `
    ${FontBodyMediumBold}
    color: ${Color.darkGrey35};
`;
export const SettingsSetItem = styled.div `
    align-items: center;
    display: flex;
    margin-top: 16px;
`;
export const SettingsSetName = styled.div `
    ${FontBodyMediumMedium}
    color: ${Color.darkGrey5};
    line-height: 100%;
    margin: 0 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
`;
export const StyledIconEdit = styled(IconEdit) `
    fill: ${Color.blue};
`;
export const StyledLink = styled(Link) `
    display: flex;
`;
export const LabelSet = styled.ul `
    display: flex;
    flex-wrap: wrap;
    max-width: 200px;
    min-width: 200px;
    width: 200px;
`;
export const StyledLabel = styled(Label) `
    margin-right: 4px;
    margin-bottom: 8px;
`;
