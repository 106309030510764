import { ImportDataFromFileToThreadGroupContainer } from "../../containers/account/TestProject/ImportDataFromFileToThreadGroup";
import { getImportDataToTestProjectComponentBySourceType } from "../../models/importData";
export const importDataFromFileToThreadGroupModalForm = ({ groupId, sourceType }) => ({
    component: {
        element: ImportDataFromFileToThreadGroupContainer,
        props: { groupId, sourceType },
        width: "800px"
    },
    title: {
        defaultMessage: sourceType,
        id: `account.request.group.editor.import.data.from.file.form.${sourceType}.title`
    }
});
export const importDataToTestProjectModalForm = (sourceType) => ({
    component: {
        element: getImportDataToTestProjectComponentBySourceType(sourceType),
        props: { sourceType },
        width: "800px"
    },
    title: {
        defaultMessage: sourceType,
        id: `account.new.test.project.import.${sourceType}.title`
    }
});
