import React from "react";
import { isEmpty } from "@boomq/utils";
import { Button } from "@boomq/uikit";
import { EDITORJS_HOLDER_ID } from "./constants";
import { ReportEmptySidebarSettings, ReportFixedActionsPanel, ReportWrapper } from "./styled";
import "./editor.css";
import { ReportNameContainer } from "../../../../containers/account/Report/ReportName";
import { BackLinkButton } from "../../../styled/BackLinkButton";
import { BlockHeaderWithMarginTop } from "../../../styled/Common";
import { getReportsRoute } from "../../../../helpers/routes";
export const Report = ({ isAuthorized, onClickSaveButton, selectedTeamId, sidebarSettingsEmptyText }) => isAuthorized && !isEmpty(selectedTeamId) ? (React.createElement(ReportWrapper, null,
    React.createElement(BlockHeaderWithMarginTop, null,
        React.createElement(BackLinkButton, { to: getReportsRoute() }),
        React.createElement(ReportNameContainer, null)),
    React.createElement(ReportEmptySidebarSettings, { className: "pflb-sidebar-settings show" }, sidebarSettingsEmptyText),
    React.createElement("div", { id: EDITORJS_HOLDER_ID }),
    React.createElement(ReportFixedActionsPanel, null,
        React.createElement(Button, { onClick: onClickSaveButton }, "Save")))) : (React.createElement(React.Fragment, null));
