export const env = process.env.REACT_APP_ENV;
export const authServiceName = process.env.REACT_APP_AUTH_SERVICE_NAME;
export const configFile = process.env.REACT_APP_CONFIG_FILE;
export const socketUrl = process.env.REACT_APP_SOCKET_URL;
export const proxyUrl = process.env.REACT_APP_PROXY_URL;
export const harServicePort = process.env.REACT_APP_HAR_SERVICE_PORT;
export const supportedJMeterConfigPropertiesDocsLink = "/docs/en/";
export const socketProtocols = ["jsonp-polling", "xdr-polling", "iframe-xhr-polling", "xhr-polling"];
export const CANCELID = "NO";
export const CONFIRMID = "YES";
export const testVersion = "1.0.0";
export const timeDifference = 10800000;
export const testProfilePercStep = 0.1;
export const testProfilePercMin = 0;
export const testProfilePercMax = 100;
export const authError = (code) => {
    switch (code) {
        case "internal_server_error":
            return {
                defaultMessage: "There were problems, please try again later.",
                id: "website.authorize.error.server"
            };
        case "blocked_user":
            return {
                defaultMessage: "User has been blocked.",
                id: "website.authorize.error.blocked"
            };
        case "access_denied":
            return {
                defaultMessage: "You have denied access to credentials.",
                id: "website.authorize.error.access"
            };
        default:
            return {
                defaultMessage: "There were problems, please try again later.",
                id: "website.authorize.error.server"
            };
    }
};
export const languages = ["ru", "en"];
export const clientStates = ["ACTIVATED", "BLOCKED"];
export const loadTypes = ["STABILITY", "MAX_LOAD"];
export const geoTypes = ["NONE", "RF_MSK", "RF_ONLY", "WORLD"];
export const geoTypesMessages = [
    {
        defaultChecked: true,
        description: {
            defaultMessage: "All 100% of users in Moscow",
            id: "account.new.settings.geo.desc.moscow"
        },
        name: "RF_MSK",
        title: {
            defaultMessage: "Moscow",
            id: "account.new.settings.geo.title.moscow"
        }
    },
    {
        defaultChecked: false,
        description: {
            defaultMessage: "There are users in Moscow and Izhevsk",
            id: "account.new.settings.geo.desc.russia"
        },
        name: "RF_ONLY",
        title: {
            defaultMessage: "Russia",
            id: "account.new.settings.geo.title.russia"
        }
    },
    {
        defaultChecked: false,
        description: {
            defaultMessage: "There are users in Moscow, Izhevsk and USA",
            id: "account.new.settings.geo.desc.world"
        },
        name: "WORLD",
        title: {
            defaultMessage: "World",
            id: "account.new.settings.geo.title.world"
        }
    }
];
export const townsList = {
    RF_MSK: ["moscow"],
    RF_ONLY: ["moscow", "izhevsk"],
    WORLD: ["moscow", "izhevsk", "california"]
};
