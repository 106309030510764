export const TEAM_MEMBER_ACTION_CANCEL = {
    defaultMessage: "Cancel",
    id: "account.team.member.actions.cancel.button.label"
};
export const TEAM_MEMBER_ACTION_SAVE = {
    defaultMessage: "Save",
    id: "account.team.member.actions.save.button.label"
};
export const TEAM_MEMBER_EMAIL_INPUT_LABEL = {
    defaultMessage: "Email",
    id: "account.team.member.email.input.label"
};
export const TEAM_MEMBER_NAME_INPUT_LABEL = {
    defaultMessage: "Name",
    id: "account.team.member.name.input.label"
};
