import location from "./reducers/location";
import { combineReducers } from "redux";
import accountTitle from "./reducers/accountTitle";
import resources from "./reducers/admin/resources";
import roles from "./reducers/admin/roles";
import sites from "./reducers/admin/sites";
import tariffs from "./reducers/admin/tariffs";
import tasks from "./reducers/admin/tasks";
import users from "./reducers/admin/users";
import analytics from "./reducers/analytics";
import authorizePlate from "./reducers/authorizePlate";
import changePassword from "./reducers/changePassword";
import clientTasks from "./reducers/clientTasks";
import clientTestProjectList from "./reducers/clientTestProjectList";
import clientWebsites from "./reducers/clientWebsites";
import config from "./reducers/config";
import runningTests from "./reducers/runningTests";
import editor from "./reducers/editor";
import importTestProjectData from "./reducers/importTestProjectData";
import jmeterConfigProperties from "./reducers/jmeterConfigProperties";
import languagePlate from "./reducers/languagePlate";
import loader from "./reducers/loader";
import login from "./reducers/login";
import modal from "./reducers/modal";
import monitoring from "./reducers/monitoring";
import newRequest from "./reducers/newRequest";
import newTest from "./reducers/newTest";
import recovery from "./reducers/recovery";
import registration from "./reducers/registration";
import reload from "./reducers/reload";
import requestParameters from "./reducers/requestParameters";
import report from "./reducers/report";
import reports from "./reducers/reports";
import reset from "./reducers/reset";
import siteModal from "./reducers/siteModal";
import slaReportProfile from "./reducers/slaReportProfile";
import slaReportProfiles from "./reducers/slaReportProfiles";
import team from "./reducers/team";
import teamMembers from "./reducers/teamMembers";
import teams from "./reducers/teams";
import testAnomalies from "./reducers/testAnomalies";
import testDetails from "./reducers/testDetails";
import testProjectModelSchema from "./reducers/testProjectModelSchema";
import testProjects from "./reducers/testProjects";
import testProjectSettingsSet from "./reducers/testProjectSettingsSet";
import testProjectSettingsSetCookies from "./reducers/testProjectSettingsSetCookies";
import testProjectSettingsSetHeaders from "./reducers/testProjectSettingsSetHeaders";
import testProjectSettingsSets from "./reducers/testProjectSettingsSets";
import testProjectsSettingsSetSidebar from "./reducers/testProjectsSettingsSetSidebar";
import testProjectNotification from "./reducers/testProjectNotification";
import urlOverwrite from "./reducers/urlOverwrite";
import webhooks from "./reducers/webhooks";
export const commonRootReducer = {
    accountTitle,
    analytics,
    authorizePlate,
    changePassword,
    clientTasks,
    clientTestProjectList,
    clientWebsites,
    config,
    editor,
    importTestProjectData,
    jmeterConfigProperties,
    languagePlate,
    loader,
    login,
    modal,
    monitoring,
    newRequest,
    newTest,
    recovery,
    registration,
    reload,
    requestParameters,
    report,
    reports,
    reset,
    resources,
    roles,
    location,
    runningTests,
    siteModal,
    sites,
    slaReportProfile,
    slaReportProfiles,
    tariffs,
    tasks,
    team,
    teamMembers,
    teams,
    testAnomalies,
    testDetails,
    testProjectModelSchema,
    testProjects,
    testProjectSettingsSet,
    testProjectSettingsSetCookies,
    testProjectSettingsSetHeaders,
    testProjectSettingsSets,
    testProjectsSettingsSetSidebar,
    testProjectNotification,
    urlOverwrite,
    users,
    webhooks
};
const rootReducer = combineReducers(commonRootReducer);
export default rootReducer;
