import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { useIntl } from "@boomq/utils";
import { LoadGeneratorsSelection, newTestActions } from "@boomq/common";
import { RUNNER_RESOURCES_STORE_FIELD } from "./constants";
import { generateFormattedResourceList } from "../../../../helpers/runnerResources";
import { getRunnerResources } from "../../../../redux/reducers/runnerResources";

const { changeRequestGroup } = newTestActions;

const connector = connect(
    (state) => ({
        resources: getRunnerResources(state)
    }),
    { onChangeRequestGroup: changeRequestGroup }
);

const getValue = (requestGroup) =>
    requestGroup && Array.isArray(requestGroup.runnerResources)
        ? requestGroup.runnerResources.map((runnerResource) => ({ id: runnerResource }))
        : [];

const LoadGeneratorsSelectionContainer = ({ requestGroup, resources, ...restProps }) => {
    const { formatMessage } = useIntl();

    const changeHandler = (resource) =>
        restProps.onChangeRequestGroup({
            field: RUNNER_RESOURCES_STORE_FIELD,
            id: requestGroup ? requestGroup.id : "",
            value: resource.id === -1 ? [] : [resource.value]
        });

    return (
        <LoadGeneratorsSelection
            {...restProps}
            items={generateFormattedResourceList(resources, formatMessage)}
            onChange={changeHandler}
            value={getValue(requestGroup)}
        />
    );
};

export default withRouter(connector(LoadGeneratorsSelectionContainer));
