const teamExceptionsByExceptionMessage = {
    DENIED_TO_DELETE_LAST_ADMIN_FROM_TEAM: [
        {
            defaultMessage: "Unfortunately, you cannot delete the last user having Admin permissions.",
            id: "account.team.member.actions.delete.exception.DENIED_TO_DELETE_LAST_ADMIN_FROM_TEAM.text1"
        },
        {
            defaultMessage: "If you still need to remove this user, re-assign Admin permissions to some other user and try again.",
            id: "account.team.member.actions.delete.exception.DENIED_TO_DELETE_LAST_ADMIN_FROM_TEAM.text2"
        }
    ],
    DENIED_TO_INVITE_EXTERNAL_USER: [
        {
            defaultMessage: "You haven't permissions to invite external users",
            id: "account.team.member.actions.add.exception.DENIED_TO_INVITE_EXTERNAL_USER"
        }
    ],
    USER_ALREADY_INVITED: [
        {
            defaultMessage: "User is already existed in current team",
            id: "account.team.member.actions.add.exception.USER_ALREADY_INVITED"
        }
    ]
};
export const getTeamErrorByExceptionMessage = (exceptionMessage) => exceptionMessage ? teamExceptionsByExceptionMessage[exceptionMessage] : undefined;
