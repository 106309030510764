import React from "react";
import { connect } from "react-redux";
import { IntlProvider } from "react-intl";
import PFLBChartContainer from "./PFLBChart";
import { dictionaryRu, dictionaryEn } from "../../../../data/dictionaries";
import { getCurrentLanguage } from "../../../../redux/reducers/languagePlate";
const dictionaries = { ru: dictionaryRu, en: dictionaryEn };
const connector = connect((state) => ({
    currentLanguage: getCurrentLanguage(state)
}));
export const PFLBChartWithIntl = connector(({ currentLanguage, id, isShowParamsSidebar, onToggleChartParamsSidebar }) => (React.createElement(IntlProvider, { locale: currentLanguage, messages: dictionaries[currentLanguage] },
    React.createElement(PFLBChartContainer, { id: id, isShowParamsSidebar: isShowParamsSidebar, onToggleChartParamsSidebar: onToggleChartParamsSidebar }))));
