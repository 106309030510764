import React from "react";
import { connect } from "react-redux";
import { useIntl } from "@boomq/utils";
import { EditableTitle } from "../../../../components/common/EditableTitle";
import { titleTheme } from "../../../../components/styled/Common";
import { formatIntlMessage } from "../../../../helpers/intl";
import { nameValidator } from "../../../../helpers/validators";
import { setReportParams } from "../../../../redux/actions/report";
import { getReportName } from "../../../../redux/reducers/report";
const connector = connect((state) => ({
    reportName: getReportName(state)
}), { onChangeReportParams: setReportParams });
const ReportNameContainer = ({ onChangeReportParams, reportName }) => {
    const { formatMessage } = useIntl();
    const changeProjectName = (value) => onChangeReportParams({ reportName: value });
    const validateFunc = (value) => {
        const { isValid, error } = nameValidator(value);
        return { isValid, error: formatIntlMessage(error, formatMessage) };
    };
    return (React.createElement(EditableTitle, { onChange: changeProjectName, editModeTheme: titleTheme, theme: titleTheme, value: reportName, validateFunc: validateFunc }));
};
export default connector(ReportNameContainer);
