var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useRef, useState } from "react";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import moment from "moment";
import "moment/locale/en-gb";
import { END_DATE_ID, ERROR_CLASS_NAME, INPUT_ICON_POSITION, START_DATE_ID } from "./constants";
import { convertDateToISOString, getLocale, getDateStateValue, isDayHighlighted, isOutsideRange } from "./helpers";
import { IconCalendar } from "../Icons";
import { withError } from "../withError";
import "./_datepicker.css";
const DateRangeCalendar = (_a) => {
    var { hideError, highlightedDateRange, isError, locale, onChange, showError } = _a, restProps = __rest(_a, ["hideError", "highlightedDateRange", "isError", "locale", "onChange", "showError"]);
    const [endDate, setEndDate] = useState(getDateStateValue(restProps.endDate));
    const [focusedInput, setFocusedInput] = useState(null);
    const [startDate, setStartDate] = useState(getDateStateValue(restProps.startDate));
    const dateRangeContainerRef = useRef(null);
    useEffect(() => {
        moment.locale(getLocale(locale));
    }, [locale]);
    const changeHandler = ({ startDate, endDate }) => {
        setStartDate(startDate);
        setEndDate(endDate);
        return typeof onChange === "function"
            ? onChange({
                startDate: convertDateToISOString(startDate),
                endDate: convertDateToISOString(endDate)
            })
            : undefined;
    };
    const closeHandler = () => (typeof hideError === "function" ? hideError() : undefined);
    const focusHandler = (focusedInput) => {
        setFocusedInput(focusedInput);
        return typeof showError === "function"
            ? showError({ target: dateRangeContainerRef.current })
            : undefined;
    };
    const isHighlightedDay = (day) => isDayHighlighted({ day, endDate, highlightedDateRange, startDate });
    return (React.createElement("div", { className: isError ? ERROR_CLASS_NAME : "", ref: dateRangeContainerRef },
        React.createElement(DateRangePicker, { customArrowIcon: React.createElement("span", null, "-"), customInputIcon: React.createElement(IconCalendar, null), endDate: endDate, isDayHighlighted: isHighlightedDay, isOutsideRange: isOutsideRange, endDateId: END_DATE_ID, focusedInput: focusedInput, hideKeyboardShortcutsPanel: true, inputIconPosition: INPUT_ICON_POSITION, onClose: closeHandler, onDatesChange: changeHandler, onFocusChange: focusHandler, startDate: startDate, startDateId: START_DATE_ID, withPortal: true })));
};
export default withError(DateRangeCalendar);
