import styled from "styled-components";
import { Button, Select } from "@boomq/uikit";
export const ApplyButton = styled(Button) `
    margin-left: 6px;
`;
export const ChartParamsRow = styled.div `
    align-items: center;
    display: flex;
    justify-content: flex-end;
    margin-top: 12px;
`;
export const ChartSelectWithMarginTop = styled(Select) `
    margin-top: 12px;
`;
