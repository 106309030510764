export const STOP_BUTTON_LABEL = {
    defaultMessage: "Stop",
    id: "account.test.details.stop.button"
};
export const STOPPING_TEXT = {
    defaultMessage: "Stopping...",
    id: "account.current.test.stopping"
};
export const TEST_DETAILS_LINK_LABEL = {
    defaultMessage: "Test Detailing",
    id: "account.current.details"
};
