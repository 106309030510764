import { IconCookies, IconSettings, IconSpeedometer } from "@boomq/uikit";
import { DEFAULT_TEST_PROJECT_DEFAULT_SETTINGS_FORM_DATA_VALUE, DEFAULT_TEST_PROJECT_DEFAULT_SETTINGS_TYPE_LIST } from "./constants";
import { TestProjectOwnJMXDefaultSettingsType, TestProjectStableScalabilityDefaultSettingsType } from "./enums";
import { httpRequestDefaultsFormModal, requestDefaultCookiesFormModal, requestDefaultHeadersFormModal, requestDefaultTimerFormModal, requestTimersFormModal } from "../../data/content/modals";
import { HttpRequestDefaultsForm } from "../../components/account/TestProject/HttpRequestDefaultsForm";
import { RequestDefaultCookiesFormContainer } from "../../containers/account/TestProject/RequestDefaultCookiesForm";
import { RequestDefaultHeadersForm } from "../../containers/account/TestProject/RequestDefaultHeadersForm";
import { RequestDefaultTimerSettingsForm } from "../../components/account/TestProject/RequestDefaultTimerSettingsForm";
import { RequestJMXTimersSettingsForm } from "../../components/account/TestProject/RequestJMXTimersSettingsForm";
import { IconHeaders } from "../../components/styled/Icons/IconHeaders";
import { TestType } from "../../helpers/types/test";
export const testProjectDefaultSettingsIconByType = {
    [TestProjectStableScalabilityDefaultSettingsType.Cookies]: IconCookies,
    [TestProjectStableScalabilityDefaultSettingsType.Headers]: IconHeaders,
    [TestProjectStableScalabilityDefaultSettingsType.HttpRequestSettings]: IconSettings,
    [TestProjectStableScalabilityDefaultSettingsType.Timer]: IconSpeedometer,
    [TestProjectOwnJMXDefaultSettingsType.Timers]: IconSpeedometer
};
const testProjectDefaultSettingsFormDataByType = {
    [TestProjectStableScalabilityDefaultSettingsType.Cookies]: requestDefaultCookiesFormModal({
        element: RequestDefaultCookiesFormContainer
    }),
    [TestProjectStableScalabilityDefaultSettingsType.Headers]: requestDefaultHeadersFormModal({
        element: RequestDefaultHeadersForm
    }),
    [TestProjectStableScalabilityDefaultSettingsType.HttpRequestSettings]: httpRequestDefaultsFormModal({
        element: HttpRequestDefaultsForm
    }),
    [TestProjectStableScalabilityDefaultSettingsType.Timer]: requestDefaultTimerFormModal({
        element: RequestDefaultTimerSettingsForm
    }),
    [TestProjectOwnJMXDefaultSettingsType.Timers]: requestTimersFormModal({
        element: RequestJMXTimersSettingsForm
    })
};
const testProjectDefaultSettingsTypesByTestType = {
    [TestType.JMX]: Object.values(TestProjectOwnJMXDefaultSettingsType),
    [TestType.Scalability]: Object.values(TestProjectStableScalabilityDefaultSettingsType),
    [TestType.Stable]: Object.values(TestProjectStableScalabilityDefaultSettingsType)
};
export const getTestProjectDefaultSettingsTypesByTestType = (testType) => testType in testProjectDefaultSettingsTypesByTestType
    ? Object.values(testProjectDefaultSettingsTypesByTestType[testType])
    : DEFAULT_TEST_PROJECT_DEFAULT_SETTINGS_TYPE_LIST;
export const getTestProjectDefaultSettingsFormDataByType = (type) => type && type in testProjectDefaultSettingsFormDataByType
    ? testProjectDefaultSettingsFormDataByType[type]
    : DEFAULT_TEST_PROJECT_DEFAULT_SETTINGS_FORM_DATA_VALUE;
