var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { FormattedMessage } from "react-intl";
import { StyledBurgerMenu, StyledButtonWithRoute } from "./styled";
import { menuOptions } from "../../../models/accountNavBar";
import { MenuListItem } from "../../common/MenuListItem";
import { LogoutMobile } from "../LogoutMobile";
import { TeamInfoMobile } from "../TeamInfoMobile";
import { UserInfo } from "../UserInfo";
const renderAccountInfo = (isAuthorized) => isAuthorized ? (React.createElement(React.Fragment, null,
    React.createElement(UserInfo, { path: "/account/profile", mobile: true }),
    React.createElement(TeamInfoMobile, { path: "/account/teams" }),
    React.createElement(LogoutMobile, null))) : (React.createElement(StyledButtonWithRoute, { to: "/authorize" },
    React.createElement(FormattedMessage, { id: "website.authorize.button", defaultMessage: "Sign in" })));
export const HeaderBurgerMenu = ({ isAuthorized }) => (React.createElement(StyledBurgerMenu, null,
    React.createElement(React.Fragment, null,
        (menuOptions || []).map((_a) => {
            var { id } = _a, restParams = __rest(_a, ["id"]);
            return (React.createElement(MenuListItem, Object.assign({ key: id }, restParams)));
        }),
        renderAccountInfo(isAuthorized))));
