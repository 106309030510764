import styled from "styled-components";
import { StyledButton } from "../../../styled/Buttons";
import { IconMenu } from "../../../styled/Icons";
import { media } from "../../../styled/response";
export const iconMenuListTheme = {
    left: "auto",
    right: 0,
    transform: "none",
    width: "max-content"
};
export const StyledDetailsButtons = styled.div `
    align-items: center;
    display: flex;
    justify-content: flex-end;
    ${StyledButton} {
        margin-left: 20px;
        &:first-child {
            margin-left: 0;
        }
        ${media.tablet} {
            margin: 5px 0;
            width: 100%;
        }
    }
    ${media.tablet} {
        flex-direction: column;
        overflow-x: auto;
        &::-webkit-scrollbar {
            display: none;
        }
    }
`;
export const StyledIconMenu = styled(IconMenu) `
    min-width: 14px;
    &:after {
        left: 100%;
        pointer-events: none;
        width: 10px;
    }
`;
