import styled from "styled-components";
import { media } from "../../../styled/response";
import { DeleteButton } from "../SlaReportTable/styled";
const TABLE_CELL_SLA_NAME_WIDTH_DEFAULT = "400px";
const TABLE_CELL_SLA_NAME_WIDTH_GIANT = "260px";
const TABLE_CELL_SLA_NAME_WIDTH_DESKTOP = "200px";
export const customNewSLASelectTheme = { width: "100%" };
export const DeleteRowButton = styled(DeleteButton) `
    align-items: center;
    display: flex;
    height: 100%;
    left: -32px;
    position: absolute;
    top: 0;
`;
export const FixedTable = styled.table `
    border-collapse: collapse;
    min-width: ${TABLE_CELL_SLA_NAME_WIDTH_DEFAULT};
    overflow-x: auto;
    width: ${TABLE_CELL_SLA_NAME_WIDTH_DEFAULT};
    ${media.giant} {
        min-width: ${TABLE_CELL_SLA_NAME_WIDTH_GIANT};
        width: ${TABLE_CELL_SLA_NAME_WIDTH_GIANT};
    }
    ${media.desktop} {
        min-width: ${TABLE_CELL_SLA_NAME_WIDTH_DESKTOP};
        width: ${TABLE_CELL_SLA_NAME_WIDTH_DESKTOP};
    }
`;
export const SlaTypeListItem = styled.div `
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;
const TableFixedCell = styled.td `
    background: #e0e0e0;
    border: 1px solid #000;
    min-width: ${TABLE_CELL_SLA_NAME_WIDTH_DEFAULT};
    width: ${TABLE_CELL_SLA_NAME_WIDTH_DEFAULT};
    ${media.giant} {
        min-width: ${TABLE_CELL_SLA_NAME_WIDTH_GIANT};
        width: ${TABLE_CELL_SLA_NAME_WIDTH_GIANT};
    }
    ${media.desktop} {
        min-width: ${TABLE_CELL_SLA_NAME_WIDTH_DESKTOP};
        width: ${TABLE_CELL_SLA_NAME_WIDTH_DESKTOP};
    }
`;
export const TableFixedCellSlaName = styled(TableFixedCell) `
    padding: 10px;
`;
export const TableFixedCellSlaNameContent = styled.div `
    position: relative;
`;
export const TableHeaderFixedCell = styled(TableFixedCell) `
    padding: 0 10px;
    text-align: center;
`;
