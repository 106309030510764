import React from "react";
import { getValueByPath, isEmpty, useIntl } from "@boomq/utils";
import { ButtonRemove, RequestParam, RequestSection } from "./styled";
import { IconRemoveSection } from "../../KeyValue";
import { UsedParameterSection } from "../../LiteralParameters/styled";
import { UsedParameterIndicator } from "../../UsedParameterIndicator";
import { IconDeleteGrey } from "../../../styled/StyledIcons";
import { getExtractorTemplateByType } from "../../../../helpers/extractResponse";
import { formatIntlMessage } from "../../../../helpers/intl";
import { FormType } from "../../../../helpers/types";
const getUsedRequestParameterName = (usedRequestParameterNames, parameterName) => usedRequestParameterNames.includes(parameterName) ? React.createElement(UsedParameterIndicator, null) : React.createElement(React.Fragment, null);
const ExtractResponseParameter = ({ expression, extractTypes, fieldToCheckItems, match, matchItems, mode, newRequestChangeExtractorField, newRequestDeleteFieldParam, usedRequestParameterNames }) => {
    const { formatMessage } = useIntl();
    const changeExtractExpressionHandler = (params) => newRequestChangeExtractorField(params);
    const deleteExtractExpressionHandler = (id) => newRequestDeleteFieldParam({
        field: "extractResponse",
        id
    });
    const formatExpressionError = (res, paramName) => {
        const errorValue = getValueByPath(expression, `error.${paramName}`);
        return Object.assign(Object.assign({}, res), { [paramName]: errorValue ? formatIntlMessage(errorValue, formatMessage) : errorValue });
    };
    const formattedExpression = Object.assign(Object.assign({}, expression), { error: Object.keys(expression.error || {}).reduce(formatExpressionError, {}) });
    const formattedFieldToCheckItems = fieldToCheckItems.map((fieldToCheckItem) => (Object.assign(Object.assign({}, fieldToCheckItem), { text: formatIntlMessage(fieldToCheckItem.text, formatMessage) })));
    const formattedMatchItems = matchItems.map((matchItem) => (Object.assign(Object.assign({}, matchItem), { text: formatIntlMessage(matchItem.text, formatMessage) })));
    const ExtractorComponent = getExtractorTemplateByType(formattedExpression.type);
    return (React.createElement(RequestSection, null,
        React.createElement(RequestParam, null,
            React.createElement(UsedParameterSection, null, getUsedRequestParameterName(usedRequestParameterNames, formattedExpression.variable)),
            React.createElement(ExtractorComponent, { expression: formattedExpression, extractTypes: extractTypes, fieldToCheckItems: formattedFieldToCheckItems, match: match, matchItems: formattedMatchItems, mode: mode, onChange: changeExtractExpressionHandler }),
            mode !== FormType.View && (React.createElement(IconRemoveSection, null, !isEmpty(formattedExpression.variable) && (React.createElement(ButtonRemove, { onClick: () => deleteExtractExpressionHandler(formattedExpression.id) },
                React.createElement(IconDeleteGrey, null))))))));
};
export default ExtractResponseParameter;
