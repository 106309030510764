import styled from "styled-components";
import { IconSettings2 } from "@boomq/uikit";
export const AnomalySettingsLabel = styled.p `
    color: #7f8198;
    font-size: 12px;
`;
export const SettingsWrapper = styled.div `
    align-items: center;
    border: solid 1px #ffc306;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    padding: 7px 12px;
    transition: all 0.2s ease-in-out;
    > :first-child {
        margin-right: 6px;
    }
    &:active {
        transform: scale(0.9);
    }
    &:hover {
        cursor: pointer;
    }
`;
export const StyledIconSettings = styled(IconSettings2) `
    fill: #ffc306;
`;
