import { ReportChartAggregationInterval, ReportChartLoadProfileType, ReportChartSystemMetricType, ReportChartTestMetricType, ReportChartTransactionMetricType, ReportChartType, ReportChartUnit } from "./enums";
import { ChartSerieCommonParamsContainer } from "../../containers/account/Report/ChartSerieCommonParams";
import { ChartSerieSystemMetricParamsContainer } from "../../containers/account/Report/ChartSerieSystemMetricParams";
import { ChartSerieTransactionParamsContainer } from "../../containers/account/Report/ChartSerieTransactionMetricParams";
const reportMetricTypeByChartType = {
    [ReportChartType.LoadProfile]: Object.values(ReportChartLoadProfileType),
    [ReportChartType.SystemMetric]: Object.values(ReportChartSystemMetricType),
    [ReportChartType.TestMetric]: Object.values(ReportChartTestMetricType),
    [ReportChartType.TransactionMetric]: Object.values(ReportChartTransactionMetricType)
};
const reportChartUnitByMetricType = {
    [ReportChartLoadProfileType.Actual]: ReportChartUnit.VUser,
    [ReportChartLoadProfileType.Planned]: ReportChartUnit.VUser,
    [ReportChartSystemMetricType.CpuUtilizationPerCpuPercent]: ReportChartUnit.Percent,
    [ReportChartSystemMetricType.CpuUtilizationPercent]: ReportChartUnit.Percent,
    [ReportChartSystemMetricType.DiskTotalBytes]: ReportChartUnit.Byte,
    [ReportChartSystemMetricType.DiskUtilizationBytes]: ReportChartUnit.Byte,
    [ReportChartSystemMetricType.DiskUtilizationPercent]: ReportChartUnit.Percent,
    [ReportChartSystemMetricType.RamTotalBytes]: ReportChartUnit.Byte,
    [ReportChartSystemMetricType.RamUtilizationBytes]: ReportChartUnit.Byte,
    [ReportChartSystemMetricType.RamUtilizationPercent]: ReportChartUnit.Percent,
    [ReportChartSystemMetricType.SystemLoadAverage]: ReportChartUnit.Percent,
    [ReportChartTestMetricType.RequestsPerSecond]: ReportChartUnit.RequestsPerSecond,
    [ReportChartTestMetricType.AvgResponseTimeSec]: ReportChartUnit.Second,
    [ReportChartTestMetricType.ErrorsPerSecond]: ReportChartUnit.ErrorsPerSecond,
    [ReportChartTestMetricType.HitsPerSecond]: ReportChartUnit.HitsPerSecond,
    [ReportChartTestMetricType.Pct95ResponseTimeSec]: ReportChartUnit.Second,
    [ReportChartTransactionMetricType.AvgTransactionTimeSec]: ReportChartUnit.Second,
    [ReportChartTransactionMetricType.Pct95ResponseTimeSec]: ReportChartUnit.Second,
    [ReportChartTransactionMetricType.TransactionErrorsPerSecond]: ReportChartUnit.TransactionErrorsPerSecond,
    [ReportChartTransactionMetricType.TransactionsPerSeconds]: ReportChartUnit.TransactionsPerSecond
};
const reportChartSerieTemplateByChartType = {
    [ReportChartType.SystemMetric]: ChartSerieSystemMetricParamsContainer,
    [ReportChartType.TransactionMetric]: ChartSerieTransactionParamsContainer
};
const getReportChartTypeItemIntlMessageByValue = (value) => ({
    defaultMessage: value,
    id: `account.report.editor.chart.type.${value}`
});
export const getReportChartTypeItems = () => Object.values(ReportChartType).map((value) => ({
    id: value,
    intlMessage: getReportChartTypeItemIntlMessageByValue(value),
    text: value,
    value
}));
const getReportChartMetricTypeItemIntlMessageByValue = (value) => ({
    defaultMessage: value,
    id: `account.report.editor.chart.metric.type.${value}`
});
const getReportChartTitleMetricTypeItemIntlMessageByValue = (value) => ({
    defaultMessage: value,
    id: `account.report.editor.chart.title.metric.type.${value}`
});
const generateReportChartMetricTypeItemUnitIntlMessageByValue = (unit) => ({
    defaultMessage: unit,
    id: `account.report.editor.chart.axis.unit.${unit}.label`
});
const getReportChartUnitByMetricType = (metricType) => Object.keys(reportChartUnitByMetricType).includes(metricType) ? reportChartUnitByMetricType[metricType] : "";
export const getReportChartMetricTypeItemsByChartType = (chartType) => chartType && chartType in reportMetricTypeByChartType
    ? (reportMetricTypeByChartType[chartType] || []).map((value) => {
        const unit = getReportChartUnitByMetricType(value);
        return {
            id: value,
            metricIntlMessage: getReportChartMetricTypeItemIntlMessageByValue(value),
            metricChartTitleIntlMessage: getReportChartTitleMetricTypeItemIntlMessageByValue(value),
            text: value,
            unit,
            unitIntlMessage: generateReportChartMetricTypeItemUnitIntlMessageByValue(unit),
            value
        };
    })
    : [];
const getReportCharteAggregationTimeIntervalItemIntlMessageByValue = (value) => ({
    defaultMessage: value,
    id: `account.report.editor.chart.aggregation.interval.${value}`
});
export const getReportChartAggregationTimeIntervalItems = () => Object.values(ReportChartAggregationInterval).map((timeUnit) => ({
    id: timeUnit,
    intlMessage: getReportCharteAggregationTimeIntervalItemIntlMessageByValue(timeUnit),
    text: timeUnit,
    value: timeUnit
}));
export const getReportChartSerieTemplateByChartType = (chartType) => chartType && chartType in reportChartSerieTemplateByChartType
    ? reportChartSerieTemplateByChartType[chartType]
    : ChartSerieCommonParamsContainer;
