import React from "react";
import { isEmpty } from "@boomq/utils";
import { Tooltip } from "@boomq/uikit";
import { StyledIconRegExp, ExtractorsCount, ExtractorsIcon, ExtractorsIconWithCounter, tooltipTheme } from "./styled";
import { ExtractorMatchType, ExtractorType } from "../../../../helpers/types";
const renderExtractorCounter = (extractResponse) => {
    const extractorsCount = (extractResponse || []).length;
    return extractorsCount > 1 ? React.createElement(ExtractorsCount, null, extractorsCount) : React.createElement(React.Fragment, null);
};
const getMatchTypeText = ({ matchType, value }) => matchType === ExtractorMatchType.Specific ? `${matchType}: ${value}` : matchType;
const getExtractorTooltipText = (index, { match, params, type, variable }) => {
    switch (type) {
        case ExtractorType.RegEx: {
            return {
                defaultMessage: "RegExp: {variable}, {re}, {groupNumber}, {fieldToCheck}, {match}",
                id: `account.request.group.editor.element.request.extract.from.response.icon.tooltip.${ExtractorType.RegEx}`,
                values: {
                    fieldToCheck: params.fieldToCheck,
                    groupNumber: params.groupNumber,
                    variable,
                    re: params.re,
                    match: getMatchTypeText(match)
                }
            };
        }
        case ExtractorType.JsonPath: {
            return {
                defaultMessage: "JsonPath: {variable}, {jsonpath}, {match}",
                id: `account.request.group.editor.element.request.extract.from.response.icon.tooltip.${ExtractorType.JsonPath}`,
                values: {
                    variable,
                    jsonpath: params.jsonpath,
                    match: getMatchTypeText(match)
                }
            };
        }
        case ExtractorType.XPath2: {
            return {
                defaultMessage: "XPath: {variable}, {xpath}, {match}",
                id: `account.request.group.editor.element.request.extract.from.response.icon.tooltip.${ExtractorType.XPath2}`,
                values: {
                    variable,
                    xpath: params.xpath,
                    match: getMatchTypeText(match)
                }
            };
        }
        case ExtractorType.Boundary: {
            return {
                defaultMessage: "Boundary: {variable}, {leftBorder}, {rightBorder}, {fieldToCheck}, {match}",
                id: `account.request.group.editor.element.request.extract.from.response.icon.tooltip.${ExtractorType.Boundary}`,
                values: {
                    fieldToCheck: params.fieldToCheck,
                    leftBorder: params.leftBorder,
                    rightBorder: params.rightBorder,
                    variable,
                    match: getMatchTypeText(match)
                }
            };
        }
        default: {
            return {
                defaultMessage: "Extractor: none",
                id: "account.request.group.editor.element.request.extract.from.response.icon.tooltip.none"
            };
        }
    }
};
const getExtractorsTooltipText = (extractResponse) => (extractResponse || []).map((extractor, index) => (Object.assign({ key: index }, getExtractorTooltipText(index, extractor))));
export const RequestExtractorIcon = ({ extractResponse }) => isEmpty(extractResponse) ? (React.createElement(React.Fragment, null)) : (React.createElement(ExtractorsIconWithCounter, null,
    React.createElement(Tooltip, { title: getExtractorsTooltipText(extractResponse), theme: tooltipTheme },
        React.createElement(ExtractorsIcon, null,
            React.createElement(StyledIconRegExp, null))),
    renderExtractorCounter(extractResponse)));
