var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { stopEvent } from "@boomq/utils";
import { DEFAULT_TEXT_COLOR } from "./constants";
import { LabelBody, LabelName, StyledIconClose } from "./styled";
import { Tooltip } from "../Tooltip";
const renderLabelName = ({ name, textColor, title }) => typeof title === "string" ? (React.createElement(Tooltip, { title: title },
    React.createElement(LabelName, { textcolor: textColor }, name))) : (React.createElement(LabelName, { textcolor: textColor }, name));
export const Label = (_a) => {
    var { color, id, onDelete, name, textColor = DEFAULT_TEXT_COLOR, title } = _a, rest = __rest(_a, ["color", "id", "onDelete", "name", "textColor", "title"]);
    const deleteHandler = (e) => {
        stopEvent(e);
        return typeof onDelete === "function" ? onDelete({ id, name }) : undefined;
    };
    const renderIconClose = (textColor) => onDelete ? React.createElement(StyledIconClose, { onClick: deleteHandler, textcolor: textColor }) : React.createElement(React.Fragment, null);
    return (React.createElement(LabelBody, Object.assign({ color: color }, rest),
        renderLabelName({ name, textColor, title }),
        renderIconClose(textColor)));
};
