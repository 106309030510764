export const CHART_ANOMALIES_LABEL = {
    defaultMessage: "Anomalies",
    id: "account.chart.anomalies.label"
};
export const CHART_METRICS_LABEL = {
    defaultMessage: "Metrics",
    id: "account.chart.metrics.label"
};
export const ICON_INFO_TOOLTIP = {
    defaultMessage: "Learn more",
    id: "account.chart.anomaly.info"
};
