import { RequestType } from "../../models/request/enums";
import { RequestTimerType } from "../types";
export const ASSERTION_LABEL_DEFAULT_VALUE = "boomq_assertion";
export const BOOMQ_LOAD_PROFILE_MODEL_SCHEMA_NAME = "BoomqLoadProfile";
export const BOOMQ_TEST_PLAN_MODEL_SCHEMA_NAME = "BoomqTestPlan";
export const CONSTANT_TIMER_MODEL_SCHEMA_NAME = "ConstantTimer";
export const CONTROLLER_MODEL_SCHEMA_NAME = "Controller";
export const DEFAULT_CONFIG_ELEMENT_DATA = {};
export const DEFAULT_CONTENT_MODEL_VERSION = "2.0.0";
export const DEFAULT_CSV_PARAMETER_FILE_ENCODING = "UTF-8";
export const DEFAULT_HTTP_COOKIE_MANAGER_LABEL = "Http cookie manager";
export const DEFAULT_HTTP_HEADER_MANAGER_LABEL = "Http header manager";
export const DEFAULT_HTTP_REQUEST_DEFAULTS_LABEL = "Http request defaults";
export const DEFAULT_RANDOM_CSV_DATASET_CONFIG_LABEL_PREFIX = "Random Csv dataset";
export const DEFAULT_RESPONSE_TIMEOUT_VALUE = 120;
export const DEFAULT_CONNECT_TIMEOUT_VALUE = 60;
export const DEFAULT_SAMPLER_CONTROLLER_TYPE = RequestType.Http;
export const DEFAULT_TEST_PROJECT_SETTINGS_SET_NAME_PREFIX = "untitled_settings_set";
export const DEFAULT_TIMER_TYPE = RequestTimerType.Constant;
export const DEFAULT_TIMER_VALUE = 0;
export const DEFAULT_USER_DEFINED_VARIABLES_LABEL = "User defined variables";
export const EXTRACTOR_LABEL_DEFAULT_VALUE = "boomq_extractor";
export const FOR_EACH_CONTROLLER_MODEL_SCHEMA_NAME = "ForEachControllerProperties";
export const GRPC_REQUEST_PROPERTIES_MODEL_SCHEMA_NAME = "GrpcRequestProperties";
export const HTTP_COOKIE_MANAGER_MODEL_SCHEMA_NAME = "HttpCookieManager";
export const HTTP_HEADER_MANAGER_MODEL_SCHEMA_NAME = "HttpHeaderManager";
export const HTTP_REQUEST_DEFAULTS_MODEL_SCHEMA_NAME = "HttpRequestDefaults";
export const HTTP_REQUEST_PROPERTIES_MODEL_SCHEMA_NAME = "HttpRequestProperties";
export const HTTP_SAMPLER_QUERY_PARAMETER_MODEL_SCHEMA_NAME = "HttpSamplerQueryParameter";
export const IF_CONTROLLER_MODEL_SCHEMA_NAME = "IfControllerProperties";
export const LOOP_CONTROLLER_MODEL_SCHEMA_NAME = "LoopControllerProperties";
export const RANDOM_CSV_DATASET_CONFIG_MODEL_SCHEMA_NAME = "RandomCsvDataSetConfig";
export const TEST_PLAN_LOAD_PROFILE_MODEL_SCHEMA_NAME = "TestPlanLoadProfile";
export const TIME_COEFFICIENT = 60;
export const TIMER_LABEL_DEFAULT_VALUE = "boomq_timer";
export const TRANSACTION_CONTROLLER_MODEL_SCHEMA_NAME = "TransactionControllerProperties";
export const UNIFORM_RANDOM_TIMER_MODEL_SCHEMA_NAME = "UniformRandomTimer";
export const USER_DEFINED_PARAMETERS_MODEL_SCHEMA_NAME = "UserDefinedVariables";
export const VARIABLE_MODEL_SCHEMA_NAME = "Variable";
export const WHILE_CONTROLLER_MODEL_SCHEMA_NAME = "WhileControllerProperties";
