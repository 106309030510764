import { call, put, takeEvery } from "redux-saga/effects";
import { configFailed, configRequest, configSuccess } from "../actions/config";
import { getConfig } from "../../helpers/api";
import { configFile } from "../../helpers/applicationParams";
import { parseHttpRequestDefaults, prepareTimerFromDtoByType } from "../../helpers/parsers";
export function* configReadingFlow() {
    try {
        const { data } = yield call(getConfig, configFile);
        yield put(configSuccess(Object.assign(Object.assign({}, data), { defaultTimer: prepareTimerFromDtoByType(data.defaultTimer), httpRequestDefaults: parseHttpRequestDefaults(data.httpRequestDefaults) })));
    }
    catch (e) {
        console.error("Config file not found");
        yield put(configFailed());
    }
}
export function* configFlow() {
    yield takeEvery(configRequest, configReadingFlow);
}
