import axios from "axios";
import { objectToParams } from "@boomq/utils";
import { authServiceName } from "../helpers/applicationParams";
const location = window.location;
const servicePort = location.port ? `:${location.port}` : "";
export const authSrvName = authServiceName ? `${authServiceName}` : "auth-srv";
export const getAuthSrvUrl = () => `${location.protocol}//${location.hostname}${servicePort}/${authSrvName}`;
export const getOAuth2SrvUrl = () => `${getAuthSrvUrl()}/oauth2/authorization`;
const keys = { accept: "Accept", auth: "Authorization" };
const axiosParams = {
    headers: { Accept: "*/*" },
    withCredentials: true
};
axios.defaults.headers.post[keys.accept] = "*/*";
export const instance = axios.create(axiosParams);
const teamInstance = axios.create(axiosParams);
export const setTokenApi = (jwt) => {
    instance.defaults.headers.common[keys.auth] = `Bearer ${jwt}`;
};
export const setTeamTokenApi = (jwt) => {
    teamInstance.defaults.headers.common[keys.auth] = `Bearer ${jwt}`;
};
export const clearTokenApi = () => {
    instance.defaults.headers.common[keys.auth] = undefined;
};
export const clearTeamTokenApi = () => {
    teamInstance.defaults.headers.common[keys.auth] = undefined;
};
const addMemberListToFormData = (formData, teamMemberList) => {
    const teamMembersBlob = new Blob([JSON.stringify(teamMemberList)], { type: "application/json" });
    formData.append("teamMemberList", teamMembersBlob);
    return formData;
};
const prepareTeamData = ({ team, teamImage, teamMemberList }) => {
    const formData = new FormData();
    const teamBlob = new Blob([JSON.stringify(team)], { type: "application/json" });
    formData.append("team", teamBlob);
    formData.append("teamImage", teamImage);
    return Array.isArray(teamMemberList) && teamMemberList.length > 0
        ? addMemberListToFormData(formData, teamMemberList)
        : formData;
};
export const activateAccount = (code) => instance.post("/api/v1/register/confirm", { code });
export const changeUserParams = (params) => instance.put(`${getAuthSrvUrl()}/user`, params);
export const resetClientPassword = (params) => instance.post("/api/v1/password/reset", params);
export const resetClientPasswordByEmail = (userEmail) => instance.post(`${getAuthSrvUrl()}/resetPasswordToken?userEmail=${userEmail}`);
export const resetClientPasswordByToken = (token) => instance.post(`${getAuthSrvUrl()}/resetPasswordToken/${token}`);
export const recoveryClientPassword = (params) => instance.post("/api/v1/password/reset/confirm", params);
export const getClientJwt = (code) => instance.post("/api/v1/authorize", { code });
export const getAuthorizeUser = () => instance.get(`${getAuthSrvUrl()}/user`);
export const getAuthProviders = () => instance.get(`${getAuthSrvUrl()}/identityProvider`);
export const getClientWebsites = ({ type, userId }) => instance.get(`${getAuthSrvUrl()}/analytic/site?type=${type}&identityUserId=${userId}`);
export const getOAuth2AuthorizationCodeUrl = (serviceType) => instance.get(`/auth-srv/oauth/link?type=${serviceType}`);
export const getSiteCustomAnalytics = (params) => instance.get(`${getAuthSrvUrl()}/analytic${objectToParams(params, "?")}`);
export const createSecretToken = () => instance.post(`${getAuthSrvUrl()}/secret`);
export const deleteSecretToken = (secretToken) => instance.delete(`${getAuthSrvUrl()}/secret`, { data: { secretToken } });
export const createAdminTeam = (data) => instance.post(`${getAuthSrvUrl()}/admin/team`, prepareTeamData(data));
export const createTeam = (data) => instance.post(`${getAuthSrvUrl()}/team`, prepareTeamData(data));
export const deleteAdminTeamMember = (teamId, data) => instance.delete(`${getAuthSrvUrl()}/admin/teamMember?teamId=${teamId}`, { data });
export const deleteTeamMember = (teamId, data) => teamInstance.delete(`${getAuthSrvUrl()}/teamMember?teamId=${teamId}`, { data });
export const getAdminTeam = (teamId) => instance.get(`${getAuthSrvUrl()}/admin/team/${teamId}`);
export const getAdminTeams = (params) => instance.get(`${getAuthSrvUrl()}/admin/team${objectToParams(params, "?")}`);
export const getAdminTeamMembers = (params) => instance.get(`${getAuthSrvUrl()}/admin/teamMember${objectToParams(params, "?")}`);
export const getAdminUsers = (params) => instance.get(`${getAuthSrvUrl()}/admin/user${objectToParams(params, "?")}`);
export const getSecretToken = () => instance.get(`${getAuthSrvUrl()}/secret`);
export const getTeam = (teamId) => instance.get(`${getAuthSrvUrl()}/team/${teamId}`);
export const getTeamMembers = (params) => teamInstance.get(`${getAuthSrvUrl()}/teamMember${objectToParams(params, "?")}`);
export const getTeamMemberPermissionsWithToken = (teamId) => instance.get(`${getAuthSrvUrl()}/teamMember/teamContext?teamId=${teamId}`);
export const getUserTeams = (params) => instance.get(`${getAuthSrvUrl()}/team${objectToParams(params, "?")}`);
export const getWorldTime = (url) => axios(url);
export const inviteAdminTeamMember = (teamId, data) => instance.post(`${getAuthSrvUrl()}/admin/teamMember?teamId=${teamId}`, data);
export const inviteTeamMembers = (teamId, data) => teamInstance.post(`${getAuthSrvUrl()}/teamMember?teamId=${teamId}`, data);
export const resetAdminUserPassword = (userId) => instance.post(`${getAuthSrvUrl()}/admin/resetPasswordToken?userId=${userId}`);
export const updateAdminTeam = (data) => instance.put(`${getAuthSrvUrl()}/admin/team`, prepareTeamData(data));
export const updateAdminTeamMembers = (teamId, data) => instance.put(`${getAuthSrvUrl()}/admin/teamMember?teamId=${teamId}`, data);
export const updateTeam = (data) => teamInstance.put(`${getAuthSrvUrl()}/team`, prepareTeamData(data));
export const updateTeamMembers = (teamId, data) => teamInstance.put(`${getAuthSrvUrl()}/teamMember?teamId=${teamId}`, data);
