var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import PopperJS from "popper.js";
import { setRef, useForkRef } from "@boomq/utils";
import { Portal } from "../Portal";
const createChainedFunction = (...funcs) => funcs.reduce((acc, func) => !func
    ? acc
    : function chainedFunction(...args) {
        acc.apply(this, args);
        func.apply(this, args);
    }, () => { });
const flipPlacement = (placement, theme) => {
    const direction = (theme && theme.direction) || "ltr";
    if (direction === "ltr") {
        return placement;
    }
    switch (placement) {
        case "bottom-end":
            return "bottom-start";
        case "bottom-start":
            return "bottom-end";
        case "top-end":
            return "top-start";
        case "top-start":
            return "top-end";
        default:
            return placement;
    }
};
const getAnchorEl = (anchorEl) => (typeof anchorEl === "function" ? anchorEl() : anchorEl);
const useEnhancedEffect = typeof window !== "undefined" ? React.useLayoutEffect : React.useEffect;
const defaultPopperOptions = {};
const Popper = function Popper(props, ref) {
    const { anchorEl, children, container, disablePortal = false, keepMounted = false, modifiers, open, placement: initialPlacement = "bottom", popperOptions = defaultPopperOptions, popperRef: popperRefProp, transition = false } = props, other = __rest(props, ["anchorEl", "children", "container", "disablePortal", "keepMounted", "modifiers", "open", "placement", "popperOptions", "popperRef", "transition"]);
    const tooltipRef = React.useRef(null);
    const ownRef = useForkRef(tooltipRef, ref);
    const popperRef = React.useRef(null);
    const handlePopperRef = useForkRef(popperRef, popperRefProp);
    const handlePopperRefRef = React.useRef(handlePopperRef);
    useEnhancedEffect(() => {
        handlePopperRefRef.current = handlePopperRef;
    }, [handlePopperRef]);
    React.useImperativeHandle(popperRefProp, () => popperRef.current, []);
    const [exited, setExited] = React.useState(true);
    const theme = {};
    const rtlPlacement = flipPlacement(initialPlacement, theme);
    const [placement, setPlacement] = React.useState(rtlPlacement);
    React.useEffect(() => (popperRef.current && popperRef.current.update()) || undefined);
    const handleClose = () => {
        popperRef.current && popperRef.current.destroy();
        popperRef.current && handlePopperRefRef.current(null);
    };
    const handleOpen = React.useCallback(() => {
        if (!tooltipRef.current || !anchorEl || !open) {
            return;
        }
        popperRef.current && handleClose();
        const handlePopperUpdate = (data) => setPlacement(data.placement);
        const popper = new PopperJS(getAnchorEl(anchorEl), tooltipRef.current, Object.assign(Object.assign({ placement: rtlPlacement }, popperOptions), { modifiers: Object.assign(Object.assign(Object.assign({}, (disablePortal
                ? {}
                : {
                    preventOverflow: {
                        boundariesElement: "window"
                    }
                })), modifiers), popperOptions.modifiers), onCreate: createChainedFunction(handlePopperUpdate, popperOptions.onCreate), onUpdate: createChainedFunction(handlePopperUpdate, popperOptions.onUpdate) }));
        handlePopperRefRef.current(popper);
    }, [anchorEl, disablePortal, modifiers, open, rtlPlacement, popperOptions]);
    const handleRef = React.useCallback((node) => {
        setRef(ownRef, node);
        handleOpen();
    }, [ownRef, handleOpen]);
    const handleEnter = () => {
        setExited(false);
    };
    const handleExited = () => {
        setExited(true);
        handleClose();
    };
    React.useEffect(() => {
        handleOpen();
    }, [handleOpen]);
    React.useEffect(() => {
        return () => handleClose();
    }, []);
    React.useEffect(() => (!open && !transition && handleClose()) || undefined, [open, transition]);
    const childProps = { placement };
    transition &&
        (childProps.TransitionProps = {
            in: open,
            onEnter: handleEnter,
            onExited: handleExited
        });
    return !keepMounted && !open && (!transition || exited) ? null : (React.createElement(Portal, { disablePortal: disablePortal, container: container },
        React.createElement("div", Object.assign({ ref: handleRef, role: "tooltip" }, other, { style: Object.assign({ position: "fixed", top: 0, left: 0, zIndex: 99999 }, other.style) }), typeof children === "function" ? children(childProps) : children)));
};
export default React.forwardRef(Popper);
