import { SettingsSetSystemMetricMonitoring } from "../types";
const generateLabelMessageByType = (type) => `account.test.project.settings.set.monitoring.system.metric.item.${type}.label`;
const generateTitleMessageByType = (type) => `account.test.project.settings.set.monitoring.system.metric.item.${type}.title`;
export const SYSTEM_METRIC_LABEL_BY_TYPE = {
    [SettingsSetSystemMetricMonitoring.Disk]: {
        defaultMessage: "Disk input/output system",
        id: generateLabelMessageByType(SettingsSetSystemMetricMonitoring.Disk)
    },
    [SettingsSetSystemMetricMonitoring.Docker]: {
        defaultMessage: "Docker metrics",
        id: generateLabelMessageByType(SettingsSetSystemMetricMonitoring.Docker)
    },
    [SettingsSetSystemMetricMonitoring.Kernel]: {
        defaultMessage: "Kernel metrics",
        id: generateLabelMessageByType(SettingsSetSystemMetricMonitoring.Kernel)
    },
    [SettingsSetSystemMetricMonitoring.Tcp]: {
        defaultMessage: "TCP metrics",
        id: generateLabelMessageByType(SettingsSetSystemMetricMonitoring.Tcp)
    },
    [SettingsSetSystemMetricMonitoring.Udp]: {
        defaultMessage: "UDP metrics",
        id: generateLabelMessageByType(SettingsSetSystemMetricMonitoring.Udp)
    },
    DEFAULT_VALUE: {
        defaultMessage: "",
        id: "title.empty"
    }
};
export const SYSTEM_METRIC_TITLE_BY_TYPE = {
    [SettingsSetSystemMetricMonitoring.Disk]: {
        defaultMessage: "Disk input/output system: collects read/write operations of a disk.",
        id: generateTitleMessageByType(SettingsSetSystemMetricMonitoring.Disk)
    },
    [SettingsSetSystemMetricMonitoring.Docker]: {
        defaultMessage: "Docker metrics: uses the Docker Engine API to gather metrics on running docker containers.",
        id: generateTitleMessageByType(SettingsSetSystemMetricMonitoring.Docker)
    },
    [SettingsSetSystemMetricMonitoring.Kernel]: {
        defaultMessage: "Kernel metrics: collects various Kernel metrics such as boot_time, context_switches, interrupts etc.",
        id: generateTitleMessageByType(SettingsSetSystemMetricMonitoring.Kernel)
    },
    [SettingsSetSystemMetricMonitoring.Tcp]: {
        defaultMessage: "TCP metrics: collects such metrics as established connections, time wait and sockets counts.",
        id: generateTitleMessageByType(SettingsSetSystemMetricMonitoring.Tcp)
    },
    [SettingsSetSystemMetricMonitoring.Udp]: {
        defaultMessage: "UDP metrics",
        id: generateTitleMessageByType(SettingsSetSystemMetricMonitoring.Udp)
    },
    DEFAULT_VALUE: {
        defaultMessage: "",
        id: "title.empty"
    }
};
