export const requestTimersSettingsNotification = ({ action, timersCount }) => ({
    afterHidingFunc: action,
    textTheme: {
        left: "20px",
        position: "absolute",
        top: "-44px"
    },
    text: {
        defaultMessage: "Duration of {timersCount} timers are modified",
        id: "account.modal.timers.settings.applied.successfully",
        values: {
            timersCount
        }
    }
});
const testProjectSaveNotification = ({ action, linkProject, theme, textTheme }) => ({
    afterHidingFunc: action,
    textTheme: textTheme
        ? textTheme
        : {
            left: "20px",
            position: "absolute",
            top: "-44px"
        },
    text: linkProject
        ? {
            defaultMessage: "Test is saved successfully. See {linkProject}",
            id: "account.new.test.project.saved.success.text_link",
            values: {
                linkProject
            }
        }
        : {
            defaultMessage: "Test is saved successfully",
            id: "account.new.test.project.saved.success.text"
        },
    theme
});
export const profileSettingsNotification = ({ action, linkProject }) => testProjectSaveNotification({
    action,
    linkProject,
    textTheme: {
        left: "20px",
        position: "absolute",
        top: "-44px"
    }
});
export const startTestNotification = ({ action, linkProject }) => testProjectSaveNotification({
    action,
    linkProject,
    theme: { position: "absolute", top: "-44px" },
    textTheme: { left: "auto", right: "auto" }
});
