import styled from "styled-components";
import { Block, Button, FontBodyBigBold, FontBodyMediumMedium, FontBodySmall, H2Styles } from "@boomq/uikit";
import { media } from "../../../styled/response";
export const WrapperContent = styled.div `
    margin: 0 auto;
    max-width: 1258px;
    display: flex;
    justify-content: space-between;
    padding-top: 68px;
    padding-left: 28px;
    padding-right: 28px;
    ${media.giant} {
        padding-top: 34px;
    }
    ${media.tablet} {
        flex-direction: column;
    }
`;
export const SectionWelcome = styled.div `
    max-width: 584px;
`;
export const Title = styled.h2 `
    ${H2Styles}
    font-size: 32px;
`;
export const Text = styled.p `
    ${FontBodyMediumMedium}
    max-width: 500px;
    padding-top: 17px;
    padding-bottom: 56px;
    line-height: 1.5;
`;
export const SectionCards = styled.div `
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 12px;
    max-width: 540px;
    width: 100%;
    max-height: 672px;
    padding-top: 56px;
    row-gap: 12px;
`;
export const CardContainer = styled.div `
    ${Block}
    padding: 24px;
    display: flex;
    justify-content: space-between;
    min-height: 174px;
    flex: auto;
`;
export const CardColumnWrapper = styled.div `
    max-width: 278px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    :last-child {
        align-items: end;
    }
`;
export const CardTitle = styled.h3 `
    ${FontBodyBigBold};
    width: 185px;
`;
export const CardText = styled.p `
    ${FontBodyMediumMedium}
    line-height: 1.5;
`;
export const CardButton = styled(Button) `
    ${FontBodySmall}
    padding: 10px 20px;
    ${media.desktop} {
        ${FontBodySmall}
    }
    width: 130px;
    background-color: #5f8ffe;
    border-radius: 8px;
    :hover {
        background-color: #5f8ffe;
    }
`;
export const IconsBlock = styled.div `
    display: flex;
    align-items: center;
    gap: 5px;
`;
