import styled, { css } from "styled-components";
import { Color } from "@boomq/uikit";
export const Item = styled.i `
    position: absolute;
    display: block;
    width: 33px;
    height: 4px;
    border-radius: 7px;
    transition: all 0.3s ease-in-out;
    &:first-child {
        top: 0;
    }
    &:nth-child(2) {
        top: 50%;
        transform: translateY(-50%);
    }
    &:last-child {
        bottom: 0;
    }
`;
export const MenuButton = styled.button `
    background: transparent;
    border: none;
    cursor: pointer;
    height: 25px;
    outline: none;
    position: relative;
    width: 33px;
    z-index: 9999;
    ${Item} {
        background: #8d97af;
    }
    ${({ isOpen }) => isOpen
    ? css `
                  ${Item} {
                      &:first-child {
                          transform: rotate(45deg) translate(8px, 7px);
                      }
                      &:nth-child(2) {
                          opacity: 0;
                      }
                      &:last-child {
                          transform: rotate(-45deg) translate(8px, -8px);
                      }
                  }
              `
    : css ``}
`;
export const MenuSidebar = styled.div `
    align-items: flex-start;
    background: ${Color.white};
    border-top: 1px solid #e4ebf2;
    bottom: 0;
    display: flex;
    flex-direction: column;
    padding-left: 40px;
    padding-top: 116px;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 999;
    ul {
        margin-top: 50px;

        li a {
            color: #022349;
        }
    }
`;
export const MenuBackground = styled.div `
    background: rgba(0, 0, 0, 0.4);
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99;
`;
