import { getUniqueId } from "@boomq/utils";
import { ReportTableColumnDiffTargetType, ReportTableColumnDiffValueType, ReportTableColumnType, ReportTableType } from "../../../models/reportEditor/enums";
export const generateTableParamsForSLAByRequest = ({ tableId, tableTitle, testId }) => ({
    id: tableId,
    type: ReportTableType.RequestSLATable,
    name: tableTitle,
    testFilters: [{ testId }],
    columns: [
        {
            id: getUniqueId(),
            displaySlaRestrictionType: true,
            type: ReportTableColumnType.SLAData,
            name: "SLA type"
        },
        {
            id: getUniqueId(),
            displayRequestName: true,
            type: ReportTableColumnType.RequestData,
            name: "Request"
        },
        {
            id: getUniqueId(),
            displayThreadGroupName: true,
            type: ReportTableColumnType.RequestData,
            name: "Group"
        },
        {
            id: getUniqueId(),
            displayTimePeriod: true,
            type: ReportTableColumnType.SLAData,
            name: "Time period"
        },
        { id: getUniqueId(), displayOperation: true, type: ReportTableColumnType.SLAData, name: "Condition" },
        { id: getUniqueId(), displayValue: true, type: ReportTableColumnType.SLAData, name: "Value" },
        { id: getUniqueId(), testId, type: ReportTableColumnType.SLAResult, name: "Result" },
        {
            id: getUniqueId(),
            diffTargetType: ReportTableColumnDiffTargetType.SLAValue,
            diffValueType: ReportTableColumnDiffValueType.Absolute,
            testId,
            type: ReportTableColumnType.SLADiff,
            name: "Delta"
        },
        {
            id: getUniqueId(),
            diffTargetType: ReportTableColumnDiffTargetType.SLAValue,
            diffValueType: ReportTableColumnDiffValueType.Relative,
            testId,
            type: ReportTableColumnType.SLADiff,
            name: "Delta, %"
        }
    ]
});
