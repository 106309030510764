import { IMPORT_FILE_EXCEPTION_MESSAGE } from "./constants";
import { API_ERROR_TITLE } from "./intl";
import { showError } from "./showError";
import { apiErrors } from "../../../data/content/errorMessages";
export function* uploadCsvFileFailureFlow(action) {
    var _a;
    const exceptionMessage = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.exceptionMessage;
    const params = exceptionMessage === IMPORT_FILE_EXCEPTION_MESSAGE ? { code: exceptionMessage } : {};
    return yield* showError(params);
}
export function* uploadImportFileFailureFlow(action) {
    var _a;
    return yield* showError({
        modalInfo: {
            description: apiErrors((_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.exceptionMessage),
            title: API_ERROR_TITLE
        }
    });
}
