import React from "react";
import { connect } from "react-redux";
import { ConfirmForm } from "../../../common/ConfirmForm";
import { excludeSlaReportProfileTest } from "../../../../redux/actions/slaReportProfile";
const connector = connect(null, { excludeSlaReportProfileTest });
const customButtonTheme = {
    minWidth: "300px",
    paddingLeft: "10px!important",
    paddingRight: "10px!important",
    width: "40%!important"
};
const ExcludeChartTestConfirmForm = ({ excludeSlaReportProfileTest, id }) => (React.createElement(ConfirmForm, { confirmButton: {
        action: () => excludeSlaReportProfileTest({ id }),
        theme: customButtonTheme,
        title: {
            defaultMessage: "Yes, exclude test",
            id: "account.modal.sla.report.profile.exclude.test.confirm.button.yes"
        }
    }, cancelButton: {
        theme: customButtonTheme,
        title: {
            defaultMessage: "No, cancel excluding",
            id: "account.modal.sla.report.profile.exclude.test.confirm.button.no"
        }
    }, text: {
        description: [
            {
                defaultMessage: "This test will be excluded in all report items. Do you want to continue?",
                id: "account.modal.sla.report.profile.exclude.test.confirm.text"
            }
        ]
    } }));
export default connector(ExcludeChartTestConfirmForm);
