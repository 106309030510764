export const SLIDER_MARKS = {
    0: "low",
    3: "high"
};
export const SLIDER_MIN = 0;
export const SLIDER_STEP = 1;
export const ANOMALIES_DETAILS_LABELS = [
    { id: "account.test.details.anomaly.transaction.label.created", defaultMessage: "Created at" },
    { id: "account.test.details.anomaly.transaction.label.details", defaultMessage: "Anomaly details" }
];
