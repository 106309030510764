import { authFailureFlow, reloadFailureFlow } from "./auth";
import { uploadJmxFileFailureFlow } from "./jmx";
import { reportPFLBChartPreviewGenerationFailureFlow } from "./report";
import { secretTokenFailureFlow } from "./secretToken";
import { showError } from "./showError";
import { teamMemberProcessingFailureFlow } from "./team";
import { testDetailsFailureFlow } from "./testDetails";
import { saveTestProjectFailureFlow, testProjectDetailsFailureFlow } from "./testProject";
import { testProjectSettingsSetSavingFailureFlow } from "./testProjectSettingsSet";
import { uploadCsvFileFailureFlow, uploadImportFileFailureFlow } from "./uploadFile";
import authorizeActions from "../../actions/authorize";
import { uploadImportFileFailure, uploadJmxFileFailure } from "../../actions/importTestProjectData";
import newTestActions from "../../actions/newTest";
import { generateReportPflbChartSeriePreviewFailure } from "../../actions/report";
import { reloadFailure } from "../../actions/reload";
import { uploadCsvFileFailure } from "../../actions/requestParameters";
import { addTeamMemberFailure, changeTeamMemberFailure, excludeTeamMemberFailure } from "../../actions/teamMembers";
import { testDetailsFailure } from "../../actions/testDetails";
import { testProjectDetailsFailure } from "../../actions/testProjects";
import { saveTestProjectSettingsSetFailure } from "../../actions/testProjectSettingsSet";
const { authFailure, secretTokenFailure } = authorizeActions;
const errorProcessFuncByAction = {
    [addTeamMemberFailure.toString()]: teamMemberProcessingFailureFlow,
    [authFailure.toString()]: authFailureFlow,
    [changeTeamMemberFailure.toString()]: teamMemberProcessingFailureFlow,
    [excludeTeamMemberFailure.toString()]: teamMemberProcessingFailureFlow,
    [generateReportPflbChartSeriePreviewFailure.toString()]: reportPFLBChartPreviewGenerationFailureFlow,
    [reloadFailure.toString()]: reloadFailureFlow,
    [newTestActions.saveTestProjectFailure.toString()]: saveTestProjectFailureFlow,
    [saveTestProjectSettingsSetFailure.toString()]: testProjectSettingsSetSavingFailureFlow,
    [secretTokenFailure.toString()]: secretTokenFailureFlow,
    [testProjectDetailsFailure.toString()]: testProjectDetailsFailureFlow,
    [testDetailsFailure.toString()]: testDetailsFailureFlow,
    [uploadCsvFileFailure.toString()]: uploadCsvFileFailureFlow,
    [uploadJmxFileFailure.toString()]: uploadJmxFileFailureFlow,
    [uploadImportFileFailure.toString()]: uploadImportFileFailureFlow
};
export function* takeError(action) {
    const actionType = action === null || action === void 0 ? void 0 : action.type.toString();
    return Object.keys(errorProcessFuncByAction).includes(actionType)
        ? yield* errorProcessFuncByAction[actionType](action)
        : yield* showError();
}
