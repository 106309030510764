import React, { FC } from "react";
import { stopEvent } from "@boomq/utils";
import { CommonThreadGroupContainer, ProfileDistribution } from "@boomq/common";
import { IntlMessage } from "../../../../helpers/types";
import { LoadGeneratorsSelection } from "../../LoadGeneratorsSelection";
import { LoadGenerators, LoadGeneratorsLabel, Separator, ThreadGroupBody } from "./styled";
import { ThreadGroupLoadTypeSettingsContainer } from "../../../../containers/account/ThreadGroupLoadTypeSettings";
// import { TestProjectRequestAction } from "../../../../helpers/types";

interface Props {
    children: JSX.Element | JSX.Element[];
    id: string;
    name: string;
    perc: string;
    percInputUnit: { text: IntlMessage; title: IntlMessage };
}

interface Handlers {
    onChangeName: (value: string) => void;
}

export const ThreadGroup: FC<any> = ({ loadGeneratorLabel, ...restProps }: any): JSX.Element => {
    const clickHandler = (event): void => stopEvent(event);

    return (
        <ThreadGroupBody>
            <CommonThreadGroupContainer {...restProps}>
                <LoadGenerators onClick={clickHandler}>
                    <LoadGeneratorsLabel>{loadGeneratorLabel}</LoadGeneratorsLabel>
                    <LoadGeneratorsSelection requestGroup={restProps} />
                </LoadGenerators>
            </CommonThreadGroupContainer>

            {restProps.profileDistribution === ProfileDistribution.Users && restProps.isExpanded && (
                <>
                    <Separator />
                    <ThreadGroupLoadTypeSettingsContainer groupId={restProps.id} />
                </>
            )}
        </ThreadGroupBody>
    );
};
