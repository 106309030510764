import { handleActions } from "redux-actions";
import { getValueByPath } from "@boomq/utils";
import { configSuccess } from "../actions/config";
export const initialState = {
    defaultTimer: {},
    httpRequestDefaults: {}
};
export default handleActions({
    [configSuccess]: (state, action) => (action && action.payload ? action.payload : initialState)
}, initialState);
const getStateParam = (state, paramName) => getValueByPath(state, `config.${paramName}`, initialState[paramName]);
export const getConfigDefaultTimer = (state) => (Object.assign(Object.assign({}, getStateParam(state, "defaultTimer")), { enabled: true }));
export const getConfigHttpRequestDefaults = (state) => getStateParam(state, "httpRequestDefaults");
