import React from 'react';
import styled from 'styled-components';
import ReactSVG from 'react-svg';
import { FormattedMessage } from 'react-intl';
import RoundLoader from "../common/RoundLoader";
const CheckStatusMobile = ({ percent, title, img }) => (React.createElement(Element, null,
    React.createElement(RoundLoader, { percent: percent },
        React.createElement(Img, { src: img })),
    React.createElement(Text, null,
        React.createElement(FormattedMessage, Object.assign({}, title)))));
const Element = styled.div `    
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const Img = styled(ReactSVG) `
    fill: #176ED6;
    div {
      display: flex;
    }
`;
const Text = styled.p `
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    font-size: 16px;
    text-align: center;
    color: #4F4F4F;
`;
export default CheckStatusMobile;
