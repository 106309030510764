import { SlaRestrictionType, SlaTargetType, SlaUnit } from "../../models/sla/enums";
export const getSlaUnitLabelByRestrictionType = (type, restrictionType) => type === SlaTargetType.Transaction && restrictionType === SlaRestrictionType.Throughput
    ? `${restrictionType}_${SlaTargetType.Transaction}`
    : restrictionType;
export const getSlaUnitTextByRestrictionType = (type, restrictionType, paramName) => ({
    defaultMessage: SlaUnit[restrictionType],
    id: `account.sla.form.item.unit.${SlaUnit[getSlaUnitLabelByRestrictionType(type, restrictionType)]}.${paramName}`
});
export const getSlaUnitByRestrictionType = (type, restrictionType) => restrictionType
    ? {
        text: getSlaUnitTextByRestrictionType(type, restrictionType, "text"),
        title: getSlaUnitTextByRestrictionType(type, restrictionType, "title")
    }
    : undefined;
