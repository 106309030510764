import React from "react";
import { Color } from "@boomq/uikit";
import { chartLeftAxisLabel, chartLeftAxisLabelOrientation, chartRightAxisLabel, chartRightAxisLabelOrientation, VUsersLabel } from "./styled";
import AreaChart from "../../common/AreaChart";
import { TIMELINE_ERRORS, TIMELINE_THREADS, TIMELINE_THROUGHPUT } from "../../../helpers/testDetailsStats";
export const ThroughputChart = ({ chartData, chartErrorsLabel, chartRequestsLabel, chartRequestsErrorsLabel, chartVUsersLabel, leftYAxisValMaxLength, rightYAxisValMaxLength, yAxisDataKey }) => (React.createElement(AreaChart, { data: chartData, leftYAxisValMaxLength: leftYAxisValMaxLength, rightYAxisValMaxLength: rightYAxisValMaxLength, axis: {
        x: "time",
        y: [
            Object.assign({ dataKey: yAxisDataKey, label: React.createElement("text", Object.assign({}, chartLeftAxisLabel), chartRequestsErrorsLabel) }, chartLeftAxisLabelOrientation),
            Object.assign({ dataKey: TIMELINE_THREADS, label: React.createElement(VUsersLabel, Object.assign({}, chartRightAxisLabel), chartVUsersLabel) }, chartRightAxisLabelOrientation)
        ]
    }, areas: [
        {
            dataKey: TIMELINE_THROUGHPUT,
            fill: Color.green,
            fillOpacity: 0.6,
            name: chartRequestsLabel,
            stroke: Color.green,
            type: "monotone",
            yAxisId: "left"
        },
        {
            dataKey: TIMELINE_ERRORS,
            fill: Color.red,
            fillOpacity: 0.6,
            name: chartErrorsLabel,
            stroke: Color.red,
            type: "monotone",
            yAxisId: "left"
        },
        {
            dataKey: TIMELINE_THREADS,
            fillOpacity: 0,
            name: chartVUsersLabel,
            stroke: Color.blue,
            strokeWidth: 3,
            type: "linear",
            yAxisId: "right"
        }
    ] }));
