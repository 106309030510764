import { getUniqueId } from "@boomq/utils";
import { Color } from "@boomq/uikit";
import { SLA_STATUS_FAILURE_TEXT, SLA_STATUS_NOT_COUNTED_TEXT, SLA_STATUS_SUCCESS_TEXT } from "./intl";
import { SlaOperation, SlaRestrictionType, SlaStatus, SlaTargetType, SlaThreadGroupFilterType, SlaTransactionFilterType, SlaType } from "./enums";
import { SlaRequestContainer } from "../../containers/account/SlaRequest";
import { SlaSystemMetricsContainer } from "../../containers/account/SlaSystemMetrics";
import { SlaTestContainer } from "../../containers/account/SlaTest";
import { SlaThreadGroupContainer } from "../../containers/account/SlaThreadGroup";
import { SlaTransactionContainer } from "../../containers/account/SlaTransaction";
import { TestResultSlaState } from "../../models/testRun";
const generateNewSLAFormItemCommonData = (testDuration) => ({
    fromDate: 0,
    id: getUniqueId(),
    isValid: true,
    operation: SlaOperation.LessEquals,
    status: SlaStatus.NotCounted,
    toDate: testDuration,
    value: 0
});
export const newSLAFormItemDataByTargetType = {
    [SlaTargetType.Request]: (testDuration) => (Object.assign(Object.assign({}, generateNewSLAFormItemCommonData(testDuration)), { requestName: "", restrictionType: SlaRestrictionType.Average, targetType: SlaTargetType.Request, threadGroupId: SlaThreadGroupFilterType.AnyThreadGroup, transactionName: SlaTransactionFilterType.AnyTransaction, type: SlaType.Request })),
    [SlaTargetType.SystemMetricsHost]: (testDuration) => (Object.assign(Object.assign({}, generateNewSLAFormItemCommonData(testDuration)), { hostname: "", restrictionType: SlaRestrictionType.CpuUsagePercentAverage, targetType: SlaTargetType.SystemMetricsHost, type: SlaType.SystemMetricsHost })),
    [SlaTargetType.Test]: (testDuration) => (Object.assign(Object.assign({}, generateNewSLAFormItemCommonData(testDuration)), { restrictionType: SlaRestrictionType.Average, targetType: SlaTargetType.Test, type: SlaType.Test })),
    [SlaTargetType.ThreadGroup]: (testDuration) => (Object.assign(Object.assign({}, generateNewSLAFormItemCommonData(testDuration)), { restrictionType: SlaRestrictionType.Average, targetType: SlaTargetType.ThreadGroup, threadGroupId: "", threadGroupName: "", type: SlaType.ThreadGroup })),
    [SlaTargetType.Transaction]: (testDuration) => (Object.assign(Object.assign({}, generateNewSLAFormItemCommonData(testDuration)), { restrictionType: SlaRestrictionType.Average, targetType: SlaTargetType.Transaction, threadGroupId: SlaThreadGroupFilterType.AnyThreadGroup, transactionName: "", type: SlaType.Transaction }))
};
export const slaOperations = [SlaOperation.GreaterThen, SlaOperation.LessEquals];
export const slaSystemMetricRestrictionTypes = [
    SlaRestrictionType.CpuUsagePercentAverage,
    SlaRestrictionType.MemoryUsagePercentAverage
];
export const slaTestMetricRestrictionTypes = [
    SlaRestrictionType.Average,
    SlaRestrictionType.ErrorRate,
    SlaRestrictionType.Percentile,
    SlaRestrictionType.Throughput
];
export const slaTargetTypes = [
    SlaTargetType.Test,
    SlaTargetType.ThreadGroup,
    SlaTargetType.Transaction,
    SlaTargetType.Request,
    SlaTargetType.SystemMetricsHost
];
const slaStatusDescriptionBySlaStatus = {
    [SlaStatus.Success]: SLA_STATUS_SUCCESS_TEXT,
    [SlaStatus.Failure]: SLA_STATUS_FAILURE_TEXT
};
export const getSlaStatusDescriptionBySlaStatus = (slaStatus) => Object.keys(slaStatusDescriptionBySlaStatus).includes(slaStatus)
    ? slaStatusDescriptionBySlaStatus[slaStatus]
    : SLA_STATUS_NOT_COUNTED_TEXT;
const slaStatusDescriptionByTestResultSlaStatus = {
    [TestResultSlaState.Processing]: SLA_STATUS_SUCCESS_TEXT,
    [TestResultSlaState.Failed]: SLA_STATUS_FAILURE_TEXT,
    [TestResultSlaState.NoData]: SLA_STATUS_FAILURE_TEXT
};
export const getSlaStatusDescriptionByTestResultSlaStatus = (testResultSlaState) => Object.keys(slaStatusDescriptionByTestResultSlaStatus).includes(testResultSlaState)
    ? slaStatusDescriptionByTestResultSlaStatus[testResultSlaState]
    : SLA_STATUS_NOT_COUNTED_TEXT;
const slaColorBySlaTestResultSlaState = {
    [TestResultSlaState.Processing]: "none",
    [TestResultSlaState.Undefined]: "#BDBDBD",
    [TestResultSlaState.Failed]: Color.red,
    [TestResultSlaState.NoData]: Color.red
};
export const getSlaColorBySlaTestResultSlaState = (testResultSlaState) => Object.keys(slaColorBySlaTestResultSlaState).includes(testResultSlaState)
    ? slaColorBySlaTestResultSlaState[testResultSlaState]
    : undefined;
const slaColorBySlaStatus = {
    [SlaStatus.Success]: Color.green,
    [SlaStatus.Failure]: Color.red
};
export const getSlaColorBySlaStatus = (status) => Object.keys(slaColorBySlaStatus).includes(status) ? slaColorBySlaStatus[status] : Color.lightGrey95;
export const slaTabList = [
    {
        id: 0,
        component: {
            elementName: "SlaTest",
            props: {}
        },
        name: SlaTargetType.Test,
        title: { defaultMessage: "Test", id: "account.sla.form.tab.test.title" }
    },
    {
        id: 1,
        component: {
            elementName: "SlaThreadGroup",
            props: {}
        },
        name: SlaTargetType.ThreadGroup,
        title: { defaultMessage: "Thread group", id: "account.sla.form.tab.thread.group.title" }
    },
    {
        id: 2,
        component: {
            elementName: "SlaTransaction",
            props: {}
        },
        name: SlaTargetType.Transaction,
        title: { defaultMessage: "Transaction", id: "account.sla.form.tab.transaction.title" }
    },
    {
        id: 3,
        component: {
            elementName: "SlaRequest",
            props: {}
        },
        name: SlaTargetType.Request,
        title: { defaultMessage: "Request", id: "account.sla.form.tab.request.title" }
    },
    {
        id: 4,
        component: {
            elementName: "SlaSystemMetrics",
            props: {}
        },
        name: SlaTargetType.SystemMetricsHost,
        title: { defaultMessage: "System metrics", id: "account.sla.form.tab.system.metrics.title" }
    }
];
export const slaTargetElements = {
    SlaTest: SlaTestContainer,
    SlaThreadGroup: SlaThreadGroupContainer,
    SlaTransaction: SlaTransactionContainer,
    SlaRequest: SlaRequestContainer,
    SlaSystemMetrics: SlaSystemMetricsContainer
};
export const getSlaTargetTypes = () => slaTargetTypes;
