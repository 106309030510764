import styled from "styled-components";
import { media } from "../../styled/response";
export const ItemList = styled.ul `
    margin-left: 12%;
    &:first-child {
        margin-top: 0;
    }
    ${media.desktop} {
        margin-left: 0;
    }
    ${media.phone} {
        margin-top: 50px;
    }
`;
export const ItemListTitle = styled.li `
    color: #8d97af;
    font-size: 16px;
    font-weight: 600;
`;
export const ItemListItem = styled.li `
    margin-top: 16px;
    &:nth-child(2) {
        margin-top: 30px;
    }
`;
