import { handleActions } from 'redux-actions';
import { tariffsFailure, tariffsRequest, tariffsSuccess, tariffUpdate } from '../../actions/admin/tariffs';
const initialState = {
    currentPage: 0,
    error: null,
    tariffs: [],
    totalPages: 0,
    updating: []
};
export default handleActions({
    [tariffsRequest]: (state, action) => (Object.assign(Object.assign({}, state), { currentPage: action.payload.page, tariffs: [], updating: [...state.updating, action.payload.id] })),
    [tariffsSuccess]: (state, action) => (Object.assign(Object.assign({}, state), { tariffs: Array.isArray(action.payload.tariffs) ? action.payload.tariffs.map(tariff => (Object.assign(Object.assign({}, tariff), { isDirty: false }))) : [], totalPages: action.payload.totalPages, updating: state.updating.filter(item => item !== action.payload.id) })),
    [tariffsFailure]: (state, action) => (Object.assign(Object.assign({}, state), { error: action.payload, updating: state.updating.filter(item => item !== action.payload.id) })),
    [tariffUpdate]: (state, action) => (Object.assign(Object.assign({}, state), { tariffs: state.tariffs.map(tariff => {
            if (tariff.id !== action.payload.id) {
                return Object.assign({}, tariff);
            }
            return Object.assign(Object.assign(Object.assign({}, tariff), action.payload.data), { isDirty: false });
        }) }))
}, initialState);
export const getListOfTariffs = state => state.tariffs.tariffs;
export const getTariffsCurrentPage = state => state.tariffs.currentPage;
export const getTariffsTotalPages = state => state.tariffs.totalPages;
export const getTariffsUpdating = state => state.tariffs.updating;
