export const CRITERIA_TYPE_FILTER_BUTTON_LABEL = {
    defaultMessage: "set test run selection criteria",
    id: "account.sla.report.profile.settings.switcher.criteria.tests"
};
export const BUTTON_NEXT_LABEL = {
    defaultMessage: "Next",
    id: "account.sla.report.profile.settings.next"
};
export const SPECIFIC_TESTS_TYPE_FILTER_BUTTON_LABEL = {
    defaultMessage: "choose specific test runs",
    id: "account.sla.report.profile.settings.switcher.specific.tests"
};
export const TEST_LABEL_FILTER_LABEL = {
    defaultMessage: "select test run label",
    id: "account.sla.report.profile.settings.test.label.placeholder"
};
export const TEST_PROJECT_FILTER_LABEL = {
    defaultMessage: "select test",
    id: "account.sla.report.profile.settings.test.project.placeholder"
};
export const TEST_STATUS_FILTER_LABEL = {
    defaultMessage: "select test run status",
    id: "account.sla.report.profile.settings.test.status.placeholder"
};
export const TESTS_FILTER_LABEL = {
    defaultMessage: "select test runs",
    id: "account.sla.report.profile.settings.tests.placeholder"
};
export const TIME_FILTER_LABEL = {
    defaultMessage: "select time unit",
    id: "account.sla.report.profile.settings.time.unit.placeholder"
};
