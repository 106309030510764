import React, { useRef, useState } from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { excludeArrayFromArrayByParam, getValueByPath, useIntl } from "@boomq/utils";
import { Block } from "@boomq/uikit";
import { ExcludeChartTestConfirmForm } from "../ExcludeChartTestConfirmForm";
import { CustomCheckbox } from "../../../common/CustomCheckbox";
import { CustomSelect } from "../../../common/CustomSelect";
import { SlaReportTable } from "../SlaReportTable";
import { StyledButton } from "../../../styled/Buttons";
import { StyledTitle3 } from "../../../styled/Titles";
import { getSlaReportTableDiffTypeList, getSlaReportTableValueTypeList, generateSlaTypeIdFromData } from "../../../../helpers/slaReport";
import { SlaReportType, SlaReportTableValueType } from "../../../../helpers/types";
import { excludeSlaReportChartTestConfirmFormModal } from "../../../../data/content/modals";
const getUnselectedTypes = (slaList, slaReportTableData = {}) => {
    const selectedSlaTypeIds = (slaReportTableData.rowList || []).map((slaReportTableRow) => generateSlaTypeIdFromData(slaReportTableRow.slaType));
    return (slaList || []).filter((sla) => !(selectedSlaTypeIds || []).includes(generateSlaTypeIdFromData(sla)));
};
const valueTypeList = getSlaReportTableValueTypeList();
const diffTypeList = getSlaReportTableDiffTypeList();
const customBaselineTestTheme = { marginLeft: "10px" };
const isShowBaselineTestSection = (valueType) => valueType && valueType.value === SlaReportTableValueType.BaselineDiff;
const isShowDiffTypeSection = (valueType) => valueType &&
    [SlaReportTableValueType.BaselineDiff, SlaReportTableValueType.PreviousTestDiff].includes(valueType.value);
const isShowPreviousTestDiffAbsValue = (valueType) => valueType && valueType.value === SlaReportTableValueType.PreviousTestDiff;
const SlaReportProfileTable = (props) => {
    const slaReportTableData = props && props.slaReportTableData ? props.slaReportTableData : {};
    const { baselineTestId, diffType, tableId, valueType } = slaReportTableData;
    const slaReportProfileId = getValueByPath(props.match, "params.sla_profile_id");
    const baselineTestList = excludeArrayFromArrayByParam(props.slaReportTableTestList, props.excludedTestList, "value");
    const [isShowAbsoluteValue, setIsShowAbsoluteValue] = useState(true);
    const pseudoInputRef = useRef(null);
    const { formatMessage } = useIntl();
    const changeSlaReportTableField = (fieldName, value) => props.slaReportProfileTableChangeFieldRequest({
        field: props.slaReportTableIndex,
        subfield: fieldName,
        value
    });
    const changeDiffTypeHandler = (diffType) => changeSlaReportTableField("diffType", diffType);
    const changeBaselineTestHandler = ({ value }) => props.slaReportProfileChangeBaselineTestRequest({ tableIndex: props.slaReportTableIndex, value });
    const changeIsShowAbsoluteValueHandler = () => setIsShowAbsoluteValue(!isShowAbsoluteValue);
    const changeValueTypeHandler = (data) => changeSlaReportTableField("valueType", data);
    const clickAddSlaTypeHandler = (data) => {
        props.slaReportProfileAddSlaTypeByViewTypeRequest({
            tableIndex: props.slaReportTableIndex,
            type: SlaReportType.Table,
            value: data
        });
    };
    const copyEmbedCode = (e) => {
        e.preventDefault();
        e.stopPropagation();
        props.copyEmbedCodeRequest({
            inputEl: pseudoInputRef ? pseudoInputRef.current : null,
            slaReportProfileId,
            slaReportItemId: tableId,
            slaReportItemType: "table"
        });
    };
    const deleteTable = () => props.deleteSlaReportProfileTable(props.slaReportTableIndex);
    const deleteTableSlaHandler = (id) => props.deleteSlaReportProfileTableSlaType({ tableIndex: props.slaReportTableIndex, slaTypeId: id });
    const deleteTestHandler = (id) => props.getSiteModal(excludeSlaReportChartTestConfirmFormModal({
        element: ExcludeChartTestConfirmForm,
        id
    }));
    const isDisabledEmbedCodeButton = () => !tableId;
    return (React.createElement(PreviewBlock, null,
        React.createElement(SlaReportItemPreviewSection, null,
            React.createElement(SlaReportTable, { baselineTestId: baselineTestId, currentLanguage: props.currentLanguage, excludedTestList: props.excludedTestList, isShowAbsoluteValue: isShowAbsoluteValue, slaTypeList: getUnselectedTypes(props.slaReportSlaTypeList, slaReportTableData), tableData: slaReportTableData, tableTestList: props.slaReportTableTestList, testDuration: props.testDuration, onAddSla: clickAddSlaTypeHandler, onDeleteSla: deleteTableSlaHandler, onDeleteTest: deleteTestHandler })),
        React.createElement(SlaReportItemSettings, null,
            React.createElement(ValueSettingsTitle, null,
                React.createElement(FormattedMessage, { defaultMessage: "Value settings:", id: "account.sla.report.profile.table.value.settings.title" })),
            React.createElement(CustomSelect, { items: valueTypeList, name: "SlaReportTableValueType", onChange: changeValueTypeHandler, openTop: true, value: [valueType] }),
            isShowBaselineTestSection(valueType) && (React.createElement(CustomSelect, { defaultValue: baselineTestId, isAutoPosition: true, items: baselineTestList, name: "BaselineTest", onChange: changeBaselineTestHandler, placeholder: formatMessage({
                    defaultMessage: "select baseline test run",
                    id: "account.sla.report.profile.table.select.baseline.test"
                }), position: { offsetTop: 60 }, theme: customBaselineTestTheme })),
            React.createElement(DiffTypeSettings, null,
                isShowDiffTypeSection(valueType) && (React.createElement(DiffTypeSelector, null, diffTypeList.map((diffTypeName) => (React.createElement(DiffTypeParam, { key: diffTypeName },
                    React.createElement(CustomCheckbox, { checked: diffTypeName === diffType, onToggle: () => changeDiffTypeHandler(diffTypeName) }),
                    React.createElement(FormattedMessage, { defaultMessage: `Show ${diffTypeName} values`, id: `account.sla.report.profile.table.check.${diffTypeName}.values` })))))),
                isShowPreviousTestDiffAbsValue(valueType) && (React.createElement(DiffTypeParam, null,
                    React.createElement(CustomCheckbox, { checked: isShowAbsoluteValue, onToggle: changeIsShowAbsoluteValueHandler }),
                    React.createElement(FormattedMessage, { defaultMessage: "Show absolute values", id: "account.sla.report.profile.table.check.show.absolute.values" }))))),
        React.createElement(SlaReportItemEmbedCodeSection, null,
            React.createElement(EmbedCode, { ref: pseudoInputRef }),
            React.createElement(StyledButton, { disabled: isDisabledEmbedCodeButton(), onClick: copyEmbedCode },
                React.createElement(FormattedMessage, { defaultMessage: "Copy embed code", id: "account.sla.report.profile.item.button.copy.embed.code.title" }))),
        React.createElement(DeleteChartButtonSection, null,
            React.createElement(DeleteButton, { onClick: deleteTable }, "\u00D7"))));
};
const DeleteButton = styled.div `
    color: #396496;
    cursor: pointer;
    font-size: 30px;
`;
const DeleteChartButtonSection = styled.div `
    position: absolute;
    right: 10px;
    top: 0;
`;
const DiffTypeParam = styled.div `
    align-items: center;
    display: flex;
    margin-left: 10px;
`;
const DiffTypeSelector = styled.div `
    align-items: center;
    display: flex;
    flex-wrap: wrap;
`;
const DiffTypeSettings = styled.div `
    display: flex;
    flex-direction: column;
`;
const EmbedCode = styled.div `
    height: 1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    top: -9999px;
    width: 1px;
    z-index: -1;
`;
const PreviewBlock = styled.div `
    ${Block}
    display: flex;
    flex-direction: column;
    margin: 11px 0;
    padding: 34px;
    position: relative;
`;
const SlaReportItemEmbedCodeSection = styled.div `
    display: flex;
    justify-content: flex-end;
`;
const SlaReportItemPreviewSection = styled.div `
    display: flex;
`;
const SlaReportItemSettings = styled.div `
    align-items: center;
    display: flex;
    margin-top: 10px;
`;
const ValueSettingsTitle = styled(StyledTitle3) `
    margin-right: 5px;
`;
export default SlaReportProfileTable;
