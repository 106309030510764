var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { isEmpty, useIntl } from "@boomq/utils";
import { ButtonBlock, SlaReportProfileWrapper, customButtonTheme, customHintButtonTheme, slaReportStyle } from "./styled";
import { Hint } from "../../../common/Hint";
import { SlaReport } from "../../../account/SlaReportProfile/SlaReport";
import { StyledButton } from "../../../styled/Buttons";
import { PageFixedActionsPanel } from "../../../styled/Common";
import { Fade } from "../../../animation";
import { SPOILER_NAME } from "../../../../helpers/slaReport";
import { isAllowedSlaReportProfileActionByPermissions } from "../../../../helpers/slaReportProfile";
import { getSiteTitle } from "../../../../helpers/title";
import { SlaReportProfileAction } from "../../../../helpers/types";
import warning from "../../../../assets/images/icons/warn.svg";
const renderSaveButton = (props, sla_profile_id) => {
    const isDisabledSaveButton = isEmpty(props.slaTypeList);
    const clickHandler = () => typeof props[SlaReportProfileAction.Save] === "function"
        ? props[SlaReportProfileAction.Save](sla_profile_id)
        : undefined;
    const getButtonTheme = () => (isDisabledSaveButton ? customButtonTheme : {});
    return isAllowedSlaReportProfileActionByPermissions(props.permissionList, SlaReportProfileAction.Save) &&
        props.openedSpoilerName === SPOILER_NAME[1] ? (React.createElement(React.Fragment, null,
        React.createElement(StyledButton, { disabled: isDisabledSaveButton, onClick: clickHandler, theme: getButtonTheme() },
            React.createElement(FormattedMessage, { defaultMessage: "Save", id: "account.sla.report.profile.save" })),
        isDisabledSaveButton && (React.createElement(Hint, { icon: warning, theme: customHintButtonTheme, tooltipText: {
                defaultMessage: "Please select test runs with SLAs",
                id: "account.sla.report.profile.tooltip.empty.sla"
            } })))) : (React.createElement(React.Fragment, null));
};
export const SlaReportProfile = (_a) => {
    var _b;
    var { isAuthorized, isSlaReportProfileEnabled, match } = _a, restProps = __rest(_a, ["isAuthorized", "isSlaReportProfileEnabled", "match"]);
    const { formatMessage } = useIntl();
    useEffect(() => {
        document.title = getSiteTitle(formatMessage({
            defaultMessage: "Trending",
            id: "account.navbar.trending"
        }));
    }, [isAuthorized]);
    useEffect(() => {
        const sectionCollection = document.getElementsByTagName("section");
        !isEmpty(sectionCollection) && (sectionCollection[0].style.maxHeight = "calc(100% - 144px)");
        return () => {
            const sectionCollection = document.getElementsByTagName("section");
            !isEmpty(sectionCollection) && sectionCollection[0].style.removeProperty("max-height");
        };
    }, []);
    const sla_profile_id = (_b = match === null || match === void 0 ? void 0 : match.params) === null || _b === void 0 ? void 0 : _b.sla_profile_id;
    return (isSlaReportProfileEnabled && (React.createElement(SlaReportProfileWrapper, null,
        React.createElement(Fade, { animation: "inUp", style: slaReportStyle },
            React.createElement(SlaReport, { id: sla_profile_id })),
        React.createElement(PageFixedActionsPanel, null,
            React.createElement(ButtonBlock, { align: "center" }, renderSaveButton(restProps, sla_profile_id))))));
};
