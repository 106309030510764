var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { getUniqueId, getValueByPath, isEmpty, isEmptyArray } from "@boomq/utils";
import { DEFAULT_DELIMITER_VALUE, DEFAULT_ENABLED_VALUE, DEFAULT_HAS_HEADERS_VALUE, DEFAULT_INDEPENDENT_LIST_PER_THREAD_VALUE, DEFAULT_IS_OPENED_VALUE, DEFAULT_RANDOM_ORDER_VALUE, DEFAULT_RECYCLE_VALUE } from "./constants";
import { getTestProjectModelElDefaultValues } from "../testProjectModelSchema";
import { RequestParameter } from "../../models/requestParameters/enums";
export const getDownloadableCsvFileName = (files) => getValueByPath(files, "0.name", "");
export const isExistCsvFiles = (files) => (isEmpty(files) ? false : true);
const prepareLiteralParameter = (parameter = {}, modelSchema) => ({
    type: RequestParameter.Literal,
    value: Object.assign(Object.assign({}, getTestProjectModelElDefaultValues(modelSchema, "LiteralParameterValue")), { name: parameter.key, content: parameter.value })
});
export const prepareLiteralParameters = (data = [], modelSchema) => data.reduce((res, parameter) => [
    ...res,
    prepareLiteralParameter(parameter || {}, modelSchema)
], []);
const prepareCsvFilesParam = (files = []) => files.reduce((res, file) => [...res, { name: file.name }], []);
const prepareCsvParameter = (parameter = {}, modelSchema) => ({
    type: RequestParameter.Csv,
    value: Object.assign(Object.assign({}, getTestProjectModelElDefaultValues(modelSchema, "CsvParameterValue")), { location: parameter.location, delimiter: parameter.delimiter, enabled: true, files: prepareCsvFilesParam(parameter.files), independentListPerThread: parameter.independentListPerThread, parameterNames: parameter.parameterNames, preview: parameter.preview, hasHeaders: parameter.hasHeaders, randomOrder: parameter.randomOrder, recycle: parameter.recycle })
});
export const prepareCsvParameters = (data = [], modelSchema) => data.reduce((res, parameter) => [
    ...res,
    prepareCsvParameter(parameter || {}, modelSchema)
], []);
export const generateContentFromFilePreview = (preview = [], delimiter = ",") => preview.reduce((res, previewString) => [...res, previewString.split(delimiter)], []);
const getFileContentFirstRow = (fileContent) => !isEmptyArray(fileContent) ? fileContent[0] : [];
export const generateCsvParameterDefaultItem = () => ({
    delimiter: DEFAULT_DELIMITER_VALUE,
    enabled: DEFAULT_ENABLED_VALUE,
    id: getUniqueId(),
    files: [],
    fileContent: [],
    hasHeaders: DEFAULT_HAS_HEADERS_VALUE,
    independentListPerThread: DEFAULT_INDEPENDENT_LIST_PER_THREAD_VALUE,
    isOpened: DEFAULT_IS_OPENED_VALUE,
    isSavedCsvFile: false,
    location: "",
    parameterNames: [],
    preview: [],
    randomOrder: DEFAULT_RANDOM_ORDER_VALUE,
    recycle: DEFAULT_RECYCLE_VALUE
});
export const generateParameterNamesByHasHeaders = (fileContent = [], hasHeaders) => hasHeaders
    ? getFileContentFirstRow(fileContent).map((name) => name.trim())
    : [...getFileContentFirstRow(fileContent)].fill("");
export const getPreviewByHasHeaders = (preview, hasHeaders) => hasHeaders ? preview.slice(1) : preview;
export const parseCsvFileParam = (files = []) => files.reduce((res, file) => [
    ...res,
    { id: file.id, name: file.name }
], []);
const parseTestProjectCsvParameter = (parameterValue = {}) => {
    const { delimiter, files, hasHeaders, preview = [] } = parameterValue, rest = __rest(parameterValue, ["delimiter", "files", "hasHeaders", "preview"]);
    return Object.assign(Object.assign({ delimiter }, rest), { fileContent: generateContentFromFilePreview(getPreviewByHasHeaders(preview, hasHeaders), delimiter), files: parseCsvFileParam(files), hasHeaders, id: getUniqueId(), isOpened: false, isSavedCsvFile: true, preview });
};
const parseTestProjectLiteralParameter = (parameterValue = {}) => {
    var _a;
    return ({
        id: getUniqueId(),
        key: parameterValue.name || "",
        value: (_a = parameterValue.content) !== null && _a !== void 0 ? _a : ""
    });
};
const parseTestProjectParameterByType = (res, parameter) => {
    switch (parameter.type) {
        case RequestParameter.Literal: {
            return isEmpty(parameter.value)
                ? res
                : Object.assign(Object.assign({}, res), { literalParameters: [...res.literalParameters, parseTestProjectLiteralParameter(parameter.value)] });
        }
        case RequestParameter.Csv: {
            return isEmpty(parameter.value)
                ? res
                : Object.assign(Object.assign({}, res), { csvParameters: [...res.csvParameters, parseTestProjectCsvParameter(parameter.value)] });
        }
        default: {
            return Object.assign(Object.assign({}, res), { others: [...res.others, parameter] });
        }
    }
};
export const parseTestProjectParameters = (parameters = []) => (parameters || []).reduce((res, parameter) => parseTestProjectParameterByType(res, parameter), { literalParameters: [], csvParameters: [], others: [] });
