import { takeEvery, call, put, select } from "redux-saga/effects";
import clientActions from "../actions/client";
import { getClientAuthProviders } from "../reducers/authorizePlate";
import { getWebsitesFromStore } from "../reducers/clientWebsites";
import { getResponseErrorData } from "../../helpers/errors";
import { isEmpty } from "../../helpers/utils";
import { getClientWebsites, getSiteCustomAnalytics } from "../../services/authSrv";
const { analyticsFailure, clientWebsitesRequest, websitesFailure, websitesSuccess } = clientActions;
export function* websitesFlow(action) {
    try {
        const payload = action && action.payload ? action.payload : {};
        const providerType = payload && payload.type ? payload.type : "";
        const identityUserId = payload.identityUserId
            ? payload.identityUserId
            : yield* getIdentityUserIdByProviderType(providerType);
        const response = !isEmpty(identityUserId) && (yield call(getClientWebsites, { type: providerType, userId: identityUserId }));
        const data = response ? response.data : [];
        const clientWebSitesWithUserId = isEmpty(data) ? [] : data.map((website) => (Object.assign(Object.assign({}, website), { identityUserId })));
        yield put(websitesSuccess({ data: clientWebSitesWithUserId, identityUserId, type: providerType }));
    }
    catch (e) {
        yield put(websitesFailure(getResponseErrorData(e)));
    }
}
function* getIdentityUserIdByProviderType(providerType) {
    const clientAuthProviders = yield select(getClientAuthProviders);
    const clientAuthProvider = clientAuthProviders.find((authProvider) => authProvider.identityProviderType === providerType);
    return clientAuthProvider ? clientAuthProvider.identityUserId : "";
}
export function* getAnalyticsData(action) {
    try {
        const { siteId, top, type, userId, startDay, endDay } = action && action.payload ? action.payload : {};
        const websites = yield select(getWebsitesFromStore);
        const website = websites && siteId ? websites.find((item) => item.id === siteId) : {};
        const baseUrl = website && website.url ? website.url : "";
        const identityUserId = userId ? userId : yield* getIdentityUserIdByProviderType(type);
        const analyticsResponse = yield call(getSiteCustomAnalytics, {
            type,
            identityUserId,
            siteId,
            topUrl: top || 5,
            fromDate: startDay,
            toDate: endDay,
            projectModelVersion: "V_2_0_0"
        });
        const analytics = analyticsResponse && analyticsResponse.data ? analyticsResponse.data : {};
        return { analytics, baseUrl };
    }
    catch (e) {
        yield put(analyticsFailure(getResponseErrorData(e)));
    }
}
export function* clientDataFlow() {
    yield takeEvery(clientWebsitesRequest, websitesFlow);
}
