export const requestCommonParamList = [
    {
        id: 0,
        component: {
            elementName: "QueryParameters",
            props: {}
        },
        name: "queryParams",
        title: { defaultMessage: "Query parameters", id: "account.request.form.query.params.title" }
    },
    {
        id: 1,
        component: {
            elementName: "HeaderParameters",
            props: {}
        },
        name: "headers",
        title: { defaultMessage: "Headers", id: "account.request.form.headers.params.title" }
    },
    {
        id: 2,
        component: {
            elementName: "BodyText",
            props: {}
        },
        name: "body",
        title: { defaultMessage: "Body", id: "account.request.form.body.params.title" }
    }
];
