import { handleActions } from "redux-actions";
import { getUniqueId } from "@boomq/utils";
import { addWebhookItem, deleteWebhookItem, setWebhookItem, setWebhookItems } from "../actions/webhooks";
export const initialState = {
    items: []
};
export default handleActions({
    [addWebhookItem]: (state) => (Object.assign(Object.assign({}, state), { items: [
            ...state.items,
            { id: getUniqueId(), headers: [], testDisplayStateSet: [], url: "" }
        ] })),
    [deleteWebhookItem]: (state, { payload }) => payload
        ? Object.assign(Object.assign({}, state), { items: [...state.items.filter((webhookItem) => webhookItem.id !== payload)] }) : state,
    [setWebhookItem]: (state, { payload }) => (Object.assign(Object.assign({}, state), { items: state.items.map((item) => (item.id === payload.id ? Object.assign(Object.assign({}, item), payload) : item)) })),
    [setWebhookItems]: (state, { payload }) => (Object.assign(Object.assign({}, state), { items: payload }))
}, initialState);
const getStateParam = (state, paramName) => state && state.webhooks ? state.webhooks[paramName] : initialState[paramName];
export const getWebhookItems = (state) => getStateParam(state, "items");
