import { call, put, select, takeEvery } from "redux-saga/effects";
import { teamsFailure, teamsRequest, teamsSuccess } from "../actions/teams";
import { getTeamsCurrentPage, getTeamsSize } from "../reducers/teams";
import { getResponseErrorData } from "../../helpers/errors";
import { getUserTeams } from "../../services/authSrv";
function* getTeamsData(teamsGettingFunc, pageSize) {
    const page = yield select(getTeamsCurrentPage);
    const size = pageSize ? pageSize : yield select(getTeamsSize);
    return yield call(teamsGettingFunc, { page, size });
}
export function* teamsGettingFlow(action, teamsGettingFunc) {
    try {
        const { size } = action && action.payload ? action.payload : {};
        const { data } = yield* getTeamsData(teamsGettingFunc, size);
        yield put(teamsSuccess(data));
    }
    catch (e) {
        yield put(teamsFailure(getResponseErrorData(e)));
    }
}
function* userTeamsGettingFlow(action) {
    yield* teamsGettingFlow(action, getUserTeams);
}
export function* teamsFlow() {
    yield takeEvery(teamsRequest, userTeamsGettingFlow);
}
