var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { StyledTeamSectionBlock } from "../TeamAdminAccessTemplate";
import { TeamInfoBlock } from "../TeamInfoBlock";
const renderChildren = (_a) => {
    var { children } = _a, restProps = __rest(_a, ["children"]);
    return React.isValidElement(children) ? React.cloneElement(children, restProps) : children;
};
export const TeamManageUsersAccessTemplate = (props) => (React.createElement(StyledTeamSectionBlock, null,
    React.createElement(TeamInfoBlock, Object.assign({}, props)),
    renderChildren(props)));
