import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getDiffHourCount, getTimeOnly } from "../../helpers/date";
const CURRENT_TIME_DEFAULT = "00:00";
const INTERVAL_MS = 1000;
let interval;
const Timer = (props) => {
    const [currentTime, setCurrentTime] = useState(CURRENT_TIME_DEFAULT);
    const deleteInterval = () => clearInterval(interval);
    const getDiffByType = () => {
        const { startTime, type } = props;
        const now = Date.now();
        const diff = startTime - now;
        return type === "increase" ? -diff : diff;
    };
    const getTime = (diff) => {
        const hours = getDiffHourCount(props.startTime);
        return hours === 0 ? getTimeOnly(diff) : `${hours}:${getTimeOnly(diff)}`;
    };
    const tick = () => {
        const diff = getDiffByType();
        const currentTime = diff > 0 ? getTime(diff) : CURRENT_TIME_DEFAULT;
        return setCurrentTime(currentTime);
    };
    useEffect(() => {
        interval = setInterval(() => tick(), INTERVAL_MS);
    }, []);
    useEffect(() => {
        return deleteInterval;
    }, []);
    return React.createElement(StyledTimer, null, currentTime);
};
const StyledTimer = styled.p `
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    font-size: 18px;
    text-align: right;
    letter-spacing: 0.05em;
    color: #4989d6;
    min-width: 60px;
`;
export default Timer;
