import { generateSlaItemText, generateSlaTypeItemFormattedText } from "./slaTypeItemFormattedText";
import { convertSecToMs, getFormattedTimeByLocale } from "../date";
import { getSlaFromDateInMs, getSlaToDateInMs } from "../sla/slaParams";
const getSlaTimelineFormattedText = (slaData, testDuration, formatMessage) => {
    const fromDate = getSlaFromDateInMs(slaData);
    const toDate = getSlaToDateInMs(slaData);
    return toDate - fromDate === convertSecToMs(testDuration)
        ? ""
        : `${getFormattedTimeByLocale(fromDate, formatMessage)} - ${getFormattedTimeByLocale(toDate, formatMessage)}`;
};
export const generateSlaTypeItemFormattedTextWithTimeline = (slaTypeItemData, testDuration, formatMessage) => generateSlaItemText([
    generateSlaTypeItemFormattedText(slaTypeItemData, formatMessage),
    getSlaTimelineFormattedText(slaTypeItemData, testDuration, formatMessage)
]);
