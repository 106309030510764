import { Uploader } from "@boomq/uikit";
import styled from "styled-components";
export const ButtonSection = styled.div `
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    > button {
        width: auto !important;
    }
`;
export const SupportingFilesUploader = styled(Uploader) `
    margin-top: 16px;
`;
