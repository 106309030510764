import styled from "styled-components";
export const PlateWrapper = styled.div `
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 80px;
    @media (max-width: 1366px) {
        padding-bottom: 80px;
    }
`;
