import { isEmpty, parseJwt } from "@boomq/utils";
import { initialState } from "./authorizePlate";
const getSelectorValue = (state, paramName) => state && state.authorizePlate ? state.authorizePlate[paramName] : initialState[paramName];
export const getActiveAction = (state) => getSelectorValue(state, "activeAction");
export const getActiveUrl = (state) => getSelectorValue(state, "activeUrl");
export const getClientAuthProviders = (state) => getSelectorValue(state, "authProviders");
export const getClientInfo = (state) => getSelectorValue(state, "clientInfo");
export const getClientId = (state) => { var _a; return (_a = getClientInfo(state)) === null || _a === void 0 ? void 0 : _a.id; };
export const getClientAuthType = (state) => getClientInfo(state).type;
export const getClientEmail = (state) => getClientInfo(state).email;
export const getClientRole = (state) => {
    const clientInfo = getClientInfo(state);
    return clientInfo && Array.isArray(clientInfo.authorityList) ? clientInfo.authorityList : [];
};
export const getClientNotifications = (state) => getSelectorValue(state, "clientNotifications");
export const getClientPermissionList = (state) => getSelectorValue(state, "permissionList");
export const getClientToken = (state) => getSelectorValue(state, "jwt");
export const getCurrentDateFormatLocale = (state) => getSelectorValue(state, "dateFormatLocale");
export const getHeapTrackStatus = (state) => getSelectorValue(state, "isHeapTracked");
export const getIsAuthorize = (state) => !!getClientToken(state);
export const getSecretTokenData = (state) => getSelectorValue(state, "secretToken");
export const getSelectedTeamData = (state) => getSelectorValue(state, "selectedTeam");
export const getSelectedTeamId = (state) => getSelectedTeamData(state).id;
export const isExistSelectedTeam = (state) => !isEmpty(getSelectedTeamData(state));
export const getIsUserRegistration = (state) => {
    const token = getClientToken(state);
    const parsedToken = parseJwt(token);
    return !!(parsedToken === null || parsedToken === void 0 ? void 0 : parsedToken.is_registration);
};
