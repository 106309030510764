import React from "react";
import { CookiesTable, CookiesTableBody, CookiesTableBodyCol, CookiesTableBodyColValue, CookiesTableHeader, CookiesTableHeaderCol, CookiesTableHeaderColValue, CookiesTableHeaderRow } from "./styled";
export const RequestResponseCookies = ({ responseCookiePreview }) => (React.createElement(CookiesTable, null,
    React.createElement(CookiesTableHeader, null,
        React.createElement(CookiesTableHeaderRow, null,
            React.createElement(CookiesTableHeaderCol, null, "Name"),
            React.createElement(CookiesTableHeaderColValue, null, "Value"),
            React.createElement(CookiesTableHeaderCol, null, "Domain"),
            React.createElement(CookiesTableHeaderCol, null, "Path"),
            React.createElement(CookiesTableHeaderCol, null, "Expires"),
            React.createElement(CookiesTableHeaderCol, null, "HttpOnly"),
            React.createElement(CookiesTableHeaderCol, null, "Secure"),
            React.createElement(CookiesTableHeaderCol, null, "Comment"))),
    React.createElement(CookiesTableBody, null, Array.isArray(responseCookiePreview) ? (responseCookiePreview.map(({ name, value, domain, path, expires, httpOnly, secure, comment }, index) => (React.createElement("tr", { key: `${name}_${index}` },
        React.createElement(CookiesTableBodyCol, null, name),
        React.createElement(CookiesTableBodyColValue, null, value),
        React.createElement(CookiesTableBodyCol, null, domain),
        React.createElement(CookiesTableBodyCol, null, path),
        React.createElement(CookiesTableBodyCol, null, expires),
        React.createElement(CookiesTableBodyCol, null, httpOnly),
        React.createElement(CookiesTableBodyCol, null, secure),
        React.createElement(CookiesTableBodyCol, null, comment))))) : (React.createElement(React.Fragment, null)))));
