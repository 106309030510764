import { getUnique, isEmpty, isEmptyArray, round } from "@boomq/utils";
import { TIME_COEFFICIENT } from "./constants";
import { TestType } from "../types";
const getRampPointValue = (points, time, usersPerStep) => points.filter((point) => point.start === time || point.finish === time).length > 1 ? usersPerStep : 0;
const isExistRampPoint = (points, time) => time > 0 &&
    points.filter((point) => !isEmpty(point.usersPerStep) && time >= point.start && time < point.finish).length > 0;
const isExistSamePointInTimeRange = (pointList, time, timeRangeMs, users) => pointList.filter((point) => point.users === users && Math.abs(point.time - time) <= timeRangeMs).length > 0;
const getMergedStepsPointUsersByTime = (points, time) => {
    const filteredPointsByTime = points.filter((point) => time >= point.start && time < point.finish);
    return filteredPointsByTime.reduce((res, point) => {
        return res + (point.usersPerStep ? getRampPointValue(points, time, point.usersPerStep) : point.usersFunc(time));
    }, 0);
};
const mergeStepsPointsByTime = (pointsDataWithTimeline, time, usersByTime) => isExistRampPoint(pointsDataWithTimeline, time)
    ? [
        { time, users: usersByTime },
        { time: time - 1, users: getMergedStepsPointUsersByTime(pointsDataWithTimeline, time - 1) }
    ]
    : [{ time, users: usersByTime }];
function getMergedStepsPointsByTime(res, pointsDataWithTimeline, time) {
    const usersByTime = getMergedStepsPointUsersByTime(pointsDataWithTimeline, time);
    return isExistSamePointInTimeRange(res, time, 2, usersByTime)
        ? []
        : mergeStepsPointsByTime(pointsDataWithTimeline, time, usersByTime);
}
export function generatePreviewChartDataForSteps(chartStepsData, timeLine, isMergedChart, params) {
    try {
        const chartSteps = chartStepsData.map((step) => (Object.assign(Object.assign({}, step), { timeLine })));
        return isMergedChart
            ? generateMergedStepsPreviewData(chartSteps, timeLine)
            : generateStepPreviewData(chartSteps[0]);
    }
    catch (e) {
        console.error(e);
        return [];
    }
}
const getFilteredPointsByTimeline = (timePoints = [], startTimeline = 0, endTimeline = 0) => {
    const filteredPointsByTimeline = (timePoints || []).filter((timePoint) => timePoint >= startTimeline && timePoint <= endTimeline);
    const filteredPointsByStartTimeline = Math.min(...timePoints) < startTimeline
        ? [startTimeline, ...filteredPointsByTimeline]
        : filteredPointsByTimeline;
    return Math.max(...timePoints) > endTimeline
        ? [...filteredPointsByTimeline, endTimeline]
        : filteredPointsByStartTimeline;
};
const generatePointsByTimeline = (pointsDataWithTimeline, timelineData) => {
    const timePoints = pointsDataWithTimeline.reduce((res, point) => [...res, point.start, point.finish], []);
    const uniqueTimePoints = getUnique(timePoints).map((point) => Number(point));
    const startTimeline = !isEmptyArray(timelineData) ? timelineData[0] : 0;
    const endTimeline = !isEmptyArray(timelineData) ? timelineData[1] : Math.max(...uniqueTimePoints);
    const startTimelineWithCoeff = TIME_COEFFICIENT * startTimeline;
    const endTimelineWithCoeff = TIME_COEFFICIENT * endTimeline;
    return getFilteredPointsByTimeline(uniqueTimePoints, startTimelineWithCoeff, endTimelineWithCoeff);
};
export function generateMergedStepsPreviewData(chartPreviewData, timelineData) {
    const timeCoefficientInSec = 60 * TIME_COEFFICIENT;
    const pointsDataWithTimeline = chartPreviewData.reduce((res, previewData) => [
        ...res,
        ...generatePreviewDataPoints(previewData, TIME_COEFFICIENT)
    ], []);
    const pointsByTimeline = generatePointsByTimeline(pointsDataWithTimeline, timelineData);
    return pointsByTimeline
        .reduce((res, time) => [
        ...res,
        ...getMergedStepsPointsByTime(res, pointsDataWithTimeline, time)
    ], [])
        .sort((a, b) => a.time - b.time)
        .map((point) => ({ time: point.time / timeCoefficientInSec, users: point.users }));
}
const getMergedGroupsPointUsersByTime = (points, time) => {
    const filteredPointsByTime = points.filter((point) => time >= point.start && time <= point.finish);
    const pointsByGroups = filteredPointsByTime.reduce((res, point) => {
        const usersPerStep = point.usersPerStep
            ? getRampPointValue(points, time, point.usersPerStep)
            : point.usersFunc(time);
        const pointGroupId = point.groupId || "";
        const usersPerStepByGroupId = res[pointGroupId] && usersPerStep < res[pointGroupId] ? res[pointGroupId] : usersPerStep;
        return Object.assign(Object.assign({}, res), { [pointGroupId]: usersPerStepByGroupId });
    }, {});
    return Object.values(pointsByGroups).reduce((res, usersPerStep) => res + usersPerStep, 0);
};
function mergeGroupsPointsByTime(pointsDataWithTimeline, time, usersByTime) {
    const previousTime = time - 1;
    const usersByPreviousTime = getMergedGroupsPointUsersByTime(pointsDataWithTimeline, previousTime);
    return isExistRampPoint(pointsDataWithTimeline, time) && usersByTime !== usersByPreviousTime
        ? [
            { time: previousTime, users: usersByPreviousTime },
            { time, users: usersByTime }
        ]
        : [{ time, users: usersByTime }];
}
function getMergedGroupsPointsByTime(existPoints, pointsDataWithTimeline, time) {
    const usersByTime = getMergedGroupsPointUsersByTime(pointsDataWithTimeline, time);
    return isExistSamePointInTimeRange(existPoints, time, 2, usersByTime)
        ? []
        : mergeGroupsPointsByTime(pointsDataWithTimeline, time, usersByTime);
}
export function generateMergedGroupsPreviewData(chartPreviewData, timelineData) {
    const timeCoefficientInSec = 60 * TIME_COEFFICIENT;
    const pointsDataWithTimeline = chartPreviewData.reduce((res, previewData) => [
        ...res,
        ...generatePreviewDataPoints(previewData, TIME_COEFFICIENT)
    ], []);
    const pointsByTimeline = generatePointsByTimeline(pointsDataWithTimeline, timelineData);
    return pointsByTimeline
        .reduce((res, time) => [
        ...res,
        ...getMergedGroupsPointsByTime(res, pointsDataWithTimeline, time)
    ], [])
        .sort((a, b) => a.time - b.time)
        .map((point) => ({ time: point.time / timeCoefficientInSec, users: point.users }));
}
export function generatePreviewDataPoints(params, sourceTimeCoefficient) {
    const result = [];
    const { groupId, initialDelay = 0, rampDown = 0, rampUp = 0, stepLength, timeLine = [], usersPerStep = 0 } = params;
    const startTimeLine = !isEmptyArray(timeLine) ? timeLine[0] : 0;
    const endTimeLine = !isEmptyArray(timeLine) ? timeLine[1] : (initialDelay || 0) + rampUp + stepLength + rampDown;
    const timeCoefficient = sourceTimeCoefficient || 1;
    const initialDelayWithCoeff = timeCoefficient * (initialDelay || 0);
    let rampUpWithCoeff = timeCoefficient * rampUp;
    let rampDownWithCoeff = timeCoefficient * rampDown;
    let stepLengthWithCoeff = timeCoefficient * stepLength;
    stepLengthWithCoeff = rampDown === 0 ? stepLengthWithCoeff - 1 : stepLengthWithCoeff;
    stepLengthWithCoeff = rampUp === 0 ? stepLengthWithCoeff - 1 : stepLengthWithCoeff;
    rampDownWithCoeff = rampDown === 0 ? 1 : rampDownWithCoeff;
    rampUpWithCoeff = rampUp === 0 ? 1 : rampUpWithCoeff;
    const rampUpTime = (initialDelay || 0) + rampUp;
    const holdTime = rampUpTime + stepLength;
    const rampDownFunction = (params) => {
        const { rampDown, rampUp, usersPerStep, initialDelay, stepLength } = params || {};
        return (time = 0) => rampDown === 0 || isEmpty(time)
            ? 0
            : round(((initialDelay || 0) + stepLength + rampUp + rampDown - time) * (usersPerStep / rampDown), 1);
    };
    const rampUpFunction = (params) => {
        const { initialDelay, rampUp, usersPerStep } = params;
        return (time = 0) => rampUp === 0 || isEmpty(time)
            ? 0
            : round((usersPerStep / rampUp) * (Number(time) - (initialDelay || 0)), 1);
    };
    if (startTimeLine >= (initialDelay || 0) + rampUp + stepLength + rampDown) {
        return result;
    }
    result.push({
        groupId,
        start: initialDelayWithCoeff,
        finish: initialDelayWithCoeff + rampUpWithCoeff,
        usersFunc: rampUpFunction({ rampUp: rampUpWithCoeff, usersPerStep, initialDelay: initialDelayWithCoeff }),
        usersPerStep: rampUp === 0 ? usersPerStep : undefined
    });
    holdTime > startTimeLine &&
        rampUpTime <= endTimeLine &&
        result.push({
            groupId,
            usersFunc: () => usersPerStep,
            start: initialDelayWithCoeff + rampUpWithCoeff,
            finish: initialDelayWithCoeff + rampUpWithCoeff + stepLengthWithCoeff
        });
    result.push({
        groupId,
        start: initialDelayWithCoeff + rampUpWithCoeff + stepLengthWithCoeff,
        finish: initialDelayWithCoeff + rampUpWithCoeff + stepLengthWithCoeff + rampDownWithCoeff,
        usersFunc: rampDownFunction({
            initialDelay: initialDelayWithCoeff,
            rampDown: rampDownWithCoeff,
            rampUp: rampUpWithCoeff,
            stepLength: stepLengthWithCoeff,
            usersPerStep
        }),
        usersPerStep: rampDown === 0 ? usersPerStep : undefined
    });
    return result;
}
export function generateStepPreviewData(props) {
    const result = [];
    const { initialDelay = 0, rampDown = 0, rampUp = 0, stepLength = 0, usersPerStep = 0, timeLine = [] } = props;
    const startTimeLine = !isEmptyArray(timeLine) ? timeLine[0] : 0;
    const endTimeLine = !isEmptyArray(timeLine) ? timeLine[1] : (initialDelay || 0) + rampUp + stepLength + rampDown;
    const timeCoefficient = 1 / 60;
    const initialDelayWithCoeff = timeCoefficient * (initialDelay || 0);
    const startTimeLineWithCoeff = timeCoefficient * startTimeLine;
    const endTimeLineWithCoeff = timeCoefficient * endTimeLine;
    const rampUpTime = (initialDelay || 0) + rampUp;
    const holdTime = rampUpTime + stepLength;
    const rampDownTime = holdTime + rampDown;
    const rampDownFunction = ({ rampDown, rampUp, usersPerStep, initialDelay, stepLength, time }) => rampDown === 0 || isEmpty(time)
        ? 0
        : ((initialDelay || 0) + stepLength + rampUp + rampDown - time) * (usersPerStep / rampDown);
    const rampUpFunction = ({ initialDelay, rampUp, usersPerStep, time }) => rampUp === 0 || isEmpty(time) ? 0 : (usersPerStep / rampUp) * (Number(time) - (initialDelay || 0));
    const getHoldTimeValue = ({ holdTime, startTimeLine, endTimeLine }) => {
        const time = holdTime < endTimeLine ? holdTime : endTimeLine;
        return startTimeLine > time ? startTimeLine : time;
    };
    if (startTimeLine >= (initialDelay || 0) + rampUp + stepLength + rampDown) {
        return result;
    }
    endTimeLine < rampUpTime &&
        endTimeLine > initialDelay &&
        result.push({
            time: endTimeLineWithCoeff,
            users: rampUpFunction({ initialDelay, rampUp, usersPerStep, time: endTimeLine })
        });
    (startTimeLine < initialDelay || (startTimeLine >= (initialDelay || 0) && startTimeLine < rampUpTime)) &&
        initialDelay < endTimeLine &&
        result.push({
            time: startTimeLine > initialDelay ? startTimeLineWithCoeff : initialDelayWithCoeff,
            users: rampUpFunction({
                initialDelay,
                rampUp,
                usersPerStep,
                time: startTimeLine > initialDelay ? startTimeLine : initialDelay || 0
            })
        });
    holdTime > startTimeLine &&
        rampUpTime <= endTimeLine &&
        result.push({
            time: startTimeLine > rampUpTime ? startTimeLineWithCoeff : rampUpTime * timeCoefficient,
            users: usersPerStep
        }, {
            time: getHoldTimeValue({ startTimeLine, holdTime, endTimeLine }) * timeCoefficient,
            users: usersPerStep
        });
    startTimeLine >= holdTime &&
        startTimeLine < holdTime + rampDown &&
        result.push({
            time: startTimeLineWithCoeff,
            users: rampDownFunction({
                initialDelay,
                rampDown,
                rampUp,
                stepLength,
                time: startTimeLine,
                usersPerStep
            })
        });
    endTimeLine > holdTime &&
        endTimeLine < rampDownTime &&
        result.push({
            time: endTimeLineWithCoeff,
            users: rampDownFunction({
                initialDelay,
                rampDown,
                rampUp,
                stepLength,
                time: endTimeLine,
                usersPerStep
            })
        });
    rampDownTime > startTimeLine &&
        rampDownTime <= endTimeLine &&
        result.push({
            time: rampDownTime * timeCoefficient,
            users: rampDownFunction({
                initialDelay,
                rampDown,
                rampUp,
                stepLength,
                time: rampDownTime,
                usersPerStep
            })
        });
    return result;
}
export function generatePreviewData(props) {
    const result = [];
    const { initialDelay = 0, loadType, rampDown = 0, rampUp = 0, stepLength = 0, usersPerStep = 0 } = props;
    let { durationAddedOnLastStep = 0, stepCount = 0 } = props;
    (!loadType || loadType === TestType.Stable) && (stepCount = 1) && (durationAddedOnLastStep = 0);
    for (let i = 0; i <= stepCount; i++) {
        const lastTime = result[result.length - 1] ? result[result.length - 1].time : 0;
        switch (i) {
            case 0:
                result.push({ time: initialDelay / 60, users: 0 });
                break;
            case stepCount:
                result.push({ time: lastTime + rampUp / 60, users: usersPerStep * i }, {
                    time: lastTime + rampUp / 60 + stepLength / 60 + durationAddedOnLastStep / 60,
                    users: usersPerStep * i
                });
                break;
            default:
                result.push({ time: lastTime + rampUp / 60, users: usersPerStep * i }, { time: lastTime + rampUp / 60 + stepLength / 60, users: usersPerStep * i });
                break;
        }
    }
    result.push({ time: result[result.length - 1].time + rampDown / 60, users: 0 });
    return result;
}
