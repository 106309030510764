import React from "react";
import { connect } from "react-redux";
import { useIntl } from "@boomq/utils";
import { getValueByPath } from "@boomq/utils";
import { END_INDEX_INPUT_PLACEHOLDER, INPUT_VARIABLE_INPUT_PLACEHOLDER, OUTPUT_VARIABLE_INPUT_PLACEHOLDER, START_INDEX_INPUT_PLACEHOLDER } from "./intl";
import { ForEachTextSection, StyledIndexInputWithParameters } from "./styled";
import { ControllerButtonDelete } from "../ControllerButtonDelete";
import { ControllerChildrenCounter } from "../ControllerChildrenCounter";
import { StyledInputWithParameters } from "../IfElement/styled";
import { Actions } from "../RequestHttpElement";
import { DragAndDropIcon, DragAndDropIconBlock, Transaction, TransactionElBody, TransactionHeader } from "../TransactionElement/styled";
import { TransactionToggler } from "../TransactionToggler";
import { formatIntlMessage } from "../../../../helpers/intl";
import { getNodeDataError, isExistNodeDataErrorByParams } from "../../../../helpers/threadGroupEditor";
import { deleteEditorElementByPath, setElementField, setForEachOutputVariableName } from "../../../../redux/actions/editor";
import { getEditorParameterListByPath, getEditorTreeData, getElementById } from "../../../../redux/reducers/editor";
const connector = connect((state, ownProps) => ({
    nodeData: getElementById(state, ownProps.node.id),
    parameterList: getEditorParameterListByPath(state, ownProps.path),
    treeData: getEditorTreeData(state)
}), {
    onChange: setElementField,
    onChangeOutputVariableName: setForEachOutputVariableName,
    onDelete: deleteEditorElementByPath
});
const ForEachElement = ({ changeActiveDnD, children, expanded, node, nodeData, onChange, onChangeOutputVariableName, onDelete, onTogglerClick, path, parameterList, treeData }) => {
    const { formatMessage } = useIntl();
    const blurHandler = () => (typeof changeActiveDnD === "function" ? changeActiveDnD(true) : undefined);
    const changeHandler = (field, value) => onChange({ field, id: node.id, value });
    const changeOutputVariableNameHandler = (field, value) => onChangeOutputVariableName({ field, id: node.id, value });
    const changeParamAsValue = (paramName, name) => {
        const value = getValueByPath(nodeData, paramName, "") + "${" + name + "}";
        return changeHandler(paramName, value);
    };
    const focusHandler = () => (typeof changeActiveDnD === "function" ? changeActiveDnD(false) : undefined);
    const toggleTransactionStateHandler = () => onTogglerClick(!expanded);
    const endIndexPlaceholder = formatIntlMessage(END_INDEX_INPUT_PLACEHOLDER, formatMessage);
    const inputVariablePlaceholder = formatIntlMessage(INPUT_VARIABLE_INPUT_PLACEHOLDER, formatMessage);
    const outputVariablePlaceholder = formatIntlMessage(OUTPUT_VARIABLE_INPUT_PLACEHOLDER, formatMessage);
    const startIndexPlaceholder = formatIntlMessage(START_INDEX_INPUT_PLACEHOLDER, formatMessage);
    return (React.createElement(Transaction, { "data-id": node.id, isExistError: isExistNodeDataErrorByParams(nodeData, ["variablePrefix", "outputVariableName"]) },
        React.createElement(DragAndDropIconBlock, null,
            React.createElement(DragAndDropIcon, null)),
        React.createElement(TransactionElBody, null,
            React.createElement(TransactionHeader, null,
                React.createElement(TransactionToggler, { isExpanded: expanded, onChange: toggleTransactionStateHandler }),
                React.createElement(ControllerChildrenCounter, { node: node }),
                React.createElement(ForEachTextSection, null, "for each"),
                React.createElement(StyledInputWithParameters, { error: getNodeDataError(nodeData), onBlur: blurHandler, onChange: changeHandler, onChangeParamAsValue: changeParamAsValue, onFocus: focusHandler, paramName: "variablePrefix", parameters: parameterList, placeholder: inputVariablePlaceholder, value: nodeData }),
                React.createElement(StyledIndexInputWithParameters, { onBlur: blurHandler, onChange: changeHandler, onChangeParamAsValue: changeParamAsValue, onFocus: focusHandler, paramName: "startIndexExclusive", parameters: parameterList, placeholder: startIndexPlaceholder, value: nodeData }),
                React.createElement(StyledIndexInputWithParameters, { onChange: changeHandler, onBlur: blurHandler, onChangeParamAsValue: changeParamAsValue, onFocus: focusHandler, paramName: "endIndexInclusive", parameters: parameterList, placeholder: endIndexPlaceholder, value: nodeData }),
                React.createElement(StyledInputWithParameters, { error: getNodeDataError(nodeData), onBlur: blurHandler, onChange: changeOutputVariableNameHandler, onChangeParamAsValue: changeParamAsValue, onFocus: focusHandler, paramName: "outputVariableName", parameters: parameterList, placeholder: outputVariablePlaceholder, value: nodeData }),
                React.createElement(Actions, null,
                    React.createElement(ControllerButtonDelete, { onClick: onDelete, path: path, treeData: treeData })))),
        children));
};
export default connector(ForEachElement);
