import styled from "styled-components";
export const EmptyBlock = styled.div `
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
`;
export const EmptyText = styled.p `
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    font-size: 14px;
    color: #bdbdbd;
    margin-top: 16px;
`;
export const EmptyTitle = styled.h3 `
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    font-size: 24px;
    text-align: center;
    color: #bdbdbd;
`;
