import { isObject, round } from "@boomq/utils";
import { AVG_RESP_TIME, CLASSIFICATION, ERRORS, PCT_95_RESP_TIME, THROUGHPUT, TIMELINE_PCT_95_RESP_TIME, TIMELINE_THREADS, TIMELINE_TIME, USERS } from "./constants";
import { convertMsToSec, getFullTime, getTimeOnly } from "../date";
const getRoundedValue = (value) => (value ? round(value) : 0);
const getStatsValue = (data, paramName) => (data && data[paramName] ? data[paramName] : 0);
export const getAvgRespTime = (data) => getStatsValue(data, AVG_RESP_TIME);
export const getErrors = (data) => getStatsValue(data, ERRORS);
export const getPct95RespTime = (data) => getStatsValue(data, PCT_95_RESP_TIME);
export const getThroughput = (data) => getStatsValue(data, THROUGHPUT);
export const getUsers = (data) => getStatsValue(data, USERS);
export const formatStatsResult = (result) => ({
    [AVG_RESP_TIME]: getRoundedValue(getAvgRespTime(result)),
    [ERRORS]: getRoundedValue(getErrors(result)),
    [USERS]: getRoundedValue(getUsers(result)),
    [PCT_95_RESP_TIME]: getRoundedValue(getPct95RespTime(result) / 1000),
    [THROUGHPUT]: getRoundedValue(getThroughput(result))
});
const getTimeLinePct95RespTime = (data) => round(getStatsValue(data, TIMELINE_PCT_95_RESP_TIME), 1000);
const getTimeLineTime = (data) => getStatsValue(data, TIMELINE_TIME);
export const formatStatsTime = (coordinates) => {
    if (coordinates && coordinates.length) {
        const startTime = getTimeLineTime(coordinates[0]);
        return coordinates.map((item) => {
            const time = getTimeLineTime(item);
            return Object.assign(Object.assign({}, item), { [TIMELINE_PCT_95_RESP_TIME]: getTimeLinePct95RespTime(item), [TIMELINE_TIME]: time - startTime < 3600000 ? getTimeOnly(time - startTime) : getFullTime(time - startTime) });
        });
    }
    return coordinates ? coordinates : [];
};
export const getDefaultStatsResult = () => [AVG_RESP_TIME, ERRORS, USERS, PCT_95_RESP_TIME, THROUGHPUT].reduce((res, paramName) => (Object.assign(Object.assign({}, res), { [paramName]: "-" })), {});
const findNearestVUMetricTime = (vuMetricTimes, responseTime) => {
    if (vuMetricTimes.includes(responseTime)) {
        return responseTime;
    }
    return vuMetricTimes.reduce((prev, curr) => Math.abs(curr - responseTime) < Math.abs(prev - responseTime) ? curr : prev);
};
const processResponseTimes = (responseTimes, vuMetricTimes) => Object.entries(responseTimes).reduce((processedTimes, [timestamp, data]) => {
    const nearestVUMetricTime = findNearestVUMetricTime(vuMetricTimes, Number(timestamp));
    return Object.assign(Object.assign({}, processedTimes), { [nearestVUMetricTime]: Object.assign(Object.assign({}, data), { value: data.value ? convertMsToSec(data.value) : 0 }) });
}, {});
const getTimestampRange = (timestamps) => {
    const numericTimestamps = timestamps.map(Number);
    return [Math.min(...numericTimestamps), Math.max(...numericTimestamps)];
};
const filterVUMetricsBefore = (vuMetrics, timestamp) => Object.entries(vuMetrics)
    .map(([time, threads]) => ({
    [TIMELINE_TIME]: Number(time),
    [TIMELINE_THREADS]: threads,
    [TIMELINE_PCT_95_RESP_TIME]: null,
    [CLASSIFICATION]: null
}))
    .filter((entry) => entry[TIMELINE_TIME] < timestamp);
const findVUMetricsAfter = (vuMetrics, timestamp) => {
    const result = Object.entries(vuMetrics).find(([time]) => Number(time) > timestamp);
    return result
        ? [
            {
                [TIMELINE_TIME]: Number(result[0]),
                [TIMELINE_THREADS]: result[1],
                [TIMELINE_PCT_95_RESP_TIME]: null,
                [CLASSIFICATION]: null
            }
        ]
        : [];
};
const formatDataMetrics = (responseTimesMetrics, vuMetrics) => Object.entries(responseTimesMetrics).map(([time, data]) => ({
    [TIMELINE_TIME]: Number(time),
    [TIMELINE_THREADS]: vuMetrics[time],
    [TIMELINE_PCT_95_RESP_TIME]: data.value || null,
    [CLASSIFICATION]: data.classification || null
}));
export const formatDataAnomalies = (data, processResponseTimesFn) => {
    const vuMetrics = (data === null || data === void 0 ? void 0 : data.vu_metric) || {};
    const responseTimes = (data === null || data === void 0 ? void 0 : data.response_time) || {};
    const vuMetricTimes = Object.keys(vuMetrics).map(Number);
    const processedResponseTimes = processResponseTimesFn(responseTimes, vuMetricTimes);
    if (Object.keys(processedResponseTimes).length === 0) {
        return [];
    }
    const [minResponseTimeTimestamp, maxResponseTimeTimestamp] = getTimestampRange(Object.keys(processedResponseTimes));
    const initialVUMetrics = filterVUMetricsBefore(vuMetrics, minResponseTimeTimestamp);
    const responseTimesData = formatDataMetrics(processedResponseTimes, vuMetrics);
    const additionalVUMetrics = findVUMetricsAfter(vuMetrics, maxResponseTimeTimestamp);
    return formatStatsTime([...initialVUMetrics, ...responseTimesData, ...additionalVUMetrics]);
};
export const formatTestDataAnomalies = (dataAnomalies) => formatDataAnomalies(dataAnomalies, (responseTimes) => responseTimes);
export const formatTransactionDataAnomalies = (dataAnomalies) => formatDataAnomalies(dataAnomalies, processResponseTimes);
export const mergeSseData = (buffer, newData) => {
    var _a, _b, _c;
    return (Object.assign(Object.assign({}, buffer), { id: newData.id, displayState: newData.displayState, vu_metric: Object.assign(Object.assign({}, buffer.vu_metric), (isObject((_a = newData.data) === null || _a === void 0 ? void 0 : _a.vu_metric) ? newData.data.vu_metric : {})), response_time: Object.assign(Object.assign({}, buffer.response_time), (((_c = (_b = newData.data) === null || _b === void 0 ? void 0 : _b.response_time) === null || _c === void 0 ? void 0 : _c.time)
            ? {
                [newData.data.response_time.time]: {
                    value: newData.data.response_time.value,
                    classification: newData.data.response_time.classification
                }
            }
            : {})) }));
};
