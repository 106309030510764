import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { isEmpty } from "@boomq/utils";
import { openedSpoilerCaptionTheme, slaReportSpoilerCaptionTheme, SlaReportBlock, SlaReportSpoilerBody, SpoilerCaption } from "./styled";
import { SlaReportProfileName } from "../SlaReportProfileName";
import { Spoiler } from "../../../common/Spoiler";
import { SlaReportItems } from "../SlaReportItems";
import { SlaReportSettings } from "../SlaReportSettings";
import { BackLinkButton } from "../../../styled/BackLinkButton";
import { BlockHeaderWithMarginTop } from "../../../styled/Common";
import { getSlaReportProfilesRoute } from "../../../../helpers/routes";
import { SPOILER_NAME } from "../../../../helpers/slaReport";
import { clearSlaReportProfileData, slaReportProfileChangeParamsField, slaReportProfileRequest } from "../../../../redux/actions/slaReportProfile";
import { getIsAuthorize, getSelectedTeamId } from "../../../../redux/reducers/authorizePlate";
import { getSlaReportOpenedSpoilerName } from "../../../../redux/reducers/slaReportProfile";
const connector = connect((state) => ({
    isAuthorized: getIsAuthorize(state),
    openedSpoilerName: getSlaReportOpenedSpoilerName(state),
    selectedTeamId: getSelectedTeamId(state)
}), {
    clearSlaReportProfileData,
    slaReportProfileChangeParamsField,
    onInit: slaReportProfileRequest
});
const isOpenedSpoiler = (openedSpoilerName, index) => openedSpoilerName === SPOILER_NAME[index];
const SlaReport = (props) => {
    const checkAuthorization = () => props.isAuthorized && !isEmpty(props.selectedTeamId) ? props.onInit(props.id) : undefined;
    useEffect(() => {
        checkAuthorization();
    }, [props.isAuthorized, props.selectedTeamId]);
    useEffect(() => {
        return () => props.clearSlaReportProfileData();
    }, []);
    const toggleSpoilerHandler = (spoilerName) => props.slaReportProfileChangeParamsField({
        field: "openedSpoilerName",
        value: spoilerName === props.openedSpoilerName ? undefined : spoilerName
    });
    return (React.createElement(SlaReportBlock, null,
        React.createElement(BlockHeaderWithMarginTop, null,
            React.createElement(BackLinkButton, { to: getSlaReportProfilesRoute() }),
            React.createElement(SlaReportProfileName, null)),
        React.createElement(Spoiler, { caption: React.createElement(SpoilerCaption, null,
                React.createElement(FormattedMessage, { defaultMessage: "SLA report settings", id: "account.sla.report.profile.settings.title" })), isOpened: isOpenedSpoiler(props.openedSpoilerName, 0), openedCaptionTheme: openedSpoilerCaptionTheme, theme: slaReportSpoilerCaptionTheme, toggle: () => toggleSpoilerHandler(SPOILER_NAME[0]) },
            React.createElement(SlaReportSpoilerBody, null,
                React.createElement(SlaReportSettings, null))),
        React.createElement(Spoiler, { caption: React.createElement(SpoilerCaption, null,
                React.createElement(FormattedMessage, { defaultMessage: "SLA report items", id: "account.sla.report.profile.items.title" })), isOpened: isOpenedSpoiler(props.openedSpoilerName, 1), openedCaptionTheme: openedSpoilerCaptionTheme, theme: slaReportSpoilerCaptionTheme, toggle: () => toggleSpoilerHandler(SPOILER_NAME[1]) },
            React.createElement(SlaReportSpoilerBody, null,
                React.createElement(SlaReportItems, null)))));
};
export default withRouter(connector(SlaReport));
