import React from "react";
import { useDispatch } from "react-redux";
import { CANCEL_BUTTON_LABEL, CONFIRM_BUTTON_LABEL, THREAD_GROUP_EDITOR_RESTORE_DATA_TEXT } from "./intl";
import { BUTTON_THEME } from "./styled";
import { ConfirmForm } from "../../../common/ConfirmForm";
import { deleteEditorBackupData, setEditorData } from "../../../../redux/actions/editor";
export const RestoreEditorDataForm = ({ editorData }) => {
    const dispatch = useDispatch();
    return (React.createElement(ConfirmForm, { confirmButton: {
            action: () => dispatch(setEditorData(editorData)),
            theme: BUTTON_THEME,
            title: CONFIRM_BUTTON_LABEL
        }, cancelButton: {
            action: () => dispatch(deleteEditorBackupData(editorData)),
            theme: BUTTON_THEME,
            title: CANCEL_BUTTON_LABEL
        }, text: {
            description: [THREAD_GROUP_EDITOR_RESTORE_DATA_TEXT]
        } }));
};
