import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { isEmpty, useIntl } from "@boomq/utils";
import { DropDownButton, DropDownItemList, DropDownSelector } from "./styled";
class DropDownList extends Component {
    constructor() {
        super(...arguments);
        this._isMounted = true;
        this.state = {
            isOpen: false
        };
        this.clickItemHandler = (e, item) => {
            const { onChange, nameKeyName = "name", paramName, paramNameKeyName, valueKeyName } = this.props;
            const buttonEl = e && e.target ? e.target.closest(".drop-down-list-button") : null;
            buttonEl &&
                typeof onChange === "function" &&
                onChange(paramNameKeyName ? item[paramNameKeyName] : paramName, item[nameKeyName], paramNameKeyName ? item[valueKeyName] : buttonEl.value);
        };
        this.toggleHandler = () => this.setState({ isOpen: !this.state.isOpen });
        this.outSideDropDownClickHandler = (e) => this.dropDownRef && !this.dropDownRef.contains(e.target) && this._isMounted && this.setState({ isOpen: false });
    }
    UNSAFE_componentWillMount() {
        document.addEventListener("click", this.outSideDropDownClickHandler, false);
    }
    componentWillUnmount() {
        this._isMounted = false;
        document.removeEventListener("click", this.outSideDropDownClickHandler, false);
    }
    render() {
        const { disabled, intl: { formatMessage }, keyName = "id", listTheme, nameKeyName = "name", valueKeyName = "id", items, theme, title } = this.props;
        return (React.createElement(DropDownSelector, { disabled: disabled, onClick: this.toggleHandler, ref: (node) => (this.dropDownRef = node), theme: theme, title: title && title.id && formatMessage(Object.assign({}, title)) },
            this.props.children,
            this.state.isOpen && (React.createElement(DropDownItemList, { theme: listTheme }, !isEmpty(items) &&
                items.map((item) => item.element ? (React.createElement(DropDownButton, { key: item[keyName] },
                    React.createElement(item.element, Object.assign({}, item.elementProps)))) : (React.createElement(DropDownButton, { action: item.action, className: "drop-down-list-button", key: item[keyName], name: item[nameKeyName], onClick: (e) => this.clickItemHandler(e, item), value: item[valueKeyName] }, item[nameKeyName] && item[nameKeyName].id ? (React.createElement(FormattedMessage, Object.assign({}, item[nameKeyName]))) : (item[nameKeyName]))))))));
    }
}
const WrappedDropDownList = (props) => {
    const { formatMessage } = useIntl();
    return React.createElement(DropDownList, Object.assign({}, props, { intl: { formatMessage } }));
};
export default WrappedDropDownList;
