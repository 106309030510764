export const BUTTON_CREATE_LABEL = {
    defaultMessage: "Create",
    id: "account.team.actions.create.button.label"
};
export const BUTTON_SAVE_LABEL = {
    defaultMessage: "Save",
    id: "account.team.actions.save.button.label"
};
export const TEAM_IMAGE_BUTTON_CANCEL_LABEL = {
    defaultMessage: "Cancel",
    id: "account.team.image.actions.cancel.button.label"
};
export const TEAM_IMAGE_EMPTY_TEXT = {
    defaultMessage: "Drag and drop your image here or choose image",
    id: "account.team.image.empty.text"
};
export const TEAM_IMAGE_ADDING_TITLE = {
    defaultMessage: "Add image",
    id: "account.team.image.adding.title"
};
export const TEAM_IMAGE_EDITING_TITLE = {
    defaultMessage: "Change image",
    id: "account.team.image.changing.title"
};
export const TEAM_MEMBERS_TITLE = {
    defaultMessage: "Team members ({teamMembersCount}):",
    id: "account.team.members.title"
};
export const TEAM_NAME_INPUT_LABEL = {
    defaultMessage: "Name",
    id: "account.team.name.input.label"
};
export const TEAM_NAME_SUBTITLE = {
    defaultMessage: "Select something that your team recognize",
    id: "account.team.name.subtitle"
};
export const TEAM_NAME_TITLE = {
    defaultMessage: "Name of your team",
    id: "account.team.name.title"
};
export const TEAM_PAGE_TITLE = { defaultMessage: "Team", id: "account.team.page.title" };
