import styled, { css } from "styled-components";
import { Color } from "../colorPallete";
export const getZIndex = (value) => (zIndex[value] ? zIndex[value] : 1);
export const Link = styled.span `
    color: #4989d6;
    cursor: pointer;
    ${(props) => (props.theme ? css(props.theme) : css ``)}
`;
export const withBorder = `
    border: 1px solid ${Color.grey75};
    border-radius: 12px;
`;
export const withDisable = css `
    pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
    opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
`;
export const withShadow = `
    box-shadow: 0px 0px 8px rgba(0, 64, 140, 0.15);
`;
export const hoverWithShadow = css `
    &:active {
        background: #f6f6f6;
        transform: scale(0.98);
    }
    &:hover {
        box-shadow: 0 0 10px rgba(0, 64, 140, 0.35);
    }
`;
export const scaleActive = css `
    transition: transform 0.3s ease-in-out;
    &:active {
        transform: scale(0.9);
    }
`;
const zIndex = {
    AutoCompleteLoader: 103,
    CustomSelectBody: 101,
    CustomSelectClosedIcon: 99,
    CustomSelectOpenedIcon: 102,
    CustomSelectUl: 100
};
