import { useEffect, useState } from "react";
import { useEventListener } from "../useEventListener";
export const useResize = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const resizeHandler = () => setWidth(window.innerWidth);
    const [addEventListener, removeEventListener] = useEventListener(window, "resize", resizeHandler);
    useEffect(() => {
        resizeHandler();
        addEventListener();
        return removeEventListener;
    }, []);
    return { width };
};
