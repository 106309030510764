import React from "react";
import { isEmpty, stopEvent } from "@boomq/utils";
import { DropDownItemList, StyledIconClose } from "./styled";
import { Label } from "../Label";
const DEFAULT_SEARCHED_ITEMS = [];
export const LabelAddDropdownTemplate = ({ searchedItems = DEFAULT_SEARCHED_ITEMS, onClose, onSelectItem }) => {
    const clickHandler = (e) => stopEvent(e);
    const clickItemHandler = (item, e) => {
        stopEvent(e);
        return typeof onSelectItem === "function" ? onSelectItem(item) : undefined;
    };
    const closeHandler = (e) => {
        stopEvent(e);
        return typeof onClose === "function" ? onClose() : undefined;
    };
    return isEmpty(searchedItems) ? (React.createElement(React.Fragment, null)) : (React.createElement(DropDownItemList, { onClick: clickHandler },
        searchedItems.map((label) => (React.createElement(Label, Object.assign({ key: label.id }, label, { onClick: (e) => clickItemHandler(label, e) })))),
        React.createElement(StyledIconClose, { onClick: closeHandler })));
};
