var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { forwardRef } from "react";
import { Label, PseudoInput, PseudoLabel, StyledCheckBox, StyledIconCheck } from "./styled";
const Checkbox = (_a, _ref) => {
    var { checked, disabled, label, name, onToggle, theme, value } = _a, rest = __rest(_a, ["checked", "disabled", "label", "name", "onToggle", "theme", "value"]);
    const changeHandler = () => undefined;
    const clickHandler = (event) => {
        event.preventDefault();
        event.stopPropagation();
        return typeof onToggle === "function" ? onToggle({ event, value }) : undefined;
    };
    return (React.createElement(StyledCheckBox, Object.assign({ disabled: disabled, onClick: clickHandler, theme: theme }, rest, { ref: _ref }),
        React.createElement(PseudoInput, { checked: checked, name: name, onChange: changeHandler, type: "checkbox" }),
        React.createElement(PseudoLabel, null,
            React.createElement(StyledIconCheck, null)),
        React.createElement(Label, null, label)));
};
export default forwardRef(Checkbox);
