import { useEffect, useRef } from "react";
const getTarget = (target) => (target && target.current ? target.current : target);
export const useEventListener = (target, type, listener, options) => {
    const getTargetListenerFuncByName = (target, funcName) => getTarget(target) && typeof getTarget(target)[funcName] === "function"
        ? () => getTarget(target)[funcName](type, listener, options)
        : () => undefined;
    const addEventListener = useRef(getTargetListenerFuncByName(target, "addEventListener"));
    const removeEventListener = useRef(getTargetListenerFuncByName(target, "removeEventListener"));
    useEffect(() => {
        addEventListener.current = getTargetListenerFuncByName(target, "addEventListener");
        removeEventListener.current = getTargetListenerFuncByName(target, "removeEventListener");
    }, [target]);
    return [addEventListener.current, removeEventListener.current];
};
