import React, { useEffect, useRef } from "react";
import { List, StyledLink, StyledSectionTitle, StyledWebsiteWrapper, TextBlock, TextSection } from "./styled";
export const UserAgreementDoc = () => {
    const privacyRef = useRef(null);
    const topRef = useRef(null);
    useEffect(() => {
        const scrollIntoRef = (nodeRef) => { var _a; return (_a = nodeRef === null || nodeRef === void 0 ? void 0 : nodeRef.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ behavior: "smooth" }); };
        const checkLocationHash = () => window.location.hash === "#privacy-policy" ? scrollIntoRef(privacyRef) : scrollIntoRef(topRef);
        checkLocationHash();
    });
    return (React.createElement(StyledWebsiteWrapper, { ref: topRef },
        React.createElement(StyledSectionTitle, null, "End User Agreement (\u201CAgreement\u201D) that defines the terms and conditions for your use of the online service boomq (\u201CBoomq\u201D)"),
        React.createElement(List, null,
            React.createElement("li", null, "The Agreement is between You, the user (\u201CUser\u201D), and Boomq."),
            React.createElement("li", null, "Your use of Boomq constitutes your agreement to these terms and conditions."),
            React.createElement("li", null, "The terms of this Agreement may change at any time. All changes will be posted online. Your continued use of Boomq after the change will constitute your acceptance of the changes.")),
        React.createElement(StyledSectionTitle, null, "Your obligations"),
        React.createElement(List, null,
            React.createElement("li", null, "The User, agrees to only load test systems that he owns, or are in lawful control of."),
            React.createElement("li", null, "The User, agrees to only access analytics systems (Google Analytics, Yandex Metrika) that you own, or are in lawful control of."),
            React.createElement("li", null, "The User, agrees to keep Boomq account password confidential, and only allow trusted people within your own organisation access to your Boomq account."),
            React.createElement("li", null, "The User agrees to accept full responsibility for any damages incurred as a result of load tests initiated by him/her.")),
        React.createElement(StyledSectionTitle, null, "Our obligations"),
        React.createElement(List, null,
            React.createElement("li", null, "Boomq agrees to always treat your account details, test configurations and test results created as a user with confidentiality and never to release this information to third parties, unless required by law to do so."),
            React.createElement("li", null, "Boomq agrees to provide the User with access to its platform in the form of a subscription described below.")),
        React.createElement(StyledSectionTitle, null, "Subscription terms"),
        React.createElement(List, null,
            React.createElement("li", null,
                "Unless otherwise agreed in the written form, use rights to Boomq are provided pursuant to a subscription plan (described ",
                React.createElement(StyledLink, { to: "/pricing" }, "here"),
                ") for the designated Subscription Term (month). Each subscription plan has different authorized use limits: concurrent tests running, VUsers, duration of the test described ",
                React.createElement(StyledLink, { to: "/pricing" }, "here"),
                ". If User desires to upgrade to a higher level subscription plan, User may do so at any time online using Boomq portal."),
            React.createElement("li", null, "The effective date of the Agreement will begin at the time User completes the online purchase process."),
            React.createElement("li", null, "The initial term of subscription runs from the effective date and will continue for the period of the subscription plan (monthly)."),
            React.createElement("li", null, "Upon expiration of the term, the subscription will automatically renew for additional periods equal to the initial subscription term unless the User cancels the subscription prior to the expiry of the current subscription term."),
            React.createElement("li", null, "If User cancels the Subscription he will be billed for the billing term already started. Subscription will be cancelled the next billing cycle (month)."),
            React.createElement("li", null, "Boomq reserves the right to suspend User access and/or use when any payment is overdue or when Boomq believes that User is using Boomq with (a) any applications, IP addresses or domain names that User does not own and/or (b) not in accordance with this Agreement, and applicable laws and government regulations or and/or (c) any other reason.")),
        React.createElement(StyledSectionTitle, null, "Pricing disclaimer"),
        React.createElement(List, null,
            React.createElement("li", null,
                "The current and up-to-date price list is located ",
                React.createElement(StyledLink, { to: "/pricing" }, "here"),
                "."),
            React.createElement("li", null, "Boomq will do everything it can to ensure that the prices listed on its website are correct and consistent. However, Boomq does reserve the right to change its prices at any time without further notice. If you are a paid subscriber of Boomq services, we will notify you in advance of any proposed pricing changes.")),
        React.createElement(StyledSectionTitle, null, "Limitations in liability"),
        React.createElement(List, null,
            React.createElement("li", null, "Boomq makes no guarantees on availability of individual load generator nodes, or the service as a whole."),
            React.createElement("li", null, "The correctness, or usability for any particular purpose, of test results depends on many factors (such as profile, scripts running, request defined). Boomq will therefore not assume responsibility for test results."),
            React.createElement("li", null, "Boomq, Free and Paid Subscription Plans and Licenses are Provided \"AS IS\". To the fullest extent permitted by law, any access to Boomq is provided on an \"AS IS\" basis, WITHOUT REMEDIES OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT."),
            React.createElement("li", null, "USER ACKNOWLEDGES AND AGREES THAT THERE IS POSSIBILITY OF COLLATERAL DAMAGE FROM ANY LOAD TESTING (INCLUDING LOAD TESTING USING BOOMQ). TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL BOOMQ BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY INCIDENTAL, CONSEQUENTIAL, PUNITIVE, SPECIAL, EXEMPLARY OR INDIRECT DAMAGES OF ANY TYPE OR KIND (INCLUDING LOST PROFITS, LOST SAVINGS, CURRENCY CONVERSION LOSSES, OR LOSS OF OTHER ECONOMIC ADVANTAGE) ARISING FROM BREACH OF WARRANTY OR BREACH OF CONTRACT, OR NEGLIGENCE, OR ANY OTHER LEGAL CAUSE OF ACTION ARISING FROM OR IN CONNECTION WITH THIS AGREEMENT BOOMQ PERFORMANCE HEREUNDER, INCLUDING WITHOUT LIMITATION (A) BOOMQ AS SOFTWARE (B) ANY INTERRUPTION OF USE OF BOOMQ (C) FOR LOSS, INACCURACY OR CORRUPTION OF YOUR DATA, EVEN IF BOOMQ HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES AND NOTWITHSTANDING THE FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY. IN NO EVENT SHALL BOOMQ LIABILITY HEREUNDER EXCEED THE AMOUNT THAT YOU PAID TO BOOMQ UNDER THIS AGREEMENT FOR THE PARTICULAR LOAD TEST GIVING RISE TO THE LIABILITY.")),
        React.createElement(StyledSectionTitle, { ref: privacyRef }, "Privacy policy"),
        React.createElement(TextSection, null,
            React.createElement(TextBlock, null, "This Privacy Policy explains how Boomq collects and uses your \u201CPersonal Information\u201D \u2013 information that identifies, relates to, describes or is capable of being associated with you as a particular individual, such as your name, address, email address or telephone number \u2013 and other data through your use of any of Boomq websites, services or APIs."),
            React.createElement(TextBlock, null, "Your use of the Boomq service is subject to this Privacy Policy in effect at the time of your use. Boomq reserves the right to update and change this Privacy Policy, inform and ask you to accept the changes from time to time.")),
        React.createElement(StyledSectionTitle, null, "Collection of personal information"),
        React.createElement(TextSection, null,
            React.createElement(TextBlock, null, "When you register for a Boomq account, you are doing so by providing an email address and/or authorizing us to use your Google/Yandex account. Both ways create you as a user with email being used as your primary identifier. Other information such as your name, company name or affiliation, email address and location are optional to your discretion."),
            React.createElement(TextBlock, null, "For paid accounts, we may collect additional personal information such as credit card number and billing address that is required to process the payment transaction. Boomq has no knowledge about your full credit card number as this resides with our payment gateway providers."),
            React.createElement(TextBlock, null, "Boomq may automatically receive and record information on our systems logs from your browser, including your IP address, cookies and the pages you request.")),
        React.createElement(StyledSectionTitle, null, "Use of personal information"),
        React.createElement(TextSection, null,
            React.createElement(TextBlock, null, "Boomq will never sell, rent or otherwise share your personal information with other people or non-affiliated companies except to provide products or services you\u2019ve requested, when we have your permission.")),
        React.createElement(StyledSectionTitle, null, "Cookies"),
        React.createElement(TextSection, null,
            React.createElement(TextBlock, null, "An HTTP cookie (also called web cookie, Internet cookie, browser cookie, or simply cookie) is a small piece of data sent from a website and stored on the user's computer by the user's web browser while the user is browsing. Cookies were designed to be a reliable mechanism for websites to remember stateful information. You can configure your browser to accept all cookies, reject all cookies or notify you when a cookie is set. If you reject all cookies, or specifically reject cookies set by Boomq, you may not be able to take full advantage of the features of Boomq products that require you to be signed in to your account or otherwise identify yourself."),
            React.createElement(TextBlock, null, "Boomq uses cookies for a number of purposes, including to:"),
            React.createElement(TextBlock, null,
                React.createElement(List, null,
                    React.createElement("li", null, "Identify you as an account holder. Require you to re-enter your password after a certain period of time has elapsed to protect you against others accidentally accessing your account contents."),
                    React.createElement("li", null, "Keep track of preferences while you are using the products."),
                    React.createElement("li", null, "Facilitate product development by access aggregated, de-identified information on product usage (such as Google Analytics etc).")))),
        React.createElement(StyledSectionTitle, null, "Login with Google/Yandex"),
        React.createElement(List, null,
            React.createElement("li", null, "When you are signing up or logging in using your Yandex or Google account you grant boomq time-limited access to the statistical information stored in those accounts, such as Google Analytics and Yandex.Metrics."),
            React.createElement("li", null, "Boomq uses that information to create a profile for the loadtest and for no other reason."),
            React.createElement("li", null, "Under no circumstances Boomq products will share that information with any third-party."),
            React.createElement("li", null, "By using Boomq you agree that you are the rightful owner of the account you used to login and you have permission to conduct a loadtest on the websites you have access to through those accounts."),
            React.createElement("li", null, "By no means Boomq is to be responsible for any damages in case you are running a test on a third-party website which you do not control or own."),
            React.createElement("li", null, "You can always revoke access from the apps you granted it, including Boomq in respective accounts. It might result in limited functionality of Boomq and inability to logon."))));
};
