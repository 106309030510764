export const INCORRECT_SETTINGS_SET_NAME_EMPTY = {
    defaultMessage: "Settings set name can't be empty",
    id: "account.test.project.settings.set.incorrect.name"
};
export const NEW_SETTINGS_SET_ACTION_CONFIRM_TOOLTIP = {
    defaulMessage: "Add",
    id: "account.settings.set.sidebar.new.action.confirm.tooltip"
};
export const NEW_SETTINGS_SET_ACTION_CANCEL_TOOLTIP = {
    defaulMessage: "Cancel",
    id: "account.settings.set.sidebar.new.action.cancel.tooltip"
};
