export const WELCOME_ALL_TESTS_TITLE = {
    defaultMessage: "Test runs",
    id: "account.user.welcome.all.tests.title"
};
export const WELCOME_ALL_TESTS_SUBTITLE = {
    defaultMessage: "That's where we'll keep all the runs of the tests you'll create. You can run one test a multiple times and then compare results.",
    id: "account.user.welcome.all.tests.subtitle"
};
export const WELCOME_ALL_TESTS_BUTTON_LABEL_ADD = {
    defaultMessage: "Add new test",
    id: "account.user.welcome.all.tests.button.label.add"
};
export const WELCOME_ALL_TESTS_BUTTON_LABEL_GO = {
    defaultMessage: "Go to tests",
    id: "account.user.welcome.all.tests.button.label.go"
};
