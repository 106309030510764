import { handleActions } from 'redux-actions';
import { userSitesSuccess, userSitesFailure, userSitesRequest } from '../../actions/admin/sites';
const initialState = {
    error: null,
    isFetching: false,
    sites: []
};
export default handleActions({
    [userSitesRequest]: (state) => (Object.assign(Object.assign({}, state), { isFetching: true, sites: [] })),
    [userSitesSuccess]: (state, action) => (Object.assign(Object.assign({}, state), { isFetching: false, sites: action.payload })),
    [userSitesFailure]: (state, action) => (Object.assign(Object.assign({}, state), { isFetching: false, error: action.payload }))
}, initialState);
export const getListOfSites = state => state.sites.sites;
export const getSitesLoading = state => state.sites.isFetching;
