import styled from "styled-components";
import { Color } from "@boomq/uikit";
export const SlaResultBlock = styled.div `
    align-items: center;
    background: ${({ color }) => (color ? color : "none")};
    border: 1px solid #d6d6d6;
    border-radius: 12px;
    color: ${Color.darkGrey5};
    display: flex;
    justify-content: center;
    min-width: 120px;
    width: 120px;
`;
