import React from "react";
import { ThreadGroupList } from "@boomq/common";
import { ThreadGroup } from "../ThreadGroup";

export const ThreadGroups = ({ loadGeneratorLabel, profileDistribution, threadGroups }): JSX.Element => (
    <ThreadGroupList>
        {(threadGroups || []).map((threadGroup) => (
            <ThreadGroup
                key={threadGroup.id}
                loadGeneratorLabel={loadGeneratorLabel}
                profileDistribution={profileDistribution}
                {...threadGroup}
            />
        ))}
    </ThreadGroupList>
);
