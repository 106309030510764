export const API_ERROR_TITLE = { id: "api.error.title", defaultMessage: "Error" };
export const JMETER_UNSUPPORTED_PROPERTIES_TITLE = {
    defaultMessage: "Attention",
    id: "account.new.settings.file.unsupported.jmeter.modules.title"
};
export const JMX_FILE_UNSUPPORTED_MODULES_TITLE = {
    defaultMessage: "Attention",
    id: "account.new.settings.file.unsupported.jmeter.modules.title"
};
export const JMX_FILE_NEEDED_FILES_TEXT = {
    defaultMessage: "We are missing some files referenced by JMX. Please make sure you’ve uploaded the following:",
    id: "api.error.exceptionMessage.MISMATCH_NEEDED_FILES"
};
export const REPORT_SYSTEM_METRIC_TITLE = {
    defaultMessage: "Attention",
    id: "account.report.exceptionMessage.TEST_DOESNT_HAVE_SYSTEM_MONITORING.modal.title"
};
export const TEAM_MEMBER_PROCESS_TITLE = {
    defaultMessage: "Attention",
    id: "account.team.exception.form"
};
export const TEST_PROJECT_DETAILS_DESCRIPTION = {
    defaultMessage: "We detected an issue with your test compatibility. Please reach out to customer support via chat or email for resolution.",
    id: "account.test.project.setting.file.error"
};
export const TEST_PROJECT_DETAILS_TITLE = {
    id: "api.error.project.compatibility.title",
    defaultMessage: "Test compatibility"
};
