var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import styled, { css, keyframes } from "styled-components";
import { Color, IconAlertCircle } from "@boomq/uikit";
export const IconMenu = styled.i `
    cursor: pointer;
    position: relative;
    display: block;
    transition: all 0.3s ease-in-out;
    ${(props) => props.modal
    ? css `
                  position: absolute;
                  top: 50px;
                  right: 50px;
              `
    : css ``}
    &:after {
        border-radius: 5px;
        content: "...";
        display: block;
        left: 50%;
        ${(props) => (props.bg && props.bg.length > 0 ? `background: ${props.bg}` : "")};
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%) rotate(90deg);
    }
    &:hover {
        &:after {
            ${(props) => props.hover && props.hover.length > 0 ? `background: ${props.hover}` : ""};
        }
    }
    ${(props) => props.size === "sm"
    ? css `
                  width: 14px;
                  height: 14px;
                  &:after {
                      width: 19px;
                      height: 2px;
                  }
              `
    : css ``}
    ${(props) => props.size === "md"
    ? css `
                  width: 20px;
                  height: 20px;
                  &:after {
                      width: 25px;
                      height: 3px;
                  }
              `
    : ""}
    ${(props) => props.size === "lg"
    ? css `
                  width: 30px;
                  height: 30px;
                  &:after {
                      width: 40px;
                      height: 4px;
                  }
              `
    : css ``}
    ${(props) => (props.theme ? css(props.theme) : css ``)}
`;
export const IconRound = styled.i `
    display: block;
    width: 12px;
    height: 12px;
    ${(props) => (props.bg && props.bg.length > 0 ? `background: ${props.bg}` : "")};
    border-radius: 50%;
`;
IconRound.defaultProps = {
    bg: "#000000"
};
export const IconAdd = (props) => (React.createElement("svg", Object.assign({ width: "15", height: "15", viewBox: "0 0 15 15" }, props),
    React.createElement("path", { clipRule: "evenodd", d: "M7.613 0a.937.937 0 00-.937.938V6H.937a.937.937 0 100 1.875h5.739v5.563a.937.937 0 101.875 0V7.875h5.511a.937.937 0 100-1.875H8.552V.937A.937.937 0 007.613 0z" })));
export const IconAddLarge = (props) => (React.createElement("svg", Object.assign({ width: "24", height: "23", viewBox: "0 0 24 23", fill: "#4394E8" }, props),
    React.createElement("rect", { x: 11, width: 3, height: 23, rx: 1.5 }),
    React.createElement("rect", { x: 24, y: 10, width: 3, height: 24, rx: 1.5, transform: "rotate(90 24 10)" })));
export const IconChartDot = (props) => {
    const { stroke } = props, rest = __rest(props, ["stroke"]);
    return (React.createElement("svg", Object.assign({}, rest, { width: "10", height: "10", fill: stroke, viewBox: "0 0 10 10" }),
        React.createElement("circle", { cx: "5", cy: "5", r: "3", stroke: stroke, strokeWidth: "2", fill: "#fff" })));
};
export const IconCopy = (props) => (React.createElement("svg", Object.assign({ width: "12", height: "15", viewBox: "0 0 12.33 14.936" }, props),
    React.createElement("path", { d: "M10.677 0H4.4C3.49 0 2.746.742 2.746 1.654v.202H1.654C.742 1.856 0 2.6 0 3.51v9.774c.002.91.742 1.652 1.654 1.652h6.275c.91 0 1.653-.742 1.653-1.653v-.203h1.095c.91 0 1.653-.742 1.653-1.654V1.654A1.657 1.657 0 0010.677 0zm-2.16 13.283a.588.588 0 01-.587.586H1.654a.588.588 0 01-.587-.586V3.509c0-.324.264-.587.587-.587h6.275c.323 0 .586.263.586.587l.002 9.774zm2.746-1.855a.588.588 0 01-.586.587H9.584V3.51c0-.911-.743-1.654-1.654-1.654H3.815v-.202c0-.323.264-.587.587-.587h6.275c.323 0 .586.264.586.587v9.774z", fill: "#396496" })));
export const IconCopyPath = (props) => (React.createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: "42", height: "24", version: "1.1", viewBox: "0 0 24 24", title: "Copy path" }, props),
    React.createElement("g", null,
        React.createElement("path", { fillRule: "evenodd", d: "M15 13V12C15 10.346 13.654 9 12 9H11V5.667C11 5.299 11.299 5 11.667 5H18.333C18.701 5 19 5.299 19 5.667V12.333C19 12.701 18.701 13 18.333 13H15ZM15 15H18.333C19.804 15 21 13.804 21 12.333V5.667C21 4.196 19.804 3 18.333 3H11.667C10.196 3 9 4.196 9 5.667V9H6C4.346 9 3 10.346 3 12V18C3 19.654 4.346 21 6 21H12C13.654 21 15 19.654 15 18V15ZM13 12C13 11.449 12.551 11 12 11H6C5.448 11 5 11.449 5 12V18C5 18.551 5.448 19 6 19H12C12.551 19 13 18.551 13 18V12Z" })),
    React.createElement("g", null,
        React.createElement("rect", { fill: Color.white, width: "10", height: "6", x: "11", y: "2.5" }),
        React.createElement("text", { x: "12", y: "6" },
            React.createElement("tspan", { x: "12", y: "6", fontSize: "8", strokeWidth: "0.3" }, "PATH")))));
export const IconCopyURLWithFormattedParams = (props) => (React.createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: "32", height: "24", version: "1.1", viewBox: "0 0 24 24", title: "Copy url without resolved parameters" }, props),
    React.createElement("g", null,
        React.createElement("path", { fillRule: "evenodd", d: "M15 13V12C15 10.346 13.654 9 12 9H11V5.667C11 5.299 11.299 5 11.667 5H18.333C18.701 5 19 5.299 19 5.667V12.333C19 12.701 18.701 13 18.333 13H15ZM15 15H18.333C19.804 15 21 13.804 21 12.333V5.667C21 4.196 19.804 3 18.333 3H11.667C10.196 3 9 4.196 9 5.667V9H6C4.346 9 3 10.346 3 12V18C3 19.654 4.346 21 6 21H12C13.654 21 15 19.654 15 18V15ZM13 12C13 11.449 12.551 11 12 11H6C5.448 11 5 11.449 5 12V18C5 18.551 5.448 19 6 19H12C12.551 19 13 18.551 13 18V12Z" })),
    React.createElement("g", null,
        React.createElement("rect", { fill: Color.white, width: "10", height: "6", x: "11", y: "2.5" }),
        React.createElement("text", { x: "12", y: "6" },
            React.createElement("tspan", { x: "12", y: "6", fontSize: "8", strokeWidth: "0.3" }, "{$}")))));
export const IconDownload = (props) => (React.createElement("svg", Object.assign({ width: "26", height: "24.21", viewBox: "0 0 26 24.21" }, props),
    props.title && React.createElement("title", null, props.title),
    React.createElement("g", { transform: "rotate(90 2374.7 -2329.7)" },
        React.createElement("path", { d: "m18.567 6.7362c0.0054-0.00531 0.0054-0.00531 0.0107-0.01061 0.016-0.02123 0.0319-0.03715 0.0426-0.05838 0.0053-0.00531 0.0053-0.01062 0.0106-0.01592 0.0107-0.02123 0.0267-0.04246 0.0373-0.06369 0-0.00531 0.0053-0.01062 0.0053-0.01062 0.0107-0.02122 0.0213-0.04245 0.032-0.06899 0-0.00531 0-0.00531 0.0053-0.01061 0.0107-0.02123 0.016-0.04777 0.0266-0.0743 0-0.00531 0-0.01062 0.0054-0.01062 0.0053-0.02654 0.0159-0.04776 0.0159-0.0743 0-0.01061 0-0.01592 0.0054-0.02654 0.0053-0.02122 0.0053-0.04245 0.0106-0.06368 0.0053-0.03185 0.0053-0.05838 0.0053-0.09023 0-0.03184 0-0.05837-0.0053-0.09022 0-0.02123-0.0053-0.04246-0.0106-0.06368 0-0.01062 0-0.01593-0.0054-0.02654-0.0053-0.02654-0.0106-0.04776-0.0159-0.0743 0-0.00531 0-0.01062-0.0054-0.01062-0.0053-0.02653-0.0159-0.04776-0.0266-0.0743 0-0.0053 0-0.0053-0.0053-0.01061-0.0107-0.02123-0.0213-0.04777-0.032-0.06899 0-0.00531-0.0053-0.01062-0.0053-0.01062-0.0106-0.02123-0.0213-0.04246-0.0373-0.06368-0.0053-0.00531-0.0053-0.01062-0.0106-0.01593-0.016-0.02123-0.0266-0.04245-0.0426-0.05838-0.0053-0.0053-0.0053-0.0053-0.0107-0.01061-0.0213-0.02123-0.0372-0.04777-0.0639-0.06899l-5.2661-5.2435c-0.3567-0.35558-0.9371-0.35558-1.2939 0-0.3567 0.35558-0.3567 0.93407 0 1.2897l3.706 3.6938h-14.733c-0.50584 0-0.91584 0.40866-0.91584 0.90753 0 0.50419 0.41 0.91284 0.91584 0.91284h14.739l-3.6793 3.6673c-0.3568 0.3556-0.3568 0.9341 0 1.2897 0.1757 0.1751 0.41 0.2653 0.6443 0.2653s0.4686-0.0902 0.6443-0.2653l5.2341-5.217c0.0266-0.03184 0.0479-0.05307 0.0692-0.0743z", fill: "#396496", transform: "translate(4704.4 25.84)" })),
    React.createElement("g", { transform: "rotate(-90 2352.1 2371.1)" },
        React.createElement("path", { d: "m4.9573 1.8204h6.8156c0.5058 0 0.9158-0.40865 0.9158-0.90753 0-0.50418-0.41-0.91284-0.9158-0.91284h-6.8156c-2.7316 0-4.9573 2.2184-4.9573 4.941v16.118c0 2.7226 2.2257 4.941 4.9573 4.941h6.7037c0.5059 0 0.9159-0.4087 0.9159-0.9075 0-0.5042-0.41-0.9129-0.9159-0.9129h-6.7037c-1.7252 0-3.1309-1.4011-3.1309-3.1206v-16.118c0.00533-1.7248 1.4057-3.1206 3.1309-3.1206z", fill: "#396496", transform: "translate(4699,19)" }))));
export const IconEdit = (props) => (React.createElement("svg", Object.assign({ width: "15", height: "15", viewBox: "0 0 15 15" }, props),
    React.createElement("path", { d: "M9.22 1.051C5.813 4.454 5.639 4.692 1.1 9.249.947 9.471.62 11.262.474 11.93l-.47 2.603c-.043.243.161.432.452.444v-.002c.072.018 1.65-.213 3.595-.654.918-.212 1.464-.304 1.728-.475.634-.571 1.127-1.044 4.089-3.956 2.255-2.188 4.04-3.964 4.083-4.06 1.214-1.236 1.596-2.968-.005-4.577-1.584-1.527-3.423-1.39-4.726-.202zm4.158.677c1.55 1.703.478 3.043.042 3.576-1.416-1.4-2.308-2.417-3.7-3.779.95-.808 2.327-1.085 3.658.203zM9.211 2.1c.7.71 1.004.996 1.68 1.706-2.596 2.6-4.845 4.738-7.26 7.061a91.667 91.667 0 01-1.583-1.522c3.4-3.426 4.8-4.882 7.163-7.245zm2.177 2.27c.757.77.72.734 1.457 1.471C10.462 8.05 9.562 9.07 5.594 12.92l-1.462-1.523zm-9.75 5.657l1.645 1.62 1.577 1.597-1.166.353c-1.378.354-2.657.62-2.657.62a.327.327 0 01-.215-.183c-.027-.051.179-.905.458-2.394.13-.628.29-1.39.358-1.613z", fill: "#396496" })));
const rotate = keyframes `
    100% {
        transform: rotate(360deg);
    }
`;
const Loader = (props) => (React.createElement("svg", Object.assign({ fill: "#2D50A7", version: "1.1", viewBox: "0 0 213.33 213.33" }, props),
    React.createElement("path", { d: "m203.64 101.82h-38.788c-5.355 0-9.697 4.342-9.697 9.697s4.342 9.697 9.697 9.697h38.788c5.355 0 9.697-4.342 9.697-9.697s-4.341-9.697-9.697-9.697z" }),
    React.createElement("path", { d: "m48.485 101.82h-38.788c-5.355 0-9.697 4.342-9.697 9.697s4.342 9.697 9.697 9.697h38.788c5.355 0 9.697-4.342 9.697-9.697s-4.342-9.697-9.697-9.697z", fillOpacity: ".6" }),
    React.createElement("path", { d: "m168.38 36.09-27.428 27.428c-3.787 3.786-3.787 9.926 0 13.713 1.893 1.894 4.375 2.841 6.856 2.841 2.482 0 4.964-.946 6.857-2.841l27.428-27.428c3.787-3.786 3.787-9.926 0-13.713-3.786-3.787-9.926-3.787-13.713 0z", fillOpacity: ".9" }),
    React.createElement("path", { d: "m106.67 169.7c-5.355 0-9.697 4.342-9.697 9.697v19.394c0 5.355 4.342 9.697 9.697 9.697s9.697-4.342 9.697-9.697v-19.394c0-5.355-4.342-9.697-9.697-9.697z", fillOpacity: ".4" }),
    React.createElement("path", { d: "m58.669 145.8-27.427 27.428c-3.787 3.787-3.787 9.926 0 13.713 1.893 1.893 4.375 2.84 6.857 2.84s4.964-.947 6.856-2.84l27.427-27.428c3.787-3.787 3.787-9.926 0-13.713-3.786-3.787-9.926-3.787-13.713 0z", fillOpacity: ".5" }),
    React.createElement("path", { d: "m106.67 4.848c-5.355 0-9.697 4.342-9.697 9.697v38.788c0 5.355 4.342 9.697 9.697 9.697s9.697-4.342 9.697-9.697v-38.788c0-5.355-4.342-9.697-9.697-9.697z", fillOpacity: ".8" }),
    React.createElement("path", { d: "m44.956 36.09c-3.786-3.787-9.926-3.787-13.713 0s-3.787 9.926 0 13.713l27.427 27.428c1.893 1.894 4.375 2.841 6.857 2.841 2.481 0 4.964-.947 6.856-2.841 3.787-3.786 3.787-9.926 0-13.713l-27.427-27.428z", fillOpacity: ".7" })));
export const IconLoader = styled(Loader) `
    max-width: 20px;
    height: auto;
    animation: ${rotate} 0.7s linear infinite;
`;
export const IconPlay = (props) => (React.createElement("svg", Object.assign({ width: "15", height: "15", viewBox: "0 0 1000 1000" }, props),
    React.createElement("path", { d: "M859.6 402.3L219.2 26.8c-16.3-8.8-59-29.5-101.3-5.8s-39.6 66.2-39.6 86.1v788.5c0 13.3-5.3 67.6 54.5 86.7 59.7 19.1 75.9-2.8 86.4-6.3l665.3-387.9c22.4-13.3 37.4-30.4 37.4-90 0-59.7-42.1-81.5-62.3-95.8zM835 498.1c-2.1 23.6-2.6 27.1-25 40.4l-516.4 301c-10.5 3.6-106.6 54.3-128.5 39-19.6-13.7-12.4-44.1-12.4-57.3V194c0-19.9-3.8-77.2 14.8-86.1 20-9.6 76 39.5 126.1 67.8L810 464.3c20.2 14.4 26.6 15.4 25 33.8z", fill: "#396496" })));
export const IconPlayEntire = (props) => (React.createElement("svg", Object.assign({ width: "25.827831", height: "30", viewBox: "0 0 25.828 30" }, props),
    React.createElement("path", { d: "M23.92 12.009L4.318.514c-.5-.27-1.806-.903-3.101-.178C-.08 1.062.004 2.363.004 2.972v24.137c0 .408-.163 2.07 1.668 2.655 1.828.584 2.323-.086 2.645-.193l20.366-11.875c.686-.407 1.145-.93 1.145-2.755 0-1.827-1.289-2.495-1.907-2.932z", fill: "#fff" })));
export const IconRemove = (props) => (React.createElement("svg", Object.assign({ width: "13", height: "17", viewBox: "0 0 13 17" }, props),
    React.createElement("path", { d: "M8.713 5.8a.375.375 0 00-.375.374v7.085a.375.375 0 00.75 0V6.174a.375.375 0 00-.375-.375zM4.29 5.8a.375.375 0 00-.374.374v7.085a.375.375 0 00.75 0V6.174a.375.375 0 00-.375-.375z", fill: "#396496" }),
    React.createElement("path", { d: "M1.065 4.765v9.236c0 .546.2 1.058.55 1.426.348.369.832.578 1.339.58h7.092c.507-.002.992-.211 1.34-.58.35-.368.55-.88.55-1.426V4.765a1.432 1.432 0 00-.368-2.816h-1.92v-.468A1.473 1.473 0 008.165 0H4.836A1.473 1.473 0 003.35 1.48v.47H1.432a1.432 1.432 0 00-.367 2.815zm8.981 10.492H2.954c-.641 0-1.14-.551-1.14-1.256V4.798h9.372v9.203c0 .705-.499 1.256-1.14 1.256zM4.101 1.48A.722.722 0 014.836.75h3.328a.722.722 0 01.735.73v.47H4.101v-.47zM1.432 2.699h10.136a.675.675 0 010 1.35H1.432a.675.675 0 110-1.35z", fill: "#396496" }),
    React.createElement("path", { d: "M6.5 5.8a.375.375 0 00-.375.374v7.085a.375.375 0 00.75 0V6.174a.375.375 0 00-.375-.375z", fill: "#396496" })));
export const IconSaveAs = (props) => (React.createElement("svg", Object.assign({ width: "20.112051", height: "15.000005", viewBox: "0 0 5.321 3.969" }, props),
    React.createElement("g", { transform: "translate(-1.86 -206.635)" },
        React.createElement("path", { d: "M3.285 209.504a.142.142 0 10.2.2l.979-.977a.14.14 0 00.04-.107.14.14 0 00-.04-.108l-.978-.978a.142.142 0 10-.2.2l.742.744H1.502a.142.142 0 000 .283h2.526zm2.83-2.869H3.28a.567.567 0 00-.567.567v.567h.284v-.567c0-.156.127-.283.283-.283h2.835c.157 0 .284.127.284.283v2.835a.283.283 0 01-.284.284H3.28a.283.283 0 01-.283-.284v-2.268h-.284v2.268c0 .313.254.567.567.567h2.835a.567.567 0 00.567-.567v-2.835a.567.567 0 00-.567-.567z" }),
        React.createElement("circle", { cx: 4.876, cy: 208.613, r: 0.168 }),
        React.createElement("circle", { r: 0.168, cy: 208.613, cx: 5.409 }),
        React.createElement("circle", { cx: 5.943, cy: 208.613, r: 0.168 }))));
export const IconSwitch = (props) => (React.createElement("svg", Object.assign({ width: "42.37", height: "51.9", viewBox: "0 0 42.37 51.9" }, props),
    React.createElement("path", { stroke: "#396496", strokeWidth: 5, d: "M37.434 30.742L21.14 46.954 4.848 30.66l-1.414 1.577L20.433 49.4h1.414l17.001-17.163zM20.498 2.5l-17 17.13 1.414 1.479 16.293-16.26 16.293 16.31 1.414-1.537-17-17.122z" })));
export const IconTeam = (props) => (React.createElement("svg", Object.assign({ width: "30", height: "24", version: "1.1", viewBox: "0 0 30 24", xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "m24 10c0-.551-.448-1-1-1s-1 .449-1 1 .448 1 1 1 1-.449 1-1zm2 0c0 1.654-1.346 3-3 3s-3-1.346-3-3 1.346-3 3-3 3 1.346 3 3zm-9-3c0-1.103-.897-2-2-2s-2 .897-2 2 .897 2 2 2 2-.897 2-2zm2 0c0 2.206-1.794 4-4 4s-4-1.794-4-4 1.794-4 4-4 4 1.794 4 4zm.94 8.046c.869-.672 1.939-1.046 3.06-1.046 2.757 0 5 2.243 5 5 0 .552-.447 1-1 1s-1-.448-1-1c0-1.654-1.346-3-3-3-.683 0-1.332.234-1.856.649.544.996.856 2.138.856 3.351 0 .552-.447 1-1 1s-1-.448-1-1c0-2.757-2.243-5-5-5s-5 2.243-5 5c0 .552-.447 1-1 1s-1-.448-1-1c0-3.86 3.141-7 7-7 1.927 0 3.673.783 4.94 2.046z" }),
    React.createElement("path", { d: "m6 10c0-.551.448-1 1-1s1 .449 1 1-.448 1-1 1-1-.449-1-1zm-2 0c0 1.654 1.346 3 3 3s3-1.346 3-3-1.346-3-3-3-3 1.346-3 3zm6.06 5.046c-.869-.672-1.939-1.046-3.06-1.046-2.757 0-5 2.243-5 5 0 .552.447 1 1 1s1-.448 1-1c0-1.654 1.346-3 3-3 .683 0 1.332.234 1.856.649.84737-1.0971 0 0 1.204-1.603z" })));
export const IconWarn = (props) => (React.createElement("svg", Object.assign({ width: "28", height: "20", viewBox: "0 0 28.941 20.941" }, props),
    React.createElement("path", { d: "M13.855 13.712l.144.294.144-.294a.16.16 0 10-.288 0zm.143-.015a.067.067 0 100-.135.067.067 0 000 .135z", fill: "#396496" }),
    React.createElement("path", { className: "prefix__fil1", d: "M14.35 0C8.568 0 3.88 4.688 3.88 10.47c0 5.782 4.688 10.47 10.47 10.47 5.782 0 10.47-4.688 10.47-10.47C24.82 4.688 20.132 0 14.35 0zm0 19.633a9.163 9.163 0 11.001-18.326 9.163 9.163 0 01-.001 18.326z", fill: "#396496" }),
    React.createElement("path", { className: "prefix__fil1", d: "M15.352 6.077c0 .359-.015.949-.036 1.308l-.31 5.722c-.02.36-.314.654-.656.654s-.637-.295-.656-.654l-.31-5.722a29.68 29.68 0 01-.036-1.308V4.549c0-.359.295-.654.654-.654h.696c.36 0 .654.295.654.654v1.528zm0 10.293a.657.657 0 01-.654.654h-.696a.657.657 0 01-.654-.654v-.523c0-.36.295-.654.654-.654h.696c.36 0 .654.295.654.654v.523zM2.975 15.92c-1.908-3.725-1.67-8.279.622-11.79.067-.09-.096-.146-.282-.246-.13-.058-.643-.303-.82-.375-.137-.06-.368-.178-.432-.1-2.485 3.951-2.788 9.255-.575 13.395.067.115.3-.015.504-.118.3-.153.567-.304.813-.482.115-.084.247-.172.17-.283zM25.966 15.92c1.908-3.725 1.67-8.279-.622-11.79-.068-.09.095-.146.282-.246.13-.058.642-.303.82-.375.137-.06.367-.178.431-.1 2.485 3.951 2.789 9.255.576 13.395-.068.115-.3-.015-.504-.118a6.085 6.085 0 01-.813-.482c-.116-.084-.248-.172-.17-.283z", fill: "#396496" })));
export const IconWarning = styled(IconAlertCircle) `
    fill: ${Color.orange};
`;
const calendarIcon = (props) => (React.createElement("svg", Object.assign({ width: "16", height: "16", viewBox: "0 0 16 16" }, props),
    React.createElement("path", { d: "M14.049 1.735a1.426 1.426 0 00-.098 0h-1.233V.674c0-.425-.405-.675-.829-.675h-1.137c-.423 0-.732.25-.732.674v1.06H5.396V.674C5.396.25 5.088 0 4.664 0H3.527C3.103 0 2.7.25 2.7.674v1.06H1.466A1.426 1.426 0 000 3.218v11.136c0 .848.617 1.638 1.465 1.638H13.95c.848 0 1.464-.79 1.464-1.638V3.218a1.426 1.426 0 00-1.366-1.483zM10.752.77h1.195v2.12h-1.195V.77zm-7.225 0h1.098v2.12H3.527V.77zm11.117 13.583c0 .424-.27.867-.693.867H1.466c-.424 0-.694-.443-.694-.867V6.166h13.873v8.188zm0-11.136v2.177H.773V3.218a.655.655 0 01.694-.713h1.233v.424c0 .424.404.732.828.732h1.137a.713.713 0 00.732-.732v-.424h4.624v.424a.713.713 0 00.732.732h1.137c.424 0 .829-.308.829-.732v-.424h1.233a.655.655 0 01.694.713z", fill: "#396496" })));
export const IconCalendar = styled(calendarIcon) `
    cursor: pointer;
    flex: 1;
    textalign: "right";
`;
export const IconView = (props) => (React.createElement("svg", Object.assign({ width: "20.396", height: "13", fill: "#396496", viewBox: "0 0 803.31 512" }, props),
    React.createElement("g", { transform: "matrix(1.569 0 0 1.569 0 -145.65)" },
        React.createElement("path", { d: "m508.74 246.04c-4.574-6.257-113.56-153.21-252.75-153.21s-248.18 146.95-252.75 153.2c-4.332 5.936-4.332 13.987 0 19.923 4.569 6.257 113.56 153.21 252.75 153.21s248.17-146.95 252.75-153.2c4.338-5.935 4.338-13.992 0-19.922zm-252.75 139.36c-102.53 0-191.33-97.533-217.62-129.42 26.253-31.913 114.87-129.4 217.62-129.4 102.52 0 191.32 97.516 217.62 129.42-26.253 31.912-114.87 129.4-217.62 129.4z" })),
    React.createElement("g", { transform: "matrix(1.569 0 0 1.569 0 -145.65)" },
        React.createElement("path", { d: "m256 154.72c-55.842 0-101.28 45.433-101.28 101.28s45.433 101.28 101.28 101.28 101.28-45.433 101.28-101.28-45.433-101.28-101.28-101.28zm0 168.79c-37.23 0-67.516-30.287-67.516-67.516s30.287-67.516 67.516-67.516 67.516 30.287 67.516 67.516-30.286 67.516-67.516 67.516z" }))));
