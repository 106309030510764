import { handleActions } from "redux-actions";
import { getFromLocalStorage } from "@boomq/utils";
import { showModal, setLanguage } from "../actions/language";
export const initialState = {
    isVisible: false,
    language: getFromLocalStorage("language") || "en"
};
export default handleActions({
    [showModal]: (state) => (Object.assign(Object.assign({}, state), { isVisible: !state.isVisible })),
    [setLanguage]: (state, action) => (Object.assign(Object.assign({}, state), { language: action.payload ? action.payload : state.language, isVisible: false }))
}, initialState);
export const getCurrentLanguage = (state) => state && state.languagePlate ? state.languagePlate.language : initialState.language;
