import { dictionaryRu as commonRu, dictionaryEn as commonEn } from "@boomq/common";
import ru from "./ru";
import en from "./en";
import api_exception_messages_ru from "./api_exception_messages_ru";
import api_exception_messages_en from "./api_exception_messages_en";
import team_ru from "./team_ru";
import team_en from "./team_en";

export default {
    ru: { ...commonRu, ...api_exception_messages_ru, ...team_ru, ...ru },
    en: { ...commonEn, ...api_exception_messages_en, ...team_en, ...en }
};
