import React from "react";
import { Radio, Tooltip } from "@boomq/uikit";
import { SETTINGS_SETS_SIDEBAR_ACTIONS_EDIT_TOOLTIP } from "./intl";
import { Action, LabelSet, SettingsSetItem, SettingsSetName, StyledIconEdit, StyledLabel, StyledLink } from "./styled";
import { getContrastTextColorByColor } from "../../../helpers/color";
const prepareLabels = (labelSet = []) => (labelSet || []).map((labelSetItem) => (Object.assign(Object.assign({}, labelSetItem), { id: labelSetItem.label, name: labelSetItem.label })));
const renderLabels = (labels) => Array.isArray(labels) && labels.length > 0 ? (React.createElement(LabelSet, null, labels.map((label) => (React.createElement(StyledLabel, Object.assign({ key: label.id }, label, { textColor: getContrastTextColorByColor(label.color) })))))) : (React.createElement(React.Fragment, null));
const SettingsSetSidebarItem = ({ id, labelSet, name, onClick, selectedSettingsSetId }) => {
    const clickRadioHandler = () => (typeof onClick === "function" ? onClick(id) : undefined);
    const labels = prepareLabels(labelSet);
    return (React.createElement(SettingsSetItem, null,
        React.createElement(Radio, { checked: selectedSettingsSetId === id, name: `settingsSet_${id}`, onClick: clickRadioHandler }),
        React.createElement(SettingsSetName, { title: name }, name),
        renderLabels(labels),
        React.createElement(Action, null,
            React.createElement(Tooltip, { title: SETTINGS_SETS_SIDEBAR_ACTIONS_EDIT_TOOLTIP },
                React.createElement(StyledLink, { to: `/account/test-settings/${id}` },
                    React.createElement(StyledIconEdit, null))))));
};
export default SettingsSetSidebarItem;
