import { unEscapeComponent } from "../unEscapingComponent";
export const parseQueryParamsFromSearchParams = (searchParams) => {
    try {
        let res = {};
        for (const [key, value] of searchParams) {
            res = Object.assign(Object.assign({}, res), { [unEscapeComponent(key)]: unEscapeComponent(value) });
        }
        return res;
    }
    catch (e) {
        return {};
    }
};
