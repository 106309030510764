import React from "react";
import { connect } from "react-redux";
import { isEmpty, useIntl } from "@boomq/utils";
import { WEBHOOK_ADDING_LABEL, WEBHOOKS_TITLE } from "./intl";
import { WebhookContainer } from "../Webhook";
import { BlockContent, BlockTitle } from "../../../../components/styled/Blocks";
import { BlockTitleText } from "../../../../components/account/TestProjectSettingsSet/BlockTitleWithWarning/styled";
import { Actions, StyledIconPlus } from "../../../../components/account/TestProjectSettingsSet/UrlOverwrite/styled";
import { Action } from "../../../../components/account/TestProjectSettingsSet/Webhook/styled";
import { StyledBlock } from "../../../../components/account/TestProjectSettingsSet/Wizard/styled";
import { formatIntlMessage } from "../../../../helpers/intl";
import { addWebhookItem } from "../../../../redux/actions/webhooks";
import { getWebhookItems } from "../../../../redux/reducers/webhooks";
const connector = connect((state) => ({
    webhooks: getWebhookItems(state)
}), {
    onAdd: addWebhookItem
});
const isExistEmptyWebhook = (webhooks) => webhooks.some(({ testDisplayStateSet }) => isEmpty(testDisplayStateSet));
const WebhooksContainer = ({ onAdd, webhooks }) => {
    const { formatMessage } = useIntl();
    const webhookAddingLabel = formatIntlMessage(WEBHOOK_ADDING_LABEL, formatMessage);
    const clickAddNewWebhookHandler = () => onAdd();
    return (React.createElement(StyledBlock, null,
        React.createElement(BlockTitle, null,
            React.createElement(BlockTitleText, null, formatMessage(WEBHOOKS_TITLE))),
        React.createElement(BlockContent, null,
            webhooks.map((webhook) => (React.createElement(WebhookContainer, Object.assign({ key: webhook.id }, webhook)))),
            React.createElement(Actions, { disabled: isExistEmptyWebhook(webhooks) },
                React.createElement(Action, { onClick: clickAddNewWebhookHandler },
                    React.createElement(StyledIconPlus, null),
                    webhookAddingLabel)))));
};
export default connector(WebhooksContainer);
