import React from "react";
import { FormattedMessage } from "react-intl";
import { DEFAULT_CANCEL_BUTTON_LABEL, DEFAULT_CONFIRM_BUTTON_LABEL } from "./intl";
import { ButtonSection, Description } from "./styled";
import { StyledButton } from "../../styled/Buttons";
import { CANCELID, CONFIRMID } from "../../../helpers/applicationParams";
export const ConfirmForm = ({ buttons, confirmButton = {}, cancelButton = {}, text }) => {
    const cancelButtonTitle = cancelButton.title && cancelButton.title.id ? cancelButton.title : DEFAULT_CANCEL_BUTTON_LABEL;
    const confirmButtonTitle = confirmButton.title && confirmButton.title.id ? confirmButton.title : DEFAULT_CONFIRM_BUTTON_LABEL;
    const clickCancelButtonHandler = () => typeof cancelButton.action === "function" ? cancelButton.action() : undefined;
    const clickConfirmButtonHandler = () => typeof confirmButton.action === "function" ? confirmButton.action() : undefined;
    return (React.createElement("div", null,
        text &&
            text.description &&
            text.description.length &&
            text.description.map((desc) => desc &&
                desc.id && (React.createElement(Description, { key: desc.id, theme: text.theme },
                React.createElement(FormattedMessage, Object.assign({}, desc))))),
        React.createElement(ButtonSection, null,
            buttons && buttons.rtl && !confirmButton.hide && (React.createElement(StyledButton, { bordered: confirmButton.bordered, className: "close_modal_window", id: CONFIRMID, onClick: clickConfirmButtonHandler, textButton: confirmButton.textButton, theme: confirmButton.theme },
                React.createElement(FormattedMessage, Object.assign({}, confirmButtonTitle)))),
            !cancelButton.hide && (React.createElement(StyledButton, { bordered: cancelButton.bordered, className: "close_modal_window", id: CANCELID, onClick: clickCancelButtonHandler, textButton: cancelButton.textButton, theme: cancelButton.theme },
                React.createElement(FormattedMessage, Object.assign({}, cancelButtonTitle)))),
            (!buttons || !buttons.rtl) && !confirmButton.hide && (React.createElement(StyledButton, { bordered: confirmButton.bordered, className: "close_modal_window", id: CONFIRMID, onClick: clickConfirmButtonHandler, textButton: confirmButton.textButton, theme: confirmButton.theme },
                React.createElement(FormattedMessage, Object.assign({}, confirmButtonTitle)))))));
};
