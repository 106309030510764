import { Link } from "react-router-dom";
import styled from "styled-components";
export const Header = styled.header `
    padding: 22px 0 10px;
    flex: 0 0 auto;
`;
export const Logo = styled.img `
    max-height: 70px;
`;
export const LogoLink = styled(Link) `
    display: inline-block;
    line-height: 0;
    transition: all 0.1s ease-in-out;
    &:active {
        transform: scale(0.9);
    }
`;
