var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { connect } from "react-redux";
import { getUniqueArrayByParam, getUniqueId, getValueByPath, useIntl } from "@boomq/utils";
import { REQUEST_PLACEHOLDER, TRANSACTION_LIST_ITEM_ANY_TRANSACTION, TRANSACTION_LIST_ITEM_NO_TRANSACTION } from "./intl";
import { THREAD_GROUP_PLACEHOLDER } from "../SlaThreadGroup";
import { THREAD_GROUP_LIST_ITEM_ANY_THREAD_GROUP, TRANSACTION_PLACEHOLDER } from "../SlaTransaction";
import { SlaRequestItem } from "../../../components/account/SlaRequestItem";
import { formatIntlMessage } from "../../../helpers/intl";
import { getRequestItems, getSlaTestMetricRestrictionTypeItems, getThreadGroupItemsByRequestName, getThreadGroupItemsByTransactionName, getTransactionItems, getTransactionItemsByRequestName, prepareTestProjectThreadGroupItems } from "../../../helpers/sla";
import { TestType } from "../../../helpers/types";
import { SlaTargetType, SlaThreadGroupFilterType, SlaTransactionFilterType } from "../../../models/sla";
import { getRequestGroups, getRequests, getThreadGroupsRequests, getThreadGroupsTransactions } from "../../../redux/reducers/newTest";
const getSlaRequestParams = (state, ownProps) => {
    const requests = ownProps.testType === TestType.JMX ? getRequests(state) : getThreadGroupsRequests(state);
    const transactions = getThreadGroupsTransactions(state);
    const transactionItems = getTransactionItems(transactions);
    return {
        requestItems: getRequestItems(requests),
        requests,
        transactions,
        transactionItems: getUniqueArrayByParam(transactionItems, "value")
    };
};
const getSlaThreadGroupItems = (state) => {
    const threadGroups = getRequestGroups(state);
    return prepareTestProjectThreadGroupItems(threadGroups);
};
const getThreadGroupItemsByParams = (params) => [SlaTransactionFilterType.AnyTransaction, SlaTransactionFilterType.NoTransaction].includes(params.transactionName)
    ? getThreadGroupItemsByRequestName(params)
    : getThreadGroupItemsByTransactionName(params);
const connector = connect((state, ownProps) => (Object.assign({ threadGroupItems: getSlaThreadGroupItems(state) }, getSlaRequestParams(state, ownProps))));
const SlaRequestContainer = (_a) => {
    var { children, slaData, requestItems, requests, threadGroupItems, transactionItems, transactions } = _a, restProps = __rest(_a, ["children", "slaData", "requestItems", "requests", "threadGroupItems", "transactionItems", "transactions"]);
    const { formatMessage } = useIntl();
    const changeHandler = (id, field, newData) => restProps.onDispatchAction({
        type: "update",
        payload: { id, field, type: SlaTargetType.Request, data: newData }
    });
    const changeRequestNameHandler = (id, data) => changeHandler(id, "requestName", data.value);
    const changeThreadGroupHandler = (id, data) => changeHandler(id, SlaTargetType.ThreadGroup, data);
    const changeTransactionHandler = (id, data) => changeHandler(id, SlaTargetType.Transaction, data);
    const getThreadGroupItems = ({ requestName, transactionName }) => [
        {
            id: SlaThreadGroupFilterType.AnyThreadGroup,
            text: formatMessage(THREAD_GROUP_LIST_ITEM_ANY_THREAD_GROUP),
            value: SlaThreadGroupFilterType.AnyThreadGroup
        },
        ...getThreadGroupItemsByParams({
            requestName,
            requests,
            testType: restProps.testType,
            threadGroupItems,
            transactionName,
            transactions
        })
    ];
    const getSlaTransactionItemsByRequestName = (requestName) => [
        {
            id: getUniqueId(),
            text: formatMessage(TRANSACTION_LIST_ITEM_ANY_TRANSACTION),
            value: SlaTransactionFilterType.AnyTransaction
        },
        {
            id: getUniqueId(),
            text: formatMessage(TRANSACTION_LIST_ITEM_NO_TRANSACTION),
            value: SlaTransactionFilterType.NoTransaction
        },
        ...getTransactionItemsByRequestName({
            transactions,
            requestName,
            requests
        })
    ];
    const requestSelectPlaceholder = formatIntlMessage(REQUEST_PLACEHOLDER, formatMessage);
    const threadGroupSelectPlaceholder = formatIntlMessage(THREAD_GROUP_PLACEHOLDER, formatMessage);
    const transactionSelectPlaceholder = formatIntlMessage(TRANSACTION_PLACEHOLDER, formatMessage);
    return getValueByPath(slaData, SlaTargetType.Request, []).map((slaItemData) => (React.createElement(SlaRequestItem, Object.assign({ key: slaItemData.id }, restProps, { data: Object.assign({}, slaItemData), onChangeRequest: (data) => changeRequestNameHandler(slaItemData.id, data), onChangeThreadGroup: (data) => changeThreadGroupHandler(slaItemData.id, data), onChangeTransaction: (data) => changeTransactionHandler(slaItemData.id, data), requestItems: requestItems, requestSelectPlaceholder: requestSelectPlaceholder, restrictionTypeItems: getSlaTestMetricRestrictionTypeItems(SlaTargetType.Request), threadGroupItems: getThreadGroupItems(slaItemData), threadGroupSelectPlaceholder: threadGroupSelectPlaceholder, transactionItems: getSlaTransactionItemsByRequestName(slaItemData.requestName), transactionSelectPlaceholder: transactionSelectPlaceholder, type: SlaTargetType.Request }))));
};
export default connector(SlaRequestContainer);
