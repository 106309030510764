var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { stopEvent } from "@boomq/utils";
import { ActionListItem } from "../../styled/Table";
import { getActionIconByName, testProjectsItemActions } from "../../../helpers/testProjects";
export const ActionButtons = (_a) => {
    var { projectId } = _a, restProps = __rest(_a, ["projectId"]);
    const actionHandler = (e, actionName) => {
        var _a;
        stopEvent(e);
        return (_a = testProjectsItemActions[actionName]) === null || _a === void 0 ? void 0 : _a.call(testProjectsItemActions, restProps, projectId);
    };
    return (React.createElement(React.Fragment, null, (restProps.actionItems || []).map(({ id, actionName }) => {
        const Icon = getActionIconByName(actionName);
        return (React.createElement(ActionListItem, { key: id, onClick: (e) => actionHandler(e, actionName) },
            React.createElement(Icon, null)));
    })));
};
