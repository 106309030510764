var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useState } from "react";
import { Item, MenuSidebar, MenuButton, MenuBackground } from "./styled";
const getClassNameByState = (isOpen) => (isOpen ? "opened" : "");
const getIsOpenFromProps = (isOpen) => (isOpen ? true : false);
export const BurgerMenu = (_a) => {
    var { children } = _a, restProps = __rest(_a, ["children"]);
    const [isOpen, setIsOpen] = useState(getIsOpenFromProps(restProps.isOpen));
    useEffect(() => {
        setIsOpen(getIsOpenFromProps(restProps.isOpen));
    }, [restProps.isOpen]);
    const clickHandler = () => setIsOpen(!isOpen);
    return (React.createElement("div", Object.assign({ onClick: clickHandler }, restProps),
        React.createElement(MenuButton, { className: getClassNameByState(isOpen), isOpen: isOpen },
            React.createElement(Item, null),
            React.createElement(Item, null),
            React.createElement(Item, null)),
        isOpen && (React.createElement(React.Fragment, null,
            React.createElement(MenuSidebar, null, children),
            React.createElement(MenuBackground, null)))));
};
