import { FC } from "react";
import { excludeArrayFromArray } from "@boomq/utils";
import {
    TeamMembersItemTemplateEditorProps,
    TeamMembersItemTemplateAddHandlers,
    generateListItemsByValues,
    getTeamAdminPermissions,
    getTeamMembersItemComponentKey
} from "@boomq/common";
import { TeamMembersItemTemplateAdd } from "../../components/account/Team/TeamMembersItemTemplateAdd";
import {
    TeamMembersItemTemplateEditProps,
    TeamMembersItemTemplateEditHandlers
} from "../../components/account/Team/TeamMembersItemTemplateEdit";
import { TeamMembersItemTemplateViewHandlers } from "../../components/account/Team/TeamMembersItemTemplateView";
import { TeamMembersItemTemplateEdit } from "../../containers/account/Team/TeamMembersItemTemplateEdit";
import { TeamMembersItemTemplateView } from "../../containers/account/Team/TeamMembersItemTemplateView";
import { ListItem, Role, TeamMemberMode, TeamMemberPermission } from "../types";

export const getTeamMembersPermissionValues = (): TeamMemberPermission[] => [
    TeamMemberPermission.View,
    TeamMemberPermission.Edit,
    TeamMemberPermission.Run
    // TeamMemberPermission.Delete,
    // TeamMemberPermission.ShareViewOnlyReports
];

export const getTeamMemberPermissionItems = (): ListItem<TeamMemberPermission>[] =>
    generateListItemsByValues(getTeamMembersPermissionValues());

export const getTeamMemberPermissionItemsByAdminAccess = (isExistTeamAdminPermission): any[] => {
    const permissionItems = getTeamMemberPermissionItems();

    return isExistTeamAdminPermission
        ? [...generateListItemsByValues(getTeamAdminPermissions()), ...permissionItems]
        : permissionItems;
};

export const prepareChangedPermissionList = (permissionList, manageUsersPermissionLst): any[] => {
    const permissionsWithoutManageUsers = excludeArrayFromArray(
        permissionList,
        getTeamMembersManageUsersPermissionValues()
    );
    const manageUsersPermissions = excludeArrayFromArray(manageUsersPermissionLst, [
        ...getTeamMembersPermissionValues(),
        ...getTeamAdminPermissions()
    ]);

    return [...permissionsWithoutManageUsers, ...manageUsersPermissions];
};

export const getAdminTeamMembersManageUsersPermissionValues = (): TeamMemberPermission[] => [
    TeamMemberPermission.ManageUsers
];

export const getTeamMembersManageUsersPermissionValues = (): TeamMemberPermission[] => [
    TeamMemberPermission.OnlyOrganisation
];

export const getTeamMemberManageUsersPermissionItemsByRoles = (
    roles?: Role[]
): ListItem<TeamMemberPermission, { disabled?: boolean }>[] => {
    const manageUsersItems = generateListItemsByValues(getTeamMembersManageUsersPermissionValues());
    const adminManageUsersItems = generateListItemsByValues(getAdminTeamMembersManageUsersPermissionValues());

    return roles && roles.includes(Role.Admin)
        ? [...manageUsersItems, ...adminManageUsersItems]
        : [
            ...manageUsersItems,
            ...adminManageUsersItems.map((manageUsersItem) => ({ ...manageUsersItem, disabled: true }))
        ];
};

const teamMemberTemplateByMode = {
    [TeamMemberMode.Add]: TeamMembersItemTemplateAdd,
    [TeamMemberMode.View]: TeamMembersItemTemplateView,
    [TeamMemberMode.Edit]: TeamMembersItemTemplateEdit
};

export const getTeamMembersItemTemplateByMode = (
    mode: TeamMemberMode
):
| FC<TeamMembersItemTemplateEditorProps & TeamMembersItemTemplateAddHandlers>
| FC<TeamMembersItemTemplateEditProps & TeamMembersItemTemplateEditHandlers>
| FC<TeamMembersItemTemplateViewHandlers> => teamMemberTemplateByMode[getTeamMembersItemComponentKey(mode)];
