import { RequestTimerType } from "../types";
import { getTimerType } from "../../models/requestTimer";
export const requestTimerValidator = (timer) => {
    const isValid = timer && timer.enabled && getTimerType(timer) === RequestTimerType.Random
        ? Number(timer.lowerLimit) <= Number(timer.upperLimit)
        : true;
    return {
        isValid,
        error: isValid
            ? undefined
            : {
                defaultMessage: "Random timer's lower limit shouldn't be larger upper limit",
                id: "account.test.profile.error.timer.random.invalid.settings"
            }
    };
};
export const requestTimersValidator = (timerList) => {
    const isValid = (timerList || []).reduce((res, timer) => {
        const { isValid } = requestTimerValidator(timer);
        return isValid ? res : res + 1;
    }, 0) === 0;
    return {
        isValid,
        error: isValid
            ? undefined
            : {
                defaultMessage: "Invalid timer's settings",
                id: "account.test.profile.error.timers.invalid.settings"
            }
    };
};
