import styled, { css } from "styled-components";
import { Color } from "../styled/colorPallete";
import { withDisable } from "../styled/global";
import { media } from "../styled/response";
import { FontBodyBigMedium, FontBodyMedium } from "../styled/typography";
var ButtonBorderColor;
(function (ButtonBorderColor) {
    ButtonBorderColor["transparent"] = "transparent";
})(ButtonBorderColor || (ButtonBorderColor = {}));
export var ButtonType;
(function (ButtonType) {
    ButtonType["outlined"] = "OUTLINED";
    ButtonType["outlinedHighlight"] = "OUTLINED_HIGHLIGHT";
    ButtonType["primary"] = "PRIMARY";
})(ButtonType || (ButtonType = {}));
export var ButtonSize;
(function (ButtonSize) {
    ButtonSize["big"] = "BIG";
    ButtonSize["medium"] = "MEDIUM";
    ButtonSize["small"] = "SMALL";
})(ButtonSize || (ButtonSize = {}));
const DEFAULT_TOP_BOTTOM_PADDING = "5px";
const DEFAULT_BACKGROUND_COLOR = Color.blue;
const DEFAULT_TEXT_COLOR = Color.white;
const DEFAULT_BORDER_COLOR = ButtonBorderColor.transparent;
const getPadding = (topBottomPadding) => {
    const topBottomPaddingSize = typeof topBottomPadding === "string" ? topBottomPadding : DEFAULT_TOP_BOTTOM_PADDING;
    return `padding: ${topBottomPaddingSize} 26px;`;
};
const getParamsBySize = (size) => {
    switch (size) {
        case ButtonSize.big: {
            return getPadding("10px");
        }
        case ButtonSize.medium: {
            return getPadding("8px");
        }
        default: {
            return getPadding(DEFAULT_TOP_BOTTOM_PADDING);
        }
    }
};
const getTypeParams = (backgroundColor, textColor, borderColor) => `
    background: ${backgroundColor};
    color: ${textColor}
    border: 2px solid ${borderColor};
`;
const getParamsByType = (type) => {
    switch (type) {
        case ButtonType.outlined: {
            return getTypeParams(Color.white, Color.darkGrey35, Color.lightGrey85);
        }
        case ButtonType.outlinedHighlight: {
            return getTypeParams(Color.white, Color.blue, Color.blue);
        }
        default: {
            return getTypeParams(DEFAULT_BACKGROUND_COLOR, DEFAULT_TEXT_COLOR, DEFAULT_BORDER_COLOR);
        }
    }
};
const getHoverParams = (hoverStyle) => `
    &:hover {${hoverStyle}}
`;
const getHoverParamsByType = (type) => {
    switch (type) {
        case ButtonType.outlined: {
            return getHoverParams(`color: ${Color.darkGrey5};`);
        }
        case ButtonType.outlinedHighlight: {
            return getHoverParams(`
                border-color: ${Color.darkBlue80};
                color: ${Color.darkBlue80};
            `);
        }
        default: {
            return getHoverParams(`background: ${Color.darkBlue80};`);
        }
    }
};
export const Button = styled.button `
    ${FontBodyBigMedium}
    ${withDisable};
    ${media.desktop} {
        ${FontBodyMedium}
        padding: 10px 20px;
    }
    align-items: center;
    ${(props) => getParamsByType(props.styletype)}
    border-radius: 30px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    line-height: 100%;
    outline: none;
    ${(props) => getParamsBySize(props.size)}
    text-align: center;
    transition: all 0.3s ease;
    user-select: none;
    &:active {
        transform: scale(0.9);
    }
    ${(props) => getHoverParamsByType(props.styletype)}
    ${(props) => (props && props.theme ? css(props.theme) : css ``)}
`;
