var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { getUniqueId, sortFuncByParamAndAlphabet } from "@boomq/utils";
import { formatIntlMessage } from "../intl";
import { modifyErrorMap } from "../validators/validators";
import { ASSERTION_NEW_ITEM_ID_PREFIX, AssertionFilter, getAssertionFilterItems, getAssertionResponseBodyJsonPathConditionItems, getAssertionResponseConditionExistsValues, getAssertionResponseConditionNullValues, getAssertionConditionComparisonItems, getAssertionResponseConditionItems, getAssertionResponseBodyXPath2ConditionItems } from "../../models/assertion";
export const getAssertionValue = (assertion, field) => assertion === null || assertion === void 0 ? void 0 : assertion[field];
const changeAssertionFieldValue = (assertion, { field, subfield, value }) => {
    const modifiedAssertion = field && subfield
        ? Object.assign(Object.assign({}, assertion), { [field]: Object.assign(Object.assign({}, assertion[field]), { [subfield]: value }) }) : Object.assign(Object.assign({}, assertion), { [field]: value });
    return modifyErrorMap(modifiedAssertion, field);
};
export const addAssertionToCurrentList = (assertions, params) => [
    ...assertions,
    changeAssertionFieldValue(Object.assign(Object.assign({}, getNewAssertionObj()), { id: getUniqueId() }), params)
];
export const changeCurrentAssertion = (assertions, _a) => {
    var { id } = _a, restParams = __rest(_a, ["id"]);
    return (assertions || []).map((assertion) => assertion.id === id ? changeAssertionFieldValue(assertion, restParams) : assertion);
};
export const getFormattedList = (items, formatMessage) => items.map((item) => (Object.assign(Object.assign({}, item), { text: formatIntlMessage(item.text, formatMessage) })));
export const getSortedFormattedList = (items, formatMessage) => getFormattedList(items, formatMessage).sort((a, b) => sortFuncByParamAndAlphabet(a, b, "text"));
export const getFormattedAssertionConditionComparisonItems = (formatMessage) => getSortedFormattedList(getAssertionConditionComparisonItems(), formatMessage);
export const getFormattedAssertionResponseConditionItems = (formatMessage) => getFormattedList(getAssertionResponseConditionItems(), formatMessage);
export const getFormattedAssertionResponseBodyJsonPathConditionItems = (formatMessage) => getSortedFormattedList(getAssertionResponseBodyJsonPathConditionItems(), formatMessage);
export const getFormattedAssertionResponseBodyXPath2ConditionItems = (formatMessage) => getSortedFormattedList(getAssertionResponseBodyXPath2ConditionItems(), formatMessage);
export const getFormattedAssertionConditionItems = (formatMessage) => getSortedFormattedList(getAssertionResponseBodyJsonPathConditionItems(), formatMessage);
export const getFormattedAssertionFilterItems = (formatMessage) => getFormattedList(getAssertionFilterItems(), formatMessage);
export const getFormattedAssertionTypeItems = (assertionTypeItems, formatMessage) => getSortedFormattedList(assertionTypeItems, formatMessage);
export const getNewAssertionObj = () => ({
    id: `${ASSERTION_NEW_ITEM_ID_PREFIX}_${getUniqueId()}`,
    condition: null,
    filter: AssertionFilter.NoFilter,
    type: null,
    value: ""
});
export const getAssertionResponseBodyJsonWithoutValueConditionList = () => [...getAssertionResponseConditionExistsValues(), ...getAssertionResponseConditionNullValues()];
