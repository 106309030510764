export const COPY_INVITE_LINK_TOOLTIP = {
    defaultMessage: "Refresh and copy invite link",
    id: "account.team.member.actions.refresh.copy.button.tooltip.text"
};

export const DELETE_TEAM_MEMBER_TOOLTIP = {
    defaultMessage: "Delete team member",
    id: "account.team.member.actions.delete.button.tooltip.text"
};

export const EDIT_TEAM_MEMBER_TOOLTIP = {
    defaultMessage: "Edit team member",
    id: "account.team.member.actions.edit.button.tooltip.text"
};

export const SEND_INVITE_LINK_TOOLTIP = {
    defaultMessage: "Send invite link",
    id: "account.team.member.actions.send.invite.button.tooltip.text"
};
