import React from "react";
import { FormattedMessage } from "react-intl";
import { getValueByPath } from "@boomq/utils";
import { Content, StyledAccountSectionTitle } from "./styled";
import { Request } from "../Request";
import { RequestHttpFormContainer } from "../../../../containers/account/TestProject/RequestHttpForm";
import { DataTable } from "../../../common/DataTable";
import { requestFormModal } from "../../../../data/content/modals";
import { FormType, TestType } from "../../../../helpers/types";
const RequestGroupJMX = (props) => {
    const requestGroupTypeName = getValueByPath(props, "requestGroup.displayTypeName");
    const showRequestFormHandler = (formType = FormType.Add, params) => props.getSiteModal(requestFormModal({
        props: Object.assign(Object.assign({}, params), { formType, testType: TestType.JMX }),
        closeAction: () => props.newRequestChangeParams(),
        element: RequestHttpFormContainer
    }));
    const requestActions = {
        editRequestJMXTimersHandler: (id, params) => {
            const request = getValueByPath(props, "requestGroup.requests", []).find((request) => request.id === id) || {};
            props.newRequestChangeParamsWithEditableTimers(Object.assign({}, request));
            showRequestFormHandler(FormType.Edit, params);
        },
        showRequestHandler: (id) => {
            const request = getValueByPath(props, "requestGroup.requests", []).find((request) => request.id === id) || {};
            props.newRequestChangeParams(Object.assign({}, request));
            showRequestFormHandler(FormType.View);
        }
    };
    const clickActionHandler = (actionName, id, params) => requestActions && typeof requestActions[actionName] === "function" && requestActions[actionName](id, params);
    return (React.createElement(Content, null,
        React.createElement(StyledAccountSectionTitle, null,
            React.createElement(FormattedMessage, { defaultMessage: "Type: {requestGroupTypeName}", id: "account.new.test.project.jmx.group.type.title", values: {
                    requestGroupTypeName
                } })),
        props.children,
        React.createElement(StyledAccountSectionTitle, null,
            React.createElement(FormattedMessage, { defaultMessage: "Requests", id: "account.new.test.project.jmx.group.requests.title" })),
        getValueByPath(props, "requestGroup.requests", []).length > 0 && (React.createElement(DataTable, { isPagingTop: true, items: props.requestGroup.requests, pageSize: "5", itemTemplate: (request) => (React.createElement(Request, Object.assign({ actions: props.requestActions, onClickAction: clickActionHandler }, request))) }))));
};
export default RequestGroupJMX;
