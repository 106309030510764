import { ReportTableType } from "./enums";
import { generateTableDataForSLAByRequestFlow, generateTableDataForSLAByTestFlow, generateTableDataForSLAByTransactionFlow, reportTableDataGenerationFailed } from "../../redux/sagas/report";
const reportTableDataGeneratorByType = {
    [ReportTableType.RequestSLATable]: generateTableDataForSLAByRequestFlow,
    [ReportTableType.TestSLATable]: generateTableDataForSLAByTestFlow,
    [ReportTableType.TransactionSLATable]: generateTableDataForSLAByTransactionFlow
};
export const getReportTableDataGeneratorByType = (reportTableType) => Object.keys(reportTableDataGeneratorByType).includes(reportTableType)
    ? reportTableDataGeneratorByType[reportTableType]
    : reportTableDataGenerationFailed;
const getReportTableTypeItemIntlMessageByValue = (value) => ({
    defaultMessage: value,
    id: `account.report.editor.table.type.${value}`
});
export const getReportTableTypeItems = () => Object.values(ReportTableType).map((value) => ({
    id: value,
    intlMessage: getReportTableTypeItemIntlMessageByValue(value),
    text: value,
    value
}));
