import { handleActions } from "redux-actions";
import { getSiteModal, closeSiteModal } from "../actions/siteModal";
export const initialState = {
    closeCallBack: null,
    component: null,
    isVisible: false,
    title: null
};
export default handleActions({
    [getSiteModal]: (state, action) => (Object.assign(Object.assign(Object.assign({}, state), { isVisible: true }), action.payload)),
    [closeSiteModal]: () => (Object.assign({}, initialState))
}, initialState);
export const getSiteTitle = state => state.siteModal.title;
export const getModalComponent = state => state.siteModal.component;
export const getSiteModalVisibility = state => state.siteModal.isVisible;
export const getCloseCallBack = state => state.siteModal.closeCallBack;
