var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { ExtractResponseParameter } from "../ExtractResponseParameter";
import { RequestResponsePreview } from "../RequestResponsePreview";
export const ExtractResponseParameters = (_a) => {
    var { expressions, isShowResponse, onChangeResponsePreviewSource, onToggleResponseShowing, responseBodyPreviewModeLabel, responsePreviewData, responsePreviewSource, responsePreviewSourceItems, responsePreviewText, responsePreviewTitle } = _a, restProps = __rest(_a, ["expressions", "isShowResponse", "onChangeResponsePreviewSource", "onToggleResponseShowing", "responseBodyPreviewModeLabel", "responsePreviewData", "responsePreviewSource", "responsePreviewSourceItems", "responsePreviewText", "responsePreviewTitle"]);
    return (React.createElement(React.Fragment, null,
        (expressions || []).map((expression) => {
            const match = expression ? expression.match : { matchType: undefined, value: undefined };
            return expression ? (React.createElement(ExtractResponseParameter, Object.assign({}, restProps, { expression: expression, key: expression.id, match: match }))) : (React.createElement(React.Fragment, null));
        }),
        React.createElement(RequestResponsePreview, { mode: restProps.mode, isShowResponse: isShowResponse, onChangeResponsePreviewSource: onChangeResponsePreviewSource, onToggleResponseShowing: onToggleResponseShowing, responseBodyPreviewModeLabel: responseBodyPreviewModeLabel, responsePreviewData: responsePreviewData, responsePreviewSource: responsePreviewSource, responsePreviewSourceItems: responsePreviewSourceItems, responsePreviewText: responsePreviewText, responsePreviewTitle: responsePreviewTitle })));
};
