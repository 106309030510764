import React, { useState } from "react";
import { useIntl } from "@boomq/utils";
import { Help, InputNumber } from "@boomq/uikit";
import { DEFAULT_TIMER } from "./constants";
import { DEFAULT_TIMER_HELP_MESSAGE } from "./intl";
import { ButtonRemove, IconSection, RequestParam, TimerTypeSelect } from "./styled";
import { IconRemove } from "../../../styled/Icons";
import { getFormattedTimerTypeItemsByParamName, getTimerInputUnit, getTimerListByMode, getTimerTypeItemsByParamName, getTimerValueParams, isDefaultTimer, isEnabled, isShowRemoveButton } from "../../../../helpers/requestTimers";
import { RequestTimerParams } from "../../../../helpers/types";
import { isEmpty } from "../../../../helpers/utils";
import { getTimerType } from "../../../../models/requestTimer";
export const RequestTimers = ({ timerList, mode, onAdd, onChange, onDelete }) => {
    const { formatMessage } = useIntl();
    const [newTimer, setNewTimer] = useState(DEFAULT_TIMER);
    const blurTimerValueHandler = (timerId, key, value) => onChange({ timerId, key, value });
    const changeNewTimerValue = (timerId, key, value) => {
        onAdd(Object.assign(Object.assign({ timerId }, newTimer), { key, value }));
        setNewTimer(DEFAULT_TIMER);
    };
    const changeTimerValueHandler = (timer, key, value) => {
        !isEmpty(timer.error) && onChange({ timerId: timer.id, key, value });
        return timer.isNewTimer && Number(value) > 0 ? changeNewTimerValue(timer.id, key, value) : undefined;
    };
    const changeTimerTypeHandler = (timerId, item, paramName, isNewTimer) => isNewTimer
        ? setNewTimer(Object.assign(Object.assign({}, newTimer), { [paramName]: item.value }))
        : onChange({ timerId, key: paramName, value: item.value });
    const deleteTimerHandler = (id) => onDelete({
        field: "timerList",
        id
    });
    const getPlaceholder = (timerType, valueInputName) => {
        const timerTypeMap = getTimerTypeItemsByParamName(RequestTimerParams.Type).find((type) => type.value === timerType);
        const placeholder = timerTypeMap && timerTypeMap.placeholder;
        return placeholder &&
            placeholder[valueInputName] &&
            placeholder[valueInputName].id &&
            typeof formatMessage === "function"
            ? formatMessage(placeholder[valueInputName])
            : "";
    };
    const getTimerItemType = (timer) => timer && timer.isNewTimer ? getTimerType(newTimer) : getTimerType(timer);
    const getTimerValueBlockByType = (timer) => getTimerValueParams(timer).map((timerValueParamName) => (React.createElement(InputNumber, { enabled: isEnabled(timer, mode), decimalCount: 2, error: timer.error, key: `${timer.id}_${timerValueParamName}`, name: `Timer_${timerValueParamName}`, onBlur: (value) => blurTimerValueHandler(timer.id, timerValueParamName, value), onChange: (value) => changeTimerValueHandler(timer, timerValueParamName, value), placeholder: getPlaceholder(getTimerType(timer), timerValueParamName), unit: getTimerInputUnit(), value: timer[timerValueParamName] })));
    return (React.createElement(React.Fragment, null, getTimerListByMode({ timerList, newTimer, mode }).map((timer) => (React.createElement(RequestParam, { key: timer.id },
        React.createElement(TimerTypeSelect, { defaultValue: getTimerItemType(timer), disabled: !isEnabled(timer, mode), items: getFormattedTimerTypeItemsByParamName(RequestTimerParams.Type, formatMessage), onChange: (item) => changeTimerTypeHandler(timer.id, item, RequestTimerParams.Type, timer.isNewTimer) }),
        getTimerValueBlockByType(timer),
        React.createElement(IconSection, null,
            isDefaultTimer(timer) && React.createElement(Help, { tooltipText: DEFAULT_TIMER_HELP_MESSAGE }),
            isShowRemoveButton(timer, mode) && (React.createElement(ButtonRemove, { onClick: () => deleteTimerHandler(timer.id) },
                React.createElement(IconRemove, null)))))))));
};
