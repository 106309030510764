import isAlphanumeric from "validator/es/lib/isAlphanumeric";
import { isEmpty } from "../utils/isEmpty";
export const isValidParameterName = (str) => isAlphanumeric((str || "").replace(/[-_]/gi, ""));
export const parameterNameValidator = (str) => {
    const isValid = isValidParameterName(str);
    const error = !isValid
        ? {
            defaultMessage: "Please input valid characters (A...Z 0...9 _ -)",
            id: "validation.parameter.name.invalid"
        }
        : undefined;
    return { isValid, error };
};
export const parameterNameListValidator = (parameterNameList = []) => {
    const isValid = Array.isArray(parameterNameList) && parameterNameList.length > 0
        ? !parameterNameList.some((paramName) => !isValidParameterName(paramName))
        : true;
    return {
        isValid,
        error: !isValid
            ? {
                defaultMessage: "Invalid parameter's name. Valid characters: A...Z 0...9 _ -",
                id: "account.test.profile.error.parameters.invalid.name"
            }
            : undefined
    };
};
export const parametersValidator = ({ csvParameters = [], parameters = [] }) => {
    const parameterNames = parameters
        .filter((parameter) => parameter.name !== "" || parameter.value !== "")
        .map((parameter) => parameter.name);
    const { isValid: isValidParamName, error: errorParamName } = parameterNameListValidator(parameterNames);
    const uniqueParameterNames = new Set(parameterNames);
    let error;
    !isValidParamName && (error = errorParamName);
    isValidParamName &&
        parameterNames.length !== uniqueParameterNames.size &&
        (error = {
            defaultMessage: "Identical parameter names are not allowed",
            id: "account.test.profile.error.parameters.identical.names"
        });
    !isEmpty(csvParameters) &&
        !isEmpty(csvParameters.filter((csvParameter) => csvParameter.files.length > 0 &&
            csvParameter.parameterNames.filter((parameterName) => parameterName === "").length > 0)) &&
        (error = {
            defaultMessage: "When .csv file is uploaded you must set name of all parameters",
            id: "account.test.profile.error.parameters.csv.names.empty"
        });
    return { isValid: isEmpty(error), error };
};
