import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { TestDetails } from "../../../components/account/TestDetails";
import {
    TestDetailsAction,
    getSiteModal,
    isAllowedTestDetailsActionByPermissions,
    newTestActions,
    setTitle,
    testDetailsRequest,
    testProjectRequest,
    getClientInfo,
    getClientPermissionList,
    getIsAuthorize,
    getIsStoppingTestById,
    getProjectName,
    getSelectedTeamId,
    getTestDetailsData,
    testDetailsRequestStop,
    testProjectVersionDataRequest,
    updateTestResultSlaRequest
} from "@boomq/common";
import { Mode } from "../../../helpers/types";

const { clearNewTestSettings } = newTestActions;

const getSlaFormModeByPermissions = (state) => {
    const permissionList = getClientPermissionList(state);
    const isAllowedEditMode = isAllowedTestDetailsActionByPermissions(permissionList, TestDetailsAction.ChangeSla);

    return isAllowedEditMode ? Mode.EditResult : Mode.View;
};

const getProps = (state) => {
    const testDetails = getTestDetailsData(state);

    return {
        testDetails,
        isStopping: getIsStoppingTestById(state, testDetails.id)
    };
};

const connector = connect(
    (state) => ({
        clientInfo: getClientInfo(state),
        isAuthorized: getIsAuthorize(state),
        projectName: getProjectName(state),
        selectedTeamId: getSelectedTeamId(state),
        slaFormMode: getSlaFormModeByPermissions(state),
        ...getProps(state)
    }),
    {
        getSiteModal,
        setTitle,
        clearNewTestSettings,
        onRefreshTestDetailsData: testDetailsRequest,
        onRefreshTestProjectData: testProjectRequest,
        onRefreshTestProjectVersionData: testProjectVersionDataRequest,
        onUnmount: testDetailsRequestStop,
        updateTestResultSlaRequest
    }
);

export default withRouter(connector(TestDetails));
