import { StyledIconCopy } from "../../components/account/AllTestProjectSettingsSetsItem/styled";
import { SettingsSetsItemAction, TeamMemberPermission } from "../types";
const actionsByTeamMemberPermissions = {
    [TeamMemberPermission.Admin]: [SettingsSetsItemAction.Copy],
    [TeamMemberPermission.Edit]: [SettingsSetsItemAction.Copy],
    [TeamMemberPermission.Run]: [SettingsSetsItemAction.Copy]
};
const actionIconsByName = {
    [SettingsSetsItemAction.Copy]: StyledIconCopy
};
const getSettingsSetsActionsByTeamMemberPermission = (permission) => {
    const actionsByPermission = actionsByTeamMemberPermissions[permission];
    return actionsByPermission ? actionsByPermission : [];
};
const getSettingsSetsActionsByTeamMemberPermissions = (permissions) => [
    ...new Set(permissions.reduce((res, permission) => [
        ...res,
        ...getSettingsSetsActionsByTeamMemberPermission(permission)
    ], []))
];
export const getSettingsSetActionIconByName = (actionName) => actionIconsByName[actionName];
export const getSettingsSetActionItemsByTeamMemberPermissions = (permissions) => getSettingsSetsActionsByTeamMemberPermissions(permissions).map((actionName) => ({
    id: actionName,
    actionName
}));
const actionHandler = (props, actionName, params) => typeof props[actionName] === "function" ? props[actionName](params) : undefined;
export const settingsSetsItemActions = {
    [SettingsSetsItemAction.Copy]: (props, id) => actionHandler(props, SettingsSetsItemAction.Copy, { id })
};
