var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { isEmpty } from "@boomq/utils";
import { MainButtons, RightBlock, StoppingText, StopTestButton, StyledAddingIcon, StyledDetailsButtons, StyledDetailsButtonWithLink, StyledDetailsButtonWithRoute, StyledDetailsTrendButton, TestDetailsAnomalySettings } from "./styled";
import { isAllowedTestDetailsActionByPermissions } from "../../../helpers/testDetails";
import { isTestCompleted, isTestDuringExecution, isTestStopping } from "../../../helpers/testStatus";
import { TestDetailsAction } from "../../../models/testRun";
const renderDetailedButton = ({ detailedStatsButtonLabel, grafanaTestUrl }) => isEmpty(grafanaTestUrl) ? (React.createElement(React.Fragment, null)) : (React.createElement(StyledDetailsButtonWithLink, { bordered: "true", href: grafanaTestUrl, target: "_blank", rel: "noreferrer noopener" }, detailedStatsButtonLabel));
const renderSystemMetricsButton = ({ detailedSysMetricsButtonLabel, grafanaSysMetricsUrl }) => isEmpty(grafanaSysMetricsUrl) ? (React.createElement(React.Fragment, null)) : (React.createElement(StyledDetailsButtonWithLink, { bordered: "true", href: grafanaSysMetricsUrl, target: "_blank", rel: "noreferrer noopener" }, detailedSysMetricsButtonLabel));
const renderStoppingText = (displayState, stoppingText) => isTestStopping(displayState) ? React.createElement(StoppingText, null, stoppingText) : React.createElement(React.Fragment, null);
const renderStopBlock = ({ displayState, onClickStopTest, stoppingText, stopTestButtonLabel }) => isTestDuringExecution(displayState) ? (React.createElement(StopTestButton, { bordered: "true", onClick: onClickStopTest }, stopTestButtonLabel)) : (renderStoppingText(displayState, stoppingText));
const renderStopBlockByPermissions = (_a) => {
    var { permissionList } = _a, restProps = __rest(_a, ["permissionList"]);
    return isAllowedTestDetailsActionByPermissions(permissionList, TestDetailsAction.Stop) ? (renderStopBlock(restProps)) : (React.createElement(React.Fragment, null));
};
const renderTrendButtonByPermissions = ({ displayState, onClickTrend, permissionList, trendButtonLabel }) => isAllowedTestDetailsActionByPermissions(permissionList, TestDetailsAction.ChangeSla) &&
    isTestCompleted(displayState) ? (React.createElement(StyledDetailsTrendButton, { bordered: "true", isShowClear: true, onClick: onClickTrend },
    React.createElement(StyledAddingIcon, null),
    trendButtonLabel)) : (React.createElement(React.Fragment, null));
const renderTestProjectButton = ({ testProjectButtonLabel, testProjectId, testProjectVersionId }) => testProjectId && testProjectVersionId ? (React.createElement(StyledDetailsButtonWithRoute, { bordered: "true", to: `/account/tests/${testProjectId}/${testProjectVersionId}` }, testProjectButtonLabel)) : (React.createElement(React.Fragment, null));
export const TestDetailsButtons = (_a) => {
    var { children, isApplyAnomalySettings = false, onClickAnomalySettings, testResultSlaState } = _a, restProps = __rest(_a, ["children", "isApplyAnomalySettings", "onClickAnomalySettings", "testResultSlaState"]);
    return (React.createElement(StyledDetailsButtons, null,
        React.createElement(MainButtons, null,
            renderDetailedButton(restProps),
            renderSystemMetricsButton(restProps),
            renderTestProjectButton(restProps),
            React.isValidElement(children) &&
                React.cloneElement(children, {
                    testResultSlaState
                }),
            renderTrendButtonByPermissions(restProps)),
        React.createElement(RightBlock, null,
            isApplyAnomalySettings && React.createElement(TestDetailsAnomalySettings, { onClickAnomalySettings: onClickAnomalySettings }),
            renderStopBlockByPermissions(restProps))));
};
