var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { MAILTO_PARAM_NAMES } from "./constants";
import { escapingComponent } from "../escapingComponent";
import { isEmpty } from "../isEmpty";
import { objectToParams } from "../objectToParams";
export const generateMailToLink = (_a) => {
    var { email } = _a, restProps = __rest(_a, ["email"]);
    const mailToParams = MAILTO_PARAM_NAMES.reduce((res, paramName) => isEmpty(restProps[paramName]) ? res : Object.assign(Object.assign({}, res), { [paramName]: escapingComponent(restProps[paramName]) }), {});
    return `mailto:${email}${objectToParams(mailToParams, "?")}`;
};
