import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { useIntl } from "@boomq/utils";
import { Tabs } from "@boomq/uikit";
import { BUTTON_SAVE_LABEL } from "./intl";
import { ButtonBlock, ButtonWithHintSection, customButtonSaveTheme, customHintTheme, customTabListTheme } from "./styled";
import { StyledButton } from "../../../styled/Buttons";
import { withResize } from "../../../styled/response";
import { Hint } from "../../../common/Hint";
import { getParameterItems } from "../../../../helpers/parameters";
import { parametersValidator } from "../../../../helpers/validators";
import { requestParametersElements } from "../../../../models/requestParameters";
import { saveRequestParameters } from "../../../../redux/actions/requestParameters";
import { getRequestGlobalParametersList, getRequestParametersImportCsv } from "../../../../redux/reducers/requestParameters";
import warning from "../../../../assets/images/icons/warn.svg";
const connector = connect((state) => ({
    parameters: getRequestGlobalParametersList(state),
    parametersCsv: getRequestParametersImportCsv(state)
}), { onSave: saveRequestParameters });
const renderHint = (error, isValid) => isValid ? React.createElement(React.Fragment, null) : React.createElement(Hint, { icon: warning, theme: customHintTheme, tooltipText: error });
const RequestParametersForm = ({ activeTabName, onSave, parameters, parametersCsv }) => {
    const { formatMessage } = useIntl();
    const { isValid, error } = parametersValidator({ csvParameters: parametersCsv, parameters });
    const items = getParameterItems(formatMessage);
    const clickSaveHandler = () => (isValid ? onSave() : undefined);
    return (React.createElement(React.Fragment, null,
        React.createElement(Tabs, { defaultActiveTabName: activeTabName, elements: requestParametersElements, items: items, name: "requestParameters", tabListTheme: customTabListTheme }),
        React.createElement(ButtonBlock, null,
            React.createElement(ButtonWithHintSection, null,
                React.createElement(StyledButton, { disabled: !isValid, name: "saveRequestParameters", onClick: clickSaveHandler, theme: customButtonSaveTheme },
                    React.createElement(FormattedMessage, Object.assign({}, BUTTON_SAVE_LABEL))),
                renderHint(error, isValid)))));
};
export default withResize(withRouter(connector(RequestParametersForm)));
