import React from "react";
import { getValueByPath, useIntl } from "@boomq/utils";
import { JSON_PATH_INPUT_PLACEHOLDER } from "./intl";
import { FILTER_SELECT_PLACEHOLDER } from "../AssertionResponseBodyTemplate";
import { AssertionResponseBodyJsonTemplate } from "../../../../components/account/TestProject/AssertionResponseBodyJsonTemplate";
import { getFormattedAssertionResponseBodyJsonPathConditionItems, getFormattedAssertionFilterItems } from "../../../../helpers/assertion";
import { formatIntlMessage } from "../../../../helpers/intl";
export const AssertionResponseBodyJsonTemplateContainer = (props) => {
    const { formatMessage } = useIntl();
    const changeFieldValue = (field, value) => props.onChangeField({
        id: getValueByPath(props.assertion, "id"),
        field,
        value
    });
    const changeFilterHandler = ({ value }) => changeFieldValue("filter", value);
    const changeJsonPathHandler = (value) => changeFieldValue("jsonPath", value);
    const filterSelectPlaceholder = formatIntlMessage(FILTER_SELECT_PLACEHOLDER, formatMessage);
    const jsonPathInputPlaceholder = formatIntlMessage(JSON_PATH_INPUT_PLACEHOLDER, formatMessage);
    const formattedAssertionConditionItems = getFormattedAssertionResponseBodyJsonPathConditionItems(formatMessage);
    const formattedAssertionFilterItems = getFormattedAssertionFilterItems(formatMessage);
    return (React.createElement(AssertionResponseBodyJsonTemplate, Object.assign({}, props, { assertionConditionItems: formattedAssertionConditionItems, assertionFilterItems: formattedAssertionFilterItems, filterSelectPlaceholder: filterSelectPlaceholder, jsonPathInputPlaceholder: jsonPathInputPlaceholder, onChangeFilter: changeFilterHandler, onChangeJsonPath: changeJsonPathHandler })));
};
