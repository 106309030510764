export const HAS_URL_OVERWRITE_YES = {
    defaultMessage: "Yes",
    id: "account.all.test.project.settings.set.url.overwrite.yes"
};
export const HAS_URL_OVERWRITE_NO = {
    defaultMessage: "No",
    id: "account.all.test.project.settings.set.url.overwrite.no"
};
export const TABLE_HEADER_NAME = {
    defaultMessage: "Name",
    id: "account.all.test.project.settings.set.header.name"
};
export const TABLE_HEADER_LABEL = {
    defaultMessage: "Label",
    id: "account.all.test.project.settings.set.header.label"
};
export const TABLE_HEADER_PARAMETER_COUNT = {
    defaultMessage: "Parameter count",
    id: "account.all.test.project.settings.set.header.parameter.count"
};
export const TABLE_HEADER_URL_OVERWRITE = {
    defaultMessage: "URL overwrite",
    id: "account.all.test.project.settings.set.header.url.overwrite"
};
export const tableHeaderColumns = [
    {
        isLong: true,
        text: TABLE_HEADER_NAME
    },
    {
        isLong: true,
        text: TABLE_HEADER_LABEL
    },
    {
        text: TABLE_HEADER_PARAMETER_COUNT
    },
    {
        text: TABLE_HEADER_URL_OVERWRITE
    },
    {
        text: { id: "title.empty", defaultMessage: " " }
    }
];
