import React, { useEffect, useState } from "react";
const isExistError = (error) => (error ? true : false);
export const withError = (WrappedComponent) => (props) => {
    const [isErrorHighLighting, setIsErrorHighLighting] = useState(isExistError(props.error));
    useEffect(() => {
        setIsErrorHighLighting(isExistError(props.error));
    }, [props.error]);
    const changeValueHandler = (value, e) => {
        setIsErrorHighLighting(false);
        return typeof props.onChange === "function" ? props.onChange(value, e) : undefined;
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(WrappedComponent, Object.assign({}, props, { isError: isErrorHighLighting, onChange: changeValueHandler }))));
};
