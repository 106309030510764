import React, { useReducer, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getUniqueId, useIntl } from "@boomq/utils";
import { Switcher } from "@boomq/uikit";
import { BUTTON_APPLY_LABEL } from "./intl";
import { ClearEachIterationSection, defaultCookieTheme } from "./styled";
import { CLEAR_EACH_ITERATION_SWITCHER_LABEL, DEFAULT_COOKIE_DOMAIN_PLACEHOLDER, DEFAULT_COOKIE_NAME_PLACEHOLDER, DEFAULT_COOKIE_PATH_PLACEHOLDER, DEFAULT_COOKIE_VALUE_PLACEHOLDER, HTTPS_ONLY_CHECKBOX_LABEL, DefaultCookie } from "../../../../components/account/DefaultCookie";
import { Text } from "../../../../components/styled/TestProject";
import { SaveButtonSection } from "../../../../components/account/TestProject/RequestDefaultTimerSettingsForm/styled";
import { StyledButton } from "../../../../components/styled/Buttons";
import { NEW_ITEM_ID } from "../../../../helpers/routes/constants";
import { defaultCookieItemListValidator } from "../../../../helpers/validators/testPojectSettingsSet";
import { validatorByRequiredFields } from "../../../../helpers/validators/validators";
import { changeDefaultCookies } from "../../../../redux/actions/testProjectDefaultSettings";
import { getDefaultCookieItems, getDefaultCookiesParams, getRequestGlobalParametersList } from "../../../../redux/reducers/newTest";
const generateDefaultCookieNewItem = () => ({
    id: `${NEW_ITEM_ID}_${getUniqueId()}`,
    domain: "",
    name: "",
    path: "",
    secure: false,
    value: ""
});
const getCookiesData = (state) => {
    const { clearEachIteration } = getDefaultCookiesParams(state);
    return {
        clearEachIteration,
        defaultCookiesFromStore: getDefaultCookieItems(state)
    };
};
const connector = connect((state) => (Object.assign(Object.assign({}, getCookiesData(state)), { requestParametersList: getRequestGlobalParametersList(state) })), { onChange: changeDefaultCookies });
const reducer = (state, action) => {
    const { type, payload } = action || {};
    switch (type) {
        case "add": {
            return Array.isArray(state) ? [...state, payload] : state;
        }
        case "delete": {
            return Array.isArray(state) ? state.filter((cookie) => cookie.id !== payload) : [];
        }
        case "set": {
            return Array.isArray(payload) ? payload : state;
        }
        case "update": {
            return Array.isArray(state)
                ? [...state.map((cookie) => (cookie.id === payload.id ? payload : cookie))]
                : state;
        }
        default: {
            return state;
        }
    }
};
const getClearEachIterationFromProps = (props) => (props.clearEachIteration ? true : false);
const RequestDefaultCookiesFormContainer = (props) => {
    const [clearEachIteration, setClearEachIteration] = useState(getClearEachIterationFromProps(props));
    const [defaultCookies, dispatch] = useReducer(reducer, props.defaultCookiesFromStore);
    const { formatMessage } = useIntl();
    const changeHandler = (data, field, value) => {
        const { validatedData } = validatorByRequiredFields(Object.assign(Object.assign({}, data), { [field]: value }), [field]);
        return data.id.includes(NEW_ITEM_ID)
            ? dispatch({ type: "add", payload: Object.assign(Object.assign({}, validatedData), { id: getUniqueId() }) })
            : dispatch({ type: "update", payload: validatedData });
    };
    const clickApplyHandler = () => {
        const { isValid, validatedItemList } = defaultCookieItemListValidator(defaultCookies);
        return isValid
            ? props.onChange({
                clearEachIteration,
                items: defaultCookies
            })
            : dispatch({ type: "set", payload: validatedItemList });
    };
    const deleteHandler = (id) => dispatch({ type: "delete", payload: id });
    const toggleClearEachIterationHandler = () => setClearEachIteration(!clearEachIteration);
    const clearEachIterationSwitcherLabel = formatMessage(CLEAR_EACH_ITERATION_SWITCHER_LABEL);
    const httpsOnlyCheckboxLabel = formatMessage(HTTPS_ONLY_CHECKBOX_LABEL);
    const placeholderDomain = formatMessage(DEFAULT_COOKIE_DOMAIN_PLACEHOLDER);
    const placeholderName = formatMessage(DEFAULT_COOKIE_NAME_PLACEHOLDER);
    const placeholderPath = formatMessage(DEFAULT_COOKIE_PATH_PLACEHOLDER);
    const placeholderValue = formatMessage(DEFAULT_COOKIE_VALUE_PLACEHOLDER);
    return (React.createElement(React.Fragment, null,
        [...defaultCookies, generateDefaultCookieNewItem()].map((cookie) => (React.createElement(DefaultCookie, { key: cookie.id, data: cookie, httpsOnlyCheckboxLabel: httpsOnlyCheckboxLabel, name: "defaultCookie", onChange: changeHandler, onDelete: deleteHandler, parameters: props.requestParametersList, placeholderDomain: placeholderDomain, placeholderName: placeholderName, placeholderPath: placeholderPath, placeholderValue: placeholderValue, theme: defaultCookieTheme }))),
        React.createElement(ClearEachIterationSection, null,
            React.createElement(Switcher, { defaultChecked: clearEachIteration, name: "clearEachIteration", onChange: toggleClearEachIterationHandler }),
            React.createElement(Text, null, clearEachIterationSwitcherLabel)),
        React.createElement(SaveButtonSection, null,
            React.createElement(StyledButton, { onClick: clickApplyHandler }, formatMessage(BUTTON_APPLY_LABEL)))));
};
export default withRouter(connector(RequestDefaultCookiesFormContainer));
