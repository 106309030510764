export const RESPONSE_BODY_PREVIEW_MODE_LABEL_MESSAGE = {
    defaultMessage: "Beautify mode",
    id: "account.request.form.extract.response.preview.body.mode.switcher.label"
};
export const RESPONSE_PREVIEW_MESSAGE = {
    defaultMessage: "Preview response from",
    id: "account.request.form.extract.response.preview.source.select.label"
};
export const RESPONSE_PREVIEW_TITLE_MESSAGE = {
    defaultMessage: "Response",
    id: "account.request.form.extract.response.preview.title"
};
