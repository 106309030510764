var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { getUniqueId, getValueByPath, isEmpty, isEmptyArray, unEscapeComponent } from "@boomq/utils";
import { generateConfigurationElements } from "./configurationElements";
import { BOOMQ_TEST_PLAN_MODEL_SCHEMA_NAME, CONTROLLER_MODEL_SCHEMA_NAME, DEFAULT_CONTENT_MODEL_VERSION, HTTP_REQUEST_DEFAULTS_MODEL_SCHEMA_NAME } from "./constants";
import { generateLoadProfileTypeByDistribution, generateTestLoadProfile, generateThreadGroupLoadProfile } from "./loadProfile";
import { generateTestProfileRequestByType } from "./request";
import { generateTestProjectSlaParams } from "./sla";
import { generateControllerIndividualPropertiesByType } from "./threadGroupElements";
import { generateDefaultTimer } from "./timers";
import { calculateBoomqStableScalabilityTestDurationByPercentDistribution, calculateBoomqStableScalabilityTestDurationByUsersDistribution } from "../loadProfile";
import { TIMEOUT_TIME_COEFFICIENT } from "../parsers/constants";
import { getElementsFromMapByType } from "../testProject";
import { getTestProjectModelElDefaultValues } from "../testProjectModelSchema";
import { ProfileDistribution, SupportingFileType } from "../../models/testProject/enums";
import { ThreadGroupController } from "../../models/threadGroupEditor/enums";
export const generateGroupsPercentages = (groups = []) => {
    const averagePercentage = Math.floor(100 / groups.length);
    const lastGroupPercentage = 100 - averagePercentage * (groups.length - 1);
    return groups.map((group, index) => (Object.assign(Object.assign({}, group), { perc: index === groups.length - 1 ? lastGroupPercentage : averagePercentage })));
};
export const generateRequestUrl = (url, queryParams) => {
    const baseUrl = url && url.split("?")[0];
    return isEmptyArray(queryParams)
        ? baseUrl
        : `${baseUrl}?${queryParams
            .map((param) => (param.key !== "" ? `${param.key}=${param.value}` : ""))
            .join("&")}`.trim();
};
export const generateQueryParams = (url = "") => {
    const queryParamsPart = (url || "").split("?")[1];
    const queryParams = (queryParamsPart && queryParamsPart.replace(/([&]){1,}/g, "&").split("&")) || [];
    return queryParams.map((paramStr) => {
        const paramKeyValue = paramStr.split("=");
        const paramKeyValLen = paramKeyValue.length;
        return {
            id: getUniqueId(),
            key: unEscapeComponent(paramKeyValue[0]),
            value: paramKeyValLen > 1
                ? unEscapeComponent(Array(paramKeyValLen - 1).join("=") + paramKeyValue[paramKeyValLen - 1])
                : ""
        };
    });
};
export const getTestTotalDuration = (params) => {
    const _a = params || {}, { groups, profileDistribution } = _a, restParams = __rest(_a, ["groups", "profileDistribution"]);
    const totalDuration = profileDistribution === ProfileDistribution.Percent
        ? calculateBoomqStableScalabilityTestDurationByPercentDistribution(restParams)
        : calculateBoomqStableScalabilityTestDurationByUsersDistribution(groups);
    return totalDuration > 0 ? totalDuration : 0;
};
const generateTestProfileRequestsByGroup = (groupId, requests, modelSchema, timers) => ({
    requests: (requests || [])
        .filter((request) => request.groupId === groupId)
        .map((requestData) => generateTestProfileRequestByType({ requestData, modelSchema, timers }))
});
export const generateTestProfileRequestGroupResourceLocations = (runnerLocations) => runnerLocations
    ? {
        resourceConfiguration: {
            cloudTestRunLocationRequirements: {
                testRunLocationIds: !isEmptyArray(runnerLocations) ? [...runnerLocations] : []
            }
        }
    }
    : {};
const generateTestProfileRequestGroupRunnerResources = (runnerResources) => runnerResources
    ? {
        resourceConfiguration: {
            testRunnerIds: !isEmptyArray(runnerResources) ? [...runnerResources] : []
        }
    }
    : {};
const generateRequestsTestProfileRequestGroup = (group, requests, modelSchema, timers) => (Object.assign(Object.assign({ id: group.id, name: group.name, perc: Number(group.perc) || 0 }, generateTestProfileRequestsByGroup(group.id, requests, modelSchema, timers)), generateTestProfileRequestGroupRunnerResources(group.runnerResources)));
const generateRequestsTestProfileRequestGroups = ({ groups = [], requests = [], modelSchema, timers = {} }) => groups.map((group) => generateRequestsTestProfileRequestGroup(group, requests, modelSchema, timers));
const generateThreadGroupController = ({ threadGroupElement, elementsData, grpcBuffer, timers, modelSchema }) => {
    const _a = elementsData[threadGroupElement.id], { groupId, id, index, name } = _a, restParams = __rest(_a, ["groupId", "id", "index", "name"]);
    return Object.assign(Object.assign({}, getTestProjectModelElDefaultValues(modelSchema, CONTROLLER_MODEL_SCHEMA_NAME)), { creationIndex: index, id, type: ThreadGroupController.Controller, typeDisplayName: "Transaction Controller", label: name, individualProperties: Object.assign(Object.assign({}, generateControllerIndividualPropertiesByType(modelSchema, restParams)), { includeDurationOfAllElementsToGeneratedSampler: false }), children: (threadGroupElement.children || []).map((threadGroupChildrenElement) => generateThreadGroupElementByType({
            threadGroupElement: threadGroupChildrenElement,
            elementsData,
            grpcBuffer,
            modelSchema,
            timers
        })), timerList: [] });
};
const generateThreadGroupSampler = (_a) => {
    var _b = _a.elementData, { id, name } = _b, restParams = __rest(_b, ["id", "name"]), { grpcBuffer, modelSchema, timers } = _a;
    const _c = generateTestProfileRequestByType({ requestData: restParams, grpcBuffer, modelSchema, timers }), { assertions, extractors, timerList, typeDisplayName } = _c, individualPropertiesData = __rest(_c, ["assertions", "extractors", "timerList", "typeDisplayName"]);
    return Object.assign(Object.assign({}, getTestProjectModelElDefaultValues(modelSchema, CONTROLLER_MODEL_SCHEMA_NAME)), { id,
        assertions, individualProperties: individualPropertiesData, extractors, label: name, timerList, type: ThreadGroupController.Sampler, typeDisplayName });
};
const generateThreadGroupElementByType = (_a) => {
    var { threadGroupElement, elementsData } = _a, restParams = __rest(_a, ["threadGroupElement", "elementsData"]);
    return threadGroupElement.type === ThreadGroupController.Controller
        ? generateThreadGroupController(Object.assign({ threadGroupElement, elementsData }, restParams))
        : generateThreadGroupSampler(Object.assign({ elementData: elementsData[threadGroupElement.id] }, restParams));
};
const generateThreadGroupData = ({ displayTypeName, id, enabled = true, name, perc, runnerLocations = [], runnerResources = [], type }) => ({
    boomqConfiguration: Object.assign(Object.assign({}, generateTestProjectResources(runnerResources)), { loadProfilePercent: perc }),
    enabled,
    id,
    label: name,
    steps: [],
    type,
    typeDisplayName: displayTypeName
});
const generateThreadGroup = (_a) => {
    var { group, threadGroupTree } = _a, restParams = __rest(_a, ["group", "threadGroupTree"]);
    return (Object.assign(Object.assign({}, generateThreadGroupData(group)), { threadGroupElements: threadGroupTree.map((threadGroupElement) => generateThreadGroupElementByType(Object.assign({ threadGroupElement }, restParams))) }));
};
const generateThreadGroups = (testProjectData, modelSchema) => {
    const { elementsData, groups, grpcBuffer, profileDistribution, treeData, timers } = testProjectData || {};
    return groups.map((group) => (Object.assign(Object.assign({}, generateThreadGroup({
        elementsData,
        group,
        grpcBuffer,
        modelSchema,
        threadGroupTree: treeData[group.id],
        timers
    })), generateThreadGroupLoadProfile(profileDistribution, group, modelSchema))));
};
export const generateSupportingFilesFromCsvParameters = (csvParameters = []) => (csvParameters || []).reduce((res, csvParameter) => [
    ...res,
    ...((csvParameter === null || csvParameter === void 0 ? void 0 : csvParameter.files) || []).map(({ id, name }) => ({
        id,
        name,
        link: csvParameter.location,
        preview: csvParameter.preview,
        type: SupportingFileType.Text
    }))
], []);
export const generateSupportingFilesFromTestProjectData = (testProjectData) => [
    ...generateSupportingFilesFromCsvParameters(getValueByPath(testProjectData, "requestParameters.csvParameters", []))
];
const generateTestProjectContentRequests = (testProjectData, modelSchema) => (Object.assign(Object.assign({ boomqTestPlan: Object.assign(Object.assign(Object.assign(Object.assign({}, generateTestProjectContentCommonParams(testProjectData, modelSchema)), generateTestLoadProfile(testProjectData, modelSchema)), generateDefaultTimer(testProjectData.defaultTimer, modelSchema)), { loadProfileType: generateLoadProfileTypeByDistribution(testProjectData.profileDistribution), threadGroups: generateThreadGroups(testProjectData, modelSchema) }) }, generateTestProfileRequestGroupResourceLocations(getValueByPath(testProjectData, "runnerLocations"))), { slaGroupList: generateTestProjectSlaParams(testProjectData.sla), supportingFiles: generateSupportingFilesFromTestProjectData(testProjectData), supportingFilesV2: [
        ...generateSupportingFilesFromTestProjectData(testProjectData),
        ...getValueByPath(testProjectData, "grpcBuffer", [])
    ] }));
const generateTestProjectResources = (resources) => isEmpty(resources) || isEmptyArray(resources)
    ? { resourceConfiguration: {} }
    : { resourceConfiguration: { testRunnerIds: resources } };
const generateHttpRequestDefaults = (httpRequestDefaultsData, modelSchema) => isEmpty(httpRequestDefaultsData)
    ? {}
    : {
        httpRequestDefaults: Object.assign(Object.assign(Object.assign({}, getTestProjectModelElDefaultValues(modelSchema, HTTP_REQUEST_DEFAULTS_MODEL_SCHEMA_NAME)), httpRequestDefaultsData), { connectTimeout: TIMEOUT_TIME_COEFFICIENT * httpRequestDefaultsData.connectTimeout, responseTimeout: TIMEOUT_TIME_COEFFICIENT * httpRequestDefaultsData.responseTimeout })
    };
const generateTestProjectContentCommonParams = (testProjectData, modelSchema) => (Object.assign(Object.assign({}, getTestProjectModelElDefaultValues(modelSchema, BOOMQ_TEST_PLAN_MODEL_SCHEMA_NAME)), { configurationElements: generateConfigurationElements(testProjectData, modelSchema) }));
export const getContentModelVersion = (testProjectData) => getValueByPath(testProjectData, "contentModelVersion", DEFAULT_CONTENT_MODEL_VERSION);
const getRequesCountFromTestProjectData = (testProjectData) => {
    var _a;
    return (testProjectData === null || testProjectData === void 0 ? void 0 : testProjectData.elementsData)
        ? (_a = getElementsFromMapByType(testProjectData === null || testProjectData === void 0 ? void 0 : testProjectData.elementsData, ThreadGroupController.Sampler)) === null || _a === void 0 ? void 0 : _a.length
        : 0;
};
export const generateTestProjectCommonParams = (testProjectData) => ({
    comment: "",
    contentFormat: "yaml",
    contentModelVersion: getContentModelVersion(testProjectData),
    labelSet: testProjectData.labelSet,
    requestCount: getRequesCountFromTestProjectData(testProjectData),
    testType: testProjectData.testType
});
export const generateTestProjectRequests = (testProjectData, modelSchema) => (Object.assign(Object.assign({}, generateTestProjectCommonParams(testProjectData)), { projectName: testProjectData.projectName, contentV2: generateTestProjectContentRequests(testProjectData, modelSchema), settingsId: testProjectData.settingsId, totalDuration: getTestTotalDuration(testProjectData) }));
