import styled from "styled-components";
import { Color, FontBodySmallBold, IconHelp } from "@boomq/uikit";
export const BlockJMeterPropertiesHelp = styled.div `
    align-items: center;
    display: flex;
`;
export const HelpLink = styled.a ``;
export const StyledIconHelp = styled(IconHelp) `
    fill: ${Color.blue};
    min-height: 24px;
    min-width: 24px;
`;
export const SupportedJMeterModulesText = styled.div `
    ${FontBodySmallBold}
    color: ${Color.darkGrey35};
    margin-left: 8px;
`;
