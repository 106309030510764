import { handleActions } from 'redux-actions';
import clientActions from '../actions/client';
const { analyticsRequest, analyticsSuccess, analyticsFailure } = clientActions;
export const initialState = {
    data: [],
    error: {},
    url: null
};
export default handleActions({
    [analyticsRequest]: (state) => (Object.assign(Object.assign({}, state), { data: [] })),
    [analyticsSuccess]: (state, action) => (Object.assign(Object.assign({}, state), action.payload)),
    [analyticsFailure]: (state, action) => (Object.assign(Object.assign({}, state), { error: action.payload }))
}, initialState);
export const getAnalyticsData = state => state.analytics.data;
export const getAnalyticsUrl = state => state.analytics.url;
