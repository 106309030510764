import React from "react";
import { Button, ButtonSize, Tabs } from "@boomq/uikit";
import { customTabListTheme, ButtonSection, Sla, SlaDataSection, StyledNewItemButton } from "./styled";
import { slaTargetElements } from "../../../models/sla";
export const SlaForm = ({ addSlaButtonLabel, isShowAddSlaButton, isShowSaveButton, onChangeTab, onClickAddSla, onClickSave, saveButtonLabel, slaTabItems, targetType }) => (React.createElement(Sla, null,
    React.createElement(SlaDataSection, null,
        React.createElement(Tabs, { currentActiveTabName: targetType, defaultActiveTabName: targetType, elements: slaTargetElements, items: slaTabItems, name: "sla", onChangeTab: onChangeTab, tabListTheme: customTabListTheme }),
        isShowAddSlaButton && (React.createElement(StyledNewItemButton, { onClick: onClickAddSla }, addSlaButtonLabel))),
    isShowSaveButton && (React.createElement(ButtonSection, null,
        React.createElement(Button, { onClick: onClickSave, size: ButtonSize.big }, saveButtonLabel)))));
