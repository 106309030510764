var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { StyledMenuListItem, StyledNav } from "./styled";
export const NavBar = (_a) => {
    var { disabled, menuOptions } = _a, restProps = __rest(_a, ["disabled", "menuOptions"]);
    return (React.createElement(StyledNav, Object.assign({ disabled: disabled }, restProps), menuOptions.map((_a) => {
        var { id } = _a, restParams = __rest(_a, ["id"]);
        return (React.createElement(StyledMenuListItem, Object.assign({ key: id }, restParams)));
    })));
};
