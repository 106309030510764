var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { useIntl } from "@boomq/utils";
import { TITLE } from "./intl";
import { ProgressBar } from "../../../common/ProgressBar";
import { RunningTestsItemTemplateContainer } from "../../../../containers/account/TestRuns/RunningTestsItemTemplate";
import { convertDateToTimestamp } from "../../../../helpers/date";
import { formatIntlMessage } from "../../../../helpers/intl";
const RunningTemplate = (_a) => {
    var { projectName, startDate, totalDuration } = _a, restProps = __rest(_a, ["projectName", "startDate", "totalDuration"]);
    const { formatMessage } = useIntl();
    const startTime = convertDateToTimestamp(startDate);
    const title = `${formatIntlMessage(TITLE, formatMessage)} ${projectName || ""}`;
    return (React.createElement(RunningTestsItemTemplateContainer, Object.assign({ title: title }, restProps),
        React.createElement(ProgressBar, { startTime: startTime, totalDuration: totalDuration })));
};
export default RunningTemplate;
