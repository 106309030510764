import React from "react";
import { FormattedMessage } from "react-intl";
import { AnomalyDescription, TooltipBody, TooltipItem } from "./styled";
export const CustomTooltip = ({ payload, label, data, isAnomalyEnabled, anomalyLabel }) => (React.createElement(TooltipBody, { className: "recharts-default-tooltip" },
    React.createElement("p", { className: "recharts-tooltip-label" }, label),
    React.createElement("ul", { className: "recharts-tooltip-item-list" }, (payload || []).map((tooltipItem, index) => {
        const { color, name, value } = tooltipItem || {};
        return (React.createElement(TooltipItem, { className: "recharts-tooltip-item-list", color: color, key: index }, `${name}: ${value}`));
    })),
    data.anomalyDescription && isAnomalyEnabled && (React.createElement(AnomalyDescription, { color: data.anomalyColor },
        React.createElement("span", null, `${anomalyLabel}: `),
        React.createElement(FormattedMessage, Object.assign({}, data.anomalyDescription))))));
