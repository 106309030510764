import React from "react";
import RcPagination from "rc-pagination";
import en from "rc-pagination/es/locale/en_GB";
import ru from "rc-pagination/es/locale/ru_RU";
import { DEFAULT_LOCALE, DEFAULT_PAGE_NUMBER, DEFAULT_PAGE_SIZE } from "./constants";
import { Button, StyledIconDropDownArrowLeft, StyledIconDropDownArrowRight, StyledPagination } from "./styled";
const locale = { ru, en };
export const Pagination = ({ clickHandler, currentPage = DEFAULT_PAGE_NUMBER, lang = DEFAULT_LOCALE, pageSize = DEFAULT_PAGE_SIZE, totalPages }) => {
    const changeHandler = (page) => clickHandler === null || clickHandler === void 0 ? void 0 : clickHandler(page - 1);
    const itemRender = (current, type) => {
        switch (type) {
            case "prev":
                return (React.createElement(Button, { isDisabled: currentPage === 0 },
                    React.createElement(StyledIconDropDownArrowLeft, null)));
            case "next":
                return (React.createElement(Button, { isDisabled: currentPage + 1 === totalPages },
                    React.createElement(StyledIconDropDownArrowRight, null)));
            case "jump-prev":
            case "jump-next":
                return React.createElement(Button, null, "...");
            default:
                return React.createElement(Button, { isDisabled: currentPage + 1 === current }, current);
        }
    };
    return (React.createElement(StyledPagination, null,
        React.createElement(RcPagination, { current: currentPage + 1, defaultPageSize: DEFAULT_PAGE_SIZE, itemRender: itemRender, locale: locale[lang], onChange: changeHandler, pageSize: pageSize, total: totalPages * pageSize })));
};
