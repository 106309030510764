export var ReportChartAggregationInterval;
(function (ReportChartAggregationInterval) {
    ReportChartAggregationInterval["Day"] = "DAY";
    ReportChartAggregationInterval["Hour"] = "HOUR";
    ReportChartAggregationInterval["Minute"] = "MINUTE";
    ReportChartAggregationInterval["Seconds5"] = "SECONDS5";
    ReportChartAggregationInterval["Seconds10"] = "SECONDS10";
})(ReportChartAggregationInterval || (ReportChartAggregationInterval = {}));
export var ReportChartType;
(function (ReportChartType) {
    ReportChartType["LoadProfile"] = "VUSERS";
    ReportChartType["SystemMetric"] = "SYSTEM_METRIC";
    ReportChartType["TestMetric"] = "TEST_METRIC";
    ReportChartType["TransactionMetric"] = "TRANSACTION_METRIC";
})(ReportChartType || (ReportChartType = {}));
export var ReportChartLoadProfileType;
(function (ReportChartLoadProfileType) {
    ReportChartLoadProfileType["Actual"] = "ACTUAL";
    ReportChartLoadProfileType["Planned"] = "PLANNED";
})(ReportChartLoadProfileType || (ReportChartLoadProfileType = {}));
export var ReportChartSystemMetricType;
(function (ReportChartSystemMetricType) {
    ReportChartSystemMetricType["CpuUtilizationPercent"] = "CPU_UTILIZATION_PERCENT";
    ReportChartSystemMetricType["CpuUtilizationPerCpuPercent"] = "CPU_UTILIZATION_PER_CPU_PERCENT";
    ReportChartSystemMetricType["DiskTotalBytes"] = "DISK_TOTAL_BYTES";
    ReportChartSystemMetricType["DiskUtilizationBytes"] = "DISK_UTILIZATION_BYTES";
    ReportChartSystemMetricType["DiskUtilizationPercent"] = "DISK_UTILIZATION_PERCENT";
    ReportChartSystemMetricType["RamTotalBytes"] = "RAM_TOTAL_BYTES";
    ReportChartSystemMetricType["RamUtilizationBytes"] = "RAM_UTILIZATION_BYTES";
    ReportChartSystemMetricType["RamUtilizationPercent"] = "RAM_UTILIZATION_PERCENT";
    ReportChartSystemMetricType["SystemLoadAverage"] = "SYSTEM_LOAD_AVERAGE";
})(ReportChartSystemMetricType || (ReportChartSystemMetricType = {}));
export var ReportChartTestMetricType;
(function (ReportChartTestMetricType) {
    ReportChartTestMetricType["AvgResponseTimeSec"] = "AVG_RESPONSE_TIME_SEC";
    ReportChartTestMetricType["ErrorsPerSecond"] = "ERRORS_PER_SECOND";
    ReportChartTestMetricType["HitsPerSecond"] = "HITS_PER_SECOND";
    ReportChartTestMetricType["Pct95ResponseTimeSec"] = "PCT_95_RESPONSE_TIME_SEC";
    ReportChartTestMetricType["RequestsPerSecond"] = "REQUESTS_PER_SECOND";
})(ReportChartTestMetricType || (ReportChartTestMetricType = {}));
export var ReportChartTransactionMetricType;
(function (ReportChartTransactionMetricType) {
    ReportChartTransactionMetricType["AvgTransactionTimeSec"] = "AVG_TRANSACTION_TIME_SEC";
    ReportChartTransactionMetricType["Pct95ResponseTimeSec"] = "PCT_95_TRANSACTION_TIME_SEC";
    ReportChartTransactionMetricType["TransactionErrorsPerSecond"] = "TRANSACTION_ERRORS_PER_SECOND";
    ReportChartTransactionMetricType["TransactionsPerSeconds"] = "TRANSACTIONS_PER_SECONDS";
})(ReportChartTransactionMetricType || (ReportChartTransactionMetricType = {}));
export var ReportChartUnit;
(function (ReportChartUnit) {
    ReportChartUnit["Byte"] = "BYTE";
    ReportChartUnit["BytesPerSecond"] = "BYTES_PER_SECOND";
    ReportChartUnit["ErrorsPerSecond"] = "ERRORS_PER_SECOND";
    ReportChartUnit["Gigabyte"] = "GIGABYTE";
    ReportChartUnit["HitsPerSecond"] = "HITS_PER_SECOND";
    ReportChartUnit["Percent"] = "PERCENT";
    ReportChartUnit["RequestsPerSecond"] = "REQUESTS_PER_SECOND";
    ReportChartUnit["Second"] = "SECOND";
    ReportChartUnit["TransactionErrorsPerSecond"] = "TRANSACTION_ERRORS_PER_SECOND";
    ReportChartUnit["TransactionsPerSecond"] = "TRANSACTIONS_PER_SECOND";
    ReportChartUnit["VUser"] = "VUSER";
})(ReportChartUnit || (ReportChartUnit = {}));
export var ReportTableColumnType;
(function (ReportTableColumnType) {
    ReportTableColumnType["RequestData"] = "REQUEST_DATA";
    ReportTableColumnType["SLAData"] = "SLA_DATA";
    ReportTableColumnType["SLADiff"] = "SLA_DIFF";
    ReportTableColumnType["SLAResult"] = "SLA_RESULT";
    ReportTableColumnType["TransactionData"] = "TRANSACTION_DATA";
})(ReportTableColumnType || (ReportTableColumnType = {}));
export var ReportTableColumnDiffTargetType;
(function (ReportTableColumnDiffTargetType) {
    ReportTableColumnDiffTargetType["PreviousTest"] = "PREVIOUS_TEST";
    ReportTableColumnDiffTargetType["SLAValue"] = "SLA_VALUE";
})(ReportTableColumnDiffTargetType || (ReportTableColumnDiffTargetType = {}));
export var ReportTableColumnDiffValueType;
(function (ReportTableColumnDiffValueType) {
    ReportTableColumnDiffValueType["Absolute"] = "ABSOLUTE";
    ReportTableColumnDiffValueType["Relative"] = "RELATIVE";
})(ReportTableColumnDiffValueType || (ReportTableColumnDiffValueType = {}));
export var ReportTableType;
(function (ReportTableType) {
    ReportTableType["RequestSLATable"] = "REQUEST_SLA_TABLE";
    ReportTableType["TestSLATable"] = "TEST_SLA_TABLE";
    ReportTableType["TransactionSLATable"] = "TRANSACTION_SLA_TABLE";
})(ReportTableType || (ReportTableType = {}));
