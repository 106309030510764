import React, { FC, useEffect } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import {
    // CommonTeamPageProps,
    // CommonTeamPageHandlers,
    adminTeamRequest,
    adminTeamSavingRequest,
    getClientRole,
    getIsAuthorize,
    getRouteTeamId,
    getTeamPermissionList,
    isExistTeamAdminPermission,
    TeamAdminAccessTemplateContainer,
    TEAM_ID_PARAM_NAME
} from "@boomq/common";
import { AdminTeamMembersContainer } from "../../../admin/Team/TeamMembers";

const connector = connect(
    (state) => ({
        isAuthorized: getIsAuthorize(state),
        permissionList: getTeamPermissionList(state),
        roles: getClientRole(state)
    }),
    {
        onAuthorized: adminTeamRequest,
        onSave: adminTeamSavingRequest
    }
);

const AdminTeamPageContainer: FC<any & RouteComponentProps<{ [TEAM_ID_PARAM_NAME]: string }>> = ({
    match,
    permissionList,
    roles,
    ...restProps
}: any & RouteComponentProps<{ [TEAM_ID_PARAM_NAME]: string }>): JSX.Element => {
    const teamId = getRouteTeamId(match);

    const checkAuthorization = (): void =>
        restProps.isAuthorized ? restProps.onAuthorized({ id: teamId }) : undefined;

    useEffect(() => {
        checkAuthorization();
    }, [restProps.isAuthorized]);

    const isExistAdminAccess = isExistTeamAdminPermission(roles, permissionList);

    return (
        <TeamAdminAccessTemplateContainer {...restProps} isExistAdminAccess={isExistAdminAccess} teamId={teamId}>
            <AdminTeamMembersContainer teamId={teamId} />
        </TeamAdminAccessTemplateContainer>
    );
};

export default connector(AdminTeamPageContainer);
