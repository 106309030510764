import React, { useEffect, useRef, useState } from "react";
import { isEmpty, stopEvent, useEventListener } from "@boomq/utils";
import { VERSION_NAME_INPUT_CLASS_NAME, VERSION_NAME_MAX_LENGTH } from "./constants";
import { VERSION_NAME_ERROR_TEXT } from "./intl";
import { EditableVersionName, InputIcons, StyledIconCheck, StyledIconClose, StyledIconEdit, StyledInputText } from "./styled";
const getValueFromProps = (value) => value || "";
export const VersionName = ({ id, name, onChange }) => {
    const [errorText, setErrorText] = useState("");
    const [isEdit, setIsEdit] = useState(false);
    const [versionName, setVersionName] = useState(getValueFromProps(name));
    const editableVersionNameRef = useRef(null);
    const outSideClick = () => {
        setIsEdit(false);
        removeEventListener();
    };
    const outSideClickHandler = (event) => editableVersionNameRef.current && !editableVersionNameRef.current.contains(event.target)
        ? outSideClick()
        : undefined;
    const [addEventListener, removeEventListener] = useEventListener(document, "click", outSideClickHandler);
    useEffect(() => {
        setVersionName(getValueFromProps(name));
    }, [name]);
    const focusInputElement = (element) => {
        addEventListener();
        element.focus();
    };
    const focusInput = () => {
        const inputEl = editableVersionNameRef.current &&
            editableVersionNameRef.current.querySelector(`.${VERSION_NAME_INPUT_CLASS_NAME}`);
        return inputEl && typeof inputEl.focus === "function" ? focusInputElement(inputEl) : undefined;
    };
    const changeHandler = (value) => {
        setVersionName(value);
        setErrorText("");
    };
    const validateValue = (value) => isEmpty(value) ? { isValid: false, error: VERSION_NAME_ERROR_TEXT } : { isValid: true, error: "" };
    const changeValue = () => {
        outSideClick();
        return typeof onChange === "function" ? onChange({ id, value: versionName }) : undefined;
    };
    const clickHandler = (event) => (isEdit ? stopEvent(event) : undefined);
    const cancelHandler = (event) => {
        stopEvent(event);
        changeHandler(getValueFromProps(name));
        outSideClick();
    };
    const confirmHandler = (event) => {
        const { isValid, error } = validateValue(versionName);
        stopEvent(event);
        return isValid ? changeValue() : setErrorText(error);
    };
    const toggleModeHandler = (event) => {
        stopEvent(event);
        setIsEdit(true);
        focusInput();
    };
    const escape = (event) => {
        cancelHandler(event);
        return event && event.target && typeof event.target.blur === "function" ? event.target.blur() : undefined;
    };
    const checkEscape = (event) => event && event.key === "Escape" ? escape(event) : undefined;
    const keyHandler = (event) => event && event.key === "Enter" ? confirmHandler(event) : checkEscape(event);
    return (React.createElement(EditableVersionName, { isEdit: isEdit, onClick: clickHandler, ref: editableVersionNameRef },
        React.createElement(StyledInputText, { className: VERSION_NAME_INPUT_CLASS_NAME, error: errorText, isEdit: isEdit, maxLength: VERSION_NAME_MAX_LENGTH, onChange: changeHandler, onKeyDown: keyHandler, value: versionName }),
        React.createElement(InputIcons, { error: errorText, isEdit: isEdit },
            React.createElement(StyledIconCheck, { onClick: confirmHandler }),
            React.createElement(StyledIconClose, { onClick: cancelHandler })),
        React.createElement(StyledIconEdit, { onClick: toggleModeHandler })));
};
