import React from "react";
import { Switcher } from "../../../common/Switcher";
import { customSwitcherTheme } from "./styled";
import { GroupName } from "../../../styled/TestProject";
const DEFAULT_REQUEST_GROUP = {
    enabled: true,
    name: ""
};
export const RequestGroupJMXCaption = ({ children, onToggle, requestGroup = DEFAULT_REQUEST_GROUP }) => {
    const toggleIsDisabledHandler = (event) => event && event.target && typeof onToggle === "function" ? onToggle(!event.target.checked) : false;
    return (React.createElement(GroupName, null,
        React.createElement(Switcher, { defaultChecked: requestGroup.enabled, onClick: toggleIsDisabledHandler, theme: customSwitcherTheme }),
        requestGroup.name,
        children));
};
