var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useComponentDidMount, useIntl } from "@boomq/utils";
import { ALL_REPORTS_PAGE_TITLE, NEW_REPORT_MESSAGE } from "./intl";
import { AllReports } from "../../../components/account/AllReports";
import { isAllowedReportActionByPermissions } from "../../../helpers/report";
import { getSiteTitle } from "../../../helpers/title";
import { isEmpty } from "../../../helpers/utils";
import { ReportAction } from "../../../models/report";
import { gridColumns } from "../../../models/reports";
import { setTitle } from "../../../redux/actions/accountTitle";
import { setCurrentPage, reportsChangeFilterLabelsRequest, reportsRequest } from "../../../redux/actions/reports";
import { getClientPermissionList, getIsAuthorize, getSelectedTeamId } from "../../../redux/reducers/authorizePlate";
import { getIsShowLoader } from "../../../redux/reducers/loader";
import { getCurrentPage, getReports, getReportsFilterLabels, getReportsLabelList, getReportsPagesCount } from "../../../redux/reducers/reports";
const connector = connect((state) => ({
    currentPage: getCurrentPage(state),
    filterLabels: getReportsFilterLabels(state),
    isAuthorized: getIsAuthorize(state),
    isShowLoader: getIsShowLoader(state),
    labelList: getReportsLabelList(state),
    permissionList: getClientPermissionList(state),
    reports: getReports(state),
    reportsPagesCount: getReportsPagesCount(state),
    selectedTeamId: getSelectedTeamId(state)
}), {
    onChangeFilter: reportsChangeFilterLabelsRequest,
    onChangePage: setCurrentPage,
    onRefreshItems: reportsRequest,
    setTitle
});
const AllReportsContainer = (_a) => {
    var { currentPage, isAuthorized, onChangePage, onRefreshItems, permissionList, selectedTeamId, setTitle } = _a, restProps = __rest(_a, ["currentPage", "isAuthorized", "onChangePage", "onRefreshItems", "permissionList", "selectedTeamId", "setTitle"]);
    const { formatMessage } = useIntl();
    useComponentDidMount(() => {
        document.title = getSiteTitle(formatMessage(ALL_REPORTS_PAGE_TITLE));
        setTitle("account.header.title.reports");
    });
    useEffect(() => {
        const checkAuthorization = () => isAuthorized && !isEmpty(selectedTeamId) ? onRefreshItems({ page: currentPage }) : undefined;
        checkAuthorization();
    }, [isAuthorized, selectedTeamId]);
    const changePage = (num) => (typeof onChangePage === "function" ? onChangePage(num) : undefined);
    const clickPageHandler = (num) => {
        changePage(Number(num));
        onRefreshItems({ page: num });
    };
    const formattedGridColumns = gridColumns.map((_a) => {
        var { nameIntlMsg } = _a, restParams = __rest(_a, ["nameIntlMsg"]);
        return (Object.assign(Object.assign({}, restParams), { name: formatMessage(nameIntlMsg) }));
    });
    const newReportBlockText = formatMessage(NEW_REPORT_MESSAGE);
    return (React.createElement(AllReports, Object.assign({ currentPage: currentPage, gridColumns: formattedGridColumns, isShowNewReportButton: isAllowedReportActionByPermissions(permissionList, ReportAction.Add), newReportBlockText: newReportBlockText, onChangePage: clickPageHandler }, restProps)));
};
export default connector(AllReportsContainer);
