import { call, put, select } from "redux-saga/effects";
import { isEmpty } from "@boomq/utils";
import { getResponseErrorData, getTestRunnerResources, showLoader } from "@boomq/common";
import { runnerResourcesFailure, runnerResourcesSuccess } from "../actions/runnerResources";
import { getRunnerResources } from "../reducers/runnerResources";

function* runnerResourcesRequestFlow() {
    try {
        yield put(showLoader());

        const { data } = yield call(getTestRunnerResources);

        yield put(runnerResourcesSuccess(data));
    } catch (e) {
        yield put(runnerResourcesFailure(getResponseErrorData(e)));
    }
}

export function* checkRunnerResourcesFlow() {
    try {
        const runnerResources = yield select(getRunnerResources);

        return isEmpty(runnerResources) ? yield* runnerResourcesRequestFlow() : undefined;
    } catch (e) {
        console.error(e);
    }
}
