import moment from "moment";
import { DAY_RANGE_FORMAT } from "./constants";
export const getDayRange = (offsetDay) => ({
    startDate: moment().add(offsetDay, "d").format(DAY_RANGE_FORMAT),
    endDate: moment().format(DAY_RANGE_FORMAT)
});
export const getISODayRange = (offsetDay) => ({
    startDate: moment().add(offsetDay, "d").toISOString(),
    endDate: moment().toISOString()
});
