var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { convertParamsNameByComplianceMap, getUniqueId, isEmpty } from "@boomq/utils";
import { EXTRACTOR_LABEL_DEFAULT_VALUE } from "./constants";
import { getExtractorMatchByMatchType } from "../extractResponse";
import { getTestProjectModelElDefaultValues } from "../testProjectModelSchema";
import { ExtractorScope, ExtractorType } from "../types";
const generateExtractorBoundary = (_a) => {
    var { fieldToCheck } = _a, restParams = __rest(_a, ["fieldToCheck"]);
    return (Object.assign({ fieldToCheck }, convertParamsNameByComplianceMap({
        leftBorder: "leftBoundary",
        rightBorder: "rightBoundary"
    }, restParams)));
};
const generateExtractorRegex = (_a) => {
    var { fieldToCheck, groupNumber } = _a, restParams = __rest(_a, ["fieldToCheck", "groupNumber"]);
    return (Object.assign({ fieldToCheck,
        groupNumber }, convertParamsNameByComplianceMap({
        re: "regularExpression"
    }, restParams)));
};
const generateExtractorJsonPath = (params) => convertParamsNameByComplianceMap({ jsonpath: "jsonPathExpression" }, params);
const generateExtractorXPath = (params) => convertParamsNameByComplianceMap({ xpath: "expression", namespacesAliases: "namespacesAliases" }, params);
const extractorGeneratorsByType = {
    [ExtractorType.Boundary]: generateExtractorBoundary,
    [ExtractorType.RegEx]: generateExtractorRegex,
    [ExtractorType.JsonPath]: generateExtractorJsonPath,
    [ExtractorType.XPath]: generateExtractorXPath,
    [ExtractorType.XPath2]: generateExtractorXPath
};
const extractorModelNameByType = {
    [ExtractorType.Boundary]: "BoundaryExtractor",
    [ExtractorType.RegEx]: "RegexpExtractor",
    [ExtractorType.JsonPath]: "JsonExtractor",
    [ExtractorType.XPath2]: "XPath2Extractor"
};
const generateExtractorByType = (type, params) => typeof extractorGeneratorsByType[type] === "function" ? extractorGeneratorsByType[type](params) : {};
const getModelElNameByType = (extractorType) => extractorModelNameByType[extractorType];
const getModelDefaultValuesByType = (modelSchema, extractorType) => {
    const elData = getTestProjectModelElDefaultValues(modelSchema, getModelElNameByType(extractorType));
    return isEmpty(elData) ? {} : elData;
};
const generateExtractorLabel = () => `${EXTRACTOR_LABEL_DEFAULT_VALUE}_${getUniqueId()}`;
const prepareExtractorLabel = (label) => (label ? label : generateExtractorLabel());
const generateExtractor = ({ defaultValue = "", id, label = "", match, params, type, typeDisplayName = "", variable }, modelSchema) => (Object.assign(Object.assign(Object.assign({}, getModelDefaultValuesByType(modelSchema, type)), { defaultValue,
    id, label: prepareExtractorLabel(label), matchNumber: getExtractorMatchByMatchType(match), scope: ExtractorScope.Parent, type,
    typeDisplayName, variableName: variable }), generateExtractorByType(type, params)));
export const generateExtractors = (extractors = [], modelSchema) => (extractors || []).reduce((res, extractor) => [...res, generateExtractor(extractor, modelSchema)], []);
