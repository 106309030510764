import { RESOURCE_DEFAULT_INTL_MESSAGE, RESOURCE_INTL_MESSAGE } from "./intl";
import { HashMap, SelectionType } from "../types";

const prepareHostUrl = (resource): string => {
    const { ip, port } = resource || {};
    const urlPort = port ? `:${port}` : "";

    return `${ip}${urlPort}`;
};

const generateDefaultText = (formatMessage): string =>
    typeof formatMessage === "function" ? formatMessage(RESOURCE_DEFAULT_INTL_MESSAGE) : RESOURCE_DEFAULT_INTL_MESSAGE.defaultMessage;

const generateDefaultResource = (formatMessage): HashMap<any> => {
    const resourceDefaultText = generateDefaultText(formatMessage);

    return {
        id: -1,
        bodyText: resourceDefaultText,
        text: resourceDefaultText,
        value: "automatic",
        type: SelectionType.Single
    };
};

const generateResourceText = (resource, formatMessage): string => {
    const resourceInfo = `${prepareHostUrl(resource)} - ${resource.allocatedUsers || 0}/${resource.maxUserCount || 0}`;

    return typeof formatMessage === "function" ? formatMessage(RESOURCE_INTL_MESSAGE, {resourceInfo}) : `${resourceInfo} ${RESOURCE_INTL_MESSAGE.defaultMessage}`
};

const generateResource = (resource, formatMessage): HashMap<any> => ({
    id: resource.id,
    bodyText: prepareHostUrl(resource),
    text: generateResourceText(resource, formatMessage),
    value: resource.id,
    type: SelectionType.Multiple
});

export const generateFormattedResourceList = (resources, formatMessage): any[] => [
    generateDefaultResource(formatMessage),
    ...(resources || []).map((resource) => generateResource(resource, formatMessage))
];
