import styled from "styled-components";
import { ProfileUserInfo, UserAccountInfoBlock } from "@boomq/common";

export const ProfileInfoBlock = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    min-width: 100%;
    ${UserAccountInfoBlock} {
        max-width: 546px;
    }
    @media (max-width: 670px) {
        ${UserAccountInfoBlock} {
            min-width: 100%;
            width: 100%;
        }
    }
`;

export const StyledProfileUserInfo = styled(ProfileUserInfo)`
    margin-bottom: 20px;
`;
