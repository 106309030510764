const testProjectExceptions = {
    "Empty HTTP method value!": {
        defaultMessage: "Empty HTTP method value",
        id: "account.new.test.project.empty.method"
    },
    "Empty URL value!": {
        defaultMessage: "Empty URL value",
        id: "account.new.test.project.empty.url"
    },
    "Sum of all requests' percentage values must be 100%": {
        defaultMessage: "Sum of all request's percentage values must be 100%",
        id: "account.new.test.project.sum.percentage"
    }
};
export const getTestProjectErrorByDescription = (errorDescription) => errorDescription ? testProjectExceptions[errorDescription] : undefined;
