import { call, cancelled, put, select, take, takeEvery } from "redux-saga/effects";
import { SSE_MESSAGE_TIMEOUT_MS } from "./constants";
import { getTestProjectVersionData } from "../testProjects";
import { getGraphDataTestAnomalies, getGraphDataTransactionAnomalies, refreshTransactionList, setCurrentTransaction, setTestDataAnomalies, setTransactionDataAnomalies, setTransactionList, stopGraphDataTestSse, stopGraphDataTransactionSse, setUpdateChannelTest, setUpdateChannelTransaction } from "../../actions/testAnomalies";
import { getSelectedTeamData } from "../../reducers/authorizePlate";
import { getChannelTest } from "../../reducers/testAnomalies";
import { getChannelTransaction } from "../../reducers/testAnomalies";
import { subscribeToSse } from "../../../helpers/subscribeToSse";
import { formatTestDataAnomalies, formatTransactionDataAnomalies, mergeSseData } from "../../../helpers/testDetailsStats";
import { prepareTransactionListByTestType } from "../../../helpers/testDetailsTransactions";
import { SseEventType } from "../../../models/testRun";
import { getTestMetricDataSSEStreamUrl, getTransactionMetricSSEStreamUrl } from "../../../services/dataScienceSrv";
function* handleSseData({ sseUrl, formatData, setDataAction, updateChannelAction }) {
    const { teamToken } = yield select(getSelectedTeamData);
    const channel = yield call(subscribeToSse, teamToken, sseUrl);
    yield put(updateChannelAction(channel));
    let buffer = {};
    let lastUpdateTime = Date.now();
    try {
        while (true) {
            const event = yield take(channel);
            if (event.type === SseEventType.Messege) {
                buffer = mergeSseData(buffer, event.data);
            }
            if (Date.now() - lastUpdateTime >= SSE_MESSAGE_TIMEOUT_MS) {
                yield put(setDataAction(formatData(buffer)));
                lastUpdateTime = Date.now();
            }
            if (event.type === SseEventType.Close) {
                yield put(setDataAction(formatData(buffer)));
                break;
            }
        }
    }
    finally {
        if (yield cancelled()) {
            channel.close();
        }
    }
}
function* getGraphDataTestAnomaliesFlow(action) {
    const sseUrl = yield call(getTestMetricDataSSEStreamUrl, action.payload);
    yield* handleSseData({
        sseUrl,
        formatData: formatTestDataAnomalies,
        setDataAction: setTestDataAnomalies,
        updateChannelAction: setUpdateChannelTest
    });
}
function* getGraphDataTransactionAnomaliesFlow(action) {
    const sseUrl = yield call(getTransactionMetricSSEStreamUrl, action.payload);
    yield* handleSseData({
        sseUrl,
        formatData: formatTransactionDataAnomalies,
        setDataAction: setTransactionDataAnomalies,
        updateChannelAction: setUpdateChannelTransaction
    });
}
function* unSubscribe(channelSelector, updateChannelAction) {
    const channel = yield select(channelSelector);
    channel === null || channel === void 0 ? void 0 : channel.close();
    yield put(updateChannelAction(undefined));
}
function* unSubscribeTest() {
    yield* unSubscribe(getChannelTest, setUpdateChannelTest);
}
function* unSubscribeTransaction() {
    yield* unSubscribe(getChannelTransaction, setUpdateChannelTransaction);
}
function* refreshTransactionListFlow(action) {
    const { projectId, projectVersion } = action.payload;
    try {
        const testProjectVersionData = yield getTestProjectVersionData({
            id: projectId,
            version: projectVersion
        });
        const transactionList = prepareTransactionListByTestType(testProjectVersionData);
        yield put(setCurrentTransaction(transactionList[0]));
        yield put(setTransactionList(transactionList));
    }
    catch (e) {
        return;
    }
}
export function* testAnomalyFlow() {
    yield takeEvery(getGraphDataTestAnomalies, getGraphDataTestAnomaliesFlow);
    yield takeEvery(getGraphDataTransactionAnomalies, getGraphDataTransactionAnomaliesFlow);
    yield takeEvery(refreshTransactionList, refreshTransactionListFlow);
    yield takeEvery(stopGraphDataTestSse, unSubscribeTest);
    yield takeEvery(stopGraphDataTransactionSse, unSubscribeTransaction);
}
