var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { getValueByPath, isEmpty } from "@boomq/utils";
import { validateThreadGroupElement } from "./threadGroupElements";
const addPathsBeforeError = (elPathsBeforeError, element) => {
    const pathIndexArr = getValueByPath(element, "path")
        .split("-")
        .filter((el, index, arr) => arr.length - 1 !== index);
    const { pathBeforeErrArr } = pathIndexArr.reduce((res, indexEl) => {
        const tempIndexArr = [...res.tempIndexArr, indexEl];
        return {
            tempIndexArr,
            pathBeforeErrArr: Object.assign(Object.assign({}, res.pathBeforeErrArr), { [tempIndexArr.join("-")]: "" })
        };
    }, { tempIndexArr: [], pathBeforeErrArr: {} });
    return Object.assign(Object.assign({}, elPathsBeforeError), pathBeforeErrArr);
};
export const threadGroupElementsValidator = (elementsData) => {
    const checkFirstElementWithErrorData = (res, elementId) => isEmpty(res.firstElementIdWithError) || res.firstElementPathWithError > elementsData[elementId].path
        ? {
            firstElementIdWithError: elementId,
            firstElementPathWithError: elementsData[elementId].path
        }
        : res;
    const _a = Object.keys(elementsData).reduce((res, elementId) => {
        const { isValid, error } = validateThreadGroupElement(elementsData[elementId]);
        delete elementsData[elementId].error;
        return isValid
            ? Object.assign(Object.assign({}, res), { validatedElementsData: Object.assign(Object.assign({}, res.validatedElementsData), { [elementId]: elementsData[elementId] }) }) : Object.assign(Object.assign({}, checkFirstElementWithErrorData(res, elementId)), { isValid: false, validatedElementsData: Object.assign(Object.assign({}, res.validatedElementsData), { [elementId]: Object.assign(Object.assign({}, elementsData[elementId]), { error }) }), elPathsBeforeError: addPathsBeforeError(res.elPathsBeforeError, elementsData[elementId]) });
    }, {
        isValid: true,
        firstElementIdWithError: "",
        firstElementPathWithError: "",
        validatedElementsData: {},
        elPathsBeforeError: {}
    }), { elPathsBeforeError } = _a, restParams = __rest(_a, ["elPathsBeforeError"]);
    return Object.assign(Object.assign({}, restParams), { elPathsBeforeError: Object.keys(elPathsBeforeError) });
};
