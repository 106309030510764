import styled from "styled-components";
import { Block } from "@boomq/uikit";
import { TeamMembersItemInputsBlockStyles } from "../TeamMembersItemTemplateEdit/styled";

export const TeamMembersItemAdd = styled.div`
    ${Block}
    ${TeamMembersItemInputsBlockStyles}
    margin-left: 12px;
    margin-right: 12px;
    padding: 24px;
    width: auto;
`;
