import styled from "styled-components";
import { TitleText } from "../../../common/TableHeader/styled";
import { media } from "../../../styled/response";
export const PaginationWrapper = styled.div `
    flex: 1 0 auto;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding-bottom: 24px;
`;
export const StyledPage = styled.div `
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 32px;
    ${TitleText}:last-child {
        max-width: 226px;
        min-width: 226px;
    }
    ${media.tablet} {
        padding: 0 12px;
    }
`;
export const TestList = styled.ul `
    flex: 0 0 auto;
    padding: 12px 0px 16px;
`;
