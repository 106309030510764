import { handleActions } from "redux-actions";
import authorizeActions from "../../actions/authorize";
import { addReportPflbChart, addReportPflbChartSerie, addReportPflbTable, changeReportPflbChartProperties, changeReportPflbChartSerie, changeReportPflbTable, changeReportPflbTableParams, clearReportData, deleteReportPflbChart, deleteReportPflbChartSerie, deleteReportPflbTable, generateReportPflbChartSeriePreviewSuccess, refreshReportDataSuccess, setReportParams } from "../../actions/report";
const { logout } = authorizeActions;
export const DEFAULT_TEAM_MEMBERS_PAGE_SIZE = 6;
export const initialState = {
    id: null,
    blocks: [],
    charts: [],
    lastSelectedChartType: null,
    lastSelectedTestId: null,
    reportName: "Report",
    series: [],
    tables: [],
    testList: [],
    threadGroupListsByTestId: {},
    transactionListsByTestId: {}
};
export default handleActions({
    [addReportPflbChart]: (state, { payload }) => (Object.assign(Object.assign({}, state), { charts: state.charts.find(({ id }) => id === payload) ? state.charts : [...state.charts, { id: payload }] })),
    [addReportPflbTable]: (state, { payload }) => (Object.assign(Object.assign({}, state), { lastSelectedTestId: payload.testId || initialState.lastSelectedTestId, tables: [...state.tables, payload] })),
    [addReportPflbChartSerie]: (state, { payload }) => (Object.assign(Object.assign({}, state), { series: [
            ...state.series,
            Object.assign(Object.assign({}, payload), { testId: state.lastSelectedTestId, type: state.lastSelectedChartType })
        ] })),
    [changeReportPflbChartSerie]: (state, { payload }) => (Object.assign(Object.assign({}, state), { series: state.series.map((serieData) => serieData.id === payload.id ? Object.assign(Object.assign({}, serieData), payload) : serieData) })),
    [changeReportPflbChartProperties]: (state, { payload }) => (Object.assign(Object.assign({}, state), { charts: state.charts.map((chartData) => chartData.id === payload.id && payload.properties ? Object.assign(Object.assign({}, chartData), payload.properties) : chartData) })),
    [changeReportPflbTable]: (state, { payload }) => (Object.assign(Object.assign({}, state), { lastSelectedTestId: payload.testId || initialState.lastSelectedTestId, tables: state.tables.map((tableData) => tableData.id === payload.id ? Object.assign(Object.assign({}, tableData), payload) : tableData) })),
    [changeReportPflbTableParams]: (state, { payload }) => (Object.assign(Object.assign({}, state), { tables: state.tables.map((tableData) => tableData.id === payload.id ? Object.assign(Object.assign({}, tableData), payload) : tableData) })),
    [clearReportData]: () => initialState,
    [deleteReportPflbChart]: (state, { payload }) => (Object.assign(Object.assign({}, state), { charts: state.charts.filter(({ id }) => id !== payload), series: state.series.filter(({ chartId }) => chartId !== payload) })),
    [deleteReportPflbChartSerie]: (state, { payload }) => (Object.assign(Object.assign({}, state), { series: state.series.filter(({ id }) => id !== payload) })),
    [deleteReportPflbTable]: (state, { payload }) => (Object.assign(Object.assign({}, state), { tables: state.tables.filter((tableData) => tableData.id !== payload) })),
    [logout]: () => (Object.assign({}, initialState)),
    [generateReportPflbChartSeriePreviewSuccess]: (state, { payload }) => (Object.assign(Object.assign({}, state), { lastSelectedChartType: payload.type || initialState.lastSelectedChartType, lastSelectedTestId: payload.testId || initialState.lastSelectedTestId, series: state.series.map((serieData) => serieData.id === payload.id ? Object.assign(Object.assign({}, serieData), payload) : serieData) })),
    [refreshReportDataSuccess]: (state, { payload }) => (Object.assign(Object.assign({}, state), payload)),
    [setReportParams]: (state, { payload }) => (Object.assign(Object.assign({}, state), payload))
}, initialState);
