import { AnomalyClassification } from "./enums";
export const anomalyClassifications = [
    {
        anomalyClassification: AnomalyClassification.ResponseTimeAlarm,
        anomalyDescription: {
            defaultMessage: "Response Time deviation",
            id: "account.chart.anomaly.response.deviation"
        },
        anomalyColor: "#f6331b"
    },
    {
        anomalyClassification: AnomalyClassification.VUResponseTimeWarning,
        anomalyDescription: {
            defaultMessage: "VUser and Response Time inverse correlation",
            id: "account.chart.anomaly.response.inverse"
        },
        anomalyColor: "#ffc306"
    }
];
export const getAnomalyDetails = (classification) => {
    const anomaly = anomalyClassifications.find((item) => item.anomalyClassification === classification);
    return anomaly ? { anomalyDescription: anomaly.anomalyDescription, anomalyColor: anomaly.anomalyColor } : {};
};
export const getAnomalyTransactionDetailsData = (data) => {
    const detailsTransactions = anomalyClassifications.reduce((acc, item) => {
        if (data.some((elem) => elem.classification === item.anomalyClassification)) {
            return [...acc, item.anomalyDescription];
        }
        return acc;
    }, []);
    return detailsTransactions.length > 0 ? detailsTransactions : null;
};
