import styled from "styled-components";
import { AddingSection } from "../../styled/Buttons";
import { media } from "../../styled/response";
export const NewSlaReportProfile = styled(AddingSection) `
    margin: 14px 5px 0;
    min-height: 50px;
`;
export const PaginationWrapper = styled.div `
    flex: 1 0 auto;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding-bottom: 24px;
`;
export const StyledPage = styled.div `
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 34px;
    ${media.tablet} {
        padding: 0 12px;
    }
`;
export const SlaReportProfileList = styled.ul `
    flex: 0 0 auto;
    padding: 14px 5px 17px;
`;
