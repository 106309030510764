import { LIST_ITEM_DEFAULT_TYPE } from "./constants";
import { MenuListItemDropdown } from "./Dropdown";
import { MenuListItemSingle } from "./Single";
import { MenuItemType } from "../../../helpers/types";
const classNameByType = {
    [MenuItemType.Custom]: "pflb-menu-item pflb-menu-item-custom",
    [MenuItemType.Dropdown]: "pflb-menu-item pflb-menu-item-dropdown-list",
    [MenuItemType.Single]: "pflb-menu-item"
};
const listItemTemplateByType = {
    [MenuItemType.Dropdown]: MenuListItemDropdown,
    [MenuItemType.Single]: MenuListItemSingle
};
export const getClassNameByType = ({ type }) => type && type in classNameByType ? classNameByType[type] : classNameByType[MenuItemType.Single];
const getListItemTemplateKeyByType = (type) => type && Object.keys(listItemTemplateByType).includes(type) ? type : LIST_ITEM_DEFAULT_TYPE;
export const getListItemTemplateByType = ({ template, type }) => type === MenuItemType.Custom ? template : listItemTemplateByType[getListItemTemplateKeyByType(type)];
