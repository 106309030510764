import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AllSlaReportProfilesItem } from "../../../components/account/AllSlaReportProfilesItem";
import { deleteSlaReportProfileRequest, editSlaReportProfile } from "../../../redux/actions/slaReportProfile";
import { getClientPermissionList } from "../../../redux/reducers/authorizePlate";
import { getActionItemsByTeamMemberPermissions } from "../../../helpers/slaReportProfiles";
import { SlaReportProfilesItemAction } from "../../../helpers/types";
const connector = connect((state) => ({
    permissionList: getClientPermissionList(state)
}), {
    [SlaReportProfilesItemAction.Delete]: deleteSlaReportProfileRequest,
    [SlaReportProfilesItemAction.Edit]: editSlaReportProfile
});
const WrappedAllSlaReportProfilesItem = (props) => {
    const actionItems = getActionItemsByTeamMemberPermissions(props.permissionList);
    return React.createElement(AllSlaReportProfilesItem, Object.assign({ actionItems: actionItems }, props));
};
export default withRouter(connector(WrappedAllSlaReportProfilesItem));
