import React from "react";
import { withRouter } from "react-router-dom";
import { isEmpty } from "@boomq/utils";
import { Spoiler, Uploader } from "@boomq/uikit";
import { FILE_CSV_EXTENSIONS } from "./constants";
import { UPLOADER_EMPTY_TEXT } from "./intl";
import { Content, CsvParameter } from "./styled";
import { withResize } from "../../styled/response";
import { ParameterCsvHeader } from "../ParameterCsvHeader";
import { ParameterCsvSettings } from "../ParameterCsvSettings";
import { isExistCsvFiles } from "../../../helpers/requestParameters";
const ParameterCsv = ({ delimiter, fileContent, fileLocation, files = [], id, hasHeaders, independentListPerThread, isOpened, isSavedCsvFile, onChangeCsvHeader, onChangeFile, onChangeParam, onClickDownloadCsvFile, parameterNames, randomOrder, recycle, usedRequestParameterNames }) => {
    const changeHandler = (name, value) => onChangeParam({ id, name, value });
    const changeFileHandler = (files) => onChangeFile({ id, delimiter, files, hasHeaders, independentListPerThread, isOpened, randomOrder, recycle });
    const clickDownloadCsvFileLinkHandler = () => typeof onClickDownloadCsvFile === "function" ? onClickDownloadCsvFile(fileLocation) : undefined;
    const deleteHandler = () => changeHandler("files", []);
    const toggleSpoilerHandler = (val) => changeHandler("isOpened", val);
    return (React.createElement(CsvParameter, { isOpened: isOpened, isExistFiles: isExistCsvFiles(files) },
        React.createElement(Spoiler, { headerTemplate: React.createElement(ParameterCsvHeader, { files: files, isOpened: isOpened, isSavedCsvFile: isSavedCsvFile, onClickDownloadCsvFileLink: clickDownloadCsvFileLinkHandler, onDelete: deleteHandler, parameterNames: parameterNames }), isOpened: isOpened, onlyArrowClick: true, onToggle: toggleSpoilerHandler },
            React.createElement(Content, null, isEmpty(files) ? (React.createElement(Uploader, { emptyText: UPLOADER_EMPTY_TEXT, extensions: FILE_CSV_EXTENSIONS, isSingleFile: true, items: files, name: "files", onChangeFiles: changeFileHandler })) : (React.createElement(ParameterCsvSettings, { delimiter: delimiter, fileContent: fileContent, hasHeaders: hasHeaders, independentListPerThread: independentListPerThread, id: id, onChangeCsvHeader: onChangeCsvHeader, onChangeParam: onChangeParam, parameterNames: parameterNames, randomOrder: randomOrder, recycle: recycle, usedRequestParameterNames: usedRequestParameterNames }))))));
};
export default withResize(withRouter(ParameterCsv));
