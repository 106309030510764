import React from "react";
import { connect } from "react-redux";
import { useIntl } from "@boomq/utils";
import {
    AuthBlockTitle,
    AuthPlateWrapper,
    AuthSectionTitle,
    ErrorPlate,
    StyledButton,
    apiErrors,
    formatIntlMessage,
    getErrorCode
} from "@boomq/common";
import { CHANGE_PASSWORD_BUTTON_LABEL, CHANGE_PASSWORD_TITLE } from "./intl";
import { StyledAuthBlock, StyledChangePasswordForm, StyledAuthButtonBlock } from "./styled";

const getErrorDesc = (state) => {
    const errorCode = getErrorCode(state);

    return errorCode ? apiErrors(errorCode) : "";
};

const connector = connect((state) => ({
    errorDesc: getErrorDesc(state)
}));

const ChangePasswordPlateContainer = ({ errorDesc, ...restProps }) => {
    const { formatMessage } = useIntl();

    const apiError = errorDesc ? formatMessage(errorDesc) : "";

    const changePassButtonLabel = formatIntlMessage(CHANGE_PASSWORD_BUTTON_LABEL, formatMessage);
    const title = formatIntlMessage(CHANGE_PASSWORD_TITLE, formatMessage);

    return (
        <AuthPlateWrapper>
            <AuthBlockTitle>
                <AuthSectionTitle>{title}</AuthSectionTitle>
            </AuthBlockTitle>

            <StyledAuthBlock type={restProps.type}>
                <StyledChangePasswordForm {...restProps}>
                    <StyledAuthButtonBlock>
                        <StyledButton type="submit">{changePassButtonLabel}</StyledButton>
                    </StyledAuthButtonBlock>
                </StyledChangePasswordForm>
            </StyledAuthBlock>

            {apiError && <ErrorPlate>{apiError}</ErrorPlate>}
        </AuthPlateWrapper>
    );
};

export default connector(ChangePasswordPlateContainer);
