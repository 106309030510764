import React from "react";
import { connect } from "react-redux";
import { useIntl } from "@boomq/utils";
import { EditableTitle } from "../../../../components/common/EditableTitle";
import { titleTheme } from "../../../../components/styled/Common";
import { formatIntlMessage } from "../../../../helpers/intl";
import { nameValidator } from "../../../../helpers/validators";
import { setGroupName } from "../../../../redux/actions/editor";
import { getEditorGroupName } from "../../../../redux/reducers/editor";
const connector = connect((state) => ({
    groupName: getEditorGroupName(state)
}), { onChangeGroupName: setGroupName });
const ThreadGroupNameContainer = ({ groupName, onChangeGroupName }) => {
    const { formatMessage } = useIntl();
    const changeThreadGroupName = (value) => onChangeGroupName(value);
    const validateFunc = (value) => {
        const { isValid, error } = nameValidator(value);
        return { isValid, error: formatIntlMessage(error, formatMessage) };
    };
    return (React.createElement(EditableTitle, { editModeTheme: titleTheme, onChange: changeThreadGroupName, theme: titleTheme, value: groupName, validateFunc: validateFunc }));
};
export default connector(ThreadGroupNameContainer);
