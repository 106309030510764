import { put, takeEvery } from "redux-saga/effects";
import { closeSiteModal, getSiteModal } from "../../actions/siteModal";
import { changeDefaultCookies, changeDefaultHeaders, showTestProjectDefaultSettingsForm } from "../../actions/testProjectDefaultSettings";
import { getTestProjectDefaultSettingsFormDataByType } from "../../../models/testProjectDefaultSettings";
function* closeSiteModalFlow() {
    yield put(closeSiteModal());
}
function* showTestProjectDefaultSettingsFormFlow({ payload }) {
    try {
        yield put(getSiteModal(getTestProjectDefaultSettingsFormDataByType(payload.value)));
    }
    catch (e) {
        return;
    }
}
export function* testProjectDefaultSettingsFlow() {
    yield takeEvery(changeDefaultCookies, closeSiteModalFlow);
    yield takeEvery(changeDefaultHeaders, closeSiteModalFlow);
    yield takeEvery(showTestProjectDefaultSettingsForm, showTestProjectDefaultSettingsFormFlow);
}
