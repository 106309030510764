export enum SlaReportProfileAction {
    Add = "onAdd",
    Save = "onSave"
}

export enum SlaReportProfilesItemAction {
    Add = "onAdd",
    Delete = "onDelete",
    Edit = "onEdit"
}
