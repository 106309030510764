export enum TeamApiTypeName {
    Create = "CREATE",
    Get = "GET",
    Update = "UPDATE"
}

export enum TeamMemberMode {
    Add = "ADD",
    Edit = "EDIT",
    View = "VIEW"
}

export enum TeamMemberPermission {
    Admin = "ADMIN",
    Delete = "DELETE",
    Edit = "EDIT",
    ManageUsers = "MANAGE_USERS",
    OnlyOrganisation = "MANAGE_USERS_IN_ORG",
    Run = "RUN",
    ShareViewOnlyReports = "SHARE_VIEW_ONLY_REPORTS",
    View = "VIEW"
}
