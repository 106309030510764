import { TeamMemberPermission } from "../../helpers/types";
export var ReportAction;
(function (ReportAction) {
    ReportAction["Add"] = "onAdd";
    ReportAction["Edit"] = "onEdit";
    ReportAction["Save"] = "onSave";
})(ReportAction || (ReportAction = {}));
const actionsByTeamMemberPermissionEdit = [ReportAction.Add, ReportAction.Edit, ReportAction.Save];
const actionsByTeamMemberPermissions = {
    [TeamMemberPermission.Admin]: actionsByTeamMemberPermissionEdit,
    [TeamMemberPermission.Edit]: actionsByTeamMemberPermissionEdit,
    [TeamMemberPermission.Run]: actionsByTeamMemberPermissionEdit
};
const getTestProjectActionsByTeamMemberPermission = (permission) => {
    const actionsByPermission = actionsByTeamMemberPermissions[permission];
    return actionsByPermission ? actionsByPermission : [];
};
export const getReportActionsByTeamMemberPermissions = (permissions) => [
    ...new Set(permissions.reduce((res, permission) => [
        ...res,
        ...getTestProjectActionsByTeamMemberPermission(permission)
    ], []))
];
