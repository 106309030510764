import styled from "styled-components";
export const TooltipBody = styled.div `
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 10px;
    white-space: nowrap;
`;
export const TooltipItem = styled.li `
    align-items: center;
    color: ${({ color }) => (color ? color : "#000")}
    display: flex;
    padding-top: 4px;
    padding-bottom: 4px;
`;
