import { createActions } from "redux-actions";
const { authRequest, authSuccess, authFailure, checkAuthorization, generateSecretTokenRequest, heapTrack, showAuthModal, linkAccountFailure, linkAccountRequest, linkAccountSuccess, logout, logoutWithCheckingOfUnsavedTestProject, redirectToUnsavedTestProject, saveToken, secretTokenFailure, secretTokenRequest, secretTokenSuccess, setActiveAction, setActiveUrl, setAuthProviders, updateAuthProvidersRequest } = createActions("AUTH_REQUEST", "AUTH_SUCCESS", "AUTH_FAILURE", "CHECK_AUTHORIZATION", "GENERATE_SECRET_TOKEN_REQUEST", "HEAP_TRACK", "SHOW_AUTH_MODAL", "LINK_ACCOUNT_FAILURE", "LINK_ACCOUNT_REQUEST", "LINK_ACCOUNT_SUCCESS", "LOGOUT", "LOGOUT_WITH_CHECKING_OF_UNSAVED_TEST_PROJECT", "REDIRECT_TO_UNSAVED_TEST_PROJECT", "SAVE_TOKEN", "SECRET_TOKEN_FAILURE", "SECRET_TOKEN_REQUEST", "SECRET_TOKEN_SUCCESS", "SET_ACTIVE_ACTION", "SET_ACTIVE_URL", "SET_AUTH_PROVIDERS", "UPDATE_AUTH_PROVIDERS_REQUEST");
export default {
    authRequest,
    authSuccess,
    authFailure,
    checkAuthorization,
    generateSecretTokenRequest,
    heapTrack,
    showAuthModal,
    linkAccountFailure,
    linkAccountRequest,
    linkAccountSuccess,
    logout,
    logoutWithCheckingOfUnsavedTestProject,
    redirectToUnsavedTestProject,
    saveToken,
    secretTokenFailure,
    secretTokenRequest,
    secretTokenSuccess,
    setActiveAction,
    setActiveUrl,
    setAuthProviders,
    updateAuthProvidersRequest
};
