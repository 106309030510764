import { getUniqueId, isEmpty } from "@boomq/utils";
import { prepareSlaListItem } from "../generators";
import { SLA_HOST_NAME_PARAM_NAME, SLA_TARGET_TYPE_PARAM_NAME, SLA_REQUEST_NAME_PARAM_NAME, SLA_TRANSACTION_NAME_PARAM_NAME, SLA_TRANSACTION_FILTER_TYPE_PARAM_NAME, SLA_TYPE_PARAM_NAME, SLA_THREAD_GROUP_ID_PARAM_NAME, SLA_THREAD_GROUP_NAME_PARAM_NAME, SLA_TRANSACTION_ID_PARAM_NAME } from "../sla/constant";
import { getSlaHostName, getSlaRequestName, getSlaTargetType, getSlaThreadGroupId, getSlaThreadGroupName, getSlaTransactionFilterType, getSlaTransactionId, getSlaTransactionName, getSlaType } from "../sla/slaParams";
import { getSlaTargetTypes, SlaTargetType, SlaThreadGroupFilterType, SlaTransactionFilterType } from "../../models/sla";
const getSlaListItemResult = (slaParams) => (isEmpty(slaParams.result) ? {} : { result: slaParams.result });
const getTransactionNameByFilterType = (slaGroup) => getSlaTransactionFilterType(slaGroup) === SlaTransactionFilterType.Name
    ? getSlaTransactionName(slaGroup)
    : getSlaTransactionFilterType(slaGroup);
const getTransactionNameByType = (slaGroup) => getSlaTargetType(slaGroup) === SlaTargetType.Request
    ? getTransactionNameByFilterType(slaGroup)
    : getSlaTransactionName(slaGroup);
const prepareSlaThreadGroupData = (slaGroup) => getSlaThreadGroupId(slaGroup) === null
    ? {
        [SLA_THREAD_GROUP_ID_PARAM_NAME]: SlaThreadGroupFilterType.AnyThreadGroup,
        [SLA_THREAD_GROUP_NAME_PARAM_NAME]: SlaThreadGroupFilterType.AnyThreadGroup
    }
    : {
        [SLA_THREAD_GROUP_ID_PARAM_NAME]: getSlaThreadGroupId(slaGroup),
        [SLA_THREAD_GROUP_NAME_PARAM_NAME]: getSlaThreadGroupName(slaGroup)
    };
const prepareSlaTransactionFilterType = (slaGroup) => getSlaTransactionFilterType(slaGroup) || SlaTransactionFilterType.NoTransaction;
const prepareSlaItem = (slaGroup) => slaGroup
    ? (slaGroup.slaList || []).reduce((res, slaItem) => [
        ...res,
        Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({ id: getUniqueId() }, prepareSlaListItem(slaItem)), getSlaListItemResult(slaItem)), { isValid: true }), prepareSlaThreadGroupData(slaGroup)), { [SLA_HOST_NAME_PARAM_NAME]: getSlaHostName(slaGroup), [SLA_REQUEST_NAME_PARAM_NAME]: getSlaRequestName(slaGroup), [SLA_TRANSACTION_ID_PARAM_NAME]: getSlaTransactionId(slaGroup), [SLA_TARGET_TYPE_PARAM_NAME]: getSlaTargetType(slaGroup), [SLA_TRANSACTION_NAME_PARAM_NAME]: getTransactionNameByType(slaGroup) || "", [SLA_TRANSACTION_FILTER_TYPE_PARAM_NAME]: prepareSlaTransactionFilterType(slaGroup), [SLA_TYPE_PARAM_NAME]: getSlaType(slaGroup) })
    ], [])
    : [];
const getSlaGroupTargetType = (group) => group && getSlaTargetType(group) ? getSlaTargetType(group) : undefined;
export const prepareSlaGroupList = (slaGroups) => {
    const slaTargetTypes = getSlaTargetTypes();
    return (slaGroups || []).reduce((res, slaGroup) => {
        const slaTargetType = getSlaGroupTargetType(slaGroup);
        return slaTargetType && slaTargetTypes.includes(slaTargetType)
            ? Object.assign(Object.assign({}, res), { [slaTargetType]: [...res[slaTargetType], ...prepareSlaItem(slaGroup)] }) : res;
    }, slaTargetTypes.reduce((res, targetType) => (Object.assign(Object.assign({}, res), { [targetType]: [] })), {}));
};
