var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getUnique, isEmpty, useIntl } from "@boomq/utils";
import { ALL_TEST_RUNS_TITLE, NEW_TEST_BUTTON_LABEL, PAGE_TITLE, RUNNING_TESTS_TITLE } from "./intl";
import { AllTests } from "../../../../components/pages/account/AllTests";
import { formatIntlMessage } from "../../../../helpers/intl";
import { getSiteTitle } from "../../../../helpers/title";
import { setTitle } from "../../../../redux/actions/accountTitle";
import clientActions from "../../../../redux/actions/client";
import { stopTestRequest } from "../../../../redux/actions/runningTests";
import { compareTests, initClientTests, toggleTest } from "../../../../redux/actions/tests";
import { getClientPermissionList, getIsAuthorize, getSelectedTeamId } from "../../../../redux/reducers/authorizePlate";
import { getClientTasksCurrentPage, getClientTasksFilters, getClientTasksFromStore, getClientTasksLabelList, getClientTasksPagesCount, getClientTasksUserList, getSelectedClientTestIds } from "../../../../redux/reducers/clientTasks";
import { getClientTestProjectList } from "../../../../redux/reducers/clientTestProjectList";
import { getRunningTests } from "../../../../redux/reducers/runningTests";
const { changeClientTasksFiltersRequest, clientTasksRequest, getTestRunsDataWithSocketRequestStop, setCurrentPage } = clientActions;
const connector = connect((state) => ({
    clientTests: getClientTasksFromStore(state),
    clientTestsPagesCount: getClientTasksPagesCount(state),
    clientTestLabels: getClientTasksLabelList(state),
    clientTestProjectList: getClientTestProjectList(state),
    clientTestUserList: getClientTasksUserList(state),
    currentPage: getClientTasksCurrentPage(state),
    filters: getClientTasksFilters(state),
    isAuthorized: getIsAuthorize(state),
    permissionList: getClientPermissionList(state),
    runningTests: getRunningTests(state),
    selectedTeamId: getSelectedTeamId(state),
    selectedTestIds: getSelectedClientTestIds(state)
}), {
    setTitle,
    onAuthorized: initClientTests,
    onChangeFilter: changeClientTasksFiltersRequest,
    onChangePage: setCurrentPage,
    onClickStopTest: stopTestRequest,
    onCompareTests: compareTests,
    onRefreshTestList: clientTasksRequest,
    onToggleTest: toggleTest,
    onUnmount: getTestRunsDataWithSocketRequestStop
});
const AllTestsContainer = (_a) => {
    var { isAuthorized, onAuthorized, onRefreshTestList, onUnmount, selectedTeamId, setTitle } = _a, restProps = __rest(_a, ["isAuthorized", "onAuthorized", "onRefreshTestList", "onUnmount", "selectedTeamId", "setTitle"]);
    const { formatMessage } = useIntl();
    useEffect(() => {
        const pageTitle = formatIntlMessage(PAGE_TITLE, formatMessage);
        setTitle("account.header.title.all");
        document.title = getSiteTitle(pageTitle);
        const checkAuthorization = () => (isAuthorized && !isEmpty(selectedTeamId) ? onAuthorized() : undefined);
        checkAuthorization();
    }, [isAuthorized, selectedTeamId]);
    useEffect(() => {
        return () => onUnmount === null || onUnmount === void 0 ? void 0 : onUnmount();
    }, []);
    const changePageHandler = (page) => onRefreshTestList({ page });
    const statuses = getUnique((restProps.clientTests || []).map(({ displayState }) => displayState));
    const allTestRunsTitle = formatIntlMessage(ALL_TEST_RUNS_TITLE, formatMessage);
    const newTestButtonLabel = formatIntlMessage(NEW_TEST_BUTTON_LABEL, formatMessage);
    const runningTestsTitle = formatIntlMessage(RUNNING_TESTS_TITLE, formatMessage);
    return (React.createElement(AllTests, Object.assign({}, restProps, { allTestRunsTitle: allTestRunsTitle, newTestButtonLabel: newTestButtonLabel, onChangePage: changePageHandler, runningTestsTitle: runningTestsTitle, statuses: statuses })));
};
export default withRouter(connector(AllTestsContainer));
