import { select, put, takeEvery } from "redux-saga/effects";
import { chartPreviewGenerator } from "./testProjectChart";
import newTestActions from "../actions/newTest";
import { getSiteModal } from "../actions/siteModal";
import { getAutoPercentages, getRequestGroups } from "../reducers/newTest";
import AutoPercentagesConfirmForm from "../../components/account/TestProject/AutoPercentagesConfirmForm";
import { generateGroupsPercentages } from "../../helpers/generators/generators";
const { changeAutoPercentages, changeRequestGroups, testProjectChangeParamsField, testProjectChangeLoadType } = newTestActions;
export function* changeRequestGroupsByAutoPercentages(requestGroups) {
    const autoPercentages = yield select(getAutoPercentages);
    const modifiedRequestGroups = autoPercentages ? generateGroupsPercentages(requestGroups) : requestGroups;
    yield put(changeRequestGroups(modifiedRequestGroups));
}
function* changeAutoPercentagesValue(value, requestGroups) {
    yield put(testProjectChangeParamsField({ field: "autoPercentages", value, isModified: true }));
    return yield* changeRequestGroupsByAutoPercentages(requestGroups);
}
function* checkAutoPercentagesConfirmation({ groupPercentagesSum, isAccepted, value }) {
    return groupPercentagesSum > 0 && !isAccepted && value
        ? yield put(getSiteModal({
            component: {
                element: AutoPercentagesConfirmForm,
                props: {}
            },
            title: {
                defaultMessage: "Confirm user distribution",
                id: "account.modal.new.test.project.auto.percentages.title"
            }
        }))
        : undefined;
}
function* changeAutoPercentagesFlow(action) {
    const { isAccepted, value } = action && action.payload;
    const requestGroups = yield select(getRequestGroups);
    const groupPercentagesSum = requestGroups.reduce((sum, group) => sum + Number(group.perc), 0);
    yield* checkAutoPercentagesConfirmation({ groupPercentagesSum, isAccepted, value });
    return (value && isAccepted) || !value || groupPercentagesSum === 0
        ? yield* changeAutoPercentagesValue(value, requestGroups)
        : undefined;
}
export function* testProjectPercentDistributionFlow() {
    yield takeEvery(changeAutoPercentages, changeAutoPercentagesFlow);
    yield takeEvery(testProjectChangeLoadType, chartPreviewGenerator);
}
