var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { copyElementDataToClipboard } from "../copyElementDataToClipboard";
import { tempElementStyles } from "./styled";
const writeTextToNavigatorClipboard = (text) => { var _a, _b, _c; return (_c = (_b = (_a = window === null || window === void 0 ? void 0 : window.navigator) === null || _a === void 0 ? void 0 : _a.clipboard) === null || _b === void 0 ? void 0 : _b.writeText) === null || _c === void 0 ? void 0 : _c.call(_b, text); };
const copyTextToClipboardByTempElementCreation = (text) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerText = text;
    Object.assign(tempDiv.style, tempElementStyles);
    document.body.appendChild(tempDiv);
    copyElementDataToClipboard(tempDiv);
    document.body.removeChild(tempDiv);
};
export const copyTextToClipboard = (text) => __awaiter(void 0, void 0, void 0, function* () { var _a; return ((_a = window === null || window === void 0 ? void 0 : window.navigator) === null || _a === void 0 ? void 0 : _a.clipboard) ? writeTextToNavigatorClipboard(text) : copyTextToClipboardByTempElementCreation(text); });
