import React from "react";
import { connect } from "react-redux";
import { useIntl } from "@boomq/utils";
import { DEFAULT_TIMER_LABEL, REQUESTS_TIMER_OFF, TIMER_LABEL, TIMER_NONE_LABEL } from "./intl";
import { TimersCount, hintTheme, hintTooltipTheme } from "./styled";
import { Hint } from "../../../common/Hint";
import { RequestTimerParams, RequestTimerType } from "../../../../helpers/types";
import timer from "../../../../assets/images/icons/timer.svg";
import { getDefaultTimerSettings } from "../../../../redux/reducers/newTest";
import { getEnabledTimerList, getTimerListWithDefaultTimer } from "../../../../helpers/requestTimers";
import { getTimerType } from "../../../../models/requestTimer";
const connector = connect((state) => ({
    defaultTimer: getDefaultTimerSettings(state)
}));
const getTimerText = (constantTimer, randomTimer) => constantTimer ? `${constantTimer.duration} sec` : `${randomTimer.lowerLimit}...${randomTimer.upperLimit} sec`;
const getTimerTextByType = (timerList) => {
    const enabledTimerList = getEnabledTimerList(timerList);
    const constantTimer = enabledTimerList.find((timer) => getTimerType(timer) === RequestTimerType.Constant);
    const randomTimer = enabledTimerList.find((timer) => getTimerType(timer) === RequestTimerType.Random);
    return constantTimer || randomTimer ? getTimerText(constantTimer, randomTimer) : "";
};
const getTimerValueLabel = (timer, value) => (timer.enabled && !isNaN(value) ? value : 0);
const RequestManualTimerIcon = ({ defaultTimer, timerList }) => {
    const { formatMessage } = useIntl();
    const timerListWithDefaultTimer = getTimerListWithDefaultTimer(timerList, defaultTimer) || [];
    const timersCount = timerListWithDefaultTimer.length;
    const getIsTimerOnLabel = (timer) => (timer.enabled ? "" : formatMessage(REQUESTS_TIMER_OFF));
    const getTimerLabel = (timer, index) => timer.isDefaultTimer ? formatMessage(DEFAULT_TIMER_LABEL) : `${formatMessage(TIMER_LABEL)} ${index}`;
    const getTimerTooltipText = (index, timer) => {
        switch (getTimerType(timer)) {
            case RequestTimerType.Constant: {
                return {
                    defaultMessage: "{label}: Constant timer, {duration} sec {isTimerOn}",
                    id: `account.new.test.project.group.requests.timer.${RequestTimerType.Constant}`,
                    values: {
                        duration: getTimerValueLabel(timer, timer.duration),
                        label: getTimerLabel(timer, index),
                        isTimerOn: getIsTimerOnLabel(timer)
                    }
                };
            }
            case RequestTimerType.Random: {
                return {
                    defaultMessage: "{label}: Random timer, {value} sec {isTimerOn}",
                    id: `account.new.test.project.group.requests.timer.${RequestTimerType.Random}`,
                    values: {
                        [RequestTimerParams.Type]: timer.randomTimerType,
                        isTimerOn: getIsTimerOnLabel(timer),
                        label: getTimerLabel(timer, index),
                        value: `${getTimerValueLabel(timer, timer.lowerLimit)}...${getTimerValueLabel(timer, timer.upperLimit)}`
                    }
                };
            }
            case RequestTimerType.Other: {
                return {
                    defaultMessage: "{label}: {type} {isTimerOn}",
                    id: `account.new.test.project.group.requests.timer.${timer.otherTimerType}`,
                    values: {
                        type: timer.otherTimerType,
                        isTimerOn: getIsTimerOnLabel(timer),
                        label: getTimerLabel(timer, index)
                    }
                };
            }
            default: {
                return Object.assign(Object.assign({}, TIMER_NONE_LABEL), { values: {
                        isTimerOn: getIsTimerOnLabel(timer),
                        label: getTimerLabel(timer, index)
                    } });
            }
        }
    };
    const getTimersTooltipText = (timerList) => timerList.map((timer, index) => (Object.assign({ key: index }, getTimerTooltipText(index, timer))));
    return timersCount === 0 ? (React.createElement(React.Fragment, null)) : (React.createElement(React.Fragment, null,
        React.createElement(Hint, { icon: timer, theme: hintTheme, themeTooltip: hintTooltipTheme, tooltipText: getTimersTooltipText(timerListWithDefaultTimer) }),
        timersCount > 1 && React.createElement(TimersCount, null, timersCount),
        getTimerTextByType(timerListWithDefaultTimer)));
};
export default connector(RequestManualTimerIcon);
