export const DEFAULT_SETTINGS_ELEMENTS_BLOCK_TITLE = {
    defaultMessage: "Default settings",
    id: "account.new.test.project.left.sidebar.default.settings.title"
};
export const IMPORT_DATA_ELEMENTS_BLOCK_TITLE = {
    defaultMessage: "Import data",
    id: "account.new.test.project.left.sidebar.import.data.title"
};
export const PARAMETERS_ELEMENTS_BLOCK_TITLE = {
    defaultMessage: "Parameters",
    id: "account.new.test.project.left.sidebar.parameters.title"
};
