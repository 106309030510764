export const THREAD_GROUP_NAME_SELECT_LABEL = {
    defaultMessage: "Thread group",
    id: "account.report.editor.chart.serie.params.thread.group.name.select.label"
};
export const THREAD_GROUP_NAME_SELECT_PLACEHOLDER = {
    defaultMessage: "select thread group",
    id: "account.report.editor.chart.serie.params.thread.group.name.select.placeholder"
};
export const TRANSACTION_NAME_SELECT_LABEL = {
    defaultMessage: "Transaction name",
    id: "account.report.editor.chart.serie.params.transaction.name.select.label"
};
export const TRANSACTION_NAME_SELECT_PLACEHOLDER = {
    defaultMessage: "select transaction name",
    id: "account.report.editor.chart.serie.params.transaction.name.select.placeholder"
};
