import { Color, FontBodySmallBold } from "@boomq/uikit";
import styled from "styled-components";
import { IconRound } from "../../styled/Icons";
export const SlaStatus = styled.div `
    align-items: center;
    border: 1px solid ${Color.lightGrey85};
    border-radius: 12px;
    display: inline-flex;
    margin-top: 8px;
    padding: 4px 8px;
`;
export const StyledIconRound = styled(IconRound) `
    height: 8px;
    margin-right: 4px;
    min-height: 8px;
    min-width: 8px;
    width: 8px;
`;
export const Description = styled.div `
    ${FontBodySmallBold}
    color: ${Color.darkGrey5};
`;
