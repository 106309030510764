export const formatDateByLocale = (date, localeName, options) => {
    const validLocaleName = localeName || undefined;
    const validOptions = options || {};
    try {
        return Intl.DateTimeFormat(validLocaleName, validOptions).format(date);
    }
    catch (error) {
        return date ? `${date}` : "";
    }
};
