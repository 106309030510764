var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { getUniqueId, isEmpty } from "@boomq/utils";
import { CONSTANT_TIMER_MODEL_SCHEMA_NAME, TIMER_LABEL_DEFAULT_VALUE, UNIFORM_RANDOM_TIMER_MODEL_SCHEMA_NAME } from "./constants";
import { convertSecToMs } from "../date/convert";
import { getTimerValueByType, isEmptyTimerParams } from "../requestTimers";
import { getTestProjectModelElDefaultValues } from "../testProjectModelSchema";
import { RequestRandomTimerType, RequestTimerParams, RequestTimerType } from "../types/requestTimer";
import { getTimerType } from "../../models/requestTimer";
const generateTimerLabel = (timerId) => `${TIMER_LABEL_DEFAULT_VALUE}_${timerId}`;
const prepareTimerLabel = (timerId, label) => (label ? label : generateTimerLabel(timerId));
const prepareTimerCommonParams = (_a) => {
    var { enabled, id, label } = _a, restParams = __rest(_a, ["enabled", "id", "label"]);
    const timerId = id || getUniqueId();
    const timerEnabled = isEmpty(enabled) ? true : enabled;
    return {
        enabled: timerEnabled,
        id: timerId,
        label: prepareTimerLabel(timerId, label),
        [RequestTimerParams.Type]: getTimerType(restParams)
    };
};
const prepareConstantTimer = (_a, modelSchema) => {
    var { duration, groupId } = _a, restParams = __rest(_a, ["duration", "groupId"]);
    return Object.assign(Object.assign(Object.assign({}, getTestProjectModelElDefaultValues(modelSchema, CONSTANT_TIMER_MODEL_SCHEMA_NAME)), prepareTimerCommonParams(restParams)), { durationMs: convertSecToMs(duration) });
};
const prepareRandomTimer = (_a, modelSchema) => {
    var { groupId, lowerLimit, upperLimit } = _a, restParams = __rest(_a, ["groupId", "lowerLimit", "upperLimit"]);
    return (Object.assign(Object.assign(Object.assign({}, getTestProjectModelElDefaultValues(modelSchema, UNIFORM_RANDOM_TIMER_MODEL_SCHEMA_NAME)), prepareTimerCommonParams(restParams)), { lowerLimitMs: convertSecToMs(lowerLimit), upperLimitMs: convertSecToMs(upperLimit), [RequestTimerParams.Type]: RequestRandomTimerType.Uniform }));
};
const timerConvertFuncToDtoByType = {
    [RequestTimerType.Constant]: prepareConstantTimer,
    [RequestTimerType.Random]: prepareRandomTimer
};
export const prepareTimerDtoByType = (timerParams, modelSchema) => {
    const timerConvertFunc = timerConvertFuncToDtoByType[getTimerType(timerParams)];
    return typeof timerConvertFunc === "function" ? timerConvertFunc(timerParams, modelSchema) : timerParams;
};
export const prepareTimers = (timerList, timers, modelSchema) => (timerList || []).map((timerId) => prepareTimerDtoByType(timers[timerId], modelSchema));
export const generateDefaultTimer = (defaultTimerSettings, modelSchema) => isEmptyTimerParams(Object.assign({ [RequestTimerParams.Type]: getTimerType(defaultTimerSettings) }, getTimerValueByType(defaultTimerSettings, 0)))
    ? {}
    : {
        timers: [prepareTimerDtoByType(defaultTimerSettings, modelSchema)]
    };
