import { all, select, call, put, takeEvery } from "redux-saga/effects";
import { push } from "connected-react-router";
import { changeRequestGroupsByProfileDistribution } from "./newTest";
import { testProjectDetailsFlow } from "./testProjects";
import { changeTreeData, setElements } from "../actions/editor";
import { importDataToTestProjectFailure, importDataToTestProjectRequest, importDataToTestProjectSuccess, importDataFromFileToThreadGroupFailure, importDataFromFileToThreadGroupRequest, importDataFromFileToThreadGroupSuccess, importJmxFileRequest, importJmxFileSuccess, importJmxFileFailure, uploadImportFileFailure, uploadImportFileRequest, uploadImportFileSuccess, uploadJmxFileFailure } from "../actions/importTestProjectData";
import { hideLoader } from "../actions/loader";
import newTestActions from "../actions/newTest";
import { closeSiteModal } from "../actions/siteModal";
import { getEditorTreeData, getElements } from "../reducers/editor";
import { getImportedFileBySourceType, getImportedFileDataBySourceType } from "../reducers/importTestProjectData";
import { getProjectId, getProjectName, getRequests, getRequestGroups, getTestProjectSettingsField, getTestType, getThreadGroupsElements, getThreadGroupsTree, getThreadGroupsTransactions } from "../reducers/newTest";
import { getTestProjectYamlFile } from "../../helpers/api";
import { getResponseErrorData } from "../../helpers/errors";
import { parseJMXTestProjectFromYaml, parseTestProjectComposedByUser } from "../../helpers/parsers";
import { getTestProjectsRoute } from "../../helpers/routes";
import { TestType } from "../../helpers/types";
import { ImportDataSourceType } from "../../models/importData";
import { uploadImportFile } from "../../services/importSrv";
import { createTestProjectJMX, updateTestProjectByJMX } from "../../services/projectSrv";
const { changeRequestGroups, changeRequests, testProjectChangeParamsData } = newTestActions;
export function* importUploadedJmxFileFlow(location) {
    try {
        const response = yield call(getTestProjectYamlFile, location);
        const data = response && response.data ? response.data : {};
        const preparedTestProjectData = parseJMXTestProjectFromYaml({ yaml: data });
        const { baseJmxFileLocation, groups, httpRequestDefaults, isRunThreadGroupsConsecutively, JMXProfiles, JMXTimersSettings, requests, runnerLocations, runnerResources, sourceJmxThreadGroups, timers, transactions } = preparedTestProjectData || {};
        yield put(changeRequestGroups(groups));
        yield put(changeRequests(requests));
        yield put(testProjectChangeParamsData({
            baseJmxFileLocation,
            httpRequestDefaults,
            isRunThreadGroupsConsecutively,
            JMXTimersSettings,
            runnerLocations,
            runnerResources,
            sourceJmxThreadGroups,
            timers,
            transactions
        }));
        yield put(importJmxFileSuccess(JMXProfiles));
    }
    catch (e) {
        const response = e && e.response ? e.response : {};
        const data = response.data || {};
        yield put(importJmxFileFailure({
            code: data.errorCode,
            message: data.message,
            status: response.status
        }));
    }
}
function* getImportedFileData(sourceType) {
    const importedFileData = yield select(getImportedFileDataBySourceType, sourceType);
    return parseTestProjectComposedByUser({}, importedFileData);
}
function* importDataToTestProjectFlow(action) {
    try {
        const { sourceType } = action && action.payload ? action.payload : {};
        const [requestGroups, testRequests, testType, threadGroupsTree, transactions, elements] = yield all([
            select(getRequestGroups),
            select(getRequests),
            select(getTestType),
            select(getThreadGroupsTree),
            select(getThreadGroupsTransactions),
            select(getThreadGroupsElements)
        ]);
        const { elementsData, groups, requests, transactions: importedTransactions = [], threadGroupsTree: importedThreadGroupsTree } = yield* getImportedFileData(sourceType);
        yield put(changeRequests([...testRequests, ...requests]));
        yield* changeRequestGroupsByProfileDistribution([...requestGroups, ...groups]);
        yield put(testProjectChangeParamsData({
            elementsData: Object.assign(Object.assign({}, elements), elementsData),
            threadGroupsTree: Object.assign(Object.assign({}, threadGroupsTree), importedThreadGroupsTree),
            transactions: [...transactions, ...importedTransactions]
        }));
        yield put(closeSiteModal());
        yield put(importDataToTestProjectSuccess({
            sourceType,
            testType: testType === TestType.JMX ? TestType.Stable : testType
        }));
    }
    catch (e) {
        yield put(importDataToTestProjectFailure(getResponseErrorData(e)));
    }
}
function* uploadImportFileFlow(action) {
    const { files, sourceType } = action && action.payload ? action.payload : {};
    try {
        const { data } = yield call(uploadImportFile, files[0], {
            sourceType,
            projectModelVersion: "V_2_0_0"
        });
        yield put(uploadImportFileSuccess({ data, files, sourceType }));
    }
    catch (e) {
        yield put(uploadImportFileFailure(Object.assign(Object.assign({}, getResponseErrorData(e)), { sourceType })));
    }
}
function* createTestProjectJMXFlow(files) {
    const testProjectName = yield select(getProjectName);
    const testProjectComment = yield select(getTestProjectSettingsField, "comment");
    const { data } = yield call(createTestProjectJMX, files, {
        testProjectName,
        testProjectComment
    });
    yield put(closeSiteModal());
    yield put(hideLoader());
    return data && data.id ? yield put(push(`${getTestProjectsRoute()}/${data.id}`)) : undefined;
}
function* updateTestProjectByJMXFlow(testProjectId, files) {
    const testProjectName = yield select(getProjectName);
    const testProjectComment = yield select(getTestProjectSettingsField, "comment");
    const { data } = yield call(updateTestProjectByJMX, testProjectId, files, {
        testProjectName,
        testProjectComment
    });
    yield put(closeSiteModal());
    yield put(hideLoader());
    yield* testProjectDetailsFlow({
        payload: {
            id: testProjectId,
            version: data.id
        }
    });
}
function* importJmxFileFlow() {
    try {
        const jmxFiles = yield select(getImportedFileBySourceType, ImportDataSourceType.JMX);
        const testProjectId = yield select(getProjectId);
        return testProjectId
            ? yield* updateTestProjectByJMXFlow(testProjectId, jmxFiles)
            : yield* createTestProjectJMXFlow(jmxFiles);
    }
    catch (e) {
        const response = e && e.response ? e.response : {};
        const data = response.data || {};
        yield put(closeSiteModal());
        yield put(uploadJmxFileFailure({
            exceptionMessage: data.exceptionMessage,
            invalidModulesInfo: data.invalidModulesInfo,
            missingNeededFiles: data.missingNeededFiles,
            status: response.status
        }));
    }
}
function* importDataFromFileToThreadGroupFlow({ payload }) {
    try {
        const { groupId, sourceType } = payload;
        const editorElements = yield select(getElements);
        const editorTreeData = yield select(getEditorTreeData);
        const { elementsData, threadGroupsTree } = yield* getImportedFileData(sourceType);
        const importedElementsData = Object.keys(elementsData).reduce((res, elementId) => (Object.assign(Object.assign({}, res), { [elementId]: Object.assign(Object.assign({}, elementsData[elementId]), { groupId, path: editorTreeData.length }) })), {});
        const importedTreeData = Object.keys(threadGroupsTree).reduce((res, threadGroupKey) => [...res, ...threadGroupsTree[threadGroupKey]], []);
        yield put(setElements(Object.assign(Object.assign({}, editorElements), importedElementsData)));
        yield put(changeTreeData([...editorTreeData, ...importedTreeData]));
        yield put(closeSiteModal());
        yield put(importDataFromFileToThreadGroupSuccess(payload));
    }
    catch (e) {
        yield put(importDataFromFileToThreadGroupFailure(getResponseErrorData(e)));
    }
}
export function* importTestProjectDataFlow() {
    yield takeEvery(importDataToTestProjectRequest, importDataToTestProjectFlow);
    yield takeEvery(importDataFromFileToThreadGroupRequest, importDataFromFileToThreadGroupFlow);
    yield takeEvery(importJmxFileRequest, importJmxFileFlow);
    yield takeEvery(uploadImportFileRequest, uploadImportFileFlow);
}
