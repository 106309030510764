import styled, { css } from "styled-components";
import { media, FormTitle } from "@boomq/common";
import { CustomCSS } from "../../../helpers/types";

export const AuthCodeLink = styled.a`
    word-break: break-word;
`;

export const Code = styled.div`
    opacity: 0.6;
    border: 1px solid #91b0cf;
    border-radius: 30px;
    font-size: 16px;
    font-weight: normal;
    line-height: 30px;
    outline: none;
    padding: 12px 28px;
    width: 100%;
`;

export const CopyButton = styled.div<{ isCodeCopied?: boolean }>`
    margin-left: 20px;
    min-width: 270px;
    text-align: center;
    ${({ isCodeCopied }): CustomCSS =>
        isCodeCopied
            ? css``
            : css`
                  cursor: pointer;
                  text-decoration: underline;
              `}
    ${media.tablet} {
        margin-left: 0;
        min-width: 240px;
    }
`;

export const CopyCodeSection = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    line-height: 70px;
    input {
        text-align: center;
    }
    ${media.tablet} {
        flex-wrap: wrap;
    }
`;

export const StyledFormTitle = styled(FormTitle)`
    line-height: 70px;
`;
