var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { Select } from "@boomq/uikit";
import { getSelectTheme } from "../SlaItem";
import { SlaTransactionItem } from "../SlaTransactionItem";
import { SlaTargetType } from "../../../models/sla";
export const SlaRequestItem = (_a) => {
    var { data, onChangeRequest, requestItems, requestSelectPlaceholder } = _a, restProps = __rest(_a, ["data", "onChangeRequest", "requestItems", "requestSelectPlaceholder"]);
    return (React.createElement(SlaTransactionItem, Object.assign({ data: data }, restProps),
        React.createElement(Select, { autoSelect: true, defaultValue: data.requestName, disabled: restProps.isDisabled, items: requestItems, onChange: onChangeRequest, placeholder: requestSelectPlaceholder, theme: getSelectTheme(SlaTargetType.Request, restProps.isShowSlaResult) })));
};
